// use workflowResult as some condition
import { useCallback, useEffect, useRef, useState } from 'react';

import { ArrowLeftEndOnRectangleIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css';
import Cookies from 'js-cookie';
import { throttle } from 'lodash';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import { createWorkflow, fetchWorkflowList } from '../api.js';
import DisplayFormattedText from '../AskPage/components/markdown.js';
import SingleLineInput from '../AskPage/components/singleLineInput.js';
import { useAlert } from '../utils/context/alert.js';
import { useHistory } from '../utils/context/history.js';
import { useLanguage } from '../utils/context/lang.js';

import WorkflowInputForm from './components/form.js';
import TableComponent from './components/table.js';
import { getWorkflowInputByType } from './const/input-const/workflow.js';
import { i18n } from './i18n/chat.js';
// status include 'complete', 'current', 'upcoming'
const columnWidth = {
  Endpoint: 900,
  Dose: 600,
};
const steps = [
  { id: '1. ', name: { en: 'Submit Query', zh: '提交查询' }, href: '#' },
  { id: '2.', name: { en: 'Processing', zh: '处理' }, href: '#' },
  { id: '3.', name: { en: 'Review', zh: '审查' }, href: '#' },
];
export function WorkflowDetail() {
  const navigate = useNavigate();
  const showAlert = useAlert();
  const gridRef = useRef(null);
  const { lang } = useLanguage();
  const { updateHistory } = useHistory();
  const [ currentStep, setCurrentStep ] = useState(1);
  const [ currentForm, setCurrentForm ] = useState({});
  const [ newWorkflowId, setNewWorkflowId ] = useState(null);
  const [ workflowResult, setWorkflowResult ] = useState(null);
  const [ processHint, setProcessHint ] = useState(null);
  const [ reachLimit, setReachLimit ] = useState(false);
  const bottomRef = useRef(null);
  const [ initialFetchStatus, fetchedTask ] = useLoaderData();
  const { workflowId } = useParams(); // path: workflow/workflowId
  const { urlWorkflowType } = useParams(); // path: new/urlWorkflowType
  const mockClient = useRef(null);
  const [ paddingBottom, setPaddingBottom ] = useState('pb-12');
  const [ isSocketOpen, setIsSocketOpen ] = useState(false);
  const latestWorkflowIdRef = useRef(newWorkflowId); // Create a ref for the newWorkflowId state

  console.log('========workflowId', workflowId);
  console.log('=====url agent type', urlWorkflowType);
  // this register the query for fetch the latest conversation
  const threadId = newWorkflowId || workflowId;
  useEffect(() => {
    window.gtag('event', 'page_view', {
      event_category: 'page_view',
      event_label: 'workflow_detail',
      workflow_id: workflowId,
    });
  }, [ workflowId ]);
  // Update the ref every time newWorkflowId changes
  useEffect(() => {
    latestWorkflowIdRef.current = threadId;
  }, [ threadId ]);

  useEffect(() => {
    // mockClient.current = new WebSocket('ws://localhost:8080');
    const token = Cookies.get('noahAccessToken');
    /* eslint-disable */
    const wsBaseURL = `wss://${
      window.location.host.includes("localhost")
        ? process.env.REACT_APP_API_URL.replace("http://", "").replace(
            "https://",
            ""
          ) //just for local dev
        : window.location.host
    }/ws/chat/`;
    let wsFullPath = `${wsBaseURL}?token=${token}`;
    console.log(wsFullPath);
    // wsFullPath = 'wss://socketsbay.com/wss/v2/1/demo/'
    mockClient.current = new WebSocket(wsFullPath);
    console.log(mockClient.current);
    mockClient.current.onopen = () => {
      console.warn("Socket client is connected to server.");
      setIsSocketOpen(true);
    };
    mockClient.current.onclose = (error) => {
      console.log("closed closed closed");
    };
    mockClient.current.onerror = (error) =>
      console.warn("WebSocket connection error:", error);
    mockClient.current.onmessage = (e) => {
      const result = JSON.parse(e.data);
      console.warn("Socket client Received: ", result);
      console.log(latestWorkflowIdRef.current);
      if (result.id !== latestWorkflowIdRef.current) {
        console.log("not the same thread");
        return;
      }
      switch (result.task_status) {
        case "start":
          console.log("running");
          // setRunningAnswer(result);
          break;
        case "process":
          console.log("process");
          setProcessHint(result.process.at(-1));
          setCurrentStep(2);
          // setWorkflowResult(result);
          break;
        case "review":
          setCurrentStep(3);
          setWorkflowResult(result);
          console.log("review");
          // showAlert("error", result.message);
          break;
        case "complete":
          setCurrentStep(3);
          console.log("complete");
          setWorkflowResult(result);
          break;
        case "error":
          setProcessHint("encountering error", result);
          showAlert("error", JSON.stringify(result.message));
        default:
          break;
      }
    };

    return () => {
      if (mockClient.current) {
        mockClient.current.close();
        mockClient.current = null;
      }
    };
  }, []);

  useEffect(() => {
    // console.log('来啦来啦来啦来啦', {initialFetchStatus, fetchedTask});
    if (initialFetchStatus === null) {
      setCurrentStep(1);
    } else if (initialFetchStatus === "complete") {
      setWorkflowResult(fetchedTask);
      setCurrentStep(3);
    } else {
      setCurrentStep(2);
    }
  }, [initialFetchStatus, fetchedTask]);

  // when a new thread is generated, update url and fetch the data
  useEffect(() => {
    if (newWorkflowId) {
      window.history.pushState(null, "", `/workflow/${newWorkflowId}`);
    }
  }, [newWorkflowId]);

  // begin scroll to bottom
  const autoScrollEnabled = useRef(true);

  const handleAutoScroll = useCallback(
    throttle(() => {
      if (autoScrollEnabled.current) {
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    }, 500), // Throttle the scroll function to run once every 100ms
    []
  );

  useEffect(() => {
    const handleUserScroll = () => {
      autoScrollEnabled.current = false; // Disable auto-scroll when user manually scrolls
    };

    window.addEventListener("scroll", handleUserScroll);

    return () => {
      window.removeEventListener("scroll", handleUserScroll);
    };
  }, []);

  useEffect(() => {
    handleAutoScroll();
  }, [paddingBottom, workflowResult, handleAutoScroll]);
  // end scroll to bottom
  const onSubmit = (text) => {
    const contextUUID = newWorkflowId || workflowId;
    const queryParams = new URLSearchParams({
      q: text,
      contextUUID,
      contextType: "workflow",
    }).toString();
    navigate(`/ask/?${queryParams}`, {
      state: {
        title: `workflow ${contextUUID}`,
        sourceURL: `/workflow/${contextUUID}`,
      },
    });
    console.log(text, `workflow ${contextUUID}`);
  };
  const currentAgentType = fetchedTask?.workflow || urlWorkflowType;
  // alert(currentAgentType);

  const createQuestion = async (formData) => {
    // showAlert("error", '>>???');
    // return;
    try {
      console.log(JSON.stringify(formData));
      // return;
      setCurrentForm(formData);
      if (mockClient.current.readyState !== mockClient.current.OPEN) {
        showAlert(
          "error",
          reachLimit
            ? i18n.DAILY_LIMIT_ERROR[lang]
            : i18n.CONNECTION_ERROR[lang],
          reachLimit
            ? [i18n.DAILY_LIMIT_HINT1[lang], i18n.DAILY_LIMIT_HINT2[lang]]
            : []
        );
        return;
      }
      // keep as rest API! no need to move to websocket
      const threadIdData = await createWorkflow(currentAgentType);
      setCurrentStep(2);
      const threadId = threadIdData.data.id;
      console.log(threadIdData, threadId);
      // const threadId = 'db0224d5-ca9c-4559-b2f0-242c3cb115xx';
      setNewWorkflowId(threadId);
      setTimeout(() => {
        updateHistory();
      }, 5000);
      mockClient.current.send(
        JSON.stringify({
          id: threadId,
          parameters: formData,
        })
      );
      console.log(formData);
    } catch (error) {
      console.log('new!!!!', error)
      switch (error?.response?.status) {
        case 429:
          showAlert("error", i18n.DAILY_LIMIT_ERROR[lang]);
          break;
          // setReachLimit(true);
        case 401:
          showAlert("error", i18n.LOGIN_MESSAGE[lang]);
          break;
        default:
          showAlert("error", error?.response?.data?.detail);
          break;
      }
    }
  };

  // if (!currentAgentType) {
  //   // redirect to home page if no agent type is found
  //   showAlert("error", i18n.NO_AGENT_TYPE[lang]);
  //   return <Navigate to="/workflow" replace />;
  // }

  return (
    // isSocketOpen &&
    <div className="relative h-screen overflow-y-scroll">
      <div
        className={`absolute bg-base-100 w-full h-full flex-1 overflow-y-scroll ${paddingBottom}`}
      >
        <nav aria-label="Progress" className="sticky top-0 z-[10]">
          <ol
            role="list"
            className="p-8 border-b space-y-4 md:flex md:space-x-8 md:space-y-0 bg-base-100"
          >
            {steps.map((step, index) => (
              <li
                key={step.name[lang]}
                className="md:flex-1"
                onClick={(e) => {
                  e.preventDefault();
                  if (initialFetchStatus === "complete" || workflowResult) {
                    setCurrentStep(index + 1);
                  }
                }}
              >
                {index <= currentStep - 1 || workflowResult ? (
                  <a
                    href={step.href}
                    className="group flex flex-col border-l-4 border-blue-500 py-2 pl-4 hover:border-blue-600 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                  >
                    <span className="text-sm font-medium text-blue-500 group-hover:text-blue-600">
                      {step.id}
                      <span className="text-sm font-medium">
                        {step.name[lang]}
                      </span>
                    </span>
                  </a>
                ) : (
                  <a
                    href={step.href}
                    className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                  >
                    <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                      {step.id}
                      <span className="text-sm font-medium">
                        {step.name[lang]}
                      </span>
                    </span>
                  </a>
                )}
              </li>
            ))}
          </ol>
        </nav>
        {currentStep == 1 && (
          <div className="p-8">
            <WorkflowInputForm
              title={i18n[currentAgentType][lang]}
              // title={getWorkflowInputByType(currentAgentType).title}
              params={getWorkflowInputByType(currentAgentType).params}
              defaultValue={fetchedTask?.parameter_json || currentForm}
              enableSave={workflowResult === null}
              onSave={(formData) => {
                createQuestion(formData);
              }}
            />
          </div>
        )}
        {currentStep == 2 && (
          <div className="p-8">
            <DisplayFormattedText text={processHint} />
          </div>
        )}
        {currentStep == 3 && (
          <div className="p-8 ag-theme-quartz-auto-dark">
            <h2 className="mb-4 text-lg">{i18n[currentAgentType][lang]}</h2>
            <button
              type="button"
              className="inline-flex items-center gap-x-1.5 rounded-full disabled:bg-blue-300 bg-brand px-2.5 py-1.5 text-sm text-white shadow-sm hover:bg-blue-700 mr-4 mb-4"
              onClick={() => {
                location.href = `/workflow/new/${currentAgentType}`;
                // TODO: ziwen, for better performance, but it can be problematic when switching routes.
                // navigate(`/workflow/new/${currentAgentType}`);
              }}
            >
              <ArrowLeftEndOnRectangleIcon className="w-5 h-5 -ml-0.5" />
              {i18n.CREATE_NEW[lang]}
            </button>
            {workflowResult.attachments &&
              workflowResult.attachments.map((attachment, index) => (
                <>
                  <TableComponent
                    key={index}
                    rowData={attachment.data}
                    type={attachment.type}
                  />
                  {attachment.data.length==30 && <p className="text-sm text-right text-neutral-content mt-4">
                    {i18n.THIRTY_LIMITS[lang]}
                  </p>}
                </>
              ))}

            <DisplayFormattedText text={workflowResult.answer} />
            <div ref={bottomRef} className="h-6"></div>
          </div>
        )}
      </div>
      {currentStep == 3 && (
        <SingleLineInput
          className="absolute bottom-8 md:w-3/4 lg:w-2/3 w-full mx-auto left-0 right-0"
          onSubmit={onSubmit}
          enableUpload={false}
          // disabled={isFollowupFetching}
          onFileCountChange={(count) => {
            // if (count > 0) {
            //   setPaddingBottom("pb-32");
            // } else {
            //   setPaddingBottom("pb-12");
            // }
          }}
        />
      )}
    </div>
  );
}

export async function workflowDetailLoader({ params }) {
  if (!params.workflowId) {
    console.log("no workflow ID");
    return [null, null];
  }
  // https://localhost:3000/workflow/db0224d5-ca9c-4559-b2f0-242c3cb1151a
  const result = await fetchWorkflowList(params.workflowId);
  console.log("===", result);
  const initialFetchStatus = result?.task_status;

  // return ['running', {workflow: 'clinical-design'}];
  return [initialFetchStatus, result];
}
