const NEW_THREAD = {
  en: 'New Thread',
  zh: '新建话题',
};
  
const HISTORY = {
  en: 'History',
  zh: '历史记录',
};
  
const LOGOUT = {
  en: 'Log Out',
  zh: '登出',
};
  
const FEEDBACK = {
  en: 'Feedback',
  zh: '反馈',
};

const DISCOVER = {
  en: 'Discover',
  zh: '发现',
};

const WORKFLOW = {
  en: 'Workflow',
  zh: '工作流',
};

const LOGIN = {
  en: 'Log In',
  zh: '登录',
}

const REGISTER = {
  en: 'Register',
  zh: '注册',
}

export const i18n = {
  NEW_THREAD,
  HISTORY,
  LOGOUT,
  FEEDBACK,
  DISCOVER,
  WORKFLOW,
  LOGIN,
  REGISTER,
}