import { useEffect, useState } from 'react';

import {
  CalendarDaysIcon,
  ChatBubbleLeftIcon,
  CheckCircleIcon,
  EllipsisVerticalIcon,
} from '@heroicons/react/24/outline';
import { Link, redirect, useLoaderData, useNavigate } from 'react-router-dom';

import { getDiscoverList } from '../api.js';
import { useLanguage } from '../utils/context/lang.js';
import { timeAgo } from '../utils/date.js';
import useIntersectionObserver from '../utils/hooks/useIntersectionObserver.js';

import { agents } from './const/agent.js';
import { i18n } from './i18n/menu.js';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function DiscoverHistory() {
  const { lang } = useLanguage();
  const navigate = useNavigate();
  const [ data, setData ] = useState([]);
  const [ page, setPage ] = useState(1); // TODO: change to 2
  const [ loading, setLoading ] = useState(false); // Lock state
  const [ hasMore, setHasMore ] = useState(true); // Termination condition state
  const histories = useLoaderData();

  useEffect(() => {
    window.gtag('event', 'page_view', {
      event_category: 'page_view',
      event_label: 'discover_landing',
    });
  }, []);

  const handleClick = (projectId) => {
    window.gtag('event', 'click', {
      event_category: 'HotArticle',
      event_label: `Article_${projectId}`,
    });
    navigate(`/discover/article/${projectId}`);
  };
  const loadMore = async () => {
    // If currently loading or no more data to fetch, return early.
    if (loading || !hasMore) return;
    setLoading(true);

    try {
      const newData = await getDiscoverList(page);
      console.log('new discover articles!', newData);
      // Check if there's no more data to load (based on server response).
      // This logic might vary depending on the structure of your server's response.
      if (newData?.length === 0 || !newData) {
        setHasMore(false);
      } else {
        setData((prevData) => [ ...prevData, ...newData ]);
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      console.error('Failed to fetch new data: ', error);
      // Handle errors as needed
    } finally {
      setLoading(false);
    }
  };

  const [ ref, isIntersecting ] = useIntersectionObserver({
    root: null,
    rootMargin: '0px',
    threshold: 1.0, // when the sentinel is fully visible
  });

  // Trigger 'loadMore' when the sentinel becomes visible
  useEffect(() => {
    if (isIntersecting && !loading && hasMore) {
      loadMore();
    }
  }, [ isIntersecting, loadMore ]);

  if (!histories || histories.length === 0) {
    return (
      <div className="flex items-center justify-center h-screen">
        <h2 className="text-lg font-semibold text-secondary-content">
          No data available
        </h2>
      </div>
    );
  }
  return (
    <>
      <div className='sticky top-0 z-10 bg-base-100 py-8'>
        <h2 className=" text-3xl font-bold tracking-tight text-left border-l-8 border-brand xs:w-full lg:w-2/3 mx-auto pl-6">
          {i18n.DISCOVER[lang]}
        </h2>
      </div>
      <div className="xs:w-full lg:w-2/3 mx-auto z-10">
        {/* banners */}
        <div className="my-0">
          {/* <h2 className="text-sm font-medium text-gray-500">Pinned Projects</h2> */}
          <ul
            role="list"
            className="grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-2"
          >
            {histories.banners.map((project) => (
              <li
                key={project.name}
                className="col-span-1 flex rounded-md shadow-sm cursor-pointer"
                onClick={() => handleClick(project.id)}
              >
                <div
                  className={classNames(
                    project.bgColor,
                    'flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white'
                  )}
                >
                  {/* {project.img &&   <img
                    className="h-full w-full bg-gray-50"
                    src={project.img}
                  />} */}
                  {/* {project.initials} */}
                  <CalendarDaysIcon className="h-5 w-5" aria-hidden="true" />
                </div>
                <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                  <div className="flex-1 truncate px-4 py-2 text-sm">
                    <p className="font-medium text-gray-900 hover:text-gray-600">
                      {project.name}
                    </p>
                    <p className="text-gray-500">{project.abstract}</p>
                  </div>
                  {/* <div className="flex-shrink-0 pr-2">
                    <button
                      type="button"
                      className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      <span className="sr-only">Open options</span>
                      <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div> */}
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="mx-auto grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-3">
          {[ ...histories.articles, ...data ].map((article, index) => (
            <div
              key={article.id}
              className={`flex flex-col overflow-hidden rounded-lg border transition-shadow duration-50 ${
                index % 4 === 0 ? 'xl:col-span-3' : 'xl:col-span-1'
              } hover:shadow-lg`}
            >
              <Link
                className="group block h-full outline-none"
                to={`/discover/article/${article.id}`}
              >
                <div className="relative h-full rounded-lg transition-transform duration-50 group-hover:-translate-y-1">
                  <img
                    alt={article.title}
                    className={`h-48 w-full object-cover rounded-t-lg ${
                      index % 4 === 0 ? 'h-48' : 'h-36'
                    }`}
                    src={article.cover_urls}
                  />
                  <div className="flex h-full flex-col p-6 bg-base-100">
                    <div className="">
                      <h2 className="text-lg font-medium">{article.title}</h2>
                      <p className="mt-2 line-clamp-2 text-base">{article.abstract}</p>
                    </div>
                    <div className="flex items-center justify-between mt-2">
                      <span className="text-sm text-gray-500">{article.author}</span>
                      <span className="text-xs text-gray-400">
                        {new Date(article.published_date).toLocaleDateString()}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}

          {/* {[ ...histories.articles, ...data ].map((history) => (
            <li
              key={history.feed_id}
              className="flex flex-wrap items-center justify-between md:gap-x-6 gap-y-4 py-5 sm:flex-nowrap"
            >
              <div className="flex items-center gap-x-4">
                <img
                  className="h-24 w-24 bg-gray-50 ring-2 ring-white"
                  src={history.cover_url}
                />

                <div>
                  <p className="text-lg font-semibold leading-6 text-secondary-content hover:text-primary-content">
                    <a
                      href={`/discover/article/${history.id}`}
                      className="hover:underline"
                    >
                      {history.title}
                    </a>
                  </p>
                  <p className="text-sm text-gray-500">
                    {history.abstract || ''}
                  </p>
                  <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-secondary-content">
                    <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                      <circle cx={1} cy={1} r={1} />
                    </svg>
                    <p>
                      <time dateTime={history.published}>
                        {timeAgo(history.published, lang)}{' '}
                      </time>
                    </p>
                  </div>
                </div>
              </div>
            </li>
          ))} */}
        </div>
        {hasMore && (
          <div className="text-center" ref={ref}>
            Loading more...
          </div>
        )}
      </div>
    </>
  );
}

export async function discoverHistoryLoader() {
  // const histories = await getDiscoverList();
  return {
    banners: [
      // {
      //   id: 'catalyst-calendar',
      //   name: 'Clinical Catalyst Calendar',
      //   abstract: 'Keep track of the latest trials',
      //   initials: 'CC',
      //   href: '#',
      //   members: 16,
      //   bgColor: 'bg-pink-600',
      //   img: '/misc/calendar-icon-free-vector.jpg',
      // },
      // {
      //   id: 'catalyst-calendar',
      //   name: 'Weekly Highlights',
      //   abstract: "News you don't want to miss",
      //   initials: 'CC',
      //   href: '#',
      //   members: 16,
      //   bgColor: 'bg-pink-600',
      //   img: '/misc/calendar-icon-free-vector.jpg',
      // },
      // { name: 'Component Design', initials: 'CD', href: '#', members: 12, bgColor: 'bg-purple-600' },
    ],
    articles: [
      // {
      //   id: '2024-06-asco',
      //   title: 'Update on ASCO 2024 - Plenary and Oral Sessions',
      //   abstract:
      //     'An overview of the latest research findings and clinical trial results on Plenary and Oral Sessions from the ASCO 2024 Annual Meeting',
      //   date_created: '2024-06-10T03:58:32.327058Z',
      //   published: '2024-06-10T04:45:18.025270Z',
      //   author: 'user1@example.com',
      //   cover_url: '/misc/ASCO-2024-logo-square.jpg',
      // },
      // // {
      // //   'id': 'catalyst-calendar',
      // //   'title': 'Clinical Catalyst Calendar',
      // //   'abstract': 'Calendar of Clinical Events',
      // //   'date_created': '2024-06-04T03:58:32.327058Z',
      // //   'published': '2024-06-04T04:45:18.025270Z',
      // //   'author': '',
      // //   'cover_url': '/misc/calendar-icon-free-vector.jpg',
      // // },
      // {
      //   id: '2024-asco',
      //   title: 'Summary from ASCO 2024',
      //   abstract:
      //     'Comprehensive Overview of Innovative Cancer Treatments and Research Findings from the Annual Meeting',
      //   date_created: '2024-06-02T03:58:32.327058Z',
      //   published: '2024-06-02T04:45:18.025270Z',
      //   author: 'user1@example.com',
      //   cover_url: '/misc/ASCO-2024-logo-square.jpg',
      // },
    ],
  };
}
