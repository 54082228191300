import { useEffect, useState } from 'react';

import {
  ChatBubbleLeftIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/outline';
import { Link, redirect, useLoaderData } from 'react-router-dom';

import { getHistoryList } from '../api.js';
import { useLanguage } from '../utils/context/lang.js';
import { timeAgo } from '../utils/date.js';
import useIntersectionObserver from '../utils/hooks/useIntersectionObserver.js';

import { agents } from './const/agent.js';

export function AskHistory() {
  const { lang } = useLanguage();
  const [ data, setData ] = useState([]);
  const [ page, setPage ] = useState(2); // first page comes from loader
  const [ loading, setLoading ] = useState(false); // Lock state
  const [ hasMore, setHasMore ] = useState(true); // Termination condition state
  const histories = useLoaderData();

  const loadMore = async () => {
    // If currently loading or no more data to fetch, return early.
    if (loading || !hasMore) return;

    setLoading(true);

    try {
      const newData = await getHistoryList(page);

      // Check if there's no more data to load (based on server response).
      // This logic might vary depending on the structure of your server's response.
      if (newData.length === 0) {
        setHasMore(false);
      } else {
        setData((prevData) => [ ...prevData, ...newData ]);
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      console.error('Failed to fetch new data: ', error);
      // Handle errors as needed
    } finally {
      setLoading(false);
    }
  };

  const [ ref, isIntersecting ] = useIntersectionObserver({
    root: null,
    rootMargin: '0px',
    threshold: 1.0, // when the sentinel is fully visible
  });

  // Trigger 'loadMore' when the sentinel becomes visible
  useEffect(() => {
    if (isIntersecting && !loading && hasMore) {
      loadMore();
    }
  }, [ isIntersecting, loadMore ]);

  if (!histories || histories.length === 0) {
    return (
      <div className="flex items-center justify-center h-screen">
        <h2 className="text-lg font-semibold text-secondary-content">
          No data available
        </h2>
      </div>
    );
  }
  return (
    <div className="xs:w-full lg:w-2/3 mx-auto">
      <ul role="list" className="divide-y divide-gray-100">
        {[ ...histories, ...data ].map((history) => {
          // depending on the type of history, render different components
          if (history.type === 'thread')
            return (
              <li
                key={history.id}
                className="flex flex-wrap items-center justify-between md:gap-x-6 gap-y-4 py-5 sm:flex-nowrap"
              >
                <div>
                  <p className="text-sm font-semibold leading-6 text-secondary-content hover:text-primary-content">
                    <a href={`/ask/${history.id}`}>{history.name}</a>
                  </p>
                  <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-secondary-content">
                    <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                      <circle cx={1} cy={1} r={1} />
                    </svg>
                    <p>
                      <time dateTime={history.time_updated}>
                        {timeAgo(history.time_updated, lang)}{' '}
                      </time>
                    </p>
                  </div>
                </div>
                {/* <dl className="flex w-full flex-none justify-between gap-x-8 sm:w-auto">
                  <div className="flex -space-x-0.5">
                    <dt className="sr-only">Commenters</dt>
                    <dd key={history.id}>
                      <img
                        className="h-10 w-10 rounded-full bg-gray-50 ring-2 ring-white"
                        src={
                          agents.find(
                            (agent) => agent.category === history.agent
                          )?.imageUrl
                        }
                        alt={
                          agents.find(
                            (agent) => agent.category === history.agent
                          )?.name
                        }
                      />
                    </dd>
                  </div>
                </dl> */}
              </li>
            );
          if (history.type === 'workflow')
            return (
              <li
                key={history.id}
                className="flex flex-wrap items-center justify-between md:gap-x-6 gap-y-4 py-5 sm:flex-nowrap"
              >
                <div>
                  <p className="text-sm font-semibold leading-6 text-secondary-content hover:text-primary-content">
                    <a href={`/workflow/${history.id}`}>{history.name}</a>
                  </p>
                  <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-secondary-content">
                    <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                      <circle cx={1} cy={1} r={1} />
                    </svg>
                    <p>
                      <time dateTime={history.time_updated}>
                        {timeAgo(history.time_updated, lang)}{' '}
                      </time>
                    </p>
                  </div>
                </div>
              </li>
            );
        })}
      </ul>
      {hasMore && (
        <div className="text-center" ref={ref}>
          Loading more...
        </div>
      )}
    </div>
  );
}

export async function askHistoryLoader() {
  return await getHistoryList();
}
