import React, { useEffect, useRef, useState } from 'react';

import { ClockIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useLoaderData, useNavigate, useParams } from 'react-router';

import { getDiscoverDetail } from '../api.js';
import DisplayFormattedText from '../AskPage/components/markdown.js';
import SingleLineInput from '../AskPage/components/singleLineInput.js';
import { i18n } from '../Common/i18n/common.js';
import { useHistory } from '../utils/context/history.js';
import { useLanguage } from '../utils/context/lang.js';

import ReferencePill from './components/referencePill.js';

const Article = ({ article }) => {
  const navigate = useNavigate();
  const { lang } = useLanguage();
  const paragraphsRef = useRef([]);
  const { articleId } = useParams();
  const { exploreContext, setExploreContext } = useHistory();
  const [ currentIndex, setCurrentIndex ] = useState(null);
  const visibilityMap = useRef({});

  // request article data
  // useEffect(() => {
  //   getDiscoverDetail(25).then((data) => {
  //     console.log(data);
  //   });
  // }, []);

  const scrollToParagraph = (index) => {
    // Remove immediate currentIndex update
    paragraphsRef.current[index].scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const onSubmit = (text) => {
    const queryParams = new URLSearchParams({
      q: text,
      contextUUID: articleId,
      contextType: 'discoverArticle',
    }).toString();
    navigate(`/ask/?${queryParams}`, {
      state: { title: article.title, sourceURL: `/discover/article/${articleId}` },
    });
    console.log(text, articleId);
  };

  useEffect(() => {
    window.gtag('event', 'page_view', {
      event_category: 'page_view',
      event_label: 'article_detail',
      article_id: articleId,
    });
  }, [ articleId ]);

  const onReferenceClick = (reference) => {
    window.gtag('event', 'click', {
      event_category: 'reference',
      event_label: reference.title,
    });
    window.open(reference.link, '_blank');
  };

  const onFollowUpQuestionClick = (question) => {
    window.gtag('event', 'click', {
      event_category: 'follow_up_question',
      event_label: question,
    });
    onSubmit(question);
  };

  useEffect(() => {
    const handleVisibilityChange = (entries) => {
      entries.forEach((entry) => {
        const index = paragraphsRef.current.indexOf(entry.target);
        if (index !== -1) {
          visibilityMap.current[index] = entry.intersectionRatio;
        }
      });

      // Find the paragraph with the highest visibility
      let maxRatio = 0;
      let mostVisibleIndex = -1;

      Object.keys(visibilityMap.current).forEach((key) => {
        const ratio = visibilityMap.current[key];
        if (ratio > maxRatio) {
          maxRatio = ratio;
          mostVisibleIndex = key;
        }
      });

      if (mostVisibleIndex !== -1) {
        setCurrentIndex(parseInt(mostVisibleIndex, 10));
      }
    };

    const observerOptions = {
      threshold: Array.from({ length: 101 }, (_, i) => i / 100), // Thresholds from 0 to 1
    };

    const observer = new IntersectionObserver(
      handleVisibilityChange,
      observerOptions
    );

    paragraphsRef.current.forEach((paragraph) => {
      if (paragraph) {
        observer.observe(paragraph);
      }
    });

    // Cleanup function
    return () => {
      paragraphsRef.current.forEach((paragraph) => {
        if (paragraph) {
          observer.unobserve(paragraph);
        }
      });
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mx-auto h-full w-full px-md md:px-lg max-w-5xl pb-24">
      <img
        className="w-full h-60 my-12 object-cover rounded-sm"
        alt="article banner"
        src={article.cover_urls}
      />
      <section></section>
      <div className="relative flex flex-col grid-cols-12 gap-md md:gap-xl w-full md:grid">
        {/* Main Article Content */}
        <div className="col-span-8">
          <h1 className="text-4xl mb-4 leading-snug">{article.title}</h1>

          <section className="flex justify-between items-center space-x-4 mb-2 text-neutral-content text-sm">
            <span className="">{i18n.CURATED_BY[lang]} {article.author}</span>
            <span className="flex items-center"><ClockIcon className="w-4 h-4 mr-2" />{new Date(article.published_date).toLocaleDateString()}</span>
          </section>

          <section>
            <div className="relative flex-col gap-1 my-2">
              <div className={`relative md:grid md:grid-cols-${Math.min(4,article.references.length)} gap-2 min-h-24`}>
                {article.references?.slice(0,4)?.map((reference, index) => (
                  <div key={index} className="flex relative flex-col px-2 py-2 my-2 bg-base-300 rounded-sm justify-between cursor-pointer"
                    onClick={() => onReferenceClick(reference)}>
                    <p className="line-clamp-3 text-left text-xs text-primary-color mb-2">
                      {reference.title}
                    </p>
                    <p className="line-clamp-1 text-left text-xs font-bold text-primary-color">
                      {reference.link}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </section>
          {/* reference at top to be added */}
          {/* {article.references && article.references.length > 0 && (
            <div className="flex flex-row grid grid-cols-4 items-center space-x-4">
              {article.references.map((reference, index) => (
                <a key={index}
                  href={reference.link}
                  target="_blank"
                  className="relative h-40 text-xs rounded-sm bg-secondary-color" rel="noreferrer">
                  <p className='line-clamp-3'>{reference.title}</p>
                </a>
              ))}
            </div>
          )} */}

          <p className="text-primary-content">{article.abstract}</p>

          {/* Render Paragraphs */}
          {article.outlines.map((paragraph, index) => (
            <div
              key={index}
              ref={(el) => (paragraphsRef.current[index] = el)}
              className={'transition-all duration-300 ease-in-out'}
              style={{ margin: '20px 0' }}
            >
              <h2 className="text-2xl font-medium leading-relaxed">
                {paragraph.title}
              </h2>
              <DisplayFormattedText text={paragraph.content} />
              {/* <p className="text-xl">{paragraph.content}</p> */}
              {paragraph.references && paragraph.references.length > 0 && (
                <div>
                  <ReferencePill />
                </div>
              )}
            </div>
          ))}
          <DisplayFormattedText text={article.conclusion} />

          <section className="items-center mt-4 mb-8">
            <h2 className="text-3xl leading-relaxed">{i18n.RELATED[lang]}</h2>
            <ul className="">
              {article.followup_questions.map((item, index) => (
                <li
                  key={index}
                  className="relative min-w-0 max-w-full flex justify-between items-center my-2 border-b border-gray-900/5 cursor-pointer"
                  onClick={() => onFollowUpQuestionClick(item)}>
                  <div className="flex-1 focus:outline-none mr-6 cursor-pointer hover:underline">
                    <span aria-hidden="true" className="absolute inset-0" />
                    <p className="text-md leading-6 my-2 font-medium text-primary-color line-clamp-2 max-w-full hover:underline cursor-pointer">
                      {item}
                    </p>
                  </div>
                  <PlusIcon className="w-5 h-5 text-primary-color" />
                </li>
              ))}
            </ul>
          </section>
          <div className="fixed bottom-4 sm:w-3/4 lg:w-1/2 mx-auto lg:left-72 right-0 left-0 z-50">
            <SingleLineInput
              className="relative w-full bottom-4 w-full mx-auto left-0 right-0"
              onSubmit={onSubmit}
              enableUpload={false}
              // disabled={isFollowupFetching}
              onFileCountChange={(count) => {
                // if (count > 0) {
                //   setPaddingBottom("pb-32");
                // } else {
                //   setPaddingBottom("pb-12");
                // }
              }}
            />
          </div>
        </div>

        {/* Sticky Sidebar */}
        <div className="hidden lg:block col-span-4">
          <ul className="ml-12 sticky top-24 space-y-4">
            {/* Render Navigation Buttons */}
            {article.outlines.map((paragraph, index) => (
              <li
                key={index}
                className={`block cursor-pointer border-l-2 text-left w-full px-4 my-2 transition-colors duration-200 ease-in-out ${
                  currentIndex === index
                    ? 'text-primary-content border-primary-content font-bold border-l-4'
                    : 'text-secondary-content border-secondary-content over:bg-gray-200'
                }`}
                onClick={() => scrollToParagraph(index)}
              >
                {paragraph.title}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

// Example usage
// const ideaArticleData = {
//   title: 'Article Title',
//   abstract: 'Sony Pictures Entertainment is embracing artificial intelligence (AI)...',
//   'references': [
//     {
//       'id': 1,
//       'title': 'Sanofi Secures First-Line Nod for Sarclisa in Multiple Myeloma, Tees Up Competition With J&J',
//       'link': 'https://www.biospace.com/FDA/sanofi-secures-first-line-nod-for-sarclisa-in-multiple-myeloma-tees-up-competition-with-j-j',
//       'published': '2024-09-23 13:21:16'
//     },
//     {
//       'id': 2,
//       'title': 'Sanofi breaks into first-line multiple myeloma with FDA approval for Sarclisa',
//       'link': 'https://www.fiercepharma.com/pharma/sanofi-breaks-first-line-multiple-myeloma-fda-approval-sarclisa',
//       'published': '2024-09-20 19:43:57'
//     }
//   ],
//   outlines: [
//     {
//       title: 'Introduction',
//       content: 'This is the introduction. This is the introduction. This is the introduction. This is the introduction. This is the introduction. This is the introduction. This is the introduction. This is the introduction. This is the introduction. This is the introduction. This is the introduction. This is the introduction. This is the introduction. This is the introduction. This is the introduction. This is the introduction. This is the introduction. This is the introduction. This is the introduction. This is the introduction. This is the introduction. This is the introduction. This is the introduction. This is the introduction. This is the introduction. ',
//       img: '',
//       references: [ 0, 1 ],
//     },
//     {
//       title: 'Body',
//       content: 'This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. ',
//       img: '',
//     },
//     {
//       title: 'Conclusion',
//       content: 'This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body.  This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. ',
//     },
//     {
//       title: 'Conclusion2',
//       content: ' This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body. This is the body.  This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. This is the conclusion. ',
//     }
//   ]
// };

const articleData = {
  id: 25,
  title: "Sanofi's Sarclisa Gains FDA Approval",
  abstract:
    "Sanofi's Sarclisa has received FDA approval as a first-line treatment for multiple myeloma, marking a significant milestone. Positioned to compete directly with Johnson & Johnson's Darzalex, Sarclisa offers a new option for patients ineligible for stem cell transplantation. Clinical trials have shown impressive results with a 40% reduction in disease progression or death when combined with VRd. Sanofi is also exploring broader applications, including subcutaneous formulations and use in transplant-eligible patients. This development reflects the dynamic competition in the CD38 class market.",
  conclusion:
    "The FDA approval of Sarclisa positions Sanofi strategically in the competitive landscape of multiple myeloma treatments. By offering a viable alternative to Darzalex, Sarclisa provides hope for newly diagnosed patients. Sanofi's commitment to advancing research and developing more convenient formulations underlines its ambition in this sector. As the CD38 class market expands, Sarclisa's success will depend on sustained innovation and effective competition.",
  doc_type: 'hotspot_json',
  published: '20240923',
  outlines: [
    {
      title: 'FDA Approval for Sarclisa',
      content:
        "The FDA has approved Sanofi's Sarclisa (isatuximab) as a first-line treatment for newly diagnosed multiple myeloma patients who are not eligible for autologous stem cell transplantation. This positions Sarclisa in direct competition with Johnson & Johnson's Darzalex, a leading therapy in this category. The approval was based on results from the Phase III IMROZ trial, which demonstrated a significant reduction in disease progression or death when Sarclisa was combined with the standard regimen of bortezomib, lenalidomide, and dexamethasone (VRd), resulting in a 40% risk reduction[[1]](https://www.biospace.com/FDA/sanofi-secures-first-line-nod-for-sarclisa-in-multiple-myeloma-tees-up-competition-with-j-j)[[2]](https://www.fiercepharma.com/pharma/sanofi-breaks-first-line-multiple-myeloma-fda-approval-sarclisa).",
    },
    {
      title: 'Competition with Darzalex',
      content:
        "Sanofi's recent FDA approval for Sarclisa positions it as a direct competitor to Johnson & Johnson's Darzalex in the first-line treatment of multiple myeloma for patients ineligible for stem cell transplants. Both therapies are anti-CD38 antibodies, but Sarclisa has demonstrated a 40% reduction in disease progression or death when combined with the standard VRd regimen, according to the Phase III IMROZ study[[1]](https://www.biospace.com/FDA/sanofi-secures-first-line-nod-for-sarclisa-in-multiple-myeloma-tees-up-competition-with-j-j)[[2]](https://www.fiercepharma.com/pharma/sanofi-breaks-first-line-multiple-myeloma-fda-approval-sarclisa). Despite Darzalex's substantial revenue, reaching nearly $10 billion in 2023, Sarclisa is seen as best-in-class by Sanofi, which is bolstering its competitive edge with ongoing research and a planned subcutaneous formulation[[1]](https://www.biospace.com/FDA/sanofi-secures-first-line-nod-for-sarclisa-in-multiple-myeloma-tees-up-competition-with-j-j)[[2]](https://www.fiercepharma.com/pharma/sanofi-breaks-first-line-multiple-myeloma-fda-approval-sarclisa).",
    },
    {
      title: 'Clinical Trial Results',
      content:
        "The clinical trial results for Sanofi's Sarclisa were pivotal in securing its FDA approval for first-line treatment in multiple myeloma patients not eligible for autologous stem cell transplantation. The approval is grounded in data from the Phase III IMROZ study, which demonstrated a 40% reduction in the risk of disease progression or death when Sarclisa was combined with the VRd regimen (bortezomib, lenalidomide, and dexamethasone), compared to VRd alone[[1]](https://www.biospace.com/FDA/sanofi-secures-first-line-nod-for-sarclisa-in-multiple-myeloma-tees-up-competition-with-j-j)[[2]](https://www.fiercepharma.com/pharma/sanofi-breaks-first-line-multiple-myeloma-fda-approval-sarclisa). Nearly 75% of patients achieved complete response or better, establishing Sarclisa as a strong contender against existing therapies like Johnson & Johnson's Darzalex. Additionally, the National Comprehensive Cancer Network has endorsed the Sarclisa-VRd combination as a preferred regimen for these patients[[1]](https://www.biospace.com/FDA/sanofi-secures-first-line-nod-for-sarclisa-in-multiple-myeloma-tees-up-competition-with-j-j)[[2]](https://www.fiercepharma.com/pharma/sanofi-breaks-first-line-multiple-myeloma-fda-approval-sarclisa).",
    },
    {
      title: 'Broader Indications and Developments',
      content:
        "Sanofi's Sarclisa has gained FDA approval for broader indications, specifically as a first-line treatment for newly diagnosed multiple myeloma patients who are ineligible for autologous stem cell transplantation[[1]](https://www.biospace.com/FDA/sanofi-secures-first-line-nod-for-sarclisa-in-multiple-myeloma-tees-up-competition-with-j-j)[[2]](https://www.fiercepharma.com/pharma/sanofi-breaks-first-line-multiple-myeloma-fda-approval-sarclisa). This approval, based on the Phase III IMROZ study, demonstrated a significant reduction in disease progression or death when Sarclisa was added to the standard VRd regimen, with a 40% risk reduction compared to VRd alone[[1]](https://www.biospace.com/FDA/sanofi-secures-first-line-nod-for-sarclisa-in-multiple-myeloma-tees-up-competition-with-j-j). Sarclisa's development now also encompasses ongoing studies targeting multiple indications, including potential use for transplant-eligible patients and in combination with therapies like Kyprolis, highlighting its expanding role in the competitive CD38 market[[2]](https://www.fiercepharma.com/pharma/sanofi-breaks-first-line-multiple-myeloma-fda-approval-sarclisa).",
    },
  ],
  references: [
    {
      id: 1,
      title:
        'Sanofi Secures First-Line Nod for Sarclisa in Multiple Myeloma, Tees Up Competition With J&J',
      link: 'https://www.biospace.com/FDA/sanofi-secures-first-line-nod-for-sarclisa-in-multiple-myeloma-tees-up-competition-with-j-j',
      published: '2024-09-23 13:21:16',
    },
    {
      id: 2,
      title:
        'Sanofi breaks into first-line multiple myeloma with FDA approval for Sarclisa',
      link: 'https://www.fiercepharma.com/pharma/sanofi-breaks-first-line-multiple-myeloma-fda-approval-sarclisa',
      published: '2024-09-20 19:43:57',
    },
  ],
};
// Render the Article component
export const ArticleDetail = () => {
  // get from loader
  const articleData = useLoaderData();
  console.log('ooooooooooooo ooooooooo', articleData);
  if (!articleData || !articleData.article) {
    return (
      <main className="flex items-center justify-center w-full min-h-screen py-8 text-gray-900 page md:py-16 ">
        <div className="relative flex flex-col items-center w-full gap-8 px-8 md:px-18 xl:px-40 md:gap-16">
          <p className="text-3xl font-bold capitalize">
            You have discovered a missing article
          </p>
        </div>
      </main>
    );
  }
  return <Article article={articleData.article} />;
};

export async function articleDetailLoader({ params }) {
  const { articleId } = params;
  const article = await getDiscoverDetail(articleId);
  return { article };
}
