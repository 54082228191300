export default function SearchResults({ person }) {
  return (
    <ul role="list" className="divide-y divide-gray-100">
      {person?.网络搜索结果?.map((content) => (
        <li
          key={content.标题}
          className="relative flex justify-between gap-x-6 py-5"
        >
          <div className="flex min-w-0 gap-x-4">
            {/* <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={content.imageUrl} alt="" /> */}
            <div className="min-w-0 flex-auto">
              <p className="text-sm font-semibold leading-6 text-secondary-content underline">
                <a href={content.链接}>
                  <span className="absolute inset-x-0 -top-px bottom-0" />
                  {content.标题}
                </a>
              </p>
              <p className="mt-1 flex text-xs leading-5 text-secondary-content">
                {content.内容}
              </p>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}
