import React from 'react';

import { Select } from 'antd';

const MultiInput = ({ tokenSeparators, placeholder, defaultValue, onChange }) => {

  const handleChange = (value) => {
    onChange(value);
  };

  return (
    <Select
      style={{ width: '100%' }}
      tokenSeparators={tokenSeparators || [ ',' ]}
      mode="tags"
      notFoundContent={null}
      placeholder={placeholder}
      defaultValue={defaultValue||[]}
      onChange={handleChange}
    />
  );
};

export default MultiInput;
