import { ConfigProvider, Input } from 'antd';

const MiscInput = ({ name, onChange, defaultValue = '', title, placeholder }) => {
  return (
    <>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-primary-content"
      >
        {title || 'Question'}
      </label>
      <div className="flex flex-wrap gap-2">
        {/* <ConfigProvider theme={{ hashed: false }}> */}
        <Input
          name={name}
          placeholder={placeholder || 'Misc Input'}
          defaultValue={defaultValue}
          onChange={(e) => onChange(e.target.value)}
        />
        {/* </ConfigProvider> */}
      </div>
    </>
  );
};

export default MiscInput;
