import { useLanguage } from '../../../utils/context/lang';

import MultiInput from './common/multi-input';

const dict = {
  en: {
    nctId: 'NCT ID',
    placeholder: 'NCT ID like NCT06528977',
  },
  zh: {
    nctId: 'NCT ID',
    placeholder: 'NCT ID，如 NCT06528977',
  },
};

const NctIdInput = ({ name, onChange, defaultValue = [] }) => {
  const { lang } = useLanguage();
  return (
    <>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-primary-content"
      >
        {dict['en'].nctId}
      </label>
      <div className="flex flex-wrap gap-2">
        <MultiInput
          name={name}
          placeholder={ dict['en'].placeholder }
          defaultValue={defaultValue}
          tokenSeparators={[ ',', ' ' ]}
          onChange={(value) => onChange(value)}
        />
      </div>
    </>
  );
};

export default NctIdInput;
