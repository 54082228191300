export const MARKET_RESEARCH_TYPE = 'MarketResearch';
export const MARKET_RESEARCH_SONNET_TYPE = 'MarketResearchSonnet';
export const CLINICAL_TYPE = 'Clinical';
export const CLINICAL_SONNET_TYPE = 'ClinicalSonnet';
export const DINGTAI_TYPE = 'Dingtai';
export const BIOMEDICAL_TYPE = 'Biomedical';
export const MED_TRANSLATOR_TYPE = 'MedicalTranslator';
export const CLINICAL_INSIGHT_TYPE = 'ClinicalInsight';
export const CLINICAL_INSIGHT_SONNET_TYPE = 'ClinicalInsightSonnet';

export const agents = [
  {
    name: 'Dingtai Agent',
    role_en: 'DingTai Agent',
    role_zh: '鼎泰智能模型',
    category: DINGTAI_TYPE,
    imageUrl: '/avatars/clinical-operation-manager.webp',
    exampleQuestions: [ '我司的成立时间', '我司的规章制度' ],
    intro_en: [ 'Trained with Dingtai internal Knowledge base.' ],
    intro_zh: [ '具有丰富的中国临床试验管理专业知识的AI临床运营助理。' ],
  },
  {
    inLanding: true,
    name: 'market analysis',
    role_en: 'Market Research Assistant',
    role_zh: '市场调研助理',
    enableUpload: true,
    category: MARKET_RESEARCH_TYPE,
    imageUrl: '/avatars/market-research-white.webp',
    intro_en: [
      'A specialized Market Analysis Assistant designed to assist in tasks related to biotech investments, business development asset evaluations, portfolio strategies, new product planning, competitive intelligence, and more.',
      'Capable of managing most daily tasks with quick responses, usually in less than 30 seconds.',
    ],
    intro_zh: [
      '专业的市场分析助手，旨在协助生物技术投资、BD资产评估、投资组合策略、新产品规划、竞争情报等任务。',
      '能够在30秒内快速响应大多数日常任务。',
    ],
    exampleQuestions: [
      'What were the significant deals involving ADC in 2023?',
      'What is the pipeline of Alnylam?',
      'Should I invest in Alnylam in 2024?',
      'What is the incidence rate of NSCLC in China?',
    ],
  },
  {
    name: 'market analysis',
    role_en: 'Market Research Assistant',
    role_zh: '市场调研助理',
    enableUpload: true,
    category: MARKET_RESEARCH_SONNET_TYPE,
    imageUrl: '/avatars/market-research-white.webp',
    intro_en: [
      'The Pro version of the Market Research Manager, equipped with higher intelligence, is designed to more effectively handle complex tasks.',
      'These tasks include drafting market understanding reports, building drug sales forecast models, or developing DCF models. However, please note that the response time is slower, typically ranging from 2 to 5 minutes.',
    ],
    intro_zh: [
      '市场调研助理的专业版，配备了更高的智能，旨在更有效地处理复杂任务。',
      '这些任务包括起草市场研究报告、建立药品销售预测模型或DCF模型。但响应时间通常在2到5分钟。',
    ],
    exampleQuestions: [
      'Write a market research report for HER2+ breast cancer.',
      'Build a DCF model of Enhertu.',
      "Build a sales forecast model of Novartis' atrasentan.",
    ],
  },
  {
    inLanding: true,
    name: 'clinical operations',
    role_en: 'China Clinical Assistant',
    role_zh: '中国临床助理',
    category: CLINICAL_TYPE,
    enableUpload: true,
    imageUrl: '/avatars/clinical-operation-manager.webp',
    exampleQuestions: [
      'I want to initiate a clinical trial for ovarian cancer in China. Could you recommend 10 PIs?',
      'List the top 10 PIs with the most experience in NSCLC trials in China.',
    ],
    intro_en: [
      'An AI assistant with extensive knowledge of clinical trials in China.',
      'This assistant helps you quickly understand the clinical trial landscape in China and identifies the best Principal Investigators (PIs) and sites for trials in China.',
    ],
    intro_zh: [
      '具有丰富的中国临床试验管理专业知识的AI临床运营助理。',
      '我可以为您推荐中国临床试验的PI和试验中心。',
    ],
  },
  {
    name: 'clinical operations',
    role_en: 'China Clinical Assistant',
    role_zh: '中国临床助理',
    category: CLINICAL_SONNET_TYPE,
    enableUpload: true,
    imageUrl: '/avatars/clinical-operation-manager.webp',
    exampleQuestions: [
      'I want to initiate a clinical trial for ovarian cancer in China. Could you recommend 10 PIs?',
      'List the top 10 PIs with the most experience in NSCLC trials in China.',
    ],
    intro_en: [
      'An AI assistant with extensive knowledge of clinical trials in China.',
      'This assistant helps you quickly understand the clinical trial landscape in China and identifies the best Principal Investigators (PIs) and sites for trials in China.',
    ],
    intro_zh: [
      '具有丰富的中国临床试验管理专业知识的AI临床运营助理。',
      '我可以为您推荐中国临床试验的PI和试验中心。',
    ],
  },
  {
    inLanding: true,
    name: 'clinical insight',
    role_en: 'Clinical Insight Assistant',
    role_zh: '临床洞察助理',
    category: CLINICAL_INSIGHT_TYPE,
    enableUpload: false,
    imageUrl: '/avatars/scientist.webp',
    exampleQuestions: [
      'Please list and compare all clinical trial results for IgAN.',
      "List all phase 3 trial protocols for Alzheimer's disease (AD), including trial ID, title, number of patients, and primary endpoints.",
    ],
    intro_en: [
      'An AI assistant trained with curated expert insights from clinical results and powered by data from ClinicalTrials.gov along with all public trial results. ',
      'This tool enables you to quickly search, analyze, compare, and generate insights from clinical trial protocols and results.',
    ],
    intro_zh: [
      '具有临床试验结果的专家见解的AI助手，由ClinicalTrials.gov的数据以及所有公共试验结果提供支持。',
      '这个工具可以帮助您快速搜索、分析、比较临床试验方案和结果，并生成见解。',
    ],
  },
  {
    name: 'clinical insight',
    role_en: 'Clinical Insight Assistant',
    role_zh: '临床洞察助理',
    category: CLINICAL_INSIGHT_SONNET_TYPE,
    enableUpload: false,
    imageUrl: '/avatars/scientist.webp',
    exampleQuestions: [
      'Please list and compare all clinical trial results for IgAN.',
      "List all phase 3 trial protocols for Alzheimer's disease (AD), including trial ID, title, number of patients, and primary endpoints.",
    ],
    intro_en: [
      'An AI assistant trained with curated expert insights from clinical results and powered by data from ClinicalTrials.gov along with all public trial results. ',
      'This tool enables you to quickly search, analyze, compare, and generate insights from clinical trial protocols and results.',
    ],
    intro_zh: [
      '具有临床试验结果的专家见解的AI助手，由ClinicalTrials.gov的数据以及所有公共试验结果提供支持。',
      '这个工具可以帮助您快速搜索、分析、比较临床试验方案和结果，并生成见解。',
    ],
  },
  {
    name: 'Alexa',
    role_en: 'Biologist (Medical)',
    role_zh: '医学生物学家',
    category: BIOMEDICAL_TYPE,
    enableUpload: true,
    imageUrl: '/avatars/medical-biologist.webp',
    exampleQuestions: [
      'Is HER2 a good target for breast cancer?',
      'Give me a list of enzymes which can produce NADPH?',
    ],
    intro_en: `Hello, I'm Alexa, your dedicated AI Biologist, poised to seamlessly integrate into your drug development team. My knowledge base is extensive, encompassing the latest scientific research, clinical trial data, and emerging trends in biotechnology. My role is specifically tailored to provide expert biological insights and data-driven guidance crucial for your drug discovery and development endeavors.
    You can leverage my expertise for a range of inquiries, such as "evaluating the viability of HER2 targets in breast cancer", "list the mainstream payloads in Antibody-Drug Conjugates (ADCs)", or "exploring the cutting-edge applications of gene-editing technologies like CRISPR-Cas9 in drug development".`,
    intro_zh: `你好，我是Alexa，您的专属AI生物学家，可以无缝地融入您的药物开发团队。我的知识面广泛，包括最新的科学研究、临床试验数据和生物技术的新趋势。我的角色专门为您的药物发现和开发工作提供专业的生物学见解和数据驱动的指导。
    您可以利用我的专业知识进行一系列查询，例如“评估HER2在乳腺癌中的靶点的可行性”，“列出抗体药物偶联物（ADC）中的主流载药”，或“探索基因编辑技术（如CRISPR-Cas9）在药物开发中的前沿应用”。`,
  },
  {
    name: 'Stella',
    role_en: 'Biologist (Synthetic Biology)',
    role_zh: '合成生物学家',
    category: 'SyntheticBiology',
    imageUrl: '/avatars/synthetic.webp',
    enableUpload: true,
    exampleQuestions: [
      'What is the current status of the clinical trial for lung cancer in China?',
      'Give me a list of enzymes which can produce NADPH?',
    ],
    intro_en:
      "Hello, I'm Stella. I specialize in synthetic biology, a field that combines biology and engineering to design and construct new biological parts, devices, and systems. My expertise lies in the design and construction of new biological systems for useful purposes. I can assist you with a variety of inquiries",
    intro_zh: '你好，我是Stella。',
  },
  {
    inLanding: true,
    name: 'Anne',
    role_en: 'Medical Translator',
    role_zh: '医学翻译',
    category: MED_TRANSLATOR_TYPE,
    enableUpload: true,
    imageUrl: '/avatars/translator-white.webp',
    intro_en: [
      "Hello, I'm Anne, your dedicated AI Medical Translator, specializing in bridging the language gap in medical contexts, particularly between Chinese and English. My role is specifically designed to facilitate seamless communication and understanding in the medical and healthcare sectors.",
      "You can rely on me for a variety of translation tasks. If you have specific medical terms, phrases, or even complex medical jargon that you need translated, I'm here to assist.",
    ],
    intro_zh: [
      '你好，我是Anne，您的专属AI医学翻译，专门致力于弥合医学领域中的语言障碍，特别是中英文之间的翻译。我的角色专门设计用于促进医疗和医疗保健领域的无缝沟通和理解。',
      '您可以依赖我进行各种翻译任务。如果您有特定的医学术语、短语，甚至是复杂的医学术语需要翻译，我会在这里为您提供帮助。',
    ],
    exampleQuestions: [
      'What does chronic obstructive pulmonary disease (COPD) mean?',
      'What is the Chinese translation for "acute lymphoblastic leukemia"?',
      'Please translate the following sentence into Chinese: "A vaccine is a biological preparation that provides active acquired immunity to a particular infectious disease."',
    ],
  },
  {
    name: 'Coming Soon',
    role_en: ' Medicinal Chemist',
    role_zh: '药物化学家',
    imageUrl: '/avatars/chemist.webp',
  },
  {
    name: 'Coming Soon',
    role_en: 'Clinical Scientist',
    role_zh: '临床科学家',
    imageUrl: '/avatars/scientist.webp',
  },
  {
    name: 'Coming Soon',
    role_en: 'Product Manager',
    role_zh: '产品助理',
    imageUrl: '/avatars/product-manager-black.webp',
  },
  {
    name: 'Coming Soon',
    role_en: 'New Product Planning Manager',
    role_zh: '新产品规划助理',
    imageUrl: '/avatars/product-planning.webp',
  },
  {
    name: 'Coming Soon',
    role_en: 'Business Development Manager',
    role_zh: '业务发展助理',
    imageUrl: '/avatars/business-develop-black.webp',
  },
];
