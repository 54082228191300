import { Select } from 'antd';

const PhaseSelect = ({ name, defaultValue = [], onChange }) => {
  const PHASES = [ 'I', 'II', 'III', 'IV', 'I/II', 'II/III', 'IIb', 'Preclinical', 'Others'  ]
  return (
    <>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-primary-content"
      >
      Phase
      </label>
      <Select
        mode="multiple"
        style={{
          width: '100%',
        }}
        placeholder={'Select Phases'}
        defaultValue={defaultValue}
        onChange={(value) => onChange(value)}
        notFoundContent={null}
        options={PHASES.map((filter) => ({
          label: filter,
          value: filter,
        }))}
      />
    </>
  );
};

export default PhaseSelect;
