const NAME = { en: 'Name', zh: '姓名' };
const COMPANY_NAME = { en: 'Company Name', zh: '医院名称' };
const TITLE = { en: 'Title', zh: '职位' };
const CLINICAL_COUNT = { en: 'Clinical Trial Count', zh: '临床试验' };
const RELEVANT_CLINICAL_COUNT = {
  en: 'Relevant Clinical Trial Count',
  zh: '相关临床试验',
};
const RELEVANT_THIRD_STAGE_CLINIICAL_COUNT = {
  en: 'Relevant Third Stage Clinical Trial Count',
  zh: '相关三期临床试验',
};
const RELEVANT_CLINICAL_INFO = {
  en: 'Relevant Clinical Trial Info',
  zh: '相关临床试验信息',
};
const INTRODUCTION = { en: 'Introduction', zh: '简介' };
const INTERNET_SEARCH_RESULT = {
  en: 'Internet Search Result',
  zh: '互联网搜索结果',
};
const PAPER_PUBLICATION = { en: 'Paper Publication', zh: '论文发表' };
const COLLABORATOR_COUNT = { en: 'Collaborator Count', zh: '合作人数' };
const COLLABORATOR = { en: 'Collaborator', zh: '合作人' };
const CHECK_OUT = { en: 'Check Out', zh: '查看' };
const ASSOCIATION_INFO = { en: 'Association Info', zh: '协会信息' };

export const i18n = {
  NAME,
  COMPANY_NAME,
  TITLE,
  ASSOCIATION_INFO,
  CLINICAL_COUNT,
  RELEVANT_CLINICAL_COUNT,
  RELEVANT_THIRD_STAGE_CLINIICAL_COUNT,
  RELEVANT_CLINICAL_INFO,
  INTRODUCTION,
  INTERNET_SEARCH_RESULT,
  PAPER_PUBLICATION,
  COLLABORATOR_COUNT,
  COLLABORATOR,
  CHECK_OUT,
}