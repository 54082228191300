import { useCallback, useState } from 'react';

import debounce from 'lodash/debounce';
import { use } from 'markdown-it-texmath';

import { fetchTypeAhead } from '../../../api';
import { useLanguage } from '../../../utils/context/lang';

import AndOrInput from './common/and-or-input';


const dict = {
  en: {
    title: 'Drug',
    placeholder: 'Drug name like Pembrolizumab',
  },
  zh: {
    title: '药物',
    placeholder: '药物名称，如 顺铂',
  },
};
const DrugInput = ({ name, onChange, defaultValue }) => {
  const { lang } = useLanguage();
  const [ options, setOptions ] = useState([]);
  const handleSearch = useCallback(debounce((value) => {
    fetchTypeAhead(value, 'drug').then((res) => {
      setOptions(res?.result?.map((item) => ({ label: item, value: item })) || []);
    })
  }, 500), []);
  return (
    <>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-primary-content"
      >
        {dict['en'].title}
      </label>
      <div className="flex flex-wrap gap-2">
        <AndOrInput
          name={name}
          onChange={onChange} // Use the memoized handleChange here
          defaultValue={defaultValue}
          placeholder={dict['en'].placeholder}
          options={options}
          filterOption={false}
          onSearch={handleSearch}
        />
        {/* <MultiInput
          name={name}
          placeholder={'Drug name like Pembrolizumab'}
          defaultValue={defaultValue}
          tokenSeparators={[ ',' ]}
          onChange={(value) => onChange(value)}
        /> */}
      </div>
    </>
  );
};

export default DrugInput;