export default function ReferencePill() {
  return (
    <div className="flex -space-x-1 overflow-hidden rounded-full border p-1 w-fit items-center">
      {[
        'https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80',
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
      ].map((src, index) => (
        <div key={index} className="relative group">
          <img
            alt=""
            src={src}
            className="inline-block h-6 w-6 rounded-full ring-2 ring-white transition-all duration-200 ease-in-out group-hover:mr-2 cursor-pointer"
          />
        </div>
      ))}
      <p className="pl-2 text-sm text-secondary-content">4 sources</p>
    </div>
  );
}
