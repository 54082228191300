import { Disclosure } from '@headlessui/react';
import { CheckCircleIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

import DisplayFormattedText from '../AskPage/components/markdown';
import { useLanguage } from '../utils/context/lang';

import { i18n } from './i18n/common';

function Node({ node, level = 0 }) {
  const { lang } = useLanguage();
  return (
    // <Disclosure defaultOpen={level == 0}>
    <Disclosure defaultOpen={level == 0 && !node.summary}>
      {({ open }) => (
        <div className="py-4 px-6">
          <Disclosure.Button className="group flex w-full items-center justify-between">
            <span className="flex-1 text-sm text-left font-medium text-black flex items-center">
              {level>0 && (node.summary ? (
                <CheckCircleIcon className="w-4 h-4 mr-3" />
              ) : (
                <span className="loading loading-spinner text-primary mr-2" />
              ))}
              {level==0 && <p className="flex-1">{i18n.THOUGHT_BREAK_DOWN[lang]}</p>}
              {level>0 &&<p className="flex-1">{node.query}</p>}
            </span>
            <ChevronDownIcon
              className={`w-5 h-5 ml-2 text-black transform ${open ? 'rotate-180' : ''}`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="mt-2 text-sm text-black">
            {node.thought_process && (
              <p>{node.thought_process}</p>
            )}
            {node.search_results && node.search_results.length > 0 && (
              <div className="mt-2">
                <h4 className="font-semibold">{i18n.SEARCH_RESULTS[lang]}</h4>
                <DisplayFormattedText text={node.summary} />
                <h4 className="font-semibold">{i18n.SEARCH_SUMMARY[lang]}</h4>
                <ul className="list-disc pl-5">
                  {node.search_results.map((result, index) => (
                    <li key={index} className="mt-2">
                      <a
                        href={result.url}
                        className="text-blue-600 underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {result.title}
                      </a>
                      {/* <p>{result.summ}</p> */}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {node.children && node.children.length > 0 && (
              <div className="mt-4 pl-2 border-l border-gray-200">
                {node.children.map((child, index) => (
                  <Node key={index} node={child} level={level + 1} />
                ))}
              </div>
            )}
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  );
}

export default function ThoughtNode({ thought }) {
  const data = {
    id: 0,
    search_type: 0,
    query: '北京天气',
    key_word: '',
    thought_process:
      '要了解北京的天气情况，可以从以下几个方面进行查询：当前天气状况、未来几天天气预报、历史天气数据等。',
    search_results: [],
    children: [
      {
        id: 0,
        search_type: 1,
        query: '查询北京未来几天的天气预报',
        key_word: '北京天气预报',
        thought_process: '',
        search_results: [
          {
            url: 'http://www.weather.com.cn/weather/101010100.shtml',
            summ: '查询北京今日天气、周末天气、一周天气、40天天气，以及北京的生活指数、健康指数、交通指数、旅游指数等。还可查看北京雷达图、气象预警信号、气象资讯等。',
            title: '北京天气预报,北京7天天气预报,北京15天天气预报,北京天气查询',
            reason: '',
            is_open: false,
            content: '',
          },
          {
            url: 'http://www.weather.com.cn/weather/101010100.shtml',
            summ: '查询ABC',
            title: 'ABC天气预报,北京7天天气预报,北京15天天气预报,北京天气查询',
            reason: '',
            is_open: false,
            content: '',
          },
          {
            url: 'http://www.weather.com.cn/weather/101010100.shtml',
            summ: '查询XYZ',
            title: 'XYZ天气预报,北京7天天气预报,北京15天天气预报,北京天气查询',
            reason: '',
            is_open: false,
            content: '',
          },
        ],
        children: [],
        summary: '',
      },
      {
        id: 2,
        search_type: 1,
        query: '查询北京未来几天的天气预报',
        key_word: '北京天气预报',
        thought_process: '',
        search_results: [
          {
            url: 'http://www.weather.com.cn/weather/101010100.shtml',
            summ: '查询北京今日天气、周末天气、一周天气、40天天气，以及北京的生活指数、健康指数、交通指数、旅游指数等。还可查看北京雷达图、气象预警信号、气象资讯等。',
            title: '北京天气预报,北京7天天气预报,北京15天天气预报,北京天气查询',
            reason: '',
            is_open: false,
            content: '',
          },
        ],
        children: [],
        summary: '',
      },
      // ... other child nodes
    ],
    summary: '',
  };

  if (!thought) {
    return null;
  }
  return (
    <div className="w-full py-4">
      <div className="w-full max-w-2xl divide-y divide-gray-200 rounded-xl bg-white shadow-md">
        <Node node={thought} />
      </div>
    </div>
  );
}
