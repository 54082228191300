import { useId, useState } from 'react';

import {
  QueryClient,
  QueryClientProvider,
  useQueryClient,
} from '@tanstack/react-query';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  redirect,
  RouterProvider,
  useLocation,
} from 'react-router-dom';

import LoadingSection from './AskPage/components/loading/loading.js';
import { AskDetail, askDetailLoader } from './AskPage/detail.js';
import { AskHistory, askHistoryLoader } from './AskPage/history.js';
// import { AskLanding, askLandingLoader } from './AskPage/newlanding.js';
// import { AskLanding, askLandingLoader } from './AskPage/landing.js';
import { MainContainer, mainContainerLoader } from './Common/container.js';
import {
  ArticleDetail,
  articleDetailLoader,
} from './DiscoverPage/article-detail.js';
import {
  DiscoverDetail,
  discoverDetailLoader,
} from './DiscoverPage/asco-detail.js';
import {
  CalendarDetail,
  calendarDetailLoader,
} from './DiscoverPage/calendar.js';
import {
  DiscoverHistory,
  discoverHistoryLoader,
} from './DiscoverPage/history.js';
import { VisUI } from './HomePage/vis.js';
import { AlertProvider } from './utils/context/alert.js';
import { HistoryProvider } from './utils/context/history.js';
import { LanguageProvider } from './utils/context/lang.js';
import { ProProvider } from './utils/context/pro.js';
import { ThemeProvider } from './utils/context/theme-system.js';
import { UserProvider } from './utils/context/user.js';
import {
  getSessionUserID,
  getSessionUserValid,
  removeSessionUserID,
  removeSessionUserValid,
} from './utils/session.js';
import { WorkflowDetail, workflowDetailLoader } from './WorkflowPage/detail.js';
import {
  WorkflowLanding,
  workflowLandingLoader,
} from './WorkflowPage/landing.js';

// Uncomment to Mock socket....... THIS IS SHIT....uncomment will cause mess up global WebSocket ....
// import './utils/mock-socket-sender.js';
import './root.css';

/* eslint-disable */
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const router = createBrowserRouter([
  // {
  //   path: "/",
  //   exact: true,
  //   Component: () => <Navigate to="/ask" replace />,
  // },
  {
    path: "/",
    Component: MainContainer,
    // loader: mainContainerLoader,
    children: [
      {
        path: "",
        Component: () => <Navigate to="ask" replace />, // Redirect to /ask
      },
      {
        path: "ask",
        children: [
          {
            path: "",
            Component: AskDetail,
            // TODO: 你这个得和detail融合起来
            // loader: askLandingLoader,
            loader: askDetailLoader,
          },
          // {
          //   path: "new/:urlAgentType", // TODO: this will be removed
          //   Component: AskDetail,
          //   loader: askDetailLoader,
          // },
          {
            path: ":askId",
            Component: AskDetail,
            loader: askDetailLoader,
          },
          {
            path: "history",
            Component: AskHistory,
            loader: askHistoryLoader,
          },
        ],
      },
      {
        path: "workflow",
        children: [
          {
            path: "",
            Component: WorkflowLanding,
            // loader: workflowLandingLoader,
          },
          {
            path: "new/:urlWorkflowType",
            Component: WorkflowDetail,
            loader: workflowDetailLoader,
          },
          {
            path: ":workflowId",
            Component: WorkflowDetail,
            loader: workflowDetailLoader,
          },
        ],
      },
      {
        path: "discover",
        children: [
          {
            path: "",
            Component: DiscoverHistory,
            loader: discoverHistoryLoader,
          },
          {
            path: "catalyst-calendar",
            Component: CalendarDetail,
            loader: calendarDetailLoader,
          },
          {
            path: ":discoverId",
            Component: DiscoverDetail,
            loader: discoverDetailLoader,
          },
          {
            path: "article/:articleId",
            Component: ArticleDetail,
            loader: articleDetailLoader,
          },
        ],
      },
    ],
  },
]);

function NavSideBar() {
  function handleClick(event) {
    removeSessionUserID();
    removeSessionUserValid();
    redirect("/chat");
  }
  return (
    <ul className="nav flex-column root-nav-sidebar">
      <li className="nav-item root-nav-sidebar-account">
        <a
          id="accountManu"
          className="root-nav-sidebar-a"
          data-bs-toggle="collapse"
          href="#accountManuCollapse"
          role="button"
          aria-expanded="false"
          aria-controls="accountManuCollapse"
        >
          <h2>
            <i className="bi bi-person-circle root-nav-sidebar-icon"></i>
            Account
          </h2>
        </a>
        <ul
          id="accountManuCollapse"
          className="collapse"
          aria-labelledby="accountManu"
        >
          <li>
            <a
              href="#"
              role="button"
              className="root-nav-sidebar-account-list"
              onClick={(e) => {
                handleClick(e);
              }}
            >
              Log Out
            </a>
          </li>
        </ul>
      </li>
      <li className="nav-item">
        <a className="nav-link p-0 root-nav-sidebar-a" href="/chat">
          <h2>
            <i className="bi bi-chat-dots root-nav-sidebar-icon"></i>
            Chat
          </h2>
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link p-0 root-nav-sidebar-a" href="/task">
          <h2>
            <i className="bi bi-clipboard-data root-nav-sidebar-icon"></i>
            Task
          </h2>
        </a>
      </li>
    </ul>
  );
}

function RootUI() {
  return (
    <div className="container-fluid vh-100 p-0">
      <div className="nav-sidebar">
        <NavSideBar />
      </div>
      <div className="content">
        <Outlet />
      </div>
    </div>
  );
}

function rootLoader({ request, params }) {
  // console.log(request);
  // disable auto login
  // if (!getSessionUserValid()) {
  //     return redirect('/login');
  // }
  return null;
}

const queryClient = new QueryClient();

export default function App() {
  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const langQueryParam = searchParams.get('lang');

  // need maincontroller to show sidebar
  return (
    <QueryClientProvider client={queryClient}>
      <LanguageProvider>
        <UserProvider>
          <ThemeProvider>
            <ProProvider>
              <AlertProvider>
                <HistoryProvider>
                  <RouterProvider
                    router={router}
                    fallbackElement={
                      <MainContainer>
                        <LoadingSection className="mx-auto max-width-lg" />
                      </MainContainer>
                    }
                  />
                </HistoryProvider>
              </AlertProvider>
            </ProProvider>
          </ThemeProvider>
        </UserProvider>
      </LanguageProvider>
    </QueryClientProvider>
  );
}
