import ThoughtNode from '../../Common/thought';
import { useLanguage } from '../../utils/context/lang';
import { useUser } from '../../utils/context/user';
import { agents } from '../const/agent';
import { i18n } from '../i18n/common';

import ChatSection from './ChatSection';
import FileThumbnail from './fileThumbnail';
import DisplayFormattedText from './markdown';

export function OneRunningConversation({ item, runningAnswer, isEmbedding }) {
  const { lang } = useLanguage();
  const { user } = useUser();
  console.log('in one running conversation', item);
  console.log('runningAnswer', runningAnswer);
  // console.log(item)
  // console.log('!!!', agents.filter((agent) => agent.category === item.agent).at(0))
  return (
    <div className="px-12 lg:px-16 overflow-x-hidden">
      <ChatSection
        imageUrl={`https://placehold.co/50x50?text=${
          user?.username?.at(0)?.toUpperCase() || 'user'
        }`}
        role="you"
      >
        <div className="flex flex-wrap mb-2">
          {item?.upload_files &&
            item?.upload_files.length > 0 &&
            item.upload_files.map((upload, index) => (
              <FileThumbnail
                key={upload.id || upload.name}
                upload={upload}
                allowDelete={false}
              />
            ))}
        </div>
        <div className="mb-4 whitespace-pre-wrap">{item?.question}</div>
      </ChatSection>
      <ChatSection
        imageUrl={
          agents.filter((agent) => agent.category === item?.agent).at(0)
            ?.imageUrl
        }
        role="agent"
        // role={
        //   agents.filter((agent) => agent.category === item?.agent).at(0)[
        //     `role_${lang}`
        //   ]
        // }
      >
        <p className="flex">
          <span className="loading loading-dots loading-xs mr-2">{isEmbedding&&i18n.ANALYZING_UPLOAD[lang]}</span>
        </p>
        <p>
          {runningAnswer?.task_status}
          {/* {<DisplayFormattedText text={runningAnswer?.thought} />} */}
          {runningAnswer?.thought && <ThoughtNode thought={JSON.parse(runningAnswer?.thought)} />}
        </p>

        <div className="mb-4">
          <p className="">
            {<DisplayFormattedText text={runningAnswer?.response} /> || (
              <span className="text-red-300">
                {' '}
                something went wrong, please try again later or{' '}
                <a href="/contact" target="_blank">
                  contact us
                </a>
              </span>
            )}
          </p>
        </div>
      </ChatSection>
    </div>
  );
}
