/* eslint-disable */
export const asco = {
    /* eslint-disable */
    "Sheet1": [
        {
            "Title": "Prospective randomized multicenter phase III trial comparing perioperative chemotherapy (FLOT protocol) to neoadjuvant chemoradiation (CROSS protocol) in patients with adenocarcinoma of the esophagus (ESOPEC trial)",
            "Indication": "Adenocarcinoma of the esophagus",
            "Drug": "FLOT protocol, CROSS protocol",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "Neoadjuvant nivolumab plus ipilimumab versus adjuvant nivolumab in macroscopic, resectable stage III melanoma: The phase 3 NADINA trial",
            "Indication": "Stage III melanoma",
            "Drug": "Nivolumab, Ipilimumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "Comparative effectiveness trial of early palliative care delivered via telehealth versus in person among patients with advanced lung cancer",
            "Indication": "Advanced lung cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "Osimertinib (osi) after definitive chemoradiotherapy (CRT) in patients (pts) with unresectable stage (stg) III epidermal growth factor receptor-mutated (EGFRm) NSCLC: Primary results of the phase 3 LAURA study",
            "Indication": "Unresectable stage III EGFR-mutated NSCLC",
            "Drug": "Osimertinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "ADRIATIC: durvalumab (D) as consolidation treatment (tx) for patients (pts) with limited-stage small-cell lung cancer (LS-SCLC)",
            "Indication": "limited-stage small-cell lung cancer (LS-SCLC)",
            "Drug": "durvalumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "Multimodal artificial intelligence models from baseline histopathology to predict prognosis in HR+HER2- early breast cancer: Subgroup analysis.",
            "Indication": "HR+HER2- early breast cancer",
            "Drug": "Not specified",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The trained MMAI score was significantly associated with risk of distant recurrence (DR) in the validation cohort (sHR [95% CI] = 2.3 [2.0-2.8]). The MMAI image component alone had significant prognostic value (sHR [95% CI] = 1.6 [1.3-1.9]).",
            "Conclusion": "Preliminary results from the current MMAI breast model provide evidence that ArteraAI MMAI technology can be leveraged for outcome prediction in HR+HER2- EBC using H&E-stained images to further personalize breast cancer management."
        },
        {
            "Title": "Telisotuzumab vedotin monotherapy in patients with previously treated c-Met\u2013overexpressing non-squamous EGFR wildtype advanced NSCLC: Primary analysis of the LUMINOSITY trial.",
            "Indication": "c-Met\u2013overexpressing non-squamous EGFR wildtype advanced NSCLC",
            "Drug": "Telisotuzumab vedotin",
            "PFS": "Median PFS: 5.5 months (c-Met high), 6.0 months (c-Met intermediate), 5.7 months (overall)",
            "OS": "Median OS: 14.6 months (c-Met high), 14.2 months (c-Met intermediate), 14.5 months (overall)",
            "ORR": "ORR: 34.6% (c-Met high), 22.9% (c-Met intermediate), 28.6% (overall)",
            "AE": "Most common any-grade treatment-related AEs: peripheral sensory neuropathy (30%), peripheral edema (16%), and fatigue (14%). Grade 5 TRAEs occurred in 2 patients (interstitial lung disease, respiratory failure).",
            "Other": "Median DOR: 9.0 months (c-Met high), 7.2 months (c-Met intermediate), 8.3 months (overall).",
            "Conclusion": "Teliso-V has shown compelling and durable responses in patients with c-Met OE NSQ EGFR WT NSCLC, especially in patients with c-Met high. Teliso-V had an acceptable safety profile that was clinically manageable, which is consistent with previous data."
        },
        {
            "Title": "Sacituzumab tirumotecan (SKB264/MK-2870) in patients (pts) with previously treated locally recurrent or metastatic triple-negative breast cancer (TNBC): Results from the phase III OptiTROP-Breast01 study.",
            "Indication": "Triple-negative breast cancer (TNBC)",
            "Drug": "Sacituzumab tirumotecan (SKB264/MK-2870)",
            "PFS": "5.7 months (95% CI, 4.3 to 7.2) with SKB264 and 2.3 months (95% CI, 1.6 to 2.7) with chemotherapy; HR 0.31 (95% CI, 0.22 to 0.45); PFS at 6 months was 43.4% vs 11.1%. In the subset of pts with TROP2 H-score >200, the median PFS was 5.8 months with SKB264 and 1.9 months with chemotherapy; HR 0.28 (95% CI, 0.17 to 0.48).",
            "OS": "Not reached (95% CI, 11.2 to NE) with SKB264 and 9.4 months (95% CI, 8.5 to 11.7) with chemotherapy; HR 0.53 (95% CI, 0.36 to 0.78); P=0.0005.",
            "ORR": "43.8% with SKB264 and 12.8% with chemotherapy.",
            "AE": "Neutrophil count decreased (32.3% vs 47.0%), anemia (27.7% vs 6.1%), and white blood cell count decreased (25.4% vs 36.4%).",
            "Other": "None",
            "Conclusion": "Sacituzumab tirumotecan monotherapy demonstrated statistically significant and clinically meaningful PFS and OS benefit over chemotherapy, with a manageable safety profile in pts with heavily pretreated advanced TNBC and limited treatment options."
        },
        {
            "Title": "Discontinuation of maintenance therapy in multiple myeloma guided by multimodal measurable residual disease negativity (MRD2STOP).",
            "Indication": "Multiple myeloma (MM)",
            "Drug": "None",
            "PFS": "The estimated 3-year PFS was 85%, including 93% for patients MRD 10-7 negative (n=40) at baseline and 31% for those MRD 10-7 positive (n=7) at baseline (log rank p < 0.001).",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The 3-year MRD-free survival (MRD-FS) was 68%; 78% for patients MRD 10-7 negative (n=38) and 33% for patients MRD 10-7 positive (n=7) (log rank p < 0.001).",
            "Conclusion": "Discontinuation of maintenance therapy among patients with MM and multimodal MRD-negativity results in a high rate of sustained MRD-negativity and lack of disease progression. CD138+-enriched MRD samples using the clonoSEQ assay may help to even better identify patients who can discontinue therapy."
        },
        {
            "Title": "The potential role of serial circulating tumor DNA (ctDNA) testing after upfront surgery to guide adjuvant chemotherapy for early stage pancreatic cancer: The AGITG DYNAMIC-Pancreas trial.",
            "Indication": "Early stage pancreatic adenocarcinoma",
            "Drug": "Adjuvant chemotherapy (FOLFIRINOX or gemcitabine/capecitabine)",
            "PFS": "Median recurrence-free survival (RFS) in ctDNA+ve patients was 13 months compared to 22 months for ctDNA-ve patients (HR 0.52, p = 0.003).",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "A tumor-informed ctDNA approach to adjuvant chemotherapy selection is feasible for patients undergoing upfront resection of pancreatic adenocarcinoma, with the first blood draw to be scheduled at week 5, allowing time for ctDNA analysis to determine adjuvant chemotherapy selection. A high proportion of patients had detectable ctDNA, which appears independent of known prognostic markers. ctDNA detection was associated with earlier recurrence. Analyses of the impact of changes in ctDNA over time on survival are ongoing."
        },
        {
            "Title": "Circulating tumor DNA analysis guiding adjuvant therapy in stage II colon cancer: Overall survival and updated 5-year results from the randomized DYNAMIC trial.",
            "Indication": "Stage II colon cancer",
            "Drug": "Oxaliplatin-based or fluoropyrimidine adjuvant chemotherapy (ACT)",
            "PFS": "None",
            "OS": "5-year OS for ctDNA-guided treatment was 93.8% and standard management 93.3% (HR 1.05; 95% CI, 0.47 to 2.37; P = 0.887). 5-year OS was significantly worse in treated ctDNA-positive versus untreated ctDNA-negative patients (85.6% vs 95.3%, HR 3.30; 95% CI, 1.02 to 9.05; P = 0.014). The 5-year OS for ctDNA-negative T3 and T4 disease were 96.0% and 90.6%, respectively (HR 2.45; 95% CI, 0.65 to 9.25; P = 0.171).",
            "ORR": "None",
            "AE": "None",
            "Other": "5-year RFS for EOT ctDNA clearance vs ctDNA persistence were 85.2% and 20.0%, respectively (HR 15.4; 95% CI, 3.91 to 61.0; P < 0.001). Patients with \u22650.38 (the median) mutant tumor molecules (MTM/mL) had a lower ctDNA clearance rate and worse RFS than patients with <0.38 MTM/mL (ctDNA clearance 75% vs 100%, P = 0.047; 5-year RFS 58.9% vs 95.2%, HR 10.62, P = 0.005). Post-op ctDNA was detected in 5/59 (8.5%) of dMMR and 40/235 (17%) of pMMR cases. In an exploratory analysis, ctDNA clearance was observed in 3/4 (75%) and 32/36 (89%) of dMMR and pMMR cases, respectively.",
            "Conclusion": "Mature outcome data confirms the previous finding of non-inferiority of RFS with ctDNA-guided approach to ACT for stage II CC. For ctDNA-positive patients, the post-surgery mutation burden provides additional prognostic information, as does the EOT ctDNA result. Additional data is needed to define any differential impact of ACT by MMR status. This data supports a role for ctDNA analysis, including serial sampling, in the management of stage II CC."
        },
        {
            "Title": "A-BRAVE trial: A phase III randomized trial with avelumab in early triple-negative breast cancer with residual disease after neoadjuvant chemotherapy or at high risk after primary surgery and adjuvant chemotherapy.",
            "Indication": "Early triple-negative breast cancer",
            "Drug": "Avelumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "Rates of pathologic complete response (pCR) after datopotamab deruxtecan (Dato) plus durvalumab (Durva) in the neoadjuvant setting: Results from the I-SPY2.2 trial.",
            "Indication": "Neoadjuvant setting in breast cancer",
            "Drug": "Datopotamab deruxtecan (Dato) plus durvalumab (Durva)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "A randomized, multicenter, open-label, phase III trial comparing anthracyclines followed by taxane versus anthracyclines followed by taxane plus carboplatin as (neo)adjuvant therapy in patients with early triple-negative breast cancer: Korean Cancer Study Group BR15-1 PEARLY trial.",
            "Indication": "Early triple-negative breast cancer",
            "Drug": "Anthracyclines followed by taxane versus anthracyclines followed by taxane plus carboplatin",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "ECOG-ACRIN EAZ171: Prospective validation trial of germline variants and taxane type in association with taxane-induced peripheral neuropathy (TIPN) in Black women with early-stage breast cancer.",
            "Indication": "Early-stage breast cancer",
            "Drug": "Taxane",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "Impact of hormone receptor status and tumor subtypes on clinical behavior and outcomes of breast cancer in young BRCA carriers.",
            "Indication": "Breast cancer in young BRCA carriers",
            "Drug": "Adjuvant chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "8-year DFS: 65.8% in hormone receptor-positive vs. 63.4% in hormone receptor-negative. Differences in pattern of recurrence and second primary BC among hormone receptor-positive vs. negative disease.",
            "Conclusion": "In young BRCA PV carriers with early BC, hormone receptor status did not appear to be a positive prognostic factor. The differences in pattern of recurrence and second primary BC among hormone receptor-positive vs. negative disease warrant consideration in counseling patients on treatment, follow-up strategies, and indication for risk-reducing surgery."
        },
        {
            "Title": "Correlation of serum anti-Mu\u0308llerian hormone (AMH) levels on identification and outcomes of premenopausal patients (pts) with hormone receptor positive (HR+), HER2-negative, node-positive breast cancer most likely to benefit from adjuvant chemotherapy in SWOG S1007 (RxPONDER).",
            "Indication": "HR+/HER2- invasive breast cancer",
            "Drug": "Endocrine therapy (ET) or with chemotherapy (CET)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "IDFS improvement in premenopausal women with AMH levels \u226510 pg/mL (HR=0.48; 95% CI 0.33-0.69). No benefit in AMH <10 pg/mL (HR=1.21; 95% CI 0.60-2.43).",
            "Conclusion": "Among self-reported premenopausal patients, age \u226455, pts with baseline serum AMH levels \u226510 pg/mL showed significant benefit from CET compared to patients with AMH levels <10 pg/mL. AMH levels were a better indicator of CET benefit than self-reported menopause status, age, or E/LH/FSH levels. Use of AMH-based selection of patients may inform who most benefits from the addition of adjuvant chemotherapy to ET."
        },
        {
            "Title": "Elucidating the immune active state of HR+HER2- MammaPrint High2 early breast cancer.",
            "Indication": "HR+HER2- early breast cancer",
            "Drug": "Neoadjuvant chemotherapy + immunotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "H2 tumors exhibited a heightened immune active state compared to H1 tumors among HR+HER2- EBC pts. The presence of APCs and increased antigen presentation may explain the improved response rates to immunotherapy observed in H2 tumors.",
            "Conclusion": "H2 tumors exhibited a heightened immune active state compared to H1 tumors among HR+HER2- EBC pts. The presence of APCs and increased antigen presentation, which are critical in eliciting T- and B-cell activation, may explain the improved response rates to immunotherapy observed in H2 tumors. Selecting pts based on grade or ER% alone may exclude pts likely to benefit from immune therapy. These data support ongoing trials evaluating the response and benefit of adding immunotherapy to standard of care treatment regimens in MP H2 tumors."
        },
        {
            "Title": "Development and validation of RSClin N+ tool for hormone receptor-positive (HR+), HER2-negative (HER2-), node-positive breast cancer.",
            "Indication": "HR+/HER2- node-positive breast cancer",
            "Drug": "Chemotherapy (CET) vs Endocrine Therapy (ET)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "5-year invasive disease-free survival (iDFS) risk estimates and absolute CET benefit for different RS and clinical risk groups.",
            "Conclusion": "The RSClin N+ model provides improved estimates of prognostic risk and absolute CET benefit than clinical or genomic data alone in node-positive, HR+/HER2- breast cancer and could be used in patient counseling."
        },
        {
            "Title": "Association of MammaPrint index and 3-year outcome of patients with HR+HER2- early-stage breast cancer treated with chemotherapy with or without anthracycline.",
            "Indication": "HR+/HER2- early-stage breast cancer",
            "Drug": "Taxane and cyclophosphamide (TC) vs anthracycline + TC (AC-T)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "3-year Recurrence-Free Interval (RFI) differences between High 1 (H1) and High 2 (H2) Luminal B-Type tumors treated with different chemotherapy regimens.",
            "Conclusion": "These data show that among patients with Luminal B-Type tumors, MammaPrint High 2 (H2) have significantly worse 3-year RFI than patients with MammaPrint High 1 (H1) tumors when treated with TC. This further supports ongoing research demonstrating the significant benefits of anthracycline-based CT for treating H2 Luminal B-Type tumors. Conversely, H1 tumors do not appear to benefit from the addition of anthracycline to CT regimen."
        },
        {
            "Title": "Baseline (BL) characteristics and efficacy endpoints for patients (pts) with node-negative (N0) HR+/HER2- early breast cancer (EBC): NATALEE trial.",
            "Indication": "HR+/HER2- early breast cancer (EBC)",
            "Drug": "Ribociclib (RIB) + non-steroidal aromatase inhibitor (NSAI) vs NSAI alone",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "The rate of discontinuation due to all grade adverse events was 24% vs 8% with RIB+NSAI vs NSAI alone.",
            "Other": "3-year invasive disease-free survival (iDFS), distant disease-free survival (DDFS), and distant recurrence-free survival (DRFS) rates.",
            "Conclusion": "This analysis showed a consistent efficacy benefit and manageable safety profile of RIB+NSAI vs NSAI alone in patients with N0 disease in NATALEE. The 3-year iDFS, DDFS, and DRFS rates in the control arm at this early follow-up underscore the risk of recurrence for the N0 subgroup. These findings support the use of RIB in patients with HR+/HER2- EBC at increased risk of recurrence, including patients with N0 disease."
        },
        {
            "Title": "The impact of adjuvant endocrine therapy (AET) omission in ER-low (1-10%) early-stage breast cancer.",
            "Indication": "Early-stage breast cancer",
            "Drug": "Adjuvant endocrine therapy (AET)",
            "PFS": "None",
            "OS": "94.3% at 2 years, 87.6% at 4 years; HR 1.40 (95% CI: 1.19-1.65, p<0.001); adjusted HR 1.22 (95% CI: 1.00-1.48, p=0.05)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Omission of AET in pts treated with chemotherapy for ER-low, early-stage BC is associated with significantly worse OS. These data strongly suggest that pts with ER-low BC should be counseled regarding the benefit of AET and practice guidelines should recommend AET in this setting."
        },
        {
            "Title": "Oncologic outcome of response-guided axillary treatment in patients with cN1 breast cancer after primary systemic therapy.",
            "Indication": "cN1 breast cancer",
            "Drug": "Axillary radiation treatment",
            "PFS": "None",
            "OS": "ypN1: 93%; ypN0: 98%",
            "ORR": "None",
            "AE": "None",
            "Other": "Axillary recurrence rate: ypN1: 3.5%, ypN0: 0.7%; Local recurrence rate: ypN1: 1.3%, ypN0: 4.4%; Distant recurrence rate: ypN1: 11.3%, ypN0: 1.5%; iDFS: ypN1: 86%, ypN0: 93%",
            "Conclusion": "Response-guided axillary treatment in patients with limited nodal disease according to the MARI protocol is associated with favorable oncologic outcome without performing ALND\u2019s. De-escalation can therefore be considered safe."
        },
        {
            "Title": "Recurrence score gene axes scores and outcomes by race and ethnicity in the RxPONDER trial.",
            "Indication": "Breast cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "IDFS: NHW: 1.00 (referent), NHB: HR 1.41 (95% CI: 0.98-2.03), adjusted HR 1.22 (95% CI: 0.84-1.76); Asian: HR 0.63 (95% CI: 0.43-0.91), adjusted HR 0.64 (95% CI: 0.44-0.93); Hispanic: HR 0.70 (95% CI: 0.47-1.04), adjusted HR 0.70 (95% CI: 0.47-1.04)",
            "Conclusion": "RS gene axes scores differ by race/ethnicity with higher proliferation axis scores noted in NHBs, which could partially explain their inferior outcomes noted in RxPONDER. These findings suggest that tumor biology is indeed important, however, we must dig deeper to uncover intricate factors that contribute to disparities, which is key to designing comprehensive strategies to address them."
        },
        {
            "Title": "Non-basal subtype defined by FOXC1 expression as an independent predictor of capecitabine efficacy in the triple negative breast cancer GEICAM/2003-11_CIBOMA/2004-01 trial.",
            "Indication": "Triple-negative breast cancer (TNBC)",
            "Drug": "Capecitabine",
            "PFS": "None",
            "OS": "HR=0.48; 95% CI 0.24\u20130.96; p=0.038",
            "ORR": "None",
            "AE": "None",
            "Other": "DRFS: HR=0.53; 95% CI 0.31-0.90; p=0.019; multivariate analysis, HR=0.44; 95% CI 0.25\u20130.76; p=0.003; DFS: HR=0.47; 95% CI 0.28\u20130.78; p=0.003; Ki67<20%, HR=0.41; 95% CI 0.20-0.82; p=0.012; Ki67\u226520%, HR=0.31; 95% CI 0.10-0.97; p=0.045",
            "Conclusion": "In the CIBOMA TNBC trial, the non-BLBC definition by a single biomarker (FOXC1 Veresca) provided prognostic and predictive value of cap benefit after (neo)adjuvant CT, corroborating our previous findings by PAM50 and IHC non-BLBC subtyping. This is a pragmatic option to effectively apply findings from this trial in the real-world setting."
        },
        {
            "Title": "ctDNA monitoring for breast cancer at high risk of recurrence: Interim analysis of JCOG1204A1",
            "Indication": "Breast cancer at high risk of recurrence",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "ctDNA status and dynamics in patients from the JCOG1204A1 study",
            "Conclusion": "Monitoring ctDNA in high-risk BC reveals a detection proportion of 11.8% at the patient level (TNBC: 0%; Luminal: 16.2%). Longitudinal testing revealed ctDNA detection in patients with luminal subtype beyond 5 years from surgery, suggesting a correlation with late recurrence. Subsequent reports will focus on investigating the association of ctDNA status with relapse and overall survival."
        },
        {
            "Title": "Clinical relevance of clinical treatment score post 5 years (CTS5) in HR-positive, HER2-positive breast cancer: An exploratory analysis from the HERA trial",
            "Indication": "HR-positive, HER2-positive breast cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "CTS5 score as a continuous variable emerged as an independent prognostic indicator for OS (adjusted HR, 2.45; 95% CI, 1.60-3.74; P < 0.001)",
            "ORR": "None",
            "AE": "None",
            "Other": "CTS5 score as a continuous variable emerged as an independent prognostic indicator for late distant recurrence (adjusted HR, 2.76; 95% CI, 1.84-4.15; P < 0.001)",
            "Conclusion": "CTS5 is a useful prognostic tool for predicting late distant recurrence and overall survival in HR-positive, HER2-positive breast cancer patients. Extension of endocrine therapy should be actively considered in patients with CTS5 high-risk group."
        },
        {
            "Title": "Estimating benefit from dose dense adjuvant chemotherapy for early breast cancer in the PANTHER randomized phase 3 trial.",
            "Indication": "Early breast cancer",
            "Drug": "Epirubicin/Cyclophosphamide (EC) and Docetaxel (D)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "PREDICT model consistently underestimated 5-year and 10-year overall survival. Absolute benefit from tDDEC/D in breast cancer relapse-free survival was stable across risk-defined overlapping subpopulations.",
            "Conclusion": "PREDICT consistently overestimated risk for death in all patient subgroups. Dose dense treatment should be the standard of care for primary resected high-risk breast cancer."
        },
        {
            "Title": "Impact of young age at diagnosis on survival outcomes in patients with early breast cancer according to tumor subtype: Pooled analysis of 5 randomized clinical trials from the Mammella InterGruppo (MIG) and Gruppo Italiano Mammella (GIM).",
            "Indication": "Early breast cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Young patients were more likely to have ductal tumors, larger than 2 cm, with nodal involvement, G3 and triple negative or HER2 positive subtypes. Patients aged \u226440 years had a greater risk of reporting breast cancer events.",
            "Conclusion": "The prognostic significance of young age at diagnosis may vary depending on tumor subtypes and nodal status, indicating poorer outcomes for young patients with hormone-receptor positive/HER2 negative and node negative tumors."
        },
        {
            "Title": "Use of chemotherapy and loco-regional therapy for stage IA triple-negative breast cancer and their association with oncologic outcomes: A cancer registry study.",
            "Indication": "Stage IA triple-negative breast cancer",
            "Drug": "Adjuvant chemotherapy",
            "PFS": "None",
            "OS": "Significant influence for the use of adjuvant chemotherapy on OS in pT1c patients (HR 0.55, 95% CI 0.38 to 0.8).",
            "ORR": "None",
            "AE": "None",
            "Other": "Local therapy (HR 0.41, 95% 0.25 to 0.69, for breast conserving therapy vs. mastectomy only) and patient age (HR 2.33, 95% CI 1.61 to 3.39 for age \u226570 vs. <70 years) were associated with OS.",
            "Conclusion": "OS in stage IA TNBC is strongly influenced by local therapy and patient age, but not use of chemotherapy. Larger studies with longer-term follow-up are welcomed to fully inform this discussion."
        },
        {
            "Title": "Benefit of perioperative chemotherapy in the patients with loco-regional recurrence of HR-positive HER2-negative breast cancer: A multi-institutional retrospective cohort study.",
            "Indication": "Loco-regional recurrence of HR-positive HER2-negative breast cancer",
            "Drug": "Perioperative chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "5-year iDFS rate was 75.4% (95% CI: 72.4-78.2). Multivariate analysis showed an improvement in iDFS for the chemotherapy group (HR: 0.70, 95% CI: 0.49-0.99, p=0.045). Subgroup analysis showed more significant iDFS improvement in cases with oLRR, recurrences during adjuvant endocrine therapy, and those without perioperative chemotherapy for primary BC.",
            "Conclusion": "Our study showed improvement in iDFS in patients who received perioperative chemotherapy for HR+ HER2- LRR. This improvement was particularly notable in patients with oLRR, recurrences during adjuvant endocrine therapy, and those without perioperative chemotherapy for primary BC."
        },
        {
            "Title": "Adjuvant chemotherapy guided by an integrated mRNA\u2013lncRNA signature in triple-negative breast cancer (BCTOP-T-A01): A randomized, open-label, multicenter phase 3 trial.",
            "Indication": "Triple-negative breast cancer",
            "Drug": "Docetaxel, epirubicin, cyclophosphamide, gemcitabine, cisplatin",
            "PFS": "None",
            "OS": "The groups did not differ in OS (HR, 0.58; 95% CI, 0.22 to 1.54; P=0.267; 3-year OS, 98.2% vs 91.3%).",
            "ORR": "None",
            "AE": "The incidence of grade 3 to 4 treatment-related adverse events for arms A, B, and C, respectively, were thrombocytopenia (14.1%, 2.5%, 1.8%), febrile neutropenia (9.9%, 4.3%, 4.9%), anemia (5.8%, 1.9%, 1.8%), nausea (7.0%, 3.7%, 4.9%), vomiting (8.3%, 4.3%, 4.9%) and myalgia (2.6%, 5.6%, 5.5%). There were no treatment-related deaths.",
            "Other": "The 3-year DFS rates were 90.9% for patients in arm A and 80.6% for patients in arm B (HR, 0.51; 95% CI, 0.28 to 0.95; P=0.030). The 3-year RFS was 92.6% in arm A and 83.2% in arm B (HR, 0.50; 95% CI, 0.25 to 0.98; P=0.039). Upon receiving the same chemotherapy regimen, patients in arm C had significantly higher DFS (HR, 0.57; 95% CI, 0.33-0.98; P=0.038; 3-year DFS, 90.1% vs 80.6%), RFS (HR, 0.42; 95% CI, 0.22-0.81; P=0.007; 3-year RFS, 94.5% vs 83.2%) and OS (HR, 0.14; 95% CI, 0.03-0.61; P=0.002; 3-year OS, 100% vs 91.3%) compared to patients in arm B.",
            "Conclusion": "The multigene signature showed potential for tailoring adjuvant chemotherapy for patients with operable TNBC. Intensive regimens incorporating GP into anthracycline/taxane-based therapy improve survival with manageable toxicity."
        },
        {
            "Title": "Breast Cancer Index re-stratifies 21-gene assay risk groups for late distant recurrence and extended endocrine therapy benefit: Results from the BCI Registry Study.",
            "Indication": "Hormone receptor-positive (HR+) breast cancer",
            "Drug": "Extended endocrine therapy (EET)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "BCI H/I and prognostic risk scores exhibited low concordance with RS risk categories. H/I consistently re-stratified RS risk categories into distinct H/I-Low and -High groups.",
            "Conclusion": "BCI H/I and prognostic risk scores exhibited low concordance with RS risk categories. H/I consistently re-stratified RS risk categories into distinct H/I-Low and -High groups, further substantiating that prediction of CT benefit does not equate to prediction of EET benefit."
        },
        {
            "Title": "RNA-Seq based gene expression profiling of baseline and on-treatment breast tumors to predict response to HER2-directed therapy, without chemotherapy (TBCRC026).",
            "Indication": "ER-negative, HER2-positive breast cancer",
            "Drug": "Neoadjuvant trastuzumab/pertuzumab (HP)",
            "PFS": "None",
            "OS": "None",
            "ORR": "pCR rate was 19% (10/53) with all but one exhibiting HER2-E subtype.",
            "AE": "None",
            "Other": "Gene expression changes from baseline to C1D15 were analyzed; ERBB2 was downregulated after HP (p=0.007), B cell signatures, CD4, CD8, natural killer cells and Treg cells were significantly upregulated at C1D15.",
            "Conclusion": "In this unique cohort with ER-negative, HER2-positive breast cancer treated with neoadjuvant HP without chemotherapy, HER2-E tumors and those with increased immune signaling were more likely to respond to HER2-directed therapy. Combining C1D15 SULmax on PET/CT and a B cell signature provided improved prognostic information and should be further explored as candidate biomarkers in this setting."
        },
        {
            "Title": "PARPi eligibility amongst women with newly diagnosed invasive breast cancer enrolled in the GREAT universal genetic testing study.",
            "Indication": "Invasive breast cancer",
            "Drug": "Poly (ADP-ribose) polymerase inhibitors (PARPi)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Approximately 14% are candidates for PARPi prior to genetic testing, and 1.8% remain PARPi eligible due to a confirmed pathogenic variant in BRCA1/2.",
            "Conclusion": "In women with newly diagnosed invasive breast cancer, genetic testing impacts systemic therapy decisions for PARPi in 10% of TNBC patients and fewer than 1% of women with ER+HER2- breast cancer."
        },
        {
            "Title": "Dose-dense adjuvant chemotherapy in patients with HER2-low early breast cancer: An exploratory analysis of the GIM2 trial.",
            "Indication": "HER2-low early breast cancer",
            "Drug": "Dose-dense (DD) adjuvant chemotherapy",
            "PFS": "15 yr iDFS were 61% vs. 54% for HER2-zero vs. HER2-low tumors, respectively, p=0.25",
            "OS": "15 yr OS were 75% vs. 72% for HER2-zero vs. HER2-low tumors, respectively, p=0.19",
            "ORR": "None",
            "AE": "None",
            "Other": "HR for iDFS was 0.64 (95% CI 0.46-0.87) and HR for OS was 0.57 (95% CI 0.38-0.88) in HER2-zero tumors. HR was 0.83 (95% CI 0.62-1.11) for iDFS and 0.83 (95% CI 0.57-1.22) for OS in HER2-low tumors.",
            "Conclusion": "In this exploratory analysis of a large phase III adjuvant trial, HER2 status (i.e., HER2-zero vs. HER2-low) did not show prognostic value. In patients with high-risk early breast cancer, the benefit of DD chemotherapy was observed irrespective of HER2 status."
        },
        {
            "Title": "Updated SEER database study of 21-gene assay to assess breast cancer\u2013specific mortality and benefit of chemotherapy by race and ethnicity.",
            "Indication": "Breast cancer",
            "Drug": "21-gene Breast Recurrence Score assay (RS)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Breast cancer specific survival (BCSS) at 9 years among patients with chemotherapy recorded as none/unknown by racial/ethnic groups.",
            "Conclusion": "Real-world evidence from the SEER registries in over 145,000 patients confirms the 21-gene assay is prognostic for BCSS in all groups by race and ethnicity. The RS result was predictive of chemotherapy benefit in N0 patients overall and in Hispanic, NHB, and NHW patients, but not in NHAPI patients in this analysis."
        },
        {
            "Title": "Cost consequence model of the MammaPrint (70-gene signature) and 21-gene signature in patients with primary HR+ HER2-, N1 early-stage breast cancer in Germany.",
            "Indication": "HR+ HER2- early-stage breast cancer",
            "Drug": "MammaPrint (70-gene signature) and Oncotype DX (21-gene signature)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Economic impact of implementing MammaPrint, Oncotype DX (ODx), or no gene signature testing for patients with HR+ HER2- early breast cancer with 1 to 3 positive lymph nodes (N1) in Germany.",
            "Conclusion": "These data demonstrate that personalized treatment planning with genomic testing for breast cancer with HR+/HER2-/LN1 breast cancer leads to a reduction in chemotherapy use and associated costs in Germany. Although MammaPrint designates a smaller proportion of genomically high-risk patients compared to ODx, MammaPrint Low Risk patients without chemotherapy demonstrate higher survival outcomes. Consequently, any increased chemotherapy costs are offset by lower testing and distant recurrence expenses, making MammaPrint a cost-conscious option."
        },
        {
            "Title": "Analysis of the sensitivity to endocrine therapy (SET) assay in the PALLAS adjuvant trial of palbociclib in HR+/HER2- breast cancer (ABCSG-42/AFT-05/BIG-14-13)",
            "Indication": "HR+/HER2- breast cancer",
            "Drug": "Palbociclib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "5-year iDFS was 91.4% in patients with high SET2,3 versus 78.5% in patients with low SET2,3 (HR 0.38, 95% CI 0.31\u20130.47, p<0.0001). SET index was significantly associated with iDFS (HR 0.70 per 0.5 units, 95% CI 0.65\u20130.77, p<0.0001).",
            "Conclusion": "There was no evidence that the SET2,3 or SET index of endocrine-related transcriptional activity predicts benefit from palbociclib treatment. SET2,3 index was strongly prognostic for patients with Stage II-III breast cancer, with excellent 5-year IDFS for patients classified as SET2,3 high."
        },
        {
            "Title": "Predicting epirubicin response in Danish patients with breast cancer",
            "Indication": "Breast cancer",
            "Drug": "Epirubicin",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The hazard ratio (HR) for the best-performing signature was 0.31 when comparing patients with a difference of at least 50 in response score (two-sided p-value=0.012, 95% confidence interval 0.12-0.77, endpoint TTP, continuous scoring).",
            "Conclusion": "Presented is the development and clinical validation of an accurate epirubicin response signature for breast cancer patients. The expression of the genes in the signature is highly correlated between microarray- and RNA sequencing data. Moving forward, the model will be validated independently on RNA sequencing data, with the aim for prospective clinical tests at local hospitals in Denmark in early 2025."
        },
        {
            "Title": "Cancer outcomes from a real-world cohort of patients eligible for adjuvant CDK4/6 inhibitors but without genomic risk for chemotherapy: A GBECA multicenter retrospective study",
            "Indication": "HR+/HER2- early breast cancer",
            "Drug": "Abemaciclib, Ribociclib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "In patients with indication of abemaciclib, 4-year iDFS and 4-year DDFS rates were both 100%. In patients with indication of ribociclib, 4-year iDFS and 4-year DDFS rates were both 100%. In the entire cohort, there was only one distant recurrence event.",
            "Conclusion": "In times of rapid incorporation of both GES and adjuvant CDK4/6i, results suggest that patients eligible for adjuvant abemaciclib and/or ribociclib in the non-CT population have a favorable short-term outcome with endocrine therapy alone. Therefore, additional data and longer follow-up are needed to clarify the role of these costly and potentially toxic agents in patients spared CT by genomic signatures."
        },
        {
            "Title": "Relevance of timing intake of everolimus (EVE) combined with adjuvant endocrine therapy (ET) in patients (pts) with high-risk early breast cancer: Results of a sub-study of the UCBG-UNIRAD trial.",
            "Indication": "High-risk early breast cancer",
            "Drug": "Everolimus (EVE) combined with adjuvant endocrine therapy (ET)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Disease-Free Survival (DFS): HR=0.84 [95% CI, 0.53-1.35], p=0.4. Significant interaction between EVE timing intake and ET type (Pinteraction= 0.001). Patients on evening/night EVE combined with tamoxifen had a reduced likelihood of relapse compared to those taking morning/afternoon EVE (HR=0.17 [0.05-0.59], p=0.005). No significant EVE effect was found in the AI group (HR=1.56 [0.92-2.64], p=0.1). Evening/night intake of EVE plus tamoxifen was independently associated with DFS (HR=0.13 [0.03-0.6], p=0.002).",
            "Conclusion": "These exploratory results are consistent with a role of circadian rhythm in the efficacy of EVE when given with tamoxifen in pts with high-risk early breast cancer."
        },
        {
            "Title": "Clinical outcomes of patients with HER2-positive microinvasive breast cancer.",
            "Indication": "HER2-positive microinvasive breast cancer",
            "Drug": "Adjuvant trastuzumab with paclitaxel",
            "PFS": "None",
            "OS": "8-year OS: 98.6% (95% CI, 97.8-99.5)",
            "ORR": "None",
            "AE": "None",
            "Other": "Invasive Disease-Free Survival (IDFS): 8-year IDFS rates for patients who did and did not receive adjuvant chemotherapy were 91.1% (95% CI, 86.6-95.8) and 90.3% (95% CI, 87.9-92.8), respectively (p = 0.930). Distant Recurrence-Free Survival (DRFS): 8-year DRFS rates were 97.3% (95% CI, 96.1-98.4).",
            "Conclusion": "In HER2-positive MIBC, a clinically meaningful proportion of patients experience IDFS events with long-term follow-up. The role of modern adjuvant chemotherapy regimens, as well as the role of adjuvant trastuzumab, should be further explored in this early-stage patient population."
        },
        {
            "Title": "Impact of CYP2D6 polymorphisms on adverse events in patients with breast cancer treated with tamoxifen: Insights from an early dose escalation trial in CYP2D6 poor metabolizers.",
            "Indication": "Breast cancer",
            "Drug": "Tamoxifen",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Uterine changes: lower incidence in extensive metabolizers (P-value < 0.001 in Kaplan-Meier; HR in Cox of 0.195, 95% CI 0.073-0.521, P=0.001).",
            "Other": "Endoxifen concentration levels similar to those of patients with extensive phenotype, no long-term survival differences among CYP2D6 phenotypes.",
            "Conclusion": "An early increase in tamoxifen dose in poor metabolizer patients according to CYP2D6 polymorphisms may significantly affect the incidence of uterine changes, but not the incidence of osteoarticular pain, hot flashes, or asthenia."
        },
        {
            "Title": "Effects of plant-based diet (PBD) and exercise therapy (Ex) on weight and body composition in patients with primary hormone receptor (HR) positive breast cancer: A phase 2 randomized controlled trial.",
            "Indication": "Primary hormone receptor (HR) positive breast cancer",
            "Drug": "Plant-based diet (PBD) and exercise therapy (Ex)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "No serious adverse events observed.",
            "Other": "Mean weight loss in PBD plus Ex was \u201313% (SD 6%) compared with \u20135% (SD 6%) in attention control (mean between group difference \u20138kg, 95% CI \u201311, \u20134). Mean fat loss in PBD plus Ex was \u20136kg compared with \u20132kg in attention control (mean between group difference \u20134kg, 95% CI \u20136, \u20132). Lean mass increases were greater in PBD plus Ex (P < 0.001).",
            "Conclusion": "Personalized PBD plus Ex led to substantial weight loss and favorable changes in body composition during endocrine therapy for HR-positive breast cancer."
        },
        {
            "Title": "Safety run-in phase of the multi-epitope HER2 peptide vaccine in combination with trastuzumab emtansine in HER2-positive breast cancer with residual disease after neoadjuvant chemotherapy.",
            "Indication": "HER2-positive breast cancer with residual disease after neoadjuvant chemotherapy",
            "Drug": "H2NVAC (multi-epitope HER2 peptide vaccine) in combination with trastuzumab emtansine (T-DM1)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Only grades 1 and 2 AEs were observed during the DLT period, with fatigue being the most common (G1 60%, G2 5%), followed by peripheral sensory neuropathy (G1 55%, G2 5%) and injection site reaction (G1 50%). Across all treatment cycles, the top 10 most common AEs that were at least possibly related to treatment include injection site reaction (G1 85%, G2 15%), AST increase (G1 65%), ALT increase (G1 45%), fatigue (G1 35%, G2 10%), peripheral sensory neuropathy (G1 35%, G2 10%), alk-phos increase (40% G1), nausea (G1 35%, G2 5%), arthralgia (G1 35%), myalgia (G1 25%, G2 5%), and platelet count decrease (G1 30%). There were four patients with G3 AEs at least possibly related to the vaccine observed after the DLT period, including allergic reaction, myositis/skin infection, urticaria, and pruritus/maculopapular rash.",
            "Other": "None",
            "Conclusion": "H2NVAC, in combination with T-DM1, was safe without DLT observed. The side effect profile was favorable, with the most common AE being the G1 injection site reaction. A multicenter, randomized, placebo-controlled, phase II trial of H2NVAC vs. placebo in combination with trastuzumab emtansine is currently ongoing through the ACCRU consortium."
        },
        {
            "Title": "Correlation of TILs indices generated by visual review and digital computational algorithm with outcomes from preoperative chemotherapy for TNBC in TBCRC030",
            "Indication": "Triple-negative breast cancer (TNBC)",
            "Drug": "Cisplatin (C) or Paclitaxel (T)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "TILs by VE had OR 1.86 (95% CI 1.24, 2.87, p=0.031) and AUC 0.69 (95% CI 0.57, 0.66), whereas CA CmbI had OR 1.54 (95% CI 1.00, 2.42, p=0.053) and AUC 0.62 (95% CI 0.51, 0.73). VE of baseline TILs and CA CmbI were predictive of response to NAC for TNBC.",
            "Conclusion": "In this analysis of TBCRC030, VE of baseline TILs and CA CmbI were predictive of response to NAC for TNBC, however CA did not outperform VE. TILs predicted response to specific chemotherapy. Automated computational evaluation of TILs or the broader immune microenvironment is an emerging field which may allow accurate and reproducible assessment of these important biomarkers."
        },
        {
            "Title": "Ki67 as a prognostic factor in male breast cancer (male BC): Results from a large GEICAM Spanish cohort of male BC",
            "Indication": "Male breast cancer (male BC)",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "HR+/HER2- is the most common Male BC subtype, with ER and PgR highly positive, and 20% as median Ki67 index. No statistically significant differences were observed in terms of iDFS and dDFS based on level of Ki67 index expression.",
            "Conclusion": "HR+/HER2- is the most common Male BC subtype, with ER and PgR highly positive, and 20% as median Ki67 index. No statistically significant differences were observed in terms of iDFS and dDFS based on level of Ki67 index expression."
        },
        {
            "Title": "Association of tumor-infiltrating lymphocytes (TILs) and immune signatures with PAM50 intrinsic subtyping hormone receptor-positive (HR+)/HER2-negative (HER2-) early breast cancer (BC): A translational analysis of two multicentric neoadjuvant trials.",
            "Indication": "HR+/HER2- early breast cancer",
            "Drug": "Combination of chemo and immune checkpoint inhibitors (ICI)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "PAM50 subtype distribution: 44% Luminal B, 33% Luminal A, 18% Basal-like, 5% HER2-enriched. Basal-like BCs showed higher TILs and immune signatures associated with CD8 T-cells, cytotoxic cells, IFN-gamma, and response to ICI.",
            "Conclusion": "PAM50 intrinsic subtyping and TILs/immune signatures capture only partially overlapping information on the biology of HR+/HER2- BCs. Patients with HR+/HER2- BCs showing features of biological aggressiveness and enhanced immunogenicity may represent ideal candidates for the combination of ICI and chemo."
        },
        {
            "Title": "Association of circulating T lymphocytes and their functional states with pathologic complete response (pCR) in breast cancer (BC). A prospective Italian study.",
            "Indication": "Breast cancer",
            "Drug": "Neoadjuvant treatments (NATs) including chemotherapy and pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "LMR associated with increased pCR (OR 1.34 [95% CIs 1.00-1.89]), and NLR with decreased pCR (OR 0.67 [0.39-1.04]). Senescent CD4+ predicted lower pCR in HR-HER2+ and TNBC. Exhausted CD8+ predicted higher pCR in TNBC and with chemotherapy + pembrolizumab.",
            "Conclusion": "The characterization of circulating T lymphocytes could help identify patients who benefit more in terms of pCR from specific NATs, including chemoimmunotherapy."
        },
        {
            "Title": "Molecular profiling after short-term preoperative endocrine therapy to identify oestrogen receptor positive (ER+)/HER2+ early breast tumours with favourable prognosis.",
            "Indication": "ER+/HER2+ early breast cancer",
            "Drug": "Peri-operative aromatase inhibitors (POAI)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "In POAI tumours, immunity-related signatures and mammary stemness were significantly upregulated in responders while proliferation, DNA-damage repair (DDR), TP53 mutational status and ER-signaling were downregulated. Intrinsic subtype (IS) shifting was significantly more prevalent in treated (37%) than in controls (14%). Most Luminal B shifted to Luminal A, driven by a reduction in proliferation.",
            "Conclusion": "We provide a comprehensive picture on how ER+/HER2+ BC gene expression profile changes in response to ET. Most Luminal B BC shift to Luminal A, being Luminal A 2wk IS significantly associated with better outcome. There is clinical utility of peri-operative ET for ER+HER2+ BC, guiding who would have good prognosis for adjuvant ET and who may need additional therapy."
        },
        {
            "Title": "Evaluating the association between radiomic features on breast MRI and dormant tumor cell presence in the SURMOUNT trial.",
            "Indication": "Breast Cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Two statistically significant radiomic phenotypes (p<0.05) were identified that were strongly associated with BMA outcome (p=0.0251).",
            "Conclusion": "We found a statistically significant association between DTC presence and radiomic phenotypes derived from MRI data. Given the large percentage of positive outcomes assigned to the second radiomic phenotype, the radiomic phenotypes may indicate low and high risk of MRD, respectively. This analysis shows promise for the ability to predict MRD from noninvasive imaging features."
        },
        {
            "Title": "Combination of predicted sensitivity to endocrine therapy (SET2,3 index) and the Recurrence Score in node-positive breast cancer: Independent validation in the PACS-01 trial.",
            "Indication": "Node-positive breast cancer",
            "Drug": "Adjuvant docetaxel to anthracycline-based chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "SET2,3 index added prognostic information to the Recurrence Score in this independent blinded validation study of the PACS-01 trial for node-positive breast cancer.",
            "Conclusion": "SET2,3 index added prognostic information to the Recurrence Score in this independent blinded validation study of the PACS-01 trial for node-positive breast cancer."
        },
        {
            "Title": "Relationship of adaptive subtyping and tumour heterogeneity of treatment response to neoadjuvant therapy in hormone receptor\u2013positive HER2-negative early breast cancer: PENELOPE-B.",
            "Indication": "Hormone receptor\u2013positive HER2-negative early breast cancer",
            "Drug": "Palbociclib or placebo",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Adaptive subtyping can complement the classical approach, focusing on those genes that are changed during therapy. Comparison of paired samples before and after therapy is superior to classical subtyping of baseline samples.",
            "Conclusion": "Classical approach of intrinsic subtyping relies on constant gene expression, limiting its scope. Adaptive subtyping can complement the classical approach, focusing on those genes that are changed during therapy. Comparison of paired samples before and after therapy is superior to classical subtyping of baseline samples. This approach identified large subgroups of patients with excellent prognosis after NACT despite clinical high risk. This can be an important step to focus on high-risk patients for new neoadjuvant and post-neoadjuvant therapy concepts."
        },
        {
            "Title": "Monitoring ctDNA dynamics in early breast cancer using a novel ultra-sensitive tumor-informed structural variant approach combining whole-genome sequencing and multiplex dPCR.",
            "Indication": "Early breast cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "Post-operative detection of ctDNA associated with poor overall survival (OS) compared to absence of ctDNA (log-rank P < 0.0001).",
            "ORR": "None",
            "AE": "None",
            "Other": "ctDNA detection post-neoadjuvant therapy, prior to surgery associated with high-risk of disease relapse. Post-operative ctDNA detection was observed in 100% patients with clinical recurrence, and associated with poorer OS and long lead-times.",
            "Conclusion": "In this interim analysis of an ongoing prospective study in patients with EBC receiving NAT, we analyzed plasma for ctDNA using a novel tumor-informed dPCR assay tracking patient-specific SVs. ctDNA detection post-neoadjuvant therapy, prior to surgery associated with high-risk of disease relapse. Post-operative ctDNA detection was observed in 100% patients with clinical recurrence, and associated with poorer OS and long lead-times. Our data demonstrate the feasibility of SVs as an MRD analyte and provide evidence for high levels of clinical sensitivity achievable with this approach in EBC."
        },
        {
            "Title": "Predicting response to neoadjuvant therapy (NAT) in patients (pts) with Nectin-4 expression in primary breast cancer and associated clinical outcomes.",
            "Indication": "early-stage breast cancer",
            "Drug": "Enfortumab vedotin",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "ctDNA dynamics during NAT can facilitate real-time assessment of treatment response and can serve as an early indicator of pathologic response, predict ctDNA status after surgery, and inform disease management for BC pts at high risk of recurrence.",
            "Conclusion": "ctDNA dynamics during NAT can facilitate real-time assessment of treatment response and can serve as an early indicator of pathologic response, predict ctDNA status after surgery, and inform disease management for BC pts at high risk of recurrence."
        },
        {
            "Title": "Artificial intelligence (AI)\u2013powered H&E whole-slide image (WSI) analysis to predict recurrence in hormone receptor positive (HR+) early breast cancer (EBC).",
            "Indication": "early-stage hormone receptor positive, HER2 negative breast cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Histopathomic models can accurately predict the RS of high risk as well as poor DFS from only H&E WSIs. These AI models can be a practical tool for treatment selection including emerging drugs such as SERDs.",
            "Conclusion": "Histopathomic models can accurately predict the RS of high risk as well as poor DFS from only H&E WSIs. These AI models can be a practical tool for treatment selection including emerging drugs such as SERDs."
        },
        {
            "Title": "The immunomodulatory impacts of endocrine therapy on host immunity in early-stage hormone receptor positive, HER2 negative breast cancer.",
            "Indication": "early-stage hormone receptor positive, HER2 negative breast cancer",
            "Drug": "Endocrine therapy (ET)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "There are significant differences in the baseline immune cell repertoire/activity in BC pts compared to HCs. The reduction in B cell activity/number and an increase in regulatory T cell number suggests a possible compromised immune system in BC pts. After surgery and 6 months of ET, these suppressed immune phenotypes reversed.",
            "Conclusion": "There are significant differences in the baseline immune cell repertoire/activity in BC pts compared to HCs. The reduction in B cell activity/number and an increase in regulatory T cell number suggests a possible compromised immune system in BC pts. After surgery and 6 months of ET, these suppressed immune phenotypes reversed."
        },
        {
            "Title": "Correlation of hormone receptor positive HER2-negative/MammaPrint high-2 breast cancer with triple negative breast cancer: Results from gene expression data from the ISPY2 trial.",
            "Indication": "Hormone Receptor positive (HR+), HER2-negative (HER2-) breast cancer",
            "Drug": "Not specified",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Pathologic complete response rates: 41% for TN/MP-H2, 31% for HR+/MP-H2, and 11% for HR+/MP-H1",
            "Conclusion": "A subset of HR+ cancers defined by MP-H2 status closely resemble TN in molecular and clinical features. The similarity of pCR rates for MP-2 observed within either HR+/HER2- and TN is supported by our findings of similarity of active biology between HR+/MP-2 and TN/MP-2. These findings have implications for understanding the biology of HR+/MP-H2 BCs and optimizing treatment strategies."
        },
        {
            "Title": "Evaluating Ki67 and Oncotype DX Breast Recurrence Score during neoadjuvant treatment with letrozole/abemaciclib or chemotherapy in patients with highly proliferative HR+/HER2- breast cancer participating in the GEICAM CARABELA trial.",
            "Indication": "Highly proliferative HR+/HER2- breast cancer",
            "Drug": "Letrozole/abemaciclib or chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Residual cancer burden (RCB) 0-I rates: 13% for letrozole/abemaciclib vs. 18% for chemotherapy. Baseline Ki67 and Recurrence Score (RS) distributions were similar between arms. Ki67 suppression was higher in the letrozole/abemaciclib arm at 2 weeks but not at surgery. Median RS at surgery was higher in the letrozole/abemaciclib arm.",
            "Conclusion": "Highly proliferative tumors (Ki67 \u2265 40%) or those with high Recurrence Score (> 25) exhibited higher rates of RCB 0-I when treated with chemotherapy than when treated with letrozole/abemaciclib. This suggests that relying solely on letrozole/abemaciclib as systemic treatment for these tumors may be insufficient."
        },
        {
            "Title": "Patient-reported outcomes in N1 breast cancer: A phase III trial comparing whole-breast to whole-breast plus regional irradiation after taxane-based chemotherapy (KROG 17-01).",
            "Indication": "N1 breast cancer",
            "Drug": "Taxane-based adjuvant chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Fatigue, nausea, and vomiting during RT (p<0.05); burns (Grade 1: 1.1%, Grade 2: 0.8%)",
            "Other": "Patient-reported outcomes (PROs) collected at multiple time points showed minor, transient declines in specific PRO domains for the WBI+RNI group.",
            "Conclusion": "Adding RNI was associated with minor, transient declines in specific PRO domains. However, these differences were not clinically significant, indicating comparable overall patient experiences between the two treatment arms."
        },
        {
            "Title": "Radiofrequency ablation without excision for breast cancer: 5-year results of ipsilateral breast tumor recurrence-free survival in the RAFAELO study (NCCH 1409).",
            "Indication": "Small breast cancers",
            "Drug": "Radiofrequency ablation",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Burns (Grade 1: 1.1%, Grade 2: 0.8%)",
            "Other": "5-year IBTR-free survival was 98.6% (90% CI 97.1%-99.3%)",
            "Conclusion": "Breast radiofrequency ablation presents a promising alternative to surgery while offering the benefits of a minimally invasive procedure with minimal risks. Further analysis within this clinical trial or registry is needed to confirm radiofrequency ablation as a viable alternative to surgical excision for breast cancer patients in terms of cosmetic results and patient satisfaction."
        },
        {
            "Title": "Radar reflectors for marking of target lymph nodes in patients receiving neoadjuvant chemotherapy for breast cancer: A subgroup analysis of the prospective AXSANA (EUBREAST-03) trial.",
            "Indication": "Breast cancer with clinically positive to clinically negative lymph node status during neoadjuvant chemotherapy",
            "Drug": "Neoadjuvant chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Very high detection and removal rates of TLN and marker. In 98% of patients with available postoperative histopathology, the TLN was unequivocally identified and removed.",
            "Conclusion": "Radar reflectors are a reliable tool for marking of TLNs in the neoadjuvant setting."
        },
        {
            "Title": "Patient-derived tumor cell clusters (PTC) guiding neoadjuvant therapy of HER2-positive breast cancer: A prospective randomized phase II clinical trial.",
            "Indication": "HER2-positive breast cancer",
            "Drug": "Cyclophosphamide combined with epirubicin\u2013Docetaxel, Trastuzumab, Pertuzumab, Pyrotinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "90.9% in experimental group vs 71.4% in control group (Hazard ratio=0.250, P=0.027)",
            "AE": "None",
            "Other": "Pathological complete response (pCR): 65.9% in experimental group vs 42.9% in control group (Hazard ratio=0.388, P=0.033); Miller-Payne (MP) grade 4~5 proportion: 79.5% in experimental group vs 59.4% in control group (Hazard ratio=0.283, P=0.009); Disease-free survival: 84.1% in experimental group vs 71.4% in control group (Log Rank, P=0.106)",
            "Conclusion": "The PTC-guiding neoadjuvant therapy could improve the effect, including pCR, MP score, and ORR in HER2-positive breast cancer."
        },
        {
            "Title": "Therapeutic response and outcomes with less common breast cancer subtypes in the I-SPY trial 2011-2022.",
            "Indication": "High-risk breast cancer with uncommon histologies",
            "Drug": "Various novel agents with chemotherapy backbone",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Pathologic complete response (pCR) and early event-free survival (EFS) by disease subtype: Metaplastic BC: pCR 34% with checkpoint blockade, 18% without; HER2-Immune+ group: pCR 50%; HER2+ driven RPS groups: pCR 88% in HER+HER2 or Basal, 0% in HER2+Luminal",
            "Conclusion": "High pCR rates observed in metaplastic BC and other difficult-to-treat BC subtypes support novel approaches and provide a roadmap for future study of uncommon BCs. Outcomes were improved when therapy matched RPS vulnerabilities."
        },
        {
            "Title": "Incidence and time to onset of immunotherapy-related adrenal insufficiency in the I-SPY2 trial.",
            "Indication": "Early breast cancer (EBC)",
            "Drug": "Immune Checkpoint Inhibitors (ICIs) including Pembrolizumab, Durvalumab, Cemiplimab, REGN3767",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "11.8% (n=52) developed adrenal insufficiency (AI); Incidence highest in arms with dual immune modulation (18.1%) compared to single ICI therapy (8.5%); Majority (94.2%) of patients with AI were symptomatic",
            "Other": "Median time to onset varied by arm, ranging from 92-204 days from first ICI dose; Most common presenting symptoms included fatigue (59.6%), nausea (48.1%), and vomiting (34.6%)",
            "Conclusion": "The incidence of AI in EBC is higher than previously reported in advanced disease, with higher rates observed in those treated with dual immune modulation. Close monitoring in the peri/postoperative setting and education of patients and providers is critical, as risk persists even after completion of therapy."
        },
        {
            "Title": "Comparing 18F-FDG positron emission tomography (PET) and breast magnetic resonance imaging (MRI) to predict pathological complete response (pCR) and 3-year invasive disease-free survival (3-yiDFS) in patients (pts) with HER2+ early breast cancer (EBC): An unplanned exploratory analysis of PHERGain trial.",
            "Indication": "HER2+ early breast cancer (EBC)",
            "Drug": "trastuzumab, pertuzumab (HP)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Comparison between PET response and MRI reduction in terms of pCR and 3-yiDFS.",
            "Conclusion": "Although PET is the recommended imaging technique for early treatment response, these data suggest that MRI-assessed tumor shrinkage could alternatively guide NAT after the first 2 HP cycles in HER2+ EBC pts following the PHERGain strategy when PET is not available."
        },
        {
            "Title": "Addition of the MUC-1 vaccine tecemotide to neoadjuvant systemic therapy for patients with early breast cancer: Survival results from the prospective randomized ABCSG34 trial.",
            "Indication": "Early breast cancer",
            "Drug": "Tecemotide (liposomal BLP25; Stimuvax)",
            "PFS": "None",
            "OS": "83.0% vs 68.2% (HR for OS 0.53, 95% CI 0.33-0.85, p=0.008)",
            "ORR": "None",
            "AE": "None",
            "Other": "DRFS: 80.8% vs 64.7% (HR for DRFS: 0.53, 95% CI 0.34-0.83, p=0.005)",
            "Conclusion": "The MUC-1 vaccine tecemotide, when added to standard neoadjuvant systemic therapy, markedly improved DRFS and OS. Despite the exploratory nature of this observation, this is the first significant and profound long-term survival benefit of an anti-cancer vaccine in breast cancer patients reported to date."
        },
        {
            "Title": "Prevalence and dynamics of circulating tumor DNA (ctDNA) among patients (pts) with HER2+ breast cancer (BC) receiving neoadjuvant paclitaxel/trastuzumab/pertuzumab (THP) in the DAPHNe trial.",
            "Indication": "HER2+ breast cancer",
            "Drug": "Paclitaxel, trastuzumab, pertuzumab (THP)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "ctDNA detection rates at various time points: baseline (92%), pre-op (4%), post-op (4%), late adjuvant (3%)",
            "Conclusion": "In this population of moderate risk (mostly stage II) HER2+ BC pts, 92% were ctDNA+ at baseline. Neoadjuvant THP was very effective at clearing MRD, regardless of whether there was residual disease in the breast/nodes or not, consistent with the absence of any BC recurrence in the cohort to date."
        },
        {
            "Title": "Multiomic factor analysis for pathologic complete response (pCR) after 12 weeks of pembrolizumab + trastuzumab + pertuzumab in HER2-enriched (HER2-E) early breast cancer (eBC) in the Keyriched-1 trial.",
            "Indication": "HER2-enriched early breast cancer (HER2-E eBC)",
            "Drug": "Pembrolizumab, Trastuzumab, Pertuzumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "pCR rates were 66.7% in the high score group and 28.6% in the low score group. Factors involving gene expression, mIHC markers, and sTILs datasets were significantly associated with pCR.",
            "Conclusion": "Our multiomic analysis uncovered potential biomarkers for pCR in HER2-E eBC treated with immunotherapy and dual-HER2 blockade alone. These results support validation in larger trials investigating chemotherapy-free regimens in selected patients with HER2+ eBC."
        },
        {
            "Title": "Pathological complete response (pCR) association with a novel homologous recombination deficiency HRD signature (HRDsig) in patients with triple-negative breast cancer (TNBC) receiving neoadjuvant therapy (Tx).",
            "Indication": "Triple-negative breast cancer (TNBC)",
            "Drug": "Carboplatin, Paclitaxel, Immune checkpoint inhibitor (ICI), Doxorubicin, Cyclophosphamide",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "HRDsig(+) patients were more likely to have pCR receiving both platinum Tx (37.0% vs. 4.4%) and non-platinum Tx (23.8% vs. 5.2%).",
            "Conclusion": "In the neoadjuvant Tx setting, patients with TNBC that were HRDsig(+) were substantially more likely to achieve a pCR. There was a trend towards higher pCR rates among HRDsig(+) patients receiving platinum vs. non-platinum Tx, while this effect was not observed among HRDsig(-) patients. These hypothesis-generating results suggest that HRDsig may help identify patients who achieve additional benefit from platinum agents."
        },
        {
            "Title": "An MRI-based multi-parameter clinical decision support system (NeoMDSS) for early prediction of pathological complete response after the first cycle of neoadjuvant therapy in breast cancer: A multi-center prospective observational cohort study.",
            "Indication": "Breast cancer",
            "Drug": "Neoadjuvant therapy (NAT)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The NeoMDSS model demonstrated excellent performance in predicting pathological complete response (pCR) at 1st-NAT, with an AUC of 0.874 in the training cohort, 0.845 in the internal validation cohort, and 0.867 in the external validation cohort.",
            "Conclusion": "The NeoMDSS model serves as a convenient tool in clinical practice at 1st-NAT to calculate and accurately predict the probability of pCR after NAT. This assists clinicians in determining whether to modify the treatment plan and improving personalized treatment for patients with poor response to NAT."
        },
        {
            "Title": "Evaluating gut microbiome as a biomarker of pathological complete response in patients with early locally advanced triple negative breast cancer (LATNBC): A pilot study.",
            "Indication": "Early locally advanced triple negative breast cancer (LATNBC)",
            "Drug": "Neoadjuvant pembrolizumab and chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Grade 3-4 immune-related adverse events in 8 patients, leading to treatment discontinuation and death in one patient",
            "Other": "Significant enrichment of microbiota with genus Akkermansia HR 0.04, p 0.03 in those with pCR. Higher abundance of tryptophan metabolizing clostridium species HR 50541, p < 0.001, along with differential activation of microbial tryptophan pathway in those with residual disease.",
            "Conclusion": "This is the first analysis of differences in the microbiome and microbial metabolism in patients with LATNBC treated with NAICB. Despite being a small study, it shows a more aggressive disease pattern with higher RD than noted in KEYNOTE522 and shows significant concern for serious immune-mediated adverse effects. This highlights the importance of biomarkers to stratify patients most likely to benefit from ICB. Enrichment of microbiota and immunosuppressive tryptophan metabolites were associated with pCR and should be validated in larger studies as biomarkers of response to NA ICB in TNBC."
        },
        {
            "Title": "Impact of extent of disease at presentation on oncologic outcomes according to pathologic response to neoadjuvant systemic therapy among participants of the I-SPY2 clinical trial.",
            "Indication": "HER2+ and triple negative (TN) breast cancer",
            "Drug": "Neoadjuvant systemic therapy (NAST)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "5-year EFS for cT1-2 vs cT3-4 according to RCB class in TNBC: RCB0: 94% vs 95%, RCB1: 90% vs 65%, RCB2: 74% vs 44%, RCB3: 45% vs 20%. 5-year EFS for cT1-2 vs cT3-4 according to RCB class in HER2+: RCB0: 95% vs 88%, RCB1: 89% vs 84%, RCB2: 85% vs 64%, RCB3: 56% vs 50%.",
            "Conclusion": "Tumor size (but not N involvement) prior to NAST was independently associated with outcomes in TN and HER2+ BC with residual disease, independently of RCB class. TN and HER2+ BC achieving pCR exhibited favorable outcomes regardless of extent of disease prior to NAST. These data are consistent with prior findings of the Neobioscore contributing only in the context of residual disease."
        },
        {
            "Title": "Personalized neoadjuvant strategy in ER positive and HER2 negative breast cancer to increase BCS rate (PLATO): A prospective, multicenter, phase II clinical trial.",
            "Indication": "ER-positive and HER2-negative breast cancer",
            "Drug": "Neoadjuvant chemotherapy (NCT) or endocrine therapy (NET) guided by 70-gene assay",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "No severe adverse events beyond expectations",
            "Other": "Primary endpoint of achieving the pre-established target tumor size for BCS was reached by 70.0% of patients. Actual BCS rate was 58.9% overall. Pathologic complete response (pCR) was achieved in 2.2% (all in the NCT group).",
            "Conclusion": "For ER-positive, HER2-negative breast cancer patients initially ineligible for BCS, the use of pre-treatment multi-gene assays to guide selective NCT or NET could substantially increase BCS rates and reduce unnecessary chemotherapy."
        },
        {
            "Title": "Neoadjuvant pegylated liposomal doxorubicin-containing chemotherapy in combination with trastuzumab and pertuzumab in patients with HER2-positive early breast cancer: A randomized phase II study (METIS).",
            "Indication": "HER2-positive early breast cancer",
            "Drug": "Pegylated liposomal doxorubicin (PLD) and cyclophosphamide with trastuzumab and pertuzumab, followed by a taxane",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Only one patient in the epirubicin-containing group experienced a grade 3 decrease in left ventricular ejection fraction, while none did in the PLD-containing group. Other adverse events were similar in both groups.",
            "Other": "The PLD-containing treatment group had a higher rate of pathological complete response (67.5%, 95% CI 56.1-77.6) than the epirubicin-containing group (48.7%, 95% CI 37.0-60.4), with a rate difference of 18.8% (p=0.033).",
            "Conclusion": "A combination of PLD and cyclophosphamide with trastuzumab and pertuzumab, followed by a taxane, trastuzumab, and pertuzumab, significantly increased the rate of tpCR without more cardiotoxicity in neoadjuvant treatment for early-stage HER2-positive breast cancer patients."
        },
        {
            "Title": "Pathological complete response to predict long term outcomes in the I-SPY2 TRIAL",
            "Indication": "Stage 2/3 breast cancer",
            "Drug": "Various investigational therapies",
            "PFS": "EFS: 96% for pCR, 82% for non-pCR at 3 years; HR not specified",
            "OS": "DRFS: 96% for pCR, 84% for non-pCR at 3 years; HR not specified",
            "ORR": "None",
            "AE": "None",
            "Other": "Residual Cancer Burden (RCB) assessment was performed; increased adoption of post-neoadjuvant therapy likely contributed to improved outcomes.",
            "Conclusion": "pCR continues to have a strong positive association with EFS and DRFS. The data demonstrate the value of pCR as an early endpoint regardless of tumor type or drugs delivered."
        },
        {
            "Title": "Predictive biomarkers for pathological complete response in early-stage triple negative breast cancer following neoadjuvant chemotherapy",
            "Indication": "Early-stage triple negative breast cancer (TNBC)",
            "Drug": "Anthracyclines and taxanes",
            "PFS": "None",
            "OS": "None",
            "ORR": "30.9% achieved pCR",
            "AE": "None",
            "Other": "NLR emerged as a significant predictor of pCR; high TILs value ($50%) yielded a specificity of 89.4% for pCR.",
            "Conclusion": "NLR emerged as a significant and independent predictor of pCR in early-stage TNBC. High specificities for pCR were observed with NLR \u22641.43 and TILs \u226550%, representing cutoff values that could be explored for treatment de-escalation."
        },
        {
            "Title": "Evaluating the efficacy of neoadjuvant endocrine therapy in HER2 low vs. HER2 negative breast cancer: An NCBD analysis",
            "Indication": "Stage I-III ER-positive breast cancer",
            "Drug": "Neoadjuvant endocrine therapy (NET)",
            "PFS": "None",
            "OS": "None",
            "ORR": "HER2-low: 1.3% CR, 41.1% PR; HER2-negative: 1.0% CR, 33.1% PR",
            "AE": "None",
            "Other": "HER2-low tumors were more likely to have any response compared to HER2-negative (aOR=1.16, 95% CI 1.01-1.34, p=0.04).",
            "Conclusion": "HER2-low tumors had greater significant clinical responses to NET when compared to HER2-negative, suggesting that HER2-low tumors may benefit from tailored treatment in the neoadjuvant setting."
        },
        {
            "Title": "Ten-year outcomes of targeted axillary surgery after neoadjuvant chemotherapy in breast cancer",
            "Indication": "Node-positive breast cancer",
            "Drug": "Neoadjuvant chemotherapy (NAC)",
            "PFS": "LRFS: No significant difference between TAS and ALND groups",
            "OS": "OS: No significant difference between TAS and ALND groups",
            "ORR": "None",
            "AE": "None",
            "Other": "TAS group had significantly more postmenopausal patients and received additional axillary radiotherapy more frequently.",
            "Conclusion": "TAS is comparable to ALND in terms of 10-year oncological outcomes across different types of node-positive breast cancer, suggesting its potential as a viable alternative. Further research focusing on patient quality of life and postoperative complications is essential."
        },
        {
            "Title": "I-SPY2 Endocrine Optimization Pilot (EOP): Neoadjuvant amcenestrant +/- abemaciclib +/- letrozole in molecularly selected patients (pts) with HR+ HER2- stage 2/3 breast cancer (BC).",
            "Indication": "HR+ HER2- stage 2/3 breast cancer",
            "Drug": "amcenestrant, abemaciclib, letrozole",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Most common AEs include grade 1-2 hot flashes (69%) and fatigue (66%). Grade 3 AEs include neutropenia (2 pts) and diarrhea (1 pt) in AA.",
            "Other": "Mean MRI Functional Tumor Volume (cc) change between T0-T1, T1-T2, T2-T3, T0-T3 was -6.06 (-34.2%), -3.55 (-29.2%), -0.829 (-20.1%), -11.3 (-68.4%), respectively, similar across arms (p=0.396). ctDNA was detected in 10/59 (17%) pts at T0. ctDNA became undetectable in 6 pts, decreased in 4 pts, and went from ctDNA undetectable at baseline to detectable at T3 in 2 pts.",
            "Conclusion": "Six months of neoadjuvant endocrine therapy (NET) with the oral SERD amcenestrant is feasible, well-tolerated, and demonstrates anti-proliferative and anti-tumor activity in pre- and postmenopausal pts. NET provides a rich platform for biomarker discovery and investigating response to endocrine therapy."
        },
        {
            "Title": "Trends, patterns, and outcomes of neoadjuvant chemotherapy (NACT) use among patients with early invasive triple negative breast cancer (TNBC).",
            "Indication": "early invasive triple negative breast cancer (TNBC)",
            "Drug": "neoadjuvant chemotherapy (NACT)",
            "PFS": "None",
            "OS": "Among patients treated with NACT, treatment receipt between 2014-2017 (aOR=1.41; 95% CI 1.33\u20131.50) or 2018-2020 (aOR=1.86; 95% CI 1.76\u20131.98) was associated with higher odds of achieving pCR relative to treatment receipt between 2010-2013. Black patients were less likely (aOR=0.87; 95% CI 0.83\u20130.94) to achieve a pCR relative to White patients. Other factors associated with NACT receipt and pCR rates were age, cancer stage, comorbidity, type of insurance, income, and facility type. Among patients treated with NACT, pCR was associated with lower risk of death (aHR=0.26; 95% CI 0.24\u20130.29). Based on matched pairs, the 5-year OS in patients who achieved pCR was 94% versus 77% among patients who did not (p<0.001).",
            "ORR": "Among patients with NACT, pCR rates increased from 19.8% in 2010 to 35.6% in 2020 (p<0.001).",
            "AE": "None",
            "Other": "The rate of NACT receipt increased from 19% in 2010 to 44.7% in 2020 (p<0.001).",
            "Conclusion": "The use of NACT among patients with TNBC has dramatically increased in the last decade. We observed an increase in pCR which is likely associated with improved cancer therapy regimens and use of NACT in patients with low burden disease. Our findings highlight the importance of NACT use and the prognostic significance of pCR in this population. Although TNBC is more prevalent in Black patients, they were less likely to be treated with NACT and less likely to achieve a pCR. Additional studies are needed to explore disparities in NACT receipt and how to address these to further improve survival outcomes."
        },
        {
            "Title": "Neoadjuvant pyrotinib and trastuzumab in HER2-positive breast cancer with no early response (NeoPaTHer): Primary analysis of a prospective, multi-center, response-adapted study.",
            "Indication": "HER2-positive breast cancer",
            "Drug": "pyrotinib, trastuzumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "The pCR rates were 30.6% (95% CI: 20.6%-43.0%) in Cohort A, 15.4% (95% CI: 6.2%-33.5%) in Cohort B, and 29.3% (95% CI: 17.6%-44.5%) in Cohort C.",
            "AE": "The most common adverse events among patients who received pyrotinib were diarrhoea, anaemia, and erythropenia, and no cardiovascular event was observed.",
            "Other": "Participants with low TMB and no RTK-RAS mutation had a quite low pCR rate of 4%.",
            "Conclusion": "The addition of pyrotinib to trastuzumab increases the pCR rates among patients who had no early response to neoadjuvant trastuzumab. Further studies are warranted to identify biomarkers predicting those who may benefit from pyrotinib in addition to trastuzumab."
        },
        {
            "Title": "Clinical outcomes and efficacy of HER2-targeted therapy in breast cancer with uncommon in situ hybridization (ISH) patterns or discordant immunohistochemistry (IHC).",
            "Indication": "HER2-positive breast cancer with uncommon ISH patterns or discordant IHC",
            "Drug": "HER2-targeted therapy",
            "PFS": "None",
            "OS": "OS was superior in Stage IV HER2+ (HR 0.49, 95% confidence interval [CI] 0.45-0.53) and Group 2/IHC3+ cases (HR 0.29, 95% CI 0.10\u20130.77) receiving HER2-targeted Tx relative to HER2\u2013 cases.",
            "ORR": "HER2+ and Group 2 IHC3+ treated with HER2-targeted Tx had higher pCR rates than HER2\u2013 cases, with no pCR improvement seen in Group 3/4 ISH or discordant ISH/IHC cases.",
            "AE": "None",
            "Other": "Few pts in HER2+ subgroups did not receive HER2-directed Tx, but improved pCR with HER2-targeted Tx was seen in HER2+ cases with a trend toward improvement in Group 2 ISH cases.",
            "Conclusion": "HER2+ cases with ASCO/CAP Group 3/4 ISH results, as well as pts with discordant ISH/IHC have inferior outcomes with HER2-targeted Tx, and alternative treatment strategies for these pts incorporating agents approved for HER2\u2013 disease should be considered."
        },
        {
            "Title": "Automated prediction of response to neoadjuvant chemotherapy from digitized H&E slides of pre-treatment core needle biopsies in germline BRCA carriers with HER2-negative breast cancer (TBCRC 031)",
            "Indication": "HER2-negative breast cancer in germline BRCA carriers",
            "Drug": "Neoadjuvant cisplatin (C) vs doxorubicin-cyclophosphamide (AC)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "QPOR Immune Heterogeneity Index (IHI) identified patients with RCB0,1 with overall accuracy=65%, sensitivity=63%, specificity=66%, PPV=62%, NPV=67%. IHI combined with a proliferative index and a G1S phase deregulation index (CmbI) was predictive of RCB0,1 with improved accuracy (overall accuracy=80%, sensitivity=71%, specificity=87%, PPV=83%, NPV=77%, OR=16.5, 95% CI: 10.05-1.14, kappa=0.59, p<0.00001, AUC=0.80, 95% CI: 0.71-0.89).",
            "Conclusion": "QPOR-generated immune, proliferative, and key cell cycle signature indices individually, and more strongly in combination, appear predictive of NAC response in germline BRCA carriers with HER2-negative breast cancers."
        },
        {
            "Title": "The predictive value of the mature B cell signature in early Her2-positive breast cancer treated with neoadjuvant therapy",
            "Indication": "Early-stage Her2-positive breast cancer",
            "Drug": "Taxane in combination with Her2-targeted therapies (trastuzumab and dual-blockade with trastuzumab plus pertuzumab)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "High expression of Her2 and low expression of ESR1 were associated with the pCR (both p<0.01). Dual blockade therapy tended towards pCR, but lacked statistical significance. Higher abundance of B cells was significantly associated with the pCR (p<0.001). Mature B cells predicted the pCR (odds ratio, 2.63; 95% CI, 1.34-5.16; p=0.005). Mature B cells were independently related to superior recurrence-free survival (hazard ratio, 0.32; 95% CI, 0.11-0.98; p=0.047).",
            "Conclusion": "The mature B cell signature was more strongly associated with the pCR and recurrence-free survival than TILs."
        },
        {
            "Title": "Phase II study of neoadjuvant ipilimumab and nivolumab in combination with paclitaxel following anthracycline-based chemotherapy in patients with treatment resistant stage III triple negative breast cancer (TNBC): BCT1702\u2014Survival results",
            "Indication": "Stage III triple negative breast cancer (TNBC)",
            "Drug": "Neoadjuvant ipilimumab and nivolumab combined with paclitaxel following anthracycline-based chemotherapy",
            "PFS": "None",
            "OS": "At a median follow-up of 36.6 months, the KM estimates for OS at 12 months were 93.7%, at 24 months were 84.4%, and at 36 months were 71.9%. Patients who achieved a pCR had numerically better OS (HR 2.6; 95% CI: 0.33-21.08; p=0.34).",
            "ORR": "The pCR rate was 24.2% (95% CI, 11.09-42.26) in all evaluable patients, 44.4% in PD-L1+ patients, and 9.5% in PD-L1- patients. In suboptimal responders, the pCR rate was 25% (95% CI, 8.6-49.1).",
            "AE": "None",
            "Other": "Patients with PD-L1+ tumors vs PD-L1- had a 3-year EFS of 100% vs 52% (p=0.01) and 100% vs 67% OS (p=0.05). Results were similarly favorable for TIL high vs low.",
            "Conclusion": "The addition of ipilimumab and nivolumab to neoadjuvant chemotherapy resulted in 100% survival for high-risk patients with PD-L1+ and/or TIL% high tumors. Combination CTLA4 and PD-1 targeting agents should be further investigated in Stage 3 TNBC patients."
        },
        {
            "Title": "Nodemarking techniques and targeted axillary dissection in patients with cN1 disease undergoing neoadjuvant chemotherapy: Analysis of initial 500 participants in the ATNEC trial.",
            "Indication": "Breast cancer",
            "Drug": "Neoadjuvant chemotherapy (NACT)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "72% of patients underwent node marking, with an intra-operative identification rate of 90%. The marked node was the sentinel node in 80% of cases.",
            "Conclusion": "Successful implementation of node marking in the UK through the ATNEC trial."
        },
        {
            "Title": "Circulating tumor DNA detection after neoadjuvant chemotherapy and prediction of distant relapse free survival, local recurrence, and distant recurrence in triple-negative breast cancer in the TRICIA study.",
            "Indication": "Triple-negative breast cancer",
            "Drug": "Neoadjuvant chemotherapy (NAC), adjuvant capecitabine",
            "PFS": "None",
            "OS": "HR=0.19, p=0.0007, 95% CI=0.08\u20130.5",
            "ORR": "None",
            "AE": "None",
            "Other": "ctDNA detection at T1 is the strongest prognostic factor for both RFS (HR=0.19, p<0.0001, 95% CI=0.09\u20130.40) and OS. 21/22 (95%) patients without detectable ctDNA at T1 did not have distant disease relapse.",
            "Conclusion": "A hospital-based tumor bespoke assay provides very strong prognostic information when performed after NAC and before surgery."
        },
        {
            "Title": "Tailored axillary surgery with or without axillary lymph node dissection followed by radiotherapy in patients with clinically node-positive breast cancer (OPBC-03/SAKK23/16/IBCSG57-18/ABCSG-53/GBG-101-TAXIS).",
            "Indication": "Clinically node-positive breast cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The trial aims to demonstrate that treatment with TAS and ART is non-inferior to TAS and ALND in terms of DFS.",
            "Conclusion": "The primary endpoint analysis is planned in Q4/2030."
        },
        {
            "Title": "A phase III trial evaluating addition of adjuvant chemotherapy to ovarian function suppression + endocrine therapy in premenopausal women with pN0-1, HR+/HER2- breast cancer (BC) and oncotype recurrence score (RS) \u226425 (OFSET): NRG-BR009.",
            "Indication": "HR+/HER2- breast cancer",
            "Drug": "Adjuvant chemotherapy, ovarian function suppression (OFS), endocrine therapy (ET)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The trial will assess differences in severe menopausal symptoms, measured by the FACT ESS-19 score between arms, as well as increased pain severity (PROMIS).",
            "Conclusion": "OFSET was activated in Aug 2023."
        },
        {
            "Title": "A phase III randomized trial of radiotherapy optimization for low-risk HER2-positive breast cancer (HERO): NRG-BR008.",
            "Indication": "HER2-positive breast cancer",
            "Drug": "Trastuzumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: recurrence-free interval (RFI). Secondary endpoints: time to ipsilateral breast recurrence, locoregional recurrence, disease-free survival, overall survival, 7-year ipsilateral breast recurrence rate among those not receiving RT. Health-related quality of life sub-study to assess differences in patient-reported breast pain and worry.",
            "Conclusion": "The trial aims to evaluate the feasibility of omitting radiotherapy among patients with early-stage HER2+ breast cancer following breast-conserving surgery and appropriate systemic therapy. The trial opened to accrual in March 2023 and aims to enroll 1,300 patients over 4.5 years."
        },
        {
            "Title": "Interim FDG-PET/CT for predicting response of HER2-positive breast cancer to neoadjuvant therapy: ECOG-ACRIN EA1211/DIRECT trial.",
            "Indication": "HER2-positive breast cancer",
            "Drug": "Pertuzumab-based neoadjuvant therapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary objective: estimate the negative predictive value (NPV) of niFDG-PET/CT for pCR using DSULmaxD15 of the primary breast cancer at a threshold of 40%. Secondary endpoints: sensitivity, specificity, positive predictive value of DSULmaxD15, ability of niFDG-PET/CT biomarker to predict 3-year event-free survival (EFS).",
            "Conclusion": "The trial aims to validate FDG-PET/CT as a neoadjuvant interim imaging biomarker in patients treated with standard HER2-directed regimens. If successful, the results will be used to design clinical utility studies, potentially changing practice."
        },
        {
            "Title": "Prospective evaluation of the breast microbiome and tumor microenvironment-related biomarkers of response to neoadjuvant systemic therapy in triple negative breast cancer.",
            "Indication": "Triple negative breast cancer (eTNBC)",
            "Drug": "Neoadjuvant chemo-immunotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary objective: evaluate the change in breast cancer microbiome composition pre-and post-therapy. Secondary objectives: correlation of the breast microbiome with pathologic complete response (pCR) and survival outcomes, tumor infiltrating lymphocytes (TILs), PDL-1 and immune subset analysis, extracellular vesicles analysis, and analysis of gut microbiome.",
            "Conclusion": "The trial aims to provide deeper insight into the potential role of the local breast microbiome as a predictive biomarker for response to neoadjuvant therapy in patients with eTNBC. The results will guide further studies exploring optimal immunomodulatory combinations for the treatment of TNBC."
        },
        {
            "Title": "Assessing the feasibility and cost-effectiveness of all oral adjuvant chemotherapy (cyclophosphamide, methotrexate, capecitabine) in high-risk hormone receptor positive breast cancer.",
            "Indication": "High-risk hormone receptor positive breast cancer",
            "Drug": "Oral Cyclophosphamide, Methotrexate, Capecitabine (CMC)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary objective: assess feasibility defined as 80% of patients maintaining a relative dose intensity (RDI) \u2265 85%. Secondary objectives: assess quality of life as reported by the patients and report adverse effects.",
            "Conclusion": "The trial aims to evaluate the feasibility of oral CMC by measuring the relative dose intensity in addition to the safety and tolerability of oral CMC. The study will be stopped if > 25% of patients report Grade 4+ toxicity on oral CMC."
        },
        {
            "Title": "Phase III study to evaluate the efficacy and safety of GLSI-100 (GP2+GM-CSF) in patients with breast cancer with residual disease or high-risk PCR after both neo-adjuvant and postoperative adjuvant anti-HER2 therapy: Flamingo-01",
            "Indication": "Breast cancer with residual disease or high-risk PCR",
            "Drug": "GLSI-100 (GP2+GM-CSF)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "To determine if GP2 therapy increases invasive breast cancer free survival (IBCFS); to assess the safety profile of GP2; and to monitor immunologic responses to treatment and assess relationship to efficacy and safety.",
            "Conclusion": "The study has been initiated at a number of sites in the US and is expected to be opened in Spain, France, Germany, Italy, and Poland."
        },
        {
            "Title": "Abemaciclib dose escalation to maintain intensity (ADE-MI)",
            "Indication": "High-risk early-stage HR+ HER2- breast cancer",
            "Drug": "Abemaciclib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The primary objective of this study is to increase the proportion of patients on the FDA recommended dose of abemaciclib at 3 months of treatment. Additional objectives are determining the number of days with greater than grade 1 diarrhea in the first month of treatment, determining rates of grade 3 or greater diarrhea, determining DFS and OS, and to assess patient reported outcomes regarding adverse events.",
            "Conclusion": "The trial opened to enrollment on 12/21/2023. The first patient on the study was enrolled 1/25/2024."
        },
        {
            "Title": "RAGE inhibition to decrease cancer therapy related cardiotoxicity in women with early breast cancer (RAGE)",
            "Indication": "Early-stage breast cancer",
            "Drug": "TTP488",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary objectives are to evaluate the change in high sensitivity troponin level before and during treatment with TTP488 and to evaluate the safety and tolerability of TTP488 when administered with chemotherapy. The secondary objective is to characterize the PK and bioavailability of chemotherapy agents with TTP488.",
            "Conclusion": "As of 2/1/24, 4 patients have been enrolled, with 4 undergoing screening. At the completion of this trial, a randomized trial is planned to evaluate the role of TTP488 to decrease cardiotoxicity, cancer related cognitive decline and disease recurrence."
        },
        {
            "Title": "The SURVIVE study: Liquid biopsy guided surveillance after intermediate-to high-risk early breast cancer",
            "Indication": "Intermediate-to high-risk early breast cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The two primary objectives are to determine whether intensified, liquid biopsy-guided surveillance leads to better OS or an overall lead-time effect, compared to standard surveillance. Secondary objectives include IDFS, DDFS, DRFS, BCSS and quality of life (QoL).",
            "Conclusion": "We propose a liquid biopsy guided follow-up method, with high sensitivity and specificity for the earlier detection of distant (oligo-)metastases, to enable earlier initiation of therapy."
        },
        {
            "Title": "CINDERELLA clinical trial: Using artificial intelligence\u2013driven healthcare to enhance breast cancer locoregional treatment decisions.",
            "Indication": "Breast cancer",
            "Drug": "CINDERELLA APP (AI-driven healthcare tool)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Patient satisfaction regarding locoregional treatment aesthetic outcomes, overall quality of life, psychological well-being.",
            "Conclusion": "The trial aims to establish a new standard in patient-centered care by bridging the gap between clinical treatment options and patient expectations through innovative technology."
        },
        {
            "Title": "NRG-BR007: A phase III trial evaluating de-escalation of breast radiation (DEBRA) following breast-conserving surgery of stage 1, HR+, HER2- breast cancer.",
            "Indication": "Stage 1, HR+, HER2- breast cancer",
            "Drug": "Endocrine therapy (tamoxifen or AI)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: In-breast recurrence (IBR). Secondary endpoints: Breast conservation rate, invasive in-breast recurrence, relapse-free interval, distant disease-free survival, overall survival, patient-reported breast pain, patient-reported worry about recurrence, adherence to ET.",
            "Conclusion": "The trial hypothesizes that breast-conserving surgery alone is non-inferior to BCS plus RT for IBR and breast preservation in women intending endocrine therapy for stage 1 invasive breast cancer."
        },
        {
            "Title": "MELODY: A prospective non-interventional multicenter cohort study to evaluate different imaging-guided methods for localization of malignant breast lesions (EUBREAST-4/iBRA-NET).",
            "Indication": "Breast cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary outcomes: Intended target lesion and/or marker removal, negative resection margin rates. Secondary outcomes: Rates of second surgery and secondary mastectomy, resection ratio, duration of surgery, marker dislocation rates, rates of marker placement or localization failure, patient-reported outcomes, rates of 'lost markers', radiologist and surgeon satisfaction, health economic evaluation.",
            "Conclusion": "The study aims to evaluate different imaging-guided localization methods with regard to oncological safety, patient-reported outcomes, and surgeon and radiologist satisfaction."
        },
        {
            "Title": "A pilot study of early PET and biomarker dynamics in patients with stage 2 and 3 triple negative breast cancer treated with neoadjuvant response-adapted chemotherapy with pembrolizumab (NeoADAPT).",
            "Indication": "Stage 2 and 3 triple negative breast cancer (TNBC)",
            "Drug": "Pembrolizumab, paclitaxel/carboplatin, doxorubicin/cyclophosphamide, capecitabine",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary objective: Evaluate if lack of decrease in maximum FDG-PET standard uptake value of lean body mass (SULmax) by less than 40% after 1 cycle of neoadjuvant treatment correlates with residual disease (RD) at the time of surgery. Additional key endpoints: ctDNA clearance, evaluation of pCR and cCR rates in the treatment population, event-free survival (EFS).",
            "Conclusion": "The study aims to determine the association of early FDG-PET changes and ctDNA clearance with pCR in early-stage TNBC."
        },
        {
            "Title": "Neoadjuvant dalpiciclib, exemestane, and goserelin in premenopausal women with HR-positive, HER2-negative breast cancer: A prospective, multicenter, randomized two-group, non-controlled phase 2 clinical trial.",
            "Indication": "HR-positive, HER2-negative breast cancer",
            "Drug": "dalpiciclib, exemestane, goserelin",
            "PFS": "None",
            "OS": "None",
            "ORR": "Primary endpoint: ORR assessed by RECIST 1.1",
            "AE": "None",
            "Other": "Secondary endpoints include residual cancer burden, preoperative endocrine prognostic index, event-free survival, and safety.",
            "Conclusion": "The trial is actively recruiting. Clinical trial information: NCT06009627."
        },
        {
            "Title": "A phase II trial comparing dalpiciclib in combination with letrozole versus standard chemotherapy as neoadjuvant therapy in patients with high-risk HR-positive HER-2 negative breast cancer: DARLING-02.",
            "Indication": "High-risk HR-positive HER-2 negative breast cancer",
            "Drug": "dalpiciclib, letrozole",
            "PFS": "None",
            "OS": "None",
            "ORR": "Primary endpoint: ORR assessed by RECIST v1.1",
            "AE": "None",
            "Other": "Secondary endpoints include patient-reported outcomes, residual cancer burden rate, preoperative endocrine prognostic index score, pathological complete response rate, 2-year invasive disease-free survival rate, and safety.",
            "Conclusion": "The trial is actively recruiting. Clinical trial information: NCT06107673."
        },
        {
            "Title": "Clinical validation of image-based AI predictive biomarkers for precision neoadjuvant triple-negative breast cancer treatment (PEAR-TNBC).",
            "Indication": "Triple-negative breast cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "Primary endpoint: Accuracy of the PearBio test in predicting pCR",
            "AE": "None",
            "Other": "Secondary outcomes include sensitivity, and positive and negative predictive values.",
            "Conclusion": "The trial is actively recruiting. Clinical trial information: NCT05435352."
        },
        {
            "Title": "A window-of-opportunity (WOO) trial of giredestrant +/- LHRHa vs anastrozole + LHRHa in premenopausal women with ER+/HER2- early breast cancer (EBC; IBCSG 67-22; PREcoopERA).",
            "Indication": "ER+/HER2- early breast cancer",
            "Drug": "giredestrant, LHRHa, anastrozole",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: Change in Ki-67 labeling index between pre-treatment diagnostic tumor biopsy and post-treatment rebiopsy.",
            "Conclusion": "The trial was activated on 9 October 2023. Clinical trial information: NCT05896566."
        },
        {
            "Title": "NeoTRACT: Phase II trial of neoadjuvant tumor infiltrating lymphocyte- and response-adapted chemoimmunotherapy for triple-negative breast cancer (TNBC).",
            "Indication": "Triple-negative breast cancer (TNBC)",
            "Drug": "Carboplatin, docetaxel, pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint is pCR rate among participants with \u226530% sTILs. Secondary endpoints include residual cancer burden (RCB) rates in patients with \u226530% sTILs, pCR and RCB rates in patients with 5-29% and <5% sTILs, and event-free and overall survival for all patients.",
            "Conclusion": "Approaches to deescalate chemoimmunotherapy without compromising outcomes for patients with highly chemosensitive disease are needed. The trial investigates deescalating neoadjuvant chemoimmunotherapy based on the degree of pretreatment sTIL enrichment."
        },
        {
            "Title": "Neoadjuvant targeted therapy in early resectable HER2-mutant lobular breast cancer.",
            "Indication": "HER2-mutant lobular breast cancer",
            "Drug": "Neratinib and endocrine therapy (ET)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary objective is the pre-operative endocrine prognostic index (PEPI) score. Secondary objectives include pCR, residual cancer burden, and rates of breast-conserving surgery.",
            "Conclusion": "We hypothesize that neoadjuvant treatment with neratinib and ET will improve pathologic responses in newly diagnosed HER2-mutant HR+ ILC. Results from this study may generate a clear signal of clinical activity leading to a randomized phase III trial."
        },
        {
            "Title": "Neoadjuvant dynamic marker-adjusted personalized therapy comparing trastuzumab-deruxtecan versus pacli-/docetaxel + carboplatin + trastuzumab + pertuzumab in HER2+ early breast cancer: WSG-ADAPT-HER2-IV.",
            "Indication": "HER2+ early breast cancer",
            "Drug": "Trastuzumab-deruxtecan, paclitaxel, docetaxel, carboplatin, trastuzumab, pertuzumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Co-primary objectives are comparison of pCR rates after 12/18 weeks of neoadjuvant treatment with T-DXd vs. SOC (PAC+T+P or PAC/DOC+Carbo+T+P) in pooled risk cohorts and to evaluate whether 3-year dDFS equals or exceeds 92% in T-DXd-treated patients from both risk cohorts.",
            "Conclusion": "Antibody-drug-conjugates (e.g., trastuzumab-deruxtecan) are excellent candidates for de-escalation approaches in HER2+ EBC due to their high efficacy and low toxicity."
        },
        {
            "Title": "ETHAN: A phase II study comparing different endocrine therapies for male breast cancer.",
            "Indication": "Male breast cancer",
            "Drug": "Tamoxifen, anastrozole, degarelix, abemaciclib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint for the window phase is Ki-67 reduction from the baseline diagnostic biopsy to the research biopsy at the end of the window phase. The primary endpoint of the neoadjuvant phase is residual cancer burden (RCB) index at the time of surgery.",
            "Conclusion": "The study aims to evaluate different endocrine therapies in men with HR+ and HER2-negative breast cancer, addressing substantial knowledge gaps in the comparative efficacy, safety, and patient-reported outcomes of endocrine therapies for male breast cancer."
        },
        {
            "Title": "Abemaciclib plus fulvestrant vs fulvestrant alone for HR+, HER2- advanced breast cancer following progression on a prior CDK4/6 inhibitor plus endocrine therapy: Primary outcome of the phase 3 post MONARCH trial.",
            "Indication": "HR+, HER2- advanced breast cancer",
            "Drug": "Abemaciclib plus fulvestrant",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "Palbociclib plus exemestane with GnRH agonist vs capecitabine in premenopausal patients with HR+/HER2- metastatic breast cancer: Updated survival results of the randomized phase 2 study Young-PEARL.",
            "Indication": "HR+/HER2- metastatic breast cancer",
            "Drug": "Palbociclib plus exemestane with GnRH agonist",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "First-line inavolisib/placebo + palbociclib + fulvestrant (Inavo/Pbo+Palbo+Fulv) in patients (pts) with PIK3CA-mutated, hormone receptor-positive, HER2-negative locally advanced/metastatic breast cancer who relapsed during/within 12 months (mo) of adjuvant endocrine therapy completion: INAVO120 Phase III randomized trial additional analyses.",
            "Indication": "PIK3CA-mutated, hormone receptor-positive, HER2-negative locally advanced/metastatic breast cancer",
            "Drug": "Inavolisib + palbociclib + fulvestrant",
            "PFS": "24.0 months (95% CI, 18.6\u2013NE) vs 15.1 months (95% CI, 13.5\u201322.3); Hazard Ratio: 0.59 (95% CI, 0.42\u20130.83)",
            "OS": "None",
            "ORR": "None",
            "AE": "Hyperglycemia (Any grade: 59% vs 9%, G1\u20132: 53% vs 9%, G3: 6% vs 0%), Diarrhea (Any grade: 48% vs 16%, G1\u20132: 44% vs 16%, G3: 4% vs 0%), Rash (Any grade: 25% vs 17%, G1\u20132: 25% vs 17%), Stomatitis (Any grade: 51% vs 27%, G1\u20132: 46% vs 27%, G3: 6% vs 0%)",
            "Other": "TTFC: NE (24.8\u2013NE) vs 15.0 months (10.6\u201324.8); Hazard Ratio: 0.53 (95% CI, 0.37\u20130.78)",
            "Conclusion": "Inavo+Palbo+Fulv was associated with sustained benefit beyond disease progression, delaying chemotherapy administration, with manageable safety and tolerability that was reflected in PROs; hence, supporting it as a new standard of care."
        },
        {
            "Title": "SACI-IOHR+: A randomized phase II trial of sacituzumab govitecan with or without pembrolizumab in patients with metastatic hormone receptor-positive/HER2-negative breast cancer.",
            "Indication": "Metastatic hormone receptor-positive/HER2-negative breast cancer",
            "Drug": "Sacituzumab govitecan with or without pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "Enfortumab vedotin (EV) in triple-negative breast cancer (TNBC) and HR+/HER2- breast cancer (BC) cohorts of EV-202",
            "Indication": "Triple-negative breast cancer (TNBC) and HR+/HER2- breast cancer (BC)",
            "Drug": "Enfortumab vedotin (EV)",
            "PFS": "None",
            "OS": "None",
            "ORR": "TNBC: 19.0%, HR+/HER2-: 15.6%",
            "AE": "TNBC: Grade \u22653 TRAEs: decreased neutrophil count (7%), decreased white blood cell count (5%), increased aspartate aminotransferase (5%). Selected TRAEs of special interest: skin reactions (60%), peripheral neuropathy (26%), hyperglycemia (5%). HR+/HER2-: Grade \u22653 TRAEs: maculopapular rash (16%), pruritus and increased aspartate aminotransferase (7%), abdominal pain and erythema (4%). Selected TRAEs of special interest: skin reactions (62%), peripheral neuropathy (27%), hyperglycemia (11%).",
            "Other": "None",
            "Conclusion": "EV showed antitumor activity in heavily pretreated TNBC. Safety in both cohorts was manageable and consistent with previous reports."
        },
        {
            "Title": "Datopotamab deruxtecan (Dato-DXd) vs chemotherapy (CT) in previously treated inoperable or metastatic hormone receptor-positive, HER2-negative (HR+/HER2\u2013) breast cancer (BC): Patient-reported outcomes (PROs) from the TROPION-Breast01 study",
            "Indication": "Inoperable or metastatic hormone receptor-positive, HER2-negative (HR+/HER2\u2013) breast cancer (BC)",
            "Drug": "Datopotamab deruxtecan (Dato-DXd)",
            "PFS": "HR 0.63 [95% CI 0.52\u20120.76]; p < 0.0001",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "TTD in GHS/QoL: 3.4 vs 2.1 months (HR 0.85 [0.68\u20131.06]), physical functioning: 5.6 vs 3.5 months (HR 0.77 [0.61\u20130.99]), pain: 3.5 vs 2.8 months (HR 0.85 [0.68\u20131.07]).",
            "Conclusion": "In TROPION-Breast01, TTD was delayed in the Dato-DXd vs ICC arms for GHS/QoL, physical functioning, pain, and most other symptoms and functioning scales. PRO data complement the improvement in efficacy and manageable safety profile demonstrated with Dato-DXd vs ICC in the primary analysis, supporting Dato-DXd as a potential new, well-tolerated therapeutic option in this setting."
        },
        {
            "Title": "Trastuzumab and pertuzumab in combination with eribulin mesylate or a taxane as first-line chemotherapeutic treatment for HER2-positive, locally advanced or metastatic breast cancer: Results of a multicenter, randomized, non-inferiority phase 3 trial in Japan (JBCRG-M06/EMERALD)",
            "Indication": "HER2-positive, locally advanced or metastatic breast cancer",
            "Drug": "Trastuzumab and pertuzumab in combination with eribulin mesylate or a taxane",
            "PFS": "Median PFS: 14.0 months (study group) vs 12.9 months (control group), HR 0.96 [95% CI 0.77\u20131.20]",
            "OS": "Median OS: 65.3 months (control group), not reached (study group)",
            "ORR": "None",
            "AE": "Adverse drug reactions including grade \u22653 febrile neutropenia, edema, and diarrhea were numerically lower in the study group than in the control group (4.9% vs 8.7%, 8.5% vs 42.2%, and 36.6% vs 54.1%, respectively).",
            "Other": "None",
            "Conclusion": "This is the first study to show non-inferiority of eribulin to taxane when used in combination with dual HER2 blockade. As a less toxic but equally effective alternative to the taxane-containing regimen, eribulin combined with HP could be first-line treatment of locally advanced or metastatic HER2+ BC."
        },
        {
            "Title": "Primary results from PATRICIA cohort C (SOLTI-1303), a randomized phase II study evaluating palbociclib with trastuzumab and endocrine therapy in pretreated HER2-positive and PAM50 luminal advanced breast cancer",
            "Indication": "HER2-positive and PAM50 luminal advanced breast cancer",
            "Drug": "Palbociclib with trastuzumab and endocrine therapy",
            "PFS": "Median PFS: 9.1 vs 7.5 months, stratified HR 0.52 [95% CI 0.29-0.94]; two-sided p=0.031",
            "OS": "None",
            "ORR": "Overall response rate: 18.9% [95% CI 8.6-35.7] (Cohort C1) vs 8.3% [95% CI 1.4-28.5] (Cohort C2)",
            "AE": "Grade \u22653 adverse events occurred in 63.2% of pts in C1 and 45.5% in C2 cohort. The most frequent grade \u22653 adverse event in the experimental arm was neutropenia (55.3%).",
            "Other": "None",
            "Conclusion": "The combination of palbociclib, T, and ET showed a statistically significant improvement in PFS in patients with previously treated PAM50 luminal A or B HER2+ advanced breast cancer, as compared to TPC."
        },
        {
            "Title": "DESTINY-Breast07: Dose-expansion interim analysis of T-DXd monotherapy and T-DXd + pertuzumab in patients with previously untreated HER2+ mBC.",
            "Indication": "HER2+ metastatic breast cancer (mBC)",
            "Drug": "Trastuzumab deruxtecan (T-DXd) and pertuzumab",
            "PFS": "T-DXd: 77.3% at 12 months (80% CI 69.0, 83.7); T-DXd + P: 89.4% at 12 months (80% CI 81.9, 93.9)",
            "OS": "None",
            "ORR": "T-DXd: 77.3% (80% CI 70.0, 83.6); T-DXd + P: 82.0% (80% CI 73.1, 88.8)",
            "AE": "T-DXd: Any-grade AEs 100%, Grade \u22653 AEs 50.7%; T-DXd + P: Any-grade AEs 100%, Grade \u22653 AEs 54.0%",
            "Other": "Adjudicated drug-related interstitial lung disease (ILD)/pneumonitis: T-DXd 8.0%, T-DXd + P 10.0%; confirmed ORR: T-DXd 77.3%, T-DXd + P 82.0%",
            "Conclusion": "Safety profiles were consistent with the known profiles for T-DXd and P, with no Grade \u22653 ILD events. Early data showed promising efficacy in both modules. The DESTINY-Breast07 study is ongoing; analyses from the Phase 3 DESTINY-Breast09 clinical trial will provide definitive data on T-DXd + P in 1L HER2+ mBC."
        },
        {
            "Title": "On-treatment (tx) dynamic circulating tumor DNA changes (\u0394ctDNA) associated with progression-free survival (PFS) and overall survival (OS) of patients (pts) with HR+/HER2- advanced breast cancer (ABC) in MONALEESA-3 (ML-3).",
            "Indication": "HR+/HER2- advanced breast cancer (ABC)",
            "Drug": "Ribociclib (RIB) and fulvestrant",
            "PFS": "C1-/C4-: 37.5 months; C1+/C4-: 22.4 months; C1-/C4+: 16.6 months; C1+/C4+: 7.5 months",
            "OS": "C1-/C4-: Not reached; C1+/C4-: 64.5 months; C1-/C4+: 40.4 months; C1+/C4+: 29.5 months",
            "ORR": "None",
            "AE": "None",
            "Other": "Hazard ratios for PFS: C1-/C4-: 0.29 (0.22\u20130.38); C1+/C4-: 0.41 (0.33\u20130.52); C1-/C4+: 0.56 (0.36\u20130.88); Ref: 1.0. Hazard ratios for OS: C1-/C4-: 0.23 (0.17\u20130.31); C1+/C4-: 0.34 (0.26\u20130.44); C1-/C4+: 0.60 (0.37\u20130.99); Ref: 1.0.",
            "Conclusion": "On-tx \u0394ctDNA from C1 to C4 was correlated with PFS/OS even after adjusting for tumor size changes, suggesting that ctDNA and tumor dynamics capture distinct aspects of tumor tx responses. Decreases in ctDNA were greater with RIB vs PBO, mirroring ML-3 efficacy readouts but at a much earlier time point. This longitudinal analysis of ctDNA in ML-3 suggests that detecting \u0394ctDNA is a sensitive way to monitor progression and may be a potential surrogate for efficacy in HR+/HER2- ABC."
        },
        {
            "Title": "Comparison of antitumor efficacy of first-line palbociclib, ribociclib, or abemaciclib in patients with HR+/HER2- aBC: Results of the multicenter, real-world, Italian study PALMARES-2.",
            "Indication": "HR+/HER2- advanced Breast Cancer (aBC)",
            "Drug": "Palbociclib, Ribociclib, Abemaciclib",
            "PFS": "Median rwPFS in the whole study cohort was 34.9 months (95% CI 32.0-37.4). Abemaciclib and Ribociclib were independently associated with better rwPFS when compared to Palbociclib (aHR 0.81, 95% CI 0.65-0.99, p=0.048 for Ribociclib vs. Palbociclib; aHR 0.71, 95% CI 0.56-0.90, p=0.005 for Abemaciclib vs. Palbociclib).",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Other covariates independently associated with rwPFS: Age (continuous) aHR 0.98 (95% CI 0.98-0.99), p=0.003; ER expression (continuous) aHR 0.98 (95% CI 0.98-0.99), p<0.001; PgR expression (continuous) aHR 0.99 (95% CI 0.98-0.99), p=0.007; Endocrine Resistance (yes vs. not) aHR 2.45 (95% CI 2.01-2.98), p<0.001; ECOG PS (0 vs. 1-2) aHR 1.44 (95% CI 1.18-1.77), p<0.001; Liver Metastasis (yes vs. not) aHR 1.95 (95% CI 1.59-2.39), p<0.001.",
            "Conclusion": "The real-world study PALMARES-2 revealed different antitumor efficacy of individual CDK4/6i in HR+/HER2- aBC patients. Longer follow-up is required to study if Palbociclib, Ribociclib, and Abemaciclib are associated with different overall survival."
        },
        {
            "Title": "H3B-6545 in women with locally advanced/metastatic estrogen receptor-positive (ER+), HER2 negative (\u2013) breast cancer (BC).",
            "Indication": "ER+, HER2- advanced/metastatic Breast Cancer (BC)",
            "Drug": "H3B-6545",
            "PFS": "Median PFS (95% CI) was 5.06 (3.15\u20137.26) months.",
            "OS": "Among all pts treated at 450 mg (n=115), median OS (95% CI) was 21.52 (16.56\u201325.46) months.",
            "ORR": "ORR was 20.2% (95% CI 12.6\u201329.8).",
            "AE": "100% and 50.3% of pts had any grade and G\u22653 treatment-emergent adverse events (TEAEs), respectively. TEAEs leading to drug interruption, dose reduction, and withdrawal occurred in 38.4%, 20.5%, and 8.6% of pts, respectively. Nausea (45.7% [2.0% G\u22653]), sinus bradycardia (44.4% [0% G\u22653]), and diarrhea (41.1% [1.3% G\u22653]) were the most common TEAEs. QT prolongation occurred in 9.9% [3.3% G\u22653] of pts.",
            "Other": "Clinical benefit rate (CBR) was 41.5% (95% CI 31.4\u201352.1); median duration of response (mDOR) was 9.23 (5.88\u201320.24) months.",
            "Conclusion": "H3B-6545 had a low rate of G\u22653 TEAEs and showed clinically meaningful antitumor activity in heavily pretreated female patients with ER+, HER2\u2013 BC."
        },
        {
            "Title": "Cognitive function in patients with HR+ advanced breast cancer treated with endocrine therapy with or without CDK4/6 inhibitors in the SONIA trial.",
            "Indication": "HR+ advanced Breast Cancer",
            "Drug": "Endocrine therapy with or without CDK4/6 inhibitors",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Patients in both study arms and the controls showed limited decline in cognitive function over the 9-month interval. Minimal differences in cognitive change were observed between the patients treated with and without CDK4/6 inhibitors, and between patients and the controls.",
            "Conclusion": "HR+ advanced breast cancer patients show worse cognitive function on all cognitive domains compared to a control group without cancer. Nine months of treatment with endocrine therapy with or without CDK4/6 inhibitors does not further worsen cognitive function in this study."
        },
        {
            "Title": "ACE-Breast-02: A pivotal phase II/III trial of ARX788, a novel anti-HER2 antibody-drug conjugate (ADC), versus lapatinib plus capecitabine for HER2+ advanced breast cancer (ABC).",
            "Indication": "HER2+ advanced breast cancer",
            "Drug": "ARX788",
            "PFS": "11.33 months (ARX788) vs 8.25 months (LC), HR 0.64, p=0.0006",
            "OS": "None",
            "ORR": "63.8% (ARX788) vs 52.7% (LC), p=0.0186",
            "AE": "TRAEs of any grade: 98.6% (ARX788) vs 99.1% (LC); Grade 3-5 TRAEs: 41.4% (ARX788) vs 40.0% (LC); Common AEs: blurred vision (12.3%), dry eye (9.1%), keratopathy (5.9%), interstitial lung disease (5.9%) with ARX788; hand-foot syndrome (18.1%), hypokalemia (5.1%), diarrhea (4.2%) with LC",
            "Other": "Interstitial lung disease: 32.3% (ARX788), primarily grade 1 or 2 (26.8%); Ocular events: 74.5% (ARX788), primarily grade 1 or 2 (55.5%)",
            "Conclusion": "ARX788 significantly prolonged PFS compared to LC in patients with HER2+ ABC previously treated with trastuzumab and taxane. While ocular toxicity and interstitial lung disease were common and manageable, its hematological and GI toxicities under no prophylactic premedication compared favorably with already available ADCs."
        },
        {
            "Title": "TBCRC 048 (olaparib expanded) expansion cohorts: Phase 2 study of olaparib monotherapy in patients (pts) with metastatic breast cancer (MBC) with germline (g) mutations in PALB2 or somatic (s) mutations in BRCA1 or BRCA2.",
            "Indication": "Metastatic breast cancer (MBC)",
            "Drug": "Olaparib",
            "PFS": "Cohort 1a (gPALB2): 9.6 months (90% CI: 8.3-12.4); Cohort 2a (sBRCA1/2): 5.6 months (90% CI: 3.0-8.3)",
            "OS": "None",
            "ORR": "Cohort 1a (gPALB2): 75% (80% CI: 60.2%-86.3%); Cohort 2a (sBRCA1/2): 36.7% (80% CI: 24.8%-50%)",
            "AE": "None",
            "Other": "CBR at 18 weeks: Cohort 1a (gPALB2): 83.3% (90% CI: 65.8%-94.1%); Cohort 2a (sBRCA1/2): 53.3% (90% CI: 37%-69.2%)",
            "Conclusion": "The notable ORR (75%) with olaparib in this second cohort of women with MBC and gPALB2m was again demonstrated. Olaparib activity in MBC pts with sBRCAm was also confirmed, though identifying predictors of response for these pts is of critical importance to distinguish those more likely to respond to PARP inhibition."
        },
        {
            "Title": "Phase 1/2 trial (NCT03328026) of Bria-IMT in metastatic/advanced breast cancer evaluating sequencing of SV-BR-1-GM inoculation and CPI.",
            "Indication": "Metastatic/advanced breast cancer",
            "Drug": "Bria-IMT regimen (SV-BR-1-GM, checkpoint inhibitors: pembrolizumab or retifanlimab)",
            "PFS": "Patients treated with phase III formulation: 4.1 months",
            "OS": "Patients treated with phase III formulation: 13.3 months",
            "ORR": "Patients treated with phase III formulation: 13%",
            "AE": "AEs related to IPs seen in 74% of patients; majority (91%) being grade 1-2, few (1.7%) grade 4 cases including pulmonary embolism, dehydration, and increased lipase.",
            "Other": "Disease control rate (DCR) for patients treated with phase III formulation: 61%",
            "Conclusion": "BRIA-IMT has shown a favorable safety profile while providing benefits to heavily treated aMBC patients. Superior outcomes and survival were seen in patients treated with the phase III formulation and regimen now being used in the ongoing Ph3 trial, confirming the improvement in efficacy of the novel regimen."
        },
        {
            "Title": "Pooled analysis by best confirmed response to trastuzumab deruxtecan (T-DXd) in patients (pts) with HER2+ metastatic breast cancer (mBC) from DESTINY-Breast-01, -02, and -03.",
            "Indication": "HER2+ metastatic breast cancer (mBC)",
            "Drug": "Trastuzumab deruxtecan (T-DXd)",
            "PFS": "CR: NE (37.9-NE); PR: 21.8 months (18.1-25.0); SD/PD: 8.4 months (6.6-9.7)",
            "OS": "CR: NE (99.2-NE); PR: 40.5 months (35.7-NE); SD/PD: 23.6 months (20.3-27.8)",
            "ORR": "Overall: 63.2%",
            "AE": "Discontinuation: 13.9%; Interruption: 53.6%; Reduction: 48.2%",
            "Other": "DoR: CR: NE (35.2-NE); PR: 16.9 months (14.3-20.7); SD/PD: N/A",
            "Conclusion": "Pts treated with T-DXd benefited from durable responses resulting in prolonged PFS and OS, especially those with a CR. Despite longer treatment duration in responders, safety remained generally manageable and did not result in a higher percentage of pts with TEAEs over time. These results further support the use of T-DXd across broad pt groups with HER2+ mBC."
        },
        {
            "Title": "Real world experience of trastuzumab deruxtecan for the treatment of metastatic breast cancer in the UK.",
            "Indication": "HER2+ metastatic breast cancer (mBC)",
            "Drug": "Trastuzumab deruxtecan (T-DXd)",
            "PFS": "None",
            "OS": "None",
            "ORR": "Overall: 63%",
            "AE": "Discontinuation: 13.9%; Interruption: 53.6%; Reduction: 48.2%",
            "Other": "None",
            "Conclusion": "The real-world dataset is not directly comparable to either the DB02 or DB03 trials, as it included patients who had received TDXd in both the second line and later line settings. However, the majority (80%) of these patients were treated in the third line and beyond. The rates of drug interruption and reduction were higher in the real-world dataset than in DB02, which may impact on efficacy in the real-world setting. Overall response rate is similar between trial data and our real-world data, although the latter was not measured by RECIST. Data collection is ongoing, including an analysis of real-world progression free survival."
        },
        {
            "Title": "Trastuzumab deruxtecan (T-DXd) vs trastuzumab emtansine (T-DM1) in patients with HER2+ metastatic breast cancer (mBC): Updated survival results of DESTINY-Breast03",
            "Indication": "HER2+ metastatic breast cancer",
            "Drug": "Trastuzumab deruxtecan (T-DXd) vs trastuzumab emtansine (T-DM1)",
            "PFS": "29.0 months (T-DXd) vs 7.2 months (T-DM1); HR 0.30 (95% CI 0.24-0.38)",
            "OS": "52.6 months (T-DXd) vs 42.7 months (T-DM1); HR 0.73 (95% CI 0.56-0.94)",
            "ORR": "None",
            "AE": "Any-grade TEAEs: 99.6% (T-DXd) vs 95.4% (T-DM1); Grade \u22653 TEAEs: 74.6% (T-DXd) vs 53.6% (T-DM1)",
            "Other": "mPFS2: 45.2 months (T-DXd) vs 23.1 months (T-DM1); mDoR: 30.5 months (T-DXd) vs 17.0 months (T-DM1)",
            "Conclusion": "The superiority of T-DXd over T-DM1 was reinforced in this long-term analysis, as observed by clinically meaningful improvement in OS, PFS, and PFS2, consistent with prior DCO. The safety profile of T-DXd continues to be manageable with no cumulative toxicities observed with longer follow-up."
        },
        {
            "Title": "Preliminary safety and efficacy of TQB2930, a HER2-targeted bispecific antibody in patients with advanced breast cancer: Results from a phase 1b study",
            "Indication": "Advanced breast cancer",
            "Drug": "TQB2930",
            "PFS": "5.5 months (95% CI 3.58-NE)",
            "OS": "None",
            "ORR": "25.8% (8 partial responses, including 5 confirmed PRs and 3 PRs awaiting confirmation)",
            "AE": "Infusion reaction, occult blood of urine, leukopenia, and neutropenia; Grade \u22653 TRAEs: neutropenia, anemia, and hypokalemia",
            "Other": "Disease control rate: 80.6%; Pharmacokinetic analysis showed exposure (Cmax and AUC0-t) of TQB2930 increased linearly with doses within 10~30mg/kg",
            "Conclusion": "TQB2930 is well tolerated and has demonstrated promising single-agent anti-tumor activity in HER2-positive breast cancer who have failed standard anti-HER2 therapies, including multiple lines of prior HER2 targeted agents. These early signs of activity support the further exploration of combination therapy of TQB2930 and the study is ongoing."
        },
        {
            "Title": "Imlunestrant, an oral selective estrogen receptor degrader (SERD), in combination with human epidermal growth factor receptor 2 (HER2) directed therapy, with or without abemaciclib, in estrogen receptor (ER) positive, HER2 positive advanced breast cancer (aBC): EMBER phase 1a/1b study",
            "Indication": "ER+, HER2+ advanced breast cancer",
            "Drug": "Imlunestrant + trastuzumab \u00b1 abemaciclib or pertuzumab",
            "PFS": "Part C: 5.3 months (95% CI 1.9, 6.6) for imlunestrant + trastuzumab; 6.7 months (95% CI 2.7, 12.4) for imlunestrant + trastuzumab + abemaciclib; Part E: 15.8 months (95% CI 8.3, NA) for imlunestrant + trastuzumab + pertuzumab",
            "OS": "None",
            "ORR": "Part C: 44% for imlunestrant + trastuzumab; 48% for imlunestrant + trastuzumab + abemaciclib; Part E: 50% for imlunestrant + trastuzumab + pertuzumab",
            "AE": "Common TEAEs: fatigue (33%) and nausea (28%) for imlunestrant + trastuzumab; diarrhea (100%), neutropenia (57%), fatigue (52%) and nausea (48%) for imlunestrant + trastuzumab + abemaciclib; diarrhea (50%) and arthralgia (50%) for imlunestrant + trastuzumab + pertuzumab; Grade \u22653 TRAEs: 6% for imlunestrant + trastuzumab; 43% for imlunestrant + trastuzumab + abemaciclib; 0% for imlunestrant + trastuzumab + pertuzumab",
            "Other": "CBR (CR + PR or SD \u226524 weeks): 44% for imlunestrant + trastuzumab; 48% for imlunestrant + trastuzumab + abemaciclib; 50% for imlunestrant + trastuzumab + pertuzumab",
            "Conclusion": "Imlunestrant in combination with trastuzumab \u00b1 abemaciclib or pertuzumab was well tolerated, showed no drug-drug interactions, and demonstrated preliminary antitumor activity in patients with ER+/HER2+ aBC."
        },
        {
            "Title": "Use of liquid biomarker thymidine kinase activity (TKa) to predict outcome and progression in patients with metastatic breast cancer (MBC) in the GEICAM/2014-12 FLIPPER trial",
            "Indication": "Metastatic breast cancer",
            "Drug": "Fulvestrant + Palbociclib vs Fulvestrant + Placebo",
            "PFS": "Low BL TKa: 30.5 months vs 19.8 months in high BL TKa; HR 1.62 (95% CI 1.11-2.35, p=0.011)",
            "OS": "HR 1.81 (95% CI 1.15-2.85, p=0.0099) for low BL TKa vs high BL TKa",
            "ORR": "HR 2.58 (95% CI 1.38-4.81, p=0.0029) for low BL TKa maintained through week 12-48",
            "AE": "None",
            "Other": "Higher TKa at BL and at 12 weeks was detected in patients that progressed before 12 months; Low BL TKa maintained throughout week 12-48 was associated with objective response and OS; At progression, patients on Fulvestrant + Palbociclib tended to have higher TKa levels than Fulvestrant + Placebo (246 vs. 138 DuA)",
            "Conclusion": "Measuring blood TKa levels can monitor and predict efficacy of Fulvestrant + Palbociclib at BL and during therapy in MBC. TKa dynamics before and during therapy are associated with PFS and OS. Future studies will provide additional knowledge on the utility of TKa in MBC."
        },
        {
            "Title": "Plasma mutational burden in PIK3CA and TP53 independently predicts early progression in patients with HR+/HER2- metastatic breast cancer (MBC) enrolled in the GEICAM/2014-12 FLIPPER trial.",
            "Indication": "HR+/HER2- metastatic breast cancer (MBC)",
            "Drug": "Fulvestrant (F) + CDK4/6 inhibitor (CDK4/6i) Palbociclib (Pa) vs. Fulvestrant (F) + Placebo (Pl)",
            "PFS": "31.8 vs. 22.0 months, HR 3.6 (95% CI 2.25-5.76, p<0.0001)",
            "OS": "HR 2.92 (95% CI 1.66-5.12, p=0.002)",
            "ORR": "None",
            "AE": "None",
            "Other": "Having \u22652 mutations in PIK3CA + TP53 was predictive of early progression (OR 22.32; 95% CI 4.12-120.9, p<0.0001) and worse mOS (61.8 vs. 34.79 months) in the F+Pl arm (HR 5.91; 95% CI 2.65-13.16, p<0.0001) but not in the F+Pa arm.",
            "Conclusion": "Baseline plasma mutational burden in both PIK3CA and TP53 genes was significantly associated with Fulvestrant +/- Palbociclib treatment outcome in HR+/HER2- MBC and appeared to be predictive of Palbociclib benefit."
        },
        {
            "Title": "Myosteatosis on computed tomography as a predictor of therapeutic response to CDK4/6 inhibitors plus aromatase inhibitors in patients with advanced breast cancer.",
            "Indication": "Hormone receptor-positive, HER2-negative advanced breast cancer (ABC)",
            "Drug": "CDK4/6 inhibitors plus aromatase inhibitors",
            "PFS": "27.2 vs. 51.1 months, adjusted HR 1.84, p=0.003",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Low SMD was an independent predictor of worse PFS after adjusting for age and visceral metastases (adjusted HR=1.20 per standard deviation decrement, p=0.041).",
            "Conclusion": "CT-derived myosteatosis predicts poor treatment outcomes in patients with ABC undergoing first-line treatment with aromatase inhibitors and CDK4/6i."
        },
        {
            "Title": "The subgroup analysis by age and geriatric assessment in a phase III study comparison of trastuzumab emtansine with trastuzumab, pertuzumab, and docetaxel in older patients with advanced stage HER2-positive breast cancer (JCOG1607 HERB TEA study).",
            "Indication": "Advanced stage HER2-positive breast cancer",
            "Drug": "Trastuzumab emtansine (T-DM1) vs. trastuzumab, pertuzumab, and docetaxel (HPD)",
            "PFS": "11.3 months for T-DM1 and 15.6 months for HPD, HR 1.373 (95% CI, 0.917-2.056)",
            "OS": "HR 1.263 (95% CI, 0.677-2.357)",
            "ORR": "None",
            "AE": "None",
            "Other": "Subgroup analysis showed HR of T-DM1 arm to HPD arm was 0.749 (95% CI: 0.310-1.808) in the group aged <75 years, while 2.867 (95% CI: 1.018-8.069) in the group aged \u226575 years.",
            "Conclusion": "No subgroups benefiting from T-DM1 as first-line treatment were identified. HPD remains the standard for advanced HER2-positive breast cancer, regardless of age (even in \u226575 years), G8 score, or IADL."
        },
        {
            "Title": "Trastuzumab deruxtecan in patients with breast cancer with brain metastases: The DE-REAL study.",
            "Indication": "HER2-positive breast cancer with brain metastases (BMs)",
            "Drug": "Trastuzumab deruxtecan (T-Dxd)",
            "PFS": "Median PFS was 15.5 months (13.7-17.3) for responders vs. 7.9 months (6.4-9.5) for non-responders, p<0.001",
            "OS": "Intracranial OS was not reached, with an overall rate of 76.6% of patients alive at 12 months.",
            "ORR": "Overall intracranial response rate (icRR) was 59% (43.5-74.4)",
            "AE": "Toxicities were in line with what was previously reported in larger registered clinical trials.",
            "Other": "Intracranial disease control rate (icDCR) was 94.9% (87.9-100.0), duration of response (icDoR) was 11.9 months (10.1-13.7), and clinical benefit rate (icCBr) at 6 and 12 months were 69.2% and 59%, respectively.",
            "Conclusion": "Our real-world analysis indicated a clinically meaningful intracranial activity of T-Dxd in patients with HER2-positive BC and BMs, and supports its use for both patients with treated/stable and untreated/active lesions."
        },
        {
            "Title": "Safety and efficacy of trastuzumab deruxtecan for metastatic HER2+ and HER2-low breast cancer: An updated systematic review and meta-analysis of clinical trials",
            "Indication": "Metastatic HER2+ and HER2-low breast cancer",
            "Drug": "Trastuzumab deruxtecan (T-DXd)",
            "PFS": "Significantly improved PFS (OR 0.37, 95% CI 0.27\u20130.52)",
            "OS": "None",
            "ORR": "None",
            "AE": "Increased risk of anemia (OR 2.10, 95% CI 1.36\u20133.25), fatigue (OR 1.56, 95% CI 1.21\u20132.02), nausea (OR 6.42, 95% CI 4.37\u20139.42), vomiting (OR 6.21, 95% CI 3.14\u201312.25), constipation (OR 2.26, 95% CI 1.53\u20133.34), and drug-related interstitial lung disease (ILD) (OR 10.89, 95% CI 3.81\u201331.12)",
            "Other": "None",
            "Conclusion": "T-DXd shows significant efficacy in treating metastatic HER2+ and HER2-low breast cancer, offering a valuable therapeutic option for patients with advanced disease. However, the treatment is associated with notable adverse events, including a heightened risk of ILD. These findings underscore the need for careful patient selection, monitoring, and management strategies to mitigate risks. Future research should focus on optimizing treatment protocols and exploring strategies to enhance safety profiles."
        },
        {
            "Title": "[68Ga]Ga-ABY-025 PET in HER2-positive breast cancer: Benefits and pitfalls in staging of axillary disease",
            "Indication": "HER2-positive breast cancer",
            "Drug": "[68Ga]Ga-ABY-025 PET",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "[68Ga]Ga-ABY-025 PET provided additional information that, in conjunction with [18F]-FDG PET findings, might help in accurate staging, particularly in cases with very small lymph node metastases. It also helped reduce false positive results as [68Ga]Ga-ABY-025 uptake was so far not seen in any benign inflammatory lesions. Physiological uptake can be seen in thyroid and apocrine glandular tissues but gradually disappear with subsequent HER2-targeted therapies."
        },
        {
            "Title": "Comparative survival analysis of patients with HER2-low and HER2-positive metastatic breast cancer with or without brain metastases treated with trastuzumab deruxtecan",
            "Indication": "HER2-low and HER2-positive metastatic breast cancer with or without brain metastases",
            "Drug": "Trastuzumab deruxtecan (T-DXd)",
            "PFS": "None",
            "OS": "HER2+ BM pts had a median overall survival (mOS) of 9.3 months [CI: 6.79-11.88], compared to 4.3 months [CI: 1.89-6.82] in the HER2-low pts. Without BM, HER2+ pts had an mOS of 10.7 months [CI: 0.71-20.82], compared to 6 months [CI: 3.47-8.67] in the HER2-low pts.",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "In this retrospective study of heavily pretreated MBC patients with multiple prior lines of therapy, pts with HER2+ MBC with and without BM had a longer mOS compared to those with HER2-low disease, suggesting HER2 expression as a key determinant of T-DXd efficacy. Poor survival in MBC patients without BM was attributed to pre-treatment with multiple lines of therapy. Future research should explore combination therapies and predictive biomarkers while conducting clinical trials to optimize treatment strategies for HER2+ metastatic breast cancer patients."
        },
        {
            "Title": "A phase I/II trial of palbociclib, pembrolizumab, and endocrine therapy for patients with HR+/HER2- locally advanced or metastatic breast cancer (MBC): Clinical outcomes and stool microbial profiling.",
            "Indication": "HR+/HER2- locally advanced or metastatic breast cancer (MBC)",
            "Drug": "palbociclib, pembrolizumab, and endocrine therapy (ET)",
            "PFS": "Median PFS across cohorts 2 and 3 (reflecting 1L therapy) was 25 months (95% CI 21-not reached)",
            "OS": "At a median follow-up of 35 months, OS was 76% (95% CI 0.60-0.96)",
            "ORR": "The ORR in cohorts 1, 2, and 3 were 2/4 (50%), 11/19 (58%), and 13/17 (76%), respectively",
            "AE": "The most common grade 3/4 adverse events across all cohorts included neutropenia (83%), leukopenia (56%), elevated alanine aminotransferase (13%), elevated aspartate aminotransferase (13%), and thrombocytopenia (10%)",
            "Other": "Gut microbial profiling demonstrated multiple species higher in abundance in pts with a response (p < 0.05), with notable species belonging to the families Lachnospiraceae, Ruminococcaceae, and Rikenellaceae. Several metabolic pathways were enriched in responders (p-value < 0.05), including pathways related to fatty acid biosynthesis and the tricarboxylic acid (TCA) cycle.",
            "Conclusion": "Pembrolizumab added to palbociclib + ET in the 1L setting demonstrated a higher response rate compared to palbociclib + ET alone in HR+/HER2- MBC. Specific gut profiles were associated with response, with similar taxa of bacteria associated with ICI response in other tumor types. Enriched metabolic pathways may generate microbiota-derived metabolites that have an immunomodulatory effect. These results suggest that the gut microbiome may be associated with response to ICI combination therapy in MBC, which warrants further evaluation."
        },
        {
            "Title": "TBCRC041: A phase II study of alisertib in endocrine resistant metastatic breast cancer (MBC).",
            "Indication": "Endocrine resistant metastatic breast cancer (MBC)",
            "Drug": "alisertib and fulvestrant",
            "PFS": "Pts with a PIK3CA mutation had significantly decreased PFS (HR 1.8; 95% CI: 1.1-2.9, p=0.014). EOC1-PFS was worse in pts with mTF > 1% (HR 3.0; 95% CI: 1.6-5.2, p < 0.001). EOC1-PFS was not significantly different for EOC1-CTC \u2265 5 (p=0.065), but was significantly decreased in pts with PreC1-CTC \u2265 5 (HR=1.8; 95% CI: 1.1-3.0; p=0.018).",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Among patients receiving A6F, PFS varied based on the PIK3CA mutation status. cfDNA and CTC assessment PreC1 and EOC1 provided complementary information. Further validation in larger datasets is needed."
        },
        {
            "Title": "Utility of circulating tumor DNA (ctDNA) to inform treatment of patients with metastatic breast cancer.",
            "Indication": "Metastatic Breast Cancer (mBC)",
            "Drug": "Not specified",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "High rate of ctDNA TF detection in patients with mBC highlights the value of lbx for CGP in this setting for detecting multiple alteration classes across mBC subtypes and metastatic sites.",
            "Conclusion": "A high rate of ctDNA TF detection in patients with mBC highlights the value of lbx for CGP in this setting for detecting multiple alteration classes across mBC subtypes and metastatic sites."
        },
        {
            "Title": "Evaluating metrics of circulating tumor DNA response and progression using a high sensitivity tumor-agnostic assay in metastatic HR+/HER2- breast cancer receiving endocrine therapy and a CDK4/6-inhibitor.",
            "Indication": "Metastatic HR+/HER2- Breast Cancer",
            "Drug": "Endocrine Therapy and CDK4/6-inhibitor",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "ctDNA dynamics are associated with clinical outcomes in HR+/HER2- mBC. The use of a high sensitivity assay permits assessment of response in nearly all patients and suggests the possibility that radiographic surveillance could be reduced in patients with ctDNA-R.",
            "Conclusion": "ctDNA dynamics are associated with clinical outcomes in HR+/HER2- mBC. The use of a high sensitivity assay permits assessment of response in nearly all patients and suggests the possibility that radiographic surveillance could be reduced in patients with ctDNA-R."
        },
        {
            "Title": "A phase Ib study of PI3Ka inhibitor risovalisib mesylate in combination with fulvestrant in patients with PIK3CA-mutated, HR+/HER2- advanced breast cancer",
            "Indication": "PIK3CA-mutated, HR+/HER2- advanced breast cancer",
            "Drug": "Risovalisib mesylate in combination with fulvestrant",
            "PFS": "Median PFS: 10.91 months for all patients, 10.97 months for patients receiving RP2D, 7.92 months for patients with prior CDK4/6i treatment at RP2D",
            "OS": "None",
            "ORR": "Confirmed ORR: 17.9% for all patients, 9.5% for patients receiving RP2D",
            "AE": "Grade 3 TRAEs: hyperglycaemia (17.3%), rash (15.4%), stomatitis (13.5%), weight decreased (7.7%), decreased appetite (5.8%), diarrhoea (5.8%), hypokalaemia (5.8%)",
            "Other": "CBR: 41.0% for all patients, 47.6% for patients receiving RP2D",
            "Conclusion": "CYH33+fulvestrant exhibited a manageable safety profile and encouraging preliminary anti-tumor efficacy in patients with PIK3CA-mutated, HR+/HER2- ABC who progressed on/after ET and CDK4/6i treatments."
        },
        {
            "Title": "Efficacy of alpelisib (ALP) + fulvestrant (FUL) with rational use of metformin (MET) in patients with HR+/HER2- advanced breast cancer (ABC)",
            "Indication": "HR+/HER2- advanced breast cancer",
            "Drug": "Alpelisib (ALP) + fulvestrant (FUL) with metformin (MET)",
            "PFS": "Median PFS: 7.0 months",
            "OS": "None",
            "ORR": "None",
            "AE": "Any grade HG: 62%, Grade 3 HG: 13%, No cases of Grade 4 HG, 10% required ALP dose reduction to 250mg due to Grade 3 HG, 2% discontinued ALP due to HG",
            "Other": "Prophylactic use of metformin allowed maintenance of full dose of ALP in most patients, increasing the efficacy of ALP. Low risk group (33%) of patients did not need prophylactic use of metformin.",
            "Conclusion": "Rational use of metformin in patients with moderate and high risk of hyperglycemia reduces the frequency and severity of hyperglycemia, decreases the rate of ALP dose reductions, and as a result may increase the efficacy of ALP. Patients with low risk of hyperglycemia do not need metformin for hyperglycemia prevention."
        },
        {
            "Title": "Entinostat, a class I selective histone deacetylase inhibitor, plus exemestane for Chinese patients with hormone receptor-positive advanced breast cancer: An overall survival update and long-term safety from the randomized, double-blind, placebo-controlled, phase 3 trial.",
            "Indication": "Hormone receptor-positive advanced breast cancer",
            "Drug": "Entinostat + Exemestane",
            "PFS": "6.32 months (HR 0.76, 95% CI 0.58-0.98, p=0.046)",
            "OS": "38.39 months (HR 0.837, 95% CI 0.624-1.124, p=0.237)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The combination of entinostat with exemestane has provided clinically meaningful OS benefit in addition to the previously observed PFS benefit over exemestane alone in patients with pre-treated, ET-resistant ABC. These findings further support entinostat as an effective and safe treatment option for the specific patient population."
        },
        {
            "Title": "H3B-6545 + palbociclib in patients (pts) with locally advanced/metastatic estrogen receptor-positive (ER+), HER2 negative (\u2013) breast cancer (BC).",
            "Indication": "Locally advanced/metastatic ER+, HER2- breast cancer",
            "Drug": "H3B-6545 + Palbociclib",
            "PFS": "7.2 months",
            "OS": "None",
            "ORR": "23.1% (95% CI 9.0\u201343.6)",
            "AE": "90.3% had Grade \u22653 TEAEs, 32.3% had serious TEAEs, 6.5% had TEAEs leading to drug withdrawal. Most frequent TEAEs: Sinus bradycardia (67.7% [Grade \u22653, 0%]), anemia (54.8% [Grade \u22653, 19.4%]), QT prolongation (19.4% [Grade \u22653, 12.9%]), rash (16.1% [Grade \u22653, 9.7%]).",
            "Other": "None",
            "Conclusion": "H3B-6545 300mg + palbo (100/125mg) had manageable safety and encouraging preliminary antitumor activity. Further studies are warranted to identify the RP2D."
        },
        {
            "Title": "LEONARDA-2: Lerociclib plus letrozole versus placebo plus letrozole in HR+/HER2- advanced or metastatic breast cancer.",
            "Indication": "HR+/HER2- advanced or metastatic breast cancer",
            "Drug": "Lerociclib + Letrozole",
            "PFS": "Not reached (HR 0.464, 95% CI 0.293-0.733, p=0.0004)",
            "OS": "None",
            "ORR": "62.3% in lerociclib group vs 48.5% in placebo group",
            "AE": "Higher incidences of grade 3/4 neutropenia (grade 3: 41.6% vs. 0.7%; grade 4: 5.1% vs. 0%) and leucopenia (grade 3: 27.0% vs. 0%; grade 4: 1.5% vs. 0%) in the lerociclib group. Grade 3 diarrhea was infrequent (0.7% in lerociclib group). QTc prolongation was comparable (4.4% in lerociclib group vs 3.5% in placebo group). No venous thromboembolism reported. Serious AEs were slightly higher in the lerociclib group (13.1% vs. 7.7%). Treatment discontinuation due to AEs was rare (0.7% in lerociclib group).",
            "Other": "None",
            "Conclusion": "Lerociclib with letrozole had an excellent safety profile and significantly prolonged PFS in HR+/HER2- advanced or metastatic BC patients. This benefit was consistent across all clinically relevant subgroups, suggesting lerociclib as a viable first-line therapeutic option with a favorable benefit-risk balance."
        },
        {
            "Title": "Safety and patient-reported outcomes in patients with hormone receptor-positive, HER2-negative advanced breast cancer treated with CDK4/6 inhibitors in China: A cross-sectional study.",
            "Indication": "Hormone receptor-positive, HER2-negative advanced breast cancer",
            "Drug": "CDK4/6 inhibitors (palbociclib, abemaciclib, dalpiciclib, ribociclib)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Decreased white blood cell (63.3%), decreased neutrophil count (58.7%), diarrhea (36.3%), increased ALT (9.3%, grade \u22653: 6.0%), increased AST (8.4%, grade \u22653: 6.7%)",
            "Other": "Dalpiciclib showed the lowest incidence of increased ALT (3.5%; grade \u22653: none), increased AST (4.2%; grade \u22653: none), and diarrhea (10.5%) compared with other CDK4/6 inhibitors (P < 0.001). Dalpiciclib also showed the lowest incidence of fatigue, alopecia, hot flashes, insomnia, arthralgia, nausea, vomiting, diarrhea, and anorexia (P < 0.05).",
            "Conclusion": "AEs are different among various CDK4/6 inhibitors. Dalpiciclib has the lowest risk of diarrhea and hepatotoxicity based on physicians\u2019 reports and shows the best patient-perceived safety. Regarding QoL, CDK4/6 inhibitors can result in upset by hair loss, while patients have the least concern about this issue when using dalpiciclib, especially for those with long treatment duration (>6 months)."
        },
        {
            "Title": "Impact of prior anticancer treatments on palbociclib (PAL) clinical outcomes in patients with hormone receptor-positive/human epidermal growth factor receptor 2-negative (HR+/HER2-) advanced breast cancer (ABC) in real-world settings.",
            "Indication": "Hormone receptor-positive, HER2-negative advanced breast cancer",
            "Drug": "Palbociclib (PAL) combined with endocrine therapy (ET)",
            "PFS": "1LOT: Prior chemotherapy-alone: 15.9 months (95% CI 10.8\u201322.3), Prior ET-alone: 30.4 months (95% CI 23.8\u201339.0), Prior chemotherapy + ET: 18.6 months (95% CI 16.8\u201323.6); 2LOT: Prior chemotherapy-alone: 17.2 months (95% CI 9.4\u201338.1), Prior ET-alone: 19.8 months (95% CI 13.2\u201328.1), Prior chemotherapy + ET: 8.5 months (95% CI 5.5\u201313.5)",
            "OS": "1LOT: Prior chemotherapy-alone: 38.7 months (95% CI 30.0\u201340.8), Prior ET-alone: NR, Prior chemotherapy + ET: 37.1 months (95% CI 30.5\u2013NE); 2LOT: Prior chemotherapy-alone: 42.2 months (95% CI 24.2\u2013NE), Prior ET-alone: 37.2 months (95% CI 31.2\u201344.3), Prior chemotherapy + ET: 37.9 months (95% CI 22.0\u201344.0)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Overall, patients with HR+/HER2- ABC with prior chemotherapy receipt tended to have shorter clinical benefit. Regardless of LOT, numerically longer rwPFS was achieved in patients who received prior ET-alone. In 1LOT, OS was longer in patients who received prior ET-alone while in 2LOT, OS was comparable across prior treatment subgroups. Small sample sizes of some 2LOT subgroups as well as the observational nature of the study may limit interpretation."
        },
        {
            "Title": "BLU-222, an investigational, oral, potent, and highly selective CDK2 inhibitor (CDK2i), as monotherapy in patients (pts) with advanced solid tumors and in combination with ribociclib (RIBO) and fulvestrant (FUL) in HR+/HER2- breast cancer (BC).",
            "Indication": "Advanced solid tumors and HR+/HER2- breast cancer",
            "Drug": "BLU-222 (CDK2 inhibitor), ribociclib, fulvestrant",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Most common treatment-emergent adverse events (TEAEs; \u226515%) included gastrointestinal events (nausea, diarrhea, vomiting), fatigue, anemia, photophobia, and hypokalemia. Dose-limiting toxicities occurred in 2 pts, 1 with grade 3 nausea at 800 mg BID and 1 with grade 3 blurred vision/photophobia at 600 mg BID; both improved after dose reduction.",
            "Other": "Increasing doses of BLU-222 monotherapy were associated with decreased TK1 activity and pRb. Eleven HR+/HER2- BC pts with a median of 5 prior LoT received BLU-222 with RIBO+FUL but have limited follow-up. The initial cohort of BLU-222 with RIBO+FUL was well tolerated.",
            "Conclusion": "BLU-222 monotherapy was generally well tolerated in unselected heavily pretreated pts. In addition to encouraging safety, markers of cell cycle modulation and antitumor activity were seen. The initial cohort of BLU-222 with RIBO+FUL exhibited no additional safety concerns. Together, these data demonstrate the clinical potential of BLU-222 in CDK2-vulnerable tumors. Enrollment is ongoing for dose escalation and optimization."
        },
        {
            "Title": "Sequential use of PI3K/AKT/mTOR pathway inhibitors alpelisib and everolimus in patients with hormone receptor-positive (HR+) metastatic breast cancer.",
            "Indication": "Hormone receptor-positive metastatic breast cancer",
            "Drug": "Alpelisib, everolimus",
            "PFS": "Group 1 (E then A): Median PFS1: 9.2 months (95% CI 5.5-13.0), Median PFS2: 5.0 months (95% CI 4.6-7.9); Group 2 (A then E): Median PFS1: 9.3 months (95% CI 7.0-14.0), Median PFS2: 3.5 months (95% CI 3.0-4.1)",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "This is the largest retrospective study to-date of patients treated sequentially with PI3K/AKT/mTOR pathway inhibitors. PFS was longer with the 1st pathway inhibitor than with the 2nd regardless of sequence. This has implications for optimal integration of capivasertib, an AKT inhibitor, into HR+ MBC treatment. Prospective studies testing optimal sequencing are needed."
        },
        {
            "Title": "Bireociclib plus fulvestrant for advanced HR+/HER2- breast cancer progressing after endocrine therapy: Interim analysis of a phase 3 trial (BRIGHT-2)",
            "Indication": "HR+/HER2- advanced breast cancer",
            "Drug": "Bireociclib plus fulvestrant",
            "PFS": "12.94 months (95% CI, 11.07-NR) in BF group vs 7.29 months (95% CI, 5.45-11.04) in F group (HR, 0.561; 95% CI, 0.393-0.799; P=0.0012)",
            "OS": "None",
            "ORR": "None",
            "AE": "Diarrhea: 92.6% (all grades); 5.4% (grade 3), Neutropenia: 87.3%; 31.4%, Leukocytopenia: 83.3%; 18.6%, Anemia: 66.7%; 10.8%",
            "Other": "None",
            "Conclusion": "Bireociclib plus fulvestrant showed superior efficacy for pretreated HR+/HER2- ABC, especially in patients with primary resistance to ET, liver metastases, or bone-only metastases. The toxicity of Bireociclib plus fulvestrant was well tolerated and manageable with no new safety signal."
        },
        {
            "Title": "MORPHEUS Breast Cancer (BC): A phase I/II study of giredestrant (G) treatment (tx) combinations in patients (pts) with estrogen receptor-positive (ER+), HER2-negative, locally advanced/metastatic BC (LA/mBC)",
            "Indication": "ER+, HER2- locally advanced/metastatic breast cancer",
            "Drug": "Giredestrant plus everolimus",
            "PFS": "Median PFS not reached at data cutoff",
            "OS": "None",
            "ORR": "26.7% (n=4)",
            "AE": "Tx-related AEs: 93.3%, Grade 3\u20134 AEs: 5.0%, Serious AEs: 13.3%, TRAE leading to tx discontinuation: 6.7%, TRAE leading to dose modification/interruption: 40.0%, Fatal AEs: 0, Most common TRAEs (\u226520% incidence rate): Dysgeusia: 33.3%, Nausea: 26.7%, Asthenia: 20.0%, Fatigue: 20.0%, Mucosal inflammation: 20.0%, Stomatitis: 20.0%, Vomiting: 20.0%",
            "Other": "Partial responses in 26.7% of pts (n=4; all had ESR1 mutations, 1 also had an Akt pathway mutation), 46.7% of pts (n=7) had stable disease, and the DCR was 53.3% (n=8). No clinically relevant drug\u2013drug interactions were observed.",
            "Conclusion": "Safety of G + EVERO was aligned with the individual safety profile of each drug with no overlapping toxicities or new safety signals seen. An encouraging efficacy signal was observed with G + EVERO in pts with PD on 1\u20132 lines of ET (including a CDK4/6i). G + EVERO is being further investigated in the Phase III evERA BC trial (NCT05306340)."
        },
        {
            "Title": "Comprehensive analysis of TROP2, PD-L1, stromal (s)TILs, and HER2 expression patterns in patients (pts) with metastatic HR+HER2- breast cancer.",
            "Indication": "Metastatic HR+HER2- breast cancer",
            "Drug": "Sacituzumab govitecan (SG)",
            "PFS": "5.5-month median PFS in endocrine refractory HR+ mBC",
            "OS": "Median OS was 43.8 months; stratification for selected biomarker combinations will be presented although no statistical significance was found.",
            "ORR": "None",
            "AE": "None",
            "Other": "Analyzing biomarkers of interest in pts with HR+ mBC unveiled diverse patterns, without associations with OS.",
            "Conclusion": "Analyzing biomarkers of interest in pts with HR+ mBC unveiled diverse patterns, without associations with OS."
        },
        {
            "Title": "Elacestrant in combination with abemaciclib in patients (pts) with brain metastasis from estrogen receptor-positive (ER+), HER2-negative (HER2-) breast cancer: Preliminary data from ELECTRA, an open-label, multicenter, phase 1b/2 study.",
            "Indication": "ER+, HER2- metastatic breast cancer with brain metastases",
            "Drug": "Elacestrant + Abemaciclib",
            "PFS": "None",
            "OS": "None",
            "ORR": "In 21 evaluable pts, 6 had partial response (1 unconfirmed) and 9 had stable disease (2 unconfirmed) as best tumor response.",
            "AE": "The most common all-grade (Gr) AEs were diarrhea (n=21, 81%; 0 Gr3), nausea (n=16, 62%; 0 Gr3), and decreased neutrophils/neutropenia (n=13, 50%; n=10, 38% Gr3). No Gr4 AEs or Gr3 diarrhea were observed.",
            "Other": "No dose-limiting toxicities were observed. RP2D of elacestrant with abemaciclib will be reported.",
            "Conclusion": "The RP2D for elacestrant + abemaciclib will be reported; the combination demonstrated a manageable and predictable safety profile. Preliminary efficacy data showed encouraging antitumor activity. Elacestrant has the potential to become the ET backbone for combination regimens."
        },
        {
            "Title": "Efficacy of subsequent treatments after disease progression on CDK4/6 inhibitors therapy in patients with hormone receptor-positive metastatic breast cancer: A Kaplan-Meier derived individual-patient data meta-analysis.",
            "Indication": "Hormone receptor-positive metastatic breast cancer",
            "Drug": "CDK4/6 inhibitors + Endocrine Therapy (ET)",
            "PFS": "Maintaining treatment with a CDK4/6i post-CDK4/6i progression was associated with longer PFS (HR: 0.64; 95% CI: 0.54-0.76; p<0.01; n=1,397). The PFS benefit was seen in both continuing the prior CDK4/6i (HR 0.62; 95% CI: 0.54-0.72; p<0.01; n=1,178) or switching to a different CDK4/6i (HR 0.49; 95% CI: 0.30-0.82; p<0.01; n=638).",
            "OS": "Maintaining treatment with a CDK4/6i post-CDK4/6i progression was associated with prolonged OS (HR: 0.70; 95% CI: 0.61-0.79; p<0.01; n=2,369). Subsequent therapy with PI3K/AKT/mTOR inhibitors plus ET achieved longer PFS (HR: 0.74; 95% CI: 0.61-0.90; p<0.01; n=744), but significantly shorter OS (HR 1.36; 95% CI: 1.09-1.71; p<0.01; n=1,564) compared with ET monotherapy. PFS with chemotherapy was not different from ET monotherapy (HR 0.93; 95% CI: 0.76-1.13; p=0.45; n=879) but exhibited shorter OS (HR 1.55; 95% CI: 1.38-1.72; p<0.01; n=2,476).",
            "ORR": "None",
            "AE": "None",
            "Other": "Sensitivity analyses including only RCTs confirmed the greater PFS of maintaining CDK4/6i plus ET compared with ET monotherapy (HR: 0.69 95% CI: 0.57-0.83; p<0.01; n=751).",
            "Conclusion": "This extensive data pool of RCTs and cohort studies suggests the potential clinical benefit of continued CDK4/6i after disease progression compared to ET monotherapy. Data from phase III trials will further aid in understanding this question. Our data also supports current guideline recommendations of ET-based therapies over chemotherapy as the preferred treatment sequencing in HR+HER2- mBC."
        },
        {
            "Title": "Real-world treatment outcomes in patients with HR+ HER2- advanced breast cancer treated with CDK4/6 inhibitors and endocrine therapy.",
            "Indication": "HR+ HER2- advanced breast cancer",
            "Drug": "Palbociclib (PAL), Ribociclib (RIB), Abemaciclib (ABM)",
            "PFS": "1st line AI: PAL 29.0 months (95% CI 21.6-37.0), RIB 32.0 months (24.5-36.9), ABM 48.9 months (42.9-N/R); 2nd line AI: PAL 11.9 months (9.49-N/R), RIB 10.8 months (8.27-N/R), ABM 11.0 months (6.43-N/R)",
            "OS": "1st line AI: PAL 27.4 months (24.4-N/R), RIB 36.7 months (30.1-N/R), ABM 45.5 months (29.0-N/R); 2nd line AI: PAL 22.7 months (16.8-N/R), RIB 32.3 months (24.9-N/R), ABM 31.0 months (13.2-N/R)",
            "ORR": "None",
            "AE": "Safety profile concordant with reported data for ET+CDK4/6i",
            "Other": "RIB+FUL was superior to PAL+FUL and ABM+FUL in both lines",
            "Conclusion": "Identifying predictive factors of response is essential for the rational use of CDK4/6i and ET in HR+/HER2- ABC."
        },
        {
            "Title": "Nationwide real-world practice pattern and clinical data of palbociclib in patients with HR(+), HER2(-) metastatic breast cancer (KCSG BR21-15).",
            "Indication": "HR+, HER2- metastatic breast cancer",
            "Drug": "Palbociclib + Letrozole",
            "PFS": "28.0 months (95% CI 25.53-32.10)",
            "OS": "61.6 months (95% CI 56.93-66.80)",
            "ORR": "47.5%",
            "AE": "95.1% experienced any grade of neutropenia, 62.7% with grade 3 neutropenia",
            "Other": "Patients with primary-tamoxifen resistance showed inferior PFS compared to secondary resistance (mPFS 14.63 vs. 27.07 months, P=0.0059)",
            "Conclusion": "Palbociclib showed OS over 60 months, comparable to other pivotal clinical trials. Further follow-up analysis of PFS2 and subsequent treatment is warranted."
        },
        {
            "Title": "Elacestrant in various combinations in patients (pts) with estrogen receptor-positive (ER+), HER2-negative (HER2-) locally advanced or metastatic breast cancer (adv/mBC): Preliminary data from ELEVATE, a phase 1b/2, open-label, umbrella study.",
            "Indication": "ER+, HER2- locally advanced or metastatic breast cancer",
            "Drug": "Elacestrant in combination with Everolimus, Alpelisib, Ribociclib, Palbociclib, or Abemaciclib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Elacestrant + Ribociclib: decreased neutrophils/neutropenia (50%, Gr3+ 33%); Elacestrant + Palbociclib: nausea (38%, Gr3+ 0%), decreased neutrophils/neutropenia (38%, Gr3+ 25%); Elacestrant + Everolimus: nausea, fatigue, diarrhea (each 40%, Gr3+ 7%), stomatitis and back pain (33%, Gr3+ 7% back pain only); Elacestrant + Alpelisib: nausea (78%, Gr3+ 11%), rash (56%, Gr3+ 33%), fatigue and stomatitis (44%, Gr3+ 0%), diarrhea, dizziness, and hyperglycemia/type 2 diabetes (33%, Gr3+ 11% hyperglycemia only)",
            "Other": "None",
            "Conclusion": "The combinations evaluated are consistent with the known safety profiles of everolimus, alpelisib, ribociclib, and palbociclib with standard of care endocrine therapy, demonstrating the potential of elacestrant to be combined with targeted therapies, enabling all oral regimens."
        },
        {
            "Title": "Real-world (RW) elacestrant use patterns and therapeutic outcomes in patients (pts) with hormone receptor-positive (HR+)/HER2-negative advanced breast cancer (aBC).",
            "Indication": "Hormone receptor-positive (HR+)/HER2-negative advanced breast cancer (aBC)",
            "Drug": "Elacestrant",
            "PFS": "3.8 months (EMERALD study); Real-world TTD: 5.4 months (95% CI: 4.2-not reached), TTNT: 6.2 months (95% CI: 5.7-not reached)",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "TTD and TTNT were used as proxies for PFS. No significant differences in TTD or TTNT based on the number of prior LOT or number of ESR1m mutations.",
            "Conclusion": "Using a clinical-genomic database, we present early RW insights of aBC pts treated with elacestrant. Initial outcomes, as assessed via TTD and TTNT, exceed the PFS reported in the EMERALD trial, perhaps due to multiple factors impacting patient selection and restaging protocols, which can differ in prospective trials versus routine clinical care. Factors such as prior LOT and number of ESR1m did not impact patient outcomes; additional factors such as prior ET use, duration of CDK4/6i treatment, specific ESR1m, and co-occurring mutations will be assessed as data matures."
        },
        {
            "Title": "Predicting CDK4/6 inhibitors outcomes in pts with HR+/HER2- metastatic breast cancer: A machine learning approach.",
            "Indication": "HR+/HER2- metastatic breast cancer",
            "Drug": "CDK4/6 inhibitors",
            "PFS": "Good-response risk group: 23.8 months (95% CI: 19.6-29.9), Intermediate-response risk group: 14.7 months (95% CI: 12.2-16.9), Poor-response risk group: 7.4 months (95% CI: 5.3-9.1), HR between good-response and poor-response groups: 2.55 (95% CI: 2.0, 3.2), p=2.54e-14",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "ML-based models have the potential to identify subsets of pts likely to poorly respond to first-line CDK4/6i leveraging clinico-pathological features. Risk stratification can help clinicians to select pts who may benefit from short-term interval imaging and potential cfDNA monitoring, as well as to identify the right population to design clinical trials with escalating treatment approaches."
        },
        {
            "Title": "Genomic alterations in DNA damage response (DDR) genes in HR+/HER2- metastatic breast cancer (mBC) and impact on clinical efficacy with sacituzumab govitecan (SG): Biomarker results from TROPICS-02 study.",
            "Indication": "HR+/HER2- metastatic breast cancer",
            "Drug": "Sacituzumab govitecan (SG)",
            "PFS": "DDR WT: TPC: 5.6 months (95% CI: 1.7-7.6), HR: 0.76; SG: 14.4 months (95% CI: 11.1-23.7), HR: (0.49, 1.39); DDR MUT: TPC: 2.9 months (95% CI: 1.4-4.3), HR: 0.61; SG: 15.4 months (95% CI: 12.8-18.4), HR: (0.45, 1.04)",
            "OS": "DDR WT: TPC: 17.3 months (95% CI: 6.9-25.2), HR: (0.41, 1.02); SG: 17.2 months (95% CI: 9.8-36.1), HR: (0.30, 0.98); DDR MUT: TPC: 17.5 months (95% CI: 8.9-28.2), HR: (0.42, 1.42); SG: 17.1 months (95% CI: 8.9-36.1), HR: (0.30, 0.98)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "While SG benefit over TPC was observed in both DDR WT and DDR MUT HR+/HER2- mBC, numerically greater benefit was observed for patients with DDR deficient tumors, suggesting possible synergy between the DDR pathway and SG\u2019s anti-tumor effect. Further study of the synergistic effects of SG in combination with agents targeting DDR pathway are warranted."
        },
        {
            "Title": "Impact of use of antibody drug conjugates on the prognostic outcome of patients with HR+ve/HER2-ve MBC: Results from a real world dataset.",
            "Indication": "HR+ve/HER2-ve metastatic breast cancer",
            "Drug": "Trastuzumab deruxtecan (T-DXd) and sacituzumab govitecan (SG)",
            "PFS": "None",
            "OS": "5-yr OS for the whole cohort: 58.1%; 5-yr OS among pts who did and did not receive T-DXd: 74.8% and 52.2% respectively (HR 0.44, 95% CI 0.28-0.69, p=0.0002); 5-yr OS among pts who did and did not receive SG: 73.4% and 64.7% respectively (HR 0.62, 95% CI 0.42-0.90, p=0.012); 5-yr OS among pts who did and did not receive T-DXd+SG: 77.4% and 62.4% respectively (HR 0.55, 95% CI 0.19-1.59, p=0.26)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "In a real-world dataset we report on the OS benefit associated with the use of SG and/or T-DXd at some time point in the therapy of pts with HR+ve/HER2-ve breast cancer who have progressed on endocrine therapy and CDK4/6i."
        },
        {
            "Title": "Outcomes with trastuzumab deruxtecan (T-DXd) by HER2 status and line of treatment in a large real-world database of patients with metastatic breast cancer.",
            "Indication": "Metastatic breast cancer",
            "Drug": "Trastuzumab deruxtecan (T-DXd)",
            "PFS": "HER2+: 12.1 months (95% CI: 10.8, 13.9); HER2-: HR+/HER2-: 7.3 months (95% CI: 5.9, 8.1); HR+, HER2-Low: 7.3 months (95% CI: 5.8, 8.3); HR+, HER2-0: 6.2 months (95% CI: 4.2, NA); HR-/HER2-: 4.5 months (95% CI: 2.7, 6.6); HR-, HER2-Low: 4.5 months (95% CI: 2.7, 6.6); HR-, HER2-0: 2.0 months (95% CI: 1.9, NA)",
            "OS": "HER2+: 26.0 months (95% CI: 21.1, 30.0); HER2-: HR+/HER2-: 15.5 months (95% CI: 15.5, NA); HR+, HER2-Low: 15.5 months (95% CI: 15.5, NA); HR+, HER2-0: NA (95% CI: 5.0, NA); HR-/HER2-: 9.4 months (95% CI: 7.2, 12.5); HR-, HER2-Low: 9.4 months (95% CI: 7.2, 12.5); HR-, HER2-0: NA (95% CI: 5.5, NA)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "In the largest dataset to date, T-DXd showed favorable real-world activity for treating MBC, although rwPFS appeared shorter than what was observed in clinical trials. Encouraging rwPFS was observed in understudied groups, such as patients with triple-negative and HR+/HER2-0 MBC."
        },
        {
            "Title": "Immune checkpoint inhibition for the treatment of metastatic breast cancer with high tumor mutational burden: Real world clinical and genomic correlates of response.",
            "Indication": "Metastatic Breast Cancer (MBC)",
            "Drug": "Pembrolizumab",
            "PFS": "Median PFS of ICI for all patients was 3.17 months (95% CI 2.27-5.5).",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Longer PFS was significantly associated with TMB \u2265 30 (HR 0.35, 95% CI 0.13-0.92, p=0.03) and dominant APOBEC signature score (APOBEC- vs APOBEC+, HR 0.49, 95% CI 0.25-0.98, p=0.04). Shorter PFS was significantly associated with later treatment line at the time of ICI exposure (continuous, HR 1.22, 95% CI 1.11-1.36, p=0.0001).",
            "Conclusion": "In this heavily pretreated real-world cohort, ICI in TMB-high MBC shows modest clinical activity. Our analysis suggests that ICI may be more effective in MBC patients that are less heavily pre-treated, have TMB \u2265 30, and have a predominantly APOBEC mutation signature. Further translational work is necessary to explore the mechanisms of these findings."
        },
        {
            "Title": "A phase 1 study of liposomal irinotecan in patients with advanced breast cancer.",
            "Indication": "Advanced Triple Negative Breast Cancer (TNBC) and HER2-negative Breast Cancer Brain Metastasis (BCBM)",
            "Drug": "Liposomal irinotecan (HE072)",
            "PFS": "Median PFS was 4.8 months (95% CI 3.9-5.8) for TNBC and 5.6 months (95% CI 1.4~ not reached) for HER2-negative BCBM.",
            "OS": "Median OS was 14.1 months (95% CI 11.2-not reached) for TNBC and 12-month OS rate was 55.5% for HER2-negative BCBM.",
            "ORR": "26.5% (95% CI 17.4-37.3) overall response rate for TNBC, 8.3% (95% CI 0.2, 38.5) overall response rate for HER2-negative BCBM.",
            "AE": "Most common \u2265 grade 3 TEAEs related to HE072 included neutropenia (21.0%), leukopenia (18.5%), diarrhea (10.1%), anemia (9.2%), and hypokalemia (8.4%).",
            "Other": "Disease control rate was 69.9% (95% CI 58.8-79.5) for TNBC and 66.7% (95% CI 34.9, 90.1) for HER2-negative BCBM.",
            "Conclusion": "HE072 exhibited excellent anti-tumor activity in heavily pre-treated patients with TNBC and HER2-negative BCBM with acceptable safety profiles. 70 mg/m2 Q2W was determined as the RP2D. Further clinical trials are under plan."
        },
        {
            "Title": "Utidelone plus bevacizumab for the treatment of HER2-negative breast cancer brain metastases (U-BOMB): A multicenter, single-arm phase II study.",
            "Indication": "HER2-negative Breast Cancer Brain Metastases",
            "Drug": "Utidelone plus bevacizumab",
            "PFS": "Median PFS was 7.7 months (95% CI: 5.5-10.8).",
            "OS": "12-month overall survival (OS) rate reached 74.4% (95% CI: 60.0%-92.3%).",
            "ORR": "CNS-ORR was 43.5% (95% confidence interval [CI], 28.9%-58.9%).",
            "AE": "Most common grade 1-2 adverse events (AEs) were peripheral neuropathy (87.5%), decreased neutrophil count (62.5%), anemia (47.5%), diarrhea (37.5%), and increased alanine aminotransferase (25%). No grade 3 or higher treatment-related AEs occurred.",
            "Other": "None",
            "Conclusion": "This study preliminarily shows promising efficacy and manageable safety of the combination of utidelone and bevacizumab in the treatment of HER2-negative metastatic breast cancer patients with brain metastases."
        },
        {
            "Title": "Multicenter retrospective cohort study of the sequential use of the antibody-drug conjugates (ADCs) trastuzumab deruxtecan (T-DXd) and sacituzumab govitecan (SG) in patients with HER2-low metastatic breast cancer (MBC): Updated data and subgroup analyses by age, sites of disease, and use of intervening therapies.",
            "Indication": "HER2-low metastatic breast cancer (MBC)",
            "Drug": "Trastuzumab deruxtecan (T-DXd) and sacituzumab govitecan (SG)",
            "PFS": "For HR+ and HR- MBC: ADC1 rwPFS: 3.8 months (SG\u2192T-DXd), 4.5 months (T-DXd\u2192SG); ADC2 rwPFS: 3.7 months (SG\u2192T-DXd), 2.6 months (T-DXd\u2192SG). For HR- MBC with CNS disease: ADC1 rwPFS: 5.4 months (with CNS disease) vs. 8.5 months (without CNS disease), p=0.03.",
            "OS": "For HR+ and HR- MBC: ADC1 rwOS: 27.8 months (SG\u2192T-DXd), 17.8 months (T-DXd\u2192SG); ADC2 rwOS: 16.5 months (SG\u2192T-DXd). For HR- MBC with CNS disease: ADC1 rwOS: 11.6 months (with CNS disease) vs. 21.1 months (without CNS disease), p<0.01.",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "This is one of the largest multicenter cohorts of pts treated with sequential ADCs for HER2-low MBC. Median rwPFS was longer for ADC1 than ADC2 in all subgroups, regardless of HR-status, ADC sequence order, age \u226465 or >65 yr, presence of visceral disease, or use of IntTx. Among pts with HR-/HER2-low MBC, pts with CNS disease had shorter ADC1 rwPFS and rwOS than pts without CNS disease."
        },
        {
            "Title": "Results and exploratory biomarker analyses of a phase II study CHANGEABLE: Combination of HX008 and niraparib in germ-line-mutated metastatic breast cancer.",
            "Indication": "Germ-line-mutated metastatic breast cancer (MBC)",
            "Drug": "HX008 and niraparib",
            "PFS": "Median PFS: 7.3 months (95% CI 4.2 to 10.4)",
            "OS": "None",
            "ORR": "Main cohort with gBRCA1/2 mutations: ORR 78.6% (22/28), DCR 96.4% (27/28). Exploration cohort with brain metastases: ORR 40% (2/5), DCR 80% (4/5).",
            "AE": "Grade 3 or higher: anemia (35.1%), thrombocytopenia (10.8%), neutropenia (8.1%)",
            "Other": "Biomarker analysis: Somatic mutations in XPO1 correlated with response. Somatic TP53 mutations correlated with shorter PFS, while ASXL1 mutations correlated with longer PFS.",
            "Conclusion": "The combination of niraparib and HX008 demonstrated promising clinical benefits with a tolerable safety profile in MBC patients with germline BRCA1/2 mutations, even in patients with brain metastases."
        },
        {
            "Title": "Real world outcomes of sequential ADC therapy in metastatic breast cancer: Patients treated with sacituzumab govitecan and trastuzumab deruxtecan.",
            "Indication": "Metastatic breast cancer (MBC)",
            "Drug": "Sacituzumab govitecan (SG) and trastuzumab deruxtecan (TDXd)",
            "PFS": "Median ADC2 PFS: 3.5 months (SG first), 2.8 months (TDXd first).",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Summary of ADC1 and ADC2 mPFS for each ADC: SG mPFS as ADC1: 5.1 months (95% CI 3.27-7.27), mPFS as ADC2: 2.8 months (95% CI 2.57-3.73), HR 2.11 (95% CI 1.23-3.64), p=0.007. TDXd mPFS as ADC1: 4.9 months (95% CI 3.27-5.7), mPFS as ADC2: 3.5 months (95% CI 2.67-7.7), HR 1.20 (95% CI 0.73-1.99), p=0.46.",
            "Conclusion": "In this heavily pre-treated patient cohort, the clinical activity of both SG and TDXd appear modest in the ADC2 setting. Further studies are needed to confirm these findings and to determine whether payload cross-resistance may undermine the benefit of sequential ADC therapy."
        },
        {
            "Title": "Utility of axillary surgery in patients with de novo metastatic breast cancer undergoing surgery of the primary site.",
            "Indication": "Metastatic Breast Cancer (MBC)",
            "Drug": "Not applicable",
            "PFS": "None",
            "OS": "36-month adjusted survival estimates: SLNB - 71.4%, ALND - 66.5%, No axillary surgery - 58.0%. Univariate HR: SLNB alone vs. no axillary surgery - 0.50 (95% CI: 0.44-0.58), ALND vs. no axillary surgery - 0.75 (95% CI: 0.68-0.82). Multivariable HR: SLNB alone vs. no axillary surgery - 0.60 (95% CI: 0.51-0.69), ALND vs. no axillary surgery - 0.73 (95% CI: 0.65-0.80)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Performance of axillary surgery in MBC patients was associated with improved OS. Selection bias is likely a contributing factor. Prospective studies are needed to validate these results."
        },
        {
            "Title": "SV-BR-1-GM after progression on ADC in patients with metastatic breast cancer.",
            "Indication": "Metastatic Breast Cancer (MBC)",
            "Drug": "SV-BR-1-GM",
            "PFS": "Median PFS: 74 days. 40% of patients demonstrated a Bria-IMT PFS (PFS2) similar to or greater than their prior line PFS (PFS1).",
            "OS": "Median OS: 42 weeks with survival of 69% at 6 months.",
            "ORR": "Disease control rate: 40%",
            "AE": "43% reported grade 3 or 4 AEs. AEs included injection site reaction (39%), fatigue (26%), nausea/vomiting (43%), mostly mild to moderate. One case of elevated lipase was the most clinically significant grade 4 AE. No interstitial lung disease (ILD) reported.",
            "Other": "43% of patients had a reduction in cancer-associated macrophage-like cell (CAML) levels; median reduction was 36% (22-98%) after 1 cycle.",
            "Conclusion": "This subset analysis suggests a potential clinical benefit and treatment option for ADC refractory MBC patients. The absence of serious AEs and no toxicity-related treatment discontinuations underscore the regimen\u2019s favorable safety profile. Future studies are warranted."
        },
        {
            "Title": "Efficacy and safety of erdafitinib in adults with breast cancer and prespecified fibroblast growth factor receptor alterations in the phase 2 open-label, single-arm RAGNAR trial.",
            "Indication": "Breast Cancer with FGFR alterations",
            "Drug": "Erdafitinib",
            "PFS": "Median PFS: 5.7 months",
            "OS": "Median OS: 8.9 months",
            "ORR": "Objective response rate by IRC: 31% (95% CI 11-59). Disease control rate by IRC: 69% (95% CI 41-89). Objective response rate by investigator: 38% (95% CI 15-65). Disease control rate by investigator: 69% (95% CI 41-89).",
            "AE": "Common AEs included stomatitis (81%), diarrhea (69%), hyperphosphatemia (69%), dry mouth (63%), and palmar-plantar erythrodysesthesia (44%). Six (38%) patients had serious AEs. No patients discontinued erdafitinib due to AEs. No treatment-related deaths were observed.",
            "Other": "None",
            "Conclusion": "Erdafitinib demonstrated clinically meaningful activity in heavily pretreated patients with breast cancer and prespecified FGFR alterations. Safety data were consistent with the erdafitinib safety profile."
        },
        {
            "Title": "Phase I clinical trial of MUC1-targeted CAR-T cells with PD-1-knockout in the treatment of advanced breast cancer.",
            "Indication": "Advanced Breast Cancer",
            "Drug": "MUC1-targeted CAR-T cells with PD-1-knockout",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Adverse events included elevated AST level (58.3%), acute fever (50%), elevated ALT level (41.7%), skin rash (41.7%), chills (33.3%), anemia (33.3%), lymphopenia (33.3%), diarrhea, vomiting, and urinary tract infection (16.7%). The most serious AE was lymphopenia (grade 4 lasting 1 day in 1 patient). No grade 3-5 CRS was observed. No patients experienced neurotoxicity. No predefined dose-limiting toxicities (DLT) or serious AEs were observed.",
            "Other": "None",
            "Conclusion": "Treatment of patients with advanced breast cancer with PD-1 disrupted MUC1-targeted CAR-T cells is safe and well-tolerated. No serious CRS or other EA was observed. The efficacy of this unique combined therapy is currently being assessed."
        },
        {
            "Title": "Trilaciclib combined with sacituzumab govitecan (SG) in metastatic triple-negative breast cancer (mTNBC): Updated phase 2 safety and efficacy results.",
            "Indication": "Metastatic triple-negative breast cancer (mTNBC)",
            "Drug": "Trilaciclib and sacituzumab govitecan (SG)",
            "PFS": "Median progression-free survival was 4.1 (2.2\u20137.3) months.",
            "OS": "Median OS among patients receiving trilaciclib prior to SG is 17.9 (9.9\u2013not estimable) months. OS data are not yet mature.",
            "ORR": "The confirmed overall response rate (95% CI) was 23.3% (9.9\u201342.3), and the clinical benefit rate was 46.7% (28.3\u201365.7).",
            "AE": "Fatigue: 63.3% (Any Grade), 3.3% (Grade 3/4); Nausea: 53.3% (Any Grade), 3.3% (Grade 3/4); Constipation: 30.0% (Any Grade), 0% (Grade 3/4); Diarrhea: 36.7% (Any Grade), 6.7% (Grade 3/4); Neutropenia: 40.0% (Any Grade), 23.3% (Grade 3/4); Anemia: 13.3% (Any Grade), 3.3% (Grade 3/4); Leukopenia: 33.3% (Any Grade), 13.3% (Grade 3/4).",
            "Other": "The median duration of response was 9.1 (4.0\u2013not estimable) months.",
            "Conclusion": "Adding trilaciclib prior to SG reduces the rate and severity of multiple adverse events, notably neutropenia and diarrhea, compared with results from trials of SG alone. Preliminary data suggest clinically meaningful OS outcomes among patients receiving trilaciclib prior to SG compared with historical data for SG alone; however, caution should be exercised when interpreting results from cross-trial comparisons. Mature OS data will be presented."
        },
        {
            "Title": "Efficacy and genomic analysis of HER2-mutant, metastatic triple-negative breast cancer treated with neratinib alone or in combination with trastuzumab in the phase 2 SUMMIT basket trial.",
            "Indication": "HER2-mutant, metastatic triple-negative breast cancer",
            "Drug": "Neratinib (N) alone or in combination with trastuzumab (T)",
            "PFS": "N: 2.9 months (95% CI: 1.0\u20135.5), N+T: 6.2 months (95% CI: 2.1\u20138.2)",
            "OS": "None",
            "ORR": "N: 40.0% (95% CI: 12.2\u201373.8), N+T: 35.3% (95% CI: 14.2\u201361.7)",
            "AE": "N: 80% any grade, 20% grade 3; N+T: 100% any grade, 18% grade 3; no grade 4/5 or permanent discontinuations in either cohort",
            "Other": "CBR (Clinical Benefit Rate): N: 40.0% (95% CI: 12.2\u201373.8), N+T: 47.1% (95% CI: 23.0\u201371.2); Duration of Response (DOR): N: 3.8 months (95% CI: 2.8\u20137.9), N+T: 6.1 months (95% CI: 3.2\u20139.2)",
            "Conclusion": "Treatment with either N and/or N+T yielded promising clinical efficacy and has been included in recent NCCN guidelines for HER2-mutant, triple-negative mBC. Molecular mechanisms of intrinsic or acquired sensitivity/resistance per genomic analysis will be reported."
        },
        {
            "Title": "Ipatasertib (IPA) combined with non-taxane chemotherapy (CT) for patients (pts) with previously treated advanced triple-negative breast cancer (aTNBC): The PATHFINDER phase IIa trial.",
            "Indication": "Advanced triple-negative breast cancer (aTNBC)",
            "Drug": "Ipatasertib (IPA) with non-taxane chemotherapy (CT)",
            "PFS": "Arm A: 2.7 months (95% CI, 1.5-4.1), Arm B: 3.8 months (95% CI, 1.5-9.6)",
            "OS": "Arm A: 15.5 months (95% CI, 11.8-19.2), Arm B: 11.5 months (95% CI, 8.8-25.1)",
            "ORR": "Arm A: 9.1%, Arm B: 36%",
            "AE": "Arm A: 81.8% any grade TEAEs, 4.5% SAEs, 27.3% G\u22653 TEAEs; Arm B: 88% any grade TEAEs, 20% SAEs, 68% G\u22653 TEAEs",
            "Other": "No treatment-related deaths reported. Arm C did not proceed with expansion phase due to toxicity.",
            "Conclusion": "Combining IPA with CA and E presented an acceptable safety profile. However, adding IPA to CG was not tolerable. E+IPA showed promising efficacy regardless of PI3K/AKT status that merits further evaluation."
        },
        {
            "Title": "Prevention of sacituzumab govitecan (SG)-related neutropenia and diarrhea in patients (pts) with triple-negative or HR+/HER2-advanced breast cancer (ABC; PRIMED): A phase 2 trial.",
            "Indication": "Triple-negative or HR+/HER2-advanced breast cancer (ABC)",
            "Drug": "Sacituzumab govitecan (SG) with prophylactic G-CSF and loperamide (L)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "28% any grade neutropenia, 34% any grade diarrhea, 16% G\u22653 neutropenia, 16% G\u22652 diarrhea (4% G3), 18% dose reductions, 44% treatment interruptions, no treatment discontinuations due to AEs",
            "Other": "No pts developed febrile neutropenia.",
            "Conclusion": "Prophylactic administration of G-CSF and L resulted in a clinically relevant reduction of the incidence and severity of SG-related neutropenia and diarrhea and limited AE related dose reductions/interruptions and treatment discontinuations."
        },
        {
            "Title": "Sequential combination of sacituzumab govitecan and talazoparib in metastatic triple negative breast cancer (mTNBC): Results from a phase II study.",
            "Indication": "Metastatic triple negative breast cancer (mTNBC)",
            "Drug": "Sacituzumab govitecan and talazoparib",
            "PFS": "6.2 months (95% CI, 3.7 to 12.8)",
            "OS": "18.0 months (95% CI, 10.7 to not evaluable)",
            "ORR": "30.1% (95% CI, 14 to 52)",
            "AE": "Serious AEs in 38.5% of patients; treatment-related serious AEs in 26.9%. Common treatment-related AEs: anemia (92.3%), neutropenia (88.5%), nausea (84.6%), fatigue (80.7%), thrombocytopenia (65.3%). Grade \u22653 neutropenia and anemia in 80.7% and 34.6% of cases, respectively.",
            "Other": "Clinical Benefit Rate (CBR) at 6 months was 53.8% (95% CI, 33 to 73). One patient with a germline BRCA mutation was treated for 625 days before disease progression.",
            "Conclusion": "In this single-arm phase II study, SG in combination with talazoparib was feasible and demonstrated preliminary evidence of efficacy. A randomized clinical trial is needed to determine if the SG-PARPi combination is superior to SG monotherapy."
        },
        {
            "Title": "Tucatinib and trastuzumab for previously treated HER2-mutated metastatic breast cancer (SGNTUC-019): A phase 2 basket study.",
            "Indication": "HER2-mutated metastatic breast cancer (HER2-mut MBC)",
            "Drug": "Tucatinib and trastuzumab",
            "PFS": "9.5 months (90% CI, 5.4-13.8)",
            "OS": "20.1 months (90% CI, 15.9 to not estimable)",
            "ORR": "41.9% (90% CI, 26.9-58.2)",
            "AE": "Common TEAEs: diarrhea (64.5%), nausea (35.5%). Common grade \u22653 TEAEs: diarrhea (13%), alanine aminotransferase increase (10%), hypertension (10%). Two patients (6.5%) discontinued TUC due to TEAEs but continued Tras. No deaths due to TEAEs.",
            "Other": "Median DOR was 12.6 months (90% CI, 4.7, not estimable). Disease Control Rate (DCR) was 80.6% (n=25; 90% CI, 65.3-91.2).",
            "Conclusion": "The investigational combination of TUC and Tras, plus F in patients with HR+ disease, was well tolerated and had clinical activity in patients with previously treated HER2-mut MBC. The results support further evaluation of the combination of TUC and Tras as a potential treatment option for this patient population."
        },
        {
            "Title": "Detection of TROP2: Ushering in a new choice in the treatment of patients with special types of invasive breast cancer.",
            "Indication": "Special types of invasive breast cancer",
            "Drug": "Sacituzumab govitecan (SG)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Expression of TROP2 is higher in tubular and cribriform carcinoma, while in MBC and MC it is significantly lower. Low expression of TROP2 is associated with low clinical staging, no lymph node metastasis, and high-grade tumor infiltrating lymphocytes.",
            "Conclusion": "This study is the first preliminary study on the TROP2 expression in special types of breast cancer. Although the current ASCO guideline recommends not testing TROP2, our study shows it is pretty low or even not expressed in MBC and MC. Notably, the lack of expression in the mesenchymal and spindle cell subtypes may highly indicate that they lack epithelial tumor cells expressing TROP2 as vectors, resulting in the inability to exert the therapeutic effect of ADC. We suggest routine testing of TROP2."
        },
        {
            "Title": "A single center retrospective cohort study of patients with metastatic breast cancer and leptomeningeal disease: Updated survival data and subgroup analyses by breast cancer subtype.",
            "Indication": "Metastatic breast cancer and leptomeningeal disease",
            "Drug": "None",
            "PFS": "None",
            "OS": "Median OS from the diagnosis of LMD to death was 4.1 months (range 0.1-78.1 months) and varied by subtype, with patients with HR+/HER2- or HER2+ MBC and LMD living longer than those with TNBC and LMD (4.2 and 6.8 months respectively vs. 2.0 months, p<0.01, HR 2.15, 95% CI 1.36-3.39).",
            "ORR": "None",
            "AE": "None",
            "Other": "Patients who received IT therapy survived longer than those who did not (7.1 vs. 2.7 months, p=0.01, HR 0.60, 95% CI 0.40-0.90). There was a difference in OS by receipt of CNS-directed radiation therapy. Patients diagnosed with LMD from 2015-2024 lived longer than those diagnosed from 2000-2014 (6.4 vs. 2.9 months, p=0.04, HR 0.67, 95% CI 0.46-0.99). On multivariable analysis, having TNBC was associated with shorter OS from time of LMD to death (p=0.004, HR 2.03, 95% CI 1.25-3.30).",
            "Conclusion": "This study represents one of the largest case series of patients with MBC and LMD, including the most patients diagnosed after 2015 to our knowledge. Patients with lobular histology, HER2+ BC, TNBC, and those with de novo MBC were over-represented compared to the general MBC population. Patients with HR+/HER2- or HER2+ disease, LMD diagnosed after 2015, and those who received IT therapy survived longer than their respective cohorts. Novel diagnostic and therapeutic strategies for patients with MBC and LMD remain an area of unmet clinical need."
        },
        {
            "Title": "Updated safety and antitumor activity of BB-1701 from study 101 in the multiple dose level cohort of patients with locally advanced/metastatic HER2-low expressing breast cancer.",
            "Indication": "HER2-low expressing breast cancer",
            "Drug": "BB-1701",
            "PFS": "1.0 mg/kg: 6-month PFS rate of 20%; 1.2 mg/kg: 6-month PFS rate of 50%; 1.4 mg/kg: 6-month PFS rate of 40%; 1.6 mg/kg: 6-month PFS rate of 50%",
            "OS": "None",
            "ORR": "1.0 mg/kg: DCR of 80.0%; 1.2 mg/kg: DCR of 88.9%; 1.4 mg/kg: DCR of 80.0%; 1.6 mg/kg: ORR of 30.0%, DCR of 60.0%",
            "AE": "Most common (\u226520%) all-grade TRAEs: peripheral neuropathy, AST increased, ALT increased, anemia, WBC decreased, hypertriglyceridemia. Most common (\u22655%) grade 3 TRAEs: peripheral neuropathy, peripheral sensory neuropathy. No grade 4 or grade 5 events. Four treatment-related serious adverse events: peripheral sensory neuropathy, anemia, spinal compression fracture, infusion-related reaction.",
            "Other": "One patient discontinued BB-1701 treatment due to peripheral neuropathy.",
            "Conclusion": "BB-1701 shows promising antitumor activity and a manageable safety profile in HER2 low-expressing breast cancer, including patients who received prior anti-HER2 ADCs."
        },
        {
            "Title": "Overall survival with palbociclib (PAL) plus an aromatase inhibitor (AI) versus AI alone in older patients (pts) with de novo, HR+/HER2- metastatic breast cancer: A SEER-Medicare analysis.",
            "Indication": "HR+/HER2- metastatic breast cancer",
            "Drug": "Palbociclib (PAL) plus an aromatase inhibitor (AI)",
            "PFS": "None",
            "OS": "PAL+AI: 37.6 months (95% CI=34.8-42.0); AI alone: 25.5 months (95% CI=22.0-28.9); HR=0.73 (95% CI=0.59-0.91)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "This comparative effectiveness study showed that PAL+AI was associated with significantly prolonged OS vs AI alone in the 1L treatment of older pts with de novo HR+/HER2- mBC treated in US clinical practice using the SEER-Medicare database. Our results add to the body of evidence on the survival benefit of PAL+AI in this population."
        },
        {
            "Title": "Molecular and immunological characterization of androgen receptor expression in different breast cancer subtypes.",
            "Indication": "Breast Cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "Post-doxorubicin survival: AR-H tumors had longer survival; LumB (75.5 vs 56.3 months; p=0.081), HER2-enriched tumors (47.9 vs 65.2 months; p=0.071). AR-H associated with longer post-dox survival in LumB PIK3CA-mt tumors (81.7 vs 39.3 months; p=0.036), but shorter in HER2-enriched PIK3CA-wt (50.4 vs 70.4 months) and PIK3CA-mt cohorts (40.8 vs 58.0 months; p=0.18).",
            "ORR": "None",
            "AE": "None",
            "Other": "AR-H tumors had higher immune infiltration in LumA, LumB, basal-like, and normal-like subtypes, but lower in HER2-enriched tumors. AR-H tumors were enriched for PIK3CA, CDH1, MAP3K1, ESR1, and NF1 mutations.",
            "Conclusion": "Findings suggest a strong association between AR and PIK3CA mutations across subtypes and distinct AR-associated alterations in the immune microenvironment. Further study is required for molecularly selected clinical trial design for advanced BC patients."
        },
        {
            "Title": "Identification of HER2 ultra-low based on an artificial intelligence (AI)-powered HER2 subcellular quantification from HER2 immunohistochemistry images.",
            "Indication": "Breast Cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "Objective response rate of trastuzumab deruxtecan in HER2-low patients in DESTINY-Breast04: 52.3%",
            "AE": "None",
            "Other": "AI-powered subcellular-level analysis enables identification of 23.6% HER2 ultra-low WSIs among HER2 score 0 by pathologist, defined by AI-HER2-weak or higher membrane-specific HER2 expression \u226510%.",
            "Conclusion": "Clinical validation of the AI-powered HER2 ultra-low definition is warranted."
        },
        {
            "Title": "Phase 1b study of EZH1/2 inhibitor valemetostat in combination with trastuzumab deruxtecan in patients with HER2 low/ultra-low/null metastatic breast cancer",
            "Indication": "HER2 low/ultra-low/null metastatic breast cancer",
            "Drug": "Valemetostat in combination with trastuzumab deruxtecan",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Dose-limiting toxicity (DLT) evaluation, maximum tolerated dose (MTD)/recommended dose for expansion (RDE), duration of response (DoR), clinical benefit rate (CBR), pharmacokinetics and pharmacodynamic markers",
            "Conclusion": "The study aims to evaluate the safety and anti-tumor activity of valemetostat in combination with T-DXd in subjects with HER2 low/ultra-low/null MBC."
        },
        {
            "Title": "Phase 2 study of novel HER2-targeting, TLR7/8 immune-stimulating antibody conjugate (ISAC) BDC-1001 (trastuzumab imbotolimod) +/- pertuzumab (P) in patients (pts) with HER2-positive metastatic breast cancer (MBC) previously treated with trastuzumab deruxtecan (T-DXd)",
            "Indication": "HER2-positive metastatic breast cancer",
            "Drug": "BDC-1001 (trastuzumab imbotolimod) +/- pertuzumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Low grade (grade 1/2) infusion-related reactions (29%)",
            "Other": "Anti-tumor activity, safety, PK, immunogenicity, PD biomarkers in tumor tissue and peripheral blood",
            "Conclusion": "The study aims to evaluate the anti-tumor activity of BDC-1001 alone and in combination with P, with secondary objectives to evaluate safety, PK, and immunogenicity."
        },
        {
            "Title": "An open-label, multicenter, phase 2 study to evaluate the safety and efficacy of BB-1701, a novel antibody drug conjugate (ADC) targeting human epidermal growth factor receptor 2 (HER2), in previously treated patients with HER2-positive (HER2+) or HER2-low unresectable or metastatic breast cancer (BC)",
            "Indication": "HER2-positive or HER2-low unresectable or metastatic breast cancer",
            "Drug": "BB-1701",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Recommended dose (RD), safety and tolerability, duration of response (DOR), disease control rate (DCR), clinical benefit rate (CBR), time to response (TTR), pharmacokinetics, biomarkers of response, BB-1701 immunogenicity",
            "Conclusion": "The study aims to determine the recommended dose and evaluate the safety and efficacy of BB-1701 in patients with HER2+ or HER2-low unresectable or metastatic BC who have progressed after treatment with T-DXd."
        },
        {
            "Title": "ACE-BREAST-03: A phase 2 trial evaluating ARX788, an anti-HER2 antibody drug conjugate (ADC), for the treatment of HER2+ metastatic breast cancer (mBC) in patients who have been previously treated with trastuzumab deruxtecan (T-DXd).",
            "Indication": "HER2+ metastatic breast cancer (mBC)",
            "Drug": "ARX788",
            "PFS": "None",
            "OS": "None",
            "ORR": "Response rate by RECIST v1.1",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The study is currently enrolling in the US."
        },
        {
            "Title": "INAVO122: A phase III study of maintenance inavolisib or placebo + pertuzumab + trastuzumab following induction with pertuzumab + trastuzumab + a taxane in patients (pts) with PIK3CA-mutated, HER2-positive advanced breast cancer (HER2+ aBC).",
            "Indication": "PIK3CA-mutated, HER2-positive advanced breast cancer (HER2+ aBC)",
            "Drug": "Inavolisib, pertuzumab, trastuzumab",
            "PFS": "Primary endpoint",
            "OS": "Secondary endpoint",
            "ORR": "Secondary endpoint",
            "AE": "None",
            "Other": "Health-related quality of life; safety; pharmacokinetics",
            "Conclusion": "The study is currently recruiting (15 pts enrolled)."
        },
        {
            "Title": "AIPAC-003: A randomized, double-blind, placebo-controlled phase 3 trial testing eftilagimod alpha (soluble LAG-3) in patients with HER2-neg/low metastatic breast cancer receiving paclitaxel, following an open-label dose optimization.",
            "Indication": "HER2-neg/low metastatic breast cancer",
            "Drug": "Eftilagimod alpha, paclitaxel",
            "PFS": "Key secondary endpoint",
            "OS": "Primary endpoint",
            "ORR": "Key secondary endpoint",
            "AE": "None",
            "Other": "Quality of life; pharmacokinetics",
            "Conclusion": "The study is currently in the dose optimization lead-in phase."
        },
        {
            "Title": "Fulvestrant with or without the cyclin-dependent kinase 7 (CDK7) inhibitor samuraciclib in advanced hormone receptor positive (HR+) breast cancer after CDK4/6 inhibition: Phase II SUMIT-BC study.",
            "Indication": "Advanced hormone receptor positive (HR+) breast cancer",
            "Drug": "Fulvestrant, samuraciclib",
            "PFS": "Secondary endpoint",
            "OS": "None",
            "ORR": "Secondary endpoint",
            "AE": "None",
            "Other": "Quality of Life (Functional Assessment of Cancer Therapy-Breast questionnaire [FACT-B]); correlations between TP53 mutation and efficacy/safety",
            "Conclusion": "The study is designed to evaluate the efficacy, safety, pharmacokinetics, and quality of life of samuraciclib combined with fulvestrant compared to fulvestrant monotherapy."
        },
        {
            "Title": "Open-label, randomized, multicenter, phase 3, ELAINE 3 study of the efficacy and safety of lasofoxifene plus abemaciclib for treating ER+/HER2-, locally advanced or metastatic breast cancer with an ESR1 mutation.",
            "Indication": "ER+/HER2-, locally advanced or metastatic breast cancer with an ESR1 mutation",
            "Drug": "Lasofoxifene plus abemaciclib",
            "PFS": "Expected PFS is \u226510.3 months for LAS/Abema and 7 months for fulv/Abema (PFS hazard ratio of 0.68 at final analysis)",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Clinical benefit rate (CBR), duration of response, and time to response. Time to cytotoxic chemotherapy, quality of life, and safety will also be evaluated.",
            "Conclusion": "The study aims to evaluate the efficacy, safety, and tolerability of LAS plus Abema versus fulv plus Abema."
        },
        {
            "Title": "First-in-human study of RLY-2608, a pan-mutant and isoform selective PI3Ka inhibitor, as a single agent in patients (pts) with advanced solid tumors and in combination with fulvestrant in pts with advanced breast cancer.",
            "Indication": "Advanced solid tumors and HR+, HER2\u2013 advanced/metastatic breast cancer",
            "Drug": "RLY-2608 plus fulvestrant with or without ribociclib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Adverse events (AEs) per CTCAE v5",
            "Other": "Primary endpoints are MTD/RP2D and AE profile; key secondary endpoints are PIK3CA genotype in blood and tumor, PK, biomarkers, and overall response rate.",
            "Conclusion": "The study investigates the maximum tolerated dose (MTD), recommended phase 2 doses (RP2Ds), safety, pharmacokinetics (PK), pharmacodynamics (PD), and anti-tumor activity of RLY-2608 monotherapy and in combination with fulvestrant and ribociclib."
        },
        {
            "Title": "International phase 3 clinical trial evaluating PF-07220060 plus fulvestrant in patients with HR+/HER2- advanced/metastatic breast cancer with progression after a prior CDK4/6 inhibitor.",
            "Indication": "HR+/HER2- advanced/metastatic breast cancer with progression after a prior CDK4/6 inhibitor",
            "Drug": "PF-07220060 plus fulvestrant",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Secondary objectives include overall survival, OR, duration of response, CBR, safety, patient-reported outcomes, and pharmacokinetics.",
            "Conclusion": "The trial will evaluate whether PF-07220060 plus fulvestrant improves clinical outcomes compared with the investigator-chosen therapies."
        },
        {
            "Title": "pionERA Breast Cancer (BC): Phase III study of first-line (1L) giredestrant vs. fulvestrant, both combined with a cyclin-dependent kinase 4/6 inhibitor (CDK4/6i), in patients (pts) with estrogen receptor-positive (ER+), HER2\u2013 locally advanced/metastatic BC (LA/mBC) with resistance to prior adjuvant (adj) endocrine therapy (ET).",
            "Indication": "ER+, HER2\u2013 locally advanced/metastatic BC with resistance to prior adjuvant endocrine therapy",
            "Drug": "Giredestrant vs. fulvestrant, both combined with a CDK4/6 inhibitor",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Co-primary endpoints: Investigator-assessed progression-free survival (PFS) in the ESR1m subgroup and in the full analysis set. Secondary endpoints: PFS (ESR1nmd subgroup); overall survival (OS); investigator-assessed confirmed overall response rate; duration of response; clinical benefit rate; time to chemotherapy; safety; patient-reported outcomes.",
            "Conclusion": "The study investigates an oral SERD (giredestrant) plus investigator\u2019s choice of CDK4/6i in patients with 1L ET-resistant ER+, HER2\u2013 LA/mBC."
        },
        {
            "Title": "TACTIVE-K: Phase 1b/2 study of vepdegestrant, a proteolysis targeting chimera (PROTAC) estrogen receptor (ER) degrader, in combination with PF-07220060, a cyclin-dependent kinase (CDK) 4 inhibitor, in ER+/human epidermal growth factor receptor 2 (HER2)- advanced breast cancer.",
            "Indication": "ER+/HER2- advanced breast cancer",
            "Drug": "Vepdegestrant + PF-07220060",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Clinical benefit rate (CBR): 63.0% in phase 1b cohort; CBR of 52.4% in PF-07220060 phase 1/2 study",
            "Conclusion": "The combination of vepdegestrant and PF-07220060 will be evaluated for preliminary antitumor activity, safety, and pharmacokinetics in a phase 1b/2 study."
        },
        {
            "Title": "Implementing geriatric assessment for dose optimization of CDK4/6 inhibitors in older breast cancer patients (IMPORTANT trial): A pragmatic randomized-controlled trial.",
            "Indication": "Advanced HR+/HER2- breast cancer in older patients",
            "Drug": "CDK4/6 inhibitors (abemaciclib, ribociclib, palbociclib) + endocrine therapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: Time to treatment failure; Secondary endpoints: Overall treatment utility, progression-free survival, overall survival, time to chemotherapy initiation, toxicity, quality-of-life, time to quality-of-life deterioration, cost-effectiveness",
            "Conclusion": "The trial aims to investigate whether a lower initial dose of CDK4/6 inhibitors combined with endocrine therapy is comparable to full dose in older patients assessed as vulnerable/frail based on comprehensive geriatric assessment."
        },
        {
            "Title": "ECLECTIC: 18F-fluoroestradiol PET and circulating biomarkers to guide the choice of the second line therapy for ER+, HER2- metastatic breast cancer: A phase 3 trial.",
            "Indication": "ER+, HER2- metastatic breast cancer",
            "Drug": "Second line endocrine therapy or chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary objective: Superiority of chemotherapy over endocrine therapy in selected patient population in terms of PFS; Secondary objectives: Efficacy of endocrine therapy in patients with very favorable features, early changes of circulating biomarkers, overall survival",
            "Conclusion": "The trial aims to demonstrate that the use of 18F-FES PET/CT scan and CTC count can distinguish patients who would benefit from chemotherapy from those experiencing a sustained benefit from second line endocrine therapy."
        },
        {
            "Title": "Innovation of the first-line strategy optimized as abemaciclib with endocrine therapy based on the ESR1 mutation of ctDNA for patients with HR-positive HER2-negative advanced metastatic breast cancer (JBCRG-M08; AMBER study).",
            "Indication": "HR-positive HER2-negative advanced metastatic breast cancer",
            "Drug": "Abemaciclib + endocrine therapy (aromatase inhibitors, fulvestrant)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: 2-year PFS rate; Secondary endpoints: 2-year PFS rate by treatment sequences, rate of disappearance of ESR1 mutations in ctDNA with fulvestrant + abemaciclib",
            "Conclusion": "The study aims to evaluate the efficacy and safety of the strategy to replace aromatase inhibitors with fulvestrant for detectable ESR1 mutations in combination with abemaciclib."
        },
        {
            "Title": "OPERA-01: A randomized, open-label, phase 3 study of palazestrant (OP-1250) vs standard-of-care for ER+, HER2- advanced or metastatic breast cancer patients after endocrine therapy and CDK4/6 inhibitors.",
            "Indication": "ER+, HER2- advanced or metastatic breast cancer",
            "Drug": "Palazestrant",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "INAVO121: Phase III study of inavolisib (INAVO) + fulvestrant (FUL) vs. alpelisib (ALP) + FUL in patients (pts) with hormone receptor-positive, HER2-negative (HR+, HER2\u2013), PIK3CA-mutated (mut) locally advanced or metastatic breast cancer (LA/mBC).",
            "Indication": "HR+, HER2\u2013, PIK3CA-mutated locally advanced or metastatic breast cancer",
            "Drug": "Inavolisib + Fulvestrant",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "Study of the Bria-IMT regimen and CPI vs physicians\u2019 choice in advanced metastatic breast cancer (BRIA-ABC).",
            "Indication": "Advanced metastatic breast cancer",
            "Drug": "Bria-IMT regimen + CPI",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "TBCRC058: A randomized phase II study of enzalutamide, enzalutamide with mifepristone, and treatment of physician\u2019s choice in patients with androgen receptor-positive metastatic triple-negative or estrogen receptor-low breast cancer.",
            "Indication": "Androgen receptor-positive metastatic triple-negative or estrogen receptor-low breast cancer",
            "Drug": "Enzalutamide, Enzalutamide + Mifepristone",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "Clinical utility of ctDNA as a tool to detect triple-negative breast cancer relapses: The CUPCAKE trial.",
            "Indication": "Triple-negative breast cancer (TNBC)",
            "Drug": "ctDNA detection, 68Ga-FAPI-46-PET/CT",
            "PFS": "None",
            "OS": "Overall survival rate at 24 months after randomization",
            "ORR": "None",
            "AE": "None",
            "Other": "Efficacy criteria like progression-free survival, clinical features upon radiological/radiological relapse, performance criteria of 68Ga-FAPI-46 versus 18F-FDG PET/CT",
            "Conclusion": "The study aims to demonstrate the clinical utility of early detection of molecular relapse by ctDNA combined with whole-body mapping of tumor recurrence sites."
        },
        {
            "Title": "Phase I/II study of stereotactic radiation and sacituzumab govitecan with zimberelimab in the management of metastatic triple-negative breast cancer with brain metastases.",
            "Indication": "Metastatic triple-negative breast cancer (TNBC) with brain metastases",
            "Drug": "Sacituzumab govitecan (SG), zimberelimab, stereotactic radiosurgery (SRS)",
            "PFS": "12-month PFS (phase II)",
            "OS": "None",
            "ORR": "None",
            "AE": "Neurologic toxicity defined by CTCAE v5 criteria (phase I)",
            "Other": "None",
            "Conclusion": "The study hypothesizes that the combination of SG and zimberelimab with SRS will be safe and improve progression-free survival compared to treatment with SG alone."
        },
        {
            "Title": "A phase II trial to assess the impact of b2 adrenergic blockade in immune checkpoint inhibitor (ICI)\u2013refractory metastatic triple-negative breast cancer (mTNBC).",
            "Indication": "Metastatic triple-negative breast cancer (mTNBC)",
            "Drug": "Propranolol, pembrolizumab (P)",
            "PFS": "6-month progression-free survival",
            "OS": "Overall survival",
            "ORR": "Objective response rate (ORR)",
            "AE": "Safety",
            "Other": "Changes in tumor and blood immune markers",
            "Conclusion": "The study hypothesizes that using propranolol in an ICI refractory population should improve responses to ICIs."
        },
        {
            "Title": "TOPOLOGY: A phase II study to evaluate the efficacy and toxicities of PLX038, in patients with locally advanced or metastatic triple-negative breast cancer.",
            "Indication": "Locally advanced or metastatic triple-negative breast cancer (TNBC)",
            "Drug": "PLX038",
            "PFS": "Progression-free survival",
            "OS": "Overall survival",
            "ORR": "Best tumor response (partial or complete response in the first 6 months of treatment)",
            "AE": "Toxicity criteria (AE/SAEs)",
            "Other": "Time to response, duration of response, association between PLX038 efficacy and biomarkers, PK/PD analysis",
            "Conclusion": "The study hypothesizes that PLX038 may be effective in patients with advanced TNBC who have been previously treated with standard chemotherapy regimens."
        },
        {
            "Title": "Phase II study of a PARP inhibitor, talazoparib, in HER2- metastatic breast cancer (MBC) with a somatic BRCA1/2 mutation identified in a cell-free DNA or tumor tissue genotyping assay.",
            "Indication": "HER2- metastatic breast cancer (MBC) with a somatic BRCA1/2 mutation",
            "Drug": "Talazoparib",
            "PFS": "Primary endpoint is progression-free survival (PFS) via RECIST 1.1. Study has 81% power to demonstrate the 12-week PFS is \u2265 53% (4% alpha).",
            "OS": "None",
            "ORR": "Secondary endpoints include objective response rate.",
            "AE": "Secondary endpoints include toxicity (NCI CTCAE v5.0).",
            "Other": "Exploratory endpoints include identifying resistance mutations, evaluating the impact of mutant allele fraction and BRCA1/2 reversion mutations on response, and correlating the Cancer Risk B (CR-B) assay with response.",
            "Conclusion": "The study is ongoing with 17 patients enrolled as of 2/2024. The results may expand the population of patients who benefit from PARP inhibitors."
        },
        {
            "Title": "Ensuring precision medicine for veterans with lung cancer: A randomized clinical trial.",
            "Indication": "Non-small cell lung cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Patient knowledge of molecular testing and patient activation scores improved in the intervention group compared to the control group. No significant differences in tumor testing rates between groups.",
            "Conclusion": "A volunteer-led effort improved patient education regarding precision medicine and patient activation among veterans as compared with usual care alone. Interventions that include volunteers and other support can enhance care and clinical outcomes among veterans with lung cancer."
        },
        {
            "Title": "Geriatric assessment in older patients with non-small cell lung cancer: Insights from a cluster-randomized, phase III trial\u2014ENSURE-GA study (NEJ041/CS-Lung001).",
            "Indication": "Non-small cell lung cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "1-year overall survival: 20.7 months (intervention) vs 18.8 months (control), p=0.414",
            "ORR": "None",
            "AE": "Incidence of grade 3 or higher adverse events: 36.8% (intervention) vs 38.1% (control), p=0.732",
            "Other": "No significant differences in patient characteristics or GA domains between intervention and control groups. G8 score had no discriminatory ability for predicting adverse events.",
            "Conclusion": "The implementation of GA and interventions based on its results enhances patient satisfaction. However, additional studies are needed before incorporating GA into an adverse event prediction system. Developing risk scoring tools specific to cancer types and races may prove useful."
        },
        {
            "Title": "Acute care and overall survival results of a randomized trial of a virtual health intervention during routine cancer treatment.",
            "Indication": "Cancer (melanoma, thoracic, gynecological, or head/neck cancers)",
            "Drug": "Integrative Medicine at Home (IM@Home) virtual mind-body fitness program",
            "PFS": "None",
            "OS": "Median overall survival: 24.3 months (EUC) vs not reached (IM@Home), p=0.06",
            "ORR": "None",
            "AE": "None",
            "Other": "Patients in the IM@Home group were less likely to be hospitalized (6.3% vs 19.1%, p=0.038) and spent fewer days in the hospital (4.3 vs 10.5 mean days per patient, p<0.001) during the 12-week study period.",
            "Conclusion": "Participation in the virtual IM@Home program reduced unplanned hospitalizations and number of hospitalization days among patients with cancer receiving systemic treatment. Larger studies with longer follow-up are needed to confirm the effect of virtual supportive care on improving overall survival and reducing acute healthcare utilization."
        },
        {
            "Title": "A randomized study comparing electronic patient-reported outcome (ePRO) monitoring with routine follow-up during trastuzumab deruxtecan treatment in patients with metastatic breast cancer (PRO-DUCE study).",
            "Indication": "HER2-positive metastatic breast cancer",
            "Drug": "Trastuzumab deruxtecan (T-DXd)",
            "PFS": "Median time to first deterioration in Global QoL score was 3.9 months (95% CI 2.5, 13.9) in ePROm and 3.0 months (95% CI 1.6, 6.7) in UC (hazard ratio 0.73 [95% CI 0.45, 1.17]; p=0.159)",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Mean changes from baseline scores in Global QoL at week 24 showed that ePROm was significantly better than UC (mean difference 8.0 [90% CI 0.2, 15.8]; p=0.091). Role, cognitive, and social functioning were better in ePROm, with mean differences of 10.0 (95% CI 1.1, 18.9; p=0.028), 6.3 (1.1, 11.5; p=0.017), and 10.9 (3.9, 18.0; p=0.003), respectively. There was no difference in nausea/vomiting (0.5 [-6.2, 7.1]; p=0.889), while fatigue was significantly better in ePROm (-8.4 [-16.1, -0.6]; p=0.034).",
            "Conclusion": "ePROm systems, especially for symptom and SpO2 tracking in HER2-positive MBC patients treated with T-DXd, are promising to enhance patient QoL."
        },
        {
            "Title": "Evaluation of a complex survivorship intervention incorporating electronic patient-reported outcomes in early stage breast and gynecologic cancer: Results from the Linking You to Support and Advice (LYSA) randomized controlled trial.",
            "Indication": "Early-stage hormone receptor-positive breast or gynecologic cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Engagement with healthcare and other supportive resources was approximately twice more than comparator arm, due to referral. There were no notable between-arm differences with respect to EQ5D5L items at study end. However, experimental arm participants had better EORTC Total scores at study end relative to comparator (difference in means -3.87 95% CI -6.58 to -1.16, p=0.005). There were also significant (p<0.05) differences observed between the arms for 8/12 symptom items reflecting less fatigue, anxiety, and depression in experimental arm at study end.",
            "Conclusion": "The LYSA trial met its primary feasibility endpoint, with high rates of ePRO completion. Secondary endpoint analysis is ongoing including an economic analysis and future studies."
        },
        {
            "Title": "Geriatric assessment and management with question prompt list using a web-based application for elderly patients with cancer to communicate aging-related concerns: A randomized clinical trial (J-SUPPORT 2101 study).",
            "Indication": "Advanced or recurrent cancers of gastrointestinal origin",
            "Drug": "Geriatric assessment and management (GAM) with communication support",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Number of conversations about aging-related concerns was significantly higher in the intervention group (mean [SD] 2.95 [1.53] vs. 1.90 [1.49], p < 0.0001). Number of high-quality conversations and conversations about GAM recommendations were also significantly higher in the intervention group (1.73 [1.40] vs. 1.12 [1.16], p = 0.001; 0.57 [0.74] vs. 0.28 [0.47], p = 0.001, respectively).",
            "Conclusion": "Our program providing GAM recommendations and communication support successfully facilitated aging-related communications between patients and their oncologists. We will further examine whether facilitated communication leads to actual implementation of GAM and improved patient health outcomes in a follow-up study."
        },
        {
            "Title": "Primary treatment modification strategies among older adults with advanced cancer.",
            "Indication": "Advanced cancer",
            "Drug": "Chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Grade 3-5 toxicity: PTM-GA (RR=0.77, 95% CI, 0.61-0.90), PTM-UC (RR, 0.46; 95% CI, 0.29-0.73)",
            "Other": "Subsequent dose reduction: PTM-GA (RR, 0.46; 95% CI, 0.29-0.73); Unplanned hospitalization: PTM-GA (RR, 0.69; 95% CI, 0.46\u20131.02); ADL decline: PTM-GA (RR, 1.05; 95% CI, 0.74\u20131.49)",
            "Conclusion": "Older patients with advanced cancer who had PTM guided by GA recommendations had improved treatment tolerability compared to those who PTM based on oncologist\u2019s estimate only. Integrating GA into treatment dosing decisions may lead to improved outcomes in this vulnerable population."
        },
        {
            "Title": "Quality of life for older patients with metastatic cancer in Brazil: A telehealth-based geriatric assessment and supportive care intervention (GAIN-S).",
            "Indication": "Metastatic cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Improvements in emotional symptoms, functional status, and quality of life; Increased utilization of active coping, acceptance, and positive reframing",
            "Conclusion": "GAIN-S demonstrated significant improvements in emotional well-being, functional status, and quality of life among older patients with metastatic cancer. These findings underscore the potential of such telehealth-driven approaches to enhance the supportive care experience in diverse healthcare settings."
        },
        {
            "Title": "Accuracy of a mobile sensor-based system for the detection of chemotherapy toxicity in older adults with cancer.",
            "Indication": "Cancer",
            "Drug": "Chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Moderate/severe toxicity detected on 22.1% of days; Mild/no toxicity detected on 77.9% of days",
            "Other": "AUC analysis for the development cohort demonstrated that a 32% decrease in steps/day from pre-CT median showed a sensitivity of 77.6% and a specificity of 67.3% for detecting moderate/severe toxicity. Sensitivity and specificity of the cutoff in the validation cohort were 75.8% and 69.6%, respectively.",
            "Conclusion": "A decline in the number of steps/day measured using an accelerometer-equipped smartphone was useful for the remote detection of moderate/severe toxicity in older adults with cancer receiving chemotherapy, with high sensitivity and specificity. This patient-centric measure could be used in clinical practice and research to detect and act promptly on toxicity and, potentially, improve outcomes."
        },
        {
            "Title": "SNF-CLIMEDIN: A prospective randomized trial of digital intervention in patients with advanced NSCLC\u2014A HeCOG study.",
            "Indication": "Advanced Non-Small Cell Lung Cancer (NSCLC)",
            "Drug": "Standard treatment (predominantly immuno-chemotherapy)",
            "PFS": "Median PFS was 7.0 months (95% CI: 5-8), 1-year 18% (38%-55%)",
            "OS": "Median OS: 12 months (11-14), 1-year 47% (38%-55%)",
            "ORR": "CR: 2%, PR: 35.5%, SD: 35%, PD: 10.5%",
            "AE": "Most common AEs: fatigue, cough, anorexia, nausea. More patients submitted AE reports online than their clinicians (89% vs 68% of patients, p < 0.01); more AEs were reported per submission, compared to their clinicians.",
            "Other": "Baseline EQ5D was similar in both arms; comparing post-treatment (6th cycle) results shows higher improvement in all 5 dimensions in arm A vs B, especially in Anxiety/Depression (final values: 1.9 vs 2.2). The mean AE-related costs in Euros in arm A vs B were: hospitalization: 455.4 (95% CI: 91.9-941.5) vs 779.5 (346.6-1328.5) (p < 0.001); diagnostics: 20.3 (0.5-50.8) vs 73.3 (1.3-186.1) (p < 0.001). Follow up is ongoing.",
            "Conclusion": "Digital oncology is feasible, cost-effective by reducing hospitalizations, improves certain AEs and tends to improve QoL of NSCLC patients regardless of clinical and molecular status. Patients report digitally more informative AEs for clinical and research analysis. Online platforms can complement the Oncology team."
        },
        {
            "Title": "TOASTIE: A United Kingdom prospective, multicentre, observational cohort study investigating tolerance of anti-cancer systemic therapy in the elderly.",
            "Indication": "Tolerance of anti-cancer systemic therapy in elderly patients",
            "Drug": "Chemotherapy (CTx)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "69 (22.3%) patients experienced a CTCAE grade \u22653 toxicity and 84 (27%) requiring hospital admission. Increasing CARG risk groups had increased toxicity rates (low 19.6%, medium 22.2%, high 28.2%) however this was non-significant with no evidence of robust predictive performance.",
            "Other": "Baseline demographics and established frailty measures were recorded, including ECOG PS, Rockwood Clinical Frailty Scale (CFS), Geriatric-8 (G8) score and Charlson Co-morbidity Index (CCI). Follow-up data including CTCAE v5 toxicity and hospital admissions were collected retrospectively. The performance of CFS and ECOG PS was superior to CARG.",
            "Conclusion": "In this UK older patient population, baseline frailty was prevalent. CARG score was unable to robustly discriminate or predict risk of high-grade toxicity. ECOG showed superior, albeit limited, ability to predict and discriminate toxicity risk. This study highlights the need for development of further tools predictive of toxicity in this population."
        },
        {
            "Title": "Human-AI teams to improve accuracy and timeliness of oncology trial prescreening: Preplanned interim analysis of a randomized trial.",
            "Indication": "Non-small cell lung cancer (NSCLC) or colorectal cancer (CrCa)",
            "Drug": "Not specified",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Overall chart-level accuracy: Human+AI (78.7%) vs. Human-alone (76.7%), Median time per review: Human+AI (34.1 min) vs. Human-alone (43.9 min)",
            "Conclusion": "Human+AI teams can improve timeliness of trial prescreening with noninferior accuracy. This platform is being used for eligibility assessment in an ongoing clinical trial."
        },
        {
            "Title": "Geographic and racial disparities in bi-specific antibodies trials access for diffuse large B-cell lymphoma.",
            "Indication": "Diffuse large B-cell lymphoma (DLBCL)",
            "Drug": "Bispecific antibodies",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Geographic and racial disparities in accessing bispecific antibodies trials for DLBCL. 69% of trials were only open in the US, with significant disparities in trial access for African Americans.",
            "Conclusion": "There is significant geographic and racial disparity in accessing bispecific antibodies trials for DLBCL. Strategies should be framed to address the causes of the observed disparities and to improve access to these trials."
        },
        {
            "Title": "Unveiling the cost-effectiveness of CDK4/6 inhibitors in treating patients with HR+/HER2- metastatic breast cancer: A closer look at non-medication expenses.",
            "Indication": "HR+/HER2- metastatic breast cancer",
            "Drug": "CDK4/6 inhibitors + Endocrine Therapy (ET)",
            "PFS": "3.2 months gain in progression-free survival",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Incremental Cost Effectiveness Ratio (ICER) of $73,098 per month without progression; average estimated monthly non-medication costs for CDK4/6i+ET group were $2,278 compared to $4,265 for the ET alone group.",
            "Conclusion": "Cost-effectiveness of treating HR+ MBC patients is primarily driven by the cost of CDK4/6i drug prices. However, findings highlight significantly lower overall non-medication healthcare costs using CDK4/6i+ET compared to ET alone in 1L treatment. These cost savings, however, are offset by the high medication costs of CDK4/6i. Thus, lowering the market cost of CDK4/6i drugs or targeting those who can benefit the most could shift the balance in favor of a cost-effective benefit from a Medicare perspective."
        },
        {
            "Title": "TriHealth Cancer Institute\u2019s collaboration with the Tempus AI TIME program impact on clinical trial operations and enrollment.",
            "Indication": "Cancer",
            "Drug": "Not specified",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Operational metrics and financial impact: 8,653,397 unique searches, 2,032 potential matches, 28 patients consented, 7 trials activated, $241,500 total trial billable revenue.",
            "Conclusion": "TriHealth\u2019s partnership with TIME evaluated 18,800+ patients for 135 trials. Over 8.6M unique TApp searches resulted in 2,000+ patients screened by nurses. Operationally, TIME trials resulted in a 2.3X increase in the number of trial activations, an 80% reduction in days to activation, and a 9.3-fold increase in enrollment compared to non-TIME studies. The use of AI-enabled patient screening, combined with a structured trial activation process, improved patient enrollment at a community health system."
        },
        {
            "Title": "A machine learning algorithm based on multi-omics biomarkers for the detection of tumor microsatellite instability.",
            "Indication": "Tumor microsatellite instability (MSI)",
            "Drug": "Immune checkpoint inhibitors (ICI) such as pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Model performance: Sensitivity 95.8%, Specificity 93.7%, PPV 80.8%, NPV 98.8%.",
            "Conclusion": "Our ML-driven approach accurately assessed MSI status of CRC and EMCA using CGIP data. This approach also identified potential cases with MSI-high status where direct sequencing of microsatellites failed. This study highlights a method to identify patients with potential MSI-high status for orthogonal screening when the MSI component of a test fails."
        },
        {
            "Title": "Multimodal fully automated predictive model for therapeutic efficacy of first-line cancer immunotherapy based on clinical information and imaging modalities including brain MRI and chest CT images in advanced non-small cell lung cancer.",
            "Indication": "Advanced non-small cell lung cancer (NSCLC)",
            "Drug": "Immune checkpoint inhibitors (ICI) + platinum-based chemotherapy",
            "PFS": "Median PFS: 7.1 months (95% CI: 5.8-9.1)",
            "OS": "Median OS: 24.5 months (95% CI: 21.2-30.3)",
            "ORR": "Overall response rate (ORR): 49.5%",
            "AE": "None",
            "Other": "Model performance: AUC of 0.70 for predicting response, 0.65 for 12-month PFS, and 0.69 for 12-month OS. High-risk group median PFS: 6.8 months, Low-risk group median PFS: 14.7 months. High-risk group median OS: 21.5 months, Low-risk group median OS: 34.3 months.",
            "Conclusion": "We have demonstrated that a multimodal, fully automated ensemble model has significant predictive accuracy for the efficacy and survival outcomes of ICI + platinum-based chemotherapy in patients with advanced NSCLC."
        },
        {
            "Title": "Impact of artificial intelligence (AI) decision support on clinical trial participation: A before-after implementation study on a nationwide molecular tumor board.",
            "Indication": "Advanced cancer",
            "Drug": "Not specified",
            "PFS": "None",
            "OS": "No OS difference seen comparing before with after implementation of the DSS (median 16.7 vs 15.3 months, adjusted HR 0.97, 0.87\u20131.08). However, patients receiving genomic matched Rx had a longer OS (median 20.1 vs 15.0 months unmatched, HR 0.76, p=0.002).",
            "ORR": "None",
            "AE": "None",
            "Other": "Cumulative participation rate (CPR) in genomic matched trials at 3, 6, and 12 months after enrollment were 3.2%, 5.0%, and 6.8% before implementation and 3.6%, 5.8%, and 8.9% afterwards (sHR 1.19, 95% CI 0.90\u20131.57, p=0.23). Patients who received an MTB recommendation were more likely to participate in a matched trial (sHR 4.75, 3.13\u20137.23, p<0.001). DSS specifically increased the likelihood of trial participation following MTB recommendation (interaction sHR 2.69, 1.02\u20137.07, p=0.045).",
            "Conclusion": "The observed interaction between the implementation of DSS and MTB recommendation suggests there is effect modification in participation rates of patients in genomic matched trials, warranting exploration into how AI might enhance trial participation among patients with incurable cancers undergoing CGP."
        },
        {
            "Title": "Efficient site selection for clinical trials using simulated annealing.",
            "Indication": "Multiple Myeloma",
            "Drug": "Not specified",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The study formed a cohort of 15,881 patients across 1,246 US clinical trial sites. Each site had 12 features. The weights optimized patient diversity while enrolling at least 10 patients per month across all sites to achieve the targeted study accrual timeline. The result was a set of 3 sites enrolling 12 patients per month with 51% diversity.",
            "Conclusion": "Our study introduced a robust, efficient, and interpretable approach to clinical trial site selection. It addressed the challenges posed by increasingly complicated protocols, requirements for study participant diversity, and heterogeneous site features. The multi-AI model scalability underscored its practical utility in streamlining the site selection process for clinical research networks, academic centers, industry-sponsored trials, cooperative group studies, and investigator-initiated studies."
        },
        {
            "Title": "Continuous remote monitoring of performance status in patients with NSCLC through fitness tracker\u2013derived life-log data.",
            "Indication": "Non-Small Cell Lung Cancer (NSCLC)",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Significant reductions in average step counts and walking distance for unscheduled visits compared to regular visits; significant increase in resting heart rate for unscheduled visits; significant correlation between PRO-based PROMIS physical function scores and EQ-5D-3L scores with resting HR and average step counts.",
            "Conclusion": "FT-derived life-log data effectively captured dynamic performance status in NSCLC patients, correlating with clinical outcomes and PRO. These findings support further research into FT data\u2019s predictive value for clinical interventions, underscoring its potential in personalized remote patient management."
        },
        {
            "Title": "Outcomes of very young women and adolescent (VYWAA) with breast cancer (BC) in rural and urban Saskatchewan: A population-based cohort study.",
            "Indication": "Breast Cancer (BC)",
            "Drug": "None",
            "PFS": "None",
            "OS": "10-year OS of all VYWAA was 69%. 10-year OS of urban women was 79% compared to 65% for rural residents (p=0.041). 10-year OS of women age >30 years was 74% compared to 62% for age 26-30 years and 55% for age <26 (p=0.35). 10-year OS of VYWAA with non-TNBC was 73% compared to 63% for those with TNBC (p=0.028).",
            "ORR": "None",
            "AE": "None",
            "Other": "Median DFS of VYWAA with stage I to III disease has not reached. 10-year DFS for stage I and II was 73% and 75%, respectively, vs. 43% for stage III (p<0.001). On multivariate analysis for women with early-stage disease, stage III BC, HR 3.0 (1.80-4.92) and grade III BC were significantly correlated with inferior DFS. On multivariate analysis for OS of all VYWAA with stage 0 to IV disease, rural residence, HR 1.75 (1.08-2.81); TNBC, HR 2.25 (1.34-3.80), and stage IV disease, HR 8.1 (4.72-13.94) were correlated with inferior OS.",
            "Conclusion": "Very young women and adolescents with BC have a high incidence of node-positive disease and HER2+ and TNBC subtypes. TNBC and grade III disease were associated with inferior DFS in stage I-III disease whereas stage IV BC, TNBC, and rural residence were correlated with inferior survival in women with stage 0-IV disease."
        },
        {
            "Title": "Real world study of racial disparities associated with toxicities of sacituzumab govitecan",
            "Indication": "Metastatic hormone receptor positive and triple negative breast cancer",
            "Drug": "Sacituzumab govitecan",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Anemia: increased odds for AA; Neutropenia: decreased odds for AA; Hospitalization: increased odds for AA",
            "Other": "AA had a 1.26 times higher hazard of SG treatment pause/cessation compared to C; Males had an 80% increase in the pause/cessation rate compared to females",
            "Conclusion": "Survival analysis indicates that AA and male patients have an increased SG treatment pause/cessation rate. AA experience increased odds for anemia and late-stage hospitalization. These findings highlight the impact of race on BC outcomes, treatment toxicities, and the need for further research to enhance treatment outcomes in diverse populations."
        },
        {
            "Title": "Inequities in timely treatment (Tx) initiation for patients (pts) with acute myeloid leukemia (AML) treated in a predominantly community setting in the United States (US)",
            "Indication": "Acute myeloid leukemia",
            "Drug": "Intensive chemotherapy (IC) or non-IC",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Fewer POC vs White pts receiving IC underwent SCT post-remission (30.8% vs 47.7%, aOR 0.44, p < .001)",
            "Conclusion": "In a predominantly US community setting, numerical but non-significant race/ethnic disparities in timely 1L Tx initiation were observed for pts with ND AML. Fewer POC vs White pts receiving IC underwent SCT post-remission. Analyses are ongoing to assess mediators and impact on survival."
        },
        {
            "Title": "Dissecting racial/ethnic disparities in non-small cell lung cancer staging at diagnosis: Intra-ethnic and geographic differences",
            "Indication": "Non-small cell lung cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Black patients had 22% higher odds (OR=1.22; 95% CI: 1.21-1.23) and Hispanic patients had 11% higher odds (OR=1.11; 95% CI: 1.10-1.12) of non-resectable stage across all subgroups diagnoses compared to White patients. Regional differences were significant, with Hispanic patients in South Florida showing more favorable staging outcomes than Whites (ORadj=0.95; 95% CI: 0.94-0.97), while in the rest of Florida, Hispanic patients were at a disadvantage (ORadj=1.09; 95% CI: 1.07-1.10).",
            "Conclusion": "Pronounced NSCLC staging disparities are manifest in Black and Hispanic populations, coupled with striking regional variations among Florida\u2019s Hispanic groups which underscores the necessity for targeted research to dissect and address the underlying causes of these inequities in the cancer care continuum."
        },
        {
            "Title": "Racial disparities in the immunotherapeutic outcomes of patients with non-small cell lung cancer (NSCLC): An in-depth systematic review and meta-analysis.",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "Immunotherapy",
            "PFS": "No significant difference in PFS when comparing Black/African American, Asian, or Hispanic patients to White patients (HR: 0.93; 95% CI: 0.79\u20131.09; p=0.35, HR: 0.89; 95% CI: 0.51\u20131.55; p=0.69, and HR: 1.01; 95% CI: 0.82\u20131.23; p=0.96, respectively)",
            "OS": "Black/African American and Asian NSCLC patients receiving immunotherapy had improved overall survival (OS) than White patients (HR: 0.84; 95% CI: 0.75\u20130.95; p=0.006 and HR: 0.53; 95% CI: 0.30\u20130.93; p=0.03, respectively). No significant difference for Hispanic patients compared to White patients (HR: 0.68; 95% CI: 0.46\u20131.00; p=0.05)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Among non-small cell lung cancer (NSCLC) patients undergoing immunotherapeutic interventions, it is discerned that Black/African American and Asian individuals exhibit superior overall survival (OS) outcomes compared to their White counterparts. However, the observed racial disparity does not appear to exert a discernible influence on the progression-free survival (PFS) of NSCLC patients subjected to immunotherapy."
        },
        {
            "Title": "Association of immune checkpoint inhibitor introduction and changes in survival disparities by health insurance coverage among individuals newly diagnosed with advanced cancers in the US.",
            "Indication": "Advanced cancers (Melanoma, HR+HER- female breast cancer, non-small cell lung cancer (NSCLC), renal cell carcinoma (RCC))",
            "Drug": "Immune checkpoint inhibitors (ICIs)",
            "PFS": "None",
            "OS": "Melanoma: 2-year survival rate increased from 16.33% pre-ICI approval to 27.04% post-ICI approval among uninsured patients and from 28.22% to 45.45% among privately insured patients, with disparity by health insurance status widened by 4.58 percentage points (ppt) after adjusting for sociodemographic factors. NSCLC: Disparity by health insurance widened by 1.58 ppt.",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The introduction of ICIs increased disparities in survival by health insurance status. Policies to improve health insurance coverage options or make new treatments more affordable to uninsured patients are needed."
        },
        {
            "Title": "Pharmacokinetics (PK) and pharmacogenomics (PGx) of ribociclib (ribo) in Black patients with metastatic breast cancer (mBC): The LEANORA study.",
            "Indication": "Metastatic breast cancer (mBC)",
            "Drug": "Ribociclib (ribo)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Non-statistically significant higher number of AEs and grade 3+ AEs in PMs compared to NM/IMs",
            "Other": "Primary endpoint, AUCtau, was similar between CYP3A5 PM and IM/NM groups. Other PK properties were similar between groups.",
            "Conclusion": "This cohort study detected no association between CYP3A5 genotype and ribo exposure. However, PMs may have more AEs relative to IMs/NMs. Future steps include exploring the impact of rare variants on ribo exposure in this population and the role of clinical and genetic factors on the interindividual variability of ribo. Diverse patient representation in clinical trials is critical to ensure research findings are applicable to all patients."
        },
        {
            "Title": "Racial disparities and survival outcome of patients with locally advanced cervix cancer in the international randomised phase 3 OUTBACK trial",
            "Indication": "Locally advanced cervix cancer",
            "Drug": "Adjuvant chemotherapy following definitive chemoradiation",
            "PFS": "HR 1.29; 95% CI, 0.95\u20131.75; p=0.10",
            "OS": "HR 1.2; 95% CI, 0.84\u20131.72; p=0.32",
            "ORR": "None",
            "AE": "None",
            "Other": "Independent prognostic factors for overall survival include performance status (HR 1.43; 95% CI, 1.04-1.95; p=0.03), FIGO stage (HR 2.02; 95% CI, 1.48-2.75; p<0.01), nodal involvement (HR 1.59; 95% CI, 1.18-2.14; p<0.01), and elevated white cell count (HR 1.68; 95% CI, 1.21-2.33; p<0.01).",
            "Conclusion": "No discernible disparities in survival outcomes were observed between Black or African American participants and other racial groups undergoing primary treatment for locally advanced cervix cancer. The primary prognostic determinants for this disease continue to be cancer staging and clinical performance status. Enrolment into a clinical trial may have abrogated the historical disparities seen among ethnic and racial groups."
        },
        {
            "Title": "Improving ethnic and racial diversity in biomarker-driven clinical trials: A proof of concept with the BASECAMP-1 master prescreening study of patients with high-risk solid tumors with human leukocyte antigen-A*02 (HLA-A*02) loss of heterozygosity (LOH).",
            "Indication": "High-risk solid tumors",
            "Drug": "Tmod chimeric antigen receptor T-cell therapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Eligibility expansion identified 62 patients with HLA-A*02:XX heterozygosity, improving diversity in the trial.",
            "Conclusion": "By expanding enrollment to include HLA-A*02:XX, 16% more Hispanic, 43% more African American, and 112% more Asian or Pacific Islander patients were identified, improving the diversity in this trial compared with that of initial enrollment."
        },
        {
            "Title": "Decentralized trial recruitment methods to facilitate broad coverage across urban and rural counties for a blood-based test in early colorectal cancer detection.",
            "Indication": "Colorectal Cancer",
            "Drug": "MyTectCRC",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Demographic characteristics and lifestyle behaviors of participants from rural and urban areas were analyzed.",
            "Conclusion": "Through the use of a DCT, the PREEMPT CRC study reached rural areas especially through DTP methodology. Furthermore, the use of SB versus DTP showed certain shifts in demographics, these differences highlight the effectiveness of targeted outreach strategies and reflect the importance for integration of multiple methods and tools to enhance essential population characteristics across geographical locations."
        },
        {
            "Title": "The effect of a multilevel community health worker-led intervention on patient knowledge and receipt of precision medicine: Updated findings from a randomized clinical trial.",
            "Indication": "Cancer",
            "Drug": "Precision Medicine",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Precision medicine knowledge at 6 months was higher in the intervention group (39.2 \u00b1 23.0) compared to the control group (24.2 \u00b1 20.0). More intervention group participants (79.3%) received precision medicine testing than control (60.7%).",
            "Conclusion": "CHW-facilitated interventions may reduce disparities in precision medicine cancer care."
        },
        {
            "Title": "Effect of a 12-week remote exercising program (ExIOnc) on quality of life and symptoms of older patients with cancer in Brazil.",
            "Indication": "Cancer (various types)",
            "Drug": "None (exercise program)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Significant improvement in overall HRQOL and symptom severity (pain, fatigue, nausea, depression, anxiety, drowsiness, appetite)",
            "Conclusion": "This study demonstrates a positive impact of a 12-week remote physical activity program on HRQOL and symptom management for older patients with cancer, and sets the stage for future randomized trials. Despite the challenges posed by cancer treatment, incorporating such programs into patient care could be an effective strategy to enhance overall well-being and mitigate treatment-related symptoms."
        },
        {
            "Title": "Accelerated epigenetic aging and risk of chemotoxicity in older adults with early breast cancer.",
            "Indication": "Early breast cancer",
            "Drug": "Chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Grade 3+ chemotoxicity: 46% overall; higher odds in patients with AEA (AgeAccelGrim \u2265 6.83 years: OR=2.73, 95% CI 1.17-6.41, p=0.02; DunedinPACE \u2265 1.17 biological years per chronological year: OR=2.20, 95% CI 1.18-4.10, p=0.01)",
            "Other": "None",
            "Conclusion": "In this cohort of older women with early breast cancer, those with pre-chemo AEA had increased odds of chemotoxicity compared to women without any evidence of AEA. Future research is needed to examine whether measures of biological age can be translated to the clinical care of older patients with breast cancer."
        },
        {
            "Title": "Start low, go slow: A systematic review and meta-analysis of upfront tailored treatment dosing in older adults with advanced cancer.",
            "Indication": "Advanced cancer in older adults",
            "Drug": "Systemic therapy with a 'start low, go slow' (SLGS) approach",
            "PFS": "No significant differences in PFS across all trials",
            "OS": "No significant differences in OS across all trials",
            "ORR": "None",
            "AE": "Lower grade \u22653 adverse events compared to standard dose (meta-analysis RR 0.88, 95% CI 0.80-0.94, p < 0.001)",
            "Other": "Greater completion of planned cycles with SLGS compared to standard dose (1 study, 43% vs 26%, p = 0.04); Treatment discontinuation was not different for SLGS vs. standard dose (meta-analysis RR 1.07, 95% CI 0.90-1.27, p = 0.42)",
            "Conclusion": "Older adults pursuing a SLGS strategy had greater completion of planned cycles, reduced toxicity, and similar survival compared to standard-dose systemic therapy."
        },
        {
            "Title": "Assessment of endocrine therapy adherence in a nurse-led cancer survivorship clinic: Results from the Linking You to Support and Advice (LYSA) trial.",
            "Indication": "Early-stage breast cancer",
            "Drug": "Adjuvant endocrine therapy (aromatase inhibitors, tamoxifen, ovarian suppression combinations)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Adherence rates at 12 months: 74% overall (77% experimental, 71% comparator, p = 0.4); Non-adherence rates: 2.9%; Reasons for non-adherence: 'simply missing it' (10%), 'experienced side effects' (5%)",
            "Conclusion": "Adjuvant endocrine therapy adherence was good, with 74% reporting adherence rates of \u226580% at 12 months. Post-menopausal patients were more likely to report excellent adherence."
        },
        {
            "Title": "Multi-cancer early detection (MCED) test performance in cancer survivors.",
            "Indication": "Cancer survivors at risk for recurrent or new primary tumors",
            "Drug": "Blood-based MCED test",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "PPV: 4.5% (95% CI 2.5-8.2) in prior cancer group, 4.2% (95% CI 2.7-6.8) in no prior cancer group; NNTS: 179 (range 101-406) in prior cancer group, 311 (range 207-552) in no prior cancer group; CSO accuracy: 88.9% (95% CI 56.5-99.4) in prior cancer group, 81.2% (95% CI 57.0-93.4) in no prior cancer group",
            "Conclusion": "The MCED test detected both cancer recurrences and new primaries in cancer survivors, potentially expanding surveillance options for this patient group. Test performance was similar in those with and without a cancer history."
        },
        {
            "Title": "Facilitating adaptive coping with fear of recurrence among breast cancer survivors: Feasibility and acceptability outcomes from a three-arm randomized controlled trial.",
            "Indication": "Breast cancer",
            "Drug": "Acceptance and commitment therapy (ACT), Cognitive behavioral therapy (CBT), Enhanced usual care (EUC)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "High accrual and attendance rates; engagement ratings: ACT (9.3), CBT (9.5), EUC (7.9); helpfulness ratings: ACT (7.2), CBT (7.9), EUC (5.5); retention: 93.8% through 6-month follow-up.",
            "Conclusion": "High accrual and attendance rates suggest that BCS are receptive to FCR interventions and may find ACT or CBT more engaging and helpful than a single session of survivorship coaching."
        },
        {
            "Title": "Mobile health\u2013supported comprehensive intervention model (CIMmH) improved physical fitness of patients with esophageal cancer after esophagectomy: Prospective randomized controlled trial.",
            "Indication": "Esophageal cancer",
            "Drug": "Comprehensive Intervention Model (CIMmH)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Significant improvement in six-minute walk distance (SMWD) at 1 month (P=0.020), significant increase in skeletal muscle index at 3 months (P=0.039), notable improvements in the 30-second arm curl test and cough symptom relief (P=0.027 and P=0.045, respectively). No significant differences in QOL scores or body weight between groups at any time point.",
            "Conclusion": "CIMmH significantly enhances short-term physical fitness in EC patients post-esophagectomy. These findings suggest the need for further refinement and optimization of the intervention to maximize its long-term effectiveness and impact on QOL."
        },
        {
            "Title": "Patient-reported experience with an immunotherapy telehealth platform.",
            "Indication": "Non-small cell lung cancer, genitourinary cancer, melanoma",
            "Drug": "Pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Patient experience with telehealth platform for extended dosing of pembrolizumab. Median net promoter score was 9 (range 2-10). 94% of patients perceived a benefit to the MATCH-IO televisit.",
            "Conclusion": "Patients endorsed an enhanced experience with an immunotherapy telehealth platform for extended dosing of pembrolizumab. Further follow-up is needed to confirm these experience findings and determine whether this platform improved efficiency through fewer in-person visits."
        },
        {
            "Title": "Establishing a protocol to increase racial/ethnic minority enrollment on an active radiation oncology randomized clinical trial.",
            "Indication": "Symptomatic spine metastases",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Protocol to increase minority enrollment in the SPORTSMEN clinical trial (NCT05617716).",
            "Conclusion": "Increasing clinical trial diversity is a challenge that must be addressed with meaningful intent to present robust Level 1 data that broadens the understanding of treatment response in all demographics."
        },
        {
            "Title": "Breaking barriers for cancer clinical trials: A multi-tiered interventional approach to boost racial and ethnic minority participation in early-phase studies.",
            "Indication": "Cancer (general)",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Interventional study to enhance referral and participation of racial and ethnic minority patients in early-phase clinical trials.",
            "Conclusion": "The interventions may provide a model that can be adapted in other healthcare delivery settings to optimize referral and enrollment of racial and ethnic minorities."
        },
        {
            "Title": "A new model to enhance enrollment of diverse participants to therapeutic clinical trials.",
            "Indication": "Cancer (general)",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Development of bi-directional matching software program (Golgi-CONNECT) to improve diversity in clinical trial participation.",
            "Conclusion": "The software will be piloted to evaluate its functionality and usability, aiming to improve diversity of clinical trial participation."
        },
        {
            "Title": "A phase IV study of ApricityCARE program for cancer adverse events rapid evaluation to improve treatment outcomes of ethnic/racial minority patients with non-small cell lung cancer (NSCLC) receiving immune checkpoint inhibitors (ICI).",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "Immune checkpoint inhibitors (ICI)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Using technology-enabled health interventions in a culturally competent manner can improve access to health care resources and reduce health disparities."
        },
        {
            "Title": "Developing behavioral intervention to support molecular testing of patients with biliary tract cancer.",
            "Indication": "Biliary tract cancer (BTC)",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Identifying knowledge gaps among BTC patients concerning tumor genetic testing and precision medicine, along with strategies to address these gaps, holds promise for improving the management and the outcome of patients with BTC."
        },
        {
            "Title": "Promoting lung cancer screening in Hispanic head and neck cancer survivors.",
            "Indication": "Lung cancer in Hispanic head and neck cancer survivors",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Our primary aim is to assess awareness and disposition to lung cancer screening (LCS) among Hispanic head and neck cancer survivors. Secondary aims include learning barriers to LCS, creating and testing a tailored LCS program, and comparing the rates of LCS uptake between arms at baseline and year 1."
        },
        {
            "Title": "Serial testing to assess cognitive function in patients with cancer being treated with immunotherapy.",
            "Indication": "Cancer patients being treated with immunotherapy",
            "Drug": "Immunotherapy (IT)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The primary objective is to assess the cognitive function change in each individual group of patients. The secondary objective is to assess change of MoCA scores after 3 months and 6 months in patients receiving immunotherapy alone in lung cancer patients."
        },
        {
            "Title": "A phase II randomized double-blind placebo-controlled study of fisetin to improve physical function in frail older breast cancer survivors (TROFFi).",
            "Indication": "Frailty in older breast cancer survivors",
            "Drug": "Fisetin",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary objective: Change in 6-minute walk distance (6MWD) from baseline to end of treatment. Secondary objectives: Quality of life, fatigue, cognition, neuropathy, biomarkers of aging, safety, and adherence.",
            "Conclusion": "Targeting senescent cells with fisetin after chemotherapy may improve physical function in frail older breast cancer survivors by reducing systemic senescent cell burden."
        },
        {
            "Title": "The LIAMMc trial (NCT05946993).",
            "Indication": "Genitourinary (GU) cancers",
            "Drug": "Enhanced supportive care programme",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: Feasibility of introducing an enhanced supportive care programme. Secondary endpoints: Acceptability, satisfaction with the programme, and quality of life changes.",
            "Conclusion": "The study aims to evaluate the feasibility and impact of a holistic 12-week survivorship programme among men with advanced GU cancers."
        },
        {
            "Title": "Neuro-oncology anywhere: A decentralized approach to clinical trials in brain tumors and beyond.",
            "Indication": "Primary and secondary central nervous system (CNS) malignancy",
            "Drug": "Metformin",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary objective: Assess neurocognitive function and related symptoms. Secondary objectives: Real-world cognitive and quality-of-life data, effectiveness of device-based health promotion with or without metformin.",
            "Conclusion": "This decentralized clinical trial will provide real-world cognitive and quality-of-life data for patients with primary and metastatic brain tumors while providing preliminary data on the effectiveness of device-based health promotion with or without metformin for treating radiation-related cognitive decline."
        },
        {
            "Title": "N2M2/NOA-20: Phase I/IIa umbrella trial of molecularly matched targeted therapies plus radiotherapy in patients with newly diagnosed glioblastoma without MGMT promoter hypermethylation.",
            "Indication": "Newly diagnosed glioblastoma without MGMT promoter hypermethylation",
            "Drug": "Alectinib, idasanutlin, palbociclib, vismogedib, temsirolimus, atezolizumab, asunercept, TMZ",
            "PFS": "TMZ: PFS-6 of 18.52% (10/54 patients) (p=0.831); Asunercept: PFS-6 of 15.4% (4/26) (p=0.8825); Atezolizumab: PFS-6 of 21.4% (9/42) (p=0.660); Palbociclib: PFS-6 of 24.4% (10/41) (p=0.4823); Temsirolimus: PFS-6 of 39.1% (18/46) (p=0.0109)",
            "OS": "TMZ: OS of 12.1 months; Asunercept: OS of 12.8 months; Atezolizumab: OS of 11.7 months; Palbociclib: OS of 12.6 months; Temsirolimus: OS of 15.4 months",
            "ORR": "None",
            "AE": "Regimen-limiting toxicity (RLT) rate is 34.8%, with most RLTs having severity grade 3, one RLT had severity grade 4. No RLTs resulted in death.",
            "Other": "None",
            "Conclusion": "N2M2 allows for elaborate molecular testing being integrated into the treatment decision and efficient determination of treatment activity for patients with newly diagnosed glioblastoma. There is clinical activity of temsirolimus in patients with tumors harboring an activated mTOR pathway although this is not positively prognostic without mTOR inhibition; there is no clinical activity for asunercept and atezolizumab in not molecularly selected patients and also palbociclib in molecularly selected patients."
        },
        {
            "Title": "Alliance A071401: Phase II trial of abemaciclib in patients with grade 2/3 meningiomas harboring somatic NF2 or CDK pathway alterations.",
            "Indication": "Grade 2/3 meningiomas with somatic NF2 or CDK pathway alterations",
            "Drug": "Abemaciclib",
            "PFS": "PFS6 rate was 54% (13/24 pts, 95% confidence interval 33-75%)",
            "OS": "None",
            "ORR": "No objective responses were observed",
            "AE": "Grade 3 and 4 adverse events included anemia (2), neutropenia (2), leukopenia (1), blurry vision (1), diarrhea (2), fatigue (2), ALT elevation (1), dehydration (1), hyperkalemia (1), hyponatremia (1), dizziness (1), acute kidney injury (1), thromboembolic event (1), Grade 4 toxicities included ALT elevation (1), AST elevation (1), and vomiting (1)",
            "Other": "None",
            "Conclusion": "Abemaciclib was well tolerated and resulted in an improved PFS6. The overall trial endpoint was met. Abemaciclib warrants further investigation for the treatment of patients with progressive grade 2/3 meningiomas."
        },
        {
            "Title": "Niraparib efficacy in patients with newly-diagnosed glioblastoma: Clinical readout of a phase 0/2 'trigger' trial.",
            "Indication": "Newly diagnosed glioblastoma",
            "Drug": "Niraparib",
            "PFS": "Median progression-free survival was 11.7 months",
            "OS": "Mature overall survival (OS) data will be reported for the first time",
            "ORR": "None",
            "AE": "Five patients in Phase 2 experienced Grade 4 thrombocytopenia related to niraparib. All adverse events resolved without sequelae.",
            "Other": "None",
            "Conclusion": "Niraparib achieves pharmacologically relevant concentrations in non-enhancing, newly-diagnosed GBM tissue in excess of any other studied PARP inhibitor. Accompanying PD effects were observed in patient tumor tissue. For the first time, we report on the clinical efficacy of the study. A global Phase 3, open-label, randomized 2-arm study comparing niraparib versus temozolomide in adult patients with newly diagnosed, MGMT unmethylated glioblastoma is being planned."
        },
        {
            "Title": "Efficacy and safety of vebreltinib in patients with previously treated, secondary glioblastoma/IDH mutant glioblastoma with PTPRZ1-MET Fusion Gene (FUGEN): A randomised, multicentre, open-label, phase II/III trial.",
            "Indication": "Previously treated, secondary glioblastoma/IDH mutant glioblastoma with PTPRZ1-MET Fusion Gene",
            "Drug": "Vebreltinib",
            "PFS": "Median PFS in the vebreltinib group was 1.87 months (95% CI, 1.41-2.76) and in the chemotherapy group was 1.05 months (95% CI, 0.95-1.77). The HR for PFS was 0.54 (95% CI, 0.33-0.88; P=0.014)",
            "OS": "Median OS in the vebreltinib group was 6.31 months (95% CI, 4.44-8.77) and in the chemotherapy group was 3.38 months (95% CI, 2.37-4.27). The HR for OS was 0.52 (90% CI, 0.32-0.85; P=0.009)",
            "ORR": "No significant differences were observed in ORR (9.5% vs. 2.6%) for the vebreltinib group and chemotherapy group",
            "AE": "Treatment-related adverse events of grade 3 or 4 were reported in 7% of the patients in the vebreltinib group, as compared with 12.2% of those in the chemotherapy group. No treatment-related deaths were observed.",
            "Other": "None",
            "Conclusion": "These results of the FUGEN trial support the use of vebreltinib as the first target therapy in patients with previously treated, PTPRZ1-MET fusion gene positive, secondary glioblastoma/IDH-mutant glioblastoma, and shed light on a novel therapeutic pathway in the landscape of IDH-mutant high-grade gliomas."
        },
        {
            "Title": "Survival outcomes associated with first-line PCV or temozolomide in combination with radiotherapy in IDH-mutant 1p/19q-codeleted grade 3 oligodendroglioma.",
            "Indication": "IDH-mutant 1p/19q-codeleted grade 3 oligodendroglioma",
            "Drug": "Procarbazine, CCNU, and Vincristine (PCV) and Temozolomide (TMZ)",
            "PFS": "None",
            "OS": "Median OS was not reached (95% CI: NR-NR) for PCV/RT and 140 months (95% CI: 110-NR) for TMZ/RT. 5-year OS: PCV/RT: 89% (95% CI: 85-94), TMZ/RT: 75% (95% CI: 66-84). 10-year OS: PCV/RT: 72% (95% CI: 61-85), TMZ/RT: 60% (95% CI: 49-73). HR 0.53 for PCV/RT (95% CI: 0.30-0.92, P=0.025)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "First-line PCV/RT was associated with better OS outcomes compared to TMZ/RT. Further investigation using prospective randomized studies is warranted."
        },
        {
            "Title": "Evaluation of VAL-083 in GBM AGILE, a phase 3 registration platform trial for newly diagnosed and recurrent glioblastoma.",
            "Indication": "Newly diagnosed and recurrent glioblastoma",
            "Drug": "VAL-083",
            "PFS": "None",
            "OS": "VAL did not increase OS compared to control in any glioblastoma subtype. Mean HR for VAL in various subtypes: NDU: 0.86 (0.56, 1.26), NDM: 1.00 (0.53, 1.71), RD: 1.08 (0.78, 1.45), ND: 0.91 (0.6, 1.33), All: 0.99 (0.76, 1.26)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "GBM AGILE is an efficient and effective model for phase 3 drug development. VAL did not increase OS compared to control in any glioblastoma subtype."
        },
        {
            "Title": "A pilot study of axicabtagene ciloleucel (axi-cel) for relapsed/refractory primary and secondary central nervous system lymphoma (PCNSL and SCNSL).",
            "Indication": "Relapsed/refractory primary and secondary central nervous system lymphoma (PCNSL and SCNSL)",
            "Drug": "Axicabtagene ciloleucel (axi-cel)",
            "PFS": "Median PFS was 14.3 months (95% CI: 6.3-NR)",
            "OS": "Median OS was 26.4 months (95% CI: 11.2-NR)",
            "ORR": "94% (17/18); 67% CR (12/18)",
            "AE": "16/18 (89%) patients developed cytokine release syndrome (CRS) (grade 3+, 0%); 8/18 (44%) developed immune effector cell-associated neurotoxic syndrome (ICANS), 5/18 (28%) grade 3+. Two patients developed Ommaya-related meningitis requiring explant with recovery. One patient had grade 3 seizures that resolved with anti-epileptic agents.",
            "Other": "None",
            "Conclusion": "Axi-cel was safe and well-tolerated in CNSL patients with encouraging efficacy and median PFS and durability of response of more than 1 year. There was no apparent additional risk of adverse neurologic events including ICANS from axi-cel."
        },
        {
            "Title": "High-dose almonertinib in treatment-na\u00efve EGFR-mutated NSCLC with CNS metastases: Efficacy and biomarker analysis.",
            "Indication": "EGFR-mutated non-small cell lung cancer (NSCLC) with CNS metastases",
            "Drug": "Almonertinib",
            "PFS": "Median PFS of 17.71 months (11.96-NE)",
            "OS": "None",
            "ORR": "Confirmed ORR was 88.9% (56/63), with 42.9% (27/63) of patients progressing. Intracranial lesions achieved either a CR in 21 cases (33.3%) or a PR in 35 cases (55.6%)",
            "AE": "None",
            "Other": "Higher baseline MedSR values predicted significantly poorer PFS (p = 0.001, HR = 0.22) and intracranial PFS (p = 0.067, HR = 0.35). On Cycle 2, those achieving ctDNA clearance in C2D1 exhibited significantly improved PFS (p < 0.001, HR = 4.63) and intracranial PFS (p = 0.001, HR = 5.71).",
            "Conclusion": "High dose Almonertinib exhibited promising efficacy and maintained tolerable safety as a first-line therapy in EGFR-mutated NSCLC with CNS metastases. The novel MedSR score demonstrated potential value in predicting the efficacy of TKI treatment."
        },
        {
            "Title": "Results from METIS (EF-25), an international, multicenter phase III randomized study evaluating the efficacy and safety of tumor treating fields (TTFields) therapy in NSCLC patients with brain metastases.",
            "Indication": "Non-Small Cell Lung Cancer (NSCLC) with brain metastases",
            "Drug": "Tumor Treating Fields (TTFields) therapy",
            "PFS": "21.9 months vs. 11.3 months; HR=0.67 [0.48-0.93], p=0.02",
            "OS": "None",
            "ORR": "None",
            "AE": "TTFields-related AEs were mainly dermatological, and Grade \u22642",
            "Other": "Deterioration-free survival of global health status, physical functioning, and fatigue improved; did not negatively impact cognition",
            "Conclusion": "METIS study met its primary endpoint, demonstrating that TTFields therapy following SRS in mutation-negative NSCLC patients with BM significantly prolongs time to intracranial progression and could postpone WBRT, without QoL and cognition decline."
        },
        {
            "Title": "Utility of circulating tumor DNA (ctDNA) from cerebrospinal fluid (CSF) for prognosis of patients with recurrent high-grade glioma.",
            "Indication": "Recurrent high-grade glioma",
            "Drug": "None",
            "PFS": "None",
            "OS": "4.83 months vs. 11.83 months, HR 2.1, p < 0.001",
            "ORR": "None",
            "AE": "None",
            "Other": "CSF ctDNA positivity correlated with leptomeningeal disease and overall survival; 44% of alterations shared between the tumor and the CSF; emergence of new clonal events in the CSF",
            "Conclusion": "Our cohort demonstrated that patients with positive CSF ctDNA had a significantly shorter overall survival compared to those who were CSF ctDNA negative. CSF ctDNA may be used as a prognostic biomarker for survival, but confirmation requires further validation in a prospective study."
        },
        {
            "Title": "Efficacy and biomarker analysis of phase 2 (P2) and window-of-opportunity (WoO) cohorts of patients with recurrent glioblastoma (rGBM) treated with ST101, an inhibitor of the transcription factor C/EBPb.",
            "Indication": "Recurrent glioblastoma (rGBM)",
            "Drug": "ST101",
            "PFS": "20% 6-mo PFS in P2 cohort; ~50% 6-mo PFS in WoO cohort",
            "OS": "37% OS at 1 year in P2 cohort; Median OS cannot be assessed at this early stage in WoO cohort",
            "ORR": "30% DCR (1 PR + 8 SD) in P2 cohort; 67% DCR (2 PR + 2 SD) in WoO cohort",
            "AE": "Safe and well-tolerated in both cohorts with no discontinuations due to AEs",
            "Other": "ST101 uptake and C/EBPb target engagement by IHC; pathologic evidence of treatment effect, such as geographic necrosis, in 5/6 patients",
            "Conclusion": "Monotherapy ST101 in patients with rGBM has an excellent safety profile and results in comparable outcomes to current 2nd line treatments. ST101 crosses the BBB, engages its target, and potentially induces treatment-related necrosis. These encouraging clinical activity, safety, and tissue findings, as well as preclinical data showing additive activity in combination with lomustine, support advancing ST101 to a randomized, placebo-controlled study comparing ST101 + lomustine vs. lomustine + placebo in rGBM."
        },
        {
            "Title": "A phase II trial of olaparib and durvalumab in patients with recurrent IDH-mutated gliomas.",
            "Indication": "Recurrent IDH-mutated gliomas",
            "Drug": "Olaparib and Durvalumab",
            "PFS": "Median PFS was 1.9 months (95% CI 1.8\u20133.0)",
            "OS": "Median OS was 9.5 months (95% CI 4.3\u201319.3)",
            "ORR": "ORR was 10% (95% CI 2.2\u201327%)",
            "AE": "No treatment-related grade 3\u20134 toxicities. Any grade toxicities included fatigue (48%), nausea (17%), diarrhea (10%), and cytopenias (3%)",
            "Other": "DCR was 28% (95% CI 12.7\u201347.2%)",
            "Conclusion": "Combination treatment with olaparib and durvalumab for patients with IDHmt gliomas is well tolerated but has limited efficacy in unselected patients. cfMeDIP-seq can reliably predict tumour progression providing a blood-based biomarker of treatment response."
        },
        {
            "Title": "A phase Ib, window-of-opportunity study of neoadjuvant avelumab and hypofractionated proton beam therapy for recurrent radiation-relapsed meningioma.",
            "Indication": "Recurrent radiation-relapsed meningioma",
            "Drug": "Avelumab and hypofractionated proton beam therapy",
            "PFS": "Median PFS was 19.1 months (95% CI: 15.2-23.0)",
            "OS": "Median overall survival not yet reached",
            "ORR": "None",
            "AE": "No dose-limiting toxicity or unexpected toxicity",
            "Other": "Three patients demonstrated notably prolonged PFS (37.7, 58.5 months, and ongoing)",
            "Conclusion": "Avelumab combined with RT may induce an immunological response in some radiation-relapsed meningioma patients, leading to prolonged remission. Further investigations with larger prospective studies and predictive biomarkers are warranted."
        },
        {
            "Title": "Phase 1b/2a study evaluating the combination of MN-166 (ibudilast) and temozolomide in patients with newly diagnosed and recurrent glioblastoma (GBM)",
            "Indication": "Glioblastoma (GBM)",
            "Drug": "MN-166 (ibudilast) and temozolomide",
            "PFS": "44% (16 patients) in nGBM, 31% (8 patients) in rGBM",
            "OS": "21.0 months (17.7, 23.1) for nGBM, 8.6 months (7.8, 10.5) for rGBM",
            "ORR": "None",
            "AE": "None",
            "Other": "IHC evaluation on the original tumor tissue for patients with GBM showed significantly higher CD3 expression in progressive disease cells compared to those who did not.",
            "Conclusion": "This study met its primary endpoint for rGBM, as the combination of ibudilast and TMZ was associated with a higher PFS-6 rate than historical controls. CD3 expression was a good predictor for tumor progression at 5 months in patients with rGBM. Encouraging preclinical studies suggest enhanced efficacy of ibudilast in GBM when combined with immune checkpoint blockade agents."
        },
        {
            "Title": "A phase 0/Ia study of brigimadlin concentration in brain tissue and a non-randomized, open-label, dose escalation study of brigimadlin in combination with radiotherapy (RT) in patients (pts) with newly diagnosed glioblastoma (GBM)",
            "Indication": "Glioblastoma (GBM)",
            "Drug": "Brigimadlin",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Unbound brigimadlin concentrations in CE regions in all pts receiving the low dose of 30mg brigimadlin exceeded the 0.5nmol/L threshold. Kp,uu in most patients was close to 1 in CE regions. Biomarker data support target engagement in brain tissue.",
            "Conclusion": "Our findings support continued investigation of brigimadlin in GBM. Recruitment is ongoing. Updated data will be presented."
        },
        {
            "Title": "Tucatinib-trastuzumab-capecitabine for treatment of leptomeningeal metastasis in HER2+ breast cancer: TBCRC049 phase 2 study results",
            "Indication": "Leptomeningeal metastasis in HER2+ breast cancer",
            "Drug": "Tucatinib, trastuzumab, capecitabine",
            "PFS": "None",
            "OS": "Median OS of 10 months",
            "ORR": "38% (5 of 13 response-eligible patients) achieved LM objective response per the composite criteria at first response assessment",
            "AE": "None",
            "Other": "Mean improvement in LASA score was 13.5, indicating improved QOL and the mean improvement in MDASI-BT score was 32, indicating improved symptom burden.",
            "Conclusion": "This is the first prospective evidence of clinically meaningful benefit including objective responses, symptom improvement, and quality of life, along with extended survival, with a systemic regimen for LM from HER2+ BC. These data support the trend toward using systemic therapy as an initial approach in CNS metastases."
        },
        {
            "Title": "Tyrosine kinase inhibitors with and without upfront CNS radiation for brain metastases in oncogene-driven non-small cell lung cancer (TURBO-NSCLC)",
            "Indication": "Brain metastases in oncogene-driven non-small cell lung cancer (NSCLC)",
            "Drug": "Tyrosine kinase inhibitors (TKIs)",
            "PFS": "TKI+SRS was associated with a significant improvement in time to CNS PD (HR 0.63; 95% CI: 0.42-0.96; p=0.033)",
            "OS": "Median OS was similar between the TKI and TKI+SRS groups (median 41 months [95% CI: 35-NR] vs 40 months [95% CI: 40-NR], respectively; p=0.5)",
            "ORR": "None",
            "AE": "None",
            "Other": "Local CNS control was significantly improved with TKI+SRS (HR 0.30, 95% CI: 0.16-0.55; p<0.001)",
            "Conclusion": "The addition of SRS improved time to CNS PD and local CNS control but not OS. Patients with BM \u22651cm may benefit the most from upfront integration of SRS."
        },
        {
            "Title": "Stereotactic radiosurgery in patients with small cell lung cancer and 1-10 brain metastases: A multi-institutional, phase II, prospective clinical trial.",
            "Indication": "Small cell lung cancer with brain metastases",
            "Drug": "Stereotactic radiosurgery (SRS)",
            "PFS": "None",
            "OS": "Median overall survival was 10.3 months",
            "ORR": "None",
            "AE": "None",
            "Other": "Neurologic death rate at 1 year was 11.0% (95% CI 4.8%\u201317.2%)",
            "Conclusion": "Our prospective, multi-institutional study demonstrated modest rates of neurologic death when SRS as opposed to WBRT is used in patients with SCLC and 1-10 brain metastases and represents the largest prospective experience to date."
        },
        {
            "Title": "Re-irradiation in recurrent glioblastoma: PET- or MRI-based? Results of a prospective randomized clinical trial.",
            "Indication": "Recurrent glioblastoma",
            "Drug": "Re-irradiation with 39 Gy in 13 fractions",
            "PFS": "Median PFS was 4.0 months (95% CI 3.7-5.2) in the FET-PET arm and 4.9 months (95% CI 3.7-6.0) in the GdT1-MRI arm (one-sided stratified log-rank test p=0.98; adjusted HR for the experimental versus the control arm 1.14 [95% CI 0.85-1.52], p=0.39)",
            "OS": "Median OS was 9.4 months (95% CI 7.8-11.1) in arm A and 9.0 months (95% CI 7.6-10.5) in arm B (HR 1.01 [95% CI 0.75-1.37], p=0.92)",
            "ORR": "None",
            "AE": "Radiation necrosis as an adverse event was documented in 25.5% of cases in arm A and in 21.6% in arm B. Of the 239 patients who received the FET tracer, 3 reported 5 severe adverse events in the timespan of 7 days after PET. No event was related to the application of the tracer.",
            "Other": "Local control rate at 12 months was 22% in the FET-PET arm (95% CI 14%-31%) and 20% in the GdT1-MRI arm (95% CI 12%-29%)",
            "Conclusion": "In this trial, FET-PET-based re-irradiation of rGBM was not superior to the GdT1-MRI-based treatment. Consequently, both options remain valid in this setting. Stereotactic re-irradiation with 39 Gy in 3 Gy fractions was shown to be safe and the FET-PET investigation was well tolerated in all cases. Due to the inclusion of FET-PET-positive patients only, our results do not impact the known diagnostic role of FET-PET in differentiating rGBM progression from post-therapeutic changes."
        },
        {
            "Title": "Dual-agent immunotherapy for prevention of melanoma brain metastases: A real-world analysis of 8686 patients.",
            "Indication": "Melanoma brain metastases",
            "Drug": "Dual agent immunotherapy (dIT)",
            "PFS": "None",
            "OS": "Median OS was 1.70 and 3.66 years for the sIT and dIT cohorts, respectively (p=0.6)",
            "ORR": "None",
            "AE": "None",
            "Other": "MBM incidence was 13.4%, and 19.3%, for the dIT and sIT cohorts, respectively (p<0.0001). dIT was associated with a lower likelihood of developing MBM compared to sIT (RR [95% CI], 0.69 [0.62-0.77]). Median BMFS was 1.56 and 2.36 years in the sIT and dIT cohorts respectively (p=0.76).",
            "Conclusion": "These data highlight the impact of combination anti-CTL4/anti-PD1 immunotherapy in decreasing MBM incidence. The potential primary prophylactic role of dIT in MBM warrants prospective exploration, including mechanistic understanding."
        },
        {
            "Title": "Effect of breast cancer receptor subtypes and CSF cytology status on survival for patients with leptomeningeal disease.",
            "Indication": "Leptomeningeal disease in metastatic breast cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "Median OS: ER+/HER2-: 8.0 months (95% CI: 3.0, 24.8), HER2+: 5.7 months (95% CI: 1.6, NE), TNBC: 3.2 months (95% CI: 1.1, 5.0); CSF+: 3.5 months (95% CI: 1.6, 12.7), CSF-: 13.4 months (95% CI: 4.9, 61.9), CSF not tested: 3.3 months (95% CI: 1.4, 6.9)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "This single institution study on MBC patients with LMD showed that BC receptor subtypes were significantly associated with OS when adjusted for the interval from MBC to LMD diagnosis and CSF cytology group. This study showed significantly improved OS among patients with negative CSF cytology compared to those with positive cytology or those not tested, indicating a novel prognostic value of CSF cytology for MBC LMD."
        },
        {
            "Title": "Radiosurgery to 5 or more newly diagnosed brain metastases in the systemic therapy era.",
            "Indication": "Brain metastases",
            "Drug": "None",
            "PFS": "Median CNS-PFS: 6.6 months; 5-9 BM: 6.4 months, \u226510 BM: 7.7 months",
            "OS": "Median OS: 11.0 months; 5-9 BM: 11.1 months, \u226510 BM: 10.0 months",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "In this large, retrospective analysis of patients with \u22655 previously untreated BM treated with SRS, we found no association between BM number and CNS-PFS or OS. Change in systemic therapy, particularly to agents with CNS efficacy, was associated with improved outcomes. These results suggest patients with larger number of BM (\u226510) are appropriate for SRS, especially when changing to systemic therapy with CNS penetrance."
        },
        {
            "Title": "Tumor reactive T cells from the cerebrospinal fluid of patients with leptomeningeal disease from melanoma.",
            "Indication": "Leptomeningeal disease from melanoma",
            "Drug": "Adoptive cell therapy using tumor-infiltrating lymphocytes (TILs)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Therapies for LMD patients are desperately needed. Results demonstrate successful expansion of T cells ex vivo from CSF in M-LMD. Results raise potential to use autologous CSF-derived T cells as therapeutic strategy for patients with LMD."
        },
        {
            "Title": "Genomic biomarkers of CNS-specific outcomes in patients with breast cancer brain metastases.",
            "Indication": "Breast cancer brain metastases",
            "Drug": "None",
            "PFS": "Median TTCP: Overall cohort: 6 months (IQR 4, 13); HR+: 6 months (IQR 4, 13); HER2+: 11 months (IQR 5, 18); TN: 4 months (IQR 3, 8)",
            "OS": "Median OS: Overall cohort: 19 months (95% CI 16, 23); HR+: 15 months (95% CI 13, 21); HER2+: 58 months (95% CI 39, -); TN: 13 months (95% CI 11, 20)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "In this large clinico-genomic analysis of CNS-specific outcomes in BC, we identified clinical and putative genomic biomarkers associated with BCBM receiving SRS that could be used to risk stratify patients and optimize treatment strategies."
        },
        {
            "Title": "Efficacy of intrathecal delivery of peptide-pulsed type 1 conventional dendritic cell vaccine for the treatment of breast cancer-associated leptomeningeal disease in preclinical models.",
            "Indication": "Breast cancer-associated leptomeningeal disease",
            "Drug": "Peptide-pulsed type 1 conventional dendritic cells (cDC1s)",
            "PFS": "None",
            "OS": "None",
            "ORR": "Approximately 70% of mice from HER2+ LMD and 30% from TNBC LMD demonstrated complete responses.",
            "AE": "None",
            "Other": "Reduced tumor burden and prolonged survival in LMD mice; elevated secretion of Th1 proinflammatory cytokines such as IFN-g in the CSF.",
            "Conclusion": "Our preclinical data suggest IT cDC1 vaccine is effective against BC-LMD and prevents LMD recurrence. We are currently investigating the mechanism(s) by which IT cDC1 initiates the CD4 Th1 adaptive immune response against LMD."
        },
        {
            "Title": "Identification of pharmaceutics with selective activity against melanoma-associated leptomeningeal disease using patient-derived circulating tumor cells.",
            "Indication": "Melanoma-associated leptomeningeal disease",
            "Drug": "Ponatinib, Sorafenib, Ceritinib, Homoharringtonine (HHT)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Of the 1,436 FDA-approved small molecule compounds, 57 (~3.9%) exerted 95% proliferation inhibition and 20 (~1.4%) had 100% killing effect in PD-CSF-CTCs and murine melanoma cell lines. HHT showed significant prolonged median survival in M-LMD mice.",
            "Conclusion": "This is the first demonstration of an approach that allows for the rational development of therapeutics in M-LMD. Results will provide crucial new insights into biology and will identify drug candidates that may be suitable for future clinical trials."
        },
        {
            "Title": "Evaluation of survival outcomes in patients with solid organ cancer with brain metastasis treated with exacta encyclopedic tumor analysis based treatments",
            "Indication": "Solid organ cancer with brain metastasis",
            "Drug": "Exacta Encyclopedic Tumor Analysis (ETA)-guided treatment",
            "PFS": "None",
            "OS": "Median OS was 9.7 months (95% CI 7.06-12.3 months; range: 1 month\u2013not reached) among patients without brain metastases and 10 months (95% CI 2.6-15.9 months; range: 2.6 months\u2013not reached) among patients with brain metastases",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Personalized Exacta ETA-guided treatments resulted in almost similar OS for patients with brain metastases as seen in patients without brain metastases. These findings are significant considering the poor outcomes with Standard of Care treatments in patients with brain metastases."
        },
        {
            "Title": "Tumor treating fields (TTFields) therapy in patients with glioblastoma: Long-term survival results from TTFields in Germany in routine clinical care (TIGER) study",
            "Indication": "Glioblastoma",
            "Drug": "Tumor Treating Fields (TTFields) therapy with adjuvant temozolomide (TMZ)",
            "PFS": "Median PFS was 10.2 months (95% CI, 9.4\u201311.4)",
            "OS": "Median OS was 19.6 months (95% CI, 17.9\u201322.4)",
            "ORR": "None",
            "AE": "Serious AEs were observed in 68% of patients, with only 0.5% being attributed to TTFields therapy",
            "Other": "1, 2, 3, and 4-year OS rates were 79.2%, 42.4%, 31.5%, and 27.7%, respectively. PFS rates were 42.0%, 23.2%, 19.9%, and 17.6%, respectively.",
            "Conclusion": "TIGER is the largest prospective study to date on routine clinical practice in ndGBM. It reaffirms TTFields therapy\u2019s positive safety profile, and its OS and PFS survival benefits are consistent with prior reports. It also reveals promising long-term survival rates."
        },
        {
            "Title": "A phase I trial on the intra- and post-operative intracranial administration of ipilimumab and nivolumab in patients with recurrent high-grade glioma.",
            "Indication": "Recurrent high-grade glioma",
            "Drug": "Ipilimumab and nivolumab",
            "PFS": "None",
            "OS": "OS compared favorably against a Belgian historical control cohort with an improved 1 and 2-year OS rate (33% vs. 18.6% and 11.7% vs. 5.7%, respectively).",
            "ORR": "None",
            "AE": "Most frequent TRAEs were fatigue (n=24), headache (n=19), fever (n=17), and bacterial Ommaya colonization (n=11). No grade 5 AE occurred.",
            "Other": "Elevated protein level and lymphocytic pleocytosis in 90% of CSF samples and no evidence for NIVO accumulation in the CSF (IPI under evaluation).",
            "Conclusion": "In this first-in-human phase I trial on intracranial CTLA-4/PD-1 blockade in patients with recurrent high-grade glioma amenable for resection, intraoperative intracerebral and postoperative intracavitary administration of nivolumab +/- ipilimumab was found to be feasible and safe up to a bi-weekly postoperative intracavitary dose of 1 mg ipilimumab + 10 mg nivolumab, with encouraging OS results."
        },
        {
            "Title": "Pharmacological targeting of lipid metabolism by a first-in-class SREBP-targeted degrader to treat glioblastoma.",
            "Indication": "Glioblastoma",
            "Drug": "MFC0101",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "MFC0101 showed no adverse events, even at the dose level required to reach maximal systemic exposure in the 28-day toxicity study in rats, and no geno- and cardiovascular toxicities.",
            "Other": "MFC0101 displayed favorable PK profiles, such as high oral bioavailability (greater than 50% in dogs, rats, and mice) and blood-brain barrier permeability (brain-to-plasma ratio of 0.543 based on exposure in mice).",
            "Conclusion": "MFC0101, the first-in-class SREBP1-targeted degrader, demonstrates its outstanding preclinical efficacy in synergy with TMZ and reasonable safety as a promising GBM treatment."
        },
        {
            "Title": "Outcomes and immune response after peptide vaccination targeting human cytomegalovirus antigen pp65 in children and young adults with recurrent high-grade glioma and medulloblastoma.",
            "Indication": "Recurrent high-grade glioma and medulloblastoma",
            "Drug": "PEP-CMV",
            "PFS": "The median progression-free survival was 2.5 months (95% CI: 2.2, 3.2).",
            "OS": "The median overall survival was 6.4 months (95% CI: 3.4, 7.9). The 12-month OS was 26.6% (95% CI: 14, 41.1%).",
            "ORR": "None",
            "AE": "The maximum grade adverse event (AE) possibly, probably, or definitely related to PEP-CMV was as follows: 17 (45%) had Grade 1 adverse events (AE), 16 (42%) had Grade 2 AEs, 2 (5%) had Grade 3 AEs (encephalopathy and pyramidal tract syndrome), and one (3%) had a Grade 4 AE (cerebral edema). One patient developed symptoms and had elevated inflammatory cytokines consistent with cytokine release syndrome (Grade 2).",
            "Other": "Of the 22 patients with evaluable immune monitoring data, T cell reactivity on IFNg pp65 ELISpot was increased after PEP-CMV delivery (median spot pretreatment vs. prior to vaccine #4: 1 vs 30, P=0.004). Lower percentage of pretreatment regulatory T cells and higher percentage of terminally differentiated effector T cells at vaccine #4 were associated with longer subsequent survival.",
            "Conclusion": "PEP-CMV is well-tolerated and elicits an antigen-specific immune response in heavily pretreated, multiply recurrent patients with a 12-month OS of 26.6%. A multi-institutional Phase II trial (NCT05096481) of PEP-CMV is opening imminently."
        },
        {
            "Title": "Retrospective study of ivosidenib for patients with recurrent IDH mutant gliomas.",
            "Indication": "Recurrent IDH mutant gliomas",
            "Drug": "Ivosidenib",
            "PFS": "The median PFS was 7.52 months (1-26).",
            "OS": "The median OS was 12.7 months (3-25).",
            "ORR": "11 (33%) patients experienced partial response (PR), 20 (60%) experienced stable disease (SD), and 2 (6%) demonstrated progressive disease (PD) at 12-week assessment.",
            "AE": "Nine patients experienced adverse events: grade 1 fatigue (3), grade 1 diarrhea (1), grade 2 fever (1), grade 2 weakness (1), and grade 3 confusion (1). One patient experienced grade 4 lobar hemorrhage thought to be related to concurrent bevacizumab use.",
            "Other": "None",
            "Conclusion": "Treatment with Ivosidenib therapy was associated with a manageable safety profile. In a subset of patients, there was disease stabilization in heavily pre-treated recurrent IDH mutant gliomas."
        },
        {
            "Title": "INB-200: Fully enrolled phase 1 study of gene-modified autologous gamma-delta (gd) T cells in patients with newly diagnosed glioblastoma multiforme (GBM) receiving maintenance temozolomide (TMZ)",
            "Indication": "Newly diagnosed glioblastoma multiforme (GBM)",
            "Drug": "Gene-modified autologous gamma-delta (gd) T cells",
            "PFS": "Majority of dosed patients exceeded the expected median PFS of 7 months (5.8-8.2 months) with Stupp alone",
            "OS": "None",
            "ORR": "None",
            "AE": "No DLTs, cytokine release syndrome (CRS) or neurotoxicity (ICANS) reported. Most common adverse events were decreased WBC/platelet count, asthenia, fatigue, hydrocephalus, headache, decreased appetite, urinary tract infection, thrombosis and balance disorder.",
            "Other": "Long-term follow-up for durability of PFS and OS continue.",
            "Conclusion": "gdT cells successfully infused with peripheral TMZ-based lymphodepletion evidenced with near or below normal range T, B, and NK subsets for up to 1 year. The majority of dosed patients who received DRI exceeded the expected median PFS of 7 months (5.8-8.2 months) with Stupp alone and had manageable toxicity with a continued encouraging trend in PFS."
        },
        {
            "Title": "Targeting glioma stem cells with thyroid hormone suppression: Phase 1/2 trial of methimazole in patients with progressive grade 4 gliomas",
            "Indication": "Progressive grade 4 gliomas",
            "Drug": "Methimazole",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Average fold increase in HPC: Pre-op (after 5-7 days of methimazole) 1.56 (p=0.029), Day of surgery 2.86 (p=0.004), Cycle 1 3.00 (p=0.023), Cycle 2 1.76 (p=0.013)",
            "Conclusion": "Methimazole treatment in recurrent GBM patients enhances HPC on a systemic level. Next steps will be to measure sulfide signaling and sulfhydration alterations in patient tumor samples. The protocol is being modified to allow earlier addition (2 weeks) of post-op chemotherapy."
        },
        {
            "Title": "Phase 1 study of anti\u2013immunoglobulin-like transcript 3 (ILT3) monoclonal antibody (mAb) MK-0482 + pembrolizumab (pembro) in patients with recurrent inoperable glioblastoma (GBM)",
            "Indication": "Recurrent inoperable glioblastoma (GBM)",
            "Drug": "MK-0482 + pembrolizumab",
            "PFS": "Median PFS was 1.4 months (95% CI, 1.3-2.9)",
            "OS": "Median OS was 9.3 months (6.3-13.7); 6-/12-month PFS and OS rates were 24%/16% and 72%/36%, respectively",
            "ORR": "Confirmed ORR was 12% (3/25; 95% CI, 2.5-31.2), DCR was 32% (8/25; 14.9-53.5), and median DOR was 11.1 months (range, 9.9-16.6+)",
            "AE": "Overall, 22 patients (88%) had an adverse event (AE); 10 patients (40%) had treatment-related AEs, most commonly (\u226510%) arthralgia (12%), fatigue (12%), and increased ALT (12%). Grade 3 treatment-related AEs occurred in 2 patients (8%; increased ALT [n=1] and fatigue [n=1]). No grade 4 or 5 treatment-related AEs occurred. Four patients (16%) experienced immune-mediated AEs (hypothyroidism, n=2; hepatitis, n=1; pancreatitis, n=1); all were grade 1 or 2.",
            "Other": "Biomarker data will be included in the presentation.",
            "Conclusion": "MK-0482 + pembro had a manageable AE profile with modest antitumor activity in patients with recurrent inoperable GBM."
        },
        {
            "Title": "The TAC-GReD trial: The combination of talazoparib and carboplatin in DNA damage repair deficient recurrent high-grade glioma",
            "Indication": "Recurrent high-grade glioma (rHGG)",
            "Drug": "Talazoparib and carboplatin",
            "PFS": "The 6-PFS was 29% (95% CI 16.2-51.9%) and the median PFS was 3.5 months (95% CI: 2.4-6.3 months)",
            "OS": "The OS at 3 and 12 months were 90.4% (95% CI 80.7-100%) and 30% (95% CI 17-53%), respectively",
            "ORR": "None",
            "AE": "The most common grade 3-4 toxicities were neutropenia (21.2%, n=7), thrombocytopenia (18.2%, n=6) and anemia (9.1%, n=3). One patient developed a grade 4 thromboembolic event. Dose level reduction was implemented in 54% (n=18) of patients and treatment was terminated in 6.1% (n=2) due to toxicity.",
            "Other": "Additional analysis including correlation of biomarkers with long-term outcomes is underway.",
            "Conclusion": "Talazoparib and carboplatin in a DDRd-enriched rHGG is feasible and tolerable."
        },
        {
            "Title": "Safety and efficacy of CyberKnife radiosurgery plus anlotinib hydrochloride in patients with recurrent glioblastoma: A prospective phase II single-arm study.",
            "Indication": "Recurrent glioblastoma",
            "Drug": "Anlotinib hydrochloride",
            "PFS": "Median PFS was 9.1 months (95% CI, 7.5\u201324.7), with a 6-month PFS rate of 85.7% (95% CI, 71.9\u2013100.0)",
            "OS": "Median OS was 19.5 months (95% CI, 10.6\u201346.8)",
            "ORR": "Objective response rate of 95.5%",
            "AE": "Common adverse events included hand-foot skin reactions (40.9%), hypercholesterolemia (27.3%), and hypertension (22.7%). Four patients experienced grade 3 adverse events (18.2% incidence rate). No grade 4 events or treatment-related deaths were reported.",
            "Other": "Therapy discontinuation due to ischemic stroke (grade 3) occurred in one patient.",
            "Conclusion": "The combination of salvage SRS with Anlotinib demonstrated promising outcomes and manageable toxicity in managing recurrent GBM. A phase II randomized controlled trial is underway to compare the efficacy of Anlotinib combined with radiosurgery against Bevacizumab combined with radiosurgery for the treatment of rGBM patients."
        },
        {
            "Title": "Phase I clinical trial of 64Cu-ATSM for malignant brain tumors.",
            "Indication": "Malignant brain tumors (glioma, primary central nervous system lymphoma, malignant meningioma, metastatic brain tumor)",
            "Drug": "64Cu-ATSM",
            "PFS": "Median PFS was 3.8 months and 1-year PFS was 19.0%",
            "OS": "Median OS was 29.4 months and 1-year OS was 76.6%",
            "ORR": "None",
            "AE": "No serious adverse events caused by 64Cu-ATSM administration were reported. Dose-limiting toxicities included grade 3 lymphocytopenia and grade 4 lymphocytopenia.",
            "Other": "The liver received relatively high doses (0.23 mSv/MBq) with an estimated effective dose of 0.037 mSv/MBq.",
            "Conclusion": "Intravenous administration of 64Cu-ATSM was feasible and well tolerated in patients with malignant brain tumors. The recommended dose for future trials is 99 MBq/kg. The initial efficacy outcomes obtained in this study warrant more clinical evaluation of this new treatment approach for brain tumors with no currently available treatment options."
        },
        {
            "Title": "Glioma monitoring via longitudinal intracranial cerebrospinal fluid cell-free DNA.",
            "Indication": "Gliomas",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Tumor fraction increased by 6.28x and 2.87x with radiographic progression in two patients. Tumor fraction decreased by 0.08x and 0.04x in two patients with paired immediate pre-versus-post chemoradiation samples. Patient-specific tumor-associated variant allelic frequencies (VAFs) decreased within individual patients after resection and chemoradiation. In two patients with IDH mutant gliomas, decreasing IDH1 VAF after resection correlated with decreased CSF D-2-hydroxyglutarate (D-2-HG) levels.",
            "Conclusion": "Longitudinal CSF cfDNA can feasibly be obtained via CSF access devices in patients with gliomas during their disease course. Ongoing studies will evaluate hypotheses generated in this case series regarding how longitudinal CSF cfDNA could be utilized to sensitively detect changes in disease burden."
        },
        {
            "Title": "Phase 1 LITESPARK-001 study of belzutifan in advanced solid tumors: Results of the glioblastoma cohort.",
            "Indication": "Glioblastoma",
            "Drug": "Belzutifan",
            "PFS": "1.4 months (95% CI, 1.1-1.8)",
            "OS": "None",
            "ORR": "0% (95% CI, 0.0-13.7)",
            "AE": "100% experienced adverse events, 60% experienced grade 3-5 adverse events. Most common: anemia (64%), fatigue (52%), headache (32%), muscular weakness (32%).",
            "Other": "CBR was 8% (95% CI, 1.0-26.0). Two patients (8%) died from adverse events (both disease progression). No deaths were considered related to treatment.",
            "Conclusion": "Antitumor activity was not observed with belzutifan for patients with GBM who received prior radiation therapy and temozolomide. The safety profile was similar to previous studies with belzutifan. Further efforts are warranted to identify therapies for patients with glioblastoma to improve outcomes."
        },
        {
            "Title": "Cost-effectiveness of dabrafenib plus trametinib in BRAFV600E-mutant pediatric low-grade glioma: A microsimulation study.",
            "Indication": "Pediatric low-grade glioma (PLGG) with BRAFV600E mutation",
            "Drug": "Dabrafenib plus trametinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Modeled life years with Dab-Tram and chemotherapy were 34.91 and 32.68 years, respectively. Dab-Tram was associated with a 1.77 QALY increase at an incremental cost of $2,701,409 compared to standard chemotherapy. The resulting ICER was $1,526,265 per QALY gained. At a WTP threshold of $150,000 per QALY, a price reduction of approximately 80% is required to render it cost-effective.",
            "Conclusion": "While clinical outcomes using Dab-Tram as first-line therapy for BRAFV600E PLGG are promising, our model-based analysis demonstrates that at the present price, Dab-Tram is not cost-effective and would require a considerable price reduction to enable equitable access and affordability."
        },
        {
            "Title": "A phase II, open label, single arm study of nivolumab for recurrent or progressive IDH mutant gliomas with prior exposure to alkylating agents.",
            "Indication": "Recurrent or progressive IDH mutant gliomas",
            "Drug": "Nivolumab",
            "PFS": "2.2 months",
            "OS": "31 months",
            "ORR": "9% with two PR and one CR",
            "AE": "Two treatment-related Grade 3 adverse events (lymphopenia and hypotension). One patient discontinued treatment due to Grade 2 transaminitis.",
            "Other": "Median DOR of 33 months. All three patients maintained response at 20+ (Grade 3 oligodendroglioma), 33+ (Grade 3 astrocytoma), and 51+ months (Grade 2 astrocytoma). 33% of patients had stable disease (8 were stable for over 6 months).",
            "Conclusion": "Nivolumab was well-tolerated with no unexpected toxicity in this brain tumor population. Three patients derived a prolonged response and still continue on CR and PR after completing 24 months of planned treatment. This study provides data on single-agent PD-1 inhibition, which will serve as baseline efficacy data for ongoing and future combination immunotherapy trials with PD-1 inhibitors in recurrent IDH-mutant gliomas."
        },
        {
            "Title": "Initial results from phase I trial of a novel oncolytic adenovirus Ad-TD-nsIL12 in recurrent glioblastoma connecting to ventricular system.",
            "Indication": "Recurrent glioblastoma (rGBM) connecting to ventricular system",
            "Drug": "Ad-TD-nsIL12",
            "PFS": "None",
            "OS": "None",
            "ORR": "Complete response (CR) in one patient, partial response (PR) in one patient, stable disease (SD) in four patients, and progressive disease (PD) in two patients.",
            "AE": "Grade 3 seizure occurred in two patients from Cohort 3 (5x1010 vp) after AD-TD-nsIL12 injection. Minimal adverse events were observed at a treatment dose of 1x1010 vp, even after multiple injections.",
            "Other": "Immunohistochemical staining showed higher infiltrations of CD3+, CD4+, and CD8+ T cells and positivity of E1A and Hexon in virus post-treated tissues. Inflammation-associated cytokines in the blood were not significantly elevated by Ad-TD-nsIL12.",
            "Conclusion": "AD-TD-nsIL12 treatment was safe and effective in patients with rGBM and warrants examination in a phase II clinical study."
        },
        {
            "Title": "Zotiraciclib for newly diagnosed or recurrent glioblastoma: Updated outcome and biomarker analysis.",
            "Indication": "Newly diagnosed or recurrent glioblastoma",
            "Drug": "Zotiraciclib (TG02)",
            "PFS": "6.7% in group C",
            "OS": "None",
            "ORR": "None",
            "AE": "Neutropenia, gastrointestinal disorders, and hepatotoxicity",
            "Other": "High protein levels of MCL-1 were associated with inferior survival. The level of tumor-related extracellular vesicles in the blood correlated with tumor volumes determined by MRI and increased from baseline to recurrence.",
            "Conclusion": "Zotiraciclib exhibits overlapping toxicity with alkylating agents which are the cornerstone of medical treatment for glioblastoma. Its single-agent clinical activity in the recurrent setting was low. The role of c-MYC and MCL-1 as therapeutic targets and the diagnostic value of extracellular vesicles in peripheral blood in glioblastoma warrant further study."
        },
        {
            "Title": "Phase II randomized, blinded, controlled trial evaluating neoadjuvant PD-1 blockade combined with A2B5+ glioma stem-like cell lysate-loaded DC vaccine in recurrent glioblastoma (IDH1/2 -).",
            "Indication": "Recurrent glioblastoma (IDH1/2 -)",
            "Drug": "PD-1 mAbs and GSC-DCV",
            "PFS": "None",
            "OS": "Monotherapy arm: 8.2 months, Combination therapy arm: 22.7 months (HR, 0.2774; 95% CI, 0.0828 to 0.9291, P=0.0376)",
            "ORR": "None",
            "AE": "Grade 1-2 trAEs in 27.3% of patients in the monotherapy arm and 50.0% in the combination therapy arm. No grade 3 or higher trAEs occurred.",
            "Other": "Patients in the combination group experienced a substantial improvement in post-progression survival (PPS) compared to the monotherapy group (7.8m vs. 1.4m; P=0.0266). The combination therapy was particularly effective in the low tumor burden cohort (mOS: 23.2 vs. 9.6 months; P=0.0162). High URGCP expression was significantly positively associated with OS in patients undergoing combination therapy (R=0.8608, P=0.0061).",
            "Conclusion": "The combination therapy has proven to be safe and well-tolerated. Although there was no significant improvement in PFS, patients achieved a sufficiently long OS benefit compared to the monotherapy arm. Notably, the combination therapy was particularly effective in patients with a low tumor burden through long-term, multi-course treatment."
        },
        {
            "Title": "Development and validation of a clinical risk score for postoperative outcome in newly diagnosed glioblastoma: A report of the RANO Resect group.",
            "Indication": "Newly diagnosed glioblastoma",
            "Drug": "None",
            "PFS": "None",
            "OS": "Low risk (0-2 points): 24 months, Intermediate risk (3-5 points): 16 months, High risk (6-9 points): 6 months",
            "ORR": "None",
            "AE": "None",
            "Other": "Residual tumor per RANO classification, MGMT promoter methylation status, age (as continuous variable; optimal cutoff: \u226465 years), and KPS (as continuous variable; optimal cutoff: \u226580) were prognostic of overall survival and forwarded into regression tree analysis. The novel RANO risk score integrates molecular and clinical factors to serve as an easy-to-use, yet highly prognostic tool to stratify patients after resection or biopsy of newly diagnosed glioblastoma.",
            "Conclusion": "The novel RANO risk score integrates molecular and clinical factors to serve as an easy-to-use, yet highly prognostic tool to stratify patients after resection or biopsy of newly diagnosed glioblastoma. The score may serve to guide patient management and reduce imbalances between study arms in prospective interventional trials."
        },
        {
            "Title": "Primary intracranial germ cell tumors in adolescents and adults: Results of an international, multicenter, retrospective cohort study.",
            "Indication": "Primary intracranial germ cell tumors (iGCT)",
            "Drug": "None",
            "PFS": "3-year PFS: 90.7% (germinoma: 98.1%, NGGCT: 73.9%)",
            "OS": "5-year OS: 92.0% (germinoma: 98.1%; NGGCT: 78.3%)",
            "ORR": "Complete remission (CR) in 61.3%, partial remission tumor marker negative (PRm-) in 16.0%, PRm+ in 4.0%, PR with tumor marker status unknown in 4.0%, progressive disease (PD) in 2.7%, and unknown in 12.0%",
            "AE": "None",
            "Other": "Relapse occurred in 13/75 pts (17.3%) with unknown histology (n=4), teratoma (n=2), other NGGCT (n=4), and germinoma (n=3). In 2 pts in whom germinoma was diagnosed at time of first diagnosis, at time of recurrence PNET and Yolk sac tumor were histologically proven. Median time to relapse was 19.0 months (range 3-223). HDCT/ASCT as salvage treatment was performed in 5 pts with NGGCT and in one pt with germinoma as 2L (n=5) or 3L (n=1). NGGCT histology was associated with increased risk for recurrence or death (p=0.012 and 0.039).",
            "Conclusion": "Disease characteristics, treatment, and outcomes of adolescent/adult iGCT pts are comparable to those reported in pediatric pts. High cure rates are achieved in pts with pure germinoma and current treatment concepts. In pts with NGGCT, further investigation is needed to improve survival outcomes."
        },
        {
            "Title": "Safety and efficacy of B7-H3 targeting CAR-T cell therapy for patients with recurrent GBM",
            "Indication": "Recurrent Glioblastoma Multiforme (rGBM)",
            "Drug": "TX103 (B7-H3 targeting CAR-T cells)",
            "PFS": "None",
            "OS": "12-month overall survival (OS) rate was 83.3% (95% CI: 58.3%~100%), and the median OS was 20.3 months (95% CI: 20.3~not reached)",
            "ORR": "Two of the three patients from dose level 2 achieved partial and complete responses, respectively",
            "AE": "All patients experienced at least one adverse event (AE). Treatment-related AEs (TRAEs) included cytokine release syndrome, increased intracranial pressure (ICP), headache, epilepsy, decreased level of consciousness, vomiting, and pyrexia. Most TRAEs were grade 1-2, while three grade-3 TRAEs were observed: one case of increased ICP in dose level 2, one case of epilepsy, and another case of decreased level of consciousness in dose level 3",
            "Other": "Significant increases in cytokines, including IL-6 and IFN-g, and elevated copy numbers of the CAR gene were observed in the cerebrospinal fluid, while only minimal elevations were noted in the peripheral blood",
            "Conclusion": "In this trial, treating rGBM with TX103 is deemed safe and tolerated. These interim results support further study using TX103 to enhance the survival outcomes in patients with rGBM"
        },
        {
            "Title": "Multi-institutional study of reirradiation for recurrent high-grade glioma",
            "Indication": "Recurrent High-Grade Glioma (HGG)",
            "Drug": "Reirradiation (ReRT) with concurrent and adjuvant systemic therapy",
            "PFS": "Median PFS was 5.3 months. PFS was longer in patients with IDH mutations (8.6 vs 4.6 months, HR 1.85, p < 0.01). Receipt of concurrent and adjuvant TMZ with ReRT was associated with improved PFS (HR 0.66, p < 0.01 and HR 0.47, p < 0.01 respectively). Adj BEV was not associated with PFS",
            "OS": "Median OS was 9.8 months. OS was longer in patients with IDH mutations (16.6 vs 7.8 months, HR 2.44, p < 0.01). Receipt of concurrent and adjuvant TMZ with ReRT was associated with improved OS (HR 0.67, p < 0.01 and HR 0.49, p < 0.01 respectively). Concurrent BEV with ReRT was associated with worse OS (HR 1.66, p < 0.01). Adj BEV was not associated with OS",
            "ORR": "None",
            "AE": "Symptomatic adverse radiation effects and Grade 3 or greater neurologic toxicity were seen in 107 (25%) and 86 (20%) of patients respectively. Concurrent BEV was associated with lower (13% vs 23%, p = 0.03) and concurrent TMZ was associated with higher rates (25% vs 15%, p = 0.01) of Grade 3 or greater neurologic toxicity",
            "Other": "None",
            "Conclusion": "The use of concurrent and adjuvant TMZ with ReRT are associated with improved OS and PFS in recurrent HGG. OS and PFS are improved with concurrent and adjuvant TMZ for IDHwt tumors, while there was no PFS benefit to concurrent TMZ in IDHm tumors. The rate of high-grade neurologic toxicity was decreased with the use of concurrent BEV and increased with the use of concurrent TMZ"
        },
        {
            "Title": "VAMANA: A phase 2 study of low-dose bevacizumab plus CCNU in relapsed/recurrent glioblastoma",
            "Indication": "Relapsed/Recurrent Glioblastoma",
            "Drug": "Low-dose bevacizumab plus CCNU",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "Interim report of a phase 2 study of sonodynamic therapy (SDT) using SONALA-001 together with MR-guided low-intensity focused ultrasound (MRgFUS) in children with diffuse intrinsic pontine glioma (DIPG)",
            "Indication": "Diffuse intrinsic pontine glioma (DIPG)",
            "Drug": "SONALA-001",
            "PFS": "None",
            "OS": "None",
            "ORR": "Partial response in 2 of the first 6 patients",
            "AE": "No DLTs or related AE grade \u22653 observed",
            "Other": "Pharmacokinetics: Cmax occurred at the end of infusion, rapid clearance (15.7 mL/min/kg), plasma half-life < 1 hour, PpIX Cmax occurred 4-6 hours post-dose, longer mean half-life of 6 hours",
            "Conclusion": "SDT is a well-tolerated strategy for patients with DIPG after initial radiotherapy. SONALA-001 shows rapid distribution and clearance, and PpIX PK align with metabolite formation. Following dose escalation completion, dose-expansion will be initiated at RP2D offering further insights into safety, PK, and efficacy."
        },
        {
            "Title": "Phase 1 study of BDTX-1535, an oral 4th generation covalent EGFR inhibitor, in patients with recurrent glioblastoma: Preliminary dose escalation results",
            "Indication": "Recurrent glioblastoma (rGBM)",
            "Drug": "BDTX-1535",
            "PFS": "None",
            "OS": "None",
            "ORR": "1 confirmed partial response, 8 patients achieved stable disease",
            "AE": "All-grade: rash (78%), diarrhea (41%), fatigue (15%), stomatitis (11%), decreased appetite (11%), nausea (11%), paronychia (11%). Grade 3 TRAEs \u226510%: rash (19%)",
            "Other": "Pharmacokinetics: Plasma exposure increased dose proportionally, half-life ~15h, supporting once daily dosing. MTD is 300mg QD.",
            "Conclusion": "BDTX-1535 was well-tolerated up to 300mg daily, which is the MTD, and promising preliminary clinical activity was observed in patients with rGBM after relapse on standard of care treatment. Further exploration of BDTX-1535 in a 'window of opportunity' study is ongoing."
        },
        {
            "Title": "A phase 0/1 trigger trial of BDTX-1535 in patients with recurrent high-grade glioma (HGG) with EGFR alterations or fusions",
            "Indication": "Recurrent high-grade glioma (HGG) with EGFR alterations or fusions",
            "Drug": "BDTX-1535",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "No serious adverse events observed",
            "Other": "Pharmacokinetics: Mean unbound concentrations of BDTX-1535 in Gd-enhancing and nonenhancing tumor regions were 16.0 nM and 10.5 nM respectively. Four of five (80%) evaluable patients exceeded the PK threshold. Suppression of pEGFR and MIB1 observed in 40% and 60% of patients, respectively.",
            "Conclusion": "BDTX-1535 is well-tolerated in recurrent HGG patients, achieves pharmacologically relevant concentrations in Gd-nonenhancing tumor tissue and is associated with suppression of EGFR signaling."
        },
        {
            "Title": "PK-PD-efficacy modeling of brigimadlin in MDM2-amplified glioblastoma",
            "Indication": "Glioblastoma (GBM)",
            "Drug": "Brigimadlin (BI907828)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Brigimadlin significantly delayed median time to regrowth of GBM108 (MDM2 amplified) subcutaneous tumors at various dose levels. In orthotopic PDXs, brigimadlin provided further benefit in combination with RT.",
            "Conclusion": "Efficacy of brigimadlin is dependent on adequate CNS delivery. These data support ongoing clinical evaluation of brigimadlin in GBM."
        },
        {
            "Title": "A randomized, controlled, phase 2 trial of nivolumab plus standard-dose or low-dose bevacizumab for recurrent glioblastoma (NAVAL)",
            "Indication": "Recurrent Glioblastoma (rGBM)",
            "Drug": "Nivolumab plus Bevacizumab",
            "PFS": "Standard-dose: Median PFS 141 days (95% CI: 119-201), PFS-6 37.9% (95% CI: 22.8-52.9); Low-dose: Median PFS 139 days (95% CI: 92-228), PFS-6 42.9% (95% CI: 27.9-57.8)",
            "OS": "OS-12 was 41.1% in standard-dose arm and 37.7% in low-dose arm",
            "ORR": "Standard-dose: 36%; Low-dose: 38%",
            "AE": "Most frequent toxicities (>20%) included fatigue (45.6%), proteinuria (34.4%), diarrhea (28.9%), hypertension (23.3%), and lipase increase (21.1%). Grade 3-4 toxicities included hypertension (7.8%) and fatigue (5.6%).",
            "Other": "Post-hoc analysis demonstrated survival benefit for patients aged \u226560 years in the standard-dose arm. Differential changes across trial arms were seen in myeloid-derived suppressor cells (MDSCs).",
            "Conclusion": "Overall PFS and OS were similar for nivolumab plus standard- or low-dose bevacizumab for rGBM, with post-hoc survival benefit seen in standard-dose arm in elderly. Standard-dose bevacizumab was associated with increased systemic inflammatory response and reduced immunosuppressive MDSCs."
        },
        {
            "Title": "A phase II study of plerixafor combined with whole brain radiation therapy (WBRT) for patients with newly diagnosed glioblastoma.",
            "Indication": "Newly diagnosed glioblastoma",
            "Drug": "Plerixafor",
            "PFS": "6-month progression free survival (PFS6) was 91.7%",
            "OS": "Median overall survival (mOS) was 15.11 months",
            "ORR": "None",
            "AE": "Common Gr2 treatment-related AEs included alopecia (n=4), nausea (n=4), and vomiting (n=2). 1 patient had Gr3 alopecia. There were no Grade 4/5 AEs.",
            "Other": "80% of patients scored worse on their neurocognitive assessments at 6 months compared to screening. ASL-CBF values of primary lesions appeared relatively stable throughout the study.",
            "Conclusion": "The combination of Plerixafor and WBRT did not improve mOS compared to the original study using focused RT only. There was an overall decline in neurocognitive performance over the course of the study."
        },
        {
            "Title": "Phase I clinical trial of peposertib plus radiation in adults with newly diagnosed MGMT-unmethylated glioblastoma.",
            "Indication": "Newly diagnosed MGMT-unmethylated glioblastoma",
            "Drug": "Peposertib",
            "PFS": "Median PFS was 12.7 months [95% CI (9-NR)]",
            "OS": "Median OS was 22.9 months [95% CI (16-NR)]",
            "ORR": "None",
            "AE": "One DLT (G3 radiation necrosis [RN] at 300mg) was observed. Most notable toxicity was transient G3 dermatitis of the scalp (2@200mg, 1@300mg; not a DLT per protocol).",
            "Other": "Two patients had pathology-proven RN (one within and one outside of the DLT period). Both tumors had baseline mutations in DDR genes (i.e. ATRX, DICER1). Recurrent tissue was available for 4 patients after treatment with peposertib, 2 of which demonstrated gain of DDR gene mutations (2 ATM mutations in one patient and gain of MAD2L2 mutation in another patient).",
            "Conclusion": "The initial safety data of peposertib plus radiation in patients with newly-diagnosed MGMT-unmethylated glioblastoma is favorable. The cases with RN in tumors with DDR mutations, the gain of DDR mutations in recurrent tumors, and the cases of scalp dermatitis suggest peposertib activity may correlate with DDR function, and possibly potentiate the effect of radiation."
        },
        {
            "Title": "Phase 2 study of bizaxofusp, an IL-4R targeted toxin payload, in non-resectable recurrent GBM: Comparison of overall survival with contemporaneous eligibility-matched and propensity score balanced external control arm.",
            "Indication": "Non-resectable recurrent glioblastoma (GBM)",
            "Drug": "Bizaxofusp (MDNA55)",
            "PFS": "None",
            "OS": "Bizaxofusp arm: 12.4 months (HR: 0.64, 95% CI 0.46-0.93, p=0.02) compared to contemporaneous emECA: 7.7 months; Bizaxofusp arm: 12.4 months (HR: 0.72, 95% CI 0.46-1.1, p=0.27) compared to pbECA: 7.2 months; Patients with tumor control: 16.7 months (HR=0.5, 95% CI 0.27-0.9, p=0.01); Patients with pseudoprogression: 22.8 months (HR: 0.49, 95% CI 0.25-0.98, p = 0.049)",
            "ORR": "None",
            "AE": "TRAEs were primarily neurological or aggravation of pre-existing neurological deficits associated with GBM and were manageable with standard measures.",
            "Other": "IL-4R expression did not impact mOS except in patients receiving low doses of bizaxofusp.",
            "Conclusion": "Bizaxofusp achieved significant OS benefit in patients with nonresectable rGBM compared to contemporaneous emECA. Patients who experienced tumor control, including those with PsP, showed significantly longer survival than patients with tumor progression. Phase 3 ready registrational trial will comprise a high dose bizaxofusp arm and a hybrid control arm with 1/3 randomized subjects and 2/3 pmECA."
        },
        {
            "Title": "NFKBIA deletions reshape the epigenome antithetical to the IDH mutation and indication of prognosis in diffuse gliomas.",
            "Indication": "Diffuse gliomas",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "NFKBIA deletions are associated with unfavorable patient outcomes and distinct epigenome changes.",
            "Conclusion": "NFKBIA haploinsufficiency aligns with distinct epigenome changes, portends a poor prognosis, and should be incorporated into clinical models predicting the disease fate of diffuse gliomas."
        },
        {
            "Title": "A 16 year-retrospective study of refractory meningiomas: Prognostic factors and systemic treatments.",
            "Indication": "Refractory meningioma",
            "Drug": "Bevacizumab, Hydroxyurea, Somatostatin, Sunitinib",
            "PFS": "Bevacizumab: 22.5 months, Hydroxyurea: 4 months, Somatostatin: 8 months, Combination of Hydroxyurea and Somatostatin: 8 months, Sunitinib: 14 months",
            "OS": "None",
            "ORR": "None",
            "AE": "Bradycardia (Somatostatin), Elevated liver enzymes (Sunitinib), Anemia and neutropenia (Hydroxyurea), Proteinuria (Bevacizumab)",
            "Other": "Prognostic factors for survival include age \u226565 years, grades 2 and 3, and the occurrence of a second progression.",
            "Conclusion": "Prognostic factors for survival in meningioma include age \u226565 years, grades 2 and 3, and the occurrence of a second progression. Bevacizumab was well tolerated and associated with the longest PFS among systemic treatments."
        },
        {
            "Title": "Real-world monitoring and treatment patterns in von Hippel-Lindau (VHL) disease-associated central nervous system hemangioblastomas (CNS-Hb).",
            "Indication": "von Hippel-Lindau (VHL) disease-associated central nervous system hemangioblastomas (CNS-Hb)",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Patients with VHL-CNS-Hb use more analgesics, undergo frequent monitoring, and have increased specialist visits compared with the control group.",
            "Conclusion": "VHL-CNS-Hb presents a multifaceted burden, involving treatments for various VHL-related tumors. Successful tumor control holds the potential to reduce morbidity and long-term medical management associated with the disease."
        },
        {
            "Title": "Emavusertib (CA-4948) in combination with ibrutinib in patients with relapsed/refractory primary central nervous system lymphoma (R/R PCNSL)",
            "Indication": "Relapsed/refractory primary central nervous system lymphoma (R/R PCNSL)",
            "Drug": "Emavusertib in combination with ibrutinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "No dose-limiting toxicities",
            "Other": "Higher CR rate when treated with emavusertib in combination with ibrutinib",
            "Conclusion": "Emavusertib in combination with ibrutinib was well tolerated with an acceptable safety profile and promising efficacy in R/R PCNSL patients with previous exposure to ibrutinib (BTKi). Enrollment in this trial is ongoing (NCT03328078)."
        },
        {
            "Title": "Marrow-ablative consolidation chemotherapy (HDCT) to decrease the development of choroid plexus carcinoma (CPC) specific relapse in high-risk TP53-mutated (germline or somatic) young children with newly diagnosed CPC",
            "Indication": "Choroid plexus carcinoma (CPC)",
            "Drug": "Marrow-ablative consolidation chemotherapy (HDCT) with autologous hematopoietic progenitor cell rescue (AuHPCR)",
            "PFS": "Five-year CPC-free survival for the entire cohort was 47.8%",
            "OS": "Five-year overall survival for the entire cohort was 44.4%",
            "ORR": "None",
            "AE": "None",
            "Other": "Eight out of 12 patients with documented genotypic (TP53 germline mutated) Li-Fraumeni Cancer Predisposition Syndrome (LFS) developed second malignancies.",
            "Conclusion": "HDCT with AuHPCR consolidation is associated with improved CPC-free survival compared with historical reports in young children with high-risk TP53 mutated CPC. However, the ultimate development of second cancers substantially affects outcomes for those with LFS, irrespective of HDCT treatment."
        },
        {
            "Title": "A multicenter phase 1 trial of tucatinib, trastuzumab, and capecitabine with stereotactic radiosurgery in patients with brain metastases from HER-2\u2013positive breast cancer (TUTOR)",
            "Indication": "Brain metastases from HER-2\u2013positive breast cancer",
            "Drug": "Tucatinib, trastuzumab, and capecitabine with stereotactic radiosurgery",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "DLTs defined by Grade 3 or 4 thrombocytopenia, grade 4 anemia, grade 4 neutropenia lasting more than seven days, febrile neutropenia, and grade 3 non-hematologic toxicity",
            "Other": "None",
            "Conclusion": "The trial aims to determine the maximum tolerated dose (MTD) of tucatinib in combinatorial therapy. Secondary endpoints include efficacy as determined by response rate, intracranial progression-free survival (PFS), extracranial PFS, overall survival, quality of life as assessed by FACT-Br, toxicity, and neurocognitive function. The trial has been approved by the Institutional Review Board (IRB) and currently is enrolling patients."
        },
        {
            "Title": "A first-in-human phase 1 trial of dose escalating intrathecal (IT) dendritic cells (cDC1s) primed against HER2/HER3 in patients (pts) with leptomeningeal disease (LMD) from triple-negative (TNBC) or HER2+ breast cancer (BC)",
            "Indication": "Leptomeningeal disease (LMD) from triple-negative (TNBC) or HER2+ breast cancer (BC)",
            "Drug": "Intrathecal (IT) dendritic cells (cDC1s) primed against HER2/HER3",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "DLTs defined as \u2265 grade 3 not due to LMD & didn\u2019t respond to medical intervention/CSF removal \u2264 96 hrs",
            "Other": "None",
            "Conclusion": "The primary endpoints are to determine the safety and DLTs, and associations between various clinical endpoints and immune profiles in the CSF. The trial uses the Bayesian Optimal Interval (BOIN) design to find the MTD. Response was measured using RANO-LM criteria."
        },
        {
            "Title": "Patritumab deruxtecan (HER3-DXd) in active brain metastases from metastatic breast and non-small cell lung cancers, and leptomeningeal disease from advanced solid tumors: The TUXEDO-3 phase II trial.",
            "Indication": "Brain metastases from metastatic breast cancer (mBC) and advanced non-small cell lung cancer (aNSCLC), and leptomeningeal disease (LMD) from metastatic solid tumors",
            "Drug": "Patritumab deruxtecan (HER3-DXd)",
            "PFS": "None",
            "OS": "3-month OS in cohort 3",
            "ORR": "Intracranial ORR (IC-ORR) in cohorts 1 and 2",
            "AE": "None",
            "Other": "Secondary endpoints include OS, ORR, progression-free survival, time to response and duration, clinical benefit rate, safety, tolerability, quality of life evaluation, and neurocognitive function evaluation by NANO scale in all cohorts.",
            "Conclusion": "This is the first prospective clinical trial to evaluate the intra-/extracranial efficacy and safety of HER3-DXd in pretreated mBC and aNSCLC with BM, and metastatic solid tumors with LMD. If positive, TUXEDO-3 could streamline the introduction of HER3-DXd as a new treatment paradigm for these patients, who currently have very limited therapeutic options."
        },
        {
            "Title": "A phase 3 randomized controlled trial of post-surgical stereotactic radiotherapy versus surgically targeted radiation therapy with GammaTile for treatment of newly diagnosed metastatic brain tumors.",
            "Indication": "Newly diagnosed metastatic brain tumors",
            "Drug": "GammaTile",
            "PFS": "None",
            "OS": "Overall survival",
            "ORR": "None",
            "AE": "None",
            "Other": "Secondary endpoints include physical function, neurocognitive status, quality of life, and safety.",
            "Conclusion": "This is a multicenter randomized controlled trial funded by GT Medical Technologies. The study opened for enrollment in April 2021. The DMC last reviewed the trial in October 2023 and recommended that the trial continue as planned."
        },
        {
            "Title": "A phase I/II study to assess safety and preliminary evidence of a therapeutic effect of azeliragon combined with stereotactic radiation therapy in patients with brain metastases (ADORATION).",
            "Indication": "Brain metastases",
            "Drug": "Azeliragon",
            "PFS": "None",
            "OS": "None",
            "ORR": "Response rate",
            "AE": "Number of DLTs and number of CNS treatment-related adverse events",
            "Other": "Response endpoints include early brain metastasis response at 8 weeks, intracranial response at 6 and 12 months, and lesion-specific local control. Neurocognitive batteries, symptom inventories, and quality-of-life questionnaires will also be administered.",
            "Conclusion": "Three participants have been enrolled in the starting cohort and are pending DLT assessment."
        },
        {
            "Title": "A phase II, multicenter, prospective study of sacituzumab govitecan in recurrent glioblastoma.",
            "Indication": "Recurrent glioblastoma (GBM)",
            "Drug": "Sacituzumab govitecan (SG)",
            "PFS": "Progression-free survival of patients on SG as compared to a historical control of lomustine monotherapy",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Patients were assessed by RANO and CTCAE v5.",
            "Conclusion": "The study has now entered the interim analysis."
        },
        {
            "Title": "Phase 1 dose-finding study to evaluate safety and tolerability of CVGBM in patients with newly diagnosed and surgically resected MGMT-unmethylated glioblastoma.",
            "Indication": "Newly diagnosed and surgically resected MGMT-unmethylated glioblastoma",
            "Drug": "CVGBM",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Biomarkers and vaccine-induced innate and adaptive immunogenicity, including systemically induced cytokines and chemokines and antigen-specific CD4+ and CD8+ T cells, will be monitored.",
            "Conclusion": "The study will assess the safety and immunogenicity of the mRNA-based multiepitope vaccine CVGBM."
        },
        {
            "Title": "Phase Ib/II CAN-201 NDG trial design.",
            "Indication": "Newly diagnosed glioblastoma",
            "Drug": "Azeliragon",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The primary objective is to determine the RP2D, defined as the dose for which 33% of patients experience a dose-limiting toxicity (DLT) within 28 days from initiation of dosing. Secondary endpoints include disease control, progression-free survival according to RANO criteria, overall survival, changes in ECOG performance status, and changes in corticosteroid requirements.",
            "Conclusion": "The trial aims to evaluate the safety and tolerability of azeliragon in combination with standard radiotherapy (RT)/TMZ followed by maintenance with azeliragon monotherapy."
        },
        {
            "Title": "Longitudinal stereotactic injections of oncolytic immunoactivating rQNestin34.5v.2 (CAN-3110) with concomitant biopsies for \u201c-omic\u201d analyses in recurrent glioblastoma (GBM).",
            "Indication": "Recurrent glioblastoma (GBM)",
            "Drug": "rQNestin34.5v.2 (CAN-3110)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The study involves up to 6 stereotactic administrations of the oncolytic immunotherapy rQNestin34.5v.2 over 4 months with concomitant multisector biopsies for \u201c-omic\u201d analyses.",
            "Conclusion": "The trial aims to evaluate the safety and technical efficacy of LIFU-based BBB disruption for increasing blood ctDNA and cfDNA levels in adults with GBM."
        },
        {
            "Title": "A prospective, multicenter trial of low-intensity focused ultrasound (LIFU) for blood-brain barrier disruption for liquid biopsy in glioblastoma (LIBERATE).",
            "Indication": "Glioblastoma (GBM)",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The primary study endpoint is the ratio between cfDNA level in blood 1-hour post-LIFU procedure compared to cfDNA level in blood pre-procedure. The secondary hypothesis is that there exists \u226575% agreement between biomarker pattern in blood cfDNA sample obtained 1-hour post-LIFU and the biomarker pattern in tumor tissue obtained later.",
            "Conclusion": "The trial aims to evaluate the utility of LIFU for boosting blood ctDNA and cfDNA for enhanced liquid biopsy in GBM patients."
        },
        {
            "Title": "A multicenter, randomized controlled phase 2b trial of survivin vaccine SurVaxM plus adjuvant temozolomide for newly diagnosed glioblastoma (SURVIVE)",
            "Indication": "Newly diagnosed glioblastoma (nGBM)",
            "Drug": "SurVaxM plus adjuvant temozolomide",
            "PFS": "None",
            "OS": "Median OS of 25.9 months in phase 2a trial; primary endpoint is median OS in phase 2b trial",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The study aims to further investigate the efficacy and safety of SurVaxM and adjuvant temozolomide for nGBM."
        },
        {
            "Title": "Randomized phase IIb trial of a CMV vaccine immunotherapeutic candidate (VBI-1901) in recurrent glioblastomas",
            "Indication": "Recurrent glioblastomas",
            "Drug": "VBI-1901 (a CMV-based GBM immunotherapeutic)",
            "PFS": "None",
            "OS": "Median OS of 12.9 months in initial phase study",
            "ORR": "Disease control rate of 44%, including 2 durable partial responses",
            "AE": "Injection site erythema was the most common adverse event",
            "Other": "Humoral response with increase in CMV gB antibodies, and cellular response with increase in CD4+ effector memory T cells against CMV pp65 antigen",
            "Conclusion": "The study was amended into a randomized study based on initial results."
        },
        {
            "Title": "A phase 1/2 dose escalation and expansion study of sonodynamic therapy with SONALA-001 in combination with Exablate 4000 Type 2.0 MR-guided focused ultrasound in patients with progressive or recurrent glioblastoma",
            "Indication": "Progressive or recurrent glioblastoma (rGBM)",
            "Drug": "SONALA-001 in combination with Exablate 4000 Type 2.0 MR-guided focused ultrasound",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Endpoints include safety evaluation, maximum tolerated dose (MTD), recommended Phase 2 dose (RP2D), and determination of pharmacokinetic (PK) parameters",
            "Conclusion": "The study aims to assess the safety and efficacy of repeated cycles of SONALA-001 SDT at the optimized RP2D."
        },
        {
            "Title": "Phase 1 study of multiple intracerebral doses of a neural stem cell-based oncolytic virotherapy for treatment of recurrent high-grade gliomas",
            "Indication": "Recurrent high-grade gliomas",
            "Drug": "NSC-CRAd-S-pk7 (neural stem cell-based oncolytic virotherapy)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Endpoints include safety and feasibility of intracerebral administration, biologic activity, biodistribution, immunogenicity, and preliminary clinical efficacy",
            "Conclusion": "The study aims to evaluate the safety and feasibility of multiple intracerebral doses of NSC-CRAd-S-pk7."
        },
        {
            "Title": "DIET2TREAT: A randomized, multi-center, phase 2 trial of a ketogenic diet vs standard dietary guidance in combination with standard-of-care treatment for patients with newly diagnosed glioblastoma.",
            "Indication": "Glioblastoma (GBM)",
            "Drug": "Ketogenic diet (KD) vs Standard dietary guidance (SD)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Secondary endpoints include assessments of health-related quality-of-life (FACT-BR, FACIT-F), cognitive performance (HVLT-R, Trail-Making Test A/B), and physical activity (modified Godin leisure questionnaire, continuous activity monitoring (Fitbit)). Blood, tumor, and stool samples are being collected for high throughput profiling to assess the effects of KD on metabolic markers and immune response.",
            "Conclusion": "The primary objective is to assess overall survival for patients on the KD arm vs the SD arm. Enrollment began in July 2023 and is ongoing. As of January 2024, the trial is open for enrollment at Cedars-Sinai Medical Center and will be opening at UCSF, Duke, Pacific Neuroscience Institute, and the Medical College of Wisconsin soon. Ten patients have been randomized so far."
        },
        {
            "Title": "Lete-cel in patients with synovial sarcoma or myxoid/round cell liposarcoma: Planned interim analysis of the pivotal IGNYTE-ESO trial.",
            "Indication": "Synovial sarcoma (SyS) and myxoid/round cell liposarcoma (MRCLS)",
            "Drug": "Letetresgene autoleucel (lete-cel)",
            "PFS": "None",
            "OS": "None",
            "ORR": "40% (99.6% CI: 20.3%, 62.3%) overall; 39% for SyS; 41% for MRCLS",
            "AE": "Most common AEs (all grades): cytokine release syndrome (CRS) in 89%, neutropenia in 73%, thrombocytopenia in 63%, rash in 53%, anemia in 52%, leukopenia in 49%. Grade \u22653 cytopenias in 86%, grade 5 neutropenia in 1%, grade 3 CRS in 12%, grade 3 rash in 23%, ICANS in 4% (all grade 1)",
            "Other": "Median duration of response: 10.6 months (95% CI: 3.3, NE; data are immature with 12 of 18 pts censored)",
            "Conclusion": "IGNYTE-ESO SS2 met the primary endpoint success criterion at this planned IA, with a 40% ORR consistent across SyS and MRCLS, and a known safety profile of hematologic toxicity and CRS. This supports the potential of lete-cel as a novel therapy for pts with advanced or metastatic SyS and MRCLS."
        },
        {
            "Title": "Claudin18.2-targeted chimeric antigen receptor T cell-therapy for patients with gastrointestinal cancers: Final results of CT041-CG4006 phase 1 trial.",
            "Indication": "Gastrointestinal cancers",
            "Drug": "Satricabtagene autoleucel (satri-cel)/CT041",
            "PFS": "4.4 months (95% CI: 4.0, 6.0) for all pts; 5.8 months (95% CI: 4.2, 8.4) for efficacy evaluable GC pts",
            "OS": "8.4 months (95% CI: 7.0, 10.0) for all pts; 9.7 months (95% CI: 7.1, 14.4) for efficacy evaluable GC pts",
            "ORR": "37.8% for all pts; 57.4% for efficacy evaluable GC pts",
            "AE": "Most commonly reported treatment-emergent adverse events of grade 3 or higher were hematologic toxicity related to lymphodepletion. No dose-limiting toxicities, treatment-related deaths, or immune effector cell-associated neurotoxicity syndrome were reported. Cytokine release syndrome occurred in 96.9% of pts, all classified as grade 1-2. Gastric mucosal injuries were identified in 8.2% of pts, including 7 cases of grade 1-2 and 1 case of grade 3 gastritis erosive which recovered.",
            "Other": "DCR reached 75.5% for all pts; 83.0% for efficacy evaluable GC pts",
            "Conclusion": "Satri-cel/CT041 demonstrated a promising safety profile and highly encouraging efficacy in heavily pretreated patients with CLDN18.2-positive advanced GI cancers."
        },
        {
            "Title": "A potential best-in-class BCMA-CD19 bispecific CAR T with advanced safety by self-inhibiting IFNG signaling during CRS.",
            "Indication": "Refractory/relapsed multiple myeloma",
            "Drug": "SAFET BCMA/CD19 BiCART",
            "PFS": "Median PFS 858 days for 7 patients who remained in CR",
            "OS": "None",
            "ORR": "10/11 achieved CR, 1 achieved VGPR",
            "AE": "Minimal CRS observed: 6 grade 0, 2 grade 1, 2 grade 2, and 1 grade 3 with mild transient hypotension for only 1 day",
            "Other": "Pharmacokinetics investigated by quantitative PCR based detection of CAR vector copies in peripheral blood. MRD negativity assessed by standardized multicolor flow cytometry analysis of bone marrow aspirate.",
            "Conclusion": "The remarkable short and long term remission suggested that CAR T self-inhibiting IFNG signaling did not impair the CAR T clinical efficacy. Notably, minimal CRS was observed, indicating that BCMA-CD19 bispecific CAR T self-inhibiting IFNG signaling is promising in translation to a best-in-class treatment for MM."
        },
        {
            "Title": "A phase 1/2 study of REGN7075 in combination with cemiplimab (cemi) in patients (pts) with advanced solid tumors: Efficacy and safety results.",
            "Indication": "Advanced solid tumors",
            "Drug": "REGN7075 in combination with cemiplimab (cemi)",
            "PFS": "None",
            "OS": "None",
            "ORR": "20% for MSSCRC without liver metastases",
            "AE": "Most TRAEs were Grade 1\u20132; >95% of infusion-related reactions (IRRs) were Grade 1\u20132. One pt experienced cytokine release syndrome (CRS; Grade 1 fever). No treatment-related deaths were reported.",
            "Other": "Disease control rate was 80% (CR, n=1; PR, n=2; SD, n=9) for MSSCRC without liver metastases. REGN7075 concentration in serum increased more than dose-proportionally at the dose range studied (0.03\u2013900 mg). T-cell activation-associated IFN-g was observed with monotherapy lead-in and combination dosing.",
            "Conclusion": "MSSCRC is historically unresponsive to immunotherapy. REGN7075 is one of the first immune therapies to demonstrate clinical activity in pts with MSSCRC (including a pt with liver metastases), and dose escalation was completed through 900 mg with an acceptable safety profile and no DLTs. One pt experienced Grade 1 CRS, demonstrating differentiation from CD28 superagonists and CD3-targeting bsAbs. IRRs were mitigated with premedication and split/step-up dosing. Efficacy data in non-immunoresponsive tumors, along with encouraging pharmacodynamic evidence, suggest that REGN7075 can enhance immune response and anti-tumor immunity. Initiation of dose expansion (Part 2) in select tumor cohorts with different EGFR levels is planned."
        },
        {
            "Title": "Efficacy and safety of LM-108, an anti-CCR8 monoclonal antibody, in combination with an anti-PD-1 antibody in patients with gastric cancer: Results from phase 1/2 studies.",
            "Indication": "Gastric cancer",
            "Drug": "LM-108 in combination with anti-PD-1 antibody (pembrolizumab or toripalimab)",
            "PFS": "6.53 months (95% CI 2.96\u2013NA)",
            "OS": "None",
            "ORR": "36.1% (95% CI 20.8%\u201353.8%)",
            "AE": "81.3% of patients experienced TRAEs. Common TRAEs (>15%) included alanine transaminase increased (25.0%), aspartate transaminase increased (22.9%), white blood cell decreased (22.9%), anemia (16.7%). Grade \u22653 TRAEs occurred in 37.5% of patients.",
            "Other": "DCR was 72.2% (95% CI 54.8%\u201385.8%). Among patients who progressed on first-line treatment, ORR was 63.6% (95% CI 30.8%\u201389.1%) and DCR was 81.8% (95% CI 48.2%\u201397.7%).",
            "Conclusion": "LM-108 in combination with an anti-PD-1 antibody showed promising antitumor activity in patients with gastric cancer that was resistant to anti-PD-1 therapy. The combination therapy was well tolerated. These results support further evaluation of LM-108 in CCR8 positive gastric cancer."
        },
        {
            "Title": "First-in-human study (FIH) of FS222, a next-generation tetravalent PD-L1/CD137 bispecific antibody: Safety, pharmacodynamics (PD), and antitumor activity in patients (pts) with advanced solid tumors including PD-1 refractory melanoma.",
            "Indication": "Advanced solid tumors including PD-1 refractory melanoma",
            "Drug": "FS222",
            "PFS": "None",
            "OS": "None",
            "ORR": "15.7% overall; 60% in post-PD-1 treated metastatic/advanced cutaneous melanoma",
            "AE": "Common TRAEs (>20%) included increased AST, ALT, pyrexia, thrombocytopenia, asthenia, and neutropenia. Grade \u22653 TRAEs included increased AST (13.3%) and ALT (11.1%).",
            "Other": "Significant peripheral CD8+ T cell modulation and increased tumor CD3+ CD8+ T cells at multiple dose levels. 22.2% of patients remained on treatment at data cutoff.",
            "Conclusion": "The novel PD-L1/CD137 bispecific antibody FS222 demonstrated PD activity across a broad range of doses. The safety profile was acceptable and manageable. Encouraging anti-tumor activity was observed, including in patients with PD-1 refractory cutaneous melanoma. Next steps include further dose optimization and further evaluation of FS222 in patients with melanoma and other tumor types."
        },
        {
            "Title": "Phase 1/2 study of the TGF-b-trap-enhanced oncolytic adenovirus, AdAPT-001, plus an immune checkpoint inhibitor for patients with immune refractory cancers.",
            "Indication": "Immune refractory cancers",
            "Drug": "AdAPT-001 plus immune checkpoint inhibitor",
            "PFS": "3.5 months (95% CI: 1.8-NA months)",
            "OS": "None",
            "ORR": "29.1% for combination therapy; 22.2% for monotherapy",
            "AE": "Common TRAEs included transient flu-like symptoms (27.7%) and injection-related events (27.7%). Only 1 patient (4.0%) developed an immune-related AE (hypophysitis).",
            "Other": "Clinical benefit rate for combination therapy was 62.5%. Monotherapy produced a clinical benefit rate of 44.4%.",
            "Conclusion": "Combination therapy of AdAPT-001 with a CI is well tolerated and demonstrates a high ORR including 1 patient with a CR per RECIST 1.1, 1 patient with a clinical CR, and 6 PRs. In several cutaneous sarcomas treated with AdAPT-001 plus a CI, radiologic SD belied how much better they looked visually \u2013 not only smaller, but less irregular and more circumscribed."
        },
        {
            "Title": "Phase 1 study (DRAGON) of SRK-181 (linavonkibart), a latent TGFb1 inhibitor, combined with pembrolizumab in patients with anti-PD1 resistant advanced solid tumors: Updated results of expansion part.",
            "Indication": "Anti-PD1 resistant advanced solid tumors",
            "Drug": "SRK-181 (linavonkibart) combined with pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "20% in ccRCC, 33.3% in HNSCC, 20% in MEL, 9.1% in UC, 0% in NSCLC",
            "AE": "Common TRAEs (>10%) included rash (23.6%), pruritus (20.8%), fatigue (19.4%), and diarrhea (12.5%). Grade 3 TRAEs included rash (8.3%). Only 1 grade 4 TRAE (dermatitis exfoliative generalised) occurred.",
            "Other": "Biomarker results showed decreased circulatory myeloid-derived suppressor cell levels and increased CD8+ infiltration into tumors across multiple tumor types.",
            "Conclusion": "Combination treatment with LKT + P is associated with enhanced proinflammatory microenvironment with promising efficacy in anti-PD-1 resistant patients across multiple tumor types with manageable safety profile. The result supports LKT as a potential treatment to overcome immune checkpoint inhibitor-associated resistance."
        },
        {
            "Title": "Prognostic and predictive value of ultrasensitive ctDNA monitoring in a metastatic pan-cancer cohort treated with immune checkpoint inhibitors in the context of phase 1 clinical trials.",
            "Indication": "Metastatic pan-cancer",
            "Drug": "Immune checkpoint inhibitors (ICI)",
            "PFS": "Lower ctDNA values at baseline correlated with increased duration of PFS (log-rank p=0.017, HR=0.57, 95% CI 0.36-0.91). Early reduction in ctDNA level from baseline to treatment cycle 3 was associated with significant increases in PFS (log-rank p=0.001, HR=0.36, 95% CI 0.19-0.67). ctDNA clearance resulted in significant improvement in PFS (log-rank p=0.002, HR=0.24, 95% CI 0.09-0.62).",
            "OS": "Lower ctDNA values at baseline correlated with extended OS (log-rank p=0.002, HR=0.46, 95% CI 0.28-0.75). Early reduction in ctDNA level from baseline to treatment cycle 3 was associated with significant increases in OS (log-rank p=0.015, HR=0.44, 95% CI 0.22-0.87). ctDNA clearance resulted in significant improvement in OS (log-rank p=0.01, HR=0.29, 95% CI 0.1-0.8).",
            "ORR": "None",
            "AE": "None",
            "Other": "All plasma timepoints from periods of durable complete response (CR) assessed via RECIST 1.1 were ctDNA-negative, with a molecular clearance lead time of 277 days over radiographically confirmed CR.",
            "Conclusion": "Early ctDNA dynamics are predictive of long-term ICI response in the advanced, pan-cancer setting. An ultra-sensitive assay is crucial for accurate MRD status determination, with low ctDNA detections down to 4.2 PPM that might otherwise have been missed."
        },
        {
            "Title": "CD8 radiomics signature to assess inter-lesion spatial heterogeneity and predict clinical outcome of advanced non-small cell lung cancers treated with durvalumab.",
            "Indication": "Advanced non-small cell lung cancer (NSCLC)",
            "Drug": "Durvalumab",
            "PFS": "The least infiltrated lesion of a patient according to the radiomics score of CD8 T-cells was positively associated with PFS (HR=0.68, P-value=0.014). Patients with liver lesions had worse prognosis than patients without liver lesions (HR=2 for PFS, P-value=0.00022). In these patients, the CD8 radiomics score enabled the stratification of patients according to hot and cold liver metastasis (HR=0.54, P-value=0.038 for PFS).",
            "OS": "The least infiltrated lesion of a patient according to the radiomics score of CD8 T-cells was positively associated with OS (HR=0.70, P-value=0.029). Patients with liver lesions had worse prognosis than patients without liver lesions (HR=2 for OS, P-value=0.00012). In these patients, the CD8 radiomics score enabled the stratification of patients according to hot and cold liver metastasis (HR=0.6, P-value=0.072 for OS).",
            "ORR": "None",
            "AE": "None",
            "Other": "A low CD8 radiomics score at baseline was associated with a significantly higher risk of progression at the lesion-level (AUC=0.59, P-value < 0.0001), especially for liver lesions (AUC=0.66, P-value=0.0002). A 4-class stratification of the whole cohort based on the least infiltrated (cold/hot) and liver or non-liver lesion was independently associated with clinical outcomes.",
            "Conclusion": "The CD8 radiomics score has shown independent prognostic values when adjusting for PD-L1 status in multivariate analyses. It has shown interesting results in discriminating outcomes of patients with liver lesions by identifying hot and cold lesions."
        },
        {
            "Title": "Effectsofneutralizationoftumor-derivedimmunosuppressantGDF-15on anti-PD-1 activity in anti-PD-(L)1 relapsed/refractory non-squamous NSCLC, urothelial, and hepatocellular cancer.",
            "Indication": "Non-squamous NSCLC, urothelial cancer, hepatocellular cancer",
            "Drug": "Visugromab (CTL-002) in combination with nivolumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "13.5% (5/37, 4PR, 1CR) in non-sqNSCLC, 0% (0/15) in sq-NSCLC, 17.6% (6/34, 5PR, 1CR) in UC, 18.8% (3/16, 2PR, 1CR) in HCC",
            "AE": "6.9% of patients experiencing CTCAE Grade \u22653 treatment-related adverse events",
            "Other": "Duration of response (DoR) surpassing 12 months for non-sq and UC lead cohort patients (N=27 each) already, and 10/14 responses are ongoing.",
            "Conclusion": "These analyses presented identify GDF-15 as a novel, potent immunosuppressant in the tumor microenvironment of non-sqNSCLC, UC, and HCC and identify it as a potential key cause for CPI resistance. In heavily pretreated, by strict criteria anti-PD-1/-L1 r/r, late/last-line patients with NSCLC, UC, and HCC, neutralization of GDF-15 by visugromab in combination with nivolumab resulted in an ORR of 16.1% (14/87; 11PR and 3CR) across these indications and long durability."
        },
        {
            "Title": "Evaluationofmixedresponseintumorsizeandsurvivalinpatientswithrare cancerstreatedwithdualcheckpointinhibitortherapy(DARTSWOGS1609).",
            "Indication": "Various rare solid tumors",
            "Drug": "Ipilimumab plus nivolumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Survival outcomes driven by the 'worst' performing lesion; having an increase in any lesion is associated with worse outcomes, even if not all lesions increase.",
            "Conclusion": "This is the first evaluation of the association between mixed response and survival outcomes among patients with various tumors receiving dual checkpoint therapy. Our results suggest that survival outcomes are driven by the 'worst' performing lesion; in other words, having an increase in any lesion is associated with worse outcomes, even if not all lesions increase."
        },
        {
            "Title": "Effect of fecal transplantation on patient reported outcomes after immune checkpoint inhibitor colitis.",
            "Indication": "Immune-mediated colitis (IMC)",
            "Drug": "Fecal Microbiota Transplantation (FMT)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Transient complication rate is 30.5% at 7 days and 18.6% at 30 days.",
            "Other": "IMC symptom response was 84.7% after FMT with median time to response of 4 days. 84.7% patients demonstrated clinical remission by the end of the study period.",
            "Conclusion": "FMT may serve as a potential treatment option in IMC refractory to standard treatment to avoid long-term steroid dependency and immunosuppression. It is effective to maintain IMC remission with a low complication rate."
        },
        {
            "Title": "Phase I study of GUCY2C CAR-T therapy IM96 in patients with metastatic colorectal cancer.",
            "Indication": "Metastatic colorectal cancer (mCRC)",
            "Drug": "GUCY2C-targeted CAR-T (IM96)",
            "PFS": "Median progression-free survival time was 7 months.",
            "OS": "None",
            "ORR": "Objective response rate (ORR) was 26.3%. In DL3 group, ORR was 40.0%.",
            "AE": "Grade 1-2 CRS occurred in 80.0% of patients. Grade 1-3 rash in 70.0%, Grade 3 diarrhea in 55.0%, and Grade 1-3 oral mucositis in 35.0%.",
            "Other": "Disease control rate (DCR) was 73.7%. Median duration of response was 10 months in DL3 group.",
            "Conclusion": "IM96 has durable efficacy with acceptable safety profile in pMMR mCRC patients, particularly showing high therapeutic potential in liver metastasis patients."
        },
        {
            "Title": "Safety and preliminary efficacy results of IBI389, an anti-CLDN18.2/CD3 bispecific antibody, in patients with solid tumors and gastric or gastroesophageal tumors: A phase 1 dose escalation and expansion study.",
            "Indication": "Advanced solid tumors, gastric/gastroesophageal junction cancer (G/GEJC), pancreatic ductal adenocarcinoma (PDAC)",
            "Drug": "IBI389 (anti-CLDN18.2/CD3 bispecific antibody)",
            "PFS": "None",
            "OS": "None",
            "ORR": "In G/GEJC patients with previous treatments \u22652 lines, ORR was 30.8%.",
            "AE": "Treatment-emergent adverse events (TEAEs) occurred in 98.2% of patients, with 66.7% experiencing grade \u22653 TEAEs. Treatment-related adverse events (TRAEs) occurred in 97.4% of patients, with 55.3% experiencing grade \u22653 TRAEs. CRS-related adverse events occurred in 57.0% of patients.",
            "Other": "Disease control rate (DCR) was 73.1% in G/GEJC patients with previous treatments \u22652 lines.",
            "Conclusion": "IBI389 showed manageable safety profiles in patients with advanced solid tumors and preliminary efficacy in CLDN18.2-positive patients with G/GEJC."
        },
        {
            "Title": "Phase I study of MCLA-145, a bispecific antibody targeting CD137 and PD-L1, in solid tumors, as monotherapy or in combination with pembrolizumab.",
            "Indication": "Advanced/metastatic solid tumors",
            "Drug": "MCLA-145 and pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "Monotherapy: 5 partial responses (PRs) in glioblastoma, sarcoma, cervical, anal, and gastric cancer; Combination: 1 PR in Merkel cell carcinoma, 1 complete response in PD-L1+ NSCLC",
            "AE": "Monotherapy: fatigue (51%/4%), decreased appetite (34%/2%), dyspnea (32%/0%), anemia (30%/9%), ALT/AST increase (25%/11%), pyrexia (25%/0%); Combination: fatigue (58%/11%), cough (42%/0%), constipation (32%/0%), ALT/AST increase (21%/11%)",
            "Other": "Disease control rate was 37% with monotherapy and 68% with combination. Exposure was dose-dependent with a terminal half-life of 69h at 75mg. Measure of peripheral blood Ki67+CD8 T cells supports maximal PD activity at 40mg.",
            "Conclusion": "MCLA-145 given alone or in combination with pembrolizumab had a well-tolerated and manageable safety profile with encouraging clinical activity, including in patients who relapsed after PD-(L)1 therapies."
        },
        {
            "Title": "Safety and preliminary efficacy of EIK1001 in combination with pembrolizumab in participants with advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "EIK1001 and pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "Complete response (CR) or partial response (PR) observed for 7/50 (14.0%), including 3 CR and 4 PR. Disease control (including CR, PR, or stable disease) observed in 24/50 (48.0%). Median duration of response was 10 months (range=4 to 32 months).",
            "AE": "82.4% experienced a treatment-related adverse event (TRAE). 17.7% experienced a \u2265 Grade 3 TRAE, including fatigue, cytokine release syndrome (CRS), hemiparesis, hypertension, joint range of motion reduced, muscular weakness, pancreatitis, rash (maculopapular), skin plaque, and stomatitis. Manageable CRS in 9.8%, with only 1 discontinuation due to CRS.",
            "Other": "EIK1001 PK was linear and dose-proportional, and combination with pembrolizumab did not affect EIK1001 PK.",
            "Conclusion": "EIK1001 was well-tolerated with a manageable safety profile and showed encouraging preliminary efficacy across several tumor types in combination with pembrolizumab. Responses were observed even in heavily pretreated patients not anticipated to respond to pembrolizumab monotherapy."
        },
        {
            "Title": "Preliminary results from the phase 2 study of AFM24 in combination with atezolizumab in patients with EGFR wild-type (EGFR-WT) non-small cell lung cancer (NSCLC).",
            "Indication": "EGFR wild-type non-small cell lung cancer (NSCLC)",
            "Drug": "AFM24 and atezolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "One complete response and three partial responses confirmed; two showed shrinkage of \u226550% in target lesions from baseline. All responders were resistant to prior CPI. Seven patients achieved stable disease as BOR.",
            "AE": "76.2% of patients experienced treatment-related adverse events (TRAEs) and 19.0% had grade 3 TRAEs. The most frequent TEAEs (with incidence >10%) were anemia, lymphocyte count decreased, infusion-related reactions (10 Grade 1\u20132, two Grade 3).",
            "Other": "None",
            "Conclusion": "AFM24 with atezolizumab shows remarkable signs of clinical efficacy, even in patients with resistance to prior CPI, and a well-tolerated and manageable safety profile in patients with EGFR-WT NSCLC. The study is ongoing and up to 40 patients will be enrolled into this cohort."
        },
        {
            "Title": "First-in-human study of ZGGS18, a dual specific antibody targeting VEGF and TGF-b, as monotherapy in patients with advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "ZGGS18",
            "PFS": "None",
            "OS": "None",
            "ORR": "One subject with endometrial carcinoma received 19 infusions of ZGGS18 and is remaining in this study, and another subject with rectal carcinoma had 29.65% tumor shrinkage at week 12.",
            "AE": "76.2% of patients experienced treatment-related adverse events (TRAEs) and 19.0% had grade 3 TRAEs. The most frequent TEAEs (with incidence >10%) were anemia, lymphocyte count decreased, infusion-related reactions (10 Grade 1\u20132, two Grade 3).",
            "Other": "Cmax and AUC increased from 0.3-20mg/kg approximately in dose proportion.",
            "Conclusion": "ZGGS18 demonstrated its safety and tolerability in this dose-escalation study, along with a good PK profile, providing a good basis for monotherapy and/or combination therapy for future use."
        },
        {
            "Title": "Results from phase 1a/1b analyses of TTX-080, a first in class HLA-G antagonist, in combination with cetuximab in patients (pts) with metastatic colorectal cancer and head and neck squamous cell carcinoma.",
            "Indication": "Metastatic colorectal cancer and head and neck squamous cell carcinoma",
            "Drug": "TTX-080 in combination with cetuximab",
            "PFS": "mPFS: mCRC - 18.3 weeks (95% CI: 10.7, NA), 24.4 weeks (95% CI: 2.5, NA), 23.9 weeks (95% CI: 9, NA); HNSCC - 9.1 weeks (95% CI: 8.3, NA)",
            "OS": "None",
            "ORR": "mCRC: WT 23%, 2K 29%, 2RAS/BRAF/Her2-Neg 57%; HNSCC: HPV-Neg 0%",
            "AE": "Ph1a: arthralgia, fatigue, decreased appetite; Ph1b: fatigue, nausea, anemia, diarrhea, AST increase, headache, vomiting, pruritis",
            "Other": "DCR: mCRC - WT 68%, 2K 71%, 2RAS/BRAF/Her2-Neg 71%; HNSCC - HPV-Neg 40%",
            "Conclusion": "TTX-080 is well tolerated alone and in combination with cetuximab. TTX-080 + cetuximab demonstrates promising activity in pts with HPV-Neg HNSCC and WT RAS/BRAF/Her2-Neg mCRC as manifested by the responses and PFS. These early findings warrant further investigation of TTX-080 + cetuximab in a randomized controlled study against standard of care in mCRC and HNSCC."
        },
        {
            "Title": "Phase I dose escalation trial of the first-in-class TNFR2 agonist monoclonal antibody, HFB200301, in monotherapy and in combination with tislelizumab, an anti-PD-1 monoclonal antibody, in adult patients with advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "HFB200301 in monotherapy and in combination with tislelizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Monotherapy: pruritis (11%), tremor (7%), fatigue (7%), asthenia (7%), nausea (7%); Combination: pruritis (11%), tremor (7%), fatigue (7%), asthenia (7%), nausea (7%)",
            "Other": "PK analysis demonstrated non-linear clearance of HFB200301, with dose-proportional maximum concentration [Cmax], achieving sufficient exposures for peripheral target engagement in all tested doses. Emerging PD data demonstrate evidence of immune activation and expansion of CD8+ T cells, but not Tregs, in the tumor microenvironment and peripheral blood.",
            "Conclusion": "HFB200301 demonstrates a favorable safety profile, dose-dependent PK, with PD and preliminary clinical activity in monotherapy and in combination with tislelizumab in pts with heavily pre-treated refractory solid tumors."
        },
        {
            "Title": "Updated results of a phase 1/2 study of AU-007, a monoclonal antibody (mAb) that binds to IL-2 and inhibits CD25 binding, in patients with advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "AU-007",
            "PFS": "None",
            "OS": "None",
            "ORR": "Confirmed partial response (32% decrease) in a nasopharyngeal carcinoma patient in Arm 1C who progressed on anti-PD-1 therapy. Tumor shrinkage occurred in patients with non-small cell lung, bladder, head and neck, colorectal (CRC), and renal cancer. A melanoma patient (Arm 1B) who progressed on anti-CTLA-4 + anti-PD-1 therapy had a 48% decrease in target tumors.",
            "AE": "Pyrexia (18%), fatigue (16%), nausea (14%), lymphopenia (8%), chills (6%). Two patients had transient Grade 2 drug-related serious AEs: pyrexia Arm 1B and cytokine release syndrome Arm 1C; both patients continued therapy.",
            "Other": "Serum Tregs and eosinophils decreased in patients while NK and CD8 cell counts trended upwards. The CD8:Treg ratio trends upward in all cohorts.",
            "Conclusion": "The mild toxicity profile and promising early efficacy observed in dose escalation across multiple tumor types in heavily pretreated patients, along with initial pharmacodynamic data, warrant continued evaluation of AU-007 + low dose SC aldesleukin in the Phase 2 expansion cohorts of the study."
        },
        {
            "Title": "A phase I/II open label, multicenter study to assess the safety, tolerability, pharmacokinetics, and efficacy of HB0028 in patients with advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "HB0028",
            "PFS": "None",
            "OS": "None",
            "ORR": "6.25% (1/16; 95% CI, 0%-30.2%)",
            "AE": "93.8% experienced any grade TRAEs; 12.5% experienced \u2265 Grade 3 TRAEs (G3 Anemia and G3 \u03b3-glutamyl-transferase)",
            "Other": "DCR was 25% (4/16; 95% CI, 7.3%-52.4%). Durable clinical benefit (SD for >32 weeks) was seen in 1 subject with malignant tumor in the left submandibular gland.",
            "Conclusion": "HB0028 was generally well tolerated, and therapy provided modest anti-tumor activity in patients with heavily pretreated advanced solid tumors. Based on these data, a phase II prospective clinical trial is being planned in specific cancer."
        },
        {
            "Title": "A phase I trial safety and efficacy of ragistomig, a bispecific antibody targeting PD-L1 and 4-1BB, in advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "Ragistomig (ABL503/TJ-L14B)",
            "PFS": "None",
            "OS": "None",
            "ORR": "15.3% for all dose levels; 30% at 5 mg/kg (3/10)",
            "AE": "75.5% experienced at least one TRAE; most common TRAEs (\u226510%, any grade/grade 3-4) were elevated AST (30.6%/18.4%), elevated ALT (26.5%/18.4%), rash (14.3%/4.1%), nausea (12.2%/0%), pyrexia (12.2%/2.0%), and fatigue (10.2%/0%)",
            "Other": "Clinical benefit rate (CBR) for all dose levels was 61.5%, and CBR at 5 mg/kg was 80% (8/10). 66.7% of responders received prior PD-(L)1 inhibitors. One patient achieved sustained tumor regression over 6 months, even after treatment discontinuation.",
            "Conclusion": "ABL503 had a manageable safety profile and demonstrated promising anti-tumor activity, with objective responses in 6 out of 39 efficacy-evaluable patients across multiple tumor types in heavily pre-treated patients, including patients previously treated with checkpoint inhibitors. The data support continued development of ABL503 alone and in combination with other compounds, as a potential therapeutic option for patients with solid tumor cancers."
        },
        {
            "Title": "A phase I monotherapy dose escalation study of HFB301001, a novel next generation OX40 agonist monoclonal antibody, in adult patients with advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "HFB301001",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "34.6% experienced treatment-related adverse events (TRAEs); none \u2265 grade 3. Common TRAEs included rash (19%), pruritis (12%), and arthralgia (8%). No dose-limiting toxicities were observed.",
            "Other": "Preliminary results demonstrate dose-proportional increases in maximum concentration, typical mAb volume of distribution, and nonlinear clearance. On-treatment biopsies revealed expansion of both natural killer and cytotoxic CD8+ T cells, with no notable expansion of Tregs. Preliminary efficacy analysis indicates a disease-control rate (DCR) of >60% with greatest effect seen in hepato-cellular and renal cell carcinoma. Median time on treatment was 1.9 months (range 0.9-17 months).",
            "Conclusion": "HFB301001 demonstrates a favorable safety profile, dose-dependent PK and PD immunomodulation, and DCR in heavily pretreated solid tumors. HFB301001 warrants further evaluation and expansion in specific tumor types is planned."
        },
        {
            "Title": "A phase 3 study to evaluate efficacy and safety of VYD222, an IgG1 monoclonal antibody for prevention of COVID-19 (CANOPY): Subset analysis of participants with significant immune compromise in the setting of solid tumor or hematologic malignancies.",
            "Indication": "Prevention of COVID-19 in patients with solid tumor or hematologic malignancies",
            "Drug": "VYD222",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Two of 55 (3.6%) participants experienced adverse events (tachycardia, fatigue/night sweats) related to study drug.",
            "Other": "High calculated sVNA titers at Day 28 against a broad range of relevant SARS-CoV-2 variants.",
            "Conclusion": "VYD222 4500mg IV was well tolerated in a subset of adult participants with solid tumor or hematologic malignancies. VYD222 produced high calculated sVNA titer levels against SARS-CoV-2 variants of interest."
        },
        {
            "Title": "A phase 3 study to evaluate efficacy and safety of Acasunlimab (DuoBody-PD-L1x4-1BB) alone or in combination with pembrolizumab (pembro) in patients (pts) with previously treated metastatic non-small cell lung cancer (mNSCLC): Initial results of a randomized, open-label, phase 2 trial.",
            "Indication": "Metastatic non-small cell lung cancer (mNSCLC)",
            "Drug": "Acasunlimab, Pembrolizumab",
            "PFS": "6-mo PFS rates of 0% for arm A, 18% for arm B, and 33% for arm C",
            "OS": "None",
            "ORR": "Unconfirmed ORR: 31% for arm A, 25% for arm B, and 30% for arm C. Confirmed ORRs: 13% for arm A, 21% for arm B, and 22% for arm C",
            "AE": "Most common TRAEs (all grades; grade \u22653): asthenia (17.4%; 8.7%), diarrhea (17.4%; 0%), nausea (17.4%; 0%), anemia (13%; 4.3%) for mono, and liver-related events (18.7%; 13.3%), fatigue (14.7%; 0%), asthenia (13.3%; 0%), and diarrhea (12%; 0%) for combo.",
            "Other": "Early peripheral pharmacodynamics were consistent with acasunlimab-mediated immune activation in all arms, with a more pronounced increase in CD8 T-cell proliferation with combo.",
            "Conclusion": "In PD-L1+ pts with mNSCLC following progression on prior CPI tx, acasunlimab + pembro combo showed a manageable safety profile and promising efficacy, with deeper responses and durable disease control in pts treated Q6W. Enrollment is ongoing."
        },
        {
            "Title": "A phase 1, dose escalation/dose-expansion study of QLF31907, a bispecific antibody (BsAb) targeting PD-L1 and 4-1BB, in patients with advanced solid tumors and lymphoma.",
            "Indication": "Advanced solid tumors and relapsed/refractory (r/r) lymphoma",
            "Drug": "QLF31907",
            "PFS": "None",
            "OS": "None",
            "ORR": "PR observed in 6 patients (15.8%), 3 PRs confirmed. SD observed in 20 patients (52.6%). DCR was 60.5% (23/38).",
            "AE": "All patients experienced TEAEs (treatment-related, 92.1%). Most common TEAE was anemia (73.7%), followed by hypertriglyceridemia (50.0%). 24 patients (63.2%) experienced Gr \u22653 TEAEs (treatment-related, 31.6%). Most common Gr \u22653 TEAE was pneumonia (13.2%). TEAEs leading to treatment discontinuation occurred in 6 patients (15.8%) (treatment-related, 7.9%). Serious TEAEs occurred in 20 patients (52.6%) (treatment-related, 26.3%).",
            "Other": "Two patients had PRs lasting for more than 1 year: 1 with PD-1/PD-L1 naive cervical cancer (PR 18 months) and 1 with PD-1/PD-L1 treated melanoma (PR 13 months).",
            "Conclusion": "QLF31907 showed an acceptable safety profile and preliminary clinical activity in heavily pretreated patients with advanced solid tumors and lymphoma. Encouraging clinical activity was observed in patients who have failed PD-1/PD-L1 therapy and further research on the mechanism is ongoing."
        },
        {
            "Title": "Phase 1 study of CTX-471, a novel CD137 agonist antibody, in patients with progressive disease following PD-1/PD-L1 inhibitors in metastatic or locally advanced malignancies.",
            "Indication": "Metastatic or locally advanced malignancies following PD-1/PD-L1 inhibitors",
            "Drug": "CTX-471",
            "PFS": "None",
            "OS": "None",
            "ORR": "Complete Response (CR) confirmed by PET scan in 1 of 3 patients with small-cell lung cancer. Four additional PRs observed: 3 of 11 (27.3%) patients with melanoma and 1 of 4 (25%) patients with mesothelioma.",
            "AE": "TRAE reported in 64% of patients (51/79), 87% of them were Grade 1-2. Treatment discontinuation due to AE was reported in 5 patients.",
            "Other": "None",
            "Conclusion": "In this phase 1 study, CTX-471 was shown to be a safe and well-tolerated, novel anti-CD137 antibody. CTX-471 monotherapy demonstrates promising monotherapy anti-tumor activity in refractory patients whose tumors have progressed on approved PD-1 or PD-L1 inhibitors. The combination arm with pembrolizumab is ongoing and will be reported at a later date."
        },
        {
            "Title": "Updated results on the bispecific PSMAxCD3 antibody CC-1 for treatment of prostate cancer.",
            "Indication": "Prostate cancer",
            "Drug": "CC-1",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "CRS (86%), elevated liver enzymes (61%), hypertension (18%), xerostomia (7%)",
            "Other": "Rapid and profound decline of PSA levels with up to 62% reduction compared to baseline in all but one heavily pretreated patient",
            "Conclusion": "CC-1 is a promising compound with a favourable toxicity profile and promising clinical activity."
        },
        {
            "Title": "Development of NTX-472, a formulated mRNA therapy targeting CD20, CD19, and CD47, for treatment of B-cell lymphoma.",
            "Indication": "B-cell lymphoma",
            "Drug": "NTX-472",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Improved tumor killing and B cell depletion over mono and bispecific controls in vitro; robust serum titers and control of Raji tumors in a xenograft model; rapid and durable depletion of B cells in circulation with no detectable multispecific binding to red blood cells",
            "Conclusion": "NTX-472 has increased specificity for tumor cells and potential to enable broad efficacy while limiting hematotoxicity within a setting of increased tumor heterogeneity post-Rituximab treatment."
        },
        {
            "Title": "A phase 1b study of NC410 in combination with pembrolizumab in immune checkpoint inhibitor (ICI) na\u00efve, and refractory microsatellite stable (MSS)/microsatellite instability-low (MSI-L) colorectal cancer (CRC) and ovarian cancer.",
            "Indication": "Colorectal cancer (CRC) and ovarian cancer",
            "Drug": "NC410 in combination with pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "CRC: 2 confirmed PRs, 6 durable SD (DCR 47%), 9 PD; Ovarian: 2 ongoing PRs, 2 durable SD, 5 PD",
            "AE": "Diarrhea, fatigue, myocarditis (Gr3)",
            "Other": "Decrease in immature collagen, higher CD8+ effector memory T cells, lower myelosuppressive neutrophils in CRC subjects without liver metastasis",
            "Conclusion": "NC410 in combination with pembrolizumab shows promising clinical activity in hard-to-treat, advanced metastatic CRC and ovarian cancer."
        },
        {
            "Title": "A phase I study to evaluate the safety and tolerability of JCXH-211 (a self-replicating mRNA encoding IL-12) intratumoral injection in patients with malignant solid tumors: Results from the phase Ia dose escalation.",
            "Indication": "Malignant solid tumors",
            "Drug": "JCXH-211",
            "PFS": "None",
            "OS": "None",
            "ORR": "Shrinkage of treated lesions by 13.0%, 33.3%, and 43.0% in BC, HNSC, and MEL respectively; 31% shrinkage in a non-injected lesion of HNSC",
            "AE": "Grade 1/2 AEs, 1 patient with Grade 3 lymphocytopenia and anemia",
            "Other": "Increased T and NK cell infiltration (up to 138 folds) post-study drug administration",
            "Conclusion": "JCXH-211 IT administration demonstrated good safety profile and antitumor activities in heavily pretreated late-stage patients. Significant increases of T and NK cell infiltration were observed."
        },
        {
            "Title": "Initial data from a phase 1, first-in-human clinical trial for T-Plex, a multiplexed, enhanced T cell receptor-engineered T cell therapy (TCR-T) for solid tumors.",
            "Indication": "Solid tumors",
            "Drug": "T-Plex (multiplexed TCR-T)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "65% of participants have \u22651 HLA match, 20% have 2 HLA matches, and 6% have 3 HLA matches. 92% express at least one target, and 65% qualify for at least one TCR-T. 13% had LOH of the targeted HLA allele.",
            "Conclusion": "Initial data indicate that the combination of HLAs and targets in the ImmunoBank results in \u22651 TCR-T match for the majority of solid tumor patients evaluated to date, and many patients qualify for multiplexed TCR-T treatment. LOH testing can prevent selection and treatment with a TCR-T that would not confer benefit."
        },
        {
            "Title": "Updated results from first-in-human phase 1 dose-escalation trial of TAK-102, a GPC3-targeted armored CAR-T cells, in patients with advanced solid tumors.",
            "Indication": "Advanced solid tumors (including hepatocellular carcinoma, gastric neuroendocrine carcinoma, liposarcoma)",
            "Drug": "TAK-102 (GPC3-targeted armored CAR-T cells)",
            "PFS": "None",
            "OS": "None",
            "ORR": "Five patients achieved stable disease (SD), with the greatest reduction in tumor size being 26.4%.",
            "AE": "Six patients experienced cytokine release syndrome (Grade 1: 5 patients, Grade 2: 1 patient); all cases were manageable. No dose-limiting toxicities or neurotoxicity were observed.",
            "Other": "AFP was measured as a tumor marker for HCC. Among 8 patients with HCC, 4 had SD after treatment with TAK-102 and 3 showed a decrease or stabilization of AFP levels. CK profiles showed improvement in TAK-102 exposure (Cmax, AUC) when escalating from DL1 to DL2. Dose-dependency was observed in peak CCL19, IFN-g, and IL-6 levels.",
            "Conclusion": "TAK-102, an armored CAR-T, is well tolerated and has a manageable safety profile with some early signs of anti-tumor activity. For CK, TAK-102 exposure (Cmax, AUC) showed improvement from DL1 to DL2, and a slight decrease in Tmax from DL2 to DL3. Dose-dependency was observed in peak CCL19, IFN-g, and IL-6 levels, which may point towards increased signal of activity from DL1 to DL3."
        },
        {
            "Title": "The role of obesity on outcomes of adoptive cellular therapy in solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "Adoptive Cellular Therapy (ACT)",
            "PFS": "Median PFS: 2.8 months (95% CI: 2.3, 3.5)",
            "OS": "Median OS: 8.4 months (95% CI: 6.4, 11.0)",
            "ORR": "None",
            "AE": "Higher proportion of immune effector cell-associated neurotoxicity syndrome (ICANS) in normal weight patients (23.1% vs 0%, p=0.049)",
            "Other": "None",
            "Conclusion": "Obesity was associated with improved survival and decreased ICANS rates following ACT. Our findings may suggest a differential interaction between obesity, different immunotherapeutic modalities, and hematologic vs non-hematologic cancers."
        },
        {
            "Title": "Updated results on multiple antigens stimulating cellular therapy (MASCT-I) in metastatic urothelial carcinoma: A randomized, open-label, phase I trial.",
            "Indication": "Metastatic urothelial carcinoma (mUC)",
            "Drug": "MASCT-I",
            "PFS": "Median PFS: 2.3 months; 24-month PFS rate: 55.6%",
            "OS": "Median OS: 16.9 months; OS for G2: 41.2 months",
            "ORR": "None",
            "AE": "Grade 1 and 2 adverse events including flushing, pruritus, rash, muscle cramp, fever, and arthralgia",
            "Other": "Duration of response (DOR) for G2: 6.4 months; 48-month DOR rate: 71.8%",
            "Conclusion": "MASCT-I alone or in combination with immunotherapy or chemotherapy is safe and well tolerated, with inspiring survival benefit. The combination of PD-1 inhibitors may enhance the antitumor activity of MASCT-I. IFN-g ELISPOT might be able to predict potentially beneficial populations."
        },
        {
            "Title": "Effect of novel autologous immune training platform on end-stage patients with cancer.",
            "Indication": "End-stage cancer",
            "Drug": "SUPLEXA therapeutic cells",
            "PFS": "None",
            "OS": "None",
            "ORR": "CR, PR, and many durable SD in approximately half the patients",
            "AE": "No chemo preconditioning or concomitant cytokine treatment was employed. Treatment-related AEs were observed in \u226550% of patients with no grade \u22653 AEs.",
            "Other": "Decrease in the number of peripheral myeloid-derived suppressor cells (MDSCs) within 3 weeks of SUPLEXA treatment. Stable increases in activated classical CD14+ blood monocytes in patients with CR and PR.",
            "Conclusion": "SUPLEXA therapeutic cells are a highly differentiated approach to cellular therapy. The first-in-human experience demonstrated a pristine safety profile and strong clinical benefit as a single agent. The pharmacodynamic decrease in the number of MDSCs supports further clinical testing to enhance the clinical activity of ICIs."
        },
        {
            "Title": "Exploring the safety and efficacy of GT201 as a first-in-class autologous tumor-infiltrating lymphocyte monotherapy in advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "GT201",
            "PFS": "None",
            "OS": "None",
            "ORR": "42.9% achieved a confirmed partial response (PR), 28.6% demonstrated stable disease (SD)",
            "AE": "Treatment-related AEs were observed in \u226550% of patients with no grade \u22653 AEs. Grade \u22653 AEs related to FC lymphodepleting chemotherapy and IL-2 included decreased lymphocyte count, decreased neutrophil count, and decreased white blood cell count, pyrexia, and increased heart rate.",
            "Other": "GT201 cells can be detected in all patients receiving treatment, indicated by both staining IL15RA protein on peripheral T cells and measuring the transgene copy number in peripheral white blood cells. GT201 cells expanded robustly in patients and persist in peripheral blood beyond at least 6 months post-cell infusion.",
            "Conclusion": "In patients with heavily pretreated advanced or metastatic solid tumor, GT201, when infused after FC lymphodepletion and followed by high dose IL-2, exhibits a manageable safety profile. Notably, GT201 has demonstrated a favorable clinical profile in NSCLC, with an encouraging objective response rate, response durability, and no GT201-treatment related grade \u22653 AEs."
        },
        {
            "Title": "Phase 1 study of GT101 as an autologous tumor infiltrating lymphocyte (TIL) therapy in advanced solid tumors",
            "Indication": "Advanced solid tumors",
            "Drug": "GT101",
            "PFS": "Median PFS was 4.2 months for cervical cancer cohort",
            "OS": "None",
            "ORR": "Overall ORR was 35.7%, with 28.6% PR and 7.1% CR",
            "AE": "Most Grade \u22653 AEs were related to FC conditioning regimen and IL-2, including decreased lymphocyte count, decreased white blood cell count, decreased neutrophil count, anemia, pyrexia, and decreased platelet count",
            "Other": "Disease control rate (DCR) was 57.1%. Median duration of CR was 24 weeks and PFS was 36 weeks for the CR patient",
            "Conclusion": "GT101 exhibited a manageable safety profile and favorable clinical profile, particularly in cervical cancer, with an encouraging ORR and sustained response duration"
        },
        {
            "Title": "A first-in-human study of CRISPR/Cas9-engineered tumor infiltrating lymphocytes (TILs) product GT316 as monotherapy in advanced solid tumors",
            "Indication": "Advanced solid tumors, especially gynecological tumors",
            "Drug": "GT316",
            "PFS": "None",
            "OS": "None",
            "ORR": "One cervical cancer patient experienced a confirmed CR with a duration of 32 weeks. One ovarian cancer patient experienced a confirmed PR with a duration of 22 weeks",
            "AE": "All patients experienced TRAEs, mostly Grade 1 or 2. Grade 3/4 TRAEs included decreased lymphocyte count, decreased white blood cell count, decreased monocyte count, and decreased neutrophil count",
            "Other": "Robust TIL expansion post-infusion and enhanced IFN-g secretion in CR and PR patients",
            "Conclusion": "GT316 demonstrates good tolerability with encouraging preliminary anti-tumor efficacy as monotherapy in patients with advanced solid cancers"
        },
        {
            "Title": "Effect of CD22-directed CAR-T cells secreting anti-CD19 T cell engagers on control of leukemia progression compared to tandem anti-CD19/CD22 CAR-T cells",
            "Indication": "Relapsed/refractory B cell malignancies",
            "Drug": "CD22-directed CAR-T cells secreting anti-CD19 T cell engagers",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "CAR-STAb-T cells induced more potent and faster cytotoxic responses than TanCAR-T cells in both short- and long-term co-culture assays",
            "Conclusion": "CAR-STAb-T cells could become an alternative to CAR-T therapies for the treatment of relapsed/refractory B cell malignancies, especially in lymphodepleted patients with low T cell counts"
        },
        {
            "Title": "IOV-3001, a modified interleukin-2 fusion protein, for potential use in tumor-infiltrating lymphocyte cell therapy regimens",
            "Indication": "Solid tumor malignancies",
            "Drug": "IOV-3001",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "IOV-3001 was well tolerated in monkeys across the dose range studied except for one animal at the highest dose level, with recovery by Day 29. Inflammatory cytokines increased from 4 hours to \u22643 days after dosing and returned to baseline by Day 29",
            "Other": "IOV-3001 exhibited a similar mechanism of action to aldesleukin in vitro and has a longer half-life in vivo. PD effects were consistent with the MoA of IL-2",
            "Conclusion": "IOV-3001 showed a favorable preclinical safety profile and suggests a potentially improved safety profile with less frequent dosing compared with aldesleukin"
        },
        {
            "Title": "Non-clinical evidence supporting the upcoming CLD-201 clinical trial: Cell-based oncolytic virotherapy for multiple solid tumors.",
            "Indication": "Multiple solid tumors",
            "Drug": "CLD-201 (SuperNova-1)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Non-clinical studies revealed that both intratumoral and systemic administration were well-tolerated.",
            "Conclusion": "CLD-201 offers several important advantages over the autologous approach, including enhanced potency through significant viral amplification within the stem cells, improved manufacturing reproducibility, off-the-shelf ability to treat multiple cancer types, and significantly lower cost. A phase I non-randomized clinical trial has been designed to evaluate the safety and initial anti-tumor effects of intratumoral administration of CLD-201."
        },
        {
            "Title": "Effect of placental circulating T cells expressing CD16 on multiple hematological and solid tumor cancers through combination with various monoclonal antibodies.",
            "Indication": "Multiple hematological and solid tumor cancers",
            "Drug": "PT-CD16VS",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "In vitro, PT-CD16VS exhibited potent ADCC and cytokine release when combined with various monoclonal antibodies. In vivo, PT-CD16VS with Rituximab or Trastuzumab demonstrated significant survival benefit and tumor volume reduction, respectively.",
            "Conclusion": "PT-CD16VS has potent in vitro and in vivo ADCC activity, and a single drug product has the potential to be combined with various monoclonal antibodies to target multiple cancers across hematological and solid tumor indications. This 'universal receptor' with antibody-dependent targeting approach, together with the benefits of an allogeneic cell platform, may democratize accessibility of such therapies for patients."
        },
        {
            "Title": "Erythrocyte-aPD-1 conjugates overcome resistance to checkpoint blockade immunotherapy: A first-in-human study.",
            "Indication": "Various solid tumors resistant to anti-PD-1 immunotherapies",
            "Drug": "WTX-212",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None of the patients treated with WTX-212 experienced treatment-related adverse events greater than Grade 3, indicating a high safety profile.",
            "Other": "Disease control was achieved in 5/7 patients (DCR=71%). A patient with esophageal cancer achieved a confirmed complete remission after 6-cycle treatment. Two patients, one with esophageal cancer (3L) and another with HPV-negative cervical cancer (4L), maintained stable disease for over 40 and 30 weeks, respectively. A substantial reduction in MDSCs was observed in 6/7 patients (ranging from 24%-82%). A median 1.5-fold increase in T cells was noted in all patients in the peripheral blood after the 1st cycle of the treatment.",
            "Conclusion": "WTX-212 treatment is safe and tolerable and shows promising clinical signs in cancer patients resistant to anti-PD-1 immunotherapy, supporting further investigation and exploration of WTX-212 monotherapy and combination therapy."
        },
        {
            "Title": "Antagonizing vasoactive intestinal peptide (VIP) receptors with Muc16CD-directed armored CAR T cells for pancreatic cancer.",
            "Indication": "Pancreatic ductal adenocarcinoma (PDAC)",
            "Drug": "CAR/VIPRa T cells",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "CAR/VIPRa T cells reveal that VIPR antagonism metabolically reprograms CAR T cells and drives a memory-rich product. CAR/VIPRa T cells are less activated and less exhausted by the manufacturing process, which lends to better viability and a metabolically quiescent phenotype at baseline. These distinct features allow CAR/VIPRa T cells, when antigen-stimulated, to have enhanced activation and expansion with repeated stimulation. In vivo, CAR/VIPRa T cells have enhanced expansion, phenotype, infiltration, and persistence, which ultimately reduces PDAC tumor burden. In a patient-derived PDAC preclinical mouse model where CAR T is typically ineffective, CAR/VIPRa T cells significantly reduce tumor burden.",
            "Conclusion": "This work demonstrates Muc16CD as a clinically relevant TAA target for CAR T therapy in PDAC. Furthermore, antagonizing the previously undescribed VIP checkpoint pathway in CAR T cells produces enhanced phenotypic and functional profiles. Collectively, this data demonstrates that novel CAR/VIPRa T cells create an advantageous cellular therapy product capable of treating PDAC."
        },
        {
            "Title": "Transforming tumor immune microenvironments with a novel systemic enveloped oncolytic virotherapy targeting all tumor sites.",
            "Indication": "Various human cancer indications (lung, melanoma, head & neck)",
            "Drug": "envRT-01 (enveloped RT-01 virus)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "EnvRT-01 particles exhibited an approximately 80% survival rate in the presence of active complement. In animal studies, a single systemic dose of envRT-01 selectively targeted three distinct human cancer indications, leading to the suppression of tumor growth in all three cases. In an immunocompetent syngeneic lung tumor model, envRT-01 effectively targeted and reduced multiple murine lung tumors with just a low single dose of treatment. EnvRT-01 was capable of targeting and expressing viral-encoded proteins in all tumor sites and drastically modifying the tumor immune microenvironment, favoring an anti-tumor immune phenotype and facilitating other cell therapies such as innate-based cell therapies.",
            "Conclusion": "The development of this innovative enveloped oncolytic virotherapeutic, coupled with advancements in its manufacturing methods, opens up new possibilities in the realm of cancer therapy. It addresses the limitations posed by untargetable and untreatable metastatic diseases, presenting a transformative solution with broad implications for the field."
        },
        {
            "Title": "Combinatorial cellular therapy in pediatric solid tumors with natural killer (NK) and genetically engineered myeloid cells (GEMys).",
            "Indication": "Pediatric solid tumors",
            "Drug": "TGF-b Imprinted, Ex Vivo Expanded, Universal Donor NK Cell Infusions and IL-12 GEMys",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "In vitro experiments showed TGF-b imprinted NK cells are significantly more effective at tumor cell killing compared to regular expanded NK cells. IL-12 GEMys also significantly enhance the cytotoxic effects of WT NK cells.",
            "Conclusion": "These initial in-vitro experiments provide insight into the promising effectiveness of NK-myeloid cell combination therapies in the treatment of pediatric sarcomas. Ongoing in vitro experiments include cytokine quantification by ELISA and flow cytometric analysis of NK cell activation and proliferation markers."
        },
        {
            "Title": "Chimeric antigen receptor T-cell therapy in B-cell malignancies and DNA-methylation\u2013based biological aging.",
            "Indication": "Acute lymphoblastic leukemia (ALL) and non-Hodgkin lymphoma (NHL)",
            "Drug": "Chimeric antigen receptor T-cell (CAR T-cell) therapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "DunedinPACE was positively associated with CAR T-cell therapy among patients with ALL, with an approximately 1-month higher pace of aging in transduced T cells compared to untransduced T cells. Chronological age significantly modified the association between CAR T-cell therapy and TLA among ALL patients.",
            "Conclusion": "CAR T-cell therapy was associated with greater biological aging as estimated from DNA methylation among ALL patients, and these associations are modified by chronological age. These findings suggest CAR T-cell therapy may be associated with age-related changes to the epigenome in ALL patients, and strategies to limit or reverse this effect may have implications for the aging process in cancer survivors."
        },
        {
            "Title": "Investigation of a potential protein biomarker signature that may predict clinical benefit of NT-I7 and pembrolizumab in patients with cold gastrointestinal tumors.",
            "Indication": "Microsatellite stable colorectal cancer (MSS-CRC) and pancreatic cancer (PDAC)",
            "Drug": "NT-I7 and pembrolizumab",
            "PFS": "None",
            "OS": "13.2 vs 8.9 months (NEGATIVE vs POSITIVE biomarker signature, p=0.030)",
            "ORR": "None",
            "AE": "None",
            "Other": "Baseline scRNAseq in peripheral blood showed that stem-like CD8 T cells (precursors of exhausted [TPEX] and stem-cell memory [TSCM]) had a differential activation pattern in LT patients. Preserved antigen-specific stemness may be needed for NT-I7 + pembro efficacy.",
            "Conclusion": "Preserved tumor-specific TPEX activity may be required for NT-I7 + pembro activity based on its presence in LT patients, who remained on treatment the longest. According to this analysis, there are 3 potentially predictive protein biomarkers that may help identify a patient subset more likely to experience clinical benefits from the combination treatment of NT-I7 + pembro. Further verification of the predictive nature of this signature in independent cohorts is ongoing."
        },
        {
            "Title": "Circulating metabolic profiling as a biomarker for immune checkpoint blockade efficacy.",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "Immune checkpoint blockade (ICB)",
            "PFS": "8.5 vs 1.6 months (high vs low baseline plasma levels of Glucosylceramide, p=0.0001)",
            "OS": "13.5 vs 6.1 months (high vs low baseline plasma levels of Glucosylceramide, p=0.0001)",
            "ORR": "None",
            "AE": "None",
            "Other": "Metabolomic analysis identified 8 metabolites whose relative abundance significantly differed between responding (CR/PR) and non-responding patients (SD/PD). Amongst these metabolites was Glucosylceramide (d18:1/24:0), whose abundance was increased in the plasma of the responding patients.",
            "Conclusion": "Utilizing a targeted metabolomics approach, we identified the metabolite and endogenous danger signal glucosylceramide (d18:1/24:0) as a potential metabolic biomarker of response to ICB therapy. Future work will aim to validate this finding in a larger cohort and to understand the biological mechanism underpinning this correlation."
        },
        {
            "Title": "Effect of indoximod-based chemo-immunotherapy in patients with pediatric brain tumors on activation and clonal proliferation of a circulating population of early non-exhausted stem-like CD8+ T cells whose on-treatment expansion is predictive of long-term outcome.",
            "Indication": "Pediatric brain tumors",
            "Drug": "Indoximod combined with chemotherapy or chemotherapy plus ibrutinib",
            "PFS": "None",
            "OS": "CEI-High patients: 26.5 months, CEI-Low patients: 8.9 months, HR=0.0003",
            "ORR": "None",
            "AE": "None",
            "Other": "Clonal Expansion Index (CEI) as a predictor of long-term outcome",
            "Conclusion": "Clonal expansion of non-exhausted stem-like T cells, as quantitated by the CEI assay, provides a mechanistically based pharmacodynamic readout of T cell response to indoximod-based chemo-immunotherapy."
        },
        {
            "Title": "Soluble mesothelin neutralizes mesothelin antibody-based therapies.",
            "Indication": "Mesothelioma and other solid tumors",
            "Drug": "Anetumab ravtansine and pembrolizumab",
            "PFS": "Anetumab ravtansine and pembrolizumab: High sMSLN group: 5 months, Low sMSLN group: 12 months; Pembrolizumab alone: High sMSLN group: 4 months, Low sMSLN group: 3.4 months",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "sMSLN reduction is feasible with TPE, decreasing sMSLN by an average of 43.6% or 15.4 ng/mL",
            "Conclusion": "High sMSLN levels are associated with shorter PFS for anetumab ravtansine, but not pembrolizumab. sMSLN can sequester anti-MSLN antibodies and may limit the efficacy of MSLN-targeted therapies. High levels of sMSLN could potentially be used as a biomarker to select which patients should not receive MSLN-targeting therapies."
        },
        {
            "Title": "A plasma-based proteomic platform for predicting clinical benefit from immune checkpoint inhibitors in multiple cancers.",
            "Indication": "Metastatic melanoma and HPV-related cancers",
            "Drug": "PD-(L)1 inhibitor-based therapies",
            "PFS": "Melanoma cohort: PROphet-positive patients: median not reached vs. 10.8 months, HR=0.48, p=0.04",
            "OS": "Melanoma cohort: PROphet-positive patients: 92.8 months vs. PROphet-negative patients: 9.5 months, HR=0.14, 95% CI: 0.06-0.34, p<0.0001; HPV-related cohort: PROphet-positive patients: 43.6 months vs. PROphet-negative patients: 4.4 months, HR=0.22, 95% CI: 0.08-0.59, p=0.001",
            "ORR": "None",
            "AE": "None",
            "Other": "PROphet-NSCLC test can be applied to PD-(L)1 inhibitor-treated melanoma and HPV-related cancers",
            "Conclusion": "The PROphet-NSCLC test can be applied to PD-(L)1 inhibitor-treated melanoma and HPV-related cancers, suggesting applicability to cancers beyond NSCLC. Further analysis is needed to explore the potential utility of PROphet-NSCLC for informing treatment decisions for a broad range of cancer types."
        },
        {
            "Title": "CD4 effector T cell expansion to identify objective responses to the CD40 agonist mitazalimab in combination with modified FOLFIRINOX (mFFX) as first-line therapy for metastatic pancreatic ductal adenocarcinoma (mPDAC) in the OPTIMIZE-1 study.",
            "Indication": "Metastatic pancreatic ductal adenocarcinoma (mPDAC)",
            "Drug": "Mitazalimab (anti-CD40) with mFFX chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Mitazalimab triggered an expected immune response characterized by transient cytokine (IFNg and MCP-1) release and B cell depletion. Chemotherapy caused a reduction in classical monocytes and proliferating CD4+ T cells. Both mitazalimab and chemotherapy caused a transient reduction in circulating dendritic cells and NK cells. Tumor response was associated with an expansion in the frequency of effector CD4 T cells (p < 0.0001) at day 8 after receiving mitazalimab.",
            "Conclusion": "Mitazalimab and mFFX differentially modulate immune responses in patients with mPDAC. Pharmacological analyses identify mitazalimab-induced expansion of CD4 effector T cells one week after first administration as a correlate of treatment outcomes. These data suggest the contribution of mitazalimab to tumor responses, and further substantiate a priming dose of mitazalimab, prior to administering chemotherapy."
        },
        {
            "Title": "Use of a tissue-free epigenomic circulating tumor DNA (ctDNA) assay for quantification of tumor fraction (TF) and association with outcomes from RADIOHEAD real-world advanced pan-cancer cohort.",
            "Indication": "Advanced pan-cancer",
            "Drug": "None",
            "PFS": "Baseline: mPFS 10.7 mo vs NR; HR=3.0, p < 0.001. C3D1: mPFS 10.7 mo vs NR; HR=2.9, p < 0.0001. Longer rwPFS associated with >95% reduction in TF from baseline to C3D1 or low ctDNA at both time points (rwPFS <3 mo=1.8%, 3-6 mo=7.8%, 6-12 mo=15.1%, >12 mo=75.3%; N=166, p < 0.001).",
            "OS": "Baseline: rwOS 19.9 mo vs NR; HR=2.9, p < 0.001. C3D1: rwOS 14.8 mo vs NR; HR=3.1, p < 0.001.",
            "ORR": "None",
            "AE": "None",
            "Other": "For pts with ctDNA not detected at C3D1, ctDNA detection at 6 mo post first dose was strongly associated with shorter rwPFS vs ctDNA not detected at 6 mo (mPFS 13.6 mo vs NR; HR=7.43, p < 0.001). For pts with ctDNA not detected at 6 mo, ctDNA detection at 12 months post first dose was also associated with shorter rwPFS vs ctDNA not detected at 12 mo (mPFS 22.8 mo vs NR; HR=5.2, p=0.02).",
            "Conclusion": "These data demonstrate a significant association of methylation-based ctDNA detection and on-treatment changes in TF with rwPFS and rwOS. Furthermore, subsequent TF detection 6 mo or 12 mo on-treatment, without TF detected at previous time point was associated with worse outcomes. This suggests the value of serial monitoring throughout treatment for early detection of progression and the potential to inform treatment decisions."
        },
        {
            "Title": "Impact of microbiota specific circulating memory T cells in response to immunotherapy.",
            "Indication": "Lung (NSCLC) and kidney (RCC) cancers",
            "Drug": "Immunotherapy (ICI)",
            "PFS": "Median PFS was 5.3 months (0.9-12.4).",
            "OS": "Median OS was 5.9 months (1.5-12.4).",
            "ORR": "None",
            "AE": "None",
            "Other": "15% of patients harbored Akk-specific T memory responses and those patients with CXCL13 secretion superior to the median of the cohort tended to exhibit longer PFS (p=0.064). 54% of patients harbored KLEBC T memory responses that were clinically irrelevant. 15% of patients harboring circulating Akk-specific T H1 memory responses had a shorter OS (p=0.055). VEILLEG or KLEBC-specific T H1 responses detected in 24% and 42% cases were clinically irrelevant. 26/32 patients who did not show Akk-specific T H17 responses had a better OS. ATB tended to decrease bacteria-specific CXCL13 and IFNg responses but increased T H17 memory T cells.",
            "Conclusion": "Although awaiting further validation and correlations with humoral IgG/A titers, circulating memory T cells against distinct commensals may be clinically relevant to predict benefit to immunotherapy, suggesting that such bacteria may invade tumor cells or share molecular homology with cancer antigens."
        },
        {
            "Title": "Effect of MP0317, a FAP x CD40 DARPin, on safety profile and tumor-localized CD40 activation in a phase 1 study in patients with advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "MP0317",
            "PFS": "None",
            "OS": "None",
            "ORR": "One patient achieved unconfirmed partial response, and stable disease was observed in 11 patients (24%).",
            "AE": "Grade #2 fatigue (30%), Grade #2 infusion-related reaction, nausea, and anorexia in 8, 7, and 5 patients, respectively. One patient experienced dose-limiting toxicity (Grade 3 alanine and aspartate aminotransferases elevation).",
            "Other": "Paired tumor biopsies confirmed colocalization of MP0317 with FAP and CD40. MP0317 detection in tumor biopsies at doses \u22651.5 mg/kg was associated with an increase in antigen-presenting cells, plasma and T follicular helper cell abundance, enhanced dendritic cell maturation, and IFN\u03b3 production in the TME. CXCL10 serum level increases post MP0317 treatment supported these findings.",
            "Conclusion": "MP0317 had a favorable safety profile in 46 patients across all 9 dose-escalation cohorts exploring Q3W and Q1W regimens. Doses \u22651.5 mg/kg showed evidence of pharmacodynamic TME modulation, indicating tumor-localized CD40 activation. The data support further clinical evaluation of MP0317 including combination with complementary anticancer therapies."
        },
        {
            "Title": "Efficacy, safety, and PK/PD of LVGN6051, 4-1BB agonistic antibody, with pembrolizumab in a phase Ib dose expansion in resistant NSCLC, melanoma, and GI malignancy.",
            "Indication": "Resistant NSCLC, melanoma, and GI malignancies",
            "Drug": "LVGN6051 with pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "NSCLC cohort: 2 patients had a PR (one confirmed, one unconfirmed), 2 patients had an SD. Melanoma cohort: 1 patient had an unconfirmed CR but confirmed PR, 1 patient had a confirmed PR, 4 patients had an SD. GI malignancy cohort: 3 patients had an SD. Cumulatively, 4 melanoma patients had a CR or PR, and 7 had an SD as the best objective response by RECIST.",
            "AE": "Treatment-related adverse events in 33 patients (68.8%), Grade \u22653 AE in 18 patients (37.5%), related SAE in 12 patients (30%). Most common Grade \u22653 AEs were thrombocytopenia (10.4%) and AST/ALT elevation (4.2%).",
            "Other": "Cmax and AUC were dose proportional and T1/2 was 1.90-8.91 days. Sustained exposure (>1000 ng/mL) was achieved at 2 and 4 mg/kg Q3W. Anti-drug antibodies of LVGN6051 in low titers were detected in 20 of 48 subjects without apparent effect on PK. Tumor single-cell RNA-seq immune profiling showed increased Tem cells and immune pathway activity.",
            "Conclusion": "Targeting 4-1BB and PD-1 with the combination of LVGN6051 and pembrolizumab appears to be well tolerated at this dose and schedule and shows predictable PK/PD parameters. In patients with ICI-resistant melanoma and NSCLC, durable objective responses were achieved with clinical benefits in patients resistant to prior ICI treatment. Further development of LVGN6051 is warranted."
        },
        {
            "Title": "Pharmacokinetics and biomarker analysis from a phase 1/2 open-label study of the anti-GPC3 T-cell engager SAR444200, in patients with advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "SAR444200",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "92% reported treatment-related adverse events (TRAEs) of any grade, including 3 patients with serious TRAE (2 events with hospitalization prolongation for a Grade 1 and 2 cytokine release syndrome [CRS] that recovered completely without and with Tocilizumab, respectively, 1 event with pneumonitis Grade 3). All CRS (79%) and infusion-related reaction (29%) were Grade 1 or 2.",
            "Other": "Biomarker analysis showed an increase of interleukin-6 and interferon gamma during lead-in doses, supporting CRS. Cytokines declined after Cycle 1. Among 14 HCC patients with baseline alpha-fetoprotein (AFP) higher than 20 ng/ml, 4 (29%) had at least a 20% AFP decrease on treatment. Two patients (both with HCC) have been on study drug for more than 6 months.",
            "Conclusion": "These results suggest that SAR444200 is tolerable at the tested dose levels in patients with GPC3+ advanced solid tumors. Dose escalation continues at this time."
        },
        {
            "Title": "Phase 1/2 study of NGM707, an ILT2/ILT4 dual antagonist antibody, in advanced solid tumors: Interim results from dose-escalation.",
            "Indication": "Advanced solid tumors",
            "Drug": "NGM707",
            "PFS": "None",
            "OS": "None",
            "ORR": "Monotherapy: 31% DCR; Combination: 43% DCR",
            "AE": "Monotherapy: 46.3% any grade, 4.8% grade \u22653; Combination: 41.3% any grade, 4.4% grade \u22653",
            "Other": "Peripheral RO was dose-dependent; PK typical for monoclonal antibodies with a half-life of 12.8 days; Evidence of myeloid and T cell activation in tumor biopsies",
            "Conclusion": "NGM707 as monotherapy and in combination with pembrolizumab was safe and well tolerated at all dose levels. Early efficacy and biomarker signals observed support further evaluation of NGM707."
        },
        {
            "Title": "Preliminary results of a phase 1 study of Decoy20, an intravenous, killed, multiple immune receptor agonist bacterial product in patients with advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "Decoy20",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Grade 3: lymphopenia (n=3), AST increase (n=3), IRR (n=1), bradycardia (n=1), malaise (n=1); Grade 4: lymphopenia (n=8)",
            "Other": "Transient immune activation with a broad systemic immune response; Decoy20 clearance within 30-120 minutes of infusion",
            "Conclusion": "Decoy20 generated transient AEs expected for LPS exposure. Broad systemic immune activation and preliminary evidence of stable disease were observed with only one infusion of Decoy20. These observations support the pulse-prime hypothesis and continued Decoy20 trial enrollment."
        },
        {
            "Title": "Safety and activity of Diakine DK210 (EGFR), a next generation tumor-targeted IL2xIL10 dual immunocytokine, in patients with advanced cancer: Initial results of the phase 1 first-in-human trial.",
            "Indication": "Advanced/metastatic tumors known to express EGFR",
            "Drug": "DK210 (EGFR)",
            "PFS": "None",
            "OS": "None",
            "ORR": "Stable disease in 4 patients (1 mPDAC, 2 mCRC, 1 mNSCLC)",
            "AE": "Any grade: injection site reactions (53%), fatigue (41%), fever (35%), nausea (29%), diarrhea (18%); Grade 3: syncope, fatigue",
            "Other": "Systemic immune activation characterized by expansion of unique T cell clones and increased plasma IFN\u0263 without other pro-inflammatory cytokines associated with CRS",
            "Conclusion": "DK210 (EGFR) was well tolerated and associated with therapeutically relevant on-target biomarker signals. Further exploration of DK210 (EGFR) in RCC and NSCLC as monotherapy and in combination is planned."
        },
        {
            "Title": "A first-in-human phase 1a dose-escalation study of BGB-15025 (HPK1 inhibitor) as monotherapy and in combination with tislelizumab (TIS; anti-PD-1 antibody) in patients with advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "BGB-15025 (HPK1 inhibitor) and tislelizumab (TIS)",
            "PFS": "None",
            "OS": "None",
            "ORR": "Monotherapy: 35.0% DCR; Combination: 18.4% ORR for all doses combined, 31.3% ORR for RDFE, 57.1% DCR for all doses combined, 56.3% DCR for RDFE",
            "AE": "Monotherapy: diarrhea (18.3%), vomiting (15.0%), blood creatinine increased (15.0%); Combination: nausea (30.6%), diarrhea (28.6%), fatigue (20.4%)",
            "Other": "No DLTs with monotherapy; 5 DLTs with combination therapy (2 ALT/AST increased, 1 colitis, 1 immune-related hepatitis, 1 GGT increased); MAD was 200mg BID for monotherapy and MTD was 150mg QD for combination therapy",
            "Conclusion": "Preliminary results show BGB-15025 monotherapy or combination therapy with TIS was generally tolerable. The antitumor activity of BGB-15025 was improved when given in combination with TIS. Further investigation of BGB-15025+TIS+/-chemotherapy is ongoing in the expansion phase."
        },
        {
            "Title": "Phase 1 dose escalation and cohort expansion study evaluating safety, PK, PD and clinical activity of STC-15, a METTL-3 inhibitor, in patients with advanced malignancies.",
            "Indication": "Advanced malignancies",
            "Drug": "STC-15",
            "PFS": "None",
            "OS": "None",
            "ORR": "78% DCR with 2 confirmed PR ongoing in angiosarcoma (60mg, 32 weeks) and IO-refractory NSCLC (100mg, 33 weeks) and 9 SD",
            "AE": "169 AEs observed with 45 AEs attributed to STC-15 treatment. Manageable, mostly hematology (thrombocytopenia 31%; 4% Grade 3), skin (pruritis, rash 14% G1/2) and GI (N/V and diarrhea 14% G1/2). 12 SAEs occurred; 1 pt (60mg QD) had DLT with G3 pneumonitis.",
            "Other": "An average of 63% reduction in m6A on mRNA in peripheral blood within the first 24h post-dosing was observed in 60mg cohorts, confirming target engagement. Whole blood Nanostring and pathway analysis of gene expression confirms upregulation of innate immune pathways (i.e., T1,2 IFN activation and anti-viral responses) as early as 8h after first dose and throughout the treatment cycle.",
            "Conclusion": "Treatment with STC-15 is well tolerated across pharmacologically active dose range with encouraging signs of clinical activity. Early biomarker data provide proof of mechanism in target engagement, strong activation of innate immune responses and correlation of pharmacological activity with clinical response. The study is ongoing and expansion cohorts are underway to further evaluate PK/PD, safety and clinical efficacy at optimized pharmacologically active doses."
        },
        {
            "Title": "Phase 1/2, open-label study evaluating less frequent intravenous (IV) dosing of nemvaleukin alfa in patients with advanced solid tumors (ARTISTRY-3).",
            "Indication": "Advanced solid tumors",
            "Drug": "Nemvaleukin alfa",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Most treatment-related adverse events (TRAEs) were grade (G) 1-2. There were 3 G3 TRAEs: anemia (schedule 2, 20mg/kg) and neutropenia and decreased white blood cell count (both schedule 3). There were no G4 TRAEs.",
            "Other": "NK and CD8+ T cell expansion was seen at all doses in all schedules, confirming the PD effect of nemvaleukin. Dose-response relationship was observed for PD markers across all schedules. Fold change from baseline for absolute count of NK and CD8+ T cells was significantly higher in schedules 2 and 3, along with better stabilization of disease (SD. 12-24 weeks). Minimal to no expansion of Tregs was observed across all schedules. Nemvaleukin exposure (Cmax and AUCinf) increased with escalating doses, with no evidence of nonlinearity.",
            "Conclusion": "Nemvaleukin demonstrated PD proof of mechanism in all 3 schedules and was tolerable at all doses tested, with some stabilization of disease. Safety profile was similar across all schedules. Nemvaleukin RP2D for less frequent dosing schedule is expected to be completed in Q1 2024."
        },
        {
            "Title": "CLN-619 (anti-MICA/B antibody) alone and in combination with pembrolizumab (P) for advanced solid tumors: Updated results of a Ph1 study.",
            "Indication": "Advanced solid tumors",
            "Drug": "CLN-619",
            "PFS": "None",
            "OS": "None",
            "ORR": "Combination cohort: 2 patients with NSCLC (1 EGFRm, 1 ALK rearr) had achieved confirmed partial response (PR). After DCO, 1 pt with gastric cancer improved from stable disease (SD) to PR at cycle 7 (pending confirmation). Monotherapy cohort: Three previously reported confirmed responses in mucoepidermoid parotid (CR), serous endometrial (PR), and endometroid endometrial (PR) were durable through 13, 7, and 8 (ongoing) months, respectively. Nine pts achieved stable disease (SD) extending through \u226518 wks.",
            "AE": "Combination cohort: Treatment-emergent adverse events (TEAEs) in \u226520% of pts were fatigue (36%), constipation (27%), and nausea (23%). Four patients (18%) experienced Grade (Gr) 1/2 infusion-related reactions. Monotherapy cohort: TEAEs in \u226520% of pts were infusion-related reactions (26%), fatigue (24%), and abdominal pain (22%). One immune-related AE of maculopapular rash was reported but resolved with steroid taper and did not result in treatment discontinuation.",
            "Other": "Clinical benefit rate at doses \u22651mg/kg (CR+PR+SD \u226518 weeks) was 41% (12/29 evaluable pts).",
            "Conclusion": "CLN-619 + P was well tolerated at doses ranging from 1 to 10 mg/kg. Objective responses were observed, including in tumor types typically unresponsive to P. Longer-term follow-up for CLN-619 monotherapy confirms favorable safety and durable clinical benefit with extended treatment, including objective responses in multiple tumor types and pts progressing after CPI. Based on these findings, expansion cohorts will be opened in endometrial cancer and NSCLC."
        },
        {
            "Title": "EMITT-1: Proof-of-mechanism immunopeptidome (ImPD) effects at target PK exposure, in a phase 1 study of GRWD5769 (a first-in-class inhibitor of Endoplasmic Reticulum Aminopeptidase 1 [ERAP1]) in patients with solid malignancies.",
            "Indication": "Solid malignancies",
            "Drug": "GRWD5769",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "No Serious Adverse Reactions, DLTs, immune-related AEs or deaths were reported. Two Gr3 serious adverse events have occurred in 2 treated subjects (aspiration pneumonia and Urinary Tract Infection, neither attributable to GRWD5769). Gr1+2 AEs were infrequent and manageable.",
            "Other": "PK analyses for the first 3 cohorts show dose proportional plasma levels, with Tmax at ~3h and a ~8h Thalf. The minimum biologically active dose (MBAD) is defined as an average steady state plasma concentration above the IC50 for ERAP1, which was achieved at the 100mg BID dose level. PD data shows dose-dependent target engagement, with marked shifts in the ImPD. This is consistent with the expected mechanistic effects of ERAP1 inhibition seen in preclinical models and is the first demonstration that the human ImPD can be manipulated pharmacologically in cancer patients.",
            "Conclusion": "GRWD5769 has been well tolerated at doses up to 100mg BID. PK/PD data support dose-dependent target engagement of ERAP1. Proof of mechanism has been achieved for this first-in-class therapy, and this is the first demonstration that the human ImPD can be manipulated pharmacologically in cancer patients. Further PD analyses will explore shifts in the T cell repertoire and modulation of immune cell phenotypes."
        },
        {
            "Title": "A phase I/II study to evaluate the safety, pharmacokinetics, and efficacy of Anti-PD-1/TGF-bRII bispecific antibody fusion protein LBL-015 in patients with advanced solid tumors",
            "Indication": "Advanced solid tumors",
            "Drug": "LBL-015",
            "PFS": "None",
            "OS": "None",
            "ORR": "5% ORR, 25% DCR",
            "AE": "100% experienced TEAEs of any grade, 44% at grade \u22653",
            "Other": "2 DLTs observed (respiratory failure at grade 5 and pulmonary infection at grade 3), MTD not reached",
            "Conclusion": "LBL-015 has demonstrated good safety profiles in patients with advanced solid tumors. The encouraging preliminary efficacy signals indicated additional studies with a focus on TGFb signaling pathway deregulated or activated tumors such as RCC, pancreatic cancer, etc. should be further explored."
        },
        {
            "Title": "A phase I, first-in-human, open-label, multicenter, dose-escalation study of PRJ1-3024 in patients with advanced malignant tumors",
            "Indication": "Advanced malignant tumors",
            "Drug": "PRJ1-3024",
            "PFS": "None",
            "OS": "None",
            "ORR": "1 melanoma subject achieved sustained PR, 4 subjects achieved SD",
            "AE": "Common TEAEs: diarrhea (12), vomiting (12), nausea (9), proteinuria (8), loss of appetite (6). No DLT events, irAEs or treatment-related deaths. 9 subjects reported SAEs, 2 cases probably related (6.7%)",
            "Other": "PK and PD assessments will be released in due course",
            "Conclusion": "PRJ1-3024 is shown to be well tolerated in Chinese advanced solid tumor patients. Further safety and efficacy results would be presented at the conference."
        },
        {
            "Title": "Phase 1/2a clinical trial of BI-1206, an anti-CD32b (FcgRIIB) antibody, in combination with pembrolizumab in subjects with advanced solid tumors previously treated with anti-PD-1/PD-L1",
            "Indication": "Advanced solid tumors",
            "Drug": "BI-1206 in combination with pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "5 patients showed SD, including one lasting 24 months in a metastatic melanoma patient. Long-lasting PR (>24 months) in a uveal melanoma patient, and CR in a metastatic melanoma patient",
            "AE": "Most frequent related adverse events: infusion-related reactions, thrombocytopenia, elevated liver enzymes. All were transient without any clinical consequences",
            "Other": "Enrollment to BI-1206 SC dose escalation began Nov 2023. Dose level and administration route to be further explored in Phase 2a",
            "Conclusion": "Coadministration of BI-1206 with pembrolizumab was well tolerated in a heavily pretreated population, with promising hints of responses to be further explored in Phase 2."
        },
        {
            "Title": "Targeting GARP\u2013TGF-b1 with livmoniplimab plus anti\u2013PD-1 budigalimab to remodel the immunosuppressive tumor microenvironment",
            "Indication": "Various advanced solid tumors",
            "Drug": "Livmoniplimab (livmo) in combination with budigalimab (budi)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Livmo alone led to reduction of tumor cell viability, increase in T-cell effector function, and decrease in immune-suppressive myeloid cells. This effect was further enhanced in combination with budi",
            "Conclusion": "These data demonstrate that livmo induces decrease in tumor cell viability, increased antitumor immune cell activation, and remodeling of the immune-suppressive TME, which is further enhanced in combination with budi. These results support the hypothesis that patients would benefit from therapeutic intervention with livmo + budi."
        },
        {
            "Title": "Phase 1/2 study of XTX202, a tumor-activated IL-2bg, in advanced solid tumors.",
            "Indication": "Advanced solid tumors, metastatic renal cell carcinoma (RCC), and melanoma",
            "Drug": "XTX202",
            "PFS": "None",
            "OS": "None",
            "ORR": "Phase 1: Overall disease control rate (DCR) was 31%. Phase 2: DCR was 62% at the 1.4 mg/kg dose-level and 80% at the 4 mg/kg dose-level.",
            "AE": "TRAE (\u226510% incidence) of any grade across Phase 1 and Phase 2: fatigue (22%), chills (22%), pyrexia (19%), and lymphocyte count decreased (11%). TRAEs \u2265 Grade 3 with \u22652% incidence: lymphocyte count decreased (7%), cytokine release syndrome (2%, all Grade 3), and ALT increased (2%, all Grade 3).",
            "Other": "PK analysis demonstrated dose-proportional exposure. Bioanalytical data from a non-treatment biopsy demonstrated tumor-selective activation with ~15% activated molecule in the tumor. Dose-dependent PD in peripheral CD8+ T cells and NK cells was consistent with IL-2 biology while tumor-selective increases in CD8+ T cells in the absence of regulatory T cell expansion were observed.",
            "Conclusion": "Clinical and translational data demonstrated tumor-specific activation of XTX202, supported by PK, tumor BA, and PD data. The safety profile observed at 4 mg/kg, as well as dose-dependent, durable anti-tumor activity, positions XTX202 for combination approaches not otherwise feasible with high dose IL-2."
        },
        {
            "Title": "Phase I dose-escalation and cohort expansion study of the anti-BTLA antibody, tifcemalimab, in combination with toripalimab (anti-PD-1) in heavily pretreated patients (pts) with advanced malignancies.",
            "Indication": "Advanced malignancies",
            "Drug": "Tifcemalimab in combination with toripalimab",
            "PFS": "None",
            "OS": "None",
            "ORR": "Dose escalation phase: 8 had stable disease. Cohort expansion phase: 1 complete response (lymphoma), 6 partial responses (2 melanoma, 2 RCC, 1 NSCLC, 1 UC), and 17 stable disease. ORRs were 5% (NSCLC), 11% (melanoma), 17% (UC), 18% (RCC), and 33% (lymphoma).",
            "AE": "Treatment-emergent adverse events (TEAEs) occurred in 92% of pts, 44% experienced grade 3 or higher TEAEs, including 2 (2%) treatment-related Grade 5 events. The most common TEAEs included: fatigue (27%), diarrhea (17%), nausea (17%), anemia (15%), arthralgia (15%), decreased appetite (15%), and dyspnea (15%). TEAE led to discontinuation of study drug in 6% of pts. Nineteen percent of pts experienced immune-related AEs.",
            "Other": "No dose-limiting toxicity (DLT) was observed during dose escalation. No new safety signal was identified outside the known risk profiles of tifcemalimab and toripalimab.",
            "Conclusion": "Tifcemalimab in combination with toripalimab showed preliminary efficacy in immunotherapy-refractory pts with a manageable safety profile. Phase II combination studies in various advanced solid tumors are ongoing."
        },
        {
            "Title": "A first-in-human, phase 1/2a study of GI-102 (CD80-IL2v3) in patients with advanced or metastatic solid tumors: Initial results from dose escalation.",
            "Indication": "Advanced or metastatic solid tumors",
            "Drug": "GI-102 (CD80-IL2v3)",
            "PFS": "None",
            "OS": "None",
            "ORR": "Objective responses were observed in 17.4% (4/23). In patients with metastatic melanoma who previously experienced ICB, ORR and DCR were 42.9% (3/7) and 85.7% (6/7), respectively, including 3 confirmed partial responses (cPR). In patients with metastatic ovarian cancer, ORR and DCR were 33.3% (1/3) and 66.7% (2/3), respectively, including 1 cPR.",
            "AE": "The most common treatment-related adverse events (TRAEs, \u226510%) were pyrexia (43.8%) and chills (34.4%). 5 patients (15.6%) had \u2265 Grade 3 TRAEs and no patient reported TRAEs leading to discontinuation of GI-102.",
            "Other": "Preliminary PK profile showed target-mediated drug disposition with a half-life of ~48 hours. 0.24 mg/kg of GI-102 resulted in a significant expansion of peripheral lymphocytes, CD8+ T cells (effector & memory), and NK cells, by 4.4 [2.1-9.6], 3.9 [2.0\u20135.7], and 20.4 [9.5\u201332.6]-fold change from baseline, respectively. There was no meaningful increase in Treg cells.",
            "Conclusion": "GI-102 was well tolerated up to a dose of 0.45 mg/kg Q3W with meaningful monotherapy activity, regardless of previous ICB experience, in patients who failed on standard of care. The dose-escalation is currently ongoing to identify RP2D."
        },
        {
            "Title": "IK-175, an oral AHR inhibitor, as monotherapy and in combination with nivolumab in patients with urothelial carcinoma resistant/refractory to PD-1/L1 inhibitors.",
            "Indication": "Urothelial carcinoma",
            "Drug": "IK-175 and nivolumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "Confirmed partial responses were observed in 1/13 (7.7%) monotherapy (DoR 22.6 months) and 2/33 (6%) in combination (DoR 4.4 and 7.3 months).",
            "AE": "The most common treatment-related AEs were nausea, fatigue, and diarrhea. Infrequent immune-related AEs occurred in <9% of patients and included adrenal insufficiency, proteinuria, rash, pneumonitis, and immune-related arthritis.",
            "Other": "Significant reduction in tumor target lesions (-30% to -100%) was observed in 6/46 (13%) response-evaluable UC patients (both arms). DCR was 46% and 49% for monotherapy and combination.",
            "Conclusion": "IK-175 showed a predictable, consistent safety profile as a monotherapy and in combination with nivolumab. Meaningful clinical benefit and objective, durable, prolonged responses were demonstrated in both monotherapy and combination arms in heavily pretreated UC patients with tumors refractory to CPI and supports further development of this asset in solid tumors."
        },
        {
            "Title": "Initial results from a first-in-human phase 1 study of SNS-101 (pH-selective anti-VISTA antibody) alone or in combination with cemiplimab in patients with advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "SNS-101 and cemiplimab",
            "PFS": "None",
            "OS": "None",
            "ORR": "One patient had a confirmed PR and 10 patients had SD as best response. In the mono arm, 1 pembrolizumab-resistant HPV+ H&N patient at 15 mg/kg had tumor regression of 17%. In the combo arm, 1 MSS endometrial patient at 3 mg/kg + cemiplimab had a confirmed PR (45% decrease) and 1 MSS colon patient at 10 mg/kg + cemiplimab had tumor regression of 27%.",
            "AE": "Most frequent AEs: cough (n=3), dermatitis acneiform and pyrexia (n=2 each) in the mono arm; fatigue (n=3), anemia, nausea and rash maculo-papular (n=2 each) in the combo arm. Immune-mediated AEs included CRS (G1, 15 mg/kg, n=1) in the mono arm; diabetic ketoacidosis (G3, 3 mg/kg, n=1), rash maculo-papular (G2, 3 mg/kg, n=1), and ALT increased (G3, 10 mg/kg, n=1) in the combo arm.",
            "Other": "PK appears dose-proportional and consistent with lack of target-mediated drug disposition, with no notable difference between mono vs combo dosing. Dose-dependent changes in specific T-cell populations indicate potential SNS-101-related pharmacological effects.",
            "Conclusion": "pH selective SNS-101, both alone and in combo with cemiplimab, has been safely administered at doses that are ~50x higher than doses where severe CRS was observed with prior VISTA agents. Both mono and combo therapy have been generally well tolerated. Early signs of clinical activity were observed in both the acquired and primary PD-1 resistant tumor settings."
        },
        {
            "Title": "TransCon IL-2b/g alone or in combination with pembrolizumab, standard of care chemotherapy or TransCon TLR7/8 agonist in advanced/metastatic solid tumors: Updated results of the phase 1/2 IL Believe study.",
            "Indication": "Advanced/metastatic solid tumors",
            "Drug": "TransCon IL-2b/g and pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "Of patients who progressed on prior anti-PD-(L)1 therapy, one patient with SCLC had confirmed complete response (combination with P, 120 mg/kg) and 2 patients had confirmed partial response (cPR): CRC (mono, 120 mg/kg) and SCLC (combination with P, 80 mg/kg).",
            "AE": "Treatment Related Adverse Events \u2265 Grade 3 (G3) occurred in 11 (24%) patients in dose escalation cohorts. One Dose Limiting Toxicity of G3 Cytokine Release Syndrome was reported at 160 mg/kg.",
            "Other": "Biomarker analysis demonstrated dose dependent increases in absolute lymphocyte counts (ALC), driven by selective expansion of cytotoxic lymphocytes (CLs) without meaningful expansion of Tregs. Expanded CLs displayed an activated phenotype along with an average 5-fold increase in soluble CD25. IFN-g and CXCL10 were elevated for \u22655 days after first dose. Safety-related biomarkers like eosinophil levels remained in the normal range after treatment.",
            "Conclusion": "TC-IL2b/g alone and in combination with P or TransCon TLR7/8 Agonist is generally well-tolerated with meaningful clinical responses in heavily pre-treated patients with anti-PD-(L)1 relapsed or refractory disease. Initial evaluation of biomarkers shows a sustained significant expansion and activation of CLs, elevated levels of cytokines and chemokines in blood without the corresponding expansion of Tregs or eosinophils."
        },
        {
            "Title": "Phase 1 study of BA3071, an anti\u2013CTLA-4 conditionally active biologic, in combination with nivolumab in advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "BA3071 and nivolumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "Among 16 efficacy-evaluable patients, 9 experienced stable disease, and 2 out of 5 patients receiving BA3071 in the 350-mg cohort achieved confirmed RECIST v1.1 responses (complete response in cervical carcinoma and partial response in gastro-esophageal carcinoma).",
            "AE": "Four patients experienced grade 3 related treatment-emergent AEs (TEAEs; hypertension, increased lipase, atrial fibrillation, gastritis, and diabetic ketoacidosis); no grade 4 related TEAEs were observed. Two patients experienced grade 3 immune-related TEAEs (diarrhea [BA3071 350 mg] and diabetic ketoacidosis [BA3071 700 mg]).",
            "Other": "One patient with metastatic small cell lung cancer who received 7 mg BA3071 remained without progression for >1 year (69 weeks).",
            "Conclusion": "Treatment with the novel, conditionally active anti\u2013CTLA-4 agent BA3071, in combination with anti\u2013PD-1 therapy (at doses higher than those currently approved for anti\u2013CTLA-4/PD-1 therapy), yielded confirmed responses with a promising tolerability profile. Phase 1 dose escalation of BA3071 continues at 700 mg up to 1000 mg, and phase 2 monotherapy and combination therapy expansion cohorts are currently enrolling at a starting dose level of 350 mg."
        },
        {
            "Title": "Correlative and spatial biomarker analysis of a phase 1/2b study to evaluate pepinemab in combination with pembrolizumab for first-line treatment of patients with recurrent or metastatic head and neck cancer.",
            "Indication": "Recurrent or metastatic head and neck cancer",
            "Drug": "Pepinemab in combination with pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "An increase in activated APC (HLA-DR+CD11c+ and HLA-DR+CD68+) and reduced density of MDSC (Arg1+CD14+ and ARG1+CD15+) was observed in patients with durable disease control. Combination therapy induced the formation of highly organized immune aggregates, including a high density of activated B cells, DCs, CD4+ and CD8+ T cells, including stem-like CD8+ TCF1+PD1+ T cells.",
            "Conclusion": "Results suggest that combination therapy induced formation of highly organized lymphoid aggregates in HNSCC tumors, with a high density of activated B cells, DC and T cells. Together with similar observations indicating that combination immunotherapy with pepinemab induces mature lymphoid structures in tumors of patients with metastatic melanoma, provides evidence of treatment-induced biologic activity corresponding with disease control and suggests a novel and independent mechanism of pepinemab to enhance immune interactions and ICI activity."
        },
        {
            "Title": "Tislelizumab first-line (1L) gastric/gastroesophageal junction cancer (G/GEJ) treatment efficacy on patient-reported outcome (PRO)-based symptom endpoints adjusting for informative missing data bias: Results from RATIONALE 305.",
            "Indication": "Locally advanced, unresectable, or metastatic gastric/gastroesophageal junction adenocarcinoma",
            "Drug": "Tislelizumab + chemotherapy",
            "PFS": "None",
            "OS": "Tislelizumab was associated with a 21% reduction in risk of death (P=0.0269).",
            "ORR": "None",
            "AE": "None",
            "Other": "Tislelizumab was associated with a constant lower level of pain compared with chemotherapy (-2.08; P=0.0109).",
            "Conclusion": "In this analysis, compared with chemotherapy, tislelizumab + chemotherapy was associated with lower pain and longer survival. Joint models for PRO-based symptom endpoints may illuminate patient-centric therapeutic benefits."
        },
        {
            "Title": "Early opioid exposure (EOE) and impaired efficacy in patients with advanced NSCLC treated with PD-L1 inhibition: A pooled post hoc analysis of the POPLAR and OAK trials.",
            "Indication": "Advanced NSCLC",
            "Drug": "Atezolizumab",
            "PFS": "1.9 vs 4.2 months, HR 1.47 (95% CI: 1.26-1.72)",
            "OS": "HR 1.70 (95% CI: 1.42-2.03)",
            "ORR": "11.7% vs 19.8%",
            "AE": "None",
            "Other": "None",
            "Conclusion": "EOE is associated with worse response/PFS from immunotherapy but not from chemotherapy exposure, suggesting a possible immune-modulating effect of opioids signaling on anti-tumor immunity."
        },
        {
            "Title": "A phase 1 study of the small-molecule PD-L1 inhibitor INCB099280 in select advanced solid tumors: Updated safety, efficacy, and pharmacokinetics (PK) results.",
            "Indication": "Advanced solid tumors",
            "Drug": "INCB099280",
            "PFS": "None",
            "OS": "None",
            "ORR": "17.8% in pts with mixed tumor types",
            "AE": "96.6% of pts had \u22651 tx-emergent adverse events (TEAEs). Immune-related (ir) TEAEs occurred in 23.5% of pts.",
            "Other": "PK exposures increased proportionally as dose increased up to 800 mg bid. Steady-state PK was achieved by cycle 1 day 8, with an accumulation ratio of 2-3.",
            "Conclusion": "INCB099280 was generally well tolerated at all doses tested and showed promising antitumor activity. These data support further development of INCB099280 as monotherapy and in combination regimens for solid tumors."
        },
        {
            "Title": "Impact of concurrent antibiotics on survival with immunotherapy in metastatic colorectal and pancreatic cancer.",
            "Indication": "Metastatic colorectal cancer (CRC) and metastatic pancreatic ductal adenocarcinoma",
            "Drug": "Durvalumab and tremelimumab",
            "PFS": "None",
            "OS": "7.4 vs 6.2 months, adjusted hazard ratio (aHR) 0.66 (95% confidence interval (CI) 0.46-0.93), p=0.018 in patients with metastatic CRC who received ICIs",
            "ORR": "None",
            "AE": "None",
            "Other": "Concurrent exposure of ICIs to fluoroquinolones (9.4 vs 6.2 months, aHR 0.50 (95% CI 0.30-0.82), p=0.0067)",
            "Conclusion": "Concurrent exposure to antibiotics, and specifically fluoroquinolones during ICI therapy was associated with a statistically significant improvement in OS in patients with metastatic CRC, but not metastatic pancreatic cancer."
        },
        {
            "Title": "BGB-A317-212: A multicenter, open-label, phase II study to evaluate the efficacy and safety of tislelizumab in combination with lenvatinib in patients with selected solid tumors.",
            "Indication": "Selected solid tumors",
            "Drug": "Tislelizumab and lenvatinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "66.7% in pts with RCC, 33.3% in pts with HNSCC, 33.3% in pts with GC, and 20.0% in pts with NSCLC",
            "AE": "Grade \u22653 treatment-related adverse events were reported in 78.3% (RCC), 59.3% (HNSCC), 33.3% (GC), and 60.0% (NSCLC) of pts.",
            "Other": "Median duration of response (mDoR) was 18.5 months (NSCLC), 9.6 months (HNSCC), not estimable (NE) for RCC and GC.",
            "Conclusion": "Tislelizumab plus lenvatinib had a manageable safety profile and showed preliminary antitumor activity in pts with selected tumor types. Longer follow-up is needed to further investigate the potential of this combination to benefit pts with advanced solid tumors."
        },
        {
            "Title": "Preliminary results from a phase I expansion study of ZG005, a bispecific antibody targeting TIGIT and PD-1, as monotherapy in patients with advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "ZG005",
            "PFS": "None",
            "OS": "None",
            "ORR": "1 CR, 6 PR, 28 SD cases reported from 57 efficacy evaluable subjects; 1 CR, 5 PRs, and 8 SDs from 17 subjects with cervical cancer",
            "AE": "43 TRAEs (63.2%), 6 grade 3 including 2 liver function abnormalities, 1 elevated g-glutamyl-transferase, 1 unknown death, 1 hypertriglyceridemia and lipase elevation, 1 elevation in both aspartate aminotransferase and blood bilirubin",
            "Other": "SAEs occurred in 19 subjects, 3 events related to ZG005 including 2 liver function abnormalities (1 was a DLT event), and 1 unknown death",
            "Conclusion": "ZG005 has demonstrated a tolerable safety profile and encouraging anti-tumor activity during the FIH study. Expansion cohorts in specific advanced solid tumors are underway to warrant further development."
        },
        {
            "Title": "Pharmacodynamic (PD) and immunophenotyping analyses of ATR inhibitor (ATRi) tuvusertib + ATM inhibitor (ATMi) lartesertib in a phase Ib study in patients with advanced unresectable solid tumors.",
            "Indication": "Advanced unresectable solid tumors",
            "Drug": "Tuvusertib + Lartesertib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Complete or almost complete target inhibition seen at 1\u20136 hours after treatment, followed by a rebound above baseline after 24 hours. Tuvusertib + lartesertib induced a transient decrease of monocytes and natural killer (NK) cells, with partial or complete recovery to baseline levels during treatment breaks.",
            "Conclusion": "Tuvusertib and lartesertib combination PD outcomes were in line with respective monotherapy observations. The combination did not cause any consistent change in the levels of immune cell subsets at all dose levels tested, except a mild, transient decrease in monocytes and NK cells."
        },
        {
            "Title": "Phase 1 trial of TU2218, TGFb-RI and VEGF-R2 dual inhibitor in monotherapy and combination with pembrolizumab in patients with advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "TU2218 + Pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "PR at 8.3% (n=1), SD 50% (n=6), PD at 33% (n=4)",
            "AE": "No Grade 3 or higher treatment related AE in monotherapy; most frequently observed TRAE in combination was pruritus (25%, n=3) and one patient experienced Grade 3 TRAE with maculo-papular rash (8.3%, n=1)",
            "Other": "Systemic exposure to TU2218 increased in a dose dependent manner. TU2218 demonstrated reductions in PD markers, particularly TGF-b1 (-16.5%) and CTGF (-45.4%) blood levels in groups of TU2218 Cmax \u2265 1.0 mM.",
            "Conclusion": "TU2218, a first-in-class oral dual inhibitor against TGFbRI and VEGFR2 was well-tolerated in the monotherapy and in the combination therapy with pembrolizumab. The RP2DC was established for subsequent trials in specific cancer types."
        },
        {
            "Title": "Pharmacokinetic (PK) and pharmacodynamic (PD) findings from a phase 1b study of ATR inhibitor tuvusertib + anti-PD-L1 avelumab in patients with advanced unresectable solid tumors.",
            "Indication": "Advanced unresectable solid tumors",
            "Drug": "Tuvusertib + Avelumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "A patient with chordoma experienced a RECIST v1.1 partial response",
            "AE": "2 of 9 patients evaluable for dose-limiting toxicity (DLT) experienced DLTs: Grade 3 ALT and Grade 3 AST increase (n=1), and Grade 3 anemia requiring transfusion (n=1)",
            "Other": "Preliminary PK data for tuvusertib suggested rapid absorption with median Tmax range of ~2\u20133h and mean elimination half-life range of ~2.93 to 4.23h, with ~2-fold accumulation of steady-state area-under-the-curve following multiple doses. Exposure of tuvusertib in combination with avelumab was consistent with tuvusertib monotherapy exposure. PD showed complete or almost complete target inhibition at 1\u20136h after tuvusertib 180mg followed by rebound above baseline after 24h on days 1 and 8 of cycle 1. No clear trend of variation in absolute counts of myeloid-derived suppressor cells, T and B lymphocytes, monocytes, and natural killer cell subtypes was detected.",
            "Conclusion": "Tuvusertib and avelumab were combined at established monotherapy doses with no new safety findings. Tuvusertib PD and exposure data were in line with monotherapy observations. The combination did not cause any consistent change of the immunophenotype. Given the accumulating evidence of ATRi as an immunosensitizer, further evaluation of this combination in patients with ICI-resistant advanced solid tumors is warranted."
        },
        {
            "Title": "A phase 2a safety run-in and preliminary efficacy study of liposomal gemcitabine (FF-10832) in combination with pembrolizumab in patients with advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "Liposomal gemcitabine (FF-10832) and pembrolizumab",
            "PFS": "6 weeks (95% CI: 3.1\u2013NR)",
            "OS": "23.3 weeks (95% CI: 4\u2013NR)",
            "ORR": "None",
            "AE": "Common AEs related to FF832: Gr#2 fatigue (50%) with 1 Gr3, anemia (33%) with 2 Gr3, & Gr#2 decreased appetite, diarrhea, \u2191AST, \u2191AlkPhos, muscular weakness, nausea, and pyrexia (25% each). Common AEs related to FF832+PEM: Gr#2 fatigue (33%) & nausea (25%). Three pts had Gr#2 infusion reactions with the first FF832 infusion; all resolved & were successfully rechallenged. FF832 dose was reduced to 30mg/m2 after Cycle 1 in 3 pts due to Gr3 rash (1), Gr2 fatigue (1), & one DLT of Gr3 malaise, pain, and arthralgia.",
            "Other": "Median time on study was 6.1 (1.1\u201323.7) weeks. Of 9 pts evaluable for response, one achieved an unconfirmed PR after one cycle (UC, prior GEM/PEM, 42% \u2193 in target lesions). Five pts had a best response of SD with 2 maintained for 6-8 cycles. An extended plasma t (~30 hours) & exposures consistent with FF832 monotherapy at the RP2D were observed. Multi-log decreases were observed in circulating Ki67+ Tregs relative to total CD4+ cells while CD8+ cells increased.",
            "Conclusion": "The safety and preliminary efficacy of FF832+PEM was demonstrated in heavily pre-treated pts with solid tumors whose disease progressed on prior GEM and/or PEM. Continuous GEM exposure from FF832 along with immune checkpoint blockade may improve antitumor activity. Evaluation of FF832 at the RP2D/schedule of 40m/gm2 Q21 days alone and in combination with PEM is ongoing in a randomized expansion study in pts with metastatic NSCLC and UC with prior disease progression on PD-1/L1 therapy."
        },
        {
            "Title": "Modulation of lipid metabolism associated with response to metronomic capecitabine plus camrelizumab in patients with refractory gastrointestinal cancer: A prospective, single-center, exploratory trial.",
            "Indication": "Refractory gastrointestinal cancer",
            "Drug": "Metronomic capecitabine and camrelizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "19.2% (5/26) including two patients with complete response",
            "AE": "Treatment emergent adverse events (TEAEs) grade \u22653 occurred in five patients (19.2%), including biliary tract infection (11.5%), fatigue (7.7%), and increased AST/ALT (3.8%). Three patients suffered severe AEs that were not related to treatment.",
            "Other": "High skeletal muscle radiation attenuation (SMRA) was associated with disease control and better survival of patients. Differential plasma lipids were identified in disease-controlled patients compared with those who achieved disease progression. High level of a plasma 6-lipid signature composed of SM40:1;3, TG54:4-FA20:2, LPC(16:0), TG52:0-FA20:0, TG56:3-FA20:2, and PE(P-18:1/18:2) indicated better survival of patients and was confirmed in the test cohort. Differential plasma lipids were increased after treatment. Differential expressed genes in C2C12 cells after treatment were involved in lipid metabolism pathways.",
            "Conclusion": "Metronomic capecitabine plus camrelizumab showed low rate of grade \u22653 AEs and promising efficacy in refractory GI cancer patients. Lipid metabolism modulation was associated with treatment response to this combination therapy."
        },
        {
            "Title": "Phase 2 trial of brentuximab vedotin (BV) with pembrolizumab (pembro) in patients with previously treated metastatic non-small cell lung cancer (NSCLC) or cutaneous melanoma (SGN35-033): Overall survival.",
            "Indication": "Metastatic non-small cell lung cancer (NSCLC) or cutaneous melanoma",
            "Drug": "Brentuximab vedotin (BV) and pembrolizumab (pembro)",
            "PFS": "4.1 months (95% CI: 2.76, 5.59) for NSCLC, 4.1 months (95% CI: 2.76, 5.32) for melanoma",
            "OS": "13.9 months (95% CI: 10.38, 17.25) for NSCLC, 12.9 months (95% CI: 8.61, 24.41) for melanoma",
            "ORR": "13% (95% CI: 5.3, 24.5) for NSCLC, 21% (95% CI: 11.2, 33.4) for melanoma",
            "AE": "Immune mediated AEs were reported in 25% of pts, Grade \u22653 TEAEs in 56%, TESAEs in 42%, and TE peripheral neuropathy (SMQ) in 48% of pts. 17% of pts discontinued treatment due to TEAEs. No new safety signals were identified and no deaths due to treatment-related AEs were reported.",
            "Other": "Paired tumor biopsies from 19 pts showed increased CD8+ T cell infiltration in 11 pts.",
            "Conclusion": "These data support the immunomodulatory capacity of BV with pembro. This combination shows encouraging OS data in pts with solid tumors who have progressed on anti-PD-1 therapy. The safety profile is comparable to previously reported data. The study remains ongoing."
        },
        {
            "Title": "Safety and preliminary efficacy of EIK1001 in combination with atezolizumab in participants with advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "EIK1001 and atezolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "8.1% (3/37)",
            "AE": "28/41 (68.3%) receiving EIK1001 + atezolizumab experienced a treatment-related adverse event (TRAE). Of these, 4/41 (9.8%) experienced a \u2265 Grade 3 TRAE, including fatigue, nausea, hyponatremia, and lymphedema. Only 1/41 (2.4%) experienced manageable cytokine release syndrome. There were no deaths due to TRAEs.",
            "Other": "Disease control (including CR, PR, or stable disease) was observed in 19/37 (51.4%). The median duration of response (DOR) was 13 months (range = 10 to 27). One responder was PD-L1 negative yet had a 12-month DOR; another had a history of prior anti-PD-1 Rx yet experienced a 10-month DOR on comb Rx. EIK1001 PK was linear and dose-proportional, and was not affected by combination with atezolizumab.",
            "Conclusion": "Overall, EIK1001 was well-tolerated with a manageable safety profile and showed encouraging preliminary efficacy across several tumor types in combination with atezolizumab. Responses were observed even in pts not anticipated to respond to atezolizumab monotherapy. Further development of EIK1001 is underway."
        },
        {
            "Title": "Lung-MAPS1800D: A phase II/III study of N-803 (ALT-803) plus pembrolizumab versus standard of care in participants with stage IV or recurrent non-small cell lung cancer (NSCLC) previously treated with anti-PD-1 or anti-PD-L1 therapy.",
            "Indication": "Stage IV or recurrent non-small cell lung cancer (NSCLC)",
            "Drug": "N-803 (ALT-803) plus pembrolizumab",
            "PFS": "Not significantly different but numerically worse (HR(95%CI):1.29(0.78-2.13,95%CI), p=0.33)",
            "OS": "Not significantly different (HR(95%CI:0.73(0.40-1.36), p=0.32) with a 12-month OS rate of 25% with SoC and 44% with NP",
            "ORR": "None",
            "AE": "NP arm: 10 Grade 3 (1 hematologic) and 1 Grade 5 treatment-related adverse events reported as Disease Progression (34% Grade 3+ TRAE). SoC arm: 53% with 10 Grade 3+ hematologic toxicities",
            "Other": "3 unconfirmed partial responses and 1 confirmed complete response with NP and 3 unconfirmed partial responses and 2 confirmed partial responses with SoC",
            "Conclusion": "While the study failed to continue accrual past IA1, there is an indication of a subgroup that might benefit from NP with a potential OS difference at 12 months. NP was safe when compared to SoC, and responses were seen in both treatment arms, including partial and complete responses in the NP group. Evaluation of tumor and patient characteristics will be critical to define if there are those who may benefit from N-803 plus pembrolizumab."
        },
        {
            "Title": "A phase 2a study of NT-I7 (efineptakin alfa), a long-acting IL-7, and pembrolizumab to evaluate efficacy, including overall survival, in hard-to-treat gastrointestinal tumors.",
            "Indication": "Microsatellite-stable colorectal (MSS-CRC) and pancreatic cancer (PDAC)",
            "Drug": "NT-I7 (efineptakin alfa) and pembrolizumab",
            "PFS": "MSS-CRC: 1.5/3.8 months (PFS/iPFS); PDAC: 1.4/2.1 months (PFS/iPFS)",
            "OS": "MSS-CRC: 13.2 months [95% CI 8.9\u201318.6 months]; PDAC: 11.1 months [95% CI 4.1\u201313.3 months]",
            "ORR": "MSS-CRC: 1 pt and 3 pts achieved confirmed partial responses per RECIST and iRECIST, respectively; PDAC: 2 and 3 pts achieved confirmed PR and iPR",
            "AE": "Safety profile for NT-I7 was similar to previous reports",
            "Other": "MSS-CRC: Disease control rate (DCR/iDCR) was 36.0% (18/50)/38.0% (19/50), with a duration of response and stable disease (DORSD/iDORSD) of 3.2/14.5 months. PDAC: DCR/iDCR was 25.0% (12/48)/27.1% (13/48), and DORSD/iDORSD was 2.9/9.8 months",
            "Conclusion": "NT-I7 and pembrolizumab treatment was associated with longer median overall survival relative to historical studies. Identification of predictive biomarkers that may define patients with a higher likelihood of clinical benefit would be a promising step to maximizing the potential of the NT-I7 + pembrolizumab treatment combination for patients with these indications. Research in this area is ongoing."
        },
        {
            "Title": "PERIO-02: Phase 1b pressure enabled regional immuno-oncology trial of nelitolimod (SD-101), a class CTLR9 agonist, delivered via hepatic artery infusion +/- checkpoint inhibition in intrahepatic cholangiocarcinoma and hepatocellular carcinoma.",
            "Indication": "Intrahepatic cholangiocarcinoma (ICC) and hepatocellular carcinoma (HCC)",
            "Drug": "Nelitolimod (SD-101) delivered via hepatic artery infusion +/- checkpoint inhibition",
            "PFS": "Median PFS in the Cohort C 4mg dose level is >120 days",
            "OS": "Median OS for this group has not reached (range 120-170 days)",
            "ORR": "None",
            "AE": "3 pts (13%) experienced serious treatment-related adverse events (AE); 1 pt dose-limiting toxicity. Five pts experienced LFT elevations, most G1 with 2 reported as G3",
            "Other": "In cohort C 4mg, 3 of 3 pts had disease control, with one CR in the liver (5L ICC) and 2 SD. Decreases were noted in the target liver lesion, non-target liver lesion, and extra-hepatic lymph nodes",
            "Conclusion": "HAI of nelitolimod has been well tolerated and associated with encouraging immunologic activity in HCC and ICC. Clinical and biologic activity in cohort C at 4mg is supportive of further enrollment in this cohort."
        },
        {
            "Title": "A phase 1/1b study of the IL-2 prodrug WTX-124 in patients with locally advanced or metastatic solid tumors after checkpoint inhibitor therapy: Initial results of the combination dose escalation with pembrolizumab.",
            "Indication": "Locally advanced or metastatic solid tumors",
            "Drug": "WTX-124 in combination with pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "Three objective responses, including a confirmed CR in a patient with cutaneous squamous cell cancer and unconfirmed PRs in patients with melanoma and gastroesophageal junction cancer",
            "AE": "Common AEs related to WTX-124 monotherapy were arthralgias, myalgias, fatigue, and pruritis. No DLTs, related serious AEs, treatment discontinuations due to related toxicities, or occurrences of VLS have been observed.",
            "Other": "On-treatment tumor biopsies showed evidence of increased lymphocyte activation and PD-L1 expression. Addition of pembrolizumab did not change the character of AEs observed with WTX-124 monotherapy.",
            "Conclusion": "WTX-124 administered as a monotherapy IV Q2W in the outpatient setting is well tolerated and clinically active in patients with relapsed/refractory solid tumors after checkpoint inhibitor therapy. Preliminary results from the ongoing combination dose escalation with pembrolizumab show no new safety signals."
        },
        {
            "Title": "Pan-cancer B- and T-cell transcriptome analysis of CXCL13 as a predictive marker for immune checkpoint inhibitor response.",
            "Indication": "Advanced cancer",
            "Drug": "Immune checkpoint inhibitors (ICIs)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "High transcriptome expression of CXCL13, CD3, BTLA, CTLA-4, and PD-1 was significantly more frequent in the responder group. High CXCL13 transcriptome expression was most common in head and neck (25%), breast (22.4%), neuroendocrine (20%), and lung (20%) tumors.",
            "Conclusion": "This pan-cancer analysis found an association between high CXCL13 mRNA expression and better response to ICI therapy. CXCL13's role in promoting a lymphoid structure in the TME by facilitating B-cell recruitment could enable anti-tumor immunity, resulting in better response to immunotherapy."
        },
        {
            "Title": "Endogenous retrotransposable elements as a novel predictive biomarker of response to immunotherapy.",
            "Indication": "Advanced solid tumors",
            "Drug": "Pembrolizumab",
            "PFS": "1.9 months vs 10.1 months, aHR 0.07, 95% CI 0.03-0.18, p<0.001",
            "OS": "8.3 months vs 22.6 months, aHR 0.4, 95% CI 0.22-0.74, p=0.004",
            "ORR": "None",
            "AE": "None",
            "Other": "Higher ERE expression associated with longer PFS and OS. Long-term survivors (OS \u2265 60 months) had higher ERE Z score vs pts with OS < 60 months (0.18 vs -0.15, p=0.03).",
            "Conclusion": "Tumor ERE expression analysis between ICI responders and NR suggests an association between ERE upregulation and radiological response, PFS, and OS during ICI treatment. Further validation studies are warranted."
        },
        {
            "Title": "Use of CRTAM expression as a predictive biomarker for immune checkpoint blockade in a pan-cancer cohort.",
            "Indication": "Various types of cancer",
            "Drug": "Immune checkpoint blockade (ICB)",
            "PFS": "High CRTAM: HR 0.60, 95% CI 0.38-0.92 (univariate analysis)",
            "OS": "High CRTAM: HR 0.39, 95% CI 0.21-0.73 (multivariate analysis)",
            "ORR": "High CRTAM associated with a higher proportion of objective response in patients treated with nivolumab (42% vs. 14%, p=0.035)",
            "AE": "None",
            "Other": "CRTAM expression significantly associated with histology, PD-L1 IHC (\u22651%), and MSI-H.",
            "Conclusion": "CRTAM promotes immune cell activation and differentiation, and high transcriptomic expression correlates with better outcome after ICB, including significantly longer survival in a pan-cancer cohort."
        },
        {
            "Title": "Identification of a predictive phosphoproteomic signature of response to atezolizumab and bevacizumab (AB) in patients with advanced hepatocellular carcinoma (aHCC).",
            "Indication": "Advanced hepatocellular carcinoma (aHCC)",
            "Drug": "Atezolizumab and bevacizumab (AB)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Phosphoproteomic analysis identified 40 phosphopeptides distinguishing between good responders (GRG) and poor responders (PRG). Model showed 100% sensitivity, 87% precision, and 70% specificity.",
            "Conclusion": "A preliminary predictive model of response to AB using phosphoproteomic data from routine FFPE biopsies in aHCC was defined. Following ongoing validation in independent patient cohorts, this model will address an unmet clinical need for biomarkers of clinical response in aHCC."
        },
        {
            "Title": "Genomic instability in advanced non-small cell lung cancer (NSCLC) treated with maintenance durvalumab in the UNICANCER SAFIR02-Lung/IFCT1301 trial.",
            "Indication": "Advanced non-small cell lung cancer (NSCLC)",
            "Drug": "Durvalumab",
            "PFS": "High INS: 2.5 months (95% CI 1.4\u20135.2), Intermediate INS: 4.3 months (95% CI 2.6\u201313.7), Low INS: 6.1 months (95% CI 1.9\u201321.2), p=0.012",
            "OS": "High INS: 12.1 months (95% CI 10.2\u201319.5), Intermediate INS: 17.9 months (95% CI 10\u2013NA), Low INS: 29.6 months (95% CI 15.4\u2013NR), p=0.039",
            "ORR": "None",
            "AE": "None",
            "Other": "Genomic instability score based on CGH array-based copy number alterations profile is associated with worse PFS and OS in patients under response to chemotherapy who received durvalumab maintenance.",
            "Conclusion": "Genomic instability score based on CGH array-based copy number alterations profile is associated with worse PFS and OS in patients under response to chemotherapy who received durvalumab maintenance."
        },
        {
            "Title": "Immune responses in a phase 2 clinical trial of peptide-based therapeutic human papillomavirus vaccine, PepCan, versus Candida adjuvant alone in treating cervical intraepithelial neoplasia 2/3.",
            "Indication": "Cervical intraepithelial neoplasia 2/3 (CIN2/3)",
            "Drug": "PepCan (HPV16 E6 peptides and Candida skin testing reagent)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "New CD3 T cell responses to at least one region of the HPV16 E6 protein were detected in 54.5% of patients in the PepCan group and 44.7% in the Candida group. Plasma RANTES/CCL5 level was significantly and consistently decreased after vaccination (p=0.004).",
            "Conclusion": "Administration of immune-enhancing adjuvant alone is sufficient for inducing anti-HPV immune responses. Consistent systemic decrease in RANTES/CCL5 was demonstrated, suggesting a possible mechanism in which Candida works through reducing RANTES/CCL5."
        },
        {
            "Title": "Efficacy and safety of recombinant human adenovirus type 5 (H101) combined with immune checkpoint inhibitors (ICIs) in patients with liver metastatic gastric cancer: A prospective multicenter phase II study (the TROJAN 021 study).",
            "Indication": "Liver metastatic gastric cancer",
            "Drug": "Recombinant Human Adenovirus Type 5 (H101) combined with immune checkpoint inhibitors (ICIs)",
            "PFS": "4.8 months",
            "OS": "13.2 months",
            "ORR": "33.3%",
            "AE": "Injection site pain (48.1%), fever (57.1%), fatigue (23.8%), grade 3 toxicities: neutropenia (9.5%), hypertension (4.8%)",
            "Other": "Disease Control Rate (DCR): 90.5%",
            "Conclusion": "Combination of Recombinant Human Adenovirus Type 5 (H101) and ICIs demonstrated acceptable toxicity and promising antitumor efficacy in patients with liver metastatic gastric cancer. Further validation of the efficacy in a randomized prospective trial is warranted."
        },
        {
            "Title": "AMPLIFY-7P, a first-in-human safety and efficacy trial of adjuvant mKRAS-specific lymph node targeted amphiphile ELI-002 7P vaccine in patients with minimal residual disease\u2013positive pancreatic and colorectal cancer.",
            "Indication": "Minimal residual disease\u2013positive pancreatic and colorectal cancer",
            "Drug": "ELI-002 7P vaccine",
            "PFS": "Not available",
            "OS": "Not available",
            "ORR": "Not available",
            "AE": "All grade 1: fatigue (29%), malaise (21%), injection site reaction (7.1%)",
            "Other": "Polyfunctional mKRAS-specific T cells observed in 100% (n=11/11). Both CD8+ and CD4+ responses induced in 63.6% of patients (7/11). Biomarker reductions observed in 2/5 (40%) at 1.4 mg dose level and in 5/7 (71%) at RP2D 4.9 mg dose level.",
            "Conclusion": "ELI-002 7P was safe with median RP2D T cell responses exceeding the prior formulation, and early indications of antitumor activity. The randomized phase II is now open in patients with pancreas cancer."
        },
        {
            "Title": "Phase 1 study of LB1410, a bivalent TIM-3/PD-1 bispecific antibody, in patients with advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "LB1410",
            "PFS": "None",
            "OS": "None",
            "ORR": "Stable disease in 14 patients, progression in 25 patients, 1 not evaluable. Target lesion shrinkage in 9 patients, including one with 48.7% reduction.",
            "AE": "65.4% of patients experienced treatment-related adverse events (TRAEs). Most common TRAE was anemia (19.2%). 2 patients developed Grade 3 hypokalemia. No dose-limiting toxicities (DLTs).",
            "Other": "PK was generally dose proportional within 1mg/kg~15mg/kg. One patient produced anti-drug antibodies (ADA) with a positive rate of 4.5%.",
            "Conclusion": "LB1410 has manageable safety and shows preliminary efficacy. Additional dose and efficacy expansion study is ongoing in immunotherapy-naive patients with MSSCRC and patients who are resistant to immunotherapy."
        },
        {
            "Title": "Primary analysis of a phase 1/2 study of LM-101: An anti-SIRPa antibody as a single agent in patients with advanced malignancies.",
            "Indication": "Advanced malignancies",
            "Drug": "LM-101",
            "PFS": "None",
            "OS": "None",
            "ORR": "One complete response (CR), one partial response (PR), and five stable disease (SD) out of 16 patients.",
            "AE": "Most frequent adverse event (AE) was decreased lymphocyte counts. Two patients experienced Grade \u22653 AEs related to LM-101.",
            "Other": "No dose-limiting toxicity (DLT) observed. Maximum tolerated dose (MTD) not reached.",
            "Conclusion": "LM-101 monotherapy showed excellent safety profile and promising anti-tumor activity in patients with advanced malignancies. Further investigation of LM-101 as a single agent and in combination with other anti-tumor agents are ongoing."
        },
        {
            "Title": "19-BI-1808-01, a phase 1/2a clinical trial of BI-1808, a tumor necrosis factor receptor 2 (TNFR2) blocker/depleter with or without pembrolizumab.",
            "Indication": "Advanced solid malignancies or T-cell lymphomas, including CTCL",
            "Drug": "BI-1808",
            "PFS": "None",
            "OS": "None",
            "ORR": "One iPR out of 20 evaluable patients, six patients showing SD. One metastatic GIST patient with 12 prior lines of treatment showed robust and ongoing response with several target lesions non-detectable and 60% SLD reduction from baseline.",
            "AE": "No Grade 3/4 AEs related to BI-1808 monotherapy. Grade 1/2 AEs evenly distributed across dose range. Five Grade 1/2 IRR observed. One DLT (colitis) in combination arm at 225mg.",
            "Other": "At doses \u2265675mg Q3W, BI-1808 t1/2 was approximately one week leading to accumulation of drug, complete receptor occupancy throughout dosing interval, substantial increase in sTNFR2, and significant reduction of regulatory T-cells.",
            "Conclusion": "Preliminary data from dose escalation phase is promising. BI-1808 has a favorable safety profile, with early signs of monotherapy activity, and is well tolerated when combined with pembrolizumab."
        },
        {
            "Title": "Updated safety, efficacy, pharmacokinetics, and biomarkers from the phase 1 study of IMC-002, a novel anti-CD47 monoclonal antibody, in patients with advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "IMC-002",
            "PFS": "None",
            "OS": "None",
            "ORR": "Disease control rate was 50.0%, clinical benefit rate (CBR) was 33.3% with a median treatment duration of 10 months.",
            "AE": "All treatment-related adverse events (TRAEs) were Grade 1-2 (92%). TRAEs with over 20% incidence included skin rash, transient floaters, and anemia.",
            "Other": "Based on PK modeling, 20mg/kg Q3W was selected as optimal RP2D. AI analysis of CD47 IHC showed higher density of CD47 positive macrophages in CBR patients.",
            "Conclusion": "IMC-002 has an excellent safety profile when administered intravenously at a dose of up to 30mg/kg every 2 weeks. IMC-002 monotherapy demonstrates meaningful clinical benefits in refractory HCC. The density of CD47+ macrophages analyzed by AI is higher in CBR patients."
        },
        {
            "Title": "Triple M overlap syndrome (TMOS): Evaluating immune checkpoint inhibitor-related overlap syndrome of myocarditis, myositis and myasthenia gravis using an international pharmacovigilance database.",
            "Indication": "Triple M overlap syndrome (TMOS)",
            "Drug": "Immune checkpoint inhibitors (ICIs)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Concurrent ICI-induced hepatitis had a significantly higher frequency in TMOS compared to the other two groups (20.4% vs 10.2% vs 10.2%).",
            "Other": "Fatality rates were significantly higher for TMOS compared to MC with MYS or MG (TMOS: 43.8% vs MC+MYS/MG: 29.8%, p=0.033).",
            "Conclusion": "TMOS is associated with significantly higher mortality than MC with MYS or MG, with risk factors of older age and treatment with doublet ICI, especially in patients with melanoma. Further research is needed to identify the underlying biology, scale, and risk factors, as well as explore early immunosuppressive strategies in addition to steroids for improved outcomes in ICI-related TMOS."
        },
        {
            "Title": "Comprehensive whole genome and transcriptome analysis of patients with advanced solid tumor treated with immune checkpoint inhibitor therapy in the pan-cancer cohorts from the Marathon of Hope Cancer Centres Network Study (MOHCCN).",
            "Indication": "Advanced solid tumors",
            "Drug": "Immune checkpoint inhibitors (ICIs)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Higher tumor mutation burden (TMB) was observed in responders vs non-responders (median 13 vs 5 coding mut/Mb, p=0.001), with the highest TMB in patients with durable response (median 14 mut/Mb). Differential RNA gene expression analysis showed non-responders had increased expression of several oncogenic pathway signatures, including MYC targets, G2M checkpoint, and E2F targets.",
            "Conclusion": "ICI responders showed enrichment in coding mutations of several negative regulators of the Wnt/b-catenin pathway and non-coding promoter mutations in CTNNB1 compared with non-responders. Further investigation is ongoing to biologically validate how these mutations in the Wnt/b-catenin pathway may lead to improved response to ICI."
        },
        {
            "Title": "Fecal microbiota transplantation combined with anti-PD-(L)1 inhibitors as first-line maintenance therapy for advanced gastric and non-small cell lung cancer.",
            "Indication": "Advanced gastric and non-small cell lung cancer",
            "Drug": "Anti-PD-(L)1 inhibitors",
            "PFS": "For the GC group, median follow-up was 8.8 months (IQR 5.1-9.7), with unreached median PFS1 and a one-year PFS rate of 78%. For NSCLC, median follow-up was 8.0 months (IQR 4.2-12.2), with unreached median PFS1 and a one-year PFS rate of 67%.",
            "OS": "None",
            "ORR": "All patients achieved either a partial response (PR, n=7) or stable disease (SD, n=10) after first-line therapy.",
            "AE": "5 patients (29%) experienced grade 1-2 FMT-related toxicities, primarily gastrointestinal reactions, including diarrhea, gas, and abdominal pain, but no grade 3 or higher adverse events were identified.",
            "Other": "53% of patients reported improved quality of life (QoL).",
            "Conclusion": "Administering FMT prior to first-line immune maintenance therapy in patients with advanced GC and NSCLC has the potential to prolong PFS and demonstrate favorable safety. However, these findings necessitate validation through larger-scale clinical trials."
        },
        {
            "Title": "Tocilizumab for advanced non-small cell lung cancer with concomitant inflammatory cachexia: A single-centre study.",
            "Indication": "Non-small cell lung cancer (NSCLC) with inflammatory cachexia",
            "Drug": "Tocilizumab",
            "PFS": "None",
            "OS": "15.1 months (tocilizumab group) vs. 3.2 months (control group); Hazard Ratio: 0.18, 95% CI: 0.08-0.38, p<0.001",
            "ORR": "None",
            "AE": "34.6% (tocilizumab group) vs. 78.3% (control group); Tocilizumab-related adverse events in 11.5% (neutropenia and skin/subcutaneous tissue infection)",
            "Other": "Significant improvements in body weight, albumin, C-reactive protein, mGPS, appetite, and fatigue in the tocilizumab group",
            "Conclusion": "Tocilizumab demonstrated significant benefits in survival and various clinical parameters in patients with NSCLC and concurrent inflammatory cachexia. Given the existing unmet medical need for effective interventions for cancer cachexia, tocilizumab may be considered as a potential treatment option."
        },
        {
            "Title": "A phase 1 study of REGN6569, a GITR mAb, in combination with cemiplimab in patients (pts) with advanced solid tumor malignancies: Initial dose-escalation results.",
            "Indication": "Advanced solid tumor malignancies",
            "Drug": "REGN6569 in combination with cemiplimab",
            "PFS": "None",
            "OS": "None",
            "ORR": "Two partial responses: 1 pt with mucoepidermoid tumor of the parotid gland (5.6 months duration), 1 pt with B3 thymoma (10.4 months duration)",
            "AE": "41.4% Grade \u22653 treatment-emergent adverse events; 55.2% treatment-related TEAEs (any grade); Most frequent TEAEs: arthralgia (24.1%), infusion-related reactions, abdominal pain (20.7% each); One dose-limiting toxicity (Grade 3 hepatic failure)",
            "Other": "Full receptor occupancy on circulating Tregs observed in all dose cohorts; Increased frequency (~10\u201350%) of proliferating NK cells in peripheral blood post REGN6569 treatment",
            "Conclusion": "In this dose-escalation study, REGN6569 was administered up to 1200 mg (DL5) in combination with cemiplimab with one dose-limiting toxicity. The study has progressed to dose-expansion cohorts in anti\u2013PD-1-resistant head and neck cancer, with pts treated with REGN6569 (DL5) + cemiplimab."
        },
        {
            "Title": "Can immune therapy prevent pre-clinical cancer from progression? The impact of immune therapy on secondary cancer incidence.",
            "Indication": "Secondary cancer incidence in patients treated for primary cancer",
            "Drug": "Immune Therapy (IT)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Two-year secondary cancer-free survival was 98% in patients who received first-line IT compared to 93% in patients who did not receive first-line IT. Multivariable regression showed a 77% decrease in secondary cancer incidence in patients who received IT at any time.",
            "Conclusion": "The study concluded that the incidence of secondary cancer in patients treated with IT was lower than in patients who received non-IT. IT may increase immune surveillance to prevent cancer in its pre-clinical stage."
        },
        {
            "Title": "A phase I open-label study of a novel IL-2Rb/g cytokine agonist, LTC004, in patients with advanced or metastatic solid tumors.",
            "Indication": "Advanced or metastatic solid tumors",
            "Drug": "LTC004",
            "PFS": "None",
            "OS": "None",
            "ORR": "ORR: 5.9% (1/17), DCR: 58.8% (10/17), PR: 4.8 months in a patient with pMMR/MSS CRC",
            "AE": "Common TEAEs in \u226530% of patients: fever, white blood cell decreased, anemia, AST/ALT increased, neutrophil count decreased, GGT increased, nausea, hypotension. Most AEs were G1 and G2, reversible and responsive to supportive care.",
            "Other": "None",
            "Conclusion": "LTC004 demonstrated encouraging anti-tumor efficacy including in cold tumors, and was well tolerated in patients with advanced or metastatic solid tumors. Further dose expansion in selected tumor types is ongoing, and evaluations in combination with other agents are being planned."
        },
        {
            "Title": "Safety and efficacy of immune checkpoint inhibitors (ICI) in patients with pre-existing neurological autoimmune diseases (NAID) and Parkinson\u2019s disease.",
            "Indication": "Patients with pre-existing neurological autoimmune diseases and Parkinson\u2019s disease",
            "Drug": "Immune Checkpoint Inhibitors (ICI)",
            "PFS": "None",
            "OS": "None",
            "ORR": "MS patients: 23% response rate, MG patients: 20% response rate",
            "AE": "NAID flares or neuro irAEs: 30%, other (non-neuro) irAEs: 33%. Severe toxicities in MG: 70% had MG flare/neuro irAE, 3 fatal. Parkinson\u2019s flares: 12.9%, neuro-irAEs: 3%, other irAEs: 42%, 1 fatal myocarditis/myositis.",
            "Other": "None",
            "Conclusion": "MG patients have high rates of MG flare/neuro irAEs, hospitalizations, and fatalities, but low response rates. Other NAIDs (particularly MS) and Parkinson\u2019s appeared to have modest risks of flares/neuro irAEs. Parkinson\u2019s patients had high rates of non-neuro irAEs and response rates."
        },
        {
            "Title": "Correlations between first cycle toxicity and overall survival in patients with rare cancers treated with immune checkpoint inhibitors (NCI/SWOG S1609).",
            "Indication": "Rare cancers",
            "Drug": "Ipilimumab and Nivolumab",
            "PFS": "Grade 1-2 treatment-related irAEs: HR=0.83, 95% CI=0.67-1.01, p=0.067; Grade 3-4: HR=1.35, 95% CI=1.02-1.78, p=0.037",
            "OS": "Grade 1-2 treatment-related irAEs: HR=0.61, 95% CI=0.49-0.75, p<0.001; Grade 3-4: HR=1.41, 95% CI=1.04-1.90, p=0.025",
            "ORR": "None",
            "AE": "None",
            "Other": "Grade 1-2 dermatologic toxicity associated with improved OS (HR=0.67, 95% CI=0.52-0.85, p=0.002)",
            "Conclusion": "In this large cohort of patients with rare tumors receiving ipilimumab and nivolumab, the grade of irAE in the first cycle of therapy was prognostic for survival."
        },
        {
            "Title": "Intravenous delivery of oncolytic adenovirus TILT-123 results in systemic tumor transduction and accumulation of lymphocytes.",
            "Indication": "Various cancers including melanoma, ovarian cancer, and sarcoma",
            "Drug": "TILT-123 (igrelimogene litadenorepvec, Ad5/3-E2F-D24-hTNFa-IRES-hIL2)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Virus detected in peripheral blood of all treated patients; tumor transduction observed in 75% of patients evaluated",
            "Conclusion": "Intravenous injection of TILT-123 results in persistence of the virus in peripheral blood for up to 7 days. Tumor transduction was observed in 75% of patients in three Phase I trials. Further increases in tumor transduction could be achieved by using multiple intravenous injections of TILT-123."
        },
        {
            "Title": "A multi-centre, single arm, phase 2 trial of pembrolizumab in treatment-na\u00efve patients with carcinoma of unknown primary site.",
            "Indication": "Carcinoma of unknown primary (CUP)",
            "Drug": "Pembrolizumab",
            "PFS": "2.56 months (95% CI 2.10\u20137.46)",
            "OS": "9.79 months (95% CI 3.84 \u2013 16.03)",
            "ORR": "18.5% (4 partial responses and 1 complete response)",
            "AE": "Grade 3 treatment-related adverse events in 4 patients (pain, anorexia, fatigue, confusion, and anemia)",
            "Other": "Disease control rate was 51.9% (9 stable disease)",
            "Conclusion": "Although not meeting the primary endpoint, pembrolizumab has some activity in CUP patients. Patients with CUP should be screened for MMR or MSI. Future studies should evaluate combination therapies with the addition of pembrolizumab."
        },
        {
            "Title": "First-line treatment of fecal microbiota transplantation for immune-mediated colitis.",
            "Indication": "Immune-mediated colitis (IMC)",
            "Drug": "Fecal microbiota transplantation (FMT)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Fatigue, transient fever, self-resolving abdominal cramping, and gassiness in six patients (50.0%) within the first week of FMT",
            "Other": "Symptom improvement in a median of 5 days (IQR: 1-7 days) after FMT. Nine patients (75%) had colitis remission by the end of the study period.",
            "Conclusion": "This study is the first to evaluate the safety and efficacy of front-line FMT for the treatment of IMC. Our preliminary results are promising and show that FMT can be an effective first-line treatment for IMC that can quickly provide symptom relief in a majority of patients while avoiding the use of steroids."
        },
        {
            "Title": "Randomized clinical trial of infliximab versus vedolizumab for immune checkpoint inhibitor related colitis.",
            "Indication": "Immune-mediated diarrhea and colitis (IMDC)",
            "Drug": "Infliximab, Vedolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Similar rate of mild adverse events in both groups, most of which were deemed unrelated to SIT use",
            "Other": "Clinical remission rates at two weeks were 90% for infliximab and 88.9% for vedolizumab. Symptom improvement was typically seen in a median of 3 days (IQR: 1.5-5.5) across both groups.",
            "Conclusion": "This is the first randomized controlled trial to evaluate the safety and efficacy of infliximab and vedolizumab in the management of IMDC. Our results suggest that both agents are equally effective at controlling symptoms within two weeks of the first infusion, with a small number of patients receiving either having recurrent disease."
        },
        {
            "Title": "First-in-human phase I/II safety and preliminary efficacy of PM1032, a bispecific antibody targeting CLDN18.2 and 4-1BB, in patients with advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "PM1032",
            "PFS": "None",
            "OS": "None",
            "ORR": "20% in 10 measurable and evaluable CLDN18.2+ GC/GEJ patients",
            "AE": "TRAEs occurred in 22 subjects (73.3%), and \u2265Grade 3 TRAEs occurred in 3 subjects (10%). The most common TRAEs were nausea (20%) and aspartate transaminase increase (16.7%).",
            "Other": "Pharmacokinetics (AUC0-336h & Cmax) were dose-proportional across the dose ranges of 0.3 mg/kg\u201312 mg/kg with a terminal half-life of 6.0~10.1 days.",
            "Conclusion": "PM1032 was well-tolerated up to 12 mg/kg Q2W and demonstrated preliminary anti-tumor activity in CLDN18.2+ tumors. Further development of PM1032 as a monotherapy and/or combination therapy for CLDN18.2-positive cancers is planned."
        },
        {
            "Title": "Preliminary results of a phase 1, first-in-human, dose escalation study of the anti-CCR8 cytolytic antibody, CHS-114 (formerly SRF114) in patients with advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "CHS-114",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "33% of patients experienced treatment-related adverse events; all were CTCAE grade 1-2, with pyrexia (13%) being the most common.",
            "Other": "Preliminary PK analysis indicates that CHS-114 exposure increases with dose with an elimination half-life between 9-17 days across the dose range of 10-700 mg. Preliminary receptor occupancy analysis revealed minimal detection of CCR8+ Tregs and a modest concomitant decrease in overall Treg frequency at the end of cycle 1.",
            "Conclusion": "CHS-114 has an acceptable safety profile to date in heavily pretreated patients at doses up to 700 mg. CHS-114 administration at 100 to 700 mg shows robust decreases in peripheral CCR8+ Tregs following the first dose (cycle 1). Dose escalation is ongoing."
        },
        {
            "Title": "A blind retrospective analysis of a novel predictive marker to ICB response in NSCLC, calculated directly from histopathological slides.",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "PD-1 inhibitors",
            "PFS": "None",
            "OS": "None",
            "ORR": "68% overall response rate (34 of 50)",
            "AE": "None",
            "Other": "ENLIGHT-DP\u2019s score is predictive of response in this cohort, with ROC AUC = 0.69 (p=0.01, one-sided permutation test). Using a predefined threshold for binary classification of response derived from independent data, all 15 patients that were predicted to respond by ENLIGHT-DP indeed responded (100% PPV, 44% sensitivity).",
            "Conclusion": "ENLIGHT-DP demonstrates high predictive power for response to ICB in NSCLC relying solely on accessible H&E slides, outperforming the commonly used PD-L1 and TMB markers. ENLIGHT-DP is also able to identify responders within patients with PD-L1 <1%, for whom ICB is usually considered ineffective."
        },
        {
            "Title": "Transcriptome-based response predictor to identify potential responders among patients with negative standard markers for response to immune checkpoint blockers.",
            "Indication": "Various cancer types",
            "Drug": "Anti-PD-1 treatments",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "ENLIGHT is highly predictive of response to anti-PD-1 in patients with negative ICB markers in three datasets (ROC AUCs of 0.80, 0.84, 0.77, respectively, for low PD-L1, low TMB, and MSS). Patients who are ENLIGHT-Matched to anti-PD1 in this biomarker-negative cohort are almost 3 times more likely to respond than patients who are not (31% vs. 11%, p=5e-13).",
            "Conclusion": "ENLIGHT is a powerful tool for predicting response to anti-PD-1 treatment in patients with negative standard biomarkers for ICB, a currently unmet need with considerable clinical importance."
        },
        {
            "Title": "Genetic drivers of severe skin toxicity with immune checkpoint inhibitors (ICIs) in Asian patients.",
            "Indication": "Various cancer types",
            "Drug": "Atezolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Severe skin immune-related adverse events (SirAEs) in 18 Asian patients: Dermatitis bullous (n=4), Erythema multiforme (n=10), Stevens-Johnson syndrome (n=3), Toxic skin eruption (n=1).",
            "Other": "There was an association between some HLA alleles and severe SirAEs, but sensitivity was low (<30%) and 1-NPV was only slightly smaller than background prevalence. Results for five HLA alleles with OR >1.5 in either the unadjusted or adjusted analysis compared to controls without severe SirAEs are presented.",
            "Conclusion": "Five HLA alleles previously reported in AI disorders were associated with severe SirAEs in Asian patients receiving Atezolizumab. Although the effect size is insufficient to be considered clinically relevant, further research is warranted to better characterize the patient-level drivers of severe SirAEs in Asian patients."
        },
        {
            "Title": "Phase I trial of talimogene laherparepvec for the treatment of peritoneal surface malignancies (TEMPO)",
            "Indication": "Peritoneal surface malignancies (PSM)",
            "Drug": "Talimogene Laherparepvec (T-VEC)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Abdominal distension: 14% (4x106), 0% (4x107), 0% (4x108); Abdominal pain: 14% (4x106), 0% (4x107), 50% (4x108); Nausea: 0% (4x106), 33% (4x107), 12% (4x108); Chills: 14% (4x106), 0% (4x107), 0% (4x108); Fatigue: 14% (4x106), 0% (4x107), 0% (4x108); Fever: 14% (4x106), 33% (4x107), 25% (4x108); Anorexia: 14% (4x106), 0% (4x107), 0% (4x108); Hyperhidrosis: 0% (4x106), 33% (4x107), 0% (4x108)",
            "Other": "Restricted Mean Survival Time (RMST): 3.1 months (4x106), 4.2 months (4x107), 4.6 months (4x108)",
            "Conclusion": "Intraperitoneal T-VEC is safe in patients with unresectable PSM. The study identified 4x108 pfu as the recommended phase 2 dose (RP2D) for T-VEC. The RMST increased with increasing doses of T-VEC, indicating promising results for T-VEC as a candidate for combination immunotherapies for PSM."
        },
        {
            "Title": "A phase Ia/Ib, non-randomized, open-label, dose escalation and expansion trial of the B7-H6/CD3 T-cell engager BI 765049 with or without ezabenlimab in Asian patients with advanced solid tumors",
            "Indication": "Advanced or unresectable gastric cancer, colorectal cancer (CRC), pancreatic ductal adenocarcinoma, hepatocellular cancer, head and neck squamous cell carcinoma, non-small cell lung cancer",
            "Drug": "BI 765049 with or without ezabenlimab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: Occurrence of dose-limiting toxicities (DLTs) for Parts I and III; Objective response (OR) for Parts II and IV. Secondary endpoints: Pharmacokinetic (PK) parameters, OR (RECIST v1.1), progression-free survival, duration of response, disease control, OR (immunotherapy RECIST [iRECIST]).",
            "Conclusion": "The study aims to assess the safety and tolerability of BI 765049 alone or in combination with ezabenlimab in patients with advanced solid tumors. The trial is ongoing."
        },
        {
            "Title": "Phase 1 study of ASP1002, a bispecific antibody targeting claudin 4 (CLDN4) and CD137, in patients with locally advanced (LA) or metastatic solid tumors that express CLDN4",
            "Indication": "Locally advanced or metastatic solid tumors that express CLDN4",
            "Drug": "ASP1002",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: Safety and tolerability, as assessed by dose-limiting toxicities (DLTs), adverse events (AEs), serious AEs, laboratory tests, vital signs, ECGs, PEs, and ECOG performance status. Secondary endpoints: Pharmacokinetic characteristics, confirmed objective response rate (ORR), duration of response, disease control rate per RECIST and RECIST 1.1, incidence rate and expression levels of CLDN4 by immunohistochemistry.",
            "Conclusion": "The study is evaluating the safety and tolerability, maximum tolerated dose (MTD), and/or recommended phase 2 dose (RP2D) of ASP1002 in adult patients with LA or metastatic solid tumors that express CLDN4. The trial is ongoing."
        },
        {
            "Title": "ATTAINMENT: A phase Ib trial of MDX-124, a first-in-class annexin-A1 targeting antibody, alone and in combination with anti-cancer treatments, in patients with advanced solid tumors",
            "Indication": "Locally advanced, unresectable, or metastatic solid malignancies believed to overexpress annexin-A1",
            "Drug": "MDX-124",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary objective: Determine the recommended phase 2 dose (RP2D) of MDX-124 both as a single agent and in combination with anti-cancer treatments. Secondary objectives: Assess the safety and tolerability of MDX-124, characterize pharmacokinetic parameters, and assess evidence of preliminary anti-tumor activity per RECIST v1.1 criteria. Exploratory objectives: Assess host immune response to MDX-124, circulating levels of annexin-A1 at baseline and after dosing, and the impact of MDX-124 on blood/tissue biomarkers.",
            "Conclusion": "The study is evaluating the safety and tolerability of MDX-124 alone and in combination with anti-cancer treatments in participants with locally advanced, unresectable, or metastatic solid malignancies. The trial is ongoing."
        },
        {
            "Title": "Phase I/II trial of LAVA-1207, a novel bispecific gamma-delta T-cell engager in metastatic castration-resistant prostate cancer (mCRPC).",
            "Indication": "Metastatic castration-resistant prostate cancer (mCRPC)",
            "Drug": "LAVA-1207",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Evaluation of pharmacokinetics, pharmacodynamics, immunogenicity, and preliminary antitumor activity. Exploratory endpoints include evaluation of the effect of study treatment on circulating tumor cells (CTCs) and circulating tumor DNA (ctDNA).",
            "Conclusion": "Dose escalation is ongoing. Expansion arm(s) will be included based on available data from part 1 of the study and may include one or more of LAVA-1207, LAVA-1207 with low dose IL-2, or LAVA-1207 in combination with pembrolizumab."
        },
        {
            "Title": "The FIPO23 trial: First-in-human phase I/II study of XON7 in advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "XON7",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoints: safety, tolerability, and determination of MTD/RP2D. Secondary endpoints: pharmacokinetics (PK), pharmacodynamics, immunogenicity, and preliminary anti-tumor activity (RECIST 1.1).",
            "Conclusion": "Accrual is ongoing in this first-in-human trial of XON7."
        },
        {
            "Title": "A phase 2, multicenter study of TILs treatment in advanced tumors with alterations in the SWI/SNF complex: The TILTS study.",
            "Indication": "Advanced tumors associated with SWI/SNF complex mutations",
            "Drug": "TILs (Tumor Infiltrating Lymphocytes)",
            "PFS": "None",
            "OS": "None",
            "ORR": "Overall response rate (ORR) by RECIST 1.1",
            "AE": "None",
            "Other": "Secondary endpoints include toxicity evaluation, duration of response (DOR), progression-free survival (PFS), or overall survival (OS).",
            "Conclusion": "An ancillary translational study will explore mechanisms of immunogenicity, antigen recognition, TILs anti-tumor activity, and changes in the stool microbiome and specific TCR population during treatment. Also, tumor immune microenvironment will be analyzed."
        },
        {
            "Title": "ATHENA: A phase 1/2 study of AZD5851, a chimeric antigen receptor (CAR) T-cell therapy directed against GPC3 in adult patients with advanced/recurrent hepatocellular carcinoma (HCC).",
            "Indication": "Advanced/recurrent hepatocellular carcinoma (HCC)",
            "Drug": "AZD5851",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoints: safety/tolerability of AZD5851 (both study parts), and to determine the recommended dose(s) of AZD5851 for expansion and phase 2 trials (Part A). Secondary endpoints include efficacy (objective response rate, best overall response, duration of response, disease control rate, durable response rate, time to response, progression-free survival, and overall survival) and pharmacokinetics. Exploratory endpoints include pharmacodynamic biomarkers and immunogenicity.",
            "Conclusion": "Enrollment began Nov 2023."
        },
        {
            "Title": "A phase 1/2, open-label, multicenter, dose escalation and cohort expansion study of the safety and efficacy of anti-CD70 allogeneic CRISPR-Cas9\u2013engineered T cells (CTX131) in adult patients with relapsed or refractory solid tumors.",
            "Indication": "Relapsed or refractory solid tumors",
            "Drug": "CTX131",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint for Part 1 is DLTs; secondary endpoints include ORR, DOR, PFS, and OS. For Phase 2, the primary endpoint is ORR by IRC assessment.",
            "Conclusion": "The trial is currently open with enrollment ongoing."
        },
        {
            "Title": "An open-label, phase 1, multicenter study to evaluate the safety and preliminary anti-tumor activity of NT-112 in human leukocyte antigen-C*08:02\u2013positive adult patients with unresectable, advanced, and/or metastatic solid tumors that are positive for the KRAS G12D mutation.",
            "Indication": "Unresectable, advanced, and/or metastatic solid tumors with KRAS G12D mutation",
            "Drug": "NT-112",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint is safety and MTD; secondary endpoints include anti-tumor activities. Comprehensive translational analysis will be conducted.",
            "Conclusion": "All subjects will be followed for up to 15 years."
        },
        {
            "Title": "A phase 1 trial of TSC-100 and TSC-101, engineered T cell therapies that target minor histocompatibility antigens to eliminate residual disease after hematopoietic cell transplantation.",
            "Indication": "Residual disease after hematopoietic cell transplantation in AML, MDS, or ALL",
            "Drug": "TSC-100 and TSC-101",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Primary endpoints include adverse event profiles and dose limiting toxicities.",
            "Other": "Secondary endpoints include relapse rates, disease-free survival, and overall survival. Exploratory endpoints include donor chimerism rates and kinetics, and minimal residual disease (MRD) rates.",
            "Conclusion": "Together, these assays measure elimination of residual patient hematopoietic cells, malignant or normal, and could provide early evidence of biological activity."
        },
        {
            "Title": "Binary oncolytic adenovirus in combination with HER2-specific autologous CAR VST for treatment of advanced HER2-positive solid tumors (VISTA).",
            "Indication": "Advanced HER2-positive solid tumors",
            "Drug": "Binary oncolytic adenovirus and HER2-specific autologous CAR VST",
            "PFS": "Median progression free survival (PFS)",
            "OS": "Median overall survival (OS)",
            "ORR": "Overall response rate (ORR)",
            "AE": "Primary endpoint is safety and maximum tolerated dose as assessed by incidence of dose limiting toxicities (DLT).",
            "Other": "Secondary endpoints include disease control rate (DCR). Exploratory endpoints include assessing the immunogenicity of combination therapy and long-term persistence and functional status of HER2 CAR T-cells.",
            "Conclusion": "This study is currently enrolling."
        },
        {
            "Title": "DUET-01: A first-in-human, phase 1/2 study of BOXR1030 in patients with advanced glypican-3-positive solid tumors.",
            "Indication": "Advanced glypican-3-positive solid tumors",
            "Drug": "BOXR1030",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The primary endpoints are the incidence of DLTs; determination of the MTD and RP2D; the type, frequency and severity of treatment-emergent adverse events; clinically significant abnormal safety laboratory findings and vital signs. Key secondary endpoints include efficacy parameters such as objective response rate, BOXR1030 T-cell expansion and persistence, and levels of inflammatory markers and cytokines."
        },
        {
            "Title": "A phase 1, first-in-human study of autologous monocytes engineered to express an anti-HER2 chimeric antigen receptor (CAR) in participants with HER2-overexpressing solid tumors.",
            "Indication": "HER2-overexpressing solid tumors",
            "Drug": "CT-0525",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Primary endpoints include assessment of safety and tolerability, as well as manufacture feasibility. Correlative assessments include pre- and post-treatment biopsies and blood samples for safety, immunogenicity, pharmacokinetics, tumor trafficking, TME modulation, epitope spreading, and other translational biomarkers."
        },
        {
            "Title": "First-in-human autologous CAR-T for metastatic breast cancers to target growth factor receptor MUC1*.",
            "Indication": "Metastatic breast cancer",
            "Drug": "huMNC2-CAR44 and huMNC2-CAR22",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Based on responses of initial huMNC2-CAR44 patients, inclusion criteria were amended to an Enrichment Trial Design. The number of prior therapies was limited to less than or equal to 10. Anticipated survival at the time of product infusion should be at least 3 months. Vein-to-vein time, which historically had been 15-16 days at one site and as high as 83 days at another site, would be targeted to 16-22 days. The tumors of eligible patients need to have a MUC1* membrane positive H-score equal to or greater than 120 out of a possible 300, defined as high MUC1* positivity."
        },
        {
            "Title": "A phase 1/2, first-in-human, open-label, dose-escalation study of TAK-280, an investigational B7-H3xCD3 conditional bispecific redirected activation (COBRA) T-cell engager, in adult patients with unresectable, locally advanced, or metastatic solid tumors.",
            "Indication": "Unresectable, locally advanced, or metastatic solid tumors",
            "Drug": "TAK-280",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The primary endpoint is incidence of dose-limiting or other toxicities during dose escalation; secondary endpoints include pharmacokinetic parameters, response assessment per RECIST v1.1, overall response rate, duration of response, progression-free survival, overall survival, disease control rate, and prostate-specific antigen response in patients with mCRPC. Cytokine release syndrome is closely managed and reported per American Society for Transplantation and Cellular Therapy consensus grading."
        },
        {
            "Title": "Two phase 2A clinical trials to evaluate the safety and efficacy of IMSA101 in combination with radiotherapy and checkpoint inhibitors in oligometastatic and oligoprogressive solid tumor malignancies.",
            "Indication": "Oligometastatic non-small cell lung cancer (NSCLC) and renal cell carcinoma (RCC), oligoprogressive solid tumor malignancies",
            "Drug": "IMSA101",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: Progression-free rate at 18 months for OMD study and 12 months for OPD study",
            "Conclusion": "Ongoing studies to evaluate the safety and efficacy of IMSA101 in combination with PULSAR and PD-1 targeted immunotherapy."
        },
        {
            "Title": "A phase 1, multi-center, safety, feasibility, and preliminary efficacy study evaluating a single dose of UNO101 in relapsed or refractory, unresectable, primary, or metastatic cutaneous and subcutaneous malignancies.",
            "Indication": "Relapsed or refractory, unresectable, primary, or metastatic cutaneous and subcutaneous malignancies",
            "Drug": "UNO101",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Evaluation of response per RECIST version 1.1 and iRECIST, toxicity graded per NCI CTCAE version 5.0",
            "Conclusion": "Ongoing phase 1 study to determine the maximum tolerated dose or biological effective dose of UNO101."
        },
        {
            "Title": "Combination therapy with the oncolytic virus CF33-CD19 and blinatumomab for the treatment of advanced solid tumors.",
            "Indication": "Advanced or metastatic solid tumors",
            "Drug": "CF33-CD19 and blinatumomab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoints: Safety and identification of the recommended phase 2 dose. Secondary endpoints: Objective response rate according to RECIST v1.1 and iRECIST.",
            "Conclusion": "Ongoing phase 1 study to evaluate the safety and efficacy of CF33-CD19 in combination with blinatumomab."
        },
        {
            "Title": "Phase I, open-label, dose-escalation trial investigating the safety and efficacy of oncolytic virus BI 1821736 in patients with advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "BI 1821736",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary objective: Determine the maximum tolerated dose and/or recommended Phase II dose based on occurrence of dose-limiting toxicities. Further objectives: Assessment of BI 1821736 preliminary efficacy, pharmacokinetics, immunogenicity, shedding, and biomarkers.",
            "Conclusion": "Ongoing phase 1 study to evaluate the safety and early efficacy of BI 1821736."
        },
        {
            "Title": "A phase 1, open-label, dose escalation study on the safety and tolerability of ANK-101 in advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "ANK-101",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary objectives: Safety and tolerability of ANK-101, identification of the recommended dose for expansion (RDE). Secondary objectives: Pharmacokinetics (PK) and immunogenicity (ADA) of ANK-101, clinical activity as measured by ORR, DCR, DOR, and PFS by RECIST v1.1. Exploratory objectives: Assessment of QOL using FACT-G and profiling immune-based pharmacodynamic (PD) changes.",
            "Conclusion": "This clinical trial is in progress."
        },
        {
            "Title": "Phase I trial of CRD3874-SI, a systemically administered STING agonist, in patients with advanced solid tumors.",
            "Indication": "Advanced sarcoma and Merkel cell carcinoma",
            "Drug": "CRD3874-SI",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary objective: Assess safety and tolerability of CRD3874-SI by determining the maximum tolerated dose, recommended phase 2 dose, and schedule of administration. Secondary objectives: Further defining the safety profile, examining pharmacokinetics and pharmacodynamics (CXCL10 analysis) of CRD3874-SI, and evaluating efficacy as determined by best objective response rate and clinical benefit rate per RECIST v1.1.",
            "Conclusion": "This study is currently open to enrollment and dosing has commenced."
        },
        {
            "Title": "A first-in-human phase I study of ATG-031, anti-CD24 antibody, in patients with advanced solid tumors or B-cell non-Hodgkin lymphomas (PERFORM).",
            "Indication": "Advanced solid tumors or B-cell non-Hodgkin lymphomas",
            "Drug": "ATG-031",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary objective: Evaluate the safety and preliminary antitumor efficacy of ATG-031. The study includes a dose escalation phase and a dose expansion phase. The dose escalation phase enrolls patients with advanced solid tumors using a Bayesian Optimal Interval (BOIN) design. The dose expansion phase will enroll patients with specific tumor types and will randomize patients into two or more dose levels to determine the recommended Phase II dose (RP2D).",
            "Conclusion": "As of January 2024, 2 sites in the United States have initiated the study, and 4 patients have received ATG-031 treatment at 0.03 mg/kg (dose level 1)."
        },
        {
            "Title": "Phase 1 study of AM003, a novel individualized immunotherapy, in a basket of advanced solid tumors.",
            "Indication": "Advanced/metastatic relapsed/refractory solid tumors",
            "Drug": "AM003",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary objective: Evaluate the safety and tolerability of AM003. Additional objectives: Assessment of preliminary evidence of clinical benefit, pharmacokinetic and pharmacodynamics measurements, and identification of biomarkers. Endpoints: AEs assessed according to CTCAE v5, treatment response determined according to RECIST 1.1 and iRECIST, changes in putative markers evaluated based on multiple analyses methodologies.",
            "Conclusion": "Cohorts 1, 2, and 3 have been completed without DLTs. The DSMB has reviewed the escalation data and concluded that the trial can continue as planned. Enrollment of the 136 mg dose expansion is ongoing and completion of the trial will be mid-2024."
        },
        {
            "Title": "A phase 1/2 study of BDC-3042, a novel dectin-2 agonistic antibody, in patients with advanced cancers.",
            "Indication": "Advanced cancers",
            "Drug": "BDC-3042",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Pharmacokinetic parameters, pharmacodynamic biomarkers, ability to reprogram TAMs, and identification of biomarkers associated with BDC-3042 biological activity.",
            "Conclusion": "BDC-3042 is being investigated for its potential to reprogram TAMs and elicit anti-tumor activity in diverse human cancers. The study is currently recruiting."
        },
        {
            "Title": "A phase I trial of intratumoral STX-001: A novel self-replicating mRNA expressing IL-12 alone or with pembrolizumab in advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "STX-001",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Pharmacokinetics, preliminary efficacy as measured by RECIST 1.1, tumor-infiltrating lymphocytes, STX-001 mRNA in baseline and on-treatment biopsies, inflammatory cytokine profiles in plasma, immune activation in circulating immune cells, and circulating tumor DNA (ctDNA) for molecular response assessment.",
            "Conclusion": "STX-001 is being evaluated for safety, tolerability, and preliminary anti-tumor activity in patients with treatment-refractory advanced cancers."
        },
        {
            "Title": "A phase 1 trial in progress for in situ immunomodulation with CDX-301, radiation therapy, poly-ICLC, and CDX-1140 in patients with unresectable and metastatic solid tumors.",
            "Indication": "Unresectable and metastatic solid tumors",
            "Drug": "CDX-301, CDX-1140, Poly-ICLC",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Immune signatures in blood and the tumor.",
            "Conclusion": "The study aims to evaluate the safety, tolerability, and maximum tolerated dose (MTD) of the combination therapy in patients with unresectable and metastatic solid tumors. The trial is currently recruiting."
        },
        {
            "Title": "EVEREST-1: A seamless phase 1/2 study of A2B530, a carcinoembryonic antigen (CEA) logic-gated Tmod CAR T-cell therapy, in patients with solid tumors associated with CEA expression also exhibiting human leukocyte antigen (HLA)-A*02 loss of heterozygosity (LOH).",
            "Indication": "Solid tumors associated with CEA expression",
            "Drug": "A2B530",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Phase 1 primary objective: safety and tolerability of A2B530, determine RP2D. Phase 2 primary objective: overall response rate per RECIST v1.1.",
            "Conclusion": "Dose escalation is ongoing. A2B530 was successfully manufactured for all patients, and all patients have received A2B530 infusion."
        },
        {
            "Title": "EVEREST-2: A seamless phase 1/2 study of A2B694, a mesothelin (MSLN) logic-gated Tmod CAR T-cell therapy, in patients with solid tumors that show MSLN expression and human leukocyte antigen (HLA)-A*02 loss of heterozygosity (LOH).",
            "Indication": "Solid tumors with MSLN expression",
            "Drug": "A2B694",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Phase 1 primary objective: safety and tolerability of A2B694, determine RP2D. Phase 2 primary objective: overall response rate per RECIST v1.1.",
            "Conclusion": "Enrollment to EVEREST-2 is ongoing. A2B694 is manufactured from cryopreserved T cells when clinically appropriate for patients."
        },
        {
            "Title": "A phase I pilot study of personalized neoantigen peptide-based vaccine in combination with pembrolizumab in advanced solid tumors (PNeoVCA).",
            "Indication": "Advanced solid tumors",
            "Drug": "Personalized neoantigen peptide-based vaccine and pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary objective: safety, feasibility, and immunogenicity of personalized neoantigen vaccines in combination with pembrolizumab.",
            "Conclusion": "Cohort 1 has been completed without dose-limiting toxicity. Enrollment in Cohort 2 began in January 2024."
        },
        {
            "Title": "A randomized phase 2 trial of the IO102-IO103 (IDO and PD-L1) cancer vaccine plus pembrolizumab as neoadjuvant/adjuvant treatment of patients with solid tumors.",
            "Indication": "Melanoma and squamous cell carcinoma of the head and neck (SCCHN)",
            "Drug": "IO102-IO103 cancer vaccine and pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: major pathological response at surgery. Secondary endpoints: safety, EFS, and disease-free survival.",
            "Conclusion": "The study will be conducted in the US, EU, and Australia and began enrollment in December 2023."
        },
        {
            "Title": "FusionVAC22_01: A phase I clinical trial evaluating a DNAJB1-PRKACA fusion transcript-based peptide vaccine combined with immune checkpoint inhibition for fibrolamellar hepatocellular carcinoma and other tumor entities carrying the oncogenic driver fusion.",
            "Indication": "Fibrolamellar hepatocellular carcinoma and other malignancies carrying the DNAJB1-PRKACA fusion transcript",
            "Drug": "FusionVAC-22-based peptide vaccine combined with immune checkpoint inhibitor atezolizumab",
            "PFS": "32 months and 13 months for respective patients",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Disease control rate, quality of life, and overall survival will be evaluated",
            "Conclusion": "The initial use of FusionVAC-22-based peptide vaccines was well-tolerated and demonstrated the induction of robust and long-lasting T cell responses."
        },
        {
            "Title": "Repurposing ibrutinib for chemo-immunotherapy in a phase 1b study of ibrutinib with indoximod plus metronomic cyclophosphamide and etoposide for patients with childhood brain cancer.",
            "Indication": "Childhood brain cancer",
            "Drug": "Ibrutinib, indoximod, cyclophosphamide, etoposide",
            "PFS": "None",
            "OS": "None",
            "ORR": "Primary objective to evaluate preliminary evidence of efficacy in terms of ORR",
            "AE": "None",
            "Other": "Exploratory analyses using single-cell RNA and TCR sequencing to monitor for treatment-expanded TCR clonotypes and study their phenotype",
            "Conclusion": "The study aims to determine the pediatric recommended phase 2 dose of ibrutinib for the combined regimen and evaluate preliminary evidence of efficacy."
        },
        {
            "Title": "A phase 1/1b, first-in-human, multi-part study of DF6215, an engineered IL-2Ra-active agonist, to investigate the safety, tolerability, pharmacokinetics, and biological and clinical activity in patients with advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "DF6215",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Assessment of safety, tolerability, pharmacokinetics, and immunogenicity of DF6215",
            "Conclusion": "The study aims to determine the MTD of DF6215 in dose escalation and demonstrate clinical activity in the efficacy expansion cohort."
        },
        {
            "Title": "First-in-human trial of M9140, an anti-CEACAM5 antibody drug conjugate (ADC) with exatecan payload, in patients (pts) with metastatic colorectal cancer (mCRC).",
            "Indication": "Metastatic colorectal cancer (mCRC)",
            "Drug": "M9140",
            "PFS": "6.7 months (95% CI: 4.6, 8.4)",
            "OS": "None",
            "ORR": "10.0% (4 partial responses)",
            "AE": "Grade \u22653 TEAEs: neutropenia (40.0%), thrombocytopenia (27.5%), anemia (27.5%), WBC decreased (25.0%)",
            "Other": "No events of ocular toxicity/interstitial lung disease (ILD) reported",
            "Conclusion": "M9140 demonstrated encouraging activity in heavily pretreated pts with advanced CRC, with a manageable and predictable safety profile. Contrary to approved ADCs with Top1i payloads, no ILD or ocular toxicities were observed. Evaluation of M9140 in mCRC continues in the dose expansion part of this study."
        },
        {
            "Title": "First-in-human study of ABBV-706, a seizure-related homolog protein 6 (SEZ6)\u2013targeting antibody-drug conjugate (ADC), in patients (pts) with advanced solid tumors.",
            "Indication": "Advanced solid tumors (SCLC, CNS tumors, NENs)",
            "Drug": "ABBV-706",
            "PFS": "None",
            "OS": "None",
            "ORR": "21% (7 partial responses); 40% for SCLC, 6% for NEN",
            "AE": "TEAEs: anemia (51%), fatigue (41%), neutropenia (31%), leukopenia (31%). Grade \u22653 TEAEs: neutropenia (29%), anemia (27%), leukopenia (25%)",
            "Other": "No ABBV-706\u2013related deaths. Maximum tolerated dose was 3 mg/kg IV Q3W. Clinical benefit rate was 91%.",
            "Conclusion": "ABBV-706 demonstrated a manageable safety profile with promising efficacy in SCLC and NENs. Further evaluation of ABBV-706 is ongoing."
        },
        {
            "Title": "Phase I/II first-in-human study to evaluate the safety and efficacy of tissue factor-ADC MRG004A in patients with solid tumors.",
            "Indication": "Solid tumors (pancreatic cancer, triple-negative breast cancer, cervical cancer)",
            "Drug": "MRG004A",
            "PFS": "None",
            "OS": "None",
            "ORR": "33.3% (4/12) for pancreatic cancer at 2.0 mg/kg; 25% (1/4) for triple-negative breast cancer; 50% (1/2) for cervical cancer",
            "AE": "Common TRAEs: conjunctivitis (27%), anemia (17%), hypoalbuminemia (13%). Serious adverse events in 7.9% (5/63) pts.",
            "Other": "One pt with TNBC experienced Grade 3 Steven Johnson Syndrome, a dose-limiting toxicity (DLT), but resolved. No other DLT observed.",
            "Conclusion": "MRG004A demonstrated a manageable toxicity and striking antitumor activity across multiple tumor types with high TF expression in heavily pretreated settings, including pancreatic cancers. These encouraging findings warrant further evaluation of MRG004A, particularly in the context of TF-overexpressed solid tumors."
        },
        {
            "Title": "Efficacy and safety of dendrimer-enhanced (DEP) cabazitaxel (DEP CTX) in patients with advanced solid cancers in a phase 1/2 trial (P1/2).",
            "Indication": "Advanced solid cancers (mCRPC, esophagogastric cancer, ovarian cancer)",
            "Drug": "DEP cabazitaxel (DEP CTX)",
            "PFS": "3.8 months overall; 4.4 months for mCRPC; 4.0 months for esophagogastric adenocarcinoma; 1.9 months for esophageal squamous cell carcinoma; 3.1 months for ovarian cancer",
            "OS": "9.0 months overall; 14.7 months for mCRPC; 8.6 months for esophagogastric cancer",
            "ORR": "20% overall; 16.7% for mCRPC; 30% for esophagogastric cancer; 17.6% for ovarian cancer",
            "AE": "TRAEs mostly mild/moderate (G1/2; 64%/25%). G3/4 non-hematological TRAEs in 21% of pts. G3/4 lab-detected neutropenia in 23% of pts.",
            "Other": "No need for steroid premedication. TRAEs observed in \u226510% of pts included fatigue, neutropenia, anemia, thrombocytopenia, diarrhea, nausea, vomiting, peripheral neuropathy, and decreased appetite.",
            "Conclusion": "DEP CTX exhibited clinically meaningful, durable antitumor activity in multiple advanced solid cancers, including mCRPC, Pt-R ovarian cancer, and esophagogastric cancers without the need for steroid premedication. The antitumor activity and safety results compare favorably to standard cabazitaxel or standard of care chemotherapy in non-prostate cancers, highlighting the promising potential of dendrimer-enhanced delivery of cabazitaxel."
        },
        {
            "Title": "First-in-human phase I trial of the oral first-in-class ubiquitin specific peptidase 1 (USP1) inhibitor KSQ-4279 (KSQi), given as single agent (SA) and in combination with olaparib (OLA) or carboplatin (CARBO) in patients (pts) with advanced solid tumors, enriched for deleterious homologous recombination repair (HRR) mutations.",
            "Indication": "Advanced solid tumors with HRR mutations",
            "Drug": "KSQ-4279 (KSQi), olaparib (OLA), carboplatin (CARBO)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Anemia (36%), increased creatinine (33%) as SA, anemia in combination with OLA (87%) and CARBO (71%). Most common G3+ TEAEs were hyponatremia (12%) as SA and anemia in combination with OLA (73%) and CARBO (29%).",
            "Other": "DLTs: G3 maculopapular rash at 1250mg (Arm1) and G3 WBC decrease at 200mg, G3 anemia at 450mg (Arm2). Disease control rate at 16 weeks was 28% (Arm1), 40% (Arm2) and 29% (Arm3).",
            "Conclusion": "KSQi is a first-in-class USP1 inhibitor with an acceptable safety profile as SA and in combination. An MTD was not reached in any Arms. PD results support the mechanism of action of USP1 inhibition. The RDE will be determined in additional back-fill cohorts."
        },
        {
            "Title": "A phase 1 dose expansion study of a first-in-class KAT6 inhibitor (PF-07248144) in patients with advanced or metastatic ER+ HER2- breast cancer.",
            "Indication": "Advanced or metastatic ER+ HER2- breast cancer",
            "Drug": "PF-07248144, fulvestrant",
            "PFS": "Monotherapy: None; Combination: 10.7 months (95% CI 5.3, NE)",
            "OS": "None",
            "ORR": "Monotherapy: 11.4% (95% CI 3.2, 26.7); Combination: 30.2% (95% CI 17.2, 46.1)",
            "AE": "Most frequent TRAE was grade (G) 1/2 dysgeusia (84.6%; 65.4% G1). G3 TRAEs >5% were neutropenia (38.5%), leukopenia (11.5%), and anemia (9.0%). The only G4 TRAE was neutropenia (3.8%), which was reversible and well managed by dose modifications. No G5 TRAEs.",
            "Other": "CBR for monotherapy: 31.4% (95% CI 16.9, 49.3); Combination: 51.2% (95% CI 35.5, 66.7). Median duration of response (DOR) for monotherapy: 12.0 months (7.4, NE); Combination: 9.2 months (7.2, NE).",
            "Conclusion": "PF-07248144 demonstrated a tolerable safety profile and durable efficacy in pts with heavily pretreated ER+ HER2- mBC with and without ESR1 or PIK3CA/AKT1/PTEN mutations. We have provided strong clinical proof of concept targeting KAT6, a novel epigenetic target and opened a new avenue to treat ER+ HER2- mBC."
        },
        {
            "Title": "Pan-tumor activity of olomorasib (LY3537982), a second-generation KRAS G12C inhibitor (G12Ci), in patients with KRAS G12C-mutant advanced solid tumors.",
            "Indication": "KRAS G12C-mutant advanced solid tumors",
            "Drug": "Olomorasib (LY3537982)",
            "PFS": "CRC: 4 months (95% CI, 3-7); NSCLC: 9 months (95% CI, 3-NE); NSCLC with prior KRAS G12Ci treatment: 6 months (95% CI, 3-NE)",
            "OS": "None",
            "ORR": "CRC: 9% (3 PR); Non-CRC tumors: 40% (30 PR and 5 uPR pending/ongoing); NSCLC with prior KRAS G12Ci treatment: 39% (9 PR, 1 uPR pending/ongoing)",
            "AE": "Any grade TRAEs were 62%; TRAEs \u226510% were diarrhea (24%), fatigue (10%), and nausea (10%); grade \u22653 TRAEs were 5%. TRAEs led to dose hold in 10% of pts, dose reduction in 3%, and discontinuation in 2%.",
            "Other": "DCR for CRC: 84%; Non-CRC tumors: 90%; NSCLC with prior KRAS G12Ci treatment: 73%. ctDNA response (>50% KRAS G12C VAF reduction) was seen in pts with PR (11/11), SD (17/22), and PD (1/4).",
            "Conclusion": "Olomorasib demonstrates efficacy across a range of KRAS G12C-mutant solid tumors with a favorable safety profile including in pts with prior G12Ci intolerance. Activity of the second-generation G12Ci olomorasib after prior exposure to G12Ci demonstrates the increased potency and target coverage these agents can deliver compared to first-generation inhibitors. Phase 2 expansion is currently enrolling pts with PANC, and a global registrational study investigating olomorasib in combination with pembrolizumab in first-line NSCLC is ongoing."
        },
        {
            "Title": "Updated safety and efficacy data of combined KRAS G12C inhibitor (glecirasib, JAB-21822) and SHP2 inhibitor (JAB-3312) in patients with KRAS p.G12C mutated solid tumors.",
            "Indication": "KRAS p.G12C mutated solid tumors",
            "Drug": "Glecirasib (JAB-21822), SHP2 inhibitor (JAB-3312)",
            "PFS": "Median PFS not mature by the data cut-off date. The 6-month and 12-month PFS rate were 67.3% and 53.7%, respectively.",
            "OS": "None",
            "ORR": "72.5% (58/80); In the group of glecirasib 800mg QD + JAB-3312 2mg one week on/one week off: 77.8% (21/27)",
            "AE": "TRAE \u2265 grade 3 occurred in 41.9% of all pts. No treatment-related deaths. Discontinuation of either glecirasib or JAB-3312 due to TRAE occurred in 7.8% of all pts. The most common (>20%) TRAEs included anemia, ALT/AST increased, hypertriglyceridemia, bilirubin increased, neutropenia/leukopenia, creatine kinase increased, and edema.",
            "Other": "DCR: 96.3% (77/80); In the group of glecirasib 800mg QD + JAB-3312 2mg one week on/one week off: 92.6% (25/27).",
            "Conclusion": "Glecirasib plus JAB-3312 has a manageable safety profile and a promising ORR and PFS as a front-line treatment for patients with KRAS p.G12C NSCLC. This combination will be further evaluated in a randomized phase III trial in NSCLC."
        },
        {
            "Title": "Personalized cell-free tumor DNA analysis for patients with HNSCC: Liquid biopsy for minimal residual disease detection in head and neck squamous cell carcinoma (LIONESS)",
            "Indication": "Head and neck squamous cell carcinoma (HNSCC)",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "ctDNA detection in plasma and saliva; correlation with tumor volumes and clinical parameters",
            "Conclusion": "The use of ctDNA detection in surgically treated patients with HNSCC has significant potential to guide treatment decisions, improve disease outcome, and potentially spare patients unnecessary, partially invasive interventions during clinical follow-up."
        },
        {
            "Title": "Circulating tumor cells and tumor DNA in patients with resectable colorectal liver metastases: The MIRACLE",
            "Indication": "Resectable colorectal liver metastases (CRLM)",
            "Drug": "None",
            "PFS": "1-year RFS: 15% vs. 51% for CTCs; 1-year RFS: 20% vs. 54% for ctDNA",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Detection of CTCs and ctDNA before and after surgery; postoperative ctDNA and CTC detection as predictors for recurrence",
            "Conclusion": "Postoperative ctDNA and CTC detection are strong predictors for a shorter RFS after local treatment, as opposed to preoperative ctDNA or CTC detection."
        },
        {
            "Title": "Results of a phase 1/2 study of MHB088C: A novel B7H3 antibody-drug conjugate (ADC) incorporating a potent DNA topoisomerase I inhibitor in recurrent or metastatic solid tumors",
            "Indication": "Recurrent or metastatic solid tumors",
            "Drug": "MHB088C",
            "PFS": "None",
            "OS": "None",
            "ORR": "41.7% overall; 100.0% in SCLC subset",
            "AE": "Grade \u22653 TRAEs: neutrophil count decreased (33.3%), lymphocyte count decreased (30.0%), WBC count decreased (26.7%), platelet count decreased (23.3%), anemia (15.0%)",
            "Other": "No ILD reported; dose-limiting toxicities included platelet count decreased and febrile neutropenia at 4.0 mg/kg Q3W",
            "Conclusion": "MHB088C exhibited a manageable safety profile, with striking efficacy in SCLC patients. The dose optimization and expansion study is continuing to establish the RP2D for MHB088C."
        },
        {
            "Title": "9MW2821, an ectin-4 antibody-drug conjugate (ADC), in patients with advanced solid tumor: Results from a phase 1/2a study.",
            "Indication": "Advanced solid tumors including urothelial cancer (UC), cervical cancer (CC), esophageal cancer (EC), and triple-negative breast cancer (TNBC)",
            "Drug": "9MW2821",
            "PFS": "UC: 8.8 months (95% CI: 3.81-NR), CC: 4.0 months (95% CI: 3.75-5.68), EC: 3.7 months (95% CI: 1.94-NR), TNBC: 5.8 months (95% CI: 2.00-NR)",
            "OS": "None",
            "ORR": "UC: 62.2% (95% CI: 44.76-77.54), CC: 37.8% (95% CI: 23.77-53.46), EC: 29.6% (95% CI: 13.75-50.18), TNBC: 43.8% (95% CI: 19.75-70.12)",
            "AE": "Most common TRAEs (\u226520%, all grade/\u22655%, \u2265G3): WBC count decreased (50.8%, 23.3%), neutrophil count decreased (46.3%, 27.9%), anemia (43.8%, 8.3%), aspartate aminotransferase increased (42.1%, 2.9%), alanine aminotransferase increased (35.4%, 2.1%), asthenia (32.1%, 2.9%), rash (30.0%, 5.0%), decreased appetite (28.8%, 1.3%), nausea (26.7%, 0%), hyperglycemia (25.4%, 2.1%), platelet count decreased (24.2%, 4.6%), alopecia (24.2%, 0%), hypoaesthesia (22.5%, 1.7%), constipation (21.3%, 0%), vomiting (20.9%, 1.3%), hypertriglyceridemia (20.4%, 2.1%), gamma-glutamyltransferase increased (15.8%, 5.4%)",
            "Other": "DCR: UC: 91.9% (95% CI: 78.09-98.30), CC: 84.4% (95% CI: 70.54-93.51), EC: 74.1% (95% CI: 53.72-88.89), TNBC: 81.3% (95% CI: 54.35-95.95)",
            "Conclusion": "The data indicated encouraging efficacy of 9MW2821 in advanced UC, CC, EC, and TNBC. 9MW2821 is the first Nectin-4 targeting ADC showing antitumor activity in patients with CC, EC, and TNBC. The safety profile showed adequate tolerability."
        },
        {
            "Title": "Dendrimer-enhanced (DEP) SN38 (DEP irinotecan) in patients (pts) with advanced solid tumors: A phase 1/2 trial.",
            "Indication": "Advanced solid tumors including colorectal cancer (CRC), platinum-resistant high-grade serous ovarian cancer (HGSOC), and breast cancer (BC)",
            "Drug": "DEP SN38",
            "PFS": "None",
            "OS": "None",
            "ORR": "CRC monotherapy: 48% DCR, 5FU/LV combo: 85.7% DCR, 14.3% ORR; HGSOC monotherapy: 100% DCR, 33.3% ORR; BC monotherapy: 100% DCR",
            "AE": "Most common TRAEs (\u226510%): neutropenia, thrombocytopenia, anemia, fatigue, nausea, vomiting, diarrhea, constipation, alopecia. Severe TRAEs: G3 diarrhea (0.9%), G3 nausea (1.8%), G3 vomiting (0.9%), febrile neutropenia at 15 mg/m2 Q2W monotherapy (2/6 pts)",
            "Other": "Reduced CA-125 of up to 98% observed in 75% of HGSOC pts. Several HGSOC pts continue DEP SN38 treatment.",
            "Conclusion": "DEP SN38 shows promising clinical utility with encouraging antitumor activity including prolonged disease control and durable PRs in heavily pre-treated CRC, HGSOC, and BC pts. DEP SN38 is well-tolerated with significantly fewer severe gastrointestinal TRAEs compared to conventional irinotecan (c-IRI), and warrants further clinical assessment."
        },
        {
            "Title": "ReNeu: A pivotal phase 2b trial of mirdametinib in children and adults with neurofibromatosis type 1 (NF1)-associated symptomatic inoperable plexiform neurofibroma (PN).",
            "Indication": "Neurofibromatosis type 1 (NF1)-associated symptomatic inoperable plexiform neurofibroma (PN)",
            "Drug": "Mirdametinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "Adults: 41% (95% CI: 29-55), Pediatrics: 52% (95% CI: 38-65)",
            "AE": "Common TRAEs: acneiform dermatitis, diarrhea, nausea, vomiting. Severe TRAEs: 16% adults and 25% pediatrics had grade \u22653 treatment-related AEs. 22% adults and 9% pediatrics discontinued due to TEAEs.",
            "Other": "Median treatment duration: 22 months for each cohort. Median DoR not reached. Median TTR: 7.8 months in adults, 7.9 months in pediatrics. Statistically significant improvements in pain severity, pain interference, and HRQoL.",
            "Conclusion": "Mirdametinib demonstrated a statistically significant ORR by BICR, with deep and durable PN volume reductions, significant improvements in pain severity, pain interference, and HRQoL, and a manageable safety profile in both adults and children. Together with a dispersible tablet formulation, these results underscore mirdametinib\u2019s potential to become an important new treatment option for NF1 PN pts across all ages."
        },
        {
            "Title": "Association between stromal tumor-infiltrating lymphocytes (TILs) and pathologic complete response (pCR) in patients with early breast cancer (BC) treated with neoadjuvant chemotherapy and HER2-directed therapies in NSABP B-41.",
            "Indication": "Early breast cancer",
            "Drug": "Trastuzumab (T), Lapatinib (L), or both (TL)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "No association between TIL group and pCR irrespective of treatment group. Among tumors with high TILs, T or TL had higher pCR than L (61.2% vs 40.4%; p=0.04).",
            "Conclusion": "Patients with a high TIL percentage treated with T or TL compared to L were more likely to achieve a pCR. The association between TILs and pCR varied across ER status."
        },
        {
            "Title": "A phase I study of highly potent oral ATR inhibitor (ATRi) tuvusertib plus oral PARP inhibitor (PARPi) niraparib in patients with solid tumors.",
            "Indication": "Solid tumors",
            "Drug": "Tuvusertib plus Niraparib",
            "PFS": "None",
            "OS": "None",
            "ORR": "5 (15.6%) responses (3 confirmed) by RECIST v1.1 in 32 evaluable patients",
            "AE": "Grade 3 anemia (10.5%), anemia (41.9%), platelet count decrease (14.0%), fatigue (9.3%)",
            "Other": "Dose-dependent \u03b3-H2AX inhibition observed at tuvusertib doses \u2265130mg QD. Tuvusertib 180mg QD and niraparib 100mg QD or tuvusertib 90mg QD and niraparib 200mg QD identified as RDEs.",
            "Conclusion": "The combination of tuvusertib plus niraparib has a manageable safety profile and is suitable for further investigation."
        },
        {
            "Title": "First-in-human phase I clinical trial of RSO-021, a first-in-class covalent inhibitor of mitochondrial peroxiredoxin 3 (PRX3), in patients with malignant pleural effusion due to mesothelioma and other advanced solid tumors (MITOPE).",
            "Indication": "Malignant pleural effusion due to mesothelioma and other advanced solid tumors",
            "Drug": "RSO-021",
            "PFS": "Median PFS at MTD was 5.7 months (95% CI, 1.4-NR)",
            "OS": "None",
            "ORR": "Partial response at 90mg/wk was a 59% reduction in mesothelioma target lesions",
            "AE": "Fatigue (33%), pyrexia (20%), elevated creatinine (20%), G3 dyspnea and G3 acute inflammatory response at 120mg/wk, G3 dyspnea at 180mg/wk",
            "Other": "Pro-inflammatory cytokines in pleural fluid/serum correlated with AEs suggestive of local inflammation. Disease control was 70% in 10 evaluable patients.",
            "Conclusion": "Weekly intrapleural RSO-021 was safe with signs of anti-tumor activity in patients with pleural mesothelioma. Phase 2 exploration of this novel agent is ongoing."
        },
        {
            "Title": "Safety and efficacy of biosimilar TDM-1: A real world retrospective study.",
            "Indication": "HER 2 Positive Breast cancer",
            "Drug": "Biosimilar TDM-1",
            "PFS": "8 months (5.7\u201310.2m)",
            "OS": "18 months (16.1-19.9m)",
            "ORR": "50.8%",
            "AE": "Fatigue (common), 21.7% required romiplostim for therapy-related thrombocytopenia, no grade \u22653 adverse effects",
            "Other": "Clinical benefit rate (CR+PR+SD) was 75%, median duration of response was 5 months",
            "Conclusion": "The availability of biosimilar TDM1 increased the reach to deserving patients. No new adverse events were identified. The safety and efficacy of the biosimilar TDM1 were comparable."
        },
        {
            "Title": "A phase 1a/b, multi-regional, first-in-human study of CS5001, a novel anti-ROR1 ADC, in patients with advanced solid tumors and lymphomas.",
            "Indication": "Advanced solid tumors and lymphomas",
            "Drug": "CS5001",
            "PFS": "None",
            "OS": "None",
            "ORR": "Objective responses in patients at dose level 5 or above, including 2 complete responses (1 Hodgkin lymphoma and 1 diffuse large B-cell lymphoma) and 3 partial responses (2 HL and 1 pancreatic cancer)",
            "AE": "59.2% experienced treatment-related adverse events (TRAEs), most common was fatigue (16.3%). Grade 3 TRAEs in 14.3%, including fatigue, gamma-glutamyltransferase increased, and pneumonia (4.1% each). No grade 4-5 TRAEs reported.",
            "Other": "Stable diseases observed in 3 patients (2 colorectal cancer and 1 renal cancer). Dose escalation is still ongoing.",
            "Conclusion": "CS5001 was well tolerated with no DLT identified, PK characteristics were as expected, and encouraging antitumor activities were observed in various advanced solid tumors and lymphomas. Current data support continued evaluations for the recommended phase 2 dose and subsequent phase 1b dose expansion."
        },
        {
            "Title": "Phase Ia/Ib trial on the safety and efficacy of mobocertinib in combination with T-DM1 for patients with HER2-mutant solid tumors (WJOG16022M).",
            "Indication": "HER2-mutant solid tumors",
            "Drug": "Mobocertinib and T-DM1",
            "PFS": "3.2 months (95% CI 1.6, 6.1) in phase Ib; 4.3 months (95% CI 2.7, 6.7) across all cohorts; 6.1 months (95% CI 2.9, 6.3) in HER2-mutant NSCLC",
            "OS": "None",
            "ORR": "28.6% in phase Ib; 42.3% across all cohorts; 53.8% in HER2-mutant NSCLC",
            "AE": "Grade \u22653 AE occurred in 72.7% of patients; common events (>5%) were thrombocytopenia (50.0%), diarrhea (13.6%), and anorexia (13.6%). No interstitial lung disease/pneumonitis observed.",
            "Other": "Disease control rate (DCR) was 71.4% in phase Ib; 76.9% across all cohorts; 84.6% in HER2-mutant NSCLC",
            "Conclusion": "The RD of this study treatment is mobocertinib 80 mg plus T-DM1 3.6 mg. Combination therapy of HER2 TKI and HER2 ADC demonstrated feasibility and potential efficacy for HER2-mutant solid tumors."
        },
        {
            "Title": "The anti-CD20 antibody-drug conjugates TRS005 in relapse/refractory CD20-positive B-cell non-Hodgkin lymphoma: A multicenter, open-label, single-arm, phase I study.",
            "Indication": "Relapse/refractory CD20-positive B-cell non-Hodgkin lymphoma",
            "Drug": "TRS005",
            "PFS": "5.6 months (95% CI: 4.2-17.4), 12-month PFS: 35.1%",
            "OS": "None",
            "ORR": "46.6%, highest ORR in 1.8 mg/kg dose cohort: 56.7%",
            "AE": "88.6% experienced all grades AEs, 65.7% had grade \u22653 TEAEs",
            "Other": "DCR: 75.9%, highest DCR in 1.8 mg/kg dose cohort: 86.7%",
            "Conclusion": "TRS005 is well-tolerated and exhibits encouraging anti-tumor efficacy in the treatment of R/R DLBCL."
        },
        {
            "Title": "Phase 1 study (NCT04931823) of CPO-100 (albumin-bound docetaxel) in patients with advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "CPO-100 (albumin-bound docetaxel)",
            "PFS": "None",
            "OS": "None",
            "ORR": "7% (2/30) with two PRs observed in patients with breast and nasopharyngeal cancers",
            "AE": "Most common AEs: fatigue (N=19), nausea (N=18), anemia (N=15). Grade 3/4 neutropenia for doses above 35 mg/m2: 60%.",
            "Other": "DCR: 60%. Median duration of response: 10.9 months (range 1.7 to 11.1 months).",
            "Conclusion": "Preliminary data with CPO-100 administered on a weekly basis shows potential efficacy in heavily pretreated patients, especially with prior taxane exposure, with a tolerable weekly schedule."
        },
        {
            "Title": "A phase 1/2 study of LM-302, an anti-claudin18.2 (CLDN18.2) antibody-drug conjugate in patients with advanced gastric/gastroesophageal junction cancer.",
            "Indication": "Advanced gastric/gastroesophageal junction cancer",
            "Drug": "LM-302",
            "PFS": "7.16 months (95% CI 2.72-NA)",
            "OS": "Not reached, OS rate of 95.0% at 6 months",
            "ORR": "30.6% (11/36)",
            "AE": "Most common TRAEs: white blood cell decreased (51.9%), neutrophil count decreased (51.1%), anaemia (38.5%), vomiting (36.3%), nausea (34.1%). Most frequent grade \u22653 TRAEs: neutrophil count decreased (22.2%), white blood cell decreased (17.8%).",
            "Other": "DCR: 75.0% (27/36)",
            "Conclusion": "LM-302 was well-tolerated with a manageable safety profile and demonstrated promising anti-tumor activity in CLDN18.2-positive patients with third-line and beyond gastric/GEJ cancer."
        },
        {
            "Title": "Pooled safety analysis of sacituzumab govitecan (SG) in multiple solid tumor types.",
            "Indication": "Multiple solid tumors",
            "Drug": "Sacituzumab govitecan (SG)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "99% experienced any-grade TEAEs, 76% experienced grade \u22653 TEAEs. Most common any-grade TEAEs: nausea (64%), diarrhea (64%), neutropenia (61%), fatigue (51%). Most common grade \u22653 TEAEs: neutropenia (46%), anemia (12%), leukopenia (11%), diarrhea (11%). Febrile neutropenia: 6%. TEAEs led to SG dose reduction in 31% and SG discontinuation in 7% of patients.",
            "Other": "UGT1A1 *28/*28 genotype associated with higher rates of grade \u22653 TEAEs and TEAEs leading to dose reduction and SG interruption compared to *1/*1 and *1/*28 genotypes.",
            "Conclusion": "The results of the pooled safety analysis of patients treated with SG were consistent with previous clinical trials, with neutropenia remaining the most common grade \u22653 TEAE. Neutropenia and diarrhea were generally manageable, and the rate of TEAEs leading to discontinuation was low."
        },
        {
            "Title": "Dose optimization and exposure-response analyses to support optimal dose of ABBV-400, a novel C-met\u2013targeting antibody drug conjugate, in patients with metastatic colorectal cancer (mCRC)",
            "Indication": "metastatic colorectal cancer (mCRC)",
            "Drug": "ABBV-400",
            "PFS": "None",
            "OS": "None",
            "ORR": "Higher exposure correlated with higher probability of response (n=122 CRC, ORR, p<0.05)",
            "AE": "Grade \u22653 neutropenia, anemia, and thrombocytopenia (p<0.001)",
            "Other": "Population PK, E-R for efficacy and safety, and RDI analyses supported the selection of 2.4 mg/kg Q3W as optimal ABBV-400 monotherapy dose for further study in mCRC patients.",
            "Conclusion": "Population PK, E-R for efficacy and safety, and RDI analyses and totality of clinical data supported the selection of 2.4 mg/kg Q3W as optimal ABBV-400 monotherapy dose for further study in mCRC patients."
        },
        {
            "Title": "Evaluation of the safety, pharmacokinetics, and efficacy of JSKN003 in patients with advanced solid tumors: A phase I/II clinical study",
            "Indication": "advanced solid tumors",
            "Drug": "JSKN003",
            "PFS": "None",
            "OS": "None",
            "ORR": "Overall ORR was 51.4% (95% CI: 34.4, 68.1)",
            "AE": "Treatment-related adverse events (TRAEs) occurred in 44 pts (95.7%), common TRAEs were diarrhea (37.0%) and nausea (32.6%). Grade \u22653 TRAEs were lymphopenia (4.3%) and neutropenia (4.3%).",
            "Other": "MTD of JSKN003 was not reached yet. Safety of JSKN003 was extremely excellent with encouraging preliminary antitumor activity in heavily pretreated pts with advanced solid tumors.",
            "Conclusion": "MTD of JSKN003 was not reached yet. Safety of JSKN003 was extremely excellent with encouraging preliminary antitumor activity in heavily pretreated pts with advanced solid tumors."
        },
        {
            "Title": "An open-label, multicenter, phase I study of ATG-022 in patients with advanced/metastatic solid tumors (CLINCH)",
            "Indication": "advanced/metastatic solid tumors",
            "Drug": "ATG-022",
            "PFS": "None",
            "OS": "None",
            "ORR": "One PR was observed in a gastric cancer pt at the dosage of 1.8 mg/kg. A CR was seen in a gastric cancer pt dosed at 2.4 mg/kg.",
            "AE": "Most common grade \u22653 TRAEs included nausea (30%), vomiting (30%), and decreased appetite (30%).",
            "Other": "ATG-022 demonstrated preliminary anti-tumor activity, tolerability, safety, as well as comparable PK properties at current dose levels.",
            "Conclusion": "ATG-022 demonstrated preliminary anti-tumor activity, tolerability, safety, as well as comparable PK properties at current dose levels. The high affinity of sub-nM grade of ATG-022 in pts with low CLDN18.2 expression needs further investigation."
        },
        {
            "Title": "YL202/BNT326, a HER3-targeted ADC, in patients with locally advanced or metastatic non-small cell lung cancer and breast cancer: Preliminary results from a first-in-human phase I trial",
            "Indication": "locally advanced or metastatic non-small cell lung cancer and breast cancer",
            "Drug": "YL202/BNT326",
            "PFS": "Median PFS: 6-month PFS rate 61.6% (95% CI: 38.0, 78.5)",
            "OS": "None",
            "ORR": "ORR was 41.0% (95% CI, 25.6, 57.9) across all tumor types; ORR in BC pts was 54.5% (95% CI, 23.4, 83.3)",
            "AE": "Most common TRAEs (>20%, all grade/\u2265G3) were anemia (71%/20%), white blood cell count decreased (67%/31%), neutrophil count decreased (63%/29%), nausea (52%/0%), decreased appetite (42%/4%), lymphocyte count decreased (37%/23%), platelet count decreased (37%/10%), vomiting (37%/0%), dry mouth (25%/0%), fatigue (25%/0%), stomatitis (23%/2%), alopecia (21%/0%).",
            "Other": "PK exposure was increased through dose escalation, with low systemic exposure of payload and no accumulation of YL202/BTN326 upon repeated administration.",
            "Conclusion": "YL202/BNT326 demonstrated encouraging efficacy in heavily pretreated locally advanced/metastatic NSCLC and BC. The safety profile showed adequate safety and tolerability."
        },
        {
            "Title": "The design, preclinical study and phase I dose escalation plan of a HER2 targeted immunoliposome (HF-K1) for HER2 low solid tumor treatment.",
            "Indication": "HER2 low solid tumors",
            "Drug": "HF-K1",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Preclinical data showed significant tumor growth inhibition (95%) at the equivalent antibody dose of 3.6 mg/kg in various mouse tumor models including HER2 low and HER2 very low (HER2-).",
            "Conclusion": "The encouraging preclinical data supported a clinical trial starting with dose escalation at equivalent antibody doses of 0.72 mg/m2, 2.16 mg/m2, 5.4 mg/m2, 10.8 mg/m2, 16.2 mg/m2, and 21.6 mg/m2. The study is ongoing to determine the safety, tolerability, PK, and preliminary anti-tumor efficacy in participants with HER2 positive or HER2 low expression advanced solid tumors."
        },
        {
            "Title": "Safety and efficacy of IBI343 (anti-claudin18.2 antibody-drug conjugate) in patients with advanced pancreatic ductal adenocarcinoma or biliary tract cancer: Preliminary results from a phase 1 study.",
            "Indication": "Advanced pancreatic ductal adenocarcinoma (PDAC) or biliary tract cancer (BTC)",
            "Drug": "IBI343",
            "PFS": "Immature",
            "OS": "None",
            "ORR": "Overall: 28.0% (95% CI: 12.1-49.4); CLDN18.2 expression \u226560%: 38.5% (95% CI: 13.9-68.4); PDAC subgroup: 40% (95% CI: 12.2-73.8)",
            "AE": "80.0% of patients experienced treatment-related adverse events (TRAEs), including grade \u22653 TRAEs in 25.7% of patients. Common TRAEs (\u226520%) were anemia (42.9%), neutrophil count decreased (28.6%), nausea (25.7%), vomiting (25.7%), and white blood cell count decreased (22.9%). Serious TRAEs occurred in 11.4% of patients.",
            "Other": "Disease control rate (DCR): Overall: 80.0% (95% CI: 59.3-93.2); CLDN18.2 expression \u226560%: 84.6% (95% CI: 54.6-98.1).",
            "Conclusion": "IBI343 was well tolerated with favorable safety profiles and encouraging efficacy in CLDN18.2-positive PDAC and BTC."
        },
        {
            "Title": "Safety and efficacy of JSKN003 in patients with advanced/metastatic solid tumors: A first-in-human, dose-escalation, multicenter, open-label, phase I study.",
            "Indication": "Advanced/metastatic solid tumors",
            "Drug": "JSKN003",
            "PFS": "None",
            "OS": "None",
            "ORR": "Overall: 50.0% (95% CI: 31.9%, 68.1%); IHC1+: 55.6% (5/9); IHC2+: 37.5% (6/16); IHC3+: 71.4% (5/7); HER2-positive breast cancer: 80% (4/5); HER2-low breast cancer: 40.0% (4/10)",
            "AE": "84.4% of patients experienced TRAEs. Common TRAEs were diarrhea (62.5%) and nausea (50.0%), all grade 1-2. Grade 3 TRAEs included anemia and fatigue in 6.3% of patients. One patient experienced ILD/pneumonia (grade 2). No DLT events or TRAEs leading to death or treatment discontinuation were found.",
            "Other": "None",
            "Conclusion": "JSKN003 was well tolerated with encouraging preliminary antitumor activity in heavily pretreated patients with advanced/metastatic solid tumors, especially in patients with HER2 expressed tumors. As of the cut-off date, no DLT was observed, and MTD has not been reached yet."
        },
        {
            "Title": "Association of KRAS G12D vs. G12V circulating tumor DNA variant allele fraction and real-world overall survival in metastatic non-small cell lung and colorectal cancers.",
            "Indication": "metastatic colorectal cancer (mCRC) and non-small cell lung cancer (mNSCLC)",
            "Drug": "Not specified",
            "PFS": "None",
            "OS": "For mCRC, KRAS VAF above median was significantly associated with shorter rwOS for both G12D and G12V. For mNSCLC, KRAS VAF above median was associated with shorter rwOS for G12V, but not G12D.",
            "ORR": "None",
            "AE": "None",
            "Other": "Subgroup analysis by first-line treatment type revealed this association to be similar for chemotherapy-containing regimens but not immunotherapy (IO) alone.",
            "Conclusion": "While a variant-specific association of KRAS VAF with rwOS was observed in mNSCLC overall (G12V associated, G12D not), above vs below median VAF was not associated with rwOS for the subset of patients receiving IO only. In contrast, for mCRC both G12D and G12V VAF were associated with rwOS, including for the subset of patients who received chemotherapy. These data suggest a complex interplay between KRAS variant, ctDNA shedding, first-line treatment, and OS that will be important in the interpretation of the prognostic implications of ctDNA-identified KRAS alterations."
        },
        {
            "Title": "Early clues in the battle against advanced gastric cancer: How plasma ctDNA signals the effectiveness of PD-1 inhibitors with chemotherapy.",
            "Indication": "advanced or metastatic gastric cancer (GC)/gastroesophageal junction cancer (GEJC)",
            "Drug": "PD-1 inhibitors (sintilimab/nivolumab) combined with chemotherapy (SOX/XELOX)",
            "PFS": "Patients with ctDNA response had a significantly longer progression-free survival (PFS) compared to patients without ctDNA response (median PFS 15.6 months vs 6.0 months; HR, 0.18; 95% CI, 0.06 to 0.61; p=0.003).",
            "OS": "Overall survival (OS) was also significantly longer among ctDNA responders (median OS not reached [NR] vs 9.0 months; HR, 0.10; 95% CI, 0.01 to 0.88; p=0.011).",
            "ORR": "The objective response rate (ORR) among ctDNA responders was 85%, markedly higher compared to the 22% observed in non-responders (P=0.0073).",
            "AE": "None",
            "Other": "The dynamic changes in maxVAF demonstrated a significant association with patients attaining either complete response (CR) or partial response (PR) to treatment (P=0.0021), but showed no correlation with stable disease (SD) or progressive disease (PD). A substantial agreement was observed between ctDNA response and the best tumor shrinkage rate (Cohen\u2019s kappa=0.69).",
            "Conclusion": "ctDNA response serves as a potential biomarker for assessing treatment efficacy and long-term outcomes in advanced gastric cancer patients treated with first-line PD-1 inhibitors and chemotherapy."
        },
        {
            "Title": "Presence of molecular residual disease after pathologic complete response among patients with inflammatory breast cancer.",
            "Indication": "Inflammatory breast cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "5-year OS: 35.0% (0.0-0.70) for pCR, +CTC; 83.1% for pCR, -CTC",
            "ORR": "None",
            "AE": "None",
            "Other": "CTC positivity associated with poor prognosis despite pCR",
            "Conclusion": "A high proportion of IBC patients who achieved pCR had MRD, as demonstrated by persistent CTCs. CTCs portended worse prognosis in patients with IBC, even after pCR. Future incorporation of longitudinal CTC monitoring may be helpful in identifying patients at risk for relapse despite having achieved a pCR."
        },
        {
            "Title": "Relationship between dynamic changes in circulating tumor fraction and real-world imaging with real-world survival in patients with solid tumors treated with immunotherapy.",
            "Indication": "Solid tumors",
            "Drug": "Immune checkpoint inhibitors (ICI)",
            "PFS": "None",
            "OS": "MRs achieved significantly longer rwOS than nMRs (HR=0.33 [0.13, 0.84], p=0.02)",
            "ORR": "None",
            "AE": "None",
            "Other": "Concordance of response markers shown in the table. For patients with nMR and CR/PR/SD predicted median survival was 10 months whereas for patients with MR and CR/PR/SD predicted median survival was 16 months.",
            "Conclusion": "MRs achieved significantly longer rwOS than nMRs and provides significant power in predicting rwOS beyond rw-imaging response. xM used for TRM can help identify nMRs who can discontinue therapy and be spared ICI toxicity."
        },
        {
            "Title": "Classification of HER2 status across multiple cancers using epigenomic profiles from a novel liquid biopsy assay.",
            "Indication": "Multiple cancers (breast, gastro-esophageal, ovarian)",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "HER2 classification of BC pts by epigenomic liquid biopsy was concordant with standard tissue-based IHC for 64/72 (89%) BC samples (AUC 0.9). Accurate classification of 11/14 (79%, GEA) and 4/4 (100%, OV) pts was achieved using the indication-refined HER2 classifier.",
            "Conclusion": "We demonstrate proof of concept for a HER2 classification approach using comprehensive epigenomic signals from 1 ml of plasma that could be applied across multiple cancers. With further development, our genome-wide profiling approach could alleviate clinical constraints associated with multiple tissue-based HER2 scoring assays (IHC/ISH) and enable longitudinal monitoring of HER2 status on therapy."
        },
        {
            "Title": "Using cell-free RNA in monitoring immune system and the demonstration of significant systemic deficiency in lymphoid and myeloid biomarkers in patients with cancer.",
            "Indication": "Various types of solid tumors",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Significant difference (P<0.0001) between normal individuals and patients with cancers in levels of circulating biomarkers specific for immune cells. Patients with cancer showed deficiency in overall systemic immune elements.",
            "Conclusion": "Immune related biomarkers using cfRNA provide important information on the immune system that can be used to monitor patients and to predict the presence of cancer or CHIP. The demonstration that patients with cancer have deficiency in overall systemic immune elements suggests that further studies are needed in monitoring the immune system and exploring means to boost systemic immunity to prevent the development of overt cancers."
        },
        {
            "Title": "ctDNA as a biomarker in phase II study of tepotinib in advanced solid cancers with MET exon 14 skipping mutation or amplification (KCSG AL19-17)",
            "Indication": "Advanced solid cancers with MET exon 14 skipping mutation or amplification",
            "Drug": "Tepotinib",
            "PFS": "PM+ group: 6.0 months (95% CI 3.6, 8.3), PM- group: 11.0 months (95% CI 8.1, 13.9)",
            "OS": "PM+ group: 14.0 months (95% CI 4.7, 23.3), PM- group: Not Reached",
            "ORR": "PM+ group: 81.2%, PM- group: 30.0%",
            "AE": "None",
            "Other": "Molecular responder (MR) group: ORR 91.7%, PFS 6.0 months (95% CI 0.0, 14.5), OS Not Reached; Molecular non-responder (MNR) group: ORR 33.3%, PFS 3.0 months (95% CI 1.4, 4.6), OS 4.0 months (95% CI 2.4, 5.6)",
            "Conclusion": "In the liquid biomarker analysis in the MET-dysregulated cancer patients who were treated with tepotinib, the presence of ctDNA MET alteration at baseline was associated with a higher response rate but shorter PFS and OS. The molecular response was well correlated with the radiological response and associated with better outcomes."
        },
        {
            "Title": "Polyploid circulating stromal cells in blood to identify invasive solid tumors and to prognosticate for highly aggressive cancer subtypes.",
            "Indication": "Invasive solid tumors",
            "Drug": "None",
            "PFS": "CAML presence was significantly associated with worse PFS (HR=2.0, 95% CI 1.4-2.9, p<0.0001) & OS (HR=2.2, 95% CI 1.4-3.3, p=0.0004), with engorged phagocytic CAMLs (>50um), highly significant for worse PFS (HR=3.3, 95% CI 2.5-4.5, p<0.0001) & OS (HR=3.0, 95% CI 2.1-4.3, p<0.0001).",
            "OS": "CAML presence was significantly associated with worse PFS (HR=2.0, 95% CI 1.4-2.9, p<0.0001) & OS (HR=2.2, 95% CI 1.4-3.3, p=0.0004), with engorged phagocytic CAMLs (>50um), highly significant for worse PFS (HR=3.3, 95% CI 2.5-4.5, p<0.0001) & OS (HR=3.0, 95% CI 2.1-4.3, p<0.0001).",
            "ORR": "None",
            "AE": "None",
            "Other": "CAML sensitivity in cancer vs healthy was 82% (CI 95% 78-85%), specificity = 100% (CI 95% 96-100%), PPV = 100% (CI 95% 99-100%), NPV = 55% (CI 95% 50-60%). CAML sensitivity in cancer vs benign was 82% (CI 95% 78-85%), specificity = 64% (CI 95% 32-44%), PPV = 93% (CI 95% 90-95%), NPV = 38% (CI 95% 32-44%).",
            "Conclusion": "CAMLs appear to be a highly sensitive blood-based biomarker that can identify invasive carcinoma in all stages of cancer regardless of subtype, but are not found in healthy controls & rare in non-malignant conditions. Further, enlarged phagocytic CAMLs appeared to correlate with shorter PFS and OS prior to treatment induction."
        },
        {
            "Title": "Enhancing cancer detection through AI-driven high content analysis of circulating tumor cells.",
            "Indication": "Solid organ cancers",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The automated deep learning model achieved remarkable performance metrics, presenting an accuracy of 96.66% (95% CI, 96.07%-97.19%), a specificity of 95.34% (95% CI, 94.37%-96.18%), and a sensitivity of 98.14% (95% CI, 97.44%-98.69%) in detecting true CTCs.",
            "Conclusion": "This study successfully evaluated a deep learning model for the detection of circulating tumor cells (CTCs) in peripheral blood mononuclear cells (PBMCs) using image data from HTS platform. The model achieved superior performance, demonstrating its potential for cancer detection from blood samples."
        },
        {
            "Title": "Clinical impact of molecular profiling in the national prospective cohort Solving Unknown Primary Cancer (SUPER) study.",
            "Indication": "Cancer of unknown primary (CUP)",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Clinically actionable genomic abnormalities were detected in 95/331 (29%) pts. Moderate or high match result on TOO assay were consistent with a suspected or later confirmed TOO as reported by clinicians in 81/195 (42%) pts in phase 1+2. Germline pathogenic mutations were detected in 34/331 (10%) pts. Molecular tests confirmed clinician\u2019s treatment (started while awaiting results) was consistent with the most likely TOO in 170/331 (51%) pts. Clinicians reported that molecular tests resulted in a change in management in 11/118 pts (9%) in phase 1 and 40/213 (19%) pts in phases 2+3, with 17/51 (33%) pts changed treatment based on potential TOO determination. 11/51 (22%) pts could access treatment via standard pathways given a more specific tumour-type diagnosis and 15/51 (29%) pts were potentially eligible for clinical trials; however, only 7 pts were well enough for referral.",
            "Conclusion": "The clinical impact of molecular testing in CUP improved over time as testing became more sophisticated and turnaround times improved. Clinicians suspected TOO pre-profiling was consistent with molecular results in half of the pts. Routine access to novel therapies in CUP remains a challenge."
        },
        {
            "Title": "CUPCOMP: A multi-site UK trial in carcinoma of unknown primary: A comparison across tissue and liquid biomarkers.",
            "Indication": "Carcinoma of Unknown Primary (CUP)",
            "Drug": "Not specified",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Success rate of tissue-based molecular profiling: 59%, ctDNA analysis success rate: 93%, Concordance of SNVs between tissue and ctDNA: 77%, Tumor mutational burden (TMB) \u2265 10 mutations/Mb in 22% of patients, Potential germline variants in 8% of patients, 70% of patients alive 6 months after consent.",
            "Conclusion": "Successful tissue-based molecular profiling is challenging in patients with CUP, achieved in only 59% of patients in this cohort. ctDNA analysis was feasible (93% successful) and reliably concordant with tissue. Potentially actionable alterations were found in 64% of patients. ctDNA molecular profiling should be considered as a reliable alternative to tissue-based testing in patients diagnosed with CUP."
        },
        {
            "Title": "A highly sensitive, low input, and automated assay for molecular residual disease detection (MRD) using whole-genome sequencing (WGS).",
            "Indication": "Molecular Residual Disease (MRD) detection in solid tumors",
            "Drug": "Not applicable",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Limit of detection down to 0.001% Variant Allele Frequency (VAF) for 10 parts per million (PPM) in multiple cancer types, Analytical specificity of 99.7%, High reproducibility in MRD detection, Optimal input amount of 5ng and minimum input amount down to 2ng, Turn-around time (TAT) of 5-7 days for fingerprinting and ctDNA detection steps.",
            "Conclusion": "The TSO MRD assay demonstrated high analytical sensitivity and specificity in detecting the presence of ctDNA from low input plasma samples with automated workflow and fast TAT. This assay enables a single streamlined solid tumor MRD platform for multiple cancer types."
        },
        {
            "Title": "RNA-based fusion testing in gastrointestinal (GI) carcinomas: A single-institution experience.",
            "Indication": "Gastrointestinal (GI) carcinomas",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Fusions of known clinical relevance were evaluated for molecular and histopathologic associations. BTCs had the highest incidence of fusions (13.7%), 80% of which were FGFR2. Fusions were detected in 3.9-7.7% of colorectal (CRC), pancreatic ductal (PDAC), esophagogastric (EGC), and small bowel (SBC) carcinomas. Several detected fusions had novel partners that were nonetheless predicted actionable.",
            "Conclusion": "Although individually rare, in aggregate RNA-based testing identifies clinically relevant fusions in a small but significant fraction of BTC, CRC, PDAC, EGC, and SBC. Molecular selection may identify subpopulations in which fusion testing is higher yield. CRC and SBC harboring RSPO3 fusions have a distinct molecular and pathologic phenotype, including a potentially novel association with BRCA1/2 mutations."
        },
        {
            "Title": "Association of complementing circulating tumor DNA and circulating tumor cells load on stable and progressive disease in treated patients.",
            "Indication": "Various cancers (lung, breast, colon, HNC)",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "From ctDNA, 47.82% (n=22) of patients identified with at least 1 actionable genomic finding. 13.04% (n=6) patients showed EGFR driver mutations. Also, 19.56% (n=9) patients were identified with either EGFR driver/KRAS/PI3K passenger mutations with 4.34% (n=2) identified with ALK-EML4 fusion. The average ctDNA load obtained in patients with progressive disease (n=26) was 8.2 molecules per 1 mL of plasma. At least 1 CTC was detected in 61.53% (n=16) of progressive disease patients with the highest of 4 CTCs identified in 7.69% (n=2) of patients.",
            "Conclusion": "We observed ctDNA and CTCs complementing MRD status, even post curative-intent surgery and therapy with prophecy for such patients likely to progress. Our findings are strongly indicative of positive correlation between ctDNA load, number of CTC detected, and disease progression from radiological findings for practical and clinical decisions."
        },
        {
            "Title": "Analytical validation of the Labcorp Plasma Complete test to enable precision oncology through solid tumor liquid biopsy comprehensive genomic profiling.",
            "Indication": "Advanced and metastatic solid cancers",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The test yielded results for 98.3% (620/631) of cases and identified variants in 94.7% (587/620) with a median of 6 variants per case. Clinically actionable variants were detected in 42.1% of clinical samples. Analytical specificity and sensitivity (limit of detection, 95%) was verified for each variant type, with a median variant allele frequency (VAF) of 1.42% for SNVs and 1.43% for indels, 1.7-fold for amplifications, 0.35% fusion read fraction for translocations, and 0.82% VAF for MSI.",
            "Conclusion": "The analytical validation of the Labcorp Plasma Complete test demonstrates that the liquid biopsy approach is highly sensitive, specific, accurate, reproducible, and robust for comprehensive genomic profiling to complement tissue-based testing and inform clinical decision making."
        },
        {
            "Title": "Non-invasive colorectal cancer detection using multimodal deep learning ensemble classifier",
            "Indication": "Colorectal Cancer (CRC)",
            "Drug": "Not specified",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Sensitivity for CRC: 84.0% (95% CI: 81%-86%) in the development set, 80.2% (95% CI: 72%-88%) in the validation set; Sensitivity for AA: 69.9% (95% CI: 66%-81%) in the development set, 63.0% (95% CI: 44%-82%) in the validation set; Specificity: 90.0% in the development set, 84.9% (95% CI: 82%-88%) in the validation set; Sensitivities across stages in the validation set: Stage I: 74%, Stage II: 83%, Stage III: 79%, Stage IV: 92%",
            "Conclusion": "Our multimodal deep learning model shows promise for accurate non-invasive colon cancer and AA detection using low-coverage cfDNA WGS. Its strong performance in detecting not only CRC but also AA lesions suggests its potential for early intervention and improved patient outcomes."
        },
        {
            "Title": "Nature and distribution of methyl thioadenosine phosphorylase (MTAP) genomic loss in human tumors",
            "Indication": "Various cancers including glioblastoma, non-small cell lung cancer, pancreatic ductal carcinoma, and bladder urothelial carcinoma",
            "Drug": "PRMT5 and MAT2A inhibitors",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "MTAP genomic alterations found in 8.6% of total samples; Complete loss in 63.7% of cases, partial loss in 36.3% of cases; Frequent co-altered genes include CDKN2A (99.7%), CDKN2B (95.1%), TP53 (55.4%), KRAS (30.1%), TERT (20.5%), and PIK3CA (12%); MSI-high status in 0.3% and TMB \u2265 10 mut/Mb in 17%",
            "Conclusion": "MTAP loss is a frequent genomic alteration of emerging clinical importance as trials using PRMT5 and MAT2A inhibitors progress. MTAP loss is frequent in common cancers of the brain, lung, pancreas, and bladder and is not associated with a diminishment of other targetable driver mutations. This study strengthens the opportunity to consider a tumor-agnostic approach to targeted therapies."
        },
        {
            "Title": "Utility of [18F]FDG PET/CT in predicting pathological complete response to neoadjuvant therapy in breast cancer: A prospective study.",
            "Indication": "Breast cancer",
            "Drug": "Neoadjuvant chemotherapy (NAC)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Baseline SUVmax and TBRmax showed correlations with key tumor characteristics, including BC subtype and Ki-67 expression. Preoperative TBRmax showed significant associations with both pCR and RCB index.",
            "Conclusion": "Our research shed light on the significant clinical utility of semi-quantitative parameters derived from [18F]FDG PET/CT scans in predicting pCR in BC patients undergoing NAC, potentially advancing the frontiers of BC management and encouraging its integration into the decision-making framework for assessing response to NAC."
        },
        {
            "Title": "Assessing the value of repeat next generation testing in patients with non-small cell lung cancer: A retrospective review.",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "Various targeted therapies based on NGS results",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Repeat NGS testing was clinically meaningful in 48% of patients. 57.5% were continued or resumed on their targeted therapy, 14.2% were switched to or received additional targeted therapy, 9% detected a target when prior tests were negative, and 2.2% had a change in their chemotherapy or immunotherapy. In 17.1% of patients, repeat NGS either confirmed recurrence or a second primary.",
            "Conclusion": "Repeat NGS testing in NSCLC can be clinically meaningful to guide management, such as in detecting a target when prior tests were negative, allowing for switch in, continuation of or addition of targeted therapy, or providing confirmation of a recurrence or a second primary. Further research is warranted in this field to evaluate if repeat NGS can ultimately improve clinical outcomes in these patients."
        },
        {
            "Title": "Preliminary results from a phase 1 study of AC699, an orally bioavailable chimeric estrogen receptor degrader, in patients with advanced or metastatic breast cancer.",
            "Indication": "Advanced or metastatic breast cancer",
            "Drug": "AC699",
            "PFS": "None",
            "OS": "None",
            "ORR": "33% (4/12) overall, 67% (4/6) in ESR1 mutation subset",
            "AE": "Fatigue (23%), dehydration (18%), nausea (18%), hot flushes (14%)",
            "Other": "Clinical benefit rate (CBR) was 42% (5/12) overall, 83% (5/6) in ESR1 mutation subset",
            "Conclusion": "Preliminary data from the ongoing phase 1 trial evaluating AC699 indicate promising safety, tolerability, and anti-tumor activity, at doses up to 300 mg orally once daily. A phase 2 study will begin enrolling in early 2024."
        },
        {
            "Title": "A phase I study of ATR inhibitor BAY1895344 (elimusertib) plus topotecan (ETCTN 10402): Results of dose escalation.",
            "Indication": "Refractory advanced solid tumors",
            "Drug": "BAY1895344 (elimusertib) plus topotecan",
            "PFS": "Median progression-free survival in the RP2D cohort was 3.45 months",
            "OS": "None",
            "ORR": "12.5% (1/8)",
            "AE": "Neutropenia (50%), thrombocytopenia (37.5%), cardiac arrest (12.5%)",
            "Other": "Disease control rate was 43% among evaluable patients",
            "Conclusion": "RP2D and MTD was established for elimusertib in combination with Topotecan: Topotecan 1 mg/m2 IV [D1-D5] plus elimusertib 20 mg Daily [D2, D5] every 3 weeks. Dose escalation was notably limited by myelotoxicity. Due to sponsor decision, the study was halted prior to planned expansions cohorts but the concept of ATR+topoI inhibition remains relevant."
        },
        {
            "Title": "A phase I study of irinotecan combined with BAY1895344 (ATR inhibitor) in advanced solid tumors: Results of ETCTN 10402 dose escalation.",
            "Indication": "Advanced solid tumors",
            "Drug": "Irinotecan combined with BAY1895344 (elimusertib)",
            "PFS": "2.1 months (biweekly) and 2.5 months (weekly)",
            "OS": "None",
            "ORR": "Partial Response: 11.1% (biweekly), Stable Disease: 44.4% (biweekly), 67% (weekly), Progression: 44.4% (biweekly), 33% (weekly)",
            "AE": "Neutropenia: 55.6% (biweekly), 58.3% (weekly); Febrile Neutropenia: 11% (biweekly), 8.3% (weekly); Thrombocytopenia: 22.2% (biweekly), 8.3% (weekly); Sepsis: 0% (biweekly), 8.3% (weekly)",
            "Other": "Dose escalation was notably limited by myelotoxicity.",
            "Conclusion": "Both RP2D and MTD of elimusertib in combination with irinotecan were reached for both dosing schedules. The study was halted prior to planned expansion due to sponsor decision, but the concept of ATR + topo I combination remains scientifically relevant."
        },
        {
            "Title": "A first-in-human trial of selective CDK7 inhibitor Q901, in patients with advanced solid tumors: Interim results of a phase I study (QRNT-009).",
            "Indication": "Advanced solid tumors",
            "Drug": "Q901",
            "PFS": "None",
            "OS": "None",
            "ORR": "Partial Response: 1 patient with pancreatic cancer at 18 mg/m2; Stable Disease: 2 patients at 18 mg/m2, 2 patients at 36 mg/m2",
            "AE": "Nausea: 29.4%, Anemia: 23.6%, Fatigue: 23.5%",
            "Other": "No treatment-related SAEs, treatment discontinuations, or dose reductions. Dose-dependent increase of AUC and Cmax observed.",
            "Conclusion": "Preliminary data from QRNT-009 study showed that selective CDK7 inhibitor Q901 is well tolerated. Preliminary antitumor activity and pharmacodynamic observations are encouraging. Dose escalation is ongoing to identify MTD or recommended dose for further studies."
        },
        {
            "Title": "Long-term efficacy, safety and PK data of TH1902 (sudocetaxel zendusortide) in solid tumors: A novel SORT1-targeting peptide-drug-conjugate (PDC).",
            "Indication": "Solid tumors",
            "Drug": "TH1902 (sudocetaxel zendusortide)",
            "PFS": "None",
            "OS": "None",
            "ORR": "Partial Response: 1 OvC patient; Stable Disease: 1 endometrial cancer patient",
            "AE": "Neuropathy: 12%, Keratitis: 8%, Anemia: 8%, Neutropenia: 4%",
            "Other": "PK measures showed lower exposure to free docetaxel compared to TH1902. Low levels of free docetaxel in human plasma may explain the low rate of taxane-related AEs.",
            "Conclusion": "TH1902 induces durable disease stabilization that lasts beyond treatment completion, suggesting a unique, multimodal mechanism of action. TH1902 has a manageable safety profile at 300 mg/m2 with few Grade 3 AEs. Next phase of the study involves dose optimization to further limit toxicity and improve efficacy."
        },
        {
            "Title": "Final results of a first-in-human phase I dose escalation trial of daily oral zelenirstat, a n-myristoyltransferase inhibitor, in patients with advanced solid tumors and relapsed/refractory B-cell lymphomas.",
            "Indication": "Advanced solid tumors and relapsed/refractory B-cell lymphomas",
            "Drug": "Zelenirstat",
            "PFS": "None",
            "OS": "None",
            "ORR": "Stable disease as best response was seen in 8 (28%) heavily pre-treated pts (3 colorectal, 2 ovarian, 1 pancreatic, 1 appendiceal, and 1 bladder)",
            "AE": "Common adverse events were Gr#2 nausea, vomiting, diarrhea, and fatigue. Three DLTs were observed at 280mg: Gr3 diarrhea, Gr3 diverticulitis, and Gr3 dehydration.",
            "Other": "Plasma concentrations peaked between 1 and 4 hours across the cohorts with terminal half-lives ranging from 6.7 to 12 hours. Steady state was achieved by Day 8 to 15, and in the higher dose cohorts, trough concentrations exceeded the levels predicted to be therapeutic.",
            "Conclusion": "Zelenirstat is well-tolerated, reaches plasma concentrations highly active in preclinical models, and shows preliminary signs of encouraging anticancer activity. NMT inhibition represents a new target for ongoing research efforts and further clinical development of zelenirstat is warranted."
        },
        {
            "Title": "Phase 1/2 trial of the HPK1 inhibitor NDI-101150 as monotherapy and in combination with pembrolizumab: Clinical update.",
            "Indication": "Relapsed or metastatic solid tumors",
            "Drug": "NDI-101150",
            "PFS": "None",
            "OS": "None",
            "ORR": "NDI-101150 induced clinical benefit in 4/24 (16.7%) response-evaluable pts: complete response in 1 pt with clear cell RCC; stable disease (SD) \u22656 months in 3 pts (RCC [21 months], pancreatic cancer and endometrial cancer). Unconfirmed SD was noted in 2 pts with RCC, and in 1 pt each with NSCLC and G/GEJ cancer.",
            "AE": "Treatment-related adverse effects (TRAEs) in the safety set (N=39) for NDI-101150 monotherapy: 30 (76.9%) pts reported \u22651 TRAE and 5 (12.8%) pts reported grade \u22653 TRAEs. Most common TRAEs were nausea, vomiting, diarrhea, and fatigue.",
            "Other": "Nearly dose-proportional increases in exposure were observed on Day 1 of Cycle 1 (C1) across 50\u2013200mg, with steady state achieved between Days 15 and 28 of tx. Pharmacokinetic profiles of monotherapy and combination tx cohorts were similar. Sustained inhibition of pSLP76 of >50% relative to pre-tx levels was observed at all doses tested by Day 15 of C1. Increased infiltration of activated CD8+ T-cells and DCs was observed in a non-tx biopsy assessment from a pt with RCC.",
            "Conclusion": "The observed clinical benefit and safety profile support continued evaluation of NDI-101150 as a viable next-generation immunotherapeutic."
        },
        {
            "Title": "Safety, pharmacokinetics (PK), pharmacodynamics (PD) and efficacy of KT-253, a targeted protein degrader of MDM2, in patients with relapsed/refractory (R/R) solid tumors, lymphoma, high grade myeloid malignancies and acute lymphoblastic leukemia (ALL).",
            "Indication": "Relapsed/refractory solid tumors, lymphoma, high grade myeloid malignancies, and acute lymphoblastic leukemia (ALL)",
            "Drug": "KT-253",
            "PFS": "None",
            "OS": "None",
            "ORR": "Overall best response: 1 CR (B:DL2, AML); 2 PRs (A:DL1, MCC; B:DL1, AML); 3 SD (A:DL1, fibromyxoid sarcoma; DL2, ACC; DL3, renal cell cancer)",
            "AE": "The most common adverse events (AEs) in >20% of pts included nausea, fatigue, headache, and vomiting. There was 1 DLT of AEs leading to discontinuation that included Grade (G) 2 nausea and fatigue in A:DL4. There were no neutropenia or thrombocytopenia AEs in either arm. KT-253 related SAEs included G3 hypotension in a pt with decreased oral intake (A:DL1).",
            "Other": "PD data from A:DL1-4 and B:DL1-2 demonstrated rapid upregulation of plasma GDF-15 protein and upregulation of CDKN1A and PHLDA3 mRNA levels in blood. KT-253 demonstrated dose-dependent increase in plasma exposure with levels approximating efficacious doses.",
            "Conclusion": "KT-253 results in potent upregulation of p53-dependent biomarkers and has demonstrated early signs of anti-tumor activity including objective responses in MCC and AML at doses that are well tolerated. Dose escalation is ongoing at DL4 in Arm A and at DL2 in Arm B and analyses from additional pts will be presented at the meeting."
        },
        {
            "Title": "Contribution of the microbiome to differential drug efficacy and clinical trial outcomes between the US and Japan.",
            "Indication": "Extensive-stage small cell lung cancer (SCLC)",
            "Drug": "Irinotecan-cisplatin",
            "PFS": "Japan: 12.8 months, US: 9.4 months",
            "OS": "None",
            "ORR": "Japan: 87%, US: 68%",
            "AE": "None",
            "Other": "NNI for irinotecan is almost 5 times lower in the US (US: 6.2; Japan: 30.9). NNI for both cisplatin and etoposide (US: 2.3; Japan: 1.7) is similar between countries.",
            "Conclusion": "This work represents the first demonstration that drug-microbiome interactions may contribute to the efficacy of anticancer drugs, as well as to differential trial outcomes between countries. Proteins produced by the microbiome that functionally mimic host proteins may interfere with the pharmacodynamics of cancer therapeutics, including irinotecan. This interaction is found 5 times more frequently in US stool samples than Japanese samples and may contribute to the observed differences in outcomes in JCOG9511 and SWOG0124."
        },
        {
            "Title": "An exploratory study on prediction of risk for abemaciclib-induced interstitial lung disease or hepatotoxicity by specific human leukocyte antigen alleles.",
            "Indication": "Advanced breast cancer",
            "Drug": "Abemaciclib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "HLA-DQA1*05:05 allele was identified as a promising marker candidate that can predict patients with a higher risk of abemaciclib-induced hepatotoxicity.",
            "Conclusion": "Evaluated 28 HLA alleles were not associated with the development of ILD. HLA-DQA1*05:05 allele was identified as a promising marker candidate that can predict patients with a higher risk of abemaciclib-induced hepatotoxicity. Further study is necessary to confirm the association in an independent population for managing liver safety risk during abemaciclib treatment. This result also implicates an immune-mediated mechanism for abemaciclib-related hepatotoxicity."
        },
        {
            "Title": "Breaking from the paradigm of antibody-drug conjugates: Evaluation of clinical pharmacokinetics and safety of bicycle toxin conjugates (BTCs).",
            "Indication": "Advanced solid tumors",
            "Drug": "BT8009 and BT5528",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Peripheral neuropathy: BT8009 22% (Any Grade), 1% (Grade \u22653); BT5528 19% (Any Grade), 0% (Grade \u22653).",
            "Other": "BTC parent drugs were rapidly eliminated (half-life [t1/2], 1 hour) while unconjugated MMAE exhibited persistent exposures and gradual elimination (MMAE t1/2, 1.9 days [D] for both BT8009 and BT5528), in contrast with the ADC (parent drug t1/2, 3.6 D; MMAE t1/2, 2.6 D). Conjugated MMAE exposures for the ADC were higher than for BT8009 and BT5528 (75- and 73-fold, respectively). Unconjugated MMAE exposures for BT8009 and BT5528 were comparable to the ADC (25% and 8% higher, respectively).",
            "Conclusion": "Preliminary data show substantial differences between BTC and ADC PK, and a promising BTC safety profile, possibly resulting from the distinct PK and selectivity (and specificity) of Bicycle peptides. These data highlight the potential value of BTCs as a platform for developing therapies against advanced malignancies."
        },
        {
            "Title": "Phase Ib portion of the ACTION-1 phase Ib/3 trial of RYZ101 in gastro-enteropancreatic neuroendocrine tumors (GEP-NET) progressing after 177Lu somatostatin analogue (SSA) therapy: Safety and efficacy findings.",
            "Indication": "Gastro-enteropancreatic neuroendocrine tumors (GEP-NET)",
            "Drug": "RYZ101",
            "PFS": "None",
            "OS": "None",
            "ORR": "29.4% (n=5; all partial responses)",
            "AE": "Most frequent TEAEs: nausea (58.8%), fatigue (52.9%). Serious AEs in 6 patients (none treatment related); grade \u22653 AEs in 9 patients (5 treatment related).",
            "Other": "4 patients had TEAEs leading to dose modification, dose hold, and/or delays. 8 patients (47.1%) had stable disease and 3 patients (17.6%) had progressive disease.",
            "Conclusion": "RYZ101 was well tolerated and a fixed dose of 10.2 MBq was declared the recommended phase 3 dose. Initial data suggest promising efficacy. Longer-term safety and efficacy data will be presented. Part 2 (phase 3) is enrolling and will compare RYZ101 at 10.2 MBq every 8 weeks for 4 cycles with standard of care in patients with advanced SSTR2+ GEP-NETs progressing following prior 177Lu-labeled SSAs."
        },
        {
            "Title": "Ipatasertib in patients with AKT1/2/3 mutation-positive (AKTmut) tumors: TAPISTRY study.",
            "Indication": "Advanced/metastatic AKTmut solid tumors",
            "Drug": "Ipatasertib",
            "PFS": "Median progression-free survival: 4.8 months (95% CI: 3.5\u20139.0)",
            "OS": "Median overall survival: 11.4 months (95% CI: 9.3\u201325.7)",
            "ORR": "31.3% (n=15/48; 95% CI: 18.7\u201346.3)",
            "AE": "Most frequent adverse event: diarrhea (78%). Grade \u22653 AEs in 65% of patients; serious AEs in 32% of patients.",
            "Other": "None",
            "Conclusion": "Treatment with ipatasertib led to a marked and durable antitumor activity in some tumor types such as endometrial cancer, but not in the overall tumor-agnostic cohort. Further studies are needed to understand the relevance of AKT inhibition in these tumor types."
        },
        {
            "Title": "First-in-human study of TQB3617, a BET inhibitor in patients with relapsed/refractory hematologic malignancies.",
            "Indication": "Relapsed/refractory hematologic malignancies",
            "Drug": "TQB3617",
            "PFS": "None",
            "OS": "None",
            "ORR": "31.25% in patients with lymphoma",
            "AE": "Most common TEAEs: platelet count decrease (74.3%), anemia (40%), hypertriglyceridemia (28.6%), hyperglycemia (25.7%). Grade \u22653 TEAEs in 45.7% of patients, most common were platelet count decrease (31.4%) and anemia (11.4%).",
            "Other": "The spleen volume of two patients with myelofibrosis decreased by 6.57% and 20.99% at four weeks, respectively.",
            "Conclusion": "TQB3617 was generally safe, well-tolerated, and demonstrated encouraging efficacy in patients with lymphoma or myelofibrosis. A phase Ib/II study is ongoing, aiming to assess the efficacy and safety of rovadicitinib combined with TQB3617 in patients with myelofibrosis. More studies are planned."
        },
        {
            "Title": "FCN-159, a MEK1/2 inhibitor, in patients with advanced melanoma harboring NRAS or NF1 mutations: A phase 1B dose-expansion study.",
            "Indication": "Advanced melanoma harboring NRAS or NF1 mutations",
            "Drug": "FCN-159",
            "PFS": "Cohort 1: 3.6 months (95% CI, 1.8\u20135.5); Cohort 2: 2.8 months (95% CI, 1.8-5.5)",
            "OS": "None",
            "ORR": "Cohort 1: 24.0% (95% CI, 9.4\u201345.1); Cohort 2: 7.1% (95% CI, 0.2-33.9)",
            "AE": "Grade \u22653 TEAEs in 21.7% of patients; most common was blood creatine phosphokinase increased (13.0%)",
            "Other": "Median DOR: 6.5 months (95% CI, 2.7\u2013NE); FCN-159-related SAEs in 6.5% of patients; no TEAEs led to death",
            "Conclusion": "FCN-159 showed encouraging efficacy data with a well-tolerated safety profile in patients with NRAS-mutant advanced melanoma, especially those who failed in previous anti-PD-1 therapy."
        },
        {
            "Title": "Efficacy and safety of FCN-159, a MEK1/2 inhibitor in pediatric participants with neurofibromatosis type 1: Results from a phase 2 trial.",
            "Indication": "Neurofibromatosis type 1 (NF1)",
            "Drug": "FCN-159",
            "PFS": "Not reached",
            "OS": "None",
            "ORR": "Investigator assessed: 48.8%; BIRC assessed: 30.2%",
            "AE": "93.5% experienced TRAEs; grade \u22653 TRAEs in 17.4%",
            "Other": "Pain scores decreased by at least 2 points in 81.3% of patients with tumor pain at baseline; pain scores reduced to 0 points in 81.3% of patients",
            "Conclusion": "FCN-159 demonstrated good tolerability and exhibited notable anti-tumor activity in pediatric patients with NF1-related PN."
        },
        {
            "Title": "Phase II dose optimization with EZH2/EZH1 inhibitor tulmimetostat in patients (pts) with advanced solid tumors or hematologic malignancies.",
            "Indication": "Advanced solid tumors or hematologic malignancies",
            "Drug": "Tulmimetostat",
            "PFS": "None",
            "OS": "None",
            "ORR": "Best responses of \u22651 CR/PR seen in 5 cohorts",
            "AE": "74.4% had dose modifications due to TEAEs; 41.9% had \u22651 serious TEAE; 9.4% discontinued therapy due to TEAEs",
            "Other": "Most frequent TEAEs (\u226535% of pts; any grade/Grade \u22653) were diarrhea (49.6%/10.3%), thrombocytopenia (48.7%/24.8%), anemia (39.3%/16.2%), and nausea (35.9%/2.6%)",
            "Conclusion": "The evolving Phase II data, including early data from lower doses, continue to show signs of anti-tumor activity/disease stabilization. The safety profile is consistent with EZH2 inhibition."
        },
        {
            "Title": "Alectinib in pediatric patients with solid or CNS tumors harboring ALK-fusions: Emerging pharmacokinetic data from the iMATRIX alectinib phase I/II open-label, multi-center study.",
            "Indication": "Solid or CNS tumors harboring ALK-fusions",
            "Drug": "Alectinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "PK exposures after starting dose administration were evaluated during the first 28-day treatment cycle and doses were adjusted as needed for subsequent cycles",
            "Conclusion": "The BW based popPK model successfully predicted the starting doses of all 11 treated subjects \u22652 years old, leading to target exposures within range. Since 3 out of 4 subjects <2 years old had exposure below target, the starting dose for subjects <2 years needs to be increased while ensuring that the optimal benefit/risk ratio is maintained."
        },
        {
            "Title": "Response to RAF/MEK/ERK inhibitors in patients with RAS altered and wild-type tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "RAF, MEK, and/or ERK inhibitors",
            "PFS": "Median PFS: 18 weeks (RAS wild-type) vs 9 weeks (RAS altered), p < 0.001",
            "OS": "Median OS: 58 weeks (RAS wild-type) vs 42 weeks (RAS altered), p = 0.027",
            "ORR": "PR: 18.1% (RAS wild-type) vs 2.2% (RAS altered)",
            "AE": "None",
            "Other": "Disease control rate (PR or SD): 70.1% (RAS wild-type) vs 52.2% (RAS altered), p = 0.007",
            "Conclusion": "RAS alterations are associated with lower efficacy of RAF/MEK/ERK inhibitors."
        },
        {
            "Title": "MyTACTIC: Activity of targeted therapy in patients (pts) with advanced solid tumors harboring specific biomarkers.",
            "Indication": "Advanced solid tumors with specific biomarkers",
            "Drug": "Various targeted therapies",
            "PFS": "None",
            "OS": "None",
            "ORR": "cORR ranging from 11.5% to 66.7% across different arms",
            "AE": "Safety profile as expected based on prior reports",
            "Other": "Responses observed across 7 arms; no responses in arms G, H, J, K, and L",
            "Conclusion": "Targeted therapies yielded responses across tumor types in most treatment arms; testing for actionable molecular alterations is warranted for pts with limited treatment options."
        },
        {
            "Title": "Safety, efficacy and pharmacokinetics of VC004, a highly selective next-generation pan-TRK inhibitor, in patients with locally advanced/metastatic solid tumors.",
            "Indication": "Locally advanced/metastatic solid tumors",
            "Drug": "VC004",
            "PFS": "None",
            "OS": "None",
            "ORR": "Confirmed ORR: 65.4% (TRK TKI-na\u00efve pts), 80.8% (confirmed + unconfirmed ORR)",
            "AE": "TRAEs in 98.0% of pts, most were grade 1-2; no fatal TRAEs",
            "Other": "Intracranial lesion shrinkage in pts with brain metastasis; median DoR not reached but 23.8% of TRK TKI-na\u00efve pts maintained response for \u226512 months",
            "Conclusion": "VC004 showed a manageable safety profile and promising efficacy against NTRK fusion-positive tumors."
        },
        {
            "Title": "Study update of the oral CDK9 inhibitor KB-0742 in relapsed or refractory transcriptionally addicted advanced solid tumors.",
            "Indication": "Relapsed or refractory transcriptionally addicted advanced solid tumors",
            "Drug": "KB-0742",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Treatment-emergent adverse events in >15% of pts include nausea, vomiting, anemia, fatigue, diarrhea, and constipation; none assessed as grade 4 or 5",
            "Other": "DCR of 42.8% in TFF positive STS pts vs 29.4% in TFF negative; best observed response was durable SD with a DCR of 53.8% in ACC and 83% in NSCLC",
            "Conclusion": "KB-0742 treatment at 60 and 80 mg was well tolerated, with manageable toxicity. Achievement of long-term SD and some preliminary anti-tumor efficacy in highly pretreated patients motivates continued enrollment."
        },
        {
            "Title": "Phase Ib trial of tasurgratinib (E7090) with or without endocrine therapies for patients (pts) with ER+, HER2- recurrent/metastatic breast cancer (BC) after receiving a CDK4/6 inhibitor.",
            "Indication": "ER+, HER2- recurrent/metastatic breast cancer",
            "Drug": "tasurgratinib (E7090) with or without endocrine therapies",
            "PFS": "5.4 months (95% CI 3.5\u2013not estimable [NE])",
            "OS": "None",
            "ORR": "21.9% (95% CI 9.3\u201340.0)",
            "AE": "100% (most commonly hyperphosphatemia)",
            "Other": "DCR: 75.0% (95% CI 56.6\u201388.5); CBR: 47.9% (95% CI 31.7\u201364.2)",
            "Conclusion": "E7090+FUL had a manageable safety profile. The combination showed promising preliminary antitumor activity in ER+ HER2- BC previously treated with a CDK4/6i; its ORR was 21.9%. Our results support the further development of this combination for HR+/HER2- advanced BC after a CDK4/6i."
        },
        {
            "Title": "Binimetinib and encorafenib for the treatment of advanced solid tumors with non-V600E BRAF mutations (mts): Final results of the investigator-initiated phase II BEAVER trial.",
            "Indication": "Advanced solid tumors with non-V600E BRAF mutations",
            "Drug": "Binimetinib and encorafenib",
            "PFS": "2.4 months (entire cohort)",
            "OS": "None",
            "ORR": "13% (3/23) with one confirmed PR in a pt with ampullary cancer (BRAF D594G) and unconfirmed PRs in 2 melanoma pts (BRAF G469S and K601E)",
            "AE": "None",
            "Other": "Pts with TP53 mts experienced shorter PFS (1.8 vs. 4.0 mo, P=0.008). Pts with melanoma (4.0 mo) experienced longer PFS than pts with pancreaticobiliary (2.6 mo), colorectal (1.8 mo), or other tumor types (1.6 mo); P=0.006.",
            "Conclusion": "B+E has only modest clinical activity in advanced cancers with non-V600E BRAF mts and resistance to B+E develops quickly. Alternative approaches incorporating Shp2 and CDK4/6 inhibitors warrant further investigation in this patient population."
        },
        {
            "Title": "Outcomes of larotrectinib compared with real-world data from non-TRK inhibitor therapies in patients with TRK fusion cancer: VICTORIA study.",
            "Indication": "TRK fusion cancer",
            "Drug": "Larotrectinib",
            "PFS": "36.8 months (HR: 0.29 [95% CI: 0.18-0.46])",
            "OS": "Not reached (NR) vs 37.2 months; HR: 0.44 [95% CI: 0.23-0.83]",
            "ORR": "None",
            "AE": "None",
            "Other": "TTNT: median NR vs 10.6 months; HR: 0.22 [95% CI: 0.13-0.38]; DoT: median 30.8 vs 3.4 months; HR: 0.23 [95% CI: 0.15-0.33]",
            "Conclusion": "In adult pts with TRK fusion cancer, treatment with laro was associated with longer OS and all measured time-to-event endpoints (TTNT, DoT, and PFS), compared to exactly matched pts treated with standard non-TRK i therapies in the RW. These results furnish additional evidence illustrating the benefit of laro treatment in pts with TRK fusion cancer and support the data generated in the single-arm registrational trials."
        },
        {
            "Title": "Efficacy and safety of SY-5007, a highly potent and selective RET inhibitor, in Chinese patients with advanced RET-fusion positive non-small cell lung cancer (NSCLC): Results from a multicenter, single-arm, phase II study.",
            "Indication": "Advanced RET-fusion positive non-small cell lung cancer (NSCLC)",
            "Drug": "SY-5007",
            "PFS": "None",
            "OS": "None",
            "ORR": "77.1% (95% CI 67.9-84.8) by BICR; 77.1% (95% CI 67.9-84.8) by investigators",
            "AE": "96.2% (common events \u226530%: increased AST, increased ALT, decreased neutrophil count, decreased white blood cell count, decreased platelet count)",
            "Other": "DCR: 83.8% (95% CI 75.3-90.3) by BICR; 90.5% (95% CI 83.2-95.3) by investigators. In treatment-naive patients (cohort 1, n=56), ORR: 83.9% (95% CI 71.4-92.4), DCR: 91.1% (95% CI 80.4-97.0). In pre-treated patients (cohort 2, n=49), ORR: 69.4% (95% CI 54.6-81.7), DCR: 89.8% (95% CI 77.8-96.6). For 29 patients with baseline brain metastasis, ORR: 69.0% (95% CI 49.2-84.7), DCR: 86.2% (95% CI 68.3-96.1).",
            "Conclusion": "SY-5007 demonstrated promising efficacy and safety for advanced NSCLC with positive RET."
        },
        {
            "Title": "Interim analysis of ABM-1310, a blood-brain barrier-penetrant BRAF inhibitor, in patients with BRAF V600-mutated solid tumors.",
            "Indication": "BRAF V600-mutated solid tumors",
            "Drug": "ABM-1310",
            "PFS": "4.6 months (primary CNS tumors), 6.0 months (PTC)",
            "OS": "None",
            "ORR": "21.4% (ABM-1310 monotherapy), 12.5% (ABM-1310 + cobimetinib), 40% (primary CNS tumors), 33.3% (PTC), 25% (PC)",
            "AE": "74.5% experienced TRAEs, most frequent were skin rash (29.4%) and asymptomatic electrocardiogram QT prolongation (35.3%)",
            "Other": "DCR was 60.7% (ABM-1310 monotherapy), 68.8% (ABM-1310 + cobimetinib)",
            "Conclusion": "ABM-1310, either alone or in combination with cobimetinib, was well tolerated without new or unexpected side effects or safety issues. Preliminary efficacy of ABM-1310 was seen in patients with BRAF V600-mutated solid tumors, including those who were refractory to prior BRAF/MEK inhibitors."
        },
        {
            "Title": "First-in-human phase 1/2a study of the first-in-class, next-generation CDK4-selective inhibitor PF-07220060 + endocrine therapy (ET): Updated safety data in patients with HR+/HER2- advanced/metastatic breast cancer (mBC).",
            "Indication": "HR+/HER2- advanced/metastatic breast cancer",
            "Drug": "PF-07220060 + endocrine therapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Most common TEAEs were neutropenia (54.5% in Parts 1B-C, 61.8%/36.1% in Parts 2B-C), diarrhea (42.4% in Parts 1B-C), and nausea (42.4% in Parts 1B-C)",
            "Other": "Incidence of G3 neutropenia and other TEAEs was low, enabling high RDI and potential for continuous target coverage.",
            "Conclusion": "PF-07220060 + ET was well tolerated in patients with HR+/HER2- mBC across post-CDK4/6i and CDK4/6i-na\u00efve cohorts."
        },
        {
            "Title": "First-in-human study of simmitinib, a novel tyrosine kinase inhibitor targeting FGFR1-3, KDR and CSF-1R.",
            "Indication": "Advanced solid tumors",
            "Drug": "Simmitinib",
            "PFS": "5.7 months (esophageal squamous carcinoma)",
            "OS": "None",
            "ORR": "16.1% (esophageal squamous carcinoma)",
            "AE": "TRAEs reported in 88 patients (89.8%), most common were proteinuria (51.0%), hypertension (42.9%), decreased platelet count (20.4%), elevated ALT (17.3%), decreased white blood cell count (16.3%)",
            "Other": "DCR of 46.4% (esophageal squamous carcinoma)",
            "Conclusion": "Simmitinib showed manageable toxicity and preliminary efficacy in patients with advanced solid tumors. The 6 mg 3 weeks on 1 week off regimen was selected as the recommended phase II dose."
        },
        {
            "Title": "Dabrafenib and trametinib in patients with tumors with BRAF V600E/K mutations: Updated results from NCI-MATCH arm H.",
            "Indication": "BRAF V600E/K mutated solid tumors",
            "Drug": "Dabrafenib and trametinib",
            "PFS": "Median PFS in EC: 18 months (pilocytic astrocytoma), 12 months (GBM)",
            "OS": "Median OS in EC: 19.8 months, Median OS in CC: 28.6 months",
            "ORR": "36.1% (combined cohort)",
            "AE": "Comparable to previously reported profiles of DAB/TRM",
            "Other": "Estimated 6-month PFS is 67.6% (combined cohort)",
            "Conclusion": "In this pretreated cohort of BRAF V600 mutated solid tumors, the combination of DAB/TRM showed clinically meaningful activity with durable responses seen in patients across multiple histologies, further supporting the disease-agnostic approval by the FDA for this regimen."
        },
        {
            "Title": "Characterization of BTX-9341, a bifunctional degrader of CDK4 and CDK6 for HR+/HER2- breast cancer and glioblastoma multiforme.",
            "Indication": "HR+/HER2- breast cancer and glioblastoma multiforme",
            "Drug": "BTX-9341",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "BTX-9341 showed up to 85% degradation of CDK4 and CDK6, phospho-RB IC50 of 30 nM, E2F target gene downregulation, G1 cell cycle arrest at concentrations as low as 10 nM, and inhibited cell proliferation with CFA IC50 of 20-50 nM. In vivo models showed dose-dependent tumor growth inhibition and tumor regression.",
            "Conclusion": "BTX-9341, a degrader of CDK4 and CDK6 and inhibitor of CDK2 and Cyclin E transcription, displayed enhanced activity compared to CDK4/6 inhibitors in breast cancer and GBM in vitro and in vivo. This indicates that a degrader approach to targeting this pathway in breast cancer may be more effective than current therapies, and that BTX-9341 may also be a promising candidate for brain metastases and GBM."
        },
        {
            "Title": "A phase 2 clinical trial of first-in-class fascin inhibitor NP-G2-044 as monotherapy and in combination therapy with anti-PD-1 immunotherapy in patients with advanced solid tumor malignancies.",
            "Indication": "Advanced solid tumor malignancies",
            "Drug": "NP-G2-044",
            "PFS": "Median PFS for 12 GYN pts receiving the MT-RP2D was 20 weeks",
            "OS": "None",
            "ORR": "RECIST PR (53% reduction in tumor bulk) for a pt with cutaneous squamous cell cancer who progressed on prior anti-PD(L)-1 therapy",
            "AE": "No DLTs or drug-related SAEs observed among MT-RP2D pts. Most common TEAEs observed for CT were diarrhea, fatigue, and nausea.",
            "Other": "MT-RP2D of 2100 mg QD was selected based on a 16-pt comparative review of PK, safety, and efficacy. One pt with treatment-refractory ovarian cancer continues on study with SD exceeding 24 months. CT-RP2D of 1600 mg QD was selected and enrollment is ongoing.",
            "Conclusion": "The first-in-class fascin inhibitor, NP-G2-044, appears safe and well tolerated administered both as monotherapy and in combination therapy with IO. Signals of anti-cancer and anti-metastatic activity were observed with both mono and combination therapy. A phase 3 randomized clinical trial evaluating NP-G2-044 in combination with chemo in pts with platinum-resistant ovarian cancer is in development with enrollment anticipated to start later this year. Additionally, a phase 2 study to further evaluate NP-G2-044 in combination with anti-PD-1 therapy in IO-na\u00efve pts is planned."
        },
        {
            "Title": "The microtubule-destabilizing agent AUS_001 as a candidate for glioblastoma therapy.",
            "Indication": "Glioblastoma",
            "Drug": "AUS_001",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "AUS_001 prevents the growth of parental primary patient-derived GBM and patient-derived glioma lines enriched in cancer stem cells under 2D culturing conditions with high potency. AUS_001 efficiently prevented the growth of tumors, including cases where the standard of care, TMZ, was not effective. AUS_001 is less prone to mechanisms of resistance to currently approved MTAs, including increased expression of the bIII-isotype of tubulin or the P-gp drug efflux pump. An in vitro neurotoxicity model revealed that functional damage caused by AUS_001 was reversible, while paclitaxel-treated neurons suffered sustained neurotoxicity.",
            "Conclusion": "Collectively, we show that AUS_001 has the potential to circumvent significant limitations of clinically approved MTAs, including brain penetration, drug resistance, and peripheral neuropathy, making it a promising approach for the treatment of GBM."
        },
        {
            "Title": "Efficacy and safety of olaparib in patients with tumors harboring alterations in homologous recombination repair pathway associated genes: Results from the Drug Rediscovery Protocol.",
            "Indication": "Tumors with mutations in HRR-pathway associated genes",
            "Drug": "Olaparib",
            "PFS": "3.4 months (95% CI 1.8-5.3) for cohort A; 3.5 months (95% CI 3.4-6.6) for cohort B",
            "OS": "9.2 months (95% CI 5.2-21.3) for cohort A; 8.1 months (95% CI 6.6-14.2) for cohort B",
            "ORR": "4% for cohort A; 41.7% (95% CI 22.1%-63.4%) for cohort B",
            "AE": "No unexpected toxicities observed",
            "Other": "Clinical benefit observed in 32% of patients in cohort A and 41.7% in cohort B",
            "Conclusion": "Olaparib has clinical benefit in patients with progressive, treatment-refractory tumors harboring mutations in ATM, CDK12, CHEK2, or RAD51B. Ongoing biomarker analysis aims to refine patient selection and improve clinical benefit rate."
        },
        {
            "Title": "Open-label, dose-escalation phase 1a study of SPH5030, a tyrosine kinase inhibitor (TKI) targeting HER2, in patients with HER2 positive advanced solid tumors.",
            "Indication": "HER2-positive advanced solid tumors",
            "Drug": "SPH5030",
            "PFS": "None",
            "OS": "None",
            "ORR": "21.4%",
            "AE": "100% of patients experienced TEAEs; 33.3% experienced grade 3 or higher TEAEs",
            "Other": "Disease control rate was 78.6%, and clinical benefit rate was 35.7%.",
            "Conclusion": "SPH5030 was well tolerated at doses ranging from 50 to 600 mg. A dose of 600 mg QD regimen could be considered for further clinical trials."
        },
        {
            "Title": "Efficacy of erdafitinib in adults with advanced solid tumors and non-prespecified fibroblast growth factor receptor mutations in the phase 2 RAGNAR trial: Exploratory cohort.",
            "Indication": "Advanced solid tumors with non-predefined FGFR mutations",
            "Drug": "Erdafitinib",
            "PFS": "0.94 months (95% CI 0.49, not evaluable)",
            "OS": "1.79 months (95% CI 0.92, not evaluable)",
            "ORR": "4%",
            "AE": "Most common AEs were hyperphosphatemia (79%), diarrhea (53%), dry mouth (45%), stomatitis (42%), decreased appetite (38%), fatigue (32%), and increased alanine transaminase levels (32%). 49% of patients had serious treatment-emergent AEs.",
            "Other": "Disease control rate was 45%, and clinical benefit rate was 17%.",
            "Conclusion": "Clinical activity was limited in tumors with FGFR mutations not predefined as potentially susceptible. These findings validate the predictive FGFR biomarker panel and highlight the importance of careful FGFR mutation selection for targeted FGFR inhibition."
        },
        {
            "Title": "Results of a phase 1, dose-finding study of Debio 0123 as monotherapy in adult patients with advanced solid tumors: Safety, pharmacokinetic, and preliminary antitumor activity data.",
            "Indication": "Advanced solid tumors",
            "Drug": "Debio 0123",
            "PFS": "None",
            "OS": "None",
            "ORR": "32% stable disease",
            "AE": "Most frequent TRAEs (\u226520%): blood creatinine increased (37%), QTcF prolongation (37%), nausea (33%), vomiting (26%), dysgeusia (22%), fatigue (22%). Most common TRAEs Gr \u22653: QTcF prolongation (11%), fatigue (7%).",
            "Other": "MTD determined at 260 mg. Plasma exposure increased proportionally with dose from 150 to 350 mg. Target engagement observed consistently from a dose of 200 mg.",
            "Conclusion": "Continuous dosing of Debio 0123 as monotherapy has a manageable safety profile and linear pharmacokinetics. A multicohort expansion, including biomarker-selected and unselected cohorts, will further evaluate the safety and antitumor activity of Debio 0123."
        },
        {
            "Title": "Safety, tolerability, pharmacokinetics and clinical activity of AST-3424, an AKR1C3-activated bis-alkylating moiety prodrug, in subjects with advanced solid tumors in China: A phase I dose-escalation study.",
            "Indication": "Advanced solid tumors",
            "Drug": "AST-3424",
            "PFS": "None",
            "OS": "None",
            "ORR": "42.9% stable disease, 4.8% non-complete remission/non-disease progression, 23.8% progressive disease",
            "AE": "Grade \u22653 AEs observed in 47.6% of patients. 38.1% had grade \u22653 TRAEs, including anemia (33.3%), platelet count decreased (19.0%), white blood cell count decreased (9.5%), and neutrophil count decreased (9.5%).",
            "Other": "MTD and RP2D determined at 6.0 mg/m2. AST-3424 showed linear pharmacokinetic characteristics in the range of 1.0-8.0 mg/m2 with a half-life of 0.16-0.40 hours. AST-2660 showed linear pharmacokinetic characteristics in the range of 4.0-8.0 mg/m2 with a half-life of 1.59-2.08 hours.",
            "Conclusion": "AST-3424 showed a tolerable safety profile and preliminary anti-tumor activity in advanced solid tumors. The phase II dose expansion phase of AST-3424 monotherapy is ongoing in subjects diagnosed as advanced HCC and with high AKR1C3 expression."
        },
        {
            "Title": "A phase Ib/IIa study of DX1002, a novel drug in patients with pretreated advanced hepatocellular carcinoma: Efficacy and safety analysis.",
            "Indication": "Advanced hepatocellular carcinoma",
            "Drug": "DX1002",
            "PFS": "3.55 months (95% CI 2.89-8.31)",
            "OS": "13.4 months (95% CI 11.0-NA)",
            "ORR": "21.1%",
            "AE": "Any-grade TRAEs occurred in 75% of patients, with 22.5% Grade 3. Most common TRAEs: Aspartate aminotransferase increased (22.5%), total bilirubin increased (20%), albuminuria (20%), hypoalbuminemia (15%), gamma-glutamyl transferase increased (15%).",
            "Other": "None",
            "Conclusion": "Initial data shows DX1002 demonstrated manageable safety profile and promising anti-tumor potential in pre-treated advanced hepatocellular carcinoma. Further exploration of DX1002 in HCC is warranted."
        },
        {
            "Title": "BY101921: Restoring interferon signaling and anti-tumor immunity through PARP7 inhibition.",
            "Indication": "Various cancers including squamous cell carcinoma, ovarian cancer, breast cancer, and pancreatic ductal adenocarcinoma",
            "Drug": "BY101921",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "BY101921 demonstrated outstanding selectivity as a PARP7 inhibitor, displaying limited off-target effects on PARP1/2, reducing the risk of systemic toxicity, especially hematologic effects. In vivo, BY101921 exhibited potent inhibition of tumor growth as a monotherapy, and its combination with anti-PD-1 further enhanced its anti-tumor efficacy, with excellent tolerability in animals.",
            "Conclusion": "BY101921, a potent and selective PARP7 inhibitor, demonstrates significant anti-tumor efficacy both as a monotherapy and in combination with anti-PD-1, while maintaining excellent tolerability in animal models. With favorable drug-like properties and a promising safety profile, BY101921 holds potential as an enhanced therapeutic option for cancer patients. Clinical studies investigating BY101921 as a single agent and in combination with anti-PD-1 are in the recruitment phase."
        },
        {
            "Title": "Updated study results of novel FAK/ALK/ROS1 inhibitor APG-2449 in patients (pts) with non-small-cell lung cancer (NSCLC) resistant to second-generation ALK inhibitors.",
            "Indication": "Non-small-cell lung cancer (NSCLC)",
            "Drug": "APG-2449",
            "PFS": "11.86 months for patients resistant to 2G ALK inhibitors",
            "OS": "None",
            "ORR": "68.2% in ROS1 TKI-na\u00efve pts; 78.6% in ALK TKI-na\u00efve pts; 40.9% in pts resistant to 2G ALK inhibitors",
            "AE": "89.6% of pts had treatment-related adverse events (TRAEs). Most frequent: elevated serum creatinine (49.3%), ALT (42.4%), AST (36.1%), nausea (28.5%), vomiting (23.6%), diarrhea (22.9%), decreased leukocyte count (22.2%). 13.9% had grade \u22653 TRAEs.",
            "Other": "Intracranial ORR of 75.0% in the RP2D group. High pFAK expression levels in baseline tumor tissue correlated with improved APG-2449 treatment responses.",
            "Conclusion": "APG-2449 demonstrated preliminary efficacy in pts with NSCLC whose disease was TKI-na\u00efve and resistant to 2G ALK inhibitors, especially in brain metastases. High pFAK expression levels in baseline tumor tissue correlated with improved APG-2449 treatment responses in pts with NSCLC resistant to 2G ALK inhibitors."
        },
        {
            "Title": "A phase 1 study evaluating the safety, pharmacokinetics, and efficacy of fadraciclib, an oral CDK2/9 inhibitor, in patients with advanced solid tumors and lymphoma.",
            "Indication": "Advanced solid tumors and lymphoma",
            "Drug": "Fadraciclib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Nausea (n=31), vomiting (n=23), thrombocytopenia (n=6). Grade 3 DLT of hyperglycemia (1/2 DL6 and 2/6 DL6a) and nausea (1/2 DL6) at doses above 125 mg bid M-F, 4/4 weeks.",
            "Other": "Preliminary PK analysis suggests dose-dependent exposure. Preliminary PD analysis showed suppression of expression in multiple target genes, including MYC, MCL1, CCNE/cyclin E, CDKN2A, CDKN2B, and PRMT5. Preliminary efficacy data: 2 PRs after the first cycle in one patient with CTCL and one with angioimmunoblastic T-Cell Lymphoma (AITL). 20 patients showed SD. Tumor size reduction observed in 10 patients.",
            "Conclusion": "Based on data from the CYC065-101 study, fadraciclib appears well tolerated from DL1 to 5. The MTD for the bid dosing schedule is 100 mg bid, M-F, 4/4 weeks. The trial is ongoing with evaluation of a daily dosing schedule."
        },
        {
            "Title": "PAS-004: A novel macrocyclic MEK inhibitor to inhibit cancer cell growth in vitro and tumor growth in mouse xenograft studies.",
            "Indication": "Solid tumors and Neurofibromatosis type 1 (NF1)",
            "Drug": "PAS-004",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "No significant differences in body weight loss between the compounds.",
            "Other": "PAS-004 strongly inhibited 5/10 NRAS mutant cancer lines with IC50 values ranging from 0.024 to 0.306 mM. Maximal growth inhibition of >50% was achieved by PAS-004 in 8 cell lines. PAS-004 achieved greater maximal inhibition in 7/10 lines vs selumetinib and binimetinib. In the HCC xenograft study, the highest dose of PAS-004 completely prevented tumor growth. PAS-004 at dose levels of 10 mg/kg and 5 mg/kg, once daily, exhibited higher efficacy compared to binimetinib and selumetinib at dose levels of 5 mg/kg and 2.5 mg/kg, respectively.",
            "Conclusion": "The novel macrocyclic MEK inhibitor PAS-004 inhibited NRAS mutant tumor growth in mouse xenograft studies with similar activity as the approved MEK inhibitors selumetinib and binimetinib. In vitro, PAS-004 provided greater maximal growth inhibition of NRAS lines than selumetinib and binimetinib. These results support investigating PAS-004 further and a clinical trial in patients with advanced solid tumors carrying RAS pathway mutations, including NRAS variants, is ongoing."
        },
        {
            "Title": "Nivolumab plus ipilimumab (N+I) in patients (pts) with solid tumors with high tumor mutational burden (HTMB): Results from the Targeted Agent and Profiling Utilization Registry (TAPUR) Study.",
            "Indication": "Advanced solid tumors with high tumor mutational burden (HTMB)",
            "Drug": "Nivolumab plus ipilimumab (N+I)",
            "PFS": "Median PFS: 9 weeks (95% CI: 8, 18)",
            "OS": "Median OS: 53 weeks (95% CI: 18, 127)",
            "ORR": "Objective Response Rate (ORR): 31% (95% CI: 14, 52)",
            "AE": "10 pts had \u22651 grade 3 tx-related adverse event (AE) or serious AE",
            "Other": "Disease Control (DC) rate: 35% (1-sided 90% CI: 22, 100)",
            "Conclusion": "N+I showed antitumor activity in pts with advanced solid tumors with HTMB. Additional study is warranted to confirm the efficacy of N+I in pts with HTMB."
        },
        {
            "Title": "Afatinib in patients (pts) with solid tumors with neuregulin 1 (NRG1) fusions: Results from the Targeted Agent and Profiling Utilization Registry (TAPUR) study.",
            "Indication": "Advanced solid tumors with neuregulin 1 (NRG1) fusions",
            "Drug": "Afatinib",
            "PFS": "None",
            "OS": "Median OS: 81 weeks",
            "ORR": "None",
            "AE": "No grade 3-5 tx-related adverse events (AE) or serious AEs were reported",
            "Other": "Disease Control (DC) rate: 3 pts achieved SD16+ (Stable Disease of at least 16 weeks duration)",
            "Conclusion": "Though a small sample size, afatinib demonstrated promising activity in pts with advanced solid tumors with NRG1 fusion, including very durable DC. Additional study in a larger group of pts is warranted to confirm the efficacy of afatinib in pts with NRG1 fusion."
        },
        {
            "Title": "Ex vivo assessment of cellular mass response for personalized therapy selection of hyperthermic intraperitoneal chemotherapy (HIPEC) agents.",
            "Indication": "Peritoneal carcinomatosis",
            "Drug": "Cisplatin, Oxaliplatin, Doxorubicin, Mitomycin C",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Feasibility of performing mass response testing (MRT) with peritoneal carcinomatosis tissues.",
            "Conclusion": "MRT is feasible for peritoneal carcinomatosis tissues, with future studies focusing on its role in guiding HIPEC treatment."
        },
        {
            "Title": "Phase II study to evaluate safety and efficacy of neratinib, an irreversible pan-HER tyrosine kinase inhibitor, and trastuzumab biosimilar in patients with HER2 mutated advanced solid tumors (KCSG AL20-17).",
            "Indication": "HER2 mutated advanced solid tumors",
            "Drug": "Neratinib, Trastuzumab biosimilar (Herzuma)",
            "PFS": "3.42 months (95% CI 1.57-5.27)",
            "OS": "9.47 months (95% CI 2.93-16.01)",
            "ORR": "23.1% (CR=0, PR=9)",
            "AE": "47.5% of patients had Grade \u22653 AEs; most frequent AE was diarrhea (25%)",
            "Other": "Median DoR was 11.18 months (95% CI 0-22.63)",
            "Conclusion": "Neratinib plus trastuzumab showed a moderate response rate with a durable duration of response in heavily pretreated patients with HER2 mutated solid tumors. Most AEs were manageable, highlighting the need for prophylactic diarrhea management."
        },
        {
            "Title": "Molecularly matched therapies identified by comprehensive genomic profiling before the first-line setting to provide alternative treatment outcomes in patients with solid tumors: 1-year follow-up of the prospective FIRST-Dx study.",
            "Indication": "Advanced solid tumors",
            "Drug": "Molecular-based recommended therapy (MBRT)",
            "PFS": "1st-line MBRT: 12.9 months [95% CI: 7.4 months to not reached], 1st-line SoC: 11.3 months [95% CI: 8.6-14.1 months], 2nd-line MBRT: 6.9 months [95% CI: 2.8 months to not reached], 2nd-line SoC: 5.7 months [95% CI: 3.7-7.4 months]",
            "OS": "Median OS not reached (95% CI: 18.4 months to not reached)",
            "ORR": "1st-line MBRT: 56.3% [95% CI: 29.9-80.2%], 1st-line SoC: 42.3% [95% CI: 33.9-51.1%], 2nd-line MBRT: 26.3% [95% CI: 9.1-51.2%], 2nd-line SoC: 17.1% [95% CI: 9.7-27.0%]",
            "AE": "None",
            "Other": "PFS ratio of 2nd-line MBRT (N=15) (PFS on MBRT in 2nd-line/PFS on 1st-line therapy), median PFS ratio was 1.0 (range: 0.1-14.6) and 4 pts (27%) had the PFS ratio of >1.3",
            "Conclusion": "MBRT identified by CGP test before the first-line setting provides better treatment outcomes than SoC in pts with solid tumors early in their disease course. Our data suggested that the timing of CGP test in Japan for pts with advanced solid tumors should be indicated before starting SoC."
        },
        {
            "Title": "The pathway alteration load as a pan-cancer determinant of outcome of targeted therapies: Results from the Drug Rediscovery Protocol (DRUP).",
            "Indication": "Treatment-refractory metastatic cancer",
            "Drug": "Targeted therapies (TT)",
            "PFS": "Median PFS: 4.7 months (PAL < 3) vs 2.9 months (PAL \u2265 3), adjusted hazard ratio [aHR] 1.70, P=0.020",
            "OS": "Median OS: 13.7 months (PAL < 3) vs 5.6 months (PAL \u2265 3), aHR 3.80, P < 0.001",
            "ORR": "Clinical benefit rate (CBR): 41.7% (PAL < 3) vs 25.5% (PAL \u2265 3), odds ratio [OR] 0.48, P=0.051",
            "AE": "None",
            "Other": "Results were consistent across different PAL cut-offs and when applying PAL continuously (aHR for PFS 1.41, P < 0.001; aHR for OS 1.77, P < 0.001). In the independent Hartwig cohort, similar results were found for CBR (54.2% vs 36.7%, OR 2.04, P=0.009) and PFS (7.0 vs 4.2 months, aHR 1.55, P=0.009), but not for OS (14.7 vs 8.2 months, aHR 1.37, P=0.111).",
            "Conclusion": "In our population, PAL emerged as a pan-cancer predictor of resistance to TT. Our findings provide support for more refined patient selection for TT and highlight the rationale for combinatorial treatment strategies, especially in patients with multiple affected pathways."
        },
        {
            "Title": "Single cell characterization of persistent cells upon immunotherapy treatment in colorectal and endometrial tumors: The SERPENTINE trial.",
            "Indication": "Refractory ICI-na\u00efve MSS colorectal cancer (CRC) and endometrial cancer (EC)",
            "Drug": "Durvalumab (Dur) plus Tremelimumab (Trem)",
            "PFS": "None",
            "OS": "None",
            "ORR": "Response rate (RR): 0%, Disease control rate: 12.5%",
            "AE": "None",
            "Other": "Following treatment, immune cell composition shifted to increased frequency of effector-memory, proliferating CD8+ cells and CD4+ cells, with enriched T helper phenotypes. Both lineages showed strong signs of exhaustion. The integration of matched T cell receptor (TCR) data pointed to an infiltration of new clonotypes, rather than the expansion of pre-existing tumor-resident T cells, with distinct degrees of dysfunctional phenotypes between the clones. Expanded clonotypes could be detected and monitored in peripheral blood cells using ultra-deep TCR sequencing.",
            "Conclusion": "Dual treatment with Dur and Trem does not result in an improved RR in patients with refractory MSS/pMMR CRC and EC, despite profound dynamics in the tumor infiltrating immune cells landscape. The infiltration of CD4+ T helper cells provides mechanistic insights of immune evasion, a potential avenue to treat MSS/pMMR tumors."
        },
        {
            "Title": "Evaluation of a predictive biomarker for antibody drug conjugates (ADCs). Role of dynamic ctDNA monitoring in cervical and anal epidermoid carcinomas under curative chemoradiation.",
            "Indication": "Cervical and anal epidermoid carcinomas",
            "Drug": "Antibody Drug Conjugates (ADCs)",
            "PFS": "8.2 months in ctDNA+ group, not reached in ctDNA- group (HR: 17.5; IC95%: 1.9-157.3; p=0.01)",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "ctDNA+ immediately post-CRT has a high predictive value for early access CRT non-responders",
            "Conclusion": "CtDNA immediately post-CRT has a high predictive value in patients with anal and cervical tumors in early access CRT non-responders, who are at a high risk of disease progression. This biomarker should be considered for tailoring strategies of treatment escalation in this population."
        },
        {
            "Title": "Pertuzumab plus trastuzumab (P+T) in patients (pts) with solid tumors with ERBB2 or ERBB3 amplification (amp) or mutations (mut): Results from the Targeted Agent and Profiling Utilization Registry (TAPUR) study.",
            "Indication": "Solid tumors with ERBB2 or ERBB3 amplification or mutations",
            "Drug": "Pertuzumab plus trastuzumab (P+T)",
            "PFS": "Median PFS: 21 weeks (95% CI: 9, 28)",
            "OS": "Median OS: 62 weeks (95% CI: 29, 83)",
            "ORR": "29% (95% CI: 13 to 49)",
            "AE": "5 pts had \u22651 grade 3 tx-related adverse event (AE) or grade 2-5 serious AE: anemia, cardiac arrest resulting in death, diarrhea, ejection fraction decrease, pleural effusion, supraventricular tachycardia, and vomiting",
            "Other": "Disease control rate (DC) of 57% (1-sided 90% CI: 43 to 100)",
            "Conclusion": "P+T showed antitumor activity in pts with advanced solid tumors with ERBB2/3 amp. Additional study is warranted to confirm the efficacy of P+T in this population."
        },
        {
            "Title": "Clinical and molecular characterization of AXL in colorectal cancer: CALGB (Alliance)/SWOG 80405 and real-world data.",
            "Indication": "Colorectal cancer (CRC)",
            "Drug": "Bevacizumab (Bev) and Cetuximab (Cet)",
            "PFS": "AXL-T3 showed significantly shorter PFS (T3: 9.2 vs T2: 11.7 vs T1: 12.9 months, T3 vs T1 (reference) adjusted HR 1.53 [1.19-1.97])",
            "OS": "AXL-H demonstrated worse OS in FOLFOX/FOLFIRI treated CRC (H: 35.7 vs L: 38.7 months, P = .003; HR 1.08, 95% CI [1.03-1.14]). The effect persisted within KRAS wildtype (wt) CRC (45.4 vs 40.1 months, P = .005; HR 1.12 [1.04-1.22]) but not KRAS mutant (mt).",
            "ORR": "None",
            "AE": "None",
            "Other": "AXL-H had increased B cells, M1/M2 macrophages, T-regs, and NK cells in the TME while increased neutrophils and dendritic cells were associated with AXL-L (all q, .001). AXL-H had increased PD-L1 positivity (5.1 vs 2.6%) and pathway activation of epithelial-mesenchymal transition (EMT), inflammatory/IFN-G response, and TNF-a signaling (all q, .005).",
            "Conclusion": "Our results indicate increased AXL expression is associated with immune cell infiltration, EMT, and inflammatory signaling. AXL-H confers worse OS/PFS on first-line chemotherapy, with a more pronounced effect in KRAS wt CRC. This data supports evaluation of AXL as a prognostic marker and potential therapeutic target in CRC."
        },
        {
            "Title": "Expression of metabolic genes associated with changes in brain structure across the lifespan affects patients (pts) outcomes in metastatic colorectal cancer (mCRC): Data from CALGB/SWOG 80405 (Alliance).",
            "Indication": "Metastatic colorectal cancer (mCRC)",
            "Drug": "Bevacizumab (Bev) and Cetuximab (Cet)",
            "PFS": "APOE-high (H) showed significantly shorter PFS (median 9.2 vs 11.8 vs 13.0 months (mo), high vs low (as reference) logrank P = .0021) in cet-treated pts. DACH1-H tumors had longer PFS (median 14.5 vs 10.6 vs 8.07 mo; logrank P = .0011) in cet-treated pts.",
            "OS": "APOE-high (H) showed significantly shorter OS (median 21.9 vs 35.8 vs 34.6 mo; logrank P = .0017) in cet-treated pts. DACH1-H tumors had longer OS (median 39.4 vs 30.9 vs 21.5 mo; logrank P = .0021) in cet-treated pts. In bev-treated pts, APOE-H showed shorter OS (median 25.8 vs 26.3 vs 37.5 mo; logrank P = .0016) and DACH1-H longer OS (median 36.5 vs 28.3 vs 22.4 mo; logrank P = .0017).",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The tumor expression of metabolic genes associated with changes in the brain structure was prognostic in mCRC pts treated with first-line therapy. Notably, APOE and DACH1 have been recently reported to play a role in CRC where APOE overexpression has been associated with aggressive biological behavior whereas DACH1 has been shown to suppress CRC cell growth and invasion. Our results support this evidence and suggest that further investigation into the role of APOE and DACH1 in CRC biology and into their potential as targets for drug development is warranted."
        },
        {
            "Title": "Comprehensive molecular profiling of newly diagnosed advanced, high-grade ovarian cancer: Unveiling BRCA1/2 mutations and genomic instability scores to advocate for public insurance coverage of genetic testing\u2014Insights from the Hellenic Society of Medical Oncology (HeSMO) national program.",
            "Indication": "Advanced high-grade ovarian cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "HRD positive: 48.14% (n=246), HRD negative: 46.58% (n=238), High GIS score: 44.81% (n=229), Low GIS score: 49.71% (n=254), BRCA1 mutations: 13.70% (n=70), BRCA2 mutations: 6.26% (n=32), Failed samples: 5.28% (n=27)",
            "Conclusion": "HRD and GIS are vital genomic instability biomarkers in high-grade ovarian cancer. These metrics provide valuable insights into tumor aggressiveness, guiding therapeutic decisions, including the use of PARP inhibitors. In this study of 511 patients from 50 centers across Greece, 48.14% tested positive for HRD, emphasizing PARP inhibitors\u2019 significance and suggesting hereditary implications. Our commitment to enhance patient access to reliable diagnostic biomarker testing in Greece is unquestionable. Furthermore, we believe, our results will strengthen our efforts towards official state reimbursement, paving the way for local HRD testing solutions."
        },
        {
            "Title": "Comprehensive characterization of ERBB2 genomic alterations in lung cancer.",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "Trastuzumab-deruxtecan",
            "PFS": "Shorter PFS for ECD vs. TKD ERBB2-mutant tumors (HR 2.4, 95% CI 0.7\u20138.2, p=0.04). Co-mutations in STK11 (HR 3.5, 0.4\u201327.4, p=0.03) and KEAP1 (HR 3.9, 0.7\u201320.6, p=0.002) conferred shorter PFS.",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Distinct genomic phenotype with higher median tumor mutational burden, median fraction of genome altered, and rate of co-mutations in EGFR, KRAS, STK11, KEAP1, and SMARCA4 for ECD vs. TKD ERBB2-mutant tumors.",
            "Conclusion": "NSCLC tumors harboring ECD ERBB2 mutations are associated with a distinct clinical and molecular phenotype and inferior outcomes with chemotherapy compared to patients with tumors harboring TKD mutations."
        },
        {
            "Title": "Selpercatinib in non-MTC, RET-mutated tumors: Efficacy in MEN-associated and other tumors.",
            "Indication": "RET-mutated tumors",
            "Drug": "Selpercatinib",
            "PFS": "Median PFS was 5.5 months (95% CI: 1.8, 19.4).",
            "OS": "None",
            "ORR": "Overall ORR by IRC was 21.7% (5/23, 95% CI: 7.5, 43.7). ORR by IRC for MEN-associated tumors was 37.5%. ORR for pheochromocytoma was 40.0% and for tumors with RET extracellular cysteine mutations was 60.0%.",
            "AE": "No new safety signals identified. One grade 5 AE (general physical health deterioration) deemed not related to selpercatinib.",
            "Other": "None",
            "Conclusion": "RET-mutated, MEN-associated cancers (adrenal, paraganglioma, neuroendocrine) may benefit from selpercatinib treatment, potentially mediated through mutations in the extracellular CRD. Non-MTC RET-mutated tumor types appear not to derive benefit from selpercatinib."
        },
        {
            "Title": "Clinical update related to the first-in-human trial of SYS6002 (CRB-701), a next-generation nectin-4 targeting antibody drug conjugate.",
            "Indication": "Nectin-4 positive solid tumors",
            "Drug": "SYS6002 (CRB-701)",
            "PFS": "None",
            "OS": "None",
            "ORR": "Unconfirmed ORR of 50% at doses \u22652.7 mg/kg. ORR for UC was 50% (1/2), and for cervical cancer was 67% (2/3).",
            "AE": "No dose limiting toxicities. Majority of AEs were grade 1 or 2. No low-grade peripheral neuropathy, skin rash, or fatigue observed.",
            "Other": "SYS6002 (CRB-701) demonstrated a longer ADC half-life and lower free-MMAE exposures relative to enfortumab vedotin (EV) at comparable dose levels.",
            "Conclusion": "SYS6002 (CRB-701) demonstrates promising anti-tumor activity with a well-tolerated safety profile in patients with advanced nectin-4 positive solid tumors. Dose escalation at 4.5 mg/kg Q3W and dose expansion at 3.6 mg/kg Q3W are ongoing."
        },
        {
            "Title": "A phase I study of TAK-659 and paclitaxel in patients with taxane-refractory advanced solid tumors.",
            "Indication": "Taxane-refractory advanced solid tumors",
            "Drug": "TAK-659 and paclitaxel",
            "PFS": "None",
            "OS": "None",
            "ORR": "9% partial response, 27% stable disease",
            "AE": "47% grade 3 or more adverse events, including decreased neutrophil count (14%), elevated lipase (12%), anemia (10%), increased amylase (8%), and decreased white blood cell counts (8%)",
            "Other": "None",
            "Conclusion": "The combination of paclitaxel and TAK-659 was well tolerated and showed preliminary modest efficacy which could be possibly overcoming resistance to taxane-based therapy in patients with advanced solid tumors."
        },
        {
            "Title": "Results of IMPACT2, a randomized study evaluating molecular profiling and targeted agents in metastatic cancer at MD Anderson Cancer Center.",
            "Indication": "Metastatic cancer",
            "Drug": "Matched targeted therapies (MTT) vs non-targeted therapy (NTT)",
            "PFS": "Part A: MTT - 3.29 months (95% CI, 2.33, 4.9), NTT - 3.27 months (95% CI, 2.76, 4.87); Part B: MTT - 2.73 months (95% CI, 2.3, NR), NTT - 2.53 months (95% CI, 1.78, NR)",
            "OS": "Part A: MTT - 8.58 months (95% CI, 6.48, 20.15), NTT - 9.52 months (95% CI, 7.79, 28.14); Part B: MTT - 5.62 months (95% CI, 3.65, NR), NTT - 6.67 months (95% CI, 6.05, NR)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Considering the challenges to randomize patients and that the study was underpowered for its original goal, no differences in response, PFS, or OS were noted between the two arms, possibly because of the advanced stage disease and the complexity of tumor biology that cannot be addressed by randomization."
        },
        {
            "Title": "A phase 1 study of HRS-1167 (M9466), a highly selective PARP1 inhibitor, in patients with advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "HRS-1167 (M9466)",
            "PFS": "None",
            "OS": "None",
            "ORR": "41.7% objective responses in patients with HRR mutations",
            "AE": "30% grade 3 or more treatment-related adverse events, including anemia (15%), decreased neutrophil count (12.5%), and decreased WBC count (12.5%)",
            "Other": "None",
            "Conclusion": "HRS-1167 was well-tolerated and exhibited favorable safety and PK profiles in patients with advanced solid tumors, and demonstrated promising anti-tumor activity in pretreated patients with HRR mutations."
        },
        {
            "Title": "A randomized, double-blind, study to evaluate the efficacy, pharmacodynamics, safety and immunogenicity of FKS518 proposed biosimilar to denosumab with the originator in postmenopausal women with osteoporosis (LUMIADE-3 study).",
            "Indication": "Postmenopausal osteoporosis",
            "Drug": "FKS518 (biosimilar to denosumab)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "67.6% treatment-emergent adverse events: 66.8% in the FKS518 group and 68.5% in the originator product group",
            "Other": "Clinically relevant increases in LS-BMD were evident at week 52 in both the FKS518 and originator product groups. The results demonstrated therapeutic equivalence.",
            "Conclusion": "We demonstrated the therapeutic equivalence of FKS518 and originator product. The safety data showed similar safety profiles for the FKS518 and the originator product groups. Results from this study add to the totality of evidence supporting the similarity of FKS518 as a proposed biosimilar to denosumab originator product."
        },
        {
            "Title": "Genomics to select treatment for patients (pts) with metastatic cancer: Final analysis of Molecular Tumor Board (MTB) evaluations in the ROME trial.",
            "Indication": "Refractory metastatic cancer",
            "Drug": "Tailored treatment (TT) driven by extensive genomic tests and Molecular Tumor Board (MTB) evaluation",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "MTB assigned ICIs to 190 pts (155 to ipilimumab plus nivolumab; 8 to nivolumab) due to high (\u226510 mut/mb) TMB. 234 pts had indications to target therapy: 86 pts (37%) to PI3K/AKT/mTOR inhibitors (i), 59 (25%) anti-HER2 agents, 34 (15%) FGFRi, 31 (13%) BRAF/MEKi, 7 (3%) PARPi, 5 (2%) CDK4/6i, 4 (2%) ALKi, 2 (.1%) NTRKi, 2 (.1%) RETi, 2 (.1%) Hedgehog-i, 1 (.1%) JAKi, 1 (.1%) MEKi. 27 pts had indication to ICIs plus target therapy combinations. Additionally, 150 pts with potentially hereditary variants were addressed to genetic counselling and germline testing. 63 pts were referred by MTB to other clinical trials even if considered SF for the ROME trial, 34 patients received from the MTB a modification of the initially proposed standard therapy and 4 had both suggestions.",
            "Conclusion": "Our study provides evidence that MTB discussion of comprehensive genomic profiling data identifies a personalized treatment in a large (36%) fraction of patients, thus outperforming the traditional histological approach."
        },
        {
            "Title": "Phase II study of the combination of lenvatinib (L) and eribulin (E) in advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "Lenvatinib and eribulin",
            "PFS": "7.4 months",
            "OS": "8.2 months",
            "ORR": "24%",
            "AE": "Grade \u22653 neutropenia seen in 34.5%, febrile neutropenia in 17.2%, and hypertension in 13.8% of patients. Treatment-related adverse events leading to study-drug discontinuation occurred in 6.8% of patients.",
            "Other": "Vimentin-negative patients exhibited significantly increased OS (fold change 4.374, p < 0.001) and PFS (fold change 3.395, p < 0.001) after adjusting for age, CD31, and CA9.",
            "Conclusion": "Lenvatinib combined with eribulin showcased promising anti-tumor activity in heavily pretreated patients with metastatic breast carcinoma, lung carcinoma, and sarcoma. Although manageable, neutropenia emerged as a notable adverse effect. These findings underscore the potential of this combination as a therapeutic strategy for advanced solid tumors, warranting further clinical investigation. The observed significant survival benefits in Vimentin-negative patients align with the biological role of Vimentin in tumor invasiveness and metastatic potential, supporting the plausibility of these results biologically."
        },
        {
            "Title": "An open-label phase 1 study to investigate SGN-CEACAM5C/SAR445953 in adults with advanced solid tumors (SGNCEA5C-001)",
            "Indication": "Advanced solid tumors including non-small cell lung cancer (NSCLC), small cell lung cancer (SCLC), colorectal cancer (CRC), gastric adenocarcinoma (GC), and pancreatic ductal adenocarcinoma (PDAC)",
            "Drug": "SGN-CEACAM5C",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Primary endpoints include incidence of AEs, laboratory abnormalities, dose modifications due to AEs, dose-limiting toxicities, and cumulative safety",
            "Other": "Secondary endpoints include estimates of PK parameters, incidence of antidrug antibodies, objective response rate and best response per RECIST v1.1 by investigator, duration of response, PFS, and OS",
            "Conclusion": "Recruitment is ongoing for Part A in North America"
        },
        {
            "Title": "A multi-center, open-label phase 1/1b dose finding, safety, and pharmacokinetic study of MBRC-101, an Anth-EphA5 monomethylauristatin (MMAE) antibody drug conjugate, in advanced refractory solid tumors",
            "Indication": "Advanced refractory solid tumors",
            "Drug": "MBRC-101",
            "PFS": "None",
            "OS": "None",
            "ORR": "Primary endpoints are MTD, dose limiting toxicities (DLTs), treatment emergent adverse events (TEAEs), and clinical laboratory tests",
            "AE": "Primary endpoints include TEAEs, clinical laboratory tests",
            "Other": "Secondary endpoints for Ph1 and 1b include PK analytes and EphA5 expression as determined by immunohistochemistry (IHC)",
            "Conclusion": "A Safety Review Committee will monitor safety at each dose escalation in Phase 1 and at regular intervals throughout Phase 1b"
        },
        {
            "Title": "Phase 1b study evaluating the efficacy and safety of ABBV-400, a c-Met\u2013targeting antibody-drug conjugate, in select advanced solid tumor indications",
            "Indication": "Select advanced solid tumors including hepatocellular carcinoma (HCC), pancreatic ductal adenocarcinoma (PDAC), biliary tract cancers (BTC), esophageal squamous cell carcinoma (ESCC), breast cancer (BC), and head and neck squamous cell carcinoma (HNSCC)",
            "Drug": "ABBV-400",
            "PFS": "Secondary efficacy endpoints include progression-free survival",
            "OS": "Secondary efficacy endpoints include overall survival",
            "ORR": "Primary efficacy endpoint is objective response rate",
            "AE": "Safety evaluations include adverse events monitoring (graded according to the NCI CTCAE, v5.0), physical examinations, vital sign measurements, ECG variables, and clinical laboratory testing",
            "Other": "Secondary objectives include the evaluation of pharmacokinetics (PK) and immunogenicity of ABBV-400. Pharmacodynamic (PD) and biomarker analyses are exploratory endpoints",
            "Conclusion": "Enrollment started in November 2023. As of 19 January 2024, 24 patients have been enrolled"
        },
        {
            "Title": "CLARITY-PanTumor01: A phase 2 trial of the claudin18.2-specific antibody-drug conjugate AZD0901 (CMG901) in patients with CLDN18.2-expressing advanced solid tumors",
            "Indication": "CLDN18.2-expressing advanced solid tumors including gastric/gastroesophageal junction (G/GEJ) cancer and pancreatic ductal adenocarcinoma (PDAC)",
            "Drug": "AZD0901",
            "PFS": "Secondary endpoints include progression-free survival",
            "OS": "Secondary endpoints include overall survival",
            "ORR": "Primary endpoints include objective response rate per RECIST v1.1",
            "AE": "Primary endpoints include safety and tolerability",
            "Other": "Secondary endpoints include duration of response, disease control rate, best change in target lesion size, pharmacokinetics, immunogenicity, and pharmacodynamics",
            "Conclusion": "Recruitment began in December 2023, and sites across Australia, Asia, Europe, and North America will enroll patients"
        },
        {
            "Title": "HERTHENA-PanTumor01: A global, multicohort, phase 2 trial of HER3-DXd in relapsed/refractory metastatic solid tumors.",
            "Indication": "Cutaneous melanoma, HER2-negative gastric cancer, or head and neck squamous cell cancer (HNSCC)",
            "Drug": "HER3-DXd",
            "PFS": "None",
            "OS": "None",
            "ORR": "Primary endpoint: ORR by investigator per RECIST version 1.1",
            "AE": "None",
            "Other": "Secondary endpoints include safety and tolerability, DOR, DCR, PFS, overall survival, pharmacokinetics, and the correlation between HER3 IHC protein expression and efficacy.",
            "Conclusion": "The study is currently enrolling patients and aims to evaluate the efficacy and safety of HER3-DXd in previously treated patients with specific solid tumors."
        },
        {
            "Title": "A phase 1/2, first-in-human study of DS-3939a in patients with advanced solid tumors: A new DXd ADC targeting TA-MUC1.",
            "Indication": "Advanced solid tumors expressing TA-MUC1",
            "Drug": "DS-3939a",
            "PFS": "Secondary endpoint",
            "OS": "Secondary endpoint",
            "ORR": "Primary endpoint: Objective tumor response rate per RECIST version 1.1",
            "AE": "Primary endpoint: Safety and tolerability assessed by the number of patients with dose-limiting toxicities (Part 1 only), treatment-emergent adverse events, and other safety parameters.",
            "Other": "Secondary endpoints include disease control rate, duration of response, time to response, TA-MUC1 expression, pharmacokinetics, and the number of patients with anti-drug antibodies against DS-3939a.",
            "Conclusion": "The study is active and plans to enroll up to 430 adult patients to assess the safety, tolerability, and efficacy of DS-3939a."
        },
        {
            "Title": "A phase 1, first-in-human study of CUSP06, a cadherin-6 (CDH6)-directed antibody-drug conjugate, in patients with platinum-refractory/resistant ovarian cancer and other advanced solid tumors.",
            "Indication": "Platinum-refractory/resistant ovarian cancer (PRROC), advanced renal cell carcinoma (RCC), and other advanced CDH6-positive solid tumors",
            "Drug": "CUSP06",
            "PFS": "None",
            "OS": "None",
            "ORR": "Preliminary efficacy",
            "AE": "Primary endpoint: Safety and tolerability",
            "Other": "The study will evaluate pharmacokinetics, pharmacodynamics, recommended Phase 2 dose (RP2D), and correlation of CDH6 levels with response, and other RNA and protein markers of sensitivity and resistance.",
            "Conclusion": "The study is currently enrolling in Phase 1a dose-escalation to evaluate the safety, tolerability, and preliminary efficacy of CUSP06."
        },
        {
            "Title": "An open-label, multicenter phase 1 study to characterize safety, tolerability, preliminary antitumor activity, pharmacokinetics, and pharmacodynamics of VIP943 monotherapy in patients with advanced CD123+ hematologic malignancies.",
            "Indication": "Relapsed or refractory CD123+ hematologic malignancies including AML, myelodysplastic syndrome, and B-cell acute lymphoblastic leukemia",
            "Drug": "VIP943",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Primary endpoint: Safety and tolerability assessed by the incidence of dose-limiting toxicities (DLTs)",
            "Other": "The study will characterize the pharmacokinetics of VIP943 and its components, explore potential biologic activity by assessing pharmacodynamic/exploratory biomarkers, and antitumor activity using standard response criteria.",
            "Conclusion": "The study is in progress and aims to derive a recommended dose range for Phase 2 based on safety and preliminary efficacy data."
        },
        {
            "Title": "An open-label, multicenter phase 1 study to characterize safety, tolerability, preliminary antitumor activity, and pharmacokinetics of VIP236 monotherapy in patients with advanced cancer.",
            "Indication": "Advanced cancer",
            "Drug": "VIP236",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Frequency, severity, and relationship to study drug of any treatment-emergent adverse events or abnormalities of laboratory tests will be studied.",
            "Other": "Tumor response will be assessed using RECIST 1.1 criteria.",
            "Conclusion": "Enrollment continues in the Q3W cohorts. Clinical trial information: NTC05371054. Research Sponsor: Vincerx Pharma."
        },
        {
            "Title": "Dose-expansion part of a phase 1b global study of E7386 in combination with lenvatinib (LEN) in patients (pts) with hepatocellular carcinoma (HCC) and other solid tumors including endometrial cancer (EC).",
            "Indication": "Hepatocellular carcinoma (HCC) and endometrial cancer (EC)",
            "Drug": "E7386 in combination with lenvatinib (LEN)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Adverse events will be monitored and recorded for up to 30 days after the last dose or until initiation of a new anticancer therapy, whichever occurs first.",
            "Other": "Efficacy of LEN monotherapy will be assessed for HCC (secondary endpoint). Tumors will be assessed by investigator per RECIST v1.1 every 8 weeks from the date of the first dose.",
            "Conclusion": "As of February 5, 2024, 46 pts with HCC and 16 pts with EC have enrolled. Clinical trial information: NCT04008797. Research Sponsor: Eisai Inc., Nutley, NJ, USA."
        },
        {
            "Title": "A modular, open-label, phase I/IIa study to evaluate the safety, tolerability, pharmacokinetics (PKs) and anti-tumour activity of a first-in-class (FIC) specific dUTPase inhibitor CV6-168 combined with anti-cancer treatments in patients (pts) with advanced malignancies.",
            "Indication": "Advanced malignancies",
            "Drug": "CV6-168 combined with anti-cancer treatments",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Primary objective: characterise safety and tolerability of CV6-168 with 5-FU and FA.",
            "Other": "Secondary objectives: establish PK profile of CV6-168 and 5-FU alone and in combination and evaluate anti-tumour activity of the combination.",
            "Conclusion": "This trial design allows one protocol responsive to emerging data for a compound with multiple hypotheses, supporting studies in multiple combinations, reducing time from emerging data to \u2018first subject in study' compared with multiple individual studies. Clinical trial information: ISRCTN12434145. Research Sponsor: CV6 Therapeutics (NI) Ltd."
        },
        {
            "Title": "A phase 1, first-in-human, open-label study evaluating the safety, tolerability, pharmacokinetics, and efficacy of TT125-802 in patients with advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "TT125-802",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "The maximum tolerated dose/recommended dose for expansion (MTD/RDE) of repeat daily dosing for TT125-802 monotherapy will be determined in subjects with advanced solid tumors in a sequential escalating cohorts design.",
            "Other": "Translational/pharmacodynamic endpoints: Exploratory endpoints will provide proof-of-principle and early biomarker data for CBP/p300 inhibition.",
            "Conclusion": "The recommended phase 2 dose will be used further to explore the efficacy of TT125-802 as monotherapy. Clinical trial information: 2022-500849-24-00. Research Sponsor: TOLREMO Therapeutics AG."
        },
        {
            "Title": "A phase 1 trial evaluating the safety, tolerability, PK, and preliminary efficacy of QTX3034, an oral G12D-preferring multi-KRAS inhibitor, in patients with solid tumors with KRAS G12D mutation.",
            "Indication": "Solid tumors with KRAS G12D mutation",
            "Drug": "QTX3034",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "This clinical trial is now enrolling patients with advanced solid tumors harboring a KRAS G12D mutation to evaluate the safety, tolerability, and preliminary antitumor activity of QTX3034 alone and in combination with cetuximab."
        },
        {
            "Title": "Study of ELU001, a C\u2019Dot drug conjugate (CDC) targeting folate receptor alpha (FRa) overexpressing solid tumors.",
            "Indication": "Ovarian and endometrial cancers",
            "Drug": "ELU001",
            "PFS": "None",
            "OS": "None",
            "ORR": "2 had PR and 19 had SD by RECIST v1.1 criteria",
            "AE": "None",
            "Other": "None",
            "Conclusion": "ELU001 demonstrated activity against tumors across a range of FRa expression levels, from low to high."
        },
        {
            "Title": "First-in-human, phase 1/2 study of GSK4524101, an oral DNA polymerase theta inhibitor (POLQi), alone or combined with the poly(ADP-ribose) polymerase (PARP) inhibitor (PARPi) niraparib in adults with solid tumors.",
            "Indication": "Advanced or metastatic solid tumors",
            "Drug": "GSK4524101 with or without niraparib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "This study aims to assess the maximum-tolerated dose, pharmacokinetics, safety, and preliminary antitumor activity of oral GSK4524101 with or without niraparib."
        },
        {
            "Title": "A first-in-human, phase 1/2 trial of FOG-001, a b-catenin: TCF antagonist, in patients with locally advanced or metastatic solid tumors.",
            "Indication": "Locally advanced or metastatic solid tumors",
            "Drug": "FOG-001",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "This study is evaluating the safety/tolerability, pharmacokinetics, pharmacodynamics, and antitumor activity of FOG-001 in patients with advanced or metastatic solid tumors."
        },
        {
            "Title": "A first-in-human, phase 1 study evaluating oral TACC3 inhibitor, AO-252, in advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "AO-252",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Primary objectives are to evaluate the safety and tolerability as noted by dose-limiting toxicities, adverse events, serious adverse events, laboratory test results, electrocardiograms, vital signs, physical exams, and ECOG performance status. Secondary objectives are to determine the antitumor activity assessed by objective response rate per RECIST 1.1, duration of response and disease control rate, and to determine the pharmacokinetics of single and repeated doses of AO-252. Potential biomarkers relating to treatment outcome will be evaluated as exploratory endpoints."
        },
        {
            "Title": "177Lu-edotreotide versus everolimus in patients with advanced neuroendocrine tumors of lung or thymic origin: The phase 3 randomized LEVEL, GETNE-T2217 trial.",
            "Indication": "Advanced neuroendocrine tumors of lung or thymic origin",
            "Drug": "177Lu-edotreotide, Everolimus",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The primary endpoint is PFS according to RECIST v1.1. Secondary endpoints include overall response rate, duration of response, overall survival, safety, and quality of life (assessed through EORTC QLQ-C30)."
        },
        {
            "Title": "An open-label study to assess the safety and efficacy of naporafenib (ERAS-254) administered with trametinib in previously treated patients with locally advanced unresectable or metastatic solid tumor malignancies with RAS Q61X mutations (SEACRAFT-1).",
            "Indication": "Locally advanced unresectable or metastatic solid tumor malignancies with RAS Q61X mutations",
            "Drug": "Naporafenib, Trametinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "Primary endpoint",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The primary endpoint is ORR; secondary endpoints include assessment of PFS, DOR, safety, and tolerability."
        },
        {
            "Title": "PRIMROSE: A modular phase 1/2a study of AZD3470, an MTA-cooperative PRMT5 inhibitor, in patients with MTAP deficient advanced solid tumors.",
            "Indication": "MTAP deficient advanced solid tumors",
            "Drug": "AZD3470",
            "PFS": "None",
            "OS": "None",
            "ORR": "Secondary objective",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The primary objectives are to assess safety and tolerability, including dose-limiting toxicities, and to determine the recommended phase 2 dose of AZD3470. The secondary objective is evaluation of efficacy, including objective response rate, duration of response, disease control rate, change in tumor size, progression-free survival, and overall survival. An additional secondary objective in Module 1 is to assess pharmacokinetics."
        },
        {
            "Title": "A phase 1, open-label, dose escalation and dose expansion study to evaluate the safety, tolerability, pharmacokinetics, and antitumor activity of PF-07799544 (ARRY-134) as a single agent and in combination with PF-07799933 BRAF dimer inhibitor, in participants 16 years and older with advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "PF-07799544 (ARRY-134) and PF-07799933",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The study aims to investigate the safety, tolerability, pharmacokinetics, pharmacodynamics, and potential clinical benefits of PF-07799544 administered as a single agent and in combination with PF-07799933 in participants with BRAF mutated advanced solid tumors."
        },
        {
            "Title": "A phase I/II, first-in-human study of VLS-1488, an oral KIF18A inhibitor, in patients with advanced cancer.",
            "Indication": "Advanced cancer with high prevalence of chromosomal instability (CIN)",
            "Drug": "VLS-1488",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The study evaluates the safety, tolerability, pharmacokinetics, and preliminary anti-tumor activity of VLS-1488 in advanced solid tumors with high prevalence of CIN."
        },
        {
            "Title": "A phase 1 study of the BET inhibitor ZEN003694 in combination with the MEK1/2 inhibitor binimetinib in solid tumors with RAS pathway alterations and triple-negative breast cancer (NCI number 10449).",
            "Indication": "Solid tumors with RAS pathway alterations and triple-negative breast cancer",
            "Drug": "ZEN003694 and binimetinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The study aims to evaluate the maximum tolerated dose (MTD) and dose-limiting toxicities (DLTs) of the BET inhibitor ZEN003694 in combination with the MEK inhibitor binimetinib in solid tumors with RAS pathway alterations and triple-negative breast cancer."
        },
        {
            "Title": "Phase II study of enasidenib in IDH2-mutated malignant sinonasal and skull base tumors.",
            "Indication": "IDH2-mutated malignant sinonasal and skull base tumors",
            "Drug": "Enasidenib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The study investigates the activity of enasidenib in adult patients with histologically or cytologically confirmed locally advanced or metastatic IDH2-mut sinonasal cancers."
        },
        {
            "Title": "Phase 1/2 study of FMC-376 an oral KRAS G12C dual inhibitor in participants with locally advanced unresectable or metastatic solid tumors (PROSPER).",
            "Indication": "Locally advanced unresectable or metastatic solid tumors with KRAS G12C mutations",
            "Drug": "FMC-376",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The study is designed to evaluate the safety, tolerability, PK/PD, and anti-tumor activity of FMC-376. The study is open to enrollment as of February 2024."
        },
        {
            "Title": "A first-in-human study of IK-595, an oral MEK/RAF molecular glue, in patients with RAS- or RAF-altered advanced solid tumors.",
            "Indication": "Advanced solid tumors with RAS or RAF alterations",
            "Drug": "IK-595",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The study aims to evaluate the safety and tolerability of IK-595 and determine the recommended phase 2 dose (RP2D) and/or maximum tolerated dose (MTD). Dose escalation continues to enroll."
        },
        {
            "Title": "IAM1363-01: A phase 1/1b study of a selective and brain-penetrant HER2 inhibitor for HER2-driven solid tumors.",
            "Indication": "HER2-driven solid tumors",
            "Drug": "IAM1363",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The trial is designed to establish the safety profile of IAM1363 as a single agent and in combination with trastuzumab and to provide initial proof of antitumor activity in malignancies with HER2 overexpression, gene amplification, or tyrosine kinase mutations, including in patients with brain metastases."
        },
        {
            "Title": "Pilot study of CBX-12 pharmacodynamics in patients with advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "CBX-12",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The study aims to assess the effects of CBX-12 on biomarkers of DNA damage response (DDR) in biopsy specimens to establish the degree and duration of CBX-12 target engagement. The study results may help identify future patient populations likely to benefit from CBX-12 treatment."
        },
        {
            "Title": "Phase I/II study of pegylated arginine deiminase (ADI-PEG20) in combination with gemcitabine and docetaxel in non-small cell and small cell lung cancers.",
            "Indication": "Non-small cell lung cancer (NSCLC) and small cell lung cancer (SCLC)",
            "Drug": "ADI-PEG20, gemcitabine, docetaxel",
            "PFS": "None",
            "OS": "None",
            "ORR": "Primary objective of the phase II portion is to determine the objective response rate (ORR) in each cohort.",
            "AE": "None",
            "Other": "Primary objectives of the phase I portion are to determine the safety, tolerability, and recommended phase II dose (RP2D) of combination ADI-PEG20, gemcitabine, and docetaxel.",
            "Conclusion": "Patient accrual at the maximum tolerated dose level is nearing completion and ADI-PEG in combination with gemcitabine and docetaxel will be further evaluated in the phase II with separate cohorts for NSCLC and SCLC."
        },
        {
            "Title": "A phase 1/1b first-in-human (FIH) study of JZP898 as monotherapy and in combination with pembrolizumab in adult patients with advanced or metastatic solid tumors.",
            "Indication": "Advanced or metastatic solid tumors",
            "Drug": "JZP898, pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "Primary endpoints include investigator-assessed objective response rate (ORR) per RECIST v1.1 (Part B).",
            "AE": "Primary endpoints include the incidence and nature of dose-limiting toxicities (DLTs) (Part A); the incidence and severity of treatment-emergent adverse events (TEAEs) and serious adverse events; changes in laboratory values or vital signs; and the incidence of dose modifications or discontinuations due to TEAEs.",
            "Other": "Secondary endpoints include pharmacokinetic (PK) parameters; incidence of antidrug antibodies; investigator-assessed duration of response; progression-free survival; disease control rate; investigator-assessed ORR (Part A); and overall survival (Part B).",
            "Conclusion": "The study is actively enrolling patients with a planned enrollment of up to 177 patients."
        },
        {
            "Title": "A first-in-human (FIH), phase 1/2, dose-escalation, dose-optimization, and dose-expansion study of PARP1-selective inhibitor IMP1734 in participants with advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "IMP1734",
            "PFS": "Secondary endpoints include measures of efficacy, including progression-free survival.",
            "OS": "Secondary endpoints include measures of efficacy, including overall survival.",
            "ORR": "Secondary endpoints include measures of efficacy, including overall response rate.",
            "AE": "Primary endpoints include safety and tolerability (evaluation of dose-limiting toxicities and determining the maximum tolerated dose, maximum achievable dose, and recommended dose for expansion [RDE]).",
            "Other": "Secondary endpoints include evaluation of the pharmacokinetic parameters of IMP1734. Exploratory endpoints include analysis of pharmacodynamic biomarkers, serial ctDNA measurements, and patient quality-of-life measures.",
            "Conclusion": "This study opened on 11 Dec 2023."
        },
        {
            "Title": "Chemotherapy and liver transplantation versus chemotherapy alone in patients with definitively unresectable colorectal liver metastases: A prospective multicentric randomized trial (TRANSMET).",
            "Indication": "Unresectable colorectal liver metastases (uCLM)",
            "Drug": "Chemotherapy (CT) and liver transplantation (LT)",
            "PFS": "Median PFS was 17.4 months in the CT+LT arm versus 6.4 months in the CT alone arm (HR 0.34; 95% CI 0.20-0.58)",
            "OS": "5-year OS was 57% in the CT+LT arm and 13% in the CT alone arm (log-rank test: p=0.0003, HR 0.37; 95% CI 0.21-0.65). In per protocol analysis, 5-year OS rate was 73% and 9%, respectively (HR 0.16; 95% CI 0.07-0.33)",
            "ORR": "None",
            "AE": "None",
            "Other": "Among transplanted patients, 28 (74%) had lung (39%), liver (3%), other (21%) or multisite (11%) recurrence, optionally treated by surgery (36%) or local ablation (11%). Fifteen (40%) patients were ultimately disease-free.",
            "Conclusion": "LT combined with CT significantly improved survival in selected patients with uCLM compared to CT alone. These results argue for validating LT as a new standard option that may change the treatment strategy for liver-only uCLM patients."
        },
        {
            "Title": "Primary outcome analysis of the ORCHESTRA trial: A randomized phase III trial of additional tumor debulking to first-line palliative systemic therapy for patients with multiorgan metastatic colorectal cancer.",
            "Indication": "Multiorgan metastatic colorectal cancer",
            "Drug": "Nivolumab (NIVO) plus ipilimumab (IPI) vs chemotherapy (chemo)",
            "PFS": "NIVO+IPI demonstrated superior PFS vs chemo (HR 0.21; 97.91% CI 0.13\u20130.35; P < 0.0001). Median PFS2 was not reached (NR) with NIVO+IPI and 29.9 months with chemo (HR 0.27; 95% CI 0.17\u20130.44)",
            "OS": "None",
            "ORR": "None",
            "AE": "Any grade and grade 3/4 treatment-related adverse events (TRAEs): NIVO+IPI: 160 (80%) / 46 (23%); Chemo: 83 (94%) / 42 (48%)",
            "Other": "12-month PFS2 rate: NIVO+IPI: 89% (95% CI 83\u201393); Chemo: 65% (95% CI 53\u201375)",
            "Conclusion": "Clinical benefit with 1L NIVO+IPI vs chemo was maintained after subsequent therapy, as shown by improved PFS2 in patients with centrally confirmed MSI-H/dMMR mCRC. No new safety concerns were identified with NIVO+IPI. These results further support NIVO+IPI as a standard-of-care 1L treatment option for patients with MSI-H/dMMR mCRC."
        },
        {
            "Title": "NEOPRISM-CRC: Neoadjuvant pembrolizumab stratified to tumour mutation burden for high risk stage 2 or stage 3 deficient-MMR/MSI-high colorectal cancer.",
            "Indication": "High risk stage 2 or stage 3 deficient-MMR/MSI-high colorectal cancer",
            "Drug": "Pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "Neoadjuvant treatment of IBI310 (anti-CTLA-4 antibody) plus sintilimab (anti-PD-1 antibody) in patients with microsatellite instability-high/mismatch repair-deficient colorectal cancer: Results from a randomized, open-labeled, phase Ib study.",
            "Indication": "Microsatellite instability-high/mismatch repair-deficient colorectal cancer",
            "Drug": "IBI310 plus sintilimab",
            "PFS": "None",
            "OS": "None",
            "ORR": "80.0% (arm A) vs 47.7% (arm B)",
            "AE": "88.5% (grade \u22653 in 13 pts) in arm A and 79.6% (grade \u22653 in 9 pts) in arm B",
            "Other": "Immune-related adverse events (irAEs) occurred in 42.3% in arm A and 36.7% in arm B. Grade \u22653 irAEs occurred in 3 pts in arm A and 4 pts in arm B. Serious TRAEs occurred in 7.7% in arm A and 6.1% in arm B. TRAE leading to death occurred in 1 pt in arm B.",
            "Conclusion": "Neoadjuvant IBI310 plus sintilimab significantly improved pCR rate than sintilimab alone in MSI-H/dMMR CRC. The safety profiles were comparable and manageable in both treatment arms."
        },
        {
            "Title": "FU/FA maintenance therapy with or without panitumumab (pmab) in RAS wild-type metastatic colorectal cancer (mCRC) (PanaMa, AIOKRK0212): Updated efficacy analyses.",
            "Indication": "RAS wild-type metastatic colorectal cancer",
            "Drug": "Panitumumab",
            "PFS": "8.8 vs. 5.8 months, HR=0.73 (95% CI 0.56\u20130.94), P=0.015",
            "OS": "29.9 vs. 24.7 months, HR=0.85 (95% CI 0.64\u20131.12), P=0.24",
            "ORR": "74.4% vs. 76.4% during induction, 40.8% vs. 29.3% during maintenance, 8.0% vs. 35.9% at re-induction",
            "AE": "None",
            "Other": "PFS re-induction: 4.1 vs. 7.4 months, HR=1.93 (95% CI 1.33\u20132.82), P<0.001. TFS: 17.1 vs. 15.7 months, HR=0.98 (95% CI 0.68\u20131.42), P=0.92.",
            "Conclusion": "PFS remained improved by the addition of pmab to FU/FA maintenance therapy, while OS was not significantly longer. Re-induction of pmab + mFOLFOX6 did not provide benefit following pmab maintenance regarding PFS."
        },
        {
            "Title": "Acquired gene alteration patterns and post-progression survival: PARADIGM study analysis.",
            "Indication": "Metastatic colorectal cancer",
            "Drug": "Panitumumab (PAN) and Bevacizumab (BEV)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "PAN pts with acquired RTK/RAS alterations had shorter PPS than those without alterations (13.2 vs 18.8 mo, HR 1.88 [95% CI: 1.28\u20132.76]). BEV pts with acquired CIMP pathway alterations had shorter PPS than those without (14.9 vs 18.6 mo; HR 1.58 [1.09\u20132.29]). Co-occurrence of gene alterations was more frequent in PAN vs BEV (overall 52.4% vs 43.3%, RTK/RAS gene co-alterations 25% vs 10%).",
            "Conclusion": "Distinctive patterns of acquired gene alterations were observed in PAN- and BEV-treated pts with PD. Co-occurrence of gene alterations, particularly in the RTK/RAS pathway, was more prevalent with PAN than BEV."
        },
        {
            "Title": "ARC-9: A randomized study to evaluate etrumadenant based treatment combinations in previously treated metastatic colorectal cancer (mCRC).",
            "Indication": "Metastatic colorectal cancer (mCRC)",
            "Drug": "Etrumadenant (E) combined with zimberelimab (Z) and FOLFOX/bevacizumab (bev) regimens or regorafenib (rego)",
            "PFS": "Median PFS: 6.1 months (95% CI: 5.2, 7.1) for EZFB group, 2.0 months (95% CI: 1.1, 3.2) for rego group; PFS HR: 0.27 (95% CI: 0.17, 0.43)",
            "OS": "Median OS: 19.7 months (95% CI: 14.7, 20.6) for EZFB group, 9.5 months (95% CI: 8.0, 12.5) for rego group; OS HR: 0.37 (95% CI: 0.22, 0.63)",
            "ORR": "Confirmed ORR: 17.3% for EZFB group, 2.7% for rego group",
            "AE": "Grade \u22653 TEAEs: 82.4% for EZFB group, 48.6% for rego group; Grade \u22653 related to E or Z or rego: 23.0% for EZFB group, 25.7% for rego group; TEAEs leading to discontinuation of all study drugs: 5.4% for EZFB group, 17.1% for rego group",
            "Other": "None",
            "Conclusion": "In this randomized phase II clinical trial, EZFB significantly improved efficacy outcomes compared to rego in refractory mCRC patients previously treated with 5-FU, oxaliplatin, and irinotecan regimens with no unexpected toxicities. Further investigation of this promising regimen is warranted given the clinically meaningful PFS and OS improvement."
        },
        {
            "Title": "Final results of a phase 2 study of tucatinib and trastuzumab for HER2-positive mCRC (MOUNTAINEER).",
            "Indication": "HER2-positive metastatic colorectal cancer (mCRC)",
            "Drug": "Tucatinib (TUC) and trastuzumab (Tras)",
            "PFS": "Median PFS per BICR: 8.1 months (95% CI: 4.2-10.2) for Cohorts A+B",
            "OS": "Median OS: 23.9 months (95% CI: 18.7-28.3) for Cohorts A+B; Median OS in Cohort C post-crossover: 21.1 months (95% CI: 17.0-NE)",
            "ORR": "Confirmed ORR: 38.1%",
            "AE": "Most common TEAEs in Cohorts A+B: diarrhea (66.3%), fatigue (44.2%), nausea (34.9%); Most common grade \u22653 TEAE: hypertension (7.0%); TEAEs leading to TUC discontinuation in Cohorts A+B: 5.8%; Most common TEAEs post-crossover in Cohort C: diarrhea (39.3%), back pain (21.4%), pyrexia (21.4%); Most common grade \u22653 TEAE: AST increase (10.7%)",
            "Other": "Median DOR per BICR: 15.2 months (95% CI: 8.9-20.5) for Cohorts A+B; Median DOR in Cohort C post-crossover: not reached (95% CI: 2.8 months-NE)",
            "Conclusion": "TUC+Tras continued to be well tolerated with sustained efficacy in patients with chemo-refractory RAS WT HER2+ mCRC, demonstrating clinically meaningful activity of the combination on longer follow-up. TUC+Tras is an important chemotherapy-free treatment option in HER2+ mCRC. TUC+Tras is being further evaluated in the first-line setting in combination with chemotherapy in the ongoing phase 3 MOUNTAINEER-03 trial (NCT05253651)."
        },
        {
            "Title": "TimedependencyforHPVctDNAdetectionasaprognosticbiomarkerforanalcancer",
            "Indication": "Anal cancer",
            "Drug": "None",
            "PFS": "5.9 months vs not reached (NR); hazard ratio (HR) 24, 95% CI 1.2-475; p < .001",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Detection of HPV ctDNA 3 months after cRT was associated with clinical recurrence (100% vs 8%; odds ratio 88, 95% CI 4-2000; p = .006)",
            "Conclusion": "Detection of HPV ctDNA at 3 months after cRT was associated with RFS. Future clinical trials should incorporate the 3-month post-treatment timepoint for identification of patients with HPV-positive anal cancer at elevated risk for recurrence according to HPV ctDNA status."
        },
        {
            "Title": "PhaseItrialofhypoxia-responsiveCEACAR-Tcelltherapyinpatientswithheavilypretreatedsolidtumorviaintraperitonealorintravenoustransfusion",
            "Indication": "Solid tumors (including colorectal cancer, gastric cancer, non-small cell lung cancer, biliary tract cancer)",
            "Drug": "Hypoxia-responsive CEACAR-T cell therapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "I.P. group: 25% (4/16); I.V. group: 8% (2/24)",
            "AE": "CRS in 62.5% of the pts at grade 1-2; no ICANS or treatment-related death; Grade 1-2 mucositis in 25% of the patients; Immune-mediated diarrhea and colitis of all grades in 32.5% of the patients, including 17.5% grade 3 events; 100% of patients experienced grade 3-4 hematologic toxicity",
            "Other": "Disease control rate (DCR): I.P. group: 88% (14/16); I.V. group: 67% (16/24). High-dose group receiving I.P. infusion: ORR increased to 28.5%. Sustained tumor remission for more than 5 months in high-dose group.",
            "Conclusion": "The CEACAR-T cell therapy had acceptable toxicity via I.P. or I.V. infusion. Promising antitumor potential by I.P. infusion and prolonged tumor remission in high-dose group. Further investigation is needed to highlight these findings and the corresponding mechanisms."
        },
        {
            "Title": "First-in-humanstudyofABBV-400,anovelc-Met\u2013targetingantibody-druginadvancedsolidtumors:Resultsincolorectalcancer",
            "Indication": "Colorectal cancer",
            "Drug": "ABBV-400",
            "PFS": "4.0 months (2.6, NE) for 1.6 mg/kg Q3W; 5.3 months (3.8, 5.5) for 2.4 mg/kg Q3W; 4.5 months (2.6, 6.8) for 3.0 mg/kg Q3W",
            "OS": "9.7 months (4.3, NE) for 1.6 mg/kg Q3W; 8.7 months (6.3, NE) for 2.4 mg/kg Q3W; 12.2 months (5.9, NE) for 3.0 mg/kg Q3W",
            "ORR": "0% for 1.6 mg/kg Q3W; 15% for 2.4 mg/kg Q3W; 20% for 3.0 mg/kg Q3W",
            "AE": "78 (64%) pts had a grade (G) \u2265 3 treatment-emergent adverse event (TEAE); 41% had a serious TEAE. Most frequent hematologic TEAEs: anemia (52%; G \u2265 3: 30%), neutropenia (37%; G \u2265 3: 25%), leukopenia (25%; G \u2265 3: 12%), thrombocytopenia (23%; G \u2265 3: 12%). Non-hematologic TEAEs: nausea (57%, G \u2265 3: 3%), fatigue (43%; G \u2265 3: 2%), vomiting (39%, G \u2265 3: 4%). G \u2265 3 diarrhea was < 1%. Unadjudicated interstitial lung disease/pneumonitis rate was 7% (G \u2265 3: 2%). Treatment-related AEs leading to discontinuation occurred in 11 (9%) pts.",
            "Other": "In pts with higher c-Met expression, an increased ORR of > 30% was observed at efficacious doses (\u2265 2.4 mg/kg). Activity was also seen at lower c-Met expression levels (10\u201315% ORR).",
            "Conclusion": "ABBV-400 at 2.4 and 3.0 mg/kg Q3W has a tolerable and manageable safety profile, with promising antitumor activity. Long-term tolerability appears improved at 2.4 relative to 3.0 mg/kg, with higher relative dose intensity and generally lower TEAEs. The study is also evaluating ABBV-400 with bevacizumab in pts with CRC."
        },
        {
            "Title": "Three-year disease-free survival after transanal vs. laparoscopic total mesorectal excision for rectal cancer (TaLaR): A randomized clinical trial.",
            "Indication": "Rectal cancer",
            "Drug": "Transanal total mesorectal excision (taTME) vs. laparoscopic total mesorectal excision (laTME)",
            "PFS": "3-year DFS: taTME group 83.42%, laTME group 82.86%",
            "OS": "5-year OS: taTME group 94.50%, laTME group 92.01%",
            "ORR": "None",
            "AE": "None",
            "Other": "Local recurrence: taTME group 3.90%, laTME group 3.62%",
            "Conclusion": "In patients with mid-low rectal cancer, the 3-year DFS of taTME is not inferior to that of laTME. This study provides evidence supporting the clinical effectiveness of taTME in the treatment of rectal cancer."
        },
        {
            "Title": "Updated results from ERAS-007 plus encorafenib and cetuximab (EC) in patients (pts) with EC-na\u00efve metastatic BRAF V600E colorectal cancer (CRC) in the phase 1b/2 HERKULES-3 study.",
            "Indication": "Metastatic BRAF V600E colorectal cancer (CRC)",
            "Drug": "ERAS-007 + encorafenib and cetuximab (EC)",
            "PFS": "None",
            "OS": "None",
            "ORR": "40% in EC-na\u00efve efficacy evaluable pts",
            "AE": "TEAEs occurring in \u226525% of pts: dermatitis acneiform (52.6%), nausea and fatigue (47.4% each), diarrhea (42.1%), constipation, headache, and hematuria (31.6% each), vomiting, anemia, and infusion-related reaction (26.3% each). No Grade 4 TEAEs. One pt suffered a Grade 5 unrelated event of respiratory failure. No Grade 3 treatment-related AEs occurred in more than 1 pt. No ERAS-007-related AEs led to discontinuation of ERAS-007.",
            "Other": "Maximum duration of response is 15 months; updated data will be presented.",
            "Conclusion": "ERAS-007 100 mg BID-QW in combination with EC was safe and well tolerated with preliminary evidence of promising clinical activity. These results support continued evaluation of this combination in EC-na\u00efve pts with BRAF V600E CRC."
        },
        {
            "Title": "Spatially resolved multi-region proteomics for prediction of clinical outcome in deficient mismatch repair metastatic colorectal cancer treated with PD-1 blockade",
            "Indication": "Metastatic colorectal cancer (CRC) with deficient DNA mismatch repair (dMMR)",
            "Drug": "Pembrolizumab",
            "PFS": "Responders: HR: 0.16; 95% CI: 0.08-0.31; p<0.001. Non-responders: HR: 2.09; 95% CI: 1.28-3.42; p=0.003",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Upregulation of NK cells and T cell subsets (CD3+, CD4+, CD8+), ICOS in responders. Non-responders showed higher phospho-JNK and SMA.",
            "Conclusion": "In metastatic dMMR CRCs, spatially defined protein markers were significantly associated with anti-PD-1 response and independently associated with patient PFS."
        },
        {
            "Title": "Clonal hematopoiesis of indeterminate potential (CHIP): Outcomes and adverse events in patients with solid tumors",
            "Indication": "Solid tumors",
            "Drug": "Durvalumab + Tremelimumab (D+T), Gemcitabine and nab-paclitaxel (GN) +/- D+T",
            "PFS": "Chemo: HR: 1.05 [0.65-1.71]; p=0.85. IO: HR: 0.5 [0.26-0.96]; p=0.036",
            "OS": "Chemo: HR: 0.92 [0.56-1.51]; p=0.75. IO: HR: 0.66 [0.34-1.28]; p=0.22",
            "ORR": "None",
            "AE": "Chemo: OR: 0.75 [0.075-3.80]; p=1. IO: OR: 1.19 [0.33-4.31]; p=0.78",
            "Other": "CHIP prevalence: 10%-21%, more common in older patients (p=0.003). No association between CHIP and sex (p=0.80) or ECOG status (p=0.31).",
            "Conclusion": "CHIP is common in patients with solid tumors. Although not appearing to impact rates of AEs, CHIP may impact outcomes from Chemo or IO."
        },
        {
            "Title": "Characterization of necroptosis activators in colorectal cancer",
            "Indication": "Colorectal cancer (CRC)",
            "Drug": "FOLFOX/FOLFIRI (F/F), Immunotherapy (IO)",
            "PFS": "None",
            "OS": "F/F: NA-L: 34.3 vs NA-H: 39.6 months; HR=0.83, 95% CI 0.77-0.90; p<0.001. IO: 15.3 vs 22.4 months; HR=0.77, 95% CI 0.62-0.96; p=0.018",
            "ORR": "None",
            "AE": "None",
            "Other": "NA-H was associated with increased PD-L1 positivity (4.0% vs 2.3%), immune cell infiltration, and T-cell inflamed score (all q<0.001).",
            "Conclusion": "Activation of the necroptosis pathway is associated with immune cell infiltration/pathway activation, PD-L1 expression, and improved OS on F/F and IO. This benefit is more pronounced in TP53-mutated CRC."
        },
        {
            "Title": "Upstream regulation of neurotransmitter signaling genes in metastatic colorectal cancer (mCRC) patients: Data from CALGB/SWOG 80405 trial (Alliance) and MONSTAR-SCREEN-2",
            "Indication": "Metastatic colorectal cancer (mCRC)",
            "Drug": "None specified",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Identified 20 signaling genes with gene expression associated with PFS and/or OS, and 68 genes with evidence of DNA mutations associated with PFS and/or OS.",
            "Conclusion": "Our novel approach identified previously unknown upstream regulators of neurotransmitter signaling associated with treatment outcomes in mCRC. PIGU, FSCN1, and LY6G6D have been suggested in prior literature as targets for CRC and ANO9 has been suggested as a target for GI cancer."
        },
        {
            "Title": "Highly prognostic signature for stage II colon cancer from the TOSCA trial",
            "Indication": "Stage II colon cancer",
            "Drug": "FOLFOX4/CAPOX",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Disease-free survival (DFS) was worse in patients with a lower prognostic signature (PROSIT-L) compared to those with a high prognostic signature (PROSIT-H) (HR 3.5; 95% CI 1.53\u20137.9; p=0.002)",
            "Conclusion": "The new transcriptomic signature may help in identifying stage II CC patients who are likely to relapse despite receiving adjuvant oxaliplatin-based treatment."
        },
        {
            "Title": "Cost-effectiveness analysis of later-line treatments for refractory metastatic colorectal cancer",
            "Indication": "Refractory metastatic colorectal cancer",
            "Drug": "Regorafenib dose optimization (ReDO), TAS-BEV (trifluridine/tipiracil and bevacizumab), Fruquintinib (FRUQ)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "ReDO provided a higher QALY at a lower cost than TAS-BEV and FRUQ. TAS-BEV had the highest total cost, partly due to the cost of managing neutropenia and GCSF use.",
            "Conclusion": "ReDO was cost-effective compared with TAS-BEV and FRUQ, regardless of previous use of anti-VEGF therapy. In the base case, ReDO had a lower treatment cost than TAS-BEV and FRUQ, especially during the first month of dose optimization."
        },
        {
            "Title": "Preliminary results correlating post-operative ctDNA status with disease-free survival in patients with stage II (high risk)/III colorectal cancer in the BNT000-001 epidemiology study",
            "Indication": "Stage II (high risk)/III colorectal cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "12-month DFS rate was 55% for ctDNA positive vs. 92% for ctDNA negative patients. Post-surgery ctDNA positivity was associated with significantly increased risk for disease recurrence or death, with a hazard ratio of 10.2.",
            "Conclusion": "This study supports existing evidence of the prognostic value of post-operative ctDNA in CRC patients. In ctDNA positive patients, the 12-month DFS rate was almost halved (55% vs. 92% in ctDNA negative)."
        },
        {
            "Title": "Combined amphiregulin and epiregulin expression as a prognostic and predictive biomarker of panitumumab (Pmab), fluorouracil, and folinic acid (FU/FA) or FU/FA maintenance therapy following Pmab + FOLFOX induction in RAS wild-type metastatic colorectal cancer (mCRC): PANAMA trial (AIO-KRK-0212).",
            "Indication": "Metastatic colorectal cancer (mCRC)",
            "Drug": "Panitumumab (Pmab), fluorouracil (FU), folinic acid (FA)",
            "PFS": "High combined AREG/EREG expression: 7.7 months (HR 0.69 [95% CI 0.51\u20130.93], P=0.016); Low combined AREG/EREG expression: 5.8 months",
            "OS": "High combined AREG/EREG expression: 30.1 months (HR 0.58 [95% CI 0.41\u20130.82], P=0.002); Low combined AREG/EREG expression: 24.4 months",
            "ORR": "High combined AREG/EREG expression: 39.3%; Low combined AREG/EREG expression: 28.6%",
            "AE": "None",
            "Other": "Significant interaction of combined AREG/EREG expression with treatment was observed in terms of PFS (P=0.003) and OS (P=0.016).",
            "Conclusion": "Combined AREG and EREG mRNA expression was confirmed as a prognostic biomarker for mCRC patients receiving maintenance treatment. Efficacy of maintenance treatment might potentially be higher for Pmab + FUFA maintenance in mCRC with high combined AREG/EREG expression in the PanaMa trial."
        },
        {
            "Title": "Results of a phase 2 study of evorpacept (E, ALX148), cetuximab (C), and pembrolizumab (P) in patients with refractory microsatellite stable metastatic colorectal cancer (MSSCRC).",
            "Indication": "Refractory microsatellite stable metastatic colorectal cancer (MSSCRC)",
            "Drug": "Evorpacept (E, ALX148), cetuximab (C), pembrolizumab (P)",
            "PFS": "2.6 months (95% CI 2.1-2.9 months)",
            "OS": "10.9 months (95% CI 3.3 months - infinity)",
            "ORR": "6.3% (95% CI 0.2-30.2%)",
            "AE": "Most common any-grade treatment-emergent adverse events: headache (32%), fatigue (26%), anemia (21%), diarrhea (21%), dyspnea (16%), abdominal pain (16%), nausea/vomiting (16% each), acneiform rash (16%). Two on-study deaths related to all three study drugs (hemophagocytic lymphohistiocytosis and cytokine release syndrome; 1 each).",
            "Other": "Disease control rate was 12.5% (95% CI 1.6-38.4%).",
            "Conclusion": "Further dose optimization of ECP is needed to establish a recommended dose in the refractory MSSCRC population. While initial activity was seen, at the dose levels evaluated, criteria for study termination were met."
        },
        {
            "Title": "Neoadjuvant chemotherapy with FOLFOXIRI plus lateral lymph node dissection for the treatment of resectable rectal cancer with lateral lymph node metastasis: A single-arm, investigator-initiated, phase II trial.",
            "Indication": "Resectable rectal cancer with lateral lymph node metastasis",
            "Drug": "FOLFOXIRI plus lateral lymph node dissection (LLND)",
            "PFS": "None",
            "OS": "3-year OS rate was 91.3%",
            "ORR": "Pathologic complete response (pCR) rate was 11.3%",
            "AE": "Grade 3/4 adverse events in 26.4% of cases",
            "Other": "3-year DFS rate was 88.3%, 3-year local recurrence-free survival rate was 94.7%",
            "Conclusion": "Neoadjuvant chemotherapy with the FOLFOXIRI regimen plus LLND can significantly reduce the risk of local recurrence and distant metastasis in resectable rectal cancer with LLNM, which may be a new treatment option."
        },
        {
            "Title": "A phase II clinical trial of metformin combined with FOLFIRI plus bevacizumab as second-line therapy in patients with RAS-mutant metastatic colorectal cancer.",
            "Indication": "RAS-mutant metastatic colorectal cancer",
            "Drug": "Metformin combined with FOLFIRI plus bevacizumab",
            "PFS": "Median PFS was 8.0 months",
            "OS": "Median OS was not reached",
            "ORR": "21.6%",
            "AE": "Grade 3 and above TEAEs occurred in 44.8% of patients",
            "Other": "Disease control rate (DCR) was 76.7%",
            "Conclusion": "Metformin combined with FOLFIRI and bevacizumab had promising efficacy and tolerable toxicity in RAS mutant mCRC patients as second-line therapy. Further study in phase 3 trials is warranted."
        },
        {
            "Title": "AGITG MONARCC: A randomized phase 2 study of panitumumab monotherapy and panitumumab (pan) plus 5 fluorouracil (FU) as first-line therapy for RAS and BRAF wild type metastatic colorectal cancer\u2014A trial by the Australasian Gastro-Intestinal Trials Group (AGITG).",
            "Indication": "RAS and BRAF wild type metastatic colorectal cancer",
            "Drug": "Panitumumab monotherapy and panitumumab plus 5 fluorouracil (FU)",
            "PFS": "6-month PFS was 63% in arm A and 82% in arm B",
            "OS": "Median OS was 21 months in arm A and 28 months in arm B",
            "ORR": "47% in arm A and 65% in arm B",
            "AE": "Rates of adverse events and serious adverse events were similar between the arms",
            "Other": "Baseline comprehensive geriatric assessments were feasible and OTU was high in both arms",
            "Conclusion": "6-month PFS in both arms was consistent with the hypothesized rate of 73% achieved with FU/bev, whilst the rate was numerically higher for arm B. Both treatment arms might be suitable first-line options in appropriately selected patients."
        },
        {
            "Title": "Efficacy and safety of fruquintinib plus trifluridine/tipiracil (TAS-102) as third-line treatment in patients with metastatic colorectal adenocarcinoma: A multicenter, randomized, open-label, phase 3 trial.",
            "Indication": "Metastatic colorectal adenocarcinoma",
            "Drug": "Fruquintinib plus TAS-102",
            "PFS": "Median PFS was 6.46 months (95% CI: 4.20-8.62). The 6-mo, 9-mo, and 12-mo PFS rates were 50.9%, 25.4%, and 21.8%, respectively. Median PFS was 5.51 (95% CI: 2.43-NA) months for RAS mutant pts, 5.84 (95% CI: 3.97-NA) months for RAS wild-type pts, and 6.59 (95% CI: 4.79-NA) months for RAS status unknown pts.",
            "OS": "Median OS was not mature with 15.5 months of median follow-up time.",
            "ORR": "10.9% (5/46) pts achieved partial response (PR) and 63.0% (29/46) pts had stable disease (SD) as best response.",
            "AE": "The most common hematological TRAEs (any grade, grade 3-4) included neutrophil count decreased (80.0%, 52.0%), white blood cell count decreased (70.0%, 24.0%), and anemia (54.0%, 20.0%). Non-hematological TRAEs were mainly grade 1-2 including loss of appetite (22.0%), malaise (14.0%), abdominal pain (12.0%), diarrhea (12.0%), and vomiting (10.0%). A grade 3 hypertension was reported. No treatment-related deaths were observed.",
            "Other": "None",
            "Conclusion": "These preliminary results indicate that fruquintinib plus TAS-102 as third-line treatment in patients with mCRC was well tolerated with encouraging clinical activity. The enrollment is closed and more OS data will be presented in the future."
        },
        {
            "Title": "Early versus conventional adjuvant chemotherapy in stage III colon cancer: A multicenter, randomized, open-label, phase III trial.",
            "Indication": "Stage III colon cancer",
            "Drug": "FOLFOX",
            "PFS": "None",
            "OS": "Three-year overall survival rates were 93.0% (95% CI 89.6-96.5) in the EAC group versus 94.5% (91.5-97.6) in the CAC group (hazard ratio 0.31, 95% CI 0.76-2.35; p=0.313).",
            "ORR": "None",
            "AE": "There was no statistically significant difference in overall toxicity (28.1% in the EAC group and 28.2% in the CAC group, p=0.244) between the two groups.",
            "Other": "Three-year disease-free survival rates were 86.9% (95% CI 82.5-91.5) in the EAC group versus 86.6% (82.2-91.3) in the CAC group (hazard ratio 0.67, 95% CI 0.69-1.78; p=0.672).",
            "Conclusion": "To the best of our knowledge, this is the first randomized controlled trial investigating the time required for adjuvant chemotherapy for colon cancer. The findings revealed that adjuvant chemotherapy within 2 weeks after surgery for stage III colon cancer did not prolong disease-free survival."
        },
        {
            "Title": "Spatial patterns in the tumor immune microenvironment as prognostic and predictive biomarkers of panitumumab (Pmab), fluorouracil and folinic acid (FU/FA) or FU/FA maintenance therapy following Pmab+FOLFOX induction in RAS wildtype (wt) metastatic colorectal cancer (mCRC): PanaMa (AIO KRK0212) trial.",
            "Indication": "RAS wildtype metastatic colorectal cancer",
            "Drug": "Panitumumab (Pmab) + FOLFOX",
            "PFS": "Low expression of CD163 (HR: 0.717; 95% CI: 0.526-0.977; p=0.036), and high expression of PD-1 (HR: 0.672; 95% CI: 0.491\u20130.921; p=0.014) in the tumor center were independent prognostic factors for prolonged PFS. The addition of Pmab to FU/FA maintenance was associated with longer PFS in patients with low expression of CD8 Inv (HR: 0.224; 95% CI: 0.085-0.594; p=0.004), LAG3 Cen (HR: 0.489; 95% CI: 0.265-0.905; p=0.025), CD163 Cen (HR: 0.552; 95% CI: 0.346-0.883; p=0.015), Immunoscore Cen (HR: 0.501; 95% CI: 0.179-0.901; p=0.024), and high expression of CD45 Cen (HR: 0.403; 95% CI: 0.222-0.732; p=0.003).",
            "OS": "In patients with CD45 Cen-high and LAG3 Cen-low, prolonged PFS translated also into a significant OS-prolongation. A positive Immunoactivation-score (defined as positivity in \u2265 2 predictive markers from univariate analysis) predicted significant benefit from additional Pmab as maintenance in terms of OS (HR: 0.388; 95% CI: 0.195-0.772; p=0.007).",
            "ORR": "None",
            "AE": "None",
            "Other": "Several factors of the immune microenvironment were found to be prognostic factors for PFS and OS in patients with RAS wt mCRC. Of those, CD8, CD163, LAG3, CD45, Immunoscore, and an Immunoactivation-score were identified as independent predictive markers for benefit from the addition of Pmab to FU/FA maintenance therapy.",
            "Conclusion": "Immune activation might be a crucial component of EGFR-antibody efficacy."
        },
        {
            "Title": "Therapeutic implications of acquired high tumor mutational burden (TMB-H) after targeted therapy (TT) in metastatic colorectal cancer (mCRC).",
            "Indication": "Metastatic colorectal cancer (mCRC)",
            "Drug": "Targeted therapy (TT) including EGFR, BRAF V600E, KRAS G12C, or HER2 inhibitors",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Acquisition of TMB-H at progression with predominantly low VAF mutations suggests activation of enhanced mutagenesis due to therapeutic pressure from exposure to TT. In this context, TMB may not be an accurate reflection of tumor immunogenicity as it does not distinguish between clonal and subclonal alterations.",
            "Conclusion": "In CRC, acquisition of TMB-H following TT is unlikely to benefit from ICB."
        },
        {
            "Title": "A phase II study to evaluate the efficacy and safety of fruquintinib combined with tislelizumab and hepatic artery infusion chemotherapy (HAIC) for advanced colorectal cancer liver metastases: An updated analysis of survival.",
            "Indication": "Advanced colorectal cancer liver metastases (CRLM)",
            "Drug": "Fruquintinib combined with tislelizumab and hepatic artery infusion chemotherapy (HAIC)",
            "PFS": "Median PFS was 7.43 months (95% CI, 5.36-9.82) and 6-month PFS rate was 60%.",
            "OS": "Median OS was 15.51 months (95% CI, 10.84-NA) and 1-year OS rate was 61%.",
            "ORR": "None",
            "AE": "None",
            "Other": "Subgroup analysis showed median PFS was significantly longer with right-sided tumor patients than left-sided tumor patients (13.31 vs 6.90 months, HR, 0.121 [95% CI, 0.046-0.320], p=0.0062). Compared with patients who received bev in prior therapy, the median PFS of patients without bev was significantly improved (5.16 vs 8.31 months, HR, 0.358 [95% CI, 0.133-0.965], p=0.0361).",
            "Conclusion": "The updated data on the survival analysis revealed that fruquintinib combined with tislelizumab and HAIC had a survival benefit in CRLM patients with MSS who had failed standard therapy, especially for right-sided tumor patients."
        },
        {
            "Title": "Survival impact of KRAS mutation in metastatic colorectal cancer (mCCR) under first-line treatment.",
            "Indication": "Metastatic colorectal cancer (mCCR)",
            "Drug": "5-FU and Oxaliplatin (mFLOX)",
            "PFS": "Median PFS: 9.2 months (95% CI 7.8-11.6) for the overall group. Specific mutations: G12S - 18.62 months, G12R - 5.31 months",
            "OS": "Median OS: 21 months (95% CI 19-23) for the overall group. Specific mutations: G12S - Not Reached, G12R - 17.89 months",
            "ORR": "40% partial response, 20.4% stable disease, 30.9% disease progression, 1.3% complete response",
            "AE": "None",
            "Other": "KRAS mutations and PFS and OS survival median times. G12S mutation demonstrated an improved OS and PFS.",
            "Conclusion": "Specific mutated codons in the KRAS gene impact PFS and OS independently of primary tumor sidedness. G12S mutation demonstrated an improved OS and PFS."
        },
        {
            "Title": "Prognostic impact of HER family expressions for metastatic colorectal cancer (mCRC): SCRUM-Japan MONSTAR-SCREEN-2 and CALGB/SWOG 80405 trial (Alliance) international collaboration.",
            "Indication": "Metastatic colorectal cancer (mCRC)",
            "Drug": "Cetuximab, Bevacizumab",
            "PFS": "None",
            "OS": "High HER2 expression: HR 0.21; 95% CI 0.06-0.71; P=0.0056 in RAS wild-type population. High HER3 expression: HR 0.23; 95% CI 0.07-0.70; P=0.0048 in RAS wild-type population.",
            "ORR": "None",
            "AE": "None",
            "Other": "Elevated expressions of HER1 and HER3 were significantly associated with better OS in RAS wild-type disease.",
            "Conclusion": "Our findings highlight the prognostic significance of HER1-3 gene expressions in RAS wild-type mCRC patients in the MONSTAR-SCREEN-2 cohort. These insights may contribute to the development of more efficacious treatment methodologies according to HER family transcriptome expression profiles."
        },
        {
            "Title": "Correlation of circulating tumor DNA and recurrence in patients with metastatic colorectal cancer with no evidence of disease: An open-label, prospective, phase II study.",
            "Indication": "Metastatic colorectal cancer (mCRC)",
            "Drug": "Circulating tumor DNA (ctDNA) analysis",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Recurrence-Free Survival (RFS): Patients with positive ctDNA post-curative treatment demonstrated an inferior RFS than those with negative ctDNA (HR: 4.58, 95% CI: 2.18\u20139.64; P < 0.001).",
            "Conclusion": "Our results demonstrated that patients with ctDNA negative post-curative treatment had superior RFS compared with those with positive ctDNA. Post-curative treatment and dynamic ctDNA had the potential to guide ACT in mCRC patients with NED. The follow-up is still ongoing."
        },
        {
            "Title": "Efficacy of FOLFIRI plus bevacizumab versus FOLFIRI plus cetuximab in RAS-mutant metastatic colorectal cancer: Final update on patients with RAS-mutant metastatic colorectal cancer treated in FIRE-3.",
            "Indication": "RAS-mutant metastatic colorectal cancer (mCRC)",
            "Drug": "FOLFIRI plus bevacizumab vs. FOLFIRI plus cetuximab",
            "PFS": "None",
            "OS": "No overall survival (OS) benefit was observed when cetuximab was compared to bevacizumab (20.3 months vs 21.1 months; HR 1.028; 95% CI, 0.547-1.583; P = .842).",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "While cetuximab notably is not effective in RASmut mCRC, this randomized comparison does not provide an indication for superior efficacy of bevacizumab in the first-line treatment of this patient group."
        },
        {
            "Title": "Encorafenib plus cetuximab in patients with metastatic, BRAF V600E-mutated, colorectal carcinoma: First effectiveness data of the European multi-centric, multi-national, non-interventional study\u2014BERING-CRC.",
            "Indication": "BRAF V600E-mutant metastatic colorectal cancer",
            "Drug": "Encorafenib plus cetuximab (E+C)",
            "PFS": "Median PFS: 5.3 months",
            "OS": "Median OS: 10.3 months",
            "ORR": "Objective Response Rate (ORR): 24%",
            "AE": "Treatment emergent adverse events (TEAE) were reported in 73% of patients (grade 3/4: 38%). Main TEAE (\u226510%, all grades) were rash (12%) and nausea (11%).",
            "Other": "None",
            "Conclusion": "This first effectiveness analysis of BERING-CRC shows the use of E+C in a real-world population. Despite the older study population and less ECOG PS 0 patients as compared to the pivotal study, the observed treatment effectiveness and safety profile are consistent with data from the BEACON CRC study."
        },
        {
            "Title": "A phase 1, open-label, dose escalation, and expansion study of CUE-102, a novel WT1-pHLA-IL2-Fc fusion protein, in patients with HLA-A*0201-positive disease and WT1-positive recurrent/metastatic cancers.",
            "Indication": "WT1-positive recurrent/metastatic cancers",
            "Drug": "CUE-102",
            "PFS": "None",
            "OS": "None",
            "ORR": "43.5% (Stable disease of \u22656 weeks observed in 10 of 23 patients)",
            "AE": "96% of treatment-related adverse events (TRAEs) grade \u22642. Most common TRAEs were fatigue, infusion reactions, nausea, and vomiting.",
            "Other": "No dose-limiting toxicities (DLTs) or drug-related serious adverse events (SAEs) observed. Preliminary PK data demonstrated dose-dependent increases in exposure. Selective expansion of WT1-specific CD8+ T cells observed.",
            "Conclusion": "CUE-102 is a novel T cell engager that demonstrates acceptable tolerability, favorable PK, and supportive preliminary PD readouts. No DLTs or drug-related SAEs have been observed in doses up to 8 mg/kg as of the data cut-off. Adverse events have been manageable and consistent with the CUE-102 mechanism of action and underlying disease. Early signs of disease stabilization and anti-tumor activity in late-stage patients are encouraging. Part B is a dose expansion that further evaluates safety, PK/PD, recommended phase 2 dose (RP2D), anti-tumor efficacy and is currently enrolling patients."
        },
        {
            "Title": "A phase 2 study evaluating response and biomarkers in patients with microsatellite stable (MSS) advanced colorectal cancer (CRC) treated with nivolumab/relatlimab.",
            "Indication": "Microsatellite stable (MSS) advanced colorectal cancer (CRC)",
            "Drug": "Nivolumab/relatlimab",
            "PFS": "Arm A: 2.2 months, Arm B: 2.8 months, Arm C: 2.6 months",
            "OS": "Arm A: 4.3 months, Arm B: 14.2 months, Arm C: 12.4 months",
            "ORR": "5% (3 patients achieved a partial response)",
            "AE": "None (treatment was well tolerated with no new safety signals over aPD1 alone)",
            "Other": "6 patients with lung-only metastases: 2 (33%) experienced a partial response, and 1 (17%) a stable disease, for a clinical benefit rate of 50%. The trial incorporated translational correlates including mass cytometry on PBMCs, RNAseq on baseline biopsies, and imaging mass cytometry on pre- and on-treatment biopsies.",
            "Conclusion": "This combination did not meet the primary endpoint of 20% ORR for any of the 3 cohorts, but there was intriguing possible activity in patients with lung-only metastases, consistent with emerging data."
        },
        {
            "Title": "Botensilimab plus balstilimab in microsatellite stable metastatic colorectal cancer: Assessing efficacy in non-liver metastatic sites.",
            "Indication": "Microsatellite stable metastatic colorectal cancer (MSS CRC)",
            "Drug": "Botensilimab plus balstilimab",
            "PFS": "None",
            "OS": "Median OS: 20.9 months (95% CI 16.4\u2013NR)",
            "ORR": "22% (17/77) in ITT; 24% (17/70) in EE",
            "AE": "None (no new safety signals observed)",
            "Other": "Disease control rate (DCR): 73% (56/77) in ITT; 80% (56/70) in EE. Median duration of response (DOR) not reached (NR; 95% CI 5.7\u2013NR). Subgroup analyses showed comparable activity (ORR 18\u201333%) across metastatic sites outside of the lungs, including the peritoneum, soft tissue, pleura, and the brain.",
            "Conclusion": "In summary, for MSS CRC, BOT and BAL is differentiated from previous I-O combinations by producing deep and durable responses even in difficult-to-treat metastatic sites. In NLM patients, responses were observed in 18\u201333% of sites of disease including the peritoneum, soft tissue, pleura, and the brain. These compelling results support further investigation in the fully enrolled, randomized, global phase 2 trial in MSS CRC (NCT05608044) and a planned global phase 3 trial."
        },
        {
            "Title": "A randomized study evaluating tailoring of advanced/metastatic colorectal cancer (mCRC) therapy using circulating tumor DNA (ctDNA): TACT-D.",
            "Indication": "Metastatic colorectal cancer (mCRC)",
            "Drug": "Trifluridine/tipiracil plus capecitabine and bevacizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "72.7% (overall population), 66.7% (at the recommended dose)",
            "AE": "Overall Population: All Grade 3/4 AEs: 72.7%, Neutropenia: 36.4%, Diarrhea: 18.2%, Thromboembolic event: 9.1%, Fatigue: 9.1%, AST/ALT increased: 9.1%, Hypokalemia: 9.1%, Colonic perforation: 9.1%. FTD/TPI 25mg/sqm BID: All Grade 3/4 AEs: 66.7%, Diarrhea: 16.7%, Thromboembolic event: 16.7%, AST/ALT increased: 16.7%, Colonic perforation: 16.7%",
            "Other": "None",
            "Conclusion": "The sequential combination of cap and FTD/TPI with bev is feasible. The phase 2 of the study is ongoing to evaluate antitumor activity of this regimen in the same patients\u2019 population."
        },
        {
            "Title": "Encorafenib and cetuximab versus irinotecan/cetuximab or FOLFIRI/cetuximab in Chinese patients with BRAF V600E mutant metastatic colorectal cancer: The NAUTICAL CRC study.",
            "Indication": "BRAF V600E mutant metastatic colorectal cancer",
            "Drug": "Encorafenib and cetuximab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "Updated results and subgroup analysis by presence of liver metastases of a phase I/II study of the EP4 antagonist vorbipiprant with the anti-PD-1 balstilimab in mismatch-repair-proficient and microsatellite stable (pMMR/MSS) chemorefractory metastatic colorectal cancer (mCRC).",
            "Indication": "Mismatch-repair-proficient and microsatellite stable (pMMR/MSS) chemorefractory metastatic colorectal cancer (mCRC)",
            "Drug": "Vorbipiprant with the anti-PD-1 balstilimab",
            "PFS": "Overall: 2.6 months (95% CI 1.7-3.6 months). With liver metastases: 1.8 months (95% CI 1.6-3.5 months). Without liver metastases: 3.6 months (95% CI 1.8-7.2 months)",
            "OS": "Overall: 13.7 months (95% CI 10.6-not reached). With liver metastases: 13.7 months (95% CI 5.5-not reached). Without liver metastases: Not reached (95% CI 10.6-not reached)",
            "ORR": "Overall: 10.7%. With liver metastases: 8%. Without liver metastases: 12.5%",
            "AE": "None",
            "Other": "Disease Control Rate (DCR): Overall: 50%. With liver metastases: 25%. Without liver metastases: 68.8%",
            "Conclusion": "Vorbipiprant combined with an anti-PD-1 (balstilimab) showed promising efficacy in this phase I/II study. Encouraging results were observed in patients with liver metastases. This finding deserves particular attention given that the presence of liver metastases is considered an immune resistance factor and is associated with poor outcomes of treatment with ICIs."
        },
        {
            "Title": "Phase Ib/II trial of hepatic arterial infusion chemotherapy in combination with fruquintinib for refractory unresectable colorectal cancer liver metastases.",
            "Indication": "Refractory unresectable colorectal cancer liver metastases",
            "Drug": "Fruquintinib",
            "PFS": "Median hepatic-PFS: 6.8 months (95% CI, 5.890-7.710), Median PFS: 5.9 months (95% CI, 3.576-8.224)",
            "OS": "Median OS: 15.0 months (95% CI, 8.671-21.329)",
            "ORR": "40.0%",
            "AE": "23.3% experienced G3/4 treatment-related adverse events (TRAEs). Common TRAEs: voice alteration, hypertension, abdominal pain, hand-foot syndrome.",
            "Other": "Disease Control Rate (DCR): 80.0%",
            "Conclusion": "The combination of HAIC and fruquintinib showed a manageable safety profile and survival benefit for heavily treated CRLM patients."
        },
        {
            "Title": "Effect of p16 immunohistochemical expression (exp) on irinotecan in metastatic colorectal cancer (mCRC): A translational analysis of the TRIBE and TRIBE2 studies.",
            "Indication": "Metastatic colorectal cancer (mCRC)",
            "Drug": "Irinotecan",
            "PFS": "No PFS difference between p16 neg and pos patients. Among p16 pos patients treated with FOLFOXIRI/bev: PFS: 12.8 vs 9.4 months, HR: 0.55, 95% CI: 0.39-0.78, p=0.0008. Among p16 neg patients: PFS: 9.0 vs 9.4 months, HR: 0.91, 95% CI: 0.57-1.46, p=0.69.",
            "OS": "Among p16 pos patients: OS: 30.0 vs 21.4 months, HR: 0.66, 95% CI: 0.46-0.95, p=0.026. Among p16 neg patients: OS: 21.3 vs 19.5 months, HR: 0.95, 95% CI: 0.58-1.57, p=0.85.",
            "ORR": "None",
            "AE": "None",
            "Other": "Patients with p16 pos tumors treated with FOLFIRI/bev in the TRIBE study reported longer PFS and OS than those with p16 neg tumors (HR for PFS: 0.45, 95% CI: 0.22-0.92, p=0.026; HR for OS: 0.41, 95% CI: 0.20-0.85, p=0.013).",
            "Conclusion": "In mCRC, p16 pos exp seems associated with higher benefit from irinotecan. Prospective confirmation in independent randomized series is warranted."
        },
        {
            "Title": "Updated survival results of BBCAPX-II: Sintilimab combined with bevacizumab and CapeOx as first-line treatment in patients with RAS-mutant, microsatellite stable, unresectable metastatic colorectal cancer.",
            "Indication": "RAS-mutant, microsatellite stable, unresectable metastatic colorectal cancer",
            "Drug": "Sintilimab, Bevacizumab, CapeOx",
            "PFS": "Median PFS (full analysis set): 17.9 months (95% CI, 8.84-27), Median PFS (per-protocol set): 9.79 months (95% CI, 6.44-24.3), Median PFS (liver metastasis only): 23.7 months (95% CI, 4.8-NA), Median PFS (other metastasis): 11.5 months (95% CI, 4.83-24.3)",
            "OS": "None",
            "ORR": "84%",
            "AE": "Most frequent grade 3 or 4 TRAEs: neutropenia (3/25), increased alanine transaminase (2/25). No grade 5 adverse events occurred.",
            "Other": "Disease Control Rate (DCR): 100%. PFS rate at 6 months: 84% (95% CI, 70.8-99.7). PFS rate at 12 months: 56% (95% CI, 39.6-79.3).",
            "Conclusion": "The combination of sintilimab plus bevacizumab and CapeOx showed promising antitumor activity and manageable toxicity in RAS-mutant, MSS, unresectable mCRC. Patients with liver metastases presented better prognosis compared to other metastases."
        },
        {
            "Title": "Comparing the efficacy and safety of HepaSphere drug-eluting bead transarterial chemoembolization combined with hepatic arterial infusion chemotherapy versus hepatic arterial infusion chemotherapy alone in unresectable colorectal liver metastases.",
            "Indication": "Unresectable colorectal liver metastases",
            "Drug": "HepaSphere DEB-TACE combined with HAIC vs. HAIC alone",
            "PFS": "Median PFS: 5.1 vs. 3.5 months, P=0.092",
            "OS": "Median OS: 15.5 vs. 14.7 months, P=0.513",
            "ORR": "37.3% in the DEB-TACE-HAIC group and 27.7% in the HAIC group",
            "AE": "Nausea occurred more frequently in the HAIC group (39.8% vs. 22.9%, P=0.019)",
            "Other": "Median hPFS: 7.6 vs. 4.1 months, P=0.023; DCR: 75.9% vs. 69.9%",
            "Conclusion": "DEB-TACE-HAIC demonstrated superior survival compared to HAIC alone in unresectable CRLM, even in patients refractory to systemic therapy. The combination of HepaSphere DEB-TACE and HAIC appears promising as a regional treatment option with improved outcomes and a comparable safety profile."
        },
        {
            "Title": "A phase II clinical evaluation of radiofrequency ablation (RFA) combined with regorafenib and toripalimab in patients with colorectal cancer with liver metastases.",
            "Indication": "Colorectal cancer with liver metastases",
            "Drug": "Radiofrequency ablation (RFA) combined with regorafenib and toripalimab",
            "PFS": "Median PFS: 3.3 months",
            "OS": "Median OS: 11.3 months",
            "ORR": "None exhibited confirmed partial or complete responses; half of the participants achieved stable disease",
            "AE": "Adverse events included hand-foot syndrome (50%), liver dysfunction, fatigue, abdominal distension, and thrombocytopenia. Serious AEs (grade \u22653) included hand-foot syndrome and hyperbilirubinemia (10% each).",
            "Other": "One patient experienced hyperprogression during immunotherapy.",
            "Conclusion": "The combination of RFA, regorafenib, and toripalimab has not shown encouraging efficacy in patients with CRC liver metastases. However, the observed safety profile is acceptable."
        },
        {
            "Title": "Fruquintinib plus capecitabine versus capecitabine as first-line maintenance treatment of metastatic colorectal cancer (mCRC): Update results from the randomized, controlled, phase Ib/II study.",
            "Indication": "Metastatic colorectal cancer (mCRC)",
            "Drug": "Fruquintinib plus capecitabine vs. capecitabine alone",
            "PFS": "Median PFS1: 9.2 months in Fru plus Cap arm vs. 3.1 months in Cap arm (HR=0.385 [95% CI: 0.163-0.906], p=0.024); PFS2: 16.8 vs. 8.5 months (HR=0.314 [95% CI: 0.125-0.788], p=0.0098)",
            "OS": "None",
            "ORR": "15.0% vs. 5.6%",
            "AE": "The safety was similar to previous results without new safety signals appearing.",
            "Other": "DCR: 80% vs. 55.6%",
            "Conclusion": "PFS was significantly prolonged and ORR was improved in mCRC patients treated with Fru plus Cap as first-line maintenance treatment. This trial is ongoing and updated data will be presented in the future."
        },
        {
            "Title": "New drugs to prevent oxaliplatin-induced peripheral neuropathy: A double-blind, randomized study.",
            "Indication": "Stage II/III colorectal cancer",
            "Drug": "Lecithinized human copper/zinc superoxide dismutase (PC-SOD) vs. placebo",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "No serious adverse events related to PC-SOD. Lower incidence of neutropenia, diarrhea, stomatitis, and malaise in the study group, but differences were not significant. HSR: 7.0% in the study group vs. 23.4% in the placebo group.",
            "Other": "The study group had a significantly greater number of cycles before DEB-NTC G2 PN (P=0.08), NCI-CTC G1 PN (P=0.01), and HSR (P=0.03). The average total oxaliplatin dose was higher in the study group but not statistically significant.",
            "Conclusion": "The primary endpoint did not occur; however, the study group had a significantly greater number of cycles before DEB-NTC G2 PN. Notably, the study group also had a significantly greater number of cycles before HSR. These results show that PC-SOD may suppress HSR as well as PN, both of which limit administration of oxaliplatin. Thus, this drug is expected to improve not only patient quality of life but also the prognosis of patients."
        },
        {
            "Title": "First-line serplulimab plus HLX04 and XELOX versus placebo plus bevacizumab and XELOX in metastatic colorectal cancer: A phase 2/3 study.",
            "Indication": "Metastatic colorectal cancer (mCRC)",
            "Drug": "Serplulimab, HLX04, XELOX",
            "PFS": "16.8 vs. 10.7 months; stratified HR 0.62, 95% CI 0.33\u20131.12",
            "OS": "NR vs. 21.2 months; stratified HR 0.75, 95% CI 0.43\u20131.30",
            "ORR": "None",
            "AE": "69.1% in group A and 59.6% in group B had grade \u22653 treatment-related adverse events",
            "Other": "DOR: 19.4 vs. 12.6 months; stratified HR 0.33, 95% CI 0.13\u20130.85",
            "Conclusion": "With an extended follow-up duration, improved survival benefits demonstrated with the addition of serplulimab were maintained in the first-line treatment of mCRC, alongside a manageable safety profile. These results support serplulimab plus HLX04 and XELOX as a promising first-line treatment option for mCRC that warrants further investigation."
        },
        {
            "Title": "Irinotecan plus anlotinib or further in combination with penpulimab as second-line treatment of metastatic colorectal cancer (ZL-IRIAN).",
            "Indication": "Metastatic colorectal cancer (mCRC)",
            "Drug": "Irinotecan, anlotinib, penpulimab",
            "PFS": "Cohort A: 9.57 months (95% CI 5.24-13.90 months); Cohort B: Not reached",
            "OS": "None",
            "ORR": "Cohort A: 39.1% (9/23); Cohort B: 42.9% (9/21)",
            "AE": "Cohort A: 65.2% experienced grade \u22653 adverse events; Cohort B: 57.1% experienced grade \u22653 adverse events",
            "Other": "DCR: Cohort A: 87.0% (20/22); Cohort B: 90.5% (19/21)",
            "Conclusion": "The combination of anlotinib with irinotecan +/- PD-1 blockade in second-line treatment for mCRC had shown promising efficacy and good safety. Whether PD-1 blockade could bring extra survival benefits required longer follow-up."
        },
        {
            "Title": "Efficacy and safety of fruquintinib plus investigator\u2019s choice of chemotherapy as second-line therapy in metastatic colorectal cancer: Updated results of a multicenter, single-arm, phase 2 trial.",
            "Indication": "Metastatic colorectal cancer (mCRC)",
            "Drug": "Fruquintinib",
            "PFS": "6.9 months (95% CI, 5.2-8.6)",
            "OS": "20.1 months (95% CI, 13.7-26.3)",
            "ORR": "26.0% (95% CI, 17.9-36.2)",
            "AE": "33.0% experienced grade 3/4 adverse effects",
            "Other": "DCR: 83.3% (95% CI, 74.0-89.9); DoR: 11.3 months (95% CI, 8.6-14.0)",
            "Conclusion": "Fruquintinib combined with investigator\u2019s chemotherapy followed by fruquintinib maintenance as second-line therapy showed satisfactory anti-tumor activity, prolonged PFS and OS, and manageable safety profiles in pts with mCRC. Further analysis of all pts with long-term follow-up will be presented in the future."
        },
        {
            "Title": "Neoadjuvant chemotherapy with atezolizumab, bevacizumab, and FOLFOX for patients with unresectable colorectal liver metastases: A translational pilot study.",
            "Indication": "Unresectable colorectal liver metastases (CRLM)",
            "Drug": "Atezolizumab, bevacizumab, and FOLFOX (ABFOLFOX)",
            "PFS": "22.7 months",
            "OS": "88% at 24 months",
            "ORR": "80%",
            "AE": "None",
            "Other": "Serial immune cell expansion was identified in CRLM of good responders.",
            "Conclusion": "Neoadjuvant ABFOLFOX induced durable responses more than 24 months with or without LTM in 37% of pts with unresectable CRLM. Serial immune cell expansion was identified in CRLM of good responders, suggesting immune monitoring can be useful in identifying pts who may derive durable benefits from ICIs in CRLM."
        },
        {
            "Title": "A phase II trial of alternating mXELOX/mXELIRI plus bevacizumab (BEV) as first-line treatment for metastatic colorectal cancer (mCRC).",
            "Indication": "Metastatic colorectal cancer (mCRC)",
            "Drug": "Alternating mXELOX/mXELIRI plus bevacizumab (BEV)",
            "PFS": "11.0 months (95% CI 9.0\u201312.4)",
            "OS": "28.1 months (95% CI 18.4\u201334.0)",
            "ORR": "73% (95% CI 59%\u201384%)",
            "AE": "Grade 3\u20134 TRAE in 33% of patients, including hypertension (25%), decreased neutrophil count (6%), hand-foot syndrome (4%), and diarrhea (2%).",
            "Other": "Disease control rate was 94% (95% CI 84%\u201399%).",
            "Conclusion": "Alternating mXELOX/mXELIRI plus BEV demonstrated promising anti-tumor activity against mCRC, with acceptable tolerability and no new safety signal. Further studies were warranted to confirm the efficacy of the regimen."
        },
        {
            "Title": "Extent of lymphadenectomy for surgical management of right-sided colon cancer: Multicenter, open label, randomized, controlled trial.",
            "Indication": "Right-sided colon cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "3-year DFS: 86.1% in CME group vs. 81.9% in D2 group; HR=0.74; 95% CI: 0.54-1.02; P=0.06. 3-year OS: 94.7% in CME group vs. 92.6% in D2 group; HR=0.70; 95% CI: 0.43-1.16; P=0.17.",
            "Conclusion": "For primary surgical excision of right-sided colon cancer, complete mesocolic excision did not improve disease-free survival, compared to standard D2 lymph node dissection. Standard D2 dissection should be the routine procedure for these patients. CME should only be considered in patients with obvious mesocolic lymph node involvement."
        },
        {
            "Title": "Early [18F]FDG PET/CT response evaluation after a single dose of anti-EGFR therapy as a predictive biomarker for treatment benefit in patients with advanced colorectal cancer.",
            "Indication": "Advanced colorectal cancer",
            "Drug": "Anti-EGFR monoclonal antibody (cetuximab or panitumumab)",
            "PFS": "6.5 months for metabolic responders vs. 1.7 months for metabolic non-responders",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Mean change in sum TLG was -58% for patients with clinical benefit vs. -19% without clinical benefit; P=0.003. A threshold of -15% change in sum TLG had a 100% negative predictive value for clinical benefit.",
            "Conclusion": "Early [18F]FDG PET/CT after a single dose of anti-EGFR mAb therapy demonstrates a 100% negative predictive value for clinical benefit. Selection of patients with left-sided RAS wt/BRAF wt mCRC successfully identifies the majority (91%) of patients with clinical benefit from anti-EGFR monotherapy. For mCRC patients with inconclusive mutational screens, early [18F]FDG PET/CT evaluation may have clinical value to predict treatment benefit."
        },
        {
            "Title": "Iparomlimab (QL1604) in patients (pts) with unresectable/metastatic deficient mismatch repair (dMMR)/microsatellite instability high (MSI-H) solid tumors: A single-arm, multicenter, pivotal, phase II study.",
            "Indication": "unresectable/metastatic dMMR/MSI-H solid tumors",
            "Drug": "Iparomlimab",
            "PFS": "Median PFS: NE (30.5\u2013NE) months in ITT, NE (16.4\u2013NE) months in FAS; 24-mo PFS rate: 61.2% (50.8\u201370.1) in ITT, 62.8% (47.1\u201374.9) in FAS",
            "OS": "Median OS: NE (NE\u2013NE) months in ITT, NE (20.8\u2013NE) months in FAS; 24-mo OS rate: 70.9% (61.0\u201378.7) in ITT, 65.3% (49.8\u201377.1) in FAS",
            "ORR": "ORR: 48.3% (39.1\u201357.6) in ITT, 50.0% (36.8\u201363.2) in FAS",
            "AE": "TRAE of any grade: 79.2%; Grade \u22653 TRAE: 5.0%; irAE of any grade: 32.5%; Most common irAE: hypothyroidism (10.0%); TRSAEs: 10.8%",
            "Other": "DCR: 77.5% (69.0\u201384.6) in ITT, 75.0% (62.1\u201385.3) in FAS; Median DoR: NE (27.8\u2013NE) in ITT, NE (NE\u2013NE) in FAS; 24-mo DoR rate: 95.0% (81.1\u201398.7) in ITT, 95.2% (70.7\u201399.3) in FAS",
            "Conclusion": "At 17-month follow-up, iparomlimab showed encouraging efficacy with durable response and tolerable safety in patients with unresectable/metastatic dMMR/MSI-H solid tumors in ITT and FAS."
        },
        {
            "Title": "Efficacy and safety of fruquintinib in patients with metastatic colorectal cancer according to prior treatment sequence in the refractory setting: Results from FRESCO and FRESCO-2.",
            "Indication": "metastatic colorectal cancer",
            "Drug": "Fruquintinib",
            "PFS": "Median PFS: 3.7 months (95% CI: 2.7\u20135.6) for prior TAS then rego, 3.8 months (95% CI: 2.7\u20135.6) for prior rego then TAS, 3.6 months (95% CI: 3.0\u20134.7) for prior TAS only, 3.6 months (95% CI: 3.0\u20134.7) for prior rego only, 3.7 months (95% CI: 3.0\u20134.7) for TAS and rego na\u00efve",
            "OS": "Median OS: 6.6 months (95% CI: 4.9\u20137.8) for prior TAS then rego, 8.5 months (95% CI: 6.3\u201310.5) for prior rego then TAS, 7.7 months (95% CI: 6.7\u20138.5) for prior TAS only, 10.2 months (95% CI: 6.1\u201311.6) for prior rego only, 9.3 months (95% CI: 8.2\u201310.5) for TAS and rego na\u00efve",
            "ORR": "None",
            "AE": "Grade \u22653 AEs: 67% for prior TAS then rego, 60% for prior rego then TAS, 60% for prior TAS only, 70% for prior rego only, 61% for TAS and rego na\u00efve",
            "Other": "None",
            "Conclusion": "Fruquintinib is effective in metastatic colorectal cancer with a manageable safety profile regardless of prior TAS or regorafenib treatment and sequence. Improvement with fruquintinib was similar for OS and PFS despite the number and sequence of prior regimens."
        },
        {
            "Title": "Long-term outcomes of ablation versus surgery in patients with colorectal lung oligometastasis: A retrospective cohort study.",
            "Indication": "Colorectal lung oligometastasis (CLOM)",
            "Drug": "Ablation vs. Surgery",
            "PFS": "12.1 months (Ablation) vs. 11.5 months (Surgery), HR not provided",
            "OS": "73.3 months (Ablation) vs. 70.4 months (Surgery), HR not provided",
            "ORR": "None",
            "AE": "None",
            "Other": "Local recurrence rates: 26.7% (Ablation) vs. 22.0% (Surgery)",
            "Conclusion": "There was no significant difference between surgery and ablation in long-term survival benefit to CLOM patients."
        },
        {
            "Title": "Staged resection versus simultaneous resection for conversion surgery for initially unresectable colorectal liver metastasis: A prospective cohort study.",
            "Indication": "Initially unresectable colorectal liver metastasis (CRLM)",
            "Drug": "Staged resection vs. Simultaneous resection",
            "PFS": "None",
            "OS": "HR=0.798, 95% CI 0.580-1.098, P=0.163",
            "ORR": "None",
            "AE": "37.4% (Staged resection) vs. 50.5% (Simultaneous resection), P=0.027",
            "Other": "RFS: HR=0.762, 95% CI 0.600-0.969, P=0.024; Liver-specific RFS: HR=0.709, 95% CI 0.554-0.908, P=0.005",
            "Conclusion": "Simultaneous resection of the primary tumor and liver metastases was associated with an increased incidence of postoperative complications and impaired relapse-free survival."
        },
        {
            "Title": "Time to target TIM-3 in colorectal cancer: TIM-3 expression to predict survival outcomes in patients in CALGB (Alliance)/SWOG 80405.",
            "Indication": "Colorectal cancer (CRC)",
            "Drug": "TIM-3 inhibitors",
            "PFS": "9.2 vs 11.4 vs 12.9 months (low, medium, high TIM-3 expression), p=0.028",
            "OS": "23.6 vs 30.9 vs 35.5 months (low, medium, high TIM-3 expression), p=0.0049",
            "ORR": "None",
            "AE": "None",
            "Other": "TIM-3 expression associated with genes involved in immune regulation and EGFR resistance pathways.",
            "Conclusion": "TIM-3 plays an important role in the immune microenvironment and may be involved in EGFR resistance pathways in CRC. High TIM-3 expression is predictive of worse survival outcomes."
        },
        {
            "Title": "Impact of colorectal liver metastases (CRLM) in patients with metastatic colorectal cancer (mCRC) receiving trifluridine/tipiracil (FTD/TPI) with or without bevacizumab (BEV) in the phase 3 SUNLIGHT trial.",
            "Indication": "Metastatic colorectal cancer (mCRC)",
            "Drug": "Trifluridine/tipiracil (FTD/TPI) with or without bevacizumab (BEV)",
            "PFS": "HR=0.44 (95% CI: 0.35, 0.55; P<0.001) with CRLM, HR=0.38 (95% CI: 0.24, 0.58; P<0.001) without CRLM",
            "OS": "HR=0.61 (95% CI: 0.48, 0.77; P<0.001) with CRLM, HR=0.60 (95% CI: 0.35, 1.0; P=0.052) without CRLM",
            "ORR": "None",
            "AE": "None",
            "Other": "DCR: 68.6% vs 38.3% (with CRLM), 73.1% vs 53.5% (without CRLM)",
            "Conclusion": "Patients treated with the combination of FTD/TPI + BEV had numerically longer OS and PFS and a higher DCR than those treated with FTD/TPI alone, irrespective of whether or not patients had CRLM at baseline."
        },
        {
            "Title": "Efficacy and safety of combination therapy with binimetinib, encorafenib, and cetuximab for BRAF non-V600E mutated metastatic colorectal cancer: Results from a phase 2 BIG BANG trial (EPOC1703).",
            "Indication": "BRAF non-V600E mutated metastatic colorectal cancer",
            "Drug": "binimetinib, encorafenib, cetuximab",
            "PFS": "Primary Analysis Cohort: 3.3 months (95% CI, 0.9-4.6); Anti-EGFR Antibody Refractory Cohort: 3.0 months (95% CI, 1.9-4.4)",
            "OS": "Primary Analysis Cohort: 9.7 months (95% CI, 3.4-25.5); Anti-EGFR Antibody Refractory Cohort: 7.6 months (95% CI, 3.6-12.9)",
            "ORR": "Primary Analysis Cohort: 16.7% (95% CI, 2.1-48.4%); Anti-EGFR Antibody Refractory Cohort: 10.0% (95% CI, 1.2-31.7%)",
            "AE": "Grade 3 or higher adverse events occurred in 4 patients (Primary Analysis Cohort) and 10 patients (Anti-EGFR Antibody Refractory Cohort)",
            "Other": "No new safety signals were identified.",
            "Conclusion": "The combination therapy with binimetinib, encorafenib, and cetuximab showed modest efficacy for BRAF non-V600E mutated mCRC, with a manageable safety profile, although there was not enough statistical power due to slow accrual. Circulating-tumor DNA analyses at the timepoint of before and after treatment are ongoing."
        },
        {
            "Title": "Artemin and immune checkpoint inhibitor (ICI) efficacy in metastatic colorectal cancer (mCRC): A correlative analysis of the Canadian Cancer Trials Group (CCTG) CO.26 trial.",
            "Indication": "Metastatic colorectal cancer (mCRC)",
            "Drug": "Durvalumab plus tremelimumab",
            "PFS": "None",
            "OS": "High artemin patients: D+T improved OS compared to BSC (mOS 6.44 vs. 3.17 months, HR 0.54 [0.33-0.91], p=0.020); No difference in OS between D+T and BSC arms in artemin low patients (mOS 6.64 vs. 5.65 months, HR 0.87 [0.53-1.43], p=0.58, multivariable p-interaction=0.061)",
            "ORR": "None",
            "AE": "None",
            "Other": "In the D+T arm, patients with progressive disease had significant increases in artemin levels at week 8 compared to baseline (p=0.026), while there was no significant change in week 8 artemin levels in patients with stable disease (p=0.14).",
            "Conclusion": "High baseline artemin may be a poor prognostic marker in MSS mCRC and increase in artemin level during treatment can be indicative of progressive disease. High baseline artemin may predict benefit from ICI treatment. Studies are warranted to better understand and explore targeting the EPC-artemin axis to enhance efficacy of ICI in MSS mCRC."
        },
        {
            "Title": "A phase II open-label study with atezolizumab (atezo) in combination with bevacizumab (bev) in patients (pts) with advanced chemotherapy-resistant colorectal cancer (CRC) and MSI-like molecular signature: The MoTriColor Consortium CT3 study.",
            "Indication": "Advanced chemotherapy-resistant colorectal cancer (CRC)",
            "Drug": "Atezolizumab (atezo) and bevacizumab (bev)",
            "PFS": "Median PFS was 6.4 months (CI 95% 4.1-21). In the sub-cohorts of MSI-H/dMMR-MSI-like and MSS/pMMR-MSI-like, mPFS was 23.0 and 4.0 months, respectively.",
            "OS": "None",
            "ORR": "Overall ORR was 38.7% (CI 95%: 24.4%-54.5%; P=0.053). In the sub-cohorts of MSI-H/dMMR-MSI-like and MSS/pMMR-MSI-like pts, ORR was 65.2% (P < 0.001) and 9.5% (P = 0.13), respectively.",
            "AE": "Grade 3/4 adverse events related to atezo and bev were reported in 11% and 20% of the pts. There were 2 Grade 5 AEs (1 pulmonary distress and 1 colonic hemorrhage).",
            "Other": "Exploratory analysis showed ORR was 33% in pts with liver mets and 76% without liver mets in MSI-H/dMMR-MSI-like tumors. In MSS/pMMR-MSI-like tumors, ORR was 0% in pts with liver mets and 15% without liver mets.",
            "Conclusion": "The primary study endpoint was not met, but clinically meaningful ORR was found with atezo in combination with bev in pts with MSI-H/dMMR-MSI-like tumors. In MSS/pMMR-MSI-like tumors, anti-tumor activity was confined to pts without liver mets. These results open a new therapeutic option for mCRC pts with MSI-H/dMMR tumors."
        },
        {
            "Title": "Comparative analysis of first-line therapy with fruquintinib plus chemotherapy versus standard therapy in advanced metastatic colorectal cancer (mCRC): A prospective cohort study compared with propensity score matching (PSM) cohort.",
            "Indication": "Advanced metastatic colorectal cancer (mCRC)",
            "Drug": "Fruquintinib plus mFOLFOX6/FOLFIRI",
            "PFS": "Median PFS was 16.76 months (95% CI 12.25-18.6) in FRU regimens, significantly longer than 10.38 months of STD regimens (p=0.0115). After 1:1.5 PMS, pts with FRU regimens showed a significant improvement in median PFS compared with STD regimens (15.9 months vs 11.3 months, p=0.020, HR=0.472, 95% CI: 0.251-0.889).",
            "OS": "None",
            "ORR": "Pts received FRU regimens were benefit with a higher ORR than STD regimens (83.33% vs 61.90%, p=0.018, OR=3.077, 95% CI: 1.181-8.017), especially RAS Mut pts (87.50% vs 58.33%, p=0.051).",
            "AE": "Most common grade 3/4 TEAEs were neutrophil count decreased (18.60%), leukopenia (16.28%) and lymphocytopenia (6.98%). Only one serious AE reported with gastric perforation recovered after non-operative therapy.",
            "Other": "None",
            "Conclusion": "The fruquintinib combination regimens as a first-line therapy for pts with mCRC significantly improved median PFS compared to standard treatment. Similar to the primary analysis, the safety profile was generally manageable with longer duration of treatment exposure. Fruquintinib combined with mFOLFOX6/FOLFIRI might be a more promising treatment option as first-line therapy for mCRC pts."
        },
        {
            "Title": "First-in-class PD-1/IL-2 bispecific antibody fusion protein IBI363 in patients with advanced colorectal cancer: Safety and efficacy results from a phase I study.",
            "Indication": "Advanced colorectal cancer",
            "Drug": "IBI363",
            "PFS": "None",
            "OS": "None",
            "ORR": "Overall ORR was 12.7% (95% CI: 5.6-23.5). In pts with liver metastasis, ORR was 13.2% (95% CI: 4.4-28.1). In pts with PD-L1 CPS \u22651, ORR was 30.8% (95% CI: 9.1-61.4), and DCR was 76.9% (95% CI: 46.2-95.0).",
            "AE": "Treatment emergent adverse events (TEAEs) were reported in 65 (95.6%) pts including grade \u22653 TEAEs in 22 (32.4%) pts. Common TEAEs (\u226520%) were arthralgia (35.3%), anemia (32.4%), pyrexia (22.1%) and hypoalbuminemia (20.6%). Treatment related adverse events (TRAEs) were reported in 62 (91.2%) pts including grade \u22653 TRAEs in 16 (23.5%) pts. Immune related adverse events (irAEs) were reported in 22 (32.4%) pts including grade \u22653 irAEs in 4 (5.9%) pts. Serious TRAE were reported in 12 (17.6%) pts. TRAEs leading to treatment interruption and discontinuation were reported in 25 (36.8%) and 2 (2.9%) pts. No TRAE leading to death was reported.",
            "Other": "Median follow-up time was 5.3 months (95% CI: 4.4-6.9). The median DoR was not reached.",
            "Conclusion": "IBI363 showed acceptable tolerability and manageable safety profiles in pts with advanced CRC. Preliminary efficacy of IBI363 was observed, particularly in pts with PD-L1 CPS \u22651."
        },
        {
            "Title": "A phase II, multicenter, open-label study of polyPEPI1018 in combination with atezolizumab in participants with relapsed or refractory microsatellite-stable metastatic colorectal (MSS mCRC) cancer (Oberto-301).",
            "Indication": "Relapsed or refractory microsatellite-stable metastatic colorectal cancer",
            "Drug": "PolyPEPI1018 and atezolizumab",
            "PFS": "mPFS was 2.7 months (95% CI 1.4-4.0)",
            "OS": "mOS was not reached (95% CI 8.75 \u2013 NE months)",
            "ORR": "ORR was 0% and the DCR was 61%",
            "AE": "The combination was well-tolerated; most common side effect related to treatment was Grade (Gr) 1-2 local skin reaction (n=22). Gr 2 events (n=2) at least possibly related to treatment were nausea, pyrexia and increased blood alkaline phosphatase. There were no Gr 3-5 events or study discontinuation due to treatment AE.",
            "Other": "Ex-vivo FluoroSpot identified 13/16 subjects with robust, vaccine-specific CD8+ and/or CD4+ T cell responses detected against multiple vaccine antigens. For patients with available biopsy pairs (n=8), both the average PD-L1 expression (IC%, p=0.029) and the density of CD8+ tumor-infiltrating lymphocytes (TILs, p=0.019) were significantly increased, post-treatment. Patients with increased PFS (>12 weeks) had both higher levels of post-treatment PD-L1 (p=0.007) and TILs (p=0.016) than patients with PFS \u226412 weeks.",
            "Conclusion": "PolyPEPI1018 in combination with atezolizumab was well-tolerated with no Grade 3 AEs observed. Despite no objective tumor responses could be detected, correlative data suggest contribution of PolyPEPI1018-induced immune responses to the modulation of tumor microenvironment and to improved disease control. The study did not proceed to stage 2; it is on-going for the collection of survival data."
        },
        {
            "Title": "Preliminary antitumor activity of COM701 in combination with COM902 and pembrolizumab in patients with MSS-CRC and liver metastases.",
            "Indication": "Metastatic microsatellite-stable colorectal cancer with liver metastases",
            "Drug": "COM701, COM902, and pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "Objective response rate [ORR] 1/20 [5%] pts; 7 pts with SD. Disease control rate [CR+PR+SD] 8/20 [40%]. In pts with liver mets ORR 1/15 [7%], DCR 6/15 (40%); 6/8 [75%] pts with best response \u2265SD had liver mets.",
            "AE": "Treatment related AEs were reported in 11/20 [55%] pts, the majority were \u2264G2 7/20 [35%] with the most frequent TRAE of 4 pts each with \u2264G2 fatigue, myalgia, 4/20 pts with G3 TRAE, there were no \u2265G4 TRAEs.",
            "Other": "Strong peripheral IFN\u0263 induction was observed after treatment. Three pts [1 PR, 2 SD] are ongoing at the time of data cut.",
            "Conclusion": "The data further supports the antitumor activity of the combination of COM701 + COM902 + pembrolizumab in pts with MSS-CRC and specifically in patients with liver metastases. Of note the data further reinforces the data previously disclosed in a similar pt population of 22 pts that received COM701 + nivolumab. No new safety signals are reported."
        },
        {
            "Title": "Association of circulating DNA with recurrence in CRS-HIPEC patients with colorectal and appendiceal peritoneal carcinomatosis: Insights from a phase II randomized trial.",
            "Indication": "Colorectal and appendiceal peritoneal carcinomatosis",
            "Drug": "Mitomycin-C versus Melphalan",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Disease-Free Survival (DFS): Pre-op ctDNA negative group: 15.9 months; Pre-op ctDNA positive group: 39.9 months",
            "Conclusion": "The unexpected numerically favorable DFS in the pre-op ctDNA(+) group, in comparison to the pre-op ctDNA(-) group, challenges the utility of pre-op ctDNA status in guiding CRS+HIPEC decision-making. Though limited by small sample size, this finding may be likely due to the impact of post-op therapy which notably converted 10 pre-op ctDNA(+) pts to post-op ctDNA(-) status. Our findings warrant validation in a larger study to unravel the multifaceted factors influencing ctDNA\u2019s prognostic value."
        },
        {
            "Title": "Preoperative (neoadjuvant) therapy with tislelizumab for locally advanced colorectal cancer with high microsatellite instability or deficient mismatch repair: An open-label, single-arm, multicenter phase II study.",
            "Indication": "Locally advanced colorectal cancer with high microsatellite instability or deficient mismatch repair",
            "Drug": "Tislelizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Any-grade TRAEs: 60.6%; Grade \u22653 TRAEs: 3.0%; Serious TRAEs: 6.1%",
            "Other": "Major pathological response (MPR) rate: 89.7%; Pathological complete response (pCR) rate: 62.1%; R0 surgical resection rate: 100%",
            "Conclusion": "Neoadjuvant TIS was associated with high MPR and pCR rates and an acceptable safety profile, and did not compromise surgery in pts with resectable MSI-H/dMMR CRC."
        },
        {
            "Title": "Phase II trial of organ preservation program using short-course radiation and FOLFOXIRI for rectal cancer (SHORT-FOX).",
            "Indication": "Rectal cancer",
            "Drug": "Short-course radiation and FOLFOXIRI",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Serious adverse events: 13.5%; Non-hematologic grade 4 toxicity: 1 patient",
            "Other": "Clinical complete response (cCR) rate: 24.3%; Near cCR rate: 21.6%; 12-month TME-free survival: 37.2%",
            "Conclusion": "Short-course radiation followed by FOLFOXIRI was feasible and safe. In this high-risk cohort of pts with rectal cancer, 24.3% of pts had a cCR at the time of primary assessment."
        },
        {
            "Title": "A multicenter single-arm phase II study examining the efficacy of TNT for locally advanced rectal cancer: ENSEMBLE-2.",
            "Indication": "Locally advanced rectal cancer",
            "Drug": "Total Neoadjuvant Therapy (TNT) with chemoradiotherapy and CAPOX",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Grade 3 or higher adverse events: Diarrhea (7.1%), Leukopenia (7.1%), Neutropenia (3.6%)",
            "Other": "Pathologic complete response (pCR) rate: 23.8%; NOM rate with pCR + cCR: 35.7%; 12-month recurrence-free survival rate: 92%",
            "Conclusion": "TNT comprising chemoradiotherapy and CAPOX4 showed the expected efficacy, including transitioning to NOM, and was deemed a safe treatment option. Future studies will compare these results to the ENSEMBLE-1 trial, assessing long-term outcomes, and explore the correlation with minimal residual disease."
        },
        {
            "Title": "Characteristics of patients (pts) with disease relapse despite absence of molecular residual disease (MRD) after resection of colorectal oligometastases (CRM): Implications from PRECISION study.",
            "Indication": "Colorectal cancer",
            "Drug": "Not specified",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Median recurrence-free survival (RFS) of MRD-negative relapsed pts was longer than that of MRD positive pts (8.6m vs. 3.5m; hazard ratio [HR], 0.28 [95% CI, 0.13-0.58]).",
            "Conclusion": "Pts with a history of LNM or poor pre-operative genomic profile were at risk of recurrence despite the absence of MRD after curative-intent resection of CRM."
        },
        {
            "Title": "Korean Society of Coloproctology trial of consolidation chemotherapy for locally advanced mid or low rectal cancer after neoadjuvant concurrent chemoradiotherapy: KONCLUDE multicenter randomized trial.",
            "Indication": "Locally advanced rectal cancer",
            "Drug": "Modified FOLFOX-6 consolidation chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Grade 3/4 adverse events occurred in 14 patients (9.1%) in the consolidation chemotherapy group.",
            "Other": "The pCR rate was not different between the two groups (STAN 16.0% vs. CON 22.1% p=0.194).",
            "Conclusion": "The consolidation group achieved more pCR than the standard group, however it did not meet the hypothesis of the KONCLUDE trial. The three cycles of modified FOLFOX6 consolidation chemotherapy might not be enough to achieve pCR in advanced mid or low-rectal cancer patients."
        },
        {
            "Title": "Prognostic and predictive value of ctDNA-based MRD and actionable biomarkers in patients with resectable colorectal cancer: CIRCULATE-Japan GALAXY",
            "Indication": "Resectable colorectal cancer",
            "Drug": "Not specified",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "ctDNA positivity during the MRD window varied from 5.1% in those with TMB high and MSI high to 29.8% in those with ERBB2 amplification. ERBB2 amplification was the only actionable biomarker significantly associated with ctDNA positivity during the MRD window (odds ratio 2.20). Positive ctDNA MRD status correlated with significantly worse DFS across all actionable biomarkers, with hazard ratios ranging from 8.46 for TP53 Y220C to 5.53e+8 for BRAF V600E. In MSI high disease, recurrence was observed in only 3 of 225 (1.3%) patients with negative ctDNA compared to 5 of 12 (41.7%) with positive ctDNA during the MRD window.",
            "Conclusion": "Our study highlights the importance of ctDNA-based MRD as both a prognostic and predictive tool for CRC across different actionable biomarkers. These findings suggest the potential of MRD-driven targeted therapeutic strategies for CRC with specific actionable biomarkers."
        },
        {
            "Title": "Neoadjuvant long-course chemoradiation plus PD1 blockade for locally advanced rectal cancer: Results of a phase 2, open-label, randomized controlled trial",
            "Indication": "Locally advanced rectal cancer",
            "Drug": "Tislelizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "No significant differences between either one of the experiment groups and the control group regarding disease progression rate, grade 3-4 treatment-related adverse event rate, and grade 3-4 surgical complication rate.",
            "Other": "The pCR rates were 33.9% and 34.5% for experiment group A and B respectively, both significantly higher than the pCR rate of 15.5% in the control group (p=0.021 and 0.019).",
            "Conclusion": "For patients with mid-low locally advanced rectal cancer, the addition of PD1 inhibitors to neoadjuvant long-course chemoradiation, whether concurrently or sequentially, increases pCR rate, meanwhile raises no significant safety concerns. For the next step, phase 3 trials with larger sample sizes are encouraged."
        },
        {
            "Title": "Circulating tumor DNA as a predictive biomarker for pathologic response after treatment with neoadjuvant immunotherapy for localized dMMR/MSI-H colorectal cancer",
            "Indication": "Localized dMMR/MSI-H colorectal cancer",
            "Drug": "Pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Clearance of ctDNA following neoadjuvant IO appears to predict pCR. Absence of detectable ctDNA at baseline may predict a higher likelihood for pCR.",
            "Conclusion": "Clearance of ctDNA following neoadjuvant IO appears to predict pCR in this pilot analysis of pts with localized dMMR/MSI-H CRC, even after a median of 2 cycles. The absence of detectable ctDNA at baseline, perhaps a surrogate for lower tumor burden at initial diagnosis, may also predict a higher likelihood for pCR. Our work suggests utility of ctDNA as a biomarker for improving risk-stratification and response assessment sensitivity."
        },
        {
            "Title": "Phase II study of neoadjuvant short-course radiotherapy combined with CAPEOX plus envafolimab in patients with microsatellite stable locally advanced rectal cancer: Updated results of PRECAM study",
            "Indication": "Microsatellite Stable (MSS) locally advanced rectal cancer (LARC)",
            "Drug": "CAPEOX (capecitabine and oxaliplatin) and Envafolimab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Common adverse events included tenesmus (78.1%), diarrhea (62.5%), and leukocyte decrease (40.6%), with two cases of Grade 3 adverse events.",
            "Other": "pCR rate was 62.5% (20/32), MPR rate was 75% (24/32), and 65.6% (21/32) patients achieved a neoadjuvant rectal (NAR) score below 8.",
            "Conclusion": "Short-course radiotherapy combined with CAPEOX plus Envafolimab showed favorable pCR rate with manageable adverse effects and surgical complications in LARC, underscoring the potential of the combination therapy for MSS LARC. A Randomized controlled trial is warranted for further exploration and validation."
        },
        {
            "Title": "Individualized tumor-informed circulating tumor DNA analysis for molecular residual disease detection in predicting recurrence and efficacy of adjuvant chemotherapy in colorectal cancer",
            "Indication": "Colorectal cancer (CRC)",
            "Drug": "Adjuvant chemotherapy (ACT)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The brPROPHET assay had a positivity rate of 96% in preoperative samples. ctDNA positivity indicated by brPROPHET predicted worse recurrence-free survival (RFS) at both POD7 (HR=7.35) and POD30 (HR=5.18). Higher mean tumor molecules (MTM) level >0.0316/mL was associated with worse RFS (HR=44.47).",
            "Conclusion": "The individualized tumor-informed brPROPHET assay effectively identified postoperative patients with high risk of recurrence and benefit from ACT. Using a personalized tumor-informed panel that covers sites outside fixed panels may improve the utility of MRD detection."
        },
        {
            "Title": "Survival and patient-reported outcomes of real-world patients with high-risk stage II and stage III colon cancer after 3 versus 6 months of adjuvant CAPOX.",
            "Indication": "High-risk stage II and stage III colon cancer",
            "Drug": "Capecitabine and oxaliplatin (CAPOX)",
            "PFS": "None",
            "OS": "3-year OS: 90% (95% CI [88-91%], HR 1.02, 95% CI [0.88-1.19])",
            "ORR": "None",
            "AE": "Lower long-term CIPN scores for 3 months (mean 16.5 SE [0.5]) vs. 6 months (26.2 [1.0], p < 0.001)",
            "Other": "Quality of life summary score higher for 3 months (83.9 [0.5]) vs. 6 months (80.9 [1.1], p = 0.013); better self-assessed workability for 3 months (7.6 [0.3]) vs. 6 months (6.6 [0.5], p = 0.042)",
            "Conclusion": "3 months of adjuvant CAPOX provided no disadvantage in OS and showed an advantage in neurotoxicity, quality of life, and workability."
        },
        {
            "Title": "Robotic versus laparoscopic surgery for middle and low rectal cancer (REAL): Primary outcome of a multicenter randomized controlled trial.",
            "Indication": "Middle and low rectal cancer",
            "Drug": "None (surgical intervention)",
            "PFS": "3-year disease-free survival: 87.3% (robotic) vs. 83.6% (laparoscopic), log-rank p = 0.035",
            "OS": "3-year OS: 94.8% (robotic) vs. 93.1% (laparoscopic), log-rank p = 0.155",
            "ORR": "None",
            "AE": "None",
            "Other": "3-year locoregional recurrence rate: 1.5% (robotic) vs. 4.0% (laparoscopic), log-rank p = 0.025, HR = 0.451 (95% CI = 0.221 to 0.921)",
            "Conclusion": "Robotic surgery for middle and low rectal cancer significantly reduced locoregional recurrence and improved disease-free survival compared with conventional laparoscopic surgery."
        },
        {
            "Title": "Longitudinal clinical performance of a novel tumor-naive minimal residual disease assay in patients with resected stage II and III colorectal cancer: A subset analysis from the GALAXY study in CIRCULATE-Japan.",
            "Indication": "Resected stage II and III colorectal cancer",
            "Drug": "None (diagnostic assay)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Clinical sensitivity: 50%, specificity: 88% at landmark timepoint (LMT); longitudinal analysis improved clinical sensitivity to 90.9% on surveillance",
            "Conclusion": "The xM ctDNA assay demonstrated remarkable clinical longitudinal performance and can accurately predict clinical recurrence on surveillance."
        },
        {
            "Title": "Clinical outcomes and impact of mismatch repair deficiency (dMMR) in patients with pT4N0 colon cancer (CC): Data from a large, consecutive, multicenter, real-world cohort.",
            "Indication": "pT4N0 colon cancer",
            "Drug": "Adjuvant chemotherapy (ACT)",
            "PFS": "3-year RFS: 70%, 5-year RFS: 55%",
            "OS": "3-year OS: 88%, 5-year OS: 73%",
            "ORR": "None",
            "AE": "None",
            "Other": "6-month ACT showed a trend towards better outcome compared to 3-month ACT; oxaliplatin-based ACT associated with significant improvement in OS compared to monotherapy",
            "Conclusion": "ACT provides large benefit in pT4N0 colon cancer patients, with oxaliplatin-based ACT potentially being better than monotherapy. 6-month oxaliplatin-based ACT showed a trend towards better outcome compared to 3-month ACT."
        },
        {
            "Title": "Postoperative positron emission tomography\u2013computed tomography (PET-CT) in patients (Pts) with pathological stage III colon cancer (CC): Results from the first prospective validation study.",
            "Indication": "Pathological stage III colon cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "The estimated 5-year overall survival rate for the entire cohort was 85.2%; the rates for true stage III pts and for pts found to have metastatic disease were 89.8% and 56.2%, respectively (P<0.0001).",
            "ORR": "None",
            "AE": "None",
            "Other": "The estimated 3-year disease-free survival rate was 83.9%.",
            "Conclusion": "Results from the first prospective study to evaluate the role of early postoperative PET-CT in pts with pathological stage III CC have validated earlier retrospective data: the use of PET-CT in this setting changed the staging and management of 13.4% of pts, allowing early detection of potentially curable metastatic disease or other malignancies. Moreover, the stage migration phenomenon observed in our study seems to have an impact on the pts overall outcome, compared to the literature. In light of the accumulating data from our center, suggesting a favorable cost-benefit of early postoperative PET-CT in pathological stage III CC, incorporation of the test into the routine management of these pts should be considered."
        },
        {
            "Title": "A phase II trial of TAS-102 in patients with colorectal cancer with ctDNA-defined minimal residual disease post-adjuvant therapy compared to synthetic control cohort: Results from the MD Anderson INTERCEPT program.",
            "Indication": "Colorectal cancer with ctDNA-defined minimal residual disease",
            "Drug": "TAS-102",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "At data cutoff, 14 pts receiving TAS-102 had 3-m ctDNA levels. 7 pts (50%) had undetectable ctDNA levels at 3-m compared to 2 pts (6.7%) in the SC (p=0.0022). 12 had a decrease in their ctDNA level (86%) compared to 5 (17%) in the SC (p<0.0001). At 6-m, 82% of pts on trial had ctDNA levels at or below prior levels. 5 pts (45%) maintained undetectable ctDNA at 6-m compared to 2 pts (6.7%) in the SC (p=0.0096). Median DFS was 9.4-m with TAS-102 compared to 5.8-m in the SC.",
            "Conclusion": "It is possible to identify MRD+ pts through screening during surveillance and enroll them onto therapeutic trials. Preliminary results suggest that treatment with TAS-102 is effective in inducing ctDNA clearance in the short term, and in doing so, may prolong DFS for pts receiving additional therapy compared to the SC. However, without evidence of persistent clearance this is unlikely to result in an increased cure fraction. Updated results, including survival data, will be presented."
        },
        {
            "Title": "A novel, non-invasive, multimodal screening test for the early detection of precancerous lesions and colorectal cancers using an artificial intelligence\u2013based algorithm.",
            "Indication": "Colorectal cancer (CRC) and precancerous lesions",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Sensitivity for detection of CRC overall was 92.3%. Sensitivity for AA detection was 82.2%. Specificity was 90.1% (AD+NC).",
            "Conclusion": "This innovative, non-invasive, multimodal screening strategy based on self-collected stool samples which combines mRNA expression patterns and FIT analysis with an AI/ML-generated algorithm represents a substantial improvement in the effectiveness of non-invasive CRC screening. Such improvements in usability and performance leading to reliable detection of early-stage CRC and precursor lesions, such as advanced adenomas, are required for wide-spread availability and adaptation of non-invasive screening tests to accepted clinically relevant usage and to prevent the development of CRC effectively."
        },
        {
            "Title": "Allele-specific KRAS mutations as prognostic biomarkers in mucinous adenocarcinoma of the appendix and colorectal cancer.",
            "Indication": "Mucinous adenocarcinoma of the appendix (MAAP) and colorectal cancer (CRC)",
            "Drug": "None",
            "PFS": "None",
            "OS": "In MAAP, median OS (mOS) in KRAS MT vs. KRAS wild-type (WT) was 147m vs. 50m (p=0.003). In CRC, mOS in KRAS MT vs. KRAS WT was 60m vs. 79m (p=0.012).",
            "ORR": "None",
            "AE": "None",
            "Other": "KRAS MT are more common in MAAP (79.8%) than in CRC (44.2%). In MAAP, mOS was 147m (p=0.004) in G12D, 74m (p=0.140) in G12V, not reached (NR) (p=0.049) in G13D, and 34m (p=0.589) in G12C. In CRC, mOS was 58m (p=0.249) in G12D, 58m (p=0.015) in G12V, 49m (p=0.002) in G13D, and 65m (p=0.437) in G12C.",
            "Conclusion": "KRAS MT are more common in MAAP (79.8%) than in CRC (44.2%). In contrast to CRC, KRAS MT in MAAP are associated with favorable prognosis. Allele-specific KRAS MT are associated with different mOS in MAAP and CRC. The mOS in MAAP with KRAS G12D was exceptionally favorable (147m). To our knowledge, this is the first study to explore allele-specific KRAS mutations as prognostic biomarkers in MAAP, and adds additional data to support the distinct differences between MAAP and CRC at the molecular level."
        },
        {
            "Title": "PREVECOL, a predictive model for early detection of colorectal cancer and precancerous lesions in liquid biopsy.",
            "Indication": "Colorectal cancer (CRC) and advanced adenoma (AA)",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "For CRC detection, PreveCol demonstrates an Area Under the ROC curve (AUC) of 92%, with a sensitivity (SS) of 81.8% and specificity (SP) of 86%. The model exhibits high sensitivity (above 90%) in identifying CRC in early stages (stages 1 and 2), as well as lesions located in the proximal colon. The AUC of PreveCol for Advanced Adenomas detection is 86.88%, with SS of 70.8% and SP of 86%.",
            "Conclusion": "PreveCol presents higher sensitivity compared to other liquid biopsy tests for early detection of CRC and AA. By facilitating the early detection of CRC and premalignant lesions as well as lesions in critical areas of the colon, PreveCol contributes to improved screening efficacy and patient outcomes."
        },
        {
            "Title": "MIRACLE1: Phase II study of radiotherapy followed by CapeOx and tirelizumab as first-line treatment in MSS rectal cancer with synchronous resectable liver/lung metastases.",
            "Indication": "MSS rectal cancer with synchronous resectable liver/lung metastases",
            "Drug": "CapeOx and tirelizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "1-year no-evidence of disease (NED) rate, acute toxicity related to immunotherapy",
            "Conclusion": "The study aims to investigate the safety and efficacy of Short-course radiotherapy (SCRT) combined with CapeOx and tirelizumab in MSS metastatic RC. The primary endpoint is the 1-year NED rate. Secondary endpoints include ORR, OS, PFS, and acute toxicity related to immunotherapy."
        },
        {
            "Title": "NRG-GI004/SWOG-S1610: Colorectal cancer metastatic dMMR immunotherapy (COMMIT) study\u2014A randomized phase III study of atezolizumab (atezo) monotherapy versus mFOLFOX6/bevacizumab/atezo in the first-line treatment of patients with deficient DNA mismatch repair (dMMR) or microsatellite instability-high (MSI-H) metastatic colorectal cancer (mCRC).",
            "Indication": "dMMR/MSI-H metastatic colorectal cancer",
            "Drug": "Atezolizumab, mFOLFOX6, bevacizumab",
            "PFS": "Primary endpoint is PFS",
            "OS": "Secondary endpoint",
            "ORR": "Secondary endpoint",
            "AE": "Secondary endpoint",
            "Other": "Disease control rate, duration of response, safety profile",
            "Conclusion": "The study hypothesizes that dMMR/MSI-H mCRC patients may be more effectively treated with the combination of PD-1/PD-L1 pathway blockade and mFOLFOX6/bevacizumab rather than with anti-PD-L1 therapy alone. The primary endpoint is PFS. Secondary endpoints include overall survival, objective response rate, safety profile, disease control rate, and duration of response."
        },
        {
            "Title": "Alliance A022101/NRG-GI009: A pragmatic randomized phase III trial evaluating total ablative therapy for patients with limited metastatic colorectal cancer\u2014Evaluating radiation, ablation, and surgery (ERASur).",
            "Indication": "Limited metastatic colorectal cancer",
            "Drug": "None specified",
            "PFS": "None",
            "OS": "Primary endpoint",
            "ORR": "None",
            "AE": "Secondary endpoint",
            "Other": "Event-free survival, treatment-related toxicities, local recurrence",
            "Conclusion": "The study is designed to evaluate the safety and efficacy of adding total ablative therapy (TAT) of all sites of disease to standard of care systemic treatment in those with limited metastatic CRC. The primary endpoint is OS. Secondary endpoints include event-free survival, treatment-related toxicities, and local recurrence."
        },
        {
            "Title": "STELLAR-303: A randomized phase 3 study of zanzalintinib (XL092) plus atezolizumab versus regorafenib in previously treated metastatic colorectal cancer.",
            "Indication": "Metastatic colorectal cancer (mCRC)",
            "Drug": "Zanzalintinib (XL092) plus atezolizumab",
            "PFS": "None",
            "OS": "Primary endpoint: OS in patients without liver metastases (LM); Key secondary endpoint: OS in all randomized patients",
            "ORR": "None",
            "AE": "Safety will be assessed",
            "Other": "None",
            "Conclusion": "STELLAR-303 enrollment is ongoing in the US, Europe, and Asia-Pacific region."
        },
        {
            "Title": "BreAK-CRC, a first-in-human phase I/II trial of STC-1010, a new allogenic cancer vaccine for advanced or metastatic unresectable colorectal cancer.",
            "Indication": "Advanced or metastatic unresectable colorectal cancer",
            "Drug": "STC-1010",
            "PFS": "Primary outcome: rate of 12 months progression-free survival based on RECIST criteria",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Minimal residual disease will be approached by ctDNA analysis",
            "Conclusion": "BreAK-CRC is meant to clinically confirm the promising preclinical outcomes obtained on different syngeneic mouse models and ex-vivo assays about the potential major therapeutic impact of STC-1010 on patients with pMMR/MSS and dMMR/MSI-H ICI-resistant unresectable advanced or metastatic colorectal cancers."
        },
        {
            "Title": "Phase 2 randomized study evaluating safety, efficacy, and optimal dose of ABBV-400 in combination with fluorouracil, folinic acid, and bevacizumab in previously treated patients with metastatic colorectal cancer.",
            "Indication": "Metastatic colorectal cancer (mCRC)",
            "Drug": "ABBV-400 in combination with fluorouracil, folinic acid, and bevacizumab",
            "PFS": "Dual primary endpoints: objective response and progression-free survival",
            "OS": "None",
            "ORR": "Primary endpoints: safety and objective response rate (ORR) determined by investigators",
            "AE": "Primary endpoints: safety",
            "Other": "Secondary endpoints included disease control rate (DCR), PFS rate at 6 months and 12 months, OS rate at 6 months and 12 months, DCR, quality of life score (QoL), and nutritional score PG-SGA",
            "Conclusion": "Enrollment was initiated in November 2023, with 3 patients enrolled as of January 4, 2024."
        },
        {
            "Title": "Efficacy and safety of chidamide in combination with serplulimab and standard third-line treatments in advanced colorectal cancer: A single arm, exploratory phase 2 trial.",
            "Indication": "Advanced colorectal cancer (mCRC)",
            "Drug": "Chidamide in combination with serplulimab and standard third-line treatments",
            "PFS": "Secondary endpoints included progression-free survival (PFS)",
            "OS": "Secondary endpoints included overall survival (OS)",
            "ORR": "Primary endpoints: safety and objective response rate (ORR) determined by investigators",
            "AE": "Primary endpoints: safety",
            "Other": "Secondary endpoints included disease control rate (DCR), PFS rate at 6 months and 12 months, OS rate at 6 months and 12 months, DCR, quality of life score (QoL), and nutritional score PG-SGA",
            "Conclusion": "As of February 2024, eight patients have been enrolled in the study."
        },
        {
            "Title": "REPROGRAM-02: A phase II-III study evaluating an induction treatment with regorafenib and metronomic chemotherapy before the second line chemotherapy in metastatic colorectal cancer.",
            "Indication": "Metastatic colorectal cancer (mCRC)",
            "Drug": "Regorafenib and metronomic chemotherapy (capecitabine, cyclophosphamide, and low-dose aspirin)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary objective: best response rate during treatment period for phase II and overall survival for phase III. Secondary endpoints: progression-free survival, disease control rate, health-related quality of life, toxicity, and evaluation of exploratory biomarkers.",
            "Conclusion": "Enrollment is ongoing, with 13 patients already recruited."
        },
        {
            "Title": "SWOG S2107: Randomized phase II trial of encorafenib and cetuximab with or without nivolumab for patients with previously treated, microsatellite stable, BRAFV600E metastatic and/or unresectable colorectal cancer.",
            "Indication": "Microsatellite stable, BRAFV600E metastatic and/or unresectable colorectal cancer (mCRC)",
            "Drug": "Encorafenib, cetuximab, and nivolumab",
            "PFS": "Median PFS of 7.2 months for E+C+N arm (experimental) vs. 4.1 months for E+C arm (standard)",
            "OS": "None",
            "ORR": "50% for E+C+N arm (experimental) vs. 20% for E+C arm (standard)",
            "AE": "None",
            "Other": "Primary endpoint: PFS. Secondary endpoints: overall survival, best overall response, duration of response, and safety/tolerability.",
            "Conclusion": "Enrollment is ongoing, with 50 of 84 planned participants enrolled."
        },
        {
            "Title": "Colon adjuvant chemotherapy based on evaluation of residual disease (CIRCULATE-NORTH AMERICA): NRG-GI008.",
            "Indication": "Resected stage IIB, IIC, and III colorectal cancer (CC)",
            "Drug": "Fluoropyrimidine (FP) + oxaliplatin (Ox) with or without irinotecan (I)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoints: time to ctDNA+ status (phase II) and disease-free survival (DFS) (phase III) in immediate vs. delayed AC arms for Cohort A; DFS in FP+Ox vs. FP+Ox+I arms for Cohort B. Secondary endpoints: prevalence of detectable ctDNA post-operatively, time-to-event outcomes (overall survival and time to recurrence) by ctDNA status, and assessment of compliance to adjuvant therapy.",
            "Conclusion": "Active enrollment across the NCTN started in June 2022 with CCTG sites joining in August 2023. Current accrual is 240/1,912."
        },
        {
            "Title": "Adjuvant chemotherapy with mFOLFOXIRI versus mFOLFOX6 in MRD-positive stage II-III colorectal cancer.",
            "Indication": "MRD-positive stage II-III colorectal cancer",
            "Drug": "mFOLFOXIRI (oxaliplatin, leucovorin, irinotecan, and 5-FU) vs. mFOLFOX6 (oxaliplatin, leucovorin, and 5-FU)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: 3-year DFS. Secondary endpoints: overall survival, 3-year distant metastasis-free survival, QoL, and toxicity.",
            "Conclusion": "The study plans to enroll 340 colorectal cancer patients with MRD positive after curative-intent R0 surgical resection."
        },
        {
            "Title": "Short-course radiotherapy (SCRT) followed by fruquintinib plus adebrelimab and CAPOX in the total neoadjuvant therapy of locally advanced rectal cancer (LARC): A multicenter, single-arm, open-label, phase II study.",
            "Indication": "Locally advanced rectal cancer (LARC)",
            "Drug": "Fruquintinib, adebrelimab, CAPOX",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint was CR rate (including clinical CR & pathologic CR). Secondary endpoints included 3-year event-free survival rate, overall survival, R0 resection rate, and adverse events (AEs). Exploratory endpoints aimed to evaluate the correlation between potential biomarkers and efficacy.",
            "Conclusion": "The study aims to improve the CR rate in high-risk LARC patients by combining SCRT, chemotherapy, Fruquintinib, and ICIs as a total neoadjuvant therapy. Until Feb 1, 2024, 7 of planned 45 patients have been enrolled."
        },
        {
            "Title": "Use of circulating tumor DNA (ctDNA) to affect the adjuvant or post-adjuvant treatment of patients with stage III and high-risk stage II resected colon cancer: The ERASE-CRC project by GONO.",
            "Indication": "Stage III and high-risk stage II resected colon cancer",
            "Drug": "FOLFOX, CAPOX, FOLFOXIRI, trastuzumab, tucatinib, trifluridine/tipiracil",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint is the clearance rate of ctDNA after the adjuvant or post-adjuvant treatment. The study includes three phase 2 trials enrolling ctDNA-positive patients with radically resected stage III or high-risk stage II adenocarcinoma of the colon or intraperitoneal rectum.",
            "Conclusion": "The study aims to intensify adjuvant and/or post-adjuvant treatment based on ctDNA positivity to improve long-term clinical outcomes. Currently, 19 and 8 patients have been randomized in ERASE-CRC Part 1 or 2, respectively."
        },
        {
            "Title": "Adjuvant chemotherapy in clinical local advanced CRC following preoperational therapies and pT0-3N0M0 diagnosis (CANWATCH).",
            "Indication": "Locally advanced colorectal cancer (CRC)",
            "Drug": "5-FU or 5-FU analogues (mFOLFOX6 or Capeox)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The study aims to assess the necessity of adjuvant chemotherapy for patients with locally advanced CRC who received neoadjuvant therapy and achieved a down-staging to pT0-3N0M0. Key inclusion criteria included preoperative clinical tumor stage III (cTxN1-2M0) CRC, pathologically confirmed CRC adenocarcinoma by endoscopic biopsy, postoperative pathological stage T0-3N0M0 without high-risk factors for recurrence.",
            "Conclusion": "The study aims to provide precise clinical evidence for individualized precision treatment in locally advanced CRC patients. A total of 650 patients are planned to be enrolled, and 301 patients have been enrolled as of February 6, 2024."
        },
        {
            "Title": "The NEO-RT trial: A phase 3 randomized trial of neoadjuvant chemotherapy, excision and observation versus chemoradiotherapy for early rectal cancer, CCTG CO.32.",
            "Indication": "Early rectal cancer",
            "Drug": "FOLFOX, CAPOX, chemoradiation (54 Gy)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The primary endpoint is tumor downstaging to clinical Complete Response (cCR) by pelvic MRI, endoscopy, and digital rectal exam. The co-primary endpoint is Quality Of Life (QOL) measured by the Low Anterior Resection Score (LARS).",
            "Conclusion": "The study aims to compare two organ-sparing strategies for patients with stage I rectal cancer. The results will inform whether induction chemotherapy is non-inferior to ChemoRT and compare patient QOL and functional outcomes. The trial is expected to open in February 2024."
        },
        {
            "Title": "Total neoadjuvant therapy with split-course hypofraction radiotherapy combined with CAPOX and envafolimab followed by local excision for locally advanced very low rectal cancer (TRACE-LE): An open-label, single-arm, multicenter, phase II trial.",
            "Indication": "Locally advanced very low rectal cancer",
            "Drug": "CAPOX and Envafolimab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Acute and late toxicity as per NCI CTCAE V.5.0",
            "Other": "Primary outcome measure: organ preservation (rectum intact, no radical total mesorectal excision (TME), no locoregional regrowth unless amenable to limited, curative (R0) salvage surgery by local excision (LE), and no permanent stoma). Secondary outcome measures: ypT0-1 rate, pathological complete response (pCR) rate, local recurrence rate, local regional recurrence rate, disease-free survival, quality of life, and anorectal function.",
            "Conclusion": "None"
        },
        {
            "Title": "A phase II/III study of peri-operative nivolumab (nivo) and ipilimumab (ipi) in patients (pts) with locoregional esophageal (E) and gastroesophageal junction (GEJ) adenocarcinoma: Results of the neoadjuvant pathologic complete response (pCR) rate (ECOG-ACRIN EA2174).",
            "Indication": "Locoregional esophageal (E) and gastroesophageal junction (GEJ) adenocarcinoma",
            "Drug": "Nivolumab (nivo) and ipilimumab (ipi)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Grade 3/4 events in Arm A: anemia (n=7), dysphagia (n=9), esophagitis (n=7), decreased lymphocytes (n=80), decreased neutrophils (n=7), decreased white blood cells (n=20). Grade 3/4 events in Arm B: anemia (n=10), dysphagia (n=8), nausea (n=8), decreased lymphocytes (n=84), decreased neutrophils (n=20), decreased white blood cells (n=31).",
            "Other": "pCR rate in Arm A: 21.0% (95% CI, 14.5-28.8); pCR rate in Arm B: 24.8% (95% CI, 17.8-32.9); no statistically significant difference (p=0.27). Surgical complication rates: Arm A = 28.7%, Arm B = 25.4%. Treatment-related deaths: Arm A = 3, Arm B = 2.",
            "Conclusion": "The addition of nivo to neoadjuvant carboplatin, paclitaxel, and radiation does not improve the pCR rate in pts with resected E/GEJ adenocarcinoma. Further understanding of the impact of radiation on ICI activity is needed."
        },
        {
            "Title": "Ramucirumab plus paclitaxel as switch maintenance versus continuation of oxaliplatin-based chemotherapy in patients (pts) with advanced HER2-negative gastric or gastroesophageal junction (GEJ) cancer: The ARMANI phase III trial.",
            "Indication": "Advanced HER2-negative gastric or gastroesophageal junction (GEJ) cancer",
            "Drug": "Ramucirumab plus paclitaxel",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "A randomized phase II study of gemcitabine and nab-paclitaxel compared with 5-fluorouracil, leucovorin, and liposomal irinotecan in older patients with treatment-na\u00efve metastatic pancreatic cancer (GIANT): ECOG-ACRIN EA2186.",
            "Indication": "Treatment-na\u00efve metastatic pancreatic cancer (mPDAC)",
            "Drug": "Gemcitabine and Nab-Paclitaxel vs. 5-Fluorouracil, Leucovorin, and Liposomal Irinotecan",
            "PFS": "None",
            "OS": "No significant difference in median OS between the arms (4.7 vs. 4.4 months in Arms A and B respectively; p=0.72)",
            "ORR": "None",
            "AE": "Rates of \u2265 grade 3 toxicity: 45.6% in Arm A and 58.7% in Arm B (p=0.10); rates of \u2265 grade 4 toxicity: 7.6% in Arm A vs 14.7% in Arm B (p=0.16). Most common \u2265 grade 3 toxicities included anemia, neutropenia, fatigue in both Arms, and diarrhea in Arm B.",
            "Other": "Factors contributing to early treatment discontinuation and affecting the lower-than-expected survival will be presented at the meeting.",
            "Conclusion": "EA2186 did not demonstrate a significant difference in efficacy or overall toxicity between two attenuated regimens tested in vulnerable older adults with mPDAC. These results provide important data to guide treatment decisions and goals of care discussions in this vulnerable patient population."
        },
        {
            "Title": "Early results of the PASS-01 trial: Pancreatic adenocarcinoma signature NRG Oncology/RTOG 0848: Results after adjuvant chemotherapy +/- stratification for treatment-01",
            "Indication": "Pancreatic adenocarcinoma",
            "Drug": "Gemcitabine +/- Erlotinib, 5FU/Capecitabine with 50.4Gy in 28 fractions RT",
            "PFS": "None",
            "OS": "Chemo: 31 months (HR Ref), Chemo+CRT: 27 months (HR 0.96 [0.79, 1.18]), Node(-) Chemo: 71% (2yr), 29% (5yr), Node(-) Chemo+CRT: 76% (2yr), 48% (5yr)",
            "ORR": "None",
            "AE": "Grade 4: 10% (chemo) vs 11% (chemo+CRT), Grade 5: 1 in each arm",
            "Other": "DFS: Chemo: 12 months (HR Ref), Chemo+CRT: 16 months (HR 0.82 [0.68, 0.99]), Node(-) Chemo: 45% (2yr), 19% (5yr), Node(-) Chemo+CRT: 56% (2yr), 47% (5yr)",
            "Conclusion": "Chemo+CRT did not improve OS overall, but did improve DFS. Both OS and DFS were improved with Chemo+CRT in node-negative patients. Chemo+CRT did not increase Grade 4 or 5 AEs compared to chemo."
        },
        {
            "Title": "Addition of stereotactic body radiotherapy (SBRT) to systemic chemotherapy in locally advanced cholangiocarcinoma (CC) (ABC-07): Results from a randomized phase II trial",
            "Indication": "Locally advanced cholangiocarcinoma",
            "Drug": "CisGem, SBRT (50Gy in 5 fractions or 67.5Gy in 15 fractions)",
            "PFS": "SBRT: 8.6 months, CG-only: 9.0 months (HR: 1.00; 95% CI: 0.58, 1.70; p=0.989)",
            "OS": "SBRT: 23.4 months (95% CI: 14.6, 27.7), CG-only: 17.2 months (95% CI: 10.2, NR), Post-randomization: SBRT: 19.4 months (11.2, 24.6), CG-only: 14.2 months (7.0, NR) (HR: 0.79; 95% CI: 0.41, 1.51; p=0.47)",
            "ORR": "None",
            "AE": "Grade \u22653: SBRT: 73%, CG-only: 88%, Infections pre-cycle 6: SBRT: 42%, CG-only: 29%, Infections post-cycle 6: SBRT: 38%, CG-only: 25%, Grade 3 duodenal hemorrhage: 1, Death due to sepsis: 1 in SBRT arm",
            "Other": "Primary causes of death: Disease (SBRT: 44%, CG-only: 46%), Sepsis (SBRT: 11%, CG-only: 13%), Hepatic failure (SBRT: 0%, CG-only: 13%)",
            "Conclusion": "SBRT did not show a PFS advantage over CG-only, yet a longer median OS time and better primary tumour control were observed without safety concerns. However, more mature survival data is still needed."
        },
        {
            "Title": "International, open-label phase 2 study of regorafenib plus pembrolizumab in patients with advanced hepatocellular carcinoma (HCC) previously treated with immune checkpoint inhibitors (ICI)",
            "Indication": "Advanced hepatocellular carcinoma",
            "Drug": "Regorafenib plus pembrolizumab",
            "PFS": "Cohort 1: 2.8 months, Cohort 2: 4.2 months",
            "OS": "None",
            "ORR": "Cohort 1: 5.9%, Cohort 2: 11.1%",
            "AE": "Grade 3: 56%, Grade 4: 5%, Drug-related Grade 3: 37%, Drug-related Grade 4: 3%, Grade 5 drug-related TEAE: 1 (Cohort 1, cardiac arrest), Common drug-related TEAE: Palmar-plantar erythrodysesthesia syndrome (39%), Asthenia (33%), Decreased appetite (32%), Diarrhea (28%), Hypertension (20%)",
            "Other": "Biomarkers showed on-treatment reductions in macrophages and angiogenesis RNA signatures",
            "Conclusion": "Regorafenib plus pembrolizumab had modest activity in second line after first-line ICI regimens. The safety profile of the combination was consistent with those of either drug. Optimal treatment for patients with advanced HCC who progress after an ICI regimen remains an unmet need."
        },
        {
            "Title": "Efficacy of disitamab vedotin (RC48) plus tislelizumab and S-1 as first-line therapy for HER2-overexpressing advanced stomach or gastroesophageal junction adenocarcinoma: A multicenter, single-arm, phase II trial (RCTS).",
            "Indication": "HER2-overexpressing advanced stomach or gastroesophageal junction adenocarcinoma",
            "Drug": "Disitamab vedotin (RC48), Tislelizumab, S-1",
            "PFS": "Not reached; 6-month and 9-month PFS rates were 100.0% and 80.8%",
            "OS": "Not reached; 6-month and 9-month OS rates were 100.0% and 83.8%",
            "ORR": "95.0% (38/40, 95% CI: 83.1-99.4%)",
            "AE": "Grade 3/4 treatment-related adverse events occurred in 40.9% of patients, notably neutropenia, fatigue, and diarrhea",
            "Other": "Clinical Complete Response rate was 20.0% (5/40, 95% CI: 4.2-26.8%); Disease Control Rate (DCR) was 100.0% (95% CI: 91.2-100.0%)",
            "Conclusion": "The combination of RC48, Tislelizumab, and S-1 showed notable efficacy with manageable safety in the first-line treatment of advanced HER2-overexpressing GC/GEJC patients. These findings support further exploration in this context."
        },
        {
            "Title": "A novel and uniquely designed bispecific antibody (LBL-024) against PD-L1 and 4-1BB in patients with advanced malignant tumors and neuroendocrine carcinoma: A report of safety and robust efficacy of LBL-024 monotherapy in phase I/II, first-in-human, open-label, multicenter, dose escalation/expansion study.",
            "Indication": "Advanced malignant tumors and neuroendocrine carcinoma",
            "Drug": "LBL-024",
            "PFS": "None",
            "OS": "None",
            "ORR": "15.1%",
            "AE": "77.8% experienced TRAEs of all grades, with 21.0% having grade \u22653 TRAEs. The most common TRAEs (\u226515%) included anemia (31.5%), AST increased (30.9%), ALT increased (25.3%), and white blood cell count decreased (22.2%)",
            "Other": "Disease Control Rate (DCR) was 46.6%; Robust efficacy signals were observed in 41 patients with EP-NEC",
            "Conclusion": "LBL-024 has shown a good safety profile and very promising anti-tumor effects in patients with advanced malignant tumors, particularly EP-NEC patients who failed at least one line of chemotherapy. The efficacy observed in EP-NEC is significantly higher than the historic reports with immunotherapy (\u226410%) and chemotherapy (about 18%). The extremely robust efficacy and good safety profile support a pivotal study for accelerated development of EP-NEC, the highly unmet medical needs and deadly disease."
        },
        {
            "Title": "Safety and efficacy of IBI389, an anti-CLDN18.2/CD3 bispecific antibody, in patients with advanced pancreatic ductal adenocarcinoma: Preliminary results from a phase 1 study.",
            "Indication": "Advanced pancreatic ductal adenocarcinoma",
            "Drug": "IBI389",
            "PFS": "Not reached",
            "OS": "None",
            "ORR": "30.4% (95% CI: 13.2-52.9)",
            "AE": "96.9% experienced TRAEs, with 54.7% having grade \u22653 TRAEs. The most common grade \u22653 TRAEs (\u22655%) were gamma-glutamyltransferase increased (20.3%), lymphocyte count decreased (9.4%), and nausea (7.8%). Cytokine release syndrome (CRS) related adverse events occurred in 51.6% with no grade \u22653 CRS occurred. TEAEs leading to dose interruption and treatment discontinuation occurred in 37.5% and 4.7% respectively",
            "Other": "Disease Control Rate (DCR) was 69.6% (95% CI: 47.1-86.8); Preliminary efficacy was observed in patients with CLDN18.2 expression \u226510% (immunohistochemistry 2+/3+) at 600 mg/kg",
            "Conclusion": "IBI389 showed manageable safety profiles in patients with advanced PDAC. Preliminary efficacy was observed, including in patients with relatively low expression of CLDN18.2."
        },
        {
            "Title": "Efficacy and safety of cadonilimab in combination with pulocimab and paclitaxel as second-line therapy in patients with advanced gastric or gastroesophageal junction (G/GEJ) cancer who failed immunochemotherapy: A multicenter, double-blind, randomized trial.",
            "Indication": "Advanced gastric or gastroesophageal junction (G/GEJ) cancer",
            "Drug": "Cadonilimab, Pulocimab, Paclitaxel",
            "PFS": "6.8 months (95% CI 4.1, 11.2) vs 4.9 months (95% CI 3.2, 7.1)",
            "OS": "Not reached for both arms, 9-month OS rate: 65.5% (95% CI 33.2, 85.0) vs 34.0% (95% CI 9.5, 60.9)",
            "ORR": "48.0% (12/25) for arm 1 vs 35.7% (10/28) for arm 2",
            "AE": "Grade 3-4 treatment-related adverse events: neutrophil count decreased (27.6% vs 33.3%), white blood cell count decreased (10.3% vs 26.7%), blood pressure increased (13.8% vs 10.0%)",
            "Other": "Disease control rate (DCR): 96.0% (24/25) vs 92.9% (26/28), median duration of response (mDoR): not reached vs 4.0 months (95% CI 1.58, NE)",
            "Conclusion": "The combination of cadonilimab with pulocimab and paclitaxel demonstrated a manageable safety profile and encouraging efficacy as second-line therapy in patients with G/GEJ cancer progressed after immunochemotherapy. Incorporation of dual immunotherapy to VEGFR-2 targeting therapy could be a potential strategy to overcome immunotherapy resistance. Additional phase III study is warranted to confirm it."
        },
        {
            "Title": "Randomized phase II/III trial of gemcitabine plus nab-paclitaxel versus concurrent chemoradiotherapy with S-1 as neoadjuvant treatment for borderline resectable pancreatic cancer: GABARNANCE study.",
            "Indication": "Borderline resectable pancreatic cancer",
            "Drug": "Gemcitabine, Nab-paclitaxel, S-1",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The full, final text of this abstract will be available at meetings.asco.org on the day of presentation and in the online supplement to the June 10, 2024, issue of the Journal of Clinical Oncology."
        },
        {
            "Title": "A randomized phase 2 study of combination atezolizumab and varlilumab (CDX-1127) with or without addition of cobimetinib in previously treated unresectable biliary tract cancer",
            "Indication": "unresectable biliary tract cancer",
            "Drug": "Atezolizumab, Varlilumab (CDX-1127), Cobimetinib",
            "PFS": "2.2 months (CAV) and 1.8 months (AV) (HR 0.71 [0.40, 1.25]); In PD[L]1-experienced patients: 3.6 months (CAV) and 1.7 months (AV) (HR 0.44 [0.14, 1.33])",
            "OS": "10.2 months (CAV) and 6.1 months (AV) (HR 0.76 [0.37, 1.55]); In PD[L]1-experienced patients: 6.4 months (CAV) and 4.4 months (AV) (HR 0.68 [0.19, 2.42])",
            "ORR": "0% (CAV) and 4% (AV)",
            "AE": "Both CAV and AV regimens were well-tolerated without new safety signals. Adding CDX-1127 did not appear to meaningfully increase immunotoxicity beyond established PD-L1 toxicity profiles.",
            "Other": "Primary correlative endpoint and updated survival will be reported at the time of the meeting.",
            "Conclusion": "The combination of Atezolizumab and Varlilumab with or without Cobimetinib was safe but did not improve clinical outcomes in advanced stage BTC patients treated in later lines. Though not statistically significant, treatment with CAV demonstrated numerically favorable PFS/OS compared to AV among immunotherapy-experienced patients."
        },
        {
            "Title": "Phase II study of sitravatinib in combination with tislelizumab in patients with advanced biliary tract cancer who have failed to at least 1 prior systemic treatment",
            "Indication": "advanced biliary tract cancer",
            "Drug": "Sitravatinib, Tislelizumab",
            "PFS": "4.93 months (95% CI, 3.10-8.87)",
            "OS": "10.3 months (95% CI, 6.67-18.2)",
            "ORR": "20.5% in unselected per-protocol population",
            "AE": "The most common treatment-related adverse events (AEs) were sitravatinib-related; hand-foot syndrome (any grade 60.5%, grade 3/4 0%), hypertension (any grade 34.9%, grade 3/4 11.6%). Immune-related AEs (irAEs) were 46.5%, with most irAEs being grade 1-2.",
            "Other": "Patients with homologous recombination deficiency (HRD) detected by baseline tissue NGS (frequency 18.5%) showed higher ORR (60% vs 13.6%), longer PFS (not reached vs 4.87 months) and OS (21.1 vs 8.57 months) than patients without HRD. HRD detected by circulating tumor DNA had a complementary role for patient selection. RNA sequencing showed upregulation in inflammatory signal and downregulation in angiogenesis signal between screening and on-treatment tumor tissue. Responders showed higher inflammatory signaling at baseline and on-treatment tumor tissue compared to non-responders.",
            "Conclusion": "Sitravatinib/tislelizumab combination as 2L therapy in patients with advanced BTC demonstrated meaningful efficacy and an acceptable safety profile. Especially, patient selection using HRD biomarker might be a promising strategy in this setting."
        },
        {
            "Title": "Phase I study of C-CAR031, a GPC3-specific TGFbRIIDN armored autologous CAR-T, in patients with advanced hepatocellular carcinoma (HCC)",
            "Indication": "advanced hepatocellular carcinoma (HCC)",
            "Drug": "C-CAR031",
            "PFS": "Not reported",
            "OS": "Not reported",
            "ORR": "50.0% for pts of all DLs; In DL4, the ORR was 57.1%",
            "AE": "No dose-limiting toxicity and ICANS was observed. CRS was observed in 22 (91.7%) pts with only 1 (4.2%) grade (G) 3 CRS. The most common \u2265G3 AEs were lymphocytopenia (100%), neutropenia (70.8%), thrombocytopenia (37.5%) and transaminase elevation (16.7%). One pt (4.2%) had G4 myelosuppression and 1 pt (4.2%) had G3 interstitial pneumonitis at DL4 caused by G3 CRS. All AEs were reversible.",
            "Other": "Tumor reductions were observed in 90.9% pts, not only in intrahepatic lesions but also extrahepatic ones with a median reduction of 44.0% (range, 3.4%-94.4%). The disease control rate was 90.9%.",
            "Conclusion": "The study showed a manageable safety profile and encouraging anti-tumor activity of C-CAR031 in heavily treated advanced HCC patients."
        },
        {
            "Title": "Safety, tolerability and efficacy of 212Pb-DOTAMTATE as a targeted alpha therapy for subjects with unresectable or metastatic somatostatin receptor-expressing gastroenteropancreatic neuroendocrine tumors (SSTR+ GEP-NETs): A phase 2 study.",
            "Indication": "Unresectable or metastatic somatostatin receptor-expressing gastroenteropancreatic neuroendocrine tumors (SSTR+ GEP-NETs)",
            "Drug": "212Pb-DOTAMTATE",
            "PFS": "None",
            "OS": "None",
            "ORR": "47.2% (32.0-63.0%) in Cohort 1, 62.5% (30.6-86.3%) in Phase I trial, combined ORR 50% (22 out of 44, 95%-CI: 36%-64%)",
            "AE": "59% grade 3 and 4 AEs, including lymphocytopenia; 4 fatal AEs: death/progressive disease (N=2), carcinoid syndrome (N=1), sepsis (N=1)",
            "Other": "Median Duration of Response (DOR) not reached; 80% of subjects with confirmed response in Phase I had a DOR of \u226512 months; 41% of subjects in Cohort 1 had a DOR of \u22656 months",
            "Conclusion": "In PRRT-na\u00efve subjects with SSTR+ unresectable or metastatic GEP-NETs, treatment with up to 4 cycles of 212Pb-DOTAMTATE (67.6 mCi/kg/cycle) was well-tolerated, with a safety profile consistent with the underlying disease and expected toxicities of radioligand therapy, similar to 177Lu-DOTATATE. The ORR of 47.2% in Cohort 1 (50% in the pooled dataset) appears to be substantially higher than the ORR previously reported for 177Lu-DOTATATE in the pivotal NETTER-1 study (ORR 18% (10\u201325%))."
        },
        {
            "Title": "IL33-ST2 axis as a diagnostic and therapeutic biomarker in nivolumab therapy for advanced gastric cancer.",
            "Indication": "Advanced gastric cancer",
            "Drug": "Nivolumab",
            "PFS": "High tumor il33 levels: median PFS 54.5 vs 104 days, HR=3.539, P=0.002; High levels of ST2+ cells: mPFS=2.50 months, HR=12.19, P=0.020; il33-high/MC-high: mPFS=1.68 months, HR=31.47, P=0.003",
            "OS": "High tumor il33 levels: median OS 196 vs 418 days, HR=2.659, P=0.014; High levels of ST2+ cells: mOS=8.85 months, N.A.; il33-high/MC-high: mOS=6.44 months, N.A.",
            "ORR": "None",
            "AE": "None",
            "Other": "High levels of ST2+ cells, including IL17RB+ ILC2s, CD117+ FceRIa+ MCs, CD11b+ HLA-DR-PDL1+ MDSCs, and CD3+CD4+CTLA4+FOXP3+ Tregs, at both timings were significantly (P<0.05) associated with worse prognosis as compared to the low levels.",
            "Conclusion": "These results suggest that the combinatory assessment of IL33 expression in tumors and ST2+ cells in peripheral blood, particularly MCs after treatment, is predictive of anti-PD1 responders with durable responses. Targeting the IL33-ST2 axis may be promising diagnostic and therapeutic strategies to improve the clinical outcome in the treatment of advanced gastric cancer."
        },
        {
            "Title": "T cell receptor and immune gene expression pharmacodynamics for durvalumab monotherapy and in combination with tremelimumab or bevacizumab in unresectable hepatocellular carcinoma (uHCC).",
            "Indication": "Unresectable hepatocellular carcinoma (uHCC)",
            "Drug": "Durvalumab, Tremelimumab, Bevacizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "Durvalumab: 10.6%, Durvalumab + Bevacizumab: 21.3%, STRIDE: 24.0%, T75 + Durvalumab: 9.5%, Tremelimumab: 7.2%",
            "AE": "None",
            "Other": "ImmunoSeq did not show significant differences in baseline T cell clonality across treatment arms. STRIDE was associated with increased expansion of T cell clones versus Durvalumab. Significant changes in CD8 T cell, M1 macrophage, and Treg GES were only observed for STRIDE.",
            "Conclusion": "While STRIDE and Durvalumab + Bevacizumab both had higher ORRs than Durvalumab monotherapy in uHCC, only STRIDE was associated with an increased expansion of T cell clones versus Durvalumab. This, in addition to the differences in magnitude and type of GES changes, suggests that STRIDE and Durvalumab + Bevacizumab have distinct and potentially complementary mechanisms of action."
        },
        {
            "Title": "Circulating immune protein biomarkers of early response and resistance in patients with metastatic HER-2\u2013negative esophagogastric cancer (mEGC) treated with pembrolizumab and chemotherapy.",
            "Indication": "Metastatic HER-2\u2013negative esophagogastric cancer (mEGC)",
            "Drug": "Pembrolizumab combined with oxaliplatin and capecitabine",
            "PFS": "None",
            "OS": "None",
            "ORR": "Overall response rate of 76%, including 6 patients with complete response and 19 patients with partial response",
            "AE": "None",
            "Other": "High levels of PREB, EPHA10, GHRHR, BAMBI, and GDF15 at baseline were associated with worse PFS. High levels of SPINK1, S100P, LDLR, GDF15, and PM20D1 were associated with worse OS. Significant changes in immune-related cytokines were seen early (C1D8) as well as at progression.",
            "Conclusion": "Multiple prognostic biomarkers were identified that implicate proteins related to hormone metabolism and TGFb biology in mEGC. Significant changes in immune-related cytokines were seen early and at progression, highlighting a potential role for circulating immune protein biomarkers in mEGC."
        },
        {
            "Title": "Clinical impact of oncogenic KRAS dosage variation in pancreatic cancer.",
            "Indication": "Pancreatic ductal adenocarcinoma (PDA)",
            "Drug": "None",
            "PFS": "HR=2.50 (1.51\u20134.14), p=0.009",
            "OS": "HR=1.98 (1.15\u20133.39), p=0.002",
            "ORR": "None",
            "AE": "None",
            "Other": "Mutant KRAS allelic imbalance was identified in 62 (37.3%) patients. Higher incidence of liver metastasis in the imbalance group compared to the balance group (81.1% vs. 52.8%, p=0.011).",
            "Conclusion": "Oncogenic KRAS dosage variation, as assessed by mutant KRAS allelic imbalance, plays a critical role in shaping clinical phenotypes and prognosis in PDA."
        },
        {
            "Title": "RNA expression-based hypoxia score as a prognostic and predictive biomarker in hepatocellular carcinoma.",
            "Indication": "Hepatocellular carcinoma (HCC)",
            "Drug": "Atezolizumab, Lenvatinib, Sorafenib",
            "PFS": "None",
            "OS": "HS-H tumors had significantly shorter OS from date of collection (166 days) compared to HS-M (396.5 days) and HS-L (513 days), p<0.001",
            "ORR": "None",
            "AE": "None",
            "Other": "HS-H tumors had higher prevalence of TP53 mutations and an immunosuppressive microenvironment. HS-H tumors had higher prevalence of PD-L1+ and T cell-inflamed tumors.",
            "Conclusion": "RNA expression-based hypoxia score is associated with TP53 mutations and an immunosuppressive microenvironment in HCC. HS-H tumors have worse OS that may be improved with Atezolizumab, whereas HS-L tumors may respond better to Sorafenib. HS is a potential prognostic and predictive biomarker in HCC that deserves validation in orthogonal datasets and prospective studies."
        },
        {
            "Title": "Longitudinal circulating tumor DNA (ctDNA) analysis during treatment (Tx) of locally advanced resectable (LAR) gastric or gastroesophageal junction (G/GEJ) adenocarcinoma (ADENOCA): The PLAGAST prospective biomarker study.",
            "Indication": "Locally advanced resectable gastric or gastroesophageal junction adenocarcinoma",
            "Drug": "Radiotherapy combined with docetaxel, cisplatin, and 5-fluorouracil",
            "PFS": "Persistent ctDNA positivity during-NAT was associated with a median RFS of 13.3 months vs. not reached (HR=4; p=0.032). ctDNA clearance post-NAT was associated with a median RFS of 7.79 vs. 52.04 months (HR=3.69; p=0.019). During the MRD window, median RFS of 3.57 months vs. not reached (HR=12.94; p<0.0001).",
            "OS": "Persistent ctDNA positivity during-NAT was associated with a median OS of not reached for both groups, with 24-month OS rate at 95.6% vs. 58.6% (HR=5.85; p=0.097). ctDNA clearance post-NAT was associated with a median OS of 18.9 months vs. not reached (HR=8.78; p=0.007). During the MRD window, median OS of 8.59 months vs. not reached (HR=14.54; p<0.0001).",
            "ORR": "None",
            "AE": "None",
            "Other": "ctDNA dynamics revealed patients with early ctDNA clearance (during NAT) had the best outcomes, followed by patients who cleared ctDNA post-NAT, while patients who remained ctDNA positive at baseline, during-NAT, and post-NAT had worse RFS and OS.",
            "Conclusion": "Tumor-informed ctDNA results were predictive of NAT response and correlated with long-term outcomes with early ctDNA clearance associated with superior RFS and OS; meanwhile persistent ctDNA positivity post-NAT/pre-surgery/during MRD correlated with inferior OS and/or RFS as well as poor pathological response. Longitudinal ctDNA monitoring was prognostic of patient outcomes and may guide NAT clinical decision-making in patients with LAR G/GEJ ADENOCA."
        },
        {
            "Title": "A phase II study of radiotherapy combined with docetaxel, cisplatin, and 5-fluorouracil for postoperative loco-regional recurrent esophageal cancer.",
            "Indication": "Postoperative loco-regional recurrent esophageal cancer",
            "Drug": "Radiotherapy combined with docetaxel, cisplatin, and 5-fluorouracil",
            "PFS": "The 5-year progression-free survival rate was 56.2% (95% CI=37.4-75.0%) with a median progression-free survival period of 62.0 months (95% CI=35.2-64.7 months).",
            "OS": "None",
            "ORR": "None",
            "AE": "Only 2 patients showed grade 4 hematologic adverse events during chemoradiotherapy, and they had to be performed reduced chemotherapy; however, they also completed 3 cycles of concurrent chemotherapy. Grade 2 or higher late toxicity was not observed in any patients.",
            "Other": "None",
            "Conclusion": "The present protocol of radiotherapy combined with DOC, CDDP, and 5-FU is a safe and effective salvage treatment for postoperative loco-regional recurrent esophageal cancer. It might be better than conventional treatment."
        },
        {
            "Title": "Neoadjuvant docetaxel, oxaliplatin, and S-1 plus surgery followed by adjuvant docetaxel and S-1 for patients with large type 3 or type 4 gastric cancer: Short-term outcomes of the phase II OGSG 1902 trial.",
            "Indication": "Large type 3 or type 4 gastric cancer",
            "Drug": "Neoadjuvant docetaxel, oxaliplatin, and S-1 plus surgery followed by adjuvant docetaxel and S-1",
            "PFS": "None",
            "OS": "None",
            "ORR": "Pathological response rate (\u2265Grade 1b) was 66.7%, response rate according to RECIST version 1.1 for 10 patients with measurable lesions was 50.0%.",
            "AE": "Adverse events (\u2265CTCAE Grade 3) during neoadjuvant DOS included neutropenia in 18 patients (38%), febrile neutropenia in one patient (2%), nausea in five patients (10%), diarrhea in six patients (13%), and anorexia in 18 patients (38%). Major postoperative complication (\u2265Clavien-Dindo Grade IIIa) was observed in one patient (2%), including pleural infection. Adverse events (\u2265CTCAE Grade 3) in 39 patients receiving adjuvant DS therapy included neutropenia in 16 patients (41%), anemia in five patients (13%), nausea in one patient (3%), diarrhea in two patients (5%), anorexia in nine patients (23%), fatigue in three patients (8%), and febrile neutropenia in four patients (10%).",
            "Other": "CY negative conversion rate for nine CY positive patients was 88.9%, R0 resection rate was 89.6%, and the protocol completion rate was 45.8%.",
            "Conclusion": "Neoadjuvant DOS therapy for large type 3 or type 4 GC demonstrates promising efficacy with high histological response and acceptable adverse events. Future analysis of survival outcomes is planned."
        },
        {
            "Title": "Global, randomized, phase III study of tislelizumab plus chemotherapy versus placebo plus chemotherapy as first-line treatment for advanced/metastatic esophageal squamous cell carcinoma (RATIONALE-306 update): Minimum 3-year survival follow-up.",
            "Indication": "Advanced/metastatic esophageal squamous cell carcinoma",
            "Drug": "Tislelizumab plus chemotherapy",
            "PFS": "24-month PFS: 18.1% (95% CI: 13.6, 23.1) vs. 7.2% (95% CI: 4.4, 11.0). 36-month PFS: 15.0% (95% CI: 10.8, 19.9) vs. 2.9% (95% CI: 1.1, 6.2).",
            "OS": "Median OS: 17.2 months (95% CI: 15.8, 20.1) vs. 10.6 months (95% CI: 9.3, 12.0). 24-month OS: 37.9% (95% CI: 32.5, 43.2) vs. 24.8% (95% CI: 20.1, 29.8). 36-month OS: 22.1% (95% CI: 17.6, 27.0) vs. 14.1% (95% CI: 10.4, 18.4).",
            "ORR": "None",
            "AE": "Serious TRAEs occurred in 29.9% vs. 19.6% of patients; TRAEs leading to death occurred in 1.9% and 1.2%. Treatment-emergent adverse events leading to treatment discontinuation were higher in Arm A (32.1%) versus B (22.1%).",
            "Other": "24-month DoR: 19.9% (95% CI: 14.3, 26.3) vs. 10.1% (95% CI: 5.0, 17.1). 36-month DoR: 17.7% (95% CI: 12.3, 24.0) vs. 5.0% (95% CI: 1.5, 11.8).",
            "Conclusion": "After minimum 3 years\u2019 follow-up, 1L TIS plus chemo continued to demonstrate clinically meaningful improvements in OS and PFS and durable anti-tumor response benefit versus PBO plus chemo in patients with advanced/metastatic ESCC, with no new safety signals."
        },
        {
            "Title": "A phase 1 study to evaluate the safety and preliminary efficacy of minnelide given alone or in combination with paclitaxel in advanced gastric cancer.",
            "Indication": "Advanced gastric cancer",
            "Drug": "Minnelide",
            "PFS": "Median PFS of 4.5 months in Regimen A, 5.3 months in the last 2 cohorts of Regimen C",
            "OS": "Median OS of 10.7 months in Regimen A, not reached in the last 2 cohorts of Regimen C",
            "ORR": "50.0% confirmed ORR in the last 2 cohorts of Regimen C",
            "AE": "Most common Grade \u22653 AEs were neutropenia (19.4%) and abdominal pain (11.1%)",
            "Other": "71.5% achieved either a partial response or stable disease in Regimen C",
            "Conclusion": "The combination of Minnelide plus paclitaxel as salvage treatment in AGC patients showed meaningful clinical activity with a manageable safety profile. Based on this Phase I experience, a Phase II will be initiated in AGC patients."
        },
        {
            "Title": "Nivolumab (NIVO) plus chemotherapy (chemo) or ipilimumab (IPI) vs chemo as first-line (1L) treatment for advanced esophageal squamous cell carcinoma (ESCC): 45-month (mo) follow-up from CheckMate 648.",
            "Indication": "Advanced esophageal squamous cell carcinoma (ESCC)",
            "Drug": "Nivolumab (NIVO) plus chemotherapy (chemo) or ipilimumab (IPI)",
            "PFS": "mPFS: NIVO+Chemo 6.8 months, NIVO+IPI 4.0 months, Chemo 4.4 months (Tumor Cell PD-L1 \u22651%)",
            "OS": "mOS: NIVO+Chemo 15.0 months, NIVO+IPI 13.1 months, Chemo 9.1 months (Tumor Cell PD-L1 \u22651%)",
            "ORR": "ORR: NIVO+Chemo 53%, NIVO+IPI 35%, Chemo 20% (Tumor Cell PD-L1 \u22651%)",
            "AE": "Any-grade TRAEs: NIVO+Chemo 96%, NIVO+IPI 80%, Chemo 90%; Grade 3/4 TRAEs: NIVO+Chemo 49%, NIVO+IPI 33%, Chemo 37%",
            "Other": "Duration of response (DOR) was longer with NIVO+Chemo and NIVO+IPI vs chemo",
            "Conclusion": "NIVO+chemo and NIVO+IPI continued to demonstrate clinically meaningful survival benefit and more durable responses vs chemo, with no new safety signals. These results further support NIVO+chemo and NIVO+IPI as 1L treatment options for advanced ESCC."
        },
        {
            "Title": "Final overall survival results from phase 3 SPOTLIGHT study evaluating zolbetuximab + mFOLFOX6 as first-line (1L) treatment for patients (pts) with claudin 18 isoform 2 (CLDN18.2)+, HER2\u2013, locally advanced (LA) unresectable or metastatic gastric or gastroesophageal junction (mG/GEJ) adenocarcinoma.",
            "Indication": "Locally advanced unresectable or metastatic gastric or gastroesophageal junction adenocarcinoma",
            "Drug": "Zolbetuximab + mFOLFOX6",
            "PFS": "Median PFS: 11.04 months (zolbetuximab + mFOLFOX6) vs 8.94 months (PBO + mFOLFOX6)",
            "OS": "Median OS: 18.23 months (zolbetuximab + mFOLFOX6) vs 15.57 months (PBO + mFOLFOX6)",
            "ORR": "ORR: 48.1% (zolbetuximab + mFOLFOX6) vs 47.5% (PBO + mFOLFOX6)",
            "AE": "Safety and tolerability were maintained with no new findings",
            "Other": "Separation of PFS and OS curves occurred earlier in the PPS population analysis compared with the ITT population",
            "Conclusion": "Zolbetuximab + mFOLFOX6 continued to demonstrate statistically significant and clinically meaningful improvement in PFS and OS vs PBO + mFOLFOX6, with no new safety signals\u2014supporting zolbetuximab + mFOLFOX6 as a new standard of care option for 1L treatment of pts with CLDN18.2+, HER2\u2013, LA unresectable or mG/GEJ adenocarcinoma."
        },
        {
            "Title": "Updated results and biomarker analyses from the phase 2 trial of nab-paclitaxel plus sintilimab as second-line therapy for advanced or metastatic gastric/gastroesophageal junction adenocarcinoma (G/GEJA).",
            "Indication": "Advanced or metastatic gastric/gastroesophageal junction adenocarcinoma (G/GEJA)",
            "Drug": "Nab-paclitaxel plus sintilimab",
            "PFS": "4.8 months (95% CI 3.06-6.54), HR 3.86 (95% CI 0.43-34.41, p=0.013)",
            "OS": "11.8 months (95% CI 10.43-13.17), HR 4.49 (95% CI 0.43-46.93, p=0.005)",
            "ORR": "44.8%",
            "AE": "None",
            "Other": "Disease control rate (DCR) was 79.3%. EPHB1 mutation and dynamic change of TCR could serve as potential predictive factors.",
            "Conclusion": "2L-treatment with sintilimab plus nab-PTX maintains favorable efficacy in advanced G/GEJA pts without prior PD-1/PD-L1 inhibitor treatment. Further investigation is warranted."
        },
        {
            "Title": "Nivolumab (NIVO) + chemotherapy (chemo) vs chemo as first-line (1L) treatment for advanced gastric cancer/gastroesophageal junction cancer/esophageal adenocarcinoma (GC/GEJC/EAC): 4-year follow-up of CheckMate 649.",
            "Indication": "Advanced gastric cancer/gastroesophageal junction cancer/esophageal adenocarcinoma (GC/GEJC/EAC)",
            "Drug": "Nivolumab (NIVO) + chemotherapy",
            "PFS": "8.3 months (95% CI 7.0-9.7), HR 0.70 (95% CI 0.61-0.81)",
            "OS": "14.4 months (95% CI 13.1-16.2), HR 0.70 (95% CI 0.61-0.81)",
            "ORR": "60% (95% CI 55-65)",
            "AE": "None",
            "Other": "48-month OS rate: 17% (95% CI 14-21). Median duration of response: 9.6 months (95% CI 8.3-12.4).",
            "Conclusion": "NIVO+chemo is the first PD-1 inhibitor/chemo combination to demonstrate long-term efficacy and acceptable safety after 4 years of follow-up in previously untreated advanced GC/GEJC/EAC. These results are consistent with earlier follow-ups, further supporting NIVO+chemo as a standard 1L treatment in these pts."
        },
        {
            "Title": "Overall survival results from the phase 2 NIVOFGFR2 study of first-line nivolumab plus CAPOX in patients with FGFR2-positive, PD-L1-positive metastatic gastric cancer.",
            "Indication": "FGFR2-positive, PD-L1-positive metastatic gastric cancer",
            "Drug": "nivolumab plus CAPOX",
            "PFS": "6.0 months (95% CI: 4.3-7.7)",
            "OS": "15.1 months (95% CI: 13.2-16.8)",
            "ORR": "21.7%",
            "AE": "Grade \u22653 AEs: 34.8%",
            "Other": "PFS2: 5.9 months (95% CI: 4.5-7.3)",
            "Conclusion": "Despite the modest efficacy of nivolumab with CAPOX in the first-line setting, a favorable median OS was observed, possibly attributable to the incorporation of ramucirumab with paclitaxel in the second-line treatment of FGFR2-positive, PD-L1-positive metastatic gastric cancer."
        },
        {
            "Title": "Efficacy of EpCAM CAR-T IMC001 in advanced gastric cancers.",
            "Indication": "EpCAM-positive advanced gastric cancers",
            "Drug": "IMC001",
            "PFS": "None",
            "OS": "None",
            "ORR": "40% (recommended dose of 1M/kg)",
            "AE": "Well tolerated with no unexpected safety signals",
            "Other": "DCR: 90%, Median Cmax: 547,500 and 9540 copies/mg DNA in the 0.3, 1, and 3M/kg dose groups respectively, Median Tmax: 7 days (7-28), Persistence of IMC001: 4 to 12 weeks",
            "Conclusion": "This phase I study in advanced gastric cancer resulted in durable response with a favorable safety and efficacy profile, especially for the recommended dose of 1M/kg, demonstrating a promising potential of EpCAM CAR-T therapy in advanced GC patients."
        },
        {
            "Title": "Pembrolizumab (pembro) + chemotherapy (chemo) for advanced HER2-negative gastric or gastroesophageal junction (G/GEJ) cancer: Updated results from the KEYNOTE-859 study.",
            "Indication": "Advanced HER2-negative gastric or gastroesophageal junction (G/GEJ) cancer",
            "Drug": "Pembrolizumab plus chemotherapy",
            "PFS": "6.9 months (95% CI: 6.3-7.2) vs 5.6 months (95% CI: 5.5-5.7)",
            "OS": "12.9 months (95% CI: 11.9-14.0) vs 11.5 months (95% CI: 10.6-12.1)",
            "ORR": "51.0% vs 42.0%",
            "AE": "Treatment-related AEs: 95.7% (grade 3-5: 59.4%) for pembro+chemo vs 93.5% (grade 3-5: 51.3%) for pbo+chemo",
            "Other": "Median DOR: 8.0 months (range, 1.2+ to 52.6+) vs 5.7 months (range, 1.3+ to 44.3+)",
            "Conclusion": "With a median follow-up of 41.6 months, use of pembro+chemo continued to show improved OS, PFS, and ORR vs pbo+chemo, regardless of PD-L1 expression. These results continue to support pembro+chemo as a first-line treatment option for patients with locally advanced or metastatic HER2-negative G/GEJ adenocarcinoma."
        },
        {
            "Title": "Enfortumab vedotin (EV) in previously treated gastric/esophageal cancers",
            "Indication": "Gastric/esophageal cancers",
            "Drug": "Enfortumab vedotin",
            "PFS": "ESCC: 3.9 months, GEA: 3.1 months",
            "OS": "ESCC: 7.4 months, GEA: 8.3 months",
            "ORR": "ESCC: 18.2%, GEA: 19.1%",
            "AE": "ESCC: pruritus (27%), maculopapular rash (27%), dysgeusia (25%); GEA: pruritus (38%), alopecia (36%), dysgeusia (31%)",
            "Other": "DCR: ESCC: 45.5%, GEA: 47.6%",
            "Conclusion": "In pts with pretreated gastric/esophageal cancers, EV demonstrated safety consistent with the known profile of EV. Promising antitumor activity was observed in pts with ESCC."
        },
        {
            "Title": "Phase 1b study of futibatinib plus pembrolizumab with or without chemotherapy in patients with esophageal carcinoma: Updated results of antitumor activity",
            "Indication": "Advanced or metastatic esophageal carcinoma",
            "Drug": "Futibatinib plus pembrolizumab with or without chemotherapy",
            "PFS": "COD: 5.6 months, COA: 16.0 months, COB: 4.7 months",
            "OS": "None",
            "ORR": "COD: 68.4%, COA: 42.9%, COB: 6.1%",
            "AE": "COD: hyperphosphatemia (85.7%), neutrophil count decreased (66.7%), stomatitis (66.7%); COA: hyperphosphatemia (91.7%), diarrhea, stomatitis, nail disorder (22.2%); COB: hyperphosphatemia (80.0%), stomatitis (20.0%), diarrhea (20.0%)",
            "Other": "DCR: COD: 89.5%, COA: 71.4%, COB: 51.0%",
            "Conclusion": "Futi plus Pem with or without chemotherapy showed promising antitumor activity in adv or metastatic EC pts with both histology of scc and adeno. Futi plus Pem and chemotherapy was safe and manageable with no new safety signals observed in 1st line pts with adv or metastatic EC."
        },
        {
            "Title": "First-line osemitamab (TST001) plus nivolumab and capox for advanced g/GEJ cancer (TranStar102): Results of cohort G from a phase I/IIa study",
            "Indication": "Advanced or metastatic gastric/gastroesophageal junction cancer",
            "Drug": "Osemitamab (TST001) plus nivolumab and CAPOX",
            "PFS": "Immature, need longer follow-up",
            "OS": "None",
            "ORR": "57%",
            "AE": "Hypoalbuminemia (67.1%), nausea (63.4%), vomiting (59.8%)",
            "Other": "DCR: 91.9%",
            "Conclusion": "Preliminary data indicate that the combination of TST001 6mg/kg Q3W plus nivolumab and CAPOX for the 1st line treatment of patients with G/GEJ cancer is safe and well tolerated with encouraging preliminary anti-tumor activities."
        },
        {
            "Title": "Safety and efficacy results from the phase I/IIa study of FG-M108 plus CAPOX as first-line (1L) treatment for patients with CLDN18.2+/HER2- locally advanced unresectable or metastatic gastric or gastroesophageal junction (G/GEJ) adenocarcinoma",
            "Indication": "Locally advanced unresectable or metastatic gastric or gastroesophageal junction adenocarcinoma",
            "Drug": "FG-M108 plus CAPOX",
            "PFS": "Low CLDN18.2 expressions: 5.0 months, Medium/high CLDN18.2 expressions: Not reached",
            "OS": "Not reached",
            "ORR": "Low CLDN18.2 expressions: 46.7%, Medium/high CLDN18.2 expressions: 77.8%",
            "AE": "Neutropenia (63.5%), thrombocytopenia (44.2%), hypoalbuminemia (42.3%)",
            "Other": "DCR: Low CLDN18.2 expressions: 100.0%, Medium/high CLDN18.2 expressions: 97.2%",
            "Conclusion": "FG-M108 plus CAPOX as 1L treatment for CLDN18.2+/HER2- advanced G/GEJ cancer showed good tolerability and improved efficacy compared to historical CAPOX control, with an apparent trend of better efficacy with higher CLDN18.2 expression."
        },
        {
            "Title": "Paclitaxel oral solution versus paclitaxel injection as second-line therapy in advanced gastric cancer: A randomized, open-label, non-inferiority phase 3 trial.",
            "Indication": "Advanced gastric cancer",
            "Drug": "Paclitaxel oral solution vs. Paclitaxel injection",
            "PFS": "3.02 months (95% CI: 2.69, 3.71) in oral paclitaxel vs. 2.89 months (95% CI: 2.53, 3.48) in paclitaxel IV (HR 0.894, 95% CI: 0.719, 1.112, p=0.311)",
            "OS": "9.13 months (95% CI: 7.72, 10.97) in oral paclitaxel vs. 6.54 months (95% CI: 5.75, 7.26) in paclitaxel IV (HR 0.770, 95.5% CI: 0.635, 0.934, p=0.006)",
            "ORR": "None",
            "AE": "Neuropathy incidence: 22.3% vs. 38.7% (all grade), Neutrophil count decreased: 47.9% vs. 54.5%, White blood cell count decreased: 41.5% vs. 35.3%, Anemia: 16.6% vs. 10.9%, Grade 5 TRAEs: 1.5% vs. 1.1%",
            "Other": "None",
            "Conclusion": "The study showed statistical non-inferiority of paclitaxel oral solution in PFS and statistically significant and clinically meaningful improvement in OS as compared to paclitaxel IV, with clinically manageable safety profile, supporting paclitaxel oral solution as second-line treatment option for patients with gastric cancer."
        },
        {
            "Title": "First-line regorafenib with nivolumab and FOLFOX or CapeOX in patients with advanced gastric, gastroesophageal junction, and esophageal adenocarcinoma: A single-arm, phase Ib/II trial (REGONIVO CTx, EPOC2104).",
            "Indication": "Advanced gastric, gastroesophageal junction, and esophageal adenocarcinoma",
            "Drug": "Regorafenib with nivolumab and FOLFOX or CapeOX",
            "PFS": "Six-month progression-free survival was 66.7% (95% CI 46.9-80.5)",
            "OS": "None",
            "ORR": "Objective tumor response was observed in 22 of 30 (73%; 80% CI 60.3-83.8) patients",
            "AE": "Neutropenia (33%), AST/ALT increased (13%), Hypertension (13%), Leucopenia (7%)",
            "Other": "Disease control rate was 97%",
            "Conclusion": "First-line regorafenib with nivolumab and chemotherapy including CapeOX showed anti-tumor activity with acceptable safety profiles in AGC patients. The results of biomarker analysis will be presented. A randomized, phase III trial is planned."
        },
        {
            "Title": "Final analysis of the randomized phase 3 ESCORT-1st trial: Camrelizumab plus chemotherapy as first-line treatment for advanced or metastatic esophageal squamous cell carcinoma.",
            "Indication": "Advanced or metastatic esophageal squamous cell carcinoma (ESCC)",
            "Drug": "Camrelizumab plus chemotherapy",
            "PFS": "7.6 months [95% CI 6.9\u20138.3] vs 5.8 months [95% CI 5.6\u20136.6]; HR 0.54 [95% CI 0.45\u20130.65]; one-sided P < 0.0001",
            "OS": "15.6 months [95% CI 14.0\u201318.4] vs 12.6 months [95% CI 11.2\u201313.8]; HR 0.70 [95% CI 0.58\u20130.84]; one-sided P < 0.0001",
            "ORR": "None",
            "AE": "None",
            "Other": "1-year, 2-year, and 3-year OS rates were 62.4% vs 51.7%, 35.9% vs 23.8%, and 25.6% vs 12.8% in the camrelizumab plus chemotherapy group and the placebo plus chemotherapy group, respectively.",
            "Conclusion": "The extended follow-up of the ESCORT-1st trial reaffirms that camrelizumab plus chemotherapy provides significant and clinically meaningful improvements in OS and PFS compared to placebo plus chemotherapy, with a manageable safety profile for patients with untreated advanced or metastatic ESCC."
        },
        {
            "Title": "Phase II randomized study of maintenance therapy with regorafenib (REGO) versus placebo after first-line platinum and fluoropyrimidines-based chemotherapy in HER2 negative locally advanced/metastatic gastric (GC) or gastroesophageal junction (GEJ) cancer: Results of a-MANTRA study (GOIRC-05-2016).",
            "Indication": "HER2 negative locally advanced/metastatic gastric (GC) or gastroesophageal junction (GEJ) cancer",
            "Drug": "Regorafenib (REGO)",
            "PFS": "3.91 months (80% CI, 2.27-5.98) vs 5.19 months (80% CI, 4.0-7.26); HR = 0.736 (80% CI, 0.51-1.04; p = 0.1318)",
            "OS": "11.25 months vs 16.97 months (80% CI, HR = 0.596 [0.318-1.103], p = 0.1003)",
            "ORR": "None",
            "AE": "Grade 3-4 toxicities in ARMA and ARMB were fatigue (3.0% vs 6.4%), thrombocytopenia (3.0% vs 3.2%), hand-foot syndrome (0% vs 12.9%), diarrhea and skin rash (0% vs 3.2%)",
            "Other": "None",
            "Conclusion": "Despite the promising trend, considering the sample was not sized for the statistical study hypothesis, REGO maintenance therapy after 1L chemotherapy did not reach statistically significant results in mPFS1. No significant safety concerns were raised. New study designs following the REGO with ICIs are currently underway."
        },
        {
            "Title": "Efficacy and safety of the combination of tisleizumab with chemoradiotherapy in oligorecurrence esophageal squamous cell carcinoma: A prospective phase II study.",
            "Indication": "Oligometastatic recurrence esophageal squamous cell carcinoma (ESCC) after radical surgery",
            "Drug": "Tisleizumab with chemoradiotherapy",
            "PFS": "1-year PFS was 79.2%",
            "OS": "1-year OS was 83.2%",
            "ORR": "84.1%",
            "AE": "Grade \u22653 treatment-related adverse events included nausea (9.1%), vomit (4.5%), anorexia (4.5%), leukopenia (13.6%), hepatitis (2.3%), radiation esophagitis (1.4%), erythra (4.5%). There were 5 cases of immune-related adverse events, including 2 cases of erythra, 1 case of hypothyroidism, hepatitis, and gastroenteritis, respectively.",
            "Other": "None",
            "Conclusion": "Concurrent chemoradiotherapy and immunotherapy yielded satisfactory 1-year OS rate and manageable toxicities in oligometastatic recurrence patients with ESCC after radical surgery."
        },
        {
            "Title": "A phase 2 study of BOLD-100 in combination with FOLFOX chemotherapy in patients with advanced gastric cancer: Efficacy and safety analysis (BOLD-100-001)",
            "Indication": "Advanced gastric cancer",
            "Drug": "BOLD-100 in combination with FOLFOX",
            "PFS": "Median PFS was 4.3 months [95% credible interval (CI) 2.8, 7.1]",
            "OS": "Median OS was 7.9 months [CI 4.8, 15]",
            "ORR": "ORR was 11% [CI 2, 31]",
            "AE": "19 pts had \u22651 treatment-related adverse events (AEs), most commonly neutrophil count decreased (n=7, 33%), nausea (n=6, 29%), and peripheral sensory neuropathy (n=4, 19%). Most AEs were grade (G) 1-2. 7 patients (33%) had G3/4 neutrophil count decreased.",
            "Other": "DCR was 72% [49, 89] in the 18 evaluable patients. Two pts achieved a partial response, 4 pts had target tumor reductions, and 11 pts had stable disease.",
            "Conclusion": "BOLD-100 plus FOLFOX is an active, well-tolerated treatment regimen in the heavily pre-treated advanced GC. The reported mPFS, mOS, ORR and DCR data in this analysis shows promising clinical activity. The combination of BOLD-100 with FOLFOX is worthy of further study."
        },
        {
            "Title": "Multicenter randomized phase II study of short course FOLFOX + nivolumab (Nivo) followed by maintenance Nivo +/- radiation (RT) in the first line treatment of metastatic or unresectable gastroesophageal adenocarcinoma (mGEA)",
            "Indication": "Metastatic or unresectable gastroesophageal adenocarcinoma (mGEA)",
            "Drug": "FOLFOX + Nivolumab",
            "PFS": "The 12-month and median PFS for all evaluable pts (n=73) is 29.5% (19.2-40.7%) and 7.4 mo (5.7-8.6 mo); for Nivo alone 34.4% (18.8-50.6%) and 7.5 mo (5.4-14.2 mo), and Nivo + RT 30.0% (14.9-46.8%) and 7.8 mo (5.7-9.6 mo) respectively (p=NS).",
            "OS": "The 12-month and median OS for all evaluable pts (n=73) is 60.9% (48.6-71.1%) and 16 mo (10.8-22.4 mo); for Nivo alone 71.9% (52.9-84.3%) and 22.4 mo (13.2-NA mo), and Nivo + RT 62.8% (43.7-76.9%) and 17.9 mo (10.3-24.5 mo) respectively (p=NS).",
            "ORR": "The objective response rate for all evaluable pts is 43.8% (95% CI 32.5-55.2%).",
            "AE": "None",
            "Other": "Forty-nine patients progressed after randomization, of which 30 patients were rechallenged with FOLFOX or CAPOX with continued response \u2013 median time to 2nd progression 3.93 mo (range 0.5-15.2 mo). Amongst patients who were rechallenged with oxaliplatin based therapy, the median time to oxaliplatin failure was 13.76 mo (range 4.4\u201330 mo).",
            "Conclusion": "Short course FOLFOX + Nivo followed by maintenance Nivo has a similar progression free and overall survival to continuing FOLFOX + Nivo until progression. Patients who receive short course chemotherapy can successfully be rechallenged with oxaliplatin. The addition of RT to maintenance Nivo does not improve CPI efficacy. Short course FOLFOX with CPI is a safe and effective 1L option for mGEA."
        },
        {
            "Title": "Neoadjuvant therapy using PD-1 inhibitor plus chemotherapy with different intervals between chemotherapy cycles for locally advanced gastric or gastroesophageal junction cancer: A randomized phase 2 study.",
            "Indication": "Locally advanced gastric or gastroesophageal junction cancer",
            "Drug": "Toripalimab plus FLOT regimen",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Higher incidence rates of pyrexia and myelosuppression in the biweekly group compared to the triweekly group",
            "Other": "pCR rate for patients with PD-L1 CPS \u2265 1 was 42.1% while 6.7% for PD-L1 CPS < 1 in the triweekly group (P=0.047). CD3+CD8+PD-1+Ki-67+ T lymphocyte absolute counts were elevated in the triweekly group and reduced in the biweekly group before the second and third cycle of therapy.",
            "Conclusion": "The efficacy of toripalimab plus FLOT, given triweekly for three cycles, was not inferior to its administration biweekly for four cycles in locally advanced G/GEJ cancer. The finding is particularly significant for those with PD-L1 CPS \u2265 1 and warrants further validation."
        },
        {
            "Title": "Validation of PD-L1 as a predictive biomarker of response in operable gastroesophageal adenocarcinoma: A meta-analysis and meta-regression of neoadjuvant chemoimmunotherapy trials.",
            "Indication": "Operable gastroesophageal adenocarcinoma",
            "Drug": "Immune checkpoint inhibitors (ICIs) plus chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "PD-L1 showed a moderate positive correlation with pCR, stronger for higher cut-offs (weighted R for cut-offs 1, 5, and 10: 0.33, 0.51, and 0.65, respectively) and after exclusion of CRT trials (weighted R for cut-offs 5 and 10: 0.67 and 0.99, respectively). In a mixed-effect meta-regression model, PD-L1 \u2265 5 was a significant predictor of response (effect size [ES]: 0.71, 95% CI, 0.29-1.13, p=0.001), even after accounting for the backbone treatment (ChT versus CRT) (ES: 0.60, 95% CI, 0.20-1.00, p=0.003).",
            "Conclusion": "Neoadjuvant ICI-ChT consistently improves pathological response in operable GEA, particularly in PD-L1-positive tumors. PD-L1 \u2265 5 appears a significant biomarker of pCR in non-metastatic GEA, supporting stratification by PD-L1 and biomarker-selected trials for evaluation at the individual patient level."
        },
        {
            "Title": "Neoadjuvant dual checkpoint inhibition followed by immune-chemoradiation in patients with resectable gastric adenocarcinoma.",
            "Indication": "Resectable gastric adenocarcinoma",
            "Drug": "Nivolumab (nivo) +/- ipilimumab (ipi) plus chemotherapy followed by immune-chemoradiation",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Three patients (10%) experienced grade 4 events (acute kidney injury, myocarditis, and neutropenia). The most frequent grade 3 toxicity was nausea and vomiting (10%).",
            "Other": "From the 23 participants that underwent resection, 22 had pathologic data at the time of analysis, with 9 achieving ypT0ypN0 (40.9%, 95% CI: 20.7%, 63.7%). There was an 86% R0 resection rate.",
            "Conclusion": "Our results are encouraging and support further development of this strategy for localized gastric adenocarcinomas. The regimen is safe and potentially generalizable."
        },
        {
            "Title": "Effect of nivolumab combined with definitive chemoradiotherapy on response rate for esophageal cancer: An immune active intrinsic subtype could be its biomarker\u2014The NOBEL trial.",
            "Indication": "Esophageal squamous cell carcinoma (ESCC)",
            "Drug": "Nivolumab combined with chemoradiotherapy (CRT)",
            "PFS": "One-year PFS rates: 65.4% (all cases), 79.6% (operable cases), 43.8% (inoperable cases)",
            "OS": "One-year OS rates: 92.7% (all cases), 100% (operable cases), 81.3% (inoperable cases)",
            "ORR": "Objective response rate: 87.8% (95% CI: 74.5%\u201394.7%)",
            "AE": "Nonhematological toxicity grade \u22654: \u226410%, Pneumonitis grade \u22653: \u226415%",
            "Other": "Complete response (CR) rate: 73.2% (95% CI: 58.1%\u201384.3%), CR rates in operable cases: 84% (95% CI: 65.3%\u201393.6%), CR rates in inoperable cases: 56.3% (95% CI: 33.2%\u201376.9%)",
            "Conclusion": "Nivolumab combined with definitive CRT provided encouraging efficacy and acceptable toxicity in patients with ESCC. Immune high-active intrinsic subtype before treatment has the potential to predict a high CR rate for this combination treatment."
        },
        {
            "Title": "Conversion surgery for tislelizumab combined chemotherapy for T4a/N3 esophageal squamous cell carcinoma (ESCC): A prospective, single-arm, phase II study.",
            "Indication": "T4a/N3 esophageal squamous cell carcinoma (ESCC)",
            "Drug": "Tislelizumab combined with chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "Objective response rate (ORR): 95% (38/40)",
            "AE": "Treatment-related adverse events (TRAE): 70% (28/40), Grade 3 and above TRAEs: 12.5% (5/40)",
            "Other": "R0 resection rate: 100% (24/24), Major pathological response (MPR) rate: 33% (8/24), Pathological complete response (pCR) rate: 20.8% (5/24), Increased percentage of CD3+CD4+CD8- T cells in peripheral blood after conversion therapy (p-value 0.02)",
            "Conclusion": "Tislelizumab combined with chemotherapy, followed by radical esophagectomy resection, has promising efficacy and good safety for T4a/N3 ESCC."
        },
        {
            "Title": "Safety and feasibility of esophagectomy following neoadjuvant immunotherapy combined with chemotherapy or chemoradiotherapy for locally advanced esophageal squamous cell carcinoma: Analysis of two phase 2 clinical trials.",
            "Indication": "Locally advanced esophageal squamous cell carcinoma (ESCC)",
            "Drug": "PD-1 inhibitor combined with chemotherapy or chemoradiotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary tumor pathological complete response (pCR) rates: 52.9% (nICRT group), 21.6% (nICT group), Postoperative pCR rates: 41.2% (nICRT group), 21.6% (nICT group), Minimally invasive surgery: 89.2% (nICT group), 94.1% (nICRT group)",
            "Conclusion": "Esophagectomy was safe after the addition of PD-1 inhibitor to preoperative CT or CRT in ESCC neoadjuvant therapies. Follow-up and exploratory endpoints, including biomarker analyses, are ongoing."
        },
        {
            "Title": "Neoadjuvant/adjuvant pembrolizumab (pembro) + chemotherapy (chemo) vs placebo (pbo) + chemo for gastric/gastroesophageal junction (G/GEJ) adenocarcinoma: Major pathologic response (mPR) in KEYNOTE-585.",
            "Indication": "Gastric/gastroesophageal junction (G/GEJ) adenocarcinoma",
            "Drug": "Pembrolizumab (pembro) + chemotherapy (chemo)",
            "PFS": "None",
            "OS": "In pts who had mPR, the HRs (95% CI) for EFS and OS were 0.6 (0.4-1.0) and 0.7 (0.4-1.2), respectively, for pembro + chemo vs pbo + chemo.",
            "ORR": "None",
            "AE": "None",
            "Other": "Downstaging and Resection Results: Primary tumor downstaging: 46.6% (pembro + chemo) vs 34.9% (pbo + chemo); Nodal status downstaging: 40.4% (pembro + chemo) vs 28.9% (pbo + chemo); Primary tumor or nodal status downstaging: 58.0% (pembro + chemo) vs 47.7% (pbo + chemo)",
            "Conclusion": "In this posthoc analysis, pts with mPR had numerically improved PFS and OS with pembro + chemo vs pbo + chemo; these findings were consistent with analysis of the ITT population. Further studies in this population are warranted."
        },
        {
            "Title": "Preoperative nivolumab plus SOX in patients with locally advanced gastric or gastro-esophageal junction cancer: A phase 2, single-arm trial.",
            "Indication": "Locally advanced gastric or gastro-esophageal junction cancer (GC/GEJC)",
            "Drug": "Nivolumab plus SOX regimen",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "No severe complications or death related to the surgery.",
            "Other": "pCR rate: 28.26% [95% CI, 15.2%-41.3%]; mPR rate: 41.3% (95% CI, 27.1%-55.5%); All patients underwent R0 resection; mPR rate in patients with PD-L1 CPS \u2265 5 and patients with PD-L1 CPS < 5 was 42.86% vs 38.89% (P=0.790); mPR rate in patients infected with COVID-19 was 44.44% (12/27), while the mPR rate in patients who have not been infected with COVID-19 was 36.84% (7/19) (P=0.146); scRNA-seq data revealed that the composition of MHC-II (HLA-DRA, HLA-DPA1, HLA-DRB1, HLA-DPB1, and HLA-DQB1) were significantly over-expressed in cancer cells of treatment-sensitive patients (TRG0/1) compared to that of treatment-insensitive patients (TRG2/3); mPR rate in patients with MHC-II IHC \u2265 2 and patients with MHC-II IHC < 2 was 58.87% (83/141) vs 21.21% (21/99) (P < 0.001)",
            "Conclusion": "Nivolumab plus SOX as neoadjuvant therapy showed an encouraging pCR rate, mPR rate, and manageable safety. The long-term efficacy of this combination of regimens will be continued to follow up. In addition, the MHC-II expression may be a potential biomarker for predicting the effectiveness of immunotherapy combined with chemotherapy for GC/GEJC."
        },
        {
            "Title": "Neoadjuvant immunotherapy (PD-1) combined with chemotherapy for locally advanced thoracic esophageal carcinoma (EC): A single-arm, open-label, phase II study.",
            "Indication": "Locally advanced thoracic esophageal carcinoma (EC)",
            "Drug": "PD-1 blockade (Pembrolizumab/Tislelizumab) plus nab-paclitaxel and cisplatin",
            "PFS": "None",
            "OS": "2-year OS rate was 81.5% (95% CI, 69.4, 90.2%)",
            "ORR": "Overall response rate (ORR) was 78.6% (33/42)",
            "AE": "Overall, grade \u2265 3 AEs included leukopenia (11.9% [5/42]), thrombocytopenia (4.8% [2/42]), and no deaths were due to TRAEs.",
            "Other": "pCR rate: 33.3% (14/42); TRG (Mandard system) 1, 2, and 3 were 14.3% (6/42), 14.3% (6/42), and 71.4% (30/42) respectively; 2-year EFS rate was 54.8% (95% CI, 43.4, 66.3%); With 34 patients of RNA sequenced, the poor outcome patients had significant molecular differences seen in PI3K2Akt pathway gene expression, markers of immune activation, and genes involved in ECM2 receptor interaction and focal adhesion in baseline samples, and the pathway enriched by differential genes expression in samples after treatment validated this result.",
            "Conclusion": "Combining PD-1 blockade with nab-paclitaxel and cisplatin is feasible and effective in patients with locally advanced resectable ESCC, and might be a promising approach for neoadjuvant treatment."
        },
        {
            "Title": "Neoadjuvant S-1 plus cisplatin-based chemoradiotherapy compared with surgery alone for locally advanced gastric cancer: Randomized, phase II trial.",
            "Indication": "Locally advanced gastric cancer",
            "Drug": "S-1, Cisplatin",
            "PFS": "None",
            "OS": "Median OS was not reached in CCRT group, and 51.2 months in OP group (P=0.335)",
            "ORR": "None",
            "AE": "No statistically significant differences between neoadjuvant CCRT and surgery group in the aspect of both hematologic and non-hematologic adverse events.",
            "Other": "Median disease-free survival (DFS) was 27.91 months in CCRT group, and 45.96 months in OP group (P=0.985).",
            "Conclusion": "Neoadjuvant SP based CCRT significantly increased downstaging in T stage compared to OP alone. The prespecified genomic analysis with transcriptomic sequencing will be available at the meeting."
        },
        {
            "Title": "Neoadjuvant toripalimab plus chemotherapy followed by concurrent chemoradiotherapy in locally advanced esophageal squamous cell carcinoma (GASTO1071).",
            "Indication": "Locally advanced esophageal squamous cell carcinoma",
            "Drug": "Toripalimab, Paclitaxel-albumin, Nedaplatin, Capecitabine",
            "PFS": "12-month and 18-month PFS rates were 78.3%, 64.4% in cohort A, and 75.7%, 63.6% in cohort B. Median PFS was not reached in either cohort.",
            "OS": "None",
            "ORR": "Objective response rate assessed 2 months after CCRT was 93.7% in cohort A and 93.4% in cohort B.",
            "AE": "Grade 3 and 5 pneumonitis occurred in 3.2% (2/63) and 1.6% (1/63) in cohort A, respectively, while no grade 3+ pneumonitis occurred in cohort B. Treatment-related esophageal fistula occurred in 1.6% (1/63) in cohort A and 1.6% (1/61) in cohort B.",
            "Other": "Locoregional recurrence was observed in 19.0% (12/63) of patients in cohort A, and 24.6% (15/61) of patients in cohort B. Distant metastasis was observed in 12.7% (8/63) of patients in cohort A, and 3.3% (2/61) of patients in cohort B.",
            "Conclusion": "Neoadjuvant toripalimab plus chemotherapy followed by definitive CCRT demonstrated promising efficacy and acceptable toxicity in patients with locally advanced ESCC. Further study is warranted to confirm this conclusion."
        },
        {
            "Title": "Prediction for major pathologic response after neoadjuvant tislelizumab combined with chemotherapy in patients with locally advanced esophageal squamous cell carcinoma by RNA sequencing: A single-arm, phase II trial.",
            "Indication": "Locally advanced esophageal squamous cell carcinoma",
            "Drug": "Tislelizumab, Albumin Paclitaxel, Carboplatin, Nedaplatin",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Grade I AEs were observed in 57 (91.3%) pts and most common of which was anemia (49/62, 79%). 19 (30.6%) pts had grade II and 4 (6.4%) had grade III AEs. No higher AEs were observed.",
            "Other": "MPR, pCR and R0 resection rate were (55.8%), (23.1%) and (100%), respectively. The median follow-up time was 7.4 (1.7-12.6) months and no relapse was found before the last follow-up.",
            "Conclusion": "Tislelizumab combined with chemotherapy is a feasibility and effective neoadjuvant treatment option for locally advanced ESCC. Some hub genes were found to be associated with non-MPR after neoadjuvant therapy. Further experimental studies are needed to investigate these findings."
        },
        {
            "Title": "Comparing toripalimab combined with chemoradiotherapy versus chemoradiotherapy alone for locally advanced esophageal squamous cell carcinoma: A multicenter, open-label, randomized phase II clinical trial.",
            "Indication": "Locally advanced esophageal squamous cell carcinoma",
            "Drug": "Toripalimab, Paclitaxel, Carboplatin",
            "PFS": "Median PFS was 10.0 months in the study arm and 17.8 months in the control arm (HR: 1.567, 95% CI: 0.755-3.251, p=0.228)",
            "OS": "Median OS was 23.7 months and 25.9 months, respectively (HR: 1.140, 95% CI: 0.536-2.427, p=0.733)",
            "ORR": "ORR rates were 64% and 86% (p=0.164), respectively.",
            "AE": "The incidence of grade 3 or worse treatment-related adverse events was similar between groups, with the most common adverse event being lymphopenia.",
            "Other": "None",
            "Conclusion": "No significant benefit in adding induction and consolidation immunotherapy to CRT for treating locally advanced ESCC. However, the combination of radiotherapy and immunotherapy warrants further exploration. Ongoing analyses of biomarker correlates of response and survival will provide additional insights."
        },
        {
            "Title": "Long-term survival comparison of adjuvant FLOT versus XELOX chemotherapy for locally advanced gastric cancer in a large real-world cohort.",
            "Indication": "Locally advanced gastric cancer",
            "Drug": "FLOT, XELOX",
            "PFS": "None",
            "OS": "Median OS: 44.80 months (FLOT) vs 34.87 months (XELOX); 5-year OS rate: 41.4% (FLOT) vs 25.3% (XELOX); 10-year OS rate: 31.9% (FLOT) vs 11.8% (XELOX)",
            "ORR": "None",
            "AE": "Grade 3-4 treatment-related adverse events: 57.9% (FLOT) vs 33.6% (XELOX)",
            "Other": "Subgroup analysis showed significant survival benefits for patients with ECOG performance status 0 or pT3-4 stage from adjuvant FLOT regimen.",
            "Conclusion": "Adjuvant FLOT regimen exhibits significantly long-term survival advantages for LAGC patients with good performance status and late disease stage compared with XELOX regimen, facilitating adjuvant FLOT regimen as a prospectively alternative option for LAGC."
        },
        {
            "Title": "A phase III trial comparing 5-FU plus cisplatin (CF) versus CF plus docetaxel or radiotherapy as neoadjuvant treatment for locally advanced esophageal cancer: 5-year follow-up from JCOG1109.",
            "Indication": "Locally advanced esophageal squamous cell cancer (ESCC)",
            "Drug": "CF, DCF, CF-RT",
            "PFS": "Median PFS: 2.7 years (CF), 9.5 years (DCF), 5.8 years (CF-RT); 5-year PFS: 42.6% (CF), 55.7% (DCF), 53.5% (CF-RT)",
            "OS": "Median OS: 5.8 years (CF), 10.2 years (DCF), 8.2 years (CF-RT); 5-year OS: 51.9% (CF), 65.1% (DCF), 60.2% (CF-RT)",
            "ORR": "None",
            "AE": "Treatment-related deaths: CF: 4 initially, 1 additional over two more years; DCF: 4 initially, 3 additional over two more years; CF-RT: 4 initially, 2 additional over two more years",
            "Other": "None",
            "Conclusion": "After 5 years follow-up, neoadjuvant DCF continued to demonstrate clinically meaningful improvements in OS and PFS compared to CF in patients with locally advanced ESCC."
        },
        {
            "Title": "Perioperative PD-1 antibody tislelizumab plus chemotherapy for locally advanced gastro-oesophageal junction cancer: A prospective, nonrandomized, open-label, phase II trial.",
            "Indication": "Locally advanced resectable gastro-oesophageal junction (GEJ) adenocarcinoma",
            "Drug": "PD-1 antibody tislelizumab plus SOX (S-1 and oxaliplatin)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Treatment-related grade 3-4 adverse events: 9.4% (3/32)",
            "Other": "R0 resection rate: 96.9% (31/32); Major pathological response (MPR) rate: 37.5% (12/32, 95% CI: 21.1%-56.3%); Pathological complete response (pCR) rate: 28.1% (9/32, 95% CI: 13.7%-46.7%); Surgical morbidity: 12.5% (4/32); No 30-day mortality observed.",
            "Conclusion": "Perioperative PD-1 antibody tislelizumab plus chemotherapy demonstrated a significantly improved pathological regression and might be a promising option for patients with locally advanced resectable GEJ adenocarcinoma."
        },
        {
            "Title": "Neoadjuvant immuno-chemo-radiotherapy in operable esophageal and gastroesophageal junction adenocarcinoma with carboplatin, paclitaxel plus radiotherapy and avelumab (anti-PDL1 antibody): Neo-CREATE trial.",
            "Indication": "Operable esophageal and gastroesophageal junction adenocarcinoma",
            "Drug": "Carboplatin, paclitaxel, radiotherapy, avelumab",
            "PFS": "1-year disease-free survival was 83.8%",
            "OS": "1-year overall survival was 91.2%",
            "ORR": "None",
            "AE": "Grade 2-4 myocarditis (4%), anastomotic leak (4%), infection (6%), and diarrhea (6%)",
            "Other": "Pathological complete response (pCR) was 23%, major histological response (MHR) was 58.3%, complete or near complete response (Tumor Regression Grade 1-2) was 31%",
            "Conclusion": "Combining avelumab with neoadjuvant CRT is feasible in patients with acceptable tolerability in locally advanced esophageal/GEJ adenocarcinoma. Avelumab with CRT is active; however, the observed pCR rate does not represent a signal of substantial improvement compared to historical controls. Exploratory analysis suggests improved outcomes in patients with optimal tumor regression after neoadjuvant CRT-A. Exploratory biomarker work to correlate with outcomes is underway."
        },
        {
            "Title": "Neoadjuvant chemotherapy with albumin-bound paclitaxel plus cisplatin and capecitabine for locally advanced esophageal squamous cell carcinoma: A prospective phase II trial.",
            "Indication": "Locally advanced esophageal squamous cell carcinoma (ESCC)",
            "Drug": "Albumin-bound paclitaxel, cisplatin, capecitabine",
            "PFS": "3-year disease-free survival rate was 79.4%",
            "OS": "3-year survival rate was 88.8%",
            "ORR": "Pathological complete response (pCR) was 28.7%, major pathological response (MPR) was 62.5%",
            "AE": "Grade 3/4 adverse events included neutropenia (25%), leukopenia (14.5%), nausea (7.5%), and peripheral neurotoxicity (5.5%)",
            "Other": "R0 resection rate was 100%, postoperative pathology revealed that 52.5% of patients had stage I (ypT0-2N0M0)",
            "Conclusion": "The results meet our initial aim that preoperative chemotherapy with albumin-bound paclitaxel, cisplatin, and capecitabine is highly active and resulted in a considerable pCR and MPR rate with a promising 3-year survival rate in patients with locally advanced ESCC, while well-tolerated. This triplet regimen could be another effective neoadjuvant treatment strategy for locally advanced ESCC."
        },
        {
            "Title": "A prospective phase 2 single-arm study to evaluate the efficacy and safety of neoadjuvant pembrolizumab plus chemotherapy in patients with locally advanced esophageal squamous cell carcinoma (Keypoint-1).",
            "Indication": "Locally advanced esophageal squamous cell carcinoma (ESCC)",
            "Drug": "Pembrolizumab plus chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Grade 3/4 treatment-related adverse events include leukopenia (33.3%), alopecia (10.0%), and pneumonitis (3.3%). One patient died of Stevens-Johnson syndrome.",
            "Other": "Pathological complete response (pCR) rate: 29.2%, near complete response (CAP1): 20.8%, R0 resection rate: 100%, perioperative complications: anastomotic leakage (8.3%), nerve injury (4.2%), median disease-free survival (DFS): 30.6 months.",
            "Conclusion": "Neoadjuvant treatment with combination pembrolizumab and chemotherapy demonstrated encouraging anti-tumor activities, good surgical outcomes, and a manageable safety profile in patients with locally advanced ESCC, supporting further evaluation in a prospective randomized phase 3 trial."
        },
        {
            "Title": "Trastuzumab deruxtecan (T-DXd) in patients with HER2-expressing biliary tract cancer (BTC) and pancreatic cancer (PC): Outcomes from DESTINY-PanTumor02 (DP-02).",
            "Indication": "HER2-expressing biliary tract cancer (BTC) and pancreatic cancer (PC)",
            "Drug": "Trastuzumab deruxtecan (T-DXd)",
            "PFS": "{'BTC': '4.6 months (95% CI 3.1, 6.0)', 'PC': '3.2 months (95% CI 1.8, 7.2)'}",
            "OS": "None",
            "ORR": "{'BTC': '22.0% (95% CI 10.6, 37.6)', 'PC': '4.0% (95% CI 0.1, 20.4)'}",
            "AE": "Grade \u22653 drug-related adverse events occurred in 39.0% of BTC patients and 28.0% of PC patients. Adjudicated drug-related interstitial lung disease/pneumonitis occurred in 17.1% of BTC patients and 4.0% of PC patients.",
            "Other": "None",
            "Conclusion": "T-DXd showed clinically meaningful benefit in patients with BTC across primary tumor locations. Low patient numbers limit interpretation of the PC cohort; however, data support further exploration of T-DXd in this population. Safety was consistent with the known profile."
        },
        {
            "Title": "Zanidatamab in previously-treated HER2-positive (HER2+) biliary tract cancer (BTC): Overall survival (OS) and longer follow-up from the phase 2b HERIZON-BTC-01 study.",
            "Indication": "Previously-treated HER2-positive (HER2+) biliary tract cancer (BTC)",
            "Drug": "Zanidatamab",
            "PFS": "None",
            "OS": "Median OS: 15.5 months (95% CI 10.4, 18.5); 12-month OS: 56.2% (95% CI 44.3, 66.5)",
            "ORR": "41% confirmed objective response rate (cORR)",
            "AE": "Grade \u22653 treatment-related adverse events (TRAEs) occurred in 21% of patients. The most common grade 3 TRAEs were diarrhea (5%), anemia (3%), and ejection fraction decreased (3%). One patient had a grade 4 TRAE (aspartate aminotransferase increased). Serious AEs occurred in 9% of patients; 2% discontinued treatment due to an AE.",
            "Other": "Median duration of response (DoR): 14.9 months (95% CI 7.4, not reached)",
            "Conclusion": "With close to 2 years of median follow-up, zanidatamab demonstrated a median OS of 15.5 months and a median duration of response of 14.9 months in previously-treated patients with HER2+ BTC. Safety remained manageable. A phase 3 trial of zanidatamab in the first-line setting for patients with metastatic BTC is planned."
        },
        {
            "Title": "SCT-I10A combined with a bevacizumab biosimilar (SCT510) versus sorafenib in the first-line treatment of advanced hepatocellular carcinoma: A randomized phase 3 trial.",
            "Indication": "Advanced hepatocellular carcinoma (HCC)",
            "Drug": "SCT-I10A (anti-PD-1 monoclonal antibody) combined with SCT510 (bevacizumab biosimilar)",
            "PFS": "7.1 months (HR 0.50; 95% CI: 0.38, 0.65; p < 0.0001) for SCT-I10A plus SCT510 group vs. 2.9 months for sorafenib group",
            "OS": "22.1 months (HR 0.60; 95% CI: 0.44, 0.81; p = 0.0008) for SCT-I10A plus SCT510 group vs. 14.2 months for sorafenib group",
            "ORR": "32.8% (75/229) for SCT-I10A plus SCT510 group vs. 4.3% (5/116) for sorafenib group",
            "AE": "42.6% (98/230) of patients in the SCT-I10A plus SCT510 group had grade \u22653 treatment-related adverse events (TRAEs) vs. 33.6% (39/116) in the sorafenib group. The most common grade \u22653 TRAE was hypertension: 7.8% (18/230) for SCT-I10A plus SCT510 group vs. 4.3% (5/116) for sorafenib group. Three drug-related deaths occurred in the SCT510 group.",
            "Other": "None",
            "Conclusion": "The combination of SCT-I10A and SCT510 showed substantial clinical advantages and an acceptable safety profile in patients with advanced HCC, supporting its suitability as a first-line treatment option for HCC."
        },
        {
            "Title": "Three-year follow-up data from KEYNOTE-966: Pembrolizumab (pembro) plus gemcitabine and cisplatin (gem/cis) compared with gem/cis alone for patients (pts) with advanced biliary tract cancer (BTC).",
            "Indication": "Advanced biliary tract cancer (BTC)",
            "Drug": "Pembrolizumab (pembro) plus gemcitabine and cisplatin (gem/cis)",
            "PFS": "6.5 months (HR 0.85; 95% CI: 0.75-0.97) for pembro arm vs. 5.6 months for placebo arm",
            "OS": "12.7 months (HR 0.86; 95% CI: 0.75-0.98) for pembro arm vs. 10.9 months for placebo arm",
            "ORR": "28.7% (153/533) for pembro arm vs. 28.7% (154/536) for placebo arm",
            "AE": "71.5% (378/529) of patients in the pembro arm had grade 3-5 treatment-related adverse events (AEs) vs. 69.3% (370/534) in the placebo arm. 5.9% (31/529) of patients in the pembro arm died vs. 9.4% (50/534) in the placebo arm.",
            "Other": "Duration of Response (DOR): 8.3 months (range: 1.2+ to 44.3+) for pembro arm vs. 6.9 months (1.1+ to 41.1+) for placebo arm. At 18 months, estimates of ongoing response were 24% in the pembro arm vs. 14% in the placebo arm.",
            "Conclusion": "With a median follow-up of 36.6 months, the clinically meaningful improvement in OS with pembro + gem/cis was maintained, with no new safety signals, compared with placebo + gem/cis in pts with unresectable or advanced BTC. These data support pembro + gem/cis as first-line treatment for advanced BTC."
        },
        {
            "Title": "The safety and efficacy of ivonescimab in combination with chemotherapy as first-line treatment for advanced biliary tract cancer.",
            "Indication": "Advanced biliary tract cancer (BTC)",
            "Drug": "Ivonescimab combined with gemcitabine and cis-platinum",
            "PFS": "8.5 months (95% CI, 6.8-9.9)",
            "OS": "16.8 months (95% CI, 9.8-NE)",
            "ORR": "63.6% (14/22) regardless of PD-L1 status, with an ORR of 77.8% (7/9) for gallbladder cancer patients specifically",
            "AE": "86.4% (19/22) of patients experienced at least one grade 3 or higher treatment-related adverse event (TRAE). The most common grade 3/4 TRAEs with a frequency of at least 10% included white blood cell count decreased (50.0%), neutrophil count decreased (50.0%), anemia (40.9%), platelet count decreased (22.7%), and hypertension (13.6%).",
            "Other": "Disease Control Rate (DCR): 100% (22/22). The 6-month PFS rate was 84.2% (95% CI, 58.7-94.6). The 9-month OS rate was 81.8% (95% CI, 58.5-92.8).",
            "Conclusion": "Ivonescimab combined with chemotherapy as 1L treatment demonstrated promising efficacy in pts with advanced BTC. Further trials to validate the efficacy and safety are warranted."
        },
        {
            "Title": "Survival outcomes with adjuvant immunotherapy after hepatic resection in patients with intermediate/advanced (BCLC stage B/C) hepatocellular carcinoma: Insights from a propensity-matched multicenter study.",
            "Indication": "Intermediate/advanced hepatocellular carcinoma (HCC)",
            "Drug": "Adjuvant immune checkpoint inhibitors (ICIs) including tislelizumab, sintilimab, camrelizumab, pembrolizumab, and toripalimab",
            "PFS": "None",
            "OS": "35.1 months vs. 27.8 months, P=0.036",
            "ORR": "None",
            "AE": "Most ICI-related adverse reactions were grade I-II",
            "Other": "Median RFS: 29.6 vs. 19.3 months, P=0.031",
            "Conclusion": "Adjuvant immunotherapy is associated with improved RFS and OS in patients undergoing curative-intent resection for intermediate/advanced HCC. These findings support the need for future randomized controlled trials to establish definitive evidence for this high-risk population."
        },
        {
            "Title": "Impact of hepatitis B virus (HBV) infection on efficacy and safety in the KEYNOTE-966 study of pembrolizumab (pembro) plus gemcitabine and cisplatin (gem/cis) for advanced biliary tract cancer (BTC).",
            "Indication": "Advanced biliary tract cancer (BTC)",
            "Drug": "Pembrolizumab plus gemcitabine and cisplatin",
            "PFS": "None",
            "OS": "HBV-positive subgroup: 12.3 months (pembro) vs. 10.9 months (placebo), HR=0.87; HBV-negative subgroup: 12.8 months (pembro) vs. 10.7 months (placebo), HR=0.85",
            "ORR": "None",
            "AE": "Grade 3-5 treatment-related AE rates: 69% (pembro) vs. 73% (placebo) in HBV-positive subgroup; 72% (pembro) vs. 68% (placebo) in HBV-negative subgroup",
            "Other": "Rates of discontinuation due to treatment-related AE: 14% (pembro) vs. 15% (placebo) in HBV-positive subgroup; 22% (pembro) vs. 16% (placebo) in HBV-negative subgroup",
            "Conclusion": "Efficacy and safety outcomes remained consistent between HBV-positive and HBV-negative subgroups from KEYNOTE-966. These data further support pembro+gem/cis as first-line therapy for advanced BTC regardless of HBV infection status."
        },
        {
            "Title": "Predictors of short-term death and long-term survival in advanced hepatocellular carcinoma (HCC) treated with contemporary tyrosine kinase inhibitors (TKI) or immunotherapy: A multicenter analysis from the HCC-CHORD consortium.",
            "Indication": "Advanced hepatocellular carcinoma (HCC)",
            "Drug": "Tyrosine kinase inhibitors (TKIs) or immunotherapy combinations",
            "PFS": "Median PFS: 7.4 months (95% CI: 6.2 to 8.4)",
            "OS": "Median OS: 17.1 months (95% CI: 15.1 to 18.8)",
            "ORR": "None",
            "AE": "None",
            "Other": "Independent predictors of STD: ALBI grade (2.26), MVI (2.13), distant metastases (2.14); Independent predictors of LTS: ALBI grade (0.45), MVI (0.49), distant metastases (0.36)",
            "Conclusion": "Higher ALBI grade, MVI, and distant metastases are predictors of STD, while lower ALBI grade, absence of MVI, and lack of distant metastases are predictive of LTS in HCC patients treated with contemporary TKIs or immunotherapy combination regimens. These predictors may help guide treatment decisions in patient populations underrepresented in clinical trials."
        },
        {
            "Title": "Atezolizumab plus bevacizumab (A+B) as first-line systemic therapy for advanced hepatocellular carcinoma (HCC): A multi-institution analysis of patient outcomes based on Child Pugh (CP) and ALBI liver function.",
            "Indication": "Advanced hepatocellular carcinoma (HCC)",
            "Drug": "Atezolizumab plus bevacizumab",
            "PFS": "Median PFS: 8.9 months (CP-A5/6), 6.4 months (CP-B7), 2.7 months (CP-B8-C12); Median PFS by ALBI grade: 11.0 months (grade 1), 6.4 months (grade 2), 3.7 months (grade 3)",
            "OS": "Median OS: 21.6 months (CP-A5/6), 9.1 months (CP-B7), 4.7 months (CP-B8-C12); Median OS by ALBI grade: 34.9 months (grade 1), 11.7 months (grade 2), 4.7 months (grade 3)",
            "ORR": "Partial response: 29.2% (CP-A), 19.8% (CP-B), 10.0% (CP-C); Stable disease: 32.7% (CP-A), 33.7% (CP-B), 40.0% (CP-C)",
            "AE": "None",
            "Other": "Factors predictive of shorter OS: CP-B7 or CP-B8-C12, ALBI grade 2 or 3, hepatitis B, poorer ECOG performance status, macrovascular invasion, non-white race, lack of prior surgery or embolization",
            "Conclusion": "A+B remains a viable option in patients with CP-B7 liver function, although benefit is significantly less than those with CP-A. ALBI score has predictive value and can identify two prognostic groups among those with CP-A liver function."
        },
        {
            "Title": "Pembrolizumab (pembro) in patients (pts) with sorafenib-treated (cohort 1) and treatment (tx)-naive (cohort 2) advanced hepatocellular carcinoma (aHCC) after additional follow-up in the phase 2 KEYNOTE-224 study.",
            "Indication": "Advanced hepatocellular carcinoma (aHCC)",
            "Drug": "Pembrolizumab",
            "PFS": "Cohort 1: 4.9 months (95% CI, 3.5-7.0); Cohort 2: 4.3 months (95% CI, 2.1-7.8)",
            "OS": "Cohort 1: 13.2 months (95% CI, 9.7-15.3); Cohort 2: 16.9 months (95% CI, 8.3-23.1)",
            "ORR": "Cohort 1: 18.3% (95% CI, 11.4-27.1); Cohort 2: 17.6% (95% CI, 8.4-30.9)",
            "AE": "Cohort 1: 73.1% (grade 3-5, 26.0%); Cohort 2: 54.9% (grade 3-5, 15.7%)",
            "Other": "Median DOR: Cohort 1: 21.0 months (range, 3.1 to 75.8+); Cohort 2: 24.7 months (range, 3.1 to 53.4+)",
            "Conclusion": "Pembrolizumab continued to provide durable responses in pts with aHCC with or without prior systemic therapy, with some lasting beyond 20 months; long-term effects on OS beyond 24 months and manageable safety were also observed."
        },
        {
            "Title": "Stereotactic body radiotherapy (SBRT) combined with transcatheter arterial chemoembolization (TACE) and tyrosine kinase inhibitors (TKIs) versus TACE and TKIs alone for unresectable hepatocellular carcinoma (uHCC) with portal vein tumor thrombus (PVTT): A randomized controlled trial.",
            "Indication": "Unresectable hepatocellular carcinoma (uHCC) with portal vein tumor thrombus (PVTT)",
            "Drug": "SBRT, TACE, TKIs (sorafenib, donafenib, lenvatinib)",
            "PFS": "Group A: 9.75 months; Group B: 4.89 months (HR=1.703 [95% CI, 1.045-2.777], P=0.0245)",
            "OS": "Group A: 17.93 months; Group B: 9.61 months (HR=1.869 [95% CI, 1.059-3.266], P=0.017)",
            "ORR": "Group A: 74.4%; Group B: 40.5% (P=0.0015)",
            "AE": "Common grade 3/4 TRAEs: Hypertension (Group A: 17.8%, Group B: 13.3%, P=0.5608), ALT elevation (Group A: 26.7%, Group B: 22.2%, P=0.6237), AST elevation (Group A: 22.2%, Group B: 15.6%, P=0.4191)",
            "Other": "DCR: Group A: 81.4%, Group B: 66.7% (P=0.1211)",
            "Conclusion": "In uHCC patients with PVTT, the combination of SBRT and TACE-TKIs showed significant survival benefit without severe safety concerns."
        },
        {
            "Title": "Hepatic artery infusion chemotherapy (HAIC) combined with tislelizumab and lenvatinib for initial unresectable hepatocellular carcinoma (HCC) with portal vein tumor thrombus: A prospective, single-arm phase II trial.",
            "Indication": "Initial unresectable hepatocellular carcinoma (HCC) with portal vein tumor thrombus (PVTT)",
            "Drug": "HAIC (FOLOFOX), tislelizumab, lenvatinib",
            "PFS": "15 months (95% CI 10-22)",
            "OS": "Not reached",
            "ORR": "68.97% (95% CI 49.19-84.72) per mRECIST; 58.62% (95% CI 38.94-76.48) per RECIST v1.1",
            "AE": "Common TRAEs: Hypoproteinemia (93.10%), abdominal pain (72.41%), nausea (68.97%), leukopenia (65.52%)",
            "Other": "DCR: 93.10% (95% CI 77.23-99.15) per mRECIST and RECIST v1.1; 7 (24.1%) patients became eligible for surgical resection",
            "Conclusion": "HAIC (FOLOFOX) combined with tislelizumab and lenvatinib afforded promising efficacy and safety in tumor control in patients with advanced HCC with PVTT in short-term observation and an eligible surgical conversion rate was observed."
        },
        {
            "Title": "Genomic and transcriptomic biomarkers of nab-paclitaxel plus gemcitabine-cisplatin in patients with advanced biliary tract cancer",
            "Indication": "Advanced biliary tract cancer (BTC)",
            "Drug": "Nab-paclitaxel plus gemcitabine-cisplatin (GemCis/nab-P)",
            "PFS": "8.3 months (95% CI, 7.0\u201311.5)",
            "OS": "19.8 months (95% CI, 15.3\u2013not reached)",
            "ORR": "None",
            "AE": "None",
            "Other": "Most common genetic aberrations were TP53 (53.7%), KRAS (29.6%), and ARID1A (16.7%) alterations. TP53 mutations were associated with poor OS (p=0.001) and PFS (p=0.005).",
            "Conclusion": "GemCis/nab-P showed promising survival outcomes in advanced BTC. Patients with TP53 mutations or high tumoral levels of VSIG4, ITGA3, and CCL8 showed a poor prognosis after GemCis/nab-P treatment."
        },
        {
            "Title": "Clinical outcomes from a phase I clinical trial of a novel oncolytic virus VG161 in patients with hepatocellular carcinoma (HCC) refractory after 2 prior lines of therapy including checkpoint inhibitors (CPI)",
            "Indication": "Hepatocellular carcinoma (HCC) refractory after 2 prior lines of therapy",
            "Drug": "VG161",
            "PFS": "2.90 months (95% CI: 1.85-3.95)",
            "OS": "9.40 months (95% CI: 0.47-18.33)",
            "ORR": "17.14% (6/35)",
            "AE": "Most common Treatment-Related Adverse Event (TRAE) was fever, resolved with symptomatic treatment.",
            "Other": "Disease Control Rate (DCR) was 60.00% (21/35). Statistically significant OS prolongation was observed in a subgroup of 22 HCC patients who progressed after receiving at least 3 months of CPI (PreCPI.3m) compared to those who received CPI for \u22643 months (PreCPI\u22643m).",
            "Conclusion": "VG161 was well tolerated and demonstrated comparable ORR and DCR with other CPIs data in 2L HCC. VG161 also prolonged survival in a subgroup of patients identified by prior treatment or gene signature, supporting further studies with enrichment of patients who may benefit from the treatment."
        },
        {
            "Title": "Efficacy and safety of envafolimab plus lenvatinib combined with TACE in initially unresectable hepatocellular carcinoma: An open-label, single-arm, phase II study.",
            "Indication": "Unresectable hepatocellular carcinoma (uHCC)",
            "Drug": "Envafolimab plus lenvatinib and TACE",
            "PFS": "8.78 months (95% CI, 5.23 to not available (NA))",
            "OS": "Immature with 1-year OS of 88.9%",
            "ORR": "47.4% (95% CI, 31.0% to 64.2%) per RECIST 1.1, 78.9% per mRECIST",
            "AE": "97.4% overall incidences of AEs of any grade, 52.6% Grade \u22653 treatment-related adverse events (TRAEs)",
            "Other": "DCR: 78.9% (95% CI, 62.7% to 90.5%) per RECIST 1.1, 78.9% per mRECIST; Median DoR: 6.7 months per RECIST 1.1, 7.7 months per mRECIST; Conversion success rate: 44.7%; R0 resection rate: 100%; Pathological complete response (pCR) rate: 31.3%; Major pathological response (MPR) rate: 56.3%",
            "Conclusion": "Envafolimab combined with lenvatinib and TACE yielded promising survival outcomes and conversion rate with a manageable safety profile in uHCC, representing a potential and feasible conversion therapy regimen for this population."
        },
        {
            "Title": "Association between beta-catenin (CTNNB1) mutations and clinical outcomes of pembrolizumab in advanced hepatocellular carcinoma (aHCC): Exploratory analyses from KEYNOTE-240.",
            "Indication": "Advanced hepatocellular carcinoma (aHCC)",
            "Drug": "Pembrolizumab",
            "PFS": "No association observed",
            "OS": "No association observed",
            "ORR": "No association observed",
            "AE": "None",
            "Other": "Concordance between ctDNA- and WES-derived CTNNB1 mutation status was high, with agreement in 95% of the evaluable samples.",
            "Conclusion": "ctDNA-derived CTNNB1 mutation status was highly concordant with WES-derived CTNNB1 mutation status in patients with aHCC. There was no association observed between ctDNA-derived CTNNB1 mutation status and clinical outcomes with pembrolizumab or placebo in patients with aHCC enrolled in KEYNOTE-240."
        },
        {
            "Title": "Camrelizumab plus rivoceranib vs sorafenib as first-line therapy for unresectable hepatocellular carcinoma (uHCC): Final overall survival analysis of the phase 3 CARES-310 study.",
            "Indication": "Unresectable hepatocellular carcinoma (uHCC)",
            "Drug": "Camrelizumab plus rivoceranib vs sorafenib",
            "PFS": "5.6 months (95% CI, 5.5-7.4) for C+R, 3.7 months (95% CI, 3.1-3.7) for S",
            "OS": "23.8 months (95% CI, 20.6-27.2) for C+R, 15.2 months (95% CI, 13.2-18.5) for S",
            "ORR": "26.8% (95% CI, 21.7-32.5) for C+R, 5.9% (95% CI, 3.4-9.4) for S",
            "AE": "None",
            "Other": "Median DoR: 17.5 months (95% CI, 9.3-NR) for C+R, 9.2 months (95% CI, 5.3-NR) for S",
            "Conclusion": "At the protocol-specified final analysis, C+R continued to show clinically meaningful survival improvement compared with S, with manageable safety. The extended follow-up further confirmed the favorable benefit-to-risk profile of C+R, supporting it as a new first-line treatment option for uHCC."
        },
        {
            "Title": "Atezolizumab + bevacizumab versus lenvatinib as first-line systemic therapy for treatment of hepatocellular carcinoma in a real-world population: Outcomes from the HCC CHORD database.",
            "Indication": "Hepatocellular carcinoma (HCC)",
            "Drug": "Atezolizumab + bevacizumab vs lenvatinib",
            "PFS": "6.9 months in the overall population, 8.3 months for AB, 6.3 months for LEN",
            "OS": "15.4 months in the overall population, 19.7 months (95% CI: 14.5-NR) for AB, 14.4 months (95% CI: 12.2-17.1) for LEN",
            "ORR": "29% overall, 31% for AB, 28% for LEN",
            "AE": "None",
            "Other": "None",
            "Conclusion": "This study provided real-world efficacy outcomes for AB directly compared to LEN in the first-line treatment of HCC in a Western country. AB was correlated with superior OS compared to LEN, but PFS and RR appear to be similar."
        },
        {
            "Title": "Updated results from venous infusion chemotherapy (VIC) plus apatinib and camrelizumab for hepatocellular carcinoma (HCC) in CNLC stage III: A prospective, single-arm, phase II trial.",
            "Indication": "Hepatocellular carcinoma (HCC) in CNLC stage III",
            "Drug": "Venous infusion chemotherapy (VIC) plus apatinib and camrelizumab",
            "PFS": "Median PFS: 6.9 months (HR, 0.51 [0.37-0.69], p<0.001)",
            "OS": "Median OS: 15.6 months (HR, 0.53 [0.38-0.74], p<0.001)",
            "ORR": "60.0% (21/35, 95% CI, 42.9-77.1) per RECIST 1.1, 68.6% (24/35, 95% CI, 52.4-84.8) per mRECIST",
            "AE": "Grade 3-4 treatment-related AEs: 40% (diarrhea 23.9%, thrombocytopenia 25.9%, hypertension 11.1%)",
            "Other": "Disease control rate: 97.1% (95% CI, 91.3-102.9) per RECIST 1.1 or mRECIST. Estimated 6- and 9-month PFS rate: 93.6% (95% CI, 76.8-98.4) and 81.5% (95% CI, 60.5-92.0). 6- and 9-month OS rate: 100% and 95.7% (95% CI, 72.9-99.4).",
            "Conclusion": "The new VIC-triplet treatment system demonstrated acceptable safety and the same anti-tumor effects as HAIC plus antiangiogenic drugs and PD-(L)1 inhibitors, especially in those with VP4. These findings provided new insights for treatment of advanced HCC and a relevant clinical phase 3 study was underway."
        },
        {
            "Title": "Sorafenib plus hepatic arterial infusion of oxaliplatin and fluorouracil vs sorafenib plus transarterial chemoembolization for advanced hepatocellular carcinoma: A biomolecular exploratory, randomized, phase III trial (SHATA-001).",
            "Indication": "Advanced hepatocellular carcinoma (HCC)",
            "Drug": "Sorafenib plus hepatic arterial infusion chemotherapy (SoraHAIC) vs Sorafenib plus transarterial chemoembolization (SoraTACE)",
            "PFS": "Median PFS: 6.9 months (SoraHAIC) vs 4.2 months (SoraTACE) (HR, 0.51 [0.37-0.69], p<0.001)",
            "OS": "Median OS: 15.6 months (SoraHAIC) vs 11.2 months (SoraTACE) (HR, 0.53 [0.38-0.74], p<0.001)",
            "ORR": "None",
            "AE": "Grade 3-4 elevated alanine aminotransferase: 5% (SoraHAIC) vs 21.7% (SoraTACE) (p<0.001); elevated aspartate aminotransferase: 16.4% (SoraHAIC) vs 35% (SoraTACE) (p<0.001); Serious adverse events: 39.3% (SoraHAIC) vs 56.7% (SoraTACE) (p=0.023)",
            "Other": "None",
            "Conclusion": "This trial demonstrated SoraHAIC significantly improved OS over SoraTACE in patients with advanced HCC. However, patients with high FAD activity might have a better tumor response and survival benefit to SoraTACE than SoraHAIC."
        },
        {
            "Title": "A phase 2 study of BOLD-100 in combination with FOLFOX chemotherapy in patients with pretreated advanced biliary tract cancer: Efficacy and safety analysis (BOLD-100-001).",
            "Indication": "Advanced biliary tract cancer (BTC)",
            "Drug": "BOLD-100 in combination with FOLFOX",
            "PFS": "Median PFS: 6.0 months (95% credible interval [CI] 3.8, 10)",
            "OS": "Median OS: 7.3 months (95% CI 4.5, 13)",
            "ORR": "6% (95% CI 1, 23)",
            "AE": "Most common treatment-related AEs: neutrophil count decreased (46%), nausea (36%), fatigue (32%), peripheral sensory neuropathy (27%), pyrexia (27%). Grade 3/4 neutrophil count decreased (41%).",
            "Other": "Disease Control Rate (DCR): 83% (95% CI 62, 95)",
            "Conclusion": "BOLD-100 combined with FOLFOX is an active and well-tolerated treatment regimen in Stage IV BTC. There were no new safety signals detected. The mPFS, mOS, ORR, and DCR data in this advanced BTC cancer population indicate a treatment combination worthy of further study for this difficult to treat cancer."
        },
        {
            "Title": "Phase 1b/2 results of a multicenter, randomized phase 1b/2 study of gemcitabine and cisplatin +/- devimistat as first-line therapy for patients with advanced biliary tract cancer (BilT-04).",
            "Indication": "Advanced biliary tract cancer (BTC)",
            "Drug": "Gemcitabine and cisplatin +/- devimistat",
            "PFS": "Median PFS: 8.7 months (GCD) vs 8.6 months (GC)",
            "OS": "Median OS: 16.3 months (GCD) vs 22.2 months (GC)",
            "ORR": "30% (GCD) vs 46.2% (GC)",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The combination of GCD did not meet the primary endpoint of increased ORR. GCD was well tolerated, however, and demonstrated encouraging PFS and especially OS as compared to historical estimates in this Ph1b/2 trial. Targeted exome/transcriptome and plasma metabolome analyses are ongoing."
        },
        {
            "Title": "Tislelizumab plus intensity modulated radiotherapy in resectable hepatocellular carcinoma with macrovascular invasion: A prospective, single-arm, phase II trial.",
            "Indication": "Hepatocellular carcinoma with macrovascular invasion",
            "Drug": "Tislelizumab and intensity modulated radiotherapy (IMRT)",
            "PFS": "None",
            "OS": "Median OS was 18.7 months",
            "ORR": "ORR was 30.0% (RECIST 1.1), DCR was 36.6%",
            "AE": "Most common events of any grade were increased thyroid stimulating hormone (TSH) (83.3%), leukopenia (60.0%), and anemia (53.3%). 4 (13.3%) pts experienced grade 3 TRAEs (leukopenia, increased TSH, and increased alkaline phosphatase)",
            "Other": "15 (50%) underwent radical surgical resection followed by adjuvant tislelizumab, 10 (66.7%) achieved a significant pathological response. Median RFS was not reached with a median follow-up of 21.77 months (95% CI 12.50-31.03) post-surgery. 7 (46.7%) pts experienced tumor relapse with 1-year RFS rate was 60%. AFP reduction after perioperative therapy was found to be prognostic of RFS.",
            "Conclusion": "The effectiveness and tolerability of tislelizumab plus radiotherapy as perioperative therapy for resectable HCC with MVI is worth further exploration."
        },
        {
            "Title": "Adjuvant S-1 vs. observation for resected biliary tract cancer: 5-year follow-up of the JCOG1202/ASCOT.",
            "Indication": "Resected biliary tract cancer",
            "Drug": "S-1",
            "PFS": "5-year RFS was 45.9% in the observation group and 53.6% in the S-1 group (unstratified HR 0.797, 95% CI 0.618-1.028, stratified HR 0.761, 95% CI 0.589-0.984 in the sensitivity analysis)",
            "OS": "5-year OS was 52.2% in the observation group and 64.1% in the S-1 group (stratified HR 0.723, 95% CI 0.551-0.948; one-sided p=0.019)",
            "ORR": "None",
            "AE": "None",
            "Other": "Most prespecified subgroup analyses (age, primary site, stage, lymph node metastases, types of resections, and serum CA19-9 levels) revealed favorable OS and RFS for the S-1 group.",
            "Conclusion": "Adjuvant S-1 therapy maintained its survival benefit in a 5-year long-term follow-up, with clinically meaningful improvement in RFS, suggesting that it could be a standard of care for BTC."
        },
        {
            "Title": "Perioperative pembrolizumab and lenvatinib for resectable hepatocellular carcinoma: A single-arm, multi-center, phase II trial (NeoLEAP-HCC).",
            "Indication": "Resectable hepatocellular carcinoma",
            "Drug": "Pembrolizumab and lenvatinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "ORR before surgery based on RECIST v1.1 and mRECIST were 11.9% and 47.6%, respectively.",
            "AE": "TRAE of any grade during the neoadjuvant period occurred in 32 (74.4%) patients and grade 3 TRAEs occurred in 6 (14.0%) patients. No grade 4-5 TRAEs occurred. The most common TRAEs of any grade during the neoadjuvant period were hypertension (10, 23.3%), stomatitis (5, 11.6%), and rash (4, 9.3%).",
            "Other": "Forty (93.0%) patients completed neoadjuvant therapy and underwent R0 resection, of which 37 patients were pathologically confirmed HCC, 2 patients were combined HCC and intrahepatic cholangiocarcinoma (ICC), and 1 patient was ICC. Three patients did not proceed with planned surgery (1 patient with disease progression, 1 with tumor rupture, and 1 refused surgery). Of the 37 patients with resected HCC, 14 (37.8%) patients achieved MPR, of which 3 (8.1%) patients achieved pCR. Eleven (29.7%) patients had significant tumor necrosis (necrosis tumor cells \u226550% of tumor bed). The pathological response of all resected HCC lesions was evaluated in 39 patients. MPR was achieved in 15 (38.5%) patients, of which 3 (7.7%) patients achieved pCR. Twelve (30.8%) patients had significant tumor necrosis.",
            "Conclusion": "Pembrolizumab plus lenvatinib demonstrated promising anti-tumor efficacy with manageable toxicity in the neoadjuvant setting for resectable HCC."
        },
        {
            "Title": "Efficacy and safety of erdafitinib in patients with advanced or metastatic cholangiocarcinoma and FGFR alterations: Pooled analysis of RAGNAR and LUC2001 studies.",
            "Indication": "Advanced or metastatic cholangiocarcinoma with FGFR alterations",
            "Drug": "Erdafitinib",
            "PFS": "8.5 months (95% CI: 6.83, 9.72)",
            "OS": "18.1 months (95% CI: 13.40, 24.28)",
            "ORR": "55%",
            "AE": "Hyperphosphatemia (83%), stomatitis (72%), diarrhea (68%), dry mouth (51%), palmar-plantar erythrodysesthesia (51%); 42% had serious TEAEs and 12% discontinued treatment due to an AE.",
            "Other": "No treatment-related deaths were observed.",
            "Conclusion": "Data from pooled analysis of the RAGNAR and LUC2001 studies confirm robust efficacy of erdafitinib in a diverse population of patients with advanced or metastatic cholangiocarcinoma and prespecified FGFR fusions or mutations. Safety data were consistent with the erdafitinib safety profile."
        },
        {
            "Title": "Safety analysis by treatment periods from EMERALD-1: A phase 3, randomized, placebo-controlled study of transarterial chemoembolization with durvalumab with/without bevacizumab in participants with embolization-eligible unresectable hepatocellular carcinoma.",
            "Indication": "Embolization-eligible unresectable hepatocellular carcinoma",
            "Drug": "Durvalumab with/without bevacizumab",
            "PFS": "15.0 months (D+B+TACE) vs 8.2 months (placebo+TACE); HR 0.77; 95% CI 0.61\u20130.98; p=0.032",
            "OS": "None",
            "ORR": "None",
            "AE": "D+TACE: 74.6%, D+B+TACE: 72.0%, Placebo+TACE: 74.0% in the D-T period; D+TACE: 68.9%, D+B+TACE: 76.2%, Placebo+TACE: 66.0% in the D-B period",
            "Other": "Manageable safety profile across the D-T and D-B periods, consistent with the individual agents and underlying disease.",
            "Conclusion": "D+B+TACE had a manageable safety profile across the D-T and D-B periods, consistent with the individual agents and underlying disease. These data further support D+B+TACE as a new potential standard of care in embolization-eligible uHCC."
        },
        {
            "Title": "PD-1 inhibitor (sintilimab) and lenvatinib plus TACE-HAIC as conversion therapy for initially unresectable HCC: A single-arm, phase 2 clinical trial (PLATIC).",
            "Indication": "Initially unresectable hepatocellular carcinoma",
            "Drug": "Sintilimab and lenvatinib plus TACE-HAIC",
            "PFS": "14.3 months (95% CI, 11.7 to 21.9)",
            "OS": NaN,
            "ORR": "77.2% per mRECIST and 42.1% per RECIST 1.1 criteria",
            "AE": "Grade 3/4 TRAEs reported in 64.9% of patients, with the most frequent being increased gamma-glutamyltransferase (36.8%), followed by abdominal pain (19.3%).",
            "Other": "Among 44 successful conversion patients, 29.5% obtained a pathological complete response, with an mPFS of 15.3 months (95% CI, 11.9 to NA) and 12-month PFS rate of 59.4% (95% CI, 45.4 to 77.9).",
            "Conclusion": "Sintilimab plus lenvatinib and TACE-HAIC provided a promising rate of conversion to resection in patients with initially unresectable HCC, with acceptable TRAEs."
        },
        {
            "Title": "Overall survival outcomes of phase II GECCOR-GB trial, a multicentre trial of adjuvant gemcitabine plus cisplatin (GC) and capecitabine with concurrent capecitabine radiotherapy (Cape-RT) for operated gallbladder cancer (GBC).",
            "Indication": "Operated gallbladder cancer",
            "Drug": "Gemcitabine plus cisplatin (GC) and capecitabine with concurrent capecitabine radiotherapy (Cape-RT)",
            "PFS": "None",
            "OS": "3-year OS: 75% in the GC arm and 73% in the Cape-RT arm; 3-year OS in stage II patients: 91.3% in the GC arm and 91.9% in the Cape-RT arm; Median OS in stage III patients: 39.1 months (GC arm) vs 23.2 months (Cape-RT arm)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Adjuvant GC arm patients, especially those in stage III, had encouraging OS at 3 years, while both GC and adjuvant Cape-RT arms had excellent 3-year OS, reaching more than 90% in patients with stage II-operated GBC. Further studies are needed to compare and validate these findings for GBC patients."
        },
        {
            "Title": "Utilization of tumor-informed circulating tumor DNA in detecting minimal residual disease and guiding adjuvant therapy in liver cancer.",
            "Indication": "Hepatocellular carcinoma (HCC)",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Preoperative ctDNA concentration was elevated in patients who experienced tumor relapse after surgical treatment compared to those who didn\u2019t relapse. Positive postoperative ctDNA status was associated with a higher recurrence risk (HR=11.5, P<0.001). Longitudinal ctDNA was significantly associated with a higher recurrence risk (HR=69.3, P<0.001). The area under the curve of longitudinal ctDNA for predicting recurrence was 0.956, with a sensitivity of 93.3% and specificity of 97.8%. Patients with positive longitudinal ctDNA experienced benefits from adjuvant therapy (HR=0.4, P=0.019). A decrease or stability of ctDNA after adjuvant therapy indicated treatment response and delayed clinical relapse (HR=0.3, P=0.00012).",
            "Conclusion": "Tumor-informed ctDNA proves to be a reliable indicator for MRD detection after surgical treatment for HCC, enabling the prediction and anticipation of recurrence before clinical manifestation. It plays a crucial role in guiding the application of adjuvant therapy and assessing treatment efficacy, thereby facilitating postoperative management and enhancing the precision of medical interventions."
        },
        {
            "Title": "Cabozantinib plus atezolizumab in patients with advanced and progressive neuroendocrine neoplasms (NENs): A prospective multi-cohort basket phase II trial (CABATEN / GETNE-T1914).",
            "Indication": "Advanced and progressive neuroendocrine neoplasms (NENs)",
            "Drug": "Cabozantinib plus atezolizumab",
            "PFS": "LungNET: 8.4 months [7.7-NR], PPGL: 8.4 months [5.7-NR], GEPNET: 13 months [11.2-NR], G3EP-NEN: 2.7 months [2.6-NR]",
            "OS": "None",
            "ORR": "PPGL: 15.4% (95% CI: 1.9-45.5), GEPNET: 16.7% (95% CI: 4.7-37.4), LungNET and G3EP-NEN: No responses",
            "AE": "Cabozantinib was reduced to 20mg/day in 58.2% of patients. Treatment was interrupted due to AE\u2019s in 80.0% and 40.0% of patients respectively.",
            "Other": "Duration of response (DoR) were 12.2 months (5.5-19.0) in PPGL and 15.8 months (10.6-20.2) in GEPNET cohorts.",
            "Conclusion": "Cabozantinib and atezolizumab showed limited activity but achieved prolonged disease control in a small group of patients with PPGL and GEPNET but not in LungNET and G3EP-NEN, regardless of the relative dose intensity of cabozantinib."
        },
        {
            "Title": "Safety, efficacy, and PKPD of 23ME-00610, a first-in-class anti-CD200R1 antibody, in patients with advanced neuroendocrine cancers: Results from a multi-center multi-country phase 1/2a expansion cohort.",
            "Indication": "Advanced neuroendocrine cancers",
            "Drug": "23ME-00610",
            "PFS": "2.1 months (median potential follow-up time was 6.3 months)",
            "OS": "None",
            "ORR": "Stable disease rate was 46.7% (N=7)",
            "AE": "At least 1 treatment emergent adverse event (TEAE) was reported by all patients (N=16). Related TEAEs occurred in 8 patients (50%); all were G1/G2, and the most common were maculopapular rash (18.8%), pruritus (18.8%), nausea (12.5%), and fatigue (12.5%). Immune related TEAEs were G1 (18.8%) and included maculopapular rash (18.8%) and pruritus (12.5%). No G4/G5 or TEAEs leading to 23ME-00610 discontinuation were reported.",
            "Other": "1400 mg dose resulted in full peripheral saturation of CD200R1 and minimal treatment-emergent ADA.",
            "Conclusion": "23ME-00610 continues to show encouraging PKPD, safety, and disease control in a subset of unselected patients with advanced neuroendocrine cancers. Exploratory biomarker data warrant additional analyses to examine the potential for biomarker stratification on disease outcomes. Phase 2a expansion trials of 23ME-00610 utilizing retrospective biomarker analysis are ongoing in multiple indications."
        },
        {
            "Title": "The clinical utility of plasma cell-free DNA (cfDNA) in NET-02: Randomised, phase II trial of liposomal irinotecan (nal-IRI)/5-fluorouracil (5-FU)/folinic acid or docetaxel as second-line (2L) therapy in patients (pts) with progressive poorly differentiated extra-pulmonary neuroendocrine carcinoma (PD-EP-NEC).",
            "Indication": "Progressive poorly differentiated extra-pulmonary neuroendocrine carcinoma (PD-EP-NEC)",
            "Drug": "nal-IRI/5-FU/folinic acid or docetaxel",
            "PFS": "6-month PFS rate: nal-IRI/5-FU (OR 1.87, 95% CI 1.15-3.65, P=.02), docetaxel (OR 0.85, 95% CI 0.39-1.49, P=.61)",
            "OS": "T0 TF was negatively prognostic for OS (HR 1.17, 95% CI 1.00-1.37, P=.044). Median OS split by TF: low (TF \u2264 8.6%) 10.2 months (95% CI 4.1-NA), medium (8.6 < TF \u2264 24.3%) 6.9 months (95% CI 3.4-14.8), high (TF > 24.3%) 3.7 months (95% CI 2.8-NA).",
            "ORR": "None",
            "AE": "None",
            "Other": "TF correlated with the presence of liver metastases (Wilcoxon Rank Sum P=.009), but not with ECOG PS (P=.14), sex (P=.10), Ki-67 (P=.39) or age (Spearman rank correlation P=.45). Longitudinal copy number and methylation cfDNA analysis is ongoing.",
            "Conclusion": "These results suggest that it may be possible to stratify prognosis based on the amount of baseline TF in patients with progressive PD-EP-NEC, and may also identify patients who would benefit most from the nal-IRI combination."
        },
        {
            "Title": "Safety and time to response of [177Lu]Lu-DOTATATE in patients with newly diagnosed advanced grade 2 and grade 3, well-differentiated gastroenteropancreatic neuroendocrine tumors: Sub-analysis of the phase 3 randomized NETTER-2 study.",
            "Indication": "Advanced grade 2 and grade 3, well-differentiated gastroenteropancreatic neuroendocrine tumors (GEP-NETs)",
            "Drug": "[177Lu]Lu-DOTATATE",
            "PFS": "None",
            "OS": "None",
            "ORR": "43% in the 177Lu-DOTATATE arm",
            "AE": "Grade 3/4 decreases in lymphocytes (38.1% vs 2.7%), leukocytes (4.1% vs 0), neutrophils (3.4% vs 0), platelets (2.0% vs 0) and hemoglobin (1.4% vs 2.7%) were observed in 177Lu-DOTATATE (n=147) and control (n=73) arms, respectively. Median time to first occurrence of hematologic toxicities was 4.4 months among pts with immediate hematotoxicities. These events were transient and manageable. There was no notable difference in infection rates between treatment arms (31.3% 177Lu-DOTATATE and 27.4% control). One case of myelodysplastic syndrome was observed (177Lu-DOTATATE arm ~14 months after first cycle). Five fatal AEs occurred during the randomized treatment period; all considered unrelated to treatment and attributed to GEP-NET progression. AEs leading to 177Lu-DOTATATE dose interruption, reduction and discontinuation occurred in 14 (9.5%), 2 (1.4%) and 3 (2.0%) pts, respectively.",
            "Other": "Median TTR (Q1, Q3) was 5.7 months (4.1, 8.3). Most responses occurred during the 4-cycle treatment with 177Lu-DOTATATE.",
            "Conclusion": "In pts with advanced G2 and G3 (Ki67 \u2265 10% and \u2264 55%) GEP-NETs treated with 177Lu-DOTATATE at 1L the most common hematologic toxicity was lymphopenia, but infection rates were similar vs control. With a median TTR of 5.7 months, most responses to 177Lu-DOTATATE occurred during scheduled treatment. Overall, the study confirmed the safety profile of 177Lu-DOTATATE and supports its use at 1L for this pt population."
        },
        {
            "Title": "OPTIMIZE-1 primary analysis: Safety, efficacy and biomarker results of a phase 1b/2 study combining CD40 agonist mitazalimab with mFOLFIRINOX in previously untreated metastatic pancreatic ductal adenocarcinoma (mPDAC).",
            "Indication": "Metastatic pancreatic ductal adenocarcinoma (mPDAC)",
            "Drug": "Mitazalimab with mFOLFIRINOX",
            "PFS": "Median PFS: 7.4 months",
            "OS": "Median OS: 14.3 months",
            "ORR": "40.4%",
            "AE": "Grade \u22653 AEs: Neutropenia (25.7%), anemia (11.4%), hypokalemia (15.7%), thrombocytopenia (11.4%)",
            "Other": "Duration of Response (DoR): 12.5 months",
            "Conclusion": "Mitazalimab in combination with mFOLFIRINOX is a feasible regimen with a manageable safety profile. The primary endpoint was met; KRAS G12V and R mutations were associated with better efficacy. Given the promising DoR linked with survival benefit in previously untreated mPDAC, these results merit continued development of mitazalimab in a confirmatory phase 3 study."
        },
        {
            "Title": "Penpulimab combined with anlotinib and nab-paclitaxel plus gemcitabine (PAAG) as first-line treatment for metastatic pancreatic cancer: A prospective, multicenter, single-arm, phase 2 study.",
            "Indication": "Metastatic pancreatic cancer (mPC)",
            "Drug": "Penpulimab combined with anlotinib and nab-paclitaxel plus gemcitabine (PAAG)",
            "PFS": "Median PFS: 8.8 months",
            "OS": "Median OS: 13.7 months",
            "ORR": "50%",
            "AE": "Grade 3 TRAEs: 36.4% (anemia, leukocytopenia, fatigue, rash)",
            "Other": "Disease Control Rate (DCR): 95.5%",
            "Conclusion": "PAAG regimen as first-line treatment was demonstrated to be tolerable, with promising anti-tumor activity in mPC. The identified biomolecular markers revealed potential to guide precision treatment of PAAG for mPC."
        },
        {
            "Title": "NALIRIFOX versus nab-paclitaxel and gemcitabine in treatment-na\u00efve patients with metastatic pancreatic ductal adenocarcinoma (mPDAC): Updated overall survival analysis with 29-month follow-up of NAPOLI 3.",
            "Indication": "Metastatic pancreatic ductal adenocarcinoma (mPDAC)",
            "Drug": "NALIRIFOX versus nab-paclitaxel and gemcitabine",
            "PFS": "None",
            "OS": "Median OS: 11.1 months (NALIRIFOX) vs. 9.2 months (Gem+NabP)",
            "ORR": "None",
            "AE": "None",
            "Other": "12-month survival rates: 45.6% (NALIRIFOX) vs. 39.6% (Gem+NabP); 18-month survival rates: 26.6% (NALIRIFOX) vs. 20.0% (Gem+NabP)",
            "Conclusion": "In this 29-month follow-up of NAPOLI 3, NALIRIFOX continued to demonstrate improved OS compared with Gem+NabP, with 11 patients still receiving the NALIRIFOX regimen. These data confirm NALIRIFOX as a new possible standard of care and reference regimen for the first-line treatment of patients with mPDAC."
        },
        {
            "Title": "Clinical and translational results from REVOLUTION cohorts A (nivolumab + ipilimumab + chemotherapy) and B (hydroxychloroquine + ipilimumab + chemotherapy) in patients with previously untreated metastatic pancreatic adenocarcinoma.",
            "Indication": "Metastatic pancreatic adenocarcinoma (mPDAC)",
            "Drug": "Cohort A: nivolumab + ipilimumab + chemotherapy; Cohort B: hydroxychloroquine + ipilimumab + chemotherapy",
            "PFS": "Cohort A: 9.0 months [3.7-9.7]; Cohort B: 7.9 months [4.7-8.8]",
            "OS": "Cohort A: 17.4 months [6.2-NE]; Cohort B: 15.8 months [6.4-NE]",
            "ORR": "Cohort A: 33% [12-62]; Cohort B: 40% [16-68]",
            "AE": "Cohort A: 60% Grade 3-4 TRAEs (most common: decreased neutrophil count); Cohort B: 53% Grade 3-4 TRAEs (most common: anemia, decreased neutrophil count)",
            "Other": "Cohort A: Increased activated/proliferating CD4 T cells, CD8 T cells, Tregs, and soluble PD-1 in circulation; Cohort B: Increases in activated/proliferating CD4 T cells and signs of autophagy inhibition.",
            "Conclusion": "REVOLUTION cohorts A and B demonstrated antitumor activity in pts with mPDAC. Cohort B treatment, which included hydroxychloroquine, incurred more early discontinuations. As designed, neither cohort will advance to Stage 2, highlighting the need for further tailored therapeutic approaches in mPDAC."
        },
        {
            "Title": "Phase 1 study of anti\u2013immunoglobulin-like transcript 3 (ILT3) monoclonal antibody MK-0482 + pembrolizumab + gemcitabine + nab-paclitaxel (Gem/Nab-P) in patients (pts) with metastatic pancreatic ductal adenocarcinoma (mPDAC).",
            "Indication": "Metastatic pancreatic ductal adenocarcinoma (mPDAC)",
            "Drug": "MK-0482 + pembrolizumab + gemcitabine + nab-paclitaxel",
            "PFS": "8.5 months (95% CI, 5.7-12.3)",
            "OS": "15.6 months (10.5-22.1)",
            "ORR": "43% (16/37; 95% CI, 27.1-60.5)",
            "AE": "97% treatment-related AEs (TRAEs); 73% Grade 3 or 4 TRAEs (most frequent: decreased neutrophil count, anemia, neutropenia); 32% immune-mediated AEs (most frequent: hypothyroidism); 14% Grade 3 immune-mediated AEs (hepatitis, severe skin reactions, colitis); no Grade 4 or 5 events.",
            "Other": "DCR: 84% (31/37; 68.0-93.8); Median DOR: 8.5 months (range, 3.7-17.8+); 12-mo PFS and OS rates: 35% and 60%, respectively.",
            "Conclusion": "MK-0482 + pembrolizumab + Gem/Nab-P showed a manageable AE profile in patients with treatment-naive mPDAC, which was generally consistent with the combined safety profiles of pembrolizumab-based immunotherapy and Gem/Nab-P, respectively. The 4-drug combination showed increased clinical efficacy compared with that historically observed for chemotherapy regimens. Further evaluation is needed to confirm the clinical activities observed."
        },
        {
            "Title": "Avutometinib/defactinib and gemcitabine/nab-paclitaxel combination in first-line metastatic pancreatic ductal adenocarcinoma: Initial safety and efficacy of phase 1b/2 study (RAMP 205).",
            "Indication": "First-line metastatic pancreatic ductal adenocarcinoma (mPDAC)",
            "Drug": "Avutometinib/defactinib + gemcitabine/nab-paclitaxel",
            "PFS": "None",
            "OS": "None",
            "ORR": "75% partial responses (3 confirmed at data cutoff)",
            "AE": "Most frequently reported TRAEs: nausea (50%), alopecia (44%), fatigue (39%), maculo-papular rash (33%), peripheral edema (28%); Most common Grade \u22653 TRAEs: alanine aminotransferase increase (17%), neutropenia (17%), peripheral edema (11%), anemia (11%), sepsis (11%); Four patients experienced serious AEs (SAEs).",
            "Other": "All efficacy evaluable pts (n=8) experienced a reduction in target lesions (100% disease control rate); All efficacy evaluable pts with elevated CA19-9 at baseline (n=7) had a \u226560% CA19-9 reduction from baseline.",
            "Conclusion": "Avutometinib/defactinib and GnP are combinable and show notable preliminary efficacy in first-line metastatic PDAC based on RECIST v.1.1 criteria and CA19-9 levels. No DLTs were reported across the 4 dosing cohorts/schedules and safety signals were consistent with previous clinical data. Updated safety and efficacy will be reported."
        },
        {
            "Title": "A randomised phase 2 study of liposomal irinotecan in combination with 5-fluorouracil/leucovorin and oxaliplatin versus nab-paclitaxel plus gemcitabine in unresectable locally advanced or metastatic pancreatic cancer.",
            "Indication": "Unresectable locally advanced or metastatic pancreatic cancer",
            "Drug": "Liposomal irinotecan (HE072), 5-fluorouracil, leucovorin, oxaliplatin, nab-paclitaxel, gemcitabine",
            "PFS": "7.6 months (95% CI 5.49-9.17) with NALIRIFOX vs 3.7 months (95% CI 3.15-5.06) with AG (HR 0.55; 95% CI 0.34-0.87; p=0.0104)",
            "OS": "13.4 months (95% CI 9.89-NE) with NALIRIFOX vs 8.8 months (95% CI 6.87-12.16) with AG (HR 0.66; 95% CI 0.39-1.11; p=0.1125)",
            "ORR": "None",
            "AE": "Grade 3 or higher TRAE in 66.7% of patients receiving NALIRIFOX and 76.9% of patients receiving AG",
            "Other": "None",
            "Conclusion": "The study met the primary endpoint of PFS demonstrating statistically significant and clinically meaningful improvements in PFS and with a trend of OS benefit for NALIRIFOX over AG. The safety profile was consistent with the known risks of the individual toxicity. This study is consistent with the existing NAPOLI3 study."
        },
        {
            "Title": "A randomised phase 2 study of liposomal irinotecan in combination with 5-FG-M108 plus nab-paclitaxel and gemcitabine (AG) as first-line (1L) treatment for patients with Claudin-18.2 (CLDN18.2) positive locally advanced unresectable or metastatic pancreatic cancer (PC): Preliminary results from the phase 1b study.",
            "Indication": "Claudin-18.2 positive locally advanced unresectable or metastatic pancreatic cancer",
            "Drug": "FG-M108, gemcitabine, nab-paclitaxel",
            "PFS": "Not reached yet",
            "OS": "Not reached yet",
            "ORR": "50.0% (7/14)",
            "AE": "Most common FG-M108 related AEs: nausea (43.5%), emesis (30.4%), neutropenia (21.7%), anemia (21.7%). Most common grade \u22653 AEs: neutropenia (26.1%). FG-M108 related serious AEs in 29.0% of patients. No FG-M108 related grade 4 or 5 AEs and no treatment-related deaths reported.",
            "Other": "DCR was 100% (14/14)",
            "Conclusion": "FG-M108 plus AG showed excellent safety profile and promising clinical efficacy as 1L treatment for patients with CLDN18.2+ locally advanced unresectable or metastatic PC. The safety and efficacy, especially PFS and OS, of FG-M108 plus AG treatment will be further evaluated."
        },
        {
            "Title": "Interim results of the randomized phase 2 cohort of study FW-2020-01 assessing the efficacy, safety and pharmacodynamics of CM24 in combination with nivolumab and chemotherapy in advanced/metastatic pancreatic cancer.",
            "Indication": "Advanced/metastatic pancreatic cancer",
            "Drug": "CM24, nivolumab, chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "Somatic mismatch repair deficiency in pancreas cancer (PC): Immune checkpoint blockade (ICB) outcomes and exploratory genomic analyses.",
            "Indication": "Pancreas cancer with somatic mismatch repair deficiency",
            "Drug": "Immune checkpoint blockade (ICB)",
            "PFS": "None",
            "OS": "None",
            "ORR": "50% in both cohorts",
            "AE": "None",
            "Other": "Median ICB duration: 27.7 months (95% CI 11.5,-)",
            "Conclusion": "MSI-H arises due to somatic and germline oncogenesis at similar rates in ~1% of PC. MMR methylation analyses may provide further insight into the mechanisms of MSI-H. Orthogonal MS testing key to inform accurate MS status. MiMSI valuable in classifying low-cellularity specimens. Patients with MSI-H PC treated with ICB derive durable benefit, irrespective of germline or somatic etiology."
        },
        {
            "Title": "Primary prophylaxis with pegylated-granulocyte colony-stimulating factor for patients with unresectable pancreatic cancer treated with modified-FOLFIRINOX: A randomized phase II trial.",
            "Indication": "Unresectable pancreatic cancer",
            "Drug": "Pegylated-granulocyte colony-stimulating factor (peg-GCSF)",
            "PFS": "9.0 vs 7.3 months (peg-GCSF vs control), HR not provided",
            "OS": "Not reached for both arms, HR 0.48 for peg-GCSF group",
            "ORR": "None",
            "AE": "Grade 3-4 neutropenia: 2.6% (peg-GCSF) vs 38.5% (control); Febrile neutropenia: 0% (peg-GCSF) vs 12.8% (control)",
            "Other": "Cumulative RDI of mFOLFIRINOX until progression or 8th cycles were similar between two groups with 93%",
            "Conclusion": "Primary prophylaxis with peg-GCSF significantly reduced grade 3-4 neutropenia and febrile neutropenia in patients with locally advanced or metastatic pancreatic cancer treated with mFOLFIRINOX. Further follow-up of survival and quality of life results will be presented."
        },
        {
            "Title": "A phase II, multi-center, open-label study to evaluate the safety and efficacy of YH003 in combination with toripalimab (anti-PD-1 mAb) in patients with unresectable/metastatic pancreatic ductal adenocarcinoma (PDAC).",
            "Indication": "Unresectable/metastatic pancreatic ductal adenocarcinoma (PDAC)",
            "Drug": "YH003 in combination with toripalimab",
            "PFS": "2L+ Cohort: 2.25 months (Caucasians); 1L Cohort: 11.27 months (Caucasians)",
            "OS": "2L+ Cohort: 7.23 months; 1L Cohort: 12.12 months; Caucasians: 9.54 months (2L+), 19.78 months (1L)",
            "ORR": "2L+ Cohort: 10.0% (overall), 7.1% (Caucasians); 1L Cohort: 27.9% (overall), 50% (Caucasians)",
            "AE": "Grade 3 or above adverse events: 57.8% (2L+ Cohort), 74.5% (1L Cohort)",
            "Other": "Disease Control Rate (DCR): 35.0% (2L+ Cohort), 50% (Caucasians in 2L+), 81.4% (1L Cohort), 100% (Caucasians in 1L)",
            "Conclusion": "The results of the phase II study have shown that YH003 plus Toripalimab with/without chemotherapy, as 1L or 2L treatment in patients with u/m PDAC have promising antitumor activity and response durability, especially in the Caucasians subgroups. Both treatment regimens are well tolerated."
        },
        {
            "Title": "Impact on chemotherapy induced peripheral neuropathy of nadunolimab, a first-in-class monoclonal antibody against IL1RAP, in patients with pancreatic cancer, with supportive mouse model data.",
            "Indication": "Pancreatic cancer",
            "Drug": "Nadunolimab in combination with nab-paclitaxel and gemcitabine (GN)",
            "PFS": "Median iPFS: 7.2 months",
            "OS": "Median OS: 13.2 months; 1-year survival: 58%",
            "ORR": "33%",
            "AE": "Grade \u22653 peripheral neuropathy: 1 of 73 patients",
            "Other": "Data from PDAC patients receiving nadunolimab + GN and experiments in preclinical models suggest that targeting IL1RAP by nadunolimab may combine antitumor activity with potent reduction of CIPN.",
            "Conclusion": "Data from PDAC patients receiving nadunolimab + GN and experiments in preclinical models suggest that targeting IL1RAP by nadunolimab may combine antitumor activity with potent reduction of CIPN."
        },
        {
            "Title": "Effect of ctDNA whole-exome sequencing in pancreatic adenocarcinoma on GATA6 expression in advanced pancreatic ductal adenocarcinoma (PDAC)",
            "Indication": "Pancreatic ductal adenocarcinoma (PDAC)",
            "Drug": "Not specified",
            "PFS": "None",
            "OS": "10.6 months (GATA6 High), 8.4 months (GATA6 Low), HR=0.003",
            "ORR": "None",
            "AE": "None",
            "Other": "GATA6 high tumors show statistically significant better OS. GATA6 is a predictive biomarker for response to mFFX.",
            "Conclusion": "GATA6 is a highly prognostic biomarker. GATA6 low tumors should receive 1st line GnP based regimens."
        },
        {
            "Title": "Phase II study of pirfenidone combined with nab-paclitaxel and gemcitabine for patients with metastatic pancreatic cancer",
            "Indication": "Metastatic pancreatic cancer",
            "Drug": "Pirfenidone, nab-paclitaxel, gemcitabine",
            "PFS": "4.9 months (95% CI 3.9-5.9)",
            "OS": "9.1 months (95% CI 7.8-10.4)",
            "ORR": "50.0% (95% CI 33.6%-66.4%)",
            "AE": "Grade 3-4 toxicities in 34.4% of patients, including leukopenia (21.9%), anemia (15.6%), and elevated AST level (12.5%)",
            "Other": "DCR was 93.8% (95% CI 79.9%-98.9%). 56% of patients experienced a decrease in CA19-9 levels of more than 50%.",
            "Conclusion": "Adding PFD to AG showed promising ORR and DCR and an acceptable adverse event profile in patients with metastatic pancreatic cancer."
        },
        {
            "Title": "Integrated genomic and transcriptomic analysis to predict FOLFIRINOX response in metastatic pancreatic ductal adenocarcinoma.",
            "Indication": "Metastatic pancreatic ductal adenocarcinoma (mPDAC)",
            "Drug": "FOLFIRINOX",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "KRAS wild-type tumors showed better survival following FOLFIRINOX treatment (p=0.009). Transcriptome-based subgroup and KRAS status were prognostic for FOLFIRINOX treatment response.",
            "Conclusion": "KRAS wild-type tumors do not fit in the classical\u2013basal-like classification schemes. Both KRAS status and transcriptome-based subgroup were prognostic for FOLFIRINOX treatment response and should be further validated for incorporation in diagnostic practice."
        },
        {
            "Title": "Serial circulating tumor DNA monitoring for predicting treatment response and prognosis in patients with pancreatic cancer.",
            "Indication": "Pancreatic cancer",
            "Drug": "None",
            "PFS": "Higher ctDNA linked to shorter PFS (HR 1.84; 95% CI 1.07 to 3.18, P=0.03)",
            "OS": "Higher ctDNA linked to shorter OS (HR 2.15; 95% CI 1.20 to 3.86, P=0.01)",
            "ORR": "None",
            "AE": "None",
            "Other": "Patients who achieved ctDNA clearance at 8 weeks demonstrated more favorable outcomes in terms of PFS (HR 0.53; 95% CI 0.30 to 0.92, P=0.02) and OS (HR 0.50; 95% CI 0.28 to 0.90, P=0.02).",
            "Conclusion": "Serial ctDNA monitoring holds promise in predicting treatment response and prognosis for unresectable pancreatic cancer patients."
        },
        {
            "Title": "Treatment landscape, outcome and quality of life in patients with pancreatic cancer: IKF642/PARAGON study.",
            "Indication": "Pancreatic ductal adenocarcinoma (PDAC)",
            "Drug": "Various (FFX, NP-G, gemcitabine, NalIRI-5FU)",
            "PFS": "Adjuvant therapy: 16 months; 1st line: 5.6 months; 2nd line: 3.0 months",
            "OS": "Adjuvant therapy: 30 months; 1st line: 10.0 months; 2nd line: 7.7 months",
            "ORR": "None",
            "AE": "None",
            "Other": "Quality of life (QoL) data available for all patients at baseline and throughout the further course of therapy.",
            "Conclusion": "PARAGON is the largest study on QoL and treatment landscape in PDAC so far. Data allow broad insight into real-world efficacy and QoL in the different treatment lines, regimens, and subgroups of patients."
        },
        {
            "Title": "Post-progression therapies and outcomes for patients with advanced, BRCA-related pancreatic cancer after receipt of PARP inhibitors: A national retrospective cohort study.",
            "Indication": "Advanced BRCA-related pancreatic cancer",
            "Drug": "Post-progression platinum (P) and non-platinum (NP) therapies",
            "PFS": "None",
            "OS": "Post-progression therapies: 4.8 months for P, 4.1 months for NP",
            "ORR": "None",
            "AE": "None",
            "Other": "Median time to treatment discontinuation (TTD) for post-progression therapies: 0.9 months for P, 1.5 months for NP.",
            "Conclusion": "Patients who progressed on olaparib rapidly progressed on post-progression P and NP and had limited survival. Further work is needed to identify mechanisms of resistance to PARPi and to optimize post-progression treatments."
        },
        {
            "Title": "Clinical utility and outcomes associated with mutations detection in liquid biopsy for patients with pancreatic ductal adenocarcinoma: Real-world data evidence from a single institution.",
            "Indication": "Pancreatic ductal adenocarcinoma (PDAC)",
            "Drug": "Not specified",
            "PFS": "None",
            "OS": "Median OS of 24 months (95% CI = 21-29); Any mutation detection associated with worse OS in metastatic disease (HR = 2, 95% CI = 1.2-3.2, P = 0.009); KRAS detection in metastatic disease associated with worse OS (median 16 vs 31 months, HR = 2.3, 95% CI = 1.4-3.8, P = 0.001); G12D and Q61 mutations detection associated with worse OS (median 13 and 10 months respectively, overall P < 0.001)",
            "ORR": "None",
            "AE": "None",
            "Other": "KRAS mutation detected in 68% of patients with metastatic disease and 16% for localized disease; Frequent co-occurring mutations with KRAS were TP53 (77.8%), CDKN2A (21%), SMAD4 (20.4%), and ARID1A (7.4%); Concordance rate for positive mutations in patients treated for metastatic disease was 61% for KRAS and 68% for TP53",
            "Conclusion": "Detection of any mutation in liquid biopsy was associated with worse OS in patients with metastatic PDAC. KRAS mutation detection, especially G12D or Q61, is associated with worse OS compared to other mutations. Repeating liquid biopsy for patients with initial negative result at disease progression might be beneficial. These data highlight the utility and importance of liquid biopsy in patients with PDAC."
        },
        {
            "Title": "Comparative effectiveness of NALIRIFOX vs. FOLFIRINOX in pancreatic cancer.",
            "Indication": "Pancreatic cancer",
            "Drug": "NALIRIFOX, FOLFIRINOX",
            "PFS": "FOLFIRINOX: 6.8 months; NALIRIFOX: 8.9 months",
            "OS": "FOLFIRINOX: 10.95 months; NALIRIFOX: 11.97 months",
            "ORR": "None",
            "AE": "Monthly cost of AE management for NALIRIFOX: $5,589; for FOLFIRINOX: $15,511",
            "Other": "QALY for NALIRIFOX: 10.23 months; for FOLFIRINOX: 9.59 months; Incremental cost-effectiveness ratio for NALIRIFOX vs. FOLFIRINOX: $1,368,937",
            "Conclusion": "NALIRIFOX is associated with improvement in PFS and QALY. The analysis highlights the importance of AE management for improving quality of life and cost in the recipients of FOLFIRINOX. The economic value for use of NALIRIFOX is very high and outside of any conventional boundary of acceptable value threshold for treatment."
        },
        {
            "Title": "Neoadjuvant fractionated versus hypofractionated chemoradiotherapy in resectable and borderline resectable pancreatic cancer.",
            "Indication": "Resectable and borderline resectable pancreatic cancer",
            "Drug": "Chemoradiotherapy (HypoFxRT: 30-36 Gy/10-15 fractions; ConvFracRT: 50.4 Gy/28 fractions)",
            "PFS": "HypoFxRT: 16 months; ConvFracRT: 8 months (P = 0.04)",
            "OS": "HypoFxRT: 37 months; ConvFracRT: 20 months (P = 0.04)",
            "ORR": "None",
            "AE": "None",
            "Other": "Higher rates of distant progression observed after ConvFracRT (42% vs 16%, P = 0.01); No differences in baseline Ca19-9, age, male proportion, arterial abutment, or proportion with BLR between groups; Similar rates of positive margin, lymph node positivity, and pathologic group 1 between ConvFracRT and HypoFxRT groups; Cumulative incidence of local failure at 2 years from surgery was similar in both groups",
            "Conclusion": "Following a shift to HypoFxRT, no apparent compromise in pathologic tumor response, durability of local control, or survival was observed. This data supports the continued use of HypoFxRT within the neoadjuvant Pca treatment paradigm as more convenient alternatives to ConvFracRT."
        },
        {
            "Title": "Tislelizumab combined with gemcitabine and albumin paclitaxel as preoperative therapy for patients with borderline resectable pancreatic cancer: A prospective, single-arm, phase II trial.",
            "Indication": "Borderline resectable pancreatic cancer (BRPC)",
            "Drug": "Tislelizumab, gemcitabine, albumin paclitaxel",
            "PFS": "12-months PFS rate: 68.2% (95% CI: 38.0%\u201386.0%)",
            "OS": "12-months OS rate: 63.8% (95% CI: 33.2%\u201388.3%)",
            "ORR": "71.4% according to mRECIST",
            "AE": "Grade 3 TRAEs in 3 (14.29%) patients. Frequently occurring adverse events were grade 1-2, including myelosuppression (90.47%), albuminuria (80.95%), nausea (61.90%), elevated aspartate aminotransferase (47.62%) and elevated alanine aminotransferase (38.10%). Grade 3 or higher adverse events were pneumonia (4.76%), elevated AST (4.76%), elevated ALT (4.76%) and myelosuppression (4.76%).",
            "Other": "R0 resection rate was 100%. Disease control rate (DCR) was 85.7%.",
            "Conclusion": "Anti-PD-1 inhibitor tislelizumab plus AG chemotherapy displayed promising antitumor activity and acceptable safety profile for patients with BRPC."
        },
        {
            "Title": "Perioperative fuzuloparib plus mFOLFIRINOX for resectable pancreatic adenocarcinoma: A phase 1 study.",
            "Indication": "Resectable pancreatic adenocarcinoma",
            "Drug": "Fuzuloparib, mFOLFIRINOX",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Grade \u22653 treatment-related adverse events (TRAEs) occurred in 12 (80.0%) patients, with the most common being decreased neutrophil count, decreased white blood cell count, hypokalemia, and anemia. No unexpected TRAEs were reported. No patients discontinued study treatment due to TRAEs.",
            "Other": "R0 resection rate was 100% (95% CI, 63.1\u2013100.0).",
            "Conclusion": "Perioperative fuzuloparib plus mFOLFIRINOX was generally tolerated and had an acceptable safety profile in patients with resectable pancreatic adenocarcinoma. Neoadjuvant fuzuloparib plus mFOLFIRINOX prior to surgery achieved an R0 resection rate of 100%."
        },
        {
            "Title": "Neoadjuvant therapy in patients with pancreatic ductal adenocarcinoma (PDAC) with germline DNA damage repair (DDR) mutations: A dual institution retrospective study.",
            "Indication": "Resectable, borderline resectable (BR), and locally advanced (LA) pancreatic ductal adenocarcinoma (PDAC)",
            "Drug": "Neoadjuvant therapy (FOLFIRINOX)",
            "PFS": "None",
            "OS": "Median OS: 88.3 months for DDR-positive group vs. 41.3 months for DDR-negative group (HR 0.55; p=0.04). For resected patients, median OS was 88.3 months for DDR-positive group vs. 57.1 months for DDR-negative group (HR 0.45; p=0.048). For non-resected patients, median OS was 25.3 months for DDR-positive group vs. 15.2 months for the DDR-negative group (HR 0.51; p=0.09).",
            "ORR": "None",
            "AE": "None",
            "Other": "The pathologic complete or near complete response rate was 47.2% (n=17) for the DDR-positive patients vs. 25.7% (n=9) for the DDR-negative patients (p=0.06). The median DFS was not reached for the DDR-positive patients vs. 10.8 months for DDR-negative patients (HR 0.37; p=0.002).",
            "Conclusion": "This study demonstrated that patients with resectable, BR or LA-PDAC with germline DDR mutations have a significantly prolonged DFS and OS and increased complete or near complete pathologic response rates. This suggests increased benefit from neoadjuvant therapy across all potentially resectable patients with germline DDR mutations."
        },
        {
            "Title": "ETCTN10366: A phase 1 study of DNA-PK inhibitor peposertib in combination with hypofractionated radiotherapy for the treatment of locally advanced pancreatic adenocarcinoma.",
            "Indication": "Locally advanced pancreatic adenocarcinoma (LAPC)",
            "Drug": "Peposertib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Most common treatment emergent adverse events included nausea, fatigue, abdominal pain, and diarrhea, most of which were grade 1 or 2 in severity. A total of 4 grade 3 adverse events were observed, and included lymphocyte count decrease (2), fatigue and anemia. Two grade 5 events were documented in study participants treated at dose level 3, one attributed to complications of a Whipple procedure, and the second attributed to disease progression following hospitalization for sepsis associated with a PICC line infection. Both events were deemed unrelated to study treatment.",
            "Other": "No dose limiting toxicities were observed across the 4 dose levels. PK evaluation is ongoing and will be reported.",
            "Conclusion": "Peposertib 300mg daily x14 days in combination with SBRT at a dose of 8Gy x5 fractions is the recommended phase 2 dose. A randomized phase 2 study is now enrolling to further assess the efficacy of the combination in patients with LAPC."
        },
        {
            "Title": "Independent blinded validation of a novel blood-based early pancreatic cancer detection test.",
            "Indication": "Pancreatic ductal adenocarcinoma (PDAC)",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Accuracy, specificity, and sensitivity of the test: In the training cohort, a 94.6% accuracy was achieved in distinguishing PDAC patients from non-cancer controls. A specificity of 95.7% and sensitivity of 90.7% were reached. In the blinded validation cohort, the blood-based test accurately differentiated PDAC patients from controls with an accuracy of 92.5%. Specificity of 95% and sensitivity of 91.7% were achieved.",
            "Conclusion": "Utilizing disease-specific molecular markers in peripheral blood offers a highly accurate method for differentiating between PDAC patients, healthy subjects, and those with pre-cancerous diseases. Should future studies across broader cohorts corroborate these results, this testing approach has potential for consideration as a screening mechanism for PDAC, aiming to facilitate early detection and consequently enhance patient prognoses."
        },
        {
            "Title": "Adjuvant mFOLFIRINOX (mFFX) for resected pancreatic cancer (PC): Long term clinical and genomic outcomes.",
            "Indication": "Resected pancreatic cancer",
            "Drug": "mFOLFIRINOX",
            "PFS": "27 months (95% CI 20, 39) for overall; 12 months (95% CI 9.6, NR) for >75 cohort",
            "OS": "Not reached (NR) for overall; 30 months (95% CI 19, NR) for >75 cohort",
            "ORR": "None",
            "AE": "67% stopped oxaliplatin early for neuropathy",
            "Other": "KRAS mutation (+), WGD (+), and higher KRAS CNV trended to shorter RFS and OS; HRD status did not confer differences in OS",
            "Conclusion": "Adjuvant mFFX is an effective therapy and results in long-term OS outcome in resected PC in a non-trial setting, including for patients >75 years. Early initiation of adjuvant mFFX may optimize outcome. Trends observed for improved outcome in select genomic subsets, which require large-scale validation."
        },
        {
            "Title": "Extracellular (EC) Mucin 5AC (MUC5AC) detection after neoadjuvant therapy (NAT) and the risk of recurrence in resected pancreatic ductal adenocarcinoma (R-PDA).",
            "Indication": "Resected pancreatic ductal adenocarcinoma",
            "Drug": "None",
            "PFS": "Worse PFS on multivariate analysis (HR 0.2, 95% CI: 0.059-0.766, p=0.01) in NAT group; similar impact (HR 0.2, 95% CI: 0.052-0.847, p=0.02) in FOLFIRINOX-treated group",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Cellular MM and IM expression were significant (p<0.05) on MVA for OS in both groups, but the HRs were not clinically significant",
            "Conclusion": "Post NAT EC-MM detection and cellular MUC5AC (MM and IM) expression levels are associated with worse PFS and pathological features. Their role in the treatment of naive patients is still unclear. MUC5AC can be a clinically useful predictive biomarker. Future research should focus on examining its importance in advanced tumors and manipulating it for therapeutic purposes."
        },
        {
            "Title": "A phase I/II open-label clinical trial of CPI-613 in combination with modified FOLFIRINOX in patients with locally advanced (LAPC) or borderline resectable pancreatic cancer (BRPC).",
            "Indication": "Locally advanced or borderline resectable pancreatic cancer",
            "Drug": "CPI-613 in combination with modified FOLFIRINOX",
            "PFS": "10.7 months",
            "OS": "16.3 months overall; 16.2 months (SD cohort); 17.6 months (DE cohort)",
            "ORR": "29% had partial response by RECIST 1.1",
            "AE": "78% and 24% of patients had grade 3 and/or 4 AEs in the SD cohort; 100% patients in DE cohort had grade 3 toxicity and 55% had grade 4 toxicity",
            "Other": "Most common toxicities (gr 3, 4) were neutropenia (27%, 25%), diarrhea (25%, 0), anorexia wt. loss (23%, 0), nausea/vomiting (23%, 0)",
            "Conclusion": "The combination of mFOLFIRINOX and D is feasible in the LAPC/BRPC population at increased dose levels of D; however, it did not meet the primary endpoint of doubling OS compared to historical controls."
        },
        {
            "Title": "Durvalumab and stereotactic ablative body radiotherapy (SABR) in locally advanced (LA) and borderline resectable (BR) pancreatic cancer (PDAC): Long-term outcomes and correlative insights.",
            "Indication": "Locally advanced (LA) and borderline resectable (BR) pancreatic cancer (PDAC)",
            "Drug": "Durvalumab (D) and SABR",
            "PFS": "6-and 12-month PFS 69%, 36%. Median PFS 8.7 months (95% CI 6.1-14)",
            "OS": "Median OS 16 months (95% CI 13-26)",
            "ORR": "None",
            "AE": "None",
            "Other": "Baseline peri-tumoral CD8+ cells and peripheral CD103+ CD8 T cells and CD4+ Treg in PBMCs at baseline and on treatment may predict for benefit.",
            "Conclusion": "A proportion of patients with LA PDAC achieved durable control with D and SABR after induction chemotherapy. Further analyses ongoing and validation in larger cohorts is warranted."
        },
        {
            "Title": "Perioperative therapy for resectable and borderline resectable pancreatic adenocarcinoma: An AGICC clinical trial.",
            "Indication": "Resectable and borderline resectable pancreatic adenocarcinoma (PDAC)",
            "Drug": "Combination chemotherapy and preoperative stereotactic radiation therapy (SBRT)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "16 patients (32%) had at least one grade \u22653 non-hematologic adverse event (AE); 25 patients (51%) had at least one grade \u22653 hematologic AE.",
            "Other": "R0 resection rates and survival in R-PDAC and BR-PDAC were low despite a perioperative approach of systemic therapy and SBRT.",
            "Conclusion": "R0 resection rates and survival in R-PDAC and BR-PDAC were low despite a perioperative approach of systemic therapy and SBRT. Future NAT therapy trials should aim to markedly improve the pCR rates associated with R0 resection."
        },
        {
            "Title": "Early-onset appendiceal adenocarcinoma (EOAA): 10-year experience from a single center.",
            "Indication": "Early-onset Appendiceal Adenocarcinoma (EOAA)",
            "Drug": "None",
            "PFS": "None",
            "OS": "35.93 months (95% CI, 28.4\u2013NR)",
            "ORR": "None",
            "AE": "None",
            "Other": "Lymphovascular (LVI) or perineural invasion (PNI) (HR, 6.41; 95% CI, 2.10\u201319.59, p=0.001), lymph nodes metastasis (HR, 13.11; 95% CI, 3.53\u201348.68; p, 0.001), and grade 3 disease (vs. grade 1; HR, 5.28; 95% CI, 1.43\u201319.51, p=0.01) were prognostic for worse OS in univariable analysis. Cytoreductive surgery (CRS), irrespective of completeness, was associated with improved OS (HR=0.21; 95% CI, 0.09\u20130.53; p, 0.001).",
            "Conclusion": "A considerable number of patients with AA may have early-onset disease. A median OS of about 3 years in this young and vulnerable patient population is alarming. As in late-onset disease, EOAA is often incidentally diagnosed. LVI or PNI may be associated with poorer outcomes and deserve additional consideration for risk stratification. Many patients have targetable genomic alterations and should be included in clinical trials where current enrollment is often unsatisfactory for AA. Germline mutation testing in patients with EOAA should be widely implemented."
        },
        {
            "Title": "Association between activating GNAS mutations and outcomes with chemotherapy in metastatic appendiceal adenocarcinoma.",
            "Indication": "Metastatic Appendiceal Adenocarcinoma",
            "Drug": "5-Flurouracil/Capecitabine (5-FU/Cape) based chemotherapy",
            "PFS": "None",
            "OS": "No significant difference in OS between GNASmt vs. GNASwt pts (HR, 0.82; 95% CI, 0.39\u20131.73; p=0.61)",
            "ORR": "None",
            "AE": "None",
            "Other": "GNAS activating mutations were seen in 18/48 (37.5%) patients. GNASmt pts had worse disease event-free survival (dEFS) (adjusted HR [aHR], 5.62; 95% CI, 1.65\u201319.12; p=0.006), after adjusting for CC0 reduction, histology (mucinous vs. non-mucinous), and synchronous metastases (vs. metachronous). CC0 reduction (aHR, 0.43; 95% CI, 0.21\u20130.88; p=0.02) and synchronous metastases (aHR, 0.30; 95% CI, 0.12\u20130.74; p=0.009) were also associated with improved dEFS.",
            "Conclusion": "GNAS mutated metastatic AAs show worse disease event-free survival with chemotherapy which aligns with historic OR data. A previously reported survival benefit of GNAS mutations in AA, likely due to favorable disease at baseline, is negated in the setting of metastatic disease treated with chemotherapy. Evaluating GNAS status as a predictive biomarker for AA is warranted."
        },
        {
            "Title": "A phase 1b, multicenter, open-label study of valemetostat in combination with DXd antibody drug conjugates (ADCs), trastuzumab deruxtecan (T-DXd) or datopotamab deruxtecan (Dato-DXd), in patients with solid tumors.",
            "Indication": "Solid tumors (HER2-positive gastric cancer and non-squamous non-small-cell lung cancer)",
            "Drug": "Valemetostat in combination with trastuzumab deruxtecan (T-DXd) or datopotamab deruxtecan (Dato-DXd)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoints are safety and tolerability of valemetostat in Part 1 and objective response rate in Part 2. Secondary endpoints include duration of response, PFS, overall survival, and pharmacokinetics.",
            "Conclusion": "This study will combine valemetostat with topoisomerase-I inhibitor ADCs, T-DXd or Dato-DXd, for the first time in patients with HER2-positive gastric cancer and non-squamous non-small-cell lung cancer. The study aims to determine the recommended dose for expansion and evaluate the efficacy of the combinations."
        },
        {
            "Title": "A randomized, multicenter, open-label, phase 2 study of TRK-950 in combination with ramucirumab and paclitaxel as second-line therapy in patients with Caprin-1 positive gastric or gastroesophageal junction (G/GEJ) adenocarcinoma.",
            "Indication": "Caprin-1 positive gastric or gastroesophageal junction (G/GEJ) adenocarcinoma",
            "Drug": "TRK-950 in combination with ramucirumab and paclitaxel",
            "PFS": "Primary endpoint",
            "OS": "Key secondary endpoint",
            "ORR": "Key secondary endpoint",
            "AE": "None",
            "Other": "The study is a multi-national, open-label, randomized, multicenter study evaluating the efficacy of TRK-950 in combination with ramucirumab (Ram) + paclitaxel (PTX) as compared with Ram + PTX treatment alone in patients with previously treated metastatic G/GEJ adenocarcinoma. An interim analysis will be performed after 105 patients are randomized in stage 1 to determine the optimal dose of TRK-950 (5mg/kg or 10mg/kg) that will be evaluated in stage 2. In total, 146 patients will be enrolled.",
            "Conclusion": "Caprin-1 is strongly expressed on the cell membrane of many cancer types including G/GEJ and on cancer stem cells. High expression is associated with enhanced in vitro colony-forming activity and in vivo tumorigenicity. TRK-950 was well tolerated as monotherapy with no DLTs and MTD not reached at doses of 3-30 mg/kg IV weekly. The phase 1b study demonstrated that TRK-950 was well tolerated with a high potential for synergistic anti-tumor activity when used in combination with standard of care treatment(s) in selected advanced solid tumors."
        },
        {
            "Title": "GEMINI-Gastric: A phase 2 study of novel treatment combinations in patients with locally advanced unresectable or metastatic gastric cancers.",
            "Indication": "Locally advanced unresectable or metastatic gastric or gastroesophageal junction adenocarcinoma",
            "Drug": "Volrustomig, Rilvegostomig, AZD0901, AZD7789",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Co-primary endpoints are objective response rate and progression-free survival (PFS) at 6 months by investigator assessment per RECIST v1.1. Secondary endpoints include safety, duration of response, PFS, OS, PK, and immunogenicity.",
            "Conclusion": "The study is currently recruiting at sites in the US, Europe, and Asia."
        },
        {
            "Title": "Tislelizumab plus concurrent chemoradiotherapy versus concurrent chemoradiotherapy for elderly patients with inoperable locally advanced esophageal squamous cell carcinoma: A multicenter, randomized, parallel-controlled, phase II clinical trial.",
            "Indication": "Inoperable locally advanced esophageal squamous cell carcinoma (LAESCC)",
            "Drug": "Tislelizumab",
            "PFS": "Expected median PFS would be 28 months at the hazard ratio of 0.57",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint is PFS. Secondary endpoints include OS, objective response rate, duration of objective response, and severity of adverse events.",
            "Conclusion": "The trial started on October 10, 2023 and has enrolled 19 patients till February 1, 2024."
        },
        {
            "Title": "A randomized, open-label phase II/III efficacy and safety study of atezolizumab in combination with FLOT versus FLOT alone in patients with gastric cancer and adenocarcinoma of the oesophagogastric junction and high immune responsiveness: The IKF633/DANTE trial, a trial of AIO in collaboration with SAKK.",
            "Indication": "Locally advanced, resectable esophagogastric adenocarcinoma (EGA)",
            "Drug": "Atezolizumab (ATZ) with perioperative FLOT",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint is event-free survival (EFS) with a target hazard ratio of 0.72. Main secondary endpoints are pCR, overall survival (OS) also in high immune-responsive subgroups (CPS \u22655/ CPS \u2265 10/ MSI), R0 resection rate and safety/tolerability.",
            "Conclusion": "Recruitment of phase III started in Aug 2023 and is still ongoing."
        },
        {
            "Title": "ESORES-Trial: Surgery as needed versus surgery on principle in patients with postneoadjuvant clinical complete tumor response of esophageal cancer.",
            "Indication": "Esophageal cancer (EC)",
            "Drug": "None",
            "PFS": "None",
            "OS": "Primary endpoints (hierarchically tested): 1) OS, 2) EORTC QLQ C30 global quality of life subscale",
            "ORR": "None",
            "AE": "None",
            "Other": "Secondary endpoints: Efficacy: long term tumor control, disease free survival, distant metastasis/local recurrence/regional recurrence; quality of life: EORTC QLQ C30, QLQ OES18, fear of progression: FoP-Q-12, surgery-free survival time in Arm A, patients\u2019 satisfaction: EORTC OUT-PATSAT7; economic impact: days and costs of hospitalization; Safety: Interventional Safety: 3-Year-Comprehensive Complication Index, Oncological Safety (diagnostic accuracy of CRE, resection status (R0/R1/R2), irresectable regrowth during AS).",
            "Conclusion": "Enrollment of patients started on January 30th, 2024."
        },
        {
            "Title": "Safety of pressurized intraperitoneal aerosolized chemotherapy (PIPAC) in patients (pts) with biliary tract cancer with peritoneal metastases.",
            "Indication": "Biliary tract cancer with peritoneal metastases",
            "Drug": "PIPAC nab-paclitaxel plus systemic gemcitabine/cisplatin/durvalumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Treatment-related adverse events (AEs) graded by NCI Common Terminology Criteria for Adverse Events version (v)5.0",
            "Other": "Secondary endpoints include objective response per Response Evaluation Criteria in Solid Tumors v1.1, peritoneal regression grading score at each PIPAC, peritoneal carcinomatosis index at time of laparoscopy, post-operative surgical complications, progression-free/overall survival, PIPAC technical failure rate, EQ-5D-5L and MD Anderson Symptom Inventory responses before/during treatment, and number of daily steps taken before/during treatment. Exploratory endpoints include characterization of sub-clonal tumor evolution/tumor microenvironment changes and PIPAC nab-paclitaxel pharmacokinetics/pharmacodynamics.",
            "Conclusion": "Enrollment began 6/2023 and is ongoing."
        },
        {
            "Title": "GEMINI-Hepatobiliary: A phase 2 study of novel first-line immuno-oncology-based treatments in patients with advanced hepatobiliary cancers.",
            "Indication": "Advanced hepatocellular carcinoma (HCC) and biliary tract cancer (BTC)",
            "Drug": "Volrustomig (volru) and rilvegostomig (rilve) in combination with standard of care anticancer agents",
            "PFS": "Primary endpoint for Substudy 2: Progression-free survival (PFS) per RECIST v1.1 by investigator\u2019s assessment",
            "OS": "None",
            "ORR": "Primary endpoint for Substudy 1: Objective response rate (ORR) per RECIST v1.1 by investigator\u2019s assessment",
            "AE": "Primary endpoint for both substudies: Safety",
            "Other": "Secondary endpoints for both substudies include duration of response, disease control rate, ORR, PFS, OS, pharmacokinetics, and immunogenicity.",
            "Conclusion": "The study is currently recruiting at sites in the US, Asia, and Europe."
        },
        {
            "Title": "TOURMALINE: A phase 3b study of durvalumab with gemcitabine-based chemotherapy regimens in advanced biliary tract cancer.",
            "Indication": "Advanced biliary tract cancer (aBTC)",
            "Drug": "Durvalumab with gemcitabine-based chemotherapy regimens",
            "PFS": "Secondary endpoint: Progression-free survival",
            "OS": "Secondary endpoint: Overall survival",
            "ORR": "Secondary endpoint: Objective response rate",
            "AE": "Primary endpoint: Incidence of Grade 3 or 4 adverse events, assessed by the investigator to be possibly related to any study treatment within 6 months after initiation of durvalumab",
            "Other": "Secondary endpoint: Duration of response",
            "Conclusion": "Enrollment is ongoing and planned in France, Germany, United States, Spain, Italy, Japan, South Korea, and Singapore."
        },
        {
            "Title": "Phase 2 study of livmoniplimab in combination with budigalimab in patients with hepatocellular carcinoma.",
            "Indication": "Locally advanced/metastatic hepatocellular carcinoma (HCC)",
            "Drug": "Livmoniplimab (livmo) in combination with budigalimab (budi)",
            "PFS": "Secondary objective: Progression-free survival",
            "OS": "Secondary objective: Overall survival",
            "ORR": "Primary objective: Best overall response rate",
            "AE": "Secondary objective: Safety, tolerability",
            "Other": "Secondary and exploratory objectives include duration of response, immunogenicity, pharmacokinetics/pharmacodynamics (PK/PD), predictive biomarkers, and patient-reported symptoms and tolerability.",
            "Conclusion": "Enrollment began in July 2023 and up to 60 sites in ~7 countries will be included."
        },
        {
            "Title": "Phase 2/3 study of livmoniplimab in combination with budigalimab in patients with locally advanced or metastatic hepatocellular carcinoma.",
            "Indication": "Hepatocellular carcinoma (HCC)",
            "Drug": "Livmoniplimab + Budigalimab",
            "PFS": "None",
            "OS": "None",
            "ORR": "42% (5/12) in phase 1 study",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The study aims to evaluate the efficacy of livmoniplimab + budigalimab in patients with advanced HCC, with primary objectives including median OS and secondary objectives including safety, tolerability, immunogenicity, PK, efficacy measured by median PFS, ORR, and duration of response."
        },
        {
            "Title": "An open-label, multicenter study investigating RP2 oncolytic immunotherapy in combination with second-line systemic atezolizumab combined with bevacizumab in patients with locally advanced unresectable or metastatic hepatocellular carcinoma.",
            "Indication": "Hepatocellular carcinoma (HCC)",
            "Drug": "RP2 + Atezolizumab + Bevacizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "Primary endpoint",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The study will evaluate the safety and efficacy of RP2 combined with Atezolizumab and Bevacizumab as second-line systemic therapy for unresectable and advanced HCC."
        },
        {
            "Title": "A phase 1/2 study of the TBL1 inhibitor, tegavivint (BC2059), in patients with advanced hepatocellular carcinoma (aHCC) with b-catenin activating mutations.",
            "Indication": "Hepatocellular carcinoma (HCC) with b-catenin activating mutations",
            "Drug": "Tegavivint",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The study aims to characterize the safety, PK/PD, and preliminary antitumor activity of tegavivint in patients with advanced HCC with b-catenin activating mutations."
        },
        {
            "Title": "Apatinib and camrelizumab plus Intravenous FOLFOX or hepatic arterial infusion chemotherapy with FOLFOX for advanced HCC: A multicenter, prospective, randomized phase III trial.",
            "Indication": "Advanced hepatocellular carcinoma (HCC)",
            "Drug": "Apatinib + Camrelizumab + FOLFOX",
            "PFS": "Primary endpoint: 6-month PFS rate",
            "OS": "Secondary endpoint: 6-month and 12-month OS rates",
            "ORR": "Secondary outcome",
            "AE": "Secondary endpoint: Treatment-related adverse events",
            "Other": "Secondary outcomes include Disease control rate, Duration of response, PFS, Overall survival (OS), 12-month PFS rate.",
            "Conclusion": "The study aims to compare the efficacy and safety of intravenous FOLFOX with apatinib and camrelizumab versus HAIC-FOLFOX with apatinib and camrelizumab as first-line treatment in advanced HCC."
        },
        {
            "Title": "Apatinib and camrelizumab plus hepatic arterial infusion with oxaliplatin and 5-fluorouracil vs. apatinib and camrelizumab as the first-line treatment for hepatocellular carcinoma with portal vein tumor thrombus: A randomized multi-center clinical trial.",
            "Indication": "Hepatocellular carcinoma with portal vein tumor thrombus",
            "Drug": "Apatinib and camrelizumab plus hepatic arterial infusion with oxaliplatin and 5-fluorouracil",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Whole-Exome Sequencing and RNA Sequencing of tumor biopsy samples were performed for predictive biomarker exploration.",
            "Conclusion": "The study aims to investigate the efficacy and safety of apatinib and camrelizumab plus HAIC compared with apatinib and camrelizumab as the first-line treatment for HCC with PVTT."
        },
        {
            "Title": "Phase 2 study of gemcitabine, cisplatin, quemliclustat (AB680), and zimberelimab (AB122) during first-line treatment of advanced biliary tract cancers (BTC)\u2014Big Ten Cancer Research Consortium study BTCRC-GI22-564.",
            "Indication": "Advanced biliary tract cancers (BTC)",
            "Drug": "Gemcitabine, cisplatin, quemliclustat (AB680), and zimberelimab (AB122)",
            "PFS": "Median progression-free survival (mPFS) with null and alternative survival of 6.0 and 10.0 months, respectively.",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Correlative endpoints will assess clinical outcomes with immune infiltration within tumor microenvironment, CD73 and PD-L1 expression.",
            "Conclusion": "The study evaluates the safety and preliminary efficacy of quemliclustat in combination with zimberelimab, gemcitabine, and cisplatin as first-line therapy for patients with advanced BTC."
        },
        {
            "Title": "Alliance EAY191-A6: FOLFOX in combination with binimetinib as second-line therapy for patients with advanced biliary tract cancers (BTCs) with MAPK pathway alterations: A ComboMATCH treatment trial.",
            "Indication": "Advanced biliary tract cancers (BTCs) with MAPK pathway alterations",
            "Drug": "FOLFOX in combination with binimetinib",
            "PFS": "None",
            "OS": "Primary efficacy analysis will compare OS in the intent-to-treat population.",
            "ORR": "None",
            "AE": "None",
            "Other": "Exploratory objectives include prognostic modeling, correlations of genomic alterations with response or resistance, and use of machine learning for outcome prediction.",
            "Conclusion": "The study evaluates if the combination of mFOLFOX6 + binimetinib improves OS for patients with BTC and MAPK mutations compared to mFOLFOX6 alone in 2nd-line therapy."
        },
        {
            "Title": "A phase 1/2, safety lead-in and dose expansion, open-label, multicenter trial investigating the safety, tolerability, and preliminary activity of ivosidenib in combination with nivolumab and ipilimumab in previously treated subjects with IDH1-mutated nonresectable or metastatic cholangiocarcinoma.",
            "Indication": "IDH1-mutated nonresectable or metastatic cholangiocarcinoma",
            "Drug": "Ivosidenib in combination with nivolumab and ipilimumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The primary objective is to evaluate the safety and tolerability of IVO in combination with IPI plus NIVO and determine the recommended combination dose (RCD).",
            "Conclusion": "The study aims to assess the clinical activity of the triplet combination in patients with and without prior ICI."
        },
        {
            "Title": "Phase II study of neoadjuvant durvalumab and tremelimumab in combination with gemcitabine and cisplatin in patients with intrahepatic cholangiocarcinoma that is borderline resectable or resectable with high risk for recurrence.",
            "Indication": "Intrahepatic cholangiocarcinoma (ICC)",
            "Drug": "Durvalumab, Tremelimumab, Gemcitabine, Cisplatin",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoints are feasibility and safety with secondary endpoints of overall survival, progression-free survival, ORR, R0 resection rate, and pathologic complete response. Molecular biomarkers from blood to predict outcomes and organoid development from circulating tumor cells are exploratory endpoints.",
            "Conclusion": "The study is currently enrolling participants."
        },
        {
            "Title": "A phase 3, randomized study of adjuvant rilvegostomig plus chemotherapy in resected biliary tract cancer: ARTEMIDE-Biliary01.",
            "Indication": "Biliary tract cancer (BTC)",
            "Drug": "Rilvegostomig, Capecitabine, S-1 (tegafur/gimeracil/oteracil), Gemcitabine/Cisplatin",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint is recurrence-free survival, and the key secondary endpoint is overall survival. Additional endpoints include progression-free survival following recurrence, patient-reported tolerability, and safety.",
            "Conclusion": "Enrollment has begun, and approximately 200 sites will be recruiting globally."
        },
        {
            "Title": "REPLACE: A phase III, randomized, open-label trial to evaluate the safety and efficacy of regorafenib and pembrolizumab versus locoregional therapy (LRT) with transarterial chemoembolization (TACE) or transarterial radioembolization (TARE), for the first-line treatment of intermediate-stage hepatocellular carcinoma (HCC) with beyond up-to-7 criteria.",
            "Indication": "Intermediate-stage hepatocellular carcinoma (HCC)",
            "Drug": "Regorafenib, Pembrolizumab",
            "PFS": "Primary endpoint is progression-free survival (PFS), assessed locally by the Investigator using mRECIST for HCC.",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Secondary endpoints include PFS assessed locally by the Investigator using RECIST 1.1; PFS centrally assessed using mRECIST and RECIST 1.1; overall survival; overall response rate according to RECIST v.1.1 and mRECIST based on the Investigator\u2019s and central assessment; time to unTACEable progression; duration of response; safety and tolerability of the trial treatments, and patient-reported outcomes for health-related quality of life.",
            "Conclusion": "Recruitment started in October 2023 and is currently ongoing."
        },
        {
            "Title": "SWOG S2012: Randomized phase II/III trial of first line platinum (P)/etoposide (E) with or without atezolizumab (NSC#783608) in patients with advanced or metastatic poorly differentiated extrapulmonary neuroendocrine carcinomas (NEC).",
            "Indication": "Advanced or metastatic poorly differentiated extrapulmonary neuroendocrine carcinomas (NEC)",
            "Drug": "Cisplatin or Carboplatin, Etoposide, Atezolizumab",
            "PFS": "None",
            "OS": "Primary objective is to compare the overall survival (OS, from randomization) between arms in a fixed sequence: A vs C -> B vs C -> A vs B.",
            "ORR": "None",
            "AE": "None",
            "Other": "Secondary objectives include comparing OS (from start of maintenance/observation), progression-free survival, response rate, duration of response, and safety/tolerability across arms. Tumor and blood samples will be banked for future biomarker analyses, including immunohistochemistry of transcription factors on tissue and whole exome sequencing on tumor and circulating tumor DNA.",
            "Conclusion": "The study was activated in December 2021 with 3 patients enrolled over 1 year due to restricting enrollment of NEC of small cell histology only. The protocol was amended in January 2023 to broaden eligibility to all NEC subtypes. As of January 2024, 35 patients have been enrolled. A protocol amendment is planned to remove the need for Ki-67 index for NEC of genitourinary origin to improve accrual."
        },
        {
            "Title": "A phase II randomized control trial of triapine plus lutetium 177 DOTATATE versus lutetium 177 DOTATATE alone for well-differentiated somatostatin receptor-positive neuroendocrine tumors.",
            "Indication": "Well-differentiated somatostatin receptor-positive neuroendocrine tumors",
            "Drug": "Triapine plus lutetium 177 DOTATATE",
            "PFS": "None",
            "OS": "None",
            "ORR": "Primary endpoint to evaluate overall response rate (ORR)",
            "AE": "None",
            "Other": "Secondary endpoint to evaluate median PFS. Also evaluating triapine PK, plasma deoxy-ribonucleosides, circulating DNA, and plasma hPG80, a novel blood-based diagnostic biomarker.",
            "Conclusion": "The combination of triapine and Lu-177 DOTATATE was safe with preliminary efficacy signals."
        },
        {
            "Title": "Phase 1/2 randomized, open-label, multicenter, Simon two-stage study of pelareorep combined with modified FOLFIRINOX +/- atezolizumab in patients with metastatic pancreatic ductal adenocarcinoma.",
            "Indication": "Metastatic pancreatic ductal adenocarcinoma (mPDAC)",
            "Drug": "Pelareorep combined with modified FOLFIRINOX +/- atezolizumab",
            "PFS": "Secondary objective to evaluate progression-free survival",
            "OS": "Secondary objective to evaluate overall survival",
            "ORR": "Primary objective to evaluate efficacy based on objective response rate per RECIST v1.1",
            "AE": "Primary objective to evaluate safety and tolerability",
            "Other": "Tumor and blood samples are being collected to evaluate the immunological effects of treatment and to explore potential biomarkers of response.",
            "Conclusion": "This study is designed to evaluate pelareorep plus modified FOLFIRINOX with or without the PD-L1 inhibitor atezolizumab as first-line therapy in patients with mPDAC."
        },
        {
            "Title": "Phase 1 study to evaluate safety and preliminary efficacy of padeliporfin vascular targeted photodynamic therapy (VTP) in patients with locally advanced (LA) unresectable pancreatic ductal adenocarcinoma (PDAC).",
            "Indication": "Locally advanced unresectable pancreatic ductal adenocarcinoma (PDAC)",
            "Drug": "Padeliporfin vascular targeted photodynamic therapy (VTP)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Primary objective to determine safety and MTD and/or recommended phase 2 laser light dose of VTP treatment",
            "Other": "Secondary objectives include rate of surgical conversion and evaluation of the pattern of disease progression (local regional and/or metastatic disease) after VTP based on CT.",
            "Conclusion": "Conversion of unresectable locally advanced pancreatic cancer to surgery is a critical unmet need. This phase I trial explores a novel approach to treat the tumor encasement enabling surgery."
        },
        {
            "Title": "Alliance A022106: A phase II/III trial of NABPLAGEM vs nab-paclitaxel/gemcitabine as second-line treatment for BRCA1/2 or PALB2 mutant metastatic pancreatic ductal adenocarcinoma (PLATINUM).",
            "Indication": "BRCA1/2 or PALB2 mutant metastatic pancreatic ductal adenocarcinoma (PDAC)",
            "Drug": "NABPLAGEM vs nab-paclitaxel/gemcitabine",
            "PFS": "None",
            "OS": "Primary endpoint of the phase III component being overall survival",
            "ORR": "None",
            "AE": "Reports containing efficacy, adverse events, and administrative information will be provided to the Alliance DSMB every 6 months.",
            "Other": "Planned correlatives include ctDNA analysis for BRCA reversion mutations in all patients, as well as whole genome and transcriptome sequencing in an optional biopsy cohort.",
            "Conclusion": "This study was activated in December 2023 to assess the optimal chemotherapy regimen in patients with HRD-associated metastatic PDAC following front-line FOLFIRINOX and to test the hypothesis that a platinum switch strategy will be beneficial."
        },
        {
            "Title": "A randomized, multi-center, phase 2 study of ivaltinostat (Ival) plus capecitabine (Cape) versus capecitabine alone in the maintenance setting in patients with metastatic pancreatic adenocarcinoma (mPDAC).",
            "Indication": "Metastatic pancreatic adenocarcinoma (mPDAC)",
            "Drug": "Ivaltinostat (Ival) and Capecitabine (Cape)",
            "PFS": "Immature, but 70% of pts who enrolled with measurable disease had stable disease as best response.",
            "OS": "None",
            "ORR": "None",
            "AE": "15% were fatigue, nausea, diarrhea, and decreased appetite. Palmar-plantar erythrodysaesthesia syndrome was also commonly observed; all events were attributed to Cape.",
            "Other": "Several pts remained on study with the longest duration of response about 10 months.",
            "Conclusion": "A randomized phase 2 study is being conducted comparing the combination of Ival and Cape versus Cape alone in the maintenance setting for pts with mPDAC after first line FOLFIRINOX, mFOLFIRINOX or NALFIRINOX therapy."
        },
        {
            "Title": "METAPANC, intensified treatment in patients with oligometastatic pancreatic cancer: Multimodal surgical treatment versus systemic chemotherapy alone\u2014A prospective randomized controlled multicenter phase III trial.",
            "Indication": "Oligometastatic pancreatic cancer",
            "Drug": "Modified FOLFIRINOX (mFFX) chemotherapy",
            "PFS": "Secondary endpoint",
            "OS": "Primary endpoint",
            "ORR": "None",
            "AE": "Procedure related complications and mortality",
            "Other": "Quality of life, quality-adjusted time without symptoms and toxicity. Exploratory biomarker analysis program.",
            "Conclusion": "This clinical trial will focus on a well-defined patient collective with resectable PDAC and metastatic load limited to the liver with a maximum of three metastases."
        },
        {
            "Title": "CheMo4METPANC: A randomized phase 2 study with combination chemotherapy (gemcitabine and nab-paclitaxel), chemokine (C-X-C) motif receptor 4 inhibitor (motixafortide), and immune checkpoint blockade (cemiplimab) compared to chemotherapy alone in metastatic treatment-na\u00efve pancreatic adenocarcinoma.",
            "Indication": "Metastatic pancreatic ductal adenocarcinoma (mPDAC)",
            "Drug": "Motixafortide (CXCR4i), Cemiplimab (aPD1), Gemcitabine, and Nab-paclitaxel (MCGN)",
            "PFS": "Preliminary median PFS was 9.6 months compared to historic median PFS of 5.5 months with GN.",
            "OS": "None",
            "ORR": "Seven patients (64%) achieved a partial response, including 6 confirmed partial responses, and 10 patients (91%) had disease control.",
            "AE": "None",
            "Other": "Increase in CD8+ T-cell density in tumors from all 11 patients treated with MCGN (p=0.007).",
            "Conclusion": "Based on these promising results, the protocol was amended from a single arm expansion totaling 40 patients to a randomized phase 2 study."
        },
        {
            "Title": "Sequential neoadjuvant chemotherapy for borderline resectable and locally advanced pancreatic adenocarcinoma.",
            "Indication": "Borderline resectable (BRPC) and locally advanced unresectable (LAPC) pancreatic cancer",
            "Drug": "Sequential chemotherapy (Gemcitabine and nab-paclitaxel followed by modified FOLFIRINOX)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Feasibility and toxicity",
            "Other": "Primary objective is to evaluate the efficacy of sequential GA followed by mFFX in improving R0 resection rate in patients with BRPC and LAPC.",
            "Conclusion": "The results will have high translational potential for providing mechanistic insight into tumor response, optimizing current management strategies for pancreatic cancer that could be generalizable to other stages of this cancer."
        },
        {
            "Title": "VIRAGE: A phase IIb, open-label, randomized study of nab-paclitaxel and gemcitabine plus/minus VCN-01 in patients with metastatic pancreatic cancer.",
            "Indication": "Metastatic pancreatic cancer",
            "Drug": "VCN-01, nab-paclitaxel, gemcitabine",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: overall survival (OS); Secondary objectives: time-to-progression (TTP), progression-free survival (PFS), overall response rate (ORR), disease control rate (DCR), duration of response (DoR), 1-year OS and PFS rates, serial changes in tumor markers (Ca19.9)",
            "Conclusion": "Enrollment is ongoing in US and Spanish sites."
        },
        {
            "Title": "Targeting replication stress in pancreatic cancer: A phase II trial investigating azenosertib in combination with gemcitabine in second-line advanced pancreatic cancer.",
            "Indication": "Advanced pancreatic cancer",
            "Drug": "Azenosertib, gemcitabine",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: 6-month PFS rate; Secondary endpoints: potential biomarkers of treatment response",
            "Conclusion": "Enrollment began November 21, 2023, and is ongoing."
        },
        {
            "Title": "A phase I/II open label study to assess safety and preliminary evidence of a therapeutic effect of azeliragon in patients refractory to first-line treatment of metastatic pancreatic cancer.",
            "Indication": "Metastatic pancreatic cancer",
            "Drug": "Azeliragon",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary objective: determine the RP2D; Secondary endpoints: disease control, overall survival, changes in pain, ECOG performance status, weight, serum albumin",
            "Conclusion": "Accrual started in June 2023."
        },
        {
            "Title": "A phase II study of cadonilimab plus mFOLFIRINOX as induction therapy for locally advanced pancreatic adenocarcinoma (LAPC).",
            "Indication": "Locally advanced pancreatic adenocarcinoma (LAPC)",
            "Drug": "Cadonilimab, mFOLFIRINOX",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: R0/R1 resection rate; Secondary endpoints: ORR per RECIST 1.1, PFS, OS, safety assessments, correlation between ctDNA changes, surgical decisions, prognosis",
            "Conclusion": "Enrollment is ongoing."
        },
        {
            "Title": "Phase 2 randomized study of rintatolimod following FOLFIRINOX in patients with locally advanced pancreatic adenocarcinoma.",
            "Indication": "Locally advanced pancreatic adenocarcinoma",
            "Drug": "Rintatolimod",
            "PFS": "13 months",
            "OS": "19 months",
            "ORR": "None",
            "AE": "Grade 1-2 myalgia (30%), chills (52%), and fatigue (30%); no toxicities \u2265 grade 3",
            "Other": "None",
            "Conclusion": "Rintatolimod was generally well tolerated and showed potential efficacy in maintaining progression-free and overall survival in patients with locally advanced pancreatic adenocarcinoma."
        },
        {
            "Title": "A phase I/II study of intraperitoneal (IP) paclitaxel (PTX) in patients with metastatic appendiceal adenocarcinoma.",
            "Indication": "Metastatic appendiceal adenocarcinoma",
            "Drug": "Intraperitoneal paclitaxel (PTX)",
            "PFS": "None",
            "OS": "None",
            "ORR": "Expected to raise ORR to 30% from a null hypothesis of 5%",
            "AE": "Adverse events will be captured and graded using NCI CTCAE v.5.0",
            "Other": "Secondary endpoints include pathologic, biochemical, and radiographic response, PFS, OS, and the influence of KRAS/GNAS/TP53 mutation on response. PK measurements will also be performed.",
            "Conclusion": "The study aims to determine the safety and efficacy of IP paclitaxel in patients with metastatic appendiceal adenocarcinoma, with a focus on increasing the objective response rate and understanding the pharmacokinetics and mutation influence on response."
        },
        {
            "Title": "Effects of immune-enhancing nutrition on radical cystectomy outcomes: Patient-reported outcomes (PROs) from a randomized, phase 3 trial",
            "Indication": "Bladder cancer",
            "Drug": "Specialized Immunonutrition (SIM) vs. Oral Nutritional Support (ONS)",
            "PFS": "2-year PFS estimates: SIM 77.2%, ONS 68.3% (1-sided p=.16)",
            "OS": "2-year OS estimates: SIM 87.4%, ONS 78.2% (1-sided p=.07)",
            "ORR": "None",
            "AE": "Proportion of patients experiencing CD Grade \u22651 complications: SIM 62.2%, ONS 58.0%",
            "Other": "No difference in high-grade complications at Day 30 or 90",
            "Conclusion": "There was no difference in any grade CD complications by type of nutritional supplement for patients with bladder cancer undergoing RC. Fewer patients on the SIM arm have progressed or died, although differences were not statistically significant; follow-up for survival will continue through 3-years after enrollment. Future work to understand the interaction of diet on cancers sensitive to immune modulation is needed."
        },
        {
            "Title": "Primary results from the randomized phase III double-blind clinical trial enfortumab vedotin plus pembrolizumab (EV+P) versus platinum-based chemotherapy (PBC) in previously untreated locally advanced or metastatic urothelial cancer (la/mUC)",
            "Indication": "Locally advanced or metastatic urothelial cancer (la/mUC)",
            "Drug": "Enfortumab vedotin plus pembrolizumab (EV+P) vs. Platinum-based chemotherapy (PBC)",
            "PFS": "Median PFS: EV+P 14.2 months, PBC 10.0 months (HR=0.92; 95% CI=0.72, 1.17; 2-sided p-value=0.48)",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Mean reduction in worst pain at week 26: EV+P -0.61 vs PBC -0.03 (LS mean difference [95% CI]: -0.58 [-1.05, -0.11] [nominal 2-sided p-value=0.015])",
            "Conclusion": "Patients treated with EV+P have improved survival compared with PBC without detriment to quality of life and functioning, further supporting the value of EV+P for patients with la/mUC. Compliance, especially after progression, was lower than expected (particularly in the PBC arm) and may have impacted the results."
        },
        {
            "Title": "Impact of exposure on outcomes with enfortumab vedotin in patients with locally advanced or metastatic urothelial cancer",
            "Indication": "Locally advanced or metastatic urothelial cancer (la/mUC)",
            "Drug": "Enfortumab vedotin (EV)",
            "PFS": "Median PFS: EV-301 across exposure quartiles: Q1 4.44 months, Q2 7.16 months, Q3 5.62 months, Q4 5.65 months, Chemotherapy 3.71 months",
            "OS": "Median OS: EV-301 across exposure quartiles: Q1 11.0 months, Q2 15.1 months, Q3 15.2 months, Q4 12.6 months, Chemotherapy 8.97 months",
            "ORR": "Higher initial EV exposure in the first 2 cycles was associated with a higher ORR (0.75 mg/kg 21.4%, 1.0 mg/kg 18.5%, 1.25 mg/kg 40-51.1% across studies)",
            "AE": "Lower EV exposure was associated with lower risk of EV-related Grade \u22653 rash or skin reactions, Grade \u22652 peripheral neuropathy, and Grade \u22653 hyperglycemia (P < 0.0001 for all)",
            "Other": "None",
            "Conclusion": "EV improved PFS and OS outcomes vs chemotherapy in patients with la/mUC across all exposure quartiles. The starting dose of 1.25 mg/kg 3Q4W resulted in EV exposure that maximized the likelihood of response. Recommended dose modifications are effective for managing EV-related AEs and should be used as clinically indicated."
        },
        {
            "Title": "Biomarker analyses in patients with advanced renal cell carcinoma (aRCC) from the phase 3 CLEAR trial",
            "Indication": "Advanced renal cell carcinoma (aRCC)",
            "Drug": "Lenvatinib plus pembrolizumab (L+P) vs. Sunitinib (S)",
            "PFS": "None",
            "OS": "None",
            "ORR": "ORR for MYC-high and low groups: 66.3% (56.1-75.6) and 84.0% (75.0-90.8), respectively. ORR for GEP-high and low groups: 46.9% (38.1-55.9) and 28.8% (18.3-41.3), respectively. ORR for angiogenesis-high and low groups: 52.1% (41.6-62.5) and 30.4% (21.7-40.3), respectively.",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The superiority of L+P vs S in ORR does not appear to be impacted by gene-expression signatures for tumor-induced proliferation, angiogenesis, hypoxia, MYC, or WNT, or by PD-L1 status, TMB/INDEL burden or mutation status of RCC driver genes."
        },
        {
            "Title": "Biomarker analysis of the phase 3 KEYNOTE-426 study of pembrolizumab (P) plus axitinib (A) versus sunitinib (S) for advanced renal cell carcinoma (RCC)",
            "Indication": "Advanced renal cell carcinoma (RCC)",
            "Drug": "Pembrolizumab plus axitinib versus sunitinib",
            "PFS": "P+A: Strong positive association with PFS (P < 0.0001); S: Positive association with PFS (P < 0.001)",
            "OS": "P+A: Improved OS (P = 0.003); S: Negative association with OS (P = 0.013)",
            "ORR": "P+A: 65.4% (59.0-71.4) in T cell inflamed GEP 1st tertile; S: 42.1% (35.9-48.5) in T cell inflamed GEP 1st tertile",
            "AE": "None",
            "Other": "Positive association with T cell inflamed GEP for P+A; Angiogenesis positively associated with OS for P+A; Various RNA signatures and DNA mutations analyzed",
            "Conclusion": "P+A improved ORR over S regardless of mutational status. The immune microenvironment in combination therapy will be critical to advance treatment strategies."
        },
        {
            "Title": "Circulating kidney injury molecule-1 (KIM-1) biomarker analysis in IMmotion010: A randomized phase 3 study of adjuvant (adj) atezolizumab (atezo) vs placebo (pbo) in patients (pts) with renal cell carcinoma (RCC) at increased risk of recurrence after resection",
            "Indication": "Renal cell carcinoma (RCC)",
            "Drug": "Atezolizumab versus placebo",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "High baseline serum KIM-1 levels were associated with poorer prognosis but improved clinical outcomes with atezo vs pbo. Increased post-tx KIM-1 was associated with worse DFS.",
            "Conclusion": "Circulating KIM-1 may be a non-invasive marker of MRD and disease recurrence and be associated with benefit from atezo in adj RCC. Further investigation of KIM-1 in adj RCC is warranted."
        },
        {
            "Title": "Partitioned overall survival: Comprehensive analysis of survival states over 4 years in CheckMate 9ER comparing first-line nivolumab plus cabozantinib versus sunitinib in advanced renal cell carcinoma (aRCC)",
            "Indication": "Advanced renal cell carcinoma (aRCC)",
            "Drug": "Nivolumab plus cabozantinib versus sunitinib",
            "PFS": "None",
            "OS": "NIVO+CABO: 49.2% at 4 years; SUN: 40.2% at 4 years",
            "ORR": "None",
            "AE": "NIVO+CABO: Grade 2+ TRAEs in 8.3 months; SUN: Grade 2+ TRAEs in 6.7 months",
            "Other": "Mean TFS was 2.4 months longer with NIVO+CABO than SUN. Mean survival time on 1L protocol therapy was 8.5 months longer with NIVO+CABO.",
            "Conclusion": "Longer OS achieved with NIVO+CABO was accompanied by 1.5 times longer mean time surviving treatment-free before 2L therapy compared with SUN."
        },
        {
            "Title": "Avelumab + axitinib vs sunitinib in patients (pts) with advanced renal cell carcinoma (aRCC): Final overall survival (OS) analysis from the JAVELIN Renal 101 phase 3 trial",
            "Indication": "Advanced renal cell carcinoma (aRCC)",
            "Drug": "Avelumab plus axitinib versus sunitinib",
            "PFS": "Avelumab+axitinib: 13.9 months (11.0-17.8); Sunitinib: 8.2 months (6.9-9.1)",
            "OS": "Avelumab+axitinib: 43.2 months (36.5-51.7); Sunitinib: 36.2 months (29.8-44.2)",
            "ORR": "Avelumab+axitinib: 64.8% (57.8-70.6); Sunitinib: 54.6% (46.6-62.3)",
            "AE": "Avelumab+axitinib: Grade \u22653 TRAEs in 66.8%; Sunitinib: Grade \u22653 TRAEs in 61.5%",
            "Other": "Second-line therapy was received by 58.1% vs 69.4%, including a PD-(L)1 inhibitor in 18.8% vs 53.6%, respectively.",
            "Conclusion": "Final analysis results confirm the long-term efficacy and manageable safety profile of avelumab+axitinib treatment in pts with aRCC."
        },
        {
            "Title": "Characterization of complete responders to nivolumab + gemcitabine-cisplatin alone and patients with lymph node\u2013only metastatic urothelial carcinoma from the CheckMate 901 trial",
            "Indication": "Metastatic urothelial carcinoma",
            "Drug": "Nivolumab + gemcitabine-cisplatin",
            "PFS": "NIVO+GC: 30.5 months (HR 0.38, 95% CI 0.22-0.66); GC: 8.8 months",
            "OS": "NIVO+GC: 46.3 months (HR 0.58, 95% CI 0.34-1.00); GC: 24.9 months",
            "ORR": "NIVO+GC: 81.5% (95% CI 68.6-90.7); GC: 64.3% (95% CI 50.4-76.6%)",
            "AE": "None",
            "Other": "CR rate: NIVO+GC: 63.0%; GC: 33.9%",
            "Conclusion": "NIVO+GC generated deep responses in CheckMate 901 with a fixed duration of chemotherapy and up to 2 years NIVO. Exploratory characterization of pts with CR identified a group of pts enriched with LN-only disease. In pts with LN-only mUC, NIVO+GC induced durable disease control and clinically meaningful improvements in OS and PFS vs GC alone. These results provide additional support for NIVO plus cisplatin-based chemo as a first-line treatment option for pts with mUC."
        },
        {
            "Title": "A multicenter phase II study of modified FOLFIRINOX for first-line treatment for advanced urachal cancer (ULTIMA; KCSG GU20-03)",
            "Indication": "Advanced urachal cancer",
            "Drug": "Modified FOLFIRINOX",
            "PFS": "9.3 months (95% CI, 6.6~12.0)",
            "OS": "26.4 months (95% CI, 14.7-38.1)",
            "ORR": "61.9%",
            "AE": "Grade 3 adverse events: neutropenia (4.8%), anemia (9.5%), thrombocytopenia (4.8%), diarrhea (4.8%)",
            "Other": "None",
            "Conclusion": "The first prospective ULTIMA study showed encouraging ORR and PFS in patients with advanced urachal cancer. The regimen is well tolerated without febrile neutropenia with prophylactic pegteograstim. Further analysis of efficacy will be presented."
        },
        {
            "Title": "Camrelizumab plus apatinib for previously treated advanced adrenocortical carcinoma: A single-arm, open-label, phase 2 trial",
            "Indication": "Advanced adrenocortical carcinoma",
            "Drug": "Camrelizumab + apatinib",
            "PFS": "12.6 months (95% CI, 8.4 to 20.9)",
            "OS": "20.9 months (95% CI, 11.0 to 20.9)",
            "ORR": "52% (95% CI, 30 to 74)",
            "AE": "Grade 3-4 treatment-related adverse events: alanine aminotransferase elevation (28.6%), aspartate aminotransferase elevation (23.8%), lymphopenia (23.8%)",
            "Other": "Disease control rate: 95% (95% CI, 84 to 100)",
            "Conclusion": "The combination of camrelizumab with apatinib showed promising activity and acceptable toxicity in advanced ACC patients who failed previous lines of therapy. Pre-treatment peripheral blood immune cell subsets could be regarded as potential predictors of efficacy. Given the promising clinical activity, the potential of camrelizumab with apatinib as first-line therapy for advanced ACC needs further evaluation."
        },
        {
            "Title": "A multi-institution analysis of outcomes with first-line systemic therapy for 99 patients with metastatic chromophobe renal cell carcinoma",
            "Indication": "Metastatic chromophobe renal cell carcinoma",
            "Drug": "Various (IO+targeted therapy doublets, Pure IO monotherapy and doublets, targeted therapy doublets, targeted monotherapy)",
            "PFS": "None",
            "OS": "Targeted monotherapy: 23 months (HR 0.56; 95% CI: 0.30, 1.04); IO/Targeted Doublet: 56 months; Targeted Doublet: 99 months; Pure IO: NR",
            "ORR": "None",
            "AE": "None",
            "Other": "TTF and OS by first-line treatment group: Targeted Monotherapy: TTF 5 months, 18-Month OS Rate 58%; IO/Targeted Doublet: TTF 15 months, 18-Month OS Rate 80%; Targeted Doublet: TTF 17 months, 18-Month OS Rate 65%; Pure IO: TTF 7 months, 18-Month OS Rate 83%",
            "Conclusion": "In this observational analysis, patients with targeted doublet regimens had a higher median TTF and OS compared to those receiving monotherapies. We continue to build on this dataset and plan to conduct progression-free survival analysis."
        },
        {
            "Title": "Preliminary safety, pharmacokinetics and clinical activity of DFF332, an oral HIF2a inhibitor, as monotherapy in a phase 1 dose escalation study in patients with advanced clear cell renal cell carcinoma.",
            "Indication": "Advanced clear cell renal cell carcinoma (ccRCC)",
            "Drug": "DFF332",
            "PFS": "None",
            "OS": "None",
            "ORR": "50% (18 patients with stable disease, 2 patients with partial response)",
            "AE": "93% reported at least one AE. Common AEs: fatigue (33%), anemia (30%), increased blood cholesterol (15%), constipation (15%). Treatment-related AEs: anemia, increased blood cholesterol, fatigue (each 13%), hypertriglyceridemia (10%). Grade 3 TRAEs: increased weight and hypertension (2.5% each). No grade 4 TRAEs or dose-limiting toxicities.",
            "Other": "Preliminary PK results demonstrated fast oral absorption, slow elimination, and dose-proportional exposure.",
            "Conclusion": "During this phase I study, the monotherapy of DFF332 has shown a promising safety profile across all doses and schedules. Additionally, there have been indications of clinical activity and a dose-proportional modulation of erythropoietin. Ongoing analysis of PK and biomarker data is being conducted."
        },
        {
            "Title": "Systemic treatments in favorable and very favorable risk metastatic renal cell carcinoma (mRCC): Real world evidence from the International mRCC Database Consortium (IMDC).",
            "Indication": "Metastatic renal cell carcinoma (mRCC)",
            "Drug": "Ipilimumab + Nivolumab (IPI/NIVO), anti-PD1/PDL1 and VEGF combinations (IO-VEGF), VEGF inhibitors",
            "PFS": "None",
            "OS": "Favorable Risk: 2-year OS: IPI/NIVO 86.4%, IO-VEGF 84.1%, VEGF 81.1%. OS HR: IPI/NIVO 0.93, IO-VEGF 0.81. Very Favorable Risk: 2-year OS: IPI/NIVO 73.1%, IO-VEGF 87.1%, VEGF 92.1%. OS HR: IPI/NIVO 3.19.",
            "ORR": "Favorable Risk: IPI/NIVO 39%, IO-VEGF 51%, VEGF 33%. Very Favorable Risk: IPI/NIVO 23.5%, IO-VEGF 58.1%, VEGF 55%.",
            "AE": "None",
            "Other": "Favorable Risk: Treatment Duration (TD): IPI/NIVO 5.7 months, IO-VEGF 22.4 months, VEGF 12 months. Time to Next Treatment (TTNT): IPI/NIVO 22.3 months, IO-VEGF 32.2 months, VEGF 15.8 months. Very Favorable Risk: TD: IPI/NIVO 10.6 months, IO-VEGF 32.1 months, VEGF 19.4 months. TTNT: IPI/NIVO 27.1 months, IO-VEGF 48.3 months, VEGF 23.2 months.",
            "Conclusion": "No significant difference in survival between different treatment regimens in the favorable risk population was detected yet. In the very favorable risk population, there was a statistically significant decrease in 2-year overall survival with IPI-NIVO (HR 3.19), which, if validated, could underline the necessity of targeting the VEGF pathway in this population. Further prospective trials and longer follow-up are required to confirm these findings."
        },
        {
            "Title": "Avelumab (A) as neoadjuvant therapy in patients (pts) with muscle-invasive urothelial carcinoma (MIUC): Survival data of AURA trial, Oncodistinct004.",
            "Indication": "Muscle-invasive urothelial carcinoma (MIUC)",
            "Drug": "Avelumab (A), dose-dense MVAC (ddMVAC), cisplatin-gemcitabine (CG), paclitaxel-gemcitabine (PG)",
            "PFS": "None",
            "OS": "Cisplatin-eligible cohort: 12-month OS: ddMVAC-A 95%, CG-A 84%. 36-month OS: ddMVAC-A 87%, CG-A 61%. Cisplatin-ineligible cohort: 12-month OS: PG-A 67%, A 75%.",
            "ORR": "None",
            "AE": "None",
            "Other": "Cisplatin-eligible cohort: 12-month DFS: ddMVAC-A 97%, CG-A 86%. 36-month DFS: ddMVAC-A 100%, CG-A 78%. Cisplatin-ineligible cohort: 12-month DFS: PG-A 52%, A 68%.",
            "Conclusion": "The addition of neoadjuvant avelumab to cisplatin-based chemotherapy results in a high DFS and OS at 12 and 36 months respectively, especially in pts with complete response to neoadjuvant treatment. Combination with ddMVAC regimen offers robust activity. Cisplatin-ineligible pts had lower survival outcomes with no benefit of PG addition."
        },
        {
            "Title": "Perioperative sacituzumab govitecan (SG) alone or in combination with pembrolizumab (Pembro) for patients with muscle-invasive urothelial bladder cancer (MIBC): SURE-01/02 interim results.",
            "Indication": "Muscle-invasive urothelial bladder cancer (MIBC)",
            "Drug": "Sacituzumab govitecan (SG) and pembrolizumab (Pembro)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "Quantitative circulating tumor DNA (ctDNA) assessment in patients (pts) with advanced urothelial carcinoma (UC) treated with pembrolizumab (pembro) or platinum-based chemotherapy (chemo) from the phase 3 KEYNOTE-361 trial.",
            "Indication": "Advanced urothelial carcinoma (UC)",
            "Drug": "Pembrolizumab (Pembro) and platinum-based chemotherapy",
            "PFS": "Lower C1 max VAF was associated with improved PFS in the pembro arm (P < 0.01).",
            "OS": "Lower C1 max VAF was associated with improved OS in the pembro arm (P < 0.01).",
            "ORR": "Lower C1 max VAF was associated with improved ORR in the pembro arm (P < 0.01).",
            "AE": "None",
            "Other": "Larger C2 reductions in ctDNA levels were observed in the chemo vs pembro arm (median ratio of C2/C1 TI max VAF, 0.03 vs 0.71, respectively).",
            "Conclusion": "ctDNA levels at baseline appear prognostic for pembro. Reductions in ctDNA during the first treatment cycle were associated with outcome. Distinct patterns of ctDNA response were observed with the different treatments, and stronger associations with long-term clinical outcome were observed with pembro. Association of OS for both pembro and chemo was not retained when adjusting for tumor response. Early ctDNA dynamics did not offer additional benefit in predicting outcome beyond radiographic assessment of tumor size change."
        },
        {
            "Title": "Sintilimab plus axitinib for advanced fumarate hydratase-deficient renal cell carcinoma: A multi-center, open-label, single-arm, phase II study (SAFH).",
            "Indication": "Fumarate hydratase-deficient renal cell carcinoma (FH-dRCC)",
            "Drug": "Sintilimab plus Axitinib",
            "PFS": "19.83 months (95% CI: 7.68-31.99)",
            "OS": "None",
            "ORR": "60.5% (23/38)",
            "AE": "All grade: 87.8% (36/41), Grade \u22653: 22.0% (9/41)",
            "Other": "Confirmed complete response rate: 10.5% (4/38), Disease-controlled rate (DCR): 86.8%",
            "Conclusion": "The combination of Sintilimab and Axitinib showed manageable safety profile and durable anti-tumor efficacy in FH-dRCC. Evaluating mutation status of FH gene could help to predict potential survival benefit from ICI plus TKI therapy."
        },
        {
            "Title": "Lenvatinib plus pembrolizumab (L+P) vs sunitinib (S) in advanced renal cell carcinoma (aRCC): Patterns of progression and subsequent therapy in the CLEAR trial.",
            "Indication": "Advanced renal cell carcinoma (aRCC)",
            "Drug": "Lenvatinib plus Pembrolizumab vs Sunitinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "HRs (95% CI) of time to progression for L+P vs S across tumors in different organs: bone, 0.40 (0.25-0.63); CNS, 0.47 (0.19-1.19); kidney, 0.65 (0.37-1.14); liver, 0.52 (0.32-0.84); lung, 0.48 (0.36-0.62); lymph nodes, 0.63 (0.46-0.85). Median sums of diameters of target lesions at overall disease progression: L+P: 29.8 mm vs S: 42.8 mm. Median duration of axitinib as the first anti-cancer regimen after L+P: 23.7 months (5.3-NE), after S: 12.6 months (6.8-NE). Median duration of cabozantinib as the first anti-cancer regimen after L+P: 13.2 months (8.2-NE), after S: 7.1 months (4.1-20.0).",
            "Conclusion": "Pts in the L+P arm trended to show later progression across tumors in different organs. At overall disease progression, the tumor burden of target lesions was lower with L+P vs S; and pts in the L+P arm stayed on 2L axitinib or cabozantinib longer than pts in the S arm. Together, these results continue to support L+P as a standard-of-care 1L therapy in pts with aRCC."
        },
        {
            "Title": "Changes in treatment (Rx) patterns and attrition rates in patients (pts) with metastatic clear cell renal cell carcinoma (mccRCC).",
            "Indication": "Metastatic clear cell renal cell carcinoma (mccRCC)",
            "Drug": "PD-1 inhibitors, CTLA4 inhibitors, TKI inhibitors",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Rx trends summarized: 1L Rx changed from TKI monotherapy (79%) before 2018 to PD-1 combination therapies (58%) after 2018. At 1-year landmark analysis after 1L discontinuation: before 2018 group - 58% received next Rx, 30% died without further Rx, 12% were alive without Rx; after 2018 group - 42% received next Rx, 26% died without further Rx, 32% were alive without Rx.",
            "Conclusion": "Despite the increase in the utilization of PD-1+CTLA-4 and PD-1+TKI regimens since 2018, a significant number of patients still did not receive novel combination regimens in the 1L mccRCC setting in this real-world population in the United States. Further validation in other datasets is needed."
        },
        {
            "Title": "Comparison of TKI and CPI strategies as first-line treatment of patients with advanced renal cell carcinoma: Real-world outcome data from the German research platform CARAT.",
            "Indication": "Advanced renal cell carcinoma (mRCC)",
            "Drug": "TKI, CPI",
            "PFS": "TKI+CPI: 12.7 months, CPI+CPI: 8.0 months, TKI: 9.7 months",
            "OS": "TKI+CPI: 31.5 months, CPI+CPI: 22.3 months, TKI: 31.4 months",
            "ORR": "None",
            "AE": "None",
            "Other": "HR for PFS: 1.41 for TKI vs CPI+CPI, 1.33 for TKI vs TKI+CPI. HR for OS: 1.30 for CPI+CPI vs TKI+CPI, 1.08 for TKI vs TKI+CPI.",
            "Conclusion": "IPWT-adjusted outcome analysis of real-world data in the CARAT registry did not detect significant differences in PFS and OS between pts treated with 1L TKI+CPI compared to CPI+CPI when adjusted for a wide range of potential confounding variables. Further analyses in other cohorts are warranted to confirm these findings."
        },
        {
            "Title": "First-line systemic therapy following adjuvant immunotherapy in renal cell carcinoma (RCC): An international multi-center study.",
            "Indication": "Renal cell carcinoma (RCC)",
            "Drug": "Adjuvant pembrolizumab, atezolizumab, nivolumab plus ipilimumab combination therapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "35% in VEGF-TT monotherapy, 45% in IO/VEGF-TT combination, 42% in single/combination IO cohort",
            "AE": "TrAEs occurred in 28/69 (41%) pts treated with subsequent systemic therapy.",
            "Other": "In 23 pts who received adjuvant pembrolizumab monotherapy and subsequent systemic therapy, ORR was 44% (4/9) vs. 29% (4/14) for those recurring after vs. prior to discontinuation of pembrolizumab, respectively. Two out of 8 pts who discontinued adjuvant IO due to toxicity were rechallenged with subsequent IO and had stable disease, and one of them developed an immune-related AE.",
            "Conclusion": "Patients with RCC who recur on or after adjuvant IO treatment benefit from subsequent systemic therapies across different regimens."
        },
        {
            "Title": "Leukocyte immunoglobulin-like receptor (LILRB2)-targeted JTX-8064 plus the anti-PD1 inhibitor JTX-4014 (pimivalimab) in immune-checkpoint inhibitor (ICI) pretreated patients (pts) with advanced or metastatic renal cell cancer (mRCC): Results from the multi-stage phase 1-2 INNATE trial.",
            "Indication": "Advanced or metastatic renal cell cancer (mRCC)",
            "Drug": "JTX-8064 and JTX-4014",
            "PFS": "Median PFS was 4 months (95% CI: 2, 6.8)",
            "OS": "12-month OS was 75% (95% CI: 55, 88)",
            "ORR": "ORR of 7% & DCR of 54%",
            "AE": "Tx-related adverse events (AE) of all grades were reported in 11 pts (45%), most commonly fatigue (16%) & diarrhea (10%). Only 4 protocol-related G3-4 AEs were reported: thrombocytopenia (G4); diplopia, diarrhea, & bradycardia (all G3).",
            "Other": "Three on-study deaths (hypotension; cardiorespiratory arrest; & unknown) were deemed unrelated to protocol tx.",
            "Conclusion": "While ORR did not meet the protocol-defined efficacy target, evidence of antitumor activity was seen in ICI pre-treated mRCC pts with combination JTX-8064 + JTX-4014. Treatment was reasonably well-tolerated. Identification & evaluation of clinical and molecular phenotypes most likely to benefit from LILRB2-targeted therapies are warranted."
        },
        {
            "Title": "Subcutaneous (SC) nivolumab (NIVO) vs intravenous (IV) NIVO in patients with previously treated advanced or metastatic clear cell renal cell carcinoma (ccRCC): Safety and patient-reported outcomes (PROs) of the randomized phase 3 CheckMate 67T trial.",
            "Indication": "Advanced or metastatic clear cell renal cell carcinoma (ccRCC)",
            "Drug": "Nivolumab (NIVO)",
            "PFS": "None",
            "OS": "None",
            "ORR": "Noninferiority endpoints of exposure (time-averaged serum concentration over the first 28 days and trough serum concentration at steady state) and efficacy (ORR by BICR) were met.",
            "AE": "The overall AE incidence rates in the SC arm were generally comparable to or lower than in the IV arm across weight categories. Local site reactions were mild to moderate with a median duration of 2.0 and 0.01 days in the SC and the IV arms, respectively; most resolved without treatment. The proportion of patients with a local site reaction of any grade was 8.1% in the SC arm and 2.0% in the IV arm. In patients testing NIVO-specific anti-drug antibody (ADA)-positive, 15.2% of patients in the SC arm reported a local site reaction, but all were grade 1\u20132 and most resolved without treatment.",
            "Other": "No hypersensitivity/infusion reaction select AEs were reported in ADA-positive patients in either arm. A majority of patients in SC and IV arms reported minimal bother by treatment side effects in their FACIT GP5 scores.",
            "Conclusion": "The safety profile of SC was consistent with IV, supporting the use of NIVO SC as an option improving patient experience. This aligns with patient preference for SC administration over IV. Analyses based on weight and ADA subgroups were consistent with the known NIVO safety profile. Toxicity was manageable with immune-modulating medications. FACIT GP5 scores indicated no bother by treatment side effects."
        },
        {
            "Title": "Belzutifan in patients with advanced clear cell renal cell carcinoma (ccRCC): Health-related quality of life (HRQoL) with nivolumab (NIVO) subcutaneous",
            "Indication": "Advanced clear cell renal cell carcinoma (ccRCC)",
            "Drug": "Belzutifan",
            "PFS": "None",
            "OS": "None",
            "ORR": "23.4% (95% CI, 16.9-30.9)",
            "AE": "None",
            "Other": "Median duration of response (DOR) was 16.1 months (range, 2.1+ to 23.5+)",
            "Conclusion": "This post hoc analysis from the LITESPARK-013 study of patients with pretreated advanced ccRCC suggests that belzutifan has antitumor activity regardless of prior lines of therapies and prognostic risk category. These results further support belzutifan as a treatment option for advanced RCC."
        },
        {
            "Title": "Belzutifan plus lenvatinib (len) for Chinese patients (pts) with previously treated advanced clear cell renal cell carcinoma (ccRCC): Preliminary results of cohort 1 of the phase 1 LITESPARK-010 study",
            "Indication": "Advanced clear cell renal cell carcinoma (ccRCC)",
            "Drug": "Belzutifan + Lenvatinib",
            "PFS": "Median PFS was 13.7 months (95% CI, 8-NR)",
            "OS": "Median OS was not reached (95% CI, NR-NR). The 12-month PFS and OS rates were 57% and 75%, respectively.",
            "ORR": "50% (95% CI, 29-71; all PRs)",
            "AE": "All 24 pts (100%) experienced a treatment-related AE (TRAE) of any grade, most commonly anemia (100%) and proteinuria (88%). Grade 3 or 4 TRAEs occurred in 17 pts (71%), most commonly anemia (29%) and hypertension (29%). One pt (4%) experienced grade 3 hypoxia. No pts died (grade 5) due to a TRAE.",
            "Other": "None",
            "Conclusion": "Preliminary data from the belzutifan + len showed promising antitumor activity in Chinese pts with previously treated ccRCC. The safety of belzutifan 120mg + len 20mg was consistent with the safety profiles of the individual drugs."
        },
        {
            "Title": "Efficacy of post-lenvatinib treatments in patients (pts) with advanced renal cell carcinoma (aRCC).",
            "Indication": "Advanced renal cell carcinoma (aRCC)",
            "Drug": "Post-lenvatinib therapies",
            "PFS": "None",
            "OS": "None",
            "ORR": "1st line: 50%, 2nd line: 5% (95% CI 0.2-25), 3rd line: 12% (95% CI 3.3-27)",
            "AE": "None",
            "Other": "Time to treatment failure (TTF): 1st line: 9.7 months, 2nd line: 5.8 months (95% CI 1.9-14.9), 3rd line: 2.8 months (95% CI 1.9-7.4)",
            "Conclusion": "Modest activity of TKI-based therapy post-lenvatinib exposure. Need for improved treatment options for pts progressing on lenvatinib-based therapies."
        },
        {
            "Title": "Camrelizumab plus apatinib for advanced renal cell carcinoma patients after first-line tyrosine kinase inhibitor treatment failure: A multicenter phase II trial.",
            "Indication": "Advanced renal cell carcinoma (aRCC)",
            "Drug": "Camrelizumab plus apatinib",
            "PFS": "10.0 months (95% CI 6.1-14.9)",
            "OS": "28.4 months (95% CI 19.7-NR)",
            "ORR": "34.3% (95% CI 19.1-52.2)",
            "AE": "77.1% experienced treatment-related adverse events of grade 3 or higher, with the most common being proteinuria (25.7%), hypertension (20.0%), and alanine aminotransferase increased (14.3%) and aspartate aminotransferase increased (14.3%).",
            "Other": "Disease control rate: 82.9% (95% CI 66.4-93.4)",
            "Conclusion": "The combination of camrelizumab and apatinib demonstrates encouraging antitumor activity and a favorable safety profile as a second-line treatment option for patients with aRCC."
        },
        {
            "Title": "Preliminary results from a phase II clinical study of first-line drugs combined with stereotactic body radiotherapy (SBRT) in the treatment of oligoprogressive renal cell carcinoma.",
            "Indication": "Oligoprogressive renal cell carcinoma (mRCC)",
            "Drug": "First-line systemic therapy combined with SBRT",
            "PFS": "6-month PFS: 88.9%, 12-month PFS: 54.2%, 18-month PFS: 36.4%, 24-month PFS: 22.7%, median PFS: 15.0 months",
            "OS": "24-month OS: 78.5%, median OS: not reached",
            "ORR": "None",
            "AE": "44.4% experienced grade 3-4 treatment-related adverse events, with the most common being hematological toxicity (18.5%), hypertension (18.5%), and palmar-plantar erythrodysesthesia syndrome (7.4%). No SBRT-related grade 3-4 toxicity was observed.",
            "Other": "Local control (LC) rate: 97.1%",
            "Conclusion": "SBRT is a safe and effective treatment for oligoprogressive mRCC, with a notable extension of the ongoing first-line systemic therapy."
        },
        {
            "Title": "A phase I/II study of talazoparib and axitinib in patients with advanced clear cell renal cell carcinoma.",
            "Indication": "Advanced clear cell renal cell carcinoma (ccRCC)",
            "Drug": "Talazoparib and axitinib",
            "PFS": "6.1 months (95% CI 3.5-8.4)",
            "OS": "27 months (95% CI 12-NE)",
            "ORR": "7% (90% CI 0, 30)",
            "AE": "56% had at least one treatment-emergent AE of grade 3+, and 39% had at least one treatment-related AE of grade 3+. Most common grade 3+ AEs included diarrhea, nausea, and anemia.",
            "Other": "None",
            "Conclusion": "This study established the RP2D and MTD of combination talazoparib and axitinib and demonstrated a tolerable safety profile across all dose escalation cohorts. The study did not meet the pre-defined efficacy threshold for further enrollment per Simon stage two design. Exploratory efforts to evaluate this treatment approach with tissue biomarker data remain ongoing."
        },
        {
            "Title": "A phase II trial of sitravatinib plus nivolumab after progression on prior immune checkpoint inhibitor (ICI) in patients with metastatic clear cell renal cell carcinoma (ccRCC).",
            "Indication": "Metastatic clear cell renal cell carcinoma (ccRCC)",
            "Drug": "Sitravatinib plus nivolumab",
            "PFS": "Median PFS was 5.5 months [95% CI 3.8\u2013not reached (NR)]",
            "OS": "Median OS was 13.3 months (95% CI 8.77\u2013NR)",
            "ORR": "15.4% (2/13)",
            "AE": "42.9% experienced a grade 3-4 adverse event (AE)",
            "Other": "Disease control rate (DCR) at 24 weeks was 35.7% (5/14)",
            "Conclusion": "Sitravatinib plus nivolumab demonstrated a manageable safety profile and produced modest clinical benefit. The observed responses occurred in patients who did not receive prior treatment with cabozantinib or lenvatinib."
        },
        {
            "Title": "Expression of the receptor tyrosine kinase AXL and clinical outcomes to cabozantinib and everolimus in patients with metastatic renal cell carcinoma enrolled in the METEOR trial.",
            "Indication": "Metastatic renal cell carcinoma (mccRCC)",
            "Drug": "Cabozantinib and everolimus",
            "PFS": "Cabozantinib: 7.3 months vs 7.2 months (HR: 1.26, 95% CI 0.73, 2.15); Everolimus: 1.9 months vs 4.1 months (HR: 2.15, 95% CI 1.21, 3.82)",
            "OS": "None",
            "ORR": "Cabozantinib: 12% vs 15%; Everolimus: Not reported",
            "AE": "None",
            "Other": "High % AXL+ TC and high % AXL+ TC/IC were significantly associated with shorter PFS in the everolimus arm.",
            "Conclusion": "The improvement in outcomes with cabozantinib relative to everolimus is more evident in patients with high levels of AXL expression, supporting that the antitumor activity of cabozantinib in mccRCC is mediated, in part, by AXL inhibition."
        },
        {
            "Title": "Anlotinib combined with sintilimab as first-line treatment in patients with advanced non-clear cell renal cell carcinoma (nccRCC): Preliminary results from an exploratory prospective multicentre clinical study.",
            "Indication": "Advanced non-clear cell renal cell carcinoma (nccRCC)",
            "Drug": "Anlotinib combined with sintilimab",
            "PFS": "Median PFS was 15.1 months (95% CI 13.2-15.9)",
            "OS": "None",
            "ORR": "52.9% (95% CI 0.35-0.70)",
            "AE": "20.9% experienced grade 3/4 adverse events",
            "Other": "Disease control rate (DCR) was 94.1% (95% CI 0.80-0.99)",
            "Conclusion": "Anlotinib plus sintilimab showed promising efficacy and acceptable toxicity for patients with advanced nccRCC. Complete results are awaited in 2024 after follow-up completion of the entire cohort."
        },
        {
            "Title": "Biomarker analysis of zanzalintinib in clear cell renal cell carcinoma from STELLAR-001.",
            "Indication": "Clear cell renal cell carcinoma (ccRCC)",
            "Drug": "Zanzalintinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "38%",
            "AE": "None",
            "Other": "Disease control rate (DCR) was 88%",
            "Conclusion": "Zanzalintinib in patients with advanced ccRCC leads to modulation of plasma biomarkers associated with angiogenesis and peripheral immune cell subsets consistent with its expected mechanism of action. Further investigation in larger studies is warranted."
        },
        {
            "Title": "Molecular analysis of the HCRN GU16-260 Cohort A phase II study of first-line (1L) nivolumab (nivo) and salvage nivo + ipilimumab (ipi) in patients (pts) with advanced clear cell renal cell carcinoma (accRCC).",
            "Indication": "Advanced clear cell renal cell carcinoma (accRCC)",
            "Drug": "Nivolumab (nivo) and ipilimumab (ipi)",
            "PFS": "CL4 median PFS: 15.2 months, 1-year PFS: 60%; CL1 median PFS: 24.5 months, 1-year PFS: 70.5%; CL3 median PFS: 8.2 months, 1-year PFS: 42.9%; CL6 median PFS: 5.1 months, 1-year PFS: 40.0%",
            "OS": "None",
            "ORR": "CL4 ORR: 60.0%; CL1 ORR: 52.4%; CL3 ORR: 40.0%; CL6 ORR: 12.5%",
            "AE": "None",
            "Other": "PD as best response in CL6: 50.0%",
            "Conclusion": "Consistent with prior analyses with combination ICB + anti-VEGF and 1L ICB, CL4 was associated with robust 1L ICB monotherapy antitumor efficacy. Surprisingly, ORR was also high in CLs 1 and 3. In this small dataset, transcriptomic signatures enriched for but did not fully predict ORR. Future prospective trials are needed to identify more discriminative biomarkers of efficacy for pure ICB therapy."
        },
        {
            "Title": "Association between circulating cytokines expression patterns and outcomes to immune-checkpoint (ICI)\u2013based regimens in metastatic renal cell carcinoma (mRCC).",
            "Indication": "Metastatic renal cell carcinoma (mRCC)",
            "Drug": "Immune-checkpoint inhibitors (ICI) combinations",
            "PFS": "Pts with high IL-6, IL-8, IL-10, and VEGF-Alpha baseline expression had a median TNT of 5.09 vs. 17.32 months (p=0.07), 5.32 vs. 17.32 months (p=0.09), 5.32 vs. 25.59 months (p=0.04), and 3.77 vs. not reached (NR) (p=0.00), respectively.",
            "OS": "None",
            "ORR": "38% of pts had clinical benefit from ICI combos in our cohort.",
            "AE": "None",
            "Other": "Poor-risk according to IMDC classification was positively associated with IL-6 (p=0.03) and IL-8 (p=0.01) baseline expression, while intermediate-risk was positively associated with IL-22 expression (p=0.028). Clinical benefit was negatively associated with IL-8 (p=0.04), IL-10 (p=0.03), and VEGF-alpha (p=0.03) baseline expression.",
            "Conclusion": "Baseline expressions of IL-6, IL-8, IL-10, VEGF-alpha, may represent an important prognostic biomarker that can further risk stratify intermediate risk mRCC and predict clinical outcomes to ICI combos."
        },
        {
            "Title": "Association of baseline inflammatory biomarkers and clinical outcomes in patients with advanced renal cell carcinoma (aRCC) treated with immune checkpoint inhibitors (ICIs).",
            "Indication": "Advanced renal cell carcinoma (aRCC)",
            "Drug": "Immune checkpoint inhibitors (ICIs)",
            "PFS": "NLR: HR 0.71 (95% CI 0.53-0.95, p=0.021); MLR: HR 0.69 (95% CI 0.53-0.90, p=0.007); PLR: HR 0.81 (95% CI 0.62-1.06, p=0.125); NER: HR 0.65 (95% CI 0.50-0.85, p=0.001)",
            "OS": "NLR: HR 0.64 (95% CI 0.50-0.82, p<0.001); MLR: HR 0.56 (95% CI 0.44-0.72, p<0.001); PLR: HR 0.64 (95% CI 0.51-0.80, p<0.001); NER: HR 0.63 (95% CI 0.50-0.80, p<0.001)",
            "ORR": "None",
            "AE": "None",
            "Other": "High mGPS, NLR, MLR, PLR, and NER were related to inferior clinical benefit (CB); hazard ratios (HR) (95% CI, p-value) were 3.03 (1.35-6.77, p=0.005), 1.86 (1.14-3.03, p=0.013), 1.83 (1.10-3.02, p=0.019), 2.37 (1.52-3.71, p<0.001), 1.93 (1.22-3.05, p=0.005) respectively.",
            "Conclusion": "The results demonstrated that high systemic inflammatory biomarkers may be related to worse clinical outcomes with ICI treatment. Prospective studies are needed for further validation."
        },
        {
            "Title": "Examination of irAE and treatment discontinuation irAE in patients with RCC with T effector phenotype.",
            "Indication": "Renal cell carcinoma (RCC)",
            "Drug": "Dual immunotherapy with ipilimumab/nivolumab (IO/IO) or a combination aVEGF inhibitor with immunotherapy (VEGF/IO)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "IO/IO: 66% developed any grade irAE (41% grade 3+), 41% required steroid treatment, 41% required hospitalization, 33% discontinued treatment due to irAE. VEGF/IO: 60% developed any grade irAE (20% grade 3+), none were treated with steroids, none were hospitalized.",
            "Other": "None",
            "Conclusion": "Patients with a T effector RNA-seq signature had higher rates of irAE than historic controls when treated with IO/IO leading to high rates of steroid use and treatment discontinuation. VEGF/IO-treated patients with the T effector RNA-seq signature had similar rates of irAE to historic controls. The ongoing OPTIC clinical will expand on these results by studying treatment assignment in a prospective manner."
        },
        {
            "Title": "Impact of CBM588 on gut microbiome composition and dysbiosis in patients receiving frontline immune checkpoint inhibitor (ICI) combinations for metastatic renal cell carcinoma (mRCC)",
            "Indication": "metastatic renal cell carcinoma (mRCC)",
            "Drug": "CBM588",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Supplementation with CBM588 leads to a marked correction of gut dysbiosis and prevents the depletion of species previously associated with ICI response (i.e., Eubacterium siraeum).",
            "Conclusion": "These findings provide a plausible mechanism for the enhanced clinical outcome with CBM588 now seen across two small, randomized trials. A phase III study is planned within the cooperative groups to evaluate the clinical activity and gut microbiome modulation capacity of CBM588 in combination with ICIs in mRCC."
        },
        {
            "Title": "Disparate outcomes among Latino and non-Hispanic White (NHW) patients with metastatic clear cell renal cell carcinoma (mccRCC).",
            "Indication": "Metastatic clear cell renal cell carcinoma (mccRCC)",
            "Drug": "Immune checkpoint inhibitor (ICI) based therapy, tyrosine kinase inhibitor (TKI) monotherapy",
            "PFS": "None",
            "OS": "2-year OS shorter among Latino pts compared to NHW pts (HR 4.05, p=0.04); 2-year OS significantly reduced for Latino pts treated with first-line TKI monotherapy compared to NHW pts (HR 9.25, p=0.03); Multivariable analysis showed significant difference in OS between Latino and NHWs (HR 2.7, 95% CI 1.18-6.36, p=0.02)",
            "ORR": "None",
            "AE": "None",
            "Other": "Higher rates of IMDC intermediate/poor risk disease (85% vs 59%, p<0.01) and synchronous metastatic disease (60% vs 41%, p=0.04) among Latino pts; Higher rates of public health insurance (42% vs 8%, p<0.01) and higher deprivation indexes (43% vs 20%, p<0.01) among Latino pts; BAP1 (26% vs 10%, p=0.02) and ATM mutations (10% vs 0%, p<0.01) more common in Latino pts; SETD2 (32% vs 8%, p<0.01) and TERT alterations (19% vs 6%, p=0.04) more prevalent among NHWs",
            "Conclusion": "Latino pts with mccRCC present with different clinical-genomic features and worse survival outcomes. These findings underscore the complex interplay between tumor biology and social determinants of health (SDOH)."
        },
        {
            "Title": "Association of immune-related adverse events, inflammatory biomarkers, and clinical outcomes in patients treated with ICIs for advanced renal cell carcinoma.",
            "Indication": "Advanced renal cell carcinoma (aRCC)",
            "Drug": "Immune Checkpoint Inhibitors (ICIs)",
            "PFS": "Longer PFS (HR 0.51 (0.37-0.71); p<0.001)",
            "OS": "Longer OS (HR 0.55 (0.37-0.82); p=0.003)",
            "ORR": "Greater clinical benefit (CB) (OR 2.56 (1.53-4.29); p<0.001)",
            "AE": "47.6% experienced any grade irAEs, with 21.5% classified as grade 3 or higher; Higher incidence of irAEs in females (OR 1.66 (1.08-2.57), p=0.021); Higher incidence of irAEs in White patients compared to African American patients (OR 2.20 (1.32-3.66), p=0.009); Low mGPS correlated with higher risk of irAEs (OR for mGPS of 0-1-2 was 6.03 (2.07-17.62), 3.75 (1.31-10.70), and reference respectively; p=0.004)",
            "Other": "None",
            "Conclusion": "Female patients, White patients, and patients with low inflammatory biomarkers may have a higher likelihood of experiencing irAEs. Patients who experienced irAEs may have better outcomes. Future prospective trials are needed for further evidence."
        },
        {
            "Title": "Comparative effectiveness of first-line (1L) ipilimumab plus nivolumab (Ipi+Nivo) versus immune checkpoint inhibitors + tyrosine kinase inhibitors (ICI+TKI) in patients (pts) with intermediate or poor (I/P) risk metastatic clear cell renal cell carcinoma (mccRCC).",
            "Indication": "Intermediate or poor (I/P) risk metastatic clear cell renal cell carcinoma (mccRCC)",
            "Drug": "Ipilimumab plus nivolumab (Ipi+Nivo), immune checkpoint inhibitors + tyrosine kinase inhibitors (ICI+TKI)",
            "PFS": "None",
            "OS": "Median rwOS for Ipi+Nivo was 30 months (mo) [95% CI 26\u201335] vs. 34 mo [95% CI 28\u201341] for ICI+TKI (HR 1.02, 95% CI 0.84\u20131.24, p=0.81); After PS matching, no significant difference in rwOS between both groups (HR 1.01, 95% CI 0.83\u20131.24, p=0.89)",
            "ORR": "None",
            "AE": "None",
            "Other": "Median rwTTNT was 9.1 mo [95% CI 8\u201311] for Ipi+Nivo vs. 15 mo [95% CI 14\u201318] for ICI+TKI (HR 1.29, 95% CI 1.09\u20131.51, p=0.002); After PS matching, rwTTNT was significantly shorter with Ipi+Nivo compared to ICI+TKI (HR 1.24, 95% CI 1.05\u20131.47, p=0.011)",
            "Conclusion": "In this study, rwOS was comparable between pts receiving Ipi+Nivo vs. ICI+TKI Rx while rwTTNT was better with ICI+TKI. These data can help clinicians in pts counseling and treatment selection. In the absence of validated biomarkers, the choice of 1L Rx for pts mccRCC should primarily depend on clinical factors (performance status, comorbidities, pt compliance and preference), Rx characteristics (toxicity profile) and disease status (urgency of response)."
        },
        {
            "Title": "Impact of obesity on immune-related adverse events and tyrosine kinase inhibitor side effects in patients with metastatic renal cell carcinoma (mRCC)",
            "Indication": "Metastatic Renal Cell Carcinoma (mRCC)",
            "Drug": "Immune Checkpoint Inhibitors (ICI) and Tyrosine Kinase Inhibitors (TKIs)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "{'Thyroid dysfunction': {'Obese': '17.7%', 'Non-Obese': '13.1%', 'p-value': '0.007'}, 'Fatigue': {'Obese': '14.1%', 'Non-Obese': '14.0%', 'p-value': '0.952'}, 'Cardiac toxicity': {'Obese': '4.1%', 'Non-Obese': '3.6%', 'p-value': '0.516'}, 'Mucositis': {'Obese': '2.2%', 'Non-Obese': '2.5%', 'p-value': '0.618'}, 'Diarrhea': {'Obese': '14.4%', 'Non-Obese': '10.9%', 'p-value': '0.017'}, 'Liver dysfunction': {'Obese': '2.5%', 'Non-Obese': '2.5%', 'p-value': '0.955'}}",
            "Other": "None",
            "Conclusion": "The use of TKIs has shown an increased risk of developing thyroid dysfunction and diarrhea in obese patients. However, there is no difference in irAE with use of ICI between obese and non-obese patients. Further large studies are needed to support this observation and to study the mechanism by which obesity and metabolic syndromes influence the development of adverse effects."
        },
        {
            "Title": "Treatment outcomes of brain metastasis from primary renal cell carcinoma in United States: A National Cancer Database analysis",
            "Indication": "Brain Metastasis from Metastatic Renal Cell Carcinoma (mRCC)",
            "Drug": "Stereotactic Radiosurgery (SRS) and Immunotherapy",
            "PFS": "None",
            "OS": "{'WBRT Alone': '5.65 months', 'SRS + Immunotherapy': '19.0 months', 'SRS Only': '9.7 months', 'Immunotherapy Only': '12.9 months', 'WBRT + Immunotherapy': '12.78 months'}",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "This national database analysis reveals that the combination of SRS with immunotherapy significantly increases the OS in RCCBM compared to immunotherapy, WBRT, or combination of WBRT and immunotherapy. Further prospective studies are warranted to confirm these findings."
        },
        {
            "Title": "Enfortumab vedotin (EV) with pembrolizumab (P) versus chemotherapy (chemo) in previously untreated locally advanced or metastatic urothelial carcinoma (la/mUC): Analysis of cisplatin (cis)-eligible population from EV-302/KEYNOTE-A39",
            "Indication": "Locally Advanced or Metastatic Urothelial Carcinoma (la/mUC)",
            "Drug": "Enfortumab vedotin (EV) with Pembrolizumab (P) versus Platinum-Based Chemotherapy (PBC)",
            "PFS": "{'EV + P': '14.6 months', 'PBC': '6.5 months', 'HR': '0.48, 95% CI, 0.38, 0.62'}",
            "OS": "{'EV + P': '31.5 months', 'PBC': '18.4 months', 'HR': '0.53, 95% CI, 0.39, 0.72'}",
            "ORR": "{'EV + P': '70.8% (32.5% CR)', 'PBC': '53.0% (15.5% CR)'}",
            "AE": "{'Grade \u22653 TRAEs in EV + P': '53.9%', 'Grade \u22653 TRAEs in PBC': '62.7%', 'Most common grade \u22653 TRAEs for EV': 'Skin reactions (14.8%), Hyperglycemia (7.8%), Peripheral neuropathy (5.8%)', 'Most common grade \u22653 TRAEs for P': 'Severe skin reactions (9.5%), Pneumonitis (4.9%), Colitis (2.9%)'}",
            "Other": "mDOR for EV + P: Not reached (18.2 months, NR); mDOR for PBC: 8.3 months (5.9, 10.9)",
            "Conclusion": "EV + P improved clinical outcomes in patients who were eligible for cis with previously untreated la/mUC, reducing the risk of death by 47% compared with PBC. Results were consistent with the overall population. The AE profile of EV + P was generally manageable with no new safety signals. The results of EV-302 support EV + P as a new SOC for la/mUC, including patients who are eligible for cis."
        },
        {
            "Title": "Enfortumab vedotin (EV) with pembrolizumab (P) versus chemotherapy Study EV-103: Neoadjuvant treatment with enfortumab vedotin mono-therapy in cisplatin-ineligible patients with muscle invasive bladder cancer (MIBC)\u20142-year event-free survival and safety data for Cohort H.",
            "Indication": "Muscle invasive bladder cancer (MIBC)",
            "Drug": "Enfortumab vedotin (EV)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Fatigue (45.5%), dysgeusia (36.4%), alopecia (31.8%), grade \u22653 EV-related TEAEs (18.2%)",
            "Other": "pCR rate: 36.4% (95% CI, 17.2-59.3), pDS rate: 50.0% (95% CI, 28.2-71.8), EFS rate at 24 months: 62.0% (95% CI, 38.2-78.9)",
            "Conclusion": "Neoadjuvant EV monotherapy treatment showed promising results for antitumor activity and 2-year EFS with a manageable safety profile in cisplatin-ineligible pts with MIBC. These results support ongoing phase 2 and 3 programs in MIBC evaluating EV alone or combined with pembrolizumab."
        },
        {
            "Title": "Efficacy and safety of trastuzumab deruxtecan (T-DXd) in patients (pts) with HER2-expressing solid tumors: Results from the bladder cohort of the DESTINY-PanTumor02 (DP-02) study.",
            "Indication": "Urothelial carcinoma",
            "Drug": "Trastuzumab deruxtecan (T-DXd)",
            "PFS": "None",
            "OS": "None",
            "ORR": "39.0% (95% CI, 24.2-55.5)",
            "AE": "Grade \u22653 drug-related adverse events: 41.5%, interstitial lung disease/pneumonitis: 9.8%",
            "Other": "None",
            "Conclusion": "T-DXd showed clinically meaningful responses in pretreated pts with urothelial cancers, including across HER2 expression levels (IHC 3+ and 2+). Safety was consistent with the known profile. These data support further evaluation of T-DXd as a potential Tx for pretreated pts with HER2-expressing urothelial cancers."
        },
        {
            "Title": "Avelumab first-line maintenance (1LM) for advanced urothelial carcinoma (aUC): Long-term outcomes from JAVELIN Bladder 100 in patients (pts) with low tumor burden.",
            "Indication": "Advanced urothelial carcinoma (aUC)",
            "Drug": "Avelumab",
            "PFS": "Median PFS by investigator: 9.0 months (95% CI, 5.7-12.6) for nonvisceral metastases, 3.3 months (95% CI, 2.0-3.7) for BSC alone",
            "OS": "Median OS: 31.4 months (95% CI, 26.1-36.8) for nonvisceral metastases, 17.1 months (95% CI, 13.7-21.3) for BSC alone",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Exploratory analyses suggest that avelumab 1LM has pronounced efficacy and manageable toxicity in pts with aUC who have low tumor burden, supporting its use as a standard of care in this setting."
        },
        {
            "Title": "Avelumab first-line (1L) maintenance for advanced urothelial carcinoma (aUC): Long-term outcomes from the JAVELIN Bladder 100 trial in patients (pts) with histological subtypes.",
            "Indication": "Advanced urothelial carcinoma",
            "Drug": "Avelumab",
            "PFS": "Median PFS: Avelumab+BSC: 4.2 months (95% CI: 2.0-7.2), BSC: 2.0 months (95% CI: 1.9-3.5); Stratified HR for PFS: 0.52 (95% CI: 0.33-0.83); 2-year PFS rate: Avelumab+BSC: 19.7% (95% CI: 9.3-32.9), BSC: 3.9% (95% CI: 0.7-11.9); 3-year PFS rate: Avelumab+BSC: 19.7% (95% CI: 9.3-32.9), BSC: 3.9% (95% CI: 0.7-11.9)",
            "OS": "Median OS: Avelumab+BSC: 19.3 months (95% CI: 13.6-30.7), BSC: 14.0 months (95% CI: 9.6-25.1); 3-year OS rate: Avelumab+BSC: 37.1% (95% CI: 22.5-51.8), BSC: 31.3% (95% CI: 19.0-44.4); Stratified HR for OS: 0.74 (95% CI: 0.44-1.24)",
            "ORR": "None",
            "AE": "Treatment-related adverse events (TRAEs) of any grade: Avelumab+BSC: 83.7%, BSC: 1.8%; Grade \u22653 TRAEs: Avelumab+BSC: 20.9%, BSC: 0%",
            "Other": "None",
            "Conclusion": "This exploratory analysis shows the long-term efficacy and safety of avelumab 1L maintenance in pts with histological subtypes in JAVELIN Bladder 100. No new safety concerns were identified. These results were consistent with those in the overall population and support the use of avelumab 1L maintenance in pts with aUC without progression following 1L PBC, including pts with predominantly UC mixed with <50% histological subtype component."
        },
        {
            "Title": "Preliminary efficacy and safety results from RC48-C017: A phase II study of neoadjuvant treatment with disitamab vedotin plus toripalimab in patients with muscle invasive bladder cancer (MIBC) with HER2 expression.",
            "Indication": "Muscle invasive bladder cancer with HER2 expression",
            "Drug": "Disitamab vedotin plus toripalimab",
            "PFS": "None",
            "OS": "None",
            "ORR": "Pathologic response rate: 75.9% (22/29)",
            "AE": "Common treatment-related adverse events (TRAEs) were mostly Grade 1 or 2, including alopecia (36.4%), alanine aminotransferase increased (31.8%), aspartate aminotransferase increased (25.0%), rash (22.7%), gamma-glutamyl transferase increased (22.7%), peripheral sensory neuropathy (22.7%), and asthenia (20.5%). Grade 3-4 TRAEs were reported in 15.9% of pts. No deaths occurred.",
            "Other": "Pathologic complete response (pCR) rate: 62.1% (18/29)",
            "Conclusion": "Neoadjuvant treatment with DV plus toripalimab showed promising efficacy results and was well tolerated in operable MIBC pts with HER2 expression. These results will support further investigation for DV plus toripalimab in this population."
        },
        {
            "Title": "Correlation of peripheral blood monocytic myeloid-derived suppressor cells (M-MDSC) and T-cell receptor (TCR) dynamics with clinical outcomes in patients (pts) with metastatic urothelial carcinoma (mUC) treated with nivolumab (NIVO).",
            "Indication": "Metastatic urothelial carcinoma",
            "Drug": "Nivolumab",
            "PFS": "Higher pre-treatment M-MDSCs were associated with worse PFS (HR 1.07; 95% CI, 1.01-1.14; p=0.025) and after adjustment for liver disease and PD-L1 at baseline (HR 1.12; 95% CI, 1.04-1.21, p=0.004). PFS significantly differed between BTAC high (high: > median 2,012 clones) and low groups at wk 2, with improved PFS in the high group (log-rank p=0.026).",
            "OS": "Higher pre-treatment M-MDSCs were associated with worse OS (HR 1.07; 95% CI, 1.00-1.14; p=0.038) and after adjustment for liver disease and PD-L1 at baseline (HR 1.11; 95% CI, 1.03-1.20, p=0.010). OS rate for the BTAC high group was 34% vs. 16% for the low group at wk 2, but this did not achieve significance (p=0.098).",
            "ORR": "None",
            "AE": "None",
            "Other": "Baseline M-MDSCs did not significantly differ between responders and non-responders, but were significantly lower in patients with clinical benefit (median 12.8 [IQR: 10.7-15.9] vs. median 15.6 [IQR: 13.5-18.3]; p=0.039), and remained significantly associated after adjustment for PD-L1 score in a multivariable model (OR 0.82; 95% CI, 0.67-0.97; p=0.037). A higher number of tumor-associated clones in the blood (BTACs) at baseline was associated with response (p=0.049). Overall, BTACs significantly increased at wk 2 (p < 0.001), and wk 2 values were associated with response (p=0.003).",
            "Conclusion": "Peripheral M-MDSCs may promote resistance to PD-1 blockade in pts with mUC. NIVO stimulated tumor-specific TCR clones in the blood, which correlated with improved response and outcomes."
        },
        {
            "Title": "Association of dietary fructose with survival of patients (pts) with metastatic cancer of the urothelium (UC) and renal cell carcinoma (RCC) on immune checkpoint blockade (ICB)",
            "Indication": "Metastatic cancer of the urothelium (UC) and renal cell carcinoma (RCC)",
            "Drug": "Immune checkpoint blockade (ICB)",
            "PFS": "UC: UV HR 1.59 (95% CI 0.87-2.91), p=0.13; MV HR 3.94 (95% CI 1.57-9.90), p=0.004. RCC: UV HR 0.43 (95% CI 0.19-1.01), p=0.05; MV HR 0.52 (95% CI 0.16-1.70), p=0.28",
            "OS": "UC: UV HR 1.86 (95% CI 0.80-4.34), p=0.15; MV HR 3.00 (95% CI 0.78-11.47), p=0.11. RCC: UV HR 0.26 (95% CI 0.08-0.83), p=0.02; MV HR 0.87 (95% CI 0.10-7.4), p=0.90",
            "ORR": "None",
            "AE": "None",
            "Other": "High fructose and fiber intake correlated (rho 0.66).",
            "Conclusion": "Dietary fructose is associated with shorter PFS in UC pts treated with ICB, but not RCC pts. Discrepant results between UC and RCC require further study and may be attributable to differences in therapy or cancer biology."
        },
        {
            "Title": "Updated results from a phase II study of penpulimab plus anlotinib as first-line therapy in metastatic urothelial cancer (mUC)",
            "Indication": "Metastatic urothelial cancer (mUC)",
            "Drug": "Penpulimab plus anlotinib",
            "PFS": "12.1 months (95% CI, 3.5-20.7)",
            "OS": "Not reached; 12-month OS rate was 100%",
            "ORR": "62.5% (95% CI, 40.6-81.2)",
            "AE": "Frequent treatment-related adverse events of all grades included creatinine increased (16.7%) and transaminase elevation (10.0%). Grade 4 toxicity of liver dysfunction occurred in two patients (6.9%). No treatment-related death was observed.",
            "Other": "DCR was 95.8% (95% CI, 78.9-99.9)",
            "Conclusion": "Penpulimab plus anlotinib demonstrated promising antitumor activity and manageable safety profile with no new safety signal in mUC."
        },
        {
            "Title": "A pilot study of tazemetostat and pembrolizumab in advanced urothelial carcinoma (ETCTN 10183)",
            "Indication": "Advanced urothelial carcinoma (UC)",
            "Drug": "Tazemetostat and pembrolizumab",
            "PFS": "Arm A: 3.06 months (95% CI: 1.38-7.75); Arm B: 3.02 months (95% CI: 2.4-5.65)",
            "OS": "None",
            "ORR": "Arm A: 30% partial response, 30% stable disease, 30% progressive disease; Arm B: 20% partial response, 30% stable disease, 20% progressive disease",
            "AE": "Treatment-related G3/4 adverse events were observed in 8 patients, most common were anemia and lymphopenia (n=2 each). Treatment-related G1/2 adverse events included nausea (n=6); skin and subcutaneous tissue disorders (n=3); anemia, lymphopenia, and thrombocytopenia (n=2 each).",
            "Other": "No dose-limiting toxicities in the safety lead-in phase (n=6 pts), and the RP2D for tazemetostat was established at 800mg BID.",
            "Conclusion": "Tazemetostat 800mg BID and pembrolizumab combination is feasible and well-tolerated in patients with advanced UC. In this pilot study, improvement in efficacy relative to historical controls of pembrolizumab alone appears to be modest. Response in tumors with COMPASS-related mutations and EZH2 activity will be evaluated."
        },
        {
            "Title": "Evorpacept plus enfortumab vedotin in patients (Pts) with locally advanced or metastatic urothelial carcinoma (la/mUC): Phase 1a dose escalation results.",
            "Indication": "Locally advanced or metastatic urothelial carcinoma",
            "Drug": "Evorpacept plus enfortumab vedotin",
            "PFS": "None",
            "OS": "None",
            "ORR": "63% (1 CR, 9 PR)",
            "AE": "Fatigue, diarrhea, abnormal loss of weight, dysgeusia, decreased appetite, hyperglycemia, constipation, hypomagnesemia, increased lacrimation, nausea, peripheral sensory neuropathy, rash maculo-papular, urinary tract infection (UTI). Serious adverse events: G3 UTI (1 pt), G4 neutrophil count decreased and G3 UTI (1 pt).",
            "Other": "No dose-limiting toxicities (DLTs) observed. Maximum tolerated dose (MTD) not reached. Maximum administered dose (MAD) of EVO was 30 mg/kg Q2W combined with EV.",
            "Conclusion": "Evorpacept plus enfortumab vedotin is well tolerated at doses evaluated with no MTD reached and a MAD of 30 mg/kg Q2W. The combination shows early promising clinical activity compared to an ORR of 41% with EV alone in pts with la/mUC who had previously received platinum-based chemotherapy and a PD-1/L1 inhibitor. Further investigation in this refractory population, including patients with prior EV exposure, is warranted."
        },
        {
            "Title": "SOGUG-Vexillum: Phase II non-randomized clinical trial of nivolumab/ipilimumab maintenance following first-line chemotherapy in unresectable locally advanced or metastatic urothelial cancer.",
            "Indication": "Unresectable locally advanced or metastatic urothelial cancer",
            "Drug": "Nivolumab plus ipilimumab",
            "PFS": "Median PFS: 4.3 months (95% CI: 3.2-NR), 4-month PFS rate: 64% (95% CI: 47.7-85.9), Median cPFS: 9.4 months (95% CI: 8-NR)",
            "OS": "6-month OS rate: 76.4% (95% CI: 60-97.2), Median cOS: 15.4 months (95% CI: 15.4-NR)",
            "ORR": "None",
            "AE": "Grade \u22653 toxicities reported in 8 (32%) patients, most common: immune-mediated hepatitis (8%), ALT/AST increased (8%), diarrhea (8%). Nivolumab doses were delayed in 8 (32%) patients and ipilimumab in 3 (12%) for management of toxicity. Nivolumab and ipilimumab were permanently discontinued due to toxicity in 3 (12%) and 1 (4.2%) patients, respectively.",
            "Other": "None",
            "Conclusion": "Maintenance therapy with nivolumab and ipilimumab showed promising efficacy in delaying disease progression in patients with unresectable urothelial cancer that did not progress after first-line platinum-based chemotherapy. The 4-month PFS rate was 64%, and the 6-month OS rate was 76.4%. Final survival results are awaited."
        },
        {
            "Title": "Association of ephrin B2 (B2) expression on overall survival (OS) and resistance to PD1/L1 inhibitors (inh) in metastatic urothelial carcinoma (mUC).",
            "Indication": "Metastatic urothelial carcinoma",
            "Drug": "PD1/L1 inhibitors",
            "PFS": "None",
            "OS": "Median OS: 17.2 months (95% CI: 13.5, 23.8). Median OS for B2 Hi: 14.5 months (95% CI: 9.4, 21.0) vs. B2 Lo: 24.0 months (95% CI: 13.7, 60.8)",
            "ORR": "21% (95% CI: 14%, 29%). ORR for B2 Hi: 12% vs. B2 Lo: 33%",
            "AE": "None",
            "Other": "None",
            "Conclusion": "B2 Hi is associated with poor OS and poor ORR in PD1/L1 inhibitor monotherapy treated mUC, acting as a biomarker of disease outcome. These data suggest B2-B4 pathway to be a mechanism of resistance to PD1/L1 inhibitors and a therapeutic target."
        },
        {
            "Title": "FGFR3 alterations (FGFRalt) in patients (pts) who develop locally advanced or metastatic urothelial cancer (mUC), and their association with tumor subtype and clinical outcomes in pts treated with erdafitinib (erda) vs. pembrolizumab (pembro).",
            "Indication": "Locally advanced or metastatic urothelial cancer",
            "Drug": "Erdafitinib vs. pembrolizumab",
            "PFS": "PFS in LumP subtype: erdafitinib 5.5 months vs. pembrolizumab 2.7 months. ITT population: erdafitinib 4.4 months vs. pembrolizumab 2.7 months",
            "OS": "OS in LumP subtype: erdafitinib 10.9 months vs. pembrolizumab 12.9 months. ITT population: erdafitinib 10.9 months vs. pembrolizumab 11.1 months",
            "ORR": "ORR in LumP subtype: erdafitinib 41.7% vs. pembrolizumab 19.7% (p=0.01). ITT population: erdafitinib 40.0% vs. pembrolizumab 21.6%",
            "AE": "None",
            "Other": "Significant proportion of LumP subtype in tumors harboring FGFRalt compared to FGFRWT (78.3% vs. 36.9%, p<0.001). Differential association of FGFRalt types with subtypes: 3.2% of fusions and 14.1% of mutations detected in Ba/Sq subtype while 74.2% and 79.3%, respectively, detected in LumP.",
            "Conclusion": "A significant overlap was identified between genomic selection of FGFRalt and the LumP subtype. Clinical outcomes based on transcriptomic analyses were consistent with those observed in the ITT population. These findings warrant further understanding of LumP tumors and association to treatment response."
        },
        {
            "Title": "Inferring PDL-1 status from H&E images using digital pathology to identify patients responsive to anti-PD(L)-1 immuno-oncology therapy for bladder cancer trials.",
            "Indication": "Bladder cancer",
            "Drug": "Pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The model achieved AUC=0.82 and AUC=0.8 on holdout and independent datasets respectively. Survival probability for PDL1-High patients is higher than PDL1-Low patients.",
            "Conclusion": "We developed a computer vision algorithm to infer PD-L1 expression from routine H&E images. The algorithm shows strong performance at classifying PD-L1 status and proficiency in inferring outcomes to anti-PD(L)-1 therapy in a small bladder cancer dataset. This tool represents a novel, rapid means to assess whether a patient may respond to anti-PD(L)-1 therapies from common H&E-stained images and has the potential to guide patient care decisions in clinic."
        },
        {
            "Title": "Clinical outcomes of sacituzumab govitecan (SG) after prior exposure to enfortumab vedotin (EV) in patients with metastatic urothelial carcinoma (mUC).",
            "Indication": "Metastatic urothelial carcinoma",
            "Drug": "Sacituzumab govitecan (SG)",
            "PFS": "2.1 months (95% CI 1.9, 2.5)",
            "OS": "6.0 months (95% CI 4.5, 7.0)",
            "ORR": "10% (95% CI 4.3%, 18.3%)",
            "AE": "Rates of G3-4 neutropenia, anemia, and thrombocytopenia were 36%, 36%, and 4% respectively.",
            "Other": "Disease control rate (DCR=CR+PR+SD) of 30% (95% CI 20.3%, 41.3%). Dose reductions were required upfront in 18%, on treatment in 44% or both in 7%. Prophylactic granulocyte stimulating factor (GCSF) was used in 57 patients (70%).",
            "Conclusion": "In our large cohort of real-world advanced mUC patients with prior exposure to EV, SG resulted in limited clinical efficacy compared to previous reports. There was an association between SG administration directly after EV and improved clinical outcomes. Further investigations are warranted to explore optimal treatment sequencing."
        },
        {
            "Title": "Tumor-specific MHC class II upregulation associated with response to anti-PD-L1 therapy in patients with urothelial cancer.",
            "Indication": "Urothelial cancer",
            "Drug": "Atezolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Upregulation of MHC class II on tumor cells in anti-PD-L1 responding patients is a novel finding. Together with the TLS presence, they offer insights into potential mechanisms underlying treatment response, thus aiding in identifying predictive biomarkers for ICI therapy in MIBC.",
            "Conclusion": "Upregulation of MHC class II on tumor cells in anti-PD-L1 responding patients is a novel finding. Together with the TLS presence, they offer insights into potential mechanisms underlying treatment response, thus aiding in identifying predictive biomarkers for ICI therapy in MIBC."
        },
        {
            "Title": "Updated interim analysis of a phase I/Ib study of enfortumab vedotin plus cabozantinib in patients with metastatic urothelial carcinoma.",
            "Indication": "Metastatic urothelial carcinoma",
            "Drug": "Enfortumab vedotin plus cabozantinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "88.9% with 7 responders achieving PR and 1 CR; the remaining 1 patient achieved SD with 18.94% target lesion reduction to date and remains on trial.",
            "AE": "88.9% had a grade \u22653 treatment-related AE; the most common were neutropenia, hyponatremia, AKI, and fatigue (all 22.2%).",
            "Other": "Median target lesion reduction is 52.51% (18.94-100). Patients on cabo 40mg and cabo 20mg have a 100% and 60% dose reduction rate, respectively.",
            "Conclusion": "This is the first clinical trial combining an ADC and TKI in mUC. These preliminary data suggest that the combination of EV and cabo is safe and tolerable with an AE profile consistent with that previously seen with each agent. Encouraging early evidence of activity has been observed. Enrollment of mUC patients into dose expansion with cabo 20mg daily continues and further results including long-term outcomes and correlatives will be reported at a later date."
        },
        {
            "Title": "Longitudinal analysis of circulating tumor DNA in localized and metastatic FGFR3-mutated urothelial carcinoma of bladder and upper tract including urothelial cancer.",
            "Indication": "Urothelial carcinoma (UC)",
            "Drug": "Erdafitinib",
            "PFS": "nmUC: ctDNA- patients remained progression-free at 13 months, ctDNA+ patients had mTTP of 3 months; mUC: 74% of patients with >50% ctDNA reduction remained progression-free at 11 months, mTTP in 8 patients was 9.5 months",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Quantitative reduction in ctDNA samples aligned with radiographic responses in 98.5% of samples analyzed",
            "Conclusion": "Our findings underscore the potential for personalized assessment of tumor-informed ctDNA dynamics as a promising prognostic biomarker in patients with both localized and metastatic UC. Further prospective studies are necessary to validate utility for ctDNA guided treatment de-escalation in mUC."
        },
        {
            "Title": "Effect of antibiotic, proton pump inhibitor, H2 blocker, metformin and statin use on outcomes with immune checkpoint inhibitors (ICIs) in patients (pts) with metastatic urothelial carcinoma (mUC) in a real-world setting.",
            "Indication": "Metastatic urothelial carcinoma (mUC)",
            "Drug": "Atezolizumab, Pembrolizumab, Avelumab",
            "PFS": "Abx within 60 days post-ICI start: HR 1.391 (1.1\u20131.8), p=0.014; PPI within 60 days pre-ICI: HR 1.512 (1.1\u20132.0), p=0.005; Metformin within 60 days pre-ICI: HR 1.433 (1.0\u20132.1), p=0.049",
            "OS": "Abx within 60 days pre-ICI: HR 1.718 (1.3\u20132.2), p<0.0001; PPI within 60 days pre-ICI: HR 1.512 (1.1\u20132.0), p=0.005",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "In our large cohort of pts with mUC treated with either A, P, or Av, (Abx), (PPI) and (M) use 60 days prior to ICI start were associated with worse OS and PFS. These findings have the potential to influence clinical practice, considering a higher threshold for prescribing antibiotics or PPIs in mUC pts planned to start ICI. These findings warrant further validation in prospective trials."
        },
        {
            "Title": "Cost-effectiveness analysis of contemporary first-line (1L) agents in locally advanced/metastatic urothelial carcinoma (la/mUC).",
            "Indication": "Locally advanced/metastatic urothelial carcinoma (la/mUC)",
            "Drug": "Enfortumab vedotin-pembrolizumab (EVPembro) and gemcitabine-cisplatin-nivolumab (GemCisNivo)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Quality adjusted life years (QALYs) and incremental cost-effectiveness ratios (ICERs)",
            "Conclusion": "In terms of US payer\u2019s perspective, GemCisNivo is most likely to be cost-effective in cisplatin-eligible pts at the WTP of 100,000 $/QALY. Limiting the number of cycles for EVPembro may render it as a cost-effective 1L treatment in la/mUC pts regardless of cisplatin-eligibility status."
        },
        {
            "Title": "Efficacy and safety of atezolizumab concurrent with radiotherapy in patients with muscle-invasive bladder cancer: An interim analysis of the atezobladder preserve phase II trial (SOGUG-2017-A-IEC(VEJ)-4).",
            "Indication": "Muscle-invasive bladder cancer (MIBC)",
            "Drug": "Atezolizumab (ATZ)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "37 (94.9%) pts experienced AEs (23 [59.0%] pts grade 1 AE, 13 [33.3%] pts grade 3, and 1 [2.6%] pt grade 3), with asthenia (21 pts) and diarrhea (17 pts) being most common. AEs related to EBRT occurred in 24 (61.5%) pts; those related to ATZ in 21 (53.8%). 13 (33.3%) pts had at least one SAE (including renal failure in 3 pts and hepatotoxicity in one). One (2.6%) pt had at least one SAE related to EBRT and 3 (7.7%) pts SAEs related to ATZ. AEs leading to treatment discontinuation occurred in one (2.6%) pt and AEs leading to death in 2 (5.1%), in one of them related to treatment.",
            "Other": "Pathological complete response (pCR) achieved in 26 (100%) pts with pathological assessment at safety visit; none underwent cystectomy.",
            "Conclusion": "Interim results suggest that ATZ combined with EBRT seems to be effective in achieving pCR in a vulnerable elderly population with multiple comorbidities. The safety profile appears manageable. The final analysis of this study will provide valuable insights into the effect of ATZ with EBRT on clinical outcomes, such as survival, in addition to updated safety data."
        },
        {
            "Title": "Clinical efficacy and biomarker analysis of pre-operative ipilimumab plus nivolumab in stage III urothelial cancer: The NABUCCO trial update.",
            "Indication": "Stage III urothelial cancer (UC)",
            "Drug": "Ipilimumab (ipi) + nivolumab (nivo)",
            "PFS": "Progression-free survival (PFS) at 2 years was 75% upon ipi-high (C1+2A) and 67% upon ipi-low (C2B).",
            "OS": "2-year overall survival (OS) was 90% upon ipi-high (C1+2A) and 73% upon ipi-low (C2B).",
            "ORR": "None",
            "AE": "None",
            "Other": "Tumor mutational burden (TMB) was higher in responding pts (#ypT1N0) across treatment arms (p=0.00068). Treatment response was associated with PD-L1 positivity (CPS p=0.048; TPS p=0.004; IC p=0.016). Increased RNA expression of immune-related genes such as IDO1, CTLA4, and PD-L1, and signatures of T-helper 1- and T-follicular helper cells at baseline were associated with response in pts treated in the ipi-low arm, but not in the ipi-high arms. Increased baseline RNA expression of a TGFb signature was associated with non-response. Non-responders to ipi/nivo demonstrated enhanced RNA expression of CD8 T-cell and stromal signatures.",
            "Conclusion": "Both OS and PFS at 2 years were numerically better in UC pts treated with a high ipi dose. Overall, TMB and PD-L1 positivity were associated with treatment response regardless of ipi dose. Pre-existing intratumoral T-cell immunity appeared to be required to induce an anti-tumor response in UC pts treated with ipi-low."
        },
        {
            "Title": "Phase II trial of intravesical camrelizumab in BCG-unresponsive high-risk non-muscle invasive bladder cancer.",
            "Indication": "BCG-unresponsive high-risk non-muscle invasive bladder cancer (NMIBC)",
            "Drug": "Camrelizumab",
            "PFS": "Both median RFS and PFS was 12.68 months (95% CI 6.31-NE).",
            "OS": "None",
            "ORR": "None",
            "AE": "Grade \u22653 treatment-emergent adverse events (TEAEs) occurred in 2 (14.3%) patients, one with urinary tract infection and the other with vertebrobasilar insufficiency. Serious TEAEs occurred in one (7.1%) patient. No deaths were reported.",
            "Other": "3-month EFS rate of 92.3% (95% CI 77.8-100), a 6-month EFS rate of 75.5% (95% CI 51.4-99.7) and a 12-month EFS rate of 50.3% (95% CI 22.1-78.6). PD-L1 expression was not related to treatment efficacy. Protein sequencing revealed a total of 296 proteins with differential expression between responsive and unresponsive patients. Analysis using Gene Ontology (GO) enrichment and Kyoto Encyclopedia of Genes and Genomes (KEGG) pathway demonstrated that the responsive group was associated with activated biological processes, including T cell receptor signaling pathway, alpha-beta T cell activation, and adaptive immune response.",
            "Conclusion": "Intravesical camrelizumab demonstrated promising anti-tumor activity and acceptable tolerance in patients with BCG-unresponsive NMIBC who either declined or were ineligible for radical cystectomy. This suggests its potential as an effective non-surgical and topical treatment option for this specific population. The efficacy was attributed to enhanced immune pathway activation."
        },
        {
            "Title": "A phase II clinical trial of toripalimab combined with cisplatin plus gemcitabine chemotherapy as neoadjuvant treatment for muscle-invasive bladder cancer.",
            "Indication": "Muscle-invasive bladder cancer (MIBC)",
            "Drug": "Toripalimab, Gemcitabine, Cisplatin",
            "PFS": "1-year PFS: 85.2%, 3-year PFS: 77.6%",
            "OS": "1-year OS: 96.2%, 3-year OS: 84.6%",
            "ORR": "Pathological complete response (pCR) rate: 40.7%, Pathological response (PaR) rate: 63%",
            "AE": "80% experienced treatment-related adverse reactions, most common AEs: pruritus (33.3%), fatigue (26.7%), nausea (23.3%), immune-related AEs: 30%",
            "Other": "Patients with PD-L1 expression \u22655% had pCR rate of 43.8% and PaR rate of 68.8%. Patients with PD-L1 expression <5% had pCR rate of 36.4% and PaR rate of 63.6%. No grade 4 AEs or above. Two patients experienced grade 3 AEs.",
            "Conclusion": "Toripalimab combined with GC chemotherapy demonstrates good efficacy and tolerability and is an alternative option for NAT of MIBC."
        },
        {
            "Title": "Outcomes of patients with bacillus Calmette-Gue\u00b4rin\u2013unresponsive high-risk non\u2013muscle-invasive bladder cancer who demonstrated nonresponse to pembrolizumab in KEYNOTE-057: A post hoc analysis.",
            "Indication": "High-risk non\u2013muscle-invasive bladder cancer (NMIBC)",
            "Drug": "Pembrolizumab",
            "PFS": "36-month PFS rates: 78% (upfront RC), 68% (delayed RC), 86% (BST alone)",
            "OS": "Median OS not reached across all groups",
            "ORR": "None",
            "AE": "None",
            "Other": "Pathologic stages for upfront RC: T0 (26%), NMIBC (64%), MIBC (10%). Pathologic stages for delayed RC: T0 (15%), NMIBC (67%), MIBC (15%).",
            "Conclusion": "Oncologic outcomes were similar between patients treated with BST or RC following nonresponse to pembrolizumab. Patients who underwent upfront vs delayed RC had similar pathologic outcomes. Data suggest that patients were not harmed by BST following pembrolizumab and that a window of safety may exist for implementing second-line BST prior to RC."
        },
        {
            "Title": "A multi-institutional observer study of AI-aided oncologists\u2019 performance on treatment response assessment of bladder cancer.",
            "Indication": "Muscle-invasive bladder cancer (MIBC)",
            "Drug": "Neoadjuvant chemotherapy (NAC)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "CDSS-T improves oncologists\u2019 accuracy in TRA, increases intra-observer agreement, and reduces intra-observer variability.",
            "Conclusion": "CDSS-T improves oncologists\u2019 accuracy in TRA, increases intra-observer agreement, and reduces intra-observer variability. A prospective study is ongoing in MIBC to validate the findings."
        },
        {
            "Title": "Tumor fraction and copy number burden from urinary cell-free tumor DNA (utDNA) to predict minimal residual disease prior to repeat-transurethral resection in high-risk non-muscle invasive bladder cancer (HR-NMIBC).",
            "Indication": "High-risk non-muscle invasive bladder cancer (HR-NMIBC)",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Minimal residual disease can be detected using utDNA prior to rTURBT with high accuracy, making this a promising urinary biomarker.",
            "Conclusion": "Minimal residual disease can be detected using utDNA prior to rTURBT with high accuracy, making this a promising urinary biomarker. If validated, the combined approach using CNB and tf from utDNA for the detection of MRD can be used to predict patients in need of maximal resection prior to starting intravesical therapy."
        },
        {
            "Title": "Final results of CORE-001: A phase-2, single arm study of cretostimogene grenadenorepvec in combination with pembrolizumab in patients with BCG-unresponsive, non-muscle invasive bladder cancer with carcinoma in situ.",
            "Indication": "BCG-Unresponsive, High-Risk, non-Muscle Invasive Bladder Cancer with Carcinoma in Situ (BCG-UR HRNMIBC with CIS)",
            "Drug": "Cretostimogene grenadenorepvec and pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "CR at 12 months: 57% (20/35), CR at any time: 83% (29/35)",
            "AE": "Consistent with the individual agents and demonstrate no synergistic toxicity.",
            "Other": "Median DOR has not been reached but exceeds 21 months. Current CR rate at 24 months is 46% (16/35). Cystectomy-free survival at 21 months is 80%.",
            "Conclusion": "The efficacy and safety of cretostimogene plus pembrolizumab for treatment of BCG-UR, HRNMIBC with CIS demonstrates best-in-class CR and DOR compared to current FDA-approved therapies, with an acceptable AE profile. Further investigation of this promising combination therapy is warranted and may serve to address a considerable unmet need."
        },
        {
            "Title": "Single agent axitinib in the management of patients with progressive pheochromocytoma and paraganglioma.",
            "Indication": "Malignant pheochromocytomas and paragangliomas",
            "Drug": "Axitinib",
            "PFS": "Median PFS: 7.9 months",
            "OS": "Median OS: 29 months",
            "ORR": "Partial response in 35.3% of patients, with a median reduction of tumor measurements of 34% and a median duration of response of 7.4 months.",
            "AE": "Axitinib was well-tolerated with treatment-emergent adverse events including G1/2 fatigue, diarrhea, mucositis, and PPE in 42% to 63% and G1/2/3 hypertension in 79%, all managed with adjustments in antihypertensive medications.",
            "Other": "None",
            "Conclusion": "These results together with similar results reported recently with sunitinib provide strong evidence for the efficacy of sunitinib and axitinib, two VEGF tyrosine kinase inhibitors with similar characteristics, in the management of malignant pheochromocytoma and paragangliomas. Axitinib should be started at a dose of 5 mg bid and adjusted as tolerated. Despite its use in individuals with often difficult to treat hypertension, blood pressure management did not present a difficult challenge."
        },
        {
            "Title": "Comparing treatment modalities for T2N0M0 muscle invasive bladder cancer: A propensity score analysis with the National Cancer Database.",
            "Indication": "Muscle-invasive Bladder Cancer (MIBC)",
            "Drug": "Neoadjuvant chemotherapy (NAC), Radical cystectomy (RC), Bladder preservation chemoradiotherapy (BPCRT)",
            "PFS": "None",
            "OS": "Low-risk group: BPRCT vs NAC+RC: 34 vs 76 months (HR=1.81, 95%CI=(1.25-2.62), p=0.0017); RC vs NAC+RC: No significant difference (p=0.3459). High-risk group: BPRCT vs NAC+RC: 22 vs 68 months (HR=2.19, 95%CI=(1.86-2.59), p<0.0001); RC vs NAC+RC: 79 vs 99 months (HR=1.2, 95%CI=(1.11-1.3), p<0.001)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "This nationwide study showed that RC was the optimal treatment for low-risk stage 2 MIBC, with improved OS compared with BPCRT and equivalent OR with NAC+RC, without the added cost or morbidity. For high-risk patients, NAC+RC was associated with a significant improvement of OS compared to BPCRT and RC alone."
        },
        {
            "Title": "Prognostic factors of relapse in surgically resected small cell neuroendocrine carcinomas of the urothelial tract (SCNEC-URO).",
            "Indication": "Small cell neuroendocrine carcinomas of the urothelial tract (SCNEC-URO)",
            "Drug": "Neoadjuvant chemotherapy (SCNEC regimens, UC regimens)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "5-year cumulative incidence function (CIF) of relapse: pCR: 18.5% [11.1%-27.4%], Any SCNEC: 69.6% [58.6%-78.9%], Non-SCNEC: 31.6% [17.6%-46.7%]. Staging at resection: pT0N0: 12.8% [5.6%-23.2%], pTisN0: 25% [11.6%-41%], pT1N0: 25% [5.4%-51.7%], pT2 or greater, but N0: 52.4% [41%-62.7%]",
            "Conclusion": "Besides staging at diagnosis and surgery, morphology at resection is a key predictor of relapse. Persistence of the SC component predicts a higher chance of relapse than its eradication and persistence of the UC component. A tailored adjuvant approach addressing the residual SC component at definitive surgery is needed to improve outcomes and decrease relapse."
        },
        {
            "Title": "Survival outcomes of small cell variant bladder cancer: Analysis from nationwide study.",
            "Indication": "Small cell carcinoma of bladder (SCB)",
            "Drug": "Perioperative systemic therapy (PST), Concurrent chemoradiation (CR), Surgery only (S), Radiation therapy only (RT), Systemic therapy only (ST)",
            "PFS": "None",
            "OS": "PST: 64.49 months (CI: 41.4-75.1, P<0.001), CR: 29.27 months (CI: 22.9-35.88), S: 14.13 months (CI: 9.92-21.65), ST: 21.29 months (CI: 18.53-23.69), RT: 7.82 months (CI: 6.24-9.53)",
            "ORR": "None",
            "AE": "None",
            "Other": "Multivariate analysis: PST vs S: HR=0.46 (CI: 0.37-0.58), CR vs S: HR=0.59 (CI: 0.47-0.73), ST vs S: HR=0.68 (CI: 0.55-0.83), RT vs S: HR=1.59 (CI: 1.24-2.05)",
            "Conclusion": "Treatment approaches with either systemic therapy combined with definitive surgery or concurrent chemoradiation enhanced survival outcomes, highlighting the significance of a multimodal approach for patients with SCB."
        },
        {
            "Title": "FIT-001: A phase 1 clinical trial of the farnesyltransferase inhibitor KO-2806 alone or as part of combination therapy for advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "KO-2806",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Safety, tolerability, pharmacokinetics, pharmacodynamics, and preliminary antitumor activity",
            "Conclusion": "The study began accrual in October 2023."
        },
        {
            "Title": "PRadR: PSMA targeted radionuclide therapy in adult patients with metastatic clear cell renal cancer, a multicentric phase I/II.",
            "Indication": "Metastatic clear cell renal cancer (mccRCC)",
            "Drug": "177Lu-PSMA-1",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoints: incidence of severe toxicity and disease control rate at 24 weeks (DCR-24W). Secondary endpoints: overall response rate, duration of response, progression-free survival, overall survival, and safety.",
            "Conclusion": "First patient was enrolled in November 2023."
        },
        {
            "Title": "Phase 2 trial for sequential treatment of high dose cabozantinib (CABO) or CABO plus nivolumab (NIVO) on/after progression on CABO monotherapy in advanced renal cell carcinoma (RCC): Seq-Cabo.",
            "Indication": "Advanced renal cell carcinoma (RCC)",
            "Drug": "Cabozantinib (CABO) and Nivolumab (NIVO)",
            "PFS": "Primary endpoint",
            "OS": "None",
            "ORR": "Key secondary endpoint",
            "AE": "None",
            "Other": "Disease control rate, duration of response, and overall survival",
            "Conclusion": "The study is open for enrollment."
        },
        {
            "Title": "Phase 1b/2 study of combination 177Lu-girentuximab plus cabozantinib and nivolumab in treatment naive patients with advanced clear cell RCC.",
            "Indication": "Advanced clear cell renal cell carcinoma (ccRCC)",
            "Drug": "177Lu-girentuximab, Cabozantinib, and Nivolumab",
            "PFS": "Secondary endpoint",
            "OS": "Secondary endpoint",
            "ORR": "Secondary endpoint",
            "AE": "None",
            "Other": "Primary endpoint: complete response (CR) rate. Secondary endpoints: overall response rate (ORR), progression-free survival (PFS), and overall survival.",
            "Conclusion": "The study will explore the effects of the treatment on inducing activated T cell infiltration."
        },
        {
            "Title": "Zanzalintinib (XL092) plus nivolumab in non-clear cell renal cell carcinoma: The randomized phase 3 STELLAR-304 study.",
            "Indication": "Non-clear cell renal cell carcinoma (nccRCC)",
            "Drug": "Zanzalintinib, nivolumab, sunitinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "STELLAR-304 is designed to assess the efficacy and safety of zanzalintinib + nivolumab versus sunitinib in previously untreated advanced nccRCC. The study is currently recruiting patients."
        },
        {
            "Title": "Combination tivozanib and nivolumab in advanced non-clear cell renal cell carcinoma (RCC): FORTUNE trial.",
            "Indication": "Advanced non-clear cell renal cell carcinoma (nccRCC)",
            "Drug": "Tivozanib, nivolumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "Primary endpoint: ORR by RECIST 1.1",
            "AE": "None",
            "Other": "Secondary endpoints include progression-free survival, overall survival, duration of therapy, and adverse events (AEs).",
            "Conclusion": "FORTUNE is a phase 2 trial assessing the efficacy of tivozanib and nivolumab in patients with advanced nccRCC. The trial will utilize a Bayesian optimal phase 2 (BOP2) design with planned accrual of 48 patients."
        },
        {
            "Title": "NRG-GU012: Randomized phase II stereotactic ablative radiation therapy (SABR) for metastatic unresected renal cell carcinoma (RCC) receiving immunotherapy (SAMURAI).",
            "Indication": "Metastatic unresected renal cell carcinoma (RCC)",
            "Drug": "Immunotherapy (IO)",
            "PFS": "Primary endpoint: nephrectomy and radiographic progression-free survival (nrPFS) with progression determined as per iRECIST criteria.",
            "OS": "None",
            "ORR": "Secondary endpoints include objective response rate by iRECIST and RECIST.",
            "AE": "None",
            "Other": "Secondary endpoints include radiographic progression-free survival by RECIST and iRECIST, time to second line therapy, rate of cytoreductive nephrectomy, treatment free survival, and overall survival.",
            "Conclusion": "The SAMURAI trial seeks to evaluate SABR as an alternative approach to treat the primary tumor in patients with metastatic RCC receiving IO, who are not recommended for surgery, or who decline surgery."
        },
        {
            "Title": "Advanced renal cell cancer combination immunotherapy clinical trial (ARCI-TECT; HCRN GU22-587).",
            "Indication": "Metastatic clear cell renal cell carcinoma (mccRCC)",
            "Drug": "Botensilimab, balstilimab, nivolumab, ipilimumab",
            "PFS": "Key secondary endpoints include landmark progression-free survival.",
            "OS": "None",
            "ORR": "Primary endpoint: overall response rate (ORR) per RECIST 1.1.",
            "AE": "Key secondary endpoints include rates of immune-related adverse events.",
            "Other": "Key secondary endpoints include treatment-free survival.",
            "Conclusion": "ARCITECT is a phase II trial evaluating the efficacy and safety of Bot/Bal relative to Nivo/Ipi in patients with mccRCC. The trial will explore immune and molecular predictors of response and resistance to IO/IO in tumor and blood."
        },
        {
            "Title": "Enfortumab vedotin plus pembrolizumab in the treatment of locally advanced or metastatic bladder cancer of variant histology: A phase II study.",
            "Indication": "Locally advanced or metastatic bladder cancer of variant histology",
            "Drug": "Enfortumab vedotin plus pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "Primary outcome: Overall response rate (ORR) per RECIST 1.1",
            "AE": "Incidence of adverse events per CTCAE v5.0",
            "Other": "Correlative biomarker analyses using blood- and tissue-based assays during screening and at disease progression",
            "Conclusion": "The trial is currently open for enrollment."
        },
        {
            "Title": "Phase 3 study of disitamab vedotin with pembrolizumab vs chemotherapy in patients with previously untreated locally advanced or metastatic urothelial carcinoma that expresses HER2 (DV-001).",
            "Indication": "Previously untreated locally advanced or metastatic urothelial carcinoma that expresses HER2",
            "Drug": "Disitamab vedotin with pembrolizumab vs chemotherapy",
            "PFS": "Primary endpoint: Progression-Free Survival (PFS) per blinded independent central review",
            "OS": "Primary endpoint: Overall Survival (OS)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Enrollment is ongoing in the US, Canada, and Australia and is planned globally."
        },
        {
            "Title": "A phase III randomized trial of eribulin (E) with gemcitabine (G) vs standard of care (SOC) for patients (pts) with metastatic urothelial carcinoma (mUC) refractory to or ineligible for PD/PDL1 antibody (Ab): SWOG S1937 updated design.",
            "Indication": "Metastatic urothelial carcinoma refractory to or ineligible for PD/PDL1 antibody",
            "Drug": "Eribulin with gemcitabine vs standard of care",
            "PFS": "Secondary endpoint: Progression-Free Survival (PFS)",
            "OS": "Primary endpoint: Overall Survival (OS)",
            "ORR": "Secondary endpoint: Objective Response Rate (ORR)",
            "AE": "Most common Grade 3-4 toxicities included: neutropenia 63%, anemia and fatigue 29%",
            "Other": "None",
            "Conclusion": "The amended and restructured study was approved in January of 2024."
        },
        {
            "Title": "Sacituzumab govitecan (SG) plus enfortumab vedotin (EV) for metastatic urothelial carcinoma (mUC) treatment-experienced (DAD) and with pembrolizumab (P) in treatment-na\u00efve UC (DAD-IO).",
            "Indication": "Metastatic urothelial carcinoma",
            "Drug": "Sacituzumab govitecan plus enfortumab vedotin, with pembrolizumab in treatment-na\u00efve UC",
            "PFS": "Secondary endpoint: Progression-Free Survival (PFS)",
            "OS": "Secondary endpoint: Overall Survival (OS)",
            "ORR": "Primary endpoint: RECIST 1.1 Objective Response Rate (ORR)",
            "AE": "Safety lead-in using a Bayesian toxicity monitoring",
            "Other": "None",
            "Conclusion": "The study aims to enroll 41 eligible patients in each cohort."
        },
        {
            "Title": "A phase 2/3 study of Bicycle toxin conjugate BT8009 targeting nectin-4 in patients with locally advanced or metastatic urothelial cancer (la/mUC): Duravelo-2.",
            "Indication": "Locally advanced or metastatic urothelial cancer (la/mUC)",
            "Drug": "BT8009",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoints are progression-free survival (PFS; Cohort 1) and objective response rate (ORR; Cohort 2) assessed by blinded independent central review. Secondary endpoints are ORR (Cohort 1), PFS (Cohort 2), and overall survival, duration of response, disease control rate, safety/tolerability, and health-related quality of life (Cohorts 1 and 2). Pharmacokinetics, incidence/titers of antidrug antibodies, and tumor/peripheral biomarkers are exploratory endpoints.",
            "Conclusion": "This study is actively recruiting."
        },
        {
            "Title": "INSIGHT-005: A new stratum of the explorative, open-labeled, phase I INSIGHT study to evaluate the feasibility and safety, as well as preliminary efficacy, of subcutaneous injections with IMP321 (eftilagimod alpha) in combination with PD-L1 inhibitor (avelumab) for metastatic or unresectable locally advanced urothelial carcinoma (UC)\u2014IKF-s614.",
            "Indication": "Metastatic or unresectable locally advanced urothelial carcinoma (UC)",
            "Drug": "IMP321 (eftilagimod alpha) in combination with PD-L1 inhibitor (avelumab)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint is to explore feasibility, safety, and preliminary efficacy of efti when added to avelumab in unresectable LAUC or mUC. Secondary endpoints include safety and efficacy parameters as defined by objective response, time to and duration of response, and PFS according to RECIST 1.1, OS, and biomarker analyses.",
            "Conclusion": "Currently, recruitment is ongoing."
        },
        {
            "Title": "ENLIGHTED phase 3 study: Efficacy and safety of padeliporfin vascular targeted photodynamic therapy (VTP) for treatment of low-grade upper tract urothelial cancer (LGUTUC).",
            "Indication": "Low-grade upper tract urothelial cancer (LGUTUC)",
            "Drug": "Padeliporfin vascular targeted photodynamic therapy (VTP)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "The most frequent treatment-related adverse events were: flank pain 17%, vomiting 8%, fatigue 8%, nausea 6%, hematuria 6%, all Grade 1-2, resolved within few days. Grade 3 serious adverse events 9% (flank pain, hypertension, renal colic, urinary tract infection) were resolved within 2-7 days.",
            "Other": "Primary outcome is CR on endoscopic evaluation and selective cytology at the time of primary response evaluation (28-63 days post last treatment) during padeliporfin VTP ITP.",
            "Conclusion": "To date, Padeliporfin VTP has shown evidence of safety and efficacy with preliminary data that is consistent with prior experience. Recruitment for the ENLIGHTED trial is ongoing with results expected to provide basis for approval of a new therapy that clinically benefits pts."
        },
        {
            "Title": "Use of gene expression patterns to identify unique molecular subtypes in muscle invasive bladder cancer: GUSTO.",
            "Indication": "Muscle invasive bladder cancer (MIBC)",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary outcome for Stage 3 is the rate of pathological complete response (pCR) in each molecular subtype within the experimental arm.",
            "Conclusion": "Recruitment began in September 2023. The study will open the first 20 sites ready to proceed and is accepting expressions of interest."
        },
        {
            "Title": "SOGUG-NEOWIN: A phase 2, open-label, multi-centre, multi-national interventional trial evaluating the efficacy and safety of erdafitinib (ERDA) monotherapy and ERDA and cetrelimab (CET) as neoadjuvant treatment in patients with cisplatin-ineligible muscle-invasive bladder cancer (MIBC) whose tumours express FGFR gene alterations.",
            "Indication": "Muscle-invasive bladder cancer (MIBC)",
            "Drug": "Erdafitinib (ERDA) and cetrelimab (CET)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Pathological complete response (pCR) rate, percentage of pathological downstaging response (<ypT2), event-free survival, overall survival, overall response rate, safety, tolerability, delay to surgery, biomarkers of response and resistance, quality of life (QoL) assessed using FACT-B and EQ-5D-5L, tumour response assessment via PET-MRI in a subset of patients.",
            "Conclusion": "This trial would be the first to systematically address whether ERDA + cetrelimab improves pCR in patients with FGFR-positive MIBC."
        },
        {
            "Title": "NETOS: A personalized approach of neoadjuvant therapy, including INCB099280 monotherapy and bladder preservation, for muscle-invasive urothelial bladder carcinoma (MIBC) with ctDNA monitoring.",
            "Indication": "Muscle-invasive urothelial bladder carcinoma (MIBC)",
            "Drug": "INCB099280",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Proportion of clinical complete response (cCR), safety (CTCAE v.5.0), event-free survival, bladder-intact overall survival (OS), and OS.",
            "Conclusion": "The study aims to evaluate the efficacy of INCB099280 as a maintenance therapy in biomarker-selected patients achieving cCR after the induction phase."
        },
        {
            "Title": "Adjuvant or rescue disitamab vedotin (RC48-ADC) for high-risk non-muscle invasive bladder cancer with HER2 overexpression: A phase II multi-center study.",
            "Indication": "High-risk non-muscle invasive bladder cancer (NMIBC) with HER2 overexpression",
            "Drug": "Disitamab Vedotin (RC48-ADC)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoints include safety, 12-month recurrence-free rate (Cohort A), and 3-month clinical complete response rate (Cohort B). Secondary endpoints include 6-month recurrence-free rate (Cohort A), duration of response (Cohort B), recurrence-free survival, progression-free survival, overall survival, and quality of life (assessed through EuroQoL EQ-5D and FACT-BI). Exploratory endpoints will investigate the relationship between biomarkers in tumor tissues, blood, and urine, and treatment efficacy.",
            "Conclusion": "The trial aims to investigate the efficacy and safety of RC48-ADC as adjuvant or rescue therapy for high-risk NMIBC with HER2 overexpression and to explore molecular biomarkers predicting the efficacy of RC48-ADC."
        },
        {
            "Title": "A phase 1/2 study of EG-70 (detalimogene voraplasmid) intravesical monotherapy for patients with BCG-unresponsive non-muscle invasive bladder cancer with carcinoma in situ.",
            "Indication": "BCG-unresponsive non-muscle invasive bladder cancer (NMIBC) with carcinoma in situ (CIS)",
            "Drug": "EG-70 (detalimogene voraplasmid)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary objective: evaluate efficacy (complete response (CR) rate at week 48) and safety of EG-70. Secondary objectives: evaluate progression-free survival, CR rate at 12, 24, 36, and 48 weeks, and duration of response.",
            "Conclusion": "The ongoing, registration-enabling phase 2 portion of the LEGEND study aims to recruit approximately 100 patients to evaluate the efficacy and safety of EG-70 in patients with high-risk BCG-unresponsive NMIBC with CIS."
        },
        {
            "Title": "SMART: A phase II study of sacituzumab govitecan (SG) with or without atezolizumab immunotherapy in rare genitourinary (GU) tumors such as small cell, adenocarcinoma, and squamous cell bladder/urinary tract cancer, renal medullary carcinoma (RMC) and penile cancer.",
            "Indication": "Rare genitourinary tumors",
            "Drug": "Sacituzumab govitecan (SG) with or without atezolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "Primary endpoint: ORR (RECIST v1.1) for each cohort",
            "AE": "None",
            "Other": "Secondary endpoints include safety, clinical benefit rate (CR+PR+SD), median PFS/OS for each cohort, and ORR for each histology. Exploratory analyses include determination of baseline tumor mutation and transcriptional profile, Trop2 expression, ctDNA/CTCs, peripheral blood immune subsets and cytokine profile correlation with response, and correlation of UGT1A1 allele genotype with response and safety.",
            "Conclusion": "This study utilizes a Simon minimax two-staged design, with enrollment of up to 43 evaluable patients (accrual ceiling of 60 patients)."
        },
        {
            "Title": "A phase II multicenter study of enfortumab vedotin with or without pembrolizumab in rare genitourinary tumors (E-VIRTUE).",
            "Indication": "Rare genitourinary tumors",
            "Drug": "Enfortumab vedotin with or without pembrolizumab",
            "PFS": "Secondary objective: PFS",
            "OS": "Secondary objective: OS",
            "ORR": "Primary objective: ORR in all cohorts",
            "AE": "None",
            "Other": "Exploratory objectives include estimating the level of nectin-4 expression for each histology, performing DNA and RNA sequencing of tumor and blood samples, and observing changes in levels of circulating tumor cells (CTCs) and circulating tumor DNA (ctDNA) with treatment and disease status.",
            "Conclusion": "If there are \u22652/10 objective responses in any cohort, activity will be demonstrated. Additional histologies may be added after nectin-4 data emerges to justify their inclusion."
        },
        {
            "Title": "A phase II study of lurbinectedin with or without avelumab in small cell carcinoma of the bladder (LASER).",
            "Indication": "Small cell carcinoma of the bladder (SCCB) or other high-grade neuroendocrine tumors (HGNETs) of the urinary tract",
            "Drug": "Lurbinectedin with or without avelumab",
            "PFS": "Secondary endpoint: PFS",
            "OS": "Secondary endpoint: OS",
            "ORR": "Primary endpoint: ORR",
            "AE": "None",
            "Other": "Key secondary endpoints include clinical benefit rate, PFS, OS, and DoR. Exploratory objectives include analyzing peripheral immune subsets, DNA and RNA sequencing of tumors, and measurement of ctDNA and CTCs.",
            "Conclusion": "The study will be conducted using a Simon minimax two-stage design to rule out an ORR of 5% in favor of an ORR of 25% or greater in each cohort."
        },
        {
            "Title": "Safety and immunological effects of ablative radiotherapy followed by pembrolizumab in patients with advanced adrenocortical carcinoma.",
            "Indication": "Advanced adrenocortical carcinoma (ACC)",
            "Drug": "Ablative radiotherapy followed by pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "Secondary endpoint: ORR (partial and complete response) according to RECIST v1.1, in the hepatic and extrahepatic disease",
            "AE": "Primary objective: Safety of treatment with ablative RT followed by pembrolizumab",
            "Other": "Correlative objectives include evaluation of the innate and adaptive immune response during treatment through analysis of pre- and on-treatment blood and tumor tissue samples, circulating tumor (ct)DNA analyses, and PD-L1 and next-generation sequencing testing in pre-treatment tumor tissue samples to evaluate for biomarkers of response and resistance.",
            "Conclusion": "This trial began enrollment on 9/27/2023, with 3 patients enrolled to date."
        },
        {
            "Title": "CYCLONE2: A phase 3 study of abemaciclib with abiraterone in patients with metastatic castration-resistant prostate cancer.",
            "Indication": "Metastatic castration-resistant prostate cancer (mCRPC)",
            "Drug": "Abemaciclib (ABEMA) plus abiraterone (ABI)",
            "PFS": "21.96 months for ABEMA plus ABI group vs 20.28 months for PBO plus ABI group (HR 0.829; 95% CI, 0.619\u20131.111; p=0.2123)",
            "OS": "Not inferentially tested (HR 0.927; 95% CI, 0.669\u20131.285; 38.9% maturity)",
            "ORR": "None",
            "AE": "Grade \u22653 AEs: anemia (13.6% vs 4.3%), neutropenia (12.6% vs 0.5%), ALT increased (8.7% vs 6.5%). Discontinuations due to AEs: 13.1% vs 4.3% for ABEMA plus ABI vs PBO plus ABI groups",
            "Other": "Time to PSA progression (HR 0.637; 95% CI, 0.474\u20130.856), time to symptomatic progression (HR 0.768; 95% CI, 0.522\u20131.131), time to worst pain progression (HR 0.935; 95% CI, 0.665\u20131.314)",
            "Conclusion": "Adding abemaciclib to abiraterone did not significantly increase rPFS. No OS detriment was observed, and secondary endpoints were not meaningfully improved. The combination was well tolerated, and safety was consistent with the known profiles of the individual medicines."
        },
        {
            "Title": "A randomized, double-blind, placebo-controlled trial of metformin in reducing progression among men on expectant management for low-risk prostate cancer: The MAST (Metformin Active Surveillance Trial) study.",
            "Indication": "Low-risk prostate cancer",
            "Drug": "Metformin",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "Health-related quality of life and pain in a phase 3 study of [177Lu]Lu-PSMA-617 in taxane-na\u00efve patients with metastatic castration-resistant prostate cancer (PSMAfore).",
            "Indication": "Metastatic castration-resistant prostate cancer (mCRPC)",
            "Drug": "[177Lu]Lu-PSMA-617",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Incidence of grade \u22653 AEs: 34% vs 44%, serious AEs: 20% vs 28%, AEs leading to discontinuation: 5.7% vs 5.2% for [177Lu]Lu-PSMA-617 vs ARPI change",
            "Other": "Delayed time to worsening in self-reported HRQoL and pain: FACT-P total score (7.46 vs 4.27 months, HR 0.59), EQ-5D-5L utility score (6.14 vs 3.88 months, HR 0.61), BPI-SF pain intensity (5.03 vs 3.71 months, HR 0.69)",
            "Conclusion": "[177Lu]Lu-PSMA-617 delayed time to worsening in self-reported pain and HRQoL vs change of ARPI in taxane-na\u00efve patients with PSMA-positive mCRPC."
        },
        {
            "Title": "EMBARK post hoc analysis of impact of treatment suspension (TxS) on health-related quality of life (HRQoL).",
            "Indication": "high-risk biochemically recurrent nonmetastatic hormone-sensitive prostate cancer",
            "Drug": "enzalutamide (ENZ) + leuprolide (L) and ENZ mono",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "HRQoL improvement after week 37 TxS was seen in all 3 arms, often reaching clinically meaningful threshold at week 205. Clinically meaningful improvement in hormonal treatment side effects at subsequent assessments of week 49 to week 97; however, patients slowly deteriorated with clinically meaningful deterioration at week 205 relative to week 37 with ENZ+L and P+L.",
            "Conclusion": "This post hoc analysis confirmed that TxS, as expected, leads to clinically meaningful improvements in HRQoL."
        },
        {
            "Title": "Health-related quality of life (HRQOL) results from PRESTO (AFT-19), a phase 3 randomized trial of intensification of androgen blockade in patients with high-risk biochemically relapsed castration-sensitive prostate cancer.",
            "Indication": "high-risk biochemically relapsed castration-sensitive prostate cancer",
            "Drug": "androgen deprivation therapy (ADT) plus apalutamide (Apa), or ADT plus Apa and abiraterone acetate (Abi) plus prednisone",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Mean changes from baseline to end of treatment (EOT) for each arm and each HRQOL measure showed no statistically significant mean difference between intensified androgen receptor blockade (Arm B, Arm C) vs ADT alone (Arm A) in any HRQOL measure. Numerical differences across arms for all HRQOL measures are below published minimally clinically important difference thresholds.",
            "Conclusion": "Intensified androgen receptor blockade with Apa or Apa/Abi added to 52 weeks of ADT improves PSA progression-free survival without negatively affecting HRQOL. These HRQOL results add further support to intensification of androgen blockade in patients with high-risk biochemically recurrent prostate cancer."
        },
        {
            "Title": "A clinical-genetic (CG) circulating tumor DNA (ctDNA)-based prognostic model for predicting overall survival (OS) in men with metastatic castrate-resistant prostate cancer (mCRPC) treated with potent androgen receptor inhibition (Alliance).",
            "Indication": "metastatic castrate-resistant prostate cancer (mCRPC)",
            "Drug": "enzalutamide +/- abiraterone",
            "PFS": "None",
            "OS": "Median OS and the hazard ratios by the three- and four-prognostic risk groups: Low risk: 58.9 months (95% CI = 50.1-NR), HR = 0.22 (95% CI = 0.17-0.27); Intermediate risk: 35.5 months (95% CI = 32.3-40.2), HR = 0.42 (95% CI = 0.35-0.51); Poor risk: 19.3 months (95% CI = 17.3-21.5), Reference. Four-prognostic risk groups: Low risk: 58.9 months (95% CI = 50.1-NR), HR = 0.22 (95% CI = 0.17-0.27); Intermediate risk: 35.5 months (95% CI = 32.3-40.2), HR = 0.42 (95% CI = 0.35-0.51); Poor risk: 19.3 months (95% CI = 17.3-21.5), Reference; Very poor risk: 17.0 months (95% CI = 16.0-18.9), Reference.",
            "ORR": "None",
            "AE": "None",
            "Other": "CG model identified novel ctDNA PGAs prognostic of OS and can be utilized to classify patients into risk groups useful in selecting patients in future trials of mCRPC.",
            "Conclusion": "The CG model identified novel ctDNA PGAs prognostic of OS and can be utilized to classify patients into risk groups useful in selecting patients in future trials of mCRPC."
        },
        {
            "Title": "Baseline ctDNA analyses and associations with outcomes in taxane-naive patients with mCRPC treated with 177Lu-PSMA-617 versus change of ARPI in PSMAfore",
            "Indication": "Metastatic castration-resistant prostate cancer (mCRPC)",
            "Drug": "177Lu-PSMA-617",
            "PFS": "Median rPFS was shorter for patients with ctDNA fraction >1% (HR 2.753; 95% CI 1.957\u20133.872; p < 0.0001). Median rPFS was longer with 177Lu-PSMA-617 versus ARPI change in patients with detected AR, TP53, PTEN.",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "ctDNA fraction >1% and AR, TP53, and PTEN alterations were associated with worse outcomes in PSMAfore regardless of treatment.",
            "Conclusion": "Patients with these negative prognostic biomarkers did better with 177Lu-PSMA-617 than with ARPI change."
        },
        {
            "Title": "A phase II trial of pembrolizumab plus platinum-based chemotherapy as first-line systemic therapy in advanced penile cancer: HERCULES (LACOG 0218) trial",
            "Indication": "Advanced penile squamous cell carcinoma (PSCC)",
            "Drug": "Pembrolizumab plus platinum-based chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "cORR by INV was 39.4% (95% CI 22.9-57.9); with 1 complete response and 12 partial responses.",
            "AE": "Treatment-related adverse events (AE) rate of any grade was 91.9% and grade 3-4 was 51.4%. Ten pts experienced Grade 5 AE, none of them related to study treatment. Immune-related AEs of any grade was 21.6% and grade 3-4 was 5.4%. 10.8% discontinued treatment due to AE.",
            "Other": "HPV16 and TMB are potential predictive biomarkers for efficacy.",
            "Conclusion": "HERCULES is the first trial to demonstrate the efficacy of ICI in advanced PSCC with a manageable safety profile."
        },
        {
            "Title": "A phase 1 study of JNJ-69086420 (JNJ-6420), an actinium-225 (225Ac) -labeled antibody targeting human kallikrein 2 (hK2), for metastatic castration-resistant prostate cancer (mCRPC)",
            "Indication": "Metastatic castration-resistant prostate cancer (mCRPC)",
            "Drug": "JNJ-69086420 (JNJ-6420)",
            "PFS": "None",
            "OS": "None",
            "ORR": "Confirmed objective response rate: 12.5%",
            "AE": "35/57 (61.4%) experienced grade \u22653 TEAEs, and 21 (36.8%) had a serious TEAE. TEAEs of note included thrombocytopenia (63.2%) and interstitial lung disease (ILD, 9%). Grade \u22653 TEAEs (\u226510%) included anemia (26.3%), thrombocytopenia (17.5%), lymphopenia (10.5%), and leukopenia (10.5%). 9/57 (15.8%) pts discontinued treatment due to TRAEs; 4 TRAEs resulted in death.",
            "Other": "At doses \u2265150 mCi, the PSA50 rate was 45.6%. Prolonged clinical, biochemical, and radiographic responses were noted in pts receiving doses of 150 mCi and higher.",
            "Conclusion": "In this first-in-human study, key TEAEs of JNJ-6420 were thrombocytopenia and ILD, both associated with repeated dosing. At 150 mCi, 1-2 doses of JNJ-6420 elicited profound and durable biochemical and radiographic responses."
        },
        {
            "Title": "ARV-766, a proteolysis targeting chimera (PROTAC) androgen receptor (AR) degrader, in metastatic castration-resistant prostate cancer (mCRPC): Initial results of a phase 1/2 study",
            "Indication": "Metastatic castration-resistant prostate cancer (mCRPC)",
            "Drug": "ARV-766",
            "PFS": "None",
            "OS": "None",
            "ORR": "In 28 PSA-evaluable patients with AR LBD mutations, PSA50 was 50.0%.",
            "AE": "Any grade treatment-related adverse events (TRAEs) reported in \u226510% of patients were fatigue (36%; 3% grade 3), nausea (19%; 1% grade 3), diarrhea (15%; 1% grade 3), alopecia (14%), increased blood creatinine (13%; 0 grade 3), and decreased appetite (11%; 0 grade 3); there were no grade 4 TRAEs.",
            "Other": "Preliminary pharmacokinetics indicated dose-dependent increases in ARV-766 exposure up to 320 mg QD, with exposure accumulation ranging from ~5-to 8-fold at steady state.",
            "Conclusion": "In this phase 1/2 study of pretreated patients with mCRPC, ARV-766 was well tolerated and showed promising clinical activity in those with tumors harboring AR LBD mutations."
        },
        {
            "Title": "Phase 1b study of tarlatamab in de novo or treatment-emergent neuroendocrine prostate cancer (NEPC).",
            "Indication": "Neuroendocrine prostate cancer (NEPC)",
            "Drug": "Tarlatamab",
            "PFS": "1.9 months (95% CI, 1.7, 3.5); in DLL3+ pts: 3.75 months (95% CI, 1.87, 11.01)",
            "OS": "None",
            "ORR": "10.5% (95% CI, 2.9, 24.8); in DLL3+ pts: 22.2% (95% CI, 6.4, 47.6)",
            "AE": "Treatment-related adverse events occurred in all patients, with no fatal TRAE. Most common TRAEs were cytokine release syndrome (65.0%), pyrexia (52.5%), and dyspnea (42.5%).",
            "Other": "None",
            "Conclusion": "Findings from this phase 1 study of tarlatamab in pts with NEPC demonstrated manageable safety with encouraging anti-tumor activity in DLL3 expressing NEPC. Further investigation is ongoing."
        },
        {
            "Title": "Nivolumab and ipilimumab for metastatic prostate cancer with an immunogenic signature: The NEPTUNES multi-centre two-cohort, biomarker-selected phase 2 trial.",
            "Indication": "Metastatic castration-resistant prostate cancer (mCRPC)",
            "Drug": "Nivolumab (NIVO) + Ipilimumab (IPI)",
            "PFS": "None",
            "OS": "C1: 16.2 months (95% CI 9.2-22.8); C2: 15.2 months (95% CI 8.9-NA)",
            "ORR": "CRR in C1: 40% (90% CI: 26-55%); CRR in C2: 25% (90% CI: 14-40%); Combined CRR: 32% (90% CI: 23-43%)",
            "AE": "Grade 3-4 treatment-related adverse events occurred in 63% (C1) and 31% (C2). The most common G3-4 event was diarrhea (42% in C1 and 8% in C2).",
            "Other": "Median DOR: 10.4 months (C1) and 6.4 months (C2)",
            "Conclusion": "Inflammatory infiltrate is a promising prospectively tested predictive biomarker in pre-treated mCRPC. Although NIVO 1mg/kg + IPI 3mg/kg had more toxicities than NIVO 3mg/kg + IPI 1mg/kg, the efficacy results were consistently better. This dose schedule and biomarkers should now be tested in a phase 3 clinical trial."
        },
        {
            "Title": "Prognostic PSMA-PET PROMISE nomograms for patients with prostate cancer.",
            "Indication": "Prostate cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "Overall C-indices were 0.81 or 0.78 for the quantitative or visual nomogram, respectively.",
            "ORR": "None",
            "AE": "None",
            "Other": "PSMA-PET nomograms based on PROMISE criteria were accurate in early and late stages of prostate cancer. Prediction of overall survival was superior when compared to clinical risk tools.",
            "Conclusion": "Our prognostic PSMA-PET nomograms based on PROMISE criteria were accurate in early and late stages of prostate cancer. Prediction of overall survival was superior when compared to clinical risk tools. Multi-center validation in the PROMISE registry is ongoing."
        },
        {
            "Title": "68Ga-PSMA PET/CT response and clinical outcomes in patients treated with enzalutamide as first-line therapy for metastatic castration-resistant prostate cancer (mCRPC): Results of a prospective study.",
            "Indication": "Metastatic castration-resistant prostate cancer (mCRPC)",
            "Drug": "Enzalutamide",
            "PFS": "PET-NR/PSA-NR: 3.5 months, PET-NR/PSA-R: 5.4 months, PET-R/PSA-NR: 28.9 months, PET-R/PSA-R: 47.8 months",
            "OS": "PET-NR/PSA-NR: 13.2 months, PET-NR/PSA-R: 36.8 months, PET-R/PSA-NR: 66.6 months, PET-R/PSA-R: Not reached",
            "ORR": "None",
            "AE": "None",
            "Other": "Some PET parameters may better predict survival benefit and need future validation.",
            "Conclusion": "68Ga-PSMA PET/CT response appeared more accurate than PSA monitoring to predict survival to enzalutamide initiation in mCRPC. Some PET parameters may better predict survival benefit and need future validation."
        },
        {
            "Title": "Utility of ctDNA burden as a prognostic biomarker for efficacy in TALAPRO-2: A phase 3 study of talazoparib (TALA) + enzalutamide (ENZA) vs placebo (PBO) + ENZA as first-line (1L) treatment in patients (pts) with metastatic castration-resistant prostate cancer (mCRPC).",
            "Indication": "metastatic castration-resistant prostate cancer (mCRPC)",
            "Drug": "talazoparib (TALA) + enzalutamide (ENZA)",
            "PFS": "High at BL: TALA+ENZA: 12.5 months (HR 2.63), PBO+ENZA: 8.3 months (HR 3.35); Low at BL: TALA+ENZA: NR, PBO+ENZA: 30.3 months; High at BL and low at WK9: TALA+ENZA: 16.6 months, PBO+ENZA: 10.9 months; Low at BL and WK9: TALA+ENZA: NR, PBO+ENZA: 30.5 months",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "High ctDNA burden at BL was prognostic of inferior rPFS. Conversion from high to low ctDNA at WK9 was prognostic of improved rPFS.",
            "Conclusion": "High ctDNA burden at BL was negatively prognostic, and ctDNA conversion from high to low at WK9 was prognostic of improved rPFS in TALAPRO-2. These results support the broad prognostic utility of ctDNA burden in mCRPC."
        },
        {
            "Title": "Discovery of a novel non-negative matrix factorization (NMF)-based homologous recombination deficiency (HRD) score and subsequent exploration in TALAPRO-2 (TP-2), a phase 3 study of talazoparib (TALA) + enzalutamide (ENZA) vs placebo (PBO) + ENZA as first-line treatment in patients (pts) with metastatic castration-resistant prostate cancer (mCRPC).",
            "Indication": "metastatic castration-resistant prostate cancer (mCRPC)",
            "Drug": "talazoparib (TALA) + enzalutamide (ENZA)",
            "PFS": "HRD-high: HR=0.49, P=0.0036; HRD-low: HR=0.48, P=0.0062",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "HRD score was associated with ctDNA HRR12m status. HRD-high and HRD-low pts exhibited comparable rPFS benefit in terms of HR favoring TALA+ENZA over PBO+ENZA.",
            "Conclusion": "The novel HRD score, incorporating both gene expression and HRR12 genomic attributes, was associated with ctDNA HRR12m status. HRD-high and HRD-low pts exhibited comparable rPFS benefit in terms of HR favoring TALA+ENZA over PBO+ENZA."
        },
        {
            "Title": "Association between prostate-specific antigen (PSA) level <0.2 ng/mL and risk of radiological progression in patients (pts) with nonmetastatic castration-resistant prostate cancer (nmCRPC): Follow-up analysis of ARAMIS.",
            "Indication": "nonmetastatic castration-resistant prostate cancer (nmCRPC)",
            "Drug": "darolutamide (DARO)",
            "PFS": "DARO: Median 33.2 months (HR 0.13); PBO: Median 7.3 months",
            "OS": "DARO: HR 0.69 (95% CI 0.53\u20130.88)",
            "ORR": "None",
            "AE": "None",
            "Other": "DARO pts with PSA <0.2 ng/mL had a lower risk of radiological progression vs those with PSA \u22650.2 ng/mL, with rates of 8.7% vs 33% at 24 months.",
            "Conclusion": "In pts with nmCRPC by conventional imaging, DARO increased overall survival vs PBO. Adding DARO to ADT resulted in deep and durable PSA response vs ADT alone. DARO was associated with low rates of PSA progression \u00b1 radiological progression."
        },
        {
            "Title": "Exploration of circulating tumor cell (CTC) conversion and CTC0 as prognostic biomarkers for efficacy in TALAPRO-2: Phase 3 study of talazoparib (TALA) + enzalutamide (ENZA) vs placebo (PBO) + ENZA as first-line (1L) treatment in patients (pts) with metastatic castration-resistant prostate cancer (mCRPC).",
            "Indication": "metastatic castration-resistant prostate cancer (mCRPC)",
            "Drug": "talazoparib (TALA) + enzalutamide (ENZA)",
            "PFS": "CTC conversion at Week 9: TALA+ENZA: HR=0.13, P<0.0001; PBO+ENZA: HR=0.16, P<0.0001; CTC0 at Week 9: TALA+ENZA: HR=0.33, P<0.0001; PBO+ENZA: HR=0.41, P=0.0006; CTC conversion at Week 17: TALA+ENZA: HR=0.28, P=0.0065; PBO+ENZA: HR=0.26, P=0.0006; CTC0 at Week 17: TALA+ENZA: HR=0.16, P<0.0001; PBO+ENZA: HR=0.36, P=0.0004",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "CTC reduction at Week 9 and 17 proved prognostic of improved rPFS in both treatment arms in TALAPRO-2.",
            "Conclusion": "CTC reduction at Week 9 and 17 proved prognostic of improved rPFS in both treatment arms in TALAPRO-2. This is the first time such an association has been demonstrated in a phase 3 trial featuring a PARP inhibitor."
        },
        {
            "Title": "Evaluation of homologous recombination repair (HRR) status in metastatic prostate cancer by next-generation sequencing and functional tissue-based immunofluorescence assays.",
            "Indication": "Metastatic prostate cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "RAD51-IF score, HRD-sum",
            "Conclusion": "RAD51-IF is feasible in clinical samples from mPC patients and associates strongly with clinically relevant HRR gene alterations."
        },
        {
            "Title": "Dissecting the significance of ACP1 gene alterations in prostate cancer (PCa).",
            "Indication": "Prostate cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "63.4 vs 86.3 months in ACP1-high vs low tumors, HR 1.5, 95% CI 1.3-1.7, p < 0.0001; 22.0 vs 27.7 months in ACP1-high vs low tumors, HR 1.2, 95% CI 1.0-1.4, p < 0.05",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "ACP1-high tumors exhibit a distinct molecular profile enriched for TP53 alterations and associated with a \u2018cold\u2019 TME. Findings may provide a rationale for novel therapeutic targeting of ACP1-high tumors."
        },
        {
            "Title": "Clinical and genomic landscape of neuroendocrine prostate cancer (NEC) vs. prostate adenocarcinoma (AdenoK).",
            "Indication": "Neuroendocrine prostate cancer, Prostate adenocarcinoma",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Genomic alterations in TP53, RB1, PTEN, BRCA2, TMPRSS2-ERG, MYC, CDKN1B, SPOP, ATM",
            "Conclusion": "This is the largest real-world study characterizing the genomic features of prostate NEC compared to AdenoK. Findings are consistent with prior studies suggesting a distinct genomic profile of NEC."
        },
        {
            "Title": "PHAROS, a real-world multi-country European study on patients with high-risk localized and locally advanced prostate cancer receiving radical treatment.",
            "Indication": "High-risk localized and locally advanced prostate cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "10-year OS of 73% [0.66-0.80] for RP cohort and 69% [0.57-0.80] for RT cohort",
            "ORR": "None",
            "AE": "None",
            "Other": "5-year EFS: 48% [0.43-0.54] for RP cohort, 74% [0.66-0.81] for RT cohort; 5-year BCR-free survival: 54% [0.48-0.59] for RP cohort, 82% [0.75-0.89] for RT cohort; 5-year survival without metastasis: 79% [0.75-0.83] for RP cohort, 88% [0.82-0.94] for RT cohort",
            "Conclusion": "A significant proportion of patients fail after primary curative treatment within 5 years, indicating an unmet medical need to improve outcomes. Further analysis of the PHAROS dataset will bring better understanding of the real-world patient pathway in this setting and the role of intermediate endpoints."
        },
        {
            "Title": "Impact of mental health illness (MHI) prior to prostate cancer (PC) diagnosis (Dx) on treatment (Tx) received and PC outcomes.",
            "Indication": "Prostate Cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The cumulative incidence of definitive treatment (DTx) was higher for MHI vs. non-MHI men (36% vs. 27% after 10 years). Men with pre-existing MHI were significantly more likely to receive DTx for PC than men without MHI in both univariable (UVA) (HR: 1.37, 95% CI: 1.32-1.41) and multivariable (MVA) (HR: 1.34, 95% CI: 1.30-1.39) analysis. Among men treated for PC (n=10,086), a similar proportion of MHI men met criteria for adhering to surveillance as non-MHI men (45% vs. 46%). The odds of adhering to surveillance did not differ significantly between MHI vs. non-MHI men in UVA (OR: 0.96, 95% CI: 0.89-1.04); however, in MVA, the odds of adhering were lower in MHI vs. non-MHI men (OR: 0.92, 95% CI: 0.85-1.00, p=0.049). The cumulative incidence of biochemical recurrence (BCR) following DTx was higher in MHI vs. non-MHI men (31% vs. 28% after 15 years). The risk of BCR was significantly higher in MHI vs. non-MHI men in both UVA (HR: 1.08, 95% CI: 1.01-1.15) and MVA (HR: 1.07, 95% CI: 1.00-1.14).",
            "Conclusion": "Men with MHI prior to PC Dx are more likely to receive DTx compared to non-MHI men with PC. Given that men with MHI and PC have more aggressive disease than non-MHI men with PC, more DTx is encouraging, however poorer post-Tx surveillance adherence and increased risk of BCR presents an opportunity for intervention to improve outcomes in these patients."
        },
        {
            "Title": "Metastatic pure seminomas with early relapse: Prognostic roles of high dose chemotherapy and surgery of residual disease.",
            "Indication": "Metastatic Seminomatous Germ Cell Tumors (mSGCT)",
            "Drug": "High-dose chemotherapy with hematopoietic stem cell transplantation (HDCT-HSCT)",
            "PFS": "3-year PFS: 77.6% (95% CI, 68.3-88.1). Patients who underwent RPLND after salvage ChT demonstrated significantly longer PFS (at 3-years: 97.1% vs 63%; HR 0.15; 95% CI 0.03-0.65; p=0.012).",
            "OS": "3-year OS: 88.4% (95% CI, 81.0-96.4). A notable trend towards improved OS for patients who underwent RPLND after salvage ChT (at 3-years: 97.0% vs 81.8%; HR 0.15; 95% CI 0.02-1.23; p=0.078).",
            "ORR": "None",
            "AE": "None",
            "Other": "HDCT-HSCT was not associated with longer PFS or OS compared to standard-dose 2nd-line ChT.",
            "Conclusion": "In mSGCT patients with first-year relapse, RPLND after salvage treatment is correlated with longer PFS and tends to be associated with longer OS. Identification of a subpopulation that might benefit from HDCT-HSCT ought to be performed since it did not confer survival benefits in the overall population."
        },
        {
            "Title": "Utilization patterns and outcomes of radiotherapy for patients with germ cell tumor relapsing with brain metastases, with a focus on stereotactic radiosurgery.",
            "Indication": "Brain metastases from germ cell tumors",
            "Drug": "Radiotherapy (RT), Stereotactic Radiosurgery (SRS), Whole Brain Radiotherapy (WBRT), Salvage Chemotherapy (CT)",
            "PFS": "None",
            "OS": "3-year OS: 39%",
            "ORR": "None",
            "AE": "None",
            "Other": "3-year Intracranial Progression (IP): 44%, Local Progression (LP) after SRS: 8% at 3 years",
            "Conclusion": "Long-term survival and intracranial control are achievable with each strategy and with both WBRT and SRS. Local control after SRS is excellent, but caution is advised for patients with multiple BM, given elevated risk of IP and death."
        },
        {
            "Title": "Prognostic reclassification and survival outcomes in intermediate- and poor-risk nonseminomatous germ cell tumors (NSGCT).",
            "Indication": "Intermediate- and poor-risk nonseminomatous germ cell tumors (NSGCT)",
            "Drug": "None",
            "PFS": "5-year RFS: 76.8% (intermediate-risk), 46.2% (poor-risk)",
            "OS": "5-year OS: 84.4% (intermediate-risk), 55.3% (poor-risk)",
            "ORR": "None",
            "AE": "None",
            "Other": "Age above 35 years, baseline alpha-fetoprotein (AFP) > 10,000 ng/mL, presence of liver and brain metastasis were identified as poor prognostic risk factors for OS. Lung metastasis in addition to the above factors was prognostic for RFS.",
            "Conclusion": "The proposed model showed better discriminatory ability than the existing IGCCCG classification. This emphasizes the need to revise the risk stratification to identify the 'very-high risk' subset of patients for potential treatment intensification."
        },
        {
            "Title": "Longitudinal evaluation of circulating tumor DNA (ctDNA) as a prognostic biomarker to detect minimal residual disease (MRD) in testicular cancer.",
            "Indication": "Testicular cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "During the MRD window, patients with detectable vs. undetectable ctDNA showed a significantly inferior EFS (HR=5.27, 95% CI: 1.22-22.71; p=0.026). During the surveillance window, elevated STM vs. normal STM was not associated significantly with worse EFS (HR=2.01, 95% CI: 0.71-5.72; p=0.191).",
            "Conclusion": "Tumor-informed ctDNA analysis shows promise for MRD detection in patients with testicular cancer. With further study, ctDNA monitoring may have utility in clinical decision-making. Larger prospective trials are planned for validation of clinical utility."
        },
        {
            "Title": "Management of relapsed stage I nonseminomatous germ-cell tumor with retroperitoneal only relapse.",
            "Indication": "Relapsed stage I nonseminomatous germ-cell tumor with retroperitoneal only relapse",
            "Drug": "Chemotherapy (BEPx3, EPx4, BEPx3+EPx1, other), Retroperitoneal Lymph Node Dissection (RPLND)",
            "PFS": "5-year PFS: 80% (chemo), 74% (surgery)",
            "OS": "5-year OS: 93% (chemo), 100% (surgery)",
            "ORR": "None",
            "AE": "None",
            "Other": "Almost half of the patients treated with chemo needed a post-chemo RPLND. In those treated with surgery, most had RP lymph nodes < 3 cm, all had normal tumor markers, and the median time to relapse while on AS for stage I disease was longer.",
            "Conclusion": "In patients on AS with stage I NSGCT with RP only relapse, there was no difference in 5-year PFS or OS for those treated with RPLND vs. chemotherapy. When patients were carefully selected for RPLND at the time of relapse, most were cured with single-modality therapy."
        },
        {
            "Title": "Management of progressive brain metastases in patients (pts) with relapsed germ-cell tumor (GCT) treated with salvage high-dose chemotherapy (HDCT)",
            "Indication": "Relapsed germ-cell tumor (GCT) with progressive brain metastases",
            "Drug": "High-dose chemotherapy (HDCT) with peripheral blood stem cell transplantation (PBSCT)",
            "PFS": "2-yr PFS was 43.9%",
            "OS": "2-yr OS was 73.2%",
            "ORR": "None",
            "AE": "None",
            "Other": "Baseline characteristics and management details provided",
            "Conclusion": "Pts with relapsed GCT with progressive brain mets can be cured with HDCT with PBSCT. Management of progressive brain mets should be individualized for each pt, taking into account extent of brain mets and presence of symptoms."
        },
        {
            "Title": "Postchemotherapy retroperitoneal lymph node dissection (PC-RPLND) for metastatic pure seminoma",
            "Indication": "Metastatic pure seminoma",
            "Drug": "None (surgical intervention)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Clavien-Dindo complications \u22653a developed in 11 (10.1%) pts",
            "Other": "FDG-PET/CT for residual masses >3cm showed a positive predictive value of only 20%. With a median follow-up of 56 months, 15 (14%) patients relapsed, and 3 (3%) patients died of disease.",
            "Conclusion": "One third of patients with progressive or >3cm FDG-PET-CT positive residual retroperitoneal masses following first-line chemotherapy for metastatic seminoma may have viable tumor. FDG-PET/CT has a poor positive predictive value and might be omitted as a staging procedure. In selected cases, PC-RPLND may be a valuable option if performed in high-volume centers with expertise in testicular cancer management."
        },
        {
            "Title": "Real-world evidence of overall survival (OS) and treatment patterns of patients (pts) with testicular germ cell tumors (GCT) receiving palliative chemotherapy in the United States",
            "Indication": "Relapsed/refractory testicular germ cell tumors (GCT)",
            "Drug": "Palliative chemotherapy (various regimens including gemcitabine+oxaliplatin, oral etoposide, and gemcitabine+paclitaxel)",
            "PFS": "None",
            "OS": "Median OS was 8.0 months; 7.0 months for those HDCT exposed, and 9.3 months for those exposed to CDCT only",
            "ORR": "None",
            "AE": "None",
            "Other": "Treatment patterns and prior salvage chemotherapy exposure details provided",
            "Conclusion": "Real-world data was able to capture treatment patterns, palliative chemotherapy use, and outcomes for pts with R/R testicular GCT. Surprisingly, more than 1/3 of pts did not receive a salvage chemotherapy regimen prior to palliative chemotherapy and of those who did, >30% did not receive HDCT. Renal insufficiency may be a major reason for lack of salvage chemotherapy use. These data provide prognostic information and variables influencing poor outcome in this understudied population for whom novel treatments are urgently needed."
        },
        {
            "Title": "Multicenter analysis of high-dose chemotherapy (HDCT) regimens for the treatment of patients (pts) with recurrent germ cell tumors (GCTs)",
            "Indication": "Recurrent germ cell tumors (GCTs)",
            "Drug": "High-dose chemotherapy (HDCT) regimens: 1) carboplatin 700mg/m2 and etoposide 750mg/m2 (CE) for two cycles, 2) carboplatin AUC 7-8 and etoposide 400mg/m2 for three cycles, typically after two cycles of paclitaxel 200mg/m2 and 2000mg/m2 (TICE)",
            "PFS": "No difference in median RFS between TICE vs CE (10.2 vs 4.5 months, HR of 0.87, 95% CI [0.51, 1.49], P=0.610)",
            "OS": "Trend toward improved median OS between TICE vs CE (56.5 vs 17.9 months, HR 0.54, 95% CI [0.29, 1.01], P=0.057)",
            "ORR": "None",
            "AE": "None",
            "Other": "No difference in the rates of post-transplant relapse among pts who received HDCT in the 2nd line vs 3rd line (76.9% vs 61.7%, P=0.51)",
            "Conclusion": "In the first multicenter comparative analysis of contemporary HDCT regimens, there was no difference in RFS between CE and TICE. However, we observed a trend toward improved survival among pts who received TICE. Additionally, we detected no difference in the rates of post-transplant relapse among pts treated in the 2nd line versus the 3rd line, supporting the application of HDCT in either setting."
        },
        {
            "Title": "High dose chemotherapy in male patients with germ cell cancer: A population-based study by the SWENOTECA group.",
            "Indication": "Advanced germ cell tumors",
            "Drug": "High-dose chemotherapy (HDCT) with carboplatin and etoposide",
            "PFS": "5-year FFS: Overall 43%, Delayed marker decline 53%, Relapse 58%, Progressive disease 18%",
            "OS": "5-year OS: Overall 55%, Delayed marker decline 75%, Relapse 61%, Progressive disease 29%",
            "ORR": "None",
            "AE": "Grade 3-4 toxicities: Infections 86%",
            "Other": "Mortality due to HDCT-treatment 5%",
            "Conclusion": "HDCT for patients with advanced germ cell cancer according to the SWENOTECA cancer care program is achievable and leads to favorable OS and FFS rates. Even though patients that receive HDCT due to progressive disease have a relatively poor outcome, 20-30% of patients do achieve long-term survival."
        },
        {
            "Title": "Survival associated with chemotherapy and prognostic biomarkers in penile cancer at a reference hospital in the Northeast Brazil from 2000 to 2021.",
            "Indication": "Penile cancer",
            "Drug": "Platinum Doublet chemotherapy",
            "PFS": "PFS-CHT: Adjuvant 11 months, Neoadjuvant 4 months, Palliative 1 month",
            "OS": "OS-CHT: Adjuvant 49 months, Neoadjuvant 21 months, Palliative 12 months",
            "ORR": "Neoadjuvant: PR 29%, SD 22%, PD 48%; Palliative: PR 5%, SD 11%, PD 82%",
            "AE": "None",
            "Other": "Lower expression of CD8t, CD163t, or CD84 correlated with greater PFS-CHT.",
            "Conclusion": "Patients who underwent surgery and adjuvant CHT had the best prognosis in terms of survival followed by those operated after neoadjuvant CHT. Lower expression of CD163t, CD84, or CD8t correlated with greater PFS-CHT. These data may help to understand the suppressive microenvironment in penile cancer and response to chemotherapy."
        },
        {
            "Title": "Albumin-bound paclitaxel, cisplatin, and bleomycin combination with tislelizumab for stage IV squamous cell carcinoma of the penis without surgical indications: A single-arm, single-center prospective phase II clinical study.",
            "Indication": "Stage IV squamous cell carcinoma of the penis",
            "Drug": "Albumin-bound paclitaxel, cisplatin, bleomycin, Tislelizumab",
            "PFS": "13.7 months",
            "OS": "Not arrived",
            "ORR": "82.0%",
            "AE": "32.1% grade 3-4 adverse reactions",
            "Other": "PD-L1 expression positive in 57.1% of cases; 3.6% HPV positive",
            "Conclusion": "Stage IV penile squamous cell carcinoma treated with chemotherapy combined with Tislelizumab has a high objective response rate and controllable adverse reactions. A high proportion of patients with regional lymph node metastases had the opportunity to regain surgery or radiotherapy. It is expected to change the poor effect of chemotherapy alone for stage IV squamous cell carcinoma of the penis."
        },
        {
            "Title": "A phase 1, multi-center, open-label, dose-escalation, and dose-expansion study of CBP-1018, a bi-ligand-drug conjugate in patients with heavily treated advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "CBP-1018",
            "PFS": "9.2 months",
            "OS": "None",
            "ORR": "42.9%",
            "AE": "Grade 1 or 2 treatment-related adverse events; Grade 3 adverse events include neutrophil decrease (29.5%), WBC decrease (19.7%), lymphocyte decrease (11.5%)",
            "Other": "No DLTs, no drug-related deaths observed; MTD not reached",
            "Conclusion": "CBP-1018 was well-tolerated at dose levels of 0.03-0.16 mg/kg Q2W with no DLTs. Promising preliminary efficacy ORR and rPFS were observed, supporting further investigation in large-scale clinical trials for mCRPC patients."
        },
        {
            "Title": "Patient-reported outcomes (PROs) for rezvilutamide versus bicalutamide in combination with androgen-deprivation therapy (ADT) in high-volume, metastatic, hormone-sensitive prostate cancer (mHSPC): An analysis of the CHART randomized, open-label, phase 3 trial.",
            "Indication": "High-volume metastatic hormone-sensitive prostate cancer (mHSPC)",
            "Drug": "Rezvilutamide",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The median time to progression of pain was not reached in either group. The 25th percentile for the time to average pain progression was 25.8 months (95% CI 14.8\u201331.4) in the rezvilutamide plus ADT group and 11.7 months (95% CI 8.7\u201322.1) in the bicalutamide plus ADT group (HR 0.79, 95% CI 0.58\u20131.08; p=0.133). The time to deterioration of the FACT-P total score was prolonged in the rezvilutamide plus ADT group compared to the bicalutamide plus ADT group (25th percentile, 12.8 months [95% CI 7.4\u201320.3] vs 6.0 months [95% CI 4.6\u20139.2]; HR 0.66, 95% CI 0.50\u20130.86; p=0.002).",
            "Conclusion": "Rezvilutamide plus ADT demonstrated superiority over bicalutamide plus ADT in delaying pain progression and functional status deterioration in high-volume mHSPC."
        },
        {
            "Title": "Fractionated docetaxel (D) and radium 223 (Ra223) in metastatic castration-resistant prostate cancer (CRPC): A modular phase I trial.",
            "Indication": "Metastatic castration-resistant prostate cancer (CRPC)",
            "Drug": "Docetaxel and Radium 223",
            "PFS": "Median PFS was 50.0 weeks (95% CI: 37.3-86.1)",
            "OS": "Median OS was 86.1 weeks (95% CI: 60.0-130.9)",
            "ORR": "PSA50 response was seen in 18 (51.4%) and PSA90 in 9 (25.7%) patients. Best response (RECIST 1.1) was 1 CR, 5 PR, 23 SD, 2 PD.",
            "AE": "Adverse events of interest: Anemia (Grade 1-2: 57.1%, Grade 3: 5.7%, Grade 4: 0%), Diarrhea (Grade 1-2: 48.5%, Grade 3: 2.8%, Grade 4: 0%), Fatigue (Grade 1-2: 74.3%, Grade 3: 5.7%, Grade 4: 0%), Neuropathy (Grade 1-2: 40%, Grade 3: 0%, Grade 4: 0%), Neutropenia (Grade 1-2: 34.3%, Grade 3: 20%, Grade 4: 5.7%), Thrombocytopenia (Grade 1-2: 31.4%, Grade 3: 0%, Grade 4: 0%)",
            "Other": "Quality of life measures remained stable on study.",
            "Conclusion": "Utilizing a D lead-in strategy, combination DQ2 and Ra223 was feasible and well tolerated, with a favorable PFS and evidence of preferential control of osseous metastases. Dose-intensity for both docetaxel and Ra223 was preserved and comparable to the FDA-approved dose-schedules for each of the single agents. The lead-in design and use of G-CSF contributes significantly to the feasibility."
        },
        {
            "Title": "Association of tumor genetics with outcomes in patients (pts) with PSMA-positive metastatic castration-resistant prostate cancer (mCRPC) treated with 177Lu-PSMA-617.",
            "Indication": "PSMA-positive metastatic castration-resistant prostate cancer (mCRPC)",
            "Drug": "177Lu-PSMA-617",
            "PFS": "Median PFS was 7.6 months",
            "OS": "Median OS was 14.0 months",
            "ORR": "PSA50 was 49%",
            "AE": "None",
            "Other": "Genetic alterations associated with PSA50: TP53 (48%), AR (42%), TMPRSS2 (48%), PTEN (47%), CDK12 (20%), BRCA1 (50%), BRCA2 (63%), MYC (16%), FGFR (25%), ATM (67%), SPOP (50%)",
            "Conclusion": "CDK12, MYC, and FGFR alterations were associated with a lower PSA50 with 177Lu-PSMA-617. Larger cohorts should be investigated for confirmation, as biomarkers to inform relative benefit of tx could be useful in prioritizing options for mCRPC."
        },
        {
            "Title": "PLATIPARP: A phase 2 study of induction docetaxel and carboplatin followed by maintenance rucaparib in treatment of patients with mCRPC with homologous recombination DNA repair deficiency.",
            "Indication": "Metastatic Castration-Resistant Prostate Cancer (mCRPC)",
            "Drug": "Docetaxel, Carboplatin, Rucaparib",
            "PFS": "Overall: 8.1 months (HR: 0.5), BRCA complex: 17.7 months, Other HR: 6.7 months, Refractory to IC: 5.9 months",
            "OS": "Overall: 18 months, BRCA complex: 26.9 months, Other HR: 13.2 months, Refractory to IC: 11.2 months",
            "ORR": "Overall: 67%, BRCA complex: 67%, Other HR: 67%, Refractory to IC: 0%",
            "AE": "33% experienced grade \u22653 adverse events",
            "Other": "PSA50: Overall: 67%, BRCA complex: 67%, Other HR: 67%, Refractory to IC: 0%",
            "Conclusion": "IC followed by rucaparib showed encouraging results in the BRCA complex group. IC refractory patients were not rescued by subsequent PARPi, suggesting overlapping mechanisms of resistance when platinum is used prior to PARPi."
        },
        {
            "Title": "Real-world comparative effectiveness and cardiovascular safety of enzalutamide versus abiraterone amongst older men diagnosed with metastatic castration-resistant prostate cancer.",
            "Indication": "Metastatic Castration-Resistant Prostate Cancer (mCRPC)",
            "Drug": "Enzalutamide, Abiraterone",
            "PFS": "None",
            "OS": "1-year: Enzalutamide: 67.9% (HR: 1.05), Abiraterone: 67.1%; 5-year: Enzalutamide: 12.6% (HR: 1.01), Abiraterone: 12.2%",
            "ORR": "None",
            "AE": "1-year risk of MACE: Enzalutamide: 4.5% (HR: 1.30), Abiraterone: 5.9%",
            "Other": "None",
            "Conclusion": "The long-term comparative effectiveness and cardiovascular safety of abiraterone and enzalutamide were similar, though a short-term increase in MACE was seen in abiraterone initiators, highlighting the importance of considering cardiovascular risk and cancer prognosis during therapy selection."
        },
        {
            "Title": "Real-world baseline treatment patterns and overall survival (rwOS) in patients (pts) with metastatic castration-resistant prostate cancer (mCRPC) treated with olaparib in the United States.",
            "Indication": "Metastatic Castration-Resistant Prostate Cancer (mCRPC)",
            "Drug": "Olaparib",
            "PFS": "None",
            "OS": "Overall median rwOS: 16.5 months; with BRCA mutations: 20.3 months; without BRCA mutations: 12.9 months",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "This real-world analysis indicates many mCRPC pts with HRRm are receiving several lines of therapy prior to olaparib, even when NHA are used prior to mCRPC. Earlier treatment with olaparib monotherapy may improve duration of therapy and overall survival."
        },
        {
            "Title": "Circulating tumour DNA fraction as a predictor of treatment efficacy in a randomized phase 2 trial of [177Lu]Lu-PSMA-617 (LuPSMA) versus cabazitaxel in metastatic castration-resistant prostate cancer (mCRPC) progressing after docetaxel (TheraP ANZUP 1603).",
            "Indication": "Metastatic Castration-Resistant Prostate Cancer (mCRPC)",
            "Drug": "[177Lu]Lu-PSMA-617, Cabazitaxel",
            "PFS": "LuPSMA: HR 5.0 for ctDNA <2% vs. 2-30%; Cabazitaxel: HR 1.4 for ctDNA <2% vs. 2-30%",
            "OS": "None",
            "ORR": "PSA50-RR: LuPSMA: 100% for ctDNA <2%, 58% for ctDNA 2-30%, 44% for ctDNA >30%; Cabazitaxel: 46% for ctDNA <2%, 44% for ctDNA 2-30%, 42% for ctDNA >30%",
            "AE": "None",
            "Other": "None",
            "Conclusion": "ctDNA% is a candidate predictive and prognostic biomarker for differential response to LuPSMA versus taxane chemotherapy in patients with molecular imaging-selected mCRPC progressing after docetaxel."
        },
        {
            "Title": "BRCA1/2 reversion mutations (BRCArev) in advanced prostate cancer in the absence of prior PARP inhibitor (PARPi) therapy.",
            "Indication": "Advanced prostate cancer",
            "Drug": "PARP inhibitors (PARPi)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Among 416 PCA patients with detected BRCA1/2 alterations, 25 (6.0%) patients harbored BRCArev that were predicted to restore BRCA function. In PARPi-treated patients, duration of PARPi treatment prior to identification of BRCArev ranged from 5 to 24 months. Duration of PARPi response was longer in chemo-na\u00efve patients compared with those who had received prior docetaxel before PARPi (Mean: 18.6 vs 8.3 months, respectively).",
            "Conclusion": "In men with BRCA-mutated PCA, chemotherapy and/or radiation may induce BRCArev in the absence of PARPi therapy. Testing of post-chemotherapy or radiation patients for BRCArev may be warranted prior to the start of PARPi therapy in patients with PCA. Our results support early PARPi therapy prior to radiation or chemotherapy as post-chemotherapy/radiation BRCArev may potentially dampen the future success of subsequent PARPi strategies."
        },
        {
            "Title": "Association of genomic alterations with clinical outcomes following lutetium-177-PSMA vipivotide tetraxetan in men with metastatic castrate-resistant prostate cancer.",
            "Indication": "Metastatic castrate-resistant prostate cancer (mCRPC)",
            "Drug": "Lutetium-177-PSMA vipivotide tetraxetan (Lu177)",
            "PFS": "Patients with amplification of cell cycle genes had shorter PFS of 4.3 vs 7.4 months (p=0.027, HR 2.40, 95% CI 1.10-5.22).",
            "OS": "Patients with amplification of cell cycle genes had poorer OS of 5.1 months vs 13.4 months for those without amplification (p=0.001, HR 4.26, 95% CI 1.79-10.11).",
            "ORR": "None",
            "AE": "None",
            "Other": "Patients with loss or mutation of HRR genes had OS of 13.7 vs 12.2 months for those with no HRR alterations, but this was not significant (p=0.101, HR 0.57, 95% CI 0.29-1.12). No other genomic alterations were significantly associated with OS or PFS and none were associated with PSA50 response.",
            "Conclusion": "Genomic alterations warrant investigation in larger cohorts as prognostic and predictive biomarkers for Lu177 therapeutic response. Our data suggest that amplification of genes impacting cell cycle regulatory pathways may be of particular interest. Our findings support continued efforts to determine the impact of cyclin-dependent kinase (CDK) 4/6 inhibitors for high-risk and metastatic prostate cancer, either alone or in combination with agents such as Lu177."
        },
        {
            "Title": "Biomarker assessment and pharmacology of HP518, an AR PROTAC degrader from the phase 1 dose-escalation study in patients with metastatic castration-resistant prostate cancer (mCRPC).",
            "Indication": "Metastatic castration-resistant prostate cancer (mCRPC)",
            "Drug": "HP518",
            "PFS": "None",
            "OS": "None",
            "ORR": "PSA50 response was seen in 3 patients. 2 patients had confirmed partial responses (PR) per RECIST criteria.",
            "AE": "There were twelve Grade \u22653 treatment-emergent adverse events (TEAEs) in 6 patients treated up to 500 mg; no grade 4 TEAEs. The most common TEAE in all cohorts was grade 1 or 2 vomiting and nausea.",
            "Other": "HP518 was well tolerated, with a favorable safety profile. No DLT, and a cumulative of ten SAE (1 related/9 unrelated) were observed. Following multiple oral doses of HP518, median peak plasma concentrations were observed at 3-12 hours post-dose. Over the 5-fold dose range (100 to 500 mg), the increase in Cmax and AUC0-last was approximately dose proportional on day 1. A steady state was reached after day 56. ctDNA data were available from 19 patients, of which four patients were diagnosed with AR LBD mutations. Three LBD mut non-responder patients had low drug exposures. In addition, one AR LBD mut patient had high mutational burden suggestive of AR-independent resistance. cDNA and CTC analysis showed diverse molecular changes in the AR including four patients with AR-V7 splice variant as well as non-AR resistance patterns in most patients.",
            "Conclusion": "HP518, a novel AR PROTAC degrader, demonstrated encouraging efficacy in mCRPC patients, with responses seen in patients with full-length AR, AR LBD mutations, and AR amplification. These data support further investigation of HP518 in mCRPC patients."
        },
        {
            "Title": "Rezvilutamide (REZ) plus docetaxel (DOC) in patients (pts) with chemo-na\u00efve metastatic castration-resistant prostate cancer (mCRPC) after progression on abiraterone (ABI).",
            "Indication": "Chemo-na\u00efve metastatic castration-resistant prostate cancer (mCRPC)",
            "Drug": "Rezvilutamide (REZ) plus docetaxel (DOC)",
            "PFS": "Radiological PFS was 13.8 months (95% CI, 5.7-19.6) with REZ 160 mg plus DOC, while that with REZ 240 mg plus DOC was 8.5 months (95% CI, 5.6-not reached).",
            "OS": "None",
            "ORR": "Rate of PSA response at week 12 was 75.0% with REZ 160 mg plus DOC and 60.0% with REZ 240 mg plus DOC. Rate of PSA decline \u226550% from baseline was 77.8% with REZ 160 mg plus DOC and 72.2% with REZ 240 mg plus DOC.",
            "AE": "Treatment-related adverse events of grade \u22653 occurred in 32 (88.9%) patients (14 [77.8%] patients with REZ 160 mg plus DOC, and 18 [100.0%] patients with REZ 240 mg plus DOC), with the most common being decreased neutrophil count, decreased white blood cell count, and vomiting.",
            "Other": "No DLTs were reported. Time to PSA progression was 10.5 months (95% CI, 5.6-11.3) with REZ 160 mg plus DOC and 14.1 months (95% CI, 4.1-21.4) with REZ 240 mg plus DOC.",
            "Conclusion": "REZ plus DOC was well tolerated with promising efficacy in chemo-na\u00efve mCRPC patients who had progressed after ABI."
        },
        {
            "Title": "ODM-209, a CYP11A1 inhibitor in patients with mCRPC: Results from the STESIDES phase 1 study.",
            "Indication": "metastatic castration-resistant prostate cancer (mCRPC)",
            "Drug": "ODM-209",
            "PFS": "None",
            "OS": "None",
            "ORR": "10 (29%) patients with mCRPC achieved a PSA decline of \u226550%, being 9/19 (47%) and 1/15 (7%) in patients with and without AR-LBD mutations respectively. 3/11 (27%) evaluable patients with mCRPC achieved a partial response by RECIST (1 in an AR-LBD wild-type patient).",
            "AE": "The most commonly reported AE\u2019s were fatigue (53%) and peripheral oedema (34%).",
            "Other": "Steroid hormone concentrations were mostly undetectable on treatment as expected. Patients generally tolerated the treatment well. No responses were seen in patients with BC. The median duration of treatment was 4.8 and 3.7 months in mCRPC patients with and without activating AR-LBD mutations.",
            "Conclusion": "Phase 1 results of ODM-209 suggest activity in heavily pre-treated patients with mCRPC particularly in those with activating AR-LBD mutations, with expected on-target AE\u2019s. Similar findings were recently reported with ODM-208/MK-5684 (NEJM Evidence 2023)."
        },
        {
            "Title": "Phase 1 trial of mevrometostat (PF-06821497), a potent and selective inhibitor of enhancer of zeste homolog 2 (EZH2), in castration-resistant prostate cancer (CRPC).",
            "Indication": "castration-resistant prostate cancer (CRPC)",
            "Drug": "Mevrometostat (PF-06821497) + Enzalutamide",
            "PFS": "Median (95% CI) rPFS was 17.0 (6.3, not estimable [NE]) mo in the total population; 17.1 (6.2, NE) mo for pts who received prior A (without E) (n=12), and 11.7 (4.2, NE) mo for pts who received prior E (\u00b1A) (n=35).",
            "OS": "None",
            "ORR": "Confirmed PSA50 (95% CI) was observed in 14.9% (7.0, 31.4) of pts. In 22 pts with baseline measurable disease, OR rate (95% CI) was 27.3% (10.7, 50.2), including 1 complete and 5 partial responses.",
            "AE": "Adverse events (AEs) led to treatment discontinuation in 9 (19.1%) pts. The most common treatment-emergent AEs (TEAEs) considered related to M were diarrhea (42.6%), dysgeusia (42.6%), and anemia (36.2%). Grade \u22653 TEAEs considered related to M were reported in 17.0% of pts. Serious TEAEs related to M were observed in 6.4% of pts. No treatment-related deaths were observed.",
            "Other": "Geometric mean (95% CI) H3K27Me3 reduction was -75% (-93, -11) for M+E at 1250 mg M (twice daily) in tumor-paired biopsies (n=6). Durable antitumor activity was observed in both post-A (without E) and post-E (\u00b1A) pts with and without androgen receptor and/or TP53 mutations.",
            "Conclusion": "M+E shows promising activity in both post-A (without E) and post-E (\u00b1A) pts with CRPC with evidence of tumor PD modulation and with a manageable AE profile. Further investigation of M+E in pts with CRPC is warranted."
        },
        {
            "Title": "Biomarkers associated with outcomes from KEYLYNK-010: Pembrolizumab (pembro) plus olaparib (ola) versus next-generation hormonal agent (NHA) in previously treated metastatic castration-resistant prostate cancer (mCRPC).",
            "Indication": "Metastatic castration-resistant prostate cancer (mCRPC)",
            "Drug": "Pembrolizumab plus olaparib",
            "PFS": "PD-L1 CPS <1: 1.01 (0.81-1.26), PD-L1 CPS \u22651: 0.87 (0.56-1.36), HRRm Mut: 0.63 (0.43-0.91), HRRm WT: 1.19 (0.94-1.51), BRCAm Mut: 0.43 (0.23-0.83), BRCAm WT: 1.10 (0.89-1.36), TP53 Mut: 0.96 (0.72-1.28), TP53 WT: 1.00 (0.76-1.32), PTEN Mut: 1.19 (0.83-1.69), PTEN WT: 0.87 (0.68-1.10), AR-V7 +ve: 0.47 (0.24-0.91), AR-V7 -ve: 1.05 (0.82-1.34)",
            "OS": "PD-L1 CPS <1: 0.96 (0.75-1.21), PD-L1 CPS \u22651: 0.80 (0.52-1.24), HRRm Mut: 0.90 (0.59-1.39), HRRm WT: 0.95 (0.75-1.21), BRCAm Mut: 0.57 (0.28-1.17), BRCAm WT: 0.96 (0.77-1.19), TP53 Mut: 0.83 (0.62-1.10), TP53 WT: 0.91 (0.68-1.24), PTEN Mut: 1.03 (0.72-1.46), PTEN WT: 0.82 (0.63-1.07), AR-V7 +ve: 0.41 (0.22-0.78), AR-V7 -ve: 1.04 (0.79-1.35)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "For pembro+ola, PD-L1 CPS had a weak positive association with rPFS, while HRRm and BRCAm had strong positive associations with rPFS; these biomarkers did not correlate with NHA outcomes. We observed a potential benefit with pembro+ola over NHA (for rPFS and OS) in AR-V7\u2013positive mCRPC pts, which requires validation."
        },
        {
            "Title": "A phase 1b dose escalation study of FOR46, a novel antibody-drug conjugate targeting a tumor-specific epitope of CD46, in combination with enzalutamide (Enza) in patients with metastatic castration-resistant prostate cancer (mCRPC).",
            "Indication": "Metastatic castration-resistant prostate cancer (mCRPC)",
            "Drug": "FOR46 in combination with enzalutamide",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Grade \u22653 treatment-related adverse events (trAEs) were observed in 29.4% of pts. The most common trAEs of any grade were fatigue (n=11, 64.7%), peripheral sensory neuropathy (n=8, 47.1%), elevated transaminases (n=3, 17.6%), alopecia (n=4, 23.5%), decreased appetite (n=7, 41.2%), neutropenia (n=3, 17.6%), and weight loss (n=3, 17.6%). Grade 2 neuropathy was observed in 2 patients (11.8%).",
            "Other": "Preliminary anti-tumor activity was observed with PSA declines in 13/16 (81.3%) of evaluable pts, and a disease control rate (stable disease \u22656 months) of 41.2%. 89Zr-DFO-YS5 demonstrated tumor uptake on whole body PET with pharmacokinetics typical for an IgG radiopharmaceutical.",
            "Conclusion": "In combination with enza, the established MTD of FOR46 with primary G-CSF prophylaxis was safe and demonstrated preliminary evidence of efficacy. 89Zr-DFO-YS5 PET demonstrates tumor-specific uptake and will be employed in the ongoing Phase 2 portion of the study as a potential predictive biomarker of response."
        },
        {
            "Title": "Plasma tumour DNA dynamics and circulating subclones patterns in patients with metastatic castration-resistant prostate cancer (mCRPC) treated with cabazitaxel in a prospective biomarker trial (NCT03381326).",
            "Indication": "Metastatic castration-resistant prostate cancer (mCRPC)",
            "Drug": "Cabazitaxel",
            "PFS": "Patients with increasing ctDNA levels had the shortest OS (7.5 months, HR 4.6 [2.1-10.3], p < 0.0001) compared to patients with decreasing ctDNA (15.7 months, HR 2.4 [1.3-4.4], p = 0.0015) and patients with no detectable tumor at either time point (29.9 months).",
            "OS": "Patients with increasing ctDNA levels had the shortest OS (7.5 months, HR 4.6 [2.1-10.3], p < 0.0001) compared to patients with decreasing ctDNA (15.7 months, HR 2.4 [1.3-4.4], p = 0.0015) and patients with no detectable tumor at either time point (29.9 months).",
            "ORR": "None",
            "AE": "None",
            "Other": "Early ctDNA dynamics identified patients with worse outcomes during cabazitaxel. Lower subclonality, representative of fewer circulating subclones, was observed at PD and associated with shorter PFS.",
            "Conclusion": "Early ctDNA dynamics identified patients with worse outcomes during cabazitaxel. Lower subclonality, representative of fewer circulating subclones, was observed at PD and associated with shorter PFS. This suggests that a treatment-resistant clone, associated with a lower index of subclonality, dominates in ctDNA."
        },
        {
            "Title": "The effect of ACEi/ARB on survival outcome of patients with mCRPC treated with abiraterone.",
            "Indication": "Metastatic castration-resistant prostate cancer (mCRPC)",
            "Drug": "Abiraterone Acetate (AA)",
            "PFS": "In COU-AA-301, the use of ACEi/ARB medications showed a significant association with improved PFS (3.1 vs. 2.9 months, p = 0.002) in the AA arm. In COU-AA-302, ACEi/ARB medications were associated with prolonged PFS (20.3 vs. 14.7 months, p = 0.002) in the AA arm.",
            "OS": "In COU-AA-301, the use of ACEi/ARB medications showed a significant association with improved OS (16.4 vs. 14.2 months, p < 0.001) in the AA arm. In COU-AA-302, ACEi/ARB medications were associated with prolonged OS (37.4 vs. 31.9 months, p = 0.003) in the AA arm.",
            "ORR": "None",
            "AE": "None",
            "Other": "The use of ACEi/ARB medications demonstrated improved OS only in COU-AA-301 (13.7 vs. 10.6 months, p = 0.021) in the placebo group. However, this trend did not reach statistical significance after adjusting for baseline factors (HR 0.76, 95% CI 0.55-1.03).",
            "Conclusion": "The use of ACEi/ARB medications has shown promise in improving OS and PFS in mCRPC patients treated with AAP. However, it is important to acknowledge the inherent limitations of post-hoc analysis, and therefore, further validation through prospective clinical trials is warranted."
        },
        {
            "Title": "Overall survival from PANTHER: A multicenter trial of apalutamide, abiraterone acetate plus prednisone in Black and White patients with metastatic castration-resistant prostate cancer (mCRPC).",
            "Indication": "Metastatic castration-resistant prostate cancer (mCRPC)",
            "Drug": "Apalutamide, abiraterone acetate plus prednisone",
            "PFS": "24 months rPFS rate was 61% (95% CI 49, 78) for Black patients and 38% (95% CI 27, 54) for White patients",
            "OS": "36 months OS rate was 68% (95% CI 55, 83) for Black patients and 50% (95% CI 37, 66) for White patients",
            "ORR": "None",
            "AE": "None",
            "Other": "Key baseline prognostic factors included Gleason score 8-10 (56% for both cohorts), KPS 70-80% (26% for Black, 18% for White), median age (67 for Black, 72 for White), median PSA (15.20 for Black, 17.56 for White), median time from diagnosis to enrollment (4.6 years for Black, 3.3 years for White), visceral metastases (23.7% for Black, 18.0% for White), prior docetaxel (33% for Black, 44% for White)",
            "Conclusion": "We hypothesize that treatment with the combination of A+AAP may result in clinical efficacy in Black men with mCRPC. Further prospectively powered studies of dual androgen receptor pathway inhibition with AAP among Black men with advanced prostate cancer are needed to determine the potential clinical benefits in this understudied population."
        },
        {
            "Title": "Final results of a phase I/II dose-escalation study of fractionated dose 177Lu-PSMA-617 for progressive metastatic castration-resistant prostate cancer (mCRPC).",
            "Indication": "Progressive metastatic castration-resistant prostate cancer (mCRPC)",
            "Drug": "177Lu-PSMA-617",
            "PFS": "Median rPFS 8.3 months [5.6-16.7]",
            "OS": "Median OS 15.7 months [10.9-20.6]",
            "ORR": "27 (54%) with >50% PSA decline",
            "AE": "32 (64%) dry mouth, 22 (44%) pain flare, 22 (44%) fatigue, 19 (38%) nausea, 15 (30%) anemia, 15 (28%) thrombocytopenia, 9 (18%) neutropenia. All TEAEs restricted to grade (Gr) 1-2 except 6 (12%) with Gr3 anemia, 2 (4%) Gr3/4 thrombocytopenia, 1 (2%) Gr3 neutropenia.",
            "Other": "Of 17 RECIST measurable, 6 (35.3%) responded, 7 (41.2%) stable, 4 (23.5%) progressed. Of 31 with paired CTC counts, 16 (52%) decreased, 5 (16%) were stable; 10 (32.3%) converted to favorable/undetectable. Plasma DNA with high allele-specific ploidy and AR copy number gain associated with OS (univariate).",
            "Conclusion": "A single-cycle of fractionated-dose 177Lu-PSMA-617 is safe. Despite no pre-selection for PSMA expression, most had PSA decline with favorable PFS and OS compared to historical controls and similar to PSMA-selected targeted radionuclide studies administering multiple cycles in a less dose-intense approach."
        },
        {
            "Title": "Rapid and deep prostate-specific antigen (PSA) response to apalutamide plus ADT and survival in metastatic castration-sensitive prostate cancer (mCSPC) in real world practice in the US (OASIS Project)",
            "Indication": "Metastatic castration-sensitive prostate cancer (mCSPC)",
            "Drug": "Apalutamide (APA) plus androgen-deprivation therapy (ADT)",
            "PFS": "None",
            "OS": "24-month OS: 68%. OS significantly higher when patients achieved undetectable PSA, PSA90, and PSA50 within 3 months after initiating APA+ADT (24-month survival rate: 87%, 84%, and 80%, respectively). Adjusted Hazard Ratios (aHRs): Undetectable PSA <3 months: 0.15 (95% CI 0.05\u20130.47; p < 0.001), >3 months: 0.27 (0.12\u20130.65; p < 0.01)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Rapid and deep PSA decline was strongly associated with better overall survival in patients with mCSPC initiated on APA+ADT."
        },
        {
            "Title": "Prognostic validation of a digital pathology-based multi-modal artificial intelligence (MMAI) biomarker in patients with metastatic hormone-sensitive prostate cancer (mHSPC) from the CHAARTED trial (ECOG-ACRIN EA3805)",
            "Indication": "Metastatic hormone-sensitive prostate cancer (mHSPC)",
            "Drug": "None",
            "PFS": "None",
            "OS": "Estimated 5-year OS across MMAI high, intermediate, and low groups: 39%, 58%, 83%, respectively (log rank p < 0.001). On MVA, MMAI-high risk group associated with OS (HR: 1.77 (95% CI: 1.10-2.84) p = 0.02)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The Artera AI MMAI model was found to be prognostic for OS among men with mHSPC in CHAARTED. This effect persisted when controlling for treatment, metastatic burden, and metastatic status at diagnosis."
        },
        {
            "Title": "Treatment outcomes for patients with Ga68-PSMA-PET prostate cancer (PC) with or without conventional imaging correlates",
            "Indication": "Prostate cancer (PC)",
            "Drug": "None",
            "PFS": "PSMA+/CT- group had a better PSA PFS and TTCR. Median PSA PFS in the PSMA+/CT- group was not reached (NR) versus 37 months in the PSMA+/CT+ group.",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Patients with PSMA+CT- PC vs patients with PSMA+/CT+ PC have better prognosis and outcomes, with low rates of PSA progression and castration resistance. Localized therapy alone is likely insufficient for these patients, and ARTT intensification to improve longer-term outcomes needs prospective studies through ongoing trials."
        },
        {
            "Title": "Prognostic implications of PSA levels at 7 months in metastatic hormone-sensitive prostate cancer treated with enzalutamide: Landmark analysis of ENZAMET (ANZUP1304)",
            "Indication": "Metastatic hormone-sensitive prostate cancer (mHSPC)",
            "Drug": "Enzalutamide added to testosterone suppression (TS) with or without docetaxel",
            "PFS": "None",
            "OS": "Five-year OS for TS+NSAA (\u00b1Doc): PSA \u22640.2 = 71%, PSA >0.2 = 36%. For TS+ENZA (\u00b1Doc): PSA \u22640.2 = 74%, PSA >0.2 = 43%.",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "A PSA nadir of \u22640.2 at 7 months is associated with longer OS regardless of treatment and prognostic group (HV vs LV). Enzalutamide increased the rate of achievement of PSA \u22640.2 for all groups."
        },
        {
            "Title": "Validation of a digital pathology-based multimodal artificial intelligence model in oligometastatic castration-sensitive prostate cancer, including in patients from the STOMP and ORIOLE phase II randomized clinical trials.",
            "Indication": "Oligometastatic castration-sensitive prostate cancer (omCSPC)",
            "Drug": "Metastasis-directed therapy (MDT)",
            "PFS": "None",
            "OS": "Median OS of 108.4 months for high MMAI vs 'not reached' for low MMAI (HR=4.38, 95% CI=1.24-15.56; p=0.022)",
            "ORR": "None",
            "AE": "None",
            "Other": "MMAI was predictive for MDT benefit in MFS (HR=0.32, 95% CI=0.12-0.90; p=0.03 for high MMAI)",
            "Conclusion": "The Artera AI MMAI biomarker is prognostic for OS in patients with omCSPC and appears to predict benefit of MDT with high MMAI demonstrating a greater improvement in MFS following MDT over observation."
        },
        {
            "Title": "Correlation of body mass index (BMI) with survival outcomes in patients with metastatic hormone-sensitive prostate cancer (mHSPC): Analysis of patient (pt)-level data from SWOG1216 study.",
            "Indication": "Metastatic hormone-sensitive prostate cancer (mHSPC)",
            "Drug": "ADT + bicalutamide or ADT + orteronel",
            "PFS": "None",
            "OS": "OS in years for underweight, normal, overweight, and obese cohorts: 1.8, 4.4, 6.5, and 4.8 for bicalutamide arm; 2.8, 5.7, not reached (NR), and NR for orteronel arm. High BMI associated with better OS (HR=0.82, 95% CI=0.68-0.98; p=0.029)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "High BMI is associated with better OS in pts with mHSPC. These data warrant external validation in other randomized phase III studies and can help in counseling and prognostication of patients with mHSPC in the clinic."
        },
        {
            "Title": "PROact: A prospective phase II study to evaluate olaparib plus abiraterone and prednisone combination therapy in patients with metastatic hormone-sensitive prostate cancer with HRR gene mutation.",
            "Indication": "Metastatic hormone-sensitive prostate cancer (mHSPC) with HRR gene mutation",
            "Drug": "Olaparib plus abiraterone and prednisone",
            "PFS": "None",
            "OS": "None",
            "ORR": "Objective response rate (ORR) was 91.7% (11/12). Two achieved complete response, nine obtained partial response, and one had stable disease.",
            "AE": "23.3% patients experienced \u2265grade 3 treatment-related adverse events (TRAEs). Common TRAE was anemia.",
            "Other": "PSA50 response rate achieved 100% (30/30), and the PSA90 response rate was 96.7% (29/30).",
            "Conclusion": "This was the first trial to show efficacy and an acceptable safety profile of olaparib plus abiraterone and prednisone in mHSPC patients with HRR mutation. Data of primary endpoint of 1-year rPFS will be reported in due time."
        },
        {
            "Title": "Post-progression survival of patients with metastatic hormone-sensitive prostate cancer (mHSPC) who received darolutamide or placebo: Post hoc analysis of ARASENS.",
            "Indication": "Metastatic hormone-sensitive prostate cancer (mHSPC)",
            "Drug": "Darolutamide (DARO) + ADT + docetaxel (DOC)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "DARO+ADT+DOC increased overall survival vs PBO+ADT+DOC and also delayed time to progression to mCRPC. DARO pts had similar survival with all post-progression therapies. Pts receiving PBO+ADT+DOC quickly progressed to mCRPC and treatment with an ARPI in ARPI-na\u00efve pts improved survival.",
            "Conclusion": "DARO+ADT+DOC increased overall survival vs PBO+ADT+DOC and also delayed time to progression to mCRPC. DARO pts had similar survival with all post-progression therapies."
        },
        {
            "Title": "EMBARK post hoc analysis of sexual activity (SA) patient-reported outcomes (PROs) in patients (pts) who were sexually active or interested in sex at baseline (BL).",
            "Indication": "High-risk biochemically recurrent nonmetastatic hormone-sensitive prostate cancer",
            "Drug": "Enzalutamide (ENZ) + Leuprolide (L) or ENZ (ENZ mono)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Sexual activity (SA) better preserved with ENZ mono vs P+L. No difference between ENZ+L vs P+L. TTCD in QLQ-PR25 SA domain, items 50 (interest), 51 (activity), GS7 (satisfaction), and BL5 (erectile function) were significantly delayed with ENZ mono vs P+L. TTCD in BL5 (erectile function) was shorter with ENZ+L vs P+L.",
            "Conclusion": "Among pts who were interested in sex or sexually active at BL, ENZ mono better preserved SA vs P+L in terms of SA domain, interest, activity, satisfaction, and maintaining erection. Adding ENZ to L had no impact on interest, activity, or satisfaction but may adversely affect erectile function, though differences in the median TTCD were clinically non-significant."
        },
        {
            "Title": "Prognostic value of baseline circulating tumor DNA (ctDNA) tumor fraction (TF) in metastatic hormone-sensitive prostate cancer (mHSPC).",
            "Indication": "Metastatic hormone-sensitive prostate cancer (mHSPC)",
            "Drug": "None",
            "PFS": "None",
            "OS": "Median OS (mOS) of 37 months vs not reached (NR) (HR 2.57, 95% CI 0.55-11.93, p=0.2) for baseline TF+ vs TF-. In pts with high volume (HV) disease, mOS was 37 months with baseline TF+ vs NR with TF- (HR 2.77, 95% CI 0.35-22.23, p=0.3).",
            "ORR": "None",
            "AE": "None",
            "Other": "Median TTCRPC of 15 months vs NR (HR 2.07, 95% CI 0.75-5.71, p=0.2) for baseline TF+ vs TF-. In pts with low volume disease, mTTCRPC was 14 months vs NR (HR 1.7, 95% CI 0.23-12.0, p=0.6) for TF+ vs TF-. Among 30 pts with on-treatment samples, TF+ during therapy had a mOS of 37 months vs 61 months (HR 2.67, 95% CI 0.69-10.35, p=0.2) and mTTCRPC of 12 months vs 52 months (HR 1.75, 95% CI 0.68-4.47, p=0.2) compared to TF-.",
            "Conclusion": "Pts with mHSPC and baseline TF+ appear to have a quantitatively but not statistically significant worse OS and TTCRPC compared to pts with TF- regardless of disease volume. Inclusion of additional pts and longer follow-up may validate whether ctDNA can improve treatment decision making in mHSPC."
        },
        {
            "Title": "Abiraterone with discontinuation of gonadotropin-releasing hormone (GnRH) analogues in patients (pts) with metastatic prostate cancer (PC): A single arm, phase II study.",
            "Indication": "Metastatic prostate cancer (PC)",
            "Drug": "Abiraterone acetate (AA) + Prednisone (AAP)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "At 6 months, 92% of pts had castrate T levels, 85% at 12 months, 78% at 18 months, 73% at 24 months, and 65% at 30 months. No treatment discontinuations due to toxicity. Mean T, LH, and PSA values at time points reported.",
            "Conclusion": "Pts with metastatic PC treated with AAP after GnRH analogue discontinuation often remain castrate with AAP monotherapy even 30 months after GnRH analogue cessation. Phase III trials showing benefit of AAP required continued GnRH analogue; this small phase II study does not change standard of care but does suggest that further evaluation of AAP monotherapy may be warranted."
        },
        {
            "Title": "A phase II trial of enzalutamide (Enz) with 5-alpha reductase inhibitors (5-ARI) as an androgen deprivation therapy (ADT)\u2013sparing approach for older men with castration-sensitive prostate cancer (CSPC)",
            "Indication": "Castration-sensitive prostate cancer (CSPC)",
            "Drug": "Enzalutamide (Enz) with Dutasteride (Dut) or Finasteride (Fin)",
            "PFS": "Median PSA progression-free survival (PFS) not reached",
            "OS": "None",
            "ORR": "98% having \u226590% PSA decline",
            "AE": "Fatigue (88%), gynecomastia (72%), hot flashes (42%), hypertension (40%), falls (37%)",
            "Other": "Median time to PSA nadir was 8.1 months, with median PSA nadir at 0.02 ng/ml (range 0-2.75)",
            "Conclusion": "The combination treatment with Enz and Dut/Fin appears to have clinical efficacy for older patients with CSPC who are at high risk for AEs from ADT."
        },
        {
            "Title": "Impact of alterations in tumor suppressor genes (TSG-alt) on survival outcomes in patients (pts) with de novo metastatic castration-sensitive prostate cancer (dn-mCSPC) receiving androgen deprivation therapy (ADT) with androgen receptor pathway inhibition (ARPI) or docetaxel",
            "Indication": "De novo metastatic castration-sensitive prostate cancer (dn-mCSPC)",
            "Drug": "Androgen deprivation therapy (ADT) with androgen receptor pathway inhibition (ARPI) or docetaxel",
            "PFS": "None",
            "OS": "Adjusted Hazard Ratios for OS: altRB1 vs wtRB1: ARPI 4.03 (2.30-7.10, <0.01), Docetaxel 2.85 (1.56-5.21, <0.01); altTP53 vs wtTP53: ARPI 1.62 (1.12-2.34, 0.01), Docetaxel 1.47 (1.03-2.08, 0.03); altPTEN vs wtPTEN: ARPI 1.01 (0.66-1.54, 0.95), Docetaxel 0.91 (0.63-1.32, 0.62)",
            "ORR": "None",
            "AE": "None",
            "Other": "Adjusted Hazard Ratios for TTCR: altRB1 vs wtRB1: ARPI 3.58 (2.04-6.29, <0.01), Docetaxel 0.77 (0.38-1.57, 0.47); altTP53 vs wtTP53: ARPI 1.60 (1.16-2.22, <0.01), Docetaxel 1.15 (0.86-1.52, 0.36); altPTEN vs wtPTEN: ARPI 0.96 (0.67-1.38, 0.83), Docetaxel 0.92 (0.67-1.26, 0.62)",
            "Conclusion": "In this real-world analysis, altRB1 and altTP53 had worse OS than their respective wild-type counterparts with both ARPI and docetaxel whereas altPTEN had similar OS with ARPI and Docetaxel in dn-mCSPC pts. Based on these results, RB1 and TP53 may represent prognostic biomarkers guiding treatment selection and clinical trial enrollment in dn-mCSPC and may assist with counseling and prognostication."
        },
        {
            "Title": "Comparison of outcomes with docetaxel or ARPI combination therapy for metastatic hormone-sensitive prostate cancer (mHSPC) by volume of disease.",
            "Indication": "Metastatic hormone-sensitive prostate cancer (mHSPC)",
            "Drug": "Docetaxel (DOC) and Androgen Receptor Pathway Inhibitors (ARPIs)",
            "PFS": "{'Low Volume': {'DOC': '24.3 months', 'ARPI': '41.9 months'}, 'High Volume': {'DOC': '14.9 months', 'ARPI': '19.2 months'}}",
            "OS": "{'Low Volume': {'DOC': '64.5 months', 'ARPI': '67.4 months'}, 'High Volume': {'DOC': '33.8 months', 'ARPI': '32.5 months'}}",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "In veterans with de novo mHSPC, no difference in OS was observed between combination treatment with DOC or ARPI in patients with low or high-volume mHSPC. ARPIs were associated with longer progression-free survival. Due to a lack of clinical trials comparing DOC and ARPI therapy, these data may guide the selection of combination therapy for mHSPC."
        },
        {
            "Title": "Immunologic effects of B cell depletion on T cells in high-risk prostate cancer.",
            "Indication": "High-risk prostate cancer (HR-PCa)",
            "Drug": "Rituximab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Higher TCRb productive clonality (0.080 vs 0.026, p = 0.03); significantly greater number of T cells in prostatectomy samples (1.18 cells/ng DNA input vs 0.10 cells/ng, p = 0.002); longer TCRb CDR3 regions (proportion CDR3 \u2265 16 amino acids of 0.232 vs 0.197, p = 0.007)",
            "Conclusion": "B cell depletion can significantly affect T cell infiltration, clonality, and phenotype in HR-PCa patients."
        },
        {
            "Title": "Impact of pre-treatment MRI capsular involvement and extraprostatic extension on metastasis free and overall survival in localized prostate cancer.",
            "Indication": "Localized prostate cancer",
            "Drug": "None",
            "PFS": "5-year cumulative incidence of metastasis was 10% (95% CI 9-11%)",
            "OS": "5-year cumulative incidence of death was 7% (95% CI 6-9%)",
            "ORR": "None",
            "AE": "None",
            "Other": "Significant capsule abutment, ECE, SVI, and OI were independently associated with MFS; EPE (ECE, SVI, or OI) was associated with worse OS",
            "Conclusion": "Significant capsule abutment and EPE on mpMRI are independently prognostic of MFS, and EPE is associated with inferior OS. mpMRI should be more widely available to PC patients."
        },
        {
            "Title": "Independent blinded validation of an AI-based digital histology classifier for prostate cancer recurrence and metastasis risk prediction.",
            "Indication": "Prostate cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "PATHOMIQ_PRAD score (BCR: >0.45 vs <0.45, p < 0.0001; DM: >0.55 vs <0.55, p < 0.0001); Decipher score (BCR: >0.6 vs <0.6, p = 0.0009; DM: >0.6 vs <0.6, p = 0.0095)",
            "Conclusion": "PATHOMIQ_PRAD demonstrates clinical validity in predicting risk of BCR and DM with favorable performance compared to Decipher. Combining the two tests may further improve risk stratification."
        },
        {
            "Title": "Prognostic impact of residual cancer burden (RCB) on long-term outcomes after neoadjuvant (neo) androgen receptor pathway inhibitor (ARPI) and radical prostatectomy (RP) for high-risk localized prostate cancer (HRLPC): A pooled analysis of phase 2 trials.",
            "Indication": "High-risk localized prostate cancer (HRLPC)",
            "Drug": "Abiraterone, Enzalutamide, Abiraterone + Enzalutamide, Abiraterone + Apalutamide, Androgen Deprivation Therapy (ADT)",
            "PFS": "5-year MFS rate was 81% (95% CI 74-86)",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "RCB index categories: RCB-0 (no residual disease, n=23), RCB-1 (<84th percentile, <0.67 cm3, n=153), RCB-2 (\u226584th percentile, \u22650.67 cm3, n=28); 5-year MFS rates were 100%, 81% (72-87), and 59% (35-76) for RCB-0, RCB-1, and RCB-2, respectively",
            "Conclusion": "The depth of pathologic response was prognostic for MFS, with a 100% 5-year MFS in patients achieving pCR. RCB could guide intensified adjuvant strategies in patients with residual disease at RP after neo-ARPI."
        },
        {
            "Title": "COBRA: Assessment of safety and efficacy of 64Cu-SAR-bisPSMA in patients with biochemical recurrence of prostate cancer following definitive therapy.",
            "Indication": "Biochemical recurrence of prostate cancer",
            "Drug": "64Cu-SAR-bisPSMA",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Only one adverse event related to 64Cu-SAR-bisPSMA (Grade 2 worsening of type II diabetes, resolved)",
            "Other": "Detection rate (DR) and positive predictive value (PPV) were assessed. Day 0 DR range: 44\u201358%, Day 1 DR range: 58\u201380%. Pelvic lymph nodes Day 0 PPV range: 71.4\u201387.5%, Day 1 PPV range: 50.0\u201361.5%. Additional lesions identified on Day 1.",
            "Conclusion": "64Cu-SAR-bisPSMA is safe and effective in detecting PC lesions in patients with BCR. It identified lesions in up to 80% of patients with negative or equivocal SOC scans and led to changes in intended treatment plans in approximately half of the patients."
        },
        {
            "Title": "Intensification of ADT with enzalutamide in high-risk patients with biochemical relapse following radical prostatectomy undergoing salvage radiation: Initial results from RTOG 3506 (STEEL).",
            "Indication": "High-risk biochemical relapse following radical prostatectomy",
            "Drug": "Enzalutamide",
            "PFS": "PFS favored the enzalutamide-intensified arm (HR=0.72, 80% CI: 0.56-0.94, one-sided p=0.14)",
            "OS": "None",
            "ORR": "None",
            "AE": "Grade 3 AEs: 11% (standard) vs. 23% (enzalutamide), Grade 4 AEs: 4% (standard) vs. 1% (enzalutamide). Common AEs: hot flashes, fatigue, diarrhea, decreased lymphocytes. Grade 3+ AEs: decreased lymphocytes, hypertension. Differences in AEs: insomnia, decreased lymphocytes, diarrhea (less frequent with enzalutamide: 40% vs 54%).",
            "Other": "None",
            "Conclusion": "The addition of enzalutamide to standard ADT did not meaningfully increase toxicity. There was a trend toward PFS benefit from intensification, but it has not yet met statistical significance. Updates on PFS and other clinical endpoints including quality of life will be reported with longer follow-up."
        },
        {
            "Title": "Willingness of patients with biochemically recurrent prostate cancer (BCR) with positive 18F-dcfpyl PET/CT PSMA to monitor without treatment.",
            "Indication": "Biochemically recurrent prostate cancer (BCR)",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Median PSA value at enrollment is 3.65 ng/mL (range, 0.5-71.6 ng/mL). Median PSA doubling time is 11.3 months (range, 1.2-132.4 months). Median patient age is 71 years (range, 54-92 years old). Self-reported race/ethnicity: 75% White, non-Hispanic, 12% Black, non-Hispanic, 3.5% White, Hispanic, 3.5% Other, Hispanic, 2% Asian, 4% Unknown. Of the first 57 patients enrolled who have had PSMA PET scans, 52 patients (91%) were found to have positive findings. Among these, 49 patients (94%) with ppBCR elected to monitor their disease until the next scan.",
            "Conclusion": "This study (NCT05588128) is the first prospective trial to monitor ppBCR patients to better define the natural history of BCR in the PSMA era. This preliminary data from the ongoing study thus far suggests that with appropriate counseling and a follow-up plan, the majority of patients are willing to monitor ppBCR. Given that no data has demonstrated a survival advantage for BCR or ppBCR, it is important for providers to have balanced conversations with patients about the implications of ppBCR, which often results in an indolent disease course."
        },
        {
            "Title": "SAVE: A prospective, single-arm, single-center clinical study of disitamab vedotin in combination with toripalimab in patients with advanced penile cancer that has progressed or is intolerant to cisplatin chemotherapy.",
            "Indication": "Advanced penile cancer",
            "Drug": "Disitamab Vedotin and Toripalimab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "About 20 patients with advanced penile cancer who have progressed or are intolerant to chemotherapy are planned to be enrolled with antibody-drug conjugates (Disitamab Vedotin for injection, 1 time/2 weeks, 2.0 mg/kg) and PD-1 (Toripalimab, 1 time/2 weeks, 3.0 mg/kg; or 1 time/3 weeks, 240 mg/kg), regimen until the disease progresses or is intolerant.",
            "Conclusion": "This study is expected to provide a basis for the treatment of advanced penile cancer using this regimen."
        },
        {
            "Title": "A phase II trial of enfortumab vedotin for locally advanced and metastatic squamous cell carcinoma of the penis.",
            "Indication": "Penile squamous cell carcinoma (PSCC)",
            "Drug": "Enfortumab vedotin (EV)",
            "PFS": "None",
            "OS": "None",
            "ORR": "Best overall response rate (BRR) to EV in patients with PSCC",
            "AE": "Adverse events as a secondary endpoint",
            "Other": "Secondary endpoints include confirmed response rate, duration of response, progression-free survival, overall survival, and BRR in HPV related and unrelated subgroups.",
            "Conclusion": "The trial is currently in progress, evaluating the efficacy of EV in advanced PSCC."
        },
        {
            "Title": "A phase 1/2, open-label, randomized, dose-finding and dose expansion study of gedatolisib in combination with darolutamide in metastatic castration-resistant prostate cancer (mCRPC).",
            "Indication": "Metastatic castration-resistant prostate cancer (mCRPC)",
            "Drug": "Gedatolisib in combination with darolutamide",
            "PFS": "Primary endpoints for Phase 2 are rPFS rate at 6 months based on RECIST v1.1",
            "OS": "Overall survival rate at 18 and 24 months as a secondary endpoint",
            "ORR": "Overall response rate as a secondary endpoint",
            "AE": "Adverse events as a primary endpoint for Phase 1",
            "Other": "Secondary endpoints include rPFS rates at 9 and 12 months, prostate-specific antigen response of \u226550% decrease from baseline at 4, 8, 12, and 16 weeks, duration of response, clinical benefit rate, and safety.",
            "Conclusion": "The trial is currently open for enrollment."
        },
        {
            "Title": "A randomized phase 2 trial in progress of flexible and extended dosing of 177Lu-PSMA-617 molecular radioligand therapy in mCRPC (FLEX-MRT).",
            "Indication": "Metastatic castration-resistant prostate cancer (mCRPC)",
            "Drug": "177Lu-PSMA-617",
            "PFS": "Determination of overall and progression-free survival as secondary endpoints",
            "OS": "2-year survival rate as the primary endpoint",
            "ORR": "None",
            "AE": "Safety by Common Terminology Criteria for Adverse Events (CTCAE) as a secondary endpoint",
            "Other": "Dosimetry and imaging response to RLT assessed using 177Lu-PSMA-617 SPECT/CT and PSMA PET/CT.",
            "Conclusion": "The trial aims to determine the efficacy of a response-based flexible dosing schedule of 177Lu-PSMA-617 RLT."
        },
        {
            "Title": "A phase 1/2 study of ONCT-534, a dual-action androgen receptor inhibitor (DAARI), in patients with metastatic castration-resistant prostate cancer.",
            "Indication": "Metastatic castration-resistant prostate cancer (mCRPC)",
            "Drug": "ONCT-534",
            "PFS": "None",
            "OS": "None",
            "ORR": "Antitumor activity as a primary endpoint",
            "AE": "Safety and tolerability as primary endpoints",
            "Other": "Pharmacokinetics, AR phenotype, and AR levels pre- and post-treatment.",
            "Conclusion": "The study is evaluating the safety, tolerability, and antitumor activity of ONCT-534 in subjects with mCRPC."
        },
        {
            "Title": "A phase 1b study of PSCA CAR T cells plus or minus radiation for the treatment of patients with PSCA+ metastatic castration-resistant prostate cancer",
            "Indication": "PSCA+ metastatic castration-resistant prostate cancer (mCRPC)",
            "Drug": "PSCA CAR T cells",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoints include evaluation of toxicity through DLTs and activity through rate of PSA50 and objective responses. Secondary endpoints include overall survival, progression-free survival (PCWG3 criteria), measurement of CAR T cell and cytokine kinetics, and T cell infiltration in pre- and post-treatment tumor biopsies.",
            "Conclusion": "The study aims to test a multi-dose strategy and whether the addition of metastasis-directed radiation therapy (MDRT) could further enhance response. The results will be used to identify the optimal strategy to move to phase 2. The study is currently open to enrollment."
        },
        {
            "Title": "CYP11A1 inhibitor MK-5684 versus next-generation hormonal agent (NHA) switch in patients with metastatic castration-resistant prostate cancer (mCRPC) after NHA and taxane-based chemotherapy: Phase 3 MK-5684-003 trial",
            "Indication": "Metastatic castration-resistant prostate cancer (mCRPC)",
            "Drug": "MK-5684 (ODM-208)",
            "PFS": "Radiographic PFS per PCWG3-modified RECIST v1.1 by BICR",
            "OS": "Overall survival in patients with AR-LBD mutation-positive and -negative disease, separately",
            "ORR": "None",
            "AE": "None",
            "Other": "Secondary endpoints include time to initiation of first subsequent anticancer therapy or death; ORR and DOR per PCWG3-modified RECIST v1.1 by BICR; time to pain progression; time to prostate-specific antigen progression; time to first symptomatic skeletal-related event; and safety and tolerability.",
            "Conclusion": "The study is evaluating the efficacy and safety of MK-5684 versus NHA switch in patients with mCRPC previously treated with NHA and taxane-based chemotherapy. Enrollment is ongoing."
        },
        {
            "Title": "CYP11A1 inhibitor MK-5684 versus next-generation hormonal agent (NHA) switch in patients with metastatic castration-resistant prostate cancer (mCRPC) after 1 prior NHA: Phase 3 MK-5684-004 study",
            "Indication": "Metastatic castration-resistant prostate cancer (mCRPC)",
            "Drug": "MK-5684 (ODM-208)",
            "PFS": "Radiographic PFS per PCWG3-modified RECIST v1.1 by BICR",
            "OS": "Overall survival in patients with AR-LBD mutation-positive and -negative disease, separately",
            "ORR": "None",
            "AE": "None",
            "Other": "Secondary endpoints include time to initiation of first subsequent anticancer therapy or death; ORR and DOR per PCWG3-modified RECIST v1.1 by BICR; time to pain progression; time to prostate-specific antigen (PSA) progression; PSA response rate; time to first symptomatic skeletal-related event; and safety and tolerability.",
            "Conclusion": "The study is evaluating the efficacy and safety of MK-5684 in patients with molecularly unselected mCRPC after 1 prior NHA. Recruitment is ongoing."
        },
        {
            "Title": "ProstACT GLOBAL: A phase 3 study of best standard of care with and without 177Lu-DOTA-rosopatamab (TLX591) for patients with PSMA expressing metastatic castration-resistant prostate cancer progressing despite prior treatment with a novel androgen axis drug",
            "Indication": "PSMA-expressing metastatic castration-resistant prostate cancer (mCRPC)",
            "Drug": "177Lu-DOTA-rosopatamab (TLX591)",
            "PFS": "Radiographic progression-free survival",
            "OS": "5-year overall survival",
            "ORR": "Tumor objective response rate",
            "AE": "Treatment-related adverse events count",
            "Other": "Secondary endpoints include time to symptomatic skeletal event, health-related quality of life.",
            "Conclusion": "The study is evaluating the efficacy and safety of TLX591 in combination with best standard of care in patients with PSMA-expressing mCRPC. The study is currently enrolling."
        },
        {
            "Title": "SECuRE: A dose escalation/expansion study to assess the anti-tumor efficacy of 67Cu-SAR-bisPSMA in patients with metastatic castrate-resistant prostate cancer.",
            "Indication": "Metastatic castrate-resistant prostate cancer (mCRPC)",
            "Drug": "67Cu-SAR-bisPSMA",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Reduction in PSA levels, radiological progression-free survival, duration of response, and overall survival.",
            "Conclusion": "No dose-limiting toxicities observed to date in the first 3 cohorts. The study is ongoing with active sites in the United States and additional sites in planning."
        },
        {
            "Title": "TRAMP study: A phase 2 trial of tumor necrosis factor-a blockade and AR inhibition in men with CRPC.",
            "Indication": "Castration-resistant prostate cancer (CRPC)",
            "Drug": "Golimumab (TNF-a inhibitor) and Apalutamide (AR antagonist)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint is the rate of PSA50 at 12 weeks. Secondary endpoints include objective response rate, PSA progression-free survival, radiographic PFS, time to subsequent antineoplastic therapy, and overall survival.",
            "Conclusion": "The trial is open to enrollment."
        },
        {
            "Title": "A phase 2 trial of ADT interruption in patients responding exceptionally to AR-pathway inhibitor in metastatic hormone-sensitive prostate cancer (A-DREAM/Alliance A032101).",
            "Indication": "Metastatic hormone-sensitive prostate cancer (mHSPC)",
            "Drug": "Androgen receptor pathway inhibitors (ARPIs)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint is remaining treatment-free with eugonadal testosterone 18 months after the start of treatment interruption. Secondary endpoints include time to eugonadal testosterone, duration off treatment, and changes in patient-reported outcomes.",
            "Conclusion": "The study was activated in July 2022 and accrual is ongoing."
        },
        {
            "Title": "LIBERTAS: A degendered and transgender-inclusive phase 3 study of apalutamide (APA) plus intermittent vs continuous androgen deprivation therapy (ADT) in participants (pts) with metastatic hormone-sensitive prostate cancer (mHSPC).",
            "Indication": "Metastatic hormone-sensitive prostate cancer (mHSPC)",
            "Drug": "Apalutamide (APA) and Androgen Deprivation Therapy (ADT)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoints are radiographic progression-free survival and hot flash frequency and severity score at 18 months from randomization. Secondary endpoints include findings from electronic patient-reported outcomes, digital health assessments, and neurocognitive function.",
            "Conclusion": "The study is enrolling patients with a target of 333 participants over 2 years at 86 sites in 9 countries."
        },
        {
            "Title": "Veterans Affairs seamless phase II/III randomized trial of standard systemic therapy with or without PET-directed local therapy for oligometastatic prostate cancer (VA STARPORT).",
            "Indication": "Oligometastatic prostate cancer",
            "Drug": "Standard systemic therapy with or without PET-directed local therapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Castration-resistant prostate cancer-free survival (CRPC-free survival), radiographic PFS, clinical PFS, freedom from index lesion progression, toxicity, quality of life, and prostate cancer-specific and overall survival.",
            "Conclusion": "The primary goal is to determine if adding PET-directed local therapy (PDLT) to standard systemic therapy (SST) improves disease control compared to SST alone in Veterans with recurrent and de novo oligometastatic prostate cancer."
        },
        {
            "Title": "A randomized phase II study of ADT + abiraterone versus ADT + abiraterone + docetaxel in patients with low-volume metastatic hormone-sensitive prostate cancer.",
            "Indication": "Low-volume metastatic hormone-sensitive prostate cancer",
            "Drug": "ADT + abiraterone versus ADT + abiraterone + docetaxel",
            "PFS": "Radiographic progression-free survival (rPFS): control arm median 33 months vs. docetaxel arm median 55 months, Hazard Ratio = 0.60",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "PSA response rate, overall response rate, time to castration-resistant prostate cancer, time to initiation of subsequent anti-neoplastic therapy, quality of life assessments, and rates of adverse events.",
            "Conclusion": "The primary objective is to assess and compare radiographic progression-free survival (rPFS) between treatment arms."
        },
        {
            "Title": "Darolutamide plus androgen-deprivation therapy in patients with high-risk biochemical recurrence of prostate cancer: A phase 3, randomized, double-blind, placebo-controlled study (ARASTEP).",
            "Indication": "High-risk biochemical recurrence of prostate cancer",
            "Drug": "Darolutamide plus androgen-deprivation therapy (ADT)",
            "PFS": "Radiological progression-free survival (rPFS) by PSMA PET/CT",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Metastasis-free survival (MFS), time to CRPC, time to first subsequent systemic antineoplastic therapy, time to locoregional progression by PSMA PET/CT, time to first symptomatic skeletal event, overall survival, PSA < 0.2 ng/mL at 12 months, time to deterioration in FACT-P score, safety, and time to symptomatic progression.",
            "Conclusion": "The primary endpoint is radiological progression-free survival (rPFS) by PSMA PET/CT assessed by BICR."
        },
        {
            "Title": "Phase III, double-blind, placebo-controlled, 2-cohort, randomized study of saruparib (AZD5305) in combination with new hormonal agents in patients with metastatic castration-sensitive prostate cancer with and without homologous recombination repair mutation (EvoPAR-Prostate01).",
            "Indication": "Metastatic castration-sensitive prostate cancer",
            "Drug": "Saruparib in combination with new hormonal agents (abiraterone, darolutamide, or enzalutamide)",
            "PFS": "Radiographic progression-free survival (rPFS)",
            "OS": "Overall survival (OS)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The primary endpoint is radiographic progression-free survival (rPFS), with overall survival (OS) as a key secondary endpoint."
        },
        {
            "Title": "Debunking the frailty-sarcopenia-ADT axis in metastatic prostate cancer with multicomponent exercise: The FIERCE trial.",
            "Indication": "Metastatic prostate cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary outcome: frailty measured by the Fried Frailty phenotype and associated biomarkers (IL-6, TNF-a, CRP). Secondary outcomes: sarcopenia measured using CT scans and muscle biopsy-driven biomarkers (myokines and insulin pathway markers). Exploratory outcome: effect of exercise on cancer cell line growth (LNCaP cell line).",
            "Conclusion": "The trial aims to assess the effects of a 16-week multicomponent exercise intervention on frailty and sarcopenic status in men with metastatic prostate cancer receiving ADT."
        },
        {
            "Title": "A phase 2 randomized trial of neoadjuvant enoblituzumab versus standard of care in men with high-risk localized prostate cancer: The HEAT prostate cancer randomized study.",
            "Indication": "High-risk localized prostate cancer",
            "Drug": "Enoblituzumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: recurrence-free survival (RFS) defined as any metastasis events, local pelvic visceral or lymph node recurrence, detectable PSA, or start of subsequent local or systemic therapy, or death for any cause. Secondary endpoints: additional clinical and immunologic correlates.",
            "Conclusion": "The trial hypothesizes that targeting B7-H3 with enoblituzumab will delay or prevent recurrence following prostatectomy compared to standard of care."
        },
        {
            "Title": "A phase 1 safety and feasibility study of intratumoral placement of bicalutamide implants combined with radiotherapy for localized prostate cancer.",
            "Indication": "Localized prostate cancer",
            "Drug": "Bicalutamide (Biolen Implant)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: safety, feasibility, and PK profile for Biolen for localized delivery of bicalutamide into the prostate when delivered with radiotherapy.",
            "Conclusion": "The study aims to assess the safety and feasibility of replacing systemic ADT with targeted local delivery of an anti-androgen agent (bicalutamide) using Biolen implants."
        },
        {
            "Title": "ALADDIN: Evaluation of darolutamide addition to androgen deprivation therapy and radiation therapy in newly diagnosed prostate cancer with pelvic lymph nodes metastases.",
            "Indication": "Prostate cancer with pelvic lymph node metastases",
            "Drug": "Darolutamide",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: failure-free survival (FFS). Secondary endpoints: MFS, progression-free survival, safety, overall survival, and health-related quality of life.",
            "Conclusion": "The trial hypothesizes that adding Darolutamide to ADT and RT could improve FFS for high-risk patients."
        },
        {
            "Title": "A phase 2 study of neoadjuvant PARP inhibition followed by radical prostatectomy (RP) in patients with unfavorable intermediate-risk or high-risk prostate cancer with BRCA1/2 gene alterations (NePtune).",
            "Indication": "Unfavorable intermediate-risk or high-risk prostate cancer with BRCA1/2 gene alterations",
            "Drug": "Olaparib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: rate of pathologic complete response (pCR) or minimum residual disease (MRD, tumor \u22645mm). Secondary endpoints: PSA response, surgical staging at RP, positive margin rate, time to testosterone recovery, and safety. Exploratory endpoints: quality of life assessment, proportion of downstaging on multi-parametric MRI (mpMRI), correlation of mpMRI with pathologic response, and tissue-based molecular predictors of response and resistance.",
            "Conclusion": "The study aims to evaluate the efficacy of neoadjuvant olaparib in combination with a LHRH agonist followed by RP in patients with high-risk localized prostate cancer with BRCA1/2 alterations. The trial is currently enrolling patients."
        },
        {
            "Title": "CLARIFY: Positron emission tomography using 64Cu-SAR-bisPSMA in patients with high-risk prostate cancer prior to radical prostatectomy (a phase 3 diagnostic study).",
            "Indication": "High-risk prostate cancer",
            "Drug": "64Cu-SAR-bisPSMA",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary objective: assess the sensitivity and specificity of 64Cu-SAR-bisPSMA PET to detect regional (pelvic) nodal metastases. Secondary objectives: assessment of safety and determining the positive and negative predictive value of 64Cu-SAR-bisPSMA PET for the detection of PC within the pelvic LN field.",
            "Conclusion": "The study aims to establish the diagnostic performance of 64Cu-SAR-bisPSMA in men with high-risk prostate cancer. The trial is open for recruitment in the United States, with additional sites in start-up in Australia."
        },
        {
            "Title": "Randomized PROSTATE-IQ trial to reduce ADT treatment burden for patients with biochemical recurrence after prostatectomy.",
            "Indication": "Biochemical recurrence of prostate cancer after prostatectomy",
            "Drug": "Androgen Deprivation Therapy (ADT) and Apalutamide",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The trial randomizes men between traditional ADT and apalutamide-based therapy. Primary endpoints include fatigue, activity, sleep, mental health, body composition, metabolic dysregulation, quality of life, and cancer control.",
            "Conclusion": "The study aims to reduce the adverse effects of ADT treatment and improve quality of life for prostate cancer survivors treated with salvage radiation and androgen axis therapy. The trial is the first to use the validated ArteraAI test to personalize the intensity of androgen axis therapy."
        },
        {
            "Title": "Final results of BrUOG 354: A randomized phase II trial of nivolumab alone or in combination with ipilimumab for people with ovarian and other extra-renal clear cell carcinomas.",
            "Indication": "Ovarian and other extra-renal clear cell carcinomas",
            "Drug": "Nivolumab alone or in combination with ipilimumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "Atezolizumab versus placebo in combination with bevacizumab and non-platinum-based chemotherapy in recurrent ovarian cancer: Final overall and progression-free survival results from the AGO-OVAR 2.29/ENGOT-ov34 study.",
            "Indication": "Recurrent ovarian cancer",
            "Drug": "Atezolizumab in combination with bevacizumab and non-platinum-based chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "Vibostolimab coformulated with pembrolizumab (vibo/pembro) for previously treated advanced mismatch repair\u2013deficient (dMMR) endometrial cancer: Results from cohort B1 of the phase 2 KEYVIBE-005 study.",
            "Indication": "Advanced mismatch repair\u2013deficient (dMMR) endometrial cancer",
            "Drug": "Vibostolimab coformulated with pembrolizumab (vibo/pembro)",
            "PFS": "Median PFS was 15.0 months (95% CI, 8.1-15.6); 12-month PFS rate was 58%",
            "OS": "Median OS was not reached (95% CI, 16.1-NR); 12-month OS rate was 82%",
            "ORR": "ORR was 65% (n=26; 95% CI, 48-79); 5 patients (13%) had a complete response and 21 (53%) had a partial response",
            "AE": "Treatment-related adverse events occurred in 38 patients (95%). Grade 3 or 4 treatment-related AEs occurred in 14 patients (35%), with the most common being arthritis (5%) and maculopapular rash (5%). Nine patients (23%) discontinued treatment due to a treatment-related AE. Any-grade immune-mediated AEs or infusion reactions occurred in 21 patients (53%) and grade 3 or 4 events occurred in 7 patients (18%). No deaths due to treatment-related AEs or immune-mediated AEs or infusion reactions occurred.",
            "Other": "Median DOR was 13.7 months (95% CI, 12.7-NR), and 75% of responders remained in response for \u226512 months based on the Kaplan-Meier method for censored data.",
            "Conclusion": "Vibo/pembro demonstrated durable antitumor activity and a manageable safety profile in patients with previously treated advanced dMMR endometrial cancer."
        },
        {
            "Title": "Adjuvant chemotherapy following concurrent chemoradiation (CRT) in patients with high-risk early-stage cervical carcinoma following radical hysterectomy: Results of NRG oncology/RTOG 0724/GOG-0724.",
            "Indication": "High-risk early-stage cervical carcinoma",
            "Drug": "Paclitaxel and Carboplatin",
            "PFS": "4-year DFS estimates: 76% (90% CI: 69%, 83%) for CRT and 77% (90% CI: 70%, 84%) for CRT+chemo (HR=1.05 [90% CI: 0.65, 1.68], p=0.56)",
            "OS": "4-year OS estimates: 87% (90% CI: 82%, 93%) for CRT and 89% (90% CI: 84%, 94%) for CRT+chemo (HR=0.91 [90% CI: 0.49 to 1.69]; 1-sided log-rank p=0.40)",
            "ORR": "None",
            "AE": "Grade 3 and 4 events: 36% and 7% in CRT; 44% and 21% in CRT+chemo",
            "Other": "None",
            "Conclusion": "The addition of systemic chemotherapy following CRT didn\u2019t improve DFS or OS in cervical cancer patients with high-risk factors after radical hysterectomy."
        },
        {
            "Title": "Phase II randomized multi-centre study of neoadjuvant olaparib in patients with platinum sensitive relapsed high grade serous ovarian cancer: The NEO trial.",
            "Indication": "Platinum sensitive relapsed high grade serous ovarian cancer",
            "Drug": "Olaparib",
            "PFS": "PFS rates at 3 years: 84.2% (69.3%-100%) for arm A and 75.1% (56.6%-99.7%) for arm B (HR: 0.90 (0.28, 2.83))",
            "OS": "OS rates at 3 years: 84.2% (69.3%-100%) for arm A and 75.1% (56.6%-99.7%) for arm B (HR: 0.90 (0.28, 2.83))",
            "ORR": "None",
            "AE": "No grade 3 AEs during neoadjuvant therapy; reported in 16% and 4% pts in arms A and B respectively during first 6 months of adjuvant therapy",
            "Other": "Subjects with no visible residual disease had better OS (HR=0.23, p=0.0097)",
            "Conclusion": "Neo-adjuvant olaparib followed by cytoreductive surgery was feasible and safe in PSHGSOC. In pts with resectable disease at secondary cytoreduction, olaparib alone post-surgery was as effective as chemotherapy followed by olaparib and less toxic, suggesting the potential for a chemo-free approach in this selected population."
        },
        {
            "Title": "A phase 1/2 study of the TORC1/2 inhibitor onatasertib combined with toripalimab in patients with advanced solid tumors: Cervical cancer cohort.",
            "Indication": "Cervical cancer",
            "Drug": "Onatasertib and Toripalimab",
            "PFS": "Median PFS: 8.4 months, 24m PFS rate: 31.0%",
            "OS": "Median OS: Not reached, 24m OS rate: 51.6%",
            "ORR": "Overall ORR: 53.3%, PD-L1 positive ORR: 61.5%, PD-L1 negative ORR: 55.6%, PD-L1 NA ORR: 37.5%",
            "AE": "Grade \u22653 TRAEs: 41.9%, Common Grade \u22653 TRAEs: Rash (12.9%), Lymphocyte count decreased (9.7%), Decreased platelet count (6.5%)",
            "Other": "Disease control rate: 86.7%, Median time to response: 1.4 months",
            "Conclusion": "Onatasertib in combination with toripalimab is tolerable with encouraging response rate and disease stabilization in cervical cancer patients at the RP2D combination dose, regardless of PD-L1 expression. Enrollment in the expansion cohort for CPI-treated cervical cancer is ongoing, and updated data will be presented."
        },
        {
            "Title": "Combination ATR and PARP Inhibitor (CAPRI): A phase 2 study of ceralasertib plus olaparib in patients with recurrent, platinum-sensitive epithelial ovarian cancer (cohort A).",
            "Indication": "Platinum-sensitive recurrent high-grade serous ovarian cancer",
            "Drug": "Ceralasertib and Olaparib",
            "PFS": "Median PFS: 8.3 months (95% CI: 5.9, 10.7)",
            "OS": "None",
            "ORR": "Overall ORR: 48.5%, ORR in tumors without genomic instability: 43.8%, ORR in tumors with genomic instability: 40%, ORR in germline HR gene alterations: 100%, ORR in unknown status of genomic instability: 50%",
            "AE": "Grade 3 toxicity: 40.5%, Common Grade 3 toxicities: Anemia (21.6%), Diarrhea (5.4%), Grade 4 thrombocytopenia: 5.4%",
            "Other": "Median number of prior regimens: 1 (range 1-3), Median number of cycles: 8 (range 1-50)",
            "Conclusion": "Ceralasertib plus olaparib was well tolerated and active in patients with platinum-sensitive high-grade serous ovarian cancer, warranting further evaluation. Efficacy was seen regardless of the presence of tumor genomic instability."
        },
        {
            "Title": "A phase II study of fulvestrant and abemaciclib in hormone receptor positive advanced or recurrent endometrial cancer.",
            "Indication": "Advanced or recurrent hormone receptor-positive endometrial cancer",
            "Drug": "Fulvestrant and Abemaciclib",
            "PFS": "Median PFS: 9.0 months (90% CI: 1.8 to 20.4)",
            "OS": "Median OS: 37.8 months (90% CI: 16.3 to NE)",
            "ORR": "Overall ORR: 44% (90% CI: 27.0% to 62.1%)",
            "AE": "Common Grade \u22653 treatment related adverse events: Neutropenia (22%), Anemia (19%)",
            "Other": "Median duration of response: 15.6 months (90% CI: 7.2\u2013NE), Responses in grade 1 or 2 endometrioid ECs: 100%, Responses in copy number low/no specific molecular profile ECs: 91%, Response in microsatellite instability-high EC: 9%",
            "Conclusion": "The combination of abemaciclib and fulvestrant has promising activity with durable responses observed in patients with advanced or recurrent endometrial cancer; a phase 3 trial is planned."
        },
        {
            "Title": "Efficacy and safety of sintilimab plus paclitaxel and cisplatin as neoadjuvant therapy for locally advanced cervical cancer: A phase II trial.",
            "Indication": "Locally advanced cervical cancer",
            "Drug": "Sintilimab, paclitaxel, cisplatin",
            "PFS": "None",
            "OS": "None",
            "ORR": "97.7% (42/43)",
            "AE": "Grade 3-4 neutropenia (15 patients), skin rash (17/47, G1-2), alanine aminotransferase increased (8/47, G1), hypothyroidism (6/47, G1-2), creatinine increased (5/47, G1), hyperlipidemia (1/47, G1)",
            "Other": "Pathological complete remission rate (pCR): 32.6% (14/43), optimal remission rate: 51.2% (22/43)",
            "Conclusion": "Sintilimab plus paclitaxel and cisplatin as neoadjuvant therapy showed encouraging antitumor activity with 32.6% pCR rate and manageable toxicities in patients with locally advanced CC."
        },
        {
            "Title": "An open label, single arm phase II, multicenter trial of durvalumab and BVAC-C, in patients with HPV16- or 18-positive cervical cancer and failure of first-line platinum-based chemotherapy (DURBAC).",
            "Indication": "HPV16- or 18-positive cervical cancer",
            "Drug": "Durvalumab, BVAC-C",
            "PFS": "6-month PFS rate: 51% (95% CI 33\u201377)",
            "OS": "None",
            "ORR": "29.2% (7/24)",
            "AE": "Pyrexia and myalgia, mostly grade 1 and manageable",
            "Other": "Disease control rate (DCR): 66.7% (16/24), median duration of response (DOR) and PFS not reached",
            "Conclusion": "The combination of BVAC-C and durvalumab demonstrates promise as a second-line treatment option with a manageable safety profile for patients with recurrent HPV 16/18 positive cervical cancer."
        },
        {
            "Title": "Nimotuzumab plus concurrent chemoradiotherapy for locally advanced cervical squamous cell carcinoma: The randomized, phase 3 CC3 study.",
            "Indication": "Locally advanced cervical squamous cell carcinoma",
            "Drug": "Nimotuzumab, concurrent chemoradiotherapy (CCRT)",
            "PFS": "1-year PFS rate: 96.1% (Nimogroup) vs. 92.1% (CCRT group), HR=0.76, 95% CI: 0.33-1.72, P=0.507",
            "OS": "3-year OS not reached",
            "ORR": "83.80% (Nimogroup) vs. 74.31% (CCRT group), P=0.049",
            "AE": "Most common AEs were graded 1-2",
            "Other": "Complete response (CR) rate: 40.14% (Nimogroup) vs. 39.58% (CCRT group)",
            "Conclusion": "Nimotuzumab combined with CCRT showed significant ORR improvement, longer trend in PFS and good safety in the treatment of LACC patients."
        },
        {
            "Title": "A phase III randomized, double-blinded, placebo-controlled study of suvemcitug combined with chemotherapy for platinum-resistant ovarian cancer (SCORES).",
            "Indication": "Platinum-resistant ovarian cancer",
            "Drug": "Suvemcitug combined with chemotherapy",
            "PFS": "4.6 months (95% CI: 3.42-5.9)",
            "OS": "None",
            "ORR": "40.4% (95% CI: 30.7-50.7)",
            "AE": "76% of patients had documented adverse events. Most common grade 3-4 toxicities included hypertension (3%), thrombocytopenia (3%), and hemorrhagic cystitis (1%).",
            "Other": "None",
            "Conclusion": "Bevacizumab in combination with oral cyclophosphamide was well tolerated and active, with a response rate of 40% in patients with recurrent platinum-resistant high-grade ovarian cancer."
        },
        {
            "Title": "Relationship of cancer stem cell functional assay and objective response rate of patients with recurrent platinum-resistant ovarian cancer in a randomized trial.",
            "Indication": "Recurrent platinum-resistant epithelial ovarian cancer (EOC)",
            "Drug": "Chemotherapies guided by the ChemoID assay",
            "PFS": "None",
            "OS": "None",
            "ORR": "55% (CI 95: 39%\u201373%) for ChemoID-guided arm vs. 5% (CI 95: 0%\u201311%) for physician's choice arm",
            "AE": "None",
            "Other": "Median duration of response: 8 months for ChemoID-guided arm vs. 5.5 months for physician's choice arm. Median clinical benefit rate (mCBR): 85% (CI 95: 73%\u201397%) for ChemoID-guided arm vs. 24% (CI 95: 11%\u201338%) for physician's choice arm.",
            "Conclusion": "Individually screening standard cytotoxic chemotherapy treatments with the ChemoID assay improves objective responses in recurrent platinum-resistant EOC patients."
        },
        {
            "Title": "Optimization of intra-peritoneal chemotherapy: Randomized phase II GERCOR CHIMOVIP study.",
            "Indication": "Epithelial ovarian cancer stage III or IV",
            "Drug": "IP cisplatin and IV epirubicin vs. IV carboplatin and paclitaxel",
            "PFS": "None",
            "OS": "Median OS: Not reached (95% CI: 3.3-NE) in IP arm vs. 3.5 years (95% CI: 1.8-5.3) in IV arm",
            "ORR": "None",
            "AE": "Grade 3-4 toxicities: 31.6% in IP arm vs. 55.5% in standard arm",
            "Other": "Median EFS: 3.0 years (95% CI: 1.1-NE) in IP arm vs. 1.4 years (95% CI: 0.9-1.8) in IV arm. pCR: 63.2% (90% CI: 42-81) in IP arm vs. 61.1% (90% CI: 39-80) in IV arm.",
            "Conclusion": "While both arms showed a high pCR rate, EFS suggests that pCR is not a good surrogate for OS. The observed benefits in EFS and OS in the IP arm suggest a greater advantage of IP for CC0 patients."
        },
        {
            "Title": "Secondary cytoreduction followed by chemotherapy versus chemotherapy alone in platinum-sensitive relapsed ovarian cancer (SOC-1): A final overall survival analysis of a multicenter, open-label, randomized, phase 3 trial.",
            "Indication": "Platinum-sensitive relapsed ovarian cancer",
            "Drug": "Chemotherapy",
            "PFS": "None",
            "OS": "Median OS: 58.1 months (surgery group) vs 52.1 months (control group), HR: 0.80 (95% CI, 0.61 to 1.05), P=0.109",
            "ORR": "None",
            "AE": "None",
            "Other": "Median time to second subsequent therapy: 34.8 months (surgery group) vs 28.1 months (control group), HR: 0.68 (95% CI, 0.55 to 0.87)",
            "Conclusion": "Secondary cytoreduction did not increase overall survival in the intention-to-treat population but led to a longer duration of overall survival when the crossover effect was taken into consideration. These findings are arguably clinically meaningful and support the efficacy of secondary cytoreduction in specialized centers and selected patients."
        },
        {
            "Title": "Combination of cemiplimab and ISA101b vaccine for the treatment of recurrent/metastatic HPV16 cervical cancer.",
            "Indication": "Recurrent HPV16 cervical cancer",
            "Drug": "Cemiplimab + ISA101b vaccine",
            "PFS": "Median PFS: 3.0 months (95% CI, 1.7\u20134.0)",
            "OS": "Median OS: 13.3 months (95% CI, 10.8\u201316.3)",
            "ORR": "16.8% (95% CI, 9.9\u201323.7)",
            "AE": "92.9% of patients experienced treatment-emergent AEs, with the most common being injection-site reaction (38.9%), anemia (25.7%), and nausea (22.1%). 5.3% of patients died due to a treatment-emergent AE.",
            "Other": "ORR by PD-L1 expression in tumor cells: 12.5% for patients with PD-L1 <1% and 22.4% for patients with PD-L1 \u22651%. Median DOR: 5.6 months (95% CI, 3.5\u2013not estimable).",
            "Conclusion": "Results from this phase 2 study of cemiplimab + ISA101b in patients with recurrent HPV16 cervical cancer show clinical benefit, especially in patients with high PD-L1 expression, and no unexpected safety signals, supporting further research."
        },
        {
            "Title": "Efficacy and safety of PM8002, a bispecific antibody targeting PD-L1 and VEGF-A, as a monotherapy in patients with solid tumors: Clinical data from advanced cervical cancer and platinum-resistant recurrent ovarian cancer cohorts.",
            "Indication": "Advanced cervical cancer and platinum-resistant recurrent ovarian cancer",
            "Drug": "PM8002",
            "PFS": "8.3 months (cervical cancer), 5.3 months (PROC)",
            "OS": "None",
            "ORR": "42.2% (cervical cancer), 20.6% (PROC)",
            "AE": "95.4% any-grade TRAEs, 35.6% Grade 3 TRAEs, 57.5% any-grade irAEs, 8% Grade 3 irAEs, 33.3% SAEs, 25.3% treatment-related SAEs",
            "Other": "DCR: 93.3% (cervical cancer), 67.7% (PROC)",
            "Conclusion": "PM8002 showed promising antitumor activity as a monotherapy in previously treated patients with PROC or cervical cancer. Multiple Phase II combination trials of PM8002 with chemotherapy are ongoing."
        },
        {
            "Title": "Efficacy and safety of enlonstobart (SG001), a PD-1 inhibitor in patients with PD-L1 positive recurrent/metastatic cervical cancer: A multicenter, single-arm, open-label, phase II study.",
            "Indication": "PD-L1 positive recurrent/metastatic cervical cancer",
            "Drug": "Enlonstobart (SG001)",
            "PFS": "3.1 months",
            "OS": "Not reached",
            "ORR": "29.0%",
            "AE": "97.2% any-grade TEAEs, 35.5% Grade 3 or higher events, 41.1% irAEs",
            "Other": "DCR: 54.2%, Median duration of response: 16.6 months",
            "Conclusion": "SG001 monotherapy demonstrated durable anti-tumor activity and acceptable safety in patients with PD-L1 positive recurrent/metastatic cervical cancer, whose disease experienced progression after first-line platinum-based therapy."
        },
        {
            "Title": "Nimotuzumab in combination with radiotherapy for elderly patients with locally advanced cervical squamous cell carcinoma: Results from a prospective, multicenter, open-label, single-arm trial.",
            "Indication": "Locally advanced cervical squamous cell carcinoma (LACSCC)",
            "Drug": "Nimotuzumab",
            "PFS": "None",
            "OS": "1 year-OS: 96.03%, 2 year-OS: 90.21%",
            "ORR": "89.92%",
            "AE": "Grade 3-5 AEs: Leukopenia (3.36%), Anemia (2.52%), Neutropenia (1.68%)",
            "Other": "DFS: 1 year-DFS: 85.71%, 2 year-DFS: 78.31%, DCR: 93.28%, CRR: 52.94%",
            "Conclusion": "Among elderly LACSCC patients, adding nimotuzumab to radiotherapy indicated promising efficacy and appeared to have manageable toxicity. Nimotuzumab plus radiotherapy could represent a novel treatment strategy for this population."
        },
        {
            "Title": "Evaluation of the effectiveness and safety of disitamab vedotin in HER2-expressing 2L recurrent or metastatic cervical cancer (r/mCC): Interim results of RC48-C018",
            "Indication": "Recurrent or metastatic cervical cancer (r/mCC)",
            "Drug": "Disitamab Vedotin (DV)",
            "PFS": "4.37 months (95% CI: 2.92, 6.90)",
            "OS": "Unmature (95% CI: 9.63, not available); 12-month OS rate was 66% (95% CI: 35%, 85%)",
            "ORR": "36.4% (8/22); confirmed ORR was 31.8% (7/22)",
            "AE": "Most common treatment-related AE included ALT increased (56%), AST increased (56%), and white blood cell count decreased (52%). Grade 3 or higher TRAEs included neutrophil count decreased (12%), ALT increased (8%). Two pts (8%) experienced treatment-related SAE. No DV-related deaths occurred.",
            "Other": "DCR was 86.4% (19/22); median time to response was 1.5 months (range 1-3); median DoR was 5.52 months (95% CI: 2.10, NE). Subgroup analysis showed ORRs of 20% (2/10), 50% (4/8), and 25% (1/4) in the 2L, 3L, and 4L+ pts, respectively; ORRs of 50% (6/12), 12.5% (1/8), and 0 (0/2) for pts with IHC scores of 1+, 2+, and 3+ respectively; ORRs of 42.9% (6/14) and 12.5% (1/8) for pts with squamous cell carcinoma and adenocarcinoma, respectively.",
            "Conclusion": "DV showed manageable safety and promising efficacy in HER2-expressing r/mCC pts who have failed from multiple lines of therapy. These results support further investigation for DV in r/mCC."
        },
        {
            "Title": "A single-arm, phase II trial assessing the combination of AK105 and anlotinib in first-line treatment for persistent, recurrent, or metastatic cervical cancer",
            "Indication": "Persistent, recurrent, or metastatic cervical cancer",
            "Drug": "AK105 and anlotinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "60.0% (95% CI: 26.2%-87.8%)",
            "AE": "Incidence of any grades TRAEs was 86.7%. Grade 3 TRAEs incidence was 33.3% including hypertension (1/15, 6.7%), thrombocytopenia (1/15, 6.7%), elevated alanine aminotransferase (1/15, 6.7%), adrenal insufficiency (1/15, 6.7%), and rash (1/15, 6.7%), pulmonary infection (1/15, 6.7%). No Grade 4 or death occurred.",
            "Other": "DCR was 80.0% (95% CI: 44.4%-97.5%).",
            "Conclusion": "This chemo-free therapy of AK105 plus anlotinib in the first-line treatment with persistent, recurrent, or metastatic cervical cancer showed promising efficacy with favorable toxicity profile. More data will be reported in the future."
        },
        {
            "Title": "Subsequent therapy data from ENGOT-cx12/GOG-3057/innovaTV 301",
            "Indication": "Recurrent or metastatic cervical cancer (r/mCC)",
            "Drug": "Tisotumab vedotin (TV)",
            "PFS": "None",
            "OS": "Observed OS and PFS benefits for TV vs chemotherapy were generally consistent in prespecified subgroups, notably regardless of prior exposure to anti-PD-(L)1.",
            "ORR": "17.8% (95% CI, 13.3-23.1) vs 5.2% (95% CI, 2.8-8.8)",
            "AE": "None",
            "Other": "DCR was 75.9% (95% CI, 70.1-81.0) vs 58.2% (95% CI, 51.8-64.4) in the TV vs chemotherapy arms, respectively. Median time-to-response was 1.58 months and 1.74 months in the TV and chemotherapy arms, respectively. Following discontinuation of study treatment, 127 (50.2%) pts on the TV arm and 108 (43.4%) on the chemotherapy arm received subsequent anticancer therapy, of which the majority received systemic therapy for progressive disease, including 112 (88.2%) pts on the TV arm and 91 (84.3%) pts on the chemotherapy arm. Median time from last dose of study treatment to first subsequent therapy was 6.7 weeks (range: 1-35) for pts treated with TV and 5.4 weeks (range: 1-51) for pts treated with chemotherapy. The most common types of subsequent systemic therapies were cytotoxic chemotherapy (82/112 [73.2%] vs 57/91 [62.2%] on the TV and chemotherapy arms, respectively) and immunotherapy (42/112 [37.5%] and 32/91 [35.2%] on the TV and chemotherapy arms, respectively).",
            "Conclusion": "TV previously demonstrated a significant improvement in OS vs chemotherapy that was generally consistent across prespecified subgroups. Additionally, a meaningful proportion of pts received subsequent anticancer therapy in both treatment arms, with cytotoxic chemotherapy and immunotherapy as the most common choices. These data show that TV provides meaningful clinical benefit and does not prevent pts from receiving subsequent therapies, including immunotherapy."
        },
        {
            "Title": "Prognostic analysis of nimotuzumab combined with concurrent chemoradiotherapy in locally advanced cervical cancer: A multicenter real-world study.",
            "Indication": "Locally advanced cervical cancer (LACC)",
            "Drug": "Nimotuzumab",
            "PFS": "2-year PFS rate: 89.5%, Nimotuzumab group: 86.2%, No-Nimotuzumab group: 90.4%, p=0.645",
            "OS": "2-year OS rate: 93%, Nimotuzumab group: 93.2%, No-Nimotuzumab group: 93%, p=0.895",
            "ORR": "Overall ORR: 91.96%, Nimotuzumab group: 98.44%, No-Nimotuzumab group: 89.38%, p=0.024",
            "AE": "Hematological toxicity: Nimotuzumab group: 89.83%, No-Nimotuzumab group: 94.48%, p=0.730; Nausea and vomiting: Nimotuzumab group: 35.59%, No-Nimotuzumab group: 38.19%, p=0.865; Radiation proctitis: Nimotuzumab group: 45%, No-Nimotuzumab group: 33.1%, p=0.371; Radiation cystitis: Nimotuzumab group: 8.47%, No-Nimotuzumab group: 7.64%, p=0.761",
            "Other": "Reduction rate of lesions: Nimotuzumab group: 79.26%, No-Nimotuzumab group: 70.94%, p=0.046; CR rate: Nimotuzumab group: 53.13%, No-Nimotuzumab group: 35%, p=0.012",
            "Conclusion": "The combination of Nimotuzumab and CCRT for LACC has a better reduction rate of lesions and ORR, without increasing side effects."
        },
        {
            "Title": "Neoadjuvant chemo-immunotherapy following concurrent immuno-chemoradiotherapy and immune-maintenance therapy as primary treatment for locally advanced cervical cancer: A prospective, single-arm, phase 2 trial.",
            "Indication": "Locally advanced cervical cancer (LACC)",
            "Drug": "Camrelizumab plus chemotherapy (TP)",
            "PFS": "1-year PFS: 90.6% (85.4%-95.8%)",
            "OS": "None",
            "ORR": "Overall remission rate: 100% (98.1% complete remission, 1.9% partial remission)",
            "AE": "Grade 3-4 AEs: Decreased neutrophils (18.2%), Thrombocytopenia (9.1%), Anemia (27.3%); Serious AEs: 12.7%, most commonly due to infectious complications",
            "Other": "None",
            "Conclusion": "The interim data support Camrelizumab plus TP neoadjuvant chemo-immunotherapy following immuno-chemoradiotherapy and Camrelizumab maintenance therapy as an active go-forward regimen in LACC. No new safety signals were observed, and most AEs were mild to moderate."
        },
        {
            "Title": "The efficacy of recombinant human adenovirus type 5 (H101) intra-tumor therapy in persistent/recurrent/metastatic gynecological cancer and the exploration of changes in tumor microenvironment.",
            "Indication": "Persistent/Recurrent/Metastatic gynecological cancer",
            "Drug": "Recombinant human adenovirus type 5 (H101)",
            "PFS": "8.7 months",
            "OS": "Not reached",
            "ORR": "64%",
            "AE": "83% experienced treatment-related adverse events. Major adverse events: fever (64%), fatigue (31%), pain at the injection site (25%)",
            "Other": "Disease control rate: 79% by RECIST 1.1. Median duration of time: 7.1 months. Changes in tumor microenvironment: decreased B cells, T regulatory cells, and Ki67+ tumor cells; increased M1 macrophage, monocytes, CD4 and CD8 T cells.",
            "Conclusion": "H101 as second-line or later therapy for P/R/M gynecological cancer patients was efficacious and safe. H101 efficiently reshaped the TME, potentially associated with increased B cells and M1 TAM in the TME."
        },
        {
            "Title": "Efficacy and safety of cadonilimab plus anti-EGFR monoclonal antibody as de-chemotherapy regimen in persistent, recurrent, or metastatic gynecologic cancer.",
            "Indication": "Persistent/Recurrent/Metastatic gynecological cancer",
            "Drug": "Cadonilimab plus Nimotuzumab",
            "PFS": "Not reached, 6-month PFS rate: 58.1%",
            "OS": "Not reached, 6-month OS rate: 76.5%",
            "ORR": "58.6%",
            "AE": "Manageable and tolerable (Grade 1-2): hypothyroidism (13.8%), immune-mediated hepatitis (13.8%), rash maculo-papular (6.9%), thrombocytopenia (6.9%), nephritis (3.4%)",
            "Other": "None",
            "Conclusion": "The combination of Cadonilimab plus Nimotuzumab exhibited a promising tumor response rate (58.6%) and demonstrated a manageable safety profile. This suggests the potential of bi-specific immunotherapy coupled with anti-EGFR target therapy, especially in patients resistant to or intolerant of chemotherapy."
        },
        {
            "Title": "Avelumab with axitinib in persistent or recurrent cervical cancer after platinum-based chemotherapy (ALARICE study)",
            "Indication": "Persistent or recurrent cervical cancer",
            "Drug": "Avelumab and axitinib",
            "PFS": "Median PFS: 10.7 months (95% CI 3-18.5)",
            "OS": "Median OS: 20.9 months (95% CI 6.6-35.2)",
            "ORR": "33.3% (7/21) in intention-to-treat group, 38.9% in per-protocol group",
            "AE": "Grade 1-2 skin rash (52.4%), proteinuria (42.9%), 76.2% required axitinib dosage reduction",
            "Other": "Disease-control rate: 52.4% (11/21) in intention-to-treat group, 61.1% in per-protocol group; Median duration of response: 14.3 months (range 11.0-32.2 months); No deterioration in patient-reported outcomes",
            "Conclusion": "Avelumab and axitinib could produce a durable and clinically meaningful response in cervical cancer irrespective of PD-L1 status. These results supported further evaluation of this combination in recurrent or even primary cervical cancer."
        },
        {
            "Title": "Neoadjuvant combination treatment of olaparib and pembrolizumab for patients with HRD-positive advanced ovarian cancer.",
            "Indication": "HRD-positive advanced ovarian cancer",
            "Drug": "Olaparib and Pembrolizumab",
            "PFS": "12-month PFS rate was 91.7% (95% CI, 53.9-98.8)",
            "OS": "None",
            "ORR": "70% (95% CI, 45.7-88.1)",
            "AE": "35% grade 3 or higher AEs. Common AEs included nausea (50%), anemia (45%), vomiting (30%), and diarrhea (20%)",
            "Other": "Pathological response rate was 84.6% (95% CI, 54.6-98.1). Pathological complete response was achieved in 30% of patients.",
            "Conclusion": "Neoadjuvant combination treatment of olaparib and pembrolizumab showed promising efficacy and safety in patients with HRD-positive advanced ovarian cancer."
        },
        {
            "Title": "Efficacy, safety, and biomarkers of neoadjuvant niraparib monotherapy in homologous recombination deficiency (HRD) advanced ovarian cancer: A prospective, multicenter, phase II, single-arm NANT study.",
            "Indication": "HRD advanced ovarian cancer",
            "Drug": "Niraparib",
            "PFS": "None",
            "OS": "None",
            "ORR": "All pts: 62.5%, BRCAm pts: 77.3%, BRCAwt/HRD pts: 50.0%",
            "AE": "TRAEs of grade \u22653 observed in 61.2% of pts, hematological toxicities were the most common",
            "Other": "Surgical outcomes: R0 resection rate: All pts: 80.0%, BRCAm pts: 85.7%, BRCAwt/HRD pts: 73.7%",
            "Conclusion": "Neoadjuvant niraparib monotherapy regimen achieved encouraging ORR and R0 resection rate with tolerable toxicities in pts with HRD unresectable HGSOC, offering an alternative option for those pts unwilling to receive or unable to tolerate neoadjuvant chemotherapy. Pre-treatment TME factors were correlated with clinical response to PARP inhibitors."
        },
        {
            "Title": "Phase 1 study of BAT8006, a folate receptor alpha antibody-drug conjugate with strong bystander effect, in subjects with advanced solid tumors.",
            "Indication": "Advanced solid tumors, including platinum-resistant epithelial ovarian, fallopian tube, or primary peritoneal cancer",
            "Drug": "BAT8006",
            "PFS": "None",
            "OS": "None",
            "ORR": "41.7% (15/36) regardless of FRa expression",
            "AE": "TEAEs reported in 94.2% of subjects, most common TEAEs (\u226520%) of any grade were thrombocytopenia, neutropenia, anemia, leukopenia, nausea, vomiting, and constipation. Grade \u22653 AEs included thrombocytopenia (21.2%), neutropenia (28.8%), anemia (21.2%), leukopenia (28.8%).",
            "Other": "DCR: 86.1% (31/36). In subjects with FRa TPS \u226550%, ORR: 44.0%, DCR: 88.0%. In subjects with FRa TPS \u226575%, ORR: 50.0%, DCR: 91.7%.",
            "Conclusion": "BAT8006 was well-tolerated with manageable toxicity and demonstrated preliminary antitumor activity in ovarian cancer."
        },
        {
            "Title": "The efficacy and safety of RC88 in patients with ovarian cancer, non-squamous-non-small-cell lung-carcinoma and cervical cancer: Results from a first-in-human phase 1/2 study.",
            "Indication": "Ovarian cancer, non-squamous-non-small-cell lung-carcinoma, and cervical cancer",
            "Drug": "RC88",
            "PFS": "None",
            "OS": "None",
            "ORR": "Ovarian cancer: 37.2% (16/43), Non-squamous-non-small-cell lung-carcinoma: 21.7% (5/23), Cervical cancer: 35.3% (6/17)",
            "AE": "TEAEs reported in 98.2% of pts, with 40.2% \u2265 grade 3 TEAEs. The most frequent TRAEs were white blood cell count decreased (46.3%), neutrophil count decreased (42.1%), anemia (34.1%), nausea (32.3%), and aspartate aminotransferase increased (31.1%).",
            "Other": "The overall safety profile was better in 2.0 mg/kg than 2.5 mg/kg, therefore 2.0 mg/kg was chosen as RP2D in further studies in China.",
            "Conclusion": "RC88 demonstrated tolerable safety and encouraging preliminary efficacy in MSLN-expressing solid tumors, warranting further investigations."
        },
        {
            "Title": "A phase I single-arm, open-label trial of engineering tumor-infiltrating lymphocytes with membrane-bound IL-7 for recurrent ovarian cancer.",
            "Indication": "Recurrent ovarian cancer",
            "Drug": "Tumor-infiltrating lymphocytes with membrane-bound IL-7 (mbIL-7-TIL)",
            "PFS": "Median PFS was 5.5 months (range, 1.0-14.1)",
            "OS": "Median OS was not reached. Six-month and 12-month OS rates were 75.6% (95% CI: 57.4-99.6%) and 68.8% (95% CI: 49.3-95.9%), respectively.",
            "ORR": "33.3% (95% CI: 16.3-56.3%) with 2 (11.1%) complete response (CR) and 4 (22.2%) partial response (PR)",
            "AE": "Most common treatment-emergent adverse events (TEAE) were elevated C-reactive protein levels (33%), fever (33%), and fatigue (11%). Only one patient experienced grade 3 TEAE (neutropenia). No grade 5 events occurred.",
            "Other": "Disease control rate (DCR) was 83.3% (95% CI: 60.8-94.2%).",
            "Conclusion": "Infusion of mbIL-7-TIL has acceptable safety and potent efficacy in recurrent OC patients who have limited treatment options."
        },
        {
            "Title": "Final safety results from ATHENA\u2013MONO (GOG-3020/ENGOT-ov45), a randomized, placebo-controlled, double-blind, phase 3 trial evaluating rucaparib monotherapy as maintenance treatment in patients with newly diagnosed ovarian cancer.",
            "Indication": "Newly diagnosed ovarian cancer",
            "Drug": "Rucaparib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Most common grade \u22653 TEAEs were anemia/decreased hemoglobin (28.7%), neutropenia/decreased neutrophils (14.6%), increased ALT/AST (10.6%), and thrombocytopenia/decreased platelets (7.3%). TEAEs leading to discontinuation were anemia/decreased hemoglobin (3.5%), asthenia/fatigue (4.9%), and nausea (2.1%).",
            "Other": "Myelodysplastic syndromes (MDS)/acute myeloid leukemia (AML) were reported in a total of 3 pts in the rucaparib group (1 MDS during treatment [0.2%] and 1 AML and 1 MDS during long-term follow-up [0.5%]).",
            "Conclusion": "Long-term maintenance treatment with rucaparib demonstrates a consistent and manageable safety profile in a broad population of pts with newly diagnosed ovarian cancer. No new safety signals were identified. Adverse events were generally manageable and led to discontinuation in a low proportion of pts."
        },
        {
            "Title": "First-in-human, phase I study of CBP-1008, a first-in-class bispecific ligand drug conjugate (Bi-XDC), in patients with advanced solid tumors.",
            "Indication": "Advanced solid tumors, including ovarian cancer",
            "Drug": "CBP-1008",
            "PFS": "None",
            "OS": "None",
            "ORR": "31.7% overall; 31.6% for patients with FRa expression level 0 to 49%",
            "AE": "Common (\u226550%) treatment-related AEs: neutropenia, WBC decreased, AST increased, pyrexia, ALT increased, nausea. Grade 3/4 TRAEs (\u22655%): neutropenia (49%), WBC decreased (26.8%), AST increased (5.9%), ALT increased (5.9%), anaemia (5.4%)",
            "Other": "Disease control rate (DCR): 70.7%. Longest treatment duration exceeds 13 months.",
            "Conclusion": "CBP-1008 demonstrated manageable safety profile and promising antitumor activity in ovarian cancer patients at a dose of 0.15mg/kg, especially in platinum-resistant HGSOC patients with \u22643 prior treatment regimens. CBP-1008 has the potential to provide a better treatment option for ovarian cancer patients regardless of FRa expression levels."
        },
        {
            "Title": "A phase II study of anlotinib plus albumin-bound paclitaxel in patients with recurrent, platinum-resistant primary epithelial ovarian or peritoneal carcinoma (A-Plus trial): Updated efficacy and safety.",
            "Indication": "Recurrent, platinum-resistant primary epithelial ovarian or peritoneal carcinoma",
            "Drug": "Anlotinib plus albumin-bound paclitaxel",
            "PFS": "None",
            "OS": "None",
            "ORR": "Overall response rate (ORR): 56.8% in the intention-to-treat population, 58.1% in the per-protocol population",
            "AE": "Grade 3/4 AEs: leukopenia (70.5%), gum pain (22.7%), hypertension (22.7%), hand-foot syndrome (9.1%)",
            "Other": "None",
            "Conclusion": "Updated results reconfirm a favorable antitumor activity and manageable safety from anlotinib plus albumin-bound paclitaxel in the treatment of recurrent ovarian or peritoneal carcinoma. Further survival results are expected."
        },
        {
            "Title": "Overall survival (OS) in patients with platinum-sensitive relapsed ovarian cancer (PSROC) treated with olaparib maintenance monotherapy: Update from the L-MOCA trial.",
            "Indication": "Platinum-sensitive relapsed ovarian cancer (PSROC)",
            "Drug": "Olaparib",
            "PFS": "None",
            "OS": "Median OS: 54.4 months (95% CI 43.8\u2013NE) in the full analysis set (FAS); BRCA mutant subgroup: NR (51.9\u2013NE); BRCA wild-type subgroup: 44.3 months (34.8\u201359.1); HRD subgroup: 59.1 months (43.5\u2013NE); HRD BRCA wild-type: 54.6 months (33.0\u2013NE); HRP subgroup: 37.2 months (28.3\u201356.1)",
            "ORR": "None",
            "AE": "Adverse events of any grade occurred in 99.1% of patients. Incidence of myelodysplastic syndrome and acute myeloid leukemia remained low (1.3%). No new safety signals were identified.",
            "Other": "None",
            "Conclusion": "Olaparib showed promising OS benefit in Asian patients with PSROC, regardless of the BRCA mutation or HRD status, with a well-tolerated safety profile."
        },
        {
            "Title": "PROTA: A phase I clinical trial combining an oncolytic adenovirus encoding for TNFa and IL-2 with pembrolizumab for the treatment of platinum-resistant or -refractory ovarian cancer.",
            "Indication": "Platinum-resistant or -refractory ovarian cancer",
            "Drug": "TILT-123 (oncolytic adenovirus encoding for IL-2 and TNFa) and pembrolizumab",
            "PFS": "105 days (median) for all patients; 174 days (median) for patients with stable disease on day 92 by RECIST1.1",
            "OS": "280 days (median) for all patients; 293 days (median) for patients with stable disease on day 92 by RECIST1.1",
            "ORR": "None",
            "AE": "Frequent grade 1-4 treatment-related AEs (>30% of patients) were anemia, nausea, lymphocyte count decreased, fever, and diarrhea.",
            "Other": "Stable disease (SD) by RECIST1.1 was achieved in 64.3% of evaluable patients and CA125 decreased below baseline in 50% of the patients at least in one time point. Tumor biopsies indicated the presence of virus and induction of T-cells in injected and non-injected lesions.",
            "Conclusion": "Combination of TILT-123 and pembrolizumab is safe and appears to induce disease control in a difficult-to-treat patient population. Investigation of TILT-123, pembrolizumab, and pegylated liposomal doxorubicin is ongoing in similar patients."
        },
        {
            "Title": "The BEV1L study: Do real-world outcomes associated with the addition of bevacizumab to first-line chemotherapy in patients with ovarian cancer reinforce clinical trial findings?",
            "Indication": "Stage III/IV ovarian cancer",
            "Drug": "Bevacizumab (bev) and first-line chemotherapy (CT)",
            "PFS": "12.6 months (median) for CT alone in high-risk clinical factors group; 13.5 months (median) for CT+bev in high-risk clinical factors group; 25.4 months (median) for CT alone in non-high-risk clinical factors group; 21.3 months (median) for CT+bev in non-high-risk clinical factors group",
            "OS": "25.2 months (median) for CT alone in high-risk clinical factors group; 27.3 months (median) for CT+bev in high-risk clinical factors group; Not reported for non-high-risk clinical factors group",
            "ORR": "None",
            "AE": "None",
            "Other": "Median time to next treatment (rwTTNT) was significantly longer with 1LCT+bev than with CT alone in high-risk clinical factors group. No differences were seen with median rwPFS, although a trend toward longer rwOS was observed with 1LCT+bev vs. with CT alone in high-risk clinical factors group. No significant differences were observed in median estimates of rwTTNT, rwPFS, or rwOS between patients receiving 1LCT+bev and patients receiving CT alone in non-high-risk clinical factors group.",
            "Conclusion": "This real-world study provides support for findings from ICON7 and GOG-0218, suggesting that the benefit of adding bev to 1LCT may be limited to patients with high-risk clinical factors."
        },
        {
            "Title": "Beacon: A phase II study of bevacizumab, atezolizumab, and cobimetinib in patients with recurrent, platinum resistant, high grade serous ovarian cancer.",
            "Indication": "Platinum resistant high grade serous ovarian cancer (HGSC)",
            "Drug": "Bevacizumab, Atezolizumab, Cobimetinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "21% [95% CI: 8-40]",
            "AE": "Grade 3 treatment-related adverse events in 59% of patients, most commonly hypertension (21%)",
            "Other": "Stable disease in 28% of patients; 17% remained on study treatment for \u226552 weeks",
            "Conclusion": "Preliminary results show promising efficacy with the combination of atezolizumab, bevacizumab, and cobimetinib in platinum-resistant HGSC. Duration of response, progression-free survival data, and translational data exploring molecular characteristics will be presented as data matures."
        },
        {
            "Title": "Role of the receptor for advanced glycation end products (RAGE) in blood as a potential biomarker for progression to olaparib: A post hoc analysis of patients with platinum-resistant ovarian cancer (PROC) treated in the ROLANDO-GEICO 1601 trial.",
            "Indication": "Platinum-resistant ovarian cancer (PROC)",
            "Drug": "Olaparib",
            "PFS": "Median PFS was 6.1 months (95% CI: 3.6-NR) for patients with RAGE >100 pg/mL and 5 months (95% CI: 4-NR) for patients with RAGE \u2264100 pg/mL (p=0.275)",
            "OS": "Median OS was 20.5 months (95% CI: 9.6-NR) for patients with RAGE >100 pg/mL vs 10.1 months (95% CI: 8.2-NR) for patients with RAGE \u2264100 pg/mL (p=0.049)",
            "ORR": "None",
            "AE": "None",
            "Other": "Patients with higher RAGE levels were older and had fewer previous treatment lines. RAGE expression was significantly reduced at progression compared to baseline (p=0.015).",
            "Conclusion": "Progressive reduction of levels of RAGE in blood could be a predictive biomarker of progression to olaparib in the PROC setting. Higher levels of RAGE at baseline in blood is a potential favorable prognostic factor for survival."
        },
        {
            "Title": "Anlotinib plus hormone therapy in patients with platinum-resistant recurrent ovarian cancer: A prospective, single-arm, open-label, phase II study.",
            "Indication": "Platinum-resistant recurrent ovarian carcinoma",
            "Drug": "Anlotinib, Letrozole",
            "PFS": "Median PFS was 4.24 months (95% CI: 3.71m to 5.09m)",
            "OS": "Median OS was 18.96 months (95% CI: 8.34m to 31.05m)",
            "ORR": "16.7% (7/27, 95% CI: 11.8% to 46.6%)",
            "AE": "Grade 1 or 2 AEs included laryngitis (44.4%), stomatitis (37.0%), hand-foot syndrome (29.6%), fatigue (25.9%), diarrhea (22.2%), hypertension (14.8%). Grade 3 AEs included hypertension (37.0%), hand-foot syndrome (3.7%), diarrhea (3.7%), hyperuricemia (3.7%).",
            "Other": "Disease control rate (DCR) was 74.1% (20/27, 95% CI: 53.4% to 88.1%)",
            "Conclusion": "As a chemo-free regimen, anlotinib plus letrozole showed promising efficacy with a favorable toxicity profile for patients with platinum-resistant recurrent ovarian cancer."
        },
        {
            "Title": "Targeting the CD47/TSP-1 axis: A promising strategy for patients with ovarian cancer (OC) that relapses on PARP inhibitors (PARPi).",
            "Indication": "Ovarian cancer (OC) that relapses on PARP inhibitors (PARPi)",
            "Drug": "TAX2 peptide, Olaparib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "TAX2 peptide binds directly to human TSP-1 with Kd of 11mM. TAX2 alone significantly extended survival in mice. Post-olaparib administration of TAX2 substantially reduced tumor burden and prolonged survival.",
            "Conclusion": "TAX2 is a promising strategy for patients relapsing on PARP inhibitors. TAX2 displayed a selective biodistribution pattern in mice, concentrating at tumor sites. Sequential use of TAX2 increased animal survival even under treatment conditions allowing olaparib efficacy."
        },
        {
            "Title": "Phase Ib INEOV neoadjuvant trial of durvalumab +/- tremelimumab with platinum chemotherapy for patients (pts) with unresectable ovarian cancer (OC): Survival outcomes and immune correlates.",
            "Indication": "Unresectable ovarian cancer",
            "Drug": "Durvalumab +/- tremelimumab with platinum chemotherapy",
            "PFS": "Median PFS in all pts was 18 months but not different between arms. CC0 at IDS was associated with significantly improved PFS (mPFS 25 mo vs 14 mo; p=0.0008).",
            "OS": "None",
            "ORR": "None",
            "AE": "4 pts were replaced for early toxicity and one early death attributed to COVID.",
            "Other": "NACT+D+/-T significantly increased intra-epithelial CD8+ infiltration (p<0.0001) and HLA-I levels (p=0.007), but the changes were no different in arm A vs B. High ieCD8 predicted improved pathological response (p=0.0014), neither ieCD8 nor HLA-1 levels at baseline or IDS predicted improved PFS. 8 (12.5%) pts were disease-free at >40 months. Among pts with known BRCA status, 43% of long-responders had a BRCAm (vs 20%).",
            "Conclusion": "NACT combined with immunotherapy resulted in encouraging CC0, pCR, and PFS in pts with unresectable OC, however, the addition of T to D did not improve immune infiltration or outcomes. Features associated with long response were pCR, CC0, and BRCAm, not immune features."
        },
        {
            "Title": "Evaluation of prognostic and predictive values of bespoke circulating tumor DNA assay in patients with advanced epithelial ovarian cancer.",
            "Indication": "Advanced epithelial ovarian cancer",
            "Drug": "Standard-of-care therapies followed by PARPi or bevacizumab for maintenance",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "ctDNA was detectable in 98.9% of patients at pre-OP, 73.5% at post-OP, and 21.2% after adjuvant chemotherapy (post-ACT). Residual disease was cleared in 70% of patients through ACT. CA125 had similar positive rates of 93.9% at pre-OP and 78.4% at post-OP, but only 1.3% at post-ACT. ctDNA has a superior negative predictive value (NPV) than CA125. Serial ctDNA monitoring during maintenance demonstrated an outstanding sensitivity of 95.0% and specificity of 86.6%, with a positive predictive value (PPV) of 67.9% and an NPV of 98.3%.",
            "Conclusion": "Bespoke ctDNA assay achieved much higher clinical accuracy than CA125. Longitudinal monitoring in maintenance provides the best predictive and prognostic value, which may provide vital evidence for treatment decisions and patient management."
        },
        {
            "Title": "Real-world biomarker testing, treatment patterns and outcomes in a US cohort of patients with advanced ovarian cancer.",
            "Indication": "Advanced ovarian cancer",
            "Drug": "Olaparib, either as monotherapy or in addition to bevacizumab",
            "PFS": "Median TFST was 44.7 months in BRCAm pts receiving olaparib 1LM, and 29.1 months in HRD+ pts receiving olaparib + bevacizumab 1LM.",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Most pts received BRCAm testing, but a majority did not receive HRD (inclusive of BRCAm) testing. A sizeable proportion of pts with actionable biomarker results did not receive PARPi as 1LM therapy. Time-to-event outcomes were comparable with those from the SOLO1 and PAOLA-1 clinical trials.",
            "Conclusion": "While most pts received BRCAm testing, a majority did not receive HRD (inclusive of BRCAm) testing. A sizeable proportion of pts with actionable biomarker results did not receive PARPi as 1LM therapy highlighting the need for education on the importance of genetic testing to guide AOC treatment. Time-to-event outcomes were comparable with those from the SOLO1 and PAOLA-1 clinical trials, thus supporting the real-world effectiveness of olaparib."
        },
        {
            "Title": "Safety, efficacy, and PKPD of 23ME-00610, a first-in-class anti-CD200R1 antibody, in patients with advanced or metastatic ovarian cancer: Results from a multi-center multi-country phase 1/2a expansion cohort.",
            "Indication": "Advanced or metastatic ovarian cancer",
            "Drug": "23ME-00610",
            "PFS": "1.5 months (median)",
            "OS": "None",
            "ORR": "Stable disease rate: 7.1%",
            "AE": "Related TEAEs in 46.7% of patients; most common were maculopapular rash (13.3%) and pruritus (13.3%). Immune-related TEAEs included pruritus (13.3%), rash, and hypothyroidism (6.7% each). Serious adverse events included G3 deep vein thrombosis and G4 pneumonia (6.7% each).",
            "Other": "1400 mg dose resulted in full peripheral target engagement and minimal treatment-emergent ADA.",
            "Conclusion": "23ME-00610 continues to show encouraging PKPD and acceptable safety, but only modest disease control in unselected advanced ovarian cancer patients. Phase 2a expansion trials of 23ME-00610 utilizing retrospective biomarker analyses are ongoing in multiple indications."
        },
        {
            "Title": "Intratumoral Ral-binding protein 1 (RalBP1) expression as a potential predictor of bevacizumab treatment efficacy in patients with ovarian cancer.",
            "Indication": "Ovarian cancer",
            "Drug": "Bevacizumab",
            "PFS": "RalBP1-high: 73.5 months, RalBP1-low: 35.8 months, HR=0.62, p=0.033",
            "OS": "RalBP1-high: 120.1 months, RalBP1-low: 71.7 months, HR=0.56, p=0.007",
            "ORR": "None",
            "AE": "None",
            "Other": "RalBP1 expression levels emerged as an independent prognostic marker for both OS (HR=0.67, p=0.031) and PFS (HR=0.72, p=0.033). In the subgroup of patients that received adjuvant platinum-based chemotherapy, RalBP1-low status was predictive for response to Bev therapy for both PFS (median 26.4 vs. 18.1 months, HR=0.94, p=0.036) and OS (median 63.4 vs. 49.3 months, HR=0.72, p=0.041).",
            "Conclusion": "RALBP1 may serve as a prognostic biomarker for ovarian cancer patients, helping to identify those who are likely to benefit not only in PFS but also in OS following Bevacizumab therapy. Its tumor biological role remains a matter of current investigations."
        },
        {
            "Title": "Phase 3 MIRASOL (GOG 3045/ENGOT-ov55) trial: Mirvetuximab soravtansine (MIRV) vs. investigator\u2019s choice chemotherapy (ICC) in older patients with platinum-resistant ovarian cancer (PROC) and high folate receptor-alpha (FRa) expression.",
            "Indication": "Platinum-resistant ovarian cancer (PROC) with high folate receptor-alpha (FRa) expression",
            "Drug": "Mirvetuximab soravtansine (MIRV)",
            "PFS": "Nominal PFS HR: 0.62 (0.45, 0.86), favoring MIRV",
            "OS": "Nominal OS HR: 0.57 (0.37, 0.87), favoring MIRV",
            "ORR": "39.3% for MIRV vs. 17.4% for ICC",
            "AE": "Grade 3+ treatment-emergent AEs: 45% for MIRV vs. 58% for ICC; Serious AEs: 24% for MIRV vs. 38% for ICC; TEAEs leading to dose reductions: 34% for MIRV vs. 30% for ICC; Dose delays: 49% for MIRV vs. 57% for ICC; Discontinuations: 10% for MIRV vs. 23% for ICC",
            "Other": "Ocular, gastrointestinal, and neurosensory adverse events were comparable to the intent-to-treat population.",
            "Conclusion": "MIRV is the first treatment to demonstrate a PFS, ORR, and OS benefit in PROC compared to ICC. MIRV demonstrated a longer PFS, OS, and higher ORR vs ICC in the older population. Importantly, MIRV had fewer dose discontinuations than ICC, suggesting that TEAEs were more manageable. The efficacy data and the well-characterized safety profile support MIRV as the standard of care for pts with FRa positive PROC across all ages."
        },
        {
            "Title": "Phase II study of pembrolizumab and lenvatinib in platinum sensitive recurrent ovarian cancer",
            "Indication": "Platinum sensitive recurrent ovarian cancer",
            "Drug": "Pembrolizumab and Lenvatinib",
            "PFS": "Median PFS was 8 months",
            "OS": "Median OS not reached",
            "ORR": "50% RECIST 1.1 response, 8% CR, 42% PR",
            "AE": "Treatment-related adverse events in all patients, predominantly hypertension and fatigue. Dose reduction of L in 79% of patients mainly due to hypertension (33%), fatigue (30%), and arthralgia (21%). Discontinuation due to adverse event in three patients (hepatitis, nephrotic range proteinuria, diabetic keto-acidosis). No treatment-related death.",
            "Other": "Data on QOL analysis and biomarker study will be presented later.",
            "Conclusion": "L+P combination showed meaningful responses and manageable toxicity in platinum sensitive recurrent OC patients, potentially providing a non-chemotherapy alternative. Effect on survival and response to subsequent therapies is yet to be evaluated. Further research is essential to pinpoint the patient subset that can benefit the most from this combination."
        },
        {
            "Title": "Characterization of long-term survivors from four clinical trials examining patients with folate receptor alpha\u2013positive recurrent ovarian cancer treated with single-agent mirvetuximab soravtansine",
            "Indication": "Folate receptor alpha\u2013positive recurrent ovarian cancer",
            "Drug": "Mirvetuximab soravtansine",
            "PFS": "Duration of response of 8 months (95% CI 5.9, 9.6)",
            "OS": "Median OS of 28.35 months (95% CI 25.72, NR), with 66% and 40% alive at 24 and 30 months, respectively",
            "ORR": "55.7% overall response rate, with 9.1% achieving a complete response and 46.5% achieving a partial response by RECIST v1.1",
            "AE": "Most common treatment-related adverse events included blurred vision (60.9%), keratopathy (43.9%), nausea (40.0%), dry eye (36.1%), fatigue (33.5%), diarrhea (33.0%), and peripheral neuropathy (21.7%). TRAEs led to dose delay or reduction in 55.2% and 36.5% of patients, respectively, and discontinuation in 10.4% of patients.",
            "Other": "None",
            "Conclusion": "In a pooled analysis of 682 patients, long-term survival was observed in 34% of patients, with a median overall survival of 28.35 months and with 66% and 40% alive at 24 and 30 months, respectively. The observed MIRV tolerability in this patient cohort was generally consistent with the safety profile as reported in the U.S. prescribing information. These data add support to MIRV as a new standard of care for patients with FRa positive PROC."
        },
        {
            "Title": "Phase 2, single arm clinical trial to evaluate the safety and activity of oregovomab and niraparib as a combinatorial immune priming strategy in subjects with platinum-sensitive recurrent ovarian cancer: FLORA-4 study.",
            "Indication": "Platinum-sensitive recurrent ovarian cancer",
            "Drug": "Oregovomab and Niraparib",
            "PFS": "None",
            "OS": "None",
            "ORR": "10% achieved partial response (PR), 50% had disease stabilization (SD), overall disease control rate (DCR) of 60% at 12 weeks",
            "AE": "Niraparib: anemia (30%), thrombocytopenia (30%), hypertension (30%); Oregovomab: urticaria (10%)",
            "Other": "None",
            "Conclusion": "Combination oregovomab and niraparib in patients with platinum-sensitive EOC was well tolerated with no new safety signals. Translational research is ongoing to assess biomarkers of early humoral response."
        },
        {
            "Title": "Efficacy of niraparib in patients with advanced ovarian cancer: A meta-analysis of randomized controlled trials.",
            "Indication": "Advanced ovarian cancer",
            "Drug": "Niraparib",
            "PFS": "Overall population: HR 0.45 (95% CI 0.31-0.66); BRCA mutation: HR 0.32 (95% CI 0.25-0.43); HRD positive: HR 0.39 (95% CI 0.30-0.51); HRD negative: HR 0.45 (95% CI 0.36-0.57); Newly diagnosed: HR 0.59 (95% CI 0.39-0.73)",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Time to first subsequent therapy (TFST): HR 0.48 (95% CI 0.33-0.68)",
            "Conclusion": "Niraparib improves PFS and lowers the time to first subsequent therapy in patients with advanced ovarian cancer."
        },
        {
            "Title": "Outcomes of reduced dosing of lenvatinib with pembrolizumab for advanced endometrial cancer.",
            "Indication": "Advanced endometrial cancer",
            "Drug": "Lenvatinib and Pembrolizumab",
            "PFS": "No difference in 6-month PFS between Group 1 (40.2%) and Group 2 (33.8%); p=0.26",
            "OS": "None",
            "ORR": "None",
            "AE": "No difference in rate of lenvatinib discontinuation due to AE between Group 1 (7.2 per 100 person-year) and Group 2 (6.6 per 100 person-year); p=0.61. Higher incidence of dose reduction in Group 1 (28.1 per 100 person-year) compared to Group 2 (14.3 per 100 person-year); p<0.01",
            "Other": "None",
            "Conclusion": "A lower lenvatinib starting dose in combination with pembrolizumab was no different to the FDA-approved daily dose of 20mg for the treatment of advanced EC with respect to 6-month PFS. Those who received a lower starting dose experienced fewer dose reductions but no difference in dose discontinuation due to AE."
        },
        {
            "Title": "Imlunestrant, an oral selective estrogen receptor degrader (SERD), as monotherapy and in combination with abemaciclib, in endometrioid endometrial cancer (EEC): Results from the EMBER phase 1a/1b study.",
            "Indication": "Endometrioid endometrial cancer (EEC)",
            "Drug": "Imlunestrant and Abemaciclib",
            "PFS": "Monotherapy: 3.1 months (95% CI: 1.8, 6.3); Combination: 6.8 months (95% CI: 2.1, 12.4)",
            "OS": "None",
            "ORR": "None",
            "AE": "Monotherapy: Nausea (36%/3%), Diarrhea (26%/0), Urinary tract infection (26%/3%), Abdominal pain (21%/8%); Combination: Diarrhea (88%/3%), Nausea (67%/0), Fatigue (49%/9%), Anemia (46%/12%)",
            "Other": "CBR: Monotherapy: 4/7 vs 4/17 for patients with \u226550% ctDNA decline",
            "Conclusion": "Imlunestrant, as monotherapy or with abemaciclib, appears safe and tolerable with preliminary evidence of efficacy in patients with metastatic ER+ EEC."
        },
        {
            "Title": "Serial circulating tumor DNA (ctDNA) sequencing to monitor response and define acquired resistance to letrozole/abemaciclib in endometrial cancer (EC).",
            "Indication": "Endometrial cancer (EC)",
            "Drug": "Letrozole and Abemaciclib",
            "PFS": "Baseline ctDNA detected: 3.5 months vs ctDNA undetected: 16.5 months, p=0.006, HR=12.5; MR: 12.8 months vs not-MR: 3.5 months, p<0.001, HR=0.1",
            "OS": "Baseline ctDNA detected: 11.6 months vs ctDNA undetected: not yet reached, p=0.008, HR=4.7e7",
            "ORR": "None",
            "AE": "None",
            "Other": "Patients achieving MR were more likely to demonstrate clinical benefit (objective response and/or PFS \u22656 months) from this regimen (MR: 84.6% vs not-MR: 10.0%, p<0.001, OR=49.5)",
            "Conclusion": "Baseline and on-treatment ctDNA dynamics may provide an early indication of long-term outcomes and benefit from letrozole/abemaciclib in EC. ctDNA at the time of progression may identify genomic alterations of acquired resistance that may guide selection of subsequent therapy."
        },
        {
            "Title": "Benmelstobart (TQB2450) combined with anlotinib hydrochloride capsule in the treatment of advanced, recurrent, or metastatic endometrial cancer: A multicohort, open-label, multicenter, phase II clinical trial (TQB2450-II-08).",
            "Indication": "Advanced, recurrent, or metastatic endometrial cancer",
            "Drug": "TQB2450 and Anlotinib",
            "PFS": "Cohort 1: 8.38 months; Cohort 2: 8.21 months; Cohort 3: 6.80 months",
            "OS": "Cohort 1: 21.72 months; Cohort 2: 27.66 months; Cohort 3: 16.03 months",
            "ORR": "Cohort 1: 31.76%; Cohort 2: 34.38%; Cohort 3: 22.58%",
            "AE": "TEAEs: Cohort 1: 99.07%, Grade \u22653: 74.77%; Cohort 2: 100%, Grade \u22653: 40.63%; Cohort 3: 100%, Grade \u22653: 87.10%. Common TEAEs: Weight loss, white blood cell decreased, blood corticotrophin increased, thyroid stimulating hormone increased",
            "Other": "None",
            "Conclusion": "TQB2450 plus anlotinib showed promising antitumor activity with a manageable safety profile in the treatment of recurrent or metastatic advanced EC."
        },
        {
            "Title": "Phase 3 dose selection for selinexor in TP53 wt endometrial cancer based on exposure-response analysis.",
            "Indication": "TP53 wildtype endometrial cancer",
            "Drug": "Selinexor",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Significant E-R relationships were observed for treatment-emergent adverse events (TEAEs) including thrombocytopenia, neutropenia, nausea/vomiting, and dose modifications. E-R simulations for 60mg SEL QW were compared to 80mg SEL QW and predicted an 8.4% and 4.3% reduction in \u2265G3 TEAEs and nausea/vomiting \u2265G2 TEAEs, respectively.",
            "Other": "No E-R trend for efficacy was observed with Week 1 PK parameters of Cmax and AUC in the broader EC population, and no E-R relationship for efficacy was detected for pts with TP53 wt EC (n=7).",
            "Conclusion": "The integrated E-R analysis demonstrates the potential for SEL 60mg QW to be reasonably well-tolerated, while maintaining efficacy. Evaluation of 60mg SEL QW as maintenance therapy for TP53 wt advanced/recurrent EC is ongoing in the phase 3 ENGOT-EN20/GOG-3083/XPORT-EC-042 trial (NCT05611931)."
        },
        {
            "Title": "Durvalumab + carboplatin/paclitaxel (CP) followed by durvalumab + olaparib as first-line treatment for newly diagnosed advanced or recurrent endometrial cancer (EC) in DUO-E: Results by BRCA1/BRCA2 mutation (BRCAm) status.",
            "Indication": "Newly diagnosed advanced or recurrent endometrial cancer",
            "Drug": "Durvalumab, Olaparib",
            "PFS": "ITT: CP+D+O vs CP HR 0.55 (95% CI 0.43\u20130.69), p<0.0001; pMMR non-BRCAm: CP+D+O vs CP HR 0.57 (95% CI 0.42\u20130.78)",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "BRCAmprevalence was low overall (dMMR: 12.6% BRCAm, 61.5% non-BRCAm, 25.9% unknown; pMMR: 4.0% BRCAm, 65.9% non-BRCAm, 30.1% unknown).",
            "Conclusion": "Clinical benefit with addition of durvalumab to CP, followed by durvalumab + olaparib, vs CP alone was observed in the ITT and pMMR populations irrespective of BRCAm status."
        },
        {
            "Title": "Surgical window of opportunity clinical trial of abemaciclib and letrozole for endometrioid adenocarcinoma of the endometrium.",
            "Indication": "Endometrioid adenocarcinoma of the endometrium",
            "Drug": "Abemaciclib, Letrozole",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "The most common study related adverse events were grade 1-2 diarrhea (68%) and grade 1-2 nausea (36%). Two patients had grade 3 hematological events (anemia and neutropenia), which resolved.",
            "Other": "Mean baseline Ki-67 was 34% (SD 22%). After 2 weeks of treatment with abemaciclib and letrozole mean Ki67 was 14% (SD 16%). The mean Ki-67 reduction was 19% (SD 23%, p<0.001) meeting the prespecified endpoint.",
            "Conclusion": "Abemaciclib and letrozole induced a reduction in cellular proliferation in endometrioid endometrial cancer over a relatively short two week time period prior to surgery, suggesting clinical efficacy."
        },
        {
            "Title": "Efficacy and safety of combination therapy with envafolimab and lenvatinib in patients with endometrial cancer after failure of first-line platinum-based therapy.",
            "Indication": "Endometrial cancer after failure of first-line platinum-based therapy",
            "Drug": "Envafolimab, Lenvatinib",
            "PFS": "The median progression-free survival (PFS) was 9.2 months (95% CI 4.0-11.0).",
            "OS": "None",
            "ORR": "The confirmed ORR per BIRC was 40.0% (10/25, 95% CI 21.1%-61.3%). Disease control rate (DCR) was 84.0% (21/25, 95% CI 63.9%-95.5%).",
            "AE": "The most common any-grade treatment-related adverse events (TRAEs) were hypothyroidism (59.4%), proteinuria (56.3%), hypertension (56.3%) and platelet count decreased (53.1%). Grade 3 or 4 TRAEs occurred in 62.5% (20/32) patients. No grade 5 TRAEs were reported.",
            "Other": "The median number of previous systemic therapies in these patients was 1 (range: 1-5), and 15.6% of them had received more than two previous systemic therapies at study entry.",
            "Conclusion": "Envafolimab plus Lenvatinib showed robust antitumor activity with a manageable safety profile in patients with advanced EC who are not MSI-H or dMMR and have experienced disease progression after prior systemic therapy. Compared to the results from other intravenous anti-PD-1 antibody plus Lenvatinib in EC patients, Envafolimab with Lenvatinib had comparable efficacy and similar safety whilst a higher proportion of heavily pretreated patients were enrolled in this trial. Moreover, subcutaneous injection of Envafolimab plus Lenvatinib provided a more convenient dosing regimen in this population. Further evaluation of this combination therapy is underway."
        },
        {
            "Title": "Safety and tolerability of durvalumab + carboplatin/paclitaxel followed by durvalumab + olaparib in patients with newly diagnosed advanced or recurrent endometrial cancer (EC) in the DUO-E/GOG-3041/ENGOT-EN10 trial.",
            "Indication": "Endometrial cancer",
            "Drug": "Durvalumab, Carboplatin, Paclitaxel, Olaparib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "CP: 18.6%, CP+D: 20.9%, CP+D+O: 24.4% had AEs leading to treatment discontinuations; CP: 50.0%, CP+D: 54.5%, CP+D+O: 68.9% had AEs leading to dose interruptions; CP: 56.4%, CP+D: 54.9%, CP+D+O: 67.2% had grade \u22653 AEs.",
            "Other": "No increase in immune-mediated AEs or AEs of special interest for O with CP+D+O. Most common AEs were low grade.",
            "Conclusion": "In DUO-E, safety findings were generally consistent with the known safety profiles of CP, D, and O. The addition of D to CP followed by D or by D+O resulted in expected and manageable safety profiles compared with CP alone, with the most common AEs being low grade and leading to few discontinuations or dose modifications/interruptions of D/pbo or O/pbo."
        },
        {
            "Title": "Cadonilimab plus lenvatinib in patients with advanced endometrial cancer: A multicenter, single-arm, phase II trial.",
            "Indication": "Advanced endometrial cancer",
            "Drug": "Cadonilimab, Lenvatinib",
            "PFS": "Not reached",
            "OS": "Not reached",
            "ORR": "52.4% (95% CI: 29.1\u201375.7)",
            "AE": "Grade 3\u20134 treatment-related adverse events included increased ALT (2 [8.3%]), increased AST (1 [4.2%]), increased creatine phosphokinase (1 [4.2%]), hypertension (1 [4.2%]), and intestinal perforation (1 [4.2%]).",
            "Other": "The Disease Control Rate (DCR) was 90.5% (95% CI: 69.6\u201398.8).",
            "Conclusion": "Cadonilimab plus lenvatinib showed promising antitumor activity in patients with advanced endometrial cancer who have experienced disease progression after prior systemic platinum-based therapy. The combination therapy had a favorable and manageable safety profile."
        },
        {
            "Title": "Neoadjuvant chemotherapy versus primary surgery in patients with stage III/IV endometrial cancer: A National Cancer Database study.",
            "Indication": "Stage III/IV endometrial cancer",
            "Drug": "Neoadjuvant chemotherapy (NACT)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Unplanned 30-day readmissions: 4.4% (primary surgery) vs. 3.0% (NACT); 30-day mortality: 0.9% (primary surgery) vs. 0.2% (NACT); 90-day mortality: 5.5% (primary surgery) vs. 2.2% (NACT)",
            "Other": "Annual rate of primary surgery vs. NACT over study duration; Length of stay; Patient characteristics",
            "Conclusion": "In advanced endometrial cancer, the utilization of NACT has increased nationally over the last two decades, while primary surgery has declined. Unplanned admissions were higher in the primary surgery group, but unexpectedly 30- and 90-day postoperative mortality were higher in the NACT group. Formal phase III trials to assess NACT vs surgery should be planned in patients with advanced endometrial cancer. Further research is necessary to determine baseline differences between cohorts in order to identify which patients would benefit from a neoadjuvant chemotherapy strategy."
        },
        {
            "Title": "Efficacy of olaparib combined to metronomic cyclophosphamide and metformin in patients with recurrent advanced/metastatic endometrial by molecular subtypes: ENDOLA phase I/II trial.",
            "Indication": "Recurrent advanced/metastatic endometrial cancer",
            "Drug": "Olaparib + metronomic cyclophosphamide + metformin",
            "PFS": "Median PFS (mPFS): 5.1 months (whole population); 5.1 months (MSI-high); 9.0 months (NSMP); 5.1 months (P53 mutated); 3.1 months (P53 mutated & LGA <= 7); 8.5 months (P53 mutated & LGA >= 8)",
            "OS": "None",
            "ORR": "Non-progression rate at 10 weeks (NP10w): 61.5% (whole population); 18.2% (MSI-high); 27.3% (NSMP); 54.5% (P53 mutated)",
            "AE": "None",
            "Other": "Alterations of POLE, MMR, and p53 assessed using local in-house assays; BRCA & HRD status centrally defined using shallow Whole Genome Sequencing (sWGS)",
            "Conclusion": "In this heavily pre-treated population of patients with advanced EC (55% patients treated with >= 4 previous lines; and 50% were >= 69 years old), the combination of olaparib + metronomic cyclophosphamide and metformin was highly effective, especially in those with NSMP or with P53 mutated & HRD (LGA >= 8) subtypes, with mPFS ~8 months. It might be a promising therapeutic alternative in the recurrent setting."
        },
        {
            "Title": "Post hoc analysis of progression-free survival (PFS) and overall survival (OS) by mechanism of mismatch repair (MMR) protein loss in patients with endometrial cancer (EC) treated with dostarlimab plus chemotherapy in the RUBY trial.",
            "Indication": "Endometrial cancer (EC)",
            "Drug": "Dostarlimab plus chemotherapy",
            "PFS": "DOST+CP: 30 months, HR 0.39 (0.20\u20130.76); PBO+CP: 14 months, HR 1.03 (0.49\u20132.14)",
            "OS": "DOST+CP: 38 months, HR 0.32 (0.18\u20130.57); PBO+CP: 30 months, HR 0.60 (0.26\u20131.33)",
            "ORR": "None",
            "AE": "TRAEs in DOST+CP: 97.9%; TRAEs in PBO+CP: 98.8%",
            "Other": "No significant differences in PFS or OS in pts with mut-dMMR vs pts with epi-dMMR.",
            "Conclusion": "DOST+CP led to substantial PFS and OS benefits compared with PBO+CP in dMMR/MSI-H pA/rEC, regardless of mechanism of MMR protein loss. The data further support a favorable long-term benefit-risk profile of dostarlimab+CP in pts with primary advanced or recurrent EC."
        },
        {
            "Title": "HER2 expression in a molecularly characterized cohort of patients with endometrial cancer (EC): SPECTRUM study.",
            "Indication": "Endometrial cancer",
            "Drug": "Trastuzumab deruxtecan",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "HER2-low had no prognostic significance.",
            "Conclusion": "HER2-low is more frequent in POLEmut and p53abn subtypes. HER2-low had no prognostic significance. Breast and endometrial scoring criteria showed high concordance."
        },
        {
            "Title": "Prognostic assessment of simple hysterectomy versus radical/modified radical hysterectomy in clinically apparent stage II endometrial cancer: A multi-center cohort study.",
            "Indication": "Stage II endometrial cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Cancer-specific survival (CSS) and disease-free survival (DFS) were better in the RH/mRH group than the SH group among patients with aggressive histological types.",
            "Conclusion": "Compared to SH, RH/mRH were associated with better CSS and DFS in patients with aggressive histological types, but not in the non-aggressive histological types. Despite guidelines recommending SH as first-line treatment for patients with suspected or gross cervical involvement, RH/mRH might be a more reasonable and individualized choice for those with aggressive histological subtypes."
        },
        {
            "Title": "The molecular landscape of pembrolizumab and lenvatinib treatment in endometrial cancer.",
            "Indication": "Endometrial cancer",
            "Drug": "Pembrolizumab and lenvatinib",
            "PFS": "None",
            "OS": "TP53-wt cohort: 31.6 vs 26 months; HR 0.73 (95% CI 0.54-0.99), p=0.039",
            "ORR": "None",
            "AE": "None",
            "Other": "ARID1A-mt trended towards improved post-pembro-lenv survival (28.5 vs 15.6 months; HR 0.91 (95% CI 0.37-1.15), p=0.14). ARID1A-mt was associated with worse post-pembro survival (9.57 vs 15.4 months; HR 1.44 (95% CI 1.0-2.06), p=0.048).",
            "Conclusion": "Among POLE-wt/MSS patients, TP53 wildtype patients have longer OS after pembrolizumab with lenvatinib compared to pembrolizumab alone, but in the TP53 mutated cohort, there was no difference. Among TP53-mt patients, ARID1A-mt is associated with improved pembro-lenv survival but not in pembro alone. Our findings suggest a need to readdress when to use lenvatinib in TP53-mt patients and further explore genomic alterations that may promote treatment response to optimize use of this agent in endometrial cancer."
        },
        {
            "Title": "Endometrial cancer (EC) by ERBB2 amplification (ERBB2amp) status: Differences in molecular subtypes, ancestry, and real-world outcomes.",
            "Indication": "Endometrial cancer",
            "Drug": "HER2 targeting agents",
            "PFS": "None",
            "OS": "rwOS: aHR 1.32, 95% CI 1.01-1.72, p=0.05",
            "ORR": "None",
            "AE": "None",
            "Other": "TTNT: aHR 0.91, 95% CI 0.71-1.15, p=0.42",
            "Conclusion": "The frequency of ERBB2amp was almost double in pts of AFR ancestry compared to pts of EUR ancestry. Significant differences in EC pts with ERBB2amp vs. no ERBB2amp with respect to histology, molecular classification, and rwOS. These differences should be considered when designing and enrolling patients in clinical trials using HER2 targeting agents."
        },
        {
            "Title": "Fruquintinib plus sintilimab in treated advanced endometrial cancer (EMC) patients (pts) with PMMR status: Results from a multicenter, single-arm phase 2 study.",
            "Indication": "Advanced endometrial cancer (EMC) with pMMR status",
            "Drug": "Fruquintinib plus sintilimab",
            "PFS": "Median PFS: 9.5 months (95% CI: 5.6, -); Prior bevacizumab therapy (Yes vs No): 13.8 months (95% CI: 4.1, -) vs 9.5 months (95% CI: 5.5, -)",
            "OS": "Median OS: 21.3 months (95% CI: 17.3, -)",
            "ORR": "Overall ORR: 35.6% (CR: 2/87, PR: 29/87); Prior bevacizumab therapy (Yes vs No): 40.9% vs 30.3%",
            "AE": "All pts experienced TEAEs; Most common Grade 3 TEAEs: Hypertension (18.4%), Hypertriglyceridaemia (11.2%), Palmar-plantar erythrodysaesthesia syndrome (11.2%)",
            "Other": "DCR: 88.5%; DoR: Not reached; 9-month DoR rate: 80.7%",
            "Conclusion": "Fruquintinib plus sintilimab showed meaningful efficacy improvements in advanced EMC pts with pMMR status, regardless of prior bevacizumab treatment. The combination therapy had a manageable toxicity profile. Fruquintinib plus sintilimab could offer a new potential treatment option for this patient population."
        },
        {
            "Title": "Study on the efficacy and toxicity of pamiparib combined with tamoxifen in the treatment of patients with epithelial ovarian cancer with biochemical recurrence during first-line PARPi maintenance therapy.",
            "Indication": "Epithelial ovarian cancer with biochemical recurrence during first-line PARPi maintenance therapy",
            "Drug": "Pamiparib combined with tamoxifen",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary outcome: CA-125 response rate assessed according to GCIG criteria; Secondary outcome: Time to First Subsequent Therapy (TFST)",
            "Conclusion": "The study aims to evaluate the efficacy and toxicity of pamiparib and tamoxifen in ovarian cancer patients with biochemical recurrence during first-line PARPi maintenance therapy. The trial design is interventional, employing a Simon two-stage design to estimate sample size."
        },
        {
            "Title": "Triage of advanced cervical cancer through immunotherapy induction (TRACTION)",
            "Indication": "Advanced/recurrent cervical cancer",
            "Drug": "Lorigerlimab (bispecific antibody targeting CTLA-4 and PD-1)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Disease control rate, objective response rate, duration of response, safety (by CTCAE v5.0)",
            "Conclusion": "The study aims to estimate the disease control rate and determine the objective response rate, duration of response, overall survival, progression-free survival, and safety of lorigerlimab in chemo-na\u00efve cervical cancer patients not amenable to curative treatment."
        },
        {
            "Title": "A phase 2 study to evaluate efficacy and safety of PRGN-2009, a novel gorilla adenovirus-based immunotherapy, in combination with pembrolizumab versus pembrolizumab monotherapy in patients with recurrent or metastatic cervical cancer",
            "Indication": "Recurrent or metastatic cervical cancer",
            "Drug": "PRGN-2009 in combination with pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "Primary endpoint: Objective Response Rate (ORR) following treatment with PRGN-2009 in combination with pembrolizumab or pembrolizumab monotherapy",
            "AE": "None",
            "Other": "Safety and efficacy of PRGN-2009 in combination with pembrolizumab, biological correlative assays",
            "Conclusion": "The study aims to evaluate the efficacy and safety of PRGN-2009 in combination with pembrolizumab compared to pembrolizumab alone in patients with recurrent or metastatic cervical cancer."
        },
        {
            "Title": "Open-label, prospective phase 2 study to evaluate the efficacy and safety of camrelizumab combined with etoposide and cisplatin as neoadjuvant therapy in patients with neuroendocrine carcinoma of the cervix",
            "Indication": "Neuroendocrine carcinoma of the cervix (NECC)",
            "Drug": "Camrelizumab combined with etoposide and cisplatin",
            "PFS": "None",
            "OS": "None",
            "ORR": "Primary objective: Objective Response Rate (ORR) as per the Response Evaluation Criteria in Solid Tumors version 1.1",
            "AE": "None",
            "Other": "Disease control rate, progression-free survival, overall survival, evaluations of safety and tolerability",
            "Conclusion": "The study aims to assess the efficacy and safety of neoadjuvant chemo-immunotherapy in patients with NECC."
        },
        {
            "Title": "REJOICE-Ovarian01: A phase 2/3 study of raludotatugderuxtecan (R-DXd) in patients with platinum-resistant ovarian cancer (OVC)",
            "Indication": "Platinum-resistant ovarian cancer (OVC)",
            "Drug": "Raludotatugderuxtecan (R-DXd)",
            "PFS": "Phase 3 dual primary endpoint: Progression-Free Survival (PFS) by blinded independent central review (BICR)",
            "OS": "Key secondary endpoint: Overall Survival (OS)",
            "ORR": "Phase 2 primary endpoint: Objective Response Rate (ORR) by blinded independent central review (BICR)",
            "AE": "None",
            "Other": "Quality of Life (QoL), Duration of Response (DOR)",
            "Conclusion": "The study aims to evaluate the efficacy and safety of R-DXd in patients with platinum-resistant ovarian cancer, with primary endpoints including ORR and PFS, and secondary endpoints including OS and QoL."
        },
        {
            "Title": "Phase I/II study of TROP2 CAR engineered IL15-transduced cord blood-derived NK cells delivered intraperitoneally for the management of platinum-resistant ovarian cancer, mesonephric-like adenocarcinoma, and pancreatic clear cell carcinoma: DOVE (APGOT-OV7/ENGOT-ov80 study).",
            "Indication": "Platinum-resistant ovarian cancer, mesonephric-like adenocarcinoma, pancreatic clear cell carcinoma",
            "Drug": "TROP2 CAR/IL-15 transduced cord blood NK cells",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoints: toxicity rates and defining the recommended phase II dose (RP2D). Secondary endpoints: treatment efficacy, circulating tumor DNA levels, serial characterization of the immune microenvironment, and patient-reported outcomes.",
            "Conclusion": "The study aims to find the maximum tolerated dose and RP2D using the Bayesian optimal interval (BOIN) design, with a maximum sample size of 21. Once the RP2D is chosen, up to 10 patients within each disease cohort will be enrolled to further evaluate safety and efficacy."
        },
        {
            "Title": "A three-arm randomized phase II study of dostarlimab alone or with bevacizumab versus nonplatinum chemotherapy in recurrent gynecological clear cell carcinoma: DOVE (APGOT-OV7/ENGOT-ov80 study).",
            "Indication": "Recurrent gynecological clear cell carcinoma (rGCCC)",
            "Drug": "Dostarlimab, bevacizumab",
            "PFS": "Primary endpoint: progression-free survival by investigator",
            "OS": "None",
            "ORR": "Secondary endpoints: ORR, DCB, DCR, PFS2, OS, and toxicity",
            "AE": "None",
            "Other": "Exploratory objectives include immune biomarker analysis.",
            "Conclusion": "The study investigates the efficacy of dostarlimab +/- bevacizumab in rGCCC. It enrolls 198 patients with rGCCC, assigning them to one of three groups in a 1:1:1 ratio. Patients with PD on Group A or Group C are allowed to crossover to Group B."
        },
        {
            "Title": "A phase IV trial to confirm the efficacy of olaparib in combination with bevacizumab as maintenance frontline treatment of HRD-positive ovarian tumours (IOLANTHE).",
            "Indication": "HRD-positive ovarian tumors",
            "Drug": "Olaparib, bevacizumab",
            "PFS": "PAOLA-1 median progression-free survival (PFS) of 37.2 months in the B-O arm",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Translational subprojects include analysis of circulating-tumor (ctDNA) and comparison of patients' response to therapy with that of cancer cells tested by organotypic model.",
            "Conclusion": "The study aims to confirm the efficacy of the combination in a setting close to clinical practice. It includes a consistent translational component and aims to enroll 190 patients in step 1 to have 90 patients eligible for step 2."
        },
        {
            "Title": "A proof-of-concept study to assess the combination of SON-1010 (IL12-FHAB) and atezolizumab in patients with platinum-resistant ovarian cancer.",
            "Indication": "Platinum-resistant ovarian cancer (PROC)",
            "Drug": "SON-1010 (IL12-FHAB), atezolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Study SB221 is a Phase 1b/2a multicenter, dose-escalation and proof-of-concept study to assess the safety, tolerability, PK, PD, and efficacy of SON-1010 administered SC, either alone or in combination with a fixed dose of atezolizumab given IV.",
            "Conclusion": "The study aims to establish the maximum tolerated dose (MTD) of the combination in patients with advanced solid tumors and to expand the dataset using patients with PROC to establish the Recommended Phase 2 Dose (RP2D)."
        },
        {
            "Title": "Phase II clinical trial of PLX038 in patients with platinum-resistant ovarian, primary peritoneal and fallopian tube cancer.",
            "Indication": "Platinum-resistant ovarian, primary peritoneal, and fallopian tube cancer",
            "Drug": "PLX038",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Measurement of TOP1-DNA covalent complexes, homologous recombination status, SLFN11 expression, pharmacokinetics of SN-38 and SN-38G, and assessment of gut microbiota",
            "Conclusion": "None"
        },
        {
            "Title": "Repurposing atovaquone as a STAT3 inhibitor for the treatment of platinum-resistant ovarian cancer.",
            "Indication": "Platinum-resistant ovarian cancer",
            "Drug": "Atovaquone (ATO)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Assessment of changes in STAT3 dependent gene expression and immune profile",
            "Conclusion": "Targeting inappropriate activation of STAT3 could be a novel and promising treatment approach in OC."
        },
        {
            "Title": "A phase 1/2 study of ubamatamab (REGN4018), a MUC16\u00d7CD3 bispecific antibody, administered alone or in combination with cemiplimab (anti\u2013PD-1) in patients with recurrent ovarian cancer or MUC16+ endometrial cancer.",
            "Indication": "Recurrent ovarian cancer or MUC16+ endometrial cancer",
            "Drug": "Ubamatamab (REGN4018) and Cemiplimab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Change from baseline in quality of life, clinical correlation with serum CA-125, MUC16 IHC, and immune markers",
            "Conclusion": "None"
        },
        {
            "Title": "A phase II study evaluating the effect of IMNN-001 on second-look laparoscopy (SLL) when administered in combination with bevacizumab (BEV) and neoadjuvant chemotherapy (NACT) in patients newly diagnosed with advanced epithelial ovarian cancer (EOC).",
            "Indication": "Newly diagnosed advanced epithelial ovarian cancer (EOC)",
            "Drug": "IMNN-001, Bevacizumab (BEV), Neoadjuvant chemotherapy (NACT)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Pathologic detection of residual tumor, comprehensive longitudinal genomic, circulating tumor DNA, immune, and microbiome profiling",
            "Conclusion": "None"
        },
        {
            "Title": "Open-label, phase 2 study of adebrelimab combined with fuzuloparib in the treatment of patients with HRD-positive, recurrent platinum-resistant ovarian cancer.",
            "Indication": "HRD-positive, recurrent platinum-resistant ovarian cancer",
            "Drug": "Adebrelimab and Fuzuloparib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: Objective response rate (ORR) as defined by RECIST v1.1. Secondary endpoints: Disease control rate, progression-free survival, overall survival, safety, and tolerability.",
            "Conclusion": "This clinical trial is in progress and 7 patients have been enrolled."
        },
        {
            "Title": "A randomized, multicenter, open-label phase III trial of hyperthermic intraperitoneal chemotherapy in platinum-resistant recurrent ovarian cancer (KOV-HIPEC-02).",
            "Indication": "Platinum-resistant recurrent ovarian cancer",
            "Drug": "Cytoreductive surgery and HIPEC (Doxorubicin and Mitomycin)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary objective: Evaluate progression-free survival (PFS) between HIPEC group and control group. Secondary objectives: Overall survival (OS), cancer-specific survival, safety, and quality of life.",
            "Conclusion": "This trial is currently actively enrolling patients."
        },
        {
            "Title": "Rationale and study design of the KOV-HIPEC-04: A phase III randomized controlled trial in primary stage III and IV ovarian cancer after interval cytoreductive surgery (FOCUS).",
            "Indication": "Stage III and IV ovarian cancer",
            "Drug": "HIPEC (Cisplatin) with maintenance therapy of Bevacizumab and/or PARP inhibitor",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: Overall survival (OS). Secondary objectives: Progression-free survival (PFS), cancer-specific survival, time to the first subsequent therapy, safety, and quality of life.",
            "Conclusion": "This trial will enroll 520 patients."
        },
        {
            "Title": "Efficacy and safety of luveltamab tazevibulin vs investigator\u2019s choice of chemotherapy in patients with recurrent platinum-resistant ovarian cancer (PROC) expressing folate receptor alpha (FRa): The REFRaME-01 (GOG-3086, ENGOT-79ov, and APGOT-OV9) phase 2/3 study.",
            "Indication": "Recurrent platinum-resistant ovarian cancer expressing folate receptor alpha (FRa)",
            "Drug": "Luveltamab tazevibulin",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoints: Progression-free survival (PFS) and objective response rate (ORR). Secondary endpoints: Overall survival (OS), duration of response (DOR), safety, and quality of life.",
            "Conclusion": "This randomized, global, open-label, 2-part phase 2/3 pivotal study has been designed to assess the efficacy and safety of luveltamab tazevibulin vs investigator\u2019s choice chemotherapy."
        },
        {
            "Title": "Randomized pilot study assessing the safety and tolerability hyperthermic intraperitoneal chemotherapy (HIPEC) at completion of interval cytoreductive surgery or chemotherapy the day prior to interval cytoreductive surgery for patients with stage III/IV ovarian cancer undergoing neoadjuvant chemotherapy.",
            "Indication": "Stage III/IV ovarian cancer",
            "Drug": "IV Cisplatin, HIPEC with Cisplatin",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Frequency and severity of adverse events (AEs) will be collected and summarized by descriptive statistics for each group.",
            "Other": "Exploratory secondary endpoint includes changes to the gut microbiome before and after HIPEC.",
            "Conclusion": "The primary objective is to assess the feasibility, safety, and tolerability of IV Cisplatin the day prior to iCRS compared to HIPEC to determine if this is an acceptable alternative."
        },
        {
            "Title": "GOG3069: Phase 2 study of alpelisib and fulvestrant for PIK3CA-mutated estrogen receptor (ER)-positive endometrioid endometrial cancers.",
            "Indication": "Advanced, persistent, or recurrent endometrial carcinoma",
            "Drug": "Alpelisib and fulvestrant",
            "PFS": "None",
            "OS": "24-month overall survival rate",
            "ORR": "Overall response rate as measured by RECIST v1.1-defined tumor response",
            "AE": "Safety and tolerability will be collected.",
            "Other": "None",
            "Conclusion": "The trial is designed to evaluate the efficacy and safety of alpelisib and fulvestrant in a biomarker-selected endometrial cancer patient population."
        },
        {
            "Title": "A phase 2, open-label, single-arm, prospective, multicenter study of nab-sirolimus plus letrozole in advanced or recurrent endometrioid endometrial cancer.",
            "Indication": "Advanced or recurrent endometrioid endometrial carcinoma (EEC)",
            "Drug": "Nab-sirolimus and letrozole",
            "PFS": "None",
            "OS": "None",
            "ORR": "Best overall response rate by RECIST v1.1",
            "AE": "Safety will be collected.",
            "Other": "Disease control rate (defined as complete response plus partial response plus stable disease of \u226512 weeks after start of treatment)",
            "Conclusion": "The trial is designed to evaluate the antitumor activity of nab-sirolimus in combination with letrozole in patients with advanced or recurrent EEC."
        },
        {
            "Title": "Phase II/III study of paclitaxel/carboplatin alone or combined with either trastuzumab and hyaluronidase-oysk or pertuzumab, trastuzumab, and hyaluronidase-zzxf in HER2 positive, stage I-IV endometrial serous carcinoma or carcinosarcoma (NRG-GY026).",
            "Indication": "HER2 positive, stage I-IV endometrial serous carcinoma or carcinosarcoma",
            "Drug": "Paclitaxel/carboplatin, trastuzumab and hyaluronidase-oysk, pertuzumab, trastuzumab, and hyaluronidase-zzxf",
            "PFS": "Primary outcome of the phase II component is PFS.",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The primary objective is to evaluate the efficacy of trastuzumab and hyaluronidase-oysk or a fixed dose combination of pertuzumab, trastuzumab, and hyaluronidase-zzxf in combination with paclitaxel/carboplatin in patients with HER2 positive uterine serous carcinoma or carcinosarcoma."
        },
        {
            "Title": "AFT-50 EndoMAP: A phase IB/II multicohort study of targeted agents and/or immunotherapy for patients with recurrent or persistent endometrial cancer (EC)",
            "Indication": "Recurrent or persistent endometrial cancer",
            "Drug": "Atezolizumab (Atezo) with various targeted agents",
            "PFS": "None",
            "OS": "None",
            "ORR": "Primary endpoint for AFT-50A is confirmed overall response rate (ORR) for each arm",
            "AE": "None",
            "Other": "Secondary endpoints include disease control rate, duration of response, OS, safety, and tolerability",
            "Conclusion": "The study is actively enrolling at 20 sites in the US with a target of 25 sites nationwide"
        },
        {
            "Title": "GOG3088: A randomized phase II study of letrozole versus observation in patients with newly diagnosed uterine leiomyosarcoma",
            "Indication": "Newly diagnosed uterine leiomyosarcoma",
            "Drug": "Letrozole",
            "PFS": "Primary endpoint is investigator-assessed progression free survival",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Exploratory translational evaluation to correlate response to letrozole based on IHC hormone receptor status, gene expression signatures, and contribution of single nucleotide polymorphism rs3820282 to tumor biology and response to letrozole",
            "Conclusion": "GOG3088 activated in January 2024 and expects to enroll across 20 US sites"
        },
        {
            "Title": "A prospective, randomized, single-blinded study evaluating the effect of frozen-section directed excision on positive surgical margins in high-grade vulvar dysplasia",
            "Indication": "High-grade vulvar dysplasia",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The study will collect information regarding the surgery procedure, the amount of time the surgery procedure takes, total amount of pain medication required during surgery and while in the surgery recovery area, recurrence rates in six months, any other therapies required, and subject satisfaction related to the surgery, recovery, and sexual function",
            "Conclusion": "It is expected that FSDE will reduce the positive margin rate by at least 50%. The study is designed to provide 80% power to detect this difference"
        },
        {
            "Title": "Adjuvant PD-1 blockade with camrelizumab in high-risk locoregionally advanced nasopharyngeal carcinoma (DIPPER): A multicenter, open-label, phase 3, randomized controlled trial.",
            "Indication": "Locoregionally advanced nasopharyngeal carcinoma (LANPC)",
            "Drug": "Tislelizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "93.3% in the tislelizumab arm and 90.7% in the placebo arm",
            "AE": "Grade \u22653 TEAEs: 40.6% in the tislelizumab arm vs 39.3% in the placebo arm; SAEs: 2.3% in the tislelizumab arm vs 1.3% in the placebo arm",
            "Other": "Complete Response Rate (CRR): 30.5% in the tislelizumab arm vs 16.7% in the placebo arm",
            "Conclusion": "The trial met its first primary endpoint with a statistically significant improvement of CRR with the addition of tislelizumab to standard IC in high-risk LANPC. Induction therapy with tislelizumab plus GP was generally well tolerated with a manageable safety profile. Continued follow-up is being conducted to assess long-term efficacy and safety."
        },
        {
            "Title": "Endostar combined with concurrent chemoradiotherapy versus concurrent chemoradiotherapy alone for locoregionally advanced nasopharyngeal carcinoma (LA-NPC): A phase III, prospective, randomised-controlled, multicenter clinical trial.",
            "Indication": "Locoregionally advanced nasopharyngeal carcinoma (LA-NPC)",
            "Drug": "Endostar",
            "PFS": "3-year PFS: 84.8% in the CCRT+E group vs 75.1% in the CCRT group (HR, 0.544; 95% CI, 0.336-0.879; P log-rank = 0.011)",
            "OS": "3-year OS: 89.2% in the CCRT+E group vs 85.3% in the CCRT group (HR, 0.595; 95% CI, 0.361-0.982; P log-rank = 0.039)",
            "ORR": "None",
            "AE": "Grade 3-4 acute and late toxicity: 61.7% in the CCRT+E group vs 65.3% in the CCRT group (p=0.519) and 8.0% vs 7.3% (p=0.815)",
            "Other": "3-year DMFS: 89.7% in the CCRT+E group vs 80.5% in the CCRT group (HR, 0.468; 95% CI, 0.266-0.821; P log-rank = 0.007); 3-year LRRFS: 91.5% in the CCRT+E group vs 90.8% in the CCRT group (HR, 0.808; 95% CI, 0.407-1.604; P log-rank = 0.541); Complete Response Rate (CR) at 3 months after RT: 90.0% in the CCRT+E group vs 80.7% in the CCRT group (P=0.022)",
            "Conclusion": "The addition of Endostar to standard CCRT significantly improved the 3-year PFS of LA-NPC patients with a good safety profile."
        },
        {
            "Title": "Results of a randomized, double-blind, placebo-controlled, phase 2 study (OpcemISA) of the combination of ISA101b and cemiplimab versus cemiplimab for recurrent/metastatic (R/M) HPV16-positive oropharyngeal cancer (OPC).",
            "Indication": "Recurrent/metastatic HPV16-positive oropharyngeal cancer (OPC)",
            "Drug": "ISA101b and cemiplimab",
            "PFS": "None",
            "OS": "Median OS (mOS) in patients with CPS \u226520: Not reached (28.1, -) in the ISA101b arm vs 23.3 (11.9, 30.1) months in the control arm (P=0.0232, PPS)",
            "ORR": "Overall population: 25.3% in the ISA101b arm vs 22.9% in the control arm (NS); PPS: 38.9% in the ISA101b arm vs 27.3% in the control arm (P=0.064); FAS CPS \u226520: 51.9% in the ISA101b arm vs 26.7% in the control arm (P=0.078); PPS CPS \u226520: 61.9% in the ISA101b arm vs 28.0% in the control arm (P=0.026)",
            "AE": "SAEs: 33.0% in the ISA101b arm vs 31.6% in the control arm",
            "Other": "None",
            "Conclusion": "Whereas there was no advantage of the addition of ISA101b to cemiplimab regarding ORR in the overall population, in patients with CPS \u226520 ISA101b significantly improved the ORR. Median OS was better in patients with CPS \u226520 who completed a full course of ISA101b. In contrast, patients with lower CPS did not benefit. Toxicity was comparable between the 2 arms."
        },
        {
            "Title": "A phase 1 dose-escalation and expansion study of CUE-101, given as HB-200 arenavirus-based immunotherapy plus pembrolizumab as first-line monotherapy and in combination with pembrolizumab, in patients with recurrent/metastatic HPV16-positive head and neck cancer: Updated results.",
            "Indication": "Recurrent/metastatic HPV16-positive head and neck squamous cell cancer (R/M HNSCC)",
            "Drug": "CUE-101 and pembrolizumab",
            "PFS": "5.8 months [95% CI 2.56; NA]",
            "OS": "None",
            "ORR": "47% (1 CR, 8 PRs)",
            "AE": "92% grade \u22642; most frequent grade 3 AEs: lymphocyte count decreased (7.6%), anemia (6.3%), decreased appetite (5.1%), infusion-related reactions (5.1%)",
            "Other": "Disease Control Rate (ORR + durable SDs) of 74%; median OS of 20.8 months [95% CI 11.0; NA] in monotherapy RP2D patients",
            "Conclusion": "An ORR of 47% and a mPFS of 5.8 months were observed in R/M HNSCC patients treated with CUE-101 4 mg/kg + pembrolizumab as 1L therapy. A median OS of 20.8 was observed in patients treated with CUE-101 monotherapy as post-platinum/CPI therapy. CUE-101 continues to demonstrate safety, tolerability, and meaningful clinical benefit in patients with HPV16+ R/M HNSCC."
        },
        {
            "Title": "Phase III randomized trial of intensity-modulated proton therapy (IMPT) versus intensity-modulated photon therapy (IMRT) for the treatment of head and neck oropharyngeal carcinoma (OPC).",
            "Indication": "Head and neck oropharyngeal carcinoma (OPC)",
            "Drug": "Intensity-modulated proton therapy (IMPT) vs. intensity-modulated photon therapy (IMRT)",
            "PFS": "HR for disease progression or death at 3 years: 0.87 (95% CI 0.56, 1.35); p=0.006",
            "OS": "HR for death: 0.63 (95% CI 0.36-1.10)",
            "ORR": "None",
            "AE": "PP gastrostomy-tube dependence decreased with IMPT vs. IMRT from 42% to 28% (p=0.019); end of treatment weight loss, 5% from baseline: 24% vs 14% (p=0.037)",
            "Other": "None",
            "Conclusion": "IMPT is non-inferior to IMRT and has emerged as a standard of care CRT approach for OPC that reduces malnutrition and gastrostomy-tube dependence."
        },
        {
            "Title": "Intra-treatment hypoxia directed major radiation de-escalation as definitive treatment for human papillomavirus-related oropharyngeal cancer.",
            "Indication": "Human papillomavirus-related oropharyngeal cancer (HPV+ OPC)",
            "Drug": "Hypoxia-directed de-escalated chemoradiotherapy",
            "PFS": "None",
            "OS": "2-year overall survival probability: 99%",
            "ORR": "None",
            "AE": "Acute grade 3-4 toxicities: 32% (67% neutropenia)",
            "Other": "2-year LF: 4.2%, RF: 6.9%, DM: 2.0%; Mean MDADI scores: 92.93 (baseline); 68.24 (3 weeks), 91.45 (4 months) after chemoradiation",
            "Conclusion": "These early data indicate that major de-escalation to 30Gy based on hypoxia status achieved significant toxicity reduction without compromise in survival for HPV+ OPC treated with chemoradiation without surgery."
        },
        {
            "Title": "A randomized, double-blind, placebo-controlled phase II study of adjuvant pembrolizumab versus placebo in patients with head and neck squamous cell cancers at high risk for recurrence: The PATHWay study.",
            "Indication": "Head and neck squamous cell carcinoma (HNSCC)",
            "Drug": "Pembrolizumab",
            "PFS": "IO: HR 0.61 (80% CI: 0.43-0.86, one-sided p=0.021); PL: HR 1.00 (80% CI: 0.6-1.68, p=0.45)",
            "OS": "None",
            "ORR": "None",
            "AE": "IO: 1 (2%) grade 5 (unrelated), 1 (2%) grade 4; PL: 3 (6%) grade 4",
            "Other": "PFS rates for IO were 65% and 54% at 1 and 2 years, respectively, compared to 48% and 33% for PL.",
            "Conclusion": "Pembrolizumab treatment for 1 year in patients with high risk of recurrent HNSCC following curative therapy resulted in a statistically significant improvement in PFS compared to placebo, and the benefit was maintained in key subgroups."
        },
        {
            "Title": "Long-term follow-up of E3311, a phase II trial of transoral surgery (TOS) followed by pathology-based adjuvant treatment in HPV-associated (HPV+) oropharynx cancer (OPC): A trial of the ECOG-ACRIN Cancer Research Group.",
            "Indication": "HPV-associated oropharynx cancer (OPC)",
            "Drug": "None",
            "PFS": "54-m PFS: Arm A 93.2% (90% CI: 79.6%, 97.8%); Arm B 94.9% (90% CI: 89.7%, 97.5%); Arm C 90.2% (90% CI: 82.7%, 94.6%); Arm D 85.5% (90% CI: 77.5%, 90.8%)",
            "OS": "54-m OS: Arm A 97.1% (90% CI: 85.7%, 99.4%); Arm B 97.9% (90% CI: 93.5%, 99.3%); Arm C 95.1% (90% CI: 90.1%, 97.6%); Arm D 92.5% (90% CI: 86.9%, 95.7%)",
            "ORR": "None",
            "AE": "None",
            "Other": "Outcome did not differ by primary site of tonsil vs. other OPC (oOPC): 54-m PFS Tonsil 89.3% (90% CI: 84.9%, 92.5%) vs. oOPC 92.9% (90% CI: 87.0%, 96.2%), p=0.28, and 54-m OS Tonsil 94.6% (90% CI: 91.5%, 96.6%) vs. oOPC 96.6% (90% CI: 92.4%, 98.5%), p=0.35.",
            "Conclusion": "TOS, neck dissection with deintensified risk-based post-operative management results in outstanding 54-m PFS and OS across all subsites of T1-2 p16+ OPC, irrespective of smoking history. Late recurrence is not increased when post-operative radiation is reduced from 60 to 50 Gy for intermediate risk patients."
        },
        {
            "Title": "Neoadjuvant sintilimab and platinum-doublet chemotherapy followed by transoral robotic surgery for HPV-associated resectable oropharyngeal cancer: Single-arm, phase II trial.",
            "Indication": "HPV-associated oropharyngeal cancer (OPC)",
            "Drug": "Sintilimab, platinum-doublet chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "100%",
            "AE": "Grade 1-2 TRAEs: 88.9%; Grade 3 TRAEs: 3.7%",
            "Other": "24 patients (96.0%) achieved MPR, and 13 (52.0%) achieved pCR of all samples including primary lesions and resected lymph nodes.",
            "Conclusion": "Neoadjuvant sintilimab plus platinum-doublet chemotherapy and sequential TORS achieved satisfactory pathological response, favorable functional preservation, and tolerable toxicity in patients with resectable HPV+ OPC. Further follow-up is warranted to confirm long-term survival benefits."
        },
        {
            "Title": "Prospective validation of ctHPVDNA for detection of minimal residual disease and prediction of recurrence in patients with HPV-associated head and neck cancer treated with surgery.",
            "Indication": "HPV-associated head and neck cancer",
            "Drug": "None",
            "PFS": "MRD positive: 2-year PFS 78% vs. MRD negative: 98%, P=0.0009; POD7-42: 2-year PFS 60% vs. 97%, P<0.0001",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Patients with detectable ctHPVDNA following completion of all treatment had significantly worse 2-year PFS compared to patients without detectable ctHPVDNA (0% vs. 97%, P<0.0001).",
            "Conclusion": "ctHPVDNA detection by HPV-DeepSeek is a highly specific biomarker of MRD in HPV+ HNSCC, accurately predicts disease progression and detects recurrence earlier than standard care clinical."
        },
        {
            "Title": "Tisotumab vedotin in head and neck squamous cell carcinoma: Updated Preliminary results of phase I/II study to evaluate safety and efficacy of analysis from innovaTV207 Part C.",
            "Indication": "Recurrent or metastatic head and neck squamous cell carcinoma (r/m HNSCC)",
            "Drug": "Tisotumab vedotin (TV)",
            "PFS": "None",
            "OS": "None",
            "ORR": "32.5% (95% CI, 18.6-49.1)",
            "AE": "85.0% of pts had at least 1 treatment-related adverse event (TRAE). Grade \u22653 TRAEs occurred in 25.0% of pts, with the most common being peripheral neuropathy events (12.5%). Adverse events of special interest were prespecified for ocular, peripheral neuropathy, and bleeding events, and occurred in 21 (52.5%), 19 (47.5%), and 15 (37.5%) pts, respectively.",
            "Other": "Median DOR was 5.6 months (95% CI, 3.0-NR) and median TTR was 1.4 months. Among pts with \u22642 prior lines (n=25), cORR was 40.0% (95% CI, 21.1-61.3). In this subgroup, DOR is not yet mature (range: 1.2+ to 7.9+ months), and among the 10 responders, 6 pts remain in response; median TTR was 1.5 months.",
            "Conclusion": "TV demonstrated encouraging antitumor activity in a heavily pretreated r/m HNSCC population with a manageable safety profile consistent with previous TV monotherapy data. The study is ongoing; TV represents a promising treatment option for pts with r/m HNSCC who have progressed after prior platinum-based therapy and immunotherapy."
        },
        {
            "Title": "Combination pucotenlimab with epidermal growth factor receptor-ADC (EGFR-ADC) MRG003 in patients with EGFR positive solid tumors.",
            "Indication": "Locally advanced or metastatic solid tumors known to express EGFR",
            "Drug": "Pucotenlimab (HX008) and MRG003",
            "PFS": "None",
            "OS": "None",
            "ORR": "63.0% (95% CI: 42.4, 80.6) in Phase I; 77.8% (95% CI: 40.0, 97.2) in Phase II for NPC pts; 60% (95% CI: 14.7, 94.7) for SCCHN pts",
            "AE": "The most commonly reported treatment-related adverse events (TRAEs) included pruritus (46%), rash (33%), AST increased (30%), anemia (30%). Grade 3-4 TRAEs occurred in 4 pts (12%), mainly white blood cell count decreased (9%) and hypokalemia (6%).",
            "Other": "The only DLT event occurred in the 2.3 mg/kg dose group, and the RP2D of MRG003 determined was 2.0 mg/kg by SMC. The DOR and PFS in the study were immature. The longest patient treated has had a DOR for more than 17 months and still ongoing.",
            "Conclusion": "The Phase I/II study pts treated with HX008 in combination with MRG003 demonstrated good tolerability and encouraging antitumor activity in NPC and SCCHN, especially in PD-1 treatment failed NPC pts. The Phase II study is currently ongoing."
        },
        {
            "Title": "Petosemtamab (MCLA-158) with pembrolizumab as first-line (1L) treatment of recurrent/metastatic (r/m) head and neck squamous cell carcinoma (HNSCC): Phase 2 study.",
            "Indication": "Recurrent/metastatic head and neck squamous cell carcinoma (r/m HNSCC)",
            "Drug": "Petosemtamab (MCLA-158) with pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Treatment-emergent adverse events (AEs) were reported in 26 pts, and most were Grade (G) 1 or 2 in severity (no G4\u20135 were observed). The most frequent AEs (all Gs/G3) were acneiform dermatitis (30.8%/0%), asthenia (26.9%/0%), and rash (26.9%/0%). Infusion-related reactions (composite term) were reported in 26.9% (all Gs) and 3.8% (G3) of pts, all occurred during the first infusion and resolved.",
            "Other": "Among 10 pts evaluable for efficacy (\u22652 cycles and \u22651 post-baseline scan, or early progressive disease), there were 1 confirmed complete response, 2 confirmed and 3 unconfirmed partial responses (2 confirmed after data cutoff), 3 stable disease, and 1 progressive disease; with all 6 responders on treatment at data cutoff.",
            "Conclusion": "Petosemtamab, a first-in-class EGFRxLGR5 bispecific antibody, in combination with pembrolizumab demonstrates a well-tolerated safety profile and promising preliminary clinical efficacy as 1L treatment for pts with r/m HNSCC."
        },
        {
            "Title": "Intralesional nivolumab in oral potentially malignant disorders: A phase 1 pilot study on safety, tolerability, and preliminary efficacy.",
            "Indication": "Oral Potentially Malignant Disorders (OPMD)",
            "Drug": "Nivolumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "Major response in 22.5% and partial response in 50% of the patients",
            "AE": "Grade 1 (80.5%), Grade 2 (13.9%), Grade 3 (5.6%)",
            "Other": "Increased infiltration of CD8 cytotoxic T cells in index lesions after treatment; significant alterations in immune pathways including Th1 and Th2 pathways, Natural killer and immunoregulatory pathways.",
            "Conclusion": "Intralesional nivolumab is well tolerated in OPMD with suggested potential biological and clinical activity. A randomized phase II trial to assess cancer-free survival is recommended to determine the efficacy in preventing cancer progression. We recommend a dose of 20mg for phase II as it was well tolerated."
        },
        {
            "Title": "Neoadjuvant HPV16-specific arenavirus-based immunotherapy HB-200 plus chemotherapy followed by response-stratified de-intensification in HPV16+ oropharyngeal cancer: TARGET-HPV.",
            "Indication": "HPV16+ oropharyngeal cancer",
            "Drug": "HB-200 (HB-201 and HB-202), Carboplatin, Paclitaxel",
            "PFS": "None",
            "OS": "None",
            "ORR": "Deep responses in 81% of patients overall, and in 93% treated on higher dose levels",
            "AE": "Grade 3 treatment-emergent adverse events in 62% of patients overall, 14% non-hematologic during neoadjuvant; no Grade 4 AEs reported",
            "Other": "All three patients who underwent TORS had no viable tumor at time of surgery. Two patients (9%) had persistent disease following CRT and underwent salvage surgery with no evidence of disease at last follow-up. ctHPV-DNA and HPV16-specific T-cell response data will be presented at the meeting.",
            "Conclusion": "Neoadjuvant HB-200/chemotherapy is safe and feasible with early efficacy signal in this setting warranting further study. Enrollment to the subsequent randomized phase II part is ongoing."
        },
        {
            "Title": "A phase II trial of neoadjuvant docetaxel/5-FU/cisplatin in combination with prophylactic pegteograstim in unresectable, locally advanced nasal cavity/paranasal squamous cell carcinoma: KCSG HN18-07",
            "Indication": "Locally advanced nasal cavity/paranasal squamous cell carcinoma",
            "Drug": "Docetaxel, 5-FU, cisplatin, prophylactic pegteograstim",
            "PFS": "2-year PFS: 64%",
            "OS": "None",
            "ORR": "64.3% (5 complete responses, 13 partial responses)",
            "AE": "Grade \u22653 adverse events: 46.4% hematologic, 14.3% non-hematologic, 32.1% Grade 3/4 neutropenia, 14.3% febrile neutropenia",
            "Other": "Eyeball preservation rate at 24 months: 100%",
            "Conclusion": "DFP NAC demonstrated promising efficacy and an excellent eyeball preservation rate with manageable toxicity. DFP NAC with prophylactic pegteograstim could be a reasonable option for patients with locally advanced SCC of the PNSNC. Follow-up is ongoing."
        },
        {
            "Title": "Comparative analysis of pembrolizumab and cetuximab-based therapy in patients with recurrent/metastatic head and neck squamous cell carcinoma: Real-world data",
            "Indication": "Recurrent/metastatic head and neck squamous cell carcinoma",
            "Drug": "Pembrolizumab, cetuximab",
            "PFS": "None",
            "OS": "Median OS: 13.7 months (P), 15 months (P+CT), 11.4 months (C+CT); 5-year survival probability: 25.7% (P), 32.2% (P+CT), 9.9% (C+CT)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Our retrospective analysis of real-world data from a large national database shows superior OS with pembrolizumab-based treatment compared to cetuximab-based treatment in the first-line setting for patients with R/MHNSCC. This analysis is consistent with KEYNOTE-048 outcomes. We found no difference in OS between Pembrolizumab alone or in combination with chemotherapy. Limitations include unknown PD-L1 and HPV status. Further long-term and prospective analysis is imperative to enhance the care and outcomes of patients with metastatic HNSCC."
        },
        {
            "Title": "Pembrolizumab plus nab-paclitaxel and platinum as first-line treatment in patients with recurrent or metastatic head and neck squamous cell carcinoma (R/MHNSCC): A prospective, single-arm, open-label, phase 2 study",
            "Indication": "Recurrent or metastatic head and neck squamous cell carcinoma",
            "Drug": "Pembrolizumab, nab-paclitaxel, cisplatin/carboplatin",
            "PFS": "Median PFS: 11.6 months",
            "OS": "Median OS: 18.7 months",
            "ORR": "62.7%",
            "AE": "Grade \u22653 TRAEs: leukopenia (22.4%), neutropenia (28.4%); Grade \u22653 immune-related AEs: pneumonitis (1.5%), hepatitis (1.5%), enteritis (1.5%)",
            "Other": "Median DOR: 14.7 months; Common alterations: TP53 mutation (86%), CDKN2A mutation (22%), FGF19 (26%), FGF4 (20%), FGF3 (20%), CCND1 (20%) amplification; FGF19, FGF4, FGF3, and CCND1 amplification associated with poor ORR; CDKN2A mutation associated with poor OS",
            "Conclusion": "Pembrolizumab combined with nab-paclitaxel and platinum shows encouraging antitumor activity accompanied by a manageable safety profile in untreated R/MHNSCC patients in China. CDKN2A mutation was a potential independent prognostic factor for this patient population."
        },
        {
            "Title": "Efficacy of nimotuzumab added to concurrent chemoradiotherapy after induction chemotherapy for patients with locally advanced nasopharyngeal carcinoma.",
            "Indication": "Locally advanced nasopharyngeal carcinoma (LANPC)",
            "Drug": "Nimotuzumab",
            "PFS": "None",
            "OS": "3-year OS: 95.4% with nimotuzumab vs. 88.0% without nimotuzumab (P=0.041)",
            "ORR": "None",
            "AE": "No significant differences in major toxicities including hematologic toxicities, hepatoxicity, nephrotoxicity, gastrointestinal reactions, and mucositis (P>0.05)",
            "Other": "3-year DFS: 90.3% with nimotuzumab vs. 77.5% without nimotuzumab (P=0.003); 3-year LRFS: 96.5% with nimotuzumab vs. 93.7% without nimotuzumab (P=0.297); 3-year DMFS: 92.9% with nimotuzumab vs. 82.5% without nimotuzumab (P=0.008)",
            "Conclusion": "The addition of nimotuzumab to CCRT after IC in LANPC has shown promising results in treatment outcomes and acceptable toxicities."
        },
        {
            "Title": "Off-label use of checkpoint inhibitor (CPI) monotherapy in PD-L1\u2013negative or unknown recurrent/metastatic head and neck cancer (R/MHNSCC).",
            "Indication": "Recurrent/metastatic head and neck squamous cell carcinoma (R/MHNSCC)",
            "Drug": "Checkpoint inhibitors (CPI)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Most common frontline treatment: CPI monotherapy (43%), followed by chemotherapy/cetuximab (33%) and CPI with chemotherapy (25%). CPI monotherapy use was highly prevalent in patients aged \u226575 (54%) and with ECOG PS \u22652 (52%).",
            "Conclusion": "Most US patients with R/MHNSCC are now receiving CPI-based therapy in the frontline setting, but PD-L1 testing rates remain suboptimal. Use of CPI monotherapy in PD-L1 negative or unknown HNSCC is common, particularly in elderly patients and those with poor performance status."
        },
        {
            "Title": "Safety and efficacy of iparomlimab and tuvoralimab in combination with gemcitabine and cisplatin as first-line treatment for patients with recurrent or metastatic nasopharyngeal carcinoma: A multicenter, single-arm, phase 2 trial (DUBHE-N-302).",
            "Indication": "Recurrent or metastatic nasopharyngeal carcinoma (R/M NPC)",
            "Drug": "Iparomlimab and tuvoralimab (QL1706)",
            "PFS": "Median PFS: 12.5 months (95% CI, 5.7 to NE); In 14 patients with high expression level of PD-L1 (CPS \u226550), median PFS was 16.2 months (95% CI, 9.9 to NE)",
            "OS": "Median OS: Not reached",
            "ORR": "82.1% (95% CI, 63.1% to 93.9%)",
            "AE": "100% experienced TRAEs; Grade 3 or 4 TRAEs in 62% of patients, most common being decreased neutrophil count (41%), decreased white blood cell count (35%), anemia (21%), and decreased platelet count (21%). 69% experienced immune-related adverse events, most common being grade 1-2 hypothyroidism (38%). Serious TRAEs in 17% of patients. 10% discontinued treatment due to adverse events.",
            "Other": "DCR: 96.4% (95% CI, 81.7% to 99.9%); Median DoR: 14.1 months (95% CI, 7.6 to NE)",
            "Conclusion": "Iparomlimab and tuvoralimab combined with chemotherapy showed tolerable safety and promising efficacy as 1L treatment for patients with R/M NPC."
        },
        {
            "Title": "Impact of immune-related adverse events (irAE) on disease response and survival in patients with head-neck cancer treated with immune checkpoint inhibitors (ICI).",
            "Indication": "metastatic head-neck squamous cell carcinoma (mHNSCC)",
            "Drug": "immune checkpoint inhibitors (ICI)",
            "PFS": "Median PFS was 3.9 months vs 2.1 months (HR 0.48, 95% CI 0.30-0.77; p=0.003)",
            "OS": "Median OS was 12.5 months vs 4.3 months (HR 0.36, 95% CI 0.21-0.60; p=0.000)",
            "ORR": "Objective response rate (ORR) was higher in the irAEs group than in the non-irAEs group (68% vs 39%, p=0.009)",
            "AE": "IrAEs (any grade) were observed in 60 patients (67%), including 9 patients (10%) with grade \u22653 AEs",
            "Other": "Patients who experienced endocrine-related irAEs (p=0.004), diarrhea/colitis (p=0.040), and lipase/amylase increase (p=0.005) had significantly longer OS than those without the corresponding toxicity. A positive trend was observed for skin toxicity (p=0.051).",
            "Conclusion": "The occurrence of irAEs was a predictor of response and survival outcomes in patients with R/M HNSCC treated with ICIs single-agent."
        },
        {
            "Title": "Duvelisib with docetaxel for patients with anti-PD-1 refractory, recurrent, or metastatic head and neck squamous cell carcinoma.",
            "Indication": "recurrent or metastatic head and neck squamous cell carcinoma (R/M HNSCC)",
            "Drug": "Duvelisib with docetaxel",
            "PFS": "Median PFS was 2.8 months (95% CI: 1.9-7.0)",
            "OS": "Median OS was 10.2 months (95% CI: 6.7-NA) and 1-year OS was 48%",
            "ORR": "Best ORR was 19% (5/26) (95% CI: 6.8-40.7%), all 5 were partial responses (median duration: 6.9 months [0.7-15.5])",
            "AE": "Grade 3+ treatment-related adverse events were observed in 13/26 (50%), most often elevated liver function tests (6, 23%)",
            "Other": "Disease in response was observed in 3/8 pts with somatic alterations in PIK3CA.",
            "Conclusion": "We report a favorable response rate when combining a PI3K pathway inhibitor with taxane chemotherapy in patients with anti-PD-1 refractory HNSCC. Further evaluation of this strategy is ongoing in the phase 3 BURAN trial which is investigating buparlisib with paclitaxel."
        },
        {
            "Title": "A phase 2 study of trifluridine/tipiracil (FTD/TPI) in recurrent/metastatic nasopharyngeal (r/mNPC) carcinomas with or without prior exposure to fluoropyrimidines.",
            "Indication": "Recurrent/metastatic nasopharyngeal carcinoma (r/mNPC)",
            "Drug": "Trifluridine/tipiracil (FTD/TPI)",
            "PFS": "6.5 months (95% CI 2.8-10.2)",
            "OS": "13.1 months (95% CI 7.1-19.2)",
            "ORR": "18.2%",
            "AE": "Neutropenia, anaemia, fatigue, nausea, anorexia",
            "Other": "Disease control rate (DCR) at 12 weeks was 63.6%. DCR was 68.8% and 58.8% in patients with and without prior fluoropyrimidine exposure respectively.",
            "Conclusion": "FTD/TPI is well tolerated, compares favorably to Capecitabine and showed promising anti-tumor activity with meaningful clinical benefit regardless of prior exposure to fluoropyrimidines. This oral chemotherapy offers an attractive choice for patients valuing quality of life, minimizing hospital visits and resource utilization such as chemotherapy infusions. Further investigations in randomized studies are warranted."
        },
        {
            "Title": "A phase Ib/II dose escalation/expansion study of Anti-LAG-3 antibody LBL-007 in combination with anti-PD-1 antibody tislelizumab with or without chemotherapy in patients with advanced nasopharyngeal carcinoma and other malignant tumors.",
            "Indication": "Advanced nasopharyngeal carcinoma (NPC) and other malignant tumors",
            "Drug": "LBL-007 (anti-LAG-3) in combination with tislelizumab (anti-PD-1) with or without chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "Phase Ib: 25.0%, Phase II: 90.2%",
            "AE": "All grades TRAEs occurred in 92.9% of patients, with grade \u22653 TRAEs in 57.1%. The most common TRAEs (\u226520%) included decreased white blood cell count, decreased neutrophil count, anaemia, increased ALT, increased AST, decreased platelet count, nausea, hyponatraemia, hypochloraemia, increased blood creatinine, vomiting, and hypothyroidism.",
            "Other": "DCR (CR+PR+SD) in Phase Ib was 65.0% and in Phase II was 100.0%. 3-month PFS rate in Phase II was 97.6%.",
            "Conclusion": "LBL-007/tislelizumab combination is well-tolerated in patients with advanced malignant tumors. LBL-007/tislelizumab/GC combination showed manageable, no new safety concerns and encouraging antitumor activity in previously untreated and advanced NPC patients. The encouraging efficacy and safety profile may support a pivotal study with LBL-007 in combination with tislelizumab and GC chemotherapy for NPC development in 1L setting."
        },
        {
            "Title": "Early signs of efficacy in patients with anti-PD-1 naive and anti-PD-1 resistant HNSCC treated with NBTXR3/SBRT in combination with nivolumab or pembrolizumab in the phase I trial Study 1100.",
            "Indication": "Locoregionally recurrent (LRR) or metastatic (M+) head & neck squamous cell carcinoma (HNSCC)",
            "Drug": "NBTXR3/SBRT in combination with nivolumab or pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "ICI-resistant: 25%, ICI-naive: 54%",
            "AE": "11 patients (20%) experienced grade \u22653 treatment-related AEs, of which 3 patients (5.5%) experienced grade \u22653 AEs related to NBTXR3.",
            "Other": "Disease control rate (DCR) in ICI-resistant patients was 75% and in ICI-naive patients was 77%. Observed improvements in injected lesions as well as in some non-injected target lesions.",
            "Conclusion": "NBTXR3/RT/anti-PD-1 combination was feasible and well tolerated. Early data suggest the addition of NBTXR3/RT to ICI showed evidence of efficacy, including in patients who failed prior ICI, opening up an opportunity to further explore the ability of NBTXR3/RT to mitigate primary or secondary resistance to ICI."
        },
        {
            "Title": "Clinical, molecular, and immunologic profiling of brain metastases (BM) in head and neck squamous cell carcinoma (HNSCC).",
            "Indication": "Head and neck squamous cell carcinoma (HNSCC)",
            "Drug": "None",
            "PFS": "None",
            "OS": "Overall survival from BM diagnosis was 6 months (range 0-27 months)",
            "ORR": "None",
            "AE": "None",
            "Other": "The most frequently altered genes in BM specimens were ATM (54%), KMT2A (54%), PTEN (46%), RB1 (46%), and TP53 (46%).",
            "Conclusion": "HNSCC patients with BM have higher-than-expected proportions of oropharyngeal primary site and HPV/p16-positivity. The most frequent molecular alterations in BM samples are also commonly found in non-BM HNSCC, including targetable PIK3CA alterations. MAP2K2 alterations were significantly enriched in BM compared to non-BM samples, which warrants further investigation. BM samples also tended to have lower markers of immunogenicity. This latter finding could have important clinical implications when considering immunotherapy or immune-modulating drugs."
        },
        {
            "Title": "Trastuzumab deruxtecan (T-DXd) in patients (pts) with HER2-expressing head and neck tumors: Outcomes from DESTINY-PanTumor02 (DP-02).",
            "Indication": "HER2-expressing head and neck tumors",
            "Drug": "Trastuzumab deruxtecan (T-DXd)",
            "PFS": "12.4 months (95% CI 8.7, 23.4)",
            "OS": "None",
            "ORR": "41.7% (95% CI 22.1, 63.4)",
            "AE": "Grade \u22653 drug-related adverse events occurred in 41.7% of patients. Adjudicated drug-related interstitial lung disease/pneumonitis occurred in 12.5% of patients.",
            "Other": "DCR at 12 weeks: 87.5% (95% CI 67.6, 97.3)",
            "Conclusion": "T-DXd showed clinically meaningful benefit in pretreated patients with head and neck tumors. Responses were observed across HER2 expression levels. Safety was consistent with the known profile. These data support T-DXd as a potential treatment for pretreated patients with HER2-expressing head and neck tumors."
        },
        {
            "Title": "A phase 1 study of fianlimab (anti\u2013LAG-3) in combination with cemiplimab (anti\u2013PD-1) in patients with advanced HNSCC.",
            "Indication": "Advanced head and neck squamous cell carcinomas (HNSCC)",
            "Drug": "Fianlimab (anti\u2013LAG-3) + cemiplimab (anti\u2013PD-1)",
            "PFS": "Cohort 11: 2 months (95% CI, 1\u201314); Cohort 12: 4 months (95% CI, 1\u20137)",
            "OS": "None",
            "ORR": "Cohort 11: 33% (5 partial responses); Cohort 12: 7% (1 partial response)",
            "AE": "Grade \u22653 treatment-emergent adverse events occurred in 47% of patients in both cohorts. Serious TEAEs occurred in 13% and 20% of patients in cohorts 11 and 12, respectively. Treatment-related TEAEs were reported in 67% of patients in cohort 11 and 53% in cohort 12. Grade \u22653 TRAEs occurred in 7% of patients in cohort 11 and 13% in cohort 12.",
            "Other": "Disease control rate (DCR): Cohort 11: 47%; Cohort 12: 67%. Duration of responses: Cohort 11: 17, 10, 20, 22, and 20 months; Cohort 12: 32 months.",
            "Conclusion": "Fianlimab + cemiplimab in patients with HNSCC showed signs of clinical activity with durable responses among patients with anti\u2013PD-1/PD-L1-na\u00efve (cohort 11) and anti\u2013PD-1/L1-experienced (cohort 12), with an acceptable safety profile which warrants further investigation."
        },
        {
            "Title": "Four-year overall survival follow-up and dynamic EBV titer analysis of toripalimab versus placebo in combination with gemcitabine and cisplatin as first-line treatment for recurrent or metastatic nasopharyngeal carcinoma (r/mNPC).",
            "Indication": "Recurrent or metastatic nasopharyngeal carcinoma (r/mNPC)",
            "Drug": "Toripalimab in combination with gemcitabine and cisplatin",
            "PFS": "None",
            "OS": "HR=0.61 (95% CI: 0.44-0.85), nominal p=0.0027. The median OS was not yet reached in the toripalimab arm and was 33.7 months in the placebo arm. The 5-year OS rates were 52.0% in the toripalimab arm, and 33.9% in the placebo arm.",
            "ORR": "None",
            "AE": "None",
            "Other": "Among patients with detectable baseline EBV DNA copy number and at least one EBV result after study treatment, significantly more patients from the toripalimab arm had EBV DNA copy number decreased to undetectable level than those in the placebo arm, 96.3% vs. 84.5%, p=0.004. Significantly fewer patients experienced EBV DNA copy number rebound in the toripalimab arm than in the placebo arm after the initial reduction, 36.5% vs. 57.4%, p=0.002.",
            "Conclusion": "The combination of toripalimab and chemotherapy showed long-term survival benefit than chemotherapy alone with a 5-year OS rate at 52%. EBV DNA copy number might be used to monitor clinical response and predict disease progression."
        },
        {
            "Title": "Long-term outcomes of anlotinib and penpulimab combined with chemotherapy in the treatment of patients with metastatic nasopharyngeal carcinoma that failed definitive platinum-based chemoradiotherapy.",
            "Indication": "Metastatic nasopharyngeal carcinoma",
            "Drug": "Anlotinib, Penpulimab, Gemcitabine, Cisplatin",
            "PFS": "Cohort GAP: NR (95% CI: NE, NE), Cohort GCAP: 4.17 months (95% CI: 0.0, 22.4), Cohort GCP: 18.6 months (95% CI: NE, NE)",
            "OS": "None",
            "ORR": "Cohort GAP: 92.3% (95% CI: 64.0%, 99.8%), Cohort GCAP: 71.4% (95% CI: 29.0%, 96.3%), Cohort GCP: 83.3% (95% CI: 35.9%, 99.6%)",
            "AE": "Cohort GAP: Grade 3 TRAE: 78.6%, Grade 4 TRAE: 7.1%; Cohort GCAP: Grade 3 TRAE: 87.5%, Grade 4 TRAE: 25.0%; Cohort GCP: Grade 3 TRAE: 100%, Grade 4 TRAE: 33.3%",
            "Other": "None",
            "Conclusion": "The long-term outcomes of this phase 2 study preliminarily disclosed the advantage of the treatment regimen including gemcitabine, anlotinib, and penpulimab as the first-line treatment of MNPC, especially for pts who had exposed to platinum-based chemotherapy. Further study with more sample size is warranted."
        },
        {
            "Title": "A phase II study of nivolumab in patients with recurrent or metastatic carcinosarcomas.",
            "Indication": "Recurrent or metastatic carcinosarcoma",
            "Drug": "Nivolumab",
            "PFS": "2.6 months",
            "OS": "None",
            "ORR": "14.3% confirmed partial response, 32.1% stable disease, disease control rate of 46.4%",
            "AE": "Urticaria: 17.9%, Dyspepsia: 14.3%, Elevated aspartate aminotransferase: 10.7%, Anemia: 10.7%",
            "Other": "None",
            "Conclusion": "Nivolumab demonstrated promising efficacy with favourable toxicity profile in metastatic/recurrent carcinosarcoma."
        },
        {
            "Title": "Efficacy from the phase 1 study of FID-007, a novel nanoparticle paclitaxel formulation, in patients with head and neck squamous cell carcinoma.",
            "Indication": "Head and neck squamous cell carcinoma",
            "Drug": "FID-007 (nanoparticle paclitaxel)",
            "PFS": "None",
            "OS": "None",
            "ORR": "56% partial response, 22% stable disease, 22% progressive disease",
            "AE": "Rash: 72%, Alopecia: 52%, Leukopenia: 46%, Pruritus: 43%, Neutropenia: 41%, Anemia: 37%, Fatigue: 37%, Nausea: 28%, Anorexia: 28%, Grade 3/4 TRAEs: Maculopapular rash: 35%, Neutropenia: 20%, Leukopenia: 20%, Anemia: 17%, Lymphopenia: 7%, Febrile neutropenia: 4%",
            "Other": "None",
            "Conclusion": "FID demonstrates preliminary evidence of anti-tumor activity in heavily pre-treated HNSCC pts across different primary tumor sites, with an ORR of 56%, including those with prior taxane exposure. A phase 2 study of FID with cetuximab in pts with HNSCC is planned to begin enrollment in 2024."
        },
        {
            "Title": "An open-label, single-center phase II trial of cadonilimab (an anti-PD-1/CTLA-4 bispecific antibody) in combination with platinum-based dual-drug neoadjuvant chemotherapy for locally advanced, resectable head and neck squamous cell carcinoma.",
            "Indication": "Locally advanced, resectable head and neck squamous cell carcinoma (HNSCC)",
            "Drug": "Cadonilimab in combination with platinum-based dual-drug neoadjuvant chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "87.5% (21/24)",
            "AE": "58.3% (14/24) experienced treatment-related adverse events (TRAEs); Grade 3-4 TRAEs in 16.7% (4/24)",
            "Other": "Pathologic complete response (pCR) in 50.0% (12/24)",
            "Conclusion": "Cadonilimab plus platinum-based dual-drug neoadjuvant chemotherapy achieved favorable ORR and pCR with manageable toxicities in patients with HNSCC. Follow-up is still underway to obtain long-term survival data."
        },
        {
            "Title": "A retrospective analysis of the safety and efficacy of tislelizumab plus neoadjuvant chemotherapy and concurrent chemoradiotherapy in locally advanced nasopharyngeal carcinoma.",
            "Indication": "Locally advanced nasopharyngeal carcinoma (LA-NPC)",
            "Drug": "Tislelizumab plus neoadjuvant chemotherapy and concurrent chemoradiotherapy (CCRT)",
            "PFS": "3-year PFS: 93.75% in combination therapy group (CG) vs 80% in observation group (OG)",
            "OS": "None",
            "ORR": "88.4% in CG vs 70.2% in OG",
            "AE": "Acute treatment-related adverse events in grades 3 or 4: 70.2% in CG vs 65.6% in OG; Grade 3-4 immune-related adverse events: hypothyroidism (7.0%) and hepatotoxicity (4.7%)",
            "Other": "Complete response rate (CRR) in CG after neoadjuvant therapy: 37.2% vs 12.8% in OG",
            "Conclusion": "Compared with regular treatment, tislelizumab plus neoadjuvant chemotherapy and concurrent chemoradiotherapy was feasible and well tolerated in LA-NPC patients. This trial is supportive of further prospective trials in LA-NPC."
        },
        {
            "Title": "Characterizing the impact of sarcopenia on treatment response and survival in previously treated recurrent or metastatic nasopharyngeal carcinoma: Insights from a secondary analysis of the KL-A167 randomized trial.",
            "Indication": "Recurrent or metastatic nasopharyngeal carcinoma (R/MNPC)",
            "Drug": "Immunotherapy (specific drug not mentioned)",
            "PFS": "HR=2.59, 95% CI=1.48\u20134.54, p=0.001",
            "OS": "HR=2.07, 95% CI=1.12\u20133.81, p=0.020",
            "ORR": "OR=0.25, 95% CI=0.08\u20130.81, p=0.020",
            "AE": "None",
            "Other": "Disease control rate (DCR): OR=0.23, 95% CI=0.07\u20130.70, p=0.010; Sarcopenia characterized by elevated neutrophil count, fibrinogen, and white blood cell count, alongside decreased creatine kinase levels; Fibrinogen accounted for 23.5% of the association between sarcopenia and OS and 15.7% of PFS.",
            "Conclusion": "Sarcopenia significantly correlates with poor treatment response and survival outcomes in patients with R/MNPC receiving immunotherapy. Targeting fibrinogen may offer a novel strategy to mitigate the negative impact of sarcopenia on patient survival."
        },
        {
            "Title": "A phase II trial of reirradiation combined with pembrolizumab in patients with locoregional inoperable recurrence or second primary squamous cell carcinoma of the head and neck (HNSCC)",
            "Indication": "Locoregional inoperable recurrence or second primary squamous cell carcinoma of the head and neck (HNSCC)",
            "Drug": "Pembrolizumab",
            "PFS": "Median PFS was 8.3 months (95% CI 5.5-10.3)",
            "OS": "Median OS was 13.8 months (95% CI 9.5-21.8)",
            "ORR": "54.8% (33.3% CR, 21.4% PR), 21.4% SD, 23.8% PD",
            "AE": "All patients had at least one treatment-related AE (TRAE). 39% had Grade 3 TRAE. Common AEs: aspiration, dehydration, anemia, dysphagia, dyspnea. 4 patients had Grade 5 TRAE.",
            "Other": "Exploratory analysis showed median PFS of 13.8 months (95% CI 9.1-NR) and median OS of 57.8 months (19.6-NR) in patients with a response (CR/PR).",
            "Conclusion": "The primary endpoint of PFS was not improved compared to historical control. PD-L1 biomarker analysis is ongoing and will be reported subsequently."
        },
        {
            "Title": "Real world effectiveness and safety of low dose nivolumab with metronomic chemotherapy in patients with advanced platinum-resistant head and neck cancer: An Indian institutional experience",
            "Indication": "Advanced platinum-resistant head and neck cancer (HNC)",
            "Drug": "Nivolumab with metronomic chemotherapy (methotrexate, erlotinib, celecoxib)",
            "PFS": "Median PFS was 4.3 months",
            "OS": "Median OS was 8.8 months",
            "ORR": "None",
            "AE": "Common AEs: acneiform rash (62%), mucositis (52%), fatigue (34%). Dose reduction for triple metronomic chemotherapy was required in 42% of patients for grade 3 and above AEs. No grade 3/4 immune-related AEs reported.",
            "Other": "8 of 85 patients survived for \u226530 months and 20 (24%) patients were on treatment at the time of data analysis.",
            "Conclusion": "Low-dose immunotherapy along with triple metronomic chemotherapy is a safe treatment option in real world advanced platinum-resistant HNC patients with acceptable outcomes."
        },
        {
            "Title": "A phase III randomized, open-label study to establish the superiority of triple oral metronomic therapy (OMCT) used in addition to chemotherapy regimen (paclitaxel + carboplatin) over chemotherapy alone for the treatment of advanced unresectable head and neck cancer squamous cell cancer (HNSCC).",
            "Indication": "Advanced unresectable head and neck cancer squamous cell cancer (HNSCC)",
            "Drug": "Triple oral metronomic therapy (OMCT) in addition to paclitaxel + carboplatin",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "The potential prognostic value of the head and neck advanced lung inflammation index (HN-ALI) for patients treated with immunotherapy.",
            "Indication": "Recurrent/metastatic head and neck squamous cell carcinoma (R/M HNSCC)",
            "Drug": "Immune checkpoint inhibitors (ICIs) alone or in combination with chemotherapy",
            "PFS": "6 months (95% CI, 2.16-9.83) within the ALI group >18 vs 1 month for the ALI group <18 (95% CI, 0.26-1.73) (p < .001)",
            "OS": "17 months (95% CI, 11.63-22.36) vs 2 months (95% CI, 0.68-3.32) for the ALI group >18 and <18, respectively (p < .001)",
            "ORR": "None",
            "AE": "None",
            "Other": "High ALI score represented an independent risk factor for better OS, also including ECOG PS and PD-L1 expression [HR 0.38; (95% CI, 0.15\u20130.96), p = .042], while showing a trend towards longer disease progression [HR 0.52; (95% CI, 0.26-1.02), p = .058]",
            "Conclusion": "In our experience, ALI value >18 is associated with better prognosis in patients treated with ICIs alone and in combination with chemotherapy. Further analyses are warranted to validate the prognostic relevance of the ALI value in patients with HNSCC undergoing ICIs."
        },
        {
            "Title": "Long term results of phase 3 randomized study evaluating the addition of low dose nivolumab to palliative chemotherapy in head and neck cancer.",
            "Indication": "Head and neck cancer",
            "Drug": "Low dose nivolumab in addition to palliative chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "Personalized circulating tumor (ct)DNA for monitoring disease status in HPV-negative head and neck squamous cell carcinoma",
            "Indication": "HPV-negative head and neck squamous cell carcinoma",
            "Drug": "None",
            "PFS": "None",
            "OS": "1-year overall survival was 83.7% for ctDNA positive vs. 100% for ctDNA negative",
            "ORR": "None",
            "AE": "None",
            "Other": "Disease-free survival was significantly worse for pts who were ctDNA positive vs. negative after treatment (HR 4.35, 95% CI: 1.68-11.21, p < 0.01)",
            "Conclusion": "Tumor-informed, personalized ctDNA testing is feasible among pts with non-virally mediated HNSCC. ctDNA positivity as an early indicator of MRD positivity post-treatment was associated with inferior survival, identifying a high-risk subgroup. Further research is warranted to understand whether ctDNA may be leveraged to guide therapy and improve outcomes for HNSCC."
        },
        {
            "Title": "The combination of patient-specific tumor and HPV sequencing to enable high-sensitivity detection of ctDNA in patients with HPV-associated oropharyngeal carcinoma",
            "Indication": "HPV-associated oropharyngeal carcinoma",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The combined detection of personalized tumor-specific alterations and HPV provides a highly sensitive approach for detecting low disease burden in early stage HPV-associated OPC patients before and during treatment.",
            "Conclusion": "The combined detection of personalized tumor-specific alterations and HPV provides a highly sensitive approach for detecting low disease burden in early stage HPV-associated OPC patients before and during treatment."
        },
        {
            "Title": "Neoadjuvant immunotherapy for oropharynx cancer: Correlative studies and long-term outcomes from the CIAO trial",
            "Indication": "Oropharynx cancer",
            "Drug": "Durvalumab (durva) with or without tremelimumab (treme)",
            "PFS": "None",
            "OS": "None",
            "ORR": "The OR rate per RECIST was 43%",
            "AE": "None",
            "Other": "Responders tended to be of younger age (p=0.02). Baseline tumor mutation burden was not associated with response, but mutational contraction post-treatment was more often observed among responders (p=0.02). A low inferred tumor neutrophil-to-leukocyte ratio was associated with response (p=0.01). Serum HPV levels significantly decreased in responders after treatment (p=0.02). With a median follow-up from surgery of 59.6 months, none of the 20 patients with newly diagnosed OPC had recurrence and all remain alive at the last contact date (DFS=100%). Of the 9 patients with recurrent OPC, 3 had subsequent distant recurrence and died of disease (median DFS=5.7 years, overall DFS=66.7%).",
            "Conclusion": "Response to neoadjuvant checkpoint inhibitors was associated with tumor mutational contraction and a low baseline neutrophil-to-leukocyte ratio in OPC. Eight TCR motifs were enriched in responders. Serum HPV level dynamics was associated with tumor burden and may be useful in response monitoring. DFS outcomes were favorable in both newly diagnosed and recurrent cohorts."
        },
        {
            "Title": "Genomic characteristics of exceptional responders to treatment with PD-1 inhibitors in refractory/metastatic head and neck squamous cell carcinoma.",
            "Indication": "Refractory/metastatic head and neck squamous cell carcinoma (HNSCC)",
            "Drug": "PD-1 inhibitors (pembrolizumab, nivolumab)",
            "PFS": "25 months (95% CI, 20mo-ongoing)",
            "OS": "33 months (95% CI, 28mo\u2013ongoing)",
            "ORR": "Exceptional responders: 20 with CR, 9 with PR, 7 with SD",
            "AE": "None",
            "Other": "PD-L1 average score: 36 (95% CI, 22-49); Average TMB: 19 mut/mb (range, 0-90 mut/mb); Most frequently mutated genes: TP53 (75%), PIK3CA (42%), CDKN2A (36%), EGFR (33%), TERT (25%), BRCA2 (22%), ERBB2 (22%), HNF1A (22%), MLL2 (22%), NOTCH1 (22%), RAD21 (22%)",
            "Conclusion": "Higher TMB and single gene mutations such as TP53, PIK3CA, CDKN2A, EGFR, and TERT are frequently detected in exceptional responders and may provide future direction into the investigation of predictors of response to ICIs."
        },
        {
            "Title": "Molecular analysis of surgical margins with NGS to assess microscopic residual disease in early oral squamous cell carcinomas.",
            "Indication": "Early oral squamous cell carcinomas (OSCC)",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Positive molecular margins observed in 17.3% of cases; Molecularly driven treatments seemed to lower the 5-year local recurrence rate from 14.1% to 6.4% (p=0.15); Positive molecular results (TGS or dPCR) with at least one positive margin observed in 19.4% of patients; Significant benefit for local control at 5 years for patients with TGS-dPCR negative results in all margins compared to pathologic negative margins (91.7% versus 64.1%, p=0.02)",
            "Conclusion": "Molecular analysis of resection margins for early OSCC leads to a better evaluation of MRD and personalized postoperative decision making to improve local control."
        },
        {
            "Title": "Multi-feature next-generation liquid biopsy for diagnosis and prognosis in HPV-associated head and neck cancer",
            "Indication": "HPV-associated head and neck squamous cell carcinoma (HPV+HNSCC)",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Sensitivity: 98.7%, Specificity: 98.7%, Diagnostic accuracy: 0.974. Detection of prognostic features including high-risk HPV16 SNPs, viral integration events, and PIK3CA mutations.",
            "Conclusion": "HPV-DeepSeek has improved diagnostic accuracy relative to ddPCR, HPV serology, and clinical work-up at the time of diagnosis and demonstrates detection of prognostic features."
        },
        {
            "Title": "Development and validation of an assay to quantify plasma cell-free human papillomavirus DNA for 13 high-risk types that cause 98% of HPV-positive cancers",
            "Indication": "HPV-positive cancers",
            "Drug": "None",
            "PFS": "cfHPV above the median was associated with worse progression-free survival (HR=2.26, 95% CI 1.23-4.17, p=0.009) in univariate analysis. However, this was no longer significant after adjustment for age, T stage, and M stage (HR=1.78, 95% CI 0.95-3.35, p=0.07).",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Sensitivity: 91.1%, Specificity: 97.7%. High sensitivity, accuracy, precision, linearity, HPV type-specificity, and robustness.",
            "Conclusion": "CF13 had excellent clinical sensitivity and specificity for a diagnosis of HPV-positive OPC."
        },
        {
            "Title": "Can TTMV clearance predict recurrence in HPV HNSCC?",
            "Indication": "Locally advanced HPV+ head and neck squamous cell carcinoma (HPV+HNSCC)",
            "Drug": "Induction chemotherapy (IC) followed by standard (sd) or reduced (rd) chemoradiation (CRT)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "TTMV clearance after 1 cycle of IC: 39% of patients remained disease-free. 27% with persistently elevated TTMV after cycle 1 relapsed.",
            "Conclusion": "IC followed by rapid clearance of TTMV in 39% of patients, and all remained disease-free. This small study was not large enough to be practice-changing but is hypothesis-generating and should be tested in a larger trial."
        },
        {
            "Title": "TTMV and association with relapse in patients with HPV related SCCHN undergoing CRT",
            "Indication": "Locally advanced HPV+ squamous cell carcinoma of the head and neck (SCCHN)",
            "Drug": "Cisplatin with CRT",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "TTMV testing during CRT: 7/14 had elevated TTMV, 7/14 had negative TTMV. Among 19/21 patients with post-CRT negative TTMV, only 2 recurrences were reported.",
            "Conclusion": "TTMV can identify recurrence or persistence and can synergize with PET to guide therapy. Validation in a larger cohort is necessary to confirm the efficacy of combining TTMV with PET for response assessment."
        },
        {
            "Title": "Photoimmunotherapy in nasopharyngeal carcinoma recurrence",
            "Indication": "Recurrent nasopharyngeal carcinoma",
            "Drug": "Cetuximab sarotalocan sodium",
            "PFS": "None",
            "OS": "Overall survival rate at 1 year was 88.2%",
            "ORR": "14 patients with CR, 3 with PR, and 1 with SD",
            "AE": "None",
            "Other": "3 patients developed distant metastases after the start of photoimmunotherapy",
            "Conclusion": "The outcomes of the photoimmunotherapy demonstrated successful local control of recurrent nasopharyngeal carcinoma. This innovative therapeutic approach holds significant potential for addressing nasopharyngeal cancer recurrence. However, it is crucial to carefully evaluate patients who can safely be treated using this method."
        },
        {
            "Title": "Neoadjuvant tislelizumab plus chemotherapy followed by salvage surgery and adjuvant tislelizumab for recurrent head and neck squamous cell carcinoma after radiotherapy: A single-arm, phase II trial",
            "Indication": "Recurrent head and neck squamous cell carcinoma",
            "Drug": "Tislelizumab, albumin-bound paclitaxel, cisplatin",
            "PFS": "None",
            "OS": "1-year OS was 82.5%",
            "ORR": "ORR of 35.3% (12/34) and DCR of 94.1% (32/34)",
            "AE": "Grade 1-2 adverse events with an incidence greater than 10% include 94.1% alopecia, 38.2% fatigue, 35.3% anorexia, 17.6% hypothyroidism, and 14.7% anemia. Grade 3-4 adverse events occurred in 1 pt (2.9%) with G3 hyperglycemia.",
            "Other": "MPR rate 19.2% (5/26), pCR rate 15.4% (4/26). 1-year EFS was 63.4%.",
            "Conclusion": "Neoadjuvant and adjuvant tislelizumab with salvage surgery is well tolerated. The 1-year EFS and OS compared favorably with historical data. These encouraging results warrant further investigations."
        },
        {
            "Title": "A prospective multicenter trial of dose escalation for weekly gemcitabine concurrent with intensity-modulated radiotherapy following cisplatin and gemcitabine induction chemotherapy in patients with locally advanced nasopharyngeal carcinoma",
            "Indication": "Locally advanced nasopharyngeal carcinoma",
            "Drug": "Gemcitabine, cisplatin",
            "PFS": "None",
            "OS": "None",
            "ORR": "Complete response rate of 100%",
            "AE": "DLT observed in two out of six patients receiving a dosage level of 300 mg/m\u00b2, with both cases presenting Grade 3 oral mucositis. Another case with Grade 3 radiation dermatitis and oral mucositis at the same dosage level. No significant hematological toxicity or serious gastrointestinal adverse events were observed.",
            "Other": "MTD for weekly gemcitabine with IMRT following induction chemotherapy in LANPC is determined to be 200 mg/m\u00b2. The DLT associated with this treatment regimen primarily consists of radiation dermatitis and/or oral mucositis.",
            "Conclusion": "The MTD for weekly gemcitabine with IMRT following induction chemotherapy in LANPC is determined to be 200 mg/m\u00b2. The DLT associated with this treatment regimen primarily consists of radiation dermatitis and/or oral mucositis."
        },
        {
            "Title": "Induction-concurrent versus concurrent-adjuvant chemoradiotherapy in patients with high-risk N2\u20133 nasopharyngeal carcinoma: An open-label, randomised, controlled, phase 3 trial.",
            "Indication": "High-risk N2\u20133 nasopharyngeal carcinoma",
            "Drug": "Paclitaxel liposome, cisplatin, fluorouracil",
            "PFS": "3-year PFS: 73.4% (95% CI 65.9%-79.5%) in induction-concurrent group vs. 69.8% (95% CI 62.0%-76.2%) in concurrent-adjuvant group (HR 0.85, [95% CI 0.58-1.26], P=0.43)",
            "OS": "None",
            "ORR": "None",
            "AE": "Acute grade 3+ AEs: leukopenia (33.1% vs. 33.1%), neutropenia (31.9% vs. 22.5%), mucositis (29.4% vs. 29.6%); Late grade 3+ AEs: auditory or hearing loss (6.3% vs. 8.5%)",
            "Other": "3-year distant metastasis-free survival: 81.4% (95% CI, 74.5%\u201386.6%) in induction-concurrent group vs. 71.0% (95% CI, 63.3%\u201377.3%) in concurrent-adjuvant group (HR, 0.64; 95% CI, 0.42\u20130.98; p=0.04)",
            "Conclusion": "Induction-concurrent chemotherapy does not significantly improve PFS compared with concurrent-adjuvant chemotherapy in high-risk N2\u20133 NPC. Long-term follow-up is required to determine long-term efficacy and toxicities."
        },
        {
            "Title": "Neoadjuvant tislelizumab with afatinib for locally advanced head and neck squamous cell carcinoma (neoCHANCE-1): An open-label, single-arm, phase 2 study.",
            "Indication": "Locally advanced head and neck squamous cell carcinoma",
            "Drug": "Tislelizumab, afatinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "Overall response rate: 48% (12/25, 95% CI: 28%\uff0d69%); Disease control rate: 100% (25/25, 95% CI: 86%\uff0d100%)",
            "AE": "Common grade 3\uff0d4 AEs: diarrhea (20%), hypokalemia (16%), rash (12%)",
            "Other": "Major pathological response (MPR): 35% (95% CI, 16%\uff0d57%); Complete pathological response of primary tumor: 17% (95% CI, 5%\uff0d39%)",
            "Conclusion": "This study underscores encouraging antitumor activity, manageable toxicity profile, and promising immune activation elicited by neoadjuvant tislelizumab plus afatinib for HNSCC, which deserves further assessment."
        },
        {
            "Title": "Sequential chemoradiotherapy versus induction chemotherapy plus concurrent chemoradiotherapy for locoregionally advanced nasopharyngeal carcinoma: A multicentre, open-label, non-inferiority, randomised, phase 3 trial.",
            "Indication": "Locoregionally advanced nasopharyngeal carcinoma",
            "Drug": "Gemcitabine, cisplatin",
            "PFS": "3-year FFS: 84.0% in SCRT group vs. 79.8% in IC+CCRT group (HR 0.804, 95% CI, 0.510 to 1.266, log-rank P=0.344)",
            "OS": "3-year OS: 97.4% in SCRT group vs. 94.5% in IC+CCRT group (HR 0.413, 95% CI, 0.159 to 1.076, log-rank P=0.061)",
            "ORR": "None",
            "AE": "Grade \u22653 acute non-hematological AEs: mucositis (29.0% vs. 41.9%, P<0.001), nausea (9.5% vs. 18.1%, P=0.011), vomiting (3.8% vs. 9.5%, P=0.019); Grade \u22653 acute hematological AEs: thrombocytopenia, leukopenia",
            "Other": "3-year locoregional control: 91.7% in SCRT group vs. 88.8% in IC+CCRT group (HR 0.767, 95% CI, 0.420 to 1.401, log-rank P=0.386); 3-year distant metastasis-free survival: 93.6% in SCRT group vs. 91.5% in IC+CCRT group (HR 0.756, 95% CI, 0.376 to 1.520, log-rank P=0.430)",
            "Conclusion": "For LA-NPC, SCRT was not inferior in 3-year FFS to IC+CCRT. It might be an alternative treatment for LA-NPC patients with fewer severe non-hematological AEs during IMRT."
        },
        {
            "Title": "Induction chemotherapy effects on very advanced (T3/T4) human papillomavirus-related oropharyngeal cancer for participation in hypoxia-directed major de-escalation (30Gy) definitive treatment trial.",
            "Indication": "Human papillomavirus-related oropharyngeal cancer (HPV+ OPC)",
            "Drug": "Carboplatin, Paclitaxel, Cetuximab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "2-year local control rate in 20 evaluable patients",
            "Conclusion": "Preliminary results suggest that ICT can allow more advanced tumors (T3/T4), that are typically excluded from de-escalation studies, to be de-escalated and may eliminate tumor hypoxia in a large proportion of cases, but a larger study is needed to confirm these results. Further follow-up of outcomes (2-year local control rate) is still needed."
        },
        {
            "Title": "Neoadjuvant immunochemotherapy for locally advanced hypopharyngeal squamous cell carcinoma: Pooled results analysis from two prospective single-arm phase 2 trials.",
            "Indication": "Locally advanced hypopharyngeal squamous cell carcinoma (HPSCC)",
            "Drug": "PD-1 inhibitors, Taxanes, Platinum-based chemotherapy",
            "PFS": "Median PFS not reached; 1-year PFS rate: 74.2%",
            "OS": "Median OS not reached; 1-year OS rate: 86%",
            "ORR": "63.2% (43/68)",
            "AE": "Grade 3 AEs: 30.9%, mainly neutropenia (20.6%)",
            "Other": "1-year laryngeal function preservation rate: 93.9%; Plasma protein expression of GZMH, GZMA, KLRD1, IL12RB1, CD244, NCR1, CXCL10, CXCL13, LAG3, IL10, MCP-3, CCL20, CD8A, TWEAK, and HGF at baseline was significantly lower in patients with tumor shrinkage \u226550%",
            "Conclusion": "PD-1 inhibitors combined with taxanes and platinum-based chemotherapy are well tolerated and effective in the neoadjuvant treatment of locally advanced HPSCC. The discordance between radiological and pathological assessments after neoadjuvant immunotherapy deserves further exploration, which requires a more precise method to screen the appropriate treatment population. Alterations in plasma proteins may be promising biomarkers for predicting treatment response in the future."
        },
        {
            "Title": "Nivolumab combined with radical chemoradiotherapy sparing concurrent cisplatin in high-risk locoregionally advanced nasopharyngeal carcinoma (PLATINUM): An investigator-initiated, open-label, multicenter, single-arm, phase II clinical trial.",
            "Indication": "High-risk locoregionally advanced nasopharyngeal carcinoma",
            "Drug": "Nivolumab",
            "PFS": "None",
            "OS": "3-year overall survival was 98.0%",
            "ORR": "None",
            "AE": "60 (40.2%) patients had grade 3\u20134 acute treatment-related adverse events; 11 (7.2%) were associated with potential immunologic causes",
            "Other": "3-year failure-free survival was 88.8% (95% CI, 83.9\u201394.0%)",
            "Conclusion": "Nivolumab incorporated into induction chemotherapy followed by radiotherapy has a promising efficacy and low toxicity for high-risk LANPC patients. A phase 3 non-inferior randomized clinical trial assessing PD-1 blockade plus this de-intensified radical chemoradiotherapy is underway."
        },
        {
            "Title": "Recent safety and efficacy findings from a phase 1b/2 open-label combination study of ASP-1929 photoimmunotherapy with anti-PD-1 therapy in EGFR-expressing advanced head and neck squamous cell carcinoma (HNSCC).",
            "Indication": "EGFR-expressing advanced head and neck squamous cell carcinoma",
            "Drug": "ASP-1929 photoimmunotherapy with anti-PD-1 therapy (pembrolizumab)",
            "PFS": "Median PFS: 2.9 months (95% CI 1.4-14.6)",
            "OS": "Median OS not reached; 24-month OS rate estimate was 52.4% (95% CI 25.9-73.4)",
            "ORR": "33.3% (95% CI 13.3-59.0), including 4 CRs (22.2%) and 2 PRs (11.1%)",
            "AE": "Most common serious adverse reactions were dysphagia (10.5%) and tongue edema (10.5%). There were two grade 4 events: laryngeal edema (PIT-related) and tumor hemorrhage due to advanced disease, and no fatal events.",
            "Other": "Median DOR was not reached at data cut-off (range 2.8+ to 18.0+ months)",
            "Conclusion": "ASP-1929 PIT in combination with anti-PD-1 therapy was generally well tolerated. Initial data demonstrate promising overall survival and response rates with this combination therapy in pts with rLA and/or mHNSCC lacking LR treatment options."
        },
        {
            "Title": "Neoadjuvant and adjuvant toripalimab for high-risk locoregionally advanced nasopharyngeal carcinoma: A randomized, double-blind, placebo-controlled phase 2 trial.",
            "Indication": "High-risk locoregionally advanced nasopharyngeal carcinoma",
            "Drug": "Toripalimab",
            "PFS": "Progression-free survival at 2 years was 91.8% in the toripalimab group and 73.9% in the placebo group (HR for disease progression or death = 0.33; 95% CI, 0.15 to 0.76; P = 0.006)",
            "OS": "Overall survival was longer in the toripalimab group than in the placebo group (100% vs. 94.0% of patients alive at 2 years; HR for death, 0.10; 95% CI, 0.01 to 0.82)",
            "ORR": "None",
            "AE": "73.7% of toripalimab group participants and 68.0% of placebo group participants had grade 3 or higher treatment-related adverse events. No deaths related to toripalimab therapy occurred.",
            "Other": "Distant metastasis\u2013free survival at 2 years was 92.8% and 80.0% (HR for distant metastasis or death, 0.39; 95% CI, 0.16 to 0.96); locoregional recurrence\u2013free survival at 2 years was 99.0% and 82.0% (HR for locoregional recurrence or death, 0.09; 95% CI, 0.02 to 0.41)",
            "Conclusion": "In patients with high-risk locoregionally advanced nasopharyngeal carcinoma, compared with concurrent chemoradiotherapy, neoadjuvant toripalimab combined with concurrent chemoradiotherapy and adjuvant toripalimab significantly improved progression-free survival, overall survival, locoregional recurrence\u2013free survival, and distant metastasis\u2013free survival."
        },
        {
            "Title": "Induction chemotherapy followed by concurrent chemoradiotherapy combined with toripalimab and Endostar in high-risk locally advanced nasopharyngeal carcinoma: A multicenter, randomized, phase 2 trial.",
            "Indication": "High-risk locally advanced nasopharyngeal carcinoma",
            "Drug": "Toripalimab and Endostar",
            "PFS": "Significantly improved in the IC-CCRT+TE group compared to the IC-CCRT group (HR=0.36, p=0.041)",
            "OS": "None",
            "ORR": "28 patients (52.8%) in the IC-CCRT+TE group and 5 patients (9.4%) in the IC-CCRT group achieved complete response (CR) after induction chemotherapy",
            "AE": "Grade \u22653 acute adverse events: 66.0% in the IC-CCRT+TE group and 62.3% in the IC-CCRT group; Grade \u22653 late adverse events: 5.8% in the IC-CCRT+TE group and 5.7% in the IC-CCRT group; Grade \u22653 immune-related adverse events: 4.7%",
            "Other": "None",
            "Conclusion": "IC-CCRT combined with Toripalimab and Endostar significantly improved PFS in patients with high-risk locally advanced nasopharyngeal carcinoma, and the adverse events are manageable."
        },
        {
            "Title": "Relationship between infiltration of tissue-resident memory T cells (TRM) in Neoadjuvant immunochemotherapy and new radiomic predictor for resectable locally advanced oral squamous cell carcinoma.",
            "Indication": "Locally advanced oral squamous cell carcinoma (LAOSCC)",
            "Drug": "Camrelizumab, albumin paclitaxel, cisplatin",
            "PFS": "None",
            "OS": "None",
            "ORR": "80.8% (21/26) with 15.4% (4/26) complete response, 65.4% (17/26) partial response",
            "AE": "7.6% (2/26) experienced grade 3 or 4 adverse events, including 3.8% (1/26) grade 4 neutropenia and 3.8% (1/26) grade 3 thrombocytopenia",
            "Other": "MPR rate was 65.4% (17/26), including a 34.6% (9/26) pathological complete response. Patients with PD-L1 (Combined Positive Score) \u2265 1 had higher MPR (80.0%, 16/20) than those with PD-L1 < 1 (16.7%, 1/6). Mean cell diameter of the tumor, derived from OGSE sequences, emerged as a potential new radiomic marker for predicting NAIC efficacy.",
            "Conclusion": "Camrelizumab combined with albumin paclitaxel/cisplatin as NAIC for LAOSCC demonstrates promising MPR and well safety. Mean cell diameter of tumor, derived from OGSE sequences, emerges as a potential new radiomic marker for predicting NAIC efficacy. Ongoing follow-ups on long-term survival and the development of a bio-radiomic prediction model integrating pathological features with OGSE data are underway."
        },
        {
            "Title": "Clinical validation of a mesenchymal gene expression signature for prognosis and treatment decision making in early-stage oral cavity squamous cell carcinoma.",
            "Indication": "Early-stage oral cavity squamous cell carcinoma (OCSCC)",
            "Drug": "None",
            "PFS": "None",
            "OS": "HR = 3.24, p = 0.0019 (univariate); HR = 3.26, p = 0.0026 (multivariable)",
            "ORR": "None",
            "AE": "None",
            "Other": "The MS subtype was strongly associated with inferior survival in both univariate and multivariable analyses, confirming a potential role for gene expression-based subtyping as a biomarker for prognostication and informing clinical treatment decisions.",
            "Conclusion": "These findings confirm that the MS subtype is associated with significantly worse survival in surgically resected, early-stage OCSCC which typically demonstrate favorable outcomes. Also, these findings strongly suggest value in gene expression subtyping for prognostication and treatment decision-making in OCSCC patients and warrant the further development of the 88-gene classifier as a diagnostic test for this clinically actionable patient population."
        },
        {
            "Title": "Dosimetry of lutetium-177-PSMA-I&T radioligand therapy in recurrent/metastatic adenoid cystic carcinoma.",
            "Indication": "Recurrent/metastatic adenoid cystic carcinoma (AdCC)",
            "Drug": "Lutetium-177 (177Lu)-PSMA-I&T",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Tumor-absorbed radiation doses were significantly lower than in prostate cancer (~10-40 Gy per cycle), likely due to lower and heterogeneous PSMA expression.",
            "Conclusion": "Tumor-absorbed radiation doses in our AdCC patient cohort receiving 177Lu-PSMA-I&T were significantly lower than in prostate cancer (~10-40 Gy per cycle), likely due to lower and heterogeneous PSMA expression. This could explain the limited efficacy. Organ-absorbed doses aligned with studies in prostate cancer."
        },
        {
            "Title": "Long-term efficacy and safety of larotrectinib (laro) in patients (pts) with TRK fusion thyroid carcinoma (TC).",
            "Indication": "TRK fusion thyroid carcinoma (TC)",
            "Drug": "Larotrectinib (laro)",
            "PFS": "Median progression-free survival was 44.0 months (95% CI 16.6-NE) at a median follow-up of 38.7 months.",
            "OS": "Median overall survival (OS) was not reached (NR; 95% CI 48.7-NE) at a median follow-up of 58.0 months; the 48-month OS rate was 72% (95% CI 56-89). Median OS was NR (95% CI 56.3-NE) in DTC and 8.8 months (95% CI 2.6-NE) in ATC.",
            "ORR": "Overall response rate (ORR) was 65% (95% CI 45-81): 3 (10%) complete responses, 17 (55%) partial responses (PR), 5 (16%) stable disease (SD) (4 for >30 months), 4 (13%) progressive disease (PD), and 2 (6%) not evaluable.",
            "AE": "Treatment-related adverse events (TRAEs) were predominantly Grade 1/2. Grade 3/4 TRAEs were reported in 3 (10%) pts. There were no treatment discontinuations due to TRAEs.",
            "Other": "None",
            "Conclusion": "Larotrectinib continues to demonstrate rapid and durable responses, extended survival, and a favorable safety profile in pts with TRK fusion DTC. Limited single-agent activity was observed in ATC. These results support the wider adoption of NGS panels, which include NTRK gene fusions in pts with advanced TC, to identify those who may benefit from targeted treatment."
        },
        {
            "Title": "Safety of tabelecleucel with pembrolizumab in recurrent/metastatic Epstein\u2013Barr virus-associated nasopharyngeal carcinoma.",
            "Indication": "Recurrent/metastatic Epstein\u2013Barr virus-associated nasopharyngeal carcinoma (RM EBV+ NPC)",
            "Drug": "Tabelecleucel (tab-cel) with pembrolizumab (pembro)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "No dose-limiting toxicities or fatal treatment-emergent adverse events (TEAEs) were identified. All 12 subjects reported \u22651 TEAE; the most common TEAEs reported by \u22654 subjects were disease progression (8 subjects, 66.7%), myalgia (5 subjects, 41.7%), pyrexia, arthralgia, and hyponatremia (4 subjects, 33.3%, each). No \u2265grade 3 TEAEs were considered related to tab-cel or pembro. No TEAEs considered related to tab-cel or pembro led to study discontinuation.",
            "Other": "The best response seen was stable disease in 6 of 12 pts (50%).",
            "Conclusion": "Phase 1b data demonstrated combination therapy of tab-cel and pembro to be well tolerated and identified a recommended phase 2 dose in subjects with platinum-pretreated RM EBV+ NPC. The study was discontinued after the phase 1b portion due to an evolving immunotherapy treatment landscape for this disease, which would need to be considered for further clinical development."
        },
        {
            "Title": "A phase 1 clinical trial of DB-020 intratympanic injections administered prior to high-dose cisplatin chemotherapy to reduce ototoxicity.",
            "Indication": "Hearing loss due to cisplatin chemotherapy",
            "Drug": "DB-020",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Acute or temporary ear pain (82%), tinnitus (50%)",
            "Other": "Ototoxicity (250\u20138000Hz): 85% in placebo ears vs 40% in DB-020 ears (p=0.0027); Ototoxicity (9000\u201316000Hz): 90% in placebo ears vs 60% in DB-020 ears (p=0.0143); Severe ototoxicity (250\u20138000Hz): 70% in placebo ears vs 15% in DB-020 ears (p=0.0009); Severe ototoxicity (9000\u201316000Hz): 80% in placebo ears vs 35% in DB-020 ears (p=0.0027); Acoustic threshold shift (250\u20138000Hz): 30.22 dB in placebo ears vs 7.99 dB in DB-020 ears (p<0.0001); Acoustic threshold shift (9000\u201316000Hz): 21.38 dB in placebo ears vs 9.19 dB in DB-020 ears (p=0.0022)",
            "Conclusion": "In this initial clinical trial experience, there was no significant safety signal, and DB-020 IT injections showed a meaningful reduction in CP-induced ototoxicity."
        },
        {
            "Title": "Survival outcomes of concurrent radiotherapy and toripalimab versus radiotherapy alone for elderly patients with postoperative head and neck squamous cell carcinoma unfit for cisplatin.",
            "Indication": "Locally advanced head and neck squamous cell carcinoma (HNSCC)",
            "Drug": "Toripalimab",
            "PFS": "2-year DFS: 55.3% with RT+PD-1 vs 51.2% with RT alone (p=0.866)",
            "OS": "2-year OS: 63.3% with RT+PD-1 vs 78.4% with RT alone (p=0.557)",
            "ORR": "None",
            "AE": "None",
            "Other": "2-year DFS in patients with CPS \u226520%: 72.2% vs 53.8% in patients with CPS <20% (p=0.412)",
            "Conclusion": "The primary objective of prolonging DFS with concurrent radiotherapy and toripalimab in elderly patients unfit for cisplatin with locally advanced HNSCC was not met. For patients with CPS \u226520, further study is still warranted to evaluate the benefit of toripalimab in this patient setting. Biomarker analysis is still ongoing."
        },
        {
            "Title": "Patients with radioiodine-refractory differentiated thyroid cancer (RAI-R DTC) with BRAF V600E and/or K601E mutation status: A real-world view of effectiveness of lenvatinib monotherapy.",
            "Indication": "Radioiodine-refractory differentiated thyroid cancer (RAI-R DTC)",
            "Drug": "Lenvatinib",
            "PFS": "Estimated 24-month rwPFS rates: 74.1% (BRAF-mutated), 61.7% (WT), 69.8% (BRAF untested)",
            "OS": "Median rwOS not reached for BRAF-mutated and WT tumors; median OS 54.2 months for BRAF untested tumors. Estimated rwOS rates at 12 and 48 months: 93.2% and 83.9% (BRAF-mutated), 90.6% and 68.5% (WT), 90.2% and 72.5% (BRAF untested)",
            "ORR": "Provider-reported overall response rate: 76.4% (BRAF-mutated), 75.0% (WT), 69.3% (BRAF untested)",
            "AE": "None",
            "Other": "Kaplan-Meier estimation for lenvatinib treatment discontinuation at 24 months: 67.5% (BRAF-mutated), 60.0% (WT), 65.3% (BRAF untested)",
            "Conclusion": "In this US real-world experience, the effectiveness of lenvatinib is consistent across a diverse cohort of RAI-R DTC patients with BRAF-mutated, WT, and BRAF untested tumors. This has implications for the first-line use of lenvatinib in BRAF mutated patients."
        },
        {
            "Title": "Clinical outcomes of NOTCH pathway-activated adenoid cystic carcinoma with and without co-occurrent TP53 mutation",
            "Indication": "Adenoid cystic carcinoma (ACC)",
            "Drug": "None",
            "PFS": "None",
            "OS": "Median OS from inoperable LA-R/M disease: NOTCHgof/TP53mt (10.0 mo; 95% CI 8\u201312), NOTCHgof/TP53wt (15.2 mo; 95% CI 8\u201322), NOTCHwt/TP53wt (58.6 mo; 95% CI 52\u201365), NOTCHwt/TP53mt (69 mo; 95% CI 13\u2013125) (p=0.001)",
            "ORR": "None",
            "AE": "None",
            "Other": "Grade 3 tumors with predominantly solid histologic pattern pose higher risk of recurrence compared to grade 1 tubular histologic patterns (HR 3.69, 95% CI 1.15-11.88, p=0.028)",
            "Conclusion": "Survival among patients with adenoid cystic carcinoma after locoregional recurrence is significantly better compared to those experiencing distant metastasis. Tumor grade is most predictive of recurrence. Distant metastases may emerge over the course of many years. These data may inform the development of disease-specific surveillance guidelines and novel salvage treatment paradigms."
        },
        {
            "Title": "Efficacy and safety of anlotinib combined with sintilizumab in locally advanced or metastatic anaplastic thyroid carcinoma",
            "Indication": "Anaplastic thyroid carcinoma (ATC)",
            "Drug": "Anlotinib and Sintilizumab",
            "PFS": "Median PFS: 7.5 months (0.5 to 56.3 months) for all patients, 5.7 months (1.0 to 13.1 months) for patients with BRAF V600E mutation",
            "OS": "1-year OS rate: 61.1%, Median OS: 12.9 months (0.6 to 56.3 months)",
            "ORR": "44.4% for all patients, 80.0% for patients with BRAF V600E mutation",
            "AE": "Grade III/IV toxicities in 4 of 18 patients, necessitating dose reduction/discontinuation of anlotinib",
            "Other": "Best overall response: 5.6% complete remission, 38.9% partial remission, 33.3% stable disease, 22.2% progressive disease",
            "Conclusion": "The combination of anlotinib and sintilizumab demonstrated promising activity and manageable toxicity in patients with locally advanced or metastatic ATC, suggesting its potential as a viable therapeutic option for this patient population."
        },
        {
            "Title": "Axitinib plus avelumab for recurrent/metastatic adenoid cystic carcinoma (R/M ACC): Biomarker analysis of the phase II trial",
            "Indication": "Recurrent/metastatic adenoid cystic carcinoma (R/M ACC)",
            "Drug": "Axitinib and Avelumab",
            "PFS": "Median PFS: 7.3 months",
            "OS": "None",
            "ORR": "18%",
            "AE": "None",
            "Other": "Higher TMB associated with worse PFS. Four mutational signatures correlated with worse PFS, including SBS86. Higher neutrophil-to-lymphocyte ratio and more T follicular helper cells associated with worse PFS. Presence of cytotoxic cells, T effector memory cells, and mast cells associated with longer PFS. 167-gene predictive signature developed and validated. IMC single-cell immune mapping revealed associations with PFS. Microbiome analysis identified bacterial genera associated with therapy outcomes.",
            "Conclusion": "Correlative analysis of the phase II axitinib plus avelumab trial revealed potential biomarkers predictive of combination clinical benefit in ACC, including a gene-expression signature. These findings may guide patient stratification for combinatorial therapy."
        },
        {
            "Title": "The efficacy and safety of ulinastatin in the prevention and treatment of radiotherapy-induced oral mucositis in locoregionally advanced nasopharyngeal carcinoma (LA-NPC): A multicenter, open-label, randomized controlled clinical trial.",
            "Indication": "Locoregionally advanced nasopharyngeal carcinoma (LA-NPC)",
            "Drug": "Ulinastatin (UTI)",
            "PFS": "3-year PFS: UTI group 89.9% vs. Control group 85.1% (P > 0.05)",
            "OS": "3-year OS: UTI group 96.5% vs. Control group 94.3% (P > 0.05)",
            "ORR": "None",
            "AE": "No UTI related adverse events observed",
            "Other": "Incidence of grade \u22653 RTOM: UTI group 25.8% vs. Control group 41.1% (P = 0.030); Severe oral pain (NRS score \u22657): UTI group 22.5% vs. Control group 36.7% (P = 0.038)",
            "Conclusion": "UTI can effectively reduce the incidence of grade \u22653 RTOM and severe oral pain without increasing adverse events and compromising survival."
        },
        {
            "Title": "Cobimetinib plus atezolizumab for RAS and NF1/2-mutated poorly differentiated thyroid carcinoma.",
            "Indication": "Poorly differentiated thyroid carcinoma (PDTC)",
            "Drug": "Cobimetinib plus atezolizumab",
            "PFS": "Median PFS: 7 months (95% CI, 2.4\u201311.6)",
            "OS": "Median OS: 23 months (95% CI, 12.8\u201333.2); OS significantly longer in patients who received RAI (32 vs 16 months; p = 0.034)",
            "ORR": "Best response: Stable disease in 6/8 (75%), Partial response in 1/8 (12.5%), Progressive disease in 1/8 (12.5%)",
            "AE": "Expected adverse event profile, specific details not provided",
            "Other": "None",
            "Conclusion": "Combination of cobimetinib and atezolizumab showed some clinical efficacy in patients with metastatic PDTC driven by RAS or NF mutations, although PFS was shorter than with single-agent lenvatinib."
        },
        {
            "Title": "Pyrotinib in HER2-altered advanced salivary gland carcinomas: Analysis of two cohorts from an exploratory study.",
            "Indication": "HER2-altered advanced salivary gland carcinomas (SGCs)",
            "Drug": "Pyrotinib",
            "PFS": "Median PFS: 7.6 months (range: 2.7-16.8) in R/M cohort; Estimated 2-years PFS: 75% in LA cohort",
            "OS": "Estimated 2-years OS: 100% in LA cohort",
            "ORR": "R/M cohort: ORR 90% (2 CRs, 7 PRs, 1 PD); Median DOR: 5.7 months (range: 3.1-13.7)",
            "AE": "Most common adverse event was diarrhea (61.1% of patients, 16.7% grade III)",
            "Other": "None",
            "Conclusion": "Pyrotinib showed promising antitumor activity in recurrent/metastatic HER2-altered SGCs, and had potential to prolong survival outcome as adjuvant treatment in LA patients setting, which needs further randomized studies."
        },
        {
            "Title": "Phase II trial of regorafenib in metastatic medullary thyroid carcinoma (MTC) and radioactive iodine refractory differentiated thyroid carcinoma (RAIRDTC)",
            "Indication": "Metastatic medullary thyroid carcinoma (MTC) and radioactive iodine refractory differentiated thyroid carcinoma (RAIRDTC)",
            "Drug": "Regorafenib",
            "PFS": "{'MTC': '5.3 months (95% CI: 3.6, 20.1)', 'RAIRDTC': '11.0 months (95% CI: 1.2, 24.0)'}",
            "OS": "{'MTC': '16.1 months (95% CI: 5.2, NA)', 'RAIRDTC': '20.1 months (95% CI: 1.6, NA)'}",
            "ORR": "{'MTC': '12.5% (95% CI: 0.3, 52.7)', 'RAIRDTC': '11.1% (95% CI: 0.3, 48.2)'}",
            "AE": "Grade 3-4 TRAEs observed in 47.1% of patients, most frequent were diarrhea, hypophosphatemia, and hypertension",
            "Other": "No treatment-related deaths. Most common reason for discontinuation was disease progression.",
            "Conclusion": "Regorafenib clinical trial did not reach its primary endpoints in MTC and DTC. Analyses are planned to investigate the impact of prior VEGFR inhibitor exposure, biomarkers, and resistance mechanisms."
        },
        {
            "Title": "Efficacy of cytotoxic chemotherapy in recurrent/metastatic adenoid cystic carcinoma (ACC)",
            "Indication": "Recurrent/metastatic adenoid cystic carcinoma (ACC)",
            "Drug": "Cytotoxic chemotherapy (various regimens)",
            "PFS": "4.3 months (95% CI: 2.8-6.3 months)",
            "OS": "16 months (95% CI: 10.8-24.5 months)",
            "ORR": "12.5%",
            "AE": "None specified",
            "Other": "Disease control rate (DCR) was 56.3%. Significant difference in mOS and mPFS between solid and non-solid growth patterns.",
            "Conclusion": "In this largest to date real-world data on chemotherapy in ACC, response and survival outcomes were lower than historical data. The efficacy of chemotherapy may vary by growth pattern and other patient and tumor factors, which requires further investigation."
        },
        {
            "Title": "Safety and efficacy of AIC100 chimeric antigen receptor (CAR) T-cell therapy in patients with advanced thyroid cancers: Results from the phase 1 study",
            "Indication": "Relapsed/refractory poorly differentiated thyroid cancer (PDTC) and anaplastic thyroid cancer (ATC)",
            "Drug": "AIC100 chimeric antigen receptor (CAR) T-cell therapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "22% (1 ATC with partial response at DL2 and 1 PDTC with complete metabolic response at DL3)",
            "AE": "Grade 1/2 CRS in 60% of patients. No ICANS or other serious adverse events related to AIC100.",
            "Other": "Disease control rate (DCR) was 56%. Peripheral blood CAR T-cell expansion observed in all patients. DOTATATE PET activity correlated with CAR T-cell activity and tumor responses.",
            "Conclusion": "This first-in-human ICAM-1 targeted CAR T-cell study demonstrated an excellent safety profile and promising antitumor activity for AIC100 in patients with PDTC and ATC. These favorable outcomes support further exploration of AIC100 to optimize the RP2D and to expand for other ICAM-1-positive neoplasms."
        },
        {
            "Title": "PEMDA-HN, an open-label, phase II, randomized controlled study of danvatirsen plus pembrolizumab compared to pembrolizumab alone in first-line recurrent and/or metastatic head and neck squamous cell carcinoma (RM HNSCC).",
            "Indication": "Recurrent and/or metastatic head and neck squamous cell carcinoma (RM HNSCC)",
            "Drug": "Danvatirsen plus pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "22.6% [12.3-36.2]",
            "AE": "None",
            "Other": "Complete responses (CR, 9.4%)",
            "Conclusion": "The study aims to evaluate the first-line combination of DANVA with pembrolizumab in patients with PD-L1 positive disease. The primary endpoint is ORR by RECIST 1.1, with secondary objectives including safety, additional antitumor activity evaluation, and pharmacokinetics."
        },
        {
            "Title": "STELLAR-305: A randomized phase 2/3 study of zanzalintinib plus pembrolizumab versus pembrolizumab alone in patients with previously untreated PD-L1 positive recurrent/metastatic head and neck squamous cell carcinoma.",
            "Indication": "Previously untreated PD-L1 positive recurrent/metastatic head and neck squamous cell carcinoma (R/M HNSCC)",
            "Drug": "Zanzalintinib plus pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The study will evaluate the efficacy and safety of zanzalintinib plus pembrolizumab vs pembrolizumab alone. The dual primary endpoints are PFS per RECIST v1.1 by blinded independent radiology committee and overall survival."
        },
        {
            "Title": "A phase 1b study of tbio-4101 (autologous selected and expanded tumor-infiltrating lymphocytes [TILs]) with pembrolizumab in patients with anti-PD-1\u2013resistant, advanced head and neck squamous cell carcinoma (aHNSCC).",
            "Indication": "Anti-PD-1\u2013resistant, advanced head and neck squamous cell carcinoma (aHNSCC)",
            "Drug": "Tbio-4101 with pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The trial aims to evaluate the efficacy of TBio-4101 and pembrolizumab in patients who progress on first-line SOC therapy. The primary endpoint is investigator-assessed, confirmed ORR per RECIST v1.1."
        },
        {
            "Title": "Enfortumab vedotin and pembrolizumab as first-line treatment in recurrent or metastatic head and neck squamous cell carcinoma: A cohort of the EV-202 trial.",
            "Indication": "Recurrent or metastatic head and neck squamous cell carcinoma (R/M HNSCC)",
            "Drug": "Enfortumab vedotin and pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "23.9% with EV monotherapy in previously treated R/M HNSCC cohort",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The study hypothesizes that EV plus pembrolizumab may demonstrate benefit as a first-line therapy in patients with R/M HNSCC and CPS \u22651. The primary endpoint is investigator-assessed, confirmed ORR per RECIST v1.1."
        },
        {
            "Title": "A phase 2 study of ipatasertib in combination with pembrolizumab for first-line treatment of recurrent or metastatic squamous cell cancer of the head and neck.",
            "Indication": "Recurrent or metastatic squamous cell carcinoma of the head and neck (R/M HNSCC)",
            "Drug": "Ipatasertib and pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Changes in tumor immune cell infiltration, AKT signaling, and changes in peripheral blood immune cells",
            "Conclusion": "Accrual is ongoing"
        },
        {
            "Title": "Phase II trial assessing safety, efficacy and immune correlates of heterologous prime-boost with pBI-11 (IM) and TA-HPV (IM) plus pembrolizumab for advanced, PD-L1 CPS \u2265 1, hrHPV+ oropharyngeal cancer.",
            "Indication": "Advanced, PD-L1 CPS \u2265 1, hrHPV+ oropharyngeal cancer",
            "Drug": "pBI-11 (IM), TA-HPV (IM), and pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Immune cell and viral markers, TCR sequencing, total transcripts, HPV16/18 E6, E7 antibodies, vaccinia virus neutralizing assays, proliferative responses of peripheral blood lymphocytes to stimulation by HPV16/18 E6 and E7, HPV16/18 E6- and E7-Specific T cells, and HPV DNA in plasma",
            "Conclusion": "Data from the 1st four patients enrolled will be presented"
        },
        {
            "Title": "Biomarker-driven radiation therapy dose reduction after transoral robotic surgery for the treatment of HPV-positive oropharyngeal cancer.",
            "Indication": "HPV-positive oropharyngeal cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "1-year swallowing function as measured by the composite MD Anderson Dysphagia Index (MDADI) score, overall/progression-free survival, and other quality of life metrics",
            "Conclusion": "Accrual began in June 2022, with 18 patients enrolled as of January 2024"
        },
        {
            "Title": "eVOLVE-HNSCC: A global phase 3 study of volrustomig as sequential therapy for unresected locally advanced head and neck squamous cell carcinoma (LA-HNSCC) following definitive concurrent chemoradiotherapy (cCRT).",
            "Indication": "Unresected locally advanced head and neck squamous cell carcinoma (LA-HNSCC)",
            "Drug": "Volrustomig",
            "PFS": "Primary endpoint: PFS in pts with PD-L1-expressing tumors",
            "OS": "Secondary endpoint: OS in pts with PD-L1-expressing tumors and in all randomized pts",
            "ORR": "None",
            "AE": "None",
            "Other": "Secondary endpoints include PFS in all randomized pts, PFS2, safety, patient-reported outcomes, pharmacokinetics, and immunogenicity. Exploratory biomarker analyses will also be conducted.",
            "Conclusion": "Enrollment began in December 2023"
        },
        {
            "Title": "Novel approach of prophylactic radiation to reduce toxicities comparing 2-step 40Gy with 56Gy simultaneous integrated boost intensity-modulated radiation therapy for locally advanced squamous cell carcinoma of the head and neck: A phase III trial (JCOG1912, NEWBRIDGE).",
            "Indication": "Locally advanced squamous cell carcinoma of the head and neck (LA-SCCHN)",
            "Drug": "Concurrent cisplatin",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: Time to treatment failure (TTF); Secondary endpoints: Overall survival, complete response rate, progression-free survival, locoregional relapse-free survival, acute and late adverse events, quality of life score, and swallowing function score.",
            "Conclusion": "The study aims to confirm the non-inferiority of reduced dose prophylactic radiation with 40Gy compared to standard dose prophylactic radiation with 56Gy in terms of TTF among patients with clinical stage III-IVB LA-SCCHN."
        },
        {
            "Title": "Molecular residual disease (MRD) interception in locoregionally advanced head and neck squamous cell carcinoma (LA-HNSCC): MERIDIAN study.",
            "Indication": "Locoregionally advanced head and neck squamous cell carcinoma (LA-HNSCC)",
            "Drug": "Rilvegostomig (AZD2936)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: ctDNA clearance in MRD+ patients; Secondary objectives: Survival and safety; Exploratory analyses: ctDNA detection using other assays, quality of life assessments, health economics, and radiomics.",
            "Conclusion": "The study hypothesizes that IO (AZD2936) could induce ctDNA clearance post-definitive treatment and avoid or delay recurrence."
        },
        {
            "Title": "EFFECT-neo: A phase 3 study of pembrolizumab plus chemotherapy versus chemotherapy as neoadjuvant therapy in patients with resectable locally advanced head and neck squamous cell carcinoma.",
            "Indication": "Resectable locally advanced head and neck squamous cell carcinoma (LAHNSCC)",
            "Drug": "Pembrolizumab plus chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: Pathological complete response (pCR); Secondary endpoints: Objective response rate, 1-year and 2-year event-free survival rates, 2-year overall survival rate, functional preservation rate, safety, and Karnofsky performance status.",
            "Conclusion": "The study evaluates the efficacy and safety of pembrolizumab plus chemotherapy versus chemotherapy as neoadjuvant therapy in patients with resectable LAHNSCC."
        },
        {
            "Title": "Phase II trial of immunotherapeutic HPV vaccine PRGN-2009 with pembrolizumab before standard treatment in subjects with newly diagnosed HPV-associated oropharyngeal cancer.",
            "Indication": "Newly diagnosed HPV-associated oropharyngeal cancer",
            "Drug": "PRGN-2009 and Pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary objective: Evaluating the antitumor immune response of PRGN-2009 in combination with Pembrolizumab; Secondary endpoints: Response rate, relapse-free survival, safety and tolerability, and impact on time to initiation of definitive therapy.",
            "Conclusion": "The study investigates the biological activity of neoadjuvant PRGN-2009 and Pembrolizumab in early-stage HPV-associated oropharyngeal cancer."
        },
        {
            "Title": "TTCC-2022-01: Niraparib and dostarlimab in locally-advanced head and neck squamous cell carcinoma treated with (chemo)radiotherapy (RADIAN).",
            "Indication": "Locally-advanced head and neck squamous cell carcinoma (LA-HNSCC)",
            "Drug": "Niraparib and dostarlimab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "1-year disease free survival (DFS), safety/tolerability including RT delay/completion, locoregional and distant control, event-free survival, ctDNA dynamics correlation with efficacy endpoints",
            "Conclusion": "It is expected that experimental treatment will provide an increase of efficacy of 0.64 in terms of HR (1-year DFS of 75.9% in cohort A and 68.2% in cohort B)."
        },
        {
            "Title": "EXtRaCT study: Xevinapant with radiation and concurrent carboplatin and paclitaxel in patients ineligible for cisplatin with locoregionally advanced squamous cell carcinoma of the head and neck.",
            "Indication": "Locoregionally advanced head and neck cancer (HNC)",
            "Drug": "Xevinapant with carboplatin and paclitaxel",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Tolerability, recommended phase 2 dose, safety, response, progression-free survival, overall survival, locoregional and distant control",
            "Conclusion": "The trial aims to extend the benefits of xevinapant with carboplatin-paclitaxel-based CRT to patients ineligible for cisplatin, hypothesizing safety and tolerability in this high-risk group."
        },
        {
            "Title": "CTEP 10492, a phase 1/1b study of the AKT inhibitor ipatasertib with chemoradiation for locally advanced head and neck squamous cell carcinoma.",
            "Indication": "Locally advanced head and neck squamous cell carcinoma (HNSCC)",
            "Drug": "Ipatasertib with chemoradiation",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Maximum tolerated dose (MTD), recommended Phase 2 dose (RP2D), acute and late toxicities, long-term swallowing function, preliminary assessment of efficacy, pharmacodynamic biopsies, pharmacokinetic profile, correlation of efficacy with tumor genotype",
            "Conclusion": "This phase I study will be the first to establish safety and preliminary efficacy of ipatasertib combined with standard of care definitive CRT for HNSCC."
        },
        {
            "Title": "Neoadjuvant adebrelimab plus dalpiciclib in HPV-negative locally advanced head and neck squamous cell carcinoma: A phase II clinical trial.",
            "Indication": "HPV-negative locally advanced head and neck squamous cell carcinoma (LAHNSCC)",
            "Drug": "adebrelimab and dalpiciclib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "1-year disease-free survival and major pathological response (MPR)",
            "Conclusion": "The study aims to assess the efficacy and safety of combining anti-PD-L1 (adebrelimab) with a CDK4/6 inhibitor (dalpiciclib) as neoadjuvant immunotherapy in resectable LAHNSCC patients."
        },
        {
            "Title": "Sacituzumab govitecan in patients with advanced or metastatic radioactive-iodine refractory thyroid carcinoma: The phase 2 SETHY, GETNE-T2318 trial.",
            "Indication": "Advanced or metastatic radioactive-iodine refractory thyroid carcinoma",
            "Drug": "sacituzumab govitecan",
            "PFS": "None",
            "OS": "None",
            "ORR": "Objective response rate (ORR) according to RECIST v1.1",
            "AE": "None",
            "Other": "Disease control rate, duration of response, safety, and quality of life (assessed through EORTC QLQ-C30 at baseline and every 12 weeks until progression)",
            "Conclusion": "The study aims to evaluate the efficacy of sacituzumab govitecan in patients with advanced or metastatic radioactive-iodine refractory thyroid carcinoma."
        },
        {
            "Title": "ASC4FIRST, a pivotal phase 3 study of asciminib (ASC) vs investigator-selected tyrosine kinase inhibitors (IS TKIs) in newly diagnosed patients with chronic myeloid leukemia (CML): Primary results.",
            "Indication": "Chronic Myeloid Leukemia (CML)",
            "Drug": "Asciminib (ASC)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "Ponatinib (PON) in patients (pts) with chronic-phase chronic myeloid leukemia (CP-CML) and the T315I mutation (mut): 4-year results from OPTIC.",
            "Indication": "Chronic-phase Chronic Myeloid Leukemia (CP-CML) with T315I mutation",
            "Drug": "Ponatinib (PON)",
            "PFS": "45 mg: NR, 30 mg: 28.4 mo, 15 mg: 45.6 mo",
            "OS": "45 mg: NR, 30 mg: NR, 15 mg: NR",
            "ORR": "45 mg: 64% (T315I), 60% (Overall); 30 mg: 25% (T315I), 41% (Overall); 15 mg: 16% (T315I), 40% (Overall)",
            "AE": "45 mg: 60% (Grade \u22653), 8% (Discontinuation), 8% (Arterial Occlusive Events); 30 mg: 38% (Grade \u22653), 14% (Discontinuation), 14% (Arterial Occlusive Events); 15 mg: 38% (Grade \u22653), 5% (Discontinuation), 5% (Arterial Occlusive Events)",
            "Other": "None",
            "Conclusion": "Ponatinib demonstrated robust long-term efficacy and manageable safety in this 4-year update in pts with the T315I mutation. A Ponatinib starting dose of 45 mg with reduction to 15 mg upon achievement of \u22641% BCR::ABL1IS provided the optimal benefit:risk ratio."
        },
        {
            "Title": "Updated safety and efficacy data from the phase 3 MANIFEST-2 study of pelabresib in combination with ruxolitinib for JAK inhibitor treatment-na\u00efve patients with myelofibrosis.",
            "Indication": "Myelofibrosis",
            "Drug": "Pelabresib (PELA) + Ruxolitinib (RUX)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "PELA+RUX: 43.9% anemia (Grade \u22653: 23.1%), 32.1% thrombocytopenia (Grade \u22653: 9%), 20.8% platelet count decreased (Grade \u22653: 4.2%), 23.1% diarrhea (Grade \u22653: 0.5%); PBO+RUX: 55.6% anemia (Grade \u22653: 36.4%), 23.4% thrombocytopenia (Grade \u22653: 5.6%), 15.9% platelet count decreased (Grade \u22653: 0.9%), 18.7% diarrhea (Grade \u22653: 1.4%)",
            "Other": "SVR35 at Week 24: 65.9% (PELA+RUX) vs 35.2% (PBO+RUX); TSS50 at Week 24: 52.3% (PELA+RUX) vs 46.3% (PBO+RUX); Hb response: 10.7% (PELA+RUX) vs 6.0% (PBO+RUX); BMF improvement \u22651 grade: 38.5% (PELA+RUX) vs 24.2% (PBO+RUX)",
            "Conclusion": "Pelabresib + Ruxolitinib significantly and durably reduced splenomegaly, with a trend toward reduced TSS, and improved anemia and BMF at Week 24 compared with PBO+RUX in JAK inhibitor treatment-na\u00efve pts with MF, addressing key hallmarks of MF. Results support a potential paradigm shift to combination therapy for MF."
        },
        {
            "Title": "Post-transplant cyclophosphamide-based graft-versus-host disease prophylaxis following mismatched unrelated donor peripheral blood stem cell (PBSC) transplantation.",
            "Indication": "Hematological malignancies",
            "Drug": "Post-transplant cyclophosphamide (PTCy)",
            "PFS": "None",
            "OS": "79% at one year",
            "ORR": "None",
            "AE": "None",
            "Other": "GVHD-free, relapse-free survival (GRFS): 47%; Non-relapse mortality (NRM): 16%; Relapse: 21%; Acute GVHD grade II-IV: 43%; Acute GVHD grade III-IV: 9%; NIH moderate/severe chronic GVHD: 9%",
            "Conclusion": "Encouraging OS was observed at one year following MMUD PBSC in patients receiving RIC and PTCy. Notably, half of enrolled patients were people of color. OS was similar to our prior study using BM grafts. Rates of GVHD and other complications appear comparable to those in HLA-matched donor recipients, suggesting MMUD HCT could expand access to a potentially life-saving therapy."
        },
        {
            "Title": "Obecabtagene autoleucel (obe-cel, AUTO1) in adults with relapsed/refractory B-cell acute lymphoblastic leukemia (R/R B-ALL): Overall survival (OS), event-free survival (EFS) and the potential impact of chimeric antigen receptor (CAR)-T cell persistency and consolidative stem cell transplantation (SCT) in the open-label, single-arm FELIX phase Ib/II study.",
            "Indication": "Relapsed/Refractory B-cell Acute Lymphoblastic Leukemia (R/R B-ALL)",
            "Drug": "Obecabtagene autoleucel (obe-cel, AUTO1)",
            "PFS": "None",
            "OS": "12-month OS rate was 61% with SCT and 59% without SCT",
            "ORR": "Overall complete remission or complete remission with incomplete count recovery rate was 78%",
            "AE": "Grade 3 or higher adverse events included leukopenia (47.6%), lymphopenia (42.9%), neutropenia (38%), thrombopenia (33.3%), anaemia (26.2%), and infections (14.3%). Cytokine release syndrome occurred in 30.9% of patients, mostly low-grade except for one grade 3 case. No immune effector cell-associated neurotoxicity syndrome observed.",
            "Other": "CAR-T cell persistency was associated with improved EFS. Loss of CAR-T cell persistency was associated with a hazard risk of relapse or death 2.9 times higher. B-cell recovery was associated with a hazard risk of relapse or death 1.7 times higher.",
            "Conclusion": "Ongoing CAR-T cell persistency and B-cell aplasia were associated with improved EFS without further consolidation post-obe-cel. Consolidative SCT for patients in MRD-negative remission post-obe-cel did not improve EFS or OS."
        },
        {
            "Title": "Phase I study of CN201, a novel CD3xCD19 IgG4 bispecific antibody, in adult patients with relapsed or refractory B-cell acute lymphoblastic leukemia.",
            "Indication": "Relapsed/Refractory B-cell Acute Lymphoblastic Leukemia (R/R B-ALL)",
            "Drug": "CN201",
            "PFS": "None",
            "OS": "None",
            "ORR": "Overall, 30% of 37 patients had complete remission (CR), 2.7% had complete remission with incomplete hematological recovery (CRi). CR rate increased with dose escalation, reaching 50% with the 10 mg target dose.",
            "AE": "Grade 3 or higher adverse events included leukopenia (47.6%), lymphopenia (42.9%), neutropenia (38%), thrombopenia (33.3%), anaemia (26.2%), and infections (14.3%). Cytokine release syndrome occurred in 30.9% of patients, mostly low-grade except for one grade 3 case. No immune effector cell-associated neurotoxicity syndrome observed.",
            "Other": "The CR rate significantly increased with dose escalation, reaching 50% with the 10 mg target dose, with two of those occurring within the first two weeks following CN201 treatment.",
            "Conclusion": "Preliminary data shows that CN201 has a high safety profile and promising activity in adult patients with relapsed or refractory ALL."
        },
        {
            "Title": "A retrospective comparison of abbreviated course \u201c7+7\u201d vs standard hypomethylating agent plus venetoclax doublets in older/unfit patients with newly diagnosed acute myeloid leukemia.",
            "Indication": "Newly Diagnosed Acute Myeloid Leukemia (ND-AML)",
            "Drug": "Hypomethylating agent (HMA) plus venetoclax (VEN)",
            "PFS": "None",
            "OS": "Median OS was 11.2 months (2-year 28%) with \u201c7+7\u201d vs 10.1 months (2-year 33%) with standard HMA+VEN",
            "ORR": "Composite complete remission rate (CRc) was 72% with \u201c7+7\u201d vs 71% with standard HMA+VEN. CR rate was 57% with \u201c7+7\u201d vs 55% with standard HMA+VEN.",
            "AE": "4-week mortality was similar (2% with \u201c7+7\u201d, 6% with standard HMA+VEN). 8-week mortality was higher with standard HMA+VEN (17%) compared to \u201c7+7\u201d (6%). Fewer patients required platelet transfusions during cycle 1 with \u201c7+7\u201d compared to standard HMA+VEN (62% vs 77%). The cycle 1 rates of neutropenic fever and red cell transfusion requirements were similar.",
            "Other": "Median cycles to first response was 1 in both groups, but 42% of responders on \u201c7+7\u201d required more than 1 cycle for first response, whereas almost all responders on standard HMA+VEN (99%) had a first response after cycle 1. Median cycles to best response was 2 with \u201c7+7\u201d vs 1 with standard HMA+VEN.",
            "Conclusion": "No significant difference in efficacy between \u201c7+7\u201d and standard VEN-based HMA doublets in ND-AML. The \u201c7+7\u201d regimen was associated with lower platelet transfusion requirements and lower 8-week mortality."
        },
        {
            "Title": "Preliminary safety and efficacy of oral azacitidine (Oral-AZA) in patients with low-/Intermediate (Int)-risk myelodysplastic syndromes (MDS): Phase 2 results from the ASTREON trial.",
            "Indication": "Low-/Intermediate-risk myelodysplastic syndromes (MDS)",
            "Drug": "Oral azacitidine (Oral-AZA)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "11/24 (45.8%) vs 12/23 (52.2%) pts in the 200mg vs 300mg arms reported \u22651 tx-related grade 3 or 4 tx-emergent AE. The most common tx-related AEs in both arms were hematologic and gastrointestinal. Tx-related serious AEs occurred in 1/24 and 3/23 pts in the 200mg and 300mg arms, respectively. One death occurred in the 300mg arm and was considered tx-related.",
            "Other": "8/22 and 7/21 pts achieved any HI in the 200mg and 300mg arms, respectively. Six pts in each dose group achieved HI-E. Among mITT pts with anemia at BL, 6/19 (31.6%) pts in the 200mg and 5/18 (27.8%) pts in the 300mg arm achieved HI-E.",
            "Conclusion": "The safety of Oral-AZA 200 mg and 300 mg was consistent with the known Oral-AZA safety profile. Preliminary efficacy data support continued evaluation of Oral-AZA in LR-MDS."
        },
        {
            "Title": "Latest results of a phase 2 study of IMM01 combined with azacitidine (AZA) as the first-line treatment in adults with higher risk myelodysplastic syndromes (MDS).",
            "Indication": "Higher-risk myelodysplastic syndromes (MDS)",
            "Drug": "IMM01 combined with azacitidine (AZA)",
            "PFS": "The median of progression-free survival (PFS) was not reached, with an estimated 12-month PFS of 54.4% (95% CI, 31.4-72.6).",
            "OS": "None",
            "ORR": "Overall response rate (ORR) was 64.7%, including 29.4% complete response (CR) rate, 15.7% marrow CR (mCR) with hematologic improvement (HI), 5.9% HI and 13.7% mCR alone.",
            "AE": "The most common \u2265G3 treatment-related adverse events (TRAEs) (\u226510%) included leukopenia (78.9%), thrombocytopenia (66.7%), neutropenia (66.7%), lymphopenia (56.1%), anemia (43.9%), infection (15.8%) and pneumonia (10.5%). Without using a low priming dose, the Grade \u22653 hemolysis was rare (only 1.8%).",
            "Other": "The median time to response (TTR) was 1.9 months (95% CI: 1.8-2.8) and the median duration of response (DoR) was not reached (NR). NGS analysis identified common mutations in DNMT3A, ASXL1, U2AF1 and RUNX1. Notably, NPM1 mutations significantly correlate with treatment response, particularly achieving CR.",
            "Conclusion": "IMM01 (without low-dose priming) combined with AZA were well tolerated and showed exciting efficacy results in patients with treatment-naive higher-risk MDS."
        },
        {
            "Title": "Interim safety and efficacy of BP1001 in a phase II acute myeloid leukemia (AML) study.",
            "Indication": "Acute myeloid leukemia (AML)",
            "Drug": "BP1001 combined with decitabine (DEC) and venetoclax (VEN)",
            "PFS": "None",
            "OS": "None",
            "ORR": "In Cohort 1, 15 pts (75% of evaluable; 54% of enrolled) achieved CR/CRi/CRh; 2 pts achieved partial remission (PR) and 2 achieved stable disease (SD). In Cohort 2, 12 pts (55% of evaluable; 32% of enrolled) achieved CR/CRi/CRh; 1 pt achieved PR, 8 achieved SD and 1 had treatment failure.",
            "AE": "Among the evaluable pts of both cohorts, adverse events were consistent with those expected with DEC, VEN and/or AML, including fatigue (72%), anemia (60%) and neutropenia (49%), while the most frequent serious adverse events were febrile neutropenia (26%) and sepsis (5%).",
            "Other": "None",
            "Conclusion": "BP1001 + DEC + VEN has been safely administered to pts without drug-related toxicity. Since \u22655 responses are observed in both cohorts, the study will continue with enrollment up to 98 and 54 evaluable pts in cohorts 1 and 2, respectively. Efficacy data are encouraging in a challenging population of frontline adverse-risk, sAML and R/R pts."
        },
        {
            "Title": "Long-term efficacy of high-dose imatinib in Hispanic patients without access to second-generation tyrosine kinase inhibitors treated in LATAM centers.",
            "Indication": "Chronic Myeloid Leukemia (CML)",
            "Drug": "Imatinib",
            "PFS": "None",
            "OS": "No difference in 150-month risk in both OS and DFS. Median OS increased with second-generation TKI (p-value=0.009).",
            "ORR": "None",
            "AE": "No difference in hematologic, gastrointestinal, dermatologic, or other adverse events (p-values of 0.39, 0.94, 0.24, and 0.33, respectively).",
            "Other": "None",
            "Conclusion": "Patients who develop a suboptimal response to imatinib should ideally be switched to a second-generation TKI. However, if impossible, high-dose imatinib provides a similar OS and DFS prognosis."
        },
        {
            "Title": "PTPN11 variants in chronic myelomonocytic leukemia: Phenotypic and prognostic correlates.",
            "Indication": "Chronic Myelomonocytic Leukemia (CMML)",
            "Drug": "None",
            "PFS": "None",
            "OS": "PTPN11 variants negatively impacted OS (median 13 vs. 31 months; p<0.01).",
            "ORR": "None",
            "AE": "None",
            "Other": "LFS negatively impacted by PTPN11 variants (AML events: 42% vs. 16%; p<0.01).",
            "Conclusion": "PTPN11 and DNMT3A mutations are independent risk factors for both OS and LFS in CMML. OS positively affected by TET2 mutations and negatively by abnormal karyotype, while LFS negatively affected by ASXL1 mutations."
        },
        {
            "Title": "Safety and efficacy of CD7-CAR-T cell in patients with relapsed/refractory T-lymphoblastic leukemia/lymphoma: Phase I dose-escalation/dose-expansion study.",
            "Indication": "Relapsed/Refractory T-lymphoblastic Leukemia/Lymphoma (r/r T-ALL/LBL)",
            "Drug": "CD7-CAR-T cell (PA3-17 injection)",
            "PFS": "None",
            "OS": "None",
            "ORR": "Best ORR was 83.3% and CR rate was 75%.",
            "AE": "83.3% of patients developed cytokine release syndrome (CRS), 25% had grade 3 CRS, no grade 4 CRS occurred. 16.7% experienced grade 1-2 ICANS, no grade 3 or higher ICANS occurred.",
            "Other": "None",
            "Conclusion": "PA3-17 injection has shown a good safety profile and encouraging efficacy in r/r T-ALL/LBL patients. RP2D has been determined and the key Phase II clinical study is about to begin."
        },
        {
            "Title": "Safety outcomes in patients with acute myeloid leukemia receiving gemtuzumab ozogamicin and proceeding to allogeneic hematopoietic stem cell transplantation",
            "Indication": "Acute Myeloid Leukemia (AML)",
            "Drug": "Gemtuzumab ozogamicin (GO)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Non-fatal VOD in 7 (4%) patients; 100-day VOD 5% (95% CI, 2-8)",
            "Other": "6-month transplant-related mortality (TRM) 8% (95% CI, 4-13)",
            "Conclusion": "The use of GO appears to be safe prior to HSCT in adults with AML. Rates for 100-day VOD and TRM were comparable to those previously reported for patients with AML who received HSCT with or without prior GO."
        },
        {
            "Title": "Hematopoietic stem cell transplant for adults with mixed phenotypic acute leukemia",
            "Indication": "Mixed Phenotypic Acute Leukemia (MPAL)",
            "Drug": "None",
            "PFS": "Patients receiving HCT had better PFS (p=0.025)",
            "OS": "Patients receiving HCT were more likely to be alive at 48 months (61.6% vs. 32.4%, p=0.011); median OS not reached (NR) for HCT group vs. 13 months for non-HCT group",
            "ORR": "None",
            "AE": "None",
            "Other": "Hazard ratio for death among patients who underwent HCT was 0.28 (95% CI 0.077\u20130.99, p=0.049)",
            "Conclusion": "HCT consolidation was significantly correlated with improved survival (p=0.011). This study suggests that patients with MPAL in CR after induction chemotherapy as well as those with less than complete responses should be considered for HCT consolidation."
        },
        {
            "Title": "Phase I study of functionally enhanced CD33 CAR T cells in patients with relapsed or refractory acute myeloid leukemia",
            "Indication": "Relapsed or Refractory Acute Myeloid Leukemia (AML)",
            "Drug": "Functionally enhanced CD33 CAR T cells",
            "PFS": "None",
            "OS": "None",
            "ORR": "Two patients achieved CRi and were MRD-negative at day 30",
            "AE": "Three patients (75%) experienced grade 1-2 cytokine release syndrome (CRS), one had grade 4 CRS, one developed grade 2 CRS after the second infusion, one patient developed sepsis",
            "Other": "Two patients remained disease-free for over two years, one patient remained disease-free for one year before CD33+ relapse",
            "Conclusion": "We report the safety and efficacy of functionally enhanced CD33 CAR T cells. While some patients had CRi, there was also depletion of CD33-positive normal cells. Further research is needed to address the issue of normal cell depletion."
        },
        {
            "Title": "FLAG-IDA+venetoclax in newly diagnosed (ND) or relapsed/refractory (RR) AML",
            "Indication": "Newly Diagnosed or Relapsed/Refractory Acute Myeloid Leukemia (AML)",
            "Drug": "FLAG-IDA+venetoclax",
            "PFS": "None",
            "OS": "ND: 2-year OS 75% (64-88); RR: 2-year OS 40% (28-55)",
            "ORR": "ND: 99% (96% CRc, 89% MRD-negative); RR: 70% (66% CRc, 79% MRD-negative)",
            "AE": "Grade \u22653 infections in 102 (80%), gastrointestinal toxicities in 20 (16%), bleeding in 9 (7%) patients",
            "Other": "Median time to neutrophil >1x10^9/L and platelet >50x10^9/L were 27d & 28d for C1, 39d & 67d for C2, 35d & 50d for C3 respectively",
            "Conclusion": "FLAG-IDA+VEN results in high MRD-negative response rates, leading to impressive survival outcomes across ELN risk groups in ND AML. It is an effective salvage regimen for RR AML, especially for S1+TP53 WT patients."
        },
        {
            "Title": "A real-world picture of patients with a new diagnosis of acute myeloid leukemia in Italy",
            "Indication": "Acute Myeloid Leukemia (AML)",
            "Drug": "Antineoplastic treatments and allogeneic stem cell transplantation (alloSCT)",
            "PFS": "None",
            "OS": "2-year overall survival: 97% for treated patients vs 65% for untreated patients; 38% for patients fit for intensive chemotherapy (ICHT) vs 29% for patients unfit for ICHT",
            "ORR": "None",
            "AE": "None",
            "Other": "Patients receiving antineoplastic treatments were younger and had fewer comorbidities than untreated patients. Older patients were more likely to be excluded from ICHT.",
            "Conclusion": "This real-world analysis suggests that being treated with chemotherapy and/or alloSCT can increase survival probabilities."
        },
        {
            "Title": "Recombinant Erwinia asparaginase (JZP458) in acute lymphoblastic leukemia/lymphoblastic lymphoma (ALL/LBL): Post hoc analysis of adverse events of interest from AALL1931",
            "Indication": "Acute Lymphoblastic Leukemia/Lymphoblastic Lymphoma (ALL/LBL)",
            "Drug": "Recombinant Erwinia asparaginase (JZP458)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Rates of any-grade treatment-related allergic reactions, pancreatitis, thrombosis, increased ALT/AST, and increased bilirubin were 11%, 7%, 1%, 16%, and 7% in the total IM cohort and 26%, 5%, 2%, 18%, and 5% in the IV cohort, respectively. Rates of treatment-related grade \u22652 nausea/vomiting (N/V) were 32% and 64% in the total IM and IV cohorts.",
            "Other": "No consistent trends in the rates of any-grade AEIs by age except for pancreatitis, where rates were numerically higher in patients aged 12 to <18 (15%) and \u226518 years (13%) than in those aged 6 to <12 (1%) or <6 years (2%).",
            "Conclusion": "The safety profile of JZP458 is consistent with other ASPs in patients with ALL/LBL and generally similar across age and ethnicity subgroups."
        },
        {
            "Title": "A post-hoc analysis of outcomes of patients with acute myeloid leukemia with myelodysplasia-related changes (AML-MRC) who received oral azacitidine (Oral-AZA) maintenance therapy in the QUAZAR AML-001 study",
            "Indication": "Acute Myeloid Leukemia with Myelodysplasia-Related Changes (AML-MRC)",
            "Drug": "Oral azacitidine (Oral-AZA)",
            "PFS": "Oral-AZA: 7.5 months vs 10.5 months for non-AML-MRC; PBO: 3.7 months vs 4.9 months for non-AML-MRC",
            "OS": "Oral-AZA: 19.9 months vs 25.1 months for non-AML-MRC; PBO: 14.8 months vs 14.9 months for non-AML-MRC",
            "ORR": "None",
            "AE": "None",
            "Other": "Oral-AZA significantly prolonged median OS and RFS, and the duration of MRD negativity for patients with AML-MRC compared with PBO.",
            "Conclusion": "Oral-AZA maintenance is an effective treatment option for patients with AML-MRC with particularly poor prognosis."
        },
        {
            "Title": "Evaluation of the toxicity and outcomes of the combination of midostaurin and CLAG-M in patients with FLT3-mutated acute myeloid leukemia (AML): A multi-center retrospective analysis",
            "Indication": "FLT3-mutated Acute Myeloid Leukemia (AML)",
            "Drug": "Midostaurin and CLAG-M (cladribine, high-dose cytarabine, GCSF, and mitoxantrone)",
            "PFS": "None",
            "OS": "None",
            "ORR": "Complete remission (CR) plus CR with incomplete blood count recovery (CRi): CLAG-M, 86% vs 7+3, 70%",
            "AE": "Diarrhea: 8.6% (CLAG-M) vs 36% (7+3); Bleeding events: 3% (CLAG-M) vs 18% (7+3); Other AE rates were similar between the two cohorts.",
            "Other": "No treatment-related mortality (TRM) occurred.",
            "Conclusion": "The toxicity profile of CLAG-M combined with midostaurin is comparable to the combination of 7+3 with midostaurin and induces high remission rates in adults with FLT3-mutated AML."
        },
        {
            "Title": "Molecular characterization and biomarker identification in pediatric B-cell acute lymphoblastic leukemia",
            "Indication": "B-cell acute lymphoblastic leukemia (B-ALL)",
            "Drug": "Systemic chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Transcriptomic analysis revealed 86.3% of patients could be categorized into 13 distinct molecular subtypes. Baseline BM NGS identified gene fusions in 61% of patients. Elevated mutant counts and maximum-variant-allele-frequency (maxVAF) in baseline BM were associated with significantly poorer response to chemotherapy. MRD-negative patients exhibited upregulated expression of immune-related pathways and increased CD8+ T cell infiltration.",
            "Conclusion": "This study elucidated novel gene fusions and potential biomarkers for treatment response in pediatric B-ALL. Both baseline plasma ctDNA and BM samples offer promising prognostic insights into chemotherapy responses, paving the way for non-invasive monitoring strategies in the management of pediatric B-ALL."
        },
        {
            "Title": "Multi-drug algorithm to accurately predict best first-line treatments in newly-diagnosed acute myeloid leukemia (AML)",
            "Indication": "Acute myeloid leukemia (AML)",
            "Drug": "Venetoclax + azacitidine (VA), Intensive chemotherapy (IC), IC plus midostaurin (MIC)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Phosphoproteomic analysis identified phosphopeptides that distinguish between Good Responders (GR) and Poor Responders (PR). Statistical models based on these features were assessed via cross-validation, stratifying patients with log rank p<0.001, HR<0.1, and more than 90% accuracy.",
            "Conclusion": "We built a suite of predictive models that accurately predict patient response to first-line AML treatment using phosphoproteomic data from routine diagnosis samples. Following validation in independent patient cohorts, this tool will be developed into a single test that predicts treatment response for AML patients, addressing an unmet clinical need in this disease."
        },
        {
            "Title": "Outcomes of blast-phase MPN and JAK2, MPL, and CALR mutated de novo AML: A propensity score-adjusted cohort study",
            "Indication": "Blast-phase myeloproliferative neoplasms (MPN) and de novo AML with JAK2, MPL, or CALR mutations",
            "Drug": "Intensive regimen (7+3, CPX-351), Hypomethylating agents (HMA), HMA + Venetoclax (VEN)",
            "PFS": "None",
            "OS": "MPN-BP: 4.8 months (95% CI: 2.7-8), AML-D-MPN: 6.1 months (95% CI: 1.6-NC)",
            "ORR": "Unadjusted CCR: 60% in intensive regimen, 20% in HMA, 20% in HMA+VEN, 0% in others",
            "AE": "None",
            "Other": "Median time for MPN to BP transformation was 8 years (IQR: 2.8-13.6). Adjusted median OS in months (95% CI): 2.6 (1.4-NC) in intensive regimen, 6.2 (1.2-NC) in HMA, 15 (10-15) in HMA+VEN, 1.7 (0.46-NC) in others. Adjusted median EFS in months (95% CI): 1.5 (0.43-NC) in intensive regimen, 6.2 (1.2-NC) in HMA, 15 (4.3-15) in HMA+VEN, 0.92 (0.46-NC) in others.",
            "Conclusion": "Patients with MPN-BP and AML-D-MPN have poor survival with similar responses to therapy. These patients have variable outcomes with different treatments; however, there is a trend toward improved survival with the use of HMA+VEN."
        },
        {
            "Title": "Safety and efficacy of olutasidenib treatment in elderly patients with relapsed/refractory mIDH1 acute myeloid leukemia",
            "Indication": "Relapsed/refractory mIDH1 acute myeloid leukemia (AML)",
            "Drug": "Olutasidenib (OLU)",
            "PFS": "None",
            "OS": "None",
            "ORR": "31% of patients \u226575 years achieved CR/CRh; median time to CR/CRh was 1.5 months (0.9-5.6) and median duration of CR/CRh was 25.9 months (95% CI 7.4, not reached)",
            "AE": "Grade 3 or 4 adverse events were reported in 45.8% of elderly patients; the most common were decreases in red blood cells (31%), platelets (13%), and neutrophils (10%) as well as febrile neutropenia (15%).",
            "Other": "Deaths occurred in 19 patients due to disease progression (9), pneumonia (2), sepsis (2), GI hemorrhage (1), aortic stenosis (1), atrioventricular block (1), cardiac failure congestive (1), acute kidney injury (1), and other (1).",
            "Conclusion": "Olutasidenib was generally well tolerated in elderly patients with R/R mIDH1 AML and induced durable remissions. Despite the challenges of treating elderly patients who had already failed prior AML treatment, the results suggest that elderly patients can benefit from therapy with olutasidenib."
        },
        {
            "Title": "Olutasidenib for mutated IDH1 acute myeloid leukemia: Final five-year results from the phase 2 pivotal cohort.",
            "Indication": "mutated IDH1 acute myeloid leukemia (AML)",
            "Drug": "Olutasidenib",
            "PFS": "None",
            "OS": "Median overall survival was 11.6 months (95% CI: 8.9, 15.5). In the 12 patients R/R to prior venetoclax, median overall survival is 16.2 months (95% CI: 2.6, not reached).",
            "ORR": "Overall response rate was 48% (95% CI: 40, 56.7).",
            "AE": "The most common AEs were: febrile neutropenia (22%), constipation (27%), diarrhea (21%), nausea (39%), fatigue (23%), pyrexia (24%), hypokalaemia (22%), red blood cell count decreased (26%), white blood cell count increased (25%). Differentiation syndrome was reported in 14% by Year 3, with no new events by Year 5.",
            "Other": "Transfusion independence from red blood cells and platelets was achieved in 34 of 87 (39%) patients and 28 of 69 (41%) patients, respectively, who were dependent at baseline. 16 (11%) patients proceeded to stem cell transplant.",
            "Conclusion": "This analysis provides an additional 2 years of data beyond the results that led to FDA approval of olutasidenib. This first report of the five-year data further demonstrates the rapid and durable responses observed with olutasidenib in heavily pretreated patients with mIDH1 AML, including those R/R to prior venetoclax."
        },
        {
            "Title": "Treatment discontinuation due to toxicity for patients with acute myeloid leukemia (AML) treated on SWOG S1203.",
            "Indication": "acute myeloid leukemia (AML)",
            "Drug": "cytarabine + daunorubicin (7+3) or cytarabine + idarubicin (IA)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Treatment discontinuation due to either death or toxicity occurred in 8 vs 22 patients on 7+3 vs IA respectively (p=0.014). During induction, 0 patients on the 7+3 arm discontinued treatment due to toxicity versus 4 patients on the IA arm (0% vs 2%; p=0.12).",
            "Other": "For patients with PS 2-3 (n=40 on 7+3, n=27 on IA), rates of grade 3-5 AEs were 85% vs 86% and rates of grade 5 AEs were 0% vs 11% (p=0.065).",
            "Conclusion": "7+3 was well tolerated. Excluding patients from S1203 with PS 2-3 or decreased hepatic/renal function would not have prevented treatment-related toxicity. The low rate of protocol therapy discontinuation due to toxicity suggests that eligibility criteria for clinical trials could be further broadened to improve patient access."
        },
        {
            "Title": "Achievement of therapeutic levels using dose-reduced peg-asparaginase in adult patients with acute lymphoblastic leukemia.",
            "Indication": "acute lymphoblastic leukemia (ALL)",
            "Drug": "peg-asparaginase",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "During induction, 23 (48%) patients had \u22651 grade 3+ toxicity, including 5 (10%) with hepatotoxicity, 1 (2%) with pancreatitis, and 2 (4%) with a thrombotic event.",
            "Other": "79% were therapeutic after induction; of note, 4 patients were transitioned from PEG to Erwinia asparaginase due to silent inactivation.",
            "Conclusion": "This study demonstrates durability of therapeutic L-asparaginase levels and ASP depletion that persists 14 days despite dose reduction. There were decreased rates of hepatotoxicity and comparable rates of pancreatitis and thrombotic events compared to rates reported in CALBG 10403 (C10403), which utilized standard pediatric dosing. This suggests PEG dose reduction may offer similar therapeutic ASP depletion with comparable or reduced toxicities. Dose reduction should be studied prospectively in AYAs with ALL."
        },
        {
            "Title": "Long-term survival outcomes of patients (pts) with relapsed or refractory B-cell acute lymphoblastic leukemia (R/R B-ALL) treated with brexucabtagene autoleucel (brexu-cel) in ZUMA-3.",
            "Indication": "relapsed or refractory B-cell acute lymphoblastic leukemia (R/R B-ALL)",
            "Drug": "brexucabtagene autoleucel (brexu-cel)",
            "PFS": "None",
            "OS": "Median OS (95% CI) was 25.6 months (16.2-60.4) in all treated pts and 47.0 months (23.2-not estimable [NE]) in pts with CR/CRi (n=57). In pts <26y (n=15), median OS (95% CI) was 23.2 months (9.0-NE) and was 26.0 months (15.9-NE) in pts \u226526y (n=63).",
            "ORR": "None",
            "AE": "Grade \u22653 infection rates since the start of study appeared higher in pts <26y and in pts with prior blina.",
            "Other": "Rates of non-relapse mortality (NRM) and relapse-related mortality (95% CI) at 48 months were 25% (15-37) and 34% (24-45; N=78), respectively. Of note, 6/17 NRM events (35%) occurred in pts with suballoSCT.",
            "Conclusion": "After >4 years follow-up, pts in ZUMA-3 continued to experience OS benefit regardless of age, prior therapy, or suballoSCT status, though pts with prior blina had a numerically lower 48-month OS rate. Small subgroups and unbalanced pt characteristics limit interpretation of these results. No new safety signals were observed. Further studies are needed to fully assess the impact of age, prior therapies, and suballoSCT on outcomes after brexu-cel."
        },
        {
            "Title": "In-depth analysis of responders in the phase 3 PhALLCON trial of ponatinib vs imatinib in newly diagnosed Ph+ ALL",
            "Indication": "Philadelphia chromosome-positive acute lymphoblastic leukemia (Ph+ ALL)",
            "Drug": "Ponatinib vs Imatinib",
            "PFS": "Ponatinib: 20.0 months (HR 0.58 [0.41, 0.83]), Imatinib: 7.9 months",
            "OS": "None",
            "ORR": "None",
            "AE": "Arterial occlusive events: Ponatinib 3%, Imatinib 2%; Venous thromboembolic events: Ponatinib 11%, Imatinib 12%; Discontinuations due to TEAEs: Ponatinib 13%, Imatinib 14%",
            "Other": "MRD-negative at any time: Ponatinib 68%, Imatinib 50%",
            "Conclusion": "Ponatinib was superior to imatinib in combination with reduced-intensity chemotherapy for frontline treatment of Ph+ ALL, with higher rates of MRD-negative at any time, substantially longer PFS across age and BCR::ABL1 variant subgroups, and a safety profile comparable to imatinib."
        },
        {
            "Title": "PMB-CT01 (BAFFR-CART cell) therapy to examine preliminary safety and clinical responses in patients with B-cell malignancies who are ineligible for or failed CD19-directed therapy, including CD19-negative disease",
            "Indication": "B-cell malignancies",
            "Drug": "PMB-CT01 (BAFFR-CART cell)",
            "PFS": "None",
            "OS": "None",
            "ORR": "100% CR rate at 3 months in the first 4 patients",
            "AE": "Gr.1 cytokine release syndrome in all patients; Gr.1 immune effector cell-associated neurotoxicity syndrome in 2/3 NHL patients",
            "Other": "Robust CART cell expansion seen in all patients with a peak on days 12-14 post-infusion",
            "Conclusion": "With a 100% CR rate at 3 months in the first 4 patients and durable responses at dose level 1, BAFFR-CART cells are a promising therapeutic option for patients with r/r B-cell malignancies who are ineligible or failed prior CD19-targeted therapy, including with CD19 antigen loss."
        },
        {
            "Title": "Mutation profiles and outcomes of patients with acute myeloid leukemia with autoimmune disease",
            "Indication": "Acute Myeloid Leukemia (AML)",
            "Drug": "Not specified",
            "PFS": "de novo AML: 8 months, AD-AML: 6 months, t-AML: 5 months",
            "OS": "de novo AML: 11 months, AD-AML: 9 months, t-AML: 6 months, s-AML: 6 months",
            "ORR": "None",
            "AE": "None",
            "Other": "Mutations in NF1 were more common in AD-AML (9.7%) than in de novo (2.7%), sAML (1.0%), and t-AML (2.7%, p=0.024). FLT3 mutations were less common in AD-AML (9.5%) than in de novo (36.1%) and t-AML (33.3%, p=0.004). Mutations in SRSF2 were more common in AD-AML (20.3%) than in de novo (12.3%) and sAML (8.8%, p=0.119).",
            "Conclusion": "Patients with AML and a prior history of AD have CG profile and outcomes similar to those with t-AML but with distinct molecular features (i.e., NF1 and SRSF2). These findings need to be validated in large series; however, they suggest that the MAPK signaling pathway as well as spliceosome inhibitors might be a future focus of investigation in pts with AD-AML."
        },
        {
            "Title": "7+3 versus decitabine + venetoclax: A global, propensity-matched study comparing outcomes for both regimens in AML",
            "Indication": "Acute Myeloid Leukemia (AML)",
            "Drug": "7+3 regimen (cytarabine and either idarubicin or daunorubicin), decitabine and venetoclax",
            "PFS": "None",
            "OS": "None",
            "ORR": "7+3: 54.5%, decitabine and venetoclax: 34.7%",
            "AE": "None",
            "Other": "Mortality at 12 months: 7+3: 37%, decitabine and venetoclax: 54%",
            "Conclusion": "These results show that among elderly patients with AML, treatment with 7+3 is associated with statistically higher remission rates and lower mortality when compared with decitabine and venetoclax therapy. One limitation of our study was that we were not able to determine the cytogenetics in either arm. These results should be taken into consideration when choosing an induction regimen in this population."
        },
        {
            "Title": "Preliminary safety, efficacy and molecular characterization of emavusertib (CA-4948) in patients with relapsed/refractory (R/R) acute myeloid leukemia (AML) with FLT3 mutation (FLT3m)",
            "Indication": "Relapsed/Refractory Acute Myeloid Leukemia (AML) with FLT3 mutation",
            "Drug": "Emavusertib (CA-4948)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Treatment-related adverse events (TRAEs) Grade \u22653 were reported in 2 of 11 (18%) patients.",
            "Other": "Responders demonstrated more than 90% bone marrow blast reduction compared to baseline. FLT3-ITD levels were decreased or became undetectable in responders. The most common co-mutations in this subset of patients included RUNX1, NRAS, and TET2.",
            "Conclusion": "Emavusertib has a favorable safety and tolerability profile in pretreated AML patients with FLT3m and demonstrated monotherapy anti-cancer activity in patients with FLT3m, including patients who have progressed on prior FLT3 inhibitors. Mutational profiles are suggestive of disease-modifying activity of emavusertib. We will present updated safety and efficacy data, including data for patients who proceeded to stem cell transplant. Enrollment in this ongoing trial is continuing at RP2D in patients with \u22643 lines of prior anti-cancer therapies."
        },
        {
            "Title": "Evaluation of treatment outcomes with CPX-351 in adults with AML: A meta-analysis.",
            "Indication": "Acute Myeloid Leukemia (AML)",
            "Drug": "CPX-351",
            "PFS": "None",
            "OS": "CPX-351: 10.3 months (range 8.8-22 months), 7+3: 6.2 months (range 4.6-12 months)",
            "ORR": "None",
            "AE": "None",
            "Other": "Complete Remission (CR) for CPX-351 vs 7+3: OR = 1.66 (CI = 1.15-2.39, p = 0.006, I2 = 55%); Hematopoietic Stem Cell Transplantation (HSCT) for CPX-351 vs 7+3: OR = 1.52 (CI = 1.01-2.30, p = 0.05, I2 = 47%)",
            "Conclusion": "The OR of attaining CR and HSCT were shown to be significantly improved with CPX-351 when compared to conventional 7+3 chemotherapy in adult patients with AML. Both groups had similar median age, and patients in the CPX-351 arm had longer median overall survival."
        },
        {
            "Title": "Safety and efficacy of lisaftoclax, a novel BCL-2 inhibitor, in combination with azacitidine in patients with treatment-na\u00efve or relapsed or refractory acute myeloid leukemia.",
            "Indication": "Acute Myeloid Leukemia (AML)",
            "Drug": "Lisaftoclax (Lisa) + Azacitidine (AZA)",
            "PFS": "R/R AML: 10.2 months (95% CI, 6.5-NR); TN AML: Not reached (3.5, NR)",
            "OS": "R/R AML: 14.7 months (95% CI, 7.8-NR); TN AML: None",
            "ORR": "R/R AML: 72.7% (95% CI, 54.5-86.7); TN AML: 64.1% (95% CI, 47.2-78.8)",
            "AE": "All patients reported TEAEs, with 89.5% Grade 3/4 AEs; 43.4% SAEs. Common TEAEs (\u226530%) included neutropenia (60.5%), thrombocytopenia (60.5%), diarrhea (42.1%), hypokalemia (40.8%), pyrexia (35.5%), and vomiting (30.3%). Grade \u22653 TEAEs reported in \u226510% of patients were neutropenia (57.9%), thrombocytopenia (50.0%), anemia (27.6%), pneumonia (17.1%), and febrile neutropenia (10.5%). No TLS was reported.",
            "Other": "Composite Complete Remission (CRc) rates: R/R AML: 45.5%; TN AML: 51.3%. Median Duration of Treatment (mDoT): R/R AML: 3.8 months (range 0.8-15.4); TN AML: 3.3 months (range 1.0-9.9). Median Time to CRc (mTTCRc): R/R AML: 2.5 months (95% CI, 1.5-6.1); TN AML: 1.9 months (95% CI, 1.2-3.3).",
            "Conclusion": "Our data support an emerging role for this new BCL-2 inhibitor Lisa combined with AZA for the treatment of elderly/unfit TN and R/R AML patients, especially with low early mortality and promising mPFS. A phase 3, randomized, double-blind clinical study is in progress to determine whether Lisa + AZA improves OS in elderly/unfit patients with AML."
        },
        {
            "Title": "Outpatient blinatumomab with digital monitoring in patients with measurable residual disease positive (MRD+) B-ALL in a phase 4 study.",
            "Indication": "Measurable Residual Disease Positive (MRD+) B-ALL",
            "Drug": "Blinatumomab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "89% (All TEAE), 56% (Treatment-related TEAE), 44.4% (Pyrexia), 22.2% (CRS), 22.2% (Headache)",
            "Other": "Safety and alarm findings during monitoring period: 100% of patients had at least one alarm triggered, with 1.2%, 5.1%, and 8.9% of alarms leading to therapeutic intervention among 3 patients.",
            "Conclusion": "To date, outpatient administration of cIV blinatumomab in patients with MRD+ B-ALL was feasible and safe with appropriate outpatient digital monitoring. Study enrollment is ongoing."
        },
        {
            "Title": "A prospective study of all-trans retinoic acid plus venetoclax and azacitidine in newly diagnosed acute myeloid leukemia.",
            "Indication": "Newly Diagnosed Acute Myeloid Leukemia",
            "Drug": "All-trans retinoic acid, Venetoclax, Azacitidine",
            "PFS": "None",
            "OS": "Median OS was 573 days",
            "ORR": "91% (32/35)",
            "AE": "74% (Any-grade infections)",
            "Other": "Composite CR rate reached 74% (26/35) at the end of cycle 1, with 73% (11/15) in the adverse risk group and 75% (15/20) in the favorable-intermediate risk group. Platelet transfusion independence occurred in 17% (6/35) in cycle 1 and 73% (24/33) in cycle 2. RBC transfusion independence occurred in 20% (7/35) in cycle 1 and 73% (24/33) in cycle 2.",
            "Conclusion": "The treatment regimen achieved a high composite CR rate and was well tolerated, with reduced hematologic toxicities in cycle 2 of induction therapy. Both adverse risk and favorable-intermediate risk patients benefited from the regimen, which holds promise for treating AML in adult patients."
        },
        {
            "Title": "A phase II study of venetoclax (VEN) in combination with 10-day decitabine (DEC) in older/unfit pts with newly diagnosed (ND) or pts with relapsed/refractory (R/R) acute myeloid leukemia (AML), or high-risk myelodysplastic syndrome (HR-MDS).",
            "Indication": "Acute Myeloid Leukemia (AML) and High-Risk Myelodysplastic Syndrome (HR-MDS)",
            "Drug": "Venetoclax (VEN) and Decitabine (DEC)",
            "PFS": "None",
            "OS": "Median OS was 12.3 months in frontline (ND 16.7 months and sAML 10.4 months) and 7.6 months in R/R. Median OS in HR-MDS cohort was 12.1 months.",
            "ORR": "CR/CRi rates in NDAML, sAML, and R/RAML were 92%, 53%, and 40% respectively. ORR was 88%, 69%, 58% respectively. ORR in HR-MDS cohort was 52% (CR+CRi 33%).",
            "AE": "Most common grade 3/4 AEs were infection with neutropenia (38%), febrile neutropenia (32%), thrombocytopenia (17%), and neutropenia (15%).",
            "Other": "Median RFS was 9.1 months (ND 10 months and sAML 6.4 months) and 7.5 months in R/R. Median time to ANC \u2265500x10^9/L in cycle 1 was 41 days and Plt \u226550x10^9/L was 31 days in FL pts. 30-day mortality was 2% in FL and 5% in R/R cohort. 18% in FL and 22% in R/R cohort proceeded to SCT.",
            "Conclusion": "DEC10-VEN demonstrated expected safety and efficacy in a particularly high-risk cohort of ND and R/R pts, with no evidence that 10-days of DEC provides improved responses over standard 5-days DEC."
        },
        {
            "Title": "Predictive biomarkers of response to the IRAK4/FLT3 inhibitor emavusertib in hematological malignancies.",
            "Indication": "Hematological Malignancies (AML and high-risk MDS)",
            "Drug": "Emavusertib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Responders in hrMDS showed lower baseline expression of the NFkB-associated pro-inflammatory gene, IL1b, and lower protein levels in plasma of the angiogenic factor VEGF-A and the chemokine CXCL12. In AML samples, responders have higher baseline levels of VEGF-A. The concentration of soluble PD1 (sPD1) increased in on-treatment samples in hrMDS patients.",
            "Conclusion": "Several baseline biomarkers indicative of a response to emavusertib were identified in patients with hematological malignancies. These findings highlight distinct baseline biomarkers for AML and hrMDS related to the pathophysiology of hematological malignancies."
        },
        {
            "Title": "Treatment of acute myeloid leukemia with Orca-T",
            "Indication": "Acute Myeloid Leukemia (AML)",
            "Drug": "Orca-T",
            "PFS": "None",
            "OS": "100% at 12 months",
            "ORR": "None",
            "AE": "None",
            "Other": "Relapse-free survival at 12 months was 81.4% (95% CI: 62.9, 91.2). Non-relapse mortality was 0% at 12 months.",
            "Conclusion": "These encouraging results suggest that investigational Orca-T could represent a reduced toxicity alternative to conventional alloHSCT. This combination of Orca-T with myeloablative BFT led to >80% RFS without treatment-related mortality, and 100% overall survival in this AML patient population. These outcomes were accomplished with consistent and reliable cell manufacturing and distribution of Orca-T at a national scale. A multi-center randomized controlled phase 3 trial comparing Orca-T to SOC, utilizing BFT or TBI-based conditioning, will complete enrollment during the first half of 2024."
        },
        {
            "Title": "Biomarkers in a phase 1b study of investigational microbiome therapeutic SER-155 in adults undergoing allogeneic hematopoietic cell transplantation (allo-HCT)",
            "Indication": "Acute graft-versus-host disease (aGvHD)",
            "Drug": "SER-155",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Treatment-emergent adverse events (AEs) were reported in all patients with GI AEs the most common. No serious AEs (SAEs) were deemed related to SER-155. Most SAEs and AEs of special interest (ie, BSIs, invasive or GI infections) occurred between HCT and the 2nd SER-155 course. There were no deaths before Day 100. No BSIs were attributable to SER-155 strains.",
            "Other": "The rate of grade 2-4 aGvHD based on MAGIC criteria through Day 100 was 45.5%; no severe grade 3-4 aGvHD was observed. Median levels of plasma biomarkers REG3a, ST2 and inflammatory cytokines were not elevated relative to a reference range.",
            "Conclusion": "In this small open-label cohort, SER-155 was generally well-tolerated through Day 100 without drug-related SAEs and no severe aGvHD. Plasma biomarkers were not elevated at HCT Day 0 or neutrophil recovery. These preliminary observations align with the design and preclinical evaluation of SER-155."
        },
        {
            "Title": "Improved survival for myeloid malignancy patients receiving thiotepa/cyclophosphamide (TT/Cy) versus total body irradiation/cyclophosphamide (TBI/Cy) conditioning and allogeneic stem cell transplantation",
            "Indication": "Myeloid malignancies",
            "Drug": "Thiotepa/Cyclophosphamide (TT/Cy)",
            "PFS": "4-year RFS was 59.2% (95% CI, 52-67.2%) for TT/Cy, significantly better than median RFS of 341 days and 4-year RFS 35.2% (95% CI, 23.7-46.9%; p<.001) for recipients of TBI/Cy.",
            "OS": "4-year OS was 57.2% (95% CI, 49.2-65.2%) for TT/Cy, significantly better than with TBI/Cy, with median OS 373 days and 4-year OS 33.9% (95% CI, 22.3-45.5%; p<.001).",
            "ORR": "None",
            "AE": "TT/Cy was associated with significantly lower rates of mucositis, sinusoidal obstruction syndrome (SOS), TPN usage, multiorgan failure, and length of stay of primary admission compared to TBI/Cy.",
            "Other": "On multivariable analysis adjusting for age, sex, diagnosis, disease risk, comorbidity index (HCI), and degree of stem cell source, TT/Cy had significantly lower risk of death (HR 0.44, 95% CI, 0.30-0.65; p<.001) together with female sex (p=.03) and lower HCI (p=.046).",
            "Conclusion": "TT/Cy is an efficacious regimen for patients with myeloid malignancies undergoing myeloablative allogeneic SCT with less toxicity and improved RFS and OS compared with TBI/Cy. TT/Cy should be compared to other non-TBI containing busulfan-based regimens."
        },
        {
            "Title": "Thiotepa/cyclophosphamide (TT/Cy) preparative regimen for patients with acute lymphoblastic leukemia (ALL) undergoing allogeneic stem cell transplantation.",
            "Indication": "Acute lymphoblastic leukemia (ALL)",
            "Drug": "Thiotepa/cyclophosphamide (TT/Cy)",
            "PFS": "None",
            "OS": "TT/Cy: Median OS not reached, 4-year OS 54.5% (95% CI, 34.3-74.7%); TBI-based: Median OS 1,491 days, 4-year OS 50.9% (95% CI, 37.8-64.2%)",
            "ORR": "None",
            "AE": "TT/Cy: Mucositis 92%, TPN use 28%, Bacterial infection 12%; TBI/Cy: Mucositis 98%, TPN use 69%, Bacterial infection 49%",
            "Other": "TT/Cy was associated with significantly lower incidence of mucositis, TPN usage, bacterial infection, sinusoidal obstruction syndrome (SOS), and longer length of stay of primary transplant admission.",
            "Conclusion": "While there was no significant difference in OS or RFS for patients receiving either TT/Cy or TBI-based conditioning, TBI was associated with greater toxicity. Our data suggests that TT/Cy is an acceptable alternative to TBI-based conditioning for ALL patients undergoing myeloablative allogeneic SCT."
        },
        {
            "Title": "Impact of spleen volume on post-allogenic hematopoietic transplant outcomes in myelofibrosis: Utility of 3D volumetrics in splenomegaly.",
            "Indication": "Myelofibrosis",
            "Drug": "None",
            "PFS": "None",
            "OS": "Five-year OS was 72.8%.",
            "ORR": "None",
            "AE": "None",
            "Other": "Relapse was 11.0% at 1 year and 14.7% at 5 years. NRM at 5 years was 17.3%. ANC engraftment peaked at 28 days at 92.3%. Pre-HCT spleen volume did not have a significant impact on OS, disease-free survival, relapse, NRM, or ANC engraftment.",
            "Conclusion": "This retrospective study includes an analysis of spleen volume by 3D volumetrics, a more accurate measurement of spleen size, and whether it significantly impacts post-HCT outcomes. Our data analysis demonstrates that splenomegaly, massive or not, does not significantly impact transplant outcomes for patients receiving a reduced intensity conditioning regimen."
        },
        {
            "Title": "Outcomes of older adults undergoing allogeneic stem cell transplant with post-transplant cyclophosphamide.",
            "Indication": "Acute myeloid leukemia (AML) and myelodysplastic syndromes (MDS)",
            "Drug": "Post-transplant cyclophosphamide",
            "PFS": "None",
            "OS": "Post-Cy: One-year OS 45%, Five-year OS 19%; Non-Cy: One-year OS 68%, Five-year OS 32%",
            "ORR": "None",
            "AE": "Post-Cy: Acute GVHD 19%, Chronic GVHD 12%, One-year relapse rate 2%, One-year NRM 52%; Non-Cy: Acute GVHD 26%, Chronic GVHD 26%, One-year relapse rate 5%, One-year NRM 26%",
            "Other": "One-year GRFS: Post-Cy 33%, Non-Cy 37%; Five-year GRFS: Post-Cy 14%, Non-Cy 16%",
            "Conclusion": "We observed similar outcomes among patients aged 70+ receiving GVHD prophylaxis with post-Cy compared to non-Cy regimens. Importantly, non-Cy patients had HLA-matched donors, whereas mismatched donors were possible for the post-Cy group. In this way, post-Cy seems to have equalized the outcomes for fully matched and non-matched SCTs by yielding a similar one and five-year GRFS. Patients without a full match would otherwise have a poor outcome. We also found no significant difference in relapse rate, NRM, OS, and five-year GRFS between patients aged 70-74 and ages 75+, showing that numerical age should not be a contraindication to SCT with post-Cy."
        },
        {
            "Title": "Frequency of comedication of proton pump inhibitors with crystalline dasatinib in chronic myeloid leukemia and effects on TKI-bioavailability.",
            "Indication": "Chronic Myeloid Leukemia (CML)",
            "Drug": "Dasatinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Bioavailability of dasatinib reduced by 96% (Cmax) and 88% (AUC0-24) when co-medicated with omeprazole.",
            "Conclusion": "Despite warnings, comedication with PPI is common among dasatinib-treated CML patients. An optimized study design observed a higher than previously reported negative interaction of PPI comedication on crystalline dasatinib bioavailability, which may compromise clinical efficacy and risk CML disease progression. Selection of a non-crystalline, less pH-sensitive formulation of dasatinib appears more appropriate."
        },
        {
            "Title": "Pharmacokinetics and safety of asciminib (ASC) in pediatric patients (pts) with Philadelphia chromosome-positive (Ph+) chronic myeloid leukemia in chronic phase (CML-CP): Interim results from the ASC4KIDS study.",
            "Indication": "Philadelphia chromosome-positive (Ph+) Chronic Myeloid Leukemia in Chronic Phase (CML-CP)",
            "Drug": "Asciminib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "All patients on ASC PF experienced AEs; Grade \u22653 AEs were reported for 2/9 patients (22.2%). No predefined dose-limiting toxicities, serious AEs, or AEs leading to discontinuation were reported in either group.",
            "Other": "ASC exposure with the PF (with food) in pediatric patients was comparable to that of adult patients treated with ASC 40mg BID (fasted); the confirmed PF dose of 1.3mg/kg BID will continue to be tested in Part 2 of the study. ASC was safe and well tolerated in pediatric patients.",
            "Conclusion": "ASC exposure with the PF (with food) in pediatric patients was comparable to that of adult patients treated with ASC 40mg BID (fasted); the confirmed PF dose of 1.3mg/kg BID will continue to be tested in Part 2 of the study. ASC was safe and well tolerated in pediatric patients."
        },
        {
            "Title": "Clinical benefit of luspatercept treatment (tx) in transfusion-dependent (TD), erythropoiesis-stimulating agent (ESA)\u2013naive patients (pts) with very low-, low- or intermediate-risk myelodysplastic syndromes (MDS) in the COMMANDS trial.",
            "Indication": "Lower-risk myelodysplastic syndromes (LR-MDS)",
            "Drug": "Luspatercept",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Neutropenia and thrombocytopenia as the most common adverse events",
            "Other": "83.0% luspatercept-treated pts achieved \u226550% reduction in RBC units transfused over \u226512 weeks compared to 66.9% in the EA-treated group. Median duration of response was 130.0 weeks for luspatercept vs 77.0 weeks for EA. Mean Hb increase \u22651.5 g/dL over weeks 1\u201324 was achieved by 74.2% luspatercept pts vs 52.5% EA pts.",
            "Conclusion": "Luspatercept provided clinically meaningful outcomes, supporting its use as the preferred tx for ESA-naive pts with LR-MDS-associated anemia."
        },
        {
            "Title": "Efficacy of imetelstat on red blood cell (RBC)-transfusion independence (TI) in the absence of platelet transfusions or myeloid growth factors in IMerge.",
            "Indication": "Lower-risk myelodysplastic syndromes (LR-MDS)",
            "Drug": "Imetelstat",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Neutropenia and thrombocytopenia as the most common adverse events",
            "Other": "Significantly higher percentages of pts achieved 8-wk, 24-wk, and 1-y RBC-TI with imetelstat vs PBO in the absence of either platelet transfusions or growth factor support. 8-wk RBC-TI: 40% imetelstat vs 15% PBO; 24-wk RBC-TI: 28% imetelstat vs 3% PBO; 1-y RBC-TI: 18% imetelstat vs 2% PBO.",
            "Conclusion": "Results confirm that pts who achieve RBC-TI with imetelstat do so without developing severe neutropenia and thrombocytopenia, supporting the efficacy of imetelstat in TD pts with LR-MDS."
        },
        {
            "Title": "A phase I study of the myeloid cell leukemia 1 (MCL1) inhibitor tapotoclax (AMG 176) in patients with myelodysplastic syndromes after hypomethylating agent failure",
            "Indication": "Myelodysplastic Syndromes (MDS)",
            "Drug": "Tapotoclax (AMG 176)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Nausea (85%), Fatigue (43%), Diarrhea (29%), Palpitations (14%), Arrhythmias (possibly related, self-resolved), Prolonged QTc intervals (similar prolongation prior to exposure), No clinically significant troponin elevations",
            "Other": "No dose-limiting toxicities (DLTs) observed. No patients responded, trial terminated due to lack of clinical activity. Transient blast reduction in 2 of 3 patients with BM blasts \u22655%. 71% decreased RBC transfusions by \u22652 units between cycle 1-2, 14% attained transfusion independence for 7 weeks.",
            "Conclusion": "This phase 1 study demonstrated that tapotoclax was safe and tolerated in a high-risk cohort of patients with MDS after HMA failure. No DLTs were observed, and cardiac AEs self-resolved without dose reductions. Though no patients responded, some experienced transient blast reduction and decreased RBC transfusion burden. Given its manageable toxicity profile and transitory anti-leukemic and transfusion-independence effects, the use of tapotoclax in combination with HMA or other therapies may warrant further consideration."
        },
        {
            "Title": "Long-term survival adjusted for treatment crossover in patients (pts) with myelofibrosis (MF) treated with momelotinib (MMB) vs danazol (DAN) in the MOMENTUM trial",
            "Indication": "Myelofibrosis (MF)",
            "Drug": "Momelotinib (MMB) vs Danazol (DAN)",
            "PFS": "None",
            "OS": "HR, 0.89 (no crossover adjustment), HR, 0.78 (RPSFT model with recensoring), HR, 0.87 (RPSFT model without recensoring)",
            "ORR": "None",
            "AE": "None",
            "Other": "Risk of death reduced with MMB vs DAN by 11% (no crossover adjustment), 22% (RPSFT model with recensoring), and 13% (RPSFT model without recensoring). Risk of LFS event reduced with MMB vs DAN by 20% (no crossover adjustment), 36% (RPSFT model with recensoring), and 23% (RPSFT model without recensoring).",
            "Conclusion": "Consistent with the original unadjusted survival analysis, RPSFT models adjusting for the effects of treatment crossover showed prolonged OS and LFS in patients initially randomized to MMB vs those initially randomized to DAN; HRs in favor of MMB were lower after crossover adjustment. While these RPSFT analyses maintain the significance level of the original unadjusted analysis (P > .05), these results support the trend of long-term survival benefits with MMB vs DAN in JAKi\u2013experienced patients with MF and anemia."
        },
        {
            "Title": "Long term follow-up results of phase II clinical trial evaluating ruxolitinib (RUX) and azacitidine (AZA) combination therapy in patients (pts) with myelofibrosis (MF)",
            "Indication": "Myelofibrosis (MF)",
            "Drug": "Ruxolitinib (RUX) and Azacitidine (AZA)",
            "PFS": "None",
            "OS": "Median overall survival (OS) was 46 months (95% CI: 25-66)",
            "ORR": "72% objective responses, 61% clinical improvement (CI), 50% IWG-MRT CI spleen reduction, 61% TSS50",
            "AE": "Grade \u22653 adverse events (AEs) in 49% of patients, most common were pneumonia (16%), anemia (12%), and sepsis (8%). 3 patients had grade 5 AEs, and 4 patients were taken off study due to toxicity.",
            "Other": "Median event-free survival was 33 months (95% CI: 24-43), median duration of any objective response was 43 months (95% CI: 24-62). Transformation to AML occurred in 23% of patients with a median time to transformation of 19 months (1-46). 33% received a stem cell transplant (SCT), with a trend towards improved median OS in Int-2/high-risk DIPSS disease group who got SCT vs. those who did not (38 vs 27 months, p=0.2).",
            "Conclusion": "Long term follow-up data from this phase 2 clinical trial shows good efficacy of the AZA-RUX combination in MF, with durable responses and promising survival outcomes."
        },
        {
            "Title": "Efficacy and safety of ruxolitinib in patients with lower risk myelofibrosis: A single-arm, exploratory and prospective study.",
            "Indication": "Lower risk myelofibrosis",
            "Drug": "Ruxolitinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Grade \u22653 hematological TEAEs: anemia (10.0%), thrombocytopenia (2.5%). Grade 1 or 2 anemia (15.0%), thrombocytopenia (37.5%). Non-hematological TEAEs: infection (12.5%), upper respiratory tract infection (7.5%), urinary infection (2.5%), fever (2.5%).",
            "Other": "Spleen length reduction \u226550% from baseline at week 48: 65.0%. Reduction in Total Symptom Score (TSS50) at week 48: 25%.",
            "Conclusion": "Ruxolitinib is an effective treatment for patients with intermediate-1 and low risk MF, resulting in improved spleen and symptom responses, along with fewer hematological and non-hematological TEAEs. This trial was registered as ChiCTR2200064250 at ClinicalTrials.gov."
        },
        {
            "Title": "Association between hemoglobin (Hb) improvement and patient-reported outcomes (PROs) in patients (pts) with myelofibrosis (MF) and anemia: Post hoc pooled analysis of momelotinib (MMB) phase 3 trials.",
            "Indication": "Myelofibrosis with anemia",
            "Drug": "Momelotinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Hb improvement at week 24 was associated with improved HRQOL and symptoms in pts with MF and anemia.",
            "Conclusion": "In these trial populations, Hb improvement at week 24 was associated with improved HRQOL and symptoms in pts with MF and anemia. These results highlight the value of treatments with anemia-related benefits in improving the patient experience in MF. Clinical trial information: NCT04173494; NCT02101268; NCT01969838."
        },
        {
            "Title": "Bromodomain and extra-terminal (BET) inhibitor INCB057643 in patients with relapsed or refractory myelofibrosis (MF) and other advanced myeloid neoplasms: A phase 1 study.",
            "Indication": "Relapsed or refractory myelofibrosis and other advanced myeloid neoplasms",
            "Drug": "INCB057643",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Thrombocytopenia (n=15), anemia (n=6), serious AEs (n=6), dose-limiting toxicities (n=3).",
            "Other": "SVR35 at week 24: 3/3 evaluable pts receiving \u226510 mg monotherapy, 1/5 evaluable pts in combination group. TSS50: 6/10 evaluable monotherapy pts, 6/11 combination therapy pts. Anemia response: 2/15 monotherapy pts.",
            "Conclusion": "INCB057643 monotherapy (4\u201310 mg qd) and combined (4 and 6 mg qd) with RUX was generally well tolerated, with no treatment-related fatal events. Improvements in spleen size and symptom burden were observed in both the monotherapy and combination therapy groups. Dose finding is complete for monotherapy and dose escalation is ongoing in the combination therapy group. Clinical trial information: NCT04279847."
        },
        {
            "Title": "Impact of pacritinib on symptoms in with thrombocytopenia and myelofibrosis who require red blood cell transfusion.",
            "Indication": "Myelofibrosis with thrombocytopenia and anemia",
            "Drug": "Pacritinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Treatment emergent grade 3 anemia: PAC (17.1%), BAT (16.3%); Treatment emergent grade 3 fatigue: PAC (0%), BAT (5%)",
            "Other": "Transfusion independence response (TI-R) by week 24: PAC (54.5%), BAT (0%); Total Symptom Score (TSS) response: PAC (31%), BAT (9%), RUX (15%); Patient Global Impression of Change (PGIC) at week 24: PAC (37%), BAT (9%), RUX (8%)",
            "Conclusion": "PAC has previously demonstrated improvement in symptoms in pts with MF. These findings are consistent in showing that PAC compared to BAT or low dose RUX provides substantial symptom benefit in those pts who require RBC transfusions. PAC could therefore be a potential option to address an unmet need for this patient population."
        },
        {
            "Title": "Efficacy of pacritinib in patients with myelofibrosis who have both thrombocytopenia and anemia.",
            "Indication": "Myelofibrosis with thrombocytopenia and anemia",
            "Drug": "Pacritinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Spleen volume reduction (SVR) \u226535%: PAC (20%), BAT (0%); Total Symptom Score (TSS) reduction \u226550%: PAC (32.5%), BAT (10.5%); Patient Global Impression of Change (PGIC) at week 24: PAC (30%), BAT (13.2%); Transfusion independence response (TI-R): PAC (26%), BAT (8%)",
            "Conclusion": "PAC at full dose demonstrates efficacy for spleen, symptoms, and transfusion response in pts with MF and both thrombocytopenia and anemia. These findings suggest PAC may be an effective option to address the unmet need for pts with MF and bicytopenias."
        },
        {
            "Title": "Real-world treatment patterns and outcomes in patients with myelofibrosis treated with pacritinib in the United States.",
            "Indication": "Myelofibrosis",
            "Drug": "Pacritinib",
            "PFS": "None",
            "OS": "1-year OS probability: Overall (69.4%), 1L PAC (77.3%), 1L PAC with PLT <50x10^9/L (75.2%)",
            "ORR": "None",
            "AE": "None",
            "Other": "Median PLT count at index: 81x10^9/L; Median PLT count at post-index day 360: 96x10^9/L; Median Hb level at index: 8.8g/dL; Median Hb level at post-index day 360: 10.4g/dL",
            "Conclusion": "In addition to spleen and symptom benefits, real-world outcomes demonstrate stability or improvement in thrombocytopenia and anemia in MF pts treated with PAC, with OS in the 1L setting comparing favorably with other JAK inhibitor historical controls."
        },
        {
            "Title": "A phase Ib, open-label study of add-on therapy with CK0804 in participants with myelofibrosis and suboptimal response to ruxolitinib.",
            "Indication": "Myelofibrosis with suboptimal response to ruxolitinib",
            "Drug": "CK0804 (T-regs) added to ruxolitinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "One patient experienced infusion reaction to second dose of CK0804 likely due to the excipient dimethylsulfoxide and withdrew consent. One patient died of unrelated cause prior to the infusion 6. There were no non-hematologic or hematologic adverse events.",
            "Other": "Two transfusion dependent patients had decreased monthly need for transfusions by the end of the sixth cycle: 4 to 2.8 units and 1.2 to 0.8 units, respectively. All patients noticed symptoms improvement; median best decline in MPN-SAF TSS was -38% (range, -20% to -71%); with \u226550% reduction in 3 patients. One patient achieved \u226535% spleen volume reduction at week 12.",
            "Conclusion": "This preliminary analysis of run-in phase of study evaluating CXCR4 enriched Tregs cell therapy as addition to ruxolitinib shows initial safety with no myelosuppressive adverse events and promising clinical activity. Active enrollment of participants to the expansion cohort is ongoing."
        },
        {
            "Title": "A phase 1b/2 study of pivekimab sunirine (PVEK, IMGN632) in combination with venetoclax/azacitidine for patients with newly diagnosed CD123-positive acute myeloid leukemia.",
            "Indication": "Newly diagnosed CD123-positive acute myeloid leukemia (AML)",
            "Drug": "Pivekimab sunirine (PVEK, IMGN632), venetoclax, azacitidine",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "MRD negativity rate of 76% (22/29) in dose expansion cohorts 1 and 2",
            "Conclusion": "The PVEK+AZA+VEN triplet in patients with ND unfit AML is currently enrolling across sites in France, Germany, Italy, Spain, UK, and USA."
        },
        {
            "Title": "A first-in-human phase 1, multicenter, open-label study of CB-012, a next-generation CRISPR-edited allogeneic anti-CLL-1 CAR-T cell therapy for adults with relapsed/refractory acute myeloid leukemia (AMpLify).",
            "Indication": "Relapsed/refractory acute myeloid leukemia (AML)",
            "Drug": "CB-012",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "CB-012 significantly reduced tumor burden and increased survival in murine xenograft models of AML",
            "Conclusion": "The dose escalation portion of the study is actively enrolling patients."
        },
        {
            "Title": "A phase 1 single-arm, open-label study of emavusertib (CA-4948) in combination with azacitidine and venetoclax in patients (pts) with acute myeloid leukemia (AML) in complete response (CR) with measurable residual disease (MRD).",
            "Indication": "Acute myeloid leukemia (AML) in complete response with measurable residual disease",
            "Drug": "Emavusertib (CA-4948), azacitidine, venetoclax",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The study will enroll approximately 24 patients at 5 to 10 sites globally."
        },
        {
            "Title": "Covalent-103: A phase 1, open-label, dose-escalation and expansion study of BMF-500, an oral covalent FLT3 inhibitor, in adults with acute leukemia (AL).",
            "Indication": "Acute leukemia (AL), including AML, ALL, or MPAL",
            "Drug": "BMF-500",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The study was initiated in July 2023, is currently in dose escalation, and plans to enroll approximately 110 patients total."
        },
        {
            "Title": "A first-in-human, phase 1, dose escalation study of SGR-2921 as monotherapy in patients with relapsed/refractory acute myeloid leukemia or myelodysplastic syndrome.",
            "Indication": "Relapsed/Refractory Acute Myeloid Leukemia (AML) or Myelodysplastic Syndrome (MDS)",
            "Drug": "SGR-2921",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The primary objectives are to evaluate the safety and tolerability of SGR-2921 as monotherapy and to identify the RP2D. Secondary objectives include evaluating SGR-2921 pharmacokinetics and investigating preliminary antitumor activity."
        },
        {
            "Title": "Phase 1b trial of IRAK1/4 inhibition for low-risk myelodysplastic syndrome.",
            "Indication": "Low-Risk Myelodysplastic Syndrome (LR-MDS)",
            "Drug": "R289 (prodrug for R835)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Most AEs were mild and transient; the most common AEs (mild/moderate) were headache and GI disturbance.",
            "Other": "None",
            "Conclusion": "The primary endpoint is safety and tolerability of R289. Secondary endpoints include preliminary efficacy and pharmacokinetics of R289."
        },
        {
            "Title": "VERIFY: A randomized controlled phase 3 study of the hepcidin mimetic rusfertide (PTG-300) in patients with polycythemia vera (PV).",
            "Indication": "Polycythemia Vera (PV)",
            "Drug": "Rusfertide (PTG-300)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The primary endpoint is the proportion of patients achieving a response in Part 1a from Week 20-32. A response is defined as the absence of TP eligibility. Secondary endpoints are the mean number of TPs from Week 0-32; proportion of patients with HCT <45% from Week 0-32; mean change from baseline to Week 32 in total fatigue score measured by PROMIS Short Form and in total symptom score measured by MFSAF v4.0."
        },
        {
            "Title": "Phase 2 study evaluating selinexor monotherapy in patients with JAKi-na\u00efve myelofibrosis and moderate thrombocytopenia.",
            "Indication": "JAKi-na\u00efve Myelofibrosis with Moderate Thrombocytopenia",
            "Drug": "Selinexor",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "In the selinexor 60 mg cohort, the most common AEs were nausea (79%), anemia (64%), thrombocytopenia (64%), and fatigue (57%). Nausea events were predominantly Grade 1 and transient in nature.",
            "Other": "None",
            "Conclusion": "The primary endpoint is SVR35 at week 24. Key secondary endpoints are safety, TSS50 at week 24, anemia response at week 24, overall survival, and overall response rate."
        },
        {
            "Title": "Phase 3 randomized double-blind study evaluating selinexor, an XPO1 inhibitor, plus ruxolitinib in JAKi-na\u00efve myelofibrosis.",
            "Indication": "Myelofibrosis (MF)",
            "Drug": "Selinexor plus Ruxolitinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "SVR35 and TSS50 achieved by 79% and 58% of the 60 mg cohort intent-to-treat population at Week 24, respectively.",
            "AE": "Nausea (79%), anemia (64%), thrombocytopenia (64%), and fatigue (57%). Treatment-related AEs leading to treatment discontinuation: thrombocytopenia (n=1) and neuropathy (n=1).",
            "Other": "Response rates were consistent across subgroups, including sex and regardless of ruxolitinib starting dose.",
            "Conclusion": "These data provide strong support to further evaluate selinexor (60 mg) and ruxolitinib in patients with JAKi-na\u00efve MF."
        },
        {
            "Title": "Phase 2 study of the lysine-specific demethylase 1 (LSD1) inhibitor bomedemstat in patients with polycythemia vera (PV).",
            "Indication": "Polycythemia Vera (PV)",
            "Drug": "Bomedemstat",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoints: safety and the proportion of patients who achieve a reduction in hematocrit to <45% without phlebotomy by week 36. Secondary endpoints: durability of reduction in hematocrit <45% without phlebotomy; incidence and durability of reduction in platelet count to \u2264450x10^9/L and white blood cell count to <10x10^9/L; incidence of new thrombotic or major hemorrhagic events; reduction in spleen volume by week 36 in patients with an enlarged spleen at baseline; progression to PPV-MF or myelodysplastic syndrome or transformation to AML; pharmacokinetics; and change in patient-reported symptom burden assessed using the MSAF v4.0 and PGIC. Exploratory endpoints: change in the concentration of circulating inflammatory cytokines and growth factors, change in mutant allele frequency of JAK2 and other mutations, and change in bone marrow fibrosis grade.",
            "Conclusion": "Recruitment for this study is currently underway in Australia, the United Kingdom, and the United States."
        },
        {
            "Title": "Phase 3 study of the lysine-specific demethylase 1 (LSD1) inhibitor bomedemstat in patients with essential thrombocythemia (ET).",
            "Indication": "Essential Thrombocythemia (ET)",
            "Drug": "Bomedemstat",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: durable clinicohematologic response rate per modified ELN criteria. Secondary endpoints: duration of clinicohematologic response, duration of hematologic remission, change from baseline in total symptom and fatigue score per MFSAF v4.0 criteria, change from baseline in total fatigue score per the PROMIS Fatigue SF-7a scale, incidence of thrombotic or major hemorrhagic events, transformation to post-ET myelofibrosis or myelodysplastic syndrome/acute myeloid leukemia, event-free survival, and safety. Exploratory endpoints: pharmacokinetics, the proportion of patients reporting stability or improvement versus decline on the MSAF v4.0 and PROMIS Fatigue SF-7a domains, and molecular biomarkers.",
            "Conclusion": "Approximately 300 patients will be enrolled."
        },
        {
            "Title": "Upfront allo-HSCT after intensive chemotherapy for untreated aggressive adult T-cell leukemia-lymphoma (ATL): JCOG0907, a single-arm, phase 3 trial.",
            "Indication": "Aggressive adult T-cell leukemia-lymphoma (ATL)",
            "Drug": "Allogeneic hematopoietic stem cell transplantation (allo-HSCT)",
            "PFS": "None",
            "OS": "3-year OS of 44.0% (90% CI, 36.0-51.6); MST was 3.0 years (95% CI, 1.5-5.8) for study transplants and 2.5 years (95% CI, 1.4-4.8) for all transplants; HR for OS for study transplants compared with non-study transplants was 0.92 (95% CI, 0.55-1.51)",
            "ORR": "None",
            "AE": "Treatment-related deaths (TRD) were 16.7% in related transplants and 20.7% in UR transplants",
            "Other": "Among 70 pts who died, causes of death were disease progression in 34, TRD due to protocol treatment in 9, TRD due to post-protocol treatment in 21, and other disease in 6",
            "Conclusion": "Upfront allo-HSCT can be recommended for chemotherapy-sensitive pts with aggressive ATL, but its survival benefit is not clear considering immortal time bias suggested by multivariable analysis with a time-dependent covariate."
        },
        {
            "Title": "CLAMP: A phase II prospective study of camrelizumab combined with pegaspargase, etoposide, and high-dose methotrexate in patients with natural killer (NK)/T-cell lymphoma.",
            "Indication": "Natural killer (NK)/T-cell lymphoma (NKTCL)",
            "Drug": "Camrelizumab combined with pegaspargase, etoposide, and high-dose methotrexate (CLAMP)",
            "PFS": "2-year PFS rates for the whole cohort were 72.81%; 2-year PFS rates for group A were 91.67% and for group B were 53.83%",
            "OS": "2-year OS rates for the whole cohort were 88.03%; 2-year OS rates for group A were 91.67% and for group B were 77.53%",
            "ORR": "Overall response rate (ORR) of 95.12% (39/41); ORR for group A patients was 100% (19/19) and 90.91% (20/22) for group B patients",
            "AE": "Hematologic toxicity was common, yet non-hematologic toxicity was mild and could be well controlled by supportive treatments, except 2 patients had delayed methotrexate metabolism and received dialysis. No treatment-related death was observed.",
            "Other": "None",
            "Conclusion": "The CLAMP regimen is effective and safe and seems to reduce the incidence of CNS involvement and HLH. The CLAMP regimen may be a promising option in the treatment of NKTCL lymphoma."
        },
        {
            "Title": "Safety and efficacy of armored huCART19-IL18 in patients with relapsed/refractory lymphomas that progressed after anti-CD19 CAR T cells.",
            "Indication": "Relapsed/Refractory Non-Hodgkin Lymphomas (NHL)",
            "Drug": "huCART19-IL18",
            "PFS": "8.7 months (90% CI 5-NR)",
            "OS": "NR (90% CI 25 months-NR)",
            "ORR": "80% (90% CI: 60-93%)",
            "AE": "CRS occurred in 71% of patients (G1 in 38%, G2 in 19%, G3 in 14%). ICANS occurred in 14% of patients (G1 in 10%, G2 in 5%). The most common G3 adverse events included fatigue (38%), hypotension (29%), and low fibrinogen (23%).",
            "Other": "CR 50% (90% CI: 30-70%), PR 30% (90% CI: 14-51%), mDOR 10 months (5.5-NR). No correlation between cell dose and outcome was identified, but response rates and mean expansion were higher in patients previously exposed to CD28 CAR than those who had prior 4-1BB CAR.",
            "Conclusion": "Treatment with huCART19-IL18 has an acceptable safety profile and produced durable remissions in heavily pre-treated patients with R/R NHL despite prior CAR T-cell therapy. The subtype of the preceding CAR product may influence the expansion and effectiveness of huCART19-IL18."
        },
        {
            "Title": "Benefit of rituximab maintenance after first-line bendamustine-rituximab in mantle cell lymphoma.",
            "Indication": "Mantle Cell Lymphoma (MCL)",
            "Drug": "Rituximab maintenance (RM) after first-line bendamustine-rituximab (BR)",
            "PFS": "Not provided",
            "OS": "136.1 months vs 74.3 months (adjusted HR 0.57, 95% CI 0.44-0.75)",
            "ORR": "Not provided",
            "AE": "Not provided",
            "Other": "EFS: median 47.1 vs 29.7 months (adjusted HR 0.59, 95% CI 0.48-0.73). EFS2: median 89.1 vs 48.3 months (adjusted HR 0.63, 95% CI 0.50-0.81).",
            "Conclusion": "In this large multicenter study, RM after 1L BR was associated with improved EFS and OS, supporting its use in routine practice for patients with newly diagnosed MCL."
        },
        {
            "Title": "Efficacy and safety of ibrutinib plus venetoclax in patients with mantle cell lymphoma (MCL) and TP53 mutations in the SYMPATICO study.",
            "Indication": "Mantle Cell Lymphoma (MCL) with TP53 mutations",
            "Drug": "Ibrutinib plus venetoclax",
            "PFS": "20.9 months (14.7-30.6)",
            "OS": "47.1 months (30.6-NE)",
            "ORR": "84% (73-91%)",
            "AE": "The most frequent grade \u22653 AEs (in \u226510% of patients) were neutropenia (32%), anemia (15%), and thrombocytopenia (15%).",
            "Other": "CR rate: 57% (45-68%). Median duration of response: 26.0 months (16.8-32.2). Median duration of CR: 32.2 months (18.7-NE).",
            "Conclusion": "Ibrutinib plus venetoclax demonstrated promising efficacy with high CR rates and durable remissions in high-risk patients with MCL and TP53 mutations in SYMPATICO, the largest single-study cohort of such patients reported to date."
        },
        {
            "Title": "Glofitamab monotherapy in patients with heavily pretreated relapsed/refractory (R/R) mantle cell lymphoma (MCL): Updated analysis from a phase I/II study.",
            "Indication": "Relapsed/Refractory Mantle Cell Lymphoma (MCL)",
            "Drug": "Glofitamab",
            "PFS": "Median PFS was 16.8 months. In post-BTKi patients, median PFS was 8.6 months.",
            "OS": "None",
            "ORR": "Overall Response Rate (ORR) was 85.0%, Complete Response (CR) rate was 78.3%. In post-BTKi patients, ORR was 74.2%, CR rate was 71.0%.",
            "AE": "Cytokine release syndrome (CRS) was the most common AE (70.0%; Grade 1/2, 58.3%).",
            "Other": "Median Duration of Complete Response (DOCR) was 15.4 months. Estimated 12-month DOCR and Duration of Response (DOR) rates were 71.0% and 66.6%, respectively.",
            "Conclusion": "Fixed-duration glofitamab continues to demonstrate compelling response rates that are maintained beyond EOT, with long-term durability observed in heavily pretreated patients with R/R MCL, including patients with prior BTKi therapy. The safety profile was manageable and CRS mainly low grade."
        },
        {
            "Title": "Outcomes in high-risk subgroups after fixed-duration ibrutinib + venetoclax for chronic lymphocytic leukemia (CLL)/small lymphocytic lymphoma (SLL): Up to 5.5 years of follow-up in the phase 2 CAPTIVATE study.",
            "Indication": "Chronic Lymphocytic Leukemia (CLL)/Small Lymphocytic Lymphoma (SLL)",
            "Drug": "Ibrutinib + Venetoclax",
            "PFS": "5-year PFS rate was 67% overall. In patients with high-risk genomic features: del(17p)/mutated TP53 41%, complex karyotype 57%, del(11q) 64%, unmutated IGHV 68%.",
            "OS": "5-year OS rate was 96% overall.",
            "ORR": "None",
            "AE": "18 second malignancies occurred in 13 patients.",
            "Other": "None",
            "Conclusion": "With up to 5.5 years of follow-up, fixed-duration ibrutinib + venetoclax continues to provide clinically meaningful PFS in patients with high-risk genomic features, as well as in the overall population. Ibrutinib-based retreatment provides promising responses in patients needing subsequent therapy after the all-oral fixed-duration regimen of ibrutinib + venetoclax."
        },
        {
            "Title": "Real-world outcomes of lisocabtagene maraleucel (liso-cel) in patients with Richter transformation (RT) from the Center for International Blood and Marrow Transplant Research (CIBMTR).",
            "Indication": "Richter Transformation (RT)",
            "Drug": "Lisocabtagene Maraleucel (liso-cel)",
            "PFS": "Median PFS was not reached; estimated 12-month PFS was 54%.",
            "OS": "Median OS was not reached; estimated 12-month OS was 67%.",
            "ORR": "Overall Response Rate (ORR) was 76%, Complete Response (CR) rate was 66%.",
            "AE": "70% of patients had CRS (Grade 3, 0%; Grade 4, 3%; Grade 5, 3%). ICANS was seen in 47% of patients (Grade 3, 17%; Grade 4, 10%; Grade 5, 0%). Prolonged cytopenia occurred in 17% of patients.",
            "Other": "Median time to first response was 1.1 months. Median Duration of Response (DOR) was not reached; 12-month DOR rate was 77%.",
            "Conclusion": "Patients with RT have poor prognosis with a high unmet need. Here, we show that liso-cel provided meaningful clinical benefit with low incidences of Grade \u22653 CRS and ICANS observed. The high CR rate is promising in this difficult-to-treat population, and longer follow-up in a larger cohort is needed."
        },
        {
            "Title": "Randomized phase II/III study of R-CHOP +/- venetoclax in previously untreated MYC/BCL2 double expressor diffuse large B cell lymphoma (DLBCL): Alliance A051701.",
            "Indication": "Diffuse Large B Cell Lymphoma (DLBCL)",
            "Drug": "Venetoclax",
            "PFS": "12-m PFS estimates of 77% in Arm 1, 76% in Arm 2 [HR=0.98 (95% CI: 0.48\u20132.01), p=0.95]",
            "OS": "12-mo OS estimates of 94% in Arm 1 and 79% in Arm 2 [HR=1.27 (95% CI: 0.57\u20132.79), p=0.56]",
            "ORR": "EOT overall and complete response rates among patients with an EOT assessment were 87.5% and 70% in Arm 1, and 90% and 82% in Arm 2",
            "AE": "Grade \u22653 adverse events occurred more frequently in Arm 2 (42% vs 76%). The most commonly increased grade \u22653 AEs included neutropenia (20% vs 47%), anemia (4% vs 25.5%), thrombocytopenia (5.5% vs 24%), febrile neutropenia (7% vs 16%) and fatigue (0% vs 11%)",
            "Other": "No grade 5 AEs on treatment were reported on Arm 1, and 3 were reported on Arm 2 (lung infection and sudden death NOS, both possibly related; respiratory failure, unlikely related)",
            "Conclusion": "Adding venetoclax to R-CHOP in DEL pts did not improve PFS and resulted in increased toxicity. The study did not meet its primary endpoint in phase II, and so did not proceed to phase III."
        },
        {
            "Title": "Lisocabtagenemaraleucel (liso-cel) vs standard of care (SOC) with salvage chemotherapy (CT) followed by autologous stem cell transplantation (ASCT) as second-line (2L) treatment in patients (pt) with R/R large B-cell lymphoma (LBCL): 3-year follow-up (FU) from the randomized, phase 3 TRANSFORM study.",
            "Indication": "Relapsed/Refractory Large B-Cell Lymphoma (R/R LBCL)",
            "Drug": "Lisocabtagenemaraleucel (liso-cel)",
            "PFS": "Median PFS: NR for liso-cel arm, 6.2 months for SOC arm [HR=0.422 (95% CI: 0.279\u20130.639)]",
            "OS": "Median OS: NR for liso-cel arm, NR for SOC arm [HR=0.757 (95% CI: 0.481\u20131.191)]",
            "ORR": "ORR: 78.3% for liso-cel arm, 45% for SOC arm",
            "AE": "Safety results were consistent with the primary analysis. Cellular kinetics and B-cell aplasia will be presented.",
            "Other": "Median EFS: 29.5 months for liso-cel arm, 2.4 months for SOC arm [HR=0.375 (95% CI: 0.259\u20130.542)]",
            "Conclusion": "After median FU of 33.9 months, liso-cel as 2L treatment in pts with primary refractory or early relapsed LBCL resulted in deepening of response and continued improvement in efficacy endpoints over SOC, confirming the ongoing benefit of liso-cel."
        },
        {
            "Title": "Epcoritamab with rituximab + lenalidomide (R2) in previously untreated (1L) follicular lymphoma (FL) and epcoritamab maintenance in FL: EPCORE NHL-2 arms 6 and 7.",
            "Indication": "Follicular Lymphoma (FL)",
            "Drug": "Epcoritamab",
            "PFS": "None",
            "OS": "None",
            "ORR": "Arm 6: ORR was 95%; CR rate was 85%. Arm 7: Converted to CR: 100%",
            "AE": "Arm 6: The most common treatment-emergent AEs were COVID-19 (63%), CRS (56%), and neutropenia (56%). CRS was low grade (41% G1, 15% G2) and resolved. Arm 7: The most common TEAEs were COVID-19 (70%) and CRS (55%). CRS was G1 (40%) or G2 (15%) and resolved.",
            "Other": "No ICANS or clinical tumor lysis syndrome occurred in either arm.",
            "Conclusion": "In arm 6, epcoritamab + R2 continued to show deep, durable responses in 1L FL; safety was manageable and consistent with prior reports. In arm 7, the first epcoritamab maintenance data in FL showed conversions from PR to CR, with manageable safety and no new safety signals. The data warrant further evaluation of epcoritamab-based, chemotherapy-free regimens in FL."
        },
        {
            "Title": "EPCORE NHL-1 follicular lymphoma (FL) cycle (C) 1 optimization (OPT) cohort: Expanding the clinical utility of epcoritamab in relapsed or refractory (R/R) FL.",
            "Indication": "Relapsed/Refractory Follicular Lymphoma (R/R FL)",
            "Drug": "Epcoritamab",
            "PFS": "None",
            "OS": "None",
            "ORR": "ORR was 91% and CR rate was 68%",
            "AE": "CRS events primarily occurred during C1, and none led to epcoritamab discontinuation. ICANS: 0 in C1OPT cohort, 8 (6%) in pivotal cohort. CTLS: 0 in both cohorts.",
            "Other": "Among MRD-evaluable pts (n=44), MRD negativity was achieved in 64% (n=28).",
            "Conclusion": "CRS mitigation measures in C1 further improved the safety of epcoritamab in pts with R/R FL by substantially reducing the rate and severity of CRS compared with previous reports. For pts treated in the outpatient setting, CRS was identified and appropriately treated, with few G2 events, no G\u22653 events, and all events resolving. Response rates, MRD-negativity rate, and time to CR suggest that C1OPT did not impact efficacy. These data support the potential use of SC epcoritamab as an off-the-shelf, outpatient tx option for pts with R/R FL."
        },
        {
            "Title": "Lisocabtagene maraleucel (liso-cel) in patients (pt) with R/R mantle cell lymphoma (MCL): Subgroup analyses by number of prior systemic lines of therapy (LOT) and by response to prior Bruton tyrosine kinase inhibitor (BTKi) from the TRANSCEND NHL 001 MCL cohort (TRANSCEND-MCL).",
            "Indication": "Relapsed/Refractory Mantle Cell Lymphoma (R/R MCL)",
            "Drug": "Lisocabtagene maraleucel (liso-cel)",
            "PFS": "15.3 months (95% CI: 6.6\u201324.9)",
            "OS": "18.2 months (95% CI: 12.9\u201336.3)",
            "ORR": "83% (69/83)",
            "AE": "Grade \u22653 TEAEs: 86%, most common was neutropenia (33\u201358%)",
            "Other": "CRR: 72%, Median DOR: 15.7 months, CRS: Grade 1\u20132 (60%), Grade 3\u20134 (8%), NE: Grade 1\u20132 (22%), Grade 3\u20134 (9%)",
            "Conclusion": "In TRANSCEND-MCL, all subgroups benefited from liso-cel and responses were generally comparable to the overall population, with numerically shorter duration in pts with \u22655 prior LOTs and disease refractory to prior BTKi, supporting study of liso-cel in earlier LOTs."
        },
        {
            "Title": "Timdarpacept (IMM01) in combination with tislelizumab in prior anti-PD-1 failed classical Hodgkin lymphoma: An open label, multicenter, phase II study (IMM01-04) evaluating safety as well as preliminary anti-tumor activity.",
            "Indication": "Relapsed/Refractory Classical Hodgkin Lymphoma (R/R cHL)",
            "Drug": "Timdarpacept (IMM01) and tislelizumab",
            "PFS": "PFS rate at 3 months: 84.5%, PFS rate at 6 months: 68.7%",
            "OS": "None",
            "ORR": "65.6% (21/32)",
            "AE": "TRAEs: 100%, most common were WBC decreased (48.5%), PLT decreased (42.4%), anemia (36.4%), ANC decreased (33.3%), lymphocyte decreased (30.3%)",
            "Other": "CRR: 18.8% (6/32), DCR: 93.8% (30/32), Median TTR: 1.6 months, Median DoR not reached",
            "Conclusion": "IMM01-04 showed a robust anti-tumor effectivity with a well-tolerated safety profile in prior anti-PD-1 failed cHL patients. The phase II study is ongoing."
        },
        {
            "Title": "Glofitamab monotherapy retreatment in patients with heavily pre-treated relapsed or refractory (R/R) non-Hodgkin lymphoma (NHL): Results from a phase I/II study.",
            "Indication": "Relapsed/Refractory Non-Hodgkin Lymphoma (R/R NHL)",
            "Drug": "Glofitamab",
            "PFS": "None",
            "OS": "None",
            "ORR": "69.2% (9/13)",
            "AE": "Cytokine release syndrome: 53.8% (all Grade 1/2)",
            "Other": "CR: 38.5% (5/13), PR: 30.8% (4/13), Median retreated follow-up time: 25.9 months",
            "Conclusion": "Glofitamab monotherapy retreatment was efficacious in heavily pre-treated pts with R/R NHL who responded to initial glofitamab treatment before subsequent progression. The safety profile was consistent with that of initial treatment."
        },
        {
            "Title": "Mosunetuzumab with polatuzumab vedotin: Subgroup analyses in patients with primary refractory or early relapsed large B-cell lymphoma (LBCL).",
            "Indication": "Primary refractory or early relapsed large B-cell lymphoma (LBCL)",
            "Drug": "Mosunetuzumab with polatuzumab vedotin",
            "PFS": "Refractory: 8.1 months (HR not provided), Relapsed: 12.7 months (HR not provided), Overall: 10.6 months (HR not provided)",
            "OS": "Refractory: 13.3 months (HR not provided), Relapsed: Not reached (HR not provided), Overall: 16.9 months (HR not provided)",
            "ORR": "Refractory: 49%, Relapsed: 74%, Overall: 62.1%",
            "AE": "Grade 3 CRS in one patient in each subgroup, ICANS-like events in 2/45 refractory patients (4%) and 3/27 relapsed patients (11%)",
            "Other": "CR rate: Refractory: 35.6%, Relapsed: 59.3%, Overall: 44.4%; Median DOR: Refractory: 20.5 months, Relapsed: 20.8 months, Overall: 20.5 months; Median DOCR: Not reached in all groups",
            "Conclusion": "Mosun-pola demonstrated durable benefits in ORR and CR rates irrespective of a poor response to 1L treatment. Outcomes for patients with refractory or relapsed LBCL are encouraging."
        },
        {
            "Title": "EX103: A newly designed CD20xCD3 molecule in heavily pre-treated patients with B-cell non-Hodgkin lymphoma from a phase I/II trial.",
            "Indication": "B-cell non-Hodgkin lymphoma",
            "Drug": "EX103",
            "PFS": "None",
            "OS": "None",
            "ORR": "Aggressive B-NHL: 78.6%, Indolent B-NHL: 100%",
            "AE": "CRS: Grade 1: 78.3%, Grade 2: 13.0%, No Grade 3 or higher events",
            "Other": "CR rate: Aggressive B-NHL: 50.0%, Indolent B-NHL: 25.0%; Overall DCR: 89.5%; Longest duration of response: 14 months",
            "Conclusion": "EX103 can induce deep and durable responses in heavily pretreated patients with R/R B-NHL, including those who failed CAR-T treatment, CD19xCD3 bispecific antibody treatment, and ASCT."
        },
        {
            "Title": "Interim results from the ELiPSE-1 study: A phase 1, multicenter, open-label study of CNTY-101 in subjects with relapsed or refractory CD19-positive B-cell malignancies.",
            "Indication": "Relapsed or refractory CD19-positive B-cell malignancies",
            "Drug": "CNTY-101",
            "PFS": "None",
            "OS": "None",
            "ORR": "100e6 cells: 25%, 300e6 cells: 67%",
            "AE": "No DLTs, GvHD, or ICANS observed. CRS: 1 Grade 1, 1 Grade 2",
            "Other": "CRR: 100e6 cells: 25%, 300e6 cells: 33%; CNTY-101 rapidly traffics out of circulation after infusion and is observed via cell-free DNA on Day 3 and detected up to 28 days.",
            "Conclusion": "CNTY-101 administered as a single dose in multiple cycles has demonstrated a manageable safety profile and preliminary evidence of efficacy. Allo-Evasion edits may allow for repeat dosing for multiple cycles without allorejection in the absence of lymphodepletion. Preliminary efficacy supports dosing at higher dose levels and with more dose-intense regimens."
        },
        {
            "Title": "A CRISPR-edited allogeneic anti-CD19 CAR-T cell therapy with a PD-1 knockout (CB-010) in patients with relapsed/refractory B cell non-Hodgkin lymphoma (r/r B-NHL): Updated phase 1 results from the ANTLER trial.",
            "Indication": "Relapsed/refractory B cell non-Hodgkin lymphoma (r/r B-NHL)",
            "Drug": "CB-010",
            "PFS": "None",
            "OS": "None",
            "ORR": "Overall response: 94%, Complete response: 69%, Complete response at \u22656 months: 44%",
            "AE": "CRS: 44% (no grade \u22653), ICANS: 25% (13% grade \u22653), Thrombocytopenia: 69%, Neutropenia: 56%, Anemia: 50%, Grade 3 infection: 1 case",
            "Other": "Peak expansion of CB-010 occurred at days 7-10 post-infusion. T and NK cells recovered rapidly in peripheral blood (<3 weeks) after lymphodepletion, and B cells remained below the limit of quantification beyond 3 months.",
            "Conclusion": "CB-010 showed a manageable safety profile and promising efficacy for treatment of patients with r/r B-NHL, including aggressive subtypes. The dose escalation phase is complete. Enrollment of 2L LBCL patients in dose expansion is ongoing."
        },
        {
            "Title": "Patients with R/R large B-cell lymphoma (LBCL) treated with lisocabtagene maraleucel (liso-cel) nonconforming product (NCP) under the Expanded Access Protocol (EAP).",
            "Indication": "Relapsed/refractory large B-cell lymphoma (R/R LBCL)",
            "Drug": "Lisocabtagene maraleucel (liso-cel)",
            "PFS": "None",
            "OS": "None",
            "ORR": "Overall response rate: 53% (95% CI: 43-62), Complete response rate: 19% (95% CI: 12-27)",
            "AE": "Cytokine release syndrome: 42% (1% grade \u22653), Neurological events: 8% (4% grade \u22653), Immune effector cell\u2013associated neurotoxicity: 2% (1% grade \u22653), Prolonged cytopenia: 40% (grade \u22653 on or after Day 29), Grade \u22653 infections: 14%, Infusion-related reactions: 1% (grade \u22653), Second primary malignancy: 1% (none grade \u22653)",
            "Other": "None",
            "Conclusion": "These data add to current clinical experience with liso-cel, showing that patients with R/R LBCL can derive clinical benefit from receiving NCP without compromising safety, and provide important evidence to facilitate clinical decision-making."
        },
        {
            "Title": "A phase 1 study of LY007, a novel anti-CD20 CAR-T cell therapy in patients with relapsed or refractory B-cell non-Hodgkin lymphoma.",
            "Indication": "Relapsed or refractory B-cell non-Hodgkin lymphoma (r/r B-NHL)",
            "Drug": "LY007",
            "PFS": "Progression-free survival: 88.9% at 6 months",
            "OS": "Overall survival: 100% at 6 months",
            "ORR": "Overall response rate: 89%, Complete response rate: 67% at day 28, 83% at month 3",
            "AE": "No dose-limiting toxicities (DLTs), No immune effector cell-associated neurotoxicity syndrome (ICANS) or grade \u22653 cytokine release syndrome (CRS), Most common grade \u22653 AEs: Lymphopenia (9/9), Leukopenia (9/9), Neutropenia (7/9), Grade 1/2 CRS: 6 patients",
            "Other": "Patients at all 3 dose levels had good CAR-T expansion and long-term persistence, particularly in the LD3 cohort, where the highest mean cell copy number of 93,750 copies/mg DNA was achieved at day 11 and was still detectable to date.",
            "Conclusion": "This phase 1 trial demonstrated that LY007 was well tolerated at the dose levels up to 5.0x10^6 cells/kg and showed a favorable dose-response relationship for the treatment of r/r B-NHL."
        },
        {
            "Title": "Subcutaneous epcoritamab (SC epcor) administered outpatient (outpt) for relapsed or refractory (R/R) diffuse large B-cell lymphoma (DLBCL) and follicular lymphoma (FL): Results from phase 2 EPCORE NHL-6.",
            "Indication": "Relapsed or refractory diffuse large B-cell lymphoma (DLBCL) and follicular lymphoma (FL)",
            "Drug": "Epcoritamab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Any grade CRS occurred in 47% of pts and were mostly low grade (G1, n=9; G2, n=7; G3, n=1). ICANS occurred in 8% (all G1-2).",
            "Other": "Median time to onset of CRS after 1st full dose was 23h. No CRS events led to discontinuation; 11 pts (31%) were treated with tocilizumab, 8 (22%) with corticosteroids, and 5 (14%) with both. Median time to resolution of CRS was 45 h.",
            "Conclusion": "Pts with R/R DLBCL and FL were successfully treated with SC epcor and monitored in the outpt setting across different types of sites. CRS events occurring in the outpt setting were appropriately managed, and the observed CRS incidence and severity was comparable to that of the pivotal EPCORE NHL-1 trial. These data suggest that SC epcor can be safely administered and pts monitored outpt, and then managed inpt as needed for CRS. Enrollment is ongoing, and a larger dataset with C1 OPT including hydration and dexamethasone, which are anticipated to further lower CRS incidence and severity, will be presented later."
        },
        {
            "Title": "Preclinical assessment of IPH6501, a first-in-class IL2v-armed tetraspecific NK cell engager directed against CD20 for R/R B-NHL, in comparison with a CD20-targeting T cell engager.",
            "Indication": "Relapsed or refractory B-cell non-Hodgkin lymphoma (B-NHL)",
            "Drug": "IPH6501",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "IPH6501 significantly boosted peripheral NK cell proliferation, drove their accumulation at tumor locations, and effectively eradicated CD20+ cells in blood and tissues. IPH6501 showed potent activity against a range of B-NHL cell lines, including those with low CD20 expression. IPH6501 induced lower cytokine secretion compared to the TCE, suggesting a potentially safer profile.",
            "Conclusion": "The preclinical demonstrations of IPH6501 activities across various in vivo models and its effectiveness in ex vivo assays using cells from R/R B-NHL patients provide compelling evidence of its therapeutic potential and tolerability. IPH6501 is emerging as a promising new candidate within the treatment landscape for R/R B-NHL and is currently being investigated in a global, first-in-human phase 1/2 study."
        },
        {
            "Title": "Long term follow-up results of BRL-201 phase I study, a CRISPR-based non-viral PD-1 locus specific integrated anti-CD19 CAR-T cells in treating relapsed or refractory non-Hodgkin\u2019s lymphoma.",
            "Indication": "Relapsed or refractory non-Hodgkin\u2019s lymphoma",
            "Drug": "BRL-201 (CRISPR-based non-viral PD-1 locus specific integrated anti-CD19 CAR-T cells)",
            "PFS": "Median PFS of 19.5 months (95% CI: 5.15-33.86)",
            "OS": "12-month OS rate was 76.2% (95% CI: 60%-96.8%). The median OS was not reached.",
            "ORR": "All 21 patients showed an objective response, with 18 (85.7%) achieving CR.",
            "AE": "Mild CRS (grade 1-2) occurred in 66.7% (14/21) of patients, with one requiring tocilizumab. Grade 1-2 ICANS was observed in 19.0% (4/21). No severe CRS or ICANS were noted.",
            "Other": "None",
            "Conclusion": "With a median 37.0 month follow-up, BRL-201 demonstrated durable responses, high median PFS (19.5 months), and a 12-month OS rate of 76.2%. The median OS for responders has not yet been reached. The safety profile was manageable, highlighting the clinical potential of BRL-201. Further data collection is ongoing to establish its clinical value."
        },
        {
            "Title": "Epcoritamab + R-DHAX/C in transplant-eligible patients (pts) with high-risk relapsed or refractory (R/R) diffuse large B-cell lymphoma (DLBCL).",
            "Indication": "High-risk relapsed or refractory diffuse large B-cell lymphoma (DLBCL)",
            "Drug": "Epcoritamab + R-DHAX/C",
            "PFS": "At 24 mo, per Kaplan\u2013Meier estimates, 60% of pts remained progression-free, while 90% of pts who proceeded to ASCT and 60% of pts who continued epcoritamab without ASCT remained progression-free.",
            "OS": "An estimated 86% of pts remained alive at 24 mo.",
            "ORR": "ORR was 76% and complete response (CR) rate was 69%.",
            "AE": "The most common tx-emergent AEs (TEAEs) of any grade (G) were thrombocytopenia (76%), anemia (59%), nausea (48%), and neutropenia (48%; febrile neutropenia, 17%). CRS was low grade (38% G1, 7% G2), resolved, and did not lead to tx discontinuation. ICANS (G2) occurred in 1 pt and led to tx discontinuation. There were no fatal TEAEs.",
            "Other": "None",
            "Conclusion": "Longer follow-up reaffirms the efficacy and feasibility of epcoritamab + R-DHAX/C in ASCT-eligible DLBCL. Response rates were high and most pts proceeded to ASCT. Safety was manageable and consistent with prior data. These results, including new high-risk subgroup analyses, support future evaluation of epcoritamab + R-DHAX/C in ASCT-eligible DLBCL."
        },
        {
            "Title": "Secondary myeloid malignancy after CAR T cell therapy for non-Hodgkin lymphoma (NHL).",
            "Indication": "Non-Hodgkin lymphoma (NHL)",
            "Drug": "CAR T cell therapy (tisa-cel, axi-cel, brexu-cel, liso-cel)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Grade 3-4 neutropenia in 29% and 18% of patients at 30 days and 90 days post-CAR T",
            "Other": "Cumulative incidence of secondary malignancy was 1%, 5%, and 6% at 2, 3, and 4 years post-CAR T",
            "Conclusion": "We report on the incidence of secondary myeloid malignancy in patients receiving CAR T for NHL. Mutational characteristics of secondary malignancies were those classically associated with therapy-related myeloid malignancy, thus presumed to be associated with prior exposure to chemotherapy (e.g., alkylators/VP-16). Further investigation in larger cohorts with additional observation time, to allow for appropriate risk discussion, is required."
        },
        {
            "Title": "Follow-up on phase 1 study of AT101, a novel anti-CD19 chimeric antigen receptor cell therapy (CAR-T) in patients with relapsed or refractory (r/r) B-cell non-Hodgkin lymphoma.",
            "Indication": "Relapsed or refractory B-cell non-Hodgkin lymphoma",
            "Drug": "AT101 (novel anti-CD19 CAR-T)",
            "PFS": "17.2 months",
            "OS": "18.9 months",
            "ORR": "91.7%",
            "AE": "None",
            "Other": "Complete response (CR) observed in 75% of patients. Median duration of response (DOR) was 19.5 months. Median event-free survival (EFS) was 17.2 months.",
            "Conclusion": "In this first-in-human phase 1 trial, AT101 was tolerable with limited and manageable toxicities. In comparison to current FMC63 scFv-based CD19 CAR-T therapies, AT101 exhibited potent and more enduring efficacy with a remarkable suppression of relapse after CR. A phase 2 clinical trial is currently under-going for DLBCL patients."
        },
        {
            "Title": "Subcutaneous epcoritamab + GemOx in patients with relapsed or refractory DLBCL: Updated results from EPCORE NHL-2.",
            "Indication": "Relapsed or refractory diffuse large B-cell lymphoma (DLBCL)",
            "Drug": "Epcoritamab + GemOx",
            "PFS": "9 months (Kaplan\u2013Meier estimates at 9 months in patients with CR: 93%)",
            "OS": "9 months (Kaplan\u2013Meier estimates at 9 months in patients with CR: 96%)",
            "ORR": "78% overall response rate (ORR), 55% complete response (CR) rate",
            "AE": "68% thrombocytopenia, 59% neutropenia (6% febrile neutropenia), 51% anemia, 51% cytokine release syndrome (CRS) (27% G1, 23% G2, 1% G3), 3% ICANS (G1: 2, G3: 1)",
            "Other": "13 fatal treatment-emergent adverse events (TEAEs); in 3 cases, the contribution of epcoritamab + GemOx could not be ruled out: COVID-19, multiple organ dysfunction syndrome, and small intestinal perforation.",
            "Conclusion": "These long-term results continue to demonstrate that SC epcoritamab in combination with GemOx leads to deep, durable responses, which translate to high rates of PFS and OS in challenging-to-treat, high-risk patients with R/R DLBCL. CR rates were consistently high across prespecified subgroups. Higher CR rates in patients with 1 vs \u22652 prior lines of treatment indicate that earlier use may further improve outcomes. No new safety signals were identified. The benefit-risk profile of this combination supports the combinability of epcoritamab in R/R DLBCL."
        },
        {
            "Title": "Extended follow-up results beyond 2.5 years from the pivotal NHL-1 Phase I study of CN201, a novel CD3xCD19 bispecific antibody, in patients with relapsed or refractory B-cell non-Hodgkin lymphoma.",
            "Indication": "Relapsed or refractory B-cell non-Hodgkin lymphoma (R/R B-NHL)",
            "Drug": "CN201",
            "PFS": "None",
            "OS": "None",
            "ORR": "77% overall response rate (ORR), 22% complete remission (CR) rate",
            "AE": "32% white blood cell decreased, 30% neutropenia, 29% lymphopenia, 29% anemia, 25% pyrexia, 23% platelet decreased, 24% lymphopenia (grade 3 or higher), 14% neutropenia (grade 3 or higher), 7% cytokine release syndrome (CRS) (all low-grade, no \u2265Grade 3), no immune effector cell-associated neurotoxicity syndrome observed",
            "Other": "Stable diseases (SD) were observed in patients with fixed doses up to 600 mg and target dose up to 2.5 mg. The best overall responses dramatically improved in patients who received full doses \u22655 mg, ranging from 5 mg to 40 mg investigated so far. Among 11 patients with indolent B-NHL, the ORR was 91%, CR rate was 45.5%, including one patient who had failed previous CAR-T therapy.",
            "Conclusion": "CN201 has a well-tolerable safety profile and promising anti-tumor activity in patients with R/R B-NHL."
        },
        {
            "Title": "Impact of late immune effector cell associated hematotoxicity (ICAHT) and prolonged neutropenia after CAR-T therapy on infection and survival outcomes.",
            "Indication": "Large B-cell lymphoma (LBCL), mantle cell lymphoma (MCL), and follicular lymphoma (FL)",
            "Drug": "CD19 CAR-T therapy",
            "PFS": "Not associated with severe late ICAHT (HR 1.64, p=0.11); D100 neutropenia associated with inferior PFS (HR 2.00, p=0.002)",
            "OS": "Not associated with severe late ICAHT (HR 1.54, p=0.17); D100 neutropenia associated with inferior OS (HR 1.95, p=0.003)",
            "ORR": "None",
            "AE": "Severe late ICAHT in 19% of patients; severe infections in 6% of patients",
            "Other": "NRM was 11% and associated with D100 neutropenia (p=0.015) but not late ICAHT (p=0.15)",
            "Conclusion": "Persistent neutropenia at D100 was associated with inferior PFS and OS, which needs validation in future studies."
        },
        {
            "Title": "Carfilzomib plus rituximab, ifosfamide, carboplatin and etoposide (C-RICE) led to higher response, OS, and PFS in patients with transplant-eligible relapsed or refractory (R/R) diffuse large B-cell lymphoma (DLBCL).",
            "Indication": "Relapsed or refractory diffuse large B-cell lymphoma (R/R DLBCL)",
            "Drug": "Carfilzomib plus rituximab, ifosfamide, carboplatin, and etoposide (C-RICE)",
            "PFS": "3-year PFS rate higher in C-RICE (54%, 32-72%) vs. RICE (35%, 19-51%); median PFS longer in C-RICE (36.8 months) vs. RICE (3.2 months), not statistically significant (P=0.714)",
            "OS": "No differences in OS between C-RICE and RICE patients; median OS 67.3 months vs. 71.8 months",
            "ORR": "Higher ORR in C-RICE (92.9% vs. 76.3%, P=0.073); higher CR rates in C-RICE (82.1% vs. 44.7%, P=0.002)",
            "AE": "None",
            "Other": "More C-RICE patients proceeded to HDC-ASCS (52.6% vs. 47.4%, P=0.618)",
            "Conclusion": "CRICE led to high CR rates and longer PFS among R/R DLBCL patients, especially in non-GCB DLBCL patients. CRICE could be an attractive regimen to achieve disease control in R/R DLBCL patients undergoing consolidation therapy with HDC-ASCT or CAR-T cell therapy."
        },
        {
            "Title": "Combination of pirtobrutinib and lentiviral transduced bispecific anti-CD20/CD19 (LV20.19) CAR-T cell therapy to improve outcomes in patients with relapsed/refractory lymphoma.",
            "Indication": "Relapsed/refractory lymphoma",
            "Drug": "Pirtobrutinib and LV20.19 CAR-T cell therapy",
            "PFS": "Median PFS: 30.8 months",
            "OS": "Median OS: 30.8 months",
            "ORR": "Day 28 ORR: 82% (CR=7, PR=2)",
            "AE": "CRS and ICANS, all grades 1-2; 4 had IEC-HS; 1 had afib recurrence; 1 had CMV viremia within 30 days of CAR-T",
            "Other": "Polyfunctionality (PFA) and polyfunctional strength index (PSI) were higher with pirtobrutinib pre-treated patients, suggesting a potential trend towards improved CAR-T functionality.",
            "Conclusion": "Pirtobrutinib can be safely used as a bridge to LV20.19 CAR-T without negatively impacting their immunophenotype and potentially improving functionality. These data support a planned phase 1 clinical trial to assess the safety of pirtobrutinib as bridging and maintenance therapy with LV20.19 CAR-T."
        },
        {
            "Title": "Real world incidence, prevention, and management of tumor lysis syndrome in patients with chronic lymphocytic leukemia treated with venetoclax in the inpatient and outpatient settings.",
            "Indication": "Chronic Lymphocytic Leukemia (CLL)",
            "Drug": "Venetoclax",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Incidence of TLS was 15% for those escalated exclusively inpatient, 5.7% for those with any prophylactic hospitalization, and 2.7% for those escalated exclusively outpatient.",
            "Conclusion": "In this single institution retrospective cohort study, lab TLS was observed, though clinical TLS was not. Baseline hyperuricemia and impaired renal function were more common among those who developed lab TLS compared to those who did not. Prophylactic measures, including use of IV hydration, may have contributed to low rates of observed TLS in the outpatient setting."
        },
        {
            "Title": "Evaluating CR as a surrogate endpoint for PFS in R/R chronic lymphocytic leukemia/small lymphocytic lymphoma (CLL/SLL): A meta-analysis of randomized controlled trials (RCT).",
            "Indication": "Relapsed/Refractory Chronic Lymphocytic Leukemia/Small Lymphocytic Lymphoma (R/R CLL/SLL)",
            "Drug": "Various therapies including BTKi, BCL2i, PI3Ki, CART cell therapy, anti-CD20 mAb, and chemotherapy",
            "PFS": "Median PFS ranged from 1 to 35.9 months across RCTs. Improved CRR corresponds to prolonged PFS.",
            "OS": "None",
            "ORR": "CRR ranged from 0.48% to 38.10%",
            "AE": "None",
            "Other": "The study supports CRR as an important treatment goal for R/R CLL/SLL and a valid surrogate endpoint.",
            "Conclusion": "Improved CRR corresponds to prolonged PFS across RCTs and treatment comparisons. The results support CRR as an important treatment goal for R/R CLL/SLL and a valid surrogate endpoint. Consistent results and predictive performance across different models indicate the robustness of the results."
        },
        {
            "Title": "Association between treatment (tx) response and PFS and OS in R/R chronic lymphocytic leukemia (CLL) or small lymphocytic lymphoma (SLL): A 12-month landmark (LM) meta-analysis.",
            "Indication": "Relapsed/Refractory Chronic Lymphocytic Leukemia/Small Lymphocytic Lymphoma (R/R CLL/SLL)",
            "Drug": "Various therapies including BTKi, BCL2i, anti-CD20 mAb, and PI3Ki",
            "PFS": "Median PFS from LM was 64.4 months for CR/CRi, 43.3 months for PR/nPR, and 23.9 months for non-CR/non-PR.",
            "OS": "Median OS from LM was not reached for CR/CRi and PR/nPR groups and 62.9 months for non-CR/non-PR.",
            "ORR": "ORR was 76.8%; 15.1% achieved CR/CRi and 61.7% achieved PR/nPR.",
            "AE": "None",
            "Other": "At 24 months from LM, 90% CR/CRi, 87% PR/nPR, and 72% non-CR/non-PR patients were still alive.",
            "Conclusion": "Achieving and maintaining CR/CRi by 12 months after start of tx was associated with significantly improved PFS versus achieving and maintaining PR/nPR, which was also better than not achieving/maintaining any response. These findings support the potential utility of durable CR/CRi as an informative early endpoint for assessing the value of tx in pts with R/R CLL/SLL."
        },
        {
            "Title": "Comparative efficacy of Bruton tyrosine kinase inhibitors in the treatment of relapsed/refractory chronic lymphocytic leukemia: A network meta-analysis (NMA).",
            "Indication": "Relapsed/Refractory Chronic Lymphocytic Leukemia (R/R CLL)",
            "Drug": "Bruton tyrosine kinase inhibitors (BTKi) including zanubrutinib, ibrutinib, and acalabrutinib",
            "PFS": "Zanubrutinib led to statistically significant improvements in PFS versus ibrutinib (HR: 0.49) and BR/IR (HR: 0.12).",
            "OS": "Zanubrutinib showed a trend towards improvement versus ibrutinib (HR: 0.59) and BR/IR (HR: 0.64).",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "This NMA found zanubrutinib to be the most efficacious BTKi for patients with high-risk R/R CLL, offering significantly delayed disease progression, and favorable survival and response vs. alternative BTKi treatments."
        },
        {
            "Title": "Efficacy of pembrolizumab monotherapy and in combination with BCR inhibitors for Richter transformation of chronic lymphocytic leukemia (CLL)",
            "Indication": "Richter transformation of chronic lymphocytic leukemia (CLL)",
            "Drug": "Pembrolizumab",
            "PFS": "2.6 months (95% CI 1.6-3.6) for monotherapy; 7.6 months (95% CI 2.3-13.3) for combination therapy",
            "OS": "11.6 months (95% CI 7.5-20.1) for all patients; 2-year OS rate was 27%",
            "ORR": "23.1% for monotherapy (7.7% CR, 15.4% PR); 62.5% for combination therapy (25.0% CR, 37.5% PR)",
            "AE": "92% any grade TRAEs, 81% grade 3 or above TRAEs",
            "Other": "Median duration of response (DOR) was 3.2 months (95% CI 2.8-NA) for monotherapy and 4.5 months (95% CI 1.6-NA) for combination therapy",
            "Conclusion": "Pembrolizumab has modest single agent activity in RT. Combination therapy with pembrolizumab and a BCR kinase inhibitor is associated with increased efficacy (ORR 60%), similar to other reports. These results support further investigation of immune checkpoint inhibitor-based combination therapy in RT."
        },
        {
            "Title": "Seven-year overall survival analysis from ECHELON-1 study of A+AVD versus ABVD in patients with previously untreated stage III/IV classical Hodgkin lymphoma",
            "Indication": "Stage III/IV classical Hodgkin lymphoma",
            "Drug": "A+AVD (brentuximab vedotin plus doxorubicin, vinblastine, and dacarbazine)",
            "PFS": "7-year PFS rates were 82.3% (95% CI 79.1\u201385.0) for A+AVD vs 74.5% (95% CI 70.8\u201377.7) for ABVD",
            "OS": "7-year OS rates were 93.5% (95% CI 91.1\u201395.2) for A+AVD vs 88.8% (95% CI 85.8\u201391.1) for ABVD",
            "ORR": "None",
            "AE": "Peripheral neuropathy (PN) improved/resolved in most patients at last follow-up (A+AVD: 86%; ABVD: 87%). PN was ongoing in 28% (4% grade \u22653) of A+AVD and 20% (1% grade \u22653) of ABVD patients. Second malignancies were reported in 5% of A+AVD and 6% of ABVD patients.",
            "Other": "Median time to complete resolution of PN was 16 weeks for A+AVD vs 10 weeks for ABVD. Median time to improvement was 42 weeks for A+AVD vs 19 weeks for ABVD.",
            "Conclusion": "At 7-year median follow-up, patients with stage III/IV cHL who received A+AVD showed a sustained PFS and OS benefit vs ABVD, with PFS rates indicating potential curability. The safety profile in patients treated with A+AVD showed no new safety signals at 7 years."
        },
        {
            "Title": "A phase 1/2 study of favezelimab in combination with pembrolizumab for anti\u2013PD-1\u2013naive relapsed or refractory (R/R) classical Hodgkin lymphoma (cHL): An updated analysis.",
            "Indication": "Relapsed or refractory classical Hodgkin lymphoma (cHL)",
            "Drug": "Favezelimab and pembrolizumab",
            "PFS": "Median PFS was 19.4 months (95% CI, 9.5-28.5), and the 24-month PFS rate was 46%.",
            "OS": "Median OS was not reached (NR; 95% CI, NR-NR), and the 24-month OS rate was 93%.",
            "ORR": "The ORR was 83% (n=25; 95% CI, 65-94); 11 pts (37%) had a complete response and 14 (47%) had a partial response.",
            "AE": "Treatment-related AEs occurred in 27 pts (90%), with the most common ($20%) being hypothyroidism (27%), infusion-related reaction (23%), and fatigue (20%). Grade 3 or 4 treatment-related AEs occurred in 7 pts (23%).",
            "Other": "Median DOR was 17.0 months (range, 2.6-30.5), and an estimated 47% of responders remained in response at 24 months.",
            "Conclusion": "With additional follow-up, favezelimab plus pembrolizumab continued to demonstrate sustained antitumor activity and manageable safety in pts with anti\u2013PD-1\u2013naive R/R cHL. Further studies to investigate this combination are warranted."
        },
        {
            "Title": "A phase 1/2 study of favezelimab in combination with pembrolizumab for heavily pretreated anti\u2013PD-1\u2013refractory classical Hodgkin lymphoma (cHL): An updated analysis.",
            "Indication": "Heavily pretreated anti\u2013PD-1\u2013refractory classical Hodgkin lymphoma (cHL)",
            "Drug": "Favezelimab and pembrolizumab",
            "PFS": "Median PFS was 9.7 months (95% CI, 5.1-14.7); 24-month PFS rate was 21%.",
            "OS": "Median OS was 39.0 months (95% CI, 25.7-not reached); 24-month OS rate was 73%.",
            "ORR": "ORR was 29% (10/34; 95% CI, 15-48), with 3 (9%) complete responses and 7 (21%) partial responses.",
            "AE": "Treatment-related AEs occurred in 28 pts (82%); the most common ($15%) were hypothyroidism (18%), nausea (18%), and fatigue (15%). Grade 3 or 4 treatment-related AEs occurred in 6 pts (18%).",
            "Other": "Median DOR was 21.9 months (range, 0.0+ to 26.1+), and an estimated 17% of responders remained in response at 24 months.",
            "Conclusion": "After additional follow-up, favezelimab + pembrolizumab continued to demonstrate manageable safety and antitumor activity in pts with heavily pretreated anti\u2013PD-1\u2013refractory cHL. The phase 3 KEYFORM-008 study (NCT05508867) will evaluate a coformulation of favezelimab and pembrolizumab in pts with anti\u2013PD-1\u2013refractory cHL."
        },
        {
            "Title": "A novel and selective oral PI3Ka/d inhibitor, TQ-B3525, in patients with relapsed and/or refractory follicular lymphoma: A phase II, single-arm, open-label study.",
            "Indication": "Relapsed and/or refractory follicular lymphoma (R/R FL)",
            "Drug": "TQ-B3525",
            "PFS": "Median PFS was 18.5 (95% CI, 10.2-not estimable) months; estimated 24-month OS rate was 86.1%.",
            "OS": "24-month OS rate was 86.1%.",
            "ORR": "ORR was 86.6% (71/82; 95% CI, 77.3%-93.1%), with 28 (34.2%) complete responses.",
            "AE": "Grade 3 or higher treatment-related adverse events occurred in 63 (76.8%) patients, with neutrophil count decreased (22.0%), hyperglycemia (19.5%), and diarrhea (13.4%) being common.",
            "Other": "Median DOR was not reached; 18-month DOR rate was 51.6%.",
            "Conclusion": "TQ-B3525 exhibited favorable efficacy and manageable safety profiles, supporting its potential as a valuable treatment modality for heavily pretreated Chinese R/R/FL patients."
        },
        {
            "Title": "Phase 1/2 of EO2463 immunotherapy as monotherapy and in combination with lenalidomide and/or rituximab in indolent NHL (EONHL1-20/SIDNEY).",
            "Indication": "Indolent non-Hodgkin lymphoma (NHL)",
            "Drug": "EO2463, lenalidomide, and rituximab",
            "PFS": "None",
            "OS": "None",
            "ORR": "Overall objective responses (OR) were seen in 6/9 pts (67%; 1st OR on EO2463 1 pat, EL 4 pts, ER2 1 pat), with CR in 5/9 (56%) pts.",
            "AE": "No related grade \u22653 adverse events were seen with EO2463 monotherapy. Most common related events were grade 1 to 2 local administration site reactions in 5/9 pts.",
            "Other": "Expansion of specific CD8+ T cells against mimic peptides and targeted B cell antigens were detected in all responding pts, incl. 2 pts with no measurable B cells at baseline (prior anti-CD20).",
            "Conclusion": "EO2463 (300 mg/peptide) monotherapy, EL, and ER2 are well tolerated, with encouraging clinical activity with EO2463 monotherapy and with subsequent CR in 5/9 pts on combo. Rapid and durable expansion of specific CD8+ T cells was seen in all responding pts, consistent with the preclinical hypothesis."
        },
        {
            "Title": "A phase II multicenter study of abexinostat, an oral histone deacetylase inhibitor, in patients with relapsed/refractory follicular lymphoma.",
            "Indication": "Relapsed/Refractory Follicular Lymphoma",
            "Drug": "Abexinostat",
            "PFS": "13.77 months (95% CI: 9.69-not evaluable [NE])",
            "OS": "Not reached; 42-month OS rate was 74.3% (95% CI: 58.1%-85.0%)",
            "ORR": "67.1% (95% CI: 55.8%-77.1%), including 12.2% CR",
            "AE": "Thrombocytopenia (85.6%), neutropenia (58.9%), leukopenia (52.2%), nausea (50.0%), anemia (48.9%), diarrhea (46.7%); Grade \u22653 TEAEs: thrombocytopenia (37.8%), neutropenia (23.3%), leukopenia (7.8%), lymphopenia (5.6%)",
            "Other": "Median DoR was 13.96 months (95% CI: 8.34-NE)",
            "Conclusion": "Abexinostat was well tolerated at a dose of 80 mg BID as monotherapy and demonstrated a significant response in patients with heavily pretreated relapsed/refractory follicular lymphoma."
        },
        {
            "Title": "Preliminary results from a phase I study of IMM0306 in patients with relapsed or refractory CD20-positive B-cell non-Hodgkin\u2019s lymphoma.",
            "Indication": "Relapsed/Refractory CD20-positive B-cell Non-Hodgkin\u2019s Lymphoma",
            "Drug": "IMM0306",
            "PFS": "10.58 months (95% CI: 2.2, NA)",
            "OS": "Not reached",
            "ORR": "Among 33 patients who received doses \u22650.8 mg/kg: 5 CR (4 follicular lymphoma [FL], 1 marginal zone lymphoma [MZL]), 5 PR (3 FL, 1 MZL, 1 diffuse large B-cell lymphoma), and 11 SD",
            "AE": "WBC decreased (66.7%), anemia (64.6%), lymphocyte decreased (58.3%), ANC decreased (47.9%), PLT decreased (45.8%), infusion-related reactions (35.4%); Grade \u22653 TRAEs: lymphocyte decreased (56.3%), WBC decreased (18.8%), ANC decreased (18.8%)",
            "Other": "No dose-limiting toxicities observed. Recommended phase II dose was determined as 2.0 mg/kg.",
            "Conclusion": "IMM0306 was well-tolerated and showed promising preliminary anti-tumor activity, especially in patients with relapsed/refractory follicular lymphoma and marginal zone lymphoma. The phase II study is ongoing."
        },
        {
            "Title": "Frontline treatment with zanubrutinib plus rituximab (ZR) followed by short course R-DHAOx in patients with mantle cell lymphoma (MCL): Results of the phase II CHESS clinical trial.",
            "Indication": "Mantle Cell Lymphoma",
            "Drug": "Zanubrutinib plus Rituximab (ZR)",
            "PFS": "1-year PFS was 90.1%",
            "OS": "1-year OS was 96.7%",
            "ORR": "92.1% (23/25) CR rate after ZR regimen",
            "AE": "Grade 3-4 adverse events on ZR regimen: neutropenia (n=3), fatigue (n=2), aminotransferase (n=1); During chemotherapy: grade 3-4 thrombocytopenia in 75.8% of patients",
            "Other": "Minimal residual disease (MRD) of bone marrow and peripheral blood were evaluated by flow cytometry.",
            "Conclusion": "Induction treatment with ZR followed by short-course R-DHAOx chemotherapy yielded promising anti-tumor efficacy. This strategy might reduce the toxicity of cytarabine-based chemotherapy without weakening the therapeutic efficacy."
        },
        {
            "Title": "A phase 1 study of SHR-A1912, a CD79b targeted antibody-drug conjugate (ADC), in patients (pts) with B-cell non-Hodgkin lymphoma (B-NHL).",
            "Indication": "B-cell non-Hodgkin lymphoma (B-NHL)",
            "Drug": "SHR-A1912",
            "PFS": "None",
            "OS": "None",
            "ORR": "Overall: 56.1% (95% CI, 39.8\u201371.5), DLBCL: 51.9% (32.0\u201371.3), FL: 63.6% (30.8\u201389.1), MZL: 66.7% (9.4\u201399.2)",
            "AE": "Grade \u22653 treatment-related adverse events occurred in 20 (40.8%) pts, with the most common (\u226520%) being decreased neutrophil count.",
            "Other": "6-mo DoR rate: 62.7% (95% CI, 26.7\u201384.8), Mean half-life of conjugated antibody ranged from 4.7 to 5.3 days for pts in the 1.8\u20133.6 mg/kg cohorts, with volume of distribution close to human plasma volume. The plasma exposure of free toxin was low, with the molar ratio of toxin/conjugated-antibody <1%, and no accumulation was observed.",
            "Conclusion": "SHR-A1912 was tolerable up to 3.6 mg/kg and had promising anti-tumor activity in pts with previously treated B-NHL, supporting further investigations of SHR-A1912."
        },
        {
            "Title": "Selinexor combined with tislelizumab in patients with relapsed or refractory extranodal NK/T-cell lymphoma (R/R ENKTL): Results of dose escalation of cohort C, from a multicenter, single-arm, phase I/II study (TOUCH).",
            "Indication": "Relapsed or refractory extranodal NK/T-cell lymphoma (R/R ENKTL)",
            "Drug": "Selinexor and tislelizumab",
            "PFS": "Median PFS: 6.1 months",
            "OS": "Median OS: Not reached (6-month OS rate: 90.9%)",
            "ORR": "72.7% (8/11), CR rate: 36.4% (4/11)",
            "AE": "The most common TEAEs were asthenia (83.3%), neutropenia (83.3%), nausea/vomiting (58.3%), decreased appetite, weight loss, anemia, thrombocytopenia (50.0%, respectively), lymphocytopenia (41.7%), pneumonia, AST increased and proteinuria (33.3%, respectively). Seven pts (58.3%) had Grade \u22653 TEAEs. TESAEs occurred in 3 pts (25%) with only 1 (sepsis) considered treatment-related.",
            "Other": "No DLT was observed and MTD was not reached. Only 1 pt experienced an irAE with thyroiditis. No pt discontinued or died due to TEAEs.",
            "Conclusion": "Selinexor plus tislelizumab showed tolerable safety profile and encouraging response rate in R/R ENKTL. Expansion stage of Cohort C is ongoing."
        },
        {
            "Title": "Glofitamab combined with poseltinib and lenalidomide for relapsed/refractory diffuse large B cell lymphoma: Interim analysis of GPL study.",
            "Indication": "Relapsed/refractory diffuse large B cell lymphoma (R/R DLBCL)",
            "Drug": "Glofitamab, poseltinib, and lenalidomide",
            "PFS": "3 months: 81% (69-96), 6 months: 55% (36-84)",
            "OS": "3 months: 91% (82-100), 6 months: 81% (67-98)",
            "ORR": "89.3% (78-100), CRR: 42.9% (25-61)",
            "AE": "Neutropenia (45.9%) was the most common grade 3-4. Three patients (8.1%) died of grade 5. There was 1 case (2.7%) of grade 3 atrial fibrillation and grade 1 bleeding. No incidence of grade 3 or above liver/renal toxicity was observed. Overall, 3 patients (8.1%) discontinued GPL treatment due to toxicity, and cytokine release syndrome was observed in 7 patients (18.9%) and only 2 patients (5.4%) were grade 3-4.",
            "Other": "DoR at 6 months was 66%. Median follow-up duration of 3.6 months.",
            "Conclusion": "Interim analysis indicates that the GPL regimen is an effective and safe regimen for R/R DLBCL patients. High response rates observed support further investigation of GPL for potential synergism between T-cell engagers and BTKi."
        },
        {
            "Title": "Impact of bridging therapy (BT) on lisocabtagene maraleucel (liso-cel) treatment in patients (pt) with R/R follicular lymphoma (FL)",
            "Indication": "Relapsed/Refractory Follicular Lymphoma",
            "Drug": "Lisocabtagene maraleucel (liso-cel)",
            "PFS": "Not reached (NR) in both subgroups",
            "OS": "Not reached (NR) in both subgroups",
            "ORR": "High and similar across subgroups, with all BT responders achieving CR",
            "AE": "Grade \u22653 treatment-emergent AEs in BT vs NBT pts were reported in 82% vs 70%, most commonly neutropenia (69% vs 52%), anemia (18% vs 5%), and thrombocytopenia (18% vs 5%)",
            "Other": "Any-grade cytokine release syndrome (CRS; 51% vs 62%) and neurological events (NE; 12% vs 17%) were numerically lower in BT vs NBT pts",
            "Conclusion": "Liso-cel showed equal clinical efficacy and safety (low rates of severe CRS/NEs/infections) in BT vs NBT pts, suggesting BT may mitigate worse outcomes and AEs associated with high tumor burden/high-risk features."
        },
        {
            "Title": "Frontline brentuximab vedotin and CHP (A+CHP) in patients (pts) with peripheral T-cell lymphoma with less than 10% CD30 expression: Results from the phase 2 SGN35-032 study",
            "Indication": "Peripheral T-cell Lymphoma with <10% CD30 expression",
            "Drug": "Brentuximab vedotin (BV) combined with cyclophosphamide, doxorubicin, and prednisone (A+CHP)",
            "PFS": "None",
            "OS": "None",
            "ORR": "77% (95% CI: 65.3%, 86.7%), including 65% (95% CI: 52.4%, 76.5%) with CR among the 66 efficacy evaluable (EE) pts",
            "AE": "Grade \u22653 treatment-emergent adverse events (TEAEs) were experienced by 43 pts (61%), most commonly neutropenia (20%), febrile neutropenia (17%), and anemia (10%)",
            "Other": "There were 2 (3%) treatment-related deaths: 1 pt died of decreased appetite and 1 pt died of general physical health deterioration",
            "Conclusion": "In pts with non-sALCL PTCL with <10% CD30 expression, A+CHP as frontline therapy appears effective and has a safety profile consistent with the label."
        },
        {
            "Title": "First-line (1L) treatment of follicular lymphoma (FL) with atezolizumab and obinutuzumab (A+O) +/- radiotherapy (RT): Results from the FLUORO study",
            "Indication": "Follicular Lymphoma",
            "Drug": "Atezolizumab and obinutuzumab (A+O) +/- radiotherapy (RT)",
            "PFS": "16-month PFS is 87%",
            "OS": "16-month OS is 100%",
            "ORR": "100% (15/15) at end of induction",
            "AE": "AEs were mostly Grade 1-2; fatigue 44%, constipation 31%, nausea 25%, fever 25%, abdominal pain 18.6%. SAEs were reported in 7/16 (44%) pts, including grade 3 infections: COVID-19 12%, upper respiratory tract 12%, urinary tract 6.2%",
            "Other": "None",
            "Conclusion": "Atezolizumab + Obinutuzumab +/- PET-adapted 4Gy RT yielded high complete response rates with low toxicity in treatment-na\u00efve FL. CR threshold to proceed to stage 2 was met, however, was not deemed commercially viable."
        },
        {
            "Title": "Survival outcomes after autologous stem cell transplantation in T follicular helper type lymphoma",
            "Indication": "T follicular helper type lymphoma",
            "Drug": "Anthracycline-based chemotherapy regimens, CHOP, CHOEP, brentuximab with either CHP or CHEP",
            "PFS": "Median PFS after first treatment: 12.5 months; Patients who received ASCT: 32.5 months; Patients who did not receive ASCT: 11 months",
            "OS": "Median OS for the cohort: 40.3 months; Patients who received ASCT: 63 months; Patients who did not receive ASCT: 32 months",
            "ORR": "None",
            "AE": "None",
            "Other": "NGS testing identified recurrent somatic mutations in TET2 (65%), DNMT3A (29%), RHOA (13%), IDH2 (10%), PLCG1 (10%), TP53 (10%), ATM (6%), CCR7 (6%), JAK3 (6%), and STAT3 (6%)",
            "Conclusion": "This retrospective analysis of a large single institution cohort of patients with TfH lymphoma found significantly improved OS in those who underwent ASCT compared to those who did not. Given the sizeable proportion of patients who are transplant-ineligible due to age or comorbidities, there is a need for newer consolidation approaches for TfH lymphoma."
        },
        {
            "Title": "HDAC I/IIb selective inhibitor purinostat mesylate in relapsed and refractory diffuse large B-cell lymphoma: A single agent phase IIa trial",
            "Indication": "Relapsed and refractory diffuse large B-cell lymphoma",
            "Drug": "Purinostat mesylate",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The full, final text of this abstract will be available at meetings.asco.org on the day of presentation and in the online supplement to the June 10, 2024, issue of the Journal of Clinical Oncology."
        },
        {
            "Title": "P-GEMOX with sequential or sandwiched radiotherapy for early-stage extranodal natural killer/T-cell lymphoma",
            "Indication": "Early-stage extranodal natural killer/T-cell lymphoma (ENKTL)",
            "Drug": "P-GEMOX (pegaspargase, gemcitabine, oxaliplatin)",
            "PFS": "3-year PFS: 87.2% (95% CI 69.3%-95%)",
            "OS": "3-year OS: 90.6% (95% CI 72%-97.1%)",
            "ORR": "94.6% (35/37) including 30 patients with CR and 5 patients with PR",
            "AE": "Grade 3-4 TEAEs: 21.6% (8/37); Neutropenia: 18.9%, Thrombocytopenia: 10.8%, Elevated aminotransferase: 16.2%, Hyperbilirubinemia: 10.8%, Hypofibrinogenemia: 8.1%",
            "Other": "Sandwich radiotherapy showed a trend for longer PFS than sequential radiotherapy (18 months-PFS 100% vs. 78%; P=0.073)",
            "Conclusion": "CMT consisting of P-GEMOX showed favorable efficacy with acceptable toxicity, and sandwich radiotherapy (CT+RT+CT) seems to give more favorable PFS than sequential radiotherapy. The CMT protocol could be an effective treatment option for early-stage ENKTL patients."
        },
        {
            "Title": "Results from the follicular lymphoma (FL) outcomes in patients (pts) with relapsed/refractory (R/R) disease treated with systemic therapy in a real-world assessment (FLORA) study",
            "Indication": "Relapsed/refractory follicular lymphoma (R/R FL)",
            "Drug": "Odronextamab",
            "PFS": "Median PFS: 11.5 months",
            "OS": "Median OS: 26.1 months",
            "ORR": "52%, CR: 31%, DCR: 62%",
            "AE": "None",
            "Other": "Median DOR: 28.2 months; Sensitivity analysis mDOR: 8.1 months, mPFS: 7.2 months",
            "Conclusion": "Patients in the FLORA study had markedly lower response rates than those in the R/R FL cohort of ELM-2. This study also showed that most patients still received an anti-CD20\u2013based regimen in 3L+ in routine practice, highlighting the need for treatment options with different mechanisms of action that provide deep and durable responses and improve outcomes in this patient population."
        },
        {
            "Title": "Durable complete response and overall survival in patients with heavily pretreated, poor-prognosis non-Hodgkin lymphoma to immunoactivating AVM0703 with few and mild drug-related adverse effects",
            "Indication": "Heavily pretreated, poor-prognosis non-Hodgkin lymphoma (NHL)",
            "Drug": "AVM0703",
            "PFS": "None",
            "OS": "Median OS: 13.3 months; 9-month survival: 100% for 18 mg/kg group",
            "ORR": "66% (3 CR, 1 PR) in 18 mg/kg group",
            "AE": "76.5% experienced study-drug-related AEs; 78.9% grade 1, 10.5% grade 2, 10.5% grade 3; Most common AEs: insomnia (N=5), hyperglycemia (N=3), pruritis (N=2)",
            "Other": "No safety signals identified; RP2D determined as 18 mg/kg",
            "Conclusion": "RP2D AVM0703, with consolidation therapy at day 28, showed no concerning safety signals and promising long-term survival."
        },
        {
            "Title": "Updated efficacy and safety results of BCL-2 inhibitor lisaftoclax (APG-2575) alone or combined with ibrutinib or rituximab in patients (pts) with Waldenstr\u00f6m macroglobulinemia (WM)",
            "Indication": "Waldenstr\u00f6m macroglobulinemia (WM)",
            "Drug": "Lisaftoclax (APG-2575) alone or combined with ibrutinib or rituximab",
            "PFS": "None",
            "OS": "None",
            "ORR": "Arm A: 41.7%, Arm B: 90.9%, Arm C: 37.5%",
            "AE": "Grade \u22653 lisaftoclax-related AEs: neutropenia (15.2%), thrombocytopenia (4.3%), decreased leukocytes (4.3%), TLS (4.3%), anemia (2.2%), weight loss (2.2%), septic shock (2.2%)",
            "Other": "No significant difference in response between pts with/without CXCR4 mutation in Arms B and C; PK exposure comparable to lisaftoclax or ibrutinib alone",
            "Conclusion": "Lisaftoclax alone and combined with ibrutinib or rituximab was well tolerated and demonstrated measurable effects in pts with naive or BTKi-treatment-failed WM."
        },
        {
            "Title": "Combined modality for localized gastric diffuse large B-cell lymphoma: Incidence of central nervous system relapse in primary mediastinal B-cell lymphoma: Implications for central nervous system prophylaxis.",
            "Indication": "Localized gastric diffuse large B-cell lymphoma (GDLBCL)",
            "Drug": "R-CHOP followed by gastric RT",
            "PFS": "96-month PFS 76% vs. 86%, p=0.7",
            "OS": "96-month OS 70% vs. 86%, p=0.8",
            "ORR": "None",
            "AE": "None",
            "Other": "Low-grade lymphoma in the stomach post-DLBCL CR observed in 2 pts, 3 and 22 months from RT end",
            "Conclusion": "Recognizing the limitations of a retrospective study, our study documents the effectiveness of short-course R-CHOP followed by RT in localized GDLBCL, with similar outcomes to the full 6-cycle regimen. Excellent disease control was observed, with few instances of DLBCL or low-grade lymphoma relapse. Further analysis will include a cohort receiving R-CHOP alone without consolidative RT. This analysis aims to contribute to the evolving standard of care for GDLBCL, providing insights for future treatment strategies."
        },
        {
            "Title": "Outcomes and prognostic factors of patients diagnosed with relapsed/refractory primary B-cell lymphoma: A retrospective study.",
            "Indication": "Relapsed/refractory primary B-cell lymphoma (PMBCL)",
            "Drug": "Platinum-based chemotherapy with RICE, RDHAP, or RGemOx",
            "PFS": "5-year PFS for the entire cohort was 76%, for patients with R/R disease, 5-year PFS was 49%",
            "OS": "5-year OS for the entire cohort was 96%, for patients with R/R disease, 5-year OS was 84%",
            "ORR": "60% among evaluable patients",
            "AE": "None",
            "Other": "Higher PFS and OS suggest improved outcomes with HDT/ASCT compared to historical outcomes in R/R LBCL (SCHOLAR-1)",
            "Conclusion": "We report high response rates to platinum-based salvage chemotherapy in the rituximab era, even with primary refractory or early relapsed disease. Higher PFS and OS suggest improved outcomes with HDT/ASCT compared to historical outcomes in R/R LBCL (SCHOLAR-1). Larger prospective studies are needed to determine whether HDT/ASCT or CAR-T is the optimal approach in R/R PMBCL."
        },
        {
            "Title": "Lacutamab in patients with relapsed and/or refractory mycosis fungoides: Results from the TELLOMAK phase 2 trial.",
            "Indication": "Relapsed and/or refractory mycosis fungoides (MF)",
            "Drug": "Lacutamab",
            "PFS": "Median PFS 10.2 months (CI 6.5, 16.8)",
            "OS": "None",
            "ORR": "16.8% (CI 10.9, 25.0; Olsen 2011), and 22.4% (CI 15.6, 31.2; Olsen 2022)",
            "AE": "Grade \u22653 Treatment-related (TR) Treatment-Emergent Adverse events (TEAEs) were observed in 4/107 (3.7%) pts, serious TRTEAEs were observed in 4/107 (3.7%) pts and 3/107 (2.8%) pts discontinued study drug due to TRTEAEs. The most common (>10%) TRTEAEs were fatigue (11.2%), nausea (11.2%), asthenia (10.3%), and arthralgia (10.3%).",
            "Other": "Among the KIR3DL2 \u22651% pts (N=48), ORR was 20.8% (CI 11.7, 34.3; Olsen 2011), and 29.2% (CI 18.2, 43.2; Olsen 2022), response in skin and in blood was observed in 33.3% (CI 21.7, 47.5) and 41.2% (CI 21.6, 64.0) respectively. Median time to response was 1.0 month and median PFS was 12.0 months (CI 5.6, 20.0). Median duration of response was not reached.",
            "Conclusion": "The data from the heavily pre-treated MF population enrolled to the TELLOMAK study confirms promising clinical activity of lacutamab regardless of KIR3DL2 expression, with a favorable safety and tolerability profile. These data support the further development of lacutamab in an effort to bring improved treatments to patients with CTCL."
        },
        {
            "Title": "A phase I, first-in-human study of ONO-7018 in patients with relapsed/refractory non-Hodgkin lymphoma or chronic lymphocytic leukemia.",
            "Indication": "Non-Hodgkin lymphoma (NHL) and chronic lymphocytic leukemia (CLL)",
            "Drug": "ONO-7018",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoints include dose-limiting toxicity, MTD, and treatment-emergent adverse events. Secondary endpoints include pharmacokinetics, objective response rate, duration of response, progression-free survival, and overall survival.",
            "Conclusion": "The study aims to determine the maximum tolerated dose (MTD) and to evaluate the safety, tolerability, pharmacokinetics, pharmacodynamics, and efficacy of ONO-7018 as monotherapy in patients with relapsed or refractory NHL or CLL."
        },
        {
            "Title": "EPCOREFL-2: Phase 3 trial of epcoritamab with rituximab and lenalidomide (R2) vs chemoimmunotherapy or R2 in previously untreated follicular lymphoma.",
            "Indication": "Follicular lymphoma (FL)",
            "Drug": "Epcoritamab with rituximab and lenalidomide (R2)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Incidence and severity of adverse events (AEs), including AEs of special interest (cytokine release syndrome, immune effector cell\u2013associated neurotoxicity syndrome, and clinical tumor lysis syndrome).",
            "Other": "Dual primary endpoints will be CR rate at 30 months and progression-free survival in arm A vs arm B. Key secondary efficacy endpoints include overall survival, minimal residual disease negativity, and changes in patient-reported outcomes on the physical functioning scale of the European Organization for Research and Treatment of Cancer Quality of Life-C30 Questionnaire from baseline to week 25.",
            "Conclusion": "The objective of this trial is to evaluate the safety and efficacy of 1L epcoritamab plus R2 vs CIT in patients with FL."
        },
        {
            "Title": "Phase 2 open label, multicenter study evaluating CRG-022, a CD22-directed autologous CAR T-cell therapy, in patients (pts) with relapsed/refractory (R/R) large B-cell lymphoma (LBCL) after CD19-directed CAR T-cell therapy.",
            "Indication": "Relapsed/refractory large B-cell lymphoma (LBCL)",
            "Drug": "CRG-022",
            "PFS": "None",
            "OS": "None",
            "ORR": "Primary endpoint is ORR according to Lugano response criteria (Cheson, 2014) by blinded independent review.",
            "AE": "Adverse events (AEs) are graded per CTCAE v5 except with CRS, ICANS, and IEC-HS graded per ASTCT guidelines.",
            "Other": "The study will evaluate the safety/efficacy of CRG-022 at 1x106 CAR+ T cells/kg in pts with R/R LBCL whose disease has progressed after CD19-directed CAR T-cell therapy.",
            "Conclusion": "Based on notable results from previous studies, this potentially pivotal phase 2 trial has been initiated."
        },
        {
            "Title": "Phase 3 trial evaluating efficacy and safety of odronextamab plus CHOP vs rituximab plus CHOP in previously untreated diffuse large B-cell lymphoma (DLBCL; OLYMPIA-3).",
            "Indication": "Diffuse large B-cell lymphoma (DLBCL)",
            "Drug": "Odronextamab plus CHOP",
            "PFS": "Primary endpoint in Part 2 is PFS by independent central review.",
            "OS": "None",
            "ORR": "None",
            "AE": "Primary endpoints are the incidence of dose-limiting toxicities, and incidence and severity of treatment-emergent adverse events (Part 1).",
            "Other": "Part 2 key secondary endpoints are event-free survival and CR rate (both by independent central review), as well as OS. Other secondary endpoints are minimal residual disease (by ctDNA) and patient-reported outcomes (EORTC QLQ-C30, FACT-LymS, PGIS, PGIC, and EQ-5D-5L).",
            "Conclusion": "The study aims to evaluate the efficacy and safety of odronextamab plus CHOP vs R-CHOP in pts with previously untreated DLBCL and intermediate- or high-risk features."
        },
        {
            "Title": "CELESTIAL-TNCLL: An ongoing, open-label, multiregional, phase 3 study of sonrotoclax (BGB-11417) + zanubrutinib vs venetoclax + obinutuzumab for treatment-na\u00efve (TN) CLL.",
            "Indication": "Chronic Lymphocytic Leukemia (CLL)",
            "Drug": "Sonrotoclax (BGB-11417) + Zanubrutinib vs Venetoclax + Obinutuzumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Recruitment is ongoing."
        },
        {
            "Title": "BELLWAVE-011: Phase 3 randomized trial of nemtabrutinib versus ibrutinib or acalabrutinib in untreated chronic lymphocytic leukemia/small lymphocytic lymphoma.",
            "Indication": "Chronic Lymphocytic Leukemia (CLL) / Small Lymphocytic Lymphoma (SLL)",
            "Drug": "Nemtabrutinib vs Ibrutinib or Acalabrutinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Enrollment in this trial is ongoing."
        },
        {
            "Title": "BELLWAVE-010: A phase 3 study of nemtabrutinib plus venetoclax versus venetoclax plus rituximab (VR) in previously treated patients (pts) with relapsed/refractory (R/R) chronic lymphocytic leukemia (CLL)/small lymphocytic lymphoma (SLL).",
            "Indication": "Relapsed/Refractory Chronic Lymphocytic Leukemia (CLL) / Small Lymphocytic Lymphoma (SLL)",
            "Drug": "Nemtabrutinib + Venetoclax vs Venetoclax + Rituximab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Recruitment is ongoing."
        },
        {
            "Title": "BGB-11417-203: An ongoing, phase 2 study of sonrotoclax (BGB-11417), a next-generation BCL2 inhibitor, in patients with Waldenstr\u00f6m macroglobulinemia.",
            "Indication": "Waldenstr\u00f6m Macroglobulinemia (WM)",
            "Drug": "Sonrotoclax (BGB-11417)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Patient recruitment is ongoing in Australia, the US, China, and Europe."
        },
        {
            "Title": "A first-in-human study of the potent and highly selective BTK degrader ABBV-101 in patients with relapsed/refractory B-cell malignancies.",
            "Indication": "Relapsed/refractory B-cell malignancies",
            "Drug": "ABBV-101",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Safety, tolerability, pharmacokinetics (PK), and preliminary efficacy",
            "Conclusion": "Enrollment is ongoing with active sites in the US and Japan."
        },
        {
            "Title": "First-line treatment of follicular lymphoma (FL) with golcadamide, rituximab +/- nivolumab: An umbrella randomised phase 2 investigator-led study.",
            "Indication": "Follicular lymphoma (FL)",
            "Drug": "Golcadamide, Rituximab, Nivolumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint is CR rate post-induction in the absence of prohibitive toxicity. Secondary endpoints include overall toxicity, ORR, time to treatment failure, progression-free survival, overall survival.",
            "Conclusion": "To date, 15 patients have been randomized."
        },
        {
            "Title": "Phase 3 trial evaluating the efficacy and safety of odronextamab versus standard-of-care (SOC) therapy in relapsed/refractory (R/R) aggressive B-cell non-Hodgkin lymphoma (B-NHL; OLYMPIA-4).",
            "Indication": "Relapsed/refractory aggressive B-cell non-Hodgkin lymphoma (B-NHL)",
            "Drug": "Odronextamab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint is event-free survival. Key secondary endpoints include progression-free survival, best overall response, and change from baseline in physical function.",
            "Conclusion": "The trial is currently recruiting and is expected to enroll ~216 patients at ~200 global sites."
        },
        {
            "Title": "Phase 3 trial of odronextamab plus lenalidomide versus rituximab plus lenalidomide in relapsed/refractory (R/R) follicular lymphoma (FL) and marginal zone lymphoma (MZL; OLYMPIA-5).",
            "Indication": "Relapsed/refractory follicular lymphoma (FL) and marginal zone lymphoma (MZL)",
            "Drug": "Odronextamab, Lenalidomide, Rituximab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint is progression-free survival. Key secondary endpoints include CR, best overall response, and overall survival. Minimal residual disease (by ctDNA) analysis is an exploratory endpoint.",
            "Conclusion": "The trial is currently recruiting and is expected to enroll approximately 24\u201348 patients in Part 1 and 422 patients in Part 2 at ~200 global sites."
        },
        {
            "Title": "A phase 1/2, open-label, multicenter trial investigating the safety, tolerability, and preliminary antineoplastic activity of IPH6501, a first-in-class NK cell engager, in patients with relapsed and/or refractory CD20-expressing non-Hodgkin lymphoma.",
            "Indication": "Relapsed and/or refractory CD20-expressing non-Hodgkin lymphoma",
            "Drug": "IPH6501",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "Phase 3 trial evaluating the efficacy and safety of odronextamab versus investigator\u2019s choice in previously untreated follicular lymphoma (OLYMPIA-1).",
            "Indication": "Previously untreated follicular lymphoma",
            "Drug": "Odronextamab",
            "PFS": "Median PFS was 20.7 months",
            "OS": "Median overall survival was not reached",
            "ORR": "Objective response rate was 80%, complete response rate was 73%",
            "AE": "Treatment-related adverse events leading to treatment discontinuation was 7.8%",
            "Other": "Median duration of response was 22.6 months",
            "Conclusion": "The activity of odronextamab monotherapy in this heavily pretreated R/R FL population, including among patients with rituximab-refractory disease, provides a strong rationale for developing odronextamab as a chemotherapy-free option that can improve long-term outcomes in 1L."
        },
        {
            "Title": "LOTIS-5: An ongoing, phase 3, randomized study of loncastuximab tesirine with rituximab (Lonca-R) versus immunochemotherapy in patients with R/R DLBCL.",
            "Indication": "Relapsed or refractory diffuse large B-cell lymphoma (DLBCL)",
            "Drug": "Loncastuximab tesirine with rituximab (Lonca-R)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "An open label, single arm, multicenter phase II study of the efficacy and safety of LP-168 monotherapy for relapsed or refractory mantle cell lymphoma.",
            "Indication": "Relapsed or refractory mantle cell lymphoma",
            "Drug": "LP-168",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "Phase 3 trial evaluating the efficacy and safety of odronextamab plus chemotherapy versus rituximab plus chemotherapy in previously untreated follicular lymphoma (OLYMPIA-2)",
            "Indication": "Previously untreated follicular lymphoma",
            "Drug": "Odronextamab plus chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Treatment-related adverse events led to treatment discontinuation in 7.8% of patients",
            "Other": "Objective and complete response (CR) rates of 80% and 73%, respectively; median duration of response of 22.6 months; median overall survival was not reached",
            "Conclusion": "Encouraging results support an investigation into whether odronextamab plus chemotherapy is superior to the current FL first-line standard of care of rituximab plus chemotherapy. This study will also evaluate whether odronextamab maintenance is required to achieve progression-free survival (PFS), given the risks associated with sustained B-cell depletion."
        },
        {
            "Title": "Multicenter, randomized phase II study of epcoritamab for patients with large B-cell lymphomas achieving a partial response after CD19-directed CAR-T-cell therapy (CAR-T)",
            "Indication": "Large B-cell lymphomas achieving a partial response after CD19-directed CAR-T-cell therapy",
            "Drug": "Epcoritamab",
            "PFS": "None",
            "OS": "None",
            "ORR": "Overall response rate (ORR) of 63% and a complete response (CR) rate of 39%",
            "AE": "Frequency and severity of treatment-emergent adverse events",
            "Other": "Conversion from D30 PR to CR; duration of response, duration of complete response, event-free survival, and overall survival",
            "Conclusion": "This study is a trial-in-progress that will evaluate the efficacy of epcoritamab compared to observation for patients with LBCLs achieving a PR on D30 post CAR-T PET-CT assessment."
        },
        {
            "Title": "Phase 3 study results of isatuximab, bortezomib, lenalidomide, and dexamethasone (Isa-VRd) versus VRd for transplant-ineligible patients with newly diagnosed multiple myeloma (IMROZ)",
            "Indication": "Newly diagnosed multiple myeloma (NDMM)",
            "Drug": "Isatuximab, bortezomib, lenalidomide, dexamethasone",
            "PFS": "Median PFS not reached (Isa-VRd) vs 54.3 months (VRd); HR 0.596 (98.5% CI 0.406\u20130.876), log-rank p=0.0005",
            "OS": "None",
            "ORR": "None",
            "AE": "Exposure-adjusted Grade 5 TEAE rate was 0.03 (Isa-VRd) vs 0.02 (VRd)",
            "Other": "CR: 74.7% (Isa-VRd) vs 64.1% (VRd); MRD-CR: 55.5% (Isa-VRd) vs 40.9% (VRd)",
            "Conclusion": "IMROZ is the first Phase 3 study of an anti-CD38 mAb with SOC VRd in this patient population to show a significantly reduced risk of progression or death by 40.4% vs VRd while providing deep and sustained responses. The safety profile was consistent with the addition of Isa to VRd. These results support Isa-VRd as a potential new SOC in patients not intended for transplant."
        },
        {
            "Title": "Phase 3 randomized study of isatuximab (Isa) plus lenalidomide and dexamethasone (Rd) with bortezomib versus IsaRd in patients with newly diagnosed transplant ineligible multiple myeloma (NDMM TI)",
            "Indication": "Newly diagnosed transplant ineligible multiple myeloma (NDMM TI)",
            "Drug": "Isatuximab, lenalidomide, dexamethasone, bortezomib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "44 (33%) patients presented with neurological adverse events grade \u22652 in the Isa-VRd vs 27 (20%) in IsaRd arm",
            "Other": "MRD negativity rates at 10-5 at 18 months were significantly higher in Isa-VRd arm compared to IsaRd arm (47% vs 24%, OR for negative MRD=2.96 [95% CI 1.73\u20135.07, p<0.001])",
            "Conclusion": "Isa-VRd significantly deepened responses including a significant increase of the MRD negative rate at 10-5 vs IsaRd. The safety profile is consistent with the addition of bortezomib. This study supports Isa-VRd as a new standard of care for NDMM TI non-frail patients."
        },
        {
            "Title": "Daratumumab (DARA) + bortezomib/lenalidomide/dexamethasone (VRd) in transplant-eligible (TE) patients (pts) with newly diagnosed multiple myeloma (NDMM): Analysis of minimal residual disease (MRD) in the PERSEUS trial",
            "Indication": "Newly diagnosed multiple myeloma (NDMM)",
            "Drug": "Daratumumab, bortezomib, lenalidomide, dexamethasone",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "MRD-negativity rates up to 36 months: 74.6% (D-VRd) vs 46.9% (VRd), p<0.0001",
            "Conclusion": "During maintenance, a greater proportion of pts with MRD-pos status achieved MRD-neg with D-R vs R. The higher rates of deep (10\u20136) and sustained MRD-neg achieved with D-VRd ind/consol and D-R maintenance vs VRd ind/consol and R maintenance translated to a clinically meaningful benefit of improved PFS. These data further support D-VRd and D-R maintenance as a new standard of care for TE pts with NDMM and highlight the benefit of DARA SC in maintenance."
        },
        {
            "Title": "DREAMM-7 update: Subgroup analyses from a phase 3 trial of belantamab mafodotin (belamaf) + bortezomib and dexamethasone (BVd) vs daratumumab, bortezomib, and dexamethasone (DVd) in relapsed/refractory multiple myeloma (RRMM)",
            "Indication": "Relapsed/refractory multiple myeloma (RRMM)",
            "Drug": "Belantamab mafodotin, bortezomib, dexamethasone, daratumumab",
            "PFS": "Median PFS in the ITT was 36.6 months with BVd vs 13.4 months with DVd (HR, 0.41; 95% CI, 0.31-0.53; P<.00001)",
            "OS": "Neither arm reached median OS (HR, 0.57; 95% CI, 0.40-0.80; nominal P=.00049)",
            "ORR": "ORR in the ITT was 82.7% (95% CI, 77.4%-87.3%) with BVd and 71.3% (65.3%-76.8%) with DVd",
            "AE": "95% (exp-adj, 69 per 100 person-years [PY]) of BVd pts and 76% (exp-adj, 62 per 100 PY) of DVd pts reported grade 3/4 AEs. Serious AEs were reported in 50% (exp-adj, 36 per 100 PY) of BVd pts vs 37% DVd (exp-adj, 30 per 100 PY) pts. Ocular AEs were more frequent with BVd vs DVd (79% vs 29%)",
            "Other": "None",
            "Conclusion": "In DREAMM-7 BVd demonstrated PFS benefit over DVd with an mPFS improvement of 23 months in pts with RRMM and \u22651 prior LOT. These results, demonstrating efficacy benefit in key subgroups with a high unmet need, support BVd as a potential new SOC in this setting."
        },
        {
            "Title": "Ciltacabtagene autoleucel vs standard of care in patients with functional high-risk multiple myeloma: CARTITUDE-4 subgroup analysis.",
            "Indication": "Functional high-risk multiple myeloma",
            "Drug": "Ciltacabtagene autoleucel",
            "PFS": "Median PFS: NR (NE\u2013NE) for Cilta-cel, 17 months (11\u2013NE) for SC; HR=0.35 (95% CI 0.2\u20130.7) for all patients. Median PFS: NR (18\u2013NE) for Cilta-cel, 12 months (8\u2013NE) for SC; HR=0.27 (95% CI 0.1\u20130.6) for FHRMM subset.",
            "OS": "None",
            "ORR": "ORR: 90% (61/68) for Cilta-cel, 79% (54/68) for SC; OR=2.3 (95% CI 1.3\u20134.05) for all patients. ORR: 88% (35/40) for Cilta-cel, 80% (31/39) for SC; OR=1.8 (95% CI 0.78\u20136.1) for FHRMM subset.",
            "AE": "Grade \u22653 TEAEs: 96% for Cilta-cel, 96% for SC; 100% for Cilta-cel, 97% for SC in FHRMM subset.",
            "Other": "MRD negativity (10\u20135): 63% for Cilta-cel, 19% for SC; OR=7.3 (95% CI 3.0\u201317.9) for all patients. MRD negativity (10\u20135): 65% for Cilta-cel, 10% for SC; OR=16.3 (95% CI 4.08\u201365.1) for FHRMM subset.",
            "Conclusion": "In patients with lenalidomide-refractory FHRMM after 1 prior LOT, ciltacabtagene autoleucel improved outcomes vs standard of care and had a safety profile consistent with the known mechanism of action of CAR-T therapy, suggesting ciltacabtagene autoleucel may overcome the poor prognosis associated with FHRMM."
        },
        {
            "Title": "Efficacy and safety of ciltacabtagene autoleucel + lenalidomide maintenance in newly diagnosed multiple myeloma with suboptimal response to frontline autologous stem cell transplant: CARTITUDE-2 cohort D.",
            "Indication": "Newly diagnosed multiple myeloma with suboptimal response to frontline autologous stem cell transplant",
            "Drug": "Ciltacabtagene autoleucel + lenalidomide",
            "PFS": "Progression-free survival rates at 18 months: 94%",
            "OS": "Overall survival rates at 18 months: 94%",
            "ORR": "Overall response rate: 94% (16/17); Complete response or better: 94%",
            "AE": "Grade 3/4 TEAEs: Neutropenia (94%), lymphopenia (65%), thrombocytopenia (47%), leukopenia (41%). Infections: 71% (grade 3/4, 29%). CRS: 82% (all grade 1/2). ICANS: 1 patient (grade 1). Other neurotoxicities: 6 patients (grade 1, n=1; grade 2, n=4; grade 3, n=1).",
            "Other": "MRD negativity (10\u20135): 80% (12/15); Median time to MRD negativity: 1 month (range, 1\u20136). Median duration of response: Not reached. Median time to first response: 1 month.",
            "Conclusion": "In patients with NDMM and less than complete response after frontline ASCT, a single ciltacabtagene autoleucel infusion + lenalidomide maintenance demonstrated deep responses that were durable. TEAEs were consistent with the known safety profile of ciltacabtagene autoleucel. These data show promising efficacy and safety with ciltacabtagene autoleucel + lenalidomide maintenance in patients with NDMM who achieved less than complete response after ASCT frontline therapy."
        },
        {
            "Title": "Safety results from the phase 3 MajesTEC-7 study in patients with transplant ineligible/not intended newly diagnosed multiple myeloma (NDMM).",
            "Indication": "Transplant ineligible/not intended newly diagnosed multiple myeloma",
            "Drug": "Teclistamab + daratumumab + lenalidomide",
            "PFS": "None",
            "OS": "None",
            "ORR": "Overall response rate: 92.3% (complete response or better, 73.1%; very good partial response or better, 92.3%)",
            "AE": "Grade 3/4 TEAEs: 84.6%. Infections: 96.2% (grade 3/4, 30.8%). CRS: 61.5% (all grade 1). ICANS: 1 patient (grade 1). Grade 3/4 TEAEs occurring in \u22653 patients: Neutropenia (50%), febrile neutropenia (19.2%), thrombocytopenia (15.4%), COVID-19 (11.5%), maculo-papular rash (11.5%), hypertension (11.5%).",
            "Other": "None",
            "Conclusion": "These results from the first safety run-in of MajesTEC-7 demonstrate a manageable safety profile with early efficacy of teclistamab + daratumumab + lenalidomide in NDMM. Two additional safety run-ins are ongoing investigating teclistamab (less frequent dosing) + daratumumab and talquetamab + daratumumab."
        },
        {
            "Title": "All-oral triplet iberdomide, ixazomib, and dexamethasone in elderly patients with multiple myeloma at first relapse: Results of the IFM phase 2 study I2D.",
            "Indication": "Multiple myeloma at first relapse",
            "Drug": "Iberdomide, ixazomib, and dexamethasone",
            "PFS": "Median PFS: 13 months. Median PFS in patients with MM refractory to both lenalidomide and daratumumab: 10 months.",
            "OS": "12-month overall survival: 85% (77-95% 95% CI)",
            "ORR": "Overall response rate: 64%, including 33% VGPR",
            "AE": "Most common non-hematologic AEs: Infection (30%), peripheral neuropathy (20%), diarrhea (19%) (mostly grade 1 or 2). Most common grade 3-4 treatment-related AEs (>5% of patients): Neutropenia (46%), thrombocytopenia (9%), infection (8%). Four patients discontinued treatment due to severe AE (cytopenia (n=3), skin rash (n=1)).",
            "Other": "None",
            "Conclusion": "The oral triplet iberdomide, ixazomib, and dexamethasone demonstrated a favorable efficacy/safety profile in elderly MM patients at first relapse, including in patients with lenalidomide and daratumumab refractory disease."
        },
        {
            "Title": "Impact of extramedullary multiple myeloma on outcomes with idecabtagene vicleucel",
            "Indication": "Relapsed/refractory multiple myeloma (RRMM)",
            "Drug": "Idecabtagene vicleucel (Ide-cel)",
            "PFS": "5.3 months (95% CI: 4.1-6.9) for EMD cohort vs. 11.1 months (95% CI: 9.2-12.6; p<0.0001) for non-EMD cohort",
            "OS": "14.8 months (95% CI: 9-Not reached) for EMD vs. 26.9 months (95% CI: 26.3-Not reached; p=0.006) for non-EMD",
            "ORR": "58% for EMD vs. 82% for non-EMD (p<0.001)",
            "AE": "Rates of Grade \u22652 cytokine release syndrome or neurotoxicity syndrome were comparable between the two cohorts",
            "Other": "Median duration of response for EMD among day 30 responders was 6.4 months (95% CI: 5.1-8.4)",
            "Conclusion": "Patients with EMD demonstrate significantly inferior Day 90 ORR and presence of EMD is an independent risk factor for inferior PFS."
        },
        {
            "Title": "Final results of a phase II study of lenalidomide-elotuzumab as maintenance therapy post-autologous stem cell transplant (AuSCT) in patients (Pts) with multiple myeloma (MM)",
            "Indication": "Multiple myeloma (MM)",
            "Drug": "Lenalidomide-elotuzumab",
            "PFS": "75.4 months",
            "OS": "5-year OS rate was 86.7%. Median OS has not been reached",
            "ORR": "At study entry, rate of \u2265VGPR was 77.3% and \u2265CR was 37.7%. On study, this improved to 91.1% \u2265VGPR and 69.3% \u2265CR",
            "AE": "Grade 3-4 HEME AEs: neutropenia 35%, thrombocytopenia 7%, anemia 10%. Grade 3-4 non-HEME AEs: respiratory infections 23%, hypophosphatemia 19%, diarrhea 13%, fatigue 11%, peripheral neuropathy 7%, other infections 6%, myalgias 4%, high ALT 4%",
            "Other": "Incidence of HEME, non-invasive and solid tumor SPMs was 8%, 8%, and 7%. Median time to HEME SPM diagnosis was 45.5 months",
            "Conclusion": "ELO-LEN is a well-tolerated maintenance therapy on which 53% of patients had improved quality of response. Median PFS and 5-year OS compare favorably with results from CALGB100104 and IFM2005-02 trials of lenalidomide alone. SPM incidence is similar to rates reported in these trials."
        },
        {
            "Title": "Efficacy of venetoclax-dexamethasone (VenDex) vs pomalidomide-dexamethasone (PomDex) in patients (Pts) with t(11;14)-positive relapsed/refractory multiple myeloma [t(11;14)+RRMM]: Phase 3 CANOVA study biomarker subgroup analysis",
            "Indication": "t(11;14)-positive relapsed/refractory multiple myeloma (RRMM)",
            "Drug": "Venetoclax-dexamethasone (VenDex) vs pomalidomide-dexamethasone (PomDex)",
            "PFS": "VenDex: 9.4 months vs PomDex: 4.0 months",
            "OS": "VenDex: 32.4 months vs PomDex: 24.5 months",
            "ORR": "VenDex: 60% vs PomDex: 39%",
            "AE": "None",
            "Other": "Pts with BCL2 high or gain (1q) had numerically improved clinical efficacy with VenDex vs PomDex. Clinical benefit was consistent across BCL2 subgroups (BCL2 high or BCL2 low) with VenDex but not PomDex. Pts with amp (1q) fared poorly irrespective of treatment arm.",
            "Conclusion": "Pts with BCL2 high or gain (1q) had numerically improved clinical efficacy with VenDex vs PomDex. Clinical benefit was consistent across BCL2 subgroups (BCL2 high or BCL2 low) with VenDex but not PomDex. Pts with amp (1q) fared poorly irrespective of treatment arm."
        },
        {
            "Title": "OriCAR-017, a novel GPRC5D-targeting CAR-T, in patients with relapsed/refractory multiple myeloma: Long term follow-up results of phase 1 study (POLARIS)",
            "Indication": "Relapsed/refractory multiple myeloma",
            "Drug": "OriCAR-017",
            "PFS": "11.37 months (95% CI, 5.93-18.00)",
            "OS": "mOS has not reached (7 pts still undergo survival follow-up, 1 pt died from disease progress, 2 from COVID)",
            "ORR": "100%, sCR: 80%, VGPR: 20%",
            "AE": "Nine (90%) pts had grade 1 CRS, and 1 (10%) pt had grade 2 CRS. No \u2265G3 CRS was observed. No ICANS, nor DLTs were observed. There were no SAE and no treatment-related deaths.",
            "Other": "MRD negativity was achieved in all RRMM pts, including in pts refractory to anti-CD38, PIs and IMIDs and failure to BCMA-directed therapy with excellent safety profile.",
            "Conclusion": "The updated results showed OriCAR-017 continued to provide deep and durable responses, MRD negativity was achieved in all RRMM pts, including in pts refractory to anti-CD38, PIs and IMIDs and failure to BCMA-directed therapy with excellent safety profile. The results of long-term efficacy and safety follow-up support that OriCAR-017 is highly probable to be developed as a promising therapy in RRMM."
        },
        {
            "Title": "Exploring the role of the combination of FDG PET plus whole body MRI for staging newly diagnosed and relapsed/refractory multiple myeloma: A prospective trial.",
            "Indication": "Multiple Myeloma",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Accuracy of WB-MRI was 97%, while PET-CT was 77%. Concordance of WB-MRI and PET-CT was 73%.",
            "Conclusion": "Our preliminary results support a potential complementary role of WB-MRI and FDG PET/CT findings in the management of patients with MM at both diagnosis and relapse."
        },
        {
            "Title": "Class comparison of BCMA-directed therapies in relapsed multiple myeloma.",
            "Indication": "Relapsed Multiple Myeloma",
            "Drug": "BCMA-directed therapies (CAR-T cells, ADCs, TCEs)",
            "PFS": "TCEs: aHR PFS = 0.59 (95% CI = 0.40-0.86), OS: aHR OS = 0.60 (95% CI = 0.39-0.93)",
            "OS": "TCEs: aHR OS = 0.60 (95% CI = 0.39-0.93)",
            "ORR": "ADCs: 27%, TCEs: 50%, CAR-T: 86%",
            "AE": "None",
            "Other": "Median PFS for patients with plasmacell leukemia (PCL) or EMD at the time of BCMA therapy: ADCs: 1.3 months, TCEs: 1.7 months, CAR-T: 6.1 months. Median PFS for patients previously treated with BCMA therapy: ADCs: 1.9 months, TCEs: 2.4 months, CAR-T: 3 months.",
            "Conclusion": "Patients able to receive BCMA-directed CAR-T therapy experience superior survival compared to ADC and TCEs. EMD, PCL, and BCMA-exposed populations are recalcitrant to BCMA-directed immunotherapy, and EMD is a common means of relapse."
        },
        {
            "Title": "Outcomes of patients who received CAR T cell therapy and developed IEC-HS treated with cytokine directed therapy",
            "Indication": "Immune effector cell hemophagocytic lymphohistiocytosis (IEC-HS)",
            "Drug": "CAR T cell therapy, tocilizumab, anakinra, siltuximab, basiliximab, dexamethasone, methylprednisolone",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "100-day mortality after IEC-HS in the CAR T patients was 40%. Higher TNF, markedly elevated levels of IL-6 and IL-10, and an IL-6 response were associated with survival at day 100.",
            "Conclusion": "IEC-HS is a devastating complication of CAR T. Anti-cytokine therapy can reduce some cytokines and ferritin but overimmunosuppression may lead to infectious complications, particularly with E. faecium. Better biomarkers are needed to fine-tune immunosuppressive therapy to avoid over-immunosuppression."
        },
        {
            "Title": "Longer-term follow-up of patients (pts) receiving prophylactic tocilizumab (toci) for the reduction of cytokine release syndrome (CRS) in the phase 1/2 MajesTEC-1 study of teclistamab in relapsed/refractory multiple myeloma (RRMM)",
            "Indication": "Relapsed/refractory multiple myeloma (RRMM)",
            "Drug": "Teclistamab, tocilizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "73% (59% very good partial response or better)",
            "AE": "Infections (79%/25%), neutropenia (63%/63%), anemia (58%/25%), neurotoxicity AE (grade 1 dizziness; grade 1 headache; grade 1 insomnia; grade 2 headache; grade 2 immune effector cell-associated neurotoxicity syndrome)",
            "Other": "CRS occurred in 6 pts (25%; 2 grade 1, 4 grade 2, no grade \u22653); 3 pts each had 1 recurrent CRS event. Median time to CRS onset was 2 days (range, 1\u20133); median duration was 2 days (range, 2\u20134). CRS was managed with additional toci in 5/6 pts and steroids in 1/6; all CRS events resolved and none led to teclistamab discontinuation.",
            "Conclusion": "Prophylactic toci reduced the incidence of CRS with teclistamab, with a 65% relative reduction vs the overall MajesTEC-1 population. No new safety signals or impact on response to teclistamab was observed with longer follow-up. Prophylactic toci may be a useful measure to consider when selecting pts for outpatient administration of teclistamab in the future."
        },
        {
            "Title": "BCMA-directed CAR T therapy in patients with multiple myeloma and CNS involvement",
            "Indication": "Multiple myeloma with CNS involvement",
            "Drug": "BCMA-directed CAR T therapy (ide-cel and cilta-cel)",
            "PFS": "None",
            "OS": "None",
            "ORR": "73% (45% CR/sCR, 18% VGPR, 9% PR)",
            "AE": "Grade 1/2 CRS (82%), Grade \u22653 CRS (none), Grade 1 ICANS (18%), Grade 3 ICANS (9%), Grade 4 ICANS (none), delayed neurotoxicity (18%)",
            "Other": "100% CNS response rate by day 90 defined by improved imaging findings or clearance of CSF involvement. CNS recurrence occurred in 1 patient (9%) by day 172 while 5 (45%) had continued systemic response at last assessment.",
            "Conclusion": "BCMA-directed CAR T therapy in patients with MM and CNS involvement is safe and feasible. Of the evaluable patients for CNS response, 100% showed CNS response by day 90. Larger studies with longer follow-up are needed to confirm these findings."
        },
        {
            "Title": "Real world outcome of patients with multiple myeloma who received bispecific antibodies after CAR-T therapy",
            "Indication": "Relapsed/refractory multiple myeloma (MM)",
            "Drug": "Bispecific antibodies (teclistamab, talquetamab)",
            "PFS": "Patients who relapsed <1 year post CAR-T: median PFS 8.84 months (range, 1.12\u201310.84); Patients who relapsed \u22651 year post CAR-T: median PFS 16.62 months (range, 13.30\u201336.20)",
            "OS": "None",
            "ORR": "Overall ORR to teclistamab was 61%. ORR for patients with relapse <1 year post CAR-T: 52.4%; ORR for patients with relapse \u22651 year post CAR-T: 85.7%",
            "AE": "None",
            "Other": "CR/sCR rate was significantly higher in patients with disease relapse \u22651 year compared to <1 year post CAR-T (57% vs 14%, p=0.02).",
            "Conclusion": "Irrespective of prior CAR-T exposure, bispecific antibodies have clinical activity in SOC practice in patients with MM. Preliminary data suggest that responses to bispecific antibodies are better in patients with late compared to early relapse post CAR-T. Longer follow-up is needed to understand the duration of response. Larger, multi-center studies with longer follow-up will help identify factors that impact bispecific antibody response post CAR-T."
        },
        {
            "Title": "Evaluation of cytokine release syndrome (CRS) in patients with relapsed or refractory multiple myeloma (RRMM) receiving step-up priming doses and longer dosing intervals of elranatamab: MagnetisMM-9.",
            "Indication": "Relapsed or refractory multiple myeloma (RRMM)",
            "Drug": "Elranatamab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Most common AEs were CRS (63.5%; grade \u22652, 15.3%) and neutropenia (54.1%; all grade \u22652). ICANS occurred in 4.7% of pts (all grade \u22642).",
            "Other": "The 4/20 mg step-up priming regimen and alternative dosing schedules resulted in similar safety and incidence of overall and grade \u22652 CRS events vs the regimen used in MM-3 (12/32 mg), with no new safety signals identified.",
            "Conclusion": "The priming regimen used in MM-3 remains the optimal regimen for mitigating CRS. Future analyses of ongoing studies will be used to confirm these results."
        },
        {
            "Title": "Effect of talquetamab on responses in patients with relapsed and refractory multiple myeloma with prior exposure to T-cell directed therapies.",
            "Indication": "Relapsed and refractory multiple myeloma",
            "Drug": "Talquetamab",
            "PFS": "None",
            "OS": "None",
            "ORR": "Overall response rate (ORR) was 71.4% (n=15) and complete response (CR) rate was 28.6% (n=6).",
            "AE": "CRS occurred in 57% (n=12) of patients, with 7 pts having grade 1 and 5 pts having grade 2. 19.0% (n=4) patients had ICANS, with 1 pt having grade 1, 2 pts having grade 2, and 1 pt having grade 3. Infections were seen in 3 (14%) pts. 8 (38%) pts had treatment delays, mainly due to CRS, ICANS, and infections. 61.9% (n=13), 28.6% (n=6), and 76.2% (n=16) of patients experienced skin AEs, nail AEs, or dysgeusia, respectively.",
            "Other": "None",
            "Conclusion": "This single center study in pts with heavily pretreated and prior TCDTs demonstrated favorable ORR (71.4%) and CR (28.6%) rates, comparable to patients in the MonumenTAL-1 trial with and without exposure to prior T-cell directed therapies. Results on PFS, OS, and AEs will be reported with continued follow-up and will be presented in the meeting."
        },
        {
            "Title": "Rapid reduction of free light chains and improvement in renal outcomes in patients with newly diagnosed multiple myeloma admitted with acute kidney injury with daratumumab-based therapy and without plasmapheresis.",
            "Indication": "Newly diagnosed multiple myeloma with acute kidney injury",
            "Drug": "Daratumumab",
            "PFS": "Median PFS was 47 months (95% CI 12-not reached).",
            "OS": "2-year OS was 84% (95% CI 68-100%).",
            "ORR": "IMWG ORR was 100% with VGPR 90%.",
            "AE": "None",
            "Other": "Within 1 cycle of therapy, all 20 pts achieved sFLC reduction \u226550% at a median of 3 days (95% CI, 3-7 days). Median time to sFLC \u2264500 mg/L was 13 days (95% CI, 9-33 days), and this was in 15 out of 16 pts (94%) assessable for response after 1 cycle of treatment. At 3 months, the overall renal response (\u2265minor response) was 85% (N=17), with complete, partial, and minor responses in 50% (N=10), 10% (N=2), and 25% (N=5), respectively. Twelve pts (60%) recovered renal function with eGFR \u226540 mL/min.",
            "Conclusion": "Treatment with daratumumab combinations shows rapid and deep reductions in sFLC, improving renal recovery outcomes. These findings highlight the efficacy of early use of daratumumab in pts with MM-induced AKI and provide an approach without plasmapheresis."
        },
        {
            "Title": "Association of patient (pt) factors and pharmacodynamic biomarkers with progression-free survival (PFS) after idecabtagene vicleucel (ide-cel) in pts from KarMMa-3",
            "Indication": "relapsed/refractory multiple myeloma (RRMM)",
            "Drug": "idecabtagene vicleucel (ide-cel)",
            "PFS": "Median PFS in ide-cel treated population: 15.7 months (95% CI, 12.5\u201318.9); PFS benefit consistent across high-risk pt subgroups",
            "OS": "None",
            "ORR": "94% of pts with longer PFS achieved a best overall response of \u2265 very good partial response",
            "AE": "None",
            "Other": "Lower levels of b-2 microglobulin, lactate dehydrogenase, and sBCMA at baseline associated with longer PFS; higher MRD negativity at 6 months and sustained MRD negativity in pts with longer PFS",
            "Conclusion": "Pts with longer PFS after ide-cel infusion had relatively lower tumor burden and lower levels of peripheral inflammatory markers before tx, highlighting the importance of managing baseline tumor burden before CAR T cell infusion to achieve optimal response. Complete and sustained clearance of sFLCs within the first few months post-infusion was associated with longer PFS. MRD negativity at 6 months and sustained MRD negativity were enriched in pts with longer PFS."
        },
        {
            "Title": "OPTec: A phase 2 study to evaluate outpatient (OP) step-up administration of teclistamab (Tec), a BCMA-targeting bispecific antibody, in patients (pts) with relapsed/refractory multiple myeloma (RRMM)",
            "Indication": "relapsed/refractory multiple myeloma (RRMM)",
            "Drug": "teclistamab (Tec)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Fatigue, headache, and neutropenia (2 each); Gr2 hypotension (1 pt) and Gr1 confusion and dizziness (1 pt each) not felt due to CRS or ICANS",
            "Other": "Prophylactic tocilizumab (proToci) reduced the incidence and severity of CRS associated with Tec",
            "Conclusion": "Initial results indicate that proToci prior to SUD1 of Tec may mitigate the risk of CRS. Enrollment is ongoing to determine if proToci may make Tec safe to give in an OP setting and increase accessibility in community centers."
        },
        {
            "Title": "Validation of prototype biomarkers to identify risk factors of inflammatory adverse events (iAEs) following idecabtagene vicleucel (ide-cel) infusion in patients with relapsed and refractory multiple myeloma (RRMM) in KarMMa-3",
            "Indication": "relapsed/refractory multiple myeloma (RRMM)",
            "Drug": "idecabtagene vicleucel (ide-cel)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Two of the 3 composite biomarkers met validation criteria for grade \u22653 CRS risk. Criteria were not met for grade 2 CRS, suggesting these models are specific to higher-grade events and that grade 2 CRS represents a more heterogeneous group of patients.",
            "Conclusion": "While high-grade iAEs are rare with ide-cel, these models may further optimize the robust benefit\u2013risk profile that ide-cel has demonstrated by helping identify, prior to or shortly after infusion, patients most at risk for severe events."
        },
        {
            "Title": "Evaluation of a novel method to guide belantamab mafodotin dosing in multiple myeloma based on a patient-reported questionnaire",
            "Indication": "newly diagnosed multiple myeloma",
            "Drug": "belantamab mafodotin",
            "PFS": "None",
            "OS": "None",
            "ORR": "ORR was 93.3%/85.7% in Groups A and B (PR/VGPR/CR: 26.7%/53.3%/13.3%; 21.4%/64.3%/0.0%)",
            "AE": "Group A: Gr2 BCVA decline 34.6%, Gr \u22653 BCVA decline 6.4%; Group B: Gr2 BCVA decline 24.2%, Gr \u22653 BCVA decline 0.0%",
            "Other": "The VRA tool was safe and effective in informing belamaf dose modifications. Future analyses will determine if VRA may effectively eliminate the need for an ophthalmic exam prior to belamaf dosing.",
            "Conclusion": "The VRA tool was safe and effective in informing belamaf dose modifications. Future analyses will determine if VRA may effectively eliminate the need for an ophthalmic exam prior to belamaf dosing."
        },
        {
            "Title": "Efficacy, safety, and determination of RP2D of ABBV-383, a BCMA bispecific antibody, in patients with relapsed/refractory multiple myeloma (RRMM).",
            "Indication": "Relapsed/Refractory Multiple Myeloma (RRMM)",
            "Drug": "ABBV-383",
            "PFS": "None",
            "OS": "None",
            "ORR": "65% at 60 mg Q4W, 64% at 40 mg Q3W, 60% at 60 mg Q3W",
            "AE": "CRS: 43% at 60 mg Q4W (38% G1, 5% G2), 71% at 40 mg Q3W (45% G1, 25% G2), 70% at 60 mg Q3W (51% G1, 18% G2, 2% G3); G3/4 neutropenia: 14% at 60 mg Q4W, 31% at 40 mg Q3W, 34% at 60 mg Q3W; G3/4 anemia: 24% at 60 mg Q4W, 31% at 40 mg Q3W, 13% at 60 mg Q3W; G3/4 thrombocytopenia: 10% at 60 mg Q4W, 16% at 40 mg Q3W, 13% at 60 mg Q3W; G3/4 infections: 10% at 60 mg Q4W, 24% at 40 mg Q3W, 34% at 60 mg Q3W",
            "Other": "VGPR: 50% at 60 mg Q4W, 53% at 40 mg Q3W, 52% at 60 mg Q3W",
            "Conclusion": "The optimal therapeutic dose of 60 mg Q4W ABBV-383 monotherapy was selected on the basis of safety, efficacy, and ER analyses. The extended interval of Q4W, with a shortened CRS monitoring period in cycle 1 and no step-up dosing, will improve convenience and reduce the treatment burden for patients. ABBV-383 at 60 mg Q4W will be investigated in the registrational phase 3 trial (NCT06158841) in RRMM."
        },
        {
            "Title": "Second primary B-ALL after exposure to lenalidomide for multiple myeloma: A US multicenter study.",
            "Indication": "Second primary B-cell acute lymphoblastic leukemia (B-ALL) after multiple myeloma",
            "Drug": "Lenalidomide",
            "PFS": "None",
            "OS": "3-year OS from ALL diagnosis: 50% (CI, 33%-76%)",
            "ORR": "CR: 56%, CRi: 28%, Induction failure: 16%",
            "AE": "None",
            "Other": "MRD negativity: 32%",
            "Conclusion": "Most patients had standard risk MM and were exposed to lenalidomide for several years prior to the diagnosis of B-ALL, characterized by a high incidence of hypodiploid karyotype; IKZF1 loss and TP53 deletions/mutations were detected in a subset of patients. MRD-adapted timely discontinuation of lenalidomide in standard risk MM may decrease the risk of second primary ALL."
        },
        {
            "Title": "Post-ASCT consolidation with EPD or ERD in patients with high-risk multiple myeloma.",
            "Indication": "High-risk Multiple Myeloma (MM)",
            "Drug": "EPD (Elotuzumab, Pomalidomide, Dexamethasone) or ERD (Elotuzumab, Lenalidomide, Dexamethasone)",
            "PFS": "Median PFS: 26.9 months",
            "OS": "None",
            "ORR": "VGPR or better: 85.8% post-EPD/ERD",
            "AE": "Anemia: 71.7%, Fatigue: 55.1%, Thrombocytopenia: 51.2%; Grade 3 adverse events: 3.8% infectious etiology, 2.5% immune-mediated toxicity",
            "Other": "MRD negativity rates improved from 21.7% to 26.9% pre and post-consolidation",
            "Conclusion": "Four cycles of EPD or ERD consolidation therapy post-ASCT demonstrated promising efficacy in HRMM patients. The observed median PFS of 26.9 months was comparable to that seen with chronic lenalidomide maintenance and superior to patients who do not receive lenalidomide maintenance. The improvement in PFS was achieved with a fixed duration rather than continuous therapy and without lenalidomide-associated toxicities such as secondary malignancies, chronic diarrhea, and financial toxicity. Further prospective confirmatory studies are needed."
        },
        {
            "Title": "Ciltacabtagene autoleucel in patients with lenalidomide-refractory multiple myeloma: CARTITUDE-2 cohort A expansion subgroup",
            "Indication": "Lenalidomide-refractory multiple myeloma",
            "Drug": "Ciltacabtagene autoleucel",
            "PFS": "12-mo PFS rate was 77%",
            "OS": "12-mo OS rate was 91%",
            "ORR": "ORR was 91%",
            "AE": "All pts had TEAEs (grade 3/4, n=22; grade 5, n=1). Hematologic TEAEs included neutropenia (96%), leukopenia (65%), lymphopenia (65%), anemia (57%), and thrombocytopenia (57%). Infections occurred in 8 (35%) pts (grade 3/4, n=1; grade 5, n=1 due to sepsis). CRS occurred in 23 (100%) pts (grade 1/2, n=23); median time to onset was 8 days and recovery was 4 days. ICANS occurred in 4 (17%) pts (grade 1, n=3; grade 4, n=1); median time to onset was 10 days and recovery was 2 days.",
            "Other": "MRD negativity at 10\u20135 in 100% of pts with evaluable samples. Median time to MRD negativity was 2 months (range, 1\u201312). Median DOR was not reached; 79% of responders remained in response at 12 months. Median time to first and best responses were 1 month (1\u201310) and 6 months (1\u201319), respectively. No other neurotoxicities or MNTs/parkinsonism occurred. 2 pts had secondary malignancies (SM); 1 pt had separate SM of grade 2 basal cell carcinoma and grade 2 squamous cell carcinoma and 1 pt had MDS diagnosed on day 846 that later transformed to AML. Per investigator assessment, SM were not tx-related.",
            "Conclusion": "In the expansion subgroup of cohort A, cilta-cel led to deep and durable responses in pts with len-refractory MM as early as after first relapse; safety was consistent with the known mechanism of action of CAR-T tx. Results in the expansion and initial subgroups are comparable. Data for CARTITUDE-2 cohort A underscore observations in CARTITUDE-4 which enrolled a similar pt population."
        },
        {
            "Title": "Real-world scheduled de-escalation of teclistamab in patients with relapsed/refractory multiple myeloma",
            "Indication": "Relapsed/refractory multiple myeloma",
            "Drug": "Teclistamab",
            "PFS": "6-mo PFS rate was 52.3% (95% CI 41.5-66%) for all pts; 6-mo PFS rate was 94.1% (95% CI 83.6-100%) for pts who switched to de-escalated dosing schedules",
            "OS": "None",
            "ORR": "ORR was 67% for early initiators and 59% for recent initiators",
            "AE": "TEAEs included neutropenia (96%), leukopenia (65%), lymphopenia (65%), anemia (57%), and thrombocytopenia (57%). Infections occurred in 8 (35%) pts (grade 3/4, n=1; grade 5, n=1 due to sepsis). CRS occurred in 23 (100%) pts (grade 1/2, n=23); median time to onset was 8 days and recovery was 4 days. ICANS occurred in 4 (17%) pts (grade 1, n=3; grade 4, n=1); median time to onset was 10 days and recovery was 2 days.",
            "Other": "None",
            "Conclusion": "In this real-world study, Tec demonstrated comparable ORR to MajesTEC-1. Both early and recent Tec initiators had high baseline risk features. A higher proportion of early initiators had prior BCMA therapy and more LOTs, but both cohorts yielded consistently high treatment response rates. In pts who responded, scheduled de-escalation was feasible with a high subsequent 6-mo PFS."
        },
        {
            "Title": "Impact of renal impairment (RI) on pharmacokinetics (PK) and clinical outcomes with mezigdomide plus dexamethasone (DEX) in relapsed/refractory multiple myeloma",
            "Indication": "Relapsed/refractory multiple myeloma",
            "Drug": "Mezigdomide plus dexamethasone",
            "PFS": "None",
            "OS": "None",
            "ORR": "ORR was 40.6% in all pts, 56.8% in no-RI pts, 29.3% in mild-RI pts, and 34.8% in moderate-RI pts",
            "AE": "Grade 3/4 treatment-emergent AEs (TEAEs) were similar, occurring in 91.1% (overall), 89.2% (no RI), 90.2% (mild RI), and 95.7% (moderate RI) of pts. TEAEs were mostly hematologic, with neutropenia the most common Grade 3/4 TEAE; its occurrence did not differ in no RI (75.7%), mild RI (75.6%), and moderate RI (73.9%) cohorts. Non-hematologic Grade 3/4 TEAEs were low. 89 (88.1%) and 32 (31.7%) pts had MEZI dose interruptions and reductions, respectively.",
            "Other": "Evaluated as a continuous variable, CrCl showed no apparent relationship with MEZI oral clearance or systemic exposures. This was supported by subgroup evaluation of MEZI exposures by CKD stage. Results showed MEZI exposures in mild or moderate RI are consistent with no RI.",
            "Conclusion": "Based on renal function, there was no adverse trend between efficacy, safety, and PK of MEZI+DEX. MEZI dose modifications are likely not required for pts with mild to moderate RI."
        },
        {
            "Title": "Long-term follow-up from the phase 1/2 MajesTEC-1 trial of teclistamab in patients with relapsed/refractory multiple myeloma",
            "Indication": "Relapsed/refractory multiple myeloma",
            "Drug": "Teclistamab",
            "PFS": "Median PFS improved to 11.4 months; for pts with CR, estimated 30-mo PFS rate was 61.0%",
            "OS": "Median OS improved to 22.2 months; for pts with CR, estimated 30-mo OS rate was 74.2%",
            "ORR": "ORR was 63.0%, with 46.1% achieving CR",
            "AE": "Hematologic AEs (any grade/grade 3/4) included neutropenia (72%/65%), anemia (55%/38%), thrombocytopenia (42%/23%), and lymphopenia (36%/35%). Infections occurred in 79% of pts (55% grade 3/4). Of grade 5 infections, 18/22 were due to COVID-19. Onset of new grade \u22653 infections generally decreased over time, aligning with the median time of switch to Q2W dosing.",
            "Other": "85.7% (48/56) of MRD-evaluable pts were MRD negative (10-5 threshold). Median duration of response (mDOR) increased to 24.0 months. For pts with CR, mDOR was not yet reached, and estimated 30-mo DOR rate was 60.8%. Of the 38 pts who remain on treatment, 37 have switched to a less frequent dosing schedule (e.g., Q2W), all of whom maintained responses.",
            "Conclusion": "With the longest follow-up of any BsAb in MM, teclistamab continues to demonstrate deep and durable responses, including in pts who switch to less frequent dosing. The safety profile of teclistamab remains consistent with that of BCMA-targeted bispecific therapies, with a notable decrease in new onset of severe infections with time."
        },
        {
            "Title": "Exposure-response analyses for optimal therapeutic dose selection of ABBV-383 in patients with relapsed/refractory multiple myeloma (RRMM)",
            "Indication": "Relapsed/Refractory Multiple Myeloma",
            "Drug": "ABBV-383",
            "PFS": "None",
            "OS": "None",
            "ORR": "63% ORR, 53% VGPR, 30% sCR/CR for 40, 60mg Q3W and 60mg Q4W",
            "AE": "31% \u2265G3 neutropenia, lower CRS events",
            "Other": "ER analyses indicated promising efficacy and acceptable safety profiles for 40, 60mg Q3W, and 60mg Q4W",
            "Conclusion": "60mg Q4W is supported as the optimal ABBV-383 therapeutic dose due to its predicted better therapeutic benefit/risk profile and extended dosing interval. ABBV-383 at 60mg Q4W will be investigated in the registrational phase 3 trial (NCT06158841) in RRMM."
        },
        {
            "Title": "Incidence of acute kidney injury in patients with relapsed and refractory multiple myeloma treated with teclistamab vs chimeric antigen receptor T-cell therapy",
            "Indication": "Relapsed/Refractory Multiple Myeloma",
            "Drug": "Teclistamab, Chimeric Antigen Receptor T-cell Therapy (CAR-T)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "32% (95% CI: 14%-47%) for teclistamab patients and 10% (95% CI: 0%-21%) for CAR-T patients",
            "Other": "Median time to development of AKI was 30 days (IQR 16, 73) in teclistamab and 59 days (IQR 9, 146) in CAR-T patients. Among the 10 patients in the teclistamab group with an AKI event, 4 (40%) had complete renal recovery and 4 (40%) had partial recovery.",
            "Conclusion": "Almost one third of patients receiving teclistamab therapy for relapsed and refractory multiple myeloma experienced an incident AKI event during follow-up. This finding underscores the importance of monitoring for kidney function in these patients."
        },
        {
            "Title": "Patient-reported outcomes (PROs) from the DREAMM-7 randomized phase 3 study comparing belantamab mafodotin, bortezomib, and dexamethasone (BVd) vs daratumumab, bortezomib, and dexamethasone (DVd) in patients with relapsed/refractory multiple myeloma (RRMM)",
            "Indication": "Relapsed/Refractory Multiple Myeloma",
            "Drug": "Belantamab mafodotin, Bortezomib, Dexamethasone (BVd) vs Daratumumab, Bortezomib, Dexamethasone (DVd)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Severity and interference of blurred vision and frequency of watery eyes were reported at higher levels (PRO-CTCAE ratings \u22653) in the BVd arm",
            "Other": "Overall quality of life, role functioning, physical functioning, fatigue, and pain were comparable in patients treated with BVd vs DVd.",
            "Conclusion": "In patients treated with BVd who reported a clinically meaningful deterioration in vision-related functioning, overall quality of life was consistent with the DVd arm."
        },
        {
            "Title": "Long-term follow-up of ARI0002h (cesnicabtagene autoleucel), an academic point-of-care B-cell maturation antigen (BCMA)-directed chimeric antigen receptor (CAR) T-cell strategy: Activity and safety after fractionated initial therapy and booster dose in 60 patients with relapsed/refractory multiple myeloma (RRMM)",
            "Indication": "Relapsed/Refractory Multiple Myeloma",
            "Drug": "ARI0002h (cesnicabtagene autoleucel)",
            "PFS": "Median PFS was 20 months (95% CI 13.2-26.8)",
            "OS": "Median OS was not reached with OS rate at 24 months of 63%",
            "ORR": "95% ORR within the first 3 months (\u2265VGPR in 77%), 58% CR (55% stringent CR)",
            "AE": "CRS observed in 90% with 5% grades \u22653, mild acute neurotoxicity in 3%, 10% developed macrophage activation syndrome, 11.7% developed second primary malignancies",
            "Other": "MRD-negative rates on evaluable samples on days 28 and 100 were 98% and 96%, respectively. 80% (44 out of 55) eligible pts had already received the booster dose, with no relevant toxicities.",
            "Conclusion": "Results from 60 pts and a longer follow-up confirm the safety profile and the deep and durable responses obtained after ARI0002h infusion."
        },
        {
            "Title": "Characterization of the BCMA epitope bound by BCMA-CD3 T cell engager elranatamab",
            "Indication": "Relapsed/Refractory Multiple Myeloma (RRMM)",
            "Drug": "Elranatamab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Elranatamab binds to BCMA with higher affinity (~38 pM) compared to reported binding affinities of BAFF (mM range) and APRIL (nM range) ligands. The identified elranatamab epitope largely overlaps with known ligand epitopes, supporting that elranatamab higher affinity BCMA binding blocks ligand binding. Alpha fold modeling of four recently reported rare BCMA mutations shows that they lie along the binding interface.",
            "Conclusion": "Due to its high affinity for BCMA, elranatamab potency is unlikely to be impacted by elevated levels of soluble BAFF or APRIL in RRMM patients. Future studies are needed to characterize the impact of emerging MM patient BCMA mutations on the binding and function of anti-BCMA targeting modalities."
        },
        {
            "Title": "Apixaban versus warfarin for primary thromboprophylaxis in patients with multiple myeloma undergoing immunomodulatory treatment",
            "Indication": "Multiple Myeloma (MM)",
            "Drug": "Apixaban vs Warfarin",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "All-cause mortality: Apixaban HR 0.70 (95% CI: 0.56-0.88), p=0.002; Intracranial hemorrhage: Apixaban HR 0.64 (95% CI: 0.19-2.19), p=0.47; Gastrointestinal bleeding: Apixaban HR 0.58 (95% CI: 0.31-1.08), p=0.083; Pulmonary embolism: Apixaban HR 1.07 (95% CI: 0.41-2.78), p=0.89; Deep venous thrombosis: Apixaban HR 0.70 (95% CI: 0.36-1.33), p=0.27; Ischemic stroke: Apixaban HR 0.90 (95% CI: 0.37-2.18), p=0.82",
            "Other": "None",
            "Conclusion": "Apixaban was associated with a lower risk of all-cause mortality and a comparable risk of bleeding and thromboembolism compared with warfarin among MM patients undergoing immunomodulatory treatment."
        },
        {
            "Title": "Associations of T-cell fitness prior to B-cell maturation antigen (BCMA)\u2013targeted chimeric antigen receptor T-cell (CART) and bispecific T-cell engager (BiTE) therapies and efficacy/toxicity in relapsed/refractory multiple myeloma (RRMM)",
            "Indication": "Relapsed/Refractory Multiple Myeloma (RRMM)",
            "Drug": "BCMA-directed T-cell therapies (CART and BiTE)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "CRS/ICANS occurred in 85.7% (n=6) in responders and 28.6% (n=2) in non-responders.",
            "Other": "Overall PSI was 184 in responders and 91 in non-responders (p=0.1649). CD4+ PSI was 160 in responders and 75 in non-responders (p=0.1649). CD8+ PSI was 207 in responders and 108 in non-responders (p=0.1981). Overall PSI was 143 in CRS/ICANS and 130 in no CRS/ICANS (p=0.7546).",
            "Conclusion": "Overall PSI, CD4+ PSI, and CD8+ PSI were 1.9-2.1 times higher in responders compared to non-responders and overall PSI was slightly higher in CRS/ICANS compared to no CRS/ICANS though the difference was not statistically significant. The non-responder group had more high-risk cytogenetics and higher extramedullary disease. One limitation was a small sample size and thus testing PSI in a larger cohort might yield statistically significant results."
        },
        {
            "Title": "Real world outcomes of high dose melphalan conditioning prior to autologous stem cell transplant in extramedullary multiple myeloma vs. multiple myeloma without extramedullary involvement: A single center experience.",
            "Indication": "Extramedullary multiple myeloma (EMM) and multiple myeloma without extramedullary involvement (non-EMM)",
            "Drug": "High dose melphalan",
            "PFS": "{'EMM': {'D+100': '98.57%', 'D+365': '87.14%'}, 'non-EMM': {'D+100': '99.43%', 'D+365': '88.07%'}, 'HR': {'D+100': 'p=0.50', 'D+365': 'p=0.75'}}",
            "OS": "{'EMM': {'D+100': '100.00%', 'D+365': '98.57%'}, 'non-EMM': {'D+100': '100.00%', 'D+365': '99.43%'}, 'HR': {'D+365': 'p=0.14'}}",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The EMM cohort exhibited similar rates of progression free survival (87.14% vs. 88.07%) and overall survival (98.57% vs. 99.43%) compared to the non-EMM cohort at one year post-ASCT after receiving high dose melphalan conditioning. There was no significant statistical difference between the EMM and non-EMM cohorts regarding progression-free survival (p=0.75) and overall survival (p=0.14) on day 365. High dose melphalan conditioning prior to autologous stem cell transplant is equally effective for patients with multiple myeloma with or without extramedullary involvement. Future studies with long-term follow-up are recommended to further explore these findings."
        },
        {
            "Title": "Effect of daratumumab on light chain reduction in newly diagnosed multiple myeloma with light chain cast nephropathy",
            "Indication": "Newly diagnosed multiple myeloma with light chain cast nephropathy",
            "Drug": "Daratumumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "FLC-R at day 30: 58% in dara-containing regimens vs 31% in non-dara-containing regimens (p=0.08); RR at day 90: 69% in dara-containing regimens vs 58% in non-dara-containing regimens (p=0.5)",
            "Conclusion": "Prompt reduction of iFLC is an important objective during the early treatment of LCCN in order to achieve a RR, and dara increases the odds of achieving a FLC-R. The use of dara led to higher odds of a RR at day 90, but not statistically significant. Achieving a FLC-R at day 30 was associated with achieving a RR at day 90. The role of dara should be explored in prospective studies for the upfront treatment of LCCN in NDMM."
        },
        {
            "Title": "Outcomes of myeloma cast nephropathy in the era of anti-CD38 monoclonal antibody-based frontline therapy: A retrospective cohort study",
            "Indication": "Newly diagnosed multiple myeloma with myeloma cast nephropathy",
            "Drug": "Anti-CD38 monoclonal antibodies",
            "PFS": "None",
            "OS": "1 year OS: 87.9% MCN, 96.1% control",
            "ORR": "None",
            "AE": "None",
            "Other": "1 year DFS: 87.0% MCN, 94.5% control; 1 year TTNT: 77.2% MCN, 91.7% control",
            "Conclusion": "Patients with NDMM and MCN who receive upfront anti-CD38+mAb therapy experience prolonged survival compared to prior findings. Anti-CD38+mAb may substantially reduce, though not fully eliminate, the negative survival impact of MCN. Multiple mechanisms, including time to proteinuria resolution, may be involved. Further study is warranted."
        },
        {
            "Title": "Effect of dose-adjusted melphalan on MRD-negativity to full dose melphalan in patients with multiple myeloma post-autologous stem cell transplant",
            "Indication": "Multiple myeloma post-autologous stem cell transplant",
            "Drug": "Melphalan",
            "PFS": "Median PFS not reached for either group (p=0.69)",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "MRD negativity rates at 10-5: 64% in MEL140, 61% in MEL200 (p=0.8); MRD negativity rates at 10-6: 39% in MEL140, 43% in MEL200 (p=0.7); Sustained MRD negativity at 10-5: 43% in MEL140, 50% in MEL200 (p=0.8)",
            "Conclusion": "Our real-world analysis demonstrates that MEL140 yields similar deep post-transplant remissions as MEL200, translating to improved PFS for patients achieving MRD negativity, regardless of the dose used. This is the first report to our knowledge of MRD negativity rate in patients receiving MEL140."
        },
        {
            "Title": "Open-label, single-arm phase Ib/II study of immune combination therapy with elotuzumab and belantamab mafodotin in patients with relapsed/refractory multiple myeloma.",
            "Indication": "Relapsed/refractory multiple myeloma (RRMM)",
            "Drug": "Elotuzumab and belantamab mafodotin",
            "PFS": "None",
            "OS": "None",
            "ORR": "40% (4/10) achieved partial responses (PR), 30% (3/10) had stable disease (SD)",
            "AE": "40% developed ocular keratopathy (grades 1-2), 3 patients had grade 3 lymphopenia, 1 patient had grade 3 pulmonary infection",
            "Other": "Median duration of treatment was 4 months (range 2-19). No dose-limiting toxicities observed. Among the four patients refractory to prior BCMA therapy, 2 (50%) achieved PR with duration of response (DOR) of 19 months and 9 months, respectively.",
            "Conclusion": "This novel combination immune therapy with belantamab mafodotin and elotuzumab appears to have an encouraging safety profile and promising preliminary efficacy in patients with heavily pretreated RRMM, including those with prior failure of BCMA-targeted therapy."
        },
        {
            "Title": "Indirect comparison of linvoseltamab versus teclistamab for triple-class exposed (TCE) relapsed/refractory multiple myeloma (RRMM).",
            "Indication": "Triple-class exposed (TCE) relapsed/refractory multiple myeloma (RRMM)",
            "Drug": "Linvoseltamab vs Teclistamab",
            "PFS": "Linvoseltamab: 11.1 months (95% CI: 8.1, 14.0); Teclistamab: 11.3 months (95% CI: 9.8, 13.0); HR: 0.75 (95% CI: 0.46, 1.22)",
            "OS": "Linvoseltamab: 18.27 months (95% CI: 18.27, NE); Teclistamab: 21.6 months (95% CI: 21.6, NE); HR: 0.77 (95% CI: 0.45, 1.31)",
            "ORR": "Linvoseltamab: 66% (before matching), 68% (after matching); Teclistamab: 63%; OR: 1.54 (95% CI: 0.96, 2.48) before matching, 1.39 (95% CI: 0.78, 2.48) after matching",
            "AE": "None",
            "Other": "Linvoseltamab exhibited higher ORR, VGPR, CR, and MRD(-) rates, with significant differences in CR. Linvoseltamab had significantly longer PFS and a trend toward longer OS and DOR.",
            "Conclusion": "The results suggest potentially greater efficacy for linvoseltamab vs teclistamab for all outcomes, highlighting its potential as a highly effective treatment option for TCE RRMM."
        },
        {
            "Title": "Comparative effectiveness of linvoseltamab versus standard-of-care (SOC) treatment (tx) in real-world patients (pts) in the United States (US) with triple-class exposed (TCE) relapsed/refractory multiple myeloma (RRMM).",
            "Indication": "Triple-class exposed (TCE) relapsed/refractory multiple myeloma (RRMM)",
            "Drug": "Linvoseltamab",
            "PFS": "Linvoseltamab: 5.4 months (95% CI: 4.7, 7.8); RWECA: 3.7 months (95% CI: 3.1, 4.4); HR: 0.54 (95% CI: 0.39, 0.75)",
            "OS": "Linvoseltamab: 18.27 months (95% CI: 18.27, NE); RWECA: 10.3 months (95% CI: 7.7, 14.7); HR: 0.75 (95% CI: 0.46, 1.22)",
            "ORR": "Linvoseltamab: 66%; RWECA: 39%; OR: 2.54 (95% CI: 1.39, 4.68)",
            "AE": "None",
            "Other": "Linvoseltamab significantly improved ORR, PFS, TTNT, and OS vs the RW ECA.",
            "Conclusion": "Linvoseltamab significantly improved outcomes vs RWSOC in the US, highlighting its potential as a highly effective tx in pts with TCE RRMM."
        },
        {
            "Title": "Effect of maintenance therapy (MT) on real-world outcomes (RWO) of patients (pts) with newly diagnosed multiple myeloma (NDMM) post stem cell transplant (SCT).",
            "Indication": "Newly diagnosed multiple myeloma (NDMM)",
            "Drug": "Maintenance therapy (MT)",
            "PFS": "TTNT1: 51 months (MT) vs 36 months (no MT), p<0.001; TTNT2: 11.7 months (MT) vs 21.6 months (no MT), p<0.001",
            "OS": "108.8 months (MT) vs 126.3 months (no MT), p=0.08",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Our study demonstrates improved TTNT2 outcomes with the no MT approach. MT did not have an impact on OS in this cohort. Both results indicate an absence of long-term benefit with the use of MT therapy. OS is a difficult primary outcome to achieve in MM RCTs where survival may have to be collected for decades. Thus, our large study of RWO with long-term follow-up is valuable."
        },
        {
            "Title": "Real-world outcomes (RWO) with tandem transplantation in patients (pts) with newly diagnosed multiple myeloma (NDMM).",
            "Indication": "Newly diagnosed multiple myeloma (NDMM)",
            "Drug": "Tandem transplantation (TT)",
            "PFS": "TTNT1: 52.7 months (TT) vs 51 months (single SCT + MT), p=0.85; TTNT2: 33.9 months (TT) vs 11.8 months (single SCT + MT), p<0.001",
            "OS": "Not reached (TT) vs 108.1 months (single SCT + MT), p<0.001",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Our study demonstrates significant improvement in unadjusted TTNT2 and OS RWO for TT pts, despite higher incidence of HRCA compared to single SCT + MT pts. PS analysis demonstrated a similar effect with decreased HRs for TT. OS is an extremely difficult primary outcome to achieve in MM RCTs where survival may have to be collected for decades. Thus, our large study of RWO with long-term follow-up is valuable."
        },
        {
            "Title": "Exploring T cell subsets as predictors of response to BCMA targeting bispecific antibody therapy in multiple myeloma",
            "Indication": "Multiple Myeloma",
            "Drug": "BCMA targeting bispecific antibody (bsAb) therapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "Overall response (OR) rate of the whole cohort was 82% (69/79) with best responders (\u2265very good partial response) comprising 51% (40/79).",
            "AE": "Cytokine release syndrome (CRS) occurred in 38/79 (48%) of patients with the majority (92%) being grade 1 with the remainder being grade 2.",
            "Other": "Median age of the patient cohort was 72 (31-84) years with 40/79 (51%) being male and 15/79 (19%) being African American. Median lines of therapy was 5 (2-12) with 78/79 (99%) patients being triple class refractory and 50/79 (63%) being penta-drug refractory. 59/79 (75%) of patients had at least one prior stem cell transplant (SCT) with 35/79 (44%) having had at least two SCTs. 26/79 (33%) patients had prior exposure to BCMA targeting therapy, with 18/79 (23%) previously having received belantamab mafodotin or BCMA targeting Car-T cell therapy respectively. Median ALC was 1.02 (0.25-5) x 10^3/mL, median CD4 count was 0.27 (0.04-1.13) x 10^3/mL while median CD8 count was 0.5 (0.07-2.97) x 10^3/mL.",
            "Conclusion": "Our study suggests that an increased proportion of CD4 cells within the ALC is significantly associated with better response to BCMA targeting bsAb. While future studies are needed to elaborate on CD4 function in bsAb therapy, our findings imply that therapeutic strategies to increase the CD4 proportion could lead to improved bsAb therapy effectiveness."
        },
        {
            "Title": "Exploring the role of the combination of FDG-PET plus whole body MRI for staging patients with high-risk smoldering myeloma: A prospective trial",
            "Indication": "High-risk Smoldering Multiple Myeloma (HR-SMM)",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Comparison between WB-MRI and FDG PET-CT showed a discordance between the two imaging modalities in 4/26 (15%) cases. In particular, in 3/26 (12%) cases WB-MRI showed bone lesions that have led to symptomatic MM diagnosis according to IMWG criteria, while PET-CT was negative. In one case, PET-CT showed a diffuse uptake, not diagnostic for MM, while WB-MRI was negative. WB-MRI showed a 100% accuracy in detecting SMM and MM. Therefore, WB-MRI has led to a modification of the prognosis and treatment approach (observation in SMM vs treatment in symptomatic MM) in 3/26 patients (11%). Furthermore, in 5/23 (22%) cases of confirmed SMM WB-MRI showed a slight diffuse alteration pattern of bone marrow without any overtlytic bone lesion, which could be a potential prognostic evidence.",
            "Conclusion": "Our results support a fundamental role of WB-MRI in combination with FDG PET/CT in the stadiation of patients with newly diagnosed high-risk SMM, which could modify prognosis and treatment, improving the differentiation with symptomatic MM. In particular, combination of WB-MRI plus FDG PET/CT could be more accurate in the detection of bone lesions than FDG PET/CT alone, being able to anticipate symptomatic MM diagnosis and consequently its treatment. Moreover, a diffuse pattern of marrow involvement could be detected in some HR-SMM patients without any overtlytic lesions: it is questionable if this group of patients is associated with a rapid progression in lytic lesions and so in symptomatic MM diagnosis. Prospective data on evolution of these patients are pending."
        },
        {
            "Title": "Clinical outcomes of retreatment with daratumumab-based regimens in anti-CD38 refractory multiple myeloma",
            "Indication": "Multiple Myeloma",
            "Drug": "Daratumumab",
            "PFS": "Median PFS for D2 was 8.2 months (95% CI 5.3-11.2)",
            "OS": "Median OS was 43 months (35.8-60.3)",
            "ORR": "Overall response rate (ORR) was 54% for D1 and 51% for D2",
            "AE": "None",
            "Other": "Median time to next treatment for D2 was 8.2 months (5.8-11.4)",
            "Conclusion": "In this retrospective study, daratumumab retreatment yielded similar ORR compared to initial daratumumab-based therapy in RRMM, despite a large proportion of patients being daratumumab-refractory prior to D2. Our findings suggest that retreatment with daratumumab in combination with other active MM agents can generate responses in patients with RRMM who are daratumumab-refractory."
        },
        {
            "Title": "Long-term outcomes after cardiac transplantation in AL amyloidosis",
            "Indication": "AL Amyloidosis",
            "Drug": "Various (including proteosome inhibitors, anti-CD38)",
            "PFS": "None",
            "OS": "Median follow-up and survival of the cohort post-OHT was 2.25 years (range 0.5-13.8)",
            "ORR": "None",
            "AE": "None",
            "Other": "Three (21%) patients had hematologic relapse 5, 21, and 48 months post-OHT",
            "Conclusion": "In this single-center analysis of patients undergoing OHT for cardiac AL amyloidosis, we demonstrate feasibility of excellent long-term outcomes, in predominantly high-risk AL patients where majority harbored high-risk disease with MM overlap, either by AL-CRAB or AL-BMPC. Induction alone led to \u2265VGPR response in 10 (71%) patients pre-OHT and further therapy deepened this to 11 (79%) patients. Both AL-CRAB patients died during follow-up, highlighting the overall poor prognosis in this specific subgroup. In our cohort, non-relapse related mortality was seen in only 2 (14%) patients, demonstrating acceptable post-OHT risk and overall ability to achieve far improved outcomes in the high-risk cardiac AL amyloidosis patient population that otherwise suffers from rapid and universal mortality."
        },
        {
            "Title": "A phase III, randomized study of daratumumab, cyclophosphamide, bortezomib and dexamethasone (DARA-VCD) induction followed by autologous stem cell transplant or DARA-VCD consolidation and daratumumab maintenance in patients with newly diagnosed AL amyloidosis.",
            "Indication": "Newly diagnosed AL amyloidosis",
            "Drug": "Daratumumab, cyclophosphamide, bortezomib, dexamethasone",
            "PFS": "83% 2-year progression-free survival (PFS) in ASCT arm, 79% in Dara-VCD arm",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Major organ deterioration progression-free survival (MOD-PFS) as primary endpoint, with additional endpoints including OS, hematological PFS, cardiac and renal responses and progression, MRD negativity, delayed utilization of ASCT, and quality of life.",
            "Conclusion": "The study aims to determine the optimal use of ASCT in the era of Dara-VCD, with a hypothesis that ASCT might provide similar benefits in AL patients as it does in multiple myeloma patients."
        },
        {
            "Title": "Evaluation of elranatamab vs EPd, PVd, or Kd in patients with relapsed or refractory multiple myeloma and prior anti-CD38\u2013directed therapy: MagnetisMM-32.",
            "Indication": "Relapsed or refractory multiple myeloma",
            "Drug": "Elranatamab",
            "PFS": "Primary endpoint: PFS by blinded independent central review (BICR) per IMWG criteria",
            "OS": "Key secondary endpoint: Overall survival (OS)",
            "ORR": "Secondary endpoint: Objective response rate",
            "AE": "Secondary endpoint: Safety",
            "Other": "Other secondary endpoints include duration of response, time to response by BICR, PFS on next line of therapy per IMWG criteria, MRD negativity rate, pharmacokinetics of ELRA, immunogenicity, and health-related quality of life outcomes.",
            "Conclusion": "The study will evaluate whether ELRA can provide superior clinical benefit compared to EPd, PVd, or Kd in patients with RRMM."
        },
        {
            "Title": "MagnetisMM-30: A phase 1b, open-label study of elranatamab in combination with iberdomide in patients with relapsed or refractory multiple myeloma (RRMM).",
            "Indication": "Relapsed or refractory multiple myeloma",
            "Drug": "Elranatamab, iberdomide",
            "PFS": "None",
            "OS": "None",
            "ORR": "Secondary endpoint: ORR",
            "AE": "Primary endpoint: Dose-limiting toxicities, Secondary endpoint: AEs, lab abnormalities",
            "Other": "Other secondary endpoints include CRR, time-to-event outcomes, PK, MRD negativity rate, and immunogenicity.",
            "Conclusion": "The study aims to evaluate the safety, efficacy, and pharmacokinetics of ELRA in combination with IBER in patients with RRMM, with the hypothesis that the combination may provide additional benefit based on their mechanisms of action."
        },
        {
            "Title": "BEAT-SC: A randomized phase III study of bevacizumab or placebo in combination with atezolizumab and platinum-based chemotherapy in patients with extensive-stage small cell lung cancer (ES-SCLC).",
            "Indication": "Extensive-stage small cell lung cancer (ES-SCLC)",
            "Drug": "Bevacizumab, Atezolizumab, Cisplatin or Carboplatin, Etoposide",
            "PFS": "5.7 months for bev+ACE vs 4.4 months for placebo+ACE (HR, 0.70; 95% CI: 0.54, 0.90; P=0.0060)",
            "OS": "13.0 months for bev+ACE vs 16.6 months for placebo+ACE (HR, 1.22; 95% CI: 0.89, 1.67; P=0.2212)",
            "ORR": "None",
            "AE": "All-grade AE: 99.4% for bev+ACE, 99.4% for placebo+ACE; Any-grade TRAE: 99.4% for bev+ACE, 98.8% for placebo+ACE; Grade 3/4 TRAE: 85.5% for bev+ACE, 86.0% for placebo+ACE; Grade 5 TRAE: 3.0% for bev+ACE, 4.9% for placebo+ACE; Serious TRAE: 34.9% for bev+ACE, 34.1% for placebo+ACE; AE leading to study drug withdrawal: 20.5% for bev+ACE, 16.5% for placebo+ACE; AE leading to study drug modification/interruption: 76.5% for bev+ACE, 72.6% for placebo+ACE; TR-AESI: 39.2% for bev+ACE, 31.7% for placebo+ACE",
            "Other": "None",
            "Conclusion": "BEAT-SC met its primary endpoint, demonstrating that the addition of bev to ACE significantly increased PFS vs placebo+ACE. OS data were immature at the first interim OS analysis, and the numerical OS improvement of bev+ACE was not shown vs placebo+ACE; OS follow-up will continue. No new safety signals were observed."
        },
        {
            "Title": "Feasibility and safety of neoadjuvant nivolumab and chemotherapy for resectable diffuse pleural mesotheliomas: Results of a prospective pilot study.",
            "Indication": "Resectable diffuse pleural mesotheliomas (DPM)",
            "Drug": "Nivolumab, Cisplatin or Carboplatin, Pemetrexed",
            "PFS": "None",
            "OS": "Median OS for the 19 patients that went for attempted P/D was 41.0 months (median follow-up: 25.3 months). OS for those who underwent R1 resections (n=12) was not reached and for R2 (n=7) was 31.4 months (p=0.67; HR 1.43, 95% CI 0.28-7.30)",
            "ORR": "None",
            "AE": "None",
            "Other": "26% of specimens (5/19) had \u226410% viable tumor cells on the resection; there was no clear correlation with outcomes. scRNAseq was performed on 15 resection specimens with available material. Comparing patients with PFS greater than or less than 12 months (PFS12), we found significantly increased abundance of CD8 cytotoxic T cells, and ISG+ (interferon stimulated) T cells.",
            "Conclusion": "Neoadjuvant platinum, pemetrexed, and nivolumab was reliably able to be given to patients with DPM without impacting their ability to undergo P/D. The encouraging OS of this cohort to date, and tolerance of the regimen, highlight the importance of patient selection and preoperative treatments when considering P/D. Ongoing exploratory analyses will profile the cellular and transcriptional landscape of DPM and identify correlates of response to neoadjuvant chemo-immunotherapy."
        },
        {
            "Title": "Adjuvant icotinib of 12 months or 6 months versus observation following adjuvant chemotherapy for resected EGFR-mutated stage II\u2013III A non-small-cell lung cancer (ICTAN, GASTO1002): A randomized phase 3 trial.",
            "Indication": "Resected EGFR-mutated stage II\u2013III A non-small-cell lung cancer (NSCLC)",
            "Drug": "Icotinib",
            "PFS": "6 months of icotinib significantly improved DFS (HR: 0.41, 95% CI, 0.27-0.62; P=0.000025) compared with observation. Adjuvant icotinib of 12 months also significantly improved DFS (HR: 0.40, 95% CI, 0.27-0.61; P=0.000014) compared with observation. Median DFS was 61.8 months (95% CI, 43.3 to 80.3) for the 12-month icotinib group, 63.2 months (95% CI, 44.8 to 81.6) for the 6-month icotinib group compared with 23.7 months (95% CI, 16.5 to 30.9) for the observation group.",
            "OS": "6 months of icotinib significantly improved OS (HR: 0.56, 95% CI, 0.32-0.98; P=0.041) compared with observation. Adjuvant icotinib of 12 months also significantly improved OS (HR: 0.55, 95% CI, 0.32-0.96; P=0.035) compared with observation. The 5-year DFS and OS for the 12-month icotinib, 6-month icotinib and observation groups were 51.3%, 50.1% and 24.8%; and 74.5%, 74.0% and 65.1%; respectively.",
            "ORR": "None",
            "AE": "Rates of adverse events of grade 3 or higher were 8.3%, 5.9% and 2.4% for the 12-month icotinib, 6-month icotinib and observation groups, respectively.",
            "Other": "None",
            "Conclusion": "Adjuvant icotinib for 12 months and 6 months provide a significant DFS and OS benefit compared with observation in patients with completely resected EGFR-mutated stage II-III A NSCLC with a manageable safety profile. Nevertheless, 12 months of icotinib had no additional benefit compared with 6 months."
        },
        {
            "Title": "Molecular residual disease (MRD) analysis from the ADAURA trial of adjuvant (adj) osimertinib in patients (pts) with resected EGFR-mutated (EGFRm) stage IB\u2013IIIA non-small cell lung cancer (NSCLC).",
            "Indication": "EGFR-mutated stage IB\u2013IIIA non-small cell lung cancer (NSCLC)",
            "Drug": "Osimertinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "MRD detection had clinical sensitivity of 65% and specificity of 95%. MRD+ preceded DFS events in most pts with a median lead time of 4.7 months across both arms.",
            "Conclusion": "MRD+ preceded DFS events in most pts with a median lead time of 4.7 months across both arms. MRD\u2013 was maintained for most pts during adj osi tx with the majority of MRD/DFS events occurring after osi tx completion. MRD detection could potentially identify a subset of pts likely to benefit from longer adj osi."
        },
        {
            "Title": "Health-related quality of life (HRQoL) results for adjuvant alectinib vs chemotherapy in patients with resected ALK+ non-small cell lung cancer (NSCLC): Data from ALINA.",
            "Indication": "Resected ALK+ non-small cell lung cancer (NSCLC)",
            "Drug": "Alectinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "HRQoL was measured using the Short Form-36 version 2 (SF-36v2) health survey. Alectinib demonstrated an improvement in most domains of HRQoL by Week 12, followed by maintenance of HRQoL on all 8 domains, MCS, and PCS to Week 96.",
            "Conclusion": "Alectinib demonstrated an improvement in most domains of HRQoL by Week 12, followed by maintenance of HRQoL on all 8 domains, MCS, and PCS to Week 96. Together with the DFS benefit seen in ALINA, these data support alectinib as an important new adjuvant treatment for patients with resected ALK+ NSCLC."
        },
        {
            "Title": "Clinical outcomes with perioperative nivolumab (NIVO) by nodal status among patients (pts) with stage III resectable NSCLC: Results from the phase 3 CheckMate 77T study.",
            "Indication": "Stage III resectable non-small cell lung cancer (NSCLC)",
            "Drug": "Nivolumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "A phase II randomized trial evaluating consolidative nivolumab in locally advanced non-small cell lung cancer post neoadjuvant chemotherapy plus nivolumab and concurrent chemoradiotherapy (GASTO-1091).",
            "Indication": "Locally advanced non-small cell lung cancer (NSCLC)",
            "Drug": "Nivolumab",
            "PFS": "Median not reached vs. 12.2 months [95% CI 6.2-18.1]; hazard ratio 0.49 [95% CI 0.31-0.79], p=0.002",
            "OS": "None",
            "ORR": "None",
            "AE": "Grade 3-4 non-hematological adverse events occurred in 14.0% during neoadjuvant therapy and hypo-CCRT. Following randomization, grade 3-5 adverse events occurred in 7.0% with consolidation and 4.6% with observation.",
            "Other": "The 12-month and 18-month PFS rates were 72.6% and 64.8% in the consolidation group, contrasting with 52.5% and 42.3% in the observation group.",
            "Conclusion": "Consolidation with nivolumab after neoadjuvant chemotherapy plus nivolumab and hypo-CCRT demonstrates effectiveness and tolerability for patients with unresectable stage III NSCLC. Extended follow-up is essential for confirming these findings."
        },
        {
            "Title": "Neoadjuvant nivolumab (NIVO) + chemotherapy (chemo) vs chemo in patients (pts) with resectable NSCLC: 4-year update from CheckMate 816",
            "Indication": "Resectable Non-Small Cell Lung Cancer (NSCLC)",
            "Drug": "Nivolumab (NIVO) + chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "Outcomes with perioperative durvalumab (D) in pts with resectable NSCLC and baseline N2 lymph node involvement (N2 R-NSCLC): An exploratory subgroup analysis of AEGEAN",
            "Indication": "Resectable Non-Small Cell Lung Cancer (NSCLC) with baseline N2 lymph node involvement",
            "Drug": "Durvalumab (D)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "38.5% vs 41.8% (Durvalumab vs Placebo)",
            "Other": "Event-Free Survival (EFS) prolonged in Durvalumab vs Placebo arm (HR, 0.63 [95% CI: 0.43\u20130.90]); pCR rates: 16.6% vs 4.9%; MPR rates: 32.6% vs 15.1%",
            "Conclusion": "With clinically meaningful improvement in efficacy, no adverse impact on surgery outcomes and a manageable safety profile, the addition of perioperative Durvalumab to neoadjuvant chemotherapy remains a potential new treatment option for patients with N2 resectable NSCLC."
        },
        {
            "Title": "Health-related quality of life (HRQoL) outcomes from the randomized, double-blind phase 3 KEYNOTE-671 study of perioperative pembrolizumab for early-stage non-small-cell lung cancer (NSCLC)",
            "Indication": "Early-stage Non-Small Cell Lung Cancer (NSCLC)",
            "Drug": "Pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "No differences in least squares mean (LSM) change from baseline in the neoadjuvant or adjuvant phase for any PRO score",
            "Conclusion": "Adding perioperative pembrolizumab maintained HRQoL in both the neoadjuvant and adjuvant settings versus neoadjuvant chemotherapy and surgery alone in patients with resectable early-stage NSCLC. Together with the significant efficacy improvements and absence of new safety signals, HRQoL data support the perioperative pembrolizumab regimen as a new standard of care."
        },
        {
            "Title": "Global retrospective study comparing consolidation ALK tyrosine kinase inhibitors (TKI) to durvalumab (durva) or observation (obs) after chemo-radiation (CRT) in unresectable locally-advanced ALK+ non-small cell lung cancer (NSCLC)",
            "Indication": "Unresectable locally-advanced ALK+ non-small cell lung cancer (NSCLC)",
            "Drug": "ALK tyrosine kinase inhibitors (TKI), durvalumab (durva), observation (obs)",
            "PFS": "ALK TKI: Not reached (NR), 95% CI 22.7-NR; Durva: 11.3 months, 95% CI 9.2-18.5, HR=0.12; Obs: 7.4 months, 95% CI 3.4-12.5",
            "OS": "ALK TKI: 100%; Durva: 90.5%; Obs: 63.5%; Median OS: ALK TKI and Durva: NR, 95% CI NR-NR; Obs: 70.6 months, 95% CI 24.9-NR",
            "ORR": "None",
            "AE": "Grade \u22653 trAE: ALK TKI: 27% (1 fatigue, 1 diarrhea, 1 hyperbilirubinemia, 1 pneumonitis); Durva: 7% (1 fatigue, 1 neutropenia); Obs: 6% (1 neutropenia)",
            "Other": "None",
            "Conclusion": "Consolidation ALK TKI demonstrated clinically meaningful improvement in PFS and OS over durva and obs, with a slightly higher rate of trAE over durva and obs. High rate of progression following CRT alone in ALK+ NSCLC underscores the need for prospective molecularly driven trials to determine the optimal consolidation therapy."
        },
        {
            "Title": "Overall survival of adebrelimab plus chemotherapy and sequential thoracic radiotherapy as first-line treatment for extensive-stage small cell lung cancer",
            "Indication": "Extensive-stage small cell lung cancer (ES-SCLC)",
            "Drug": "Adebrelimab plus chemotherapy and sequential thoracic radiotherapy (TRT)",
            "PFS": "10.1 months (95% CI: 6.9\u201315.5 months)",
            "OS": "21.4 months (95% CI: 17.2\u2013not reached months); 1-year OS rate: 74.1% (95% CI: 63.6-86.4%); 2-year OS rate: 39.7% (95% CI: 25.5-61.9%)",
            "ORR": "71.6% (95% CI: 59.3-82.0%)",
            "AE": "Most common grade 3 or higher treatment-related adverse events: Neutrophil count decreased (41.8%), white blood cell count decreased (19.4%), lymphocyte count decreased (13.4%)",
            "Other": "Disease control rate (DCR): 89.6% (95% CI: 79.7-95.7%)",
            "Conclusion": "Adebrelimab plus chemotherapy and sequential TRT as first-line therapy for ES-SCLC showed promising efficacy and acceptable safety."
        },
        {
            "Title": "DeLLphi-301: Tarlatamab phase 2 trial in small cell lung cancer (SCLC)\u2014Efficacy and safety analyzed by presence of brain metastasis",
            "Indication": "Small cell lung cancer (SCLC) with brain metastasis",
            "Drug": "Tarlatamab",
            "PFS": "Patients with brain metastases: 7.7 months (95% CI: 5.6-12.8); Patients without brain metastases: 9.4 months (95% CI: 6.3-20.6)",
            "OS": "Data yet to mature",
            "ORR": "Patients with brain metastases: 45.3% (95% CI: 31.6-59.6); Patients without brain metastases: 32.6% (95% CI: 24.7-41.3)",
            "AE": "Any grade immune effector cell associated neurotoxicity syndrome and associated neurological events: Patients with brain metastases: 24.1%; Patients without brain metastases: 13.6%; Grade \u22653 events: Patients with brain metastases: 9.4%; Patients without brain metastases: 1.8%",
            "Other": "None",
            "Conclusion": "Tarlatamab showed promising efficacy and a favorable benefit-risk profile in patients with previously treated SCLC and stable brain metastases."
        },
        {
            "Title": "Niraparib and dostarlimab efficacy in patients with platinum-sensitive relapsed mesothelioma: MIST5, a phase IIa clinical trial.",
            "Indication": "Relapsed malignant mesothelioma",
            "Drug": "Niraparib and dostarlimab",
            "PFS": "None",
            "OS": "None",
            "ORR": "Partial response: 15.4% (95% CI, 4.4-34.9%); Stable disease: 65.4% (44.3\u201382.8%); Progression: 15.4% (4.4\u201334.9%)",
            "AE": "Grade 3 or higher toxicities affected 19.2% of patients",
            "Other": "Disease control rate at 12 weeks (DCR12w): 65.4% (90% CI, 47.4%\u201380.6%); Disease control rate at 24 weeks (DCR24w): 30.8% (95% CI, 14.3%\u201351.8%)",
            "Conclusion": "MiST5 met its primary endpoint with an overall disease control rate of 80.8%. Analysis of the cellular and molecular correlates of response are ongoing and will be presented."
        },
        {
            "Title": "ctDNA-Lung-DETECT: ctDNA outcomes for resected early stage non-small cell lung cancers at 12 months.",
            "Indication": "Early stage non-small cell lung cancer (NSCLC)",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "ctDNA was detected pre-operatively in 18 patients; 99% (71/72) had ctDNA clearance post-operatively, and 93% (62/67) remained ctDNA negative at 12 months. Median time to recurrence was 13.9 months (range 6.2-24.9). Recurrence rate was 16.7% (3/18, 95% CI 3.6-41.4%) in patients with ctDNA detected pre-operatively vs. 7.5% (4/53, CI 2.1-18.2%) in those without.",
            "Conclusion": "Pre-operative ctDNA detection may help identify patients with resected stage I NSCLC that could benefit from treatment intensification, currently under study in ctDNA Lung RCT (NCT04966663)."
        },
        {
            "Title": "Real-world data based on PD-L1 expression in early-stage NSCLC in an east-Asian patient population: A 10-year follow-up study.",
            "Indication": "Early-stage non-small cell lung cancer (NSCLC)",
            "Drug": "None",
            "PFS": "None",
            "OS": "5-year OS: PD-L1 negative/low: 82%, PD-L1 high: 63% (p=0.00011); Stage IA PD-L1 negative/low: 96%, Stage IA PD-L1 high: 63%, Stage IB-IIIB PD-L1 negative/low: 67%, Stage IB-IIIB PD-L1 high: 62% (p<0.0001); Stage IA EGFR mutant: 97%, Stage IA EGFR wildtype: 84% (p=0.0028); Stage IB-IIIB EGFR mutant: 66%, Stage IB-IIIB EGFR wildtype: 65% (p=0.13)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Stage IA NSCLC patients with PD-L1 high and EGFR wildtype were associated with poorer survival outcomes. These findings highlight the urgent need for clinical trials investigating perioperative management strategies in this population."
        },
        {
            "Title": "Surgical outcomes in patients with non-small cell lung cancer receiving neoadjuvant chemoimmunotherapy versus chemotherapy alone: A systematic review and meta-analysis.",
            "Indication": "Resectable non-small cell lung cancer (NSCLC)",
            "Drug": "Neoadjuvant chemoimmunotherapy (PD-1/PD-L1 blockade) vs. chemotherapy alone",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Complication Rate: OR 1.06 (95% CI 0.84-1.33); Any Grade TRAEs: OR 1.03 (95% CI 0.57-1.86); Grade 3-5 TRAEs: OR 1.43 (95% CI 0.64-3.22); SAEs: OR 1.12 (95% CI 0.79-1.59); AEs leading to Cancellation of Surgery: OR 1.52 (95% CI 0.89-2.61)",
            "Other": "Surgical Resection Rate: OR 1.28 (95% CI 0.90-1.83); R0 Resection Rate: OR 1.53 (95% CI 1.15-2.04)",
            "Conclusion": "Neoadjuvant chemoimmunotherapy was associated with increased R0 resection rates without an increase in the incidence of complications, TRAEs, and cancellation of surgery. This indicates that neoadjuvant chemoimmunotherapy can be safely administered with an increase in R0 resection rate, which potentially leads to improvement of survival outcomes in patients with resectable NSCLC."
        },
        {
            "Title": "Multiplex analysis of the immune environment before and after neoadjuvant durvalumab as a prognostic factor in resectable non-small cell lung cancer (NSCLC) in the IFCT-1601 IONESCO phase 2 trial.",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "Durvalumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "High density of CD8+TIM3+ cells in biopsies (HR=0.25 [0.09-0.71], p=0.0092) and high density of CD20+ cells in surgical specimen (HR=0.36 [0.13-0.97], p=0.04) associated with DFS. RVT associated with CK+Caspase3+ cells and CK+PDL1+ cells in surgical specimen.",
            "Conclusion": "Multiparameter analysis of the immune NSCLC environment of patients treated with neoadjuvant anti-PDL1 allows identification of markers associated with clinical, pathological parameters and DFS. High CD8+TIM3+ cell density pre-durvalumab and high CD20+ cell density post-durvalumab impact DFS."
        },
        {
            "Title": "A phase II study of tislelizumab (TIS) and chemotherapy as neoadjuvant therapy for potentially resectable stage IIIA/IIIB non-small cell lung cancer (NSCLC).",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "Tislelizumab (TIS)",
            "PFS": "None",
            "OS": "None",
            "ORR": "88% (22/25)",
            "AE": "None",
            "Other": "R0 resection rate: 96% (24/25), MPR: 60% (15/25), pCR: 32% (8/25), 1-year EFS rate: 82.3% (95% CI 58.3-93.2), 2-year EFS rate: 76.4% (95% CI 51.1-89.8)",
            "Conclusion": "Neoadjuvant TIS plus chemotherapy increased surgical opportunities and survival benefits in potentially resectable locally advanced stage IIIA/IIIB NSCLC."
        },
        {
            "Title": "Impact of EGFR mutation and types of treatment on the longitudinal recurrence risk in patients with completely resected non-small cell lung cancer: A combined analysis of two phase III studies (JIPANG and IMPACT).",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "Gefitinib, CDDP+PEM, CDDP+VNR",
            "PFS": "Median DFS: 42.2 months (arm A), 30.3 months (arm B), 35.9 months (arm C)",
            "OS": "Median OS not reached in each arm, OS rates at 8 years: 61.3% (arm A), 67.7% (arm B), 59.8% (arm C)",
            "ORR": "None",
            "AE": "None",
            "Other": "Higher distant relapse in arm C (51.7%) compared to arm A (47.2%) and arm B (45.5%). CNS was the most frequent site of relapse (33.8%).",
            "Conclusion": "In patients with completely resected NSCLC, chemotherapy led to cure regardless of EGFR mutation status, whereas gefitinib showed a sustained recurrence risk even after completion."
        },
        {
            "Title": "Efficacy and safety of perioperative, neoadjuvant or adjuvant immunotherapy alone or in combination chemotherapy in early-stage non-small cell lung cancer: A systematic review and meta-analysis of randomized clinical trials.",
            "Indication": "Early-stage non-small cell lung cancer (eNSCLC)",
            "Drug": "Immunotherapy (ICI) plus chemotherapy (CT)",
            "PFS": "None",
            "OS": "No significant OS difference in NE and AD setting (HR=0.62, 95% CI: 0.36-1.05; HR=0.90, 95% CI: 0.76-1.06, respectively), and only PE immunotherapy showed OS benefits (HR=0.67, 95% CI: 0.54-0.84)",
            "ORR": "None",
            "AE": "AD (OR 1.52, 95% CI: 1.01-2.30) and PE (OR 1.29, 95% CI: 1.08-1.54) immunotherapies were significantly associated with higher grade \u22653 adverse events (AEs) compared with controls. Rash was the most common grade \u22653 irAEs.",
            "Other": "EFS significantly improved in PE treatment subgroup regardless of stage, pathologic response, histology, PD-L1 expression, and gender, but no significant benefit in the EGFRm NSCLC subgroup (HR=0.54, 95% CI: 0.21-1.43). AD immunotherapy significantly improved DFS (HR=0.83, 95% CI: 0.73-0.94).",
            "Conclusion": "PE immunotherapy seems to be more effective than NE and AD immunotherapy in three treatment modes. NE and PE immunotherapy significantly improved pCR, MPR, EFS, and AD immunotherapy significantly improved DFS in eNSCLC patients in comparison with the control group, but only PE immunotherapy significantly improved OS."
        },
        {
            "Title": "Efficacy and safety of immunotherapy plus chemotherapy for lung cancer neoadjuvant treatment: A network meta-analysis based on randomized controlled trials.",
            "Indication": "Lung cancer",
            "Drug": "Immunotherapy combined with chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "The combination of nivolumab and ipilimumab + CT showed the lowest occurrence of treatment-related adverse events greater than grade 3, with an lnOR value of -1.38.",
            "Other": "EFS was highest for the durvalumab + CT group, ranking first with an HR of 2 (95% CI: 1.0 to 3.7). The pembrolizumab + CT group ranked second with an HR of 1.8 (95% CI: 0.76 to 4.2), followed by the nivolumab + CT group in third place with an HR of 1.7 (95% CI: 0.95 to 3.1). Both the nivolumab and ipilimumab + CT and toripalimab + CT groups tied for fourth place with HRs of 1.4 (95% CI: 0.58 to 3.5) and 1.5 (95% CI: 0.70 to 3.2), respectively. The camrelizumab + CT group had the lowest EFS ranking, with an HR of 1.7 (95% CI: 0.74 to 3.8). The combination of nivolumab + ipilimumab + CT showed the most favorable outcomes in terms of Pathologic Complete Response (PCR) and R0 resection rates among the six treatments, with lnOR values of 3.17 and 1.48, respectively.",
            "Conclusion": "This network meta-analysis indicates that durvalumab, when combined with chemotherapy, offers the best EFS among the immunotherapy options evaluated, demonstrating good safety and efficacy. Concurrent use of nivolumab and ipilimumab resulted in the highest pCR and R0 resection rates. Moreover, for patients with lung cancer, the preliminary evidence supports the value of combining immunotherapy with chemotherapy as a neoadjuvant treatment strategy over chemotherapy alone."
        },
        {
            "Title": "Unlocking therapeutic potential: IL-1b as a target in non-small cell lung cancer with oncogenic mutations\u2014Prognostic and predictive insights.",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "IL-1b inhibitor (canakinumab)",
            "PFS": "None",
            "OS": "Entire cohort: 20.0 months (low IL-1b expressors) vs 17.3 months (high expressors), HR 0.88, 95% CI 0.88-0.95, p < 0.0001; EGFR-mutant NSCLC: 34.6 months (low expressors) vs 25.1 months (high expressors), HR 0.79, 95% CI 0.68-0.92, p = 0.002; Adenocarcinoma: 41.2 months (low expressors) vs 27.1 months (high expressors), HR 0.74, 95% CI 0.63-0.87, p < 0.001; ALK fusion-positive NSCLC: 63.4 months (low expressors) vs 27.0 months (high expressors), HR 0.53, 95% CI 0.37-0.76, p < 0.001",
            "ORR": "None",
            "AE": "None",
            "Other": "IL-1b expression was positively associated with TP-53 mutations, high TMB, and PD-L1+ expression, while negatively associated with mutations in KRAS, EGFR, ERBB2, BRAF, and STK11.",
            "Conclusion": "Low IL-1b expression is associated with improved OS across NSCLC subtypes. IL-1b expression is closely linked to key driver mutations in NSCLC and may have predictive value for ICIs. Targeting IL-1b in high IL-1b expressing NSCLC with driver mutations could be beneficial."
        },
        {
            "Title": "The association between osimertinib and nutritional deficiency among patients with non-small cell lung cancer.",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "Osimertinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Vitamin B deficiency: HR 1.52 (95% CI: 1.07-2.16), p = 0.018; Vitamin D deficiency: HR 2.58 (95% CI: 1.34-4.96), p = 0.005; Vitamin B12 deficiency anemia: HR 2.02 (95% CI: 1.08-3.81), p = 0.026; Iron deficiency anemia: HR 1.56 (95% CI: 1.27-1.91), p < 0.001",
            "Other": "None",
            "Conclusion": "Osimertinib is associated with a higher risk of nutritional deficiencies and associated anemias compared to other EGFR-TKIs. Screening for specific nutritional deficiencies in individuals receiving osimertinib therapy is advisable."
        },
        {
            "Title": "IMpower010: Final disease-free survival (DFS) and second overall survival (OS) interim results after \u22655 years of follow up of a phase III study of adjuvant atezolizumab vs best supportive care in resected stage IB-IIIA non-small cell lung cancer (NSCLC).",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "Atezolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "Prospective \u201ccommon arm\u201d comparison of US SWOG S0424 and Japanese JME studies in early-stage non-small cell lung cancer (NSCLC): Survival differences in the context of race, gender and smoking.",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "None",
            "PFS": "None",
            "OS": "Overall survival was greater in JME patients (p<0.0001, HR=0.49 [95% CI: 0.40-0.60]). In the subset of patients without an EGFR mutation, survival still favored the JME cohort (p<0.001, HR=0.68 [0.53-0.86]).",
            "ORR": "None",
            "AE": "None",
            "Other": "EGFR mutations were more likely in JME patients (p<0.0001) and less likely to have KRAS mutations (p=0.02). EGFR mutations were associated with never-smoking (p<0.0001) and female sex (p<0.0001); KRAS was associated with smoking (p<0.0001).",
            "Conclusion": "Patient-level comparison of US (SWOG) and Japanese (JME) patients with early stage NSCLC demonstrated longer survival in Japanese patients even after adjusting for EGFR mutation frequency. Identification of factors that contribute to this finding are under investigation. Female sex remained a risk factor for EGFR mutation-driven disease in both populations. This analysis forms the basis for applying this population-related model in other clinical settings."
        },
        {
            "Title": "Predicting response of patients with NSCLC to immunotherapy using innate immune fitness (IIF) profiling",
            "Indication": "Non-Small Cell Lung Cancer (NSCLC)",
            "Drug": "Immune Checkpoint Inhibitors (ICI)",
            "PFS": "PFS > 12 months (Responder) vs. PFS <= 12 months (Non-Responder); Hazard Ratio (HR) = 0.39 (0.29-0.53; p < 0.001)",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Predictive accuracy of IIF Scores was 76% (Sensitivity = 58%; Specificity = 83%, NPV = 83%, PPV = 58%)",
            "Conclusion": "IIF Score was the strongest predictor of patient response to ICI. Despite the inherent limitations of combining data from multiple cohorts, IIF Score outperformed TMB and PD-L1 in predictive accuracy, HR, and AUC. With a Negative Predictive Value of 83%, these results support the use of IIF Score as a biomarker for identifying ICI Non-Responders in NSCLC patients."
        },
        {
            "Title": "The genomic, transcriptomic, and immunological profile of patients with recurrent/refractory NSCLC",
            "Indication": "Recurrent/Refractory Non-Small Cell Lung Cancer (NSCLC)",
            "Drug": "Durvalumab and Pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "TP53 (91% vs 68%) and KEAP1 (30% vs. 14%) mutation prevalence was higher in R/R, whereas a decreased prevalence of KRAS mutations was noted in this group (13% vs. 33%). Increased prevalence of mutations in BCL2, BCL9, HNF1A, TNFAIP3, AKT1, MAP3K1, and SPEN. Copy number amplification of NUTM1, SMO, CHIC2, PIK3CA, and FLCN was more common in R/R. R/R TME was associated with decreased B cell (2.9% vs 4.4%) and regulatory T cell fractions (1.3% vs. 2.6%).",
            "Conclusion": "R/R patient tumors had distinct molecular alterations and immune landscapes, including an increased prevalence of biomarkers associated with immunotherapy resistance (TP53 and KEAP1 mutations) and lower tumor B- and T-reg-cell fractions, which may suggest decreased likelihood of response to immunotherapy compared to patients with DN NSCLC. The RR population had a lower-than-expected percentage of KRAS mutations. These distinct molecular differences highlight the need for NGS testing in the recurrent setting, despite initial NGS testing at diagnosis. Molecularly targeted interventions may be options in the future for patients with R/R Stage III NSCLC."
        },
        {
            "Title": "Induction therapy with PD-1 antibody combined with platinum-based doublet chemotherapy for locally-advanced non-small cell lung cancer: A randomised controlled, open-label, phase 2 trial.",
            "Indication": "locally-advanced non-small cell lung cancer (NSCLC)",
            "Drug": "PD-1 antibody (Serplulimab) and platinum-based doublet chemotherapy",
            "PFS": "Median PFS: 9.72 months (95% CI; 9.33\u201310.38), 3-yr PFS: 19.7%, 5-yr PFS: 14.3%, 10-yr PFS: 10.1%",
            "OS": "None",
            "ORR": "74.0% (54/73)",
            "AE": "Grade 3-5 adverse event rate: 50.0% (28/56), 1 patient died of immune-related pneumonia",
            "Other": "MPR: 62.5% (10/16), pCR: 31.3% (5/16), conversion to resectability rate: 57.1% (32/56)",
            "Conclusion": "Induction therapy with PD-1 antibody (Serplulimab) and chemotherapy could transform half of unresectable stage IIIB-IIIC NSCLC into resectable, and the efficacy and safety were satisfactory."
        },
        {
            "Title": "Updated results from COAST, a phase 2 study of durvalumab (D) + oleclumab (O) or monalizumab (M) in patients (pts) with stage III unresectable non-small cell lung cancer (uNSCLC).",
            "Indication": "Stage III unresectable non-small cell lung cancer (uNSCLC)",
            "Drug": "Durvalumab (D), Oleclumab (O), Monalizumab (M)",
            "PFS": "Median PFS (95% CI), mo: D: 7.3 (4.0, 13.8), D+O: 21.1 (10.4, 30.9), D+M: 19.8 (13.6, 31.3); PFS HR (95% CI): D+O: 0.59 (0.37, 0.93), D+M: 0.63 (0.40, 0.99)",
            "OS": "Median OS (95% CI), mo: D: 40.9 (22.6, NR), D+O: NR (31.9, NR), D+M: NR (31.3, NR); 2-year OS rate (95% CI): D: 66.8% (34.3, 82.0), D+O: 77.1% (54.6, 88.3), D+M: 72.7% (48.4, 86.3)",
            "ORR": "D: 22.4%, D+O: 30.9%, D+M: 25.0%",
            "AE": "Tx-emergent AEs: D: 98.5% (Grade 3/4: 34.8%), D+O: 96.6% (Grade 3/4: 33.9%), D+M: 100% (Grade 3/4: 32.8%); Immune-mediated AEs: D: 34.8% (Grade 3/4: 3.0%), D+O: 25.4% (Grade 3/4: 0%), D+M: 34.4% (Grade 3/4: 3.3%); AE of special interest pneumonitis: D: 16.7% (Grade 3/4: 0%), D+O: 20.3% (Grade 3/4: 0%), D+M: 18.0% (Grade 3/4: 1.6%)",
            "Other": "None",
            "Conclusion": "D+O and D+M increased ORR and prolonged PFS and OS vs D alone. Safety was similar across arms, with no new safety signals. Further investigation of D, D+O, and D+M in this population is ongoing in the Phase 3 PACIFIC-9 study (NCT05221840)."
        },
        {
            "Title": "Final survival outcomes and exploratory biomarker analysis from the randomized, phase 2 neoSCORE trial: Two versus three cycles of neoadjuvant sintilimab plus chemotherapy for resectable non-small cell lung cancer.",
            "Indication": "Stage IB-IIIA resectable non-small cell lung cancer (NSCLC)",
            "Drug": "Sintilimab plus chemotherapy",
            "PFS": "DFS HR=1.25, P=0.595; Median DFS not reached in both groups; 2-year DFS rate: two-cycle: 76.9%, three-cycle: 65.5%",
            "OS": "OS HR=0.88, P=0.830; Median OS not reached in both groups; 2-year OS rate: two-cycle: 84.6%, three-cycle: 86.2%",
            "ORR": "None",
            "AE": "None",
            "Other": "MPR was significantly associated with longer DFS (HR=0.32, P=0.044). Increased infiltration of CD8+CD38+CD103- T cells after neoadjuvant immuno-chemotherapy was significantly associated with MPR (P=0.035).",
            "Conclusion": "Neoadjuvant sintilimab plus chemotherapy was feasible and demonstrated a robust and persistent survival benefit in resectable NSCLC. MPR was associated with improved DFS. Increased CD8+CD38+CD103- T cells were associated with MPR, with a trend towards a better survival benefit, suggesting that it could be a promising predictive biomarker."
        },
        {
            "Title": "Multi-institution real world analysis of patients with non-small cell lung cancer (NSCLC) treated with standard of care (SOC) neoadjuvant chemo-immunotherapy (chemoIO).",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "Neoadjuvant chemo-immunotherapy (chemoIO)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "10% experienced grade \u22653 immune-related adverse events, 72% of which were pre-op",
            "Other": "29 tumors (36%) demonstrated major pathologic response (MPR); of these, 16 (20%) had pathologic complete response (pCR)",
            "Conclusion": "Real world analysis demonstrated older, frailer patients with more advanced disease than those enrolled on trial; lower chemoIO completion rates but comparable pCR and resection rates were seen. Further analyses will focus on outcomes of patients who did not undergo surgery."
        },
        {
            "Title": "Cost analysis of targeted and immunotherapies in operable esophageal and non-small cell lung cancers.",
            "Indication": "Non-small cell lung cancer (NSCLC) and esophageal cancers (EC)",
            "Drug": "Atezolizumab, Nivolumab, Osimertinib, Pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Cost to treat one patient with adjuvant Nivolumab for EC: $248,529; Cost to treat one patient with neoadjuvant Nivolumab for NSCLC: $32,894; Cost to treat one patient with adjuvant Atezolizumab or Pembrolizumab for NSCLC: $178,584\u2013$197,320; Cost to treat one patient with adjuvant Osimertinib for NSCLC: $556,720",
            "Conclusion": "Neoadjuvant and adjuvant immune and targeted therapy in operable thoracic patients is associated with a significant cost burden. Prospective large-scale studies are needed to assess cost-effectiveness, particularly given the paucity of superior overall survival data with these agents."
        },
        {
            "Title": "Analysis of outcomes in resected early-stage non-small cell lung cancer (NSCLC) with rare targetable driver mutations.",
            "Indication": "Early-stage non-small cell lung cancer (NSCLC) with rare targetable driver mutations",
            "Drug": "None specified",
            "PFS": "Stage II (HR: 1.90, p=0.04), Stage III (HR: 2.26, p=0.006)",
            "OS": "Stage II (HR: 2.17, p=0.038), Stage III (HR: 2.38, p=0.015); TP53 co-mutation (HR: 2.50, p=0.004)",
            "ORR": "None",
            "AE": "None",
            "Other": "KRAS G12C (5-yr RFS: 61%, 5-yr OS: 64%, Brain Metastases at 5 years: 1.9%); EGFR Exon 20 (5-yr RFS: 49%, 5-yr OS: 56%, Brain Metastases at 5 years: 18%); ERBB2 (5-yr RFS: 37%, 5-yr OS: 53%, Brain Metastases at 5 years: 29%); MET Exon 14 (5-yr RFS: 77%, 5-yr OS: 84%, Brain Metastases at 5 years: 0%); Other (5-yr RFS: 78%, 5-yr OS: 93%, Brain Metastases at 5 years: 20%); All fusions (5-yr RFS: 52%, 5-yr OS: 90%, Brain Metastases at 5 years: 21%)",
            "Conclusion": "Despite consistently poorer RFS, our study shows that, with the exception of ERBB2, OS of all other rare mutations was superior to KRAS G12C mutated NSCLC. TP53 co-mutation was demonstrated to be prognostic of poorer outcome. These dichotomous results may be explained by the use of targeted treatments at relapse, and suggest a potential role of targeted agents in the adjuvant setting."
        },
        {
            "Title": "Neoadjuvant hypofractionated radiotherapy combined with pembrolizumab plus chemotherapy for potentially resectable non-small cell lung cancer: A phase Ib study.",
            "Indication": "Potentially resectable stage III non-small cell lung cancer (NSCLC)",
            "Drug": "Pembrolizumab plus chemotherapy",
            "PFS": "21.7 months",
            "OS": "Median OS not reached, 2-year OS rate 85.6%",
            "ORR": "94.1%",
            "AE": "No grade 3 or higher adverse events observed during neoadjuvant therapy. One patient had grade 1 nephrotoxicity, and another had grade 2 pneumonitis.",
            "Other": "Partial response in 16 out of 17 patients (94.1%), one patient had stable disease. 11 patients (64.7%) met surgical standards, 8 (72.7%) achieved R0 resection, and 6 (54.5%) attained pCR. One patient died from intraoperative bleeding.",
            "Conclusion": "Neoadjuvant HFRT with immunotherapy for potentially resectable stage III NSCLC is a tolerable treatment with an improved surgical conversion rate and pCR rate."
        },
        {
            "Title": "Four-year outcomes and circulating tumor DNA (ctDNA) analysis of pembrolizumab (pembro) plus concurrent chemoradiation therapy (cCRT) in unresectable, locally advanced, stage III non\u2013small-cell lung cancer (NSCLC): From KEYNOTE-799.",
            "Indication": "Unresectable, locally advanced, stage III non-small-cell lung cancer (NSCLC)",
            "Drug": "Pembrolizumab plus concurrent chemoradiation therapy (cCRT)",
            "PFS": "Cohort A: 29.0 months (95% CI: 16.6\u201348.5), Cohort B: 37.9 months (95% CI: 17.9\u2013NR)",
            "OS": "Cohort A: 35.6 months (95% CI: 26.1\u201344.2), Cohort B: NR (95% CI: 41.1\u2013NR)",
            "ORR": "Cohort A: 71.4%, Cohort B: 74.5%",
            "AE": "Grade \u22653 pneumonitis: Cohort A: 8.0%, Cohort B: 6.9%. Grade 3\u20135 treatment-related AEs: Cohort A: 65.2%, Cohort B: 51.0%",
            "Other": "ctDNA was evaluable in ~half of the samples assessed; most patients with detectable ctDNA at baseline had cleared ctDNA at cycle 7. Patients who cleared ctDNA at cycle 7 had better trends in PFS and OS.",
            "Conclusion": "With ~4 years of follow-up, pembrolizumab plus cCRT continues to demonstrate durable antitumor activity and manageable safety in previously untreated unresectable, locally advanced stage III NSCLC."
        },
        {
            "Title": "The safety and efficacy of induction chemoimmunotherapy in initially unresectable stage III non-small cell lung cancer.",
            "Indication": "Unresectable stage III non-small cell lung cancer",
            "Drug": "Carboplatin, nab-paclitaxel or pemetrexed, and tislelizumab",
            "PFS": "Median PFS not reached, 24-months PFS rate was 77.6% (95% CI, 59.9%-88.2%)",
            "OS": "None",
            "ORR": "93.2% (95% CI, 83.5%-98.1%)",
            "AE": "Grade 3-5 pneumonitis: 3.4%",
            "Other": "DCR was 98.3% (95% CI, 90.9%-100.0%)",
            "Conclusion": "The findings of this study indicate encouraging antitumor activity and manageable safety of induction chemoimmunotherapy, and the response-adapted strategy requires further exploration in locally advanced unresectable non-small cell lung cancer."
        },
        {
            "Title": "Next-generation sequencing (NGS) completion and timeliness using reflex testing protocol for patients with stage II-III non-squamous non-small cell lung cancer (NS-NSCLC).",
            "Indication": "Stage II-III non-squamous non-small cell lung cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "Median OS was 64.1 months in those with complete NGS testing and 53.4 months in those without complete NGS (p=0.222)",
            "ORR": "None",
            "AE": "None",
            "Other": "Actionable mutations identified included: KRAS 39% (G12C 15%), EGFR 7%, BRAF 4%, MET 2%, RET 1%, and NTRK1%",
            "Conclusion": "Reflexive, in-house NGS resulted in a shorter median time from biopsy to results (17 days) and higher completion rate (NGS, 77%; at least limited testing, 92%) compared to historical controls. The majority of patients had NGS results available to inform systemic therapy decisions (69%). While OS was numerically higher for patients with complete NGS, this was not statistically significant. Reflex NGS testing protocols increase the likelihood of obtaining timely results in the new era of adjuvant tyrosine kinase inhibitors and neoadjuvant chemotherapy and immunotherapy."
        },
        {
            "Title": "Clinical outcomes of maintenance durvalumab after definitive concurrent chemoradiotherapy in unresectable locally advanced stage III non-small cell lung cancer according to epidermal growth factor receptor and anaplastic lymphoma kinase status: Korean Cancer Study Group LU-22-18.",
            "Indication": "Unresectable locally advanced stage III non-small cell lung cancer",
            "Drug": "Durvalumab",
            "PFS": "Median PFS was 21.4 months (95% CI 17.3\u201325.3) for the EGFR/ALK wild-type group and 21.0 months (95% CI 15.7\u2013NA) for the EGFR or ALK mutant group (p=0.74)",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Significant differences occurred in PFS based on PD-L1 expression with values of 13.6 (95% CI 10.5\u2013NA), 18.7 (95% CI 15.1\u201326.9), and 24.7 (95% CI 20.7\u2013NA) months for TPS of 0, 1\u201349, and 50 or higher, respectively (p=0.02)",
            "Conclusion": "Durvalumab maintenance therapy after definitive CCRT in unresectable locally advanced NSCLC patients with EGFR or ALK mutation demonstrates comparable clinical outcomes to those with wild-type EGFR/ALK when PD-L1 expression is present."
        },
        {
            "Title": "Anlotinib plus docetaxel in advanced NSCLC progressing on immunotherapy: A pooled analysis of two randomized trials.",
            "Indication": "Advanced non-small cell lung cancer (NSCLC) progressing after immune-checkpoint inhibitors (ICIs)",
            "Drug": "Anlotinib plus docetaxel",
            "PFS": "5.4 months (95% CI, 5.0-9.3) with anlotinib plus docetaxel vs. 2.3 months (95% CI, 1.4-2.9) with docetaxel alone; HR=0.34 (95% CI, 0.18-0.63; P<0.001)",
            "OS": "16.2 months with anlotinib plus docetaxel vs. 13.7 months with docetaxel alone; HR=0.82 (95% CI, 0.47-1.44; P=0.488)",
            "ORR": "25.0% with anlotinib plus docetaxel vs. 12.9% with docetaxel alone",
            "AE": "32.5% grade 3 or higher with anlotinib plus docetaxel vs. 6.5% with docetaxel alone",
            "Other": "Disease Control Rate (DCR): 82.5% with anlotinib plus docetaxel vs. 45.2% with docetaxel alone",
            "Conclusion": "Anlotinib plus docetaxel demonstrated survival and response improvements compared with docetaxel alone in patients with advanced NSCLC progressing after ICIs, with a manageable safety profile. This finding suggests anlotinib plus docetaxel might be an effective and safe option in this setting."
        },
        {
            "Title": "Five-year survival outcomes from the PIT-1 trial: Pemetrexed-cisplatin plus bevacizumab or concurrent thoracic radiation therapy followed by surgery in stage IIIA (N2) nonsquamous non-small cell lung cancer.",
            "Indication": "Stage IIIA (N2) nonsquamous non-small cell lung cancer (NSCLC)",
            "Drug": "Pemetrexed-cisplatin plus bevacizumab or concurrent thoracic radiation therapy (TRT)",
            "PFS": "5-year PFS: 26.2% (95% CI: 14.1-40.0) in the BEV arm vs. 27.5% (95% CI: 14.9-41.7) in the TRT arm; P=0.356",
            "OS": "5-year OS: 63.5% (95% CI: 46.9-76.1) in the BEV arm vs. 57.2% (95% CI: 40.5-70.8) in the TRT arm; P=0.572",
            "ORR": "None",
            "AE": "None",
            "Other": "Postoperative recurrence: locoregional only (11% BEV vs. 3% TRT), distant metastasis only (42% BEV vs. 57% TRT), both (13% BEV vs. 11% TRT). Recurrence rate of ipsilateral hilar or mediastinal lymph nodes (irradiation field): 3% TRT vs. 21% BEV (P=0.01)",
            "Conclusion": "Five-year survival outcomes were not different between the BEV and TRT arms. The TRT arm demonstrated sufficient local control but insufficient control of distant metastasis, which is an important issue that requires improvement in the future."
        },
        {
            "Title": "A phase II, two-cohort study of neoadjuvant chemotherapy plus tislelizumab \u00b1 bevacizumab followed by hypofractionated radiotherapy, concurrent chemotherapy, and consolidative immunotherapy in locally advanced non-small cell lung cancer (GASTO-1086).",
            "Indication": "Unresectable stage III NSCLC",
            "Drug": "Neoadjuvant chemotherapy plus tislelizumab \u00b1 bevacizumab followed by hypofractionated radiotherapy, concurrent chemotherapy, and consolidative immunotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "Cohort A: 89.7% (26/29) post-neoadjuvant therapy, 96.6% (28/29) post-hypo-CCRT; Cohort B: 92.3% (24/26) post-neoadjuvant therapy, 92.3% (24/26) post-hypo-CCRT",
            "AE": "Neoadjuvant therapy: G3 leukopenia in 3.4% (1/29) of Cohort A, no G3+ toxicities in Cohort B; Hypo-CCRT phase: G3 neutropenia (3.4% in Cohort A vs. 3.8% in Cohort B), G2 pneumonitis (6.9% in Cohort A vs. 7.7% in Cohort B); No Grade 3+ adverse events during consolidative tislelizumab phase",
            "Other": "None",
            "Conclusion": "The Ki value derived from dynamic total-body FDG PET/CT proved instrumental in guiding the addition of bevacizumab to neoadjuvant therapy. Both cohorts exhibited promising responses to neoadjuvant therapy, suggesting that neoadjuvant chemotherapy plus tislelizumab \u00b1 bevacizumab, followed by hypo-CCRT and consolidative tislelizumab, is effective and well-tolerated for unresectable, stage III NSCLC patients. Longer follow-up is essential to validate these findings."
        },
        {
            "Title": "Treatment outcomes in locally advanced, unresectable NSCLC treated with concurrent chemoradiation and PD-L1 consolidation: Real-world data from a NCI comprehensive cancer center with a racially diverse, high poverty catchment area.",
            "Indication": "Unresectable Stage III Non-Small Cell Lung Cancer (NSCLC)",
            "Drug": "Durvalumab",
            "PFS": "Overall median PFS was 23 months (95% CI 16.1-31.9) and 28.2 months (95% CI 11.1-50.7) for patients of Black race.",
            "OS": "Overall median OS was 52.4 months (95% CI 31.2-NR) and 52.4 months (95% CI 18.2-NR) for Black patients.",
            "ORR": "None",
            "AE": "Their AE rate was 27.6%",
            "Other": "42.4% of patients lived in high poverty areas, 44.3% were current smokers. Patients had a median annual PM2.5 exposure of 7.9 ug/m3. 42.7% had PD-L1 expression >50%.",
            "Conclusion": "In this diverse cohort treated with the PACIFIC regimen at a NCI CCC, favorable real-world PFS (23.0 vs. 16.9 months), and OS (52.4 vs. 47.5 months) were demonstrated. This cohort is more representative of the US patient population than that of a stringent clinical trial population with at least comparable results. This unexpected result may arise from institutional practices leading to equitable access to care and other biological characteristics of the population. Clinical trial enrollment representative of the broader population remains paramount for equitable care delivery."
        },
        {
            "Title": "Preliminary results of the Lung Cancer Mutation Consortium LCMC4 evaluation of actionable drivers in early stage lung cancer (LEADER) screening trial.",
            "Indication": "Early Stage Lung Adenocarcinoma and Adenosquamous Carcinoma",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Of the 110 patients, 95% (105/110) had a successful tissue and/or liquid profiling. Of samples received, 91% (64/70) yielded successful tissue profiling, and 86% (91/106) successful blood profiling. Some ctDNA was present in 37% (34/91) of blood samples; 62% (56/91) had no detectable. An actionable oncogenic driver was found in 35% (38/110, 95% confidence interval 26-44%) of patients tested. If any ctDNA was detected in blood, there was a 100% agreement with tumor tissue whether a driver was present or absent. In blood samples without paired tumor, an actionable alteration was found in 19% of cases (8/42). Identified driver alterations included: 12 EGFR sensitizing, 12 KRAS G12C, 4 EGFR exon 20 insertions, 2 BRAF V600E, 2 RET fusions, 2 HER2 mutations, 1 ALK fusion, 1 HER2 amplification, 1 MET ex14 skipping, and 1 ROS1 fusion. Alterations detected on liquid biopsy included EGFR exon 20 insertion, EGFR sensitizing mutations, KRAS G12C, RET fusion, and HER2 mutation.",
            "Conclusion": "In this national study using both tissue and blood NGS testing, actionable oncogenic drivers were found in 35% of patients with clinical stages IA2-III lung adenocarcinoma at diagnosis. Blood testing identified an actionable driver 19% of the time when tumor testing was not done. Comprehensive genomics testing on patients with early-stage lung cancer should be standard. It identifies relevant oncogenic drivers and provides important \u2018negative selection\u2019 to identify the patients appropriate for neoadjuvant chemoimmunotherapy. Accrual to the LEADER trial continues."
        },
        {
            "Title": "Feasibility of induction chemoimmunotherapy (ID chemo-IO) followed by chemoradiation for locally advanced unresectable non-small cell lung cancer (NSCLC).",
            "Indication": "Locally advanced unresectable non-small cell lung cancer (NSCLC)",
            "Drug": "Induction chemoimmunotherapy (ID chemo-IO) with pembrolizumab, nivolumab, or atezolizumab",
            "PFS": "Median PFS: 23.67 months",
            "OS": "Median OS: 32.07 months",
            "ORR": "86.11%",
            "AE": "Any grade TrAE: 58.33%, Grade 3-4 pneumonitis: 28.5%, Other toxicities: hepatitis (8.3%), colitis (11.1%), myasthenia gravis (2.8%)",
            "Other": "None",
            "Conclusion": "ID chemo-IO shows promise as a therapeutic approach for patients with unresectable stage III NSCLC, especially for patients deemed ineligible or high risk for upfront radiation. Further investigation is needed through a randomized clinical trial to systematically explore and validate the safety and efficacy of ID chemo-IO."
        },
        {
            "Title": "Efficacy and surgical safety of sequential surgical resection after neoadjuvant chemoimmunotherapy for unresectable stage III NSCLC.",
            "Indication": "Unresectable stage III non-small cell lung cancer (NSCLC)",
            "Drug": "Neoadjuvant chemoimmunotherapy",
            "PFS": "Median PFS: 19.6 months in the non-surgery group, not reached in the surgery group",
            "OS": "Median OS: Not reached in either group",
            "ORR": "None",
            "AE": "Postoperative complications: 8.8%, No surgical-related mortality within 30 days",
            "Other": "Complete (R0) resection rate: 100%, Major pathological response (MPR): 63.7%, Pathologic complete response (pCR): 41.2%",
            "Conclusion": "The use of neoadjuvant chemoimmunotherapy is effective in converting unresectable stage III NSCLC into resectable NSCLC. Subsequent radical surgery is safe with low complications and surgical-related mortality."
        },
        {
            "Title": "Comparative survival after neoadjuvant vs adjuvant systemic therapy in resectable stage II and stage III non-small cell lung cancer: A retrospective study using the National Cancer Database.",
            "Indication": "Resectable Stage II and III Non-small Cell Lung Cancer (NSCLC)",
            "Drug": "Neoadjuvant Chemo, Neoadjuvant chemoIO, Adjuvant chemo, Adjuvant chemoIO",
            "PFS": "None",
            "OS": "Neoadjuvant chemoIO: 2-year OS 77.9%, 5-year OS 68.8%; Adjuvant chemoIO: 2-year OS 68.7%, 5-year OS 42.8%; Hazard Ratio: 70% higher mortality risk in Adjuvant chemoIO group compared to Neoadjuvant chemoIO group (p=0.030, 95% CI 1.05-2.77)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Neoadjuvant chemoIO demonstrates superior survival outcomes compared to Adjuvant chemoIO. Findings suggest the potential predictive value of Immunotherapy in combination with chemotherapy in the Neoadjuvant setting."
        },
        {
            "Title": "Phase I trial of aurora kinase A (AURKA) inhibitor VIC-1911 plus osimertinib for TKI-resistant, EGFR-mutant non-small cell lung cancer (NSCLC).",
            "Indication": "TKI-resistant, EGFR-mutant non-small cell lung cancer (NSCLC)",
            "Drug": "VIC-1911 plus osimertinib",
            "PFS": "Osimertinib-na\u00efve: Median PFS not reached, 6-month PFS rate 70.0%; Osimertinib-resistant: Median PFS 4.3 months, 6-month PFS rate 38.5%",
            "OS": "None",
            "ORR": "Osimertinib-na\u00efve: ORR 50% (5 PRs), DCR 80% (3 SDs); Osimertinib-resistant: ORR 0%, DCR 53.8% (7 SDs)",
            "AE": "Diarrhea (52.3%), leucopenia (38.1%), neutropenia (33.3%), thrombocytopenia (28.6%)",
            "Other": "Median duration of response (DOR) not reached; 6-month DOR rate 50%",
            "Conclusion": "VIC-1911 in combination with osimertinib is well-tolerated in previously-treated EGFRmt NSCLC. The combination demonstrates sustained antitumor activity, indicating the potential of AURKA blockade in delaying the emergence of resistance to osimertinib."
        },
        {
            "Title": "Ultrasensitive circulating tumor DNA (ctDNA) minimal residual disease (MRD) detection in early stage non-small cell lung cancer (NSCLC).",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "PhasED-Seq detected MRD at levels below 1 ppm and was associated with significantly better outcomes, revealing potential benefits of adjuvant therapy in MRD+ patients.",
            "Conclusion": "Ultrasensitive ctDNA detection improved the clinical sensitivity of MRD at key landmarks in early stage NSCLC. PhasED-Seq detected MRD at levels below 1 ppm and was associated with significantly better outcomes, revealing potential benefits of adjuvant therapy in MRD+ patients. This suggests that ultrasensitive MRD detection is promising for use in risk-adapted trials in early stage NSCLC."
        },
        {
            "Title": "An interpretable AI-derived radiology signature to identify patients at risk of progression on the PACIFIC regimen for unresectable non-small cell lung cancer.",
            "Indication": "Unresectable non-small cell lung cancer (NSCLC)",
            "Drug": "PACIFIC regimen (chemotherapy, radiotherapy, and immune checkpoint inhibitors)",
            "PFS": "Hazard ratio (HR) of 4.99 (95% CI: 2.04-12.18; p < 0.005) and concordance index (C-index) of 0.66 for the high-risk group.",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The risk groups identified by the model outperformed PD-L1 negative status in identifying likely progressors (C-index = 0.52; HR = 1.49 [0.51-4.54], p = 0.47), and were additionally independently prognostic of PD-L1 status when compared in a multivariable analysis (p < 0.001).",
            "Conclusion": "An AI imaging tool that stratifies patients by risk after CRIT from baseline radiology was developed. The tool was able to strongly identify a subset of patients at very high risk of progression if treated with CRIT. These risk groups outperformed PD-L1 and thus may address biomarker gaps in the CRIT setting. With further clinical validation, radiology-based risk stratification could be used to identify a subset of unresectable NSCLC patients for whom alternatives to the standard of care should be explored."
        },
        {
            "Title": "Integration of circulating tumor DNA and metabolic parameters for outcome prediction in unresectable locally advanced non-small cell lung cancer.",
            "Indication": "Unresectable locally advanced non-small cell lung cancer (LA-NSCLC)",
            "Drug": "None",
            "PFS": "Patients with undetectable ctDNA and low TMTV exhibited significantly better PFS (p = 0.024). Post-CRT ctDNA and 4TMTV possess strong prognostic capabilities.",
            "OS": "Patients with undetectable ctDNA post-treatment exhibited significantly longer OS. A lower \u0394TMTV was significantly associated with longer OS.",
            "ORR": "None",
            "AE": "None",
            "Other": "Compared with the matched 30 patients receiving ICI consolidation, those with negative ctDNA and high 4TMTV after CRT demonstrated significantly better PFS (p = 0.042) and OS (p = 0.039).",
            "Conclusion": "Baseline ctDNA combined with TMTV can enhance the predictive ability for survival. Post-CRT ctDNA and 4TMTV possess strong prognostic capabilities. Patients who are ctDNA- and with high 4TMTV may be exempt from ICI consolidation therapy."
        },
        {
            "Title": "EGFR-TKI as neoadjuvant treatment in patients with NSCLC with EGFR sensitive mutation: A retrospective real-world analysis.",
            "Indication": "Non-small cell lung cancer (NSCLC) with EGFR sensitive mutation",
            "Drug": "EGFR-TKI",
            "PFS": "None",
            "OS": "The DFS and OS of the whole cohort was 25.7 months and not reached respectively. Patients with MPR had statistically improved outcomes than those without.",
            "ORR": "60.3% (35/58) according to RECIST v1.1.",
            "AE": "None",
            "Other": "Patients with tumor regression grade 3 (13/58) demonstrated a significant benefit from EGFR-TKI preoperative treatment than those with TRG4 or 5 (p = 0.01). The DPR cohort showed significantly prolonged DFS than those with non-DPR (median DFS, 60.9 vs 16.7 months; HR, 0.29 [95% CI: 0.11-0.74], p = 0.006).",
            "Conclusion": "EGFR-TKI as neoadjuvant therapy had good effect on tumor pathological regression and disease downstaging. DPR patients were more likely to achieve long-term disease-free survival. Patients with NDPR should be closely followed up and appropriate adjuvant therapy was necessary after surgery due to the high risk of recurrence."
        },
        {
            "Title": "Neoadjuvant SHR-1701 with or without chemotherapy in unresectable stage III NSCLC (TRAILBLAZER): Efficacy, safety and feasibility of surgical conversion outcomes from a proof-of-concept, phase 2 trial.",
            "Indication": "Unresectable stage III non-small cell lung cancer (NSCLC)",
            "Drug": "SHR-1701",
            "PFS": "18-month EFS rate: 56.6% (95% CI 45.2-66.5) in arm A+B, 77.1% (95% CI 34.5-93.9) in arm C",
            "OS": "24-month overall survival rate: 78.3% (95% CI 68.0-85.7) in arm A+B, 100% in arm C",
            "ORR": "Post-induction ORR: 58% (95% CI 47-68) in arm A+B, 40% (95% CI 12-74) in arm C",
            "AE": "All grade \u22653 TRAEs with frequency \u226510% were hematological toxicities",
            "Other": "None",
            "Conclusion": "Neoadjuvant SHR-1701 with chemotherapy, followed by surgery or radiotherapy, showed promising anti-tumor activity with a tolerable safety profile in unresectable stage III NSCLC. Surgical conversion was feasible in a notable proportion of patients and was associated with better survival outcomes."
        },
        {
            "Title": "Combining a WT1 cancer vaccine (galinpepimut-S) with checkpoint inhibition (nivolumab) in patients with WT1-expressing diffuse pleural mesothelioma (DPM): A phase I study.",
            "Indication": "WT1-expressing diffuse pleural mesothelioma (DPM)",
            "Drug": "Galinpepimut-S and nivolumab",
            "PFS": "None",
            "OS": "Median overall survival: 13.5 months from the most recent prior line of therapy",
            "ORR": "Disease control rate of 30% at 8 weeks",
            "AE": "70% experienced treatment-related adverse events which were mostly mild; 2 experienced a grade \u22653 adverse event",
            "Other": "Four (50%) of 8 patients had T-cell responses to WT1 peptides",
            "Conclusion": "Coadministration of galinpepimut-S and nivolumab demonstrated a tolerable toxicity profile and induced immune responses in a subset of patients as indicated by immunophenotyping and disease control rate of 30% at 8 weeks but initial response and survival benefit were limited which may be due to the small sample size."
        },
        {
            "Title": "Final analysis of the BIMES trial, a phase II single arm study assessing efficacy and safety of bintrafusp alfa in previously treated advanced malignant pleural mesothelioma (GECP 20/09).",
            "Indication": "Advanced malignant pleural mesothelioma (MPM)",
            "Drug": "Bintrafusp alfa",
            "PFS": "Median PFS: 1.9 months (95% CI 1.7\u20135.4); 6-month PFS rate: 15.9%",
            "OS": "Median OS: 11.9 months (95% CI 4.4\u2013not reached); 6, 12, and 18-month OS rate: 65.3%, 46.5%, and 26.6%, respectively",
            "ORR": "Disease control rate: 34.8% (95% CI 22.2-49.9)",
            "AE": "Grade 3-4 treatment-related adverse events occurred in 16 (34%) patients, anemia being the most common (n=5); skin toxicity (n=3); colitis (n=2); adrenal insufficiency, acute kidney injury, allergic reaction, lipase, and amylase increased (n = 1 each)",
            "Other": "None",
            "Conclusion": "Bintrafusp alfa did not reach the expected efficacy in patients with advanced malignant pleural mesothelioma previously treated with platinum-based chemotherapy."
        },
        {
            "Title": "Efficacy of nivolumab/ipilimumab as first-line treatment of pleural mesothelioma in the German real-world setting.",
            "Indication": "Unresectable pleural mesothelioma (PM)",
            "Drug": "Nivolumab and ipilimumab",
            "PFS": "Median PFS for the entire cohort: 4.76 months (95% CI 3.7-6.7); 5.6 months for NE histology and 4.2 months for E histology",
            "OS": "Median OS for the entire cohort: 13.6 months (95% CI 10.64-17.35); 16.6 months in NE histology vs. 13.6 months in E histology",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "In this real-world analysis of unselected patients, survival benefit with nivolumab/ipilimumab was somewhat less pronounced in both non-epithelioid and epithelioid subtypes compared to the Checkmate 743 trial. Older patients had worse prognosis, while there was also a tendency for shorter survival of never smokers and patients with worse ECOG performance status."
        },
        {
            "Title": "Programmed cell death ligand 1 (PD-L1) inhibitors versus programmed cell death 1 (PD-1) inhibitors for the first-line therapy of extensive-stage small cell lung cancer: A propensity score-matched study.",
            "Indication": "Extensive-stage small cell lung cancer (ES-SCLC)",
            "Drug": "PD-L1 inhibitors, PD-1 inhibitors, etoposide-platinum (EP) chemotherapy",
            "PFS": "7.8 months (PD-L1 inhibitors + EP), 8.5 months (PD-1 inhibitors + EP), HR 1.099, 95% CI 0.886-1.364, p=0.390",
            "OS": "20.4 months (PD-L1 inhibitors + EP), 20.7 months (PD-1 inhibitors + EP), HR 1.043, 95% CI 0.776-1.401, p=0.781",
            "ORR": "None",
            "AE": "Overall adverse events comparable; Grade 3 neutropenia more frequent in PD-L1 inhibitors + EP group (77.7% vs 69.0%, p=0.040)",
            "Other": "None",
            "Conclusion": "The overall efficacy and safety profile was similar between PD-L1 inhibitors and PD-1 inhibitors for the first-line treatment of ES-SCLC."
        },
        {
            "Title": "Outcome of chemo-immunotherapy for extensive-stage small-cell lung cancer according to potential clinical trial eligibility: 3-year outcomes from a prospective cohort study.",
            "Indication": "Extensive-stage small-cell lung cancer (ES-SCLC)",
            "Drug": "Carboplatin, etoposide, atezolizumab",
            "PFS": "3-year PFS probability: 6.1% (95% CI: 3.5-10.4%)",
            "OS": "3-year OS probability: 20.9% (95% CI: 15.6-27.3%); 3-year OS rate: 26.7% (trial-eligible group), 9.5% (trial-ineligible group)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "This is the first real-world study to show the long-term efficacy of chemo-immunotherapy for ES-SCLC by using the largest prospective cohort of its kind. Additionally, this study demonstrated that trial eligibility was associated with long-term efficacy."
        },
        {
            "Title": "Tifcemalimab combined with toripalimab and chemotherapy as 1st line treatment for extensive-stage small cell lung cancer (ES-SCLC): A phase Ib/II, open-label study.",
            "Indication": "Extensive-stage small cell lung cancer (ES-SCLC)",
            "Drug": "Tifcemalimab, toripalimab, etoposide, carboplatin/cisplatin",
            "PFS": "None",
            "OS": "None",
            "ORR": "86.5% (32/37)",
            "AE": "Leukopenia (65.9%), neutropenia (63.6%), anemia (40.9%), thrombocytopenia (40.9%); 9.1% experienced immune-related AEs (irAEs), 1 experienced \u2265grade 3 irAE",
            "Other": "Disease control rate (DCR): 100% (37/37); 94.6% of responses ongoing; median duration of response not reached",
            "Conclusion": "Tifcemalimab in combination with toripalimab and chemotherapy showed a promising objective response rate with a manageable safety profile as a 1st line treatment for patients with ES-SCLC. Continued follow-up is ongoing for additional safety and efficacy (PFS and OS) evaluation after extended exposure."
        },
        {
            "Title": "Updated results from a phase 1/2 study of HPN328, a tri-specific, half-life extended DLL3-targeting T-cell engager in patients (pts) with small cell lung cancer (SCLC) and other neuroendocrine cancers (NEC).",
            "Indication": "Small cell lung cancer (SCLC) and other neuroendocrine cancers (NEC)",
            "Drug": "HPN328",
            "PFS": "None",
            "OS": "None",
            "ORR": "50% in SCLC, 44% in NEC",
            "AE": "CRS (59%), dysgeusia (36%), fatigue (34%), diarrhea (19%), nausea (17%), vomiting (14%), decreased appetite and decreased neutrophil count (13% each), weight decreased (11%)",
            "Other": "Immune effector cell-associated neurotoxicity syndrome (ICANS) occurred in 9% of pts, all G1-2. Maximum tolerated priming dose was 1mg; dose escalation of target dose continued up to 24 mg QW without reaching a maximum tolerated target dose.",
            "Conclusion": "HPN328 is well tolerated and clinically active in SCLC, NEC, and NEPC. Current dose optimization cohorts have completed enrollment and data continue to mature; selection of a recommended phase 2 dose will be made based upon complete mature data. Updated safety and efficacy results will be presented."
        },
        {
            "Title": "J-TAIL-2: A prospective, observational study of atezolizumab (atezo) combined with carboplatin and etoposide (CE) in patients (pts) with extensive-stage small cell lung cancer (ES-SCLC) in Japan.",
            "Indication": "Extensive-stage small cell lung cancer (ES-SCLC)",
            "Drug": "Atezolizumab combined with carboplatin and etoposide (CE)",
            "PFS": "5.1 months",
            "OS": "12-month OS rate was 63.7%, median OS was 16.5 months",
            "ORR": "None",
            "AE": "Treatment-related AEs occurred in 36.0% of pts, Grade \u22653 AEs in 30.3% of pts, Grade 5 AEs occurred in 5.2% of pts",
            "Other": "None",
            "Conclusion": "The efficacy and safety of atezo+CE in Japanese pts treated in clinical practice were consistent with those seen in IMp133. Subgroup analyses support the use of atezo+CE in pts who would have been ineligible for IMp133, although clinical outcomes favored the IMp133-like group. Efficacy and safety were similar for pts aged <70 vs \u226570 years but were worse for those with lower vs higher G8 scores, suggesting that this assessment may be a useful tool for therapeutic strategies. These data support the use of atezo+CE in Japanese pts with ES-SCLC, including in subgroups excluded from IMp133."
        },
        {
            "Title": "ARTEMIS-001: Data from a phase 1a/b study of HS-20093 in patients with relapsed small cell lung cancer (SCLC).",
            "Indication": "Relapsed small cell lung cancer (SCLC)",
            "Drug": "HS-20093",
            "PFS": "None",
            "OS": "None",
            "ORR": "58.1% at 8.0 mg/kg, 57.1% at 10.0 mg/kg",
            "AE": "The most common grade \u22653 treatment-related adverse events (\u226510%) were neutropenia, leukopenia, lymphopenia, thrombocytopenia, and anemia.",
            "Other": "Tumor shrinkage in target lesions occurred in 96.2% of pts. Pharmacokinetic (PK) showed approximately dose-proportional increase in exposure with a half-life of 3-7 days. PK profiles of total antibody and ADC were similar and exposure to payload was considerably low.",
            "Conclusion": "HS-20093 demonstrated promising antitumor activity and manageable safety in pts with previously-treated SCLC. Phase 3 study is planned to compare the efficacy and safety of HS-20093 with standard-of-care chemotherapy in relapsed SCLC."
        },
        {
            "Title": "Efficacy and safety of lurbinectedin (LUR) with irinotecan (IRI) in patients with relapsed small cell lung cancer (SCLC): Results from a phase 2 expansion cohort.",
            "Indication": "Relapsed small cell lung cancer (SCLC)",
            "Drug": "Lurbinectedin (LUR) with irinotecan (IRI)",
            "PFS": "4.7 months (95% CI: 3.8-6.0) overall; 3.3 months (95% CI: 2.6-5.0) for CTFI < 90d; 6.2 months (95% CI: 4.2-9.6) for CTFI \u2265 90d; 5.6 months (95% CI: 4.1-7.2) for CTFI > 30d",
            "OS": "9.7 months (95% CI: 8.5-13.4) overall; 7.8 months (95% CI: 3.5-8.9) for CTFI < 90d; 14.0 months (95% CI: 10.1-16.1) for CTFI \u2265 90d; 12.7 months (95% CI: 9.1-14.1) for CTFI > 30d",
            "ORR": "42.6% (95% CI: 32.8-52.8) overall; 25.0% (95% CI: 14.0-38.9) for CTFI < 90d; 61.2% (95% CI: 46.2-74.8) for CTFI \u2265 90d; 51.4% (95% CI: 39.4-63.1) for CTFI > 30d",
            "AE": "99.0% of patients experienced treatment-related adverse events (grade \u2265 3 in 69.3%). Most relevant grade \u2265 3 events: neutropenia (52.5%), anemia (27.7%), diarrhea (19.8%), fatigue (18.8%), febrile neutropenia (9.9%). Treatment-related SAEs occurred in 25.0% of patients, and 5.0% discontinued due to treatment-related AEs. No treatment-related deaths occurred.",
            "Other": "Duration of response (DoR): 7.6 months (95% CI: 5.4-9.7) overall; 6.3 months (95% CI: 3.9-9.7) for CTFI < 90d; 8.4 months (95% CI: 4.6-12.7) for CTFI \u2265 90d; 8.2 months (95% CI: 5.4-11.7) for CTFI > 30d",
            "Conclusion": "The LUR/IRI combination showed promising antitumor activity and a manageable safety profile in these patients with poor prognosis, particularly those with CTFI \u2265 30d. These encouraging results reinforce the rationale for including this combination as an experimental arm in the ongoing pivotal phase 3 LAGOON trial (NCT05153239) in relapsed SCLC with CTFI \u2265 30d."
        },
        {
            "Title": "Results of a phase III clinical trial with anti-PDL1 treatment in combination with chemotherapy for extensive stage small cell lung cancer.",
            "Indication": "Extensive stage small cell lung cancer (ES-SCLC)",
            "Drug": "Socazolimab + carboplatin + etoposide",
            "PFS": "5.55 months (95% CI: 5.06-5.82) for study group; 4.37 months (95% CI: 4.27-4.70) for control group",
            "OS": "13.90 months (95% CI: 12.22-15.34) for study group; 11.58 months (95% CI: 10.64-12.81) for control group",
            "ORR": "None",
            "AE": "The treatment-related adverse events were similar in both groups.",
            "Other": "24-month survival rate: 20.7% (95% CI: 14.8-27.3) for study group; 5.9% (95% CI: 0.8-18.9) for control group",
            "Conclusion": "These results show that Socazolimab plus chemotherapy continued to provide clinically meaningful improvements in OS for patients with extensive stage small cell lung cancer."
        },
        {
            "Title": "Survival outcomes of patients with extensive-stage small cell lung cancer who received treatment with atezolizumab in combination with carboplatin and etoposide: A propensity score adjusted cohort study.",
            "Indication": "Extensive-stage small cell lung cancer (ES-SCLC)",
            "Drug": "Atezolizumab in combination with carboplatin and etoposide",
            "PFS": "5.9 months (95% CI: 5.3-6.9) for Carbo-E-Atezo; 5.5 months (95% CI: 4.7-5.9) for Carbo-E",
            "OS": "9.2 months (95% CI: 8.1-11) for Carbo-E-Atezo; 8.4 months (95% CI: 7.7-9.0) for Carbo-E",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Among pts with ES-SCLC, the addition of atezolizumab to the standard chemotherapy regimen of Carbo-E showed no significant difference in PFS or OS in our real-world study."
        },
        {
            "Title": "Cost-effectiveness study of atezolizumab (ATZ) vs. durvalumab (DUR): Real-world data (RWD) on first-line chemotherapy combined with immune-checkpoint inhibitors (Chemo-ICIs) for extensive disease small-cell lung cancer (ED-SCLC).",
            "Indication": "Extensive disease small-cell lung cancer (ED-SCLC)",
            "Drug": "Atezolizumab (ATZ) vs. Durvalumab (DUR)",
            "PFS": "4.9 months (95% CI: 4.4-5.6) for ATZ-G; 5.6 months (95% CI: 5.0-6.7) for DUR-G",
            "OS": "13.9 months (95% CI: 11.7-17.5) for ATZ-G; 13.6 months (95% CI: 11.0-20.0) for DUR-G",
            "ORR": "73.4% for ATZ-G; 75.0% for DUR-G",
            "AE": "ATZ-G revealed lower incidences of immune-related adverse events (irAEs) \u2265 grade 2 (10.9% vs. 31.3%, p=0.009) and any grade interstitial lung disease (ILD) (3.1% vs. 20.3%, p=0.004) than DUR-G. Number of hospitalizations in ATZ-G (median 1, range 0-6) was lower than that in DUR-G (median 2, range 0-7) (p=0.005).",
            "Other": "Mean total medical costs per month during ICIs: 1,003,922 JPY (6,783 USD) in ATZ-G; 1,596,511 JPY (10,787 USD) in DUR-G (p=0.001). Mean ICI drug costs per month during ICIs: 799,079 JPY (5,399 USD) in ATZ-G; 1,570,744 JPY (10,163 USD) in DUR-G (p=0.001).",
            "Conclusion": "Our RWD demonstrated a superior cost-effectiveness of ATZ to that of DUR. Total efficacies were similar, whereas the safety profiles, including irAE and ILD were more favorable in ATZ-G. Further discussion is required regarding not only cost-effectiveness but also clinical practicality such as: dose schedule of 3 weeks vs. 4 weeks; regimen flexibility of carboplatin vs. cisplatin; and etoposide dose adjustment of 100 mg/m2 vs. 80 mg/m2."
        },
        {
            "Title": "A randomized phase II study of toripalimab consolidation or observation after concurrent chemoradiotherapy in limited-stage small cell lung cancer.",
            "Indication": "Limited-stage small cell lung cancer (LS-SCLC)",
            "Drug": "Toripalimab",
            "PFS": "Median PFS in observation group: 12.3 months (95% CI, 0.04-24.50); Median PFS in Toripalimab group: not reached; 24-month PFS rate: 61.6% (95% CI, 43.0%-88.3%) in Toripalimab group vs. 34.8% (95% CI, 21.5%-56.3%) in observation group; Hazard ratio [95% CI]: 0.47 [0.22-1.02]; P=0.04",
            "OS": "24-month OS: 82.7% (95% CI, 65.2%-100%; P=0.23) in Toripalimab group vs. 59.1% (95% CI, 44.2%-79.1%) in observation group",
            "ORR": "None",
            "AE": "G2+ pneumonitis: 16.1% in Toripalimab group vs. 9.1% in observation group; No G4+ toxic events in either group",
            "Other": "None",
            "Conclusion": "Our preliminary results suggested Toripalimab consolidation following definitive CCRT was effective and tolerable in LS-SCLC."
        },
        {
            "Title": "BIOLUMA: A phase II trial of nivolumab and ipilimumab in lung cancer\u2014Results from the SCLC TMB high cohort.",
            "Indication": "Small cell lung cancer (SCLC) with high tumor mutation burden (TMB)",
            "Drug": "Nivolumab and Ipilimumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "13.6% showed response to therapy; 9% response confirmed according to RECIST 1.1",
            "AE": "None",
            "Other": "Disease control rate (DCR): 20.4% (n=9); Three patients with confirmed PR or SD experienced long-lasting treatment benefit",
            "Conclusion": "This represents the first trial to prospectively evaluate the combination therapy of nivolumab and ipilimumab in TMB high SCLC patients. The primary endpoint ORR was not reached. However, we observed an impressive clinical benefit in single patients, which warrants further investigation."
        },
        {
            "Title": "Serplulimab vs. placebo combined with chemotherapy as first-line treatment for extensive-stage small-cell lung cancer: Extended follow-up results and patient-reported outcomes from the international phase 3 ASTRUM-005 study.",
            "Indication": "Extensive-stage small-cell lung cancer (ES-SCLC)",
            "Drug": "Serplulimab",
            "PFS": "None",
            "OS": "Median OS: 15.8 months in serplulimab arm vs. 11.1 months in placebo arm; Stratified HR 0.61, 95% CI 0.50\u20130.74; Estimated OS rate at 3 years: 24.6% (95% CI 19.5\u201330.1) in serplulimab arm vs. 9.8% (95% CI 5.6\u201315.4) in placebo arm",
            "ORR": "None",
            "AE": "None",
            "Other": "Patient-reported outcomes (PROs) were not adversely impacted, and pain in other parts was significantly improved in the serplulimab arm.",
            "Conclusion": "With extended follow-up, the survival benefits brought by the addition of serplulimab were maintained in the first-line therapy of ES-SCLC. PROs were not adversely impacted, and pain in other parts was significantly improved. All these results support serplulimab plus chemo as a promising first-line treatment option for ES-SCLC."
        },
        {
            "Title": "Safety and efficacy of durvalumab plus carboplatin and etoposide for patients with previously untreated extensive-stage small-cell lung cancer (ES-SCLC) with a poor performance status: The results from phase II single-arm study (NEJ045A study).",
            "Indication": "Extensive-stage small-cell lung cancer (ES-SCLC) with poor performance status (PS)",
            "Drug": "Durvalumab plus carboplatin and etoposide",
            "PFS": "Median PFS in PS2: 4.7 months (95% CI, 3.8-6.4); Median PFS in PS3: 5.1 months (95% CI, 1.4-10.6)",
            "OS": "Median OS in PS2: 9.5 months (95% CI, 6.6-26.7); Median OS in PS3: 5.1 months (95% CI, 1.4-not reached)",
            "ORR": "ORR in PS2: 52% (95% CI, 36-68); ORR in PS3: 45% (95% CI, 17-77)",
            "AE": "Grade \u22653 adverse events (AEs) reported in 93% in PS2 and 100% in PS3 patients; 20% discontinued study treatment due to AE",
            "Other": "The ratios of PS improvement in PS2 and PS3 were 57% (95% CI, 42-71) and 45% (95% CI, 21-72)",
            "Conclusion": "The current study met its primary endpoint. Durvalumab plus CBDCA and ETP showed tolerability and promising efficacy as first-line treatment for ES-SCLC patients with a poor PS. These data support the combination therapy of immune checkpoint inhibitor and chemotherapy with dosage adjustment for ES-SCLC with a poor PS."
        },
        {
            "Title": "An open-label, multicenter, phase Ib/II study of the ATR inhibitor SC0245 in combination with irinotecan in patients with relapsed and refractory extensive stage small cell lung cancer (ES-SCLC).",
            "Indication": "Extensive stage small cell lung cancer (ES-SCLC)",
            "Drug": "SC0245 in combination with irinotecan",
            "PFS": "None",
            "OS": "None",
            "ORR": "One confirmed partial response (PR) in a patient with ES-SCLC treated in the 120mg QD dose cohort, sustained for 32+ weeks; five patients (5/9, 55.6%) had stable disease (SD) as their best response.",
            "AE": "Cytopenia (73.3%), diarrhea (46.7%), liver function test elevations (40%), Grade 3 febrile neutropenia (one DLT event), leukopenia (20.0%). Most AEs were grade 1 or 2 in severity and manageable.",
            "Other": "PK characteristics indicated that SC0245 was rapidly absorbed (median Tmax 1.0 to 2.0 h), and drug exposure was proportional to dose for the QD and BID regimens. No drug-drug interactions (DDI) between SC0245 and either irinotecan or its active metabolite SN38 were observed.",
            "Conclusion": "SC0245 exhibited favorable safety and PK characteristics when administered in combination with irinotecan at doses ranging from 80mg QD to 120mg BID and the regimen has demonstrated preliminary antitumor activity in ES-SCLC, supporting further evaluation of the regimen in ES-SCLC and other relevant malignancies."
        },
        {
            "Title": "A phase II, open-label, combination therapy of durvalumab and ceralasertib in relapsed and refractory small cell lung cancer (SUKSES-N4).",
            "Indication": "Relapsed and refractory small cell lung cancer (SCLC)",
            "Drug": "Durvalumab and ceralasertib",
            "PFS": "Median progression-free survival (PFS) was 1.64 months (95% CI: 1.61-1.97).",
            "OS": "Overall survival was 7.16 months (95% CI: 6.07-13.9).",
            "ORR": "The objective response rate (ORR) per RECIST was 9.5%, with a disease control rate of 26.2%, including 4 partial responses (PR) and 7 cases of stable disease (SD).",
            "AE": "Hematologic adverse events (AEs) included thrombocytopenia as grade 4 (n=9) and grade 3 (n=3), anemia as grade 3 (n=3), and neutropenia as grade 4 (n=3) and grade 3 (n=1). Other AEs were mostly grades 1 or 2 and manageable.",
            "Other": "The duration of responses was notable, showing PFS of 4.8, 17.0, and 18.3 months in ICI-na\u00efve PR patients and 14.1 months in ICI-exposed patient currently still ongoing on treatment. A sustained clinical benefit of up to 10.7 months was observed in an ICI-na\u00efve patient with SD.",
            "Conclusion": "Ceralasertib and durvalumab combination therapy have demonstrated durable responses in select patients with manageable hematologic toxicity. Biomarker research is ongoing to identify patients most likely to benefit from this treatment strategy."
        },
        {
            "Title": "Comprehensive analysis of the value of dynamic changes in ctDNA in SCLC",
            "Indication": "Small-cell lung cancer (SCLC)",
            "Drug": "Standard-of-care chemotherapy +/- immunotherapy",
            "PFS": "11.2 vs. 6.1 months, HR not provided",
            "OS": "19.4 vs. 12.2 months, HR not provided",
            "ORR": "None",
            "AE": "None",
            "Other": "Baseline max VAF ctDNA levels strongly correlate with PFS and DFS but not OS and ORR. Patients with detectable residual RB1 alterations at completion of prescribed chemotherapy have an extremely short DFS of 1 month.",
            "Conclusion": "Baseline max VAF ctDNA levels strongly correlate with PFS and DFS but not OS and ORR. Early assessment for clinical trials is justified for patients with detectable residual RB1 alterations."
        },
        {
            "Title": "Cytoreductive surgery followed by hyperthermic intrathoracic chemotherapy for the treatment of thymic epithelial malignancies with pleural spread or recurrence",
            "Indication": "Thymic epithelial malignancies (TETs) with pleural spread or recurrence",
            "Drug": "Doxorubicin and cisplatin",
            "PFS": "1-, 2-, and 3-year PFS rates were 96.6%, 82.8%, and 70.9%",
            "OS": "1-, 2-, and 3-year OS rates were 100.0%, 100.0%, and 85.7%",
            "ORR": "None",
            "AE": "18.6% had grade 3-4 treatment-related complications",
            "Other": "Overall remission rate of MG was 100%, with complete stable remission rate of 25.0%, pharmacological remission rate of 41.7%, and minimal manifestation status rate of 33.3%",
            "Conclusion": "S-HITOC is a safe and feasible procedure with acceptable complication rate. Early clinical outcomes confirm S-HITOC offers encouraging oncological benefits for TETs and satisfactory control of MG."
        },
        {
            "Title": "S1701: A randomized phase II trial of carboplatin-paclitaxel with and without ramucirumab in patients with locally advanced, recurrent, or metastatic thymic carcinoma",
            "Indication": "Thymic carcinoma",
            "Drug": "Carboplatin-paclitaxel with or without ramucirumab",
            "PFS": "8 months for RCP and 7 months for CP, HR: 0.51 [0.24\u20131.09]",
            "OS": "Not available",
            "ORR": "88% partial response rate for RCP vs. 40% for CP",
            "AE": "50% experienced grade 3 adverse events in RCP arm, 11% encountered grade 4 neutropenia in CP arm",
            "Other": "Disease control rate was higher in the RCP arm (100% vs. 70%)",
            "Conclusion": "The addition of ramucirumab to CP led to higher response rates than CP alone. At this early analysis, PFS was not significantly improved; OS is pending. High-grade adverse events necessitate careful patient selection."
        },
        {
            "Title": "A retrospective study of 157 patients comparing platinum-anthracycline to other platinum combination for untreated unresectable thymoma: NEJ023B",
            "Indication": "Unresectable thymoma",
            "Drug": "Platinum-anthracycline combination vs. other platinum combinations",
            "PFS": "13.4 months for platinum-anthracycline vs. 15.0 months for other platinum combinations, HR: 0.932",
            "OS": "111.9 months for platinum-anthracycline vs. 84 months for other platinum combinations, HR: 0.733",
            "ORR": "57% for platinum-anthracycline vs. 30% for other platinum combinations",
            "AE": "None",
            "Other": "Disease control rates were 96% for platinum-anthracycline and 100% for other platinum combinations",
            "Conclusion": "Platinum plus anthracycline showed better response rates than other platinum combinations as a first-line chemotherapy, but there was no significant difference in PFS and OS. The median PFS for primary treatment was about one year, whereas the overall survival was nearly ten years, indicating the need for a long-term multidisciplinary approach."
        },
        {
            "Title": "Combining SBRT with GM-CSF and Peg-IFNa to induce abscopal effects in previously treated patients with stage IV thymic tumors.",
            "Indication": "Stage IV thymic tumors",
            "Drug": "SBRT, GM-CSF, Peg-IFNa",
            "PFS": "9.0 months",
            "OS": "None",
            "ORR": "45.0%",
            "AE": "15.0% Grade 3 treatment-related adverse events",
            "Other": "8 (40.0%) out of 20 patients had abscopal effects",
            "Conclusion": "Combining SBRT with GM-CSF and Peg-IFNa was well tolerated with acceptable toxicity and may represent a promising salvage therapy for previously treated patients with stage IV thymic tumors. The occurrence of abscopal effects is likely to improve patient outcomes."
        },
        {
            "Title": "A phase II clinical trial of selinexor in patients with advanced thymoma and thymic carcinoma.",
            "Indication": "Advanced thymoma and thymic carcinoma",
            "Drug": "Selinexor",
            "PFS": "7.8 months (TC group), 13.6 months (T group)",
            "OS": "15.5 months (TC group), Not reached (T group)",
            "ORR": "6.7% (TC group), 12.5% (T group)",
            "AE": "83.8% nausea, 45.2% vomiting, 41.9% anemia, 38.7% fatigue; 16.1% Grade 3 or higher anemia, 12.9% thrombocytopenia, 9.7% asthenia",
            "Other": "20 (64.5%) patients required dose reductions due to AEs, and 20 (64.5%) required dose interruptions",
            "Conclusion": "Selinexor demonstrated modest anticancer activity in patients with pretreated advanced TET. ORRs were low but prolonged stable disease was noted in a subset of patients."
        },
        {
            "Title": "LIBRETTO-432: A phase 3 study of adjuvant selpercatinib or placebo in stage IB-IIIA RET fusion-positive (RET+) NSCLC.",
            "Indication": "Stage IB-IIIA RET fusion-positive (RET+) NSCLC",
            "Drug": "Selpercatinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint is investigator-assessed event-free survival (IAEFS) in the primary analysis population (pts with Stage II-IIIA). EFS is defined as time from randomization until locoregional disease recurrence with histopathological confirmation, distant disease recurrence per RECIST v1.1 or histopathological confirmation, or death.",
            "Conclusion": "Results from this trial will further inform the value of RET inhibition and genomic testing for adjuvant NSCLC pts."
        },
        {
            "Title": "The phase 3 INTerpath-002 study design: Individualized neoantigen therapy V940 (mRNA-4157) plus pembrolizumab vs placebo plus pembrolizumab for resected early-stage non\u2013small-cell lung cancer (NSCLC).",
            "Indication": "Non-small-cell lung cancer (NSCLC)",
            "Drug": "V940 (mRNA-4157) plus pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Disease-free survival (DFS) per investigator review, distant metastasis-free survival per investigator assessment, lung cancer-specific survival, patient-reported outcomes, and safety.",
            "Conclusion": "Enrollment began in October 2023 and is ongoing globally."
        },
        {
            "Title": "Efficacy and safety of aumolertinib combined with or without chemotherapy as an adjuvant treatment for stage II-III A non-small cell lung cancer following complete tumour resection: The first third-generation EGFR-TKI versus chemotherapy phase III clinical study (APEX).",
            "Indication": "Stage II-III A non-small cell lung cancer (NSCLC)",
            "Drug": "Aumolertinib combined with or without chemotherapy",
            "PFS": "None",
            "OS": "5-year overall survival (OS)",
            "ORR": "None",
            "AE": "Adverse effects were graded per CTCAE v.4.0.",
            "Other": "2, 3, 4, 5-year DFS rates, safety, and quality of life.",
            "Conclusion": "This trial is registered as NCT04762459."
        },
        {
            "Title": "A randomized phase II trial of adjuvant pembrolizumab versus observation following curative resection for stage I non-small cell lung cancer (NSCLC) with primary tumors between 1-4 cm: Big Ten Cancer Research Consortium BTCRC-LUN18-153.",
            "Indication": "Stage I non-small cell lung cancer (NSCLC)",
            "Drug": "Pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Disease-free survival (DFS) at multiple time points (1-, 2-, and 3-years), safety.",
            "Conclusion": "Enrollment is currently at 95 patients with a planned enrollment of 244."
        },
        {
            "Title": "Olaparib, AZD1390, ceralasertib, saruparib and consolidation durvalumab (CONCORDE) phase Ib platform study of novel DNA damage response inhibitor (DDRi) agents in combination with radiotherapy in non-small cell lung cancer (NSCLC).",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "Olaparib, AZD1390, ceralasertib, saruparib, durvalumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Dose-limiting toxicity (DLT) period for treatment-related toxicities, biomarkers of treatment response, toxicity, and impact on the immune system.",
            "Conclusion": "CONCORDE continues to recruit to the target sample size of 160 (40/arm) and welcomes further UK centres."
        },
        {
            "Title": "Neoadjuvant toripalimab plus chemotherapy for resectable stage II-IIIB non-squamous non-small cell lung cancer with EGFR mutations: A multi-center, multi-cohort, exploratory study.",
            "Indication": "Resectable stage II-IIIB non-squamous non-small cell lung cancer with EGFR mutations",
            "Drug": "Toripalimab plus platinum-based doublet chemotherapy (pemetrexed and carboplatin)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: complete pathological response (pCR) rate. Secondary endpoints: major pathological response (MPR) rate, pCR/MPR rate in different EGFR mutation subgroups and PD-L1 expression subgroups, event-free survival, safety and tolerability.",
            "Conclusion": "The study is ongoing with the aim to evaluate the efficacy and safety of neoadjuvant toripalimab plus chemotherapy in EGFR-positive NSCLC patients."
        },
        {
            "Title": "VELOCITY-Lung substudy-03: A phase 2 study of neoadjuvant domvanalimab (dom) + zimberelimab (zim) + chemotherapy (chemo) or zim + chemo followed by adjuvant dom + zim or zim in patients with resectable stage II-III non-small cell lung cancer (NSCLC).",
            "Indication": "Resectable stage II-III non-small cell lung cancer",
            "Drug": "Domvanalimab (dom) + zimberelimab (zim) + platinum-based chemotherapy or zim + platinum-based chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: pathological complete response rate. Secondary endpoints: major pathological response rate, event-free survival, overall survival, safety.",
            "Conclusion": "The study is currently recruiting and aims to evaluate novel perioperative treatment combinations in resectable NSCLC."
        },
        {
            "Title": "Phase II study of induction platinum doublet in combination with nivolumab followed by surgery or concurrent chemotherapy-nivolumab-radiation in unresectable stage IIIA-C non-small cell lung cancer (NSCLC).",
            "Indication": "Unresectable stage IIIA-C non-small cell lung cancer",
            "Drug": "Platinum doublet chemotherapy plus nivolumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary outcome: response to the induction regimen by repeat CT scan. Secondary outcomes: rate of converting non-surgical stage III(A-C) to surgically resectable disease, pCR rate, extent of post-induction XRT field decrease, 2-year progression-free survival, overall survival, quality of life, exploratory outcomes including markers of resistance and response, and remote symptom monitoring.",
            "Conclusion": "The study is accruing with a plan to open at a second site."
        },
        {
            "Title": "A randomized phase II trial of atezolizumab with or without tiragolumab before and after definitive chemoradiation for unresectable stage III non-small cell lung cancer (NSCLC; AFT-57).",
            "Indication": "Unresectable stage III non-small cell lung cancer",
            "Drug": "Atezolizumab with or without tiragolumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: progression-free survival. Secondary endpoints: safety and efficacy of atezolizumab with or without tiragolumab before and after definitive chemoradiation.",
            "Conclusion": "The trial was activated on 12/7/23 with the first patients screened on 1/19/24."
        },
        {
            "Title": "Phase 2 study to evaluate the novel mitochondrial PRX3 inhibitor, RSO-021, as an intrapleural monotherapy and in combination with IV paclitaxel in patients with malignant pleural effusion due to mesothelioma or another advanced solid tumor.",
            "Indication": "Malignant pleural effusion due to mesothelioma or another advanced solid tumor",
            "Drug": "RSO-021",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Preclinical studies show RSO-021 selectively inhibits mitochondrial PRX3 in vitro and is highly effective in various tumor models. Phase 1 data supported weekly intrapleural dosing of RSO-021 at the 90mg dose level with acceptable safety and early signs of efficacy.",
            "Conclusion": "The study aims to confirm the efficacy, safety, tolerability, and pharmacology of weekly intrapleural RSO-021 administration."
        },
        {
            "Title": "IDeate-Lung02: A phase 3, randomized, open-label study of ifinatamab deruxtecan (I-DXd) vs treatment of physician\u2019s choice (TPC) in relapsed small cell lung cancer (SCLC).",
            "Indication": "Relapsed small cell lung cancer (SCLC)",
            "Drug": "Ifinatamab deruxtecan (I-DXd)",
            "PFS": "None",
            "OS": "None",
            "ORR": "52.4% in Phase 1/2 trial",
            "AE": "Manageable safety profile in Phase 1/2 trial",
            "Other": "The study treatment continues until disease progression, unacceptable toxicity, withdrawal of consent, death, loss to follow-up, or other reasons per protocol. Radiographic tumor assessments occur every 6 weeks for 48 weeks, then every 12 weeks.",
            "Conclusion": "The study aims to compare the efficacy and safety of I-DXd vs TPC in relapsed SCLC."
        },
        {
            "Title": "DAREON-8: A phase I, open-label, dose escalation/expansion trial of the DLL3-targeting T-cell engager, BI 764532, combined with first-line (1L) standard of care (platinum, etoposide, and anti-PD-L1 antibody) in patients with extensive-stage small cell lung carcinoma (ES-SCLC).",
            "Indication": "Extensive-stage small cell lung carcinoma (ES-SCLC)",
            "Drug": "BI 764532",
            "PFS": "None",
            "OS": "None",
            "ORR": "28% in ongoing Phase I trial",
            "AE": "Manageable safety profile in ongoing Phase I trial",
            "Other": "The primary objective is to determine the maximum tolerated dose (MTD) of BI 764532 and/or the recommended dose for expansion (RDE)/recommended Phase II dose (RP2D) for BI 764532 plus SoC.",
            "Conclusion": "The study aims to confirm the safety and tolerability of BI 764532 at the RDE/RP2D plus SoC treatment."
        },
        {
            "Title": "Alisertib in patients with extensive-stage small-cell lung cancer: The phase 2 ALISCA-Lung1 study.",
            "Indication": "Extensive-stage small-cell lung cancer (SCLC)",
            "Drug": "Alisertib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "The most common treatment-related grade \u22653 AEs were neutropenia, febrile neutropenia, and leukopenia.",
            "Other": "Preclinical data showed greater alisertib sensitivity in models with high c-Myc expression and/or loss of RB1 function. In a clinical study, c-Myc expression or mutation in RB1, RBL1, RBL2, or CDK6 showed strong correlation with improvement in both PFS and OS in the alisertib arm.",
            "Conclusion": "Findings are anticipated to identify a biomarker-defined patient population that derives the greatest clinical benefit from alisertib. Future development of alisertib in SCLC is anticipated to focus on this biomarker-defined population."
        },
        {
            "Title": "A phase II trial of monalizumab in combination with durvalumab (MEDI4736) plus platinum-based chemotherapy for first-line treatment of extensive-stage small cell lung cancer (MOZART): Hoosier Cancer Research Network LUN21-530 study.",
            "Indication": "Extensive-stage small-cell lung cancer (SCLC)",
            "Drug": "Monalizumab, Durvalumab, Platinum-based chemotherapy",
            "PFS": "Primary endpoints include 1-year progression-free survival.",
            "OS": "Secondary endpoints include 1-year overall survival.",
            "ORR": "Secondary endpoints include objective response rate.",
            "AE": "None",
            "Other": "Exploratory objectives include analyzing association of treatment efficacy with minimal residual disease status, blood- and tissue-based genomic and transcriptomic signatures, tumor infiltrating immune cells, and peripheral blood NK cell and CD8+ T cell activity.",
            "Conclusion": "The addition of monalizumab to standard of care first-line treatment may be associated with improved efficacy in patients with SCLC."
        },
        {
            "Title": "Tazemetostat in combination with topotecan and pembrolizumab in patients with recurrent small cell lung cancer.",
            "Indication": "Recurrent small-cell lung cancer (SCLC)",
            "Drug": "Tazemetostat, Topotecan, Pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The study involves collection of mandatory biopsies at pre-treatment and post-treatment (cycle 1) to gain insights into mechanism of action and resistance of the combination using single cell and spatial transcriptomic approaches.",
            "Conclusion": "The dose escalation cohort aims to determine safety and optimal doses of tazemetostat and topotecan (with standard dose of pembrolizumab) using a 3+3 design by assessing for dose limiting toxicities. The dose expansion cohort aims to assess safety, tolerability and preliminary efficacy of the combination in 15 additional patients with relapsed SCLC."
        },
        {
            "Title": "A phase II exploratory trial of adebrelimab in combination with chemotherapy and concurrent radiotherapy as a first-line treatment for oligometastatic extensive-stage small-cell lung cancer.",
            "Indication": "Oligometastatic extensive-stage small-cell lung cancer (ES-SCLC)",
            "Drug": "Adebrelimab, Carboplatin/Cisplatin, Etoposide",
            "PFS": "The primary endpoint is progression-free survival (PFS).",
            "OS": "Secondary endpoints include overall survival (OS), 6-month and 1-year PFS rates, 1-year and 2-year OS rates.",
            "ORR": "Secondary endpoints include objective response rate, duration of response.",
            "AE": "None",
            "Other": "The trial is under recruitment, and a total of 62 patients are planned to be enrolled.",
            "Conclusion": "The early addition of chest radiotherapy and stereotactic radiotherapy (SBRT) for metastatic lesions to first-line treatment with adebrelimab combined with chemotherapy may be expected to achieve local control, thereby improving survival benefits."
        },
        {
            "Title": "JCOG2002: A randomized phase III study of thoracic radiotherapy for extensive-stage small cell lung cancer",
            "Indication": "Extensive-stage small cell lung cancer (ES-SCLC)",
            "Drug": "Atezolizumab + carboplatin + etoposide or Durvalumab + cisplatin/carboplatin + etoposide",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The study aims to assess the superiority of additional cTRT in terms of OS for ES-SCLC following induction aPD-L1 plus platinum doublet chemotherapy. The primary endpoint is OS, with secondary endpoints being PFS and safety."
        },
        {
            "Title": "ICARUS-LUNG01: A phase 2 study of datopotomab deruxtecan (Dato-DXd) in patients with previously treated advanced non-small cell lung cancer (NSCLC), with sequential tissue biopsies and biomarkers analysis to predict treatment outcome.",
            "Indication": "Advanced non-small cell lung cancer (NSCLC)",
            "Drug": "Datopotomab deruxtecan (Dato-DXd)",
            "PFS": "Median PFS: 3.6 months [95% CI: 2.6-6.0]; NSQ: 4.8 months [95% CI: 2.6-6.1]; SQ: 2.9 months [95% CI: 1.9-3.5]",
            "OS": "Median OS: 11.9 months [95% CI: 7.5-14.6]; NSQ: 12.6 months [95% CI: 9.5-15.4]; SQ: 6.3 months [95% CI: 3.5-9.1]",
            "ORR": "Confirmed ORR: 28.0%; NSQ: 32.9%; SQ: 5.0%",
            "AE": "Grade \u22653 TRAEs: 25.0%; Most common TEAEs (any grade/\u22653): stomatitis (49.0%/10.0%), nausea (45.0%/0), confirmed ILD (1%)",
            "Other": "Disease control rate: 75.0%",
            "Conclusion": "In this heavily pretreated population, Dato-DXd showed similar efficacy and safety results to those reported in TROPION-Lung01. Patients with NSQ appeared to derive the greatest benefit. Translational analyses to determine biomarkers associated with response and/or resistance will be presented."
        },
        {
            "Title": "Sacituzumab tirumotecan (SKB264/MK-2870) in combination with KL-A167 (anti-PD-L1) as first-line treatment for patients with advanced NSCLC from the phase II OptiTROP-Lung01 study.",
            "Indication": "Advanced non-small cell lung cancer (NSCLC)",
            "Drug": "Sacituzumab tirumotecan (SKB264/MK-2870) + KL-A167",
            "PFS": "Cohort 1A: Median PFS: 15.4 months [95% CI: 6.7, NE]; 6-mo PFS rate: 69.2%; Cohort 1B: Median PFS: not reached; 6-mo PFS rate: 84.6%",
            "OS": "None",
            "ORR": "Cohort 1A: ORR: 48.6% (18/37, 2 pending confirmation); Cohort 1B: ORR: 77.6% (45/58, 5 pending confirmation)",
            "AE": "Most common Grade \u22653 TRAEs: neutrophil count decreased (30.0%/30.2%), white blood cell count decreased (5.0%/17.5%), anemia (5.0%/15.9%), rash (5.0%/6.3%), drug eruption (7.5%/0)",
            "Other": "DCR: Cohort 1A: 94.6%; Cohort 1B: 100%",
            "Conclusion": "SKB264 in combination with KL-A167 demonstrated promising efficacy results in treatment-naive advanced NSCLC with manageable safety profile. SKB264 Q2W was recommended for further investigation. A Phase 3 study of SKB264 Q2W plus pembrolizumab vs pembrolizumab in 1L metastatic NSCLC with PD-L1 TPS \u226550% (NCT06170788) is ongoing."
        },
        {
            "Title": "Amivantamab plus lazertinib vs osimertinib in first-line EGFR-mutant advanced non-small cell lung cancer (NSCLC) with biomarkers of high-risk disease: A secondary analysis from the phase 3 MARIPOSA study.",
            "Indication": "EGFR-mutant advanced non-small cell lung cancer (NSCLC)",
            "Drug": "Amivantamab plus lazertinib vs osimertinib",
            "PFS": "HR, 0.70; P, 0.001. Subgroup PFS: TP53 co-mutation: 18.2 vs 12.9 months (HR, 0.65; P=0.003), TP53 wild-type: HR, 0.75; P=0.11, Detectable baseline ctDNA by ddPCR: 20.3 vs 14.8 months (HR, 0.68; P=0.002), Cleared at C3D1: 24.0 vs 16.5 months (HR, 0.64; P=0.004), Not cleared at C3D1: 16.5 vs 9.1 months (HR, 0.48; P=0.014), Liver metastases at baseline: 18.2 vs 11.0 months (HR, 0.58; P=0.017)",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Ami+laz demonstrated significantly improved mPFS vs osi in pts with biomarkers of high-risk disease. Given these features can occur in up to 85% of pts, ami+laz represents an important new standard of care for treatment-na\u00efve EGFR-mutant advanced NSCLC."
        },
        {
            "Title": "NRG-LU002: Randomized phase II/III trial of maintenance systemic therapy versus local consolidative therapy (LCT) plus maintenance systemic therapy for limited metastatic non-small cell lung cancer (NSCLC).",
            "Indication": "Limited metastatic non-small cell lung cancer (NSCLC)",
            "Drug": "Local consolidative therapy (LCT) plus maintenance systemic therapy vs maintenance systemic therapy",
            "PFS": "HR, 0.93 (95% CI: 0.66, 1.31). 1-yr and 2-yr PFS rates: 48% (95% CI: 35.9, 59.0) and 36% (95% CI: 24.8, 47.2) in the maintenance systemic therapy arm, 52% (95% CI: 42.5, 59.8) and 40% (95% CI: 31.5, 48.6) in the LCT + maintenance systemic therapy arm (2-sided log-rank test p-value=0.66).",
            "OS": "HR, 1.05 (0.70, 1.56) among all patients and 1.05 (0.68, 1.63) among IO-treated patients.",
            "ORR": "None",
            "AE": "Overall grade 2 or higher toxicities: 73% vs 84%, Grade 3 or higher pneumonitis: 1% vs 10%",
            "Other": "None",
            "Conclusion": "LCT added to IO-based 1st line systemic therapy was associated with a PFS HR of 0.90. Reducing toxicity and increasing biologically-driven patient selection may optimize this therapeutic ratio."
        },
        {
            "Title": "Ivonescimab combined with chemotherapy in patients with EGFR-mutant non-squamous non-small cell lung cancer who progressed on EGFR tyrosine-kinase inhibitor treatment (HARMONi-A): A randomized, double-blind, multi-center, phase 3 trial.",
            "Indication": "EGFR-mutant non-squamous non-small cell lung cancer (NSCLC)",
            "Drug": "Ivonescimab combined with chemotherapy vs chemotherapy alone",
            "PFS": "HR 0.46 (0.34, 0.62), P < 0.0001. Median PFS (95% CI) by IRRC: 7.06 months (5.85, 8.74) in the ivonescimab arm vs 4.80 months (4.21, 5.55) in the chemotherapy arm. Subgroup PFS: Patients receiving ivonescimab over placebo: HR 0.48 (95% CI 0.35-0.66), Brain metastases: HR 0.40 (0.22-0.73), EGFR mutation of deletion 19: HR 0.48 (0.32-0.73), T790M mutation positive: HR 0.22 (0.09-0.54).",
            "OS": "None",
            "ORR": "50.6% in ivonescimab arm vs 35.4% in chemotherapy arm",
            "AE": "Grade \u22653 TEAEs: 61.5% in ivonescimab arm vs 49.1% in chemotherapy arm. Grade \u22653 immune-related adverse events: 6.2% in ivonescimab arm vs 2.5% in chemotherapy arm. Grade \u22653 VEGF blocking related adverse events: 3.1% in ivonescimab arm vs 2.5% in chemotherapy arm.",
            "Other": "None",
            "Conclusion": "Ivonescimab plus chemotherapy significantly improved PFS while maintaining a manageable safety profile in patients who had failed EGFR-TKIs treatments."
        },
        {
            "Title": "KRYSTAL-12: Phase 3 study of adagrasib versus docetaxel in patients with previously treated advanced/metastatic non-small cell lung cancer (NSCLC) harboring a KRAS G12C mutation.",
            "Indication": "Advanced/metastatic non-small cell lung cancer (NSCLC) with KRAS G12C mutation",
            "Drug": "Adagrasib versus docetaxel",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "Efficacy and safety of olomorasib (LY3537982), a second-generation KRAS G12C inhibitor (G12Ci), in combination with pembrolizumab in patients with KRAS G12C-mutant advanced NSCLC.",
            "Indication": "KRAS G12C-mutant advanced NSCLC",
            "Drug": "Olomorasib (LY3537982) in combination with pembrolizumab",
            "PFS": "Median PFS was not estimable (95% CI, 5-NE)",
            "OS": "None",
            "ORR": "ORR was 63% (95% CI, 44-80) in KRAS G12Ci-na\u00efve patients; ORR was 75% (9/12) in PD-L1 \u226550%, 56% (10/18) in PD-L1 <50%/unknown; ORR was 78% (95% CI, 40-97) in first-line patients",
            "AE": "TRAEs \u226510%: diarrhea (30%), ALT increased (20%), AST increased (18%), fatigue (14%), nausea (14%), pruritus (11%); Grade 3 TRAEs \u226510%: diarrhea (16%); pneumonitis seen in 3 patients (grades 2/3/4)",
            "Other": "DCR was 93% (95% CI, 78-99) in KRAS G12Ci-na\u00efve patients; DCR was 100% in first-line patients",
            "Conclusion": "Olomorasib (50 or 100 mg BID) in combination with pembrolizumab demonstrated favorable safety and antitumor activity in patients with KRAS G12C-mutant advanced NSCLC, supporting further development in first-line NSCLC. A global, registrational study investigating this combination in first-line NSCLC is currently enrolling (SUNRAY-01, NCT06119581)."
        },
        {
            "Title": "KROCUS: A phase II study investigating the efficacy and safety of fulzerasib (GFH925) in combination with cetuximab in patients with previously untreated advanced KRAS G12C mutated NSCLC.",
            "Indication": "Previously untreated advanced KRAS G12C mutated NSCLC",
            "Drug": "Fulzerasib (GFH925) in combination with cetuximab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "Sotorasib plus carboplatin and pemetrexed in KRAS G12C advanced NSCLC: Updated analysis from the international CodeBreaK 101 trial.",
            "Indication": "KRAS G12C-mutated advanced non-small cell lung cancer (NSCLC)",
            "Drug": "Sotorasib plus carboplatin and pemetrexed",
            "PFS": "1L: Median PFS was 10.8 months (95% CI, 5.4-NE); Median PFS was 11.9 months (95% CI, 5.3-NE) in the PD-L1 <1% subgroup; 2L+: Median PFS was 8.3 months (95% CI, 4.1-NE)",
            "OS": "None",
            "ORR": "1L: ORR was 65% (95% CI, 46.5-80.3); 2L+: ORR was 42% (95% CI, 20.3-66.5)",
            "AE": "TRAEs, any grade: 1L: 92%, 2L+: 95%, Total: 93%; Grade 3-4: 1L: 49%, 2L+: 57%, Total: 52%; Fatal: 1L: 0%, 2L+: 5%, Total: 2%; Grade \u22653 TRAE in >10% overall: Neutropenia/Neutrophil count decreased: 1L: 30%, 2L+: 29%, Total: 29%; Thrombocytopenia/Platelet count decreased: 1L: 16%, 2L+: 14%, Total: 16%; Anemia: 1L: 16%, 2L+: 10%, Total: 14%; TRAE leading to discontinuation of sotorasib/carboplatin/pemetrexed/any study drug: 1L: 5%/0%/19%/22%, 2L+: 14%/5%/24%/29%, Total: 9%/2%/24%",
            "Other": "DCR was 100% in 1L; DCR was 84% in 2L+; Median DOR was 9.1 months (95% CI, 4.4-12.5) in 1L; Median DOR was NE in 2L+",
            "Conclusion": "Sotorasib plus platinum doublet chemotherapy conferred robust and durable responses with a manageable safety profile in CodeBreaK 101, supporting evaluation of this regimen in the ongoing CodeBreaK 202 phase 3 trial in treatment-na\u00efve, PD-L1 negative, KRAS G12C-mutated advanced NSCLC (NCT05920356)."
        },
        {
            "Title": "A multinational pivotal study of sunvozertinib in platinum pretreated non-small cell lung cancer with EGFR exon 20 insertion mutations: Primary analysis of WU-KONG1 study",
            "Indication": "Non-small cell lung cancer (NSCLC) with EGFR exon 20 insertion mutations",
            "Drug": "Sunvozertinib",
            "PFS": "Not mature with approximately 6-month follow-up",
            "OS": "None",
            "ORR": "54.3% (41.0% confirmed and 5.8% pending confirmation)",
            "AE": "Diarrhea, skin rash, and CPK increase; majority were CTCAE grade 1 or 2 and clinically manageable",
            "Other": "Disease control rate was 90.8%; tumor response observed in patients with baseline brain metastasis",
            "Conclusion": "Sunvozertinib demonstrated promising antitumor efficacy in platinum pre-treated NSCLC with EGFR exon 20 insertion mutations, with a tolerable safety profile. The updated data will be presented at the meeting."
        },
        {
            "Title": "Phase Ia/Ib trial of zongertinib (BI 1810631), a HER2-specific tyrosine kinase inhibitor (TKI), in patients (pts) with HER2 aberration-positive solid tumors: Updated Phase Ia data from Beamion LUNG-1, including progression-free survival (PFS) data",
            "Indication": "HER2 aberration-positive solid tumors",
            "Drug": "Zongertinib",
            "PFS": "Median PFS was 8.7 months (95% CI: 5.5 months\u2500not evaluable [NE]) in all treated patients; in 36 evaluable patients with NSCLC, median PFS was NE (95% CI: 7.6 months\u2500NE)",
            "OS": "None",
            "ORR": "49% in Phase Ia; 73.9% in Phase Ib Cohort 1",
            "AE": "TRAEs (all/G3/G4/G5): 72/10/0/0% in Phase Ia; 67/5/5/0% in Phase Ib Cohort 1, most commonly diarrhea",
            "Other": "Disease control rate (DCR) was 91% in Phase Ia; 91.3% in Phase Ib Cohort 1; median duration of response was 12.7 months (95% CI: 4.2\u201312.7 months)",
            "Conclusion": "Zongertinib was well tolerated and demonstrated promising efficacy in patients with HER2 aberration-positive solid tumors, showing particular effectiveness in those with HER2-mutated NSCLC."
        },
        {
            "Title": "Efficacy and safety of erdafitinib in adults with NSCLC and prespecified fibroblast growth factor receptor alterations in the phase 2 open-label, single-arm RAGNAR trial",
            "Indication": "Non-small cell lung cancer (NSCLC) with prespecified fibroblast growth factor receptor (FGFR) alterations",
            "Drug": "Erdafitinib",
            "PFS": "4.1 months",
            "OS": "10.5 months",
            "ORR": "26% (95% CI 10-48)",
            "AE": "Diarrhea (65%), stomatitis (61%), dry mouth (44%), hyperphosphatemia (65%), dry skin (30%), and fatigue (22%); 26% had serious AEs; 9% discontinued due to AEs",
            "Other": "Disease control rate was 74% (95% CI 52-90); median duration of response was 4.6 months",
            "Conclusion": "Erdafitinib demonstrated clinically meaningful activity in pre-treated patients with NSCLC and pre-specified FGFR alterations. Safety data were consistent with erdafitinib's safety profile."
        },
        {
            "Title": "Amivantamab plus lazertinib in atypical EGFR-mutated advanced non-small cell lung cancer (NSCLC): Results from CHRYSALIS-2",
            "Indication": "Atypical EGFR-mutated advanced non-small cell lung cancer (NSCLC)",
            "Drug": "Amivantamab plus lazertinib",
            "PFS": "19.5 months (95% CI 11.0\u2013NE) in treatment-na\u00efve subset; 5.7 months (95% CI 4.2\u201310.7) in patients treated with prior afatinib",
            "OS": "None",
            "ORR": "51% (95% CI 41\u201361); 55% (95% CI 40\u201369) in treatment-na\u00efve subset; 45% (95% CI 29\u201362) in patients treated with prior afatinib",
            "AE": "Most common AEs were primarily EGFR- and MET-related toxicities, primarily grade 1-2; discontinuations due to treatment-related AEs occurred in 9% of patients; incidence of VTE was 30%",
            "Other": "Median duration of response (DoR) was not estimable (NE; 95% CI 9.9 months\u2013NE) in treatment-na\u00efve subset; 8.9 months (95% CI 2.8\u2013NE) in patients treated with prior afatinib",
            "Conclusion": "Amivantamab plus lazertinib demonstrated clinically meaningful and durable antitumor activity in patients who were treatment-na\u00efve or had disease progression on afatinib."
        },
        {
            "Title": "A phase 2 study of amivantamab plus lazertinib in patients with EGFR-mutant lung cancer and active central nervous system disease.",
            "Indication": "EGFR-mutant lung cancer with active central nervous system disease",
            "Drug": "Amivantamab plus lazertinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "{'BrM Cohort': {'Systemic ORR': '30% (95% CI: 13-54%)', 'Intracranial ORR': '40% (95% CI: 20-64%)', 'Median time on treatment': '3.9 months (range 0.3-18.6 months)'}, 'LM Cohort': {'Systemic ORR': '32% (95% CI: 15-55%)', 'Intracranial ORR': '23% for LM pts (95% CI: 9-46%)', 'Median time on treatment': '8.1 months (range 0-21.7 months)'}}",
            "AE": "Most frequent treatment-related adverse events (TRAEs) \u226530% in overall population: rash (71%), infusion-related reaction (59%), paronychia (43%), fatigue (40%), edema (40%), mucositis (33%), and nausea (33%). Most frequent \u22655% grade \u22653 TRAEs: infusion-related reactions (7%), thromboembolic event (5%), elevated AST/ALT (5%), and rash (5%).",
            "Other": "14 LM pts (64%) had decrease in CSF CTCs and 7 (32%) had improvement in neurologic symptoms. Three pts (7%) discontinued treatment due to TRAEs.",
            "Conclusion": "The combination of amivantamab + lazertinib is a promising treatment for pts with EGFR-mutant lung cancer and active CNS disease, with clinically meaningful time on treatment and radiographic response. This is the first clinical trial successfully completed in pts with EGFR-mutant lung cancer and progressive CNS metastases."
        },
        {
            "Title": "Safety and efficacy of osimertinib plus consolidative stereotactic ablative radiation (SABR) in advanced EGFR mutant non-small cell lung cancer (NSCLC): Results from a multi-center phase II trial.",
            "Indication": "Advanced EGFR mutant non-small cell lung cancer (NSCLC)",
            "Drug": "Osimertinib plus consolidative stereotactic ablative radiation (SABR)",
            "PFS": "Median PFS of 32.6 months",
            "OS": "Median OS of 45.7 months",
            "ORR": "None",
            "AE": "The incidence of the following: pain at the site of radiation, paronychia, liver enzyme elevation, fatigue, hyponatremia, diarrhea, and pneumonitis.",
            "Other": "None",
            "Conclusion": "Osimertinib plus consolidative SABR appears to provide a PFS and OS benefit when considered against historical data with osimertinib alone. This treatment strategy may offer a well-tolerated and practical approach to improving outcomes for patients with advanced EGFR mutant NSCLC."
        },
        {
            "Title": "Lorlatinib in TKI-na\u00efve, advanced ROS1-positive non-small-cell lung cancer: A multicenter, open-label, single-arm, phase 2 trial.",
            "Indication": "Advanced ROS1-positive non-small-cell lung cancer (NSCLC)",
            "Drug": "Lorlatinib",
            "PFS": "Median PFS of 35.8 months (95% CI: Not Reached [NR] to NR)",
            "OS": "NR",
            "ORR": "69% (95% CI: 52 to 83)",
            "AE": "The most common 3\u20134 grade treatment-related adverse events were hypertriglyceridemia (25%) and hypercholesterolemia (16%). No treatment-related deaths were reported.",
            "Other": "None",
            "Conclusion": "Lorlatinib showed durable clinical activity in patients with TKI-na\u00efve, advanced ROS1-positive NSCLC including those with CNS metastases. Adverse events were mainly of low grade and compatible with long-term administration. Due to its limited treatment options, lorlatinib could be integrated into an earlier stage of treatment for ROS1-positive NSCLC."
        },
        {
            "Title": "Efficacy and safety of taletrectinib in patients with advanced or metastatic ROS1+ non\u2013small cell lung cancer: The phase 2 TRUST-I study.",
            "Indication": "Advanced or metastatic ROS1+ non\u2013small cell lung cancer (NSCLC)",
            "Drug": "Taletrectinib",
            "PFS": "{'TKI-Na\u00efve': 'Median PFS of 23.5 months', 'TKI-Pretreated': 'Median PFS of 9.7 months'}",
            "OS": "None",
            "ORR": "{'TKI-Na\u00efve': '91% (95% CI: 83, 95)', 'TKI-Pretreated': '52% (95% CI: 40, 65)'}",
            "AE": "The most frequent treatment-emergent adverse events (TEAEs) were AST increase (76%), diarrhea (70%), and ALT increase (68%). Rates of neurologic TEAEs were low (dizziness: 23%; dysgeusia: 10%) and mostly grade 1. Discontinuation (5%) and dose reductions (19%) due to TEAEs were low.",
            "Other": "In patients with G2032R mutation, 67% (95% CI: 35, 90) responded. Duration of Response (DoR) and PFS remain durable.",
            "Conclusion": "With longer follow-up, taletrectinib continues to show high and durable overall responses, robust intracranial and G2032R activity, and a favorable safety profile with a low incidence of neurologic AEs. An additional ongoing pivotal phase 2 study, TRUST-II, is further evaluating the efficacy and safety of taletrectinib in patients in the US, Europe, and Asia."
        },
        {
            "Title": "Efficacy and safety of sigvotatugvedotin, an investigational ADC, in NSCLC: Updated phase 1 results (SGNB6A-001)",
            "Indication": "Non-Small Cell Lung Cancer (NSCLC)",
            "Drug": "Sigvotatugvedotin (SV)",
            "PFS": "3.8 months (95% CI, 2.1+, 4.9+)",
            "OS": "None",
            "ORR": "19.5% (95% CI, 12.6-28.0) in all patients with NSCLC; 32.5% (95% CI, 18.6-49.1) in patients with non-squamous/taxane-naive NSCLC",
            "AE": "98.2% TEAEs, 46.0% \u2265G3 TEAEs, 33.6% SAEs, 13.3% TEAEs leading to discontinuation",
            "Other": "Most common \u2265G3 TEAEs in NSCLC: dyspnea (9.7%), fatigue (7.1%), neutropenia (5.3%). One treatment-related death (pneumonitis).",
            "Conclusion": "SV continues to demonstrate encouraging antitumor activity and a manageable safety profile in patients with NSCLC. Data at 1.8 mg/kg AiBW 2Q4W support initiation of the Ph3 SGNB6A-002 study in 2/3L NSCLC and combination with pembrolizumab in earlier settings."
        },
        {
            "Title": "Repotrectinib in tyrosine kinase inhibitor (TKI)-na\u00efve patients (pts) with advanced ROS1 fusion-positive (ROS1+) NSCLC in the phase 1/2 TRIDENT-1 trial: Clinical update, treatment beyond progression and subsequent therapies",
            "Indication": "ROS1 fusion-positive Non-Small Cell Lung Cancer (NSCLC)",
            "Drug": "Repotrectinib",
            "PFS": "35.7 months (95% CI, 24.6\u2013NE)",
            "OS": "None",
            "ORR": "79% (95% CI, 68\u201388)",
            "AE": "57% grade \u22653 treatment-emergent adverse events, 29% treatment-related adverse events. Treatment-emergent dizziness occurred in 63% of patients, mostly grade 1-2.",
            "Other": "Median DOR was 34.1 months (95% CI, 27.4\u2013NE); 70% of responders maintained a response of \u226524 months. Intracranial ORR was 89% (95% CI, 52\u2013100).",
            "Conclusion": "With a median follow-up of ~3 years in TRIDENT-1, repotrectinib continued to demonstrate durable clinical activity in ROS1 TKI-na\u00efve patients. Progression patterns and treatment beyond progression were described for the first time."
        },
        {
            "Title": "Survival and mutational differences based on ESR1 and ESR2 expression in non-small cell lung cancer (NSCLC).",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "Not specified",
            "PFS": "None",
            "OS": "ESR1-H had longer median OS vs. ESR1-L (22 months vs. 16 months, p < .001); ESR2-H had longer median OS vs. ESR2-L (23 months vs. 15 months, p < .001); ESR1-H/ESR2-H had the longest OS (25 months) followed by ESR1-H/ESR2-L (18 months), ESR1-L/ESR2-H (16 months), and ESR1-L/ESR2-L (14 months, p < .001).",
            "ORR": "None",
            "AE": "None",
            "Other": "In EGFR-Mt, significant difference in survival since treatment (SST) with osimertinib for ESR1-L/ESR2-H (40 months, N=13), ESR1-H/ESR2-L (36 months, N=62), ESR1-H/ESR2-H (34 months, N=161), and ESR1-L/ESR2-L (30 months, N=138, p = .03). In KRASG12C-Mt, significant difference in SST with sotorasib; ESR1-H/ESR2-H had the longest SST (median not reached, N=17), followed by ESR1-H/ESR2-L (17 months, N=17), ESR1-L/ESR2-L (13 months, N=34), and ESR1-L/ESR2-H (1 month, N=1, p = .002).",
            "Conclusion": "ESR1-H had a higher percentage of females, AD, and EGFR/KRAS-mt vs. ESR1-L while ESR2-H had no sex difference, more SCC, and fewer EGFR/KRAS-mt vs. ESR2-L. ESR1-H/ESR2-H tumors had the highest MPAS and longest OS, and there were SST differences with EGFR and KRASG12C inhibition. ESR1 & 2 may play key roles in activating the MAPK pathway and future trials could consider targeted therapy combined with ER inhibition based on ESR1 & 2 expression."
        },
        {
            "Title": "Association of napsin A-specific T cell clonotypes with durable clinical benefit to immunotherapy in patients with metastatic NSCLC.",
            "Indication": "Metastatic non-small cell lung cancer (NSCLC)",
            "Drug": "Anti-PD-1 (alone or in combination)",
            "PFS": "None",
            "OS": "Patients with a higher frequency of Napsin A-specific TCRs had a significant improvement in OS compared to patients with a lower frequency of these clonotypes (p = 0.006 pre-treatment; p = 0.016 post-treatment).",
            "ORR": "None",
            "AE": "None",
            "Other": "Napsin A-specific clonotypes were significantly enriched within 3 months after treatment in patients achieving durable clinical benefit (DCB) from ICI compared to patients without DCB (p = 0.037 post-treatment, n = 41).",
            "Conclusion": "A higher frequency of Napsin A-specific T cell clonotypes is associated with DCB and improved OS with ICI in patients with NSCLC. T cell immune responses recognizing the tumor antigen Napsin A may play a role in shaping ICI responses, a finding that warrants further investigation in larger datasets."
        },
        {
            "Title": "Gene expression correlates of adagrasib response in KRASG12C mutated non-small cell lung cancer (NSCLC)",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "Adagrasib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Expression of the AST plasticity signature and KRT6A at baseline correlates with poor adagrasib responses.",
            "Conclusion": "KRASG12C mutated lung adenocarcinoma patients with a higher expression of squamous cell carcinoma gene expression signature respond poorly to adagrasib treatment. These data indicate the role of AST in KRAS inhibitor resistance and provide predictive biomarkers for KRAS-targeted therapies in lung cancer."
        },
        {
            "Title": "Identifying digital pathology imaging based biomarkers of checkpoint inhibitor response in non-small cell lung cancer",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "Immune checkpoint inhibitors (ICIs)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Our multi-task attention-based approach achieves an overall predictive accuracy of 79%, precision of 0.53, recall of 0.24, and an F1 score of 0.33 on the held-out test set.",
            "Conclusion": "Initial findings indicate that attention-based multitask learning of NSCLC H&E-images could uncover crucial tumor intrinsic and microenvironmental features that are predictive of ICI responses in patients. Inclusion of additional clinical and molecular data for training and validation holds potential to further improve ICI response prediction accuracy using AI-derived biomarkers and classifiers."
        },
        {
            "Title": "PD-L1 and alternative immune checkpoints (AICs) in non-small cell lung cancer (NSCLC): Insights into the tumor immune microenvironment (TIME) and immunotherapy (IO) outcomes",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "Pembrolizumab",
            "PFS": "None",
            "OS": "Real-world overall survival (rwOS): PD-L1 IHC+ NSCLC and cases with high PD-L1 RNA and low B7H3 RNA were associated with longer rwOS.",
            "ORR": "None",
            "AE": "None",
            "Other": "Time on treatment (TOT): PD-L1 IHC+ NSCLC and cases with high PD-L1 RNA and low B7H3 RNA were associated with longer TOT.",
            "Conclusion": "Both PD-L1 IHC+ NSCLC and cases with high PD-L1 RNA expression are associated with a more inflammatory TIME, and superior survival and IO outcomes. The distinct expression profiles of AICs identified in our study may represent key subtypes mediating IO response in WT and EGFR mutant NSCLC."
        },
        {
            "Title": "Phase 2 study of pembrolizumab plus pemetrexed for older patients with non-squamous non-small cell lung cancer with PD-L1 tumor proportion score of less than 50%: CJLSG1901.",
            "Indication": "Non-squamous non-small cell lung cancer (NSCLC) with PD-L1 tumor proportion score of less than 50%",
            "Drug": "Pembrolizumab plus pemetrexed",
            "PFS": "Median PFS was 7.6 months (95% CI 4.8-16.2)",
            "OS": "Median OS was 19.4 months (95% CI 11.8-not reached)",
            "ORR": "36.7% (95% CI 23.4-51.7)",
            "AE": "Most common grade 3 or 4 adverse events were neutropenia (31.3%), leukopenia (20.8%), and anemia (12.5%). Serious adverse events were observed in 14 patients (4 with pneumonitis, 2 with decreased appetite, 2 with febrile neutropenia). No treatment-related deaths were observed.",
            "Other": "Disease control rate was 65.3% (95% CI 50.4-78.3%)",
            "Conclusion": "Pembrolizumab plus pemetrexed demonstrated an ORR of 36.7% and met the primary endpoint. The safety profile was tolerable and manageable. This combination therapy is a promising candidate as a novel first-line treatment strategy for older patients with metastatic non-squamous NSCLC."
        },
        {
            "Title": "Phase II study of combination of radiotherapy with pembrolizumab plus chemotherapy in patients with previously untreated metastatic non-small cell lung cancer: North Japan Lung Cancer Study Group 1902.",
            "Indication": "Metastatic non-small cell lung cancer (NSCLC)",
            "Drug": "Pembrolizumab plus chemotherapy",
            "PFS": "Median PFS was 8.4 months (95% CI, 5.7-22.2)",
            "OS": "Median OS was 30.1 months (95% CI, 22.3-not reached)",
            "ORR": "67.6%",
            "AE": "Grade 3 or 4 adverse events (AEs) occurred in 65.8% of patients, with one treatment-related death (pneumonitis). The most common grade \u22653 AEs were neutropenia, leukopenia, and anemia. Pneumonitis was reported in 26.3% of patients, with two patients having grade 3 and one patient having grade 5.",
            "Other": "Disease control rate (DCR) was 89.2%.",
            "Conclusion": "The addition of RT to pembrolizumab plus chemotherapy showed promising efficacy for patients with previously untreated metastatic NSCLC, although caution should be exercised with regard to pneumonitis."
        },
        {
            "Title": "Phase I clinical trial of trametinib plus anlotinib in non-G12C KRAS-mutant non-small cell lung cancer: A new strategy.",
            "Indication": "Non-G12C KRAS-mutant non-small cell lung cancer (NSCLC)",
            "Drug": "Trametinib plus anlotinib",
            "PFS": "Part A: 207 days; Part B: 330 days; Integrated analysis: 300 days",
            "OS": "None",
            "ORR": "Part A: 69.2%; Part B: 65%; Integrated analysis: 66.7%",
            "AE": "Part A: 23% (grade \u22653); Part B: 35% (grade \u22653); Integrated analysis: 30% (grade \u22653)",
            "Other": "Disease control rate (DCR) was 92% in Part A, 100% in Part B, and 97% in the integrated analysis.",
            "Conclusion": "This study provides a potential combinational therapeutic strategy for non-G12C KRAS-mutant lung cancer patients via oral administration of trametinib and anlotinib."
        },
        {
            "Title": "Trastuzumab deruxtecan (T-DXd) in patients with HER2-mutant metastatic non-small cell lung cancer (mNSCLC): Final analysis results of DESTINY-Lung02.",
            "Indication": "HER2-mutant metastatic non-small cell lung cancer (mNSCLC)",
            "Drug": "Trastuzumab deruxtecan (T-DXd)",
            "PFS": "T-DXd 5.4 mg/kg: 10.0 months (95% CI, 7.7-15.2); T-DXd 6.4 mg/kg: 12.9 months (95% CI, 7.2-16.7)",
            "OS": "T-DXd 5.4 mg/kg: 19.0 months (95% CI, 14.7-NE); T-DXd 6.4 mg/kg: 17.3 months (95% CI, 13.8-NE)",
            "ORR": "T-DXd 5.4 mg/kg: 50.0% (95% CI, 39.9-60.1); T-DXd 6.4 mg/kg: 56.0% (95% CI, 41.3-70.0)",
            "AE": "Drug-related grade \u22653 treatment-emergent adverse events (TEAEs) were reported in 39.6% (T-DXd 5.4 mg/kg) and 60.0% (T-DXd 6.4 mg/kg) of patients. Adjudicated drug-related interstitial lung disease (ILD)/pneumonitis was reported in 14.9% (T-DXd 5.4 mg/kg) and 32.0% (T-DXd 6.4 mg/kg) of patients.",
            "Other": "Median duration of response (DoR) was 12.6 months (95% CI, 6.4-NE) for T-DXd 5.4 mg/kg and 12.2 months (95% CI, 7.0-NE) for T-DXd 6.4 mg/kg.",
            "Conclusion": "T-DXd 5.4 and 6.4 mg/kg continued to show strong and durable responses, consistent with the primary analysis, and no significant changes in toxicity were observed with longer follow-up. The approved T-DXd 5.4 mg/kg dose was associated with a more favorable benefit-risk profile and a reduced incidence of adjudicated drug-related ILD/pneumonitis."
        },
        {
            "Title": "A phase II trial of AZD1775 plus carboplatin-paclitaxel in squamous cell lung cancer (SqCLC)",
            "Indication": "Squamous cell lung cancer (SqCLC)",
            "Drug": "AZD1775 (Adavosertib) plus carboplatin-paclitaxel",
            "PFS": "4.8 months (80% CI, 4.1 to 5.7)",
            "OS": "7.3 months (80% CI, 6.4 to 8.5)",
            "ORR": "None",
            "AE": "88% of patients experienced AEs, with 39% experiencing grade 3 AEs. The most common AEs were fatigue (61%), diarrhea (51%), and nausea (49%).",
            "Other": "DCR was 60.9% with a median DoR of 4.44 months (80% CI, 3.25 to 6.94) and a median DSD of 3.5 months (80% CI, 3.0 to 4.9).",
            "Conclusion": "The study met its primary endpoint with a median PFS of 4.8 months with the combination of Adavosertib with carboplatin and paclitaxel. However, the study regimen achieved similar response rates and median PFS of platinum-doublet combination when comparing to historical data from phase II/III trials. Although the combination was safe, over a third of patients experienced grade 3 AEs, and 10% required dose reductions or delays due to AEs."
        },
        {
            "Title": "Mefatinib as first-line treatment of EGFR sensitizing mutation-positive non-small-cell lung cancer: A phase III efficacy and biomarker study",
            "Indication": "EGFR sensitizing mutation-positive non-small-cell lung cancer (NSCLC)",
            "Drug": "Mefatinib",
            "PFS": "13.73 vs. 9.66 months, HR=0.68, P=0.0024; 13.73 vs. 8.28 months in L858R subgroup, HR=0.55",
            "OS": "Not reached vs. 26.68 months, HR=0.54, P=0.0045; Not reached vs. 20.01 months in L858R subgroup, HR=0.40, P=0.0006",
            "ORR": "None",
            "AE": "5.8% of patients experienced dose reduction and less than 5% discontinued treatment.",
            "Other": "TP53 mutations were the most common disruptive concurrent alterations (45%). In patients with co-occurring disruptive mutations, mefatinib showed significant improvement in PFS compared to gefitinib (12.55 vs 9.56 months, HR=0.56, P=0.0005; 12.55 vs 12.39 months, HR=0.65, P=0.0753 in 19del; and 13.70 vs 8.28 months, HR=0.44, P=0.0004 in L858R).",
            "Conclusion": "Mefatinib is a new frontline option for EGFR mutant NSCLC. Co-occurring disruptive mutations caused decreased therapeutic efficacy of gefitinib, but had minimal impact on the PFS and OS of mefatinib. Comparing to gefitinib, PFS and OS improvements of mefatinib were most pronounced in patients with EGFR L858R mutation, especially those with disruptive co-occurring mutations. EGFR 19del or L858R mutation alone may not be sufficient to inform optimized frontline therapies. Further studies are warranted to verify functional concurrent mutations for a better individualized precision medicine."
        },
        {
            "Title": "Intracranial outcomes of 1L selpercatinib in advanced RET fusion-positive NSCLC: LIBRETTO-431 study",
            "Indication": "Advanced RET fusion-positive non-small cell lung cancer (NSCLC)",
            "Drug": "Selpercatinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Selpercatinib delayed CNS PD as evidenced by a lower 12-month cumulative incidence rate (CIR) for CNS PD, as well as delaying non-CNS PD compared to control in patients with and without brain metastases. In patients with measurable brain metastases at baseline, median time to IC response per RECIST 1.1 was similar between selpercatinib and control (1.4 months [range: 1.2-2.9] vs 1.6 months [range: 1.2-2.9]); however, IC response rates were higher (82% vs 58%) and more durable (12-month DOR rate 76% vs 63%) with selpercatinib vs control.",
            "Conclusion": "Selpercatinib delayed IC progression in advanced RET+ NSCLC patients with or without baseline brain metastases and achieved higher IC response rates compared to chemotherapy + pembrolizumab. LIBRETTO-431 is the first study to demonstrate IC efficacy improvement of a targeted therapy vs chemo/IO in a biomarker-selected NSCLC population. These data further support selpercatinib as the preferred 1L regimen in patients with advanced RET+ NSCLC."
        },
        {
            "Title": "Datopotamab deruxtecan (Dato-DXd) in Chinese patients (pts) with advanced or metastatic non-small cell lung cancer (NSCLC): Results from the phase 1/2 TROPION-PanTumor02 study",
            "Indication": "Advanced or metastatic non-small cell lung cancer (NSCLC)",
            "Drug": "Datopotamab deruxtecan (Dato-DXd)",
            "PFS": "7.4 months (5.7\u2013not calculable [NC]) overall; 9.6 months (7.1\u2013NC) in the non-squamous subgroup; 5.5 months (1.4\u2013NC) in the squamous subgroup",
            "OS": "None",
            "ORR": "45.0% overall; 56.5% in the non-squamous subgroup; 29.4% in the squamous subgroup; 75.0% in patients with EGFR mutations",
            "AE": "95.0% of patients experienced TEAEs; grade \u22653 TEAEs occurred in 57.5%. The most common TEAEs were nausea (62.5%), stomatitis (57.5%), and anemia (57.5%). TEAEs led to dose reduction/discontinuation in 20.0%/10.0% of patients.",
            "Other": "Median duration of confirmed response was 8.3 months, and median time to onset of response from first dose was 1.4 months. No adjudicated drug-related interstitial lung disease was reported.",
            "Conclusion": "Dato-DXd showed encouraging efficacy in Chinese patients with advanced or metastatic NSCLC; the non-squamous subgroup appeared to derive the most benefit. The efficacy/safety profile was consistent with previous studies, with no new safety signals observed."
        },
        {
            "Title": "Efficacy and clinicogenomic biomarkers of response to dual versus single agent checkpoint inhibitor therapy in untreated metastatic non-small cell lung cancer",
            "Indication": "Metastatic non-small cell lung cancer (mNSCLC)",
            "Drug": "Dual immune checkpoint inhibitor (ICI) therapy vs single-agent PD-(L)1 inhibitor alone (ICI-mono) or with chemotherapy (ICI-chemo)",
            "PFS": "ICI-mono: HR=0.8, p=0.041; ICI-chemo: HR=0.9, p=0.076; Dual ICI vs ICI-chemo with bone mets: HR=0.34, p=0.0029; Dual ICI vs ICI-mono with bone mets: HR=0.45, p=0.041",
            "OS": "ICI-mono: HR=0.8, p=0.011; ICI-chemo: HR=0.7, p=4.7e-05; Dual ICI vs ICI-mono: HR=1.0, p=0.89; Dual ICI vs ICI-chemo: HR=0.7, p=0.11",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Dual ICI therapy improved overall survival compared to ICI+/- chemo among patients without response to treatment. Dual ICI therapy increased time to progression in patients with bone mets and squamous histology."
        },
        {
            "Title": "Comparison of ICI plus chemotherapy or ipilimumab plus nivolumab based therapy for patients with NSCLC with TPS (1-49%): TOPGAN 2023-01",
            "Indication": "Non-small cell lung cancer (NSCLC) with PD-L1 tumor proportion score (TPS) 1-49%",
            "Drug": "ICI plus chemotherapy (ICI-chemo) vs ipilimumab plus nivolumab-based therapy (I-N)",
            "PFS": "ICI-chemo vs I-N: HR=0.72, 95% CI, 0.52-1.00, p=0.05",
            "OS": "ICI-chemo: 21.0 months (95% CI, 16.7\u201327.5); I-N: 20.0 months (95% CI, 15.1\u2013not reached); ICI-chemo vs I-N: HR=0.83, 95% CI, 0.54-1.26, p=0.38; ICI-chemo vs I-N for PD-L1 TPS 25-49%: HR=0.30, 95% CI, 0.09\u20130.85, p=0.02",
            "ORR": "None",
            "AE": "Treatment discontinuation due to adverse events: ICI-chemo 26.2%, I-N 41.9%; Treatment related death: ICI-chemo 4.5%, I-N 3.2%; Pneumonitis: ICI-chemo 13.9%, I-N 17.6%",
            "Other": "None",
            "Conclusion": "In real-world data for NSCLC with PD-L1 TPS 1-49%, there were no significant differences in both OS and PFS between ICI-chemo group and I-N group. Toxicity discontinuation rate was higher in I-N group. Based on the subgroup-analysis results, ICI-chemo might be better for NSCLC with PD-L1 TPS 25-49%."
        },
        {
            "Title": "A multicenter, prospective phase II trial of first-line osimertinib treatment for patients with poor performance status and EGFR mutation\u2013positive non-small cell lung cancer (OPEN/TORG2040)",
            "Indication": "EGFR mutation-positive non-small cell lung cancer (NSCLC) with poor performance status (PS)",
            "Drug": "Osimertinib",
            "PFS": "Median PFS: 8.0 months (95% CI, 5.6\u201320.4)",
            "OS": "Median OS: 25.4 months (95% CI, 15.6\u2013not available)",
            "ORR": "63.3% (n=19/30, 90% confidence interval [CI], 46.7\u201377.9; one-sided exact p=0.033)",
            "AE": "Serious adverse events leading to discontinuation: 27% (6 patients with any grade of interstitial lung disease (ILD), 1 with grade 4 AST/ALT increased, 1 with grade 3 diarrhea, 1 with grade 3 nausea, 1 with grade 3 rash)",
            "Other": "PS improvement rate: 63.3% (n=19/30), PS improved (Wilcoxon signed-rank test p < .001)",
            "Conclusion": "Osimertinib treatment is effective in patients with EGFR mutation-positive NSCLC, even those with poor PS and limited treatment options, but the risk of adverse events must be adequately managed, especially ILD."
        },
        {
            "Title": "Phase Ib clinical study of first-line treatment with gefitinib in combination with bevacizumab and chemotherapy in advanced non-squamous non-small cell lung cancer with EGFR mutation.",
            "Indication": "Advanced non-squamous non-small cell lung cancer (NSCLC) with EGFR mutation",
            "Drug": "Gefitinib in combination with bevacizumab, pemetrexed, and carboplatin",
            "PFS": "28 months (95% CI, 20.4-35.6)",
            "OS": "Not reached",
            "ORR": "95% (95% CI, 75.1%-99.9%)",
            "AE": "Grade \u22653 adverse events: 65%, including neutropenia (30%), thrombocytopenia (20%), nausea (20%), skin rash (20%), bleeding (10%), increased ALT (10%)",
            "Other": "Disease Control Rate (DCR): 100% (95% CI, 83.2%-100%), Duration of Response (DOR): 26.4 months (95% CI, 18.9-33.9)",
            "Conclusion": "The results of this study demonstrate that the four-drug combination regimen, led by gefitinib, is well-tolerated and effective for patients with EGFR-mutated advanced non-squamous NSCLC."
        },
        {
            "Title": "A phase-1 dose escalation and expansion study of EP0031, a next-generation selective RET inhibitor (SRI), in patients with SRI-na\u00efve or pre-treated advanced RET-altered NSCLC and other tumors.",
            "Indication": "Advanced RET-altered NSCLC, medullary thyroid cancer (MTC), and other solid tumors",
            "Drug": "EP0031",
            "PFS": "None",
            "OS": "None",
            "ORR": "In 8 response-evaluable NSCLC patients: 4 confirmed PRs (cPR) in 7 SRI pre-treated patients, 2 complete responses (cCR) in the brain, 1 cCR in SRI-na\u00efve patient. In 8 response-evaluable MTC patients: 2 cPRs and 1 SD in 3 SRI-na\u00efve patients, 2 of 5 SRI pre-treated patients achieved SD. In 5 response-evaluable patients with other tumors: 2 SDs in patients with pancreatic and papillary thyroid cancer, both SRI pre-treated.",
            "AE": "Most frequent G1/2 TEAEs (\u226515%): constipation, headache, ALT/AST, anemia, blurred vision, dizziness. G3 TEAEs (\u22655%): hyponatremia, anemia, hypertension, diarrhea.",
            "Other": "Dose interruptions, reductions, and discontinuations were seen in 8, 3, and 1 patient, respectively. Plasma exposures increased proportionately with doses up to 120 mg QD. 90 mg QD was selected as the RP2D.",
            "Conclusion": "These data add significantly to the large clinical dataset reported for EP0031 from a trial of over 130 patients in China, most with advanced RET fusion+ve NSCLC. EP0031 has a manageable toxicity profile with promising activity in advanced NSCLC previously treated with or na\u00efve to SRI, including those with brain metastases. This study is continuing at pace with Phase 2 expansion cohorts in SRI pre-treated and na\u00efve patients."
        },
        {
            "Title": "Efficacy and safety of vebreltinib in patients with advanced NSCLC harboring MET exon 14-skipping: Results of 2.5-year follow-up in KUNPENG.",
            "Indication": "Non-small cell lung cancer (NSCLC) with MET exon 14 skipping mutations",
            "Drug": "Vebreltinib",
            "PFS": "14.3 months (95% CI 6.4-18.2)",
            "OS": "20.3 months (95% CI 16.2-29.7)",
            "ORR": "75% (95% CI: 61.1-86.0)",
            "AE": "Peripheral edema (56.3%), hypoalbuminemia (27.4%), hypoproteinemia (25.9%), anemia (20.7%)",
            "Other": "Disease control rate (DCR): 96.2% (95% CI 86.8-99.5), Median duration of response (DoR): 16.5 months (95% CI 9.2-19.4), Median time to response (TTR): 1.0 month (95% CI 1.0-2.8)",
            "Conclusion": "Vebreltinib consistently showed promising efficacy and favorable safety in NSCLC patients with MET exon 14 mutations."
        },
        {
            "Title": "MYTX-011 in patients with previously treated locally advanced or metastatic NSCLC: Initial dose escalation results in the phase 1 KisMET-01 study.",
            "Indication": "Previously treated locally advanced or metastatic non-small cell lung cancer (NSCLC)",
            "Drug": "MYTX-011",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Corneal changes (30%), blurred vision (26%), fatigue (26%), dry eyes (19%), dyspnea (19%)",
            "Other": "Preliminary anti-tumor activity observed in non-SqCC and SqCC histology, and across cMET expression levels. Preliminary PK showed nearly dose proportional exposure and a half-life that supports dosing every 3 weeks.",
            "Conclusion": "MYTX-011 has been well tolerated with low rates of common MMAE or MET targeted therapy-associated AEs. Preliminary anti-tumor activity and PK support the development of MYTX-011 in a wide range of cMET-expressing NSCLC patients."
        },
        {
            "Title": "Sotorasib plus panitumumab for pre-treated non-small cell lung cancer with KRAS G12C mutation: A phase 1b study.",
            "Indication": "Pre-treated advanced non-small cell lung cancer (NSCLC) with KRAS G12C mutation",
            "Drug": "Sotorasib plus panitumumab",
            "PFS": "5.8 months (median follow-up 14.0 months)",
            "OS": "None (data not yet mature)",
            "ORR": "45% (all partial responses)",
            "AE": "Diarrhea (48%), dermatitis acneiform (45%), dry skin (40%), increased ALT (18%), increased AST (10%), dermatitis acneiform (13%), diarrhea (8%), hypomagnesemia (5%)",
            "Other": "Disease control rate (DCR): 90%, Median time to response (TTR): 1.0 month (95% CI 1.0-2.8)",
            "Conclusion": "This cohort of sotorasib plus panitumumab in pre-treated patients with KRAS G12C advanced NSCLC demonstrated safety consistent with each drug\u2019s profile. The ORR appears promising in this pretreated population and may warrant further investigation."
        },
        {
            "Title": "Five-year outcomes with first-line (1L) nivolumab + ipilimumab + chemotherapy (N+I+C) vs C in patients (pts) with metastatic NSCLC (mNSCLC) in CheckMate 9LA.",
            "Indication": "Metastatic non-small cell lung cancer (mNSCLC)",
            "Drug": "Nivolumab + ipilimumab + chemotherapy (N+I+C)",
            "PFS": "None",
            "OS": "Median OS: 15.8 months (N+I+C) vs 11.0 months (C)",
            "ORR": "N+I+C: 38%, C: 25%",
            "AE": "None",
            "Other": "5-year OS rates: 18% (N+I+C) vs 11% (C), 5-year PFS rates: 11% (N+I+C) vs 3% (C), 5-year DOR rates: 25% (N+I+C) vs 0% (C) in PD-L1 <1% subgroup",
            "Conclusion": "1L N+I+C maintained long-term, durable survival benefit vs C in pts with mNSCLC at this 5-year follow-up, particularly in pts with tumor PD-L1 expression <1% or squamous histology. N+I+C increased 5-year survivorship, with improved PFS and TFI in 5-year survivors over C. These data further underscore 1L N+I+C as an efficacious treatment option for pts with mNSCLC."
        },
        {
            "Title": "Plasma ctDNA biomarker study in patients with non-small cell lung cancer with EGFR exon 20 insertion mutation treated with sunvozertinib.",
            "Indication": "Non-small cell lung cancer (NSCLC) with EGFR exon 20 insertion mutation",
            "Drug": "Sunvozertinib",
            "PFS": "7.4 months vs. 5.5 months",
            "OS": "None",
            "ORR": "68.0% vs. 45.8%",
            "AE": "None",
            "Other": "Positive correlation between detectable EGFR exon 20 ins and higher number of metastasis sites. Higher EGFR exon 20 ins abundance correlated with more metastasis sites and brain metastasis.",
            "Conclusion": "Positive EGFR exon 20 ins in plasma ctDNA is associated with advanced disease characteristics. Sunvozertinib can effectively clear EGFR exon 20 ins in plasma ctDNA, confirming its direct effect on the EGFR pathway. Resistance to sunvozertinib can be through EGFR-dependent and EGFR-independent mechanisms."
        },
        {
            "Title": "Phase II study of multidisciplinary therapy combined with pembrolizumab for patients with synchronous oligometastatic non-small cell lung cancer TRAP-OLIGO study (WJOG11118L): Feasibility analysis of induction therapy and local ablative therapy.",
            "Indication": "Synchronous oligometastatic non-small cell lung cancer (NSCLC)",
            "Drug": "Pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "70%",
            "AE": "70% grade 3/4 adverse events including neutropenia (17%), anemia (7%), and thrombocytopenia (7%)",
            "Other": "None",
            "Conclusion": "Results of the TRAP-OLIGO study showed that multimodality treatment with platinum-based chemotherapy plus pembrolizumab and LAT had favorable implementation, and planned LAT was feasible in this population."
        },
        {
            "Title": "Osimertinib, platinum, etoposide as initial treatment for patients with EGFR mutant lung cancers with TP53 and RB1 alterations.",
            "Indication": "EGFR mutant lung cancers with TP53 and RB1 alterations",
            "Drug": "Osimertinib, platinum, etoposide",
            "PFS": "15.6 months",
            "OS": "37.9 months",
            "ORR": "82%",
            "AE": "None",
            "Other": "46% of patients experienced small cell transformation. Among transformed patients, all had high Ki-67 (70-95%) and 80% were positive for synaptophysin and chromogranin.",
            "Conclusion": "In patients with EGFR+ lung cancers with concurrent TP53 and RB1 alterations, osimertinib plus platinum and etoposide demonstrated activity and no new safety signals were seen. Early addition of small cell directed chemotherapy did not prevent histologic transformation."
        },
        {
            "Title": "Real-world outcomes of osimertinib (Osi) vs. afatinib or erlotinib in patients with classical vs. atypical EGFR-mutated metastatic non-small cell lung cancer (mNSCLC).",
            "Indication": "Metastatic non-small cell lung cancer (mNSCLC)",
            "Drug": "Osimertinib, Afatinib, Erlotinib",
            "PFS": "All: 29[17-34] months for CM vs. 15[11-19] months for AM (p=0.021); Osi: 15[8-18] months for AM (p=0.002); Afatinib: 5[4-6] months for AM; Erlotinib: 8[6-10] months for AM (p=0.001)",
            "OS": "All: 49[39-61] months for CM vs. 13[9-17] months for AM (p<0.001); Osi: 29[6-50] months for AM (p<0.001); Afatinib: 13[9-17] months for AM; Erlotinib: 14[6-21] months for AM (p<0.001)",
            "ORR": "None",
            "AE": "Dose-reduction due to AE: Osi 19% vs. Afatinib 24% (p=0.003) vs. Erlotinib 23% (p=0.002); Discontinuation due to AE: Osi 1% vs. Afatinib 2% (p=0.001) vs. Erlotinib 2% (p=0.004)",
            "Other": "None",
            "Conclusion": "Retrospectively, AM were associated with inferior PFS and OS compared to CM. Osi demonstrated superior survival and tolerability compared to afatinib or erlotinib for AM."
        },
        {
            "Title": "Impact of maintenance pemetrexed cessation on clinical outcomes of patients with metastatic non-squamous NSCLC.",
            "Indication": "Metastatic non-squamous non-small cell lung cancer (NSCLC)",
            "Drug": "Pemetrexed, Pembrolizumab",
            "PFS": "11.7 months (95% CI, 7.47-NA) for continued pemetrexed vs. 24.3 months (95% CI, 19.4-NA) for stopped pemetrexed (p=0.1)",
            "OS": "25.8 months (95% CI, 13.8-NA) for continued pemetrexed vs. 36.4 months (95% CI, 26.9-NA) for stopped pemetrexed (p=0.15)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Patients who stopped pemetrexed due to toxicity experienced similar outcomes to those who continued with pemetrexed. The necessity of continuation of maintenance chemotherapy should be further evaluated in the immunotherapy era."
        },
        {
            "Title": "Socioeconomic disparities in survival outcomes of patients with NSCLC with brain metastases: A real-world analysis.",
            "Indication": "Non-small cell lung cancer (NSCLC) with brain metastases",
            "Drug": "None",
            "PFS": "None",
            "OS": "Asian patients: 29.1 months (24.57-36.5); Hispanic patients: 18.1 months (14.5-24.3); Medicaid and Medicare-insured: 14.62 months (12.75-16.49) and 8.84 months (8.41-9.36) vs. private-insured: 21.72 months (20.37-23.29)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Asian and Hispanic patients with NSCLC BM had better 3-year survival compared to White and non-Hispanic patients, respectively. Patients at community centers had worse outcomes than those at academic centers. Medicare-insured and Medicaid-insured patients had unfavorable outcomes. Targeted efforts are warranted to improve disparities in outcomes across racial and ethnic groups."
        },
        {
            "Title": "Long-term efficacy and safety of larotrectinib in patients with TRK fusion lung cancer",
            "Indication": "TRK fusion lung cancer",
            "Drug": "Larotrectinib",
            "PFS": "22.0 months (95% CI 9.9-NE)",
            "OS": "39.3 months (95% CI 17.2-NE)",
            "ORR": "66% (95% CI 47-81)",
            "AE": "Grade 1/2 TRAEs predominantly; Grade 3/4 TRAEs in nine patients",
            "Other": "Median duration of response (DoR) was 33.9 months (95% CI 9.5-NE); 48-month rates for DoR, PFS, and OS were 47% (95% CI 19-76), 34% (95% CI 11-56), and 38% (95% CI 13-64), respectively.",
            "Conclusion": "Larotrectinib continued to demonstrate rapid and durable responses, extended survival benefit, and a favorable safety profile in patients with advanced TRK fusion lung cancer. These results support the wider adoption of next-generation sequencing panels that include NTRK gene fusions in patients with lung cancer to identify those who may benefit from targeted treatment."
        },
        {
            "Title": "A phase I/Ib study of the aurora kinase A inhibitor alisertib in combination with osimertinib in advanced osimertinib-resistant EGFR-mutated lung cancer",
            "Indication": "Advanced osimertinib-resistant EGFR-mutated lung cancer",
            "Drug": "Alisertib in combination with osimertinib",
            "PFS": "5.5 months",
            "OS": "None",
            "ORR": "9.5% (95% CI: 1.2% to 30.4%)",
            "AE": "Common treatment-related adverse events were neutropenia (42.9%), anemia (42.9%), and diarrhea (38.1%). Grade 4 neutropenia at the alisertib 40mg BID dose level.",
            "Other": "Disease control rate (DCR) was >80%. Median duration of response (DoR) was 11.9 months\u2013NR.",
            "Conclusion": "Intermittent dosing of alisertib 30mg BID in combination with osimertinib 80mg daily demonstrated no dose limiting toxicities and was identified as the RP2D. While ORR was <10%, DCR was >80% and the median PFS was 5.5 months. These results are comparable to other emerging therapies for patients with advanced osimertinib-resistant EGFR-mutated LUAD and warrants further exploration."
        },
        {
            "Title": "J-TAIL-2: A prospective observational study of atezolizumab (atezo) combination therapy in patients (pts) with non-small cell lung cancer (NSCLC) in Japan",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "Atezolizumab combination therapy",
            "PFS": "None",
            "OS": "12-month OS rates: 62.9% (95% CI 55.8, 69.2) for atezo+CnP, 72.1% (95% CI 65.2, 77.9) for atezo+PP, 68.3% (95% CI 63.2, 72.9) for atezo+bev+CP",
            "ORR": "None",
            "AE": "Grade \u22653 AEs occurred in 60.4% of pts and Grade 5 AEs in 1.3%",
            "Other": "Median OS was 19.7 months for atezo+CnP, 23.5 months for atezo+PP, and 17.3 months for atezo+bev+CP. OS HRs (95% CI) in the IMpower-unlike vs. like subgroups were 1.36 (0.91, 2.05), 1.08 (0.70, 1.68), and 1.49 (1.09, 2.06), respectively.",
            "Conclusion": "In Japanese pts, the efficacy and safety of each atezo combination therapy were comparable to those seen in the relevant IMpower studies and largely consistent in pts who would have been ineligible for the trials. Pts aged \u226570y with a G8 score < median vs \u2265 median had poorer efficacy and safety outcomes."
        },
        {
            "Title": "A randomized phase II trial of 8 months of afatinib switching to osimertinib (A) versus osimertinib alone (B) as first-line treatment in advanced NSCLC patients with common EGFR mutation: YAMATO study (TORG1939/WJOG12919L)",
            "Indication": "Advanced NSCLC with common EGFR mutation",
            "Drug": "Afatinib and Osimertinib",
            "PFS": "2-year PFS rate: 37.3% in arm A vs 47.4% in arm B (HR: 1.28, 80% CI: 0.95-1.73); Median PFS: 16.8 months in arm A vs 22.2 months in arm B (HR: 1.280, p=0.29)",
            "OS": "None",
            "ORR": "82% in arm A and 87% in arm B",
            "AE": "Severe pneumonitis: 7% in arm A, 1.8% in arm B",
            "Other": "None",
            "Conclusion": "YAMATO study failed to demonstrate the superiority of EGFR-TKI switching therapy of 8 months afatinib to osimertinib over osimertinib alone in terms of 2-year PFS rate in untreated advanced NSCLC patients with common EGFR mutations."
        },
        {
            "Title": "Health-related quality of life (HRQoL) with tepotinib in patients with MET exon 14 (METex14) skipping non-small cell lung cancer (NSCLC) with brain, liver, adrenal, or bone metastases in the phase II VISION trial",
            "Indication": "MET exon 14 skipping NSCLC with brain, liver, adrenal, or bone metastases",
            "Drug": "Tepotinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "HRQoL remained stable in patients with brain, liver, adrenal, or bone metastases during tepotinib treatment, with trends for improvement in cough, consistent with results for the overall population.",
            "Conclusion": "In the VISION trial in METex14 skipping NSCLC, patients with brain, liver, adrenal, or bone metastases maintained overall HRQoL during tepotinib treatment, with trends for improvement in cough."
        },
        {
            "Title": "A phase II trial to evaluate the epithelial-to-mesenchymal (EMT) inhibitor sotevtamab combined with docetaxel in patients with metastatic non-small cell lung cancer following failure of primary chemoimmunotherapy",
            "Indication": "Metastatic non-small cell lung cancer following failure of primary chemoimmunotherapy",
            "Drug": "Sotevtamab and Docetaxel",
            "PFS": "None",
            "OS": "Estimated one-year survival rate: 33.9%",
            "ORR": "17.1% (6 subjects with adenocarcinoma had a partial response)",
            "AE": "Fatigue, infusion-related reaction (IRR), diarrhea, nausea, and anemia",
            "Other": "Stable disease (SD) rate: 28.6%; The observed responses were sustained with a median duration of over one year (405 days).",
            "Conclusion": "This phase II study showed promising response and overall tumor control. Duration of response was particularly encouraging and relevant to further exploration. The sotevtamab and docetaxel combination may enhance immune-mediated anti-tumor response through induction of TLS."
        },
        {
            "Title": "Association of RNA-sequencing signatures with clinical outcomes of pembrolizumab + chemotherapy in patients with metastatic nonsquamous non\u2013small cell lung cancer (NSCLC) enrolled in the phase 2 KEYNOTE-782 trial.",
            "Indication": "Metastatic nonsquamous non-small cell lung cancer (NSCLC)",
            "Drug": "Pembrolizumab + chemotherapy (pemetrexed + carboplatin or cisplatin)",
            "PFS": "Tcell infGEP low: 7.2 months (95% CI, 5.1-24.1); Tcell infGEP nonlow: 7.1 months (95% CI, 5.0-13.8)",
            "OS": "Tcell infGEP low: 12.8 months (95% CI, 6.6-not reached); Tcell infGEP nonlow: 22.5 months (95% CI, 15.6-not reached)",
            "ORR": "Tcell infGEP low: 9 (39.1%); Tcell infGEP nonlow: 19 (41.3%)",
            "AE": "None",
            "Other": "None of the RNA-sequencing signatures evaluated were associated with clinical outcomes of pembrolizumab + chemotherapy.",
            "Conclusion": "There was little evidence of clinical utility of Tcell infGEP when evaluated as a dichotomous variable. Data support the use of pembrolizumab + chemotherapy as first-line therapy for patients with nonsquamous NSCLC regardless of consensus signature status."
        },
        {
            "Title": "Detection of comprehensive oncogenic driver alteration of non-small cell lung cancer from cytology specimens by multi-gene PCR panel.",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "CS exhibit an extremely high success rate for multi-gene analysis, with nucleic acid yield comparable to TS. Moreover, the concordance rate of gene alteration between CS and TS is also remarkably high.",
            "Conclusion": "CS emerge as a viable alternative to TS for analyzing driver gene alteration in multi-gene PCR analysis."
        },
        {
            "Title": "Characteristics and clinical outcomes of US veterans with advanced non\u2013small cell lung cancer (NSCLC) by KRAS subtype: A National Veterans Health Administration analysis.",
            "Indication": "Advanced non-small cell lung cancer (NSCLC)",
            "Drug": "KRAS G12C inhibitors (e.g., sotorasib)",
            "PFS": "None",
            "OS": "KRAS G12C: HR 0.77 (95% CI 0.64-0.93, p=0.006); KRAS G12D: HR 1.35 (CI 1.03-1.76, p=0.03); KRAS G12V: HR 1.42 (CI 1.10-1.81, p=0.006)",
            "ORR": "None",
            "AE": "None",
            "Other": "Co-mutations in KEAP1 (HR 1.60, CI 1.28-1.97, p<0.0001) and STK11 (HR 1.57, CI 1.31-1.88, p<0.0001) had worse OS.",
            "Conclusion": "In this cohort, KRAS G12C appears to confer a favorable prognosis compared to non-G12C variants, which may be explained in part by the adoption of sotorasib."
        },
        {
            "Title": "A phase II, open-label, single-arm, multicenter, efficacy and safety study of 80 mg osimertinib in patients with leptomeningeal metastases (LM) associated with epidermal growth factor receptor (EGFR) mutation\u2013positive non-small cell lung cancer (NSCLC): BLOSSOM.",
            "Indication": "Leptomeningeal metastases (LM) associated with EGFR mutation\u2013positive non-small cell lung cancer (NSCLC)",
            "Drug": "Osimertinib",
            "PFS": "Median LM PFS: 11.2 months (95% CI: 7.7-15.3)",
            "OS": "Median OS: 15.6 months (95% CI: 11.5-20.2)",
            "ORR": "ORR of LM: 51.6% (15.6% complete response, 35.9% partial response)",
            "AE": "Mostly grade 1 or 2, all manageable with appropriate treatment",
            "Other": "DCR: 81.3%, Median LM DOR: 12.6 months (95% CI: 8-18)",
            "Conclusion": "This study underscores the notable intracranial efficacy and survival benefit of 80 mg osimertinib in predominantly T790M negative NSCLC, with CSF concentrations comparable to the higher 160 mg dose. These findings advocate for the consideration of 80 mg osimertinib in the treatment regimen for patients with EGFR-mutated LM regardless of T790M mutation status."
        },
        {
            "Title": "Efficacy and safety of MCLA-129, an anti-EGFR/c-MET bispecific antibody, in non-small-cell lung cancer (NSCLC) with c-MET exon 14 skipping mutations (METex14).",
            "Indication": "Non-small-cell lung cancer (NSCLC) with c-MET exon 14 skipping mutations (METex14)",
            "Drug": "MCLA-129",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "MCLA-129 is being evaluated as monotherapy for the treatment of patients with locally advanced/metastatic c-MET exon 14 skipping NSCLC."
        },
        {
            "Title": "Enfortumab vedotin (EV) in non-squamous and squamous non\u2013small cell lung cancer (NSCLC) cohorts of EV-202.",
            "Indication": "Non-squamous and squamous non\u2013small cell lung cancer (NSCLC)",
            "Drug": "Enfortumab vedotin (EV)",
            "PFS": "Non-squamous: 4.1 months (2.8, 5.7), Squamous: 3.5 months (1.9, 5.4)",
            "OS": "Non-squamous: 10.5 months (8.1, 13.1), Squamous: 8.2 months (5.5, 10.4)",
            "ORR": "Non-squamous: 14.0%, Squamous: 4.3%",
            "AE": "Non-squamous: Any-grade TRAE: 91%, Grade \u22653 TRAE: 30%; Squamous: Any-grade TRAE: 83%, Grade \u22653 TRAE: 43%",
            "Other": "Non-squamous: Confirmed DCR: 67.4%, DOR: 10.0 months (9.7, 10.4); Squamous: Confirmed DCR: 65.2%, DOR: 3.7 months (NE, NE)",
            "Conclusion": "EV showed antitumor activity in heavily pretreated non-squamous NSCLC and manageable safety consistent with the known safety profile. The IA efficacy threshold was not met in squamous NSCLC."
        },
        {
            "Title": "High-dose furmonertinib in patients with EGFR-mutated non-small cell lung cancer and leptomeningeal metastases: A real-world study.",
            "Indication": "EGFR-mutated non-small cell lung cancer with leptomeningeal metastases",
            "Drug": "Furmonertinib",
            "PFS": "None",
            "OS": "8.433 months (95% CI, 5.481 to 11.386 months)",
            "ORR": "47.4% (LM objective response rate), 92.1% (disease control rate)",
            "AE": "45.8% (adverse events possibly related to furmonertinib), 6.3% (grade 3 elevated aminotransaminase or nausea or leucopenia)",
            "Other": "Clinical response rate: 75%, Median TTD: 8.267 months (95% CI, 5.395 to 11.138 months)",
            "Conclusion": "In the real world, high-dose furmonertinib demonstrated significant clinical efficacy and tolerable safety in patients of EGFR-mutated NSCLC with LM, even in patients who had been treated with other 3rd generation EGFR-TKIs previously."
        },
        {
            "Title": "Survival outcomes and subgroup analyses derived from a phase III randomized trial comparing afatinib to chemotherapy in treatment-na\u00efve non-small cell lung cancer with a sensitizing uncommon epidermal growth factor receptor mutation (ACHILLES/TORG1834).",
            "Indication": "Non-small cell lung cancer with a sensitizing uncommon epidermal growth factor receptor mutation",
            "Drug": "Afatinib",
            "PFS": "Median PFS: 7.29-15.3 months (HR: 0.422, p=0.0007)",
            "OS": "Not reached (HR: 0.989, 95% CI, 0.457 to 2.141; p=0.9772; 29/109 events, 26.6% maturity)",
            "ORR": "44% (common mutations), 26% (compound mutations), 34% (platinum doublet)",
            "AE": "Consistent with previous reports",
            "Other": "None",
            "Conclusion": "A consistent PFS benefit of afatinib over platinum-doublet chemotherapy was shown regardless of the mutation status in patients with uncommon or compound EGFR mutations. The OS data were immature, and further follow-up will be warranted."
        },
        {
            "Title": "A real-world study on biomarker retesting among patients with non-small cell lung cancer and disparity in outcomes.",
            "Indication": "Advanced/metastatic non-small cell lung cancer (a/mNSCLC)",
            "Drug": "Not specified",
            "PFS": "None",
            "OS": "mOS was 5.9 vs 9.9 months in Black vs White patients in 2L community setting",
            "ORR": "None",
            "AE": "None",
            "Other": "Biomarker testing rates and their association with overall survival",
            "Conclusion": "Disparities in testing rates and outcomes were observed, which may be overcome by normalizing testing across race and treatment setting to potentially improve patient outcomes."
        },
        {
            "Title": "Response to targeted and immune therapy in patients with NSCLC and BRAF non-V600E mutations: A retrospective, multicenter analysis within the national Network Genomic Medicine Lung Cancer (nNGM) in Germany.",
            "Indication": "Non-small cell lung cancer (NSCLC) with BRAF non-V600E mutations",
            "Drug": "Dabrafenib and trametinib",
            "PFS": "PFS ranged from 0.9 to 53.5 months; mPFS 9 months for MEK/ERK-activating mutations; specific mutations: p.V600D, p.V600K, p.K601E (mPFS 9.2 months), p.G466V (PFS 4.9 months), p.G469R (PFS 9 months), p.G469V (mPFS 28.2 months), p.V600_K601delinsE (mPFS 5.6 months)",
            "OS": "None",
            "ORR": "15% achieved partial remission, 40% stable disease, 40% progressive disease under TKI-therapy",
            "AE": "None",
            "Other": "Outcome upon (chemo)immune therapy for activating mutations was similar to targeted therapy (mPFS 15.0 and 11.7 months); kinase-dead mutants had better outcomes under (chemo)immune therapy (mPFS 11.0 and 11 months)",
            "Conclusion": "Patients with activating BRAF non-V600E mutations can be treated efficiently with BRAF/MEK TKI or (chemo)immune therapy, while patients with non-activating mutations benefit from (chemo)immune therapy."
        },
        {
            "Title": "Sacituzumab govitecan (SG) + pembrolizumab (pembro) in first-line (1L) metastatic non-small cell lung cancer (mNSCLC) with PD-L1 \u226550%: Cohort A of EVOKE-02.",
            "Indication": "Metastatic non-small cell lung cancer (mNSCLC) with PD-L1 \u226550%",
            "Drug": "Sacituzumab govitecan (SG) + pembrolizumab (pembro)",
            "PFS": "Median PFS was 13.1 months (95% CI, 5.5-not reached)",
            "OS": "None",
            "ORR": "67% (95% CI, 47-83%)",
            "AE": "Grade \u22653 TEAEs: 67%; Grade \u22653 TEAEs related to any study drug: 40%; Grade \u22653 TEAEs in \u226510% of patients: neutropenia (17%), diarrhea (10%), respiratory failure (10%); TEAEs leading to SG discontinuation: 17%; Treatment-related death: 3%",
            "Other": "None",
            "Conclusion": "SG + pembro demonstrated promising activity in patients with tumor proportion score \u226550% mNSCLC. AEs were manageable and consistent with the known safety profile of each individual agent."
        },
        {
            "Title": "Intracranial efficacy of datopotamab deruxtecan (Dato-DXd) in patients (pts) with previously treated advanced/metastatic non-small cell lung cancer (a/mNSCLC) with actionable genomic alterations (AGA): Results from TROPION-Lung05.",
            "Indication": "Advanced/metastatic non-small cell lung cancer (a/mNSCLC) with actionable genomic alterations (AGA)",
            "Drug": "Datopotamab deruxtecan (Dato-DXd)",
            "PFS": "Median PFS: 3.2 months (range 0.7-17.2) in pts with brain mets",
            "OS": "None",
            "ORR": "IC ORR: 22% (95% CI, 6-48) in pts with measurable IC target lesions",
            "AE": "TRAEs any grade/grade \u22653: 91%/23% in pts with brain mets; 96%/32% in pts without brain mets",
            "Other": "IC DCR: 72% (95% CI, 47-90); IC CBR: 44% (95% CI, 22-69); Reduction in brain lesion diameters: 56%; Median time to IC response: 1.5 months (range 1.3-5.4); Median duration of IC response: 5.5 months (95% CI, 3.4-NE)",
            "Conclusion": "Dato-DXd showed encouraging IC activity consistent with systemic responses in pts with a/mNSCLC with AGAs, supporting further investigation of Dato-DXd in pts with brain mets."
        },
        {
            "Title": "FDA analysis of immune checkpoint inhibitors in combination with vascular endothelial growth factor tyrosine kinase inhibitors in the second-line treatment of patients with advanced non-small cell lung cancer.",
            "Indication": "Advanced non-small cell lung cancer (NSCLC)",
            "Drug": "Immune checkpoint inhibitors (ICIs) plus vascular endothelial growth factor tyrosine kinase inhibitors (VEGF TKIs)",
            "PFS": "LEAP-008: 5.6 months (HR: 1.08, 95% CI: 0.89-1.32), SAPPHIRE: 4.4 months (HR: 0.74, 95% CI: 0.59-0.92), CONTACT-01: 4.6 months (HR: 0.89, 95% CI: 0.70-1.12)",
            "OS": "LEAP-008: 11.3 months (HR: 0.98, 95% CI: 0.78-1.23), SAPPHIRE: 12.2 months (HR: 0.86, 95% CI: 0.70-1.05), CONTACT-01: 10.7 months (HR: 0.88, 95% CI: 0.68-1.16)",
            "ORR": "LEAP-008: 22.7%, SAPPHIRE: 15.6%, CONTACT-01: 11.8%",
            "AE": "LEAP-008: 75%, SAPPHIRE: 74%, CONTACT-01: 73%",
            "Other": "None",
            "Conclusion": "ICI plus oral VEGF TKI combinations have not demonstrated an advantage over docetaxel in randomized trials of patients with advanced NSCLC. In 2 of the 3 trials, response rate data did not demonstrate superior efficacy compared to docetaxel. Additionally, any observed PFS benefit was small in magnitude. The relatively low efficacy, in combination with the additive toxicity of the regimen, may curtail a potential OS benefit. There remains a high unmet medical need in this patient population and further analysis is necessary to explore potential NSCLC subgroups which may derive benefit."
        },
        {
            "Title": "Phase 1/2 trial of the XPO1 inhibitor selinexor in combination with docetaxel in previously treated, advanced KRAS mutant non-small cell lung cancer (NSCLC).",
            "Indication": "Previously treated, advanced KRAS mutant non-small cell lung cancer (NSCLC)",
            "Drug": "Selinexor plus docetaxel",
            "PFS": "4.1 months (95% CI: 2.9-5 months)",
            "OS": "7.1 months (95% CI: 4.5-13.7 months)",
            "ORR": "22% (partial response), 56% (stable disease)",
            "AE": "Nausea (73%, Gr \u2265 3: 8%), fatigue (70%, Gr \u2265 3: 5%), neutropenia (65%, Gr \u2265 3: 60%), diarrhea (58%, Gr \u2265 3: 10%), anorexia (50%, Gr \u2265 3: 0%), vomiting (45%, Gr \u2265 3: 5%)",
            "Other": "TP53 co-mutations (48%) were associated with significantly worse outcomes: disease control rate (PR+SD) 27% vs 92% (P=0.006); median PFS 1.8 vs 7.4 months (P<0.001); median OS 5.8 vs 17 months (P=0.006)",
            "Conclusion": "Selinexor plus docetaxel is tolerated with multi-agent supportive care. The combination shows moderate efficacy across KRAS mutation types, with promising activity in TP53 wildtype cases."
        },
        {
            "Title": "Safety and anti-tumor activity of BAY 2927088 in patients with HER2-mutant NSCLC: Results from an expansion cohort of the SOHO-01 phase I/II study.",
            "Indication": "HER2-mutant non-small cell lung cancer (NSCLC)",
            "Drug": "BAY 2927088",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "Genomic landscape of MTAP deletions and association with EGFR alterations in NSCLC.",
            "Indication": "Non-small cell lung cancer (NSCLC) with MTAP deletions",
            "Drug": "Osimertinib",
            "PFS": "Median TTD 19 months vs 24 months for MTAP-intact, HR=1.2, P=0.57",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Acquired MTAP del was found in 14% of patients at progression on osimertinib, more frequent than other previously characterized osimertinib acquired resistance mechanisms.",
            "Conclusion": "MTAP deletions are enriched in tumors with targetable driver alterations, particularly in EGFR, and are frequently observed at acquired resistance to osimertinib. Demonstration of activity of novel agents targeting MTAP deficiency could be of significant benefit to patients with EGFR- and other oncogene-driven lung cancers."
        },
        {
            "Title": "Association of combination tumor texture and vessel tortuosity with progression free survival across PD-L1 subgroups in durvalumab treated non-small cell lung cancer (NSCLC): Blinded validation results from CP1108.",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "Durvalumab",
            "PFS": "Sv: HR=0.22 (CI: 0.09, 0.50), p<0.001, C-index=0.61; Sv+: HR=0.21 (CI: 0.06, 0.80), p=0.022, C-index=0.60; Sv-: HR=0.20 (CI: 0.06, 0.64), p=0.006, C-index=0.64",
            "OS": "Sv: HR=0.28 (CI: 0.11, 0.68), p=0.03, C-index=0.59; Sv+: HR=0.22 (CI: 0.05, 0.93), p=0.039, C-index=0.61; Sv-: HR=0.34 (CI: 0.10, 1.19), p=0.093, C-index=0.57",
            "ORR": "None",
            "AE": "None",
            "Other": "Combination of radiomics and vessel tortuosity biomarker from CT is independently prognostic of response and PFS across PD-L1 levels.",
            "Conclusion": "Prospective validation of this biomarker is needed."
        },
        {
            "Title": "A phase 2, multicenter, open-label, dose-optimization study evaluating telomere-targeting agent THIO sequenced with cemiplimab in patients with advanced NSCLC: Updated results.",
            "Indication": "Advanced non-small cell lung cancer (NSCLC)",
            "Drug": "THIO sequenced with cemiplimab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Most frequent related AEs were AST increase (23%), ALT increase (20%), and nausea (11%). All instances of LFT elevations were without clinical symptoms and resolved to baseline.",
            "Other": "DCR greatly exceeded the threshold required for Stage 2 expansion in both arms: 60mg 14/16 (88%); 180mg 11/13 (85%). The 180mg dose was selected for further investigation based on superior efficacy (31% PR rate; 54% reduction in tumor burden).",
            "Conclusion": "THIO followed by cemiplimab was generally well tolerated in this difficult-to-treat population. Based on safety and promising efficacy, the 180mg dose was selected for further development. Enrollment is ongoing and is expected to be completed in Feb-2024."
        },
        {
            "Title": "Comparison of chemotherapy to chemo-immunotherapy as first-line treatment in patients with advanced large cell neuroendocrine carcinomas (LCNECs) of mixed histology: A multi-institutional international retrospective study.",
            "Indication": "Advanced large cell neuroendocrine carcinomas (LCNECs) of mixed histology",
            "Drug": "Chemotherapy alone (chemo) or chemo-immunotherapy (chemoIO)",
            "PFS": "Chemo: 5 months (95% CI 2.7-9.2), ChemoIO: 6 months (95% CI 4.4-8.3), p=0.86",
            "OS": "Chemo: 14.2 months (95% CI 10.4-22.5), ChemoIO: 17.2 months (95% CI 9.4-27.2), p=0.66",
            "ORR": "Chemo: 45%, ChemoIO: 48.4%, p=0.81",
            "AE": "Chemo: 60% experienced trAEs of any grade, 23.8% experienced grade 3+ events; ChemoIO: 61% experienced trAEs of any grade, 25.8% experienced grade 3+ events",
            "Other": "No significant differences in median PFS or OS between mixed LCNEC/NSCLC and LCNEC/SCLC",
            "Conclusion": "For mixed LCNECs, there was no significant difference in PFS or OS between first-line treatment with chemo vs. chemoIO, suggesting limited benefit from immunotherapy for these patients. Additionally, survival outcomes did not differ between mixed LCNEC/NSCLC and LCNEC/SCLC, suggesting a biology primarily driven by their neuroendocrine component."
        },
        {
            "Title": "Efficacy and safety of MCLA-129, an EGFR/c-MET bispecific antibody, in advanced non-small cell lung cancer (NSCLC).",
            "Indication": "Advanced non-small cell lung cancer (NSCLC)",
            "Drug": "MCLA-129",
            "PFS": "None",
            "OS": "None",
            "ORR": "Cohort A: 43.5%, Cohort B: 28.6%, Cohort C: 21.8%",
            "AE": "Common TEAEs: infusion related reaction (71.9%), hypoalbuminemia (54.8%), decreased neutrophil count (46.1%), decreased white blood cell count (40.1%). Grade \u22653 TEAEs: 51.6%, Drug-related TEAEs: 39.2%",
            "Other": "DCR for Cohort A: 95.7%, Cohort B: 84.1%, Cohort C: 69.1%",
            "Conclusion": "MCLA-129 demonstrates robust and durable antitumor activity in patients with NSCLC harboring METex14, EGFR exon20ins, and sensitized EGFR mutations, with a manageable safety profile; enrollment in dose expansion is ongoing."
        },
        {
            "Title": "Safety and efficacy of ifebemtinib (IN10018) combined with D-1553 in non-small-cell lung cancer (NSCLC) with KRAS G12C mutation: Results from a phase Ib/II study.",
            "Indication": "Non-small-cell lung cancer (NSCLC) with KRAS G12C mutation",
            "Drug": "Ifebemtinib (IN10018) combined with D-1553",
            "PFS": "Not reached",
            "OS": "Not reached",
            "ORR": "87.5% (95% CI: 56.6, 96.2)",
            "AE": "Ifebemtinib-related SAEs: 12.1%, Grade \u22653 ifebemtinib-related AEs: 18.1%",
            "Other": "DCR: 93.8% (95% CI: 63.6, 98.5)",
            "Conclusion": "The combination of ifebemtinib and D-1553, as a dual-oral regimen, showed promising antitumor activity and manageable safety profile in KRAS G12C mutant front-line NSCLC population. Long-term follow-up is needed to assess the potential of such novel combo."
        },
        {
            "Title": "Amivantamab plus chemotherapy vs chemotherapy as first-line treatment among patients with EGFR exon 20 insertion\u2013mutated advanced non-small cell lung cancer (NSCLC): PAPILLON Chinese subgroup analysis.",
            "Indication": "Advanced non-small cell lung cancer (NSCLC) with EGFR exon 20 insertion mutation",
            "Drug": "Amivantamab plus carboplatin/pemetrexed (ACP) vs carboplatin/pemetrexed (CP)",
            "PFS": "ACP: 12.3 months (95% CI: 7.00, NE), HR: 0.47 (95% CI: 0.26-0.85), P=0.011; CP: 6.7 months (95% CI: 4.21, 8.57)",
            "OS": "ACP: NE, HR: 0.58 (95% CI: 0.17-2.02), P=0.387; CP: NE",
            "ORR": "ACP: 71.8% (95% CI: 55.1%-85.0%), CP: 48.9% (95% CI: 34.1%-63.9%), OR: 2.46 (95% CI: 1.01-5.98), P=0.048",
            "AE": "Common AEs (>50%) in ACP arm: rash, paronychia, hypoalbuminemia, neutropenia, anemia, leukopenia, thrombocytopenia",
            "Other": "Median PFS2: ACP: NE, CP: 18.8 months (HR: 0.32, 95% CI: 0.11-0.88, P=0.021)",
            "Conclusion": "ACP demonstrated superior PFS compared to CP in Chinese patients. The safety profile was manageable and tolerable. These findings in the Chinese subgroup were consistent with those of the overall population and Asian subgroup."
        },
        {
            "Title": "Efficacy and safety of bronchial arterial chemoembolization (BACE) in combination with tislelizumab for non-small cell lung cancer (NSCLC): A phase II study.",
            "Indication": "Stage III-IV non-small cell lung cancer (NSCLC)",
            "Drug": "Bronchial arterial chemoembolization (BACE) plus tislelizumab",
            "PFS": "10.5 months (95% CI: 7.8-13.2)",
            "OS": "Not reached",
            "ORR": "3-month ORR: 63.3% (95% CI: 43.9%-80.1%); 6-month ORR: 56.7% (95% CI: 37.4%-74.5%); 12-month ORR: 30.4% (95% CI: 13.2%-52.9%)",
            "AE": "No grade 3 or higher treatment-related adverse events (TRAEs). Common grade 2 TRAEs: nausea, fever, cough.",
            "Other": "DCR at 3 months: 80% (95% CI: 61.4%-92.3%); at 6 months: 76.7% (95% CI: 57.7%-90.1%); at 12 months: 47.8% (95% CI: 26.8%-69.4%). QoL improved significantly after 1 cycle of treatment.",
            "Conclusion": "The study demonstrated promising efficacy and manageable safety profiles of BACE plus tislelizumab for advanced NSCLC patients, indicating a potential and effective treatment option for this population."
        },
        {
            "Title": "Effect of tumor burden on survival in patients with stage IV EGFR-mutant NSCLC receiving osimertinib monotherapy",
            "Indication": "Stage IV EGFR-mutant non-small cell lung cancer (NSCLC)",
            "Drug": "Osimertinib",
            "PFS": "Median PFS for the overall cohort was 16.3 months (95% CI; 13.7-18.9). PFS for TTn 1-4: 21.5 months, TTn 5-9: 16.3 months, TTn \u226510: 13.4 months (P=0.001). PFS for MDn 1-2: 21.6 months, MDn 3-4: 11.4 months, MDn \u22655: 9.0 months (P<0.0001).",
            "OS": "Median OS for the overall cohort was 31.1 months (95% CI; 27.4-39.3). OS for TTn 1-4: 57.7 months, TTn 5-9: 35.1 months, TTn \u226510: 23.7 months (P<0.0001). OS for MDn 1-2: 44.3 months, MDn 3-4: 20.8 months, MDn \u22655: 22.4 months (P<0.0001).",
            "ORR": "None",
            "AE": "None",
            "Other": "Patients with liver and bone metastases had significantly worse PFS and OS, whereas those with lung and brain metastases had worse OS without affecting PFS.",
            "Conclusion": "The extent of baseline tumor burden impacts both PFS and OS in patients with stage IV EGFR-mutant NSCLC treated with osimertinib monotherapy. Patients with 'low-TB' appear to have a survival advantage compared to those with 'intermediate-or high-TB' and may derive less benefit from the addition of chemotherapy to osimertinib."
        },
        {
            "Title": "OSIRAM-1/TORG1833: Overall survival results of a randomized phase II study of osimertinib plus ramucirumab versus osimertinib alone as initial chemotherapy for EGFR mutation-positive non-squamous non-small cell lung cancer",
            "Indication": "EGFR mutation-positive non-squamous non-small cell lung cancer (NSCLC)",
            "Drug": "Osimertinib plus Ramucirumab",
            "PFS": "Median PFS was 20.0 months for Osi+Ram and 24.0 months for Osi, hazard ratio of 1.054 (95% CI 0.674-1.648).",
            "OS": "Median OS was 43.4 months (95% CI 36.5-N.R.) for Osi+Ram and N.R. (95% CI 41.3-N.R.) for Osi, resulting in a hazard ratio of 1.159 (95% CI 0.645-2.083, p=0.62).",
            "ORR": "None",
            "AE": "Ram was discontinued in 45 patients (76%) due to treatment-related adverse effects. Common reasons for Ram discontinuation were platelet count decreased (n=14), proteinuria (n=12), neutrophil count decreased (n=11), pneumonitis (n=3), bleeding (n=3), and infection (n=3). Pneumonitis was observed in 16% in Osi, 9% in Osi+Ram, with grade 3 observed in 2% of both arms.",
            "Other": "None",
            "Conclusion": "Both treatment arms demonstrated sustained OS over 43 months. However, this study did not show an advantage of biweekly Ram administration with Osi in terms of PFS and OS. Notably, there was a significant increase in early discontinuation of Ram due to higher adverse events."
        },
        {
            "Title": "Clinical activity and safety of naptumomab estafenatox (NAP) and docetaxel plus carboplatin-pemetrexed in patients with advanced non-squamous, metastatic non-small cell lung cancer (NSCLC): Preliminary results of a P2 trial.",
            "Indication": "Advanced non-squamous, metastatic non-small cell lung cancer (NSCLC)",
            "Drug": "Naptumomab estafenatox (NAP) and docetaxel",
            "PFS": "Mean PFS was 4.6 months",
            "OS": "Median OS was 8 months",
            "ORR": "ORR 16%",
            "AE": "Mostly grade 1-2 infusion related reactions, 5% discontinued due to toxicities",
            "Other": "Disease-control rate (DCR) was 72%, with mean duration of 5.3 months",
            "Conclusion": "These preliminary results of NAP + docetaxel show potential evidence of activity with acceptable safety in heavily pre-treated NSCLC patients. Further trials of NAP in combination, including CPIs are planned."
        },
        {
            "Title": "Final analysis of SCARLET study: A single-arm, phase II study of sotorasib in patients (pts) with checkpoint inhibitor (CPI) \u2013pretreated advanced/ metastatic non-small cell lung cancer KRAS G12C mutation.",
            "Indication": "Advanced/metastatic non-small cell lung cancer KRAS G12C mutation",
            "Drug": "Sotorasib",
            "PFS": "Median PFS was 6.6 months",
            "OS": "Median OS was 20.6 months",
            "ORR": "ORR by independent review was 88.9%",
            "AE": "Common adverse events were anemia, platelet count decreased, neutrophil count decreased, decreased appetite and nausea. Grade \u22653 AEs were mostly hematological toxicities, and one treatment-related death (pneumonia) occurred.",
            "Other": "Increased allele frequency of KRAS G12C was observed in 73% of the patients at the time of disease progression.",
            "Conclusion": "Sotorasib in combination with CBDCA/PEM demonstrated favorable ORR with modest PFS and good tolerability in advanced non-Sq, NSCLC patients with KRAS G12C mutation."
        },
        {
            "Title": "Datopotamab deruxtecan (Dato-DXd) plus pembrolizumab (pembro) with or without platinum chemotherapy (Pt-CT) as first-line (1L) therapy for advanced non-small cell lung cancer (aNSCLC): Subgroup analysis from TROPION-Lung02.",
            "Indication": "Advanced non-small cell lung cancer (aNSCLC)",
            "Drug": "Datopotamab deruxtecan (Dato-DXd) plus pembrolizumab (pembro) with or without platinum chemotherapy (Pt-CT)",
            "PFS": "Median PFS was 11.1 months for doublet, 6.8 months for triplet",
            "OS": "None",
            "ORR": "Confirmed objective response rate was 52% for doublet, 56% for triplet",
            "AE": "Most common any-grade treatment-emergent adverse events (TEAEs) were stomatitis (57%, 33%) and nausea (40%, 46%). Grade \u22653 TEAEs were seen in 57% and 76% of pts; serious TEAEs in 38% and 44%. TEAEs associated with Dato-DXd discontinuation occurred in 29% and 39% of pts receiving doublet or triplet tx. TEAEs associated with death were seen in 2% and 9%, none considered related to study drug.",
            "Other": "None",
            "Conclusion": "As 1L therapy for aNSCLC, Dato-DXd plus pembro with or without Pt-CT continues to demonstrate durable antitumor activity. Efficacy was observed regardless of PD-L1 expression. Tolerability of the combinations was as expected based on known profiles of the individual agents, with no new safety signals observed."
        },
        {
            "Title": "Amivantamab plus capmatinib in advanced non-small cell lung cancer (NSCLC) harboring MET alterations: Recommended phase 2 combination dose and preliminary dose-escalation results from the phase 1/2 METalmark study.",
            "Indication": "Advanced non-small cell lung cancer (NSCLC) harboring MET alterations",
            "Drug": "Amivantamab plus capmatinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "1 partial response (PR; MET amp + Ex19del), 2 unconfirmed PRs (1 METex14, 1 L858R; both ongoing), and 8 stable diseases observed as a best overall response",
            "AE": "Most common AEs were primarily EGFR- and MET-related. Rate of peripheral edema was 28% (5/18), none grade \u22653",
            "Other": "Preliminary PK data suggest similar exposure for the combination vs individual agents. Among the 8 treatment discontinuations, 6 were due to progressive disease, 1 withdrew consent, and 1 due to respiratory failure (unrelated to study treatments)",
            "Conclusion": "RP2CD for ami + cap was identified as a combination of each approved dose. Initial safety profile of ami + cap does not appear to have additive MET-related toxicities."
        },
        {
            "Title": "Early FDG PET imaging and circulating T-cell repertoire biomarkers of response to chemotherapy and PD-1 checkpoint inhibitors in patients with stage IV NSCLC.",
            "Indication": "Stage IV NSCLC",
            "Drug": "Carboplatin-pemetrexed-pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "63% had partial response (PR) to initial chemoICI versus 33% with stable disease (SD) and 4% with progressive disease (PD)",
            "AE": "None",
            "Other": "Higher baseline peak SUV and TLG, along with greater decline after first dose, were correlated with post-chemoICI size reduction (R 0.43-0.64, p=0.001-0.036). PR patients had higher baseline peak SUV (12 vs 6 g/mL, p=0.014) and greater week 3 decline in TLG (39% vs 7%, p=0.032) relative to SD/PD patients. FDG PET biomarkers were stronger discriminator of chemoICI response status (AUC 0.75-0.82, p=0.009-0.046) than PD-L1 (AUC 0.60, p=0.42). Higher baseline blood TCR Simpson clonality was positively correlated with size reduction (R 0.70, p=0.004), whereas TCR Pielou evenness was negatively correlated (R -0.68, p=0.006).",
            "Conclusion": "FDG PET imaging prior to and after first dose chemoICI, along with baseline circulating T cell repertoire biomarkers, predict future response. Further investigation of these patient- and lesion-level biomarkers to guide personalized (de)escalation, consolidation, or adaptation of regimens for stage IV NSCLC is warranted."
        },
        {
            "Title": "Analysis of drug-related interstitial lung disease (ILD) in patients (pts) treated with datopotamab deruxtecan (Dato-DXd).",
            "Indication": "Advanced solid tumors (including non-small cell lung cancer (NSCLC) and breast cancer (BC))",
            "Drug": "Datopotamab deruxtecan (Dato-DXd)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Adjudicated drug-related ILD incidence was 5% (43 pts; 28 grade 1-2, 15 grade 3-5).",
            "Other": "Median time to first onset and duration of first event were 44.0 and 39.0 days, respectively. ILD associated with dose reduction, drug interruption, or drug withdrawal occurred in 3%, 13%, and 24% of pts, respectively. 8 fatal events in NSCLC pts.",
            "Conclusion": "Cases of adjudicated drug-related ILD seen with Dato-DXd monotherapy were mainly low grade. Grade 3+ events have been reported, highlighting the need for careful monitoring and adherence to management guidelines. ILD incidence was numerically higher in NSCLC; risk factors for Dato-DXd-related ILD are under investigation."
        },
        {
            "Title": "A phase 1b study of ensartinib in combination with platinum-based chemotherapy and bevacizumab in ALK-positive non-small cell lung cancer (NSCLC).",
            "Indication": "ALK-positive advanced non-small cell lung cancer (NSCLC)",
            "Drug": "Ensartinib in combination with platinum-based chemotherapy and bevacizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Maximum severity adverse event (AE) was grade 2 in 1/6 patients, grade 3 in 4/6 patients, and grade 5 in 1/6 (therapy unrelated broncho-pulmonary hemorrhage). Ensartinib-related grade 1, 2, and 3 AEs occurred in 1/6, 3/6, and 2/6 respectively.",
            "Other": "Dose reductions from 225mg daily to 200mg daily occurred in 1/6 patients secondary to grade 3 transaminitis. Median time on trial was 9.6 months. 2/6 patients remained on treatment at time of censorship with trial durations of 22.6 and 15.8 months. 1/6 patients progressed and 2/6 patients died on treatment.",
            "Conclusion": "Ensartinib in combination with platinum-based chemotherapy and bevacizumab had an acceptable safety profile and demonstrates clinical activity in treatment-na\u00efve and previously treated advanced ALK-positive NSCLC, including in patients treated with prior ALK-targeted therapies. Next phase study designs are currently being considered."
        },
        {
            "Title": "Efficacy of immunotherapy retreatment in patients with non-small cell lung cancer receiving consolidative durvalumab.",
            "Indication": "Unresectable non-small cell lung cancer (NSCLC)",
            "Drug": "Durvalumab followed by subsequent systemic therapy",
            "PFS": "Median rwPFS of chemotherapy vs IO-containing therapy: 7.0 months vs 5.8 months, respectively; p=0.9419",
            "OS": "Median OS of chemotherapy vs IO-containing therapy: 13.0 months vs 12.6 months, respectively; p=0.5769",
            "ORR": "None",
            "AE": "None",
            "Other": "Patients who received IO alone had a numerically longer rwPFS (12.6 months vs 5.8 months, respectively; p=0.3951) and OS (12.6 months vs 6.4 months, respectively; p=0.8885) compared to chemoIO.",
            "Conclusion": "This study found no statistically significant differences in median rwPFS or OS between chemotherapy or IO-based regimens following the conclusion of durvalumab consolidation. Interestingly, a numerically improved rwPFS and OS was observed in those who received IO alone compared to chemoIO, suggesting there may be key clinical or molecular features that may help identify patients who benefit from IO therapy. The efficacy of IO-based regimens post-durvalumab warrants further investigation in a larger, prospective manner to assess the risks and benefits of IO retreatment."
        },
        {
            "Title": "Real-world treatment patterns and outcomes in US patients (pts) with advanced non-small cell lung cancer (NSCLC) after platinum-based chemotherapy (PBC) and anti\u2013PD-(L)1 treatment.",
            "Indication": "Advanced non-small cell lung cancer (NSCLC)",
            "Drug": "Various treatments post PBC and anti\u2013PD-(L)1, most commonly docetaxel with or without ramucirumab",
            "PFS": "Median PFS: 5.29 months (95% CI: 5.03-5.52)",
            "OS": "Median OS: 11.20 months (95% CI: 10.48-11.93)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "A broad range of subsequent treatments were used following PBC and anti\u2013PD-(L)1 regimens, most commonly docetaxel with or without ramucirumab. Short median PFS and OS underscore a significant unmet need among pts previously treated with PBC and anti\u2013PD-(L)1."
        },
        {
            "Title": "Clinical-genomic characteristics in patients with EGFR-mutated non-small cell lung cancer harboring MET copy number gains after progression on EGFR-TKI: A report from LC-SCRUM-TRY.",
            "Indication": "EGFR-mutated non-small cell lung cancer (NSCLC) with MET copy number gains",
            "Drug": "Platinum-based chemotherapy (PBC) post EGFR-TKI",
            "PFS": "Median PFS: 5.7 months (MET amp), 6.8 months (MET poly), 6.7 months (no CNG) (p=0.02)",
            "OS": "None",
            "ORR": "41% (MET amp), 29% (MET poly), 31% (no CNG) (p=0.34)",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Patients with shorter PFS with prior 1st line EGFR-TKI are more likely to have MET amp. The efficacy of standard PBC post EGFR-TKI in patients with MET CNG is limited. Current MET-targeted therapy may not be effective for EGFR-mutated NSCLC harboring MET poly after progression on EGFR-TKI."
        },
        {
            "Title": "Efficacy of immunotherapy in first-line treatment for non-small cell lung cancer with HER2 mutation: Results from LC-SCRUM-Asia.",
            "Indication": "Non-small cell lung cancer (NSCLC) with HER2 mutation",
            "Drug": "Platinum-based chemotherapy with or without immune checkpoint inhibitors (ICIs)",
            "PFS": "Median PFS: 8.5 months (Chemo-ICI) vs 6.3 months (Chemo-alone), HR [95% CI]: 0.66 [0.50\u20130.88], p < 0.005",
            "OS": "Median OS: 31.1 months (Chemo-ICI) vs 23.3 months (Chemo-alone), HR [95% CI]: 0.80 [0.57\u20131.12]",
            "ORR": "34.7% (Chemo-ICI) vs 35.3% (Chemo-alone)",
            "AE": "None",
            "Other": "Tumors with HER2 mutations showed a higher TMB (median 4.22 mut/Mb) and a higher percentage of TMB \u2265 10 mut/Mb (12.3%) compared to tumors with EGFR mutations (median 2.54 mut/Mb and 1.0%) or ALK fusions (median 2.52 mut/Mb and 0.8%).",
            "Conclusion": "The addition of ICI to platinum-based chemotherapy as first-line treatment may improve PFS in NSCLC patients with HER2 mutations. Relatively high TMB may contribute to prolongation of PFS with ICI."
        },
        {
            "Title": "Therapeutic activity of a new KRASG12C inhibitor JMKX001899 in brain metastases: Preclinical models of KRASG12C-mutant non-small cell lung cancer.",
            "Indication": "KRASG12C-mutant non-small cell lung cancer (NSCLC) with brain metastases",
            "Drug": "JMKX001899",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "In preclinical models, JMKX001899 at 100 mg/kg once daily treatment for 21 days resulted in significant inhibition of brain tumor growth compared with vehicle and AMG510. JMKX001899 at 100 mg/kg once daily treatment was well tolerated with minimal signs of overt toxicity or animal weight loss.",
            "Conclusion": "The preclinical models in vivo suggest the new KRASG12C inhibitor JMKX001899 penetrates the central nervous system and shows great inhibition of KRASG12C-mutant NSCLC with BM, which demonstrates therapeutic activity of JMKX001899 in BM. These data support further development of JMKX001899 in patients with KRASG12C-mutant NSCLC with BM."
        },
        {
            "Title": "Preliminary results from a FIH, open-label phase 1 study with BMC128, a rationally designed live bacterial consortium, in combination with nivolumab.",
            "Indication": "Non-small cell lung cancer (NSCLC), cutaneous melanoma, clear cell renal cell carcinoma (ccRCC)",
            "Drug": "BMC128 in combination with nivolumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "No SAEs related to BMC128; two patients exhibited minor AEs potentially associated with BMC128",
            "Other": "4 out of the first 8 patients showed SD and sustained benefit beyond the first imaging timepoint, demonstrating clinical benefit for a total of 16 weeks (2 patients), 24 weeks (1 patient), and 68 weeks (1 patient)",
            "Conclusion": "BMC128 has demonstrated an excellent safety profile and preliminary beneficial clinical effect. A phase 2 study to investigate the efficacy of BMC128 is planned to be initiated in late 2024."
        },
        {
            "Title": "CheckpointPx: A predictive radiology AI model of immune checkpoint inhibitor (ICI) benefit in non-small cell lung cancer (NSCLC).",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "CheckpointPx (radiology AI tool)",
            "PFS": "HR=0.68 (95% CI: 0.52-0.93, p=0.019) for ICI patients; HR=0.91 (95% CI: 0.51-1.61, p=0.740) for chemotherapy patients",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "CheckpointPx included 16 features such as measurements of intra-tumoral heterogeneity and vessel twistedness and branching patterns",
            "Conclusion": "CheckpointPx demonstrated the ability to identify NSCLC patients who would benefit from ICI over chemo. The model\u2019s association with PFS among ICI recipients, but not patients receiving chemotherapy alone, suggests that the signature is predictive of immunotherapy-related outcomes rather than generally prognostic. Additional independent, multi-site and prospective validation is warranted."
        },
        {
            "Title": "Clinical outcomes and post-progression retreatment in patients with metastatic NSCLC who complete two years of treatment with immune checkpoint blockade.",
            "Indication": "Metastatic non-small cell lung cancer (NSCLC)",
            "Drug": "Immune checkpoint inhibitors (ICI)",
            "PFS": "Median time to progression (mTTP) was 6.9 years (95% CI 4.9-NR)",
            "OS": "Median overall survival (mOS) was 9.5 years (95% CI 8.0-NR)",
            "ORR": "63% response rate among those retreated with ICI",
            "AE": "None",
            "Other": "Patients with high PD-L1 \u226550% (HR 0.6, P=0.02), very high tumor mutational burden (TMB) \u226520 mut/Mb (HR 0.7, P=0.03), and ever smoking status (HR 0.52, P=0.04) had significantly longer mTTP",
            "Conclusion": "Patients who received a minimum of two years of treatment with ICI experienced unprecedented long-term survival. There was no difference in outcomes between patients who stopped at two years compared to those who continued therapy. After completing two years of initial ICI therapy, a large fraction of patients who subsequently experienced disease progression and are retreated with ICI experience an objective response, particularly those with longer duration of prior ICI treatment, higher TMB, and adenocarcinoma histology."
        },
        {
            "Title": "Overall survival after treatment with CAN-2409 plus valacyclovir in combination with continued ICI in patients with stage III/IV NSCLC with an inadequate response to ICI.",
            "Indication": "Stage III/IV non-small cell lung cancer (NSCLC)",
            "Drug": "CAN-2409 plus valacyclovir in combination with continued ICI",
            "PFS": "None",
            "OS": "mOS of the evaluable population was estimated as 22.0 months (95% CI: 14.3 months, NA); mOS in C2 (n=38) was 20.6 months (mFU 19.2 months) and in C1 (n=6) was NA (mFU 15.2 months); mOS in the safety population (n=73) was 14.3 months (mFU 16.8 months)",
            "ORR": "64% of evaluable patients had systemic clinical response with shrinkage of both injected and uninjected lesions",
            "AE": "No dose-limiting toxicities or \u2265grade 4 treatment-related AEs",
            "Other": "Significant correlation between increases in T cell populations after the second injection of CAN-2409 and subsequent OS (R2 between 0.181 and 0.354 and p = 0.043 and 0.004)",
            "Conclusion": "Experimental treatment with CAN-2409 plus valacyclovir in NSCLC patients following an inadequate response to ICI is well tolerated and results in the induction of a cytotoxic and memory circulating T cell response associated with shrinkage of injected and uninjected lesions and a raised tail of the survival curve. mOS of the evaluable population was estimated as 22.0 months, markedly longer than the expected mOS of 11.6-14.5 months."
        },
        {
            "Title": "KRAS mutation subtypes responded differently to combination immunotherapy via disturbing myeloid-derived suppressor cells in non-small cell lung cancer.",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "Immune checkpoint inhibitors (ICIs) and chemotherapy",
            "PFS": "Median PFS: 11.3 months (G12C subgroup) vs 6.8 months (other mutations subgroup), P=0.001; Median PFS: 11.1 months (G12C/G12V subgroup) vs 6.5 months (other groups), P=0.0005; HR: 2.021, 95% CI: 1.265-3.227, P=0.003",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Infiltration of myeloid-derived suppressor cells (MDSCs, CD33+CD11b+) was significantly lower in the G12C/G12V subgroup compared to the other subgroup (tumor area, P=0.045; stroma area, P=0.015). No significant difference in PD-L1 expression was found between the G12C/G12V group and the other groups.",
            "Conclusion": "KRAS G12C and G12V were associated with favorable combined ICI treatment efficacy in patients with NSCLC. Patients with G12C or G12V mutations had longer PFS and lower infiltration of MDSCs. KRAS mutation subtypes are potential predictive biomarkers disturbed by MDSCs for combination therapy in NSCLC patients."
        },
        {
            "Title": "PD-L1 expression in pulmonary sarcomatoid carcinoma and response to immunotherapy.",
            "Indication": "Pulmonary sarcomatoid carcinoma (PSC), non-small cell lung cancer (NSCLC)",
            "Drug": "Immune checkpoint inhibitors (ICIs) alone or with chemotherapy",
            "PFS": "Median PFS: 5.83 months (PSC), 7.5 months (LUAD), 6.13 months (LUSC); P=0.033",
            "OS": "Median OS: 33.4 months (PSC), 27.6 months (LUAD), 18.2 months (LUSC); P<0.001",
            "ORR": "38% (PSC), 26.4% (LUAD), 21.8% (LUSC)",
            "AE": "None",
            "Other": "PSC patients had higher PD-L1 expression (71.7% with PD-L1 \u226550%). PSC tumors were enriched for KRAS, MET, and TP53 mutations. No significant difference across histologies in tumor mutational burden or tumor-infiltrating lymphocytes (TIL).",
            "Conclusion": "PSC is associated with higher PD-L1 expression and a higher proportion of responders when treated with immunotherapy, with similar PFS and OS compared to LUAD and LUSC. Further investigation of the immune profile in PSC is needed to explore predictive factors of immunotherapy to optimize patient selection in clinical practice."
        },
        {
            "Title": "Safety and efficacy of osimertinib in patients with NSCLC and uncommon tumoral EGFR mutations: A systematic review and meta-analysis.",
            "Indication": "Non-Small Cell Lung Cancer (NSCLC) with uncommon EGFR mutations",
            "Drug": "Osimertinib",
            "PFS": "Median PFS of 9.5 months (95% CI, 8.2\u201311.0)",
            "OS": "Median OS of 24.5 months (95% CI, 11.9\u201324.5)",
            "ORR": "Overall ORR of 49.5% (95% CI, 42.2\u201356.9)",
            "AE": "All-grade AEs of 86.2% (95% CI, 61.2\u2013100) and \u2265G3 of 18.0% (95% CI, 1.2\u201334.7)",
            "Other": "DCR of 90.2% (95% CI, 86.2\u201394.3), median DOR of 17.4 months (95% CI, 7.9\u201322.7), intracranial ORR of 50.3% (95% CI, 30.3\u201370.3), intracranial DCR of 92.8% (95% CI, 76.2\u2013100), and median intracranial PFS of 6.1 months (95% CI, 4.5\u20136.6). Subgroup analysis showed ORR differences in G719X (28.8%), S768I (33.3%), and L861X (67.7%) mutations.",
            "Conclusion": "This systematic review and meta-analysis suggest that patients with NSCLC with uncommon tumoral EGFR mutations may benefit from osimertinib treatment. Patients harboring tumoral L861 mutation achieved a significantly higher ORR compared to the modest ORR in G719X, S768I cases."
        },
        {
            "Title": "Double lung transplantation in patients with metastatic lung-limited non-small cell lung carcinoma (NSCLC): A case series.",
            "Indication": "Metastatic lung-limited Non-Small Cell Lung Carcinoma (NSCLC)",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Six patients underwent double lung transplantation (DLT) successfully without significant complications. All patients are alive with 28, 18, 8, 8, 5, and 4-month post-transplant follow-up. One patient had a recurrence at 18 months treated with SBRT.",
            "Conclusion": "Six patients underwent DLT successfully without significant complications. All patients were alive and in good health. Ongoing DREAM study will continue to investigate the role of DLT in metastatic lung-limited NSCLC patients (especially in pN0)."
        },
        {
            "Title": "The clinical utility of a circulating tumor cell based cerebrospinal fluid assay in the diagnosis and molecular analysis of leptomeningeal disease in patients with metastatic non-small cell lung cancer.",
            "Indication": "Leptomeningeal disease in metastatic non-small cell lung cancer (mNSCLC)",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Higher sensitivity to diagnose LMD with CNSide assay; 38% of LMD cases missed by standard CSF cytology; 15% of cases missed by both CSF cytology and MRI; High concordance with tissue-based molecular testing.",
            "Conclusion": "Multigene molecular analysis of CSF CTCs can be a useful tool for diagnosis, monitoring of response to therapy, and identification of therapeutic targets specific to the CSF, especially for patients with oncogene-driven mNSCLC."
        },
        {
            "Title": "Comparing rates of immune checkpoint inhibitor toxicity in different treatment settings (neoadjuvant, adjuvant, and palliative) in non-small cell lung cancer (NSCLC): A meta-analysis of phase III clinical trials.",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "Immune checkpoint inhibitors (ICIs)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Palliative trials: 9.5% grade 3-5 irAEs, 35.8% all grade irAEs; Neoadjuvant: 3.5% grade 3-5 irAEs, 16.8% all grade irAEs; Peri-operative: 5.4% grade 3-5 irAEs, 24.47% all grade irAEs; Adjuvant: 7% grade 3-5 irAEs, 38.52% all grade irAEs.",
            "Other": "Relative risk (RR) between palliative and neoadjuvant setting of grade 3-5 irAE is 2.35 (95% CI: 1.54 to 3.59; p=0.0001); RR of any irAE is 2.03 (95% CI: 1.69 to 2.43; p=0.0001).",
            "Conclusion": "Neoadjuvant, peri-operative, and adjuvant ICIs are associated with a lower rate of irAEs than that seen in palliative trials. A neoadjuvant-only approach is associated with the lowest rates of irAEs, which may be due to the lower number of cycles received."
        },
        {
            "Title": "Immunotherapy outcomes and profile in lung cancer brain metastases.",
            "Indication": "Brain metastasis in non-small cell lung cancer (NSCLC)",
            "Drug": "Immune checkpoint inhibitors (ICIs)",
            "PFS": "None",
            "OS": "BM: 24.4 months vs. PT: 19.7 months (HR=0.83, 95% CI 0.76-0.90, p<0.05)",
            "ORR": "None",
            "AE": "None",
            "Other": "Higher TMB and LOH rates in BM vs. PT; Significant mutational differences in BM vs. PT; Longer IO-OS and TOT in BM compared to PT; PD-L1+ and TMB High associated with longer IO-OS in BM vs. PT.",
            "Conclusion": "BM has higher TMB, increased infiltration of dendritic cells, and higher LOH than PT. After stratifying patients based on PD-L1 and TMB, patients with BM adenocarcinoma histology had improved post-ICI survival."
        },
        {
            "Title": "Bevacizumab in combination with chemotherapy for treating patients with advanced RET+ non-small cell lung cancer.",
            "Indication": "Advanced RET fusion-positive (RET+) non-small cell lung cancer (NSCLC)",
            "Drug": "Bevacizumab with platinum-doublet chemotherapy",
            "PFS": "D+Bev: 17 months vs. D: 8.8 months (p=0.018); D+Bev vs. D+ICB: 17 months vs. 9.7 months (p=0.05)",
            "OS": "D+Bev: 51.4 months vs. D: 38.7 months (p=0.89); M+Bev: 12.1 months vs. M: 39 months (p=0.06)",
            "ORR": "D+Bev: 55% vs. D: 47% (p=0.7); M+Bev: 13% vs. M: 25% (p=0.6)",
            "AE": "None",
            "Other": "Bev use independently associated with longer PFS (HR 0.4, p=0.01); ECOG >1 (HR 4.3, p=0.0001) and male sex (HR 1.7, p=0.01) associated with shorter PFS.",
            "Conclusion": "Bevacizumab with platinum-doublet chemotherapy seems to outperform platinum-doublet with or without ICB in progression-free survival. Prospective research is needed to evaluate the extent of bevacizumab\u2019s benefit for RET+ NSCLC."
        },
        {
            "Title": "Phase I/II trial of healthy donor fecal microbiota transplant (hdFMT) in PD-1 relapsed/refractory (R/R) non-small cell lung cancer (NSCLC).",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "Healthy donor fecal microbiota transplant (hdFMT) with pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "Primary endpoint: overall response rate (ORR) assessed using RECIST v1.1 by Blinded Independent Central Review (BICR)",
            "AE": "None",
            "Other": "Key translational endpoints include immune activation (tumor tissue, blood), metagenomic engraftment, and transcriptomic analyses of intestinal luminal myeloid cells and exfoliome.",
            "Conclusion": "Enrollment has commenced. The study aims to test the hypothesis that hdFMT may resensitize NSCLC to PD-1 blockade."
        },
        {
            "Title": "SUNRAY-01, a pivotal, global study of olomorasib (LY3537982) in combination with pembrolizumab with or without chemotherapy for 1L treatment in KRAS G12C-mutant advanced NSCLC.",
            "Indication": "KRAS G12C-mutant advanced non-small cell lung cancer (NSCLC)",
            "Drug": "Olomorasib in combination with pembrolizumab with or without chemotherapy",
            "PFS": "Primary objective: compare efficacy based on PFS per RECIST v1.1 by blinded independent central review",
            "OS": "Secondary endpoint",
            "ORR": "Secondary endpoint",
            "AE": "Secondary endpoint: safety and tolerability",
            "Other": "Secondary endpoints include DOR, DCR, TTR, PFS2, and patient-reported outcomes.",
            "Conclusion": "The study opened for enrollment in December 2023."
        },
        {
            "Title": "AFNT-211: A phase 1 study of autologous CD4+ and CD8+ T cells engineered to express a high avidity HLA-A*11:01-restricted, KRAS G12V-specific, transgenic TCR, a CD8a/b coreceptor, and a FAS-41BB switch receptor in patients with advanced/metastatic solid tumors.",
            "Indication": "Advanced/metastatic solid tumors with KRAS G12V mutation",
            "Drug": "AFNT-211",
            "PFS": "Secondary endpoint",
            "OS": "Secondary endpoint",
            "ORR": "Secondary endpoint: preliminary antitumor activity",
            "AE": "Primary endpoint: safety/tolerability",
            "Other": "The study consists of dose escalation and dose expansion parts, guided by the Bayesian optimal interval Phase 1/2 (BOIN12) design.",
            "Conclusion": "The study is open for recruitment in the United States."
        },
        {
            "Title": "Krascendo-170Lung: A phase Ib/II study of divarasib + pembrolizumab + platinum-based chemotherapy and pemetrexed in untreated KRAS G12C+ advanced non-small cell lung cancer (NSCLC).",
            "Indication": "KRAS G12C+ advanced non-small cell lung cancer (NSCLC)",
            "Drug": "Divarasib + pembrolizumab + platinum-based chemotherapy and pemetrexed",
            "PFS": "Key secondary endpoint",
            "OS": "None",
            "ORR": "Key secondary endpoint",
            "AE": "Co-primary endpoint: adverse events",
            "Other": "Co-primary endpoint: change from baseline in targeted safety parameters",
            "Conclusion": "Enrollment into the combination dose finding stage of Cohort A has been completed without dose-limiting toxicities and enrollment into the dose expansion stage is continuing."
        },
        {
            "Title": "eVOLVE-Lung02: A phase 3 study of first-line (1L) volrustomig plus chemotherapy (CT) versus pembrolizumab plus CT in metastatic non-small-cell lung cancer (mNSCLC) with PD-L1 tumor cell (TC) expression <50%.",
            "Indication": "Metastatic non-small-cell lung cancer (mNSCLC) with PD-L1 tumor cell (TC) expression <50%",
            "Drug": "Volrustomig plus chemotherapy (CT) versus pembrolizumab plus CT",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Enrollment is ongoing."
        },
        {
            "Title": "CodeBreaK 202: Sotorasib versus pembrolizumab in combination with platinum doublet chemotherapy as first-line treatment for metastatic or locally advanced, PD-L1 negative, KRAS G12C-mutated NSCLC.",
            "Indication": "Metastatic or locally advanced, PD-L1 negative, KRAS G12C-mutated non-small-cell lung cancer (NSCLC)",
            "Drug": "Sotorasib versus pembrolizumab in combination with platinum doublet chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Recruitment began on November 18, 2023."
        },
        {
            "Title": "Beamion LUNG-2: A phase III randomized controlled trial of zongertinib (BI 1810631) versus standard of care (SoC) in patients with locally advanced/metastatic non-squamous non-small cell lung cancer (NSCLC) harboring HER2 tyrosine kinase domain (TKD) mutations.",
            "Indication": "Locally advanced/metastatic non-squamous non-small cell lung cancer (NSCLC) harboring HER2 tyrosine kinase domain (TKD) mutations",
            "Drug": "Zongertinib versus standard of care (SoC)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Enrollment is ongoing."
        },
        {
            "Title": "TRITON: Phase 3b study of tremelimumab (T) + durvalumab (D) vs pembrolizumab (P), in combination with chemotherapy (CT), in non-squamous (NSQ) metastatic NSCLC (mNSCLC) with STK11 and/or KEAP1 and/or KRAS mutations.",
            "Indication": "Non-squamous (NSQ) metastatic non-small-cell lung cancer (mNSCLC) with STK11 and/or KEAP1 and/or KRAS mutations",
            "Drug": "Tremelimumab (T) + durvalumab (D) vs pembrolizumab (P) in combination with chemotherapy (CT)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Enrollment is ongoing."
        },
        {
            "Title": "A phase 3 global study of telisotuzumab vedotin versus docetaxel in previously treated patients with c-Met overexpressing, EGFR wildtype, nonsquamous NSCLC (TeliMET NSCLC-01).",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "Telisotuzumab vedotin",
            "PFS": "None",
            "OS": "None",
            "ORR": "37% (95% CI: 24\u201351) in previously treated patients with c-Met overexpressing EGFR wildtype nonsquamous NSCLC and 52% (95% CI: 31\u201373) in the high c-Met overexpressing group",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "SWOG S2302, PRAGMATICA-LUNG: A prospective randomized study of ramucirumab plus pembrolizumab (PR) versus standard of care (SOC) for locally advanced/metastatic nonsquamous NSCLC.",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "Ramucirumab plus pembrolizumab",
            "PFS": "None",
            "OS": "Primary objective is to compare OS between the arms",
            "ORR": "None",
            "AE": "Summarize serious and unexpected high-grade (Grade 3-4) treatment-related adverse events and all Grade 5 adverse events",
            "Other": "None",
            "Conclusion": "Pragmatic trial designs may overcome challenges with accrual and representativeness in the appropriate setting."
        },
        {
            "Title": "Phase 1/2 clinical trial of JIN-A02, a 4th generation EGFR-TKI, in patients with 3rd generation EGFR-TKI resistance in EGFR mutated advanced/metastatic non-small cell lung cancer (NSCLC).",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "JIN-A02",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The strong potency and high selectivity of JIN-A02 for mutant EGFR may offer an improved therapeutic window in the clinical setting vs available EGFR-TKIs."
        },
        {
            "Title": "Randomized phase III study comparing suspension or continuation of PD-1 pathway blockade for patients with advanced non-small-cell lung cancer (SAVE study: JCOG1701).",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "PD-1 pathway inhibitors (nivolumab, pembrolizumab, atezolizumab)",
            "PFS": "None",
            "OS": "Primary endpoint is OS",
            "ORR": "None",
            "AE": "Safety",
            "Other": "None",
            "Conclusion": "The findings of the JCOG1701 will provide data for making more informed treatment decisions in advanced NSCLC, specifically related to the continuation or suspension of ICIs."
        },
        {
            "Title": "A phase 2/3 study of fianlimab, cemiplimab, plus chemotherapy versus cemiplimab plus chemotherapy in first-line treatment of advanced non-small cell lung cancer.",
            "Indication": "Advanced non-small cell lung cancer (NSCLC)",
            "Drug": "Fianlimab, cemiplimab, chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "Primary endpoint of Phase 2",
            "AE": "None",
            "Other": "Secondary endpoints include efficacy (ORR, disease control rate, time to tumor response, duration of response, progression-free survival, and overall survival), safety and tolerability, immunogenicity, patient-reported outcomes, and pharmacokinetics.",
            "Conclusion": "This ongoing study will further investigate the combination of fianlimab + cemiplimab + chemotherapy."
        },
        {
            "Title": "A phase 1/2, open-label study of amivantamab in combination with tyrosine kinase inhibitors among participants with advanced NSCLC harboring ALK, ROS1, and RET gene fusions.",
            "Indication": "Advanced NSCLC harboring ALK, ROS1, and RET gene fusions",
            "Drug": "Amivantamab, tyrosine kinase inhibitors (TKIs)",
            "PFS": "None",
            "OS": "None",
            "ORR": "Primary endpoint",
            "AE": "None",
            "Other": "Primary endpoint will be dose-limiting toxicity (DLT) within the first cycle of dosing at a given dose level. The study will have both a safety lead-in cohort and a dose expansion cohort.",
            "Conclusion": "The study has initiated enrollment without DLT and is ongoing."
        },
        {
            "Title": "TOP: A phase III, multicentre, randomized controlled study comparing osimertinib in combination with carboplatin and pemetrexed to osimertinib monotherapy for untreated patients with advanced non-squamous (Non-sq) non-small cell lung cancer (NSCLC) with concurrent EGFR and TP53 mutations.",
            "Indication": "Advanced non-squamous NSCLC with concurrent EGFR and TP53 mutations",
            "Drug": "Osimertinib, carboplatin, pemetrexed",
            "PFS": "Primary endpoint",
            "OS": "Secondary endpoint",
            "ORR": "Secondary endpoint",
            "AE": "Secondary endpoint",
            "Other": "Secondary endpoints include duration of response, adverse events, and quality of life. Exploratory endpoint is correlation between concurrent mutations other than TP53 and efficacy, and the resistance mechanisms to combination therapy revealed by dynamic ctDNA profiling.",
            "Conclusion": "The study aims to determine whether osimertinib plus chemotherapy could improve efficacy compared with osimertinib monotherapy in patients with concurrent EGFR and TP53 mutations."
        },
        {
            "Title": "A phase 2/3 study of fianlimab plus cemiplimab versus cemiplimab in patients with advanced non-small cell lung cancer with tumors expressing PD-L1 \u226550%.",
            "Indication": "Advanced non-small cell lung cancer with tumors expressing PD-L1 \u226550%",
            "Drug": "Fianlimab, cemiplimab",
            "PFS": "Secondary endpoint",
            "OS": "Primary endpoint of Phase 3",
            "ORR": "Primary endpoint of Phase 2",
            "AE": "Secondary endpoint",
            "Other": "Secondary endpoints include safety, ORR, disease control rate, time to tumor response, duration of response, PFS, patient-reported outcomes, pharmacokinetics, and immunogenicity.",
            "Conclusion": "This study is open for enrollment along with a second study of fianlimab + cemiplimab + chemotherapy in aNSCLC."
        },
        {
            "Title": "LUNAR-2: Pivotal, randomized, open-label study of tumor treating fields (TTFields, 150kHz) concomitant with pembrolizumab and platinum-based chemotherapy for the treatment of metastatic non-small cell lung cancer.",
            "Indication": "Metastatic non-small cell lung cancer (NSCLC)",
            "Drug": "Tumor Treating Fields (TTFields), Pembrolizumab, Platinum-based chemotherapy",
            "PFS": "None",
            "OS": "3.3-month improvement in median overall survival (OS) vs an immune checkpoint inhibitor (ICI) or docetaxel alone",
            "ORR": "None",
            "AE": "No added systemic toxicities and no clinically significant difference on quality of life between groups",
            "Other": "None",
            "Conclusion": "TTFields with an immune checkpoint inhibitor or docetaxel provided a statistically significant and clinically meaningful improvement in median overall survival with no added systemic toxicities."
        },
        {
            "Title": "FURTHER: A global study to evaluate furmonertinib in patients with EGFR mutant NSCLC including uncommon EGFR mutations (FURMO-002).",
            "Indication": "Non-small cell lung cancer (NSCLC) with EGFR and HER2 mutations",
            "Drug": "Furmonertinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "Primary endpoint for Stage 2 is ORR using RECIST v1.1",
            "AE": "Primary endpoint for Stage 1 is incidence and severity of adverse events, including dose limiting toxicities",
            "Other": "None",
            "Conclusion": "Developing efficacious, well-tolerated, and CNS-penetrant medicines for NSCLC patients with EGFR ex20ins mutations and PACC mutations remains an unmet need."
        },
        {
            "Title": "A phase 2 study of EIK1001, a Toll-like receptor 7/8 (TLR7/8) agonist, in combination with pembrolizumab and chemotherapy in patients with stage 4 non-small cell lung cancer.",
            "Indication": "Stage 4 non-small cell lung cancer (NSCLC)",
            "Drug": "EIK1001, Pembrolizumab, Chemotherapy (Carboplatin plus either Pemetrexed or Paclitaxel)",
            "PFS": "None",
            "OS": "None",
            "ORR": "Evaluation of antitumor activity by objective response rate (ORR; defined as the proportion of patients who have a confirmed complete response [CR] or partial response [PR] per RECIST 1.1)",
            "AE": "Primary objective is to determine the safety and tolerability of EIK1001 in combination with pembrolizumab and chemotherapy",
            "Other": "None",
            "Conclusion": "There remains a critical unmet need to develop therapies for the treatment of NSCLC. This study aims to determine the safety and tolerability of EIK1001 in combination with pembrolizumab and chemotherapy."
        },
        {
            "Title": "FURVENT: Phase 3 trial of furmonertinib vs chemotherapy as first-line treatment for advanced NSCLC with EGFR exon 20 insertion mutations (FURMO-004)",
            "Indication": "Advanced NSCLC with EGFR exon 20 insertion mutations",
            "Drug": "Furmonertinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "78.6% (n=28) by blinded independent central review (BICR)",
            "AE": "None",
            "Other": "Preliminary median DOR of 15.2 months when treated with 240mg daily [QD] of furmonertinib",
            "Conclusion": "Furmonertinib showed a generally well-tolerated safety profile with expected EGFR-TKI class toxicities. The primary endpoint is progression-free survival comparing between the treatment arms (furmonertinib 160mg or 240mg vs chemotherapy) based on BICR assessment. The key secondary endpoint is overall survival. Study enrollment is ongoing."
        },
        {
            "Title": "A biomarker-directed, multi-center phase II/III study of ctDNA molecular response-adaptive immuno-chemotherapy in patients with non-small cell lung cancer",
            "Indication": "Non-small cell lung cancer",
            "Drug": "Pembrolizumab and chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The primary endpoint of the phase II portion is PFS with secondary endpoints of feasibility, overall response rate, and safety/tolerability. The primary endpoint of the phase III portion is OS with secondary endpoints of best overall response, response duration, PFS, and safety/tolerability.",
            "Conclusion": "The trial aims to evaluate if adding chemotherapy to pembrolizumab for patients who have persistent ctDNA on liquid biopsy drawn after 6 weeks of treatment will result in better PFS and OS compared to patients who remain on pembrolizumab until clinical progression."
        },
        {
            "Title": "REZILIENT2: Phase 2 study of zipalertinib in patients with advanced non-small cell lung cancer (NSCLC) with exon 20 insertions (ex20ins) and other uncommon epidermal growth factor receptor (EGFR) mutations",
            "Indication": "Advanced non-small cell lung cancer (NSCLC) with exon 20 insertions (ex20ins) and other uncommon EGFR mutations",
            "Drug": "Zipalertinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The primary endpoint is investigator-assessed objective response rate per Response Evaluation Criteria in Solid Tumours, version 1.1. Secondary endpoints include disease control rate, duration of response, progression-free survival, overall survival, intracranial efficacy per Response Assessment in Neuro-Oncology Brain Metastases (cohort C), safety and tolerability, and pharmacokinetics.",
            "Conclusion": "The aim of REZILIENT2 is to evaluate the efficacy and safety of zipalertinib in patients with NSCLC harboring EGFR ex20ins and other uncommon single or compound EGFR mutations, including patients with brain metastases and leptomeningeal disease (LMD)."
        },
        {
            "Title": "REZILIENT3: Phase 3 study of zipalertinib plus chemotherapy in patients with previously untreated, advanced nonsquamous non-small cell lung cancer (NSCLC) harboring epidermal growth factor receptor (EGFR) exon 20 insertions (ex20ins) mutations",
            "Indication": "Previously untreated, advanced nonsquamous non-small cell lung cancer (NSCLC) harboring EGFR exon 20 insertions (ex20ins) mutations",
            "Drug": "Zipalertinib plus chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The primary objective in Part A is safety and tolerability. In Part B, the primary objective is efficacy; progression-free survival assessed by blinded independent central review is the primary endpoint. Secondary endpoints include overall survival, investigator-assessed progression-free survival, objective response rate, duration of response, disease control rate, safety, intracranial efficacy endpoints, and quality of life.",
            "Conclusion": "REZILIENT3 is a pivotal phase 3 study designed to compare the efficacy and safety of zipalertinib plus first-line standard-of-care platinum-based chemotherapy versus chemotherapy alone in previously untreated patients with nonsquamous NSCLC harboring EGFR ex20ins mutations."
        },
        {
            "Title": "Long-term follow up for adjuvant dabrafenib plus trametinib in stage III BRAF-mutated melanoma: Final results of the COMBI-AD study.",
            "Indication": "Stage III BRAF-mutated melanoma",
            "Drug": "Dabrafenib plus trametinib",
            "PFS": "None",
            "OS": "Median OS was not attained in the two arms (HR: 0.80; 95% CI: 0.62, 1.01; P=0.063). Consistent OS benefits were seen across most prespecified subgroups including patients with BRAF V600E mutation (n=397; HR: 0.75; 95% CI: 0.58, 0.96).",
            "ORR": "None",
            "AE": "Safety profile was consistent with previous reports.",
            "Other": "Estimated RFS (HR: 0.52; 95% CI: 0.43, 0.63) and DMFS (HR: 0.56; 95% CI: 0.44, 0.71) favored the dabrafenib plus trametinib arm.",
            "Conclusion": "COMBI-AD presents the longest follow-up data (over 10 years) in adjuvant treatment of stage III melanoma in the modern era. OS was improved with dabrafenib plus trametinib over placebo for adjuvant treatment of stage III melanoma with a 20% risk reduction for death. However, this difference was not statistically significant. Consistent with published results at 3 and 5 years, RFS and DMFS were more favorable in the treatment vs. placebo arm."
        },
        {
            "Title": "A randomised, controlled, multicentre trial of imiquimod versus radiotherapy for lentigo maligna.",
            "Indication": "Lentigo maligna",
            "Drug": "Imiquimod versus radiotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "There were no differences between trial groups in patient-reported skin symptoms (itching, burning/stinging, pain, irritation), skin-specific emotional and functional QOL impacts, or generic QOL at any time points.",
            "Other": "Six (10.5%) patients in the imiquimod group and 12 (24.0%) patients in the radiotherapy group developed recurrence within 24 months (OR 2.68, 95% CI, 0.92-7.8, p=0.063). Median time to recurrence was not reached in either group. Treatment failure was significantly different within the subgroup of patients who had reflectance confocal microscopy (RCM) follow up of the LM (imiquimod 4/46, 8.7% radiotherapy 13/52 25.0%; OR 3.50 95% CI 1.05-11.65, p=0.033). Only one RCM feature was associated with failure at 24 months and significantly different between treatment groups: the presence of atypical round cells at the dermal-epidermal junction (6.9% of imiquimod failure and 27% of radiotherapy failure: p=0.035).",
            "Conclusion": "Both imiquimod and radiotherapy are valid, well-tolerated and efficient non-surgical options for LM. There is no significant difference in the acute or long-term QoL for the two treatments."
        },
        {
            "Title": "Efficacy and safety of triplet nivolumab, relatlimab, and ipilimumab (NIVO+RELA+IPI) in advanced melanoma: Results from RELATIVITY-048.",
            "Indication": "Advanced melanoma",
            "Drug": "Nivolumab, Relatlimab, Ipilimumab",
            "PFS": "Median PFS: NR (3.94\u2013NR); 24-mo PFS rate: 57.2% (40.8\u201370.5); 48-mo PFS rate: 51.6% (35.3\u201365.6)",
            "OS": "Median OS: NR (NR\u2013NR); 24-mo OS rate: 79.8% (64.8\u201389.0); 48-mo OS rate: 69.1% (52.6\u201380.9)",
            "ORR": "Confirmed ORR: 58.7% (43.2\u201373.0); Confirmed CR/PR/SD rates: 17.4%/41.3%/17.4%",
            "AE": "Any-grade TRAEs: 95.7%; Grade 3/4 TRAEs: 39.1%; Treatment discontinuation due to TRAEs: 41.3%; Deaths due to TRAEs: 4.3%",
            "Other": "Confirmed DCR: 76.1% (61.2\u201387.4); Median duration of treatment: 5.0 months (range, 0.0\u201349.0); Median duration of response: NR (NR\u2013NR)",
            "Conclusion": "NIVO+RELA+IPI demonstrated encouraging efficacy with a confirmed ORR of 58.7% and a 48-mo OS rate of 69.1%. There were no new safety signals with NIVO+RELA+IPI, and the safety profile was generally consistent with I-O combinations. Given the small sample size, additional studies are needed to confirm the efficacy and safety of NIVO+RELA+IPI."
        },
        {
            "Title": "Efficacy and safety of lifileucel, an autologous tumor-infiltrating lymphocyte cell therapy, and pembrolizumab in patients with immune checkpoint inhibitor-naive unresectable or metastatic melanoma: Updated results from IOV-COM-202 cohort 1A.",
            "Indication": "Immune checkpoint inhibitor-naive unresectable or metastatic melanoma",
            "Drug": "Lifileucel, Pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "Confirmed ORR: 63.6% (14/22); CR: 22.7% (5/22); PR: 40.9% (9/22); SD: 27.3% (6/22)",
            "AE": "Most common grade \u22653 TEAEs: Thrombocytopenia (68.2%), Neutropenia (50.0%), Anemia (45.5%)",
            "Other": "Median time to initial response: 2.5 months; Median duration of response: Not reached; Responses deepened over time; 71.4% (10/14) had ongoing response; 36.4% (8/14) had response \u226512 months",
            "Conclusion": "These results demonstrate encouraging efficacy and durability for the combination of lifileucel and pembrolizumab and support its further evaluation in patients with untreated advanced melanoma in the phase 3 study TILVANCE-301 (NCT05727904)."
        },
        {
            "Title": "Triplet combination treatments with pembrolizumab (pembro) for anti\u2013PD-(L)1\u2013refractory advanced melanoma: Preliminary results of the phase 1/2 KEYMAKER-U02A study.",
            "Indication": "Anti\u2013PD-(L)1\u2013refractory advanced melanoma",
            "Drug": "Pembrolizumab, Quavonlimab, Vibostolimab, Lenvatinib, ATRA",
            "PFS": "Arm 1: 2.1 months (2.1-3.7); Arm 2: 6.2 months (4.2-8.3); Arm 3: 2.1 months (1.9-2.1)",
            "OS": "Arm 1: NR (12.1-NR); Arm 2: 17.9 months (14.5-21.4); Arm 3: NR (NR-NR)",
            "ORR": "Arm 1: 9.8% (1 CR); Arm 2: 10.1% (2 CRs); Arm 3: 0%",
            "AE": "Arm 1: TRAEs in 88%, Grade 3-5 TRAEs in 28%; Arm 2: TRAEs in 95%, Grade 3-5 TRAEs in 58%, 1 death from grade 5 TRAE (immune-mediated nephritis); Arm 3: TRAEs in 75%, Grade 3-5 TRAEs in 15%",
            "Other": "None",
            "Conclusion": "Although objective responses were observed in some patients with anti\u2013PD-(L)1\u2013refractory melanoma, protocol-prespecified criteria for enrollment expansion were not met in any arm. The safety profile was manageable. Additional arms will be reported for this patient population with a high unmet need."
        },
        {
            "Title": "Phase 1 safety and efficacy of IMC-F106C, a PRAME 3 CD3 ImmTAC bispecific, in post-checkpoint cutaneous melanoma (CM).",
            "Indication": "Post-checkpoint cutaneous melanoma",
            "Drug": "IMC-F106C, Pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "Clinical benefit rate (CBR) of PR+SD: 61% (19/31); ORR: 13% (4 PRs); SD: 26% (7 SDs)",
            "AE": "Most common AE: Grade 1/2 CRS (50%); No drug-related AEs led to treatment discontinuation or death",
            "Other": "31/40 mono pts had a RECIST evaluable tumor assessment; 35% (11/31) had any tumor reduction confirmed on \u22651 subsequent scan; 41% of ctDNA-evaluable, PRAME+ mono pts had a molecular response (9/22); Correlative biomarkers will be shared",
            "Conclusion": "IMC-F106C was well tolerated with promising clinical activity in ICI-pretreated, mCM patients without clinical options. Clinical activity, measured by any confirmed tumor reduction and ctDNA molecular response, is enriched in PRAME+ patients at ~40% and associated with longer PFS and OS. IMC-F106C can be combined with anti-PD1. A Ph3 trial of IMC-F106C with nivolumab in 1st line mCM has been initiated (PRISM-MEL301; NCT06112314)."
        },
        {
            "Title": "Safety and efficacy of first-in-class CXCR1/2 inhibitor SX-682 in combination with pembrolizumab (pem) in patients (pts) with metastatic melanoma (mMEL) with disease progression on anti\u2013PD-1 therapy.",
            "Indication": "Metastatic melanoma",
            "Drug": "SX-682 and pembrolizumab",
            "PFS": "None",
            "OS": "Median OS was longer in pts in the 200mg cohort (14.7 months; 95% CI, 10.5\u2013NR [not reached], n=31) than in pts treated with \u2264100mg SX-682 (7.4 months; 95% CI, 5.0\u2013NR; n=10)",
            "ORR": "21% in 19 evaluable pts (4 PR + 0 CR)",
            "AE": "Any grade and grade 3/4 treatment-related AEs (TRAEs) occurred in 75% and 43%, respectively, and discontinuation (DC) due to TRAEs in 20%",
            "Other": "DCR was 63% (8 SD + 4 PR). DCR significantly depended on SX-682 dose (P vs. \u2264100mg): 0% (0/9) at \u2264100mg, 50% (3/6) at 150mg (P=0.0440) and 63% (12/19) at 200mg (P=0.0028).",
            "Conclusion": "SX-682 combined with pem had a tolerable safety profile and activity reflected by objective responses and clinically meaningful disease control in heavily pretreated mMEL pts with progression on anti-PD-1 and anti\u2013CTLA-4."
        },
        {
            "Title": "Phase II multi-center study of adjuvant nivolumab in combination with ipilimumab in patients with high-risk uveal melanoma (HCRN MEL17-309).",
            "Indication": "High-risk uveal melanoma",
            "Drug": "Nivolumab and ipilimumab",
            "PFS": "None",
            "OS": "Median OS has not been reached with only 7 deaths from UM in the tx cohort so far.",
            "ORR": "None",
            "AE": "Grade \u22653 any-cause AE occurred in 27 pts (54%). Grade \u22653 treatment-related (TR) AE occurred in 48%. 22 pts (44%) discontinued the treatment due to toxicity. One pt died due to immune-related myocarditis.",
            "Other": "The 3-year DMFS rate vs COOG control was 69.1% vs 45.1% (two-sided p=0.012) without any adjustment; and 70.4% vs 43.4% (two-sided p=0.018) with the propensity score method. The median DMFS was not reached (NR) in the tx cohort vs 33.8 months. There was a reduction in the risk of distant recurrence with Nivo/Ipi vs control cohort (RR 0.52, 95% CI, 0.309-0.888).",
            "Conclusion": "This phase II study of adjuvant Nivo/Ipi resulted in clinically meaningful and statistically significant improvement in 3-year DMFS of 70.4% vs 43.4% in control for pts with high-risk UM from the COOG database matched for tumor characteristics. Further investigation of this regimen as adjuvant therapy of high-risk UM is warranted."
        },
        {
            "Title": "A phase 2 safety and efficacy study of neoadjuvant/adjuvant darovasertib for localized ocular melanoma.",
            "Indication": "Localized ocular melanoma",
            "Drug": "Darovasertib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Treatment emergent adverse events included postural hypotension (Gr1/2 \u2013 13/13%), syncope (Gr3 \u2013 13%), rash (Gr1/2 \u2013 33/5%), pruritis (Gr1 \u2013 13%), dizziness (Gr1 \u2013 27%), fatigue (Gr1/2 \u2013 30/5%), nausea (Gr1/2 \u2013 73/6%), vomiting (Gr1 \u2013 40%), and diarrhea (Gr1 \u2013 60%).",
            "Other": "Median tumor shrinkage (maximum height/base/volume change) was 11.2%/7.6%/22.7% after 1 month of treatment and 31.7%/ 11.9% /45.3% after 6 months.",
            "Conclusion": "NADOM provides the first evidence that a globe-salvage neoadjuvant treatment strategy in UM is feasible, safe, and efficacious. The results suggest that PKC inhibition with darovasertib can induce clinically meaningful tumor shrinkage in patients with primary UM patients who otherwise require enucleation. Larger trials are in now progress (NCT05907954) to further quantify visual and oncological outcomes."
        },
        {
            "Title": "Safety, efficacy, and biomarker results from an open-label, multicenter, phase 1 study of RP2 alone or combined with nivolumab in a cohort of patients with uveal melanoma.",
            "Indication": "Metastatic uveal melanoma",
            "Drug": "RP2 and nivolumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "29.4% (5/17; all PRs; RP2 monotherapy, 1/3; RP2+nivo, 4/14)",
            "AE": "The most common overall grade 1\u20132 treatment-related AEs (TRAEs; \u226520% overall) were pyrexia, chills, fatigue, hypotension, and pruritus. The only grade 3 TRAE in >1 pt was hypotension (2 pts receiving RP2+nivo); no grade 4/5 TRAEs occurred.",
            "Other": "The DCR (CR+PR+SD) was 58.8% (10/17). The median (range) duration of response at the data cutoff was 11.5 (2.8\u201321.2) months, with some responses and disease stabilizations ongoing. Paired biopsies from pts with clinical benefit (n=5; 2PR, 3SD) showed an increase in tumor PD-L1 expression by IHC analysis, and 4 of these pts (2PR, 2SD) exhibited an increase in CD8+ T-cell infiltration into tumors. TCR sequencing following treatment with RP2+nivo revealed expansion of pre-existing TCRs and generation of new T-cell clones.",
            "Conclusion": "RP2 alone or combined with nivo demonstrated a favorable safety profile and meaningful antitumor activity in pts with previously treated MUM. Biomarker data indicated immune cell infiltration and increased PD-L1 expression in tumors and changes in the peripheral T-cell repertoire following RP2+nivo therapy. Based on these results, a randomized, controlled clinical development plan is being planned."
        },
        {
            "Title": "Individualized neoantigen therapy mRNA-4157 (V940) plus pembrolizumab in resected melanoma: 3-year update from the mRNA-4157-P201 high-risk, resectable melanoma (NEO-TIM): Primary results.",
            "Indication": "High-risk, resectable melanoma",
            "Drug": "mRNA-4157 (V940) plus pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "A phase 2 study of de-escalation in resectable, locally advanced cutaneous squamous cell carcinoma (cSCC) with the use of neoadjuvant pembrolizumab: De-Squamate.",
            "Indication": "Resectable, locally advanced cutaneous squamous cell carcinoma (cSCC)",
            "Drug": "Pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Investigator assessed treatment-related adverse events \u2265 grade 3 was seen in 2 patients (7%).",
            "Other": "Primary endpoint observed in 17 patients (63%, 95% CI: 42-80), comprised of PCR (15%), MPR (0%) and CCR (48%). De-escalation of both surgery and PORT was achieved in 48% and a further 15% avoided PORT alone. No patients with PCR/MPR/CCR recurred with a minimum follow-up of 6 months.",
            "Conclusion": "Pembrolizumab led to a high rate of PCR and CCR in resectable cSCC. The de-squamate study design effectively selected patients for surgical and PORT de-escalation and demonstrated a sustained response in this cohort."
        },
        {
            "Title": "OBX-115, an interleukin 2 (IL2)-sparing engineered tumor-infiltrating lymphocyte (TIL) cell therapy, in patients (pts) with immune checkpoint inhibitor (ICI)-resistant unresectable or metastatic melanoma.",
            "Indication": "ICI-resistant unresectable or metastatic melanoma",
            "Drug": "OBX-115",
            "PFS": "None",
            "OS": "None",
            "ORR": "ORR per RECIST v1.1 was 50% (2 CR, 1 PR, 3 SD); all responses occurred between Week 6\u201318 and were ongoing, with longest response sustained 12 months.",
            "AE": "No DLTs, 3 Gr3 non-hematologic TEAEs in 2 patients (abdominal pain, ALT elevation, syncope), and no Gr4 non-hematologic TEAEs.",
            "Other": "OBX-115 was successfully manufactured for all patients, including from CNB tumor tissue (n=5). The infusion product had a high proportion of CD8+ cells (\u226596%) and stem-like cells (CD8+CD39-CD69-; median 76%) with very low PD-1 expression in the CD8+ population (<1%). Post-infusion safety included no DLTs, 3 Gr3 non-hematologic TEAEs in 2 patients (abdominal pain, ALT elevation, syncope), and no Gr4 non-hematologic TEAEs. ACZ was redosed and well-tolerated in 4 of 5 eligible patients. Among patients with minimum 12-week follow-up (n=6), ORR per RECIST v1.1 was 50% (2 CR, 1 PR, 3 SD); all responses occurred between Week 6\u201318 and were ongoing, with longest response sustained 12 months. One patient developed new metastatic disease (liver) and progressed at Week 24, despite continued target lesion reduction; no patient developed brain metastasis. Post-infusion ctDNA was not detectable in any of the responders at Day 14 or 42. Though OBX-115 had minimal NK cells (<1%), post-infusion peripheral blood and tumor biopsies showed NK cell expansion, consistent with trans-presentation of mbIL15 to circulating NK cells.",
            "Conclusion": "OBX-115 regulatable engineered TIL cell therapy was well-tolerated and produced consistently deepening and durable responses, indicating that OBX-115 may mediate CRs and durable clinical benefit in ICI-resistant metastatic melanoma without high-dose IL2. OBX-115 investigation continues in this and an ongoing Phase 1/2 multicenter study (NCT06060613)."
        },
        {
            "Title": "Phase III randomized trial evaluating tilsotolimod in combination with ipilimumab versus ipilimumab alone in patients with advanced refractory melanoma (ILLUMINATE-301).",
            "Indication": "Advanced refractory melanoma",
            "Drug": "Tilsotolimod in combination with ipilimumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "Efficacy and safety of RP1 combined with nivolumab in patients with anti\u2013PD-1\u2013failed melanoma from the IGNYTE clinical trial.",
            "Indication": "Anti\u2013PD-1\u2013failed melanoma",
            "Drug": "RP1 combined with nivolumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "Overall ORR was 31.4%, with 12.2% achieving complete response. ORR for primary anti\u2013PD-1 resistance was 34.1%. ORR for prior ipilimumab+nivolumab failure was 26.4%.",
            "AE": "Treatment-related adverse events were predominantly grade 1\u20132; there was 1 grade 5 TRAE (immune-mediated myocarditis attributed to nivolumab).",
            "Other": "Responses were observed in both injected and uninjected lesions, including visceral lesions. Median duration of response was >24 months, with 100% of responses lasting >6 months from baseline; 78% of responses were ongoing.",
            "Conclusion": "RP1+nivolumab provides durable and clinically meaningful antitumor activity in patients with anti\u2013PD-1\u2013failed melanoma. The combination continues to be well tolerated."
        },
        {
            "Title": "A phase 2 clinical trial of regorafenib in patients with advanced pretreated melanoma (RegoMel).",
            "Indication": "Advanced pretreated melanoma",
            "Drug": "Regorafenib",
            "PFS": "Median PFS was 11.9 weeks [95% CI 3.5-20.3]",
            "OS": "Median OS was not reached",
            "ORR": "Overall ORR was 31% (5 partial responses), with a median duration of response of 29.7 weeks (range 12-44). Disease control rate was 56%.",
            "AE": "No grade \u22654 treatment-related adverse events; 56% of patients had at least one grade 3 TRAE, including hypertension (n=3) and maculo-papular rash (n=2). One toxicity-related regorafenib discontinuation.",
            "Other": "Continuation of regorafenib in combination with BRAF-/MEK-inhibitors demonstrated promising activity in patients who previously progressed on BRAF-/MEK-inhibitors.",
            "Conclusion": "This phase 2 clinical trial on regorafenib monotherapy in advanced pretreated melanoma patients met its primary endpoint and demonstrated significant anti-tumor activity in KIT-mutant melanoma patients. Further prospective investigation is ongoing."
        },
        {
            "Title": "Outcomes following long-term response to immune checkpoint inhibitors in patients with advanced melanoma",
            "Indication": "Advanced melanoma",
            "Drug": "Immune checkpoint inhibitors (ICI) targeting PD-1 and CTLA-4",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The risk of late progression was lower in those who achieved a complete response (CR) compared to those without a CR (6.2% vs 15.2%, P<0.001). The landmark 5-year PFS was 95% compared to 89% in those with or without a CR respectively.",
            "Conclusion": "In this population of patients with advanced melanoma with long-term disease control from ICI, the risk of subsequent disease progression and death was low (1.2%). Predictors for late progression after 3 years were a non-CR best response and prior progression within 3 years. This and other data suggest a significant proportion of long-term ICI responders are likely cured, and may inform frequency and duration of follow-up."
        },
        {
            "Title": "When to stop immunotherapy for advanced melanoma: Emulation of target trials",
            "Indication": "Advanced melanoma",
            "Drug": "Immune checkpoint inhibitors (ICI)",
            "PFS": "None",
            "OS": "The 48-month survival difference was 37.8% (95% CI 19.8 to 60.5) for the 6-month discontinuation emulated trial. The corresponding restricted mean survival time difference was 8.3 months (95% CI: 4.1 to 12.7).",
            "ORR": "None",
            "AE": "None",
            "Other": "The 12-month and 18-month discontinuation emulated trials both showed no evidence of benefit of either discontinuing or continuing ICI at any of those time points. The 24-month discontinuation emulated trial results were more in favor of stopping compared to continuing treatment at that decision point, with an absolute 48-month survival 10.5% higher (95% CI 4.4 to 18.1).",
            "Conclusion": "These results suggest that a one-year course of immunotherapy is both necessary and sufficient for patients with advanced melanoma. Prolonged treatment beyond 2 years does not appear to be beneficial in terms of survival and could even be detrimental."
        },
        {
            "Title": "Continuous intrathecal infusion of nivolumab in advanced melanoma with leptomeningeal disease",
            "Indication": "Advanced melanoma with leptomeningeal disease",
            "Drug": "Nivolumab",
            "PFS": "None",
            "OS": "The median overall survival (OS) was 2.5 (1.1-NA) months. OS at 3, 12, and 19 months were 36.4% (16.6-79.5), 27.3% (10.4-71.6), and 13.6% (10.4-71.6), respectively.",
            "ORR": "None",
            "AE": "Treatment-related adverse events (all reversible) occurred in 5 patients: hemorrhage on catheter path (n=1, grade 2), immune-mediated meningoencephalitis (n=1, grade 3), intracranial hypotension syndrome (n=3, grade 2).",
            "Other": "Median nivolumab concentrations in CSF were comparable to through plasma concentrations at steady state after 3 mg/kg IV infusion. High plasma concentrations suggest rapid clearance of nivolumab from the CSF, possibly related to reverse transcytosis of immunoglobulins.",
            "Conclusion": "Survival data are slightly inferior to those previously published. However, 3 patients had prolonged survival (>=1 year)."
        },
        {
            "Title": "FDG-PET associations with pathological response and survival with neoadjuvant immunotherapy for melanoma",
            "Indication": "Stage III melanoma",
            "Drug": "Pembrolizumab and lenvatinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "All 8 (24%) patients achieving CMR or near-CMR obtained major pathologic response (MPR), while the 14 patients (42%) with PMR had variable pathological outcomes (57% MPR, 21% pPR, 21% pNR). For the 6 patients with PMD, 5 (83%) had pNR and the one patient who achieved pCR recurred with brain metastases.",
            "AE": "None",
            "Other": "After a median 29.9 months of follow-up (95% CI 27.0-33.1), PET response associated with RFS; those with CMR/near-CMR had 100% 24-month RFS (nil recurrences to date) while those with PMR or SMD had inferior survival (79% and 80% 24-month RFS), and those with PMD the worst outcomes (17% 24-month RFS, all patients except one have recurred) (p=0.0029).",
            "Conclusion": "FDG-PET demonstrates utility in predicting pathological response and survival with neoadjuvant immunotherapy in melanoma. PET may identify patients who are not going to be pathological responders and who have the worst survival outcomes, enabling a potential switch of neoadjuvant systemic therapy."
        },
        {
            "Title": "Nivolumab (NIVO) plus relatlimab (RELA) vs NIVO in previously untreated metastatic or unresectable melanoma (RELATIVITY-047): Overall survival (OS) and melanoma-specific survival (MSS) outcomes at 3 years.",
            "Indication": "Metastatic or unresectable melanoma",
            "Drug": "Nivolumab (NIVO) plus relatlimab (RELA)",
            "PFS": "10.2 months (95% CI: 6.5\u201315.4) / 4.6 months (95% CI: 3.5\u20136.5), HR: 0.79 (95% CI: 0.66\u20130.95)",
            "OS": "Not reached / 46.7 months (95% CI: 34.1\u2013NR), HR: 0.75 (95% CI: 0.60\u20130.94)",
            "ORR": "43.7% (95% CI: 38.4\u201349.0) / 33.7% (95% CI: 28.8\u201338.9)",
            "AE": "Grade 3\u20134 TRAEs: 22% / 12%, TRAEs leading to discontinuation: 18% / 10%",
            "Other": "MSS: Not reached / 46.7 months (95% CI: 34.1\u2013NR), HR: 0.75 (95% CI: 0.60\u20130.94)",
            "Conclusion": "At 3 years of follow-up, NIVO+RELA continued to show a benefit vs NIVO for PFS, OS, ORR, and MSS. Safety of NIVO+RELA remained consistent with previous reports, with no new or unexpected safety signals."
        },
        {
            "Title": "Randomized phase II evaluation of nivolumab (nivo), or relatlimab (rela), or combined nivo-rela lead-in followed by nivo-rela as first line therapy for patients (pts) with advanced melanoma (mel).",
            "Indication": "Advanced melanoma",
            "Drug": "Nivolumab (nivo), relatlimab (rela), combined nivo-rela",
            "PFS": "6.5 months (95% CI: 2.2\u2013NR) / 4.7 months / 1.8 months / NR, HR: 3.41 (95% CI: 1.11\u201310.4)",
            "OS": "None",
            "ORR": "Week 4: 20% / 7.1% / 0%, Week 16: 42.9% / 15.4% / 42.9%",
            "AE": "Grade 3\u20134 TRAEs: 32.6%, TRAEs leading to discontinuation: 20.9%",
            "Other": "MPRbx at week 4: 50% / 0% / 43%",
            "Conclusion": "Nivo-rela results in 34.2% ORR and median PFS of 6.5 months in pts with advanced mel after lead-in nivo, rela or combination therapy. This first single agent lead-in rela evaluation demonstrated week 4 ORR of 7%, week 16 ORR of 15.4% and median PFS 1.8 months. MPRBx and CD8 density at week 4 are associated with improved PFS."
        },
        {
            "Title": "A phase I dose escalation trial of BCD-145 in patients with unresectable or metastatic melanoma.",
            "Indication": "Unresectable or metastatic melanoma",
            "Drug": "BCD-145 (nurulimab)",
            "PFS": "None",
            "OS": "None",
            "ORR": "30.8%",
            "AE": "100% any grade AEs, 33.3% grade 3-4 AEs, 46.7% immuno-related AEs",
            "Other": "Dose-dependent peak study drug serum concentrations and systemic exposure. PR and SD were registered in 2 pts of each response group.",
            "Conclusion": "BCD-145 demonstrated a tolerable safety profile and preliminary anti-tumor activity in pts with unr/mM. Anti-tumor effects of BCD-145 were observed in pts who had progressed on prior treatment. Further co-targeting PD-1 and CTLA-4 simultaneously will achieve a better anti-tumor activity due to cooperative binding to exhausted T-cell subsets."
        },
        {
            "Title": "A phase I/II study of KD6001, a novel fully human anti-CTLA4 IgG1 monoclonal antibody, in combination with toripalimab in patients with advanced melanoma.",
            "Indication": "Advanced melanoma",
            "Drug": "KD6001 in combination with toripalimab",
            "PFS": "Not reached",
            "OS": "Not reached",
            "ORR": "Unconfirmed: 38.5%, Confirmed: 23.1%",
            "AE": "89.7% treatment-related AEs, 10.3% grade 3 or higher TRAEs",
            "Other": "DCR: 76.9%, Among pts who received KD6001 as the \u22652 line treatment and all failed anti-PD-1/L1 therapy, unconfirmed ORR: 57.1%, confirmed ORR: 42.9%, DCR: 85.7%",
            "Conclusion": "KD6001 in combination with toripalimab for treatment of advanced melanoma was safe, well-tolerated and showed anti-tumor activity in pts with advanced melanoma. Preliminary analysis indicates that KD6001 combined with toripalimab is efficacious in pts who have progressed after previous anti-PD-1/L1 therapy and in pts with mucosal melanoma."
        },
        {
            "Title": "Efficacy of encorafenib/binimetinib in patients with metastatic melanoma with brain metastases: Results from the nationwide Dutch Melanoma Treatment Registry.",
            "Indication": "Metastatic melanoma with brain metastases",
            "Drug": "Encorafenib/binimetinib",
            "PFS": "Median PFS was 5.6 months (95% CI: 4.9-6.2)",
            "OS": "Median OS was 10.9 months (95% CI: 9.6-14.0)",
            "ORR": "67.3%",
            "AE": "None",
            "Other": "OS was significantly shorter in patients with symptomatic BMs versus asymptomatic BMs (median 9.7 versus 22.9 months, p < 0.01).",
            "Conclusion": "Encorafenib plus binimetinib has clinical activity in real-world melanoma patients with BMs, but PFS and OS are shorter than previously reported for patients without brain metastases. These data may aid physicians in clinical decision-making."
        },
        {
            "Title": "Comparison of clinical outcomes of stable disease with confirmed tumor reduction and RECIST partial response for tebentafusp in metastatic uveal melanoma (mUM).",
            "Indication": "Metastatic uveal melanoma (mUM)",
            "Drug": "Tebentafusp",
            "PFS": "None",
            "OS": "None",
            "ORR": "5% (PR), 20% (SD)",
            "AE": "None",
            "Other": "The clinical benefit rate of PR + SD was 50% (64/127). 25% (32/127) had any tumor reduction that was confirmed on \u22651 subsequent scan, including 6 PR (ORR 5%) and 26 SD (20%).",
            "Conclusion": "This Ph2 UM study prospectively confirmed that a subset of SD patients had tumor reduction over multiple scans. The frequency of minor response was similar to that of PR and had similar durability, OS, and ctDNA molecular response. SD with confirmed tumor reduction is an emerging endpoint for the ImmTAC platform and will be studied in other trials."
        },
        {
            "Title": "Length of treatment after partial or complete remission in immunotherapy for metastatic melanoma: An EUMelaReg real world study.",
            "Indication": "Metastatic melanoma",
            "Drug": "Immune checkpoint inhibitors (ICI)",
            "PFS": "Prolonged PFS for treatment until progressive disease (PD) or >12 months after remission (HR: 0.82 [95% CI: 0.6-0.9])",
            "OS": "Prolonged OS for treatment until PD or >12 months after remission (HR: 0.67 [95% CI: 0.5-0.9])",
            "ORR": "None",
            "AE": "None",
            "Other": "This effect was more pronounced in PR (HR 0.66 for OS) than in CR (HR 0.84 for OS). Treatment for 6-12 months compared to <6 months also showed a trend towards prolonged PFS and OS (HR: 0.85 [95% CI: 0.7-1.1] and HR: 0.82 [95% CI: 0.6-1.1], respectively).",
            "Conclusion": "This study showed PFS and OS benefit for patients continuing on treatment for >12 months or until progression after achieving PR or CR compared to patients treated for <6 months."
        },
        {
            "Title": "Long-term overall survival after isolated liver perfusion with melphalan in patients with isolated liver metastases of uveal melanoma (SCANDIUM trial)",
            "Indication": "Uveal melanoma with isolated liver metastases",
            "Drug": "Melphalan",
            "PFS": "None",
            "OS": "Median OS in IHP group: 21.4 months (95% CI, 19.0-30.4 months) vs. control group: 17.3 months (95% CI, 13.8-22.3 months), p=0.11",
            "ORR": "40% in the IHP group",
            "AE": "None",
            "Other": "3-year OS rate: 18.6% (95% CI, 10.0-34.8%) in IHP group vs. 9.1% (95% CI, 3.6-23.1%) in control group, p=0.23; 5-year OS rate: 16.3% (95% CI, 8.3-32.1%) in IHP group vs. 6.8% (95% CI, 2.3-20.3%) in control group",
            "Conclusion": "This extended analysis from the SCANDIUM trial supports long-term efficacy of a one-time treatment with IHP compared to best alternative care in patients with isolated liver metastases of uveal melanoma."
        },
        {
            "Title": "Overall survival after isolated hepatic perfusion in combination with ipilimumab and nivolumab in patients with uveal melanoma metastases (the SCANDIUM II trial)",
            "Indication": "Uveal melanoma with liver dominant metastatic disease",
            "Drug": "Isolated hepatic perfusion (IHP) with melphalan, ipilimumab (IPI3), nivolumab (NIVO1)",
            "PFS": "None",
            "OS": "Median OS in Arm A: 18.2 months (95% CI, 13.2-NA months) vs. Arm B: 17.2 months (95% CI, 14.0-NA months)",
            "ORR": "Overall response rate: 38%, 57% in Arm A and 22% in Arm B",
            "AE": "More IPI/NIVO related adverse events (grade I-IV) in Arm B (48 vs. 86)",
            "Other": "None",
            "Conclusion": "Combining IHP with IPI3/NIVO1 is a feasible treatment. One dose of IPI3/NIVO1 before IHP had no obvious benefit in response or OS but was associated with higher rates of toxicity. The numerically higher response rate in Arm A warrants a larger randomized study."
        },
        {
            "Title": "EVICTION study: ICT01, an anti-Butyrophilin 3A monoclonal antibody activating g9d2 T cells in combination with pembrolizumab in checkpoint inhibitor refractory melanoma",
            "Indication": "Checkpoint inhibitor refractory melanoma",
            "Drug": "ICT01, pembrolizumab",
            "PFS": "6-month progression-free survival of 38%",
            "OS": "None",
            "ORR": "Disease Control Rate (DCR) of 42% (including 3 PR)",
            "AE": "First-dose Grade 1/2 infusion related reactions (IRR) and cytokine release syndrome (CRS) in 38% and 19% of patients respectively, 5% and 2% Grade 3",
            "Other": "Full BTN3A receptor occupancy on immune cells achieved at 7mg dose, with down-modulation of BTN3A observed at doses greater than 20mg. Clinical response related to baseline tumoral BTN3A expression, sustained elevation of IFN\u03b3 levels, and tumor microenvironment remodeling.",
            "Conclusion": "ICT01 in combination with pembrolizumab has a favorable safety profile and promising efficacy data. Patient selection based on BTN3A tumor expression will be further evaluated as an enrichment strategy."
        },
        {
            "Title": "ADNA plasmid melanoma cancer vaccine, SCIB1, combined with nivolumab + ipilimumab in patients with advanced unresectable melanoma: Efficacy and safety results from the open-label phase 2 SCOPE trial",
            "Indication": "Advanced unresectable melanoma",
            "Drug": "SCIB1, nivolumab, ipilimumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "Objective response rate (ORR) of 85% (11/13 responses confirmed in subsequent scan: 1 CR and 10 PRs); Disease Control Rate (DCR) 12/13 (92%)",
            "AE": "Most SCIB1 adverse reactions were Grade 1/2 injection site related or headaches. 3 patients reported Grade 3 events (rash, neutropenia, and raised GGT) all related to the CPIs.",
            "Other": "Patients showed a 40-100% reduction in tumor volume at 13 and/or 25 weeks.",
            "Conclusion": "SCIB1 given with nivolumab and ipilimumab, as 1st line treatment in unresectable melanoma, improved the ORR to 85% without an increase in clinically meaningful adverse events. These results, if confirmed in the larger ongoing patient cohort, provide confidence in initiating a randomized registration program in unresectable melanoma patients with this novel DNA plasmid technology."
        },
        {
            "Title": "Use of baseline and serial ctDNA dynamics to predict outcomes in patients treated with first-line tebentafusp, including those who were and were not treated beyond progression.",
            "Indication": "Metastatic uveal melanoma (mUM)",
            "Drug": "Tebentafusp",
            "PFS": "None",
            "OS": "Baseline ctDNA undetectable: HR 0.59 (95% CI 0.4-0.88); TBP for \u226516 weeks: HR 0.33 (95% CI 0.21-0.51); TBP without ctDNA clearance: HR 0.48 (95% CI 0.30-0.79); TBP with ctDNA clearance: HR 0.87 (95% CI 0.43-1.78); TBP with \u226550% reduction: HR 0.63 (95% CI 0.4-0.97); TBP with \u226568% reduction: HR 0.62 (95% CI 0.39-0.99)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Baseline and week 9 ctDNA detection predicts OS in mUM patients treated with first-line tebentafusp. The utility of ctDNA in predicting patients most likely to benefit from TBP is less clear, although TBP seems to be more beneficial in those with reduction of ctDNA at week 9."
        },
        {
            "Title": "Interleukin-6 receptor blockade with tocilizumab to reduce immune-related toxicity with ipilimumab and nivolumab in metastatic melanoma.",
            "Indication": "Metastatic melanoma",
            "Drug": "Tocilizumab, Ipilimumab, Nivolumab",
            "PFS": "Median PFS: 13 months",
            "OS": "None",
            "ORR": "Best overall response rate (BORR): 57% (95% CI 44-68%)",
            "AE": "Grade 3-4 irAE: 22% (95% CI 12-31%); irAE leading to discontinuation: 14%",
            "Other": "None",
            "Conclusion": "Prophylactic TOCI resulted in a low rate of grade 3-4 irAEs by week 24 in patients with stage IV melanoma receiving induction IPI 1mg/kg and NIVO 3mg/kg while maintaining a favorable overall response rate and survival. Novel biomarkers of response and toxicity including NK cells and monocytes as well as na\u00efve and effector memory CD8 T cells were defined by high-resolution flow cytometry."
        },
        {
            "Title": "First Circulating tumor DNA analysis in patients with BRAF-mutated metastatic cutaneous melanoma treated with BRAF and MEK inhibitors: Analysis of the OPTIMEL study.",
            "Indication": "Metastatic cutaneous melanoma",
            "Drug": "BRAF and MEK inhibitors",
            "PFS": "Baseline BRAF mutation: 4.01 vs 12.0 months, HR=28.5; CI95% [7.75;105.0]; p<0.001. BRAF vaf > 4.5%: 3.15 vs 12.0 months, HR=112; CI95% [13.8;913.0]; p<0.001. NRAS mutation: 4.84 vs 10.9 months, HR=11.6; CI95% [2.76;48.5]; p<0.001.",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "BRAF mutation at baseline associated with lower PFS (HR=3.42; CI95% [1.19;9.82]; p=0.022). BRAF vaf < 2.3% linked to objective response (p<0.001).",
            "Conclusion": "The variant allele frequency of BRAF mutations in circulating tumor DNA is indicative of the objective response to BRAF and MEK inhibitors, as well as PFS. These findings could potentially influence our approach to managing patients with MM harboring a BRAF mutation."
        },
        {
            "Title": "High dose bolus (HDB) interleukin-2 (IL2) and concurrent low dose ipilimumab followed sequentially by nivolumab in patients with advanced melanoma after failure of anti-PD1-based immunotherapy and BRAF-MEK inhibition.",
            "Indication": "Advanced inoperable stage III or stage IV melanoma",
            "Drug": "HDB IL2, low dose ipilimumab, nivolumab",
            "PFS": "Median PFS was 3 months.",
            "OS": "Median OS was 18.8 months. One-year OS was 83% (95% CI: 65%-100%).",
            "ORR": "Response rate was 2/11 (18.2%; 95% CI: 2.3%\u201351.8%); 1 CR, 1 PR, 5 SD, 4 PD.",
            "AE": "The adverse event profile was consistent with the expected toxicity profile for each agent with no increase in frequency with the combination.",
            "Other": "Median number of prior regimens for metastatic melanoma was 3 (range 1-5).",
            "Conclusion": "Our combination regimen was relatively safe and well tolerated and demonstrated promising efficacy in a heavily pretreated patient population passing the prespecified efficacy criteria for Simon Stage I. The study has moved into Stage II testing."
        },
        {
            "Title": "Overall survival and efficacy subgroup analysis of tunlametinib in patients with advanced NRAS-mutant melanoma: A multicenter, open-label, single-arm, phase 2 study.",
            "Indication": "Advanced NRAS-mutant melanoma",
            "Drug": "Tunlametinib",
            "PFS": "4.2 months (95% CI: 3.5, 5.6)",
            "OS": "13.7 months (95% CI: 10.3\u201318.0)",
            "ORR": "35.8% (95% CI: 26.3%, 46.3%)",
            "AE": "Increased blood creatine phosphokinase (CK), diarrhea, facial oedema, peripheral oedema, increased aspartate aminotransferase",
            "Other": "ORRs by melanoma subtype: 42.9% (95% CI: 29.7\u201356.8) in acral melanoma, 25.0% (95% CI: 7.3\u201352.4) in mucosal melanoma. ORRs by NRAS mutation site: 41.3% (95% CI: 30.1\u201353.3) in patients treated with immunotherapy, 25.8% (95% CI: 11.9\u201344.6) in those without immunotherapy.",
            "Conclusion": "Tunlametinib demonstrated an encouraging treatment response rate in patients with advanced NRAS-mutant melanoma with a manageable safety profile. These results suggest that tunlametinib could be a promising treatment option for NRAS-mutant melanoma, even for those who had previously received immunotherapy."
        },
        {
            "Title": "Real-world outcomes of combined lenvatinib and anti-PD-1 in advanced melanoma: Lenvamel, a multicenter retrospective study of the French Group of Skin Cancers.",
            "Indication": "Advanced melanoma",
            "Drug": "Lenvatinib and anti-PD-1",
            "PFS": "None",
            "OS": "None",
            "ORR": "28.4% (95% CI: 18\u201341%)",
            "AE": "Fatigue (43.3%), nausea/vomiting (26.8%), diarrhea (20.9%), hypertension (20.9%)",
            "Other": "None",
            "Conclusion": "Our study demonstrates an interesting response rate and acceptable safety profile of this combination, with manageable toxicities, in an immuno-refractory population with poor prognostic factors. Patients pre-treated with anti-PD-1 + anti-CTLA-4, and those with BRAF wild-type melanoma seemed to benefit more from this strategy. Our study also provides promising data (considering the small sample) for patients with mucosal melanoma. Our data support this treatment option for refractory melanoma, though not yet approved by the health authorities, and highlight the need for new strategies."
        },
        {
            "Title": "Camrelizumab plus apatinib and temozolomide as first-line therapy for advanced acral melanoma: 2-year survival results from CAP 03.",
            "Indication": "Advanced acral melanoma",
            "Drug": "Camrelizumab, apatinib, and temozolomide",
            "PFS": "21.2 months (95% CI: 10.1-27.1)",
            "OS": "Not reached; 1-year OS: 88.0% (95% CI: 75.2%-94.4%), 2-year OS: 64.8% (95% CI: 49.5%-76.5%)",
            "ORR": "66.0% (95% CI: 51.2%-78.8%)",
            "AE": "None",
            "Other": "None",
            "Conclusion": "This first-line triplet combination shows encouraging survival benefits in patients with advanced acral melanoma, regardless of NRAS status or PD-L1 expression level. Follow-up is still ongoing to obtain mature OS data."
        },
        {
            "Title": "Atezolizumab (A), cobimetinib (C), and vemurafenib (V) in patients (pts) with BRAFV600 mutation\u2013positive melanoma with central nervous system (CNS) metastases (mets): Final results and exploratory biomarker analysis from the phase 2 TRICOTEL study.",
            "Indication": "BRAFV600 mutation\u2013positive melanoma with CNS metastases",
            "Drug": "Atezolizumab, cobimetinib, vemurafenib",
            "PFS": "5.1 months (95% CI, 3.7\u20136.9)",
            "OS": "13.4 months (95% CI, 10.7\u201316.9)",
            "ORR": "38% (95% CI, 27\u201351)",
            "AE": "No new safety signals observed since primary analysis",
            "Other": "Mutations detected in baseline ctDNA samples of 60 pts; high prevalence of NF1, NRAS, and GNAI2 mutations; 11 additional BRAF mutations detected in ctDNA at baseline; acquired mutations in AKT1 detected in ctDNA at PD; median OS shortened in pts with >2 versus \u22642 mutated MAPK pathway genes in ctDNA at baseline (9.1 vs 14.5 months; hazard ratio, 2.0 [95% CI, 1.0\u20133.7]; p < 0.05); baseline mutations in PIK3C2A and PLEKHG4 enriched in non-responders, while RAD51B mutations enriched in responders.",
            "Conclusion": "Combination A+C+V had clinical intracranial activity in pts with BRAFV600-mutated melanoma with CNS mets. Exploratory biomarker analyses in this small cohort demonstrate the presence of ctDNA in pts with melanoma and CNS mets and provide insight into mutations associated with response and resistance to the triplet combination."
        },
        {
            "Title": "ADU-1604, a novel CTLA-4 blocking antibody, to modulate pharmacodynamic markers in patients with PD1 relapse/refractory melanoma.",
            "Indication": "PD1 relapse/refractory melanoma",
            "Drug": "ADU-1604",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Two Grade 2 severe adverse events (both immune-related enterocolitis) and two Grade 3 AESIs (ALT elevation and immune-related gastritis)",
            "Other": "Dose-dependent modulation of ICOS and Ki-67 on circulating CD4+ T cells; dose-dependent increase of ALC and CD4+ and CD8+ T cells; no DLTs across the 25, 75, 225, and 450 mg dose levels; clinical efficacy demonstrated in two patients at 225 mg and one patient at 450 mg.",
            "Conclusion": "ADU-1604 Phase 1 dose-escalation data indicates clinical activity in line with what has been observed with other CTLA-4 blocking antibodies. Importantly, no DLTs and very limited safety signals were reported during the study. Dose expansion at 225 mg dose is ongoing in subjects with advanced-stage, relapsed/refractory melanoma who relapsed or were refractory to a prior anti-PD-1/PD-L1 therapy."
        },
        {
            "Title": "Phase II trial of weekly or bi-weekly tocilizumab with ipilimumab and nivolumab in advanced melanoma: Clinical outcomes and biomarker analysis.",
            "Indication": "Advanced melanoma",
            "Drug": "Tocilizumab, ipilimumab, nivolumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "56% at 12 weeks in RD group; 70% at 12 weeks in DD group",
            "AE": "44% of pts in RD group developed G3/4 irAEs; 40% of pts in DD group developed G3/4 irAEs",
            "Other": "Median time to G3/4 irAEs onset was 7.4 weeks in RD group and 4.9 weeks in DD group; 16% of pts in RD group and 30% of pts in DD group discontinued ICIs due to irAEs; DCR was 80% in both RD and DD groups; gene expression analysis showed a trend towards higher expression of IL-17 pathway genes in pts who developed G3/4 irAEs.",
            "Conclusion": "Preliminary data suggests IL-6R blockade using the DD regimen could decrease the rate of G3/4 irAEs while maintaining antitumor responses of ipi3/nivo1. A randomized phase II trial investigating this strategy is underway. Ongoing immune analysis comparing treatment regimens aims to identify distinct pathways involved in auto- vs antitumor immunity and IL-6/Th17 independent pathways of toxicity."
        },
        {
            "Title": "Pembrolizumab (pembro) for locally advanced (LA) or recurrent/metastatic (R/M) cutaneous squamous cell carcinoma (cSCC): Long-term results of the phase 2 KEYNOTE-629 study.",
            "Indication": "Locally advanced or recurrent/metastatic cutaneous squamous cell carcinoma",
            "Drug": "Pembrolizumab",
            "PFS": "LA cohort: 14.4 months (95% CI: 5.5-43.6), R/M cohort: 5.7 months (95% CI: 3.1-8.5), Total population: 8.0 months (95% CI: 5.3-14.4)",
            "OS": "LA cohort: Not reached (95% CI: 33.3-NR), R/M cohort: 23.8 months (95% CI: 13.4-30.9), Total population: 29.8 months (95% CI: 20.0-42.8)",
            "ORR": "LA cohort: 51.9% (95% CI: 37.8-65.7), R/M cohort: 35.2% (95% CI: 26.2-45.2), Total population: 40.9% (95% CI: 33.2-48.9)",
            "AE": "Grade 3-5 treatment-related AEs: 11.3%, Grade 3-5 immune-mediated AEs and infusion reactions: 8.8%, Treatment-related deaths: 1.3%",
            "Other": "DCR: LA cohort: 64.8% (95% CI: 50.6-77.3), R/M cohort: 52.4% (95% CI: 42.4-62.2), Total population: 56.6% (95% CI: 48.5-64.4)",
            "Conclusion": "With a median follow-up of more than 5 years, pembrolizumab continued to show durable responses in patients with LA or R/M cSCC. No new safety signals were observed. Results from this study continue to support the use of pembrolizumab in this patient population."
        },
        {
            "Title": "Intracranial outcomes with ipilimumab and nivolumab in melanoma brain metastases following progression on anti-PD-1 therapy.",
            "Indication": "Melanoma brain metastases",
            "Drug": "Ipilimumab and nivolumab",
            "PFS": "Cohort A: 1.7 months (95% CI: 1.3-4.4), Cohort B: 7.6 months (95% CI: 3.6-)",
            "OS": "Cohort A: 6.7 months (95% CI: 3.6-10), Cohort B: 24 months (95% CI: 11-)",
            "ORR": "Cohort A: 11% (3/28, 2 were complete responses)",
            "AE": "Not specified",
            "Other": "24-month OS: Cohort A: 19% (95% CI: 8.1-45), Cohort B: 51% (95% CI: 35-75)",
            "Conclusion": "Ipilimumab and nivolumab have limited efficacy in patients with progressive melanoma brain metastases post-PD-1 therapy in the absence of local therapy. These results highlight the unmet clinical need for more effective therapies to address progressive melanoma brain metastases post-PD-1 therapy."
        },
        {
            "Title": "Melphalan/hepatic delivery system versus best available care in patients with unresectable metastatic uveal melanoma: Randomized FOCUS trial results.",
            "Indication": "Unresectable metastatic uveal melanoma",
            "Drug": "Melphalan/Hepatic Delivery System",
            "PFS": "Melphalan/HDS: 9.1 months, BAC: 3.3 months",
            "OS": "Melphalan/HDS: 18.5 months, BAC: 14.5 months",
            "ORR": "Melphalan/HDS: 27.5%, BAC: 9.4%",
            "AE": "Serious adverse events: Melphalan/HDS: 51.2%, BAC: 21.9%",
            "Other": "Disease control rate: Melphalan/HDS: 80.0%, BAC: 46.9%",
            "Conclusion": "Treatment with melphalan/HDS shows clinically meaningful efficacy and demonstrates a favorable benefit-risk profile in patients with unresectable metastatic uveal melanoma as compared to best alternative care."
        },
        {
            "Title": "Efficacy and safety of first-line (1L) nivolumab plus relatlimab (NIVO + RELA) versus NIVO plus ipilimumab (NIVO + IPI) in advanced melanoma: An updated indirect treatment comparison (ITC).",
            "Indication": "Advanced melanoma",
            "Drug": "Nivolumab plus relatlimab vs. nivolumab plus ipilimumab",
            "PFS": "NIVO + RELA: 12.0 months (95% CI: 8.2\u201317.1), NIVO + IPI: 11.2 months (95% CI: 8.5\u201318.1)",
            "OS": "NIVO + RELA: Not reached (95% CI: 38.6\u2013NR), NIVO + IPI: Not reached (95% CI: 37.1\u2013NR)",
            "ORR": "NIVO + RELA: 48%, NIVO + IPI: 50%",
            "AE": "TRAEs: NIVO + RELA: 23%, NIVO + IPI: 61%; Any-grade TRAEs leading to DC: NIVO + RELA: 17%, NIVO + IPI: 41%",
            "Other": "MSS: NIVO + RELA: Not reached (95% CI: NR\u2013NR), NIVO + IPI: Not reached (95% CI: NR\u2013NR)",
            "Conclusion": "Consistent with previous results, this updated ITC suggests that 1L treatment with NIVO + RELA may have comparable efficacy to, and lower toxicity than, NIVO + IPI in patients with advanced melanoma. Results should be interpreted with caution given differences in study designs and changes in treatment landscape."
        },
        {
            "Title": "Lenvatinib (len) plus pembrolizumab (pembro) in patients with advanced melanoma that progressed on anti\u2013PD-(L)1 therapy: Over 4 years of follow-up from the phase 2 LEAP-004 study.",
            "Indication": "Advanced melanoma",
            "Drug": "Lenvatinib plus pembrolizumab",
            "PFS": "Median PFS was 4.2 months (95% CI, 3.5-6.3); 24-mo PFS rate was 10.1%",
            "OS": "Median OS was 14.0 months (95% CI, 10.8-18.3); 24-mo OS rate was 29.7%",
            "ORR": "ORR in the overall population was 24.3% (95% CI, 16.4-33.7), with 5 pts (4.9%) having a complete response and 20 (19.4%) a partial response",
            "AE": "Any-grade treatment-related AEs occurred in 99 pts (96.1%). Grade 3-5 treatment-related AEs occurred in 51 pts (49.5%)",
            "Other": "Median DOR in the overall population was 8.5 months (range, 3.2-40.8); an estimated 35% of responders remained in response at \u226512 months",
            "Conclusion": "With over 4 years of follow-up, len + pembro continued to show antitumor activity in pts with advanced melanoma and confirmed progression after \u22652 doses of anti\u2013PD-(L)1-based therapy. Safety remained consistent with previous reports, with no new or unexpected safety signals. These results support len + pembro as a potential treatment option for advanced melanoma after anti\u2013PD-(L)1-based therapy."
        },
        {
            "Title": "The efficacy and safety of toripalimab combined with anlotinib in the first-line treatment of Chinese patients with metastatic melanoma.",
            "Indication": "Metastatic melanoma",
            "Drug": "Toripalimab combined with anlotinib",
            "PFS": "The median PFS of all 47 patients was 5.7 months (95% CI 4.2 to 7.2 months)",
            "OS": "The median OS was 14.4 months (95% CI 7.3 to 21.5 months)",
            "ORR": "The objective response rate (ORR) and disease control rate (DCR) were 23.9% and 80.4%, respectively",
            "AE": "Treatment-related adverse events (TRAEs) for all grades occurred in 76.6% (36/47) of patients, and grade 3 or 4 occurred in 4.3% (2/47) of patients. The incidence of immune-related adverse reactions (irAE) was 23.4% (11/47) for all grades",
            "Other": "Median duration of response was 14.4 months (95% CI: 7.5, 21.3). The median PFS of patients with M1a stage is 24.4 months, statistically longer than M1b (5.1 months), M1c (6.6 months) and M1d (4.0 months). The median PFS of acral, mucosal and non-CSD melanoma were 6.6m, 5.1m, 5.2m respectively with no statistical difference",
            "Conclusion": "Toripalimab combined with anlotinib displays good efficacy and well-tolerant safety profiles in the treatment of patients with metastatic melanoma. Further biomarker analysis was ongoing."
        },
        {
            "Title": "Immunogenicity of an AI-designed personalized neoantigen vaccine, EVX-01, in combination with anti-PD-1 therapy in patients with metastatic melanoma.",
            "Indication": "Metastatic melanoma",
            "Drug": "EVX-01 in combination with anti-PD-1 therapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "EVX-01 was safe and well-tolerated, with only grade 1 and 2 ADRs related to EVX-01 even after boosting. The combination of EVX-01 and anti-PD1 also appeared safe and well tolerated.",
            "Other": "EVX-01 induced neoAg-specific immune responses in all analyzed patients and a broad response against the neoAgs. The observed responses were mediated by both CD4+ and CD8+ neoAg-specific T-cells.",
            "Conclusion": "EVX-01 induced neoAg-specific immune responses in all analyzed patients and a broad response against the neoAgs. These results further validate the precision and predictive power of our proprietary vaccine target discovery AI-Immunology platform. The combination of EVX-01 and anti-PD1 was safe and well tolerated."
        },
        {
            "Title": "First-in-class PD-1/IL-2 bispecific antibody fusion protein IBI363 in patients with advanced melanoma: Safety and efficacy results from a phase I study.",
            "Indication": "Advanced melanoma",
            "Drug": "IBI363",
            "PFS": "None",
            "OS": "None",
            "ORR": "Overall ORR was 28.1% (95% CI: 17.0-41.5%). In pts had prior IO (n=52), ORR was 21.2% (95% CI: 11.1-34.7%). In 1 mg/kg Q2W pts had prior IO (n=25), ORR was 32.0% (95% CI: 14.9-53.5%).",
            "AE": "TEAEs occurred in 63 (94.0%) pts. Grade \u22653 TEAEs and TRAEs occurred in 16 (23.9%) and 12 (17.9%) pts. Common TEAEs (\u226520%) were arthralgia (34.3%), hyperthyroidism (29.9%), anemia (25.4%). TEAE leading to treatment discontinuation occurred in 1 (1.5%) pt. No pts had TEAEs leading to death.",
            "Other": "DCR was 71.9% (95% CI: 58.5-83.0). In pts had prior IO (n=52), DCR was 67.3% (95% CI: 52.9-79.7%). In 1 mg/kg Q2W pts had prior IO (n=25), DCR was 80.0% (95% CI: 59.3-93.2%). Biomarker analysis in baseline tumor region observed significantly higher CD8 T cell infiltration (measured by cell positivity and density) in PR/SD patients than PD patients (p < 0.05).",
            "Conclusion": "In pts with advanced melanoma, IBI363 showed encouraging efficacy in different tumor subtypes and in pts with prior IO. The safety profiles were acceptable and manageable. Further clinical development of IBI363 in melanoma are ongoing both in China and overseas."
        },
        {
            "Title": "Association between circulating tumor DNA (ctDNA) and recurrence-free survival (RFS) in patients (pts) with resected stage III melanoma: An exploratory analysis of SWOG S1404.",
            "Indication": "Resected stage III melanoma",
            "Drug": "Pembrolizumab, Interferon alfa-2b, Ipilimumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "2-yr RFS was 30% in pts who were ctDNA+ pre-Tx and 54% in pts who were ctDNA- (HR 1.75; 95% CI: 0.78, 3.94; p = 0.18). Of the 7 pts who were ctDNA+ pre-Tx and had disease recurrence within 2 yrs, 5 were ctDNA+ at all time points assessed. The other 2 pts transiently cleared ctDNA during Tx, turning back ctDNA+ prior to recurrence. Of the 3 pts who were ctDNA+ pre-Tx and did not recur within 2 yrs, 2 cleared ctDNA during immunotherapy and remained ctDNA- at later time points. All 39 pts who were ctDNA- pre-Tx and did not recur within 2 yrs remained ctDNA- at later time points. Among 29 pts having ctDNA time points available for analysis near the time of recurrence (end-of-Tx or on-progression time points), 22 (75.9%) were ctDNA+.",
            "Conclusion": "In this study, pre-Tx incidence of ctDNA+ after surgical resection was low, potentially related to limited plasma yield. However, the ctDNA positivity rate increased in subsequent serial testing. ctDNA might help identify early disease recurrence in patients with melanoma in the adjuvant setting, but further studies of larger cohorts accounting for treatment effect with more uniform pre-analytic collection are needed."
        },
        {
            "Title": "Neoadjuvant pembrolizumab plus lenvatinib in patients with resectable stage III melanoma (NeoPele): Analysis of tumor microenvironment (TME) correlated to pathological response.",
            "Indication": "Stage III melanoma",
            "Drug": "Pembrolizumab plus lenvatinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "At baseline, MPR patients had a higher % of T cells (11% vs 3%, p=0.0127) and follicular B helper T cells (CXCR5+; 29% vs 9%, p=0.0293) than non-MPR patients in the TME. NeoIT led to an increase in the % of T cells, mainly in patients with MPR (median +30%; p=0.0156) vs non-MPR (median +7%; p=0.0312) patients, including an increase in the % of tumor-reactive (CD39+) T cells (p=0.0195), but a decrease in % of tissue-resident stem-like (CD103+ TCF7+) T cells (p=0.0195). Within the B cell compartment, there was an increase in the % of CD21+ B cells and mature (CD21+ CXCR5+) B cells in MPR vs non-MPR patients. At week 6, MPR patients maintained a higher % of T cells (36% vs 11%, p=0.0172), follicular B helper T cells (20% vs 9%, p=0.0133), and mature B cells (10% vs 2%, p=0.0021) in the TME compared with non-MPR. NeoIT led to a significant increase in the number of lymphoid aggregates in the TME from MPR patients (median +42; p=0.0156), but no difference in non-MPR patients. No differences were observed in the tdLN based on pathological response.",
            "Conclusion": "MPR patients had a higher density of T cells in the TME at baseline, and a more differentiated and activated immune profile after neoIT compared with non-MPR patients. MPR patients had a significant increase in the number of lymphoid aggregates at week 6 compared to non-MPR patients; however, the role of these lymphoid aggregates, including the follicular B helper T cell subset and mature B cells, promoting a good pathological response to neoIT is yet to be clarified."
        },
        {
            "Title": "Adjuvant BRAF/MEK versus anti-PD-1 in BRAF-mutant melanoma: Propensity score\u2013matched recurrence-free, distant metastasis-free, and overall survival analysis.",
            "Indication": "BRAF-mutant melanoma",
            "Drug": "BRAF/MEK inhibitors and anti-PD-1",
            "PFS": "None",
            "OS": "1-year OS rate: 93.0% (BRAF/MEK) vs 96.8% (anti-PD-1); 2-year OS rate: 81.3% (BRAF/MEK) vs 87.4% (anti-PD-1)",
            "ORR": "None",
            "AE": "Grade \u22653 toxicity rates and discontinuation rates due to toxicity will be presented at the meeting.",
            "Other": "1-year RFS rate: 83.1% (BRAF/MEK) vs 71.6% (anti-PD-1); 2-year RFS rate: 66.4% (BRAF/MEK) vs 61.7% (anti-PD-1); 1-year DMFS rate: 91.8% (BRAF/MEK) vs 85.4% (anti-PD-1); 2-year DMFS rate: 84.2% (BRAF/MEK) vs 78.0% (anti-PD-1)",
            "Conclusion": "Our results suggest no significant differences in outcomes between adjuvant BRAF/MEKi and anti-PD-1 treatment in stage III melanoma."
        },
        {
            "Title": "Clinical validation of a prognostic 7-marker IHC assay (7-IHC) in 382 patients with stage IB/IIA cutaneous melanoma (CM; MELARISK-001).",
            "Indication": "Stage IB/IIA cutaneous melanoma",
            "Drug": "None",
            "PFS": "5Y RFS: 79% (high-risk) vs 100% (low-risk); 10Y RFS: 72% (high-risk) vs 99% (low-risk)",
            "OS": "5Y MSS: 92% (high-risk) vs 100% (low-risk); 10Y MSS: 82% (high-risk) vs 100% (low-risk)",
            "ORR": "None",
            "AE": "None",
            "Other": "7-IHC risk categorization identified 98% of relapses and 100% of CM-related deaths in stage IB/IIA patients.",
            "Conclusion": "7-IHC risk categorization identified 98% of relapses and 100% of CM-related deaths in stage IB/IIA patients. In MVA, 7-IHC risk class was the strongest independent prognosticator of survival endpoints."
        },
        {
            "Title": "Identification of patients at high risk for relapse using the Merlin Assay (CP-GEP) in an independent cohort of 451 patients with stage I/II melanoma who did not undergo sentinel lymph node biopsy.",
            "Indication": "Stage I/II melanoma",
            "Drug": "None",
            "PFS": "5-year RFS: 90.5% (CP-GEP Low-Risk) vs 0% (CP-GEP High-Risk)",
            "OS": "5-year MSS: 99.1% (CP-GEP Low-Risk) vs 25.7% (CP-GEP High-Risk)",
            "ORR": "None",
            "AE": "None",
            "Other": "5-year DMFS: 97.2% (CP-GEP Low-Risk) vs 27% (CP-GEP High-Risk)",
            "Conclusion": "This study shows that CP-GEP has the potential to stratify patients with early-stage melanoma who did not undergo SLNB based on their risk of recurrence. Patients with CP-GEP Low-Risk have good long-term survival, while those with CP-GEP High-Risk have a high risk of recurrence."
        },
        {
            "Title": "Antigen-specific profiling for neoadjuvant anti-PD-1 therapy in melanoma.",
            "Indication": "Stage III melanoma",
            "Drug": "Anti-PD-1 therapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "53% of patients had detectable melanoma-specific CD8 T cells. Pathologic responders to PD-1 blockade are characterized by both a higher quantity and a less exhausted phenotype of tumor-specific CD8 T cells.",
            "Conclusion": "These findings highlight the feasibility of antigen-specific profiling in a clinical trial setting, the importance of melanoma-specific CD8 T cells in checkpoint blockade responses, and their potential use as an early on-treatment biomarker of clinical outcomes."
        },
        {
            "Title": "Use of early circulating tumour DNA dynamics in patients with stage III Neoadjuvant immune checkpoint inhibitors for patients with resectable melanoma receiving neoadjuvant combination immunotherapy.",
            "Indication": "Stage IIIB/C melanoma",
            "Drug": "Ipilimumab (anti-CTLA-4) and Nivolumab (anti-PD-1)",
            "PFS": "None",
            "OS": "None",
            "ORR": "Complete pathological response (pCR): 23%, Near pCR: 17%, Partial response: 13%, Non-response: 47%",
            "AE": "None",
            "Other": "Baseline ctDNA detectable in 50% of patients. Post-surgery ctDNA positivity predicted higher risk of disease recurrence (RFS HR 7.83, 95% CI 0.82-74.48; p=0.0002) and poorer melanoma-specific survival (MSS HR 6.35, 95% CI 0.36-110.70; p=0.0337).",
            "Conclusion": "Post-surgery ctDNA positivity can signal imminent recurrence, thus offering a window for personalized adjuvant therapy modification."
        },
        {
            "Title": "Neoadjuvant immune checkpoint inhibitors for patients with resectable stage III/IV melanoma: A nationwide real-life study in France (NEOMEL).",
            "Indication": "Resectable stage III/IV melanoma",
            "Drug": "Anti-PD-1 monotherapy or Nivolumab + Ipilimumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "Pathologic complete response (pCR): 44%, Major pathologic response (MPR): 59%, Radiologic overall response rate: 54%",
            "AE": "All grade immune-related adverse events (irAEs): 31%, Grade 3-4 irAEs: 10%, Grade 5 irAE: 1%",
            "Other": "None",
            "Conclusion": "NT-ICI for advanced melanoma demonstrated its efficacy with high rates of pCR in a real-life setting and an acceptable safety profile, particularly NT anti-PD1 monotherapy."
        },
        {
            "Title": "Neoadjuvant immunotherapy (neoIT) in melanoma: A prediction tool for pathological response and recurrence, and the role of adjuvant (Adj) therapy (Tx) to improve recurrence-free survival (RFS).",
            "Indication": "Stage IIIB\u2013D melanoma",
            "Drug": "PD1-based neoIT (PD1 alone, PD1 + anti-CTLA-4, PD1 + novel IT agents)",
            "PFS": "None",
            "OS": "None",
            "ORR": "Major Pathological Response (MPR): 64%, Non-MPR: 36%",
            "AE": "None",
            "Other": "Clinical, pathological, and radiological features can accurately predict MPR or non-MPR to neoIT, as well as recurrence. The addition of radiological response improved predictive accuracy (AUC = 0.86).",
            "Conclusion": "Adj TT or PD1 reduces recurrence and should be discussed with pts with pNR. De-escalation of surgery (6 adj Tx) should be investigated in MPR pts."
        },
        {
            "Title": "Neoadjuvant pembrolizumab and lenvatinib in resectable mucosal melanoma: NeoPlus study update.",
            "Indication": "Resectable mucosal melanoma",
            "Drug": "Pembrolizumab and Lenvatinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "Pathologic response rate: 38% (pCR: 9.5%, Near pCR: 9.5%, pPR: 19%)",
            "AE": "Most common TRAEs: Hypertension (50%), Proteinuria (46%), LDH increased (35%), Hypothyroidism (31%)",
            "Other": "Increased CD8+ T cell infiltration observed in tumor with pathologic response.",
            "Conclusion": "The combination of pembrolizumab plus lenvatinib as neoadjuvant therapy had moderate anti-tumor efficacy in resectable mucosal melanoma. Increased CD8+ T cell infiltration was observed in tumor with pathologic response, supporting further investigation in mucosal melanoma."
        },
        {
            "Title": "Incidence and risk factors for central nervous system (CNS) metastasis in patients with stage III melanoma treated with adjuvant anti-PD1 immunotherapy.",
            "Indication": "Stage III melanoma",
            "Drug": "Adjuvant anti-PD1 immunotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "1-, 2-, and 5-year rates of CNS metastasis were 7%, 12%, and 17% respectively. Risk factors for CNS metastasis included AJCC stage IIID, increased primary tumor Breslow thickness, ulceration, and mitotic rate.",
            "Conclusion": "Despite the established impact of adjuvant anti-PD1 immunotherapy on distant metastasis-free survival, the incidence of CNS metastasis in stage III melanoma patients treated with adjuvant anti-PD1 immunotherapy remains high. The results support the need for continued investigation of risk factors, prevention strategies, and optimal surveillance for CNS metastasis in stage III melanoma patients."
        },
        {
            "Title": "Prognosis of deep margin excisions within or beyond subcutaneous fat for invasive acral melanoma of the sole: A multi-institutional retrospective study.",
            "Indication": "Invasive acral melanoma of the sole",
            "Drug": "Wide excision (WE) of primary tumors",
            "PFS": "None",
            "OS": "5-year OS: S-group 84% vs. D-group 69%; P < 0.01",
            "ORR": "None",
            "AE": "None",
            "Other": "5-year DFS: S-group 70% vs. D-group 41%; P < 0.01. No significant differences in LRFS, DFS, and OS between groups in multivariable Cox proportional hazard models and after propensity-score matching.",
            "Conclusion": "In sole acral melanoma, wide excision with excessive deep margins beyond the subcutaneous fat did not improve prognosis. Excision of the primary tumor within the subcutaneous fat may be an optimal deep margin for sole acral melanoma."
        },
        {
            "Title": "Identifying the high-risk group in stage pT1bN0 cutaneous melanoma.",
            "Indication": "Stage pT1bN0 cutaneous melanoma",
            "Drug": "None",
            "PFS": "None",
            "OS": "10-year OS rate for the whole group was 86% (95% CI: 83-90%). In patients with BOTH pT1b features, the 10-year survival rate was 75% (95% CI: 62-89%) and 87% (95% CI: 84-91%) in case of just one feature (log-rank p = 0.008).",
            "ORR": "None",
            "AE": "None",
            "Other": "Patients with BOTH pT1b features (Breslow thickness > 0.8mm AND ulceration) had worse prognosis. Nodular or acral melanoma and males also had worse prognosis.",
            "Conclusion": "There is a specific subgroup of patients diagnosed with stage IB who have a relatively bad prognosis. Focusing on perioperative treatment or on a more strict follow-up schedule in this group may prove beneficial."
        },
        {
            "Title": "Primary immunotherapy monotherapy (PRIMO) in locally advanced cutaneous squamous cell carcinoma.",
            "Indication": "Locally advanced cutaneous squamous cell carcinoma (cSCC)",
            "Drug": "Cemiplimab, Pembrolizumab",
            "PFS": "1 and 2 year PFS were 72% and 51%, respectively.",
            "OS": "1 and 2 year OS were 76% and 64%, respectively.",
            "ORR": "Complete response (CR) in 41.7%, partial response (PR) in 38.9%, stable disease (SD) in 8.3%, and progressive disease (PD) in 11.1%.",
            "AE": "33.3% stopped PRIMO due to an immune-related adverse event (irAE).",
            "Other": "Median duration of response for CR was 15.5 months (8.8\u201323.3), and for PR was 3 months (2.0-6.8).",
            "Conclusion": "The use of PRIMO for locally advanced cSCC produces impressive response rates that appear durable without any additional therapy. This attractive alternative to the emerging neoadjuvant paradigm deserves prospective validation with longer-term follow-up."
        },
        {
            "Title": "Biomarker analysis and updated clinical outcomes: Neoadjuvant systemic treatment (NST) with nivolumab (nivo) and relatlimab (rela) in surgically resectable melanoma.",
            "Indication": "Surgically resectable melanoma",
            "Drug": "Nivolumab, Relatlimab",
            "PFS": "None",
            "OS": "None",
            "ORR": "Major pathologic response rate (MPR) (#10% viable tumor) of 63%.",
            "AE": "None",
            "Other": "Baseline immune gene expression signatures (GES) associated with major pathologic response include higher GES of B cells, CD45+ cells, CD8 T cells, and increased expression of TIGIT, IDO1, and IFN-g. Higher B7-H3 in non-MPR patients may indicate a clinically actionable strategy for further evaluation.",
            "Conclusion": "To our knowledge, this is the first longitudinal RNA analysis reported from patients treated with nivo+rela. Baseline immune features associated with major pathologic response include higher GES of B cells, CD45+ cells, CD8 T cells, and increased expression of TIGIT, IDO1, and IFN-y. Additionally, higher B7-H3 in non-MPR patients may indicate a clinically actionable strategy for further evaluation."
        },
        {
            "Title": "Metabolic factors affecting response to adjuvant anti-PD1 therapy for melanoma.",
            "Indication": "Melanoma",
            "Drug": "Anti-PD-1 therapy",
            "PFS": "None",
            "OS": "T2DM associated with decreased OS (HR=2.51, 95% CI 1.14-5.52; p=0.0219). Metformin use associated with improved OS (HR=0.28, 95% CI 0.08-0.9; p=0.0385).",
            "ORR": "None",
            "AE": "None",
            "Other": "T2DM associated with decreased RFS (HR=1.92, 95% CI 1.16-3.164; p=0.011). Metformin use associated with improved RFS (HR=0.42, 95% CI 0.17-1.06; p=0.065).",
            "Conclusion": "T2DM is associated with decreased RFS for patients with melanoma treated with adjuvant anti-PD-1 therapy. Metformin use was associated with significantly improved RFS and OS."
        },
        {
            "Title": "Combined EORTC FDG-PET/CT and RECIST 1.1 response criteria and prediction of pathological response to neoadjuvant nivolumab and ipilimumab for clinical stage III melanoma.",
            "Indication": "Clinical stage III melanoma",
            "Drug": "Nivolumab and ipilimumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Using combined RECIST 1.1 and EORTC-PET/CT analysis, double objective responders had 100% specificity to MPR and double progressors had 95% specificity to non-MPR.",
            "Conclusion": "Using combined RECIST 1.1 and EORTC-PET/CT response criteria may help to predict pathological response in patients with clinical stage III melanoma who undergo neoadjuvant nivolumab and ipilimumab."
        },
        {
            "Title": "Pathologic response rates to neoadjuvant pembrolizumab in locally advanced (LA) resectable cutaneous squamous cell carcinoma (cSCC).",
            "Indication": "Locally advanced resectable cutaneous squamous cell carcinoma (cSCC)",
            "Drug": "Pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "pCR in 57%, pPR in 8%, pNR in 31%",
            "AE": "One Grade 5 (NSTEMI) and two Grade 3 (hepatitis, colitis) treatment-related AEs observed.",
            "Other": "Median RFS was 13 (pCR) versus 10.5 (non-pCR) months.",
            "Conclusion": "Neoadjuvant pembrolizumab is efficacious in resectable LA cSCC with a high pCR rate (57%). RT was de-escalated in 93% of pCR patients. No relapse events in pCR patients."
        },
        {
            "Title": "Phase 1b trial of IFx-Hu2.0, a novel in situ cancer vaccine, in checkpoint inhibitor-resistant Merkel cell carcinoma (MCC) and cutaneous squamous cell carcinoma (cSCC).",
            "Indication": "Checkpoint inhibitor-resistant Merkel cell carcinoma (MCC) and cutaneous squamous cell carcinoma (cSCC)",
            "Drug": "IFx-Hu2.0",
            "PFS": "None",
            "OS": "None",
            "ORR": "56% objective response in MCC patients and 14% in cSCC patients to ICI rechallenge.",
            "AE": "Well tolerated with 1 high-grade toxicity (G3 hepatitis).",
            "Other": "Frequent (56%) and durable responses in advanced MCC to ICI re-challenge despite prior resistance.",
            "Conclusion": "IT IFx-Hu2.0 is safe and feasible to administer at weekly dosing repeated up to 3 weeks. Preliminary biomarker analyses to investigate immune priming effect are ongoing."
        },
        {
            "Title": "The impact of adjuvant radiation therapy on disease-specific survival in stage I and II Merkel cell carcinoma: A SEER database analysis.",
            "Indication": "Merkel Cell Carcinoma (MCC)",
            "Drug": "Adjuvant Radiation Therapy (RT)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "5-year Disease-Specific Survival (DSS): Stage I - 82.8% (RT) vs 87.5% (Surgery Monotherapy), Stage II - 77.0% (RT) vs 74.4% (Surgery Monotherapy)",
            "Conclusion": "This study demonstrates that there is no statistically significant disease-specific survival benefit from adjuvant radiation therapy for stage I and II MCC in the SEER database. Interestingly, in stage I patients we find a significant negative association between adjuvant RT and DSS. Younger patients are more likely to receive adjuvant RT, which likely contributes to higher overall survival previously reported from the SEER database, but the explanation for decreased DSS in patients who received RT remains unclear. Further studies regarding the effect of radiation therapy on local disease control and tumor microenvironment are needed to establish the appropriate role of RT in stage I-II MCC patients."
        },
        {
            "Title": "Dynamics of circulating cytokines and chemokines during and after tumor-infiltrating lymphocyte cell therapy with lifileucel in advanced melanoma patients.",
            "Indication": "Advanced Melanoma",
            "Drug": "Lifileucel (autologous tumor-infiltrating lymphocyte (TIL) cell therapy)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Cytokine and chemokine levels measured during treatment are consistent with lack of severe systemic inflammation observed in patients treated with lifileucel. Cytokine and chemokine dynamics did not predict for response to lifileucel.",
            "Conclusion": "These data support the hypothesized mechanism of action of NMA-LD in the lifileucel regimen. Cytokine levels measured during treatment are consistent with lack of severe systemic inflammation observed in patients treated with lifileucel. Cytokine and chemokine dynamics did not predict for response to lifileucel."
        },
        {
            "Title": "Durability of immune checkpoint inhibitor (ICI) response following treatment discontinuation in advanced Merkel cell carcinoma (MCC).",
            "Indication": "Advanced Merkel Cell Carcinoma (MCC)",
            "Drug": "Immune Checkpoint Inhibitor (ICI) therapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "Complete Response (CR) - 47%, Partial Response (PR) - 51%, Stable Disease (SD) - 2%",
            "AE": "None",
            "Other": "47% of patients experienced disease progression at a median of 11.2 months from ICI cessation. Lower rate of progression in patients who achieved CR vs. non-CR (24% vs 67%, p=0.006). Higher risk for progression in patients with viral MCC (75%) compared to UV-associated MCC (31%, p=0.02). 73% of ICI-retreated patients achieved an objective response to rechallenge.",
            "Conclusion": "Patients with advanced MCC have a substantial risk of disease progression following treatment discontinuation despite initial favorable ICI response, 47% in our series with a median time to event of 11.2 months, particularly in those that achieve less than a complete response. In the setting of disease progression post-ICI discontinuation, most patients maintain sensitivity to retreatment with the same drug class. Virus-positive MCC may be a risk factor for post-discontinuation relapse, which should be validated in future studies."
        },
        {
            "Title": "Roginolisib, an oral, highly selective and allosteric modulator of phosphoinositide 3-kinase inhibitor delta (PI3Kd) in patients with uveal melanoma and advanced cancers.",
            "Indication": "Uveal melanoma and advanced cancers",
            "Drug": "Roginolisib (IOA-244)",
            "PFS": "None",
            "OS": "Median OS for Part A: 20.8 months; Part B.1: 20.1 months; Part B.2: not determined; 1-year OS rate: Part A 66.7%; Part B.1 71%; Part B.2 immature",
            "ORR": "ORR (RECIST1.1): PR: 1/29 (3%); SD: 21/29 (72%)",
            "AE": "Only Grade 1 and 2 AEs by CTCAE v.5 were observed, including 2 cases of transient (lasting <24 hrs) diarrhea and 2 of AST/ALT elevation. Grade 3 toxicities were transient and observed in 2/8 (25%) pts.",
            "Other": "Phenotypic changes in circulating immune cells by mass cytometry (i.e., CyTOF); response assessments by radiomics; analysis of circulating protein signatures via Olink proteomics.",
            "Conclusion": "Roginolisib is well tolerated at 80 mg, demonstrates efficacy, and induces phenotypic changes in circulating immune cells. At the RP2D, the most robust anti-tumor activity was observed in FL (50% PR) and UM pts (3% PR; 72% SD)."
        },
        {
            "Title": "A phase 1/2 study to investigate the safety and efficacy of OBX-115 engineered tumor-infiltrating lymphocyte (TIL) cell therapy in patients (pts) with advanced solid tumors.",
            "Indication": "Advanced solid tumors",
            "Drug": "OBX-115 engineered tumor-infiltrating lymphocyte (TIL) cell therapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "Primary endpoint: overall response rate (ORR) by RECIST 1.1",
            "AE": "None",
            "Other": "Extensive correlative translational analyses will focus on immunophenotypic changes in the TME, ITH, profiling of tumor cell metabolism, and TIL function.",
            "Conclusion": "None"
        },
        {
            "Title": "A phase II trial of nivolumab plus axitinib in patients with anti-PD1 refractory advanced melanoma.",
            "Indication": "Anti-PD1 refractory advanced melanoma",
            "Drug": "Nivolumab plus axitinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "Primary endpoint: overall response rate (ORR) by RECIST 1.1",
            "AE": "None",
            "Other": "Extensive correlative translational analyses will focus on immunophenotypic changes in the TME, ITH, profiling of tumor cell metabolism, and TIL function.",
            "Conclusion": "None"
        },
        {
            "Title": "A phase 3 trial of IMC-F106C (PRAME x CD3) plus nivolumab versus standard nivolumab regimens in HLA-A*02:01+ patients with previously untreated advanced melanoma (PRISM-MEL-301)",
            "Indication": "advanced melanoma",
            "Drug": "IMC-F106C (PRAME x CD3) plus nivolumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Combining IMC-F106C with the anti-PD1 ICI nivolumab has the potential to improve progression free survival (PFS), OS, and response rate (RR)."
        },
        {
            "Title": "A pilot trial of autologous tumor infiltrating lymphocytes (lifileucel, LN-144) for patients with asymptomatic melanoma brain metastases",
            "Indication": "melanoma brain metastases",
            "Drug": "lifileucel (LN-144)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The primary endpoint is feasibility, defined as \u22657/10 patients who undergo tumor resection successfully receiving lifileucel infusion."
        },
        {
            "Title": "A randomized, controlled, multicenter, phase 3 study of vusolimogene oderparepvec (VO) combined with nivolumab vs treatment of physician\u2019s choice in patients with advanced melanoma that has progressed on anti\u2013PD-1 and anti\u2013CTLA-4 therapy (IGNYTE-3)",
            "Indication": "advanced melanoma",
            "Drug": "vusolimogene oderparepvec (VO) combined with nivolumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "This phase 3 study will evaluate the overall survival and clinical benefit as well as the safety of VO plus nivo for patients with advanced melanoma whose disease has progressed after receiving anti\u2013PD-1 and anti\u2013CTLA-4 therapy."
        },
        {
            "Title": "A phase 2 trial of IO102-IO103 and nivolumab-relatlimab fixed-dose combination in previously untreated, unresectable melanoma.",
            "Indication": "Unresectable melanoma",
            "Drug": "IO102-IO103 and nivolumab-relatlimab",
            "PFS": "None",
            "OS": "None",
            "ORR": "80% in the phase 1/2 MM1636 trial",
            "AE": "None",
            "Other": "Secondary endpoints include safety assessed by Common Terminology Criteria for Adverse Events v5.0, progression-free survival (PFS) by RECIST v1.1, duration of disease response, and disease control rate.",
            "Conclusion": "No prior studies have evaluated IO102-IO103 in combination with dual PD-1 and LAG-3 blockade with nivolumab-relatlimab, and to our knowledge, there are no existing clinical data for other investigational agents in combination with nivo-rela."
        },
        {
            "Title": "A phase III, open-label, multicenter, randomized controlled trial of tunlametinib versus investigator-selected chemotherapy in patients with advanced NRAS-mutant melanoma who had previously received immunotherapy.",
            "Indication": "Advanced NRAS-mutant melanoma",
            "Drug": "Tunlametinib",
            "PFS": "4.2 months (phase II pivotal registrational study)",
            "OS": "None",
            "ORR": "34.8% (phase II pivotal registrational study)",
            "AE": "None",
            "Other": "Secondary endpoints included overall survival, objective response rate, disease control rate, duration of response, safety, pharmacokinetics, and exposure-response.",
            "Conclusion": "Tunlametinib has shown encouraging efficacy with a manageable safety profile in the phase II pivotal registrational study."
        },
        {
            "Title": "Randomized phase II trial of pembrolizumab/lenvatinib (P+L) +/- responder fecal microbiota transplant (R-FMT) in PD-1 relapsed/refractory (R/R) cutaneous melanoma (MEL).",
            "Indication": "PD-1 relapsed/refractory cutaneous melanoma",
            "Drug": "Pembrolizumab/lenvatinib (P+L) +/- responder fecal microbiota transplant (R-FMT)",
            "PFS": "None",
            "OS": "None",
            "ORR": "Primary endpoint: ORR assessed using RECIST v1.1 by Blinded Independent Central Review (BICR)",
            "AE": "None",
            "Other": "Secondary endpoints include safety, progression-free survival (PFS), overall survival (OS), and landmark PFS/OS.",
            "Conclusion": "We hypothesized that intestinal dysbiosis mediates PD-1 resistance in PD-1 R/R MEL, microbiome modulation may reverse TKI toxicity, and that R-FMT may resensitize MEL to PD-1 blockade."
        },
        {
            "Title": "Reduced dose fludarabine/cyclophosphamide lymphodepletion for tumor-infiltrating lymphocytes therapy (LN-144, lifileucel) in metastatic melanoma.",
            "Indication": "Metastatic melanoma",
            "Drug": "LN-144 (lifileucel)",
            "PFS": "None",
            "OS": "None",
            "ORR": "31% (phase II study with traditional lymphodepletion)",
            "AE": "None",
            "Other": "Secondary objectives will be to evaluate the efficacy parameters including ORR, DOR, progression-free survival (PFS), and overall survival (OS), and the safety profile of TIL in combination with a reduced dose Flu/Cy LD regimen.",
            "Conclusion": "We hypothesize that a reduced dose of Flu/Cy LD for TIL therapy will have similar TIL expansion and persistence post-infusion, resulting in similar efficacy with a reduced toxicity profile."
        },
        {
            "Title": "Novel RNA-nanoparticle vaccine for the treatment of early melanoma recurrence following adjuvant anti-PD-1 antibody therapy.",
            "Indication": "Early melanoma recurrence following adjuvant anti-PD-1 antibody therapy",
            "Drug": "RNA-nanoparticle vaccine",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Initial feasibility and safety of vaccine generation, ctDNA surveillance as early detection of adjuvant failure, biological information on immunotherapy resistance to aPD1 therapy",
            "Conclusion": "The study aims to assess safety, tolerability, and feasibility of tumor-specific RNA-LPs, and collect important biological correlates."
        },
        {
            "Title": "A phase I/IIa of [212Pb]VMT01 targeted a-particle therapy for unresectable or metastatic melanoma.",
            "Indication": "Unresectable or metastatic melanoma",
            "Drug": "[212Pb]VMT01",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Safety, tolerability, pharmacokinetics, and efficacy of [212Pb]VMT01",
            "Conclusion": "The trial aims to evaluate the safety of [212Pb]VMT01, determine the maximum tolerated or maximum feasible dose, and determine the anti-tumor efficacy of [212Pb]."
        },
        {
            "Title": "A phase 3 trial of fixed dose combinations of fianlimab (anti\u2013LAG-3) + cemiplimab (anti\u2013PD-1) versus relatlimab + nivolumab in patients with unresectable or metastatic melanoma.",
            "Indication": "Unresectable or metastatic melanoma",
            "Drug": "Fianlimab + cemiplimab",
            "PFS": "None",
            "OS": "None",
            "ORR": "Primary endpoint",
            "AE": "None",
            "Other": "Duration of response, disease control rate, investigator-assessed ORR and PFS, safety, pharmacokinetics, and immunogenicity",
            "Conclusion": "The study aims to demonstrate the superiority of fianlimab + cemiplimab compared with relatlimab + nivolumab as measured by ORR."
        },
        {
            "Title": "First-in-human study of 225actinium mti-201 (225Ac-MTI-201) in metastatic uveal melanoma (UM).",
            "Indication": "Metastatic uveal melanoma",
            "Drug": "225Ac-MTI-201",
            "PFS": "Secondary endpoint",
            "OS": "Secondary endpoint",
            "ORR": "Secondary endpoint",
            "AE": "None",
            "Other": "Pharmacokinetics and clearance of 225Ac-MTI-201, safety of a single intravenous dose",
            "Conclusion": "The primary objective is to determine the safety of a single intravenous dose of 225Ac-MTI-201 relative to radioactivity dose."
        },
        {
            "Title": "A phase 2 study to determine the pathological (path) response to neo-adjuvant nivolumab (nivo) and relatlimab (rela) in stage I to III resectable Merkel cell carcinoma (Neo-MCC).",
            "Indication": "Merkel cell carcinoma",
            "Drug": "Nivolumab and Relatlimab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: rate of pCR at RES after NAT using INMC response criteria. Secondary endpoints: RFS, OS, safety/tolerability, surgical outcomes, QOL, and biomarker analyses.",
            "Conclusion": "The Neo-MCC trial will examine whether combination PD-1 blockade plus lymphocyte-activation 3 (LAG3) checkpoint inhibition will achieve a high rate of path response with manageable toxicity in patients with resectable stage I-III MCC."
        },
        {
            "Title": "A phase 1/2 study of vusolimogene oderparepvec (RP1) in primary melanoma (mel) to reduce the risk of sentinel lymph node (SLN) metastasis.",
            "Indication": "Primary melanoma",
            "Drug": "Vusolimogene oderparepvec (RP1)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: rate of SLN positivity in the overall cohort. Secondary endpoints: safety and tolerability of RP1, RFS, and OS.",
            "Conclusion": "This trial addresses a crucial gap in understanding RP1\u2019s impact on SLN dynamics, holding promise for preventing disease recurrence in this high-risk population."
        },
        {
            "Title": "The MARIANE-trial: Multicenter phase 1b/2 trial testing safety and efficacy of neoadjuvant intradermal ipilimumab and nivolumab in high-risk stage II melanoma.",
            "Indication": "High-risk stage II melanoma",
            "Drug": "Ipilimumab and Nivolumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: feasibility and efficacy of intradermal IPI plus NIVO in high-risk stage II melanoma patients.",
            "Conclusion": "This trial is the first to test feasibility and efficacy of intradermal IPI plus NIVO in high-risk stage II melanoma patients."
        },
        {
            "Title": "INTerpath-001: Pembrolizumab with V940 (mRNA-4157) versus pembrolizumab with placebo for adjuvant treatment of high-risk stage II-IV melanoma.",
            "Indication": "High-risk stage II-IV melanoma",
            "Drug": "Pembrolizumab and V940 (mRNA-4157)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: RFS by investigator review. Secondary endpoints: DMFS by investigator review, overall survival, safety and tolerability, and quality of life.",
            "Conclusion": "This trial is designed to evaluate the efficacy and safety of adjuvant pembrolizumab plus V940 versus pembrolizumab plus placebo in patients with resected high-risk stage II-IV melanoma."
        },
        {
            "Title": "Intralesional administration of L19IL2/L19TNF in high-risk locally advanced basal cell carcinoma or cutaneous squamous cell carcinoma",
            "Indication": "High-risk locally advanced basal cell carcinoma (BCC) or cutaneous squamous cell carcinoma (cSCC)",
            "Drug": "Bifikafuspalfa (L19IL2) and Onfekafuspalfa (L19TNF)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The first part of the trial has been completed with 28 laBCC pts and 12 lacSCC pts evaluable for safety and efficacy. The pre-specified activity goal for the first stage of accrual was met, and the second stage of accrual began in September 2023."
        },
        {
            "Title": "TRICK-MCC: A proof-of-concept, investigator-initiated study of combination therapy with anti\u2013PD-1, anti\u2013LAG-3, and anti\u2013TIM-3 in participants with advanced or metastatic PD-(L)1 refractory Merkel cell carcinoma",
            "Indication": "Advanced or metastatic PD-(L)1 refractory Merkel cell carcinoma (MCC)",
            "Drug": "Retifanlimab (anti-PD-1), INCAGN02385 (anti-LAG-3), INCAGN02390 (anti-TIM-3)",
            "PFS": "None",
            "OS": "None",
            "ORR": "Primary objective",
            "AE": "Secondary objective",
            "Other": "None",
            "Conclusion": "We hope to gain insights that are broadly applicable to solid tumor immunotherapy."
        },
        {
            "Title": "A phase 2, open-label, 2-cohort study to evaluate patient preference for nivolumab (NIVO) + relatlimab (RELA) fixed-dose combination (FDC) subcutaneous (SC) vs NIVO + RELA FDC intravenous (IV) and NIVO SC vs NIVO IV in participants with melanoma",
            "Indication": "Melanoma",
            "Drug": "Nivolumab (NIVO) + Relatlimab (RELA)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Secondary endpoints include safety outcomes such as adverse events (AEs), serious AEs, treatment-related AEs, AEs leading to discontinuation, immune-mediated AEs, other events of special interest, injection/infusion-related AEs, deaths, and laboratory abnormalities.",
            "Other": "None",
            "Conclusion": "The primary endpoint is the proportion of participants preferring the SC route of administration based on question 7 of the Patient Experience and Preference Questionnaire after the Cycle 4 Day 1 dose."
        },
        {
            "Title": "PH-762: PD-1 intratumoral immunotherapy for cutaneous carcinoma",
            "Indication": "Cutaneous squamous cell carcinoma, melanoma, or Merkel cell carcinoma",
            "Drug": "PH-762",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "This clinical study, enrolling patients since November 2023, will establish the basis for continued clinical development of PH-762."
        },
        {
            "Title": "Efficacy and safety of entrectinib in children with extracranial solid or primary central nervous system (CNS) tumors harboring NTRK or ROS1 fusions.",
            "Indication": "Extracranial solid or primary CNS tumors harboring NTRK or ROS1 fusions",
            "Drug": "Entrectinib",
            "PFS": "NE (23.1, NE) for NTRK; NE (21.8, NE) for ROS1",
            "OS": "NE (35.7, NE) for NTRK; NE (NE, NE) for ROS1",
            "ORR": "68.2% (95% CI 52.4\u201381.4) for NTRK; 65.0% (95% CI 40.8\u201384.6) for ROS1",
            "AE": "Pyrexia (50.5%), vomiting (40.7%), anemia (40.7%)",
            "Other": "Median duration of survival follow-up was 24.2 months (NTRK: range 1\u201366) and 27.6 months (ROS1: range 1\u201373).",
            "Conclusion": "Entrectinib continued to yield rapid and durable responses in pediatric patients with extracranial solid or primary CNS tumors harboring an NTRK or ROS1 fusion. The safety profile of entrectinib was in line with previous reports."
        },
        {
            "Title": "Phase IB study of tovorafenib for children with relapsed/recurrent low-grade gliomas and other MAPK pathway activated tumors.",
            "Indication": "Relapsed/recurrent low-grade gliomas and other MAPK pathway activated tumors",
            "Drug": "Tovorafenib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Decreased growth velocity (median 0.09 cm/year; range 0.02-0.3) observed in all 13 of 13 pre-pubertal females, 14 or males, 16 years of age receiving \u22656 months of protocol 1B therapy.",
            "Other": "The 2S-Sub-TITE model recommended 530 mg/m2 for BSA \u22641.5 m2 and 420 mg/m2 for BSA >1.5 m2. Dose modifications were required in 8/22 versus 1/13 patients treated at 530 and 420 mg/m2, respectively.",
            "Conclusion": "Oral weekly tovorafenib is well tolerated and shows preliminary efficacy. Decreased growth velocity was observed in pre-pubertal patients on drug and warrants further investigation to understand the mechanism of action. Based on the number of dose modifications required for toxicity, the chosen RP2D for weekly oral dosing of tovorafenib was 420 mg/m2."
        },
        {
            "Title": "Efficacy and safety of erdafitinib in pediatric patients with advanced solid tumors and FGFR alterations in the phase 2 RAGNAR trial.",
            "Indication": "Advanced solid tumors with FGFR alterations",
            "Drug": "Erdafitinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Hyperphosphatemia (64%), diarrhea (64%), pain in extremity (45%), alanine transaminase increased (36%), nausea (36%), onycholysis (27%)",
            "Other": "In this small pediatric population comprising primarily refractory HGG and LGG with any FGFR alteration, erdafitinib demonstrated limited objective responses but promising disease control with acceptable safety.",
            "Conclusion": "Erdafitinib demonstrated limited objective responses but promising disease control with acceptable safety in pediatric patients with advanced solid tumors and FGFR alterations."
        },
        {
            "Title": "A pilot study of post-consolidation chemoimmunotherapy for high-risk neuroblastoma (ANBL19P1): A report from the Children\u2019s Oncology Group.",
            "Indication": "High-risk neuroblastoma",
            "Drug": "Temozolomide, irinotecan, dinutuximab, sargramostim, isotretinoin",
            "PFS": "None",
            "OS": "One-year OS: 97.5% \u00b1 2.7%",
            "ORR": "None",
            "AE": "Febrile neutropenia: 7.5%, Diarrhea: 2.5%, Anorexia: 7.5%, Dehydration: 7.5%, Enterocolitis infectious: 10%, Sepsis: 5%, Infections & infestations-Other: 5%",
            "Other": "One-year EFS: 90.0% \u00b1 5.0%",
            "Conclusion": "Administration of post-consolidation chemoimmunotherapy in patients who underwent tandem ASCT is tolerable and met pre-defined feasibility criteria. The impact of this approach on survival outcomes will be studied in a future COG trial."
        },
        {
            "Title": "Preliminary experience of ex-vivo expanded allogeneic universal donor TGFbi NK cell infusions in combination with irinotecan, temozolomide, and dinutuximab in patients with relapsed or refractory neuroblastoma: The Allo-STING trial.",
            "Indication": "Relapsed or refractory neuroblastoma",
            "Drug": "Irinotecan, temozolomide, dinutuximab, TGFbi NK cells",
            "PFS": "None",
            "OS": "None",
            "ORR": "Three patients achieved a partial response. A fourth patient attained prolonged stable disease (9 months).",
            "AE": "Grade 1 fever with two NK cell infusions, no other adverse events attributable to NK cells. Adverse events attributable to CIT were similar to those described in COG ANBL1221. Treatment delays for cytopenias due to CIT occurred in two patients.",
            "Other": "None",
            "Conclusion": "UD allo-TGFbi NK cells can be safely and feasibly administered to children with RR NBL after treatment with CIT, with early objective responses observed in the preliminary cohort of patients. Ongoing studies will assess markers of response, NK cell persistence, pharmacokinetics, and pharmacodynamics."
        },
        {
            "Title": "Outcome of children and young adults with localized extremity rhabdomyosarcoma treated on Children\u2019s Oncology Group trials.",
            "Indication": "Localized extremity rhabdomyosarcoma",
            "Drug": "None",
            "PFS": "5-year EFS: 62.6% (95% CI: 54%-71.2%)",
            "OS": "5-year OS: 78.7% (95% CI: 71.4%-85.9%)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "IR localized extremity RMS patients treated on ARST0531 had suboptimal outcomes. Survival of IRS group III patients with R0 DPE was comparable to IRS group II patients. Females with localized extremity RMS had better OS than males."
        },
        {
            "Title": "Radiation dose escalation and local control for intermediate-risk rhabdomyosarcoma on ARST1431: A report from the Children\u2019s Oncology Group.",
            "Indication": "Intermediate-risk rhabdomyosarcoma",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "3-year LF rate for patients with tumors >5cm at diagnosis: 24.9% (95% CI 18.6%-31.7%). 3-year LF rate for patients with tumors \u22645cm at diagnosis: 9.9% (95% CI 5.2%-16.4%).",
            "Conclusion": "On ARST1431, larger tumors (>5cm at diagnosis) were associated with increased risk of LF. Dose escalation to 59.4Gy did not improve local control for patients with tumors >5cm at diagnosis with residual gross disease at the time of RT. For a select group of non-parameningeal, group III patients who underwent DPE followed by RT, local control was improved."
        },
        {
            "Title": "A phase II trial using risk adapted focal proton beam radiation and/or surgery with the addition of maintenance chemotherapy in intermediate risk rhabdomyosarcoma.",
            "Indication": "Intermediate risk rhabdomyosarcoma",
            "Drug": "Ifosfamide, vincristine (VCR), dactinomycin (DAC) +/- doxorubicin, cyclophosphamide (CYC), bevacizumab, sorafenib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "5-year disease-free survival (DFS) for the entire cohort was 67.5% with a median follow-up of 54 months. The 5-year DFS for the 24 patients that started cycle 3 was 70.3%. When limited to patients classified as high-risk in RMS2005 trial, the 5-year DFS was 81.4%. No patients experienced local failure after margin-negative surgery. The cumulative incidence (CI) of local failure (LF) was: 0% following 36 Gy RBE RT for microscopic residual disease, 8% for patients with unresected tumors <5 cm receiving standard dose RT (50.4 Gy RBE), and 10% for patients with unresected tumors >=5 cm receiving dose-escalated RT (59.4 Gy RBE). 5/6 patients with alveolar N1 disease recurred.",
            "Conclusion": "Local control with risk-adapted dose-escalated RT was excellent in IR patients. Those with nodal involvement fared poorly. While 4-cycles of MTC with CBvSor was not feasible, those who underwent at least 3 cycles experienced enhanced DFS outcomes, aligning with the positive results seen in other trials incorporating MTC."
        },
        {
            "Title": "Uptake of germline cancer genetic services in a randomized trial of remote telehealth services as compared to usual care: A report from the Childhood Cancer Survivor Study (CCSS).",
            "Indication": "Childhood cancer survivors",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "At 6 months, 40% (n=113) of survivors in the remote telehealth services arms utilized genetic services as compared to 16% (n=21) in the usual care arm (p<0.001). Factors associated with uptake of services included lower baseline genetic knowledge score, having more relatives with cancer, having a higher perceived risk of cancer, having a history of internet use, and not having a high deductible plan.",
            "Conclusion": "Offering remote centralized telehealth genetic services increases the uptake of genetic services in survivors of childhood cancer across the US using a collaborative PCP model. Although uptake was higher than usual care, barriers to uptake of genetic services remain, including concerns about cost and negative perceptions about genetic testing. Strategies to address multi-level barriers to genetic services are needed to realize the potential of genetic testing in childhood cancer survivors and patients in community practices."
        },
        {
            "Title": "A stepped wedge trial of a healthcare provider\u2013focused intervention to increase human papillomavirus (HPV) vaccine initiation among survivors of childhood cancer.",
            "Indication": "Childhood cancer survivors",
            "Drug": "HPV vaccine",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Overall incident HPV vaccine initiation was 16.0% at baseline. The adjusted odds of vaccine initiation were 1.3-fold (95% CI, 1.03-1.6; p=0.03) higher post-intervention vs. pre-intervention, accounting for secular trend OR of 1.1. Overall prevalence of vaccine initiation increased from 51.9% pre-intervention to 61.3% post-intervention.",
            "Conclusion": "The improvement in childhood cancer survivor HPV vaccine initiation rates in a diverse sample following implementation of a healthcare provider-focused intervention underscores the potential for pediatric oncology providers to positively impact HPV vaccine uptake in this vulnerable population."
        },
        {
            "Title": "Communicating cardiovascular health information and improving coordination with primary care: A Childhood Cancer Survivor Study randomized trial.",
            "Indication": "Childhood cancer survivors at high cardiovascular disease (CVD) risk",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "At baseline, rates of hypertension, dyslipidemia, and glucose intolerance were 53%, 52%, and 49%, respectively; 43% had more than one undertreated condition. The intervention was not associated with reduced undertreatment vs. control (OR 0.9, 95% CI 0.7-1.3). Notably, 48% of intervention and 44% of control participants had less undertreatment after 1 year. Greater internal locus of control was associated with less undertreatment at 1 year (OR 0.7, 95% CI 0.6-0.9). The intervention group was more likely than controls to have CVD risk (+10% vs. -1%), SCP (+17% vs. -2%), and some late effect surveillance plan (+8% vs. +2%) documented within PCP records at 1 year vs. baseline (p<0.05 for all).",
            "Conclusion": "While a remotely delivered counseling intervention did not reduce CVD risk factor undertreatment compared with provision of test results alone, both study arms had over 40% reduction in undertreatment. These results suggest that simply providing a formal CVD risk assessment to high-risk cancer survivors and their PCPs may be effective."
        },
        {
            "Title": "Access for adolescents and young adults with hard-to-cure cancer to CINSARC score use in pediatric non-rhabdomyosarcoma soft tissue sarcoma (NRSTS) in the Children\u2019s Oncology Group (COG) studies ARST0332 and ARST1321",
            "Indication": "Non-rhabdomyosarcoma soft tissue sarcoma (NRSTS)",
            "Drug": "None",
            "PFS": "5-year 52.1% vs 74.6%, HR for CINSARC high was 2.4 (p<0.01)",
            "OS": "5-year 66.2% vs 83.6%, HR for CINSARC high was 2.7 (p<0.01)",
            "ORR": "None",
            "AE": "None",
            "Other": "CINSARC maintained the association with EFS among patients with non-metastatic disease (p=0.017), and with OS among patients with metastatic disease (p=0.031). GI analysis is ongoing and will be reported.",
            "Conclusion": "In this analysis from the largest prospective North American pediatric NRSTS studies to date, CINSARC score was prognostic for EFS and OS. Further analysis including additional clinical prognostic factors (tumor size, grade, and extent of resection) is ongoing and will be reported. Use of CINSARC score for risk stratification should be considered on future NRSTS clinical trials."
        },
        {
            "Title": "Comparison of immunoglobulin high-throughput sequencing MRD in bone marrow and peripheral blood in pediatric B-ALL: A report from the Children\u2019s Oncology Group AALL1731",
            "Indication": "Pediatric B-ALL",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Strong correlation between PB and BM HTS MRD with an overall correlation coefficient of 0.75 (P<0.001). The ratio of BM/PB MRD varied and was highest among patients with Unfavorable cyto suggesting BM tropism. PB MRD was detectable in nearly all patients with flow EOI BM MRD \u22650.01%, a threshold warranting therapy intensification.",
            "Conclusion": "This is the largest analysis of paired B-ALL BM/PB HTS MRD to date. We show strong correlation between PB and BM across risk and cytogenetic groups. PB HTS MRD may provide a useful adjunct for screening and clinical management of B-ALL patients. Defining a PB HTS MRD threshold useful for risk stratification will require correlation with outcome."
        },
        {
            "Title": "Vincristine (VCR) pharmacokinetics (PK) in infants dosed using a body surface area (BSA) banded dosing table and in older children dosed based on BSA: A pediatric early phase clinical trial network study (PEPN22P1)",
            "Indication": "Pediatric cancer",
            "Drug": "Vincristine (VCR)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The mean \u00b1 SD AUCb in the 39 evaluable patients was 54.7 \u00b1 19.8 ng\u00b7h/mL. The AUCb is similar in patients with a BSA <0.6 m2 dosed according to the BSA-banded infant dosing table and patients with a BSA \u22650.6 m2 dosed by multiplying their BSA times 1.5 mg/m2 (p=0.53, Mann Whitney test).",
            "Conclusion": "The VCR BSA-banded infant dosing table appears to provide uniform VCR exposure for infants and young children compared to conventional BSA-based dosing in older children. The study design and sampling strategy facilitated rapid accrual of a young population and generated more VCR PK data in infants within a year than has been published since the drug was approved in 1963. This study demonstrates the feasibility of systematically studying other anticancer drugs in infants and other special populations to ensure they are receiving safe and effective doses."
        },
        {
            "Title": "Evolution of tisagenlecleucel use for the treatment of pediatric and young adult relapsed/refractory (r/r) B-cell acute lymphoblastic leukemia (B-ALL): Center for International Blood & Marrow Transplant Research (CIBMTR) registry results.",
            "Indication": "Relapsed/refractory B-cell acute lymphoblastic leukemia (B-ALL)",
            "Drug": "Tisagenlecleucel",
            "PFS": "None",
            "OS": "36-month probabilities (95% CI) with and without censoring were 66 (61-71) and 62 (57-66), respectively.",
            "ORR": "None",
            "AE": "None",
            "Other": "Median RFS improved: 18 months in 2018, 27 months in 2020, and not estimable in 2021.",
            "Conclusion": "Pediatric and young adult patients with r/r B-ALL are receiving tisagenlecleucel earlier in the course of their disease treatment, reducing the use of HSCT for r/r disease, and prolonging RFS. As both real-world and clinical trial data supporting the curative potential of tisagenlecleucel grow, the use of HSCT in patients with remission after CAR-T should be carefully evaluated."
        },
        {
            "Title": "Brigatinib monotherapy in children with R/R ALK+ ALCL, IMT, or other solid tumors: Results from the BrigaPED (ITCC-098) phase 1 study.",
            "Indication": "ALK+ malignancies (R/R ALK+ ALCL, IMT, or other solid tumors)",
            "Drug": "Brigatinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Common treatment-related adverse events (AE) were (n any G; n \u2265 G3): abdominal pain (6;0), CPK increase (7;1), nausea/vomiting (6;0), AST/ALT elevation (4;0). Reported AEs of special interest included ophthalmological events (3;0) and weight gain (2;1), no brigatinib-related pulmonary toxicity or endocrine AEs were observed.",
            "Other": "PK exposure at DL2 was equivalent to that reported in adults at the approved dose. All 9 ALCL patients are still on therapy with an ongoing response.",
            "Conclusion": "The RP2D of brigatinib was established at DL2, corresponding to 150 mg (18-40 kg), or 240 mg (>40 kg) once daily. In general, the drug was well tolerated, with no instances of patients discontinuing dosing due to safety concerns. Brigatinib monotherapy demonstrated promising preliminary signs of efficacy in pediatric ALCL, although follow-up is still limited. The phase 2 part of this study has recently opened for accrual, and an AAF will soon be available for smaller children to define the RP2D in this population."
        },
        {
            "Title": "Association of elevated ctDNA burden following one cycle of chemotherapy with inferior outcomes for patients with metastatic Ewing sarcoma: A report from the Children\u2019s Oncology Group (COG).",
            "Indication": "Metastatic Ewing sarcoma",
            "Drug": "Chemotherapy (vincristine/doxorubicin/cyclophosphamide alternating with ifosfamide/etoposide)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "ctDNA was \u2265 0.5% in 86% of patients at baseline (median 25%, range 0-113%, n=264), in 53% (median 0.53%, range 0-15%, n=34) after 1 cycle (median day 14, range 11-20), and in 14% (median 0, range 0-53%, n=130) after 2 cycles (median day 29, range 25-36). Baseline ctDNA burden \u2265 0.5% was associated with an increased risk of EFS-event (3-year EFS 35% [95% CI 29-41%] vs. 61% [95% CI 43-76%], HR=2.2, P=0.002). ctDNA \u2265 0.5% after cycle 1 was also associated with an increased risk of EFS-event (3-year EFS 6% [95% CI 0.4-22%] vs. 56% [95% CI 29-76%], HR=6.0, P<0.0001).",
            "Conclusion": "ctDNA burden \u2265 0.5% following one cycle of chemotherapy identifies patients highly likely to relapse, albeit in a small cohort with available data at that time point. Conversely, ctDNA at a cut point of \u2265 0.5% after two cycles of chemotherapy was not prognostic, suggesting either more sensitive assays are needed or the prognostic value of ctDNA burden is diminished following additional therapy. These findings will enable novel trials of risk-adapted therapy focused on baseline and early ctDNA burden."
        },
        {
            "Title": "Impact of breaks in therapy on survival for pediatric patients with relapsed or refractory solid tumors: A single-center study.",
            "Indication": "Relapsed or refractory solid tumors",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Increased time from first R/R event to death was associated with a break in disease-directed therapy; type of diagnosis; non-metastatic stage at original diagnosis; type of first event; type of first salvage regimen; and receiving phase 1 therapy.",
            "Conclusion": "The prognosis for children with R/R solid tumors is poor. Most children experience multiple R/R events and therapy regimens without a break in therapy. Presence of a >30 day break between an R/R event and subsequent therapy did not negatively impact survival, implying that time between therapy regimens can safely be offered to some patients to promote quality of life, exploration of goals of care, and thoughtful decision-making, without compromising survival."
        },
        {
            "Title": "Safety and efficacy of selpercatinib in pediatric patients with RET-altered solid tumors: Updated results from LIBRETTO-121.",
            "Indication": "RET-altered solid tumors",
            "Drug": "Selpercatinib",
            "PFS": "Median follow-up of 18 months, the median PFS among all patients has not yet been reached, and the 24-month rate of PFS was 92.4% (95% CI: 73.0%-98.1%).",
            "OS": "None",
            "ORR": "Overall ORR was 83.3% (10/12). PTC patients had an ORR of 100% (5/5), and MTC patients had an ORR of 83.3% (5/6), while 1 MTC patient had stable disease.",
            "AE": "The most common TEAEs observed (\u226525% of patients) were diarrhea, headache, coronavirus infection, nausea, vomiting, elevated ALT, elevated AST, and pyrexia. The most common TEAEs \u2265G3 included constipation, reduced neutrophil count, vomiting, and weight gain, each occurring in 2 patients (7.4%).",
            "Other": "One patient (age 15 years) experienced a TEAE \u2265G3 of epiphysiolysis, a risk for selpercatinib in this population based on pre-clinical data.",
            "Conclusion": "Selpercatinib\u2019s safety profile remains consistent with prior reports from adult trials. These results, including more robust efficacy and PK data, continue to support the use of selpercatinib in pediatric and adolescent patients with RET-altered solid tumors."
        },
        {
            "Title": "Tamoxifen for breast cancer prevention among survivors of pediatric lymphoma previously treated with chest radiation: Clinical benefits, harms and tradeoffs.",
            "Indication": "Breast cancer prevention in survivors of pediatric lymphoma",
            "Drug": "Tamoxifen",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Increased risks for venous thromboembolism, deep vein thrombosis, coronary heart disease, stroke, and endometrial cancer during treatment",
            "Other": "Reduction in breast cancer deaths by 8.0 to 9.6 percentage points depending on age at initiation; reduction of 1950 to 3740 childbearing life years and 11 to 20 side-effects per breast cancer death averted",
            "Conclusion": "Tamoxifen use for primary breast cancer prevention among pediatric lymphoma survivors may further reduce breast cancer deaths but decisions might depend on survivor preferences for side effects vs. avoiding breast cancer and consideration of timing for childbearing."
        },
        {
            "Title": "Treatment modifications and mortality among female patients with subsequent breast cancer: A report from the Childhood Cancer Survivor Study (CCSS).",
            "Indication": "Subsequent breast cancer in childhood cancer survivors",
            "Drug": "Various treatments including anthracyclines, mastectomy, and radiotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Hematological toxicities (21% vs. 9%), other organ system-specific toxicities (36% vs. 11%)",
            "Other": "Strikingly higher all-cause mortality among survivors than controls (HR=3.5, 95% CI 2.2-5.6), especially after in situ disease (HR=9.9, 95% CI 2.2-44.2). Among survivors with invasive disease, breast cancer accounted for most deaths (51%), followed by other subsequent malignancies (15%) and cardiovascular diseases (15%).",
            "Conclusion": "Childhood cancer survivors with subsequent breast cancer generally receive guideline-concordant breast cancer treatment. While the treatments survivors receive differ from females with primary breast cancer, they do not experience higher rates of on-therapy treatment modifications. Despite this, they face excess mortality, primarily driven by other health conditions. Managing comorbidities is critical to enhancing long-term survival for this high-risk population."
        },
        {
            "Title": "Preclinical efficacy of BRG1/BRM ATPase inhibitor in B lymphoblastic leukemia.",
            "Indication": "B lymphoblastic leukemia",
            "Drug": "FHD-286",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "FHD-286 treatment resulted in decreased cell growth in a dose-dependent manner across all B-ALL cell lines. Sensitivity varied among cell lines with IC50s ranging from 2-7 nM for the most sensitive lines to 40-70 nM for less sensitive lines. Preliminary data indicates cell growth inhibition is only slightly attributable to cell cycle arrest and apoptosis induction, suggesting terminal differentiation as a mechanism of slowed proliferation.",
            "Conclusion": "Our results show FHD-286 can strongly inhibit B-ALL cell growth in vitro, indicating FHD-286 as a potential therapeutic agent to treat B-ALL. In vivo validation is ongoing. Further directions include defining the effect of FHD-286 on chromatin remodeling, gene expression, and exploring efficacy in combination therapy, which has been shown to increase FHD-286 efficacy against AML. Our data may ultimately justify inclusion of B-ALL patients in clinical studies of BRG1/BRM inhibitors."
        },
        {
            "Title": "Naxitamab-related adverse events within and across treatment cycles in patients with relapsed/refractory (R/R) high-risk neuroblastoma.",
            "Indication": "Relapsed/refractory high-risk neuroblastoma",
            "Drug": "Naxitamab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Grade \u22653 AEs reported in \u226510% of patients were hypotension (60%), pain (54%), urticaria (19%), bronchospasm (18%), and abdominal pain (16%). The frequency of related hypotension Grade \u22653 decreased across cycles, from Cycle 1 (47%) to Cycle 5 (33%) and across infusions, from Cycle 1 Day 1 (43%) to Cycle 1 Day 3 (18%) and Cycle 1 Day 5 (11%). Similar decreases were seen during Cycle 2 to Cycle 5. Among patients with Grade \u22653 hypotension, 73% had their first event on Cycle 1 Day 1, of whom 34% and 31% had their second event on Cycle 1 Day 3 or Cycle 2 Day 1, respectively. The frequency of related pain Grade 3 AEs decreased from Cycle 1 (53%) to Cycle 2 (37%), generally stabilizing thereafter with frequencies consistent across infusions. Most (77%) had their first event on Cycle 1 Day 1, of whom 82% had a second event on Cycle 1 Day 3. The frequency of related bronchospasm Grade 3 was relatively stable around 7% from Cycle 1 to Cycle 3, decreasing to 4% and 2% in Cycle 4 and Cycle 5, respectively. Patients generally experienced these events on Day 1 infusions with few reported on Day 3 or Day 5. No consistent pattern was seen for Grade 3 urticaria. None of the Grade \u22653 AEs of hypotension, urticaria, pain, or bronchospasm resulted in treatment discontinuation.",
            "Other": "None",
            "Conclusion": "The frequency of naxitamab-related Grade \u22653 AEs changed over the course of therapy. Hypotension was most frequent on Cycle 1 Day 1, decreasing across infusions and cycles. Pain frequency peaked in Cycle 1 and decreased in subsequent cycles, remaining stable across infusions. Bronchospasm events, though relatively rare, generally occurred on Day 1 and decreased across infusions. The results underline the importance of initial and ongoing monitoring within and across cycles to ensure appropriate and timely management."
        },
        {
            "Title": "Phase I trial of chidamide, an oral HDAC inhibitor, in combination with oral etoposide in patients with refractory/recurrent neuroblastoma.",
            "Indication": "Refractory/recurrent neuroblastoma",
            "Drug": "Chidamide and oral etoposide",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "61.3% experienced treatment-related grade 3/4 hematologic toxicity, no grade 3/4 non-hematological toxicity observed",
            "Other": "No objective responses were seen",
            "Conclusion": "In children with refractory/recurrent neuroblastoma, chidamide is well-tolerated at 14 mg/m2/d twice weekly when combined with oral etoposide 35 mg/m2/d daily. Prolonged disease stability achieved in patients with minimal residual diseases deserves further investigation."
        },
        {
            "Title": "Patterns of improvement following initial response in patients treated with naxitamab for relapsed/refractory high-risk neuroblastoma.",
            "Indication": "Refractory/relapsed high-risk neuroblastoma",
            "Drug": "Naxitamab plus granulocyte-macrophage colony-stimulating factor (GM-CSF)",
            "PFS": "None",
            "OS": "None",
            "ORR": "50% (26/52) overall response rate",
            "AE": "Naxitamab safety (N=74) has been reported previously",
            "Other": "23% of patients achieved a CR or PR only after C3. Among these patients, most had initial SD within specific bone or BM compartments before achieving a post-C3 CR/PR.",
            "Conclusion": "A considerable proportion (23%) of patients achieved a CR or PR only after C3. Among these patients, most had initial SD within specific bone or BM compartments before achieving a post-C3 CR/PR. Taken together, the results support the rationale for continued treatment of patients not achieving CR/PR at first assessment."
        },
        {
            "Title": "Type II RAF inhibitor tovorafenib in relapsed/refractory pediatric low-grade glioma (pLGG): Reversible decreases in growth velocity in the phase 2 FIREFLY-1 trial.",
            "Indication": "Relapsed/refractory pediatric low-grade glioma (pLGG)",
            "Drug": "Tovorafenib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "29.2% of 137 patients reported decreased growth velocity (GV); 19% had GV reduction \u226550% from baseline",
            "Other": "Early follow-up data from patients whose treatment was interrupted show consistent evidence for GV recovery and preservation of growth potential on bone age studies.",
            "Conclusion": "Decreased GV has been observed in patients treated with tovorafenib. Early follow-up data from patients whose treatment was interrupted show consistent evidence for GV recovery and preservation of growth potential on bone age studies."
        },
        {
            "Title": "Naxitamab chemo-immunotherapy regimens other than with irinotecan/temozolomide for patients with relapsed/refractory high-risk neuroblastoma.",
            "Indication": "Relapsed/refractory high-risk neuroblastoma",
            "Drug": "Naxitamab",
            "PFS": "None",
            "OS": "None",
            "ORR": "30% (5 complete responses, 2 partial responses, 2 mixed responses)",
            "AE": "Myelosuppression, pain, hypertension, hemorrhagic cystitis, BK infection",
            "Other": "Disease control rate (DCR) after 2 cycles was 56.6%",
            "Conclusion": "Naxitamab-based chemo-immunotherapy with regimens other than I/T exhibited similar and manageable toxicity profiles. Switching chemotherapeutics provided objective responses only to patients who had previously shown efficacy with I/T chemo-immunotherapy."
        },
        {
            "Title": "Revealing and targeting metabolic drivers contributing to treatment escape in diffuse midline glioma.",
            "Indication": "Diffuse midline glioma",
            "Drug": "Avapritinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Upregulation of genes associated with fatty acid metabolism and oxidative phosphorylation following avapritinib treatment. Identified compounds capable of inhibiting these drivers that demonstrate synergy with avapritinib.",
            "Conclusion": "Metabolic drivers may contribute to avapritinib resistance in DMG cells, and identified compounds capable of inhibiting these drivers that demonstrate synergy with avapritinib. Further testing of these combination therapies in vivo is intended to provide long-term clinical benefit to DMG patients receiving avapritinib."
        },
        {
            "Title": "Harmonizing cancer care delivery for AYAs with osteosarcoma: Comparison of pediatric and medical oncology approaches in a single center.",
            "Indication": "Osteosarcoma",
            "Drug": "MAP (Methotrexate, Doxorubicin, Cisplatin) vs. AP+ (Doxorubicin, Cisplatin, Ifosfamide)",
            "PFS": "Three-year PFS rates: 80% (MAP) vs. 66% (AP+), p=0.40",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Percent tumor necrosis varied widely with both regimens (range 0-99%) and was not statistically different (p=0.11).",
            "Conclusion": "No statistically significant difference in PFS for AYA patients with OS treated with a standard pediatric vs. adult type regimen containing at least 3-drug chemotherapy. Further study is needed to refine treatment for this patient population. These data support prospective efforts to harmonize AYA treatment paradigms in osteosarcoma."
        },
        {
            "Title": "MRI compared to 123I-MIBG scan for detection of central nervous system relapse in neuroblastoma.",
            "Indication": "Neuroblastoma with central nervous system relapse",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "MIBG scan has poor sensitivity for the detection of CNS NB, regardless of size or location. MRI is recommended for surveillance of high-risk NB for \u22652 years after initial diagnosis or last systemic relapse.",
            "Conclusion": "CNS relapse is isolated to the brain in most patients. MIBG scan has poor sensitivity for the detection of CNS NB. Although CT or MRI are both effective in detecting CNS relapse, the former can miss some lesions. Brain MRI is recommended for surveillance of high-risk NB."
        },
        {
            "Title": "Institutional review of pediatric oncology patients participating in early phase clinical trials.",
            "Indication": "Pediatric oncology",
            "Drug": "Various new therapies or new combinations",
            "PFS": "None",
            "OS": "Median overall survival from first enrollment for 210 patients treated with available vital status was 12.8 months.",
            "ORR": "25.2% of patients had an objective response to trial therapy. Stratified by disease type, objective response was documented for 52.9% of patients with hematologic malignancies, 20.5% of patients with CNS tumors, and 15.8% of patients with solid tumors.",
            "AE": "27 patients (11.0%) required dose modification and 22 patients (9.0%) had DLT.",
            "Other": "The median duration of therapy was 1.5 months.",
            "Conclusion": "We present clinical outcomes for a recent cohort of pediatric patients treated on early phase clinical trials. We identified differential response rates to phase 1 therapy by disease and trial-type in a single-center cohort treated over the past decade. These data are immediately informative to discussions between providers and patients/parents regarding expected phase 1 trial outcomes."
        },
        {
            "Title": "Efficacy and safety of the use of dinutuximab in newly diagnosed or relapse high-risk neuroblastoma: Single arm systematic review and meta-analysis.",
            "Indication": "High-risk neuroblastoma",
            "Drug": "Dinutuximab",
            "PFS": "The 3-year EFS rate was 61% (CI 0.52-0.69; I\u00b2=77%). In the group of newly diagnosed patients, the 3-year EFS rate was 62% (CI 0.58-0.66; I\u00b2=52%).",
            "OS": "The 3-year OS rate was 69% (CI 0.62-0.76; I\u00b2=85%). In the group of newly diagnosed patients, the 3-year OS rate was 74% (CI 0.69-0.79; I\u00b2=71%). Among patients with relapse, there was a 3-year OS rate of 57% (CI 0.50-0.64; I\u00b2=0%).",
            "ORR": "The overall ORR was 47% (CI 0.37-0.57; I\u00b2=69%), of which 38 newly diagnosed patients using Dinutuximab during induction chemotherapy showed an ORR of 86%.",
            "AE": "TEAEs of grade \u22653 were 70% (CI 0.44-0.88; I\u00b2=94%), and in the subgroup without IL-2 use, it was 43% (CI 0.29-0.57; I\u00b2=85%).",
            "Other": "None",
            "Conclusion": "This single-arm meta-analysis suggests that both newly diagnosed or relapsed/refractory high-risk Neuroblastoma patients benefit from Dinutuximab, with a promising impact on 3-year OS and EFS. Dinutuximab appears to be a safer option; however, concomitant use of IL2 is associated with a higher rate of adverse effects."
        },
        {
            "Title": "Diffuse midline gliomas in children: Does changing strategies change first results?",
            "Indication": "Diffuse intrinsic pontine gliomas (DIPG) / diffuse midline glioma (DMG)",
            "Drug": "Temozolomide, Nimotuzumab, Bevacizumab, CCNU, ONC201",
            "PFS": "None",
            "OS": "2 years: 25.6%, 3 years: 15%, 5 years: 11.5% for all patients. Group 1 (RT only): 2 years: 0.79%, 3 years: 0%, 5 years: 0%. Group 2 (RT + TMZ): 2 years: 38.4%, 3 years: 27.9%, 5 years: 16%. Group 3 (RT + TMZ + other agents): 2 years: 17.6%, 3 years: 0%, 5 years: 0%. Group 4 (RT + other agents): 2 years: 10.3%, 3 years: 0.34%, 5 years: 0%.",
            "ORR": "None",
            "AE": "No major side effects due to TMZ or Nimotuzumab",
            "Other": "None",
            "Conclusion": "The overall survival was significantly superior in patients who received RT with concurrent and adjuvant temozolomide and/or adjuvant nimotuzumab containing regimens or other adjuvant systemic treatment in comparison to patients that received RT alone. Oral temozolomide may be easier to use as first-line treatment after radiotherapy especially in resource-limited settings, switching to nimotuzumab containing or other chemotherapy and/or re-radiotherapy at progression."
        },
        {
            "Title": "Phase I dose escalation and pharmacokinetics clinical trial of mitoxantrone hydrochloride liposome in children with relapsed and refractory solid tumors",
            "Indication": "Relapsed and refractory solid tumors",
            "Drug": "Mitoxantrone hydrochloride liposome, Irinotecan, Vincristine",
            "PFS": "None",
            "OS": "None",
            "ORR": "50.0% (95% CI: 33.8%-66.2%)",
            "AE": "Hematological: Anemia (88.2%). Non-hematological: Diarrhea (84.3%), newly found or aggravated TnIUtra, BNP value abnormality (11.8%), lower LV function (14.3%)",
            "Other": "DCR: 90.0% (95% CI: 76.3% -97.2%). 13 patients (25.0%) treated with mitoxantrone hydrochloride liposome combination regimen received the opportunity of surgery.",
            "Conclusion": "The mitoxantrone hydrochloride liposome combination regimen has demonstrated an acceptable toxicity profile and promising clinical efficacy in pediatric patients with solid tumors, especially in sarcomas."
        },
        {
            "Title": "Clinical and molecular characteristics and targeted therapy of pediatric non-infantile fibrosarcoma NTRK rearrangement\u2013related tumors",
            "Indication": "Pediatric non-infantile fibrosarcoma NTRK rearrangement\u2013related tumors",
            "Drug": "TRK inhibitors",
            "PFS": "None",
            "OS": "None",
            "ORR": "70.6% received TRK inhibitors as salvage treatment achieved complete or partial response. Patients without DDR, NOTCH, or TP53 pathway-related gene mutations had a high objective response rate of 82%, 82%, and 83%, respectively.",
            "AE": "None",
            "Other": "NTRK fusion partners were diverse and tumor-specific. Several novel NTRK fusions were identified, including SNIP1-NTRK1, AGAP1-NTRK2, CRCT1-NTRK1, ZBTB7B-NTRK1, and SLC6A15-NTRK3. RTK-RAS, NOTCH, TP53, and WNT pathways were top 4 significantly enriched in tested samples.",
            "Conclusion": "Our findings may have important implications for the diagnosis and treatment of non-IFS NTRK fusion-positive tumors in pediatric patients. We also provide potential therapeutic targets for tumors resistant to TRK inhibitors. Clinical trials with TRK inhibitors, either alone or in combination, are required to establish the optimal treatment regimen and sequence in large-cohort pediatric patients with non-IFS NTRK rearrangement-related tumors."
        },
        {
            "Title": "Evaluating antitumor activity and response determinants to trastuzumab deruxtecan in pediatric solid tumors",
            "Indication": "Pediatric solid tumors",
            "Drug": "Trastuzumab deruxtecan (T-DXd)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Preclinical antitumor activity observed across multiple pediatric solid tumors with no consistent correlation with HER2 expression.",
            "Conclusion": "T-DXd shows significant preclinical antitumor activity across multiple pediatric solid tumors but little correlation with HER2 expression, suggesting a mechanism of action similar to the clinical activity observed in HER2-low breast cancer. Xenograft efficacy studies and preliminary clinical experience with T-DXd in DSRCT patients warrant formal clinical trial investigation in this largely incurable disease."
        },
        {
            "Title": "Clinical characteristics, prognosis, and immunotherapy outcome in pediatric melanoma: Analysis from a large Chinese cohort",
            "Indication": "Pediatric melanoma",
            "Drug": "PD-1 inhibitors",
            "PFS": "None",
            "OS": "Cutaneous melanoma: 65.5% \u00b1 8.9%, Mucosal melanoma: 75.0% \u00b1 21.7%, Meningeal melanoma: 16.7% \u00b1 15.2%",
            "ORR": "20% (for patients treated with PD-1 inhibitors)",
            "AE": "Most common immune-related adverse event was hypothyroidism.",
            "Other": "None",
            "Conclusion": "This is the largest real-world study in pediatric melanoma patients in Asia. The prognosis of mucosal melanoma was favorable, followed by cutaneous melanoma, and meningeal melanoma had the worst prognosis. PD-1 antibody combined with angiogenesis inhibitors may be a promising choice in pediatric melanoma."
        },
        {
            "Title": "N9: Pilot study of novel shortened induction for high-risk neuroblastoma",
            "Indication": "High-risk neuroblastoma",
            "Drug": "Cyclophosphamide, topotecan, vincristine, ifosfamide, carboplatin, etoposide, doxorubicin, naxitamab, irinotecan, temozolomide",
            "PFS": "None",
            "OS": "None",
            "ORR": "Complete response (n=6), partial response (n=5), stable disease (n=4)",
            "AE": "Typical for myelosuppression (including uncomplicated fever/neutropenia), no ototoxicity in 11/11 patients tested",
            "Other": "Peripheral blood stem cells (PBSCs) collected in 12 patients (9-75, median 14 x 10^6/kg CD34(+) cells), 4 x 10^6/kg in 2 patients, pending in 1. All patients had gross total resections of the primary tumor.",
            "Conclusion": "N9 shows promise for reducing chemotherapy and long-term toxicity. Less chemotherapy without compromising survival is a realistic goal by virtue of the advances with anti-GD2 monoclonal antibodies which, in combination with GM-CSF + low-dose chemotherapy, are highly effective against chemo-resistant disease in bone/bone marrow."
        },
        {
            "Title": "Clinical application of individualized tumor-informed circulating tumor DNA for therapeutic response and relapse prediction in patients with neuroblastoma.",
            "Indication": "Neuroblastoma",
            "Drug": "Not specified",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "ctDNA changes matched radiological evaluations in 94% of patients. ctDNA was detected prior to radiological relapse, with a lead time of 6 months in one patient.",
            "Conclusion": "Individualized tumor-informed ctDNA monitoring shows promise for evaluating therapeutic response and forecasting relapse in NB patients. This approach proves more effective than fixed tumor-agnostic panel-based MRD due to high genetic mutation uniqueness."
        },
        {
            "Title": "Overall survival prediction and risk stratification in patients with neuroblastoma through machine learning in the large multi-institutional PRIMAGE cohort.",
            "Indication": "Neuroblastoma",
            "Drug": "Not applicable",
            "PFS": "None",
            "OS": "C-index of 0.7886 \u00b1 0.049 in the test partitions, with a random survival forest (RSF) exhibiting the best performance. In the validation cohort, a C-index of 0.934 was achieved.",
            "ORR": "None",
            "AE": "None",
            "Other": "The model stratified NB patients into low-, intermediate-, and high-risk categories and suggests that radiomics features potentially could improve current risk stratification systems.",
            "Conclusion": "The RSF predictive model exhibited high performance, emphasizing the significant contribution of radiomics features and alignment with established clinical and molecular variables. Additional external validation is warranted as this may present new evidence for enhancing patient care and clinical decision-making for NB patients."
        },
        {
            "Title": "PP2A activation as a new therapeutic strategy in neuroblastoma: Making sense out of senescence.",
            "Indication": "Neuroblastoma",
            "Drug": "Topotecan and ATUX-1215",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "PP2A activation reversed the effects of topotecan TIS and acted in a synergistic fashion to decrease NBL cell viability.",
            "Conclusion": "Activation of PP2A with a novel small molecule ameliorates topotecan-induced TIS in NBL cells irrespective of MYCN status. These results are promising, providing a potential novel therapeutic adjunct to children who are most in need of innovative interventions."
        },
        {
            "Title": "Real-world experience of larotrectinib in children, adolescents and young adults with TRK fusion solid tumors in France.",
            "Indication": "TRK fusion solid tumors",
            "Drug": "Larotrectinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "Partial response in 13 out of 19 patients with evaluable disease (10/12 patients with soft tissue sarcomas, 2/6 patients with CNS tumors).",
            "AE": "Adverse drug reactions were reported in 2/21 patients (9%), including a grade 2 weight gain and a grade 3 neutrophil count decrease (1 patient each).",
            "Other": "Median treatment duration was 219 days (range: 10-1368, 5 patients still on therapy). Seven patients with soft tissue sarcomas stopped larotrectinib, after a median duration of 242 days (range: 122-1190), three of them after tumor surgery; none presented with tumor recurrence (follow-up: 350 days; range 40-636).",
            "Conclusion": "Our real-world data confirm the favorable safety profile of larotrectinib with rapid and durable tumor-agnostic efficacy."
        },
        {
            "Title": "Theroleofimmunecheckpointblockadeinchildren,adolescents,andyoungadults: A systematic review and meta-analysis.",
            "Indication": "Various cancers in children, adolescents, and young adults",
            "Drug": "Immune checkpoint blockade (ICB) therapies",
            "PFS": "Median PFS ranged from 1.2 to 6.2 months",
            "OS": "Median OS ranged from 4.4 to 23.7 months",
            "ORR": "13% (95% CI 5-27)",
            "AE": "All-grade TRAE rate of 66% (95% CI 60-71), Grade 3/4 TRAE rate of 19% (95% CI 13-27)",
            "Other": "Fatigue, anemia, and nausea were the most frequently reported TRAE. Subgroup analysis showed PD-1 inhibitors and lymphoma had the highest ORR, with 25% (95% CI 8-56) and 59% (95% CI 23-87), respectively.",
            "Conclusion": "ICB is well tolerated in CAYA patients with different cancer types. Continued investigation of ICB in CAYA patients most likely to benefit from ICB therapy is supported."
        },
        {
            "Title": "Feasibilityoftumor-informedcirculatingtumorDNA(ctDNA)formolecularresidualdisease(MRD)assessmentinpediatricpatientswithsolidtumors.",
            "Indication": "Pediatric solid tumors",
            "Drug": "Signatera ctDNA assay",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Of 30 patients enrolled, a tumor-informed ctDNA assay was successfully created for 27 (90%). Of 25 patients who had radiographic evidence of disease at any time of monitoring, 23 (92%) had simultaneous detectable ctDNA. Seven patients had disease recurrence, all of whom had detectable ctDNA at or preceding the time of recurrence.",
            "Conclusion": "Signatera tumor-informed ctDNA assay is a feasible noninvasive tool which may be utilized in parallel with standard of care imaging to monitor for disease recurrence in pediatric patients with extracranial solid tumors."
        },
        {
            "Title": "Intravitrealchemotherapy(IVC)inretinoblastoma:Astudyofclinicalvariablesandocularpreservationoutcomes.",
            "Indication": "Retinoblastoma",
            "Drug": "Intravitreal chemotherapy (IVC) with melphalan and topotecan",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "No grade 3 or 4 adverse events occurred.",
            "Other": "Control of vitreous seeds and vision preservation were achieved in 57/61 eyes (93%). Seven of 61 (11.5%) were enucleated. Three of 61 eyes (4.9%) received radiotherapy.",
            "Conclusion": "IVC emerges as a crucial component in the management of retinoblastoma, offering a viable alternative to external radiotherapy. The analysis highlights the importance of this approach in preserving ocular integrity, thereby avoiding enucleation and reducing the risk of second neoplasms, particularly in germline retinoblastoma patients."
        },
        {
            "Title": "Survival outcomes in patients with high-risk neuroblastoma (HRNB) in remission after relapsed or refractory treatment receiving eflornithine (DFMO) maintenance.",
            "Indication": "High-risk neuroblastoma (HRNB)",
            "Drug": "Eflornithine (DFMO)",
            "PFS": "None",
            "OS": "Four-year OS was 62% (95% CI 44%, 76%)",
            "ORR": "None",
            "AE": "None",
            "Other": "Two-year EFS was 46% (95% CI 29%, 61%). Four-year EFS was 46% (95% CI 29%, 61%). For patients enrolled with refractory disease only (n=7), the four-year EFS was 85.7%. In the 28 relapsed patients, the four-year EFS was 35.7%, with single relapse patients (n=23) having an EFS rate of 39.1% and multiple relapsed patients (n=5) 20.0%.",
            "Conclusion": "Patients in remission after chemotherapy for relapsed or refractory HRNB and treated with DFMO had improved EFS compared to the best historical estimate available at the time of the study. Although the number of patients is too small to support clear conclusions, the subgroup of refractory patients had outcomes that trended better than the relapsed subgroup with an EFS rate that was unexpectedly similar to prior reports of DFMO-treated S1 patients in remission after standard upfront therapy."
        },
        {
            "Title": "Effect of oncolytic herpes simplex virus on fusion-positive rhabdomyosarcoma radiotherapy",
            "Indication": "Fusion-positive rhabdomyosarcoma (FP-RMS)",
            "Drug": "Oncolytic herpes simplex virus (M002)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "M002 significantly reduced RMS PDX cell viability in a dose-dependent manner. M002 showed slightly higher cytotoxicity than its parent construct R3659. When combined with low-dose irradiation, RMS PDX cell viability was significantly decreased compared to cells treated with either M002 or irradiation alone (26% decrease at 10 PFU/cell).",
            "Conclusion": "M002 exhibits cytotoxicity in a PDX model of FP-RMS and increases the cytotoxicity of ionizing radiation compared to individual treatments, suggesting that oHSV sensitized the cells to radiotherapy. Future studies in vivo may provide more pre-clinical evidence for the radio-sensitizing and therapeutic potential of M002 in FP-RMS."
        },
        {
            "Title": "The dose-response relationship between cumulative alkylating agent exposure and sperm concentration in adult survivors of childhood cancer: A report from the St. Jude Lifetime (SJLIFE) cohort study.",
            "Indication": "Impaired sperm production in adult survivors of childhood cancer",
            "Drug": "Alkylating agents (AA)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "22.5% had azoospermia, 26.6% had oligospermia, and 50.9% had normospermia. Odds of azoospermia increased by 1.14 per 1,000 mg/m2 increase in CED. Odds of azoospermia and oligospermia increased by 1.22 per 1,000 mg/m2 increase in CED. CED=7,000 mg/m2 is the optimal cutoff for differentiating risk for oligo- or azoospermia from normospermia.",
            "Conclusion": "Nearly half of adult childhood cancer survivors who received alkylating agents without radiation therapy experience impaired sperm production. Recovery of spermatogenesis is unlikely among those who present azoospermic."
        },
        {
            "Title": "AMH levels after initial cycles of chemotherapy in female adolescents with Ewing sarcoma and implications for preservation of fertility.",
            "Indication": "Ovarian insufficiency in female adolescents with Ewing sarcoma",
            "Drug": "VDC-IE chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Baseline mean AMH: 4.16 ng/mL. Mean AMH level after first cycle: 0.43 ng/mL. Mean AMH level before local therapy: 0.17 ng/mL. Mean AMH level at the end of chemotherapy: 0.07 ng/mL.",
            "Conclusion": "Female adolescents with Ewing sarcoma treated by VDC-IE regimen showed a rapid reduction of AMH after initial cycles. Methods for preservation of fertility should be implemented as soon as possible but not at the same time of local therapy."
        },
        {
            "Title": "The effects of oral nutritional supplements on functional outcomes and body composition in patients with solid cancer undergoing systemic chemotherapy: A pilot study",
            "Indication": "Cancer cachexia in patients with solid cancer undergoing chemotherapy",
            "Drug": "Oral nutritional supplements (ONS)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Statistically significant improvement in hand-grip strength (p=0.002) and 4-meter walk test (p=0.021) after 8 weeks. No significant differences in body weight (p=0.43), fat mass (p=0.62), fat-free mass (p=0.27), and fat-free mass index (p=0.38). No significant differences in HRQoL survey results (p=0.85). No differences in microbiome composition at phylum and genus levels, a-diversity, and b-diversity between baseline and after 8 weeks.",
            "Conclusion": "While ONS have shown potential in enhancing physical functions of patients with cancer cachexia undergoing chemotherapy, this did not correlate with the anticipated microbiome changes or improvements in body composition. The absence of expected microbiome alterations, despite enriched protein and dietary fiber, underscores the complexity of cachexia and indicates that its management may extend beyond nutritional supplementation alone. Further research should explore the broader context of cachexia, including nutrition, metabolic processes, and the impact of chemotherapy."
        },
        {
            "Title": "Cost-effectiveness of single-day intravenous fosaprepitant versus three-day oral aprepitant anti-emetic regimen in pediatric patients receiving highly-emetogenic chemotherapy",
            "Indication": "Prevention of chemotherapy-induced nausea and vomiting in pediatric patients receiving highly-emetogenic chemotherapy",
            "Drug": "Fosaprepitant vs. Aprepitant",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Total QALY of 0.0116 for fosaprepitant arm compared to 0.0118 in the aprepitant arm. Incremental cost of $14.21 in India and a cost reduction of $193.81 in the US for fosaprepitant. ICUR was -$59,974.86/QALY in India and $817,737.23/QALY in the US. Fosaprepitant was not cost-effective versus aprepitant in India but was cost-saving and cost-effective in the US.",
            "Conclusion": "Fosaprepitant was not found to be cost-effective versus aprepitant in India, comparatively it was cost-saving and cost-effective in the US. These findings highlight the necessity of region-specific considerations when evaluating the cost-effectiveness of anti-emetic regimens."
        },
        {
            "Title": "TAZNI: A phase I/II combination trial of tazemetostat with nivolumab and ipilimumab for children with INI1-negative or SMARCA4-deficient tumors.",
            "Indication": "INI1-negative or SMARCA4-deficient tumors",
            "Drug": "Tazemetostat, Nivolumab, Ipilimumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "17% overall objective response rate, with 19% response in ATRT patients and higher response rates in patients with chordoma and ES",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The study is ongoing in the United States. Clinical trial information: NCT05407441."
        },
        {
            "Title": "Impact of molecular therapy on radioactive iodine uptake in patients with oncogene-driven metastatic differentiated thyroid cancer.",
            "Indication": "Oncogene-driven metastatic differentiated thyroid cancer",
            "Drug": "Targeted kinase inhibitors (Larotrectinib, entrectinib, repotrectinib, selpercatinib, pralsetinib, crizotinib, lorlatinib, alectinib, dabrafenib with or without trametinib)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: Change in RAI avidity between baseline and 4 weeks of treatment. Secondary endpoints: Progression-free survival and the proportion of patients who achieve a complete response to the combination of targeted therapy and RAI.",
            "Conclusion": "Five of 32 patients have been enrolled. Clinical trial information: NCT05024929."
        },
        {
            "Title": "A multicohort platform trial to improve drug access for pediatric cancer patients in Japan: The PARTNER trial (NCCH2220).",
            "Indication": "Pediatric cancer with no standard therapy, refractory, or intolerance to standard therapy",
            "Drug": "Imatinib, pazopanib, ruxolitinib, trametinib, atezolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "The incidence of dose-limiting toxicity in each cohort",
            "Other": "Secondary endpoints: The incidence of adverse events, the overall response rate, and, if necessary, the pharmacokinetics in each cohort.",
            "Conclusion": "The study had five treatment cohorts as of January 2024. Clinical trial information: jRCTs031230544."
        },
        {
            "Title": "Effect of polygenic risk score for clinically significant prostate cancer in a screening program: The BARCODE1 study results.",
            "Indication": "Prostate Cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "PPV of biopsy for PSA > 3.0 ug/L was 49.6%. PPV of MRI (presence of PI-RADS 3-5 lesion) 60.4%. PPV of PRS alone 40%.",
            "Conclusion": "A population PCa screening program using PRS risk-stratification enriches for clinically significant PCa requiring treatment. It detects a high proportion of clinically significant disease compared with PSA or MRI based screening programs and MRI misses a significant proportion (17-67%) of cancers found on biopsy. This is the first study to assess if this approach will be useful in population screening programs."
        },
        {
            "Title": "Evaluation of a polygenic risk score as a predictor of early onset triple-negative breast cancer in Black women.",
            "Indication": "Triple-Negative Breast Cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "MA-PRS significantly improved TNBC risk prediction over clinical factors in the full cohort and in the subpopulation of women under 50. Women in the top 5% of the MA-PRS distribution were at roughly 2-fold increased risk of TNBC.",
            "Conclusion": "MA-PRS substantially improved upon clinical factors for the prediction of TNBC and early-onset TNBC in a large cohort of Black women. MA-PRS appears to be equally as important for managing risk of early-onset TNBC as mammographic density for overall BC. Incorporation of MA-PRS into BC risk assessment has the potential to improve TNBC survival through more accurate identification of women at high risk."
        },
        {
            "Title": "Breast cancer polygenic risk score and patient survival outcomes in the Pathways study.",
            "Indication": "Breast Cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "All-cause death: HR 1.28 (0.96, 1.31), Breast cancer-specific death: HR 1.34 (0.99, 1.73)",
            "ORR": "None",
            "AE": "None",
            "Other": "Patients with medium (T2) and high (T3) PGS313 had higher risk of recurrence, total breast cancer event, and invasive disease.",
            "Conclusion": "Our study reveals that breast cancer patients with high PGS313 had higher risk of recurrence and death. These findings highlight a potential prognostic role of breast cancer PGS, warranting further exploration."
        },
        {
            "Title": "Clinical behavior of breast cancer in young BRCA carriers and prognostic impact of the timing of genetic testing: Results from an international cohort study.",
            "Indication": "Breast Cancer in BRCA Carriers",
            "Drug": "None",
            "PFS": "8-year DFS: 73% (tested before diagnosis) vs 70% (tested at diagnosis), HR 1.25 (95% CI 0.99-1.58)",
            "OS": "8-year OS: 91% (tested before diagnosis) vs 87% (tested at diagnosis), HR 1.65 (95% CI 1.08-2.52)",
            "ORR": "None",
            "AE": "None",
            "Other": "BRCA1 carriers had worse DFS and OS in the first 5 years due to a peak in DFS events in the first 2 years while BRCA2 carriers had a constant risk of recurrence over time and worse OS after year 9.",
            "Conclusion": "In this global study of young BRCA carriers, different patient, tumor, and treatment characteristics, as well as pattern and risk of DFS/OS events over time were observed between BRCA1 and BRCA2. Identification of carrying a BRCA PV in healthy individuals led to earlier BC diagnosis and improved OS. These findings highlight the importance of conducting efforts to identify women at risk for carrying a BRCA1 or BRCA2 PV to offer them genetic counseling and testing to inform not only prevention, but tailored earlier detection, treatment, and follow-up strategies once diagnosed."
        },
        {
            "Title": "Menopausal hormone therapy and ovarian and endometrial cancers: Long-term follow-up of the Women\u2019s Health Initiative randomized trials.",
            "Indication": "Ovarian and Endometrial Cancers",
            "Drug": "Conjugated Equine Estrogen (CEE) plus Medroxyprogesterone Acetate (MPA) and CEE-alone",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Ovarian cancer incidence: CEE-alone vs placebo (HR 2.04; 95% CI 1.14-3.65; P=0.01); Ovarian cancer mortality: CEE-alone vs placebo (HR 2.79; 95% CI 1.30-5.99; P=0.006); Endometrial cancer incidence: CEE plus MPA vs placebo (HR 0.72; 95% CI 0.56-0.92; P=0.01)",
            "Conclusion": "CEE-alone significantly increased ovarian cancer incidence and mortality, while CEE plus MPA did not increase ovarian cancer incidence or mortality but reduced endometrial cancer incidence."
        },
        {
            "Title": "Comparative risk of obesity-related cancer with glucagon-like protein-1 receptor agonists vs. bariatric surgery in patients with BMI \u2265 35.",
            "Indication": "Obesity-related Cancers",
            "Drug": "Glucagon-like peptide-1 receptor agonist (GLP-1 RA)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Obesity-related cancer incidence: GLP-1 RA vs bariatric surgery (HR 0.99; 95% CI 0.87-1.13); GLP-1 RA vs no intervention (HR 0.61; 95% CI 0.46-0.81); Bariatric surgery vs no intervention (HR 0.78; 95% CI 0.67-0.91); All-cause mortality: GLP-1 RA vs control (HR 0.5; 95% CI 0.40-0.62); GLP-1 RA vs bariatric surgery (HR 0.859; 95% CI 0.77-0.96)",
            "Conclusion": "GLP-1 RA was associated with a lower risk of obesity-related cancer comparable to bariatric surgery in patients with BMI \u2265 35. GLP-1 RA has superior all-cause mortality benefits despite inferior weight loss compared to bariatric surgery."
        },
        {
            "Title": "Effect of low-dose tamoxifen on IGF-I/IGFBP-3 and estradiol/SHBG ratio and their prognostic/predictive effect in DCIS/LCIS/ADH",
            "Indication": "DCIS/LCIS/ADH",
            "Drug": "Low-dose tamoxifen (Baby Tam)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "IGF-I/IGFBP-3 decreased by 0.039 (95% CI, 0.027-0.052), relative difference (rd), 22% (15-29) (p < 0.001); E2/SHBG increased by 2.967 (1.767-4.166), rd, 79% (47-111) in pre-menopausal women on BT (p-interaction = 0.001).",
            "Conclusion": "BT significantly lowers IGF-I/IGFBP-3 ratio, increases E2/SHBG in premenopausal women to a lesser extent than 20 mg/d and is effective in most subgroups regardless of baseline biomarker levels. Lower baseline IGF-I/IGFBP-3 levels were predictive of BT efficacy at 5 and 10 years, while higher levels at 10 years were associated with a lower benefit of BT. E2/SHBG had a tendency to be prognostic and predictive of BT efficacy at 5 years, but softened down at 10 years. These biomarkers may help differentiate which women benefit most from BT. The role of IGF-I change as a surrogate biomarker of BT effect requires further studies."
        },
        {
            "Title": "Effect of cancer screening with multi-cancer early detection testing and whole body MRI on cancer worry in a high-risk population.",
            "Indication": "High-risk population for cancer",
            "Drug": "Multi-cancer Early Detection tests (MCED) and whole-body MRI (WBM)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Cancer worry decreased at 6 months compared with baseline (Composite CWS scores: 12.7 vs 13.7, p=0.003). 4% of patients had cancer detected based on WBM findings.",
            "Conclusion": "MCED and WBM testing is feasible and was associated with decreased cancer worry at 6 months. In this high-risk cohort, cancer was detected in 4% of patients. Further evaluation of cancer screening in high-risk populations using WBM and MCED testing is warranted."
        },
        {
            "Title": "AI-based approach to enable proactive identification of early lung cancer: A retrospective population health study and economic model.",
            "Indication": "Lung cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The PM achieved a peak AUROC of 0.75, exceeding the performance of currently used risk prediction models. A 9% left shift equated to 4.5 years mean increase in survival. The DMCM was associated with a net healthcare cost benefit of \u00a36k per patient diagnosed with lung cancer, improved quality-adjusted life years of \u00a321M for the year 1 cohort over 10 years, and increased economic productivity of \u00a32.4M.",
            "Conclusion": "The study demonstrates the practical utility of AI-supported early lung cancer detection at population scale. Real-world evidence is needed to prospectively validate the model as cost-effective, scalable, and efficient. Future work will seek to improve the model\u2019s performance against a broader at-risk patient population and explore its applicability to other late-presenting tumors."
        },
        {
            "Title": "Evaluation of cost-effective multiple cancer early detection with extremely low coverage whole genome sequencing from plasma.",
            "Indication": "Multiple cancers (colorectal, pancreas, liver, lung, esophagus, and gastric cancer)",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "HIFI-PROF exhibited an excellent performance in the early detection of multiple cancer types, achieving a sensitivity of 87.58% at a specificity of 99.09% and identifying the tissue of origin with an accuracy of 81.99%. The sensitivity rates for detecting cancer at different stages were 84.62% for stage I, 85.59% for stage II, 89.61% for stage III, and 92.86% for stage IV.",
            "Conclusion": "These results highlight the high sensitivity and specificity of HIFI-PROF in identifying cancer cases while maintaining a low rate of false-positive results. The use of HIFI-PROF offers a cost-effective MCED test with excellent performance. Such advancements will contribute to improved strategies for multi-cancer early detection, leading to more effective clinical interventions and better outcomes."
        },
        {
            "Title": "Significant results from a major multi-year, multi-cancer follow-up study with an enrollment of over 19,000 individuals.",
            "Indication": "Multiple cancers",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "256 individuals have been confirmed with cancer, 1,607 confirmed with pre-cancer diseases and benign tumors, and 1,378 confirmed with diseases. For the confirmed cancer group, 91.4% is in the high-risk group, 7.5% in the medium-risk group, and only 1.1% in the low-risk group. The study showed that CDA technology is very effective in screening out multiple cancer types, including some cancer types which do not yet have other effective IVD tests such as thyroid cancer and esophageal cancer.",
            "Conclusion": "A large scale, multi-year, multi-cancer follow-up study has been carried out. Results showed that CDA technology is effective for multi-cancer screening in a general population."
        },
        {
            "Title": "Analytical and clinical validation of a circulating tumor DNA\u2013based assay for multi-cancer early detection",
            "Indication": "Multi-cancer early detection",
            "Drug": "SPOT-MAS (Screening for the Presence of Tumor by DNA Methylation and Size)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Positive Predictive Value (PPV): 58.14% (95% CI: 43.33-71.62), Accuracy: 84.00% (95% CI: 65.35-93.60), Negative Predictive Value (NPV): 99.92% (95% CI: 99.83-99.96)",
            "Conclusion": "To our knowledge, this is the first and largest prospective validation study in Asia supporting the utility of SPOT-MAS as a multi-cancer blood test for early detection in a low- and middle-income country, where a nationwide cancer screening program is urgently needed but currently not available."
        },
        {
            "Title": "Clinical and economic benefit of genomic testing strategies to guide the treatment of patients with HR+/HER2- breast cancer in the US.",
            "Indication": "HR+/HER2- early-stage breast cancer",
            "Drug": "21-gene assay and germline genetic testing (GGT)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Cost-effectiveness analysis showed a QALY improvement of 0.286 and cost savings of $16,463 in the N0 population, and a 0.099 QALY improvement and $299 cost savings in the N1 population.",
            "Conclusion": "Using a testing strategy including both the 21-gene assay and GGT enables optimization of treatment decisions in early HR+/HER2- breast cancer, improving patient outcomes at reduced costs."
        },
        {
            "Title": "Association between CH mutation in TET2 and solid cancer in elderly Asian women.",
            "Indication": "Solid cancers (breast, lung, colon) in elderly Asian women",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "CH was found in 29.0% of cases and 37.9% of controls. TET2 mutations at 0.3-0.5% VAF thresholds were more prevalent in cancer cases.",
            "Conclusion": "CH determined through multi-genes was not associated with solid cancers, but TET2 mutations at 0.3-0.5% VAF thresholds were more prevalent in cancer cases, implying a potential association between TET2 mutation and solid cancer."
        },
        {
            "Title": "Correlation of patient HLA class I genotypes with severe immune-related adverse events: A retrospective study in Chinese population.",
            "Indication": "Metastatic cancer treated with immune checkpoint inhibitors (ICIs)",
            "Drug": "Immune checkpoint inhibitors (ICIs)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "HLA-B*15:27 was significantly associated with severe irAEs overall (OR=9.1, P=0.008). HLA-B*13:02 was significantly associated with serious myocarditis (OR=3.4, P=0.01). HLA-A*02:03 haplotypes were significantly associated with severe adrenal insufficiency (OR=10.0, P<0.0001).",
            "Other": "None",
            "Conclusion": "Specific HLA alleles were associated with severe irAEs. Consideration of pre-prescription HLA typing and vigilance for serious reactions to ICIs are warranted."
        },
        {
            "Title": "Association of germline HSD3B1(c.1100) genotype with tumoral and clinical outcomes in breast and endometrial cancers.",
            "Indication": "Breast and endometrial cancers",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "In pre-menopausal HER2+ breast cancers, the restrictive genotype was associated with worse survival (HR=1.40, P=0.027). Post-menopausal basal breast cancers with the permissive genotype had higher PD-L1 protein expression (50% vs 13%) and fewer PI-3-kinase alterations. Post-menopausal clear cell endometrial cancers with the restrictive genotype exhibited worse survival (HR=1.60, P=0.03) and were more likely to be TMB-high or MSI-high.",
            "Conclusion": "Breast and endometrial cancers can be classified as adrenal-permissive or -restrictive based on HSD3B1 c.1100 genotype. This classification distinguishes clinical outcomes and tumoral features, particularly when stratifying by menopausal status."
        },
        {
            "Title": "Impact of pathogenic germline variants in HRR genes on gastroesophageal cancer in a large real-world Chinese population: A comprehensive study.",
            "Indication": "Gastroesophageal cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The study found that the proportion of patients carrying pathogenic/likely pathogenic (P/LP) germline variants was 5.3% in gastric cancer (GC), 5.6% in gastroesophageal junction cancer (GEJC), and 5.0% in esophageal cancer (EC). HRR genes exhibited the highest rates of P/LP germline mutations in GEC (94, 3.7%), with the majority occurring in non-BRCA HRR genes (77, 3.0%). The top five genes with the highest mutation rates were ATM, PALB2, FANCA, CHEK2, and RAD54L.",
            "Conclusion": "The presence of P/LP variants in HRR genes in GEC is linked to distinct disease characteristics and progression, underscoring their importance in guiding personalized treatment approaches and risk evaluation."
        },
        {
            "Title": "Germline and somatic MUTYH mutations: Clinicopathologic and mutational analysis in a pan-cancer cohort.",
            "Indication": "Various solid tumors",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The study identified 587 patients with solid tumors and presence of MUTYH germline pathogenic variants (gPVs). Among these, 15 patients had \u22652 MUTYH gPVs, including 4 patients with homozygous MUTYH gPVs and 11 patients with compound heterozygous MUTYH gPVs. Concurrent gPVs in other genes were observed in 12% of patients, with high/moderate-penetrance gPVs in 4%. Among patients with monoallelic MUTYH gPVs, 24% of evaluable tumors exhibited biallelic MUTYH inactivation due to loss of heterozygosity (LOH).",
            "Conclusion": "This study represents the largest cohort of patients with MUTYH gPVs and FACETS analysis on concurrent tumor samples, confirming a high prevalence of LOH in patients with monoallelic MUTYH gPVs and enrichment in KRAS G12C in patients with MUTYH gPVs and biallelic MUTYH inactivation."
        },
        {
            "Title": "Implementing DPYD/UGT1A1 testing to prevent treatment related adverse events (TRAE) in patients with gastrointestinal (GI) cancer: Results of a pragmatic implementation trial.",
            "Indication": "Gastrointestinal (GI) cancer",
            "Drug": "Fluoropyrimidines (FP), 5-FU, capecitabine, irinotecan",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "28% DPYD carriers vs. 33% wildtype carriers had a severe TRAE; 50% UGT1A1 PMs vs. 36% UGT1A1 wildtype carriers experienced a severe TRAE",
            "Other": "Feasibility of integrating DPYD/UGT1A1 testing into clinical care; genotype concordant dose modifications",
            "Conclusion": "DPYD/UGT1A1 testing is feasible with oncology providers following the genotype guided dose reductions when results were available prior to cycle 1. Pretreatment DPYD/UGT1A1 testing in conjunction with preemptive dose reductions resulted in a similar proportion of severe TRAE as compared with wild-type patients. Further workflow modifications are required to decrease test turnaround time to ensure results are available prior to chemotherapy initiation."
        },
        {
            "Title": "Association of germline polygenic risk for thyroid autoimmunity with overall survival in the I-SPY2 Trial",
            "Indication": "Early-stage breast cancer",
            "Drug": "Neoadjuvant chemotherapy",
            "PFS": "None",
            "OS": "HR=0.78, 95% CI 0.66-0.92, p=0.004",
            "ORR": "None",
            "AE": "None",
            "Other": "33% (n=447) had pCR, 85% (n=1,157) were alive at the last follow-up",
            "Conclusion": "Germline genetic variation in thyroid autoimmunity proclivity is associated with improved overall survival in our cohort of women with early-stage breast cancer receiving neoadjuvant chemotherapy. Our findings suggest that the genetic risk for autoimmunity may be important for long-term survival. We will expand this analysis to include the entire ISPY2 population when all patients have completed genotyping. We plan to further characterize the role of germline autoimmunity profiles and survival."
        },
        {
            "Title": "Interactive assistance via eHealth for small-and medium-sized enterprises\u2019 employer and healthcare manager teams on tobacco control (eSMART-TC): A cluster randomized hybrid type II trial (N-EQUITY2101/J-SUPPORT2102).",
            "Indication": "Tobacco smoking cessation",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Validated 7-day point-prevalence abstinence rate at 6 months was significantly higher in the intervention group (11.2%) compared to the control group (4.6%). Adoption score of the two recommended measures was also significantly higher in the intervention group (1.5) compared to the control group (1.1).",
            "Conclusion": "Interactive support for SME managers to implement tobacco control measures can accelerate the implementation of evidence-based smoking cessation measures and significantly increase 6-month abstinence rates."
        },
        {
            "Title": "Glucagon-like peptide-1 (GLP-1) agonist use and weight change among patients with breast cancer.",
            "Indication": "Breast cancer",
            "Drug": "GLP-1 agonists",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Mean relative weight change at 12 months after GLP-1 agonist initiation was -5%. Mean weight change from GLP-1 agonist initiation was -2.8 kg at 6 months, -4.2 kg at 12 months, and -6.2 kg post-GLP-1 agonist treatment.",
            "Conclusion": "The use of GLP-1 agonists in this cohort of patients treated for breast cancer was efficacious and associated with modest (5%) weight loss. Prospective trials are warranted."
        },
        {
            "Title": "HOPE: Harnessing olaparib, palbociclib, and endocrine therapy for BRCA1/2-associated HR+, HER2- metastatic breast cancer.",
            "Indication": "BRCA1/2-associated HR+, HER2- metastatic breast cancer",
            "Drug": "Olaparib, Fulvestrant, Palbociclib",
            "PFS": "None",
            "OS": "None",
            "ORR": "CR/PR/SD/PD in 1/1/5/1 patients",
            "AE": "Grade 3 neutropenia, leukopenia, thrombocytopenia, anemia, fatigue, nausea",
            "Other": "MTD of Olaparib, Fulvestrant, and Palbociclib (DL-1) was reached and demonstrated antitumor activity. Hematologic toxicity at this dose complicated drug delivery and required close monitoring.",
            "Conclusion": "MTD of Olaparib, Fulvestrant, and Palbociclib (DL-1) was reached and demonstrated antitumor activity. Although no AEs meeting protocol-specified DLT criteria were observed at MTD, hematologic toxicity at this dose complicated drug delivery and required close monitoring during and after the DLT period. Combinations utilizing PARP1 selective inhibitors, lower doses of therapy, or sequential rather than combination therapy may improve feasibility."
        },
        {
            "Title": "Blood-based colorectal cancer (CRC) screening implementation into clinical practice in the outpatient clinical setting in the Appalachian highlands.",
            "Indication": "Colorectal Cancer (CRC)",
            "Drug": "Blood-based test (BBT) detecting circulating tumor DNA (ctDNA)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Sensitivity: 83%, Specificity: 90%",
            "Conclusion": "The study aims to explore the implementation of a BBT and its impact on patient acceptability and adherence to CRC screening. The primary endpoint involves calculating the total number of consented patients who opted for and completed either BBT or SOC during the interventional period compared to the total number of patients who opted for SOC during the control period. Final data analysis is expected by July 2024."
        },
        {
            "Title": "Efficacy of eSyM: Acute care utilization among patients with cancer who do versus do not report ePROs",
            "Indication": "Cancer (thoracic, gastrointestinal, or gynecologic)",
            "Drug": "eSyM (electronic patient-reported outcome-based symptom management program)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "ED visits at 30 days: 5.3% (reporters) vs. 7.1% (non-reporters); ED visits at 90 days: 10.0% (reporters) vs. 12.9% (non-reporters); INPT events at 30 days: 6.7% (reporters) vs. 11.3% (non-reporters); INPT events at 90 days: 14.0% (reporters) vs. 19.5% (non-reporters)",
            "Conclusion": "Completing at least one symptom questionnaire was associated with lower odds of experiencing an ED or INPT encounter among CHEM and SURG patients across six diverse health systems. eSyM engagement reduced acute care utilization."
        },
        {
            "Title": "An AI-assisted navigation approach for patients with radiographic suspicion of new pancreas cancer",
            "Indication": "Pancreas cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Mean days to biopsy: 7 days; Mean days to outpatient oncology visit: 15 days; Mean days to treatment initiation: 34 days; Monthly average of patients approached for biospecimen studies: 4; Monthly average of patients consented: 4; Monthly average of patients enrolled: 3.2",
            "Conclusion": "An AI-guided navigation workflow identified patients earlier in the diagnostic timeline; reduced time to biopsy, follow-up, and treatment initiation; increased patient referrals to PMDC; and tripled participation in biospecimen studies at our institution."
        },
        {
            "Title": "Impact of trial eligibility criteria on enrollment to KRAS G12C inhibitor trials in patients with non-small cell lung cancer.",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "KRAS G12C inhibitors (sotorasib, adagrasib, LY3537982, divarasib, JDQ443, RMC-6291)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Eligibility criteria for 14 trials were characterized. EC were similar across trials regardless of agent or phase and did not substantially change over time. Of 1172 patients with NSCLC, 185 pts with G12C mutations (15%), including 69 pts with advanced or metastatic disease. 19% (13/69) would have been eligible for any G12C trial, 15% (10/69) were borderline, and 67% (46/69) were ineligible. The most common reasons for ineligibility were poor performance status (54% [30/56]), excluded comorbidities (25% [14/56]), and renal dysfunction (27% [15/56]). Among patients who received therapy after FDA accelerated approval (N=22), only 23% (5) would have been eligible for the related Phase III trial.",
            "Conclusion": "Most patients with KRAS G12C mutations would not have been eligible for relevant trials, including 75% of patients who received KRAS G12C inhibitors off-trial after accelerated FDA approval. EC did not expand after early phase trials demonstrated evidence of safety. These data should guide sponsor and FDA considerations in the development of trial protocols for targeted therapies, as fewer barriers to trial participation would enable trials to be completed more quickly and would improve the generalizability of trial results."
        },
        {
            "Title": "The toxicity swimmer plot: A novel approach for evaluating patient-level toxicity following immune checkpoint inhibitor (ICI) therapy on the Checkmate 214 trial.",
            "Indication": "Advanced renal cell carcinoma (aRCC)",
            "Drug": "Nivolumab plus ipilimumab, sunitinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "42-month mean TFS was 7.8 months (with grade 2+ TRAEs: 3.2 months) for patients treated with nivolumab plus ipilimumab and 3.3 months (1.6 months) for patients treated with sunitinib. TFS with grade 2+ TRAEs was experienced by 32% and 25% of patients and comprised of 13% and 9% of patients for whom it was the entire TFS period. TFS with grade 3+ TRAEs was experienced by 15% and 3% of patients, respectively.",
            "Other": "For most patients having TFS with TRAEs, the TRAEs persisted from the protocol therapy period, but 7% and 1% of patients in the respective treatment groups had new-onset grade 3+ TRAE during TFS.",
            "Conclusion": "We describe a novel extension of swimmer plots for presenting patient-level information and illustrating patterns in toxicity duration relative to TFS by incorporating individual toxicity data into a swimmer plot of partitioned survival. This approach allows for a more comprehensive understanding of toxicity trends and risk of prolonged toxicity after treatment is discontinued."
        },
        {
            "Title": "Patient-reported fatigue and risk of treatment-related adverse events (AEs) in patients receiving systemic therapy in cancer clinical trials.",
            "Indication": "Various cancers (prostate, lung, colorectal, lymphoma, breast, bladder, melanoma, ovarian, pancreas)",
            "Drug": "Systemic therapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Patients with some or greater fatigue were nearly twice as likely to have severe or worse toxicity (OR=1.92, 95% CI, 1.53-2.40, p<.001) or life-threatening toxicity (OR=1.93, 95% CI, 1.44-2.59, p<.001) and nearly threefold more likely to have fatal toxicity (OR=2.76, 95% CI, 1.37-5.53, p=.004). Similar patterns were seen at a threshold of 'quite a lot'. A dose-response pattern was evident; patients reporting quite a lot/very much compared to no fatigue were 5 times more likely to experience fatal toxicity (OR=5.63, p=.002).",
            "Other": "Baseline fatigue was highly predictive of subsequent risk of AEs. These findings suggest that in an era of precision medicine, patient-reported fatigue may be an important component of determining toxicity risk and could aid in treatment decision making.",
            "Conclusion": "Baseline fatigue was highly predictive of subsequent risk of AEs. These findings suggest that in an era of precision medicine, patient-reported fatigue may be an important component of determining toxicity risk and could aid in treatment decision making."
        },
        {
            "Title": "Association of higher baseline stress and pain with clinical outcomes: Early palliative care among patients diagnosed with advanced cancers in the Secondary analysis from Alliance A011502.",
            "Indication": "High-risk non-metastatic breast cancer",
            "Drug": "Aspirin 300mg daily vs. placebo",
            "PFS": "iDFS: Low stress (HR=1.00), Moderate stress (HR=0.96), High stress (HR=2.13); No/mild pain (HR=1.00), Moderate/severe pain (HR=1.57); Good sleep quality (HR=1.00), Poor sleep quality (HR=1.29); Non-depressed (HR=1.00), Depressed (HR=1.29)",
            "OS": "Low stress (HR=1.00), Moderate stress (HR=1.04), High stress (HR=2.55); No/mild pain (HR=1.00), Moderate/severe pain (HR=2.09); Good sleep quality (HR=1.00), Poor sleep quality (HR=1.50); Non-depressed (HR=1.00), Depressed (HR=1.23)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Patients reporting higher stress and pain at baseline had worse outcomes. Both pain and stress may be related to other non-cancer issues (chronic comorbidities). The study highlights the need for clinical trials to consider including questionnaires to assess patient-reported outcomes. Future studies are warranted to determine if measures to decrease pain and stress would improve breast cancer outcomes."
        },
        {
            "Title": "Multi-stakeholder, intentional outreach for improving representative recruitment in Pragmatica\u2013Lung (SWOG S2302).",
            "Indication": "2nd line advanced/metastatic non-small cell lung cancer (NSCLC)",
            "Drug": "Pembrolizumab + ramucirumab vs. investigator-chosen standard therapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The study accrued 37% of its goal and is enrolling above its target rate of 25 patients per month, averaging 36 per month over the last 5 months. Of enrolled patients, 58% are male, 13% are Black, and 3% are LatinX; from 59 academic, 67 community (13% rural), and 2 VA sites.",
            "Conclusion": "The intentional, multi-pronged recruitment plan has exceeded historical overall, Black, and LatinX patient accrual rates. The data highlight novel approaches to trial design, recruitment strategies, and increased internal and external collaboration resulting in improved diversity of clinical trial enrollment and may be a potential toolkit for future trials."
        },
        {
            "Title": "Improving clinical trial performance using adult-learning methods.",
            "Indication": "Oncology treatment trials",
            "Drug": "Not specified",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Screening rate (SR) and randomization rate (RdR) improvements",
            "Conclusion": "Our novel, site-focused intervention strategy structured upon adult-learning methods significantly enhanced clinical trial performance, as measured by SR and RdR. Future work will expand these findings to different trial designs and outcome measures such as endpoint fidelity and cost."
        },
        {
            "Title": "Comorbid opioid use disorder and resource utilization outcomes in patients with neoplasm-related pain: A population analysis.",
            "Indication": "Neoplasm-related pain (NRP)",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Length of Stay (LOS) increased by 0.93 days (95% CI: 0.265\u20131.600; p=0.006); Total Hospitalization Charges (THC) increased by $9248 (95% CI: 1676.00\u201316820.41; p=0.017)",
            "Conclusion": "Our study highlights an increased resource utilization among patients with NRP and comorbid OUD. This could be due to increased tolerance to and dependence on the effects of opioids requiring more intricate multidisciplinary care in managing their pain. Recognizing this association is important for medical oncologists and other healthcare providers when managing NRP patients with OUD. Incorporating multimodality pain management early on admission could improve patient outcomes while optimizing healthcare resources."
        },
        {
            "Title": "The impact of multiple pharmacy use on medication adherence in individuals with colorectal cancer.",
            "Indication": "Colorectal cancer (CRC)",
            "Drug": "Regorafenib, Encorafenib, Trifluridine + Tipiracil",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Adherence was high with 79.5% of members having a Medication Possession Ratio (MPR) \u2265 0.8. Members with multiple pharmacy use had significantly lower adherence rates (76.0% vs. 81.9%; p=0.04) and MPRs (mean \u00b1 SD) (1.06 \u00b1 0.45 vs. 1.15 \u00b1 0.53; p=0.011). Multiple pharmacy use was significantly associated with decreased likelihood of optimal adherence compared to single pharmacy use (OR [95% CI]: 0.705 [0.507-0.98]; p=0.038). Male gender was associated with significantly higher likelihood of optimal adherence (OR [95% CI]: 1.517 [1.093-2.107]; p=0.013).",
            "Conclusion": "In this study of individuals with CRC receiving regorafenib, encorafenib, or trifluridine + tipiracil, utilizing multiple pharmacies for non-CRC medications was associated with a 29.5% decreased likelihood of optimal adherence compared to those who only utilized one pharmacy. Further examinations are warranted that include other modulators of adherence."
        },
        {
            "Title": "Relationship between receipt of combined axillary dissection and nodal irradiation and age.",
            "Indication": "Breast cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Age < 45 years was independently associated with ALND+RNI (< 45 years OR 1.40; 45-64 years OR 1.26 vs 65, p < 0.001). Residing in areas with a high proportion of high-school graduates (%HSG), Hispanic ethnicity but not race, and lower income were also associated with ALND+RNI in adjusted analyses (all p < 0.05). Medicare (p = 0.02 vs private insurance) was inversely associated with ALND+RNI. Among clinical factors, pN+ was the greatest risk factor for receipt of ALND+RNI (all p < 0.001 vs pN0). Higher cT, cN1 and cN2 categories, lobular tumors, mastectomy, not receiving endocrine therapy, and having chemotherapy were also associated with ALND+RNI (all p < 0.05). Sensitivity analyses showed that while sociodemographics influenced receipt of ALND+RNI for women \u2265 45 years, only clinical factors were significant for ALND+RNI for < 45 years. Among 45-64 years, high %HSG (p < 0.001) and low household incomes (p < 0.01) were associated with ALND+RNI. In the \u2265 65 years cohort, high %HSG (p = 0.028), and Asian race (p = 0.011) were more likely to receive ALND+RNI while Medicare patients were less likely to have ALND+RNI (p = 0.012).",
            "Conclusion": "Our findings suggest that clinical factors predominate in axillary treatment decisions for younger patients; while sociodemographic factors are more prominent for those \u2265 45 years. Given their higher odds of receiving ALND+RNI, BCRL screening may be especially important in patients < 45 years. Further studies are needed to understand the interplay between age and sociodemographics on receipt of ALND+RNI."
        },
        {
            "Title": "Electronic patient-reported outcome measures (E-Proms): Results of the H2020 CAPABLE project for patients with metastatic renal cell cancer undergoing immune checkpoint inhibitor therapy",
            "Indication": "metastatic renal cell cancer (mRCC)",
            "Drug": "immune checkpoint inhibitor therapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Quality of Life (QoL) measured by EORTC QLQ-C30 score showed an increase in the CAPABLE cohort (80.7 vs 84.9) and a slight decrease in the control cohort (80 vs 77), with a statistically significant interaction between time and cohort (p < 0.05). CAPABLE patients showed less fatigue and improved social and role functioning.",
            "Conclusion": "This study shows that E-Proms may be effectively included in a comprehensive and engaging system for remote management of mRCC patients."
        },
        {
            "Title": "Examining 1-year mortality risk among patients with malignant melanoma with a history of cannabis use undergoing immune checkpoint inhibitor therapy",
            "Indication": "malignant melanoma",
            "Drug": "immune checkpoint inhibitor therapy",
            "PFS": "None",
            "OS": "1-year mortality risk: Before matching, HR [95% CI] = 1.29 [1.04, 1.6], p = 0.0197. After matching, HR [95% CI] = 1.18 [0.87, 1.6], p = 0.293.",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Cannabis use among melanoma patients was shown to not have any meaningful difference in 1-year mortality risk compared to patients without cannabis use history while undergoing ICI-therapy. Additional studies, such as examining cannabis frequency and dosage, are warranted to further examine its effect."
        },
        {
            "Title": "Health-related quality of life (HRQoL) and symptoms in LIBRETTO-431 patients with RET fusion-positive advanced non-small-cell lung cancer (NSCLC).",
            "Indication": "RET fusion-positive advanced non-small-cell lung cancer (NSCLC)",
            "Drug": "Selpercatinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Selpercatinib significantly delayed TTCD of all individual symptoms with hazard ratio ranging from 0.41 (cough and pain) to 0.57 (dyspnea), compared to control. Selpercatinib also showed a significant and clinically meaningful difference in the mean NSCLC-SAQ total score (difference=-2.0, p<.001) and physical function (difference=8.1, p=.003) at 1 year, compared to control. GHS/QoL was improved in both arms with no difference in the mean scores between the arms at 1 year.",
            "Conclusion": "Selpercatinib significantly delayed TTCD of NSCLC symptoms and improved physical function compared to control in this patient population after 1 year of treatment. The findings were consistent with the favorable efficacy of selpercatinib compared with platinum-based chemotherapy + pembro and further support 1L use of selpercatinib in this population."
        },
        {
            "Title": "Characterizing the authorship of phase 3 randomized clinical trials in oncology, 1960-2023.",
            "Indication": "Cancer",
            "Drug": "Systemic anticancer therapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The study found that 44.3% of the trials were positive. Publications reporting positive trial results had, on average, 3 more authors. Significant variation in positive publication rates (PPR) among fields could be due to therapeutic advances, differential selection of primary endpoints, and/or selective reporting of results.",
            "Conclusion": "Despite known positive publication bias, the success rate of published phase 3 RCTs remains below 50%. Publications reporting positive results had significantly more authors; this finding requires further study including adjustment for trial sample size. Significant variation in PPR among fields could be due to watershed therapeutic advances, differential selection of primary endpoints, and/or selective reporting of results. Finally, a signal for geographic association of outlier PPRs suggests the need for further investigation."
        },
        {
            "Title": "Performance of a trained large language model to provide clinical trial recommendation in a head and neck cancer population.",
            "Indication": "Head and neck cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The study evaluated the performance of a trained large language model (GPT-4) to recommend appropriate clinical trials for a head and neck cancer population. GPT-4 achieved a moderate performance with 63% precision and 100% recall (F1 score 0.77).",
            "Conclusion": "This proof-of-concept study demonstrated that trained LLM can achieve moderate performance in matching physician clinical trial recommendations in head and neck oncology. Our results suggest the potential of embedding trained LLM into oncology workflow to aid clinical trial search and accelerate clinical trial accrual. Future research is needed to optimize precision of trained LLM and to assess whether trained LLM may be a scalable solution to enhance the diversity and equity of clinical trial participation."
        },
        {
            "Title": "Cost-effectiveness of a circulating tumor fraction molecular biomarker for treatment response monitoring.",
            "Indication": "Advanced pan-cancer",
            "Drug": "Immune checkpoint inhibitors (ICIs) +/- chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The study evaluated the cost-effectiveness of a molecular biomarker, Tempus xM, used for treatment response monitoring (TRM). The intervention patients saved $4,400 by preventing the use of ineffective therapy (4.3 weeks avoided vs 0.2 weeks in controls), a savings of $1076 per week of ineffective therapy avoided.",
            "Conclusion": "This model demonstrates that xM-guided treatment is cost-saving compared to imaging alone during 24 weeks of treatment. Future work will incorporate variable clinical uptake and long-term outcomes by expanding the time horizon and including treatment discontinuation due to toxicities and mortality."
        },
        {
            "Title": "Cost-effectiveness of short-course radiotherapy versus long-course chemoradiation in organ preservation for locally advanced rectal cancer.",
            "Indication": "Locally advanced rectal cancer",
            "Drug": "Short-course radiotherapy (SCRT) vs. Long-course chemoradiation (LCRT)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Cost-effectiveness analysis with incremental cost-effectiveness ratio (ICER) of $67,200/QALY for LCRT compared to SCRT.",
            "Conclusion": "Long-course chemoradiation could represent a cost-effective strategy compared with short-course radiotherapy in the non-operative management of patients with locally advanced rectal cancer. The ongoing ACO/ARO/AIO-18.1 trial testing the hypothesis that LCRT-TNT will increase organ preservation rates relative to SCRT-TNT will help confirm these findings."
        },
        {
            "Title": "Multilevel factors associated with delays to neoadjuvant chemotherapy among young adults with operable breast cancer.",
            "Indication": "Breast cancer",
            "Drug": "Neoadjuvant chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Factors associated with treatment initiation delays, including racial, ethnic, and institutional-level disparities.",
            "Conclusion": "While the overall incidence of delays was low, significant racial, ethnic, and institutional-level treatment initiation disparities were observed among young adults receiving neoadjuvant chemotherapy for breast cancer."
        },
        {
            "Title": "Systemic anti-cancer therapy and cost at end of life: A SEER Medicare analysis.",
            "Indication": "Solid tumors and liquid tumors",
            "Drug": "Systemic anti-cancer therapy (SACT)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Higher total cost of care (TCOC) associated with SACT within 30 days of death, including costs for drugs, hospitalizations, emergency department visits, and lower hospice costs.",
            "Conclusion": "Receipt of SACT within 30 days of death was associated with significantly higher Medicare costs. Higher TCOC in those who received SACT is only partially explained by drug costs; most acute care costs were also significantly higher among patients who received any type of SACT."
        },
        {
            "Title": "Enhancing the patient journey to clinical trial enrollment with navigation to optimize accrual: A pilot study for a pragmatic multicentre, stepped wedge, cluster randomized controlled trial (The CTN Pilot Trial).",
            "Indication": "Cancer (various types)",
            "Drug": "Clinical trial enrollment assistance",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Clinical trial enrollment occurred in 3.2% of patients in the database, translating to a 19% rate of successful enrollment of those referred to a trial by the CTN.",
            "Conclusion": "The CTN program is a successful tool to identify clinical trials for cancer patients and can improve clinical trial accrual, as almost one-fifth of patients (19%) who were referred to a clinical trial were enrolled."
        },
        {
            "Title": "Disparities in stereotactic radiosurgery practice patterns for treatment of brain metastases: A large national cancer database study.",
            "Indication": "Brain metastases",
            "Drug": "Stereotactic radiosurgery (SRS) vs. whole brain radiation therapy (WBRT)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Disparities in SRS treatment patterns were identified, with SRS less likely to be used for patients who were of Hispanic ethnicity, lower income, lower educational attainment, without private insurance, and treated at community centers.",
            "Conclusion": "In one of the largest brain metastases studies with nearly 90,000 US patients, disparities in SRS treatment patterns were identified. The data highlight populations with cancer and brain metastases who may benefit from increased access to SRS."
        },
        {
            "Title": "Implementation and preliminary efficacy of a risk stratification system +/- Evaluating disparities in receptor status, overall survival, and time to hormone therapy among women with breast cancer.",
            "Indication": "Advanced lung cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Preliminary efficacy was defined as percentage of concerning ePRO symptoms resulting in new clinical action and 90-day readmissions. By 90 days, 16/37 (43%) had hospital readmissions.",
            "Conclusion": "Patient navigation was feasible and acceptable in patients with hospital-diagnosed ALC. However, there are ongoing barriers to implementation of post-hospitalization ePRO symptom monitoring and palliative care. Additional evaluation is needed to understand the barriers and facilitators and to measure the impact of navigation on patient outcomes. Since nearly all patients met high-risk criteria, future programs will eliminate risk stratification and target all patients with hospital-diagnosed ALC."
        },
        {
            "Title": "Quality of life and testosterone recovery after androgen deprivation therapy in patients with high-risk prostate cancer: Long-term data from a phase III trial.",
            "Indication": "High-risk prostate cancer",
            "Drug": "Androgen deprivation therapy (ADT)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Patients recovering T had a significantly better QoL. In 32 out of 55 items and in 10 out of 21 scales (p<0.01) in the 18-month and 30 out of 55 items and 10 out of 21 scales in the 36-month cohort. Also, 9 items and one scale reached clinical relevance in the 18-month cohort and 10 items and one scale in the 36-month cohort.",
            "Conclusion": "In HRPC treated with RT and long-term ADT, T recovery to normal level is associated with major improvements in QoL in several domains. Since a higher proportion of pts recover a normal T level in a much shorter time without apparent detriment in long-term outcomes, our results suggest that 18-month may be the most appropriate ADT duration for these pts."
        },
        {
            "Title": "Impact of clinical response and AEs on health-related quality of life (HRQoL) in patients (pts) with R/R large B-cell lymphoma (LBCL): Pooled data from 4 lisocabtagene maraleucel (liso-cel) trials.",
            "Indication": "Relapsed/Refractory Large B-Cell Lymphoma (R/R LBCL)",
            "Drug": "Lisocabtagene maraleucel (liso-cel)",
            "PFS": "None",
            "OS": "None",
            "ORR": "73%\u201380%",
            "AE": "CRS and NEs associated with HRQoL worsening in selected domains; recovery to levels of pts without the AE by Day 60",
            "Other": "Positive clinical response associated with significant HRQoL improvement in nearly all domains. CRS and NEs may negatively impact certain HRQoL domains but are temporary.",
            "Conclusion": "Among liso-cel\u2013treated pts with R/R LBCL, positive clinical response was associated with meaningful and sustained improvement in nearly all HRQoL domains. CRS and NEs may negatively impact certain HRQoL domains but are temporary. As liso-cel has demonstrated high response rates and a manageable safety profile across studies, these findings further support the pt-reported benefits of liso-cel for R/R LBCL."
        },
        {
            "Title": "Symptom management through electronic patient-reported outcome (ePRO)-based intervention in patients with breast cancer treated with abemaciclib: Primary results from the LIBRA study, a phase II randomized controlled trial.",
            "Indication": "Advanced breast cancer",
            "Drug": "Abemaciclib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Treatment interruption rate: 41.4% (intervention arm) vs. 31.0% (control arm); Dose reduction rate: 31.0% (intervention arm) vs. 27.6% (control arm)",
            "Other": "Increase in number of defecations from baseline during 28 days: 0.64 (intervention arm) vs. 1.21 (control arm), p=0.003; Increase in number of defecations from baseline during 56 days: 0.55 (intervention arm) vs. 1.18 (control arm), p=0.0004; EORTC QLQ-C30 scales: Global health status/QOL (mean score change from baseline to after one cycle of abemaciclib treatment: 0 vs. -13.5, p=0.038) and diarrhea (20.7 vs. 32.2, p=0.049) were better in the intervention arm.",
            "Conclusion": "This study is the first randomized trial to demonstrate that the intervention based on daily ePROs reduces the frequency of diarrhea in patients with advanced breast cancer treated with abemaciclib."
        },
        {
            "Title": "Comparative patient-reported tolerability (PRT): A multiplicity-controlled analysis of LIBRETTO-531, a randomized controlled trial (RCT) in medullary thyroid cancer (MTC).",
            "Indication": "Medullary thyroid cancer (MTC)",
            "Drug": "Selpercatinib",
            "PFS": "HR=0.28",
            "OS": "None",
            "ORR": "None",
            "AE": "Patients on selpercatinib reported significantly less PTOT with diarrhea (5% vs. 38%), fatigue (6% vs. 21%), taste change (3% vs. 15%), decreased appetite (2% vs. 15%), hand/foot syndrome (2% vs. 9%) (all p<0.001) vs. control.",
            "Other": "PRT (lower PTOT with high SE bother) was significantly better for selpercatinib (8% vs. 24%, p<0.0001); On QLQ-C30, patients on selpercatinib reported significantly less PTOT with HRQoL impairment across physical (36% vs. 52%), role (2% vs. 11%), cognitive (4% vs. 8%), emotional (6% vs. 11%), and social (2% vs. 8%) functions (all p<0.01) vs. control.",
            "Conclusion": "Through the incorporation of a novel, single question and quantifiable metric that measured time on treatment under high SE bother into the LIBRETTO-531 study, we demonstrated superior PRT and improved PFS for selpercatinib in patients with RET-mutant MTC versus vandetanib/cabozantinib. This simplified PRT metric deserves further adoption as a quantitative endpoint to complement PFS in future RCTs."
        },
        {
            "Title": "Evaluation of drug tolerability as a function of toxicity and quality of life in patients enrolled on I-SPY2",
            "Indication": "Breast Cancer",
            "Drug": "Not specified",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Higher grade abdominal pain, decreased appetite, dizziness, blurry vision, adrenal insufficiency (9%), neuropathy (14%)",
            "Other": "Tolerability scores worsened over study treatment, with a maximum mean score of 1.71 at Cycle 11. Tolerability improved after treatment cessation at FU1 (1.09). Mean tolerability at week 6 was 1.53. Higher GP5 scores during treatment were correlated with higher fatigue scores at PS and FU1 and lower physical functioning at PS.",
            "Conclusion": "The GP5 measure is associated with self-reported symptoms, higher grade adverse events, and quality of life scores. Furthermore, greater accumulation of lower grade symptoms can lead to poor tolerability. The single measure GP5 composite score reflects multiple components of treatment toxicity."
        },
        {
            "Title": "Neuropathy trajectory informed by algorithm-based alerts from remote symptom monitoring (RSM) tool in breast cancer practice",
            "Indication": "Breast Cancer",
            "Drug": "Taxanes and platinum-based drugs",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Moderate/severe neuropathy (34.1%), mild neuropathy (53.4%)",
            "Other": "Patients without pre-existing neuropathy whose first report of neuropathy was mild, quickly progressed to moderate/severe neuropathy. Median time to first neuropathy report was 42 days from baseline, and median time to alert trigger due to severity was 28 days.",
            "Conclusion": "Breast cancer patients are at risk for developing severe and sustained neuropathy. Physician notification at first report of neuropathy rather than with an alert of moderate/severe neuropathy provides opportunity for earlier intervention and better outcomes."
        },
        {
            "Title": "The pre-operative window of endocrine therapy to inform radiation therapy decisions (POWER) trial: Baseline patient beliefs about breast cancer and endocrine therapy",
            "Indication": "Early-stage ER+ Breast Cancer",
            "Drug": "Endocrine therapy (pre-ET)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Most patients (81.3%) did not consider themselves sensitive to medications. Although 81.3% reported side effects as important when deciding to continue or stop medications, almost all participants (95.8%) stated they would continue medications, despite side effects, if it would help lower their risk of recurrence.",
            "Conclusion": "Many early-stage ER+ breast cancer patients have a low level of worry about breast cancer recurrence, which may support treatment de-escalation such as RT omission. Most participants are open to taking AET despite the potential for side effects, yet historical data demonstrate low rates of adherence to AET. This discrepancy highlights that patients\u2019 baseline beliefs about themselves and their medications may not align with their actual adherence."
        },
        {
            "Title": "A descriptive patient-reported outcomes (PROs) analysis of KEYNOTE-412 to understand head and neck symptom burden.",
            "Indication": "Locally advanced head and neck squamous cell carcinoma (LAHNSCC)",
            "Drug": "Pembrolizumab (pembro) + chemoradiation therapy (CRT) versus placebo + CRT",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "High symptom burden at baseline for pain, trouble sleeping, needing rest, tiredness, and worry. Greater symptom burden observed in patients with events compared to event-free patients.",
            "Conclusion": "Observations suggest a relationship between baseline item scores and clinical outcomes. Heterogeneity of populations may lead to variability of baseline PRO scores and should be considered in PRO analysis. Further research is needed to explore the relationship between baseline PROs and event outcomes as well as additional subgroup analysis."
        },
        {
            "Title": "Cost-utility analysis of a supervised exercise program for patients with metastatic breast cancer in the PREFERABLE-EFFECT randomized controlled trial (RCT)",
            "Indication": "Metastatic breast cancer",
            "Drug": "Supervised exercise program",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "QALY gain of 0.015 (95% CI: -0.02; 0.05) over a 9-month period. Cost savings in hospital costs (exercise group: \u20ac4,430 vs control group: \u20ac5,211). Probability of cost-effectiveness at willingness-to-pay thresholds of \u20ac20,000 or \u20ac80,000 per QALY gained was 62% or 76% in SA-1, and 91% or 92% in SA-2.",
            "Conclusion": "Exercise for patients with mBC is likely to be cost-effective when individually supervised and even dominant (greater cost-savings and more effective) when group-based. Based on our positive findings for both effectiveness and cost-effectiveness, we recommend supervised exercise to be reimbursed as supportive care during treatment for mBC."
        },
        {
            "Title": "Association of enrollment in Medicare Advantage plans versus fee-for-service in patients with a late-stage diagnosis of gynecologic cancer.",
            "Indication": "Gynecologic cancer (uterine and ovarian cancer)",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Adjusted percentage of late-stage diagnosis was 0.6 percentage points lower for MA enrollees compared to FFS enrollees in uterine cancer. No significant difference in ovarian cancer stage at diagnosis between MA and FFS enrollees.",
            "Conclusion": "Among Medicare beneficiaries (age 65+) diagnosed with uterine cancer, those enrolled in an MA plan were significantly less likely to be diagnosed at a later stage. No such MA-FFS difference in the probability of late-stage diagnosis was found for ovarian cancer."
        },
        {
            "Title": "Estimating adult cancer cachexia prevalence and impact on survival in the US: Real-world data analysis.",
            "Indication": "Cancer cachexia",
            "Drug": "None",
            "PFS": "None",
            "OS": "Diagnosed cachexia: HR 10.72 [95% CI 10.56-10.88]; Observed cachexia: HR 2.35 [95% CI 2.32-2.38]",
            "ORR": "None",
            "AE": "None",
            "Other": "Diagnosed cachexia rates ranged from 0.9-6.3%; observed cachexia rates ranged from 60-83%.",
            "Conclusion": "Prevalence of observed cachexia cases documented by weight loss far exceeded cachexia prevalence based on diagnostic coding. After cancer diagnosis, patients met diagnostic criteria of cachexia within 1 year, but formal diagnosis took up to 2 years. The presence of cancer cachexia (diagnosed or observed) was associated with lower survival."
        },
        {
            "Title": "Demographics and survival outcomes of stage IV male breast cancer: A retrospective analysis of SEER database.",
            "Indication": "Stage IV male breast cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "HR+/HER2-: 31.5 months; HR+/HER2+: 25 months; HR-/HER2-: 4.5 months; HR-/HER2+: 10 months",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The retrospective analysis provides valuable insights into the demographics and survival outcomes of stage IV MBC patients. Triple-negative (HR-/HER2-) subtype continues to have poor outcomes in males, emphasizing the need to explore novel treatment strategies to improve outcomes for this unique patient population."
        },
        {
            "Title": "Pulmonary toxicities in patients (pts) with metastatic breast cancer (mBC) treated with trastuzumab deruxtecan (T-DXd): The Mayo Clinic experience.",
            "Indication": "Metastatic Breast Cancer (mBC)",
            "Drug": "Trastuzumab Deruxtecan (T-DXd)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "22% of pts treated with T-DXd experienced ILD (5% G5)",
            "Other": "17 (22%) developed any grade ILD [G1: 3 (4%), G2: 7 (9%), G3: 2 (3%), G4: 1 (1%), G5: 4 (5%)]. Among pts with ILD of any grade, 13 (76.5%) presented with at least 1 symptom (cough/SOB). The median number of T-DXd cycles prior to onset of any grade ILD was 6 (range 2-13), or 18 (range 6-39) weeks. 5 (29%) pts with any grade ILD received a prior ADC. Of pts with \u2265G3 ILD (n=7), all were Caucasian, 2 (30%) were prior smokers, none had prior lung disease, and had received a median of 5 prior lines of chemo, with 4 (57%) having received a prior ADC. All grade \u22652 ILDs were treated with systemic steroids, and 1 also received IVIG. CT chest was used for diagnosis of all ILD. 7 pts (41%) required hospitalization, all of whom had bronchoscopy, with 5 (29%) requiring intubation. All pts with G1 ILD (3, 18%) restarted T-DXd without ILD recurrence.",
            "Conclusion": "In this case series, 22% of pts treated with T-DXd experienced ILD (5% G5), higher than the rate observed in the pivotal DESTINY-Breast trials, possibly due to real-world use of T-DXd in more heavily pre-treated pts and in pts with pulmonary comorbidities."
        },
        {
            "Title": "Ambulatory teclistamab administration in patients with relapsed/refractory multiple myeloma.",
            "Indication": "Relapsed/Refractory Multiple Myeloma",
            "Drug": "Teclistamab (Tec)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "CRS was observed in 15 (60%) patients, all of which were grade I/II events. ICANS was reported in 4 (16%) patients. Grade 3/4 infections were seen in 3 (12%) patients during the ramp-up period.",
            "Other": "Seventeen patients (68%) required admission during the Tec ramp-up period. CRS was observed in 15 (60%) patients, all of which were grade I/II events. Fourteen of these patients were hospitalized for CRS. They were admitted for a median number of 2 hospital days (range 1-6). Tocilizumab was administered for Grade 2 CRS in 3 patients (12%). ICANS was reported in 4 (16%) patients. Two of those patients developed Grade 2 ICANS which completely resolved with dexamethasone. The median number of admitted hospital days for these patients was 3 days (range 2-49). Neutropenia with ANC <500 was seen in only 1 (4%) patient.",
            "Conclusion": "Tec administration through an inpatient/outpatient hybrid model is safe and feasible. CRS was common but low grade and short-lived. ICANS was observed, but remained low grade and may be attributable to our older patient population. As our center gained experience with the Tec management, we noticed lower rates of admission for low grade CRS. Lower incidences of infections and neutropenia demonstrate that these complications emerge in the months after starting therapy. Future studies will help identify patients who are higher risk for CRS and ICANS during ramp-up treatment and may pave the way for prophylactic interventions and outpatient administration."
        },
        {
            "Title": "Timing of EGFR or ALK inhibitor initiation and survival among patients with advanced NSCLC",
            "Indication": "advanced non-small cell lung cancer (NSCLC)",
            "Drug": "osimertinib, lorlatinib, alectinib, brigatinib",
            "PFS": "None",
            "OS": "By 1 month: 26.8 months (HR 0.85, 95% CI: 0.75-0.97, p=0.01); By 2 months: 24.4 months (HR 0.80, 95% CI: 0.72-0.89, p<0.001); By 3 months: 23.9 months (HR 0.78, 95% CI: 0.69-0.87, p<0.001); By 6 months: 22.7 months (HR 0.80, 95% CI: 0.70-0.91, p<0.001); By 12 months: 24.1 months (HR 0.85, 95% CI: 0.73-0.99, p=0.04); By 18 months: 23.9 months (HR 0.92, 95% CI: 0.76-1.12, p=0.41)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "In this population-based analysis, an initiation of PTT occurring as late as at least 1 year from diagnosis still resulted in a significant survival benefit, though the magnitude of benefit appeared decreased as time passed."
        },
        {
            "Title": "Impact of carboplatin and cisplatin shortages on treatment patterns in patients with metastatic solid tumors",
            "Indication": "metastatic solid tumors",
            "Drug": "carboplatin (CB), cisplatin (CP)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Platinum utilization rates (UR) and odds ratios (OR) for pre- and post-periods across various cancers: BC CP: OR(pre) 1.13, OR(post) 0.97, p=0.32; BC CB: OR(pre) 0.99, OR(post) 1.04, p=0.90; HNC CP: OR(pre) 1.01, OR(post) 0.96, p=0.56; HNC CB: OR(pre) 0.99, OR(post) 0.74, p=0.17; OC CB: OR(pre) 1.01, OR(post) 0.73, p=0.08; EC CB: OR(pre) 1.06, OR(post) 0.58, p=0.004*; NSCLC CB: OR(pre) 1.00, OR(post) 0.71, p<0.001*; SCLC CP: OR(pre) 0.90, OR(post) 0.82, p=0.30",
            "Conclusion": "We observed decreased platinum use across multiple cancers during the shortage period, most notably for metNSCLC and EC, suggesting shortages led oncologists to seek alternatives to standard 1L platinum-based regimens. Further study of how treatment changes due to platinum shortages impacted patient outcomes is warranted."
        },
        {
            "Title": "Mutated KRAS as a promising target in pancreas cancer: PURPLE registry data to inform real-world incidence and prognostic significance and to aid trial recruitment",
            "Indication": "pancreatic ductal adenocarcinoma (PDAC)",
            "Drug": "None",
            "PFS": "None",
            "OS": "KRAS wildtype vs KRAS mutant: 29.0 months vs 19.7 months (p=0.007); For metastatic patients: 15.1 months vs 10.4 months (p=0.28)",
            "ORR": "None",
            "AE": "None",
            "Other": "KRAS mutation frequencies: G12D (45%), G12V (30%), G12R (13%), Q61H (6%), G12A (3%), G12C (1%), G13D (1%)",
            "Conclusion": "Registry based analysis informs understanding of KRAS mutation status of PDAC in a community setting. Here, KRAS mutation status was associated with worse OS outcomes, likely in part due to the association with ECOG PS and receiving less active treatment. With newer promising KRAS-targeted therapies becoming available in clinical trials, known RAS status will aid identification of trial candidates. The clinical utility of NGS and rationale for reflex testing in PDAC is increasing."
        },
        {
            "Title": "Incorporating precision oncology in everyday clinical practice: First two years of comprehensive genomic profiling (CGP) testing experience in Croatia",
            "Indication": "various cancers",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Most commonly tested cancers: gastrointestinal (29.1%) and reproductive tumors (28.9%); Most frequent mutations: KRAS (27.2%), PIK3CA (12.9%), AR (10.6%), NRAS (10.4%), PTEN (9.1%); Clinically not relevant mutations: TP53 (42.8%)",
            "Conclusion": "Our results have shown that almost 50% of tested patients could have potential treatment benefit based on the detection of clinically relevant genomic alteration. Unfortunately, taking into consideration country level insurance eligibility criteria for CGP testing and number of potentially eligible cancer patients, we could say that only less than 5% of patients with metastatic disease were tested within first two years and that penetration of CGP is rather low, resulting with a significant number of patients underserved."
        },
        {
            "Title": "Real-world outcomes in patients with biomarker-selected early-stage non-small cell lung cancer.",
            "Indication": "Early-stage non-small cell lung cancer (NSCLC)",
            "Drug": "Targeted therapy (TT)",
            "PFS": "RFS (Recurrence-Free Survival): WT: 18 months, ALK: 38 months, EGFR: 35 months, KRASG12C: 39 months, KRASnon-G12C: 37 months; Adjusted HR: WT (REF), ALK: 0.51, EGFR: 0.48, KRASG12C: 0.48, KRASnon-G12C: 0.48",
            "OS": "OS (Overall Survival): WT: 53 months, ALK: 109 months, EGFR: 98 months, KRASG12C: 80 months, KRASnon-G12C: 67 months; Adjusted HR: WT (REF), ALK: 0.48, EGFR: 0.48, KRASG12C: 0.71, KRASnon-G12C: 0.76",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "In this real-world analysis, patients with eNSCLC LDM-positive tumors had improved OS and RFS relative to WT patients. Differences in OS between WT and LDM+ patients are likely due to frequent receipt of targeted therapy (TT) following progression to advanced disease. However, the small fraction of patients that received adjuvant TT within 6 months of surgery is not likely to explain observed differences in RFS; thus the differences may be due to innate characteristics of these LDMs."
        },
        {
            "Title": "Real-world treatment patterns and outcomes of zanubrutinib in chronic lymphocytic leukemia and small lymphocytic leukemia (CLL/SLL).",
            "Indication": "Chronic lymphocytic leukemia and small lymphocytic leukemia (CLL/SLL)",
            "Drug": "Zanubrutinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "TEAE: 31.3% (Zanubrutinib), 36.3% (Ibrutinib); TLAE: 7.8% (Zanubrutinib), 11.1% (Ibrutinib); Cardiotoxicity TLAE: 0.7% (Zanubrutinib), 4.2% (Ibrutinib); Non-TLAE: 28.2% (Zanubrutinib), 19.0% (Ibrutinib); CTCAE grade of TLAE < 3: 6.8% (Zanubrutinib), 7.4% (Ibrutinib)",
            "Other": "Dose modification: 50 pts on Zanubrutinib, 34 pts on Ibrutinib; Mortality: 13 pts died (8 from infection, including 5 from COVID); 79% remain on treatment at the end of data collection",
            "Conclusion": "In the real-world setting post-formulary change, zanubrutinib is effective and safe in pts with or without prior ibrutinib use. Zanubrutinib use had lower cardiotoxicity and TLAE rates than ibrutinib though data was limited by a difference in follow-up time. Similar results were seen in zanubrutinib-only pts despite being older and having more comorbidities, with discontinuation most often due to grade 3 AEs."
        },
        {
            "Title": "Genomic landscape and therapeutic implications in advanced solid cancers: KOSMOS-II (KOrean Precision Medicine Networking Group Study of Molecular profiling guided therapy based on genomic alterations in advanced Solid tumors, KCSGAL-22-09)",
            "Indication": "Advanced solid cancers",
            "Drug": "Various investigational drugs targeting genetic alterations",
            "PFS": "None",
            "OS": "None",
            "ORR": "16-week clinical benefit rate of 34.8% for Tier 1 treatment",
            "AE": "None",
            "Other": "Most common genomic alterations: TP53 mutation (50.8%), APC mutation (34.4%), ERBB2 amplification (25%). MGT assigned to 59.5% of patients, with 45.2% allocated to investigational drugs targeting genetic alterations approved for other indications (Tier 1), and 12.6% to clinical trials matching their genetic alterations (Tier 3).",
            "Conclusion": "The KOSMOS-II study demonstrated the feasibility of a pragmatic, nationwide precision medicine approach using diverse real-world NGS panels and favorable clinical outcomes."
        },
        {
            "Title": "Treatment trends for metastatic urothelial carcinoma across eight Mexican centers: A global oncology perspective.",
            "Indication": "Metastatic urothelial carcinoma",
            "Drug": "Cisplatin-based chemotherapy, Carboplatin-based chemotherapy, Gemcitabine, Immunotherapy, Avelumab",
            "PFS": "None",
            "OS": "Median overall survival (mOS) was 11.8 months. Treatment notably impacted mOS, favoring those who received a first line (16.7 vs. 4.6 months, p < 0.0001). Stratified analysis showed worse mOS for those with visceral disease (17.1 vs 10.3 months, p = 0.0065) and ECOG \u2265 2 (17.6 vs 5.8 months, p < 0.001).",
            "ORR": "None",
            "AE": "None",
            "Other": "Progression as best response was found in 47% (n=88). The proportion of individuals receiving a second, third or more lines was 24.6%, 8.8% and 3.5%, respectively. The most common second line treatment was chemotherapy (64.4%) followed by immunotherapy (28.6%).",
            "Conclusion": "These data represent the first effort to delineate treatment trends of mUC in Mexico. First line treatment rates were higher than those described worldwide (35-60%). Further, cisplatin-eligibility was higher compared to reports of high-income countries. Considerable rates of progression to platinum therapy were found, likely due to aggressive disease. This study highlights the limited access to novel treatments, showed by the infrequent use of avelumab maintenance, immunotherapy or targeted agents."
        },
        {
            "Title": "Retrospective analysis: Does 5-FU dose adjustment per DPYD mutation status affect toxicity?",
            "Indication": "Solid tumors including head and neck cancers",
            "Drug": "5-Fluorouracil (5-FU)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Frequency of adverse events with 5-FU: Mucositis: No DPYD mutation: 26/118 (22%), Grade 3 or worse: 6/118 (5.1%); DPYD heterozygous mutation: 12/44 (27.3%), Grade 3 or worse: 2/44 (4.5%). Nausea: No DPYD mutation: 9/118 (7.6%), Grade 3 or worse: 1/118 (0.8%); DPYD heterozygous mutation: 3/44 (6.8%), Grade 3 or worse: 0/44 (0%). Vomiting: No DPYD mutation: 9/118 (7.6%), Grade 3 or worse: 1/118 (0.8%); DPYD heterozygous mutation: 3/44 (6.8%), Grade 3 or worse: 0/44 (0%). Neutropenia: No DPYD mutation: 8/118 (6.8%), Grade 3 or worse: 1/118 (0.8%); DPYD heterozygous mutation: 5/44 (11.4%), Grade 3 or worse: 0/44 (0%). Thrombocytopenia: No DPYD mutation: 3/118 (2.5%), Grade 3 or worse: 1/118 (0.8%); DPYD heterozygous mutation: 2/44 (4.5%), Grade 3 or worse: 0/44 (0%).",
            "Other": "Multivariate analysis: Visceral disease: Hazard Ratio 1.49, Confidence Interval (95%) 1.10-2.02, p = 0.009. ECOG \u2265 2: Hazard Ratio 1.54, Confidence Interval (95%) 1.11-2.14, p = 0.009. First line treatment: Hazard Ratio 0.46, Confidence Interval (95%) 0.32-0.65, p < 0.001.",
            "Conclusion": "There was no statistically significant difference in adverse events between the group with no DPYD mutation who received the full dose of 5-FU and the heterozygous DPYD mutation group receiving a lower 5-FU dose based on DPYD-AS score. Thus, the dose reduction as set out by the CPIC appears to be appropriate."
        },
        {
            "Title": "Outcomes of liver transplant recipients with pretransplant malignancies: Effect of combination of genomic variation\u2013based machine learning and insights from a single institution\u2019s experience.",
            "Indication": "Liver transplant recipients with pretransplant malignancies",
            "Drug": "None",
            "PFS": "None",
            "OS": "1-year OS: 86.6%, 5-year OS: 60-65%, OS at last follow-up: 70%, OS for patients with post-LT malignancies: 46%, OS for patients without post-LT malignancies: 77.7%",
            "ORR": "None",
            "AE": "None",
            "Other": "Post-LT malignancy rates: 25%, Tumor recurrences: 17%, De novo malignancies: 8%",
            "Conclusion": "This study demonstrates post-LT malignancy rates of 25%, including 17% with recurrences and 8% with de novo malignancies. Post-LT OS is excellent in these recipients and comparable to all LT patients. However, OS is lower in those with post-LT malignancies, implying a possible role for increased cancer surveillance."
        },
        {
            "Title": "Biomarker testing and targeted therapy use among patients with non-small cell lung cancer in the United States: An analysis using a physician notes real-world database.",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "Various targeted therapies",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Biomarker testing rates: 13.4% overall, 13.3% in stage III, 22.4% in stage IV. Among patients with actionable mutations: 35.9% received matched targeted therapy, 10.1% received nonindicated targeted therapy.",
            "Conclusion": "Biomarker testing may be underutilized, and many patients may not be benefiting from treatment with precision therapies, highlighting the need for additional educational strategies to optimize precision oncology and elevate patient outcomes."
        },
        {
            "Title": "A deep learning\u2013enabled workflow to estimate real world progression-free survival in patients with metastatic breast cancer.",
            "Indication": "Metastatic breast cancer (mBC)",
            "Drug": "Palbociclib and Letrozole combination therapy",
            "PFS": "Median rwPFS: 20 months (95% CI; 18-25 months)",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "NLP algorithm performance: Sentence-level sensitivity: 99.8%, specificity: 96.7%, accuracy: 98.2%. Patient-level sensitivity: 92.5%, specificity: 83.0%, accuracy: 88.0%.",
            "Conclusion": "An ensemble of NLP algorithms extracted progression events from clinical notes and radiology reports with high accuracy. A semi-automated workflow enabled rapid and accurate determination of rwPFS in mBC patients receiving a combination chemotherapy regimen. Further evaluation of this workflow to estimate rwPFS in other cancers and therapeutic settings is warranted."
        },
        {
            "Title": "Intra-read analysis of radiologists in the setting of blinded independent central review of oncology studies.",
            "Indication": "Oncology",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Intra-read variability in DOP and BOR determinations",
            "Conclusion": "This analysis underscored the role of Intra-read variability in identifying inconsistent readers within the landscape of BICR in Oncology clinical trials. The re-read inconsistency is not a universal requirement, and its application is recommended primarily for complex oncology clinical trials or those with indications known to have elevated adjudication metrics. The study emphasized the potential for targeted retraining strategies based on Intra-read analysis outcomes, contributing to the ongoing refinement of reader proficiency."
        },
        {
            "Title": "Rethinking the impact of pretransplant malignancy on eligibility for double lung transplantation (DLT): An analysis of 23,291 recipients.",
            "Indication": "Pre-transplant malignancy",
            "Drug": "None",
            "PFS": "None",
            "OS": "Overall Survival: HR 1.20 (95% CI 1.12-1.29), p < 0.001 for entire cohort; HR 1.05 (95% CI 0.97-1.13), p = 0.229 for propensity-score matched cohort",
            "ORR": "None",
            "AE": "None",
            "Other": "PostTM-free survival: HR 1.32 (95% CI 1.24-1.41), p < 0.001 for entire cohort; HR 1.13 (95% CI 1.06-1.22), p < 0.001 for propensity-score matched cohort. Non-cutaneous PostTM-free survival: HR 1.21 (95% CI 1.13-1.29), p < 0.001 for entire cohort; HR 1.06 (95% CI 0.99-1.15), p = 0.116 for propensity-score matched cohort.",
            "Conclusion": "Patients with PreTM show similar overall survival rates after DLT as those without PreTM, despite a higher incidence of PostTM, mainly cutaneous. Importantly, there is no increased risk of the original cancer type recurring in PreTM patients compared to the risk of de novo malignancy. These findings highlight the necessity for a more nuanced evaluation of transplant candidacy to prevent premature exclusion of PreTM patients from potentially life-saving surgeries."
        },
        {
            "Title": "Clinical trial participation and end-of-life care among older adults: A multi-center longitudinal observational cohort analysis of 121,717 patients with cancer.",
            "Indication": "Cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "ER/Observation visits: CTP 23.4%, usual care 22.3%, p=0.11. Hospitalizations: CTP 25.9%, usual care 21.9%, p<0.001. Hospice enrollment: CTP 56.8%, usual care 52.6%, OR 1.18, p=0.13. Median duration of hospice: CTP 8 days, usual care 9 days, p=0.16.",
            "Conclusion": "Our findings show that while more hospitalizations were observed, clinical trial participation is not associated with suboptimal end-of-life care outcomes pertaining to hospice enrollment & duration as well as unplanned ER/healthcare utilization. Further efforts to integrate access to clinical trials and evidence-based supportive/palliative care in a scalable sustainable model of goal-concordant, patient-centered cancer care are ongoing."
        },
        {
            "Title": "Disparities in frontline treatment and overall survival in the era of targeted tyrosine kinase inhibitor therapy for chronic myeloid leukemia: 2004-2021.",
            "Indication": "Chronic Myeloid Leukemia (CML)",
            "Drug": "Tyrosine Kinase Inhibitors (TKIs) including imatinib, dasatinib, nilotinib, bosutinib, ponatinib, and asciminib",
            "PFS": "None",
            "OS": "Median OS for 2004-2005: 11.5 years (95% CI 10.6-12.5); Median OS for 2006-2011: 13.6 years (CI 13.1-14.1); Median OS for 2012-2020: unreached; Age-adjusted HR for 2006-2011: 0.86 (CI 0.81-0.91); HR for 2012-2020: 0.67 (CI 0.63-0.71)",
            "ORR": "None",
            "AE": "None",
            "Other": "5-year OS for 2004-2005: 65%; 5-year OS for 2006-2011: 71%; 5-year OS for 2012-2020: 76%",
            "Conclusion": "Survival for CML patients has significantly improved during the last 20 years with the availability of each additional TKI. However, patients from traditionally underserved populations, including those who are underinsured or from racial minority groups, have reduced OS. Continued advances in treatment and efforts to improve access to care for underserved populations are vital to achieve equitable survival outcomes in CML."
        },
        {
            "Title": "Cost-effectiveness of an oral THC: CBD cannabis extract for secondary prevention of refractory, chemotherapy-induced nausea and vomiting.",
            "Indication": "Refractory, chemotherapy-induced nausea and vomiting",
            "Drug": "THC: CBD (tetrahydrocannabinol: cannabidiol) cannabis extract",
            "PFS": "None",
            "OS": "None",
            "ORR": "Complete response (no emesis and no use of rescue medications during the overall treatment phase) for THC: CBD: 24%; for placebo: 5%",
            "AE": "None",
            "Other": "Mean cost (AUD) of hospitalization: $580 (SD $7,831) for THC: CBD group and $1,873 (SD $7,235) for placebo; Mean healthcare costs (AUD): $1,464 (SD $4,667) for THC: CBD group and $2,725 (SD $9,237) for placebo; THC: CBD group had a mean total healthcare cost saving of $1,261 per patient (95% CI -$3,550 to $1029) compared with the placebo group",
            "Conclusion": "This within-trial analysis indicates oral THC: CBD is cost-effective for the treatment of refractory, chemotherapy-induced nausea and vomiting from a health system perspective and supports reimbursement in this setting."
        },
        {
            "Title": "Implementing fragmentomics into real world screening interventions to evaluate clinical utility among individuals with elevated risk for lung cancer (FIRSTLUNG)",
            "Indication": "Lung cancer",
            "Drug": "DELFILung cancer screening test",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: Proportion of practice-identified lung cancer screen-eligible individuals receiving a screening CT order and scan during the study period in the entirety of Arm A (control) versus Arm B (intervention).",
            "Conclusion": "This RCT aims to understand the utilization and impact on lung cancer screening uptake after the DELFI blood-based test intended to inform clinical guidelines and policies focused on improving patient care and health outcomes."
        },
        {
            "Title": "Efficacy, safety, and patient-reported outcomes of vimseltinib in patients with tenosynovial giant cell tumor: Results from the phase 3 MOTION trial.",
            "Indication": "Tenosynovial giant cell tumor (TGCT)",
            "Drug": "Vimseltinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "40% vs 0% (RECIST), 67% vs 0% (TVS)",
            "AE": "Most non-laboratory treatment-emergent adverse events were grade 1/2, no evidence of cholestatic hepatotoxicity or drug-induced liver injury",
            "Other": "Significant improvements in active ROM (18.4% vs 3.8%), physical function (3.3-point difference), worst stiffness (21.8-point difference), health status (7.4-point difference), and pain responders (48% vs 23%)",
            "Conclusion": "Vimseltinib is an effective, well-tolerated CSF1R-targeted therapy that demonstrates significant clinical benefit and improves functional health and QoL in pts with symptomatic TGCT not amenable to surgery."
        },
        {
            "Title": "StrateGIST1: A first-in-human (FIH), phase 1 study of IDRX-42 in patients with metastatic gastrointestinal stromal tumors resistant to prior treatment with tyrosine kinase inhibitors (TKIs).",
            "Indication": "Metastatic gastrointestinal stromal tumors (GIST)",
            "Drug": "IDRX-42",
            "PFS": "None",
            "OS": "None",
            "ORR": "Clinical benefit rate (mRECIST PR or stable disease \u226516 weeks) is 71% overall and 100% in 2nd line",
            "AE": "Most frequently reported TRAE (\u226525%) were gastrointestinal symptoms (diarrhea, nausea, vomiting, decreased appetite, dysgeusia) and fatigue. Grade 3/4 TRAEs included gastrointestinal symptoms, fatigue, and anemia. Two events qualified as DLT.",
            "Other": "Sequential analyses of ctDNA show reductions across all primary and secondary KIT mutations.",
            "Conclusion": "IDRX-42 demonstrates promising clinical activity and a favorable safety profile in patients with advanced GIST following resistance to prior TKIs. Dose finding continues, and additional cohorts are ongoing."
        },
        {
            "Title": "Updated efficacy results of olverembatinib (HQP1351) in patients with tyrosine kinase inhibitor (TKI)-resistant succinate dehydrogenase (SDH)-deficient gastrointestinal stromal tumors (GIST) and paraganglioma.",
            "Indication": "SDH-deficient gastrointestinal stromal tumors (GIST) and paraganglioma",
            "Drug": "Olverembatinib",
            "PFS": "Median PFS was 25.7 months (12.1-not reached) for SDH-deficient GIST, and 8.25 months (1.87-NR) for paraganglioma",
            "OS": "None",
            "ORR": "Clinical benefit rate (CR+PR+SD >4 cycles) was 92.3% for SDH-deficient GIST, and 83.3% for paraganglioma",
            "AE": "The adverse event profile was the same as previously reported, with no newly emergent safety issues observed.",
            "Other": "None",
            "Conclusion": "Olverembatinib was well tolerated. The CBR exceeded 90%, and the estimated median PFS was significantly prolonged, indicating potential benefit of this treatment and providing a benchmark for future studies in this rare subset of GIST."
        },
        {
            "Title": "Final results of a randomized phase II/III study comparing perioperative adriamycin plus ifosfamide and gemcitabine plus docetaxel for high-grade soft tissue sarcomas: Japan Clinical Oncology Group study JCOG1306.",
            "Indication": "High-grade soft tissue sarcomas (STS)",
            "Drug": "Adriamycin plus ifosfamide (AI) and gemcitabine plus docetaxel (GD)",
            "PFS": "Estimated 5-year PFS was 65.2% (95% CI: 52.7\u201375.2%) in AI and 57.4% (45.2\u201367.8%) in GD (HR: 1.33, 95% CI 0.81\u20132.18)",
            "OS": "Estimated 5-year OS was 90.0% (95% CI 80.1\u201395.1%) in AI and 76.1% (64.5\u201384.4%) in GD (HR 2.67, 95% CI 1.23\u20135.80)",
            "ORR": "None",
            "AE": "No treatment-related deaths in both arms",
            "Other": "None",
            "Conclusion": "The results of the second interim analysis were confirmed in the final analysis after five-year follow-up, i.e., non-inferiority of GD to AI in terms of OS could not be confirmed. In the perioperative chemotherapy for high-grade STS in the extremities and trunk, AI remains the standard regimen."
        },
        {
            "Title": "SU2C-SARC032: A randomized trial of neoadjuvant RT and surgery with or without pembrolizumab for soft tissue sarcoma.",
            "Indication": "Advanced undifferentiated pleomorphic sarcoma (UPS), synovial sarcoma (SS), myxoid liposarcoma (MLPS), and de-differentiated liposarcoma (DDLPS)",
            "Drug": "Pembrolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "The proportion of patients with grade 3+ adverse events was significantly higher in the experimental arm (52%) vs the standard of care arm (26%) (p=0.002).",
            "Other": "DFS in the experimental arm is significantly higher than the standard of care arm (p=0.023; HR 0.57, 90% CI: 0.35, 0.91). Estimated 2-yr DFS is 53% (90% CI: 43, 66%) for SOC vs 70% (90% CI: 61, 81%) for the experimental arm. No statistically significant difference in LRFS (HR 0.55, 95% CI: 0.21, 1.42), DDFS (HR 0.57, 95% CI: 0.32, 1.01), or OS (HR 0.39, 95% CI: 0.14, 1.12).",
            "Conclusion": "The addition of neoadjuvant and adjuvant pembrolizumab to RT and surgery significantly improves DFS for patients with stage III UPS and LPS of the extremity and limb girdle."
        },
        {
            "Title": "SU2C-SARC032: A randomized trial of neoadjuvant RT and surgery with or without pembrolizumab for soft tissue sarcoma.",
            "Indication": "Advanced undifferentiated pleomorphic sarcoma (UPS), synovial sarcoma (SS), myxoid liposarcoma (MLPS), and de-differentiated liposarcoma (DDLPS)",
            "Drug": "Sintilimab, doxorubicin, and ifosfamide",
            "PFS": "9.0 months (95% CI: 6.5-11.5 months)",
            "OS": "19.9 months (95% CI: 14.9-24.9 months)",
            "ORR": "68.3% (28/41), including 87.5% UPS, 65.0% SS, 100% MLPS, and 50% DDLPS patients",
            "AE": "The most frequent grade 3 adverse events were leucopenia (50.0%), neutropenia (45.7%), thrombocytopenia (21.7%), anemia (21.7%), and febrile neutropenia (21.7%).",
            "Other": "None",
            "Conclusion": "The primary endpoint of ORR was met. This study indicated promising efficacy and safety of sintilimab combined with AI in the first-line treatment of UPS, SS, MLPS, and DDLPS."
        },
        {
            "Title": "ImmunoSarc II master trial (phase II of sunitinib and nivolumab): Results from the dedifferentiated chondrosarcoma (DDCS) cohort\u2014AGEIS, ISG and UCL study.",
            "Indication": "Advanced dedifferentiated chondrosarcoma (DDCS)",
            "Drug": "Sunitinib and nivolumab",
            "PFS": "5.6 months (95% CI: 4.5-6.7); 6m-PFSR was 46% (95% CI: 23-66)",
            "OS": "10.3 months (95% CI: 6.7-13.9)",
            "ORR": "26.3% partial response; 52.6% stable disease; 21.1% progressive disease",
            "AE": "The most relevant study drug-related grade 3-4 toxicities were neutropenia (20.9%), anemia (16.7%), and increased ALT (12.5%).",
            "Other": "None",
            "Conclusion": "Sunitinib and nivolumab demonstrated encouraging activity in advanced DDCS that did not meet the primary endpoint, but compares favourably with previous analyses of cytotoxic and targeted therapy. The combination is worthy of further exploration in conjunction with reaching a consensus on clinically meaningful endpoints in this challenging population."
        },
        {
            "Title": "ARTEMIS-002: Phase 2 study of HS-20093 in patients with relapsed or refractory osteosarcoma.",
            "Indication": "Relapsed or refractory osteosarcoma",
            "Drug": "HS-20093",
            "PFS": "Not mature",
            "OS": "None",
            "ORR": "20.0% for 12.0 mg/kg HS-20093",
            "AE": "The common grade 3/4 TEAEs (\u22655%) were: neutropenia, leukopenia, thrombocytopenia, lymphopenia, and anemia. The incidences of discontinuations, dose withhold, and dose reductions were 2.9%, 11.8%, and 23.5%, respectively. There was no TEAE leading to death.",
            "Other": "The disease control rate was 81.8% (9/11) and 100% (10/10) in patients with 8 mg/kg and 12.0 mg/kg. B7-H3 is highly expressed in osteosarcoma with median H-score 185 (0-260). No correlation was observed between tumor response and B7-H3 expression level. The PK exposure of HS-20093 ADC, total Ab and payload increased with dose, approximately proportional to dose, with a half-life of 4 to 6 days, and no or minor accumulation after multiple doses of Q3W.",
            "Conclusion": "The data has demonstrated that HS-20093 exhibited promising antitumor activity with acceptable toxicity in patients of heavily-pretreated R/R osteosarcoma. The enrollment of ARTEMIS-002 is continuing."
        },
        {
            "Title": "SARC037: Phase II results of trabectedin given as a 1-hour (h) infusion in combination with low dose irinotecan in patients (pts) with relapsed/refractory Ewing sarcoma (ES).",
            "Indication": "Relapsed/refractory Ewing sarcoma (ES)",
            "Drug": "Trabectedin and low dose irinotecan",
            "PFS": "6-month PFS was 37.7% (95% CI 18.3%, 77.7%)",
            "OS": "None",
            "ORR": "5 PRs in 16 evaluable patients",
            "AE": "No Grade 5 Adverse Events (AEs)",
            "Other": "Median time to response was 2.6 months; all responses were sustained at the time of data cutoff at 10.4+, 7.5+, 5.5+, 5.0+ and 4.6+ months.",
            "Conclusion": "Trabectedin and low dose irinotecan exhibited anti-tumor activity in heavily pretreated patients with ES and met its prespecified ORR with 5 PRs in 16 evaluable patients, all of which are ongoing at data cutoff. There were no unexpected safety signals. The combination demonstrated meaningful clinical benefit and is worthy of further study in ES patients. Analysis of biological correlates is ongoing."
        },
        {
            "Title": "Prospective evaluation of pre-treatment ctDNA burden in localized osteosarcoma to identify patients with inferior outcomes: A report from the LEOPARD study.",
            "Indication": "Localized osteosarcoma",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Baseline detectable ctDNA burden (\u22653%) was associated with an increased risk of EFS-event (relapse/progression, second malignant neoplasm, death) compared to undetectable ctDNA (2-year EFS: 56% [95% CI 45-68%] vs. 88% [95% CI 78-98%]; P < 0.0001). A similar pattern was seen using a cut point of \u22655% (53% [95% CI 41-67%] vs. 83% [95% CI 74-94%], P < 0.0001).",
            "Conclusion": "These findings validate prior data associating baseline ctDNA detection with inferior EFS in patients with localized osteosarcoma and identify the first prospectively validated molecular biomarker in this disease. Baseline ctDNA burden is now positioned for implementation into upcoming therapeutic trials of risk-stratified therapy in localized osteosarcoma."
        },
        {
            "Title": "Impact of ATRX loss on survival and immune microenvironment in multiple sarcoma subtypes.",
            "Indication": "Multiple sarcoma subtypes",
            "Drug": "None",
            "PFS": "Worse PFS in ULMS (HR 0.51, 95% CI 0.33-0.81, p=0.004) and WD/DDLPS (HR 0.13, 95% CI 0.02-0.73, p=0.02)",
            "OS": "Worse OS in ULMS (HR 0.50, 95% CI 0.31-0.80, p=0.004)",
            "ORR": "None",
            "AE": "None",
            "Other": "In ULMS, ATRX loss was associated with increased density of CD8+ T cells (p=0.01), PDL1 expression (p=0.03), CTLA4 expression (p=0.049), IDO-positive tumor cells (p=0.01), and CD163-positive cells (p=0.003).",
            "Conclusion": "ATRX loss is associated with worse outcomes in ULMS, UPS, and WD/DDLPS. ATRX loss is also associated with a distinct immune microenvironment in ULMS characterized by T cell exhaustion and immunosuppressive M2 macrophages. Future work will focus on understanding the molecular underpinnings of these associations and potential ways to exploit these findings therapeutically."
        },
        {
            "Title": "Phase II trial, multicenter, first line paclitaxel-avelumab treatment for inoperable angiosarcoma",
            "Indication": "Unresectable angiosarcoma",
            "Drug": "Paclitaxel plus Avelumab",
            "PFS": "6.0 months (95% CI, 5.4 to 9.5)",
            "OS": "14.5 months (95% CI, 9.4 to 24.6)",
            "ORR": "50.0% (n=16)",
            "AE": "90.9% (any grade), 12.1% (severe), including one death",
            "Other": "Neutropenia (39.4%), Pain (36.4%), Febrile neutropenia, neutropenia, and aspiration (6.1% each)",
            "Conclusion": "Avelumab plus paclitaxel for patients with inoperable angiosarcoma was effective and has a tolerable safety profile. Additional translation studies and phase III studies are needed to clearly identify effectiveness and safety."
        },
        {
            "Title": "Cemiplimab in locally advanced and/or metastatic secondary angiosarcomas (CEMangio): A phase II clinical trial",
            "Indication": "Locally advanced and/or metastatic secondary angiosarcomas",
            "Drug": "Cemiplimab",
            "PFS": "4.1 months (95% CI 1.2-6.4)",
            "OS": "Not reached",
            "ORR": "27.8% (BORR at 24 weeks)",
            "AE": "Three patients stopped treatment due to ICI related toxicity (hepatitis grade 3 and 4, dermatitis grade 2)",
            "Other": "mTTR: 2.6 months (range 1.2-5.4), mDOR: 6.2 months (range 0.43-N/A), High TMB observed in 3 pts with UV-sAS, all showing single-base substitution signature 7a, associated with UV damage",
            "Conclusion": "Cemiplimab shows promising effectivity in secondary angiosarcomas."
        },
        {
            "Title": "Alliance A091902: A multicenter randomized phase II trial of paclitaxel (P) with or without nivolumab (N) in patients (pts) with advanced angiosarcoma (AS)",
            "Indication": "Advanced angiosarcoma",
            "Drug": "Paclitaxel with or without Nivolumab",
            "PFS": "P+N: 7.2 months (5.3-17 months), P: 8.3 months (4.0-18 months)",
            "OS": "P+N: 18 months (13-NR), P: 23 months (15-NR)",
            "ORR": "P+N: 33% (10/30 pts), P: 34% (11/32 pts)",
            "AE": "P+N: 57% (Grade 3-4), P: 44% (Grade 3-4), most commonly fatigue, AST increase, dyspnea for P+N, and anemia for P",
            "Other": "Scalp/face ORR: P+N: 73%, P: 38%, All others ORR: P+N: 11%, P: 26%",
            "Conclusion": "Paclitaxel alone can be an effective therapy in a subset of patients with AS. The combination of Paclitaxel and Nivolumab failed to demonstrate improvement in median PFS over Paclitaxel alone, but may show benefit in scalp/face AS. OS trended better with Paclitaxel alone. No new safety signals were observed."
        },
        {
            "Title": "A phase II study of anlotinib and an anti-PDL1 antibody in patients with alveolar soft part sarcoma: Results of expansion cohorts",
            "Indication": "Alveolar soft part sarcoma (ASPS)",
            "Drug": "Anlotinib and TQB2450",
            "PFS": "Not reached (95% CI: 20.7, not-reached)",
            "OS": "Not reached",
            "ORR": "79.3%, characterized by 3 complete responses and 20 partial responses",
            "AE": "44.83% experienced grade \u22653 AEs, including hypertriglyceridemia (13.79%), lipase elevation (6.90%), amylase elevation (3.45%), and hypertension (3.45%)",
            "Other": "Median DOR for responders: Not reached (95% CI: 18.0, not-reached), Tumor microenvironment analysis revealed significant discrepancy in the presence of tertiary lymphoid structures (TLS) between good and poor responders",
            "Conclusion": "The combination of anlotinib and TQB2450 exhibits promising efficacy in ASPS patients, markedly enhancing ORR and extending PFS with favorable tolerability. TLS emerges as a potential predictive indicator of immunotherapeutic efficacy in ASPS."
        },
        {
            "Title": "Reshaping the tumor microenvironment of cold soft-tissue sarcomas with anti-VEGFR targeted therapy: A Phase 2 Trial of Regorafenib combined with avelumab as first line or later line treatment in advanced chordoma.",
            "Indication": "Advanced chordoma",
            "Drug": "Regorafenib and avelumab",
            "PFS": "6-month PFS was 22.1%, median PFS was 8.6 months (95% CI 5.6-13.6)",
            "OS": "Median OS of 15.1 months",
            "ORR": "32.6% of patients experienced tumor shrinkage, clinical benefit rate of 48.8%",
            "AE": "Most common severe adverse events were grade 1 or 2 palmar-plantar erythrodysesthesia, fatigue, and diarrhea",
            "Other": "High-throughput analysis indicated upregulation of immune-inducing protein biomarkers such as CXCL10 and soluble CD8 antigen. Significant increase in CD8 T cell infiltration on-treatment.",
            "Conclusion": "The combination of regorafenib and avelumab demonstrates a marked mobilization of antitumor immunity in patients with TLS-negative STS. The observed efficacy appears superior to that of single-agent immune checkpoint inhibition in 'cold' STS, and higher than the 6-month PFS benchmark of 14% set by EORTC. This indicates the potential effectiveness of this treatment combination in managing advanced cold STS, marking a significant stride in precision immunotherapy for this group of tumors."
        },
        {
            "Title": "A phase 2, single arm, European multi-center trial evaluating the efficacy of afatinib as first line or later line treatment in advanced chordoma.",
            "Indication": "Advanced chordoma",
            "Drug": "Afatinib",
            "PFS": "PFS rate at 12 months was 40.0% in cohort 1, and 38.5% in cohort 2. Median PFS was 8.6 months (95% CI 5.6-13.6); 9.1 months (95% CI 5.8-16.6) in cohort 1 and 7.1 months (95% CI 2.8-16.5) in cohort 2.",
            "OS": "None",
            "ORR": "Partial response in 4 patients (9.3%), stable disease in 33 (76.7%), PD in 5 patients (11.6%)",
            "AE": "16/47 patients (30.4%) experienced grade \u22653 adverse events. Most common grade 3-4 afatinib-related AEs were skin toxicity (10.6% of patients), diarrhea (10.6%), mucositis (6.4%), hypertension (4.3%). Dose reduction was needed in 20/47 (42.6%) patients, and at least one dose interruption was needed in 31/47 (66.0%) patients.",
            "Other": "QoL data will be provided at the conference.",
            "Conclusion": "With a PFS rate at 12 months of 40.0% in the first-line cohort and 38.5% at 9 months in the further-line cohort, this phase 2 study met the PFS endpoint. Partial response by RECIST was seen in 4/43 patients. Toxicity and dose reductions were relevant but manageable in most patients."
        },
        {
            "Title": "Phase 1 study of NB003, a broad-spectrum KIT/PDGFRa inhibitor, in patients with advanced gastrointestinal stromal tumors (GIST).",
            "Indication": "Advanced gastrointestinal stromal tumors (GIST)",
            "Drug": "NB003",
            "PFS": "None",
            "OS": "None",
            "ORR": "Confirmed ORR was 26.2% (11/42, 95% CI: 13.9, 42.0) per mRECIST v1.1 by investigator assessment, DCR was 73.8% (31/42, 95% CI: 58.0, 86.1). Most of the responses (7/11) are still ongoing.",
            "AE": "Most frequent treatment-related adverse events (TRAEs) were asymptomatic CPK increased (92.9%), anemia (78.6%), AST increased, face edema, WBC decreased (76.2% each), periorbital edema (66.7%), neutrophil count decreased (64.3%), amylase increased (57.1%), lipase increased (52.4%), platelet count decreased (45.2%), edema peripheral (38.1%). Most frequent Grade \u22653 TRAEs were anemia (61.9%), asymptomatic CPK increased (59.5%), neutrophil count decreased (23.8%), WBC decreased (21.4%).",
            "Other": "Tumor responses were observed in patients with a broad spectrum of acquired resistance mutations in both ATP-binding site and activation loop of the kinase domain of KIT based on predose ctDNA. A correlation is observed between changes in the KIT mutation allele fraction (MAF) in ctDNA and changes in tumor size from baseline.",
            "Conclusion": "In heavily pretreated patients with advanced GIST, NB003 demonstrated a manageable safety profile with encouraging antitumor activity across a broad spectrum of secondary resistance mutations in KIT. The RP2D was defined as 20 mg BID based on overall safety and efficacy. Expansion cohorts in different lines of GIST are currently under enrollment."
        },
        {
            "Title": "Durvalumab and tremelimumab versus doxorubicin in previously untreated patients with advanced or metastatic soft tissue sarcoma: Patient-reported outcomes (PROs) of the randomized phase II MEDISARC study (AIO-STS-0415).",
            "Indication": "Advanced or metastatic soft tissue sarcoma",
            "Drug": "Durvalumab and tremelimumab versus doxorubicin",
            "PFS": "No PFS benefit (HR 1.22; 95% CI 0.90-1.64; P=.4049)",
            "OS": "Trend in prolonged OS (HR 0.73; 95% CI 0.54-0.99; P=.185) in favor of DUR-TRE",
            "ORR": "None",
            "AE": "None",
            "Other": "Median follow-up for DUR-TREM vs. DOX were 34.0 months (25.6-42.2) and 37.9 months (31.5-37.9). Median duration of therapy was 2.8 (0-13) and 2.1 (0-4) months, respectively. 86 of 92 (93.5%) patients had \u22651 PRO assessments and were included in the analyses. The completion rate at week 12 was 41.3%. Mean baseline global health status (GHS) was 54.1 (SD: 25.64) and 56.5 (SD: 28.47), respectively. GHS above the median was associated with improved OS and PFS in both arms, indicating a possible predictive biomarker. Under treatment, changes in PRO domains at 12 weeks were noted. TTD favored DUR-TRE in selected functional and symptom domains.",
            "Conclusion": "MEDISARC is the first study to compare check-point inhibitor and doxorubicin treatment in sarcoma patients. PRO measures from baseline to week 12 favored DUR-TRE. Overall, results indicated better symptom and quality of life in patients treated with DUR-TRE."
        },
        {
            "Title": "Monitoring ovarian function in oncology studies: Results and insights from the DeFi phase 3 study of nirogacestat in desmoid tumor.",
            "Indication": "Desmoid tumor",
            "Drug": "Nirogacestat",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "OT in 75% of FORP receiving niro, 0% in placebo. 78% resolution of OT in niro group.",
            "Other": "OT identified as a safety signal. Hormone measures and return of menses used to assess OT.",
            "Conclusion": "In DeFi, 75% of FORP treated with niro experienced OT. Most events resolved, including in 100% who stopped treatment for any reason, suggesting OT with niro is transient."
        },
        {
            "Title": "Feasibility and tolerance of high-dose methotrexate (HDMTX) in older patients with osteosarcoma.",
            "Indication": "Osteosarcoma",
            "Drug": "High-dose methotrexate (HDMTX)",
            "PFS": "None",
            "OS": "5-yr OS significantly improved at 45% for those who received \u226550% doses HDMTX compared to 25% for those who did not. Among patients \u226540 yrs, 5-yr OS was 86% for those who received \u226550% doses HDMTX compared to 31% for those who did not.",
            "ORR": "None",
            "AE": "HDMTX-related AKI did not differ significantly between age groups.",
            "Other": "Older adults were significantly less likely to receive the majority of planned doses, which may be due to clinician bias regarding anticipated toxicity.",
            "Conclusion": "5-yr OS was superior in those patients who received at least 50% of the planned doses of HDMTX. Common HDMTX-associated toxicities did not vary significantly by age."
        },
        {
            "Title": "Preliminary results from FLAGSHP-1: A phase 1 study of ERAS-601 as a monotherapy or in combination with cetuximab in patients (pts) previously treated for advanced chordoma.",
            "Indication": "Advanced chordoma",
            "Drug": "ERAS-601 and cetuximab",
            "PFS": "None",
            "OS": "None",
            "ORR": "1 PR and 8 pts with a best response of SD. Of the 8 pts who had a best response of SD, 7 had some tumor shrinkage.",
            "AE": "Dermatitis acneiform, paronychia, dry skin, skin fissures, skin infection, diarrhea, nausea, vomiting, stomatitis, peripheral edema, fatigue, thrombocytopenia, and AST elevation.",
            "Other": "The median time on combination treatment was 5.06 months, with 8 out of 9 pts remaining on the study.",
            "Conclusion": "The most common toxicities observed for the ERAS-601 + cetuximab combination were dermatologic, which were reversible. Encouraging preliminary activity was seen in advanced, refractory chordoma."
        },
        {
            "Title": "Phase 1 results of the WEE1 inhibitor, azenosertib, in combination with gemcitabine (gem) in adult and pediatric patients (pts) with relapsed or refractory (R/R) osteosarcoma.",
            "Indication": "Relapsed or refractory osteosarcoma",
            "Drug": "Azenosertib + Gemcitabine",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Thrombocytopenia and lymphopenia (33% each); no grade 4 thrombocytopenia events or instances of febrile neutropenia",
            "Other": "18-week EFS was 39% (11/28) across all dose levels",
            "Conclusion": "Azenosertib + gemcitabine was well tolerated at the MTD and provided a greater EFS than historical control cohorts of salvage therapy in pts with R/R osteosarcoma. The MTD of the combination was well tolerated and these data support further investigation of azenosertib with gemcitabine in pts with R/R osteosarcoma in an upcoming Investigator-Initiated Phase 2 trial."
        },
        {
            "Title": "Correlative results from NCI CTEP/ETCTN 10330: A phase 2 study of belinostat with SGI-110 (guadecitabine) or ASTX727 (decitabine/cedazuridine) for advanced conventional chondrosarcoma (cCS).",
            "Indication": "Advanced conventional chondrosarcoma",
            "Drug": "Belinostat with SGI-110 or ASTX727",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "This is the first study to describe transcriptomic changes following epigenetic treatment in cCS. Contradictory to our preclinical data, HDACi + DNTMi resulted in low expression of inflammation. Prior studies reported that the immune infiltrate of CS at progression is immunosuppressive; higher immune infiltrate is correlated with worse outcomes. Loss of inflammation may have implications for disease stability experienced by most pts on NCI10330. Further analyses are planned to correlate TME (pro- vs anti-inflammatory infiltrates) and outcomes. Analyses are limited by small sample size, highlighting the challenges in collecting adequate CS specimens."
        },
        {
            "Title": "Apatinib combined with ifosfamide and etoposide versus ifosfamide and etoposide in relapsed or refractory osteosarcoma (OAIE/PKUPH-sarcoma 11): A multicenter, randomized controlled trial.",
            "Indication": "Relapsed or refractory osteosarcoma",
            "Drug": "Apatinib + Ifosfamide + Etoposide",
            "PFS": "Apatinib + IE: 5.5 months (95% CI, 3.9 to 6.7); IE: 3.4 months (95% CI, 1.4 to 4.6); HR: 0.41 (95% CI, 0.24 to 0.71); P = 0.0010",
            "OS": "None",
            "ORR": "Apatinib + IE: 32.1% (95% CI, 19.9 to 46.3); IE: 25.0% (95% CI, 10.7 to 44.9)",
            "AE": "Grade 3-4 treatment-related adverse events occurred in 69.8% of patients in the apatinib plus IE group and 64.3% in the IE group, with the most common events being white blood cell count decreased and neutrophil count decreased.",
            "Other": "Disease control rates: Apatinib + IE: 90.6% (95% CI, 79.3 to 96.9); IE: 60.7% (95% CI, 40.6 to 78.5); Median time to response: Apatinib + IE: 1.4 months (95% CI, 1.2 to 2.6); IE: 1.5 months (95% CI, 1.3 to 2.0); Duration of response: Apatinib + IE: 6.1 months (95% CI, 1.9 to 8.0); IE: 4.1 months (95% CI, 1.7 to NE)",
            "Conclusion": "Apatinib plus IE demonstrated a significant improvement in PFS in patients with advanced osteosarcomas, with an acceptable safety profile."
        },
        {
            "Title": "Efficacy and safety of fruquintinib-based treatment in patients with refractory bone and soft tissue sarcomas after developing resistance to several TKIs: A multicentered retrospective study.",
            "Indication": "Refractory bone and soft tissue sarcomas",
            "Drug": "Fruquintinib",
            "PFS": "4.4 months (95% CI, 3.9-5.0), HR 1.79 (95% CI, 1.10-2.93; P=0.020)",
            "OS": "11.4 months (95% CI, 10.3-12.5)",
            "ORR": "17.7% partial response (PR), 62.9% stable disease (SD)",
            "AE": "37.9% experienced adverse events, most common were pneumothorax (14.5%), diarrhea (6.5%), mucositis oral (5.6%), and thrombocytopenia (5.6%)",
            "Other": "4-month PFS rate (4m-PFSR) was 58.4% (95% CI, 49.6%-67.1%)",
            "Conclusion": "Fruquintinib demonstrated as a potential option for patients with refractory bone and soft tissue sarcomas after developing resistance to several TKIs, with satisfactory efficacy and safety profile when used in combination therapy. More prospective trials on fruquintinib are needed, especially on different pathological types and diverse combination regimens."
        },
        {
            "Title": "Updated results from ALTER-S002: A single-arm multicenter trial of the combination of anlotinib with chemotherapy in patients with stage IIB classic osteosarcoma of the extremity.",
            "Indication": "Stage IIB classic osteosarcoma of the extremity",
            "Drug": "Anlotinib with doxorubicin and cisplatin",
            "PFS": "Median EFS not reached, 12-month EFS rate 84.18% (95% CI: 70.82, 91.77), 24-month EFS rate 73.43% (95% CI: 57.40, 84.21)",
            "OS": "Median OS not reached, 24-month OS rate 95.92% (95% CI: 84.65, 98.96)",
            "ORR": "None",
            "AE": "Grade 3/4 TRAEs (>20%) included neutropenia (51.92%), leukopenia (38.46%), thrombocytopenia (36.54%), and anemia (32.69%)",
            "Other": "12 (23.53%, 95% CI: 12.79, 37.49) patients experienced recurrence or metastasis",
            "Conclusion": "The updated results suggested that anlotinib combined with doxorubicin and cisplatin in the perioperative period showed favorable efficiency and manageable adverse events in Stage IIB classic osteosarcoma of the extremity."
        },
        {
            "Title": "Phase II study in pediatric and AYA patients with non-metastatic high-grade extremity osteosarcoma with a risk-adapted strategy based on P-glycoprotein (ISG/OS-2): A correlative study on tumour immune microenvironment.",
            "Indication": "Non-metastatic high-grade extremity osteosarcoma",
            "Drug": "Mifamurtide",
            "PFS": "None",
            "OS": "5-year OS for high-risk patients 35.7%, and 89% for the low-risk (p<0.0001, AUC 0.865)",
            "ORR": "None",
            "AE": "None",
            "Other": "5-year EFS for high-risk patients 32.2% and 93% for low-risk (p<0.05, AUC=0.872); 54-gene signature identified for Pgp-positive patients treated with CT+mifamurtide, 5-year EFS of 0% for high-risk and 100% for low-risk (p<0.05, AUC 0.964)",
            "Conclusion": "Tumour immune microenvironment prognostic gene signatures have been identified for risk stratification of patients with osteosarcoma, regardless of treatment with mifamurtide. Importantly, a mifamurtide-specific signature that predicts EFS has been developed, which might be used to select patients who could benefit from adjuvant mifamurtide."
        },
        {
            "Title": "Anti-angiogenic TKIs in patients with advanced Ewing's sarcoma: A systematic review and single-arm meta-analysis.",
            "Indication": "Advanced Ewing's sarcoma",
            "Drug": "Cabozantinib, regorafenib, apatinib, anlotinib, sorafenib",
            "PFS": "Overall median PFS 4.4 months (95% CI 3.4-10)",
            "OS": "None",
            "ORR": "30.1% (95% CI 16.3-43.8; I2=83.14%)",
            "AE": "Dose reduction and interruption of treatment due to toxicity were reported in 39.6% and 6% of patients, respectively. Most common grade 3 or 4 adverse events were leukopenia (24.5%), anemia (8.8%), hypertension (5.6%), and diarrhea (4.3%)",
            "Other": "DCR was 66.8% (95% CI 59.4-73.5; I2=3.97%)",
            "Conclusion": "Anti-angiogenic TKIs are well tolerated and have shown anti-tumoral activity and clinical benefit in the treatment of patients with advanced Ewing's sarcoma. Prospective randomized trials with adequate control groups are warranted to further evaluate the benefits of these agents."
        },
        {
            "Title": "Peak part 1 summary: A phase 3, randomized, open-label multicenter clinical study of bezuclastinib (CGT9486) and sunitinib combination versus sunitinib in patients with gastrointestinal stromal tumors (GIST).",
            "Indication": "Gastrointestinal stromal tumors (GIST)",
            "Drug": "Bezuclastinib and sunitinib",
            "PFS": "Data remain immature to estimate median PFS for Part 1 patients.",
            "OS": "None",
            "ORR": "20% (6 confirmed PRs, 2 unconfirmed PRs); In a subset of patients with prior imatinib only (n=6), ORR was 33% (2 confirmed PRs).",
            "AE": "38% of Grade 3+ events; limited (24%) dose reductions and infrequent (n=2) discontinuations due to TEAEs; 3 patients experienced serious AEs possibly associated with study medications.",
            "Other": "The majority of 2nd-line patients remain on treatment past 12 months.",
            "Conclusion": "Data from Peak Part 1 show an encouraging safety and tolerability profile generally consistent with published sunitinib monotherapy experience. ORR in evaluable patients from Part 1 was 20%; ORR in 2nd line patients was 33%. Part 2 of the Peak study is actively enrolling patients globally at the selected dose of bezuclastinib 600mg QD + sunitinib 37.5mg QD versus sunitinib 37.5 mg QD."
        },
        {
            "Title": "Personalized tumor-informed circulating tumor DNA analysis in monitoring recurrence following resection of high-risk stage II-III gastrointestinal stromal tumor.",
            "Indication": "High-risk stage II-III gastrointestinal stromal tumor (GIST)",
            "Drug": "Imatinib/avapritinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "ctDNA was detected in 64.7% (22/34) of pre-surgical plasma samples. The ctDNA-positive rate at baseline was associated with tumor mutation burden (p < 0.05), rather than tumor size (p = 0.6). Landmark analysis at 1 week post-surgery showed that 12.1% of patients were positive for ctDNA. Among patients with tumor recurrence based on imaging results, 3 patients (3/4) exhibited ctDNA positivity preceding radiological recurrence.",
            "Conclusion": "The present study suggests that personalized tumor-informed ctDNA has the potential to inform recurrence in high-risk stage II-III GIST patients. Clinical enrollment is still ongoing."
        },
        {
            "Title": "Combination targeted therapy of avapritinib and sunitinib for patients with refractory advanced gastrointestinal stromal tumors after failure of standard treatments: Early results from a multi-institutional pilot study.",
            "Indication": "Refractory advanced gastrointestinal stromal tumors (GIST)",
            "Drug": "Avapritinib and sunitinib",
            "PFS": "Median PFS was 6.6 months (95% CI 5.8-7.4).",
            "OS": "Median OS was not reached.",
            "ORR": "20% (4 PR), 75% (15 patients) had SD, 5% (1 patient) had PD with a CBR (PR + SD \u2265 16 weeks) of 75%.",
            "AE": "Common adverse events (AEs) were anemia, leukopenia, diarrhea, fatigue, periorbital and face edema, and memory impairment. Common grade \u2265 3 AEs included leukopenia (30.0%), neutropenia (25.0%), anemia (20.0%), diarrhea (20.0%), and gastrointestinal hemorrhage (15.0%).",
            "Other": "Tumor shrinkage was observed in 18 patients (90%), with a median best percent change in target lesions of -20.2% (range, -54.5% to 16.1%). mPFS in patients with primary KIT11 was similar compared to those with KIT9. Regarding secondary KIT mutations, mPFS was numerically longer in KIT AL pos ABP neg patients compared to without (6.9 months vs 2.3 months, p = 0.251). mPFS was significantly shorter in patients with KIT AL neg ABP pos than without (1.1 months vs 6.9 months, p < 0.001). Three patients carrying KIT16 L783V exhibited significantly longer mPFS than others (18.2 months vs 6.4 months, p = 0.041).",
            "Conclusion": "The combination of avapritinib and sunitinib provided meaningful clinical benefit with an acceptable safety profile in patients with refractory GISTs, except for those harbored KIT AL neg ABP pos mutations."
        },
        {
            "Title": "Molecular residual disease (MRD) detection using bespoke circulating tumor DNA (ctDNA) assays in localized soft tissue sarcoma (STS): A multi-center study.",
            "Indication": "Localized soft tissue sarcoma (STS)",
            "Drug": "Not applicable (ctDNA detection)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "ctDNA detection rate of 79% at baseline; 33% became ctDNA negative post-radiotherapy; 97% became ctDNA negative after surgical resection; ctDNA detected in 47% of patients before radiologic recurrence with a median lead time of 64.8 days.",
            "Conclusion": "Personalized, tumor-informed ctDNA assays can detect MRD and have prognostic value after definitive therapy in localized STS patients. Additional studies are ongoing to evaluate ctDNA as a predictive biomarker for benefit from AST in STS."
        },
        {
            "Title": "Phase II study to evaluate surufatinib in patients with osteosarcoma and soft tissue sarcoma that has failed standard chemotherapy: Updated analysis.",
            "Indication": "Advanced, unresectable or metastatic osteosarcoma and soft tissue sarcoma (STS)",
            "Drug": "Surufatinib",
            "PFS": "Median PFS was 5.68 months (95% CI, 4.27-12.68) for 2L patients, and 2.74 months (95% CI, 2.63-3.12) for 3L and later-line therapy patients.",
            "OS": "None",
            "ORR": "ORR in 2L therapy was 19.05% (1 CR, 3 PR).",
            "AE": "TRAEs were mostly mild (grade 1-2), including proteinuria (75%), hypertension (60%), hypertriglyceridemia (60%), diarrhea (40%), hyperbilirubinemia (40%), and urine occult blood (40%). Grade 3-4 TRAEs were recorded in 16 patients, including hypertension, proteinuria, hypertriglyceridemia, and hyperbilirubinemia.",
            "Other": "DCR of 2L therapy was 90.48% (19/21) and 80% (16/20) for 3L and later-line therapy. PFS for main subtypes: 7.13 months (95% CI, 2.76-NA) for LMS and 3.78 months (95% CI, 2.73-9.99) for LPS.",
            "Conclusion": "The updated results continued to support the clinical activity of surufatinib as second-line therapy in advanced osteosarcoma and STS patients who failed standard chemotherapy with long-term follow-up, while the safety profile remained satisfactory, especially for LMS patients. Surufatinib might be a potential second-line option for patients with advanced osteosarcoma and soft tissue sarcoma."
        },
        {
            "Title": "Treatment of patients with dedifferentiated liposarcoma (DDLPS) with the MDM2\u2013p53 antagonist brigimadlin and p53 function: A longitudinal analysis of circulating microRNAs (miRNAs) in a phase Ia/Ib study",
            "Indication": "Dedifferentiated liposarcoma (DDLPS)",
            "Drug": "Brigimadlin (BI 907828)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Significant changes in protein expression levels, including angiopoietin-1, LAP_TGF-beta, PAI-1, thrombomodulin, and serpinA7. Downregulation of AXL receptor tyrosine kinase (UFO) in patients achieving CR or PR.",
            "Conclusion": "These findings provide insight into the proteomic changes associated with brigimadlin treatment in patients with DDLPS and highlight potential biomarkers for treatment response and adverse events. Further investigation is needed to determine their significance and impact on patient outcomes."
        },
        {
            "Title": "Molecular characterization of long-term and short-term survivors of metastatic uterine leiomyosarcoma",
            "Indication": "Metastatic uterine leiomyosarcoma (uLMS)",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Rates of TP53 and RB1 alterations, chromosomal instability, and tumor mutational burden were analyzed. BRCA2 alterations were higher than previously reported.",
            "Conclusion": "While uLMS from LS and SS have similar levels of chromosomal instability and tumor mutational burden, uLMS in LS have lower rates of TP53 alterations compared to SS patients, as well as compared to previously reported rates in uLMS. Rates of RB1 alterations were higher in LS compared to SS. Further studies in larger cohorts to validate these findings are warranted."
        },
        {
            "Title": "Predictive gene signature for trabectedin efficacy in advanced soft-tissue sarcoma: A Spanish group for research in sarcoma (GEIS) study",
            "Indication": "Advanced soft-tissue sarcoma (STS)",
            "Drug": "Trabectedin",
            "PFS": "Patients in the high-risk gene signature group showed a significantly worse PFS compared with patients in the low-risk group: [2.0 months (95% CI 1.7-2.2) vs. 8.2 months (95% CI 5.1-11.3), p < 0.001]",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "A 29-gene predictive signature of trabectedin efficacy was built, including genes associated with resistance or sensitivity to trabectedin.",
            "Conclusion": "Our study identified a new 29-gene-based signature that significantly predicts trabectedin efficacy in STS patients. Prospective validation of this predictive gene signature is warranted."
        },
        {
            "Title": "A phase 2 trial of the CDK4 inhibitor dalpiciclib in well-differentiated or dedifferentiated liposarcoma",
            "Indication": "Well-differentiated liposarcomas (WDLS) and dedifferentiated liposarcomas (DDLS)",
            "Drug": "Dalpiciclib",
            "PFS": "Median PFS was 17.4 weeks (95% CI, 10.65-24.15 weeks); PFS at 12 weeks was 61.3% (95% CI, 44-78.5%)",
            "OS": "None",
            "ORR": "Only one patient had a partial response to the treatment",
            "AE": "Grade 3 to 4 events included neutropenia (37.5%), anemia (6.25%), thrombocytopenia (6.25%), and nausea (3.13%)",
            "Other": "None",
            "Conclusion": "Treatment with the CDK4 inhibitor dalpiciclib was associated with a favorable PFS rate in patients with advanced CDK4-amplified and RB-expressing WDLS/DDLS."
        },
        {
            "Title": "Phase IIa study of high-affinity TCR-T (TAEST16001) targeting NY-ESO-1 in soft tissue sarcoma.",
            "Indication": "Soft tissue sarcoma",
            "Drug": "TAEST16001",
            "PFS": "None",
            "OS": "None",
            "ORR": "50%",
            "AE": "Common grade 3 adverse events: lymphocytopenia (n=8), decreased WBC count (n=7), neutropenia (n=6), elevated g-GT (n=3), hypokalemia (n=2). Cytokine release syndrome (CRS): G3:1, G2:1, G1:4. ICANS: G1 in 2 patients.",
            "Other": "Median time to initial response: 1.1 months (1.1 to 2.2). Median duration of response: 5.0 months (1.5 to 8.8).",
            "Conclusion": "TAEST16001 demonstrated an acceptable safety profile. The encouraging efficacy data (ORR=50%) exceeded our pre-specified target efficacy (ORR=25%). The review committee has recommended TAEST16001 to proceed early to the next stage of clinical development."
        },
        {
            "Title": "Efficacy and safety of SHR-2554 in advanced epithelioid sarcoma: A phase 2 trial.",
            "Indication": "Epithelioid sarcoma",
            "Drug": "SHR-2554",
            "PFS": "9 (64.3%) of 14 pts remained progression-free at 12 weeks.",
            "OS": "None",
            "ORR": "21.4% partial response, 57.1% stable disease",
            "AE": "Most common treatment-related hematological AEs: anaemia, platelet count decreased, hyperuricaemia.",
            "Other": "None",
            "Conclusion": "SHR-2554 showed promising efficacy and an acceptable safety profile in pts with refractory ES, warranting further investigation."
        },
        {
            "Title": "Results of a randomized phase II trial of 2nd-line treatment for advanced soft tissue sarcoma comparing trabectedin, eribulin and pazopanib: Japan Clinical Oncology Group study JCOG1802 (2ND-STEP).",
            "Indication": "Advanced soft tissue sarcoma",
            "Drug": "Trabectedin, Eribulin, Pazopanib",
            "PFS": "Median PFS: Trabectedin: 2.9 months (95% CI 1.3-5.3), Eribulin: 2.2 months (1.5-3.8), Pazopanib: 3.7 months (2.6-5.2).",
            "OS": "Median OS: Trabectedin: 14.8 months (10.8-29.1), Eribulin: 13.3 months (7.6-20.1), Pazopanib: 15.7 months (9.8-not estimable).",
            "ORR": "DCR: Trabectedin: 50.0% (32.4-67.6), Eribulin: 34.3% (19.1-52.2), Pazopanib: 64.9% (47.5-79.8).",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Pazopanib showed the best PFS and OS as the second-line treatment for patients with advanced STS among trabectedin, eribulin, and pazopanib. A phase III trial comparing GD with pazopanib is planned as the next step."
        },
        {
            "Title": "Phase IB/II trial of durvalumab plus doxorubicin combination in patients with advanced soft-tissue sarcoma.",
            "Indication": "Advanced soft-tissue sarcoma",
            "Drug": "Durvalumab and doxorubicin",
            "PFS": "8.2 months (95% CI, 7.3-9.0)",
            "OS": "24.1 months (95% CI, 7.6-40.3)",
            "ORR": "31.7%",
            "AE": "Grade 3 or 4: neutropenia (53.4%), thrombocytopenia (13.9%), anemia (11.6%)",
            "Other": "None",
            "Conclusion": "Durvalumab combined with doxorubicin demonstrated promising efficacy in an unselected STS cohort, with a manageable toxicity profile."
        },
        {
            "Title": "Redefining radiologic responses (RR) in solid tumors: Shall we brace ourselves for a post-RECIST era? Results from a randomized clinical trial (RCT) on neoadjuvant chemotherapy in high-risk soft-tissue sarcomas (HR-STS) of the trunk or extremities.",
            "Indication": "High-risk soft-tissue sarcomas (HR-STS)",
            "Drug": "Anthracycline + ifosfamide (AI) vs. histology-tailored (HT) neoadjuvant chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "Partial response (11.9%), stable disease (82.6%), progressive disease (5.5%)",
            "AE": "None",
            "Other": "Disease Free Survival (DFS) and Overall Survival (OS) correlated with RECIST best responses.",
            "Conclusion": "These results confirm the independent prognostic value of RR both per RECIST and D in selected HR-STS treated with N, suggesting that any change in size allows to infer the proportional efficacy of treatment."
        },
        {
            "Title": "An open label, phase 1b/2 trial of LVGN6051 (4-1BB agonistic antibody) combined with anlotinib for refractory soft tissue sarcoma (STS).",
            "Indication": "Refractory soft tissue sarcoma",
            "Drug": "LVGN6051 and anlotinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "Disease control rate: 86.21%, partial response: 2 patients, stable disease: 23 patients",
            "AE": "Grade 4 thrombocytopenia (43.59%), AST increased (12.82%), WBC count decreased (10.26%), neutrophil count decreased (10.26%)",
            "Other": "None",
            "Conclusion": "LVGN6051 plus anlotinib was well tolerated, and the RP2D is LVGN6051 3 mg/kg plus a standard dose of anlotinib. The combination showed preliminary and encouraging efficacy for refractory STS."
        },
        {
            "Title": "Phase 2a results of SQ3370, a doxorubicin-based click chemistry therapeutic in patients with advanced STS: Planned interim analysis.",
            "Indication": "Advanced soft tissue sarcomas (STS)",
            "Drug": "SQ3370 (SQL70 biopolymer and doxorubicin protodrug)",
            "PFS": "None",
            "OS": "None",
            "ORR": "14.3% (CI 95% 1.7-40%)",
            "AE": "Nausea and fatigue (all grade \u22642), grade 1 neutropenia (10/14), anemia (13/14), thrombocytopenia (13/14)",
            "Other": "Disease control rate (DCR): 71% (CI 95% 44-92%)",
            "Conclusion": "SQ3370 is the first clinical click chemistry-based cancer therapy, showing that the biopolymer activates the protodrug, which is not a vesicant, and releases doxorubicin in patients. Click chemistry favorably alters PK and safety of the doxorubicin payload."
        },
        {
            "Title": "Efficacy of nirogacestat in participants with poor prognostic factors for desmoid tumors: Analyses from the randomized phase 3 DeFi study.",
            "Indication": "Desmoid tumors",
            "Drug": "Nirogacestat",
            "PFS": "HR, 0.29 [95% CI: 0.15-0.55]; P < .001",
            "OS": "None",
            "ORR": "41% vs 8%; P < .001",
            "AE": "None",
            "Other": "Patient-reported outcomes (pain, DT-specific symptom burden, physical and role functioning, and overall quality of life; P \u2264 0.01, all)",
            "Conclusion": "Niro demonstrated consistent improvement in PFS and ORR vs pbo in patients with characteristics implicated as poor prognostic factors for DT\u2014including larger tumor size, younger age, CTNNB1 mutation types, and pain. These results indicate that niro can provide substantial benefit across the subgroups analyzed."
        },
        {
            "Title": "Safety and toxicity evaluation of the combination of selinexor with atezolizumab in patients with soft tissue sarcomas.",
            "Indication": "Soft tissue sarcomas",
            "Drug": "Selinexor and Atezolizumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Grade 3 TRAEs: neutropenia and lymphopenia",
            "Other": "Fatigue and nausea were the most prevalent TRAEs, but only one patient experienced grade 2 nausea; all other occurrences of nausea and fatigue were at grade 1.",
            "Conclusion": "The safety run-in completed without any occurrences of protocol-defined treatment-related toxicity during cycle 1. The treatment was well tolerated. Based on these data, the randomized part of this study began accruing patients with ASPS across the ETCTN."
        },
        {
            "Title": "Efficacy and safety of nirogacestat in patients with desmoid tumor and adenomatous polyposis coli (APC) mutation: Phase 3 DeFi analyses.",
            "Indication": "Desmoid tumors with APC mutation",
            "Drug": "Nirogacestat",
            "PFS": "HR, 0.21 [95% CI: 0.05-1.00], P = .016",
            "OS": "None",
            "ORR": "38% (5/13) for niro vs 13% (2/16) for pbo",
            "AE": "Adverse events in >50% of niro-treated patients with APC mutation were diarrhea, nausea, rash maculopapular, and fatigue; ovarian toxicity occurred in 9 of 10 females of reproductive potential (6 resolved, 2 lost to follow-up, 1 ongoing and receiving niro)",
            "Other": "Greater reduction in median best percent change from baseline compared with pbo-treated patients in target tumor size (-29.5 vs +2.2), volumetric MRI (-71.0 vs +5.4), and T2 hyperintensity (-74.1 vs -21.0).",
            "Conclusion": "Improvement in PFS, ORR, tumor imaging characteristics, and PROs was observed with niro compared to pbo in patients with DT harboring APC mutations. Efficacy and safety of niro in patients with APC mutations were generally consistent with findings for the overall DeFi population, suggesting that niro can provide clinically meaningful benefit to patients with progressing DT and APC mutations."
        },
        {
            "Title": "Efficacy and safety of IBI110 (anti-LAG-3 antibody) plus sintilimab (anti-PD-1 antibody) in patients with advanced alveolar soft parts sarcoma: Results from a phase II study.",
            "Indication": "Advanced alveolar soft parts sarcoma (ASPS)",
            "Drug": "IBI110 and Sintilimab",
            "PFS": "6-month PFS rate: 94.7% in cohort A and 66.7% in cohort B; Median PFS: not reached in cohort A and 14.7 months in cohort B",
            "OS": "Median OS: not reached in both cohorts",
            "ORR": "55.0% in cohort A and 28.6% in cohort B",
            "AE": "TRAEs occurred in 27 (96.4%) pts, including grade \u22653 TRAEs in 9 (32.1%) pts. The most frequent TRAEs were hyperuricemia (57.1%), increased blood lactate dehydrogenase (42.9%), and hypertriglyceridemia (42.9%), all of which were grade 1-2. Immune-related adverse events (irAEs) occurred in 27 (96.4%) pts, including grade \u22653 irAEs in 9 (32.1%) pts. Serious TRAE occurred in 2 (7.1%) pts with type 1 diabetes mellitus. TRAE leading to treatment interruption occurred in 1 (3.6%) pt due to type 1 diabetes mellitus. No TRAE led to treatment discontinuation or death.",
            "Other": "None",
            "Conclusion": "Co-inhibition of LAG-3 and PD-1 showed encouraging efficacy in pts with advanced ASPS. Compared to anti-PD-1 monotherapy, the combination treatment improved ORR in IO-na\u00efve pts and restored IO effectiveness in previously IO-failed pts. The safety profiles were acceptable and manageable in all pts."
        },
        {
            "Title": "Efficacy and safety of lurbinectedin (LUR) with irinotecan (IRI) in a phase 2 expansion cohort of patients (Pts) with synovial sarcoma (SS).",
            "Indication": "Synovial Sarcoma (SS)",
            "Drug": "Lurbinectedin (LUR) and Irinotecan (IRI)",
            "PFS": "Median PFS: 4.1 months (95% CI: 1.7-7.4) for Investigator, 2.7 months (95% CI: 1.0-4.8) for IRC",
            "OS": "Median OS: 15.1 months (95% CI: 10.9-21.7)",
            "ORR": "ORR: 13.3% (95% CI: 3.8-30.7%) for Investigator, 19.2% (95% CI: 6.6-39.4%) for IRC",
            "AE": "Most common G3/4 events: neutropenia (60%), anemia (27%), thrombocytopenia (17%), diarrhea (10%), fatigue (10%), febrile neutropenia (10%)",
            "Other": "No treatment-related discontinuations or deaths occurred. No evidence of drug-drug interaction was observed in the PK analysis.",
            "Conclusion": "The LUR/IRI combination showed activity in pretreated SS with manageable toxicity. These results reinforce the rationale for assessing the activity of LUR combined with IRI in SS and other tumor types."
        },
        {
            "Title": "Safety and efficacy of eribulin plus anlotinib in patients with advanced soft tissue sarcoma (ERAS): A multi-center phase II study.",
            "Indication": "Advanced Soft Tissue Sarcoma (STS)",
            "Drug": "Eribulin and Anlotinib",
            "PFS": "Median PFS: 30.1 weeks",
            "OS": "Median OS: Not reached",
            "ORR": "ORR: 20.7%",
            "AE": "Most common G3/4 events: neutropenia (35.5%), leukopenia (25.8%), hypertension (16.1%), hypertriglyceridemia (16.1%), increased gamma-glutamyl transpeptidase (16.1%)",
            "Other": "DCR: 82.8%, Predicted PFR 24w: 65.5%",
            "Conclusion": "The combination of eribulin and anlotinib shows promising efficacy with an acceptable toxicity profile in patients with advanced STS, irrespective of pathological type."
        },
        {
            "Title": "Anlotinib versus placebo as adjuvant therapy for completely resected high-grade soft tissue sarcomas: Interim results of a phase 2, double-blinded, randomized controlled trial.",
            "Indication": "High-grade Soft Tissue Sarcomas (STS)",
            "Drug": "Anlotinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Adverse events: 64% in the anlotinib group vs 23% in the placebo group (P < 0.001). Grade 1-2 AEs: 34 patients, Grade 3 AEs: 4 patients. Discontinuations: 3 patients (2 for proteinuria/hematuria, 1 for secondary poor surgical wound healing)",
            "Other": "1-year DFS rate: 88% (anlotinib) vs 64% (placebo), 2-year DFS rate: 77% (anlotinib) vs 58% (placebo). HR for disease recurrence: 0.47 (95% CI: 0.22-1.00, P = 0.0445)",
            "Conclusion": "Current results suggest anlotinib could lower disease recurrence risk in patients with completely resected high-grade STS, with an acceptable toxicity profile."
        },
        {
            "Title": "A phase II study of the combination of pexidartinib and sirolimus to target tumor-associated macrophages in unresectable malignant peripheral nerve sheath tumors",
            "Indication": "Unresectable malignant peripheral nerve sheath tumors (MPNST)",
            "Drug": "Pexidartinib and sirolimus",
            "PFS": "Median PFS: 6 weeks (95% CI, 6-19.1)",
            "OS": "Median OS: 21.8 weeks (95% CI, 14.6-NA)",
            "ORR": "None",
            "AE": "Grade 3 treatment-related adverse events in 28.6% of patients (leukopenia and rash)",
            "Other": "Exploratory analyses of paired biopsies using multiplex immunofluorescence and bulk RNA seq will be presented",
            "Conclusion": "Cotreatment with pexidartinib and sirolimus has limited efficacy in patients with unresectable MPNST but may benefit a subset of patients. Exploratory analyses of TIME modulation are ongoing."
        },
        {
            "Title": "First-in-human CEB-01: Novel loco-regional SN-38 release membrane to prevent local recurrence in retroperitoneal sarcomas",
            "Indication": "Retroperitoneal sarcomas",
            "Drug": "CEB-01 (SN-38 release membrane)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Grade 3 toxicities: retroperitoneal hemorrhage, small intestine obstruction, anemia, fever",
            "Other": "SN-38\u2019s PK showed dose proportionality. Median half-life of SN-38 was 296 hours.",
            "Conclusion": "CEB-01 at 18mg provides promising local disease control in retroperitoneal sarcomas. Further clinical evaluation is underway."
        },
        {
            "Title": "Do patients with myxofibrosarcoma and undifferentiated pleomorphic sarcoma have different clinical outcomes to immune checkpoint blockade-based therapy?",
            "Indication": "Myxofibrosarcoma (MFS) and undifferentiated pleomorphic sarcoma (UPS)",
            "Drug": "Immune checkpoint blockade (ICB)-based therapy",
            "PFS": "MFS: 4.4 months (2.0\u20138.3), UPS: 4.1 months (1.8\u20135.5), P-value: 0.900",
            "OS": "MFS: 17.2 months (10.7\u201326.5), UPS: 25.5 months (15.4\u201335.3), P-value: 0.360",
            "ORR": "MFS: 33%, UPS: 26%, P-value: 0.716",
            "AE": "None",
            "Other": "Sensitivity analysis within MFS did not find a significant difference between patients treated off or on a clinical trial.",
            "Conclusion": "Clinical outcomes to ICB-based therapy did not differ between MFS and UPS patients. It may be reasonable to consider ICB as a treatment option for unresectable or metastatic MFS refractory to standard chemotherapy and to group UPS and MFS together in clinical studies of ICB-based therapy."
        },
        {
            "Title": "Analysis of the time to progression in patients who underwent metastasectomy plus isolated lung chemoperfusion for disease recurrence after metastasectomy alone.",
            "Indication": "Pulmonary disease progression after metastasectomy",
            "Drug": "Cisplatin, Melphalan",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Median TTP1 was 6.9 months, and the median TTP2 was 19.0 months. TTP2/TTP1 ratio equal or above 1.5 was observed in 34 out of 48 patients (71%).",
            "Conclusion": "The data obtained strongly support the feasibility of adding isolated lung chemoperfusion to surgery in patients amenable to excision of lung metastases."
        },
        {
            "Title": "Use of histologic and immunologic factors in sarcoma to predict response rates to immunotherapy.",
            "Indication": "Advanced sarcoma",
            "Drug": "Ipilimumab, Nivolumab, Pembrolizumab",
            "PFS": "KS (NR), ASPS (NR), MFS (27.3 months), UPS (11.3 months)",
            "OS": "Median OS for all patients was 12.8 months",
            "ORR": "Overall: CR 3.2%, PR 13.4%, SD 30%, PD 53.2%. Higher ORR in patients treated with pembrolizumab (21.9%) than with ipilimumab and nivolumab (14.3%).",
            "AE": "16.2% of patients discontinued immunotherapy due to autoimmune adverse effects; the most common were pneumonitis (18.4%), colitis (15.8%), arthritis (13.2%), and hepatitis (13.2%).",
            "Other": "TMB \u226510 and PD-L1 expression correlated with increased ORR. TMB median was 2 mut/MB. PD-L1 positive (27.8% ORR).",
            "Conclusion": "Response and PFS were highly variable across sarcoma histologic subtype. KS, ASPS, AS, MFS, and UPS demonstrated the best ORR and longest PFS while OS, SS, and LPS were the lowest."
        },
        {
            "Title": "Analyzing the effect of treatment modalities on survival in synovial sarcoma.",
            "Indication": "Synovial sarcoma",
            "Drug": "None",
            "PFS": "None",
            "OS": "Surgery: 132.2 months vs. 48.6 months, p < 0.001. Adjuvant chemotherapy: 121.9 months vs. 108.4 months, p < 0.001. Adjuvant chemoradiation: 132.2 months vs. 108.4 months, p = 0.023.",
            "ORR": "None",
            "AE": "None",
            "Other": "Lobectomy and wedge/segmental resections had improved outcomes compared to other types of surgery (p < 0.001).",
            "Conclusion": "Receiving surgery for SS is associated with markedly increased overall survival. Wedge/segmental resections and lobectomies are associated with improved outcomes compared to other types of surgery. Adjuvant chemotherapy and adjuvant chemoradiation are associated with better outcomes than adjuvant radiation alone."
        },
        {
            "Title": "A phase II study of an oncolytic herpes simplex virus 2 and an anti-PD-1 antibody in patients with advanced sarcoma.",
            "Indication": "Advanced sarcoma",
            "Drug": "OH2 (oncolytic herpes simplex virus 2), HX008 (anti-PD-1 antibody)",
            "PFS": "Monotherapy: 1.4 months (CI: 1.2-2.8), Combination: 2.1 months (CI: 1.5-3.1)",
            "OS": "Monotherapy: 4.5 months (CI: 1.9-8.2), Combination: 22.3 months (CI: 8.9-NR)",
            "ORR": "Monotherapy: 0%, Combination: 16.7%",
            "AE": "Most common AEs were grade I and II, including GGT elevation (26.9%), fever (23.1%), neutropenia (19.2%), weight loss (15.4%), LDH elevation (15.4%), and anemia (15.4%).",
            "Other": "DCR: Monotherapy 14.3%, Combination 50.0%. 1 CR and 2 PRs in the combination group with DOR of 4.2, 5.6, and 11.1 months.",
            "Conclusion": "OH2 and HX008 demonstrated good safety and encouraging anti-tumor activity in a phase II sarcoma cohort. Further clinical investigation of this combination is warranted."
        },
        {
            "Title": "Maintenancetreatmentwithtoripalimabandanlotinibafteranthracycline-based chemotherapy in patients with advanced soft tissue sarcoma (TORAN): A single-arm, phase 2trial.",
            "Indication": "Advanced soft tissue sarcoma",
            "Drug": "Toripalimab and anlotinib",
            "PFS": "Median PFS: 11.8 months",
            "OS": "Median OS: Not reached",
            "ORR": "ORR: 22.2%",
            "AE": "Grade 3 or higher adverse events: Hypertension (n=4), hemorrhage (n=1), adrenal insufficiency (n=1), hypertriglyceridemia (n=1)",
            "Other": "Disease Control Rate (DCR): 100%, Progression-Free Survival Rate at 24 weeks (PFR24w): 67%",
            "Conclusion": "Maintenance therapy with toripalimab and anlotinib is a promising treatment option for patients with advanced STS after first-line anthracycline-based chemotherapy."
        },
        {
            "Title": "PrognosticsignificanceofplasmacirculatingtumorDNAfractioninpatients withadvancedsarcoma:AFrenchSarcomaGroupstudy.",
            "Indication": "Advanced sarcoma",
            "Drug": "None",
            "PFS": "PFS: 2.6 months (high TF) vs 4.3 months (low TF), p=0.018",
            "OS": "OS: 6.7 months (high TF) vs 14.1 months (low TF), p=0.005",
            "ORR": "None",
            "AE": "None",
            "Other": "Elevated TF correlated with significantly poorer PFS and OS. TF and ECOG performance status identified as independent predictors of OS.",
            "Conclusion": "Plasma ctDNA TF is a practical and independent prognostic biomarker in advanced sarcoma patients. Its integration into treatment paradigms can facilitate precision therapeutic strategies tailored to individual tumor biology."
        },
        {
            "Title": "Seven year update on SOC-1702: A phase 2 study using trabectedin (T) in combination with ipilimumab (I), nivolumab (N) and trabectedin (T) in previously untreated patients with advanced soft tissue sarcoma.",
            "Indication": "Advanced soft tissue sarcoma",
            "Drug": "Trabectedin, Ipilimumab, Nivolumab",
            "PFS": "Median PFS was 7.4 (95% CI: 5.6-9.2) months; PFS rate at 6 months was 56%",
            "OS": "Median OS was 32.0 (95% CI: 19.8-44.2) months",
            "ORR": "25% ORR, 85% DCR",
            "AE": "Grade 3/4 TRAEs related to Trabectedin include both hematologic and non-hematologic toxicities: fatigue, nausea, vomiting, fever, exhaustion, dehydration, asthenia, cellulitis of port, anemia, neutropenia, thrombocytopenia, transaminitis, elevated CK. Grade 3/4 TRAEs include decreased TSH, increased T4, increased TSH, transaminitis, hyponatremia, dehydration, pruritus, and psoriasis.",
            "Other": "None",
            "Conclusion": "Trabectedin in combination with Ipilimumab and Nivolumab is a safe and effective regimen for previously untreated advanced STS. Randomized studies are needed to confirm whether this regimen is superior to standard first-line therapy for advanced soft tissue sarcoma."
        },
        {
            "Title": "A signal-finding study of nivolumab and relatlimab in patients with advanced chordoma.",
            "Indication": "Advanced chordoma",
            "Drug": "Nivolumab, Relatlimab",
            "PFS": "Median PFS was 21.4 weeks; At 4 months, 7 out of 9 patients were progression-free (77.7%); At 6 months, 4 out of 9 patients remained progression-free (44.4%)",
            "OS": "None",
            "ORR": "One out of 9 (9.1%) patients had a PR via RECIST 1.1",
            "AE": "A total of 64 AEs (Grades 1-4) with 6 events classified as grade 3-4: nausea, facial pain, headache, and pulmonary embolism. Five serious adverse events (SAEs): headache (2), asthma (1), myocarditis (1), and sepsis (1).",
            "Other": "None",
            "Conclusion": "This signal-finding study indicated that some patients with advanced chordomas can receive benefit with relatlimab in combination with nivolumab. The combination was well-tolerated and no new safety signals were found in this rare patient population. Correlative studies are ongoing. The study was closed for slow accrual."
        },
        {
            "Title": "Systemic therapy in NF-1 associated malignant peripheral nerve sheath tumors (MPNST)",
            "Indication": "NF-1 associated malignant peripheral nerve sheath tumors (MPNST)",
            "Drug": "Doxorubicin (D) based chemotherapy",
            "PFS": "None",
            "OS": "Median follow-up was 2.46 years (IQR 1.24-9.52). The median time on treatment for 1st and 2nd line CT was 1 year (IQR 0.48-1.53) and 0.57 years (IQR 0.19-1.35), respectively. In the multivariate Cox model for OS, metastases at diagnosis were associated with decreased risk of death (HR: 0.05, 95% CI: 0.02-0.18).",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "In this study, 50% of patients with advanced NF1 related MPNST made it to 2nd line CT and outcomes were inferior to the predominantly D based 1st line regimens. This highlights an area of high unmet need."
        },
        {
            "Title": "Primary localized malignant PEComa treated with surgery: A report of a large series of patients treated in a referral institution",
            "Indication": "Primary localized malignant PEComa",
            "Drug": "None",
            "PFS": "Five- and 10-year DFS was 69.4% [CI: 56.3-85.6%] and 65.1% (CI: 50.9-83.1%). Predictors of higher risk of DFS were age (>64 years old) and site of origin [p=0.5; HR trunk and arms: 1.2 (CI: 0.38-3.81) vs. retroperitoneum and 3.49 (CI: 0.43-28.37) vs other].",
            "OS": "Five- and 10-year OS was 81.6% [95% Confidence Interval (CI): 70.1-94.9%)] and 77.3% (CI: 64.2-93.0%), respectively.",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "PEComas have a 30% risk of recurrence at 5 years. While age and the site of origin are associated with the natural history of the disease, better pathologic criteria are needed to stratify their risk."
        },
        {
            "Title": "Off-label use of fam-trastuzumab deruxtecan-nxki with early activity in a cohort of patients with desmoplastic small round cell tumor",
            "Indication": "Desmoplastic small round cell tumor (DSRCT)",
            "Drug": "Fam-trastuzumab deruxtecan-nxki (T-DXd)",
            "PFS": "None",
            "OS": "None",
            "ORR": "All 3 patients have experienced clinical benefit including one patient with a RECIST 1.1 partial response and two patients with stable disease.",
            "AE": "Treatment was overall well tolerated with the only grade 3 toxicity of transaminitis (in patients with known liver metastases), which self-resolved. All other toxicities including constipation, nausea, and fatigue were grade 2 or less.",
            "Other": "Favorable responses were noted by PET imaging. 7/7 and 4/7 patients have available IHC and ERBB2 RNA expression data available respectively. All ERBB2 RNA expression data is elevated when compared with the median across all evaluated pediatric tumors in our institutional series.",
            "Conclusion": "The use of anti-HER2 therapy in a limited and unselected group of heavily pre-treated DSRCT patients is demonstrating an early signal of activity with minimal toxicity and suggests that formal assessment as monotherapy or in other relevant combinations in clinical trials is warranted. Further correlation with IHC and RNAseq expression data may help optimize correlatives and/or generate biomarkers for future clinical trials."
        },
        {
            "Title": "Sorafenib treatment duration in desmoid tumors",
            "Indication": "Desmoid tumors",
            "Drug": "Sorafenib",
            "PFS": "The 2-year treatment-free survival was 60% (95% confidence interval [CI], 0.35 to 0.85) in the prior to 1-year group and 87.5% (95% CI, 0.71 to 1.0) in the post-1-year group (P=0.046).",
            "OS": "None",
            "ORR": "None",
            "AE": "The most frequently reported adverse events while on sorafenib were grade 1 or 2 events of palmar-plantar erythrodysesthesia (40%) and diarrhea (40%), with no difference between groups on therapy discontinuation due to side effects (p=0.1).",
            "Other": "None",
            "Conclusion": "Discontinuing sorafenib prior to 1 year was associated with a higher risk of requiring further therapy within the following two years. Still, most patients did not require additional therapy. This finding underscores the complexity of determining the optimal duration for sorafenib therapy, and multiple features should be considered, including side effects, symptom improvement, fertility planning, tumor cellularity (evaluated by T2 MRI signal), and physician-patient shared decision."
        },
        {
            "Title": "A first-in-human phase 1 trial of T cell membrane-anchored tumor-targeted IL12 (ATTIL12)-T cell therapy in advanced/metastatic soft tissue and bone sarcoma.",
            "Indication": "Advanced/metastatic soft tissue and bone sarcoma",
            "Drug": "ATTIL12-T cell therapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Safety, maximal tolerated dose (MTD), and recommended phase 2 dose (RP2D)",
            "Conclusion": "The study aims to assess the safety, MTD, and RP2D of ATTIL12-T cells in patients with locally advanced or metastatic soft tissue or bone sarcomas."
        },
        {
            "Title": "A phase 1/2 study of DCC-3116 in combination with ripretinib for patients with advanced gastrointestinal stromal tumor.",
            "Indication": "Advanced gastrointestinal stromal tumor (GIST)",
            "Drug": "DCC-3116 in combination with ripretinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Safety, recommended phase 2 dose (RP2D), and preliminary efficacy",
            "Conclusion": "The study aims to evaluate the safety, RP2D, and preliminary efficacy of DCC-3116 in combination with ripretinib in advanced GIST."
        },
        {
            "Title": "Peak study: A phase 3, randomized, open-label multi-center clinical study of bezuclastinib (CGT9486) and sunitinib combination versus sunitinib in patients with gastrointestinal stromal tumors (GIST).",
            "Indication": "Gastrointestinal stromal tumors (GIST)",
            "Drug": "Bezuclastinib (CGT9486) and sunitinib",
            "PFS": "Primary endpoint: Progression-Free Survival (PFS) confirmed by blinded independent central review per mRECIST v1.1",
            "OS": "None",
            "ORR": "Additional efficacy endpoints include overall survival and objective response rate",
            "AE": "None",
            "Other": "Safety endpoints and circulating tumor DNA (ctDNA) will be collected and assessed",
            "Conclusion": "The study aims to evaluate the efficacy and safety of bezuclastinib in combination with sunitinib versus sunitinib alone as second-line treatment in adult patients with GIST."
        },
        {
            "Title": "Randomized phase III trial of neo-adjuvant and adjuvant chemotherapy vs adjuvant chemotherapy alone for localized soft tissue sarcoma (NACLESS, JCOG2102).",
            "Indication": "Localized soft tissue sarcoma",
            "Drug": "Doxorubicin plus ifosfamide (AI)",
            "PFS": "None",
            "OS": "Primary endpoint: Overall Survival (OS)",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The trial aims to optimize the balance between the survival benefit of perioperative chemotherapy, reduce toxicities, and risks of extended surgery."
        },
        {
            "Title": "Randomized controlled, open-label, phase IIb/III study of lurbinectedin in combination with doxorubicin versus doxorubicin alone as first-line treatment in patients with metastatic leiomyosarcoma.",
            "Indication": "Metastatic Leiomyosarcoma (LMS)",
            "Drug": "Lurbinectedin (LUR) and Doxorubicin (DOX)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: Progression-Free Survival (PFS) according to Independent Radiological Committee (IRC). Secondary endpoints include overall survival, PFS according to Investigator assessment (IA), overall response rate, duration of response, and clinical benefit rate (according to IRC and IA), safety profile, patient-reported outcomes, pharmacokinetics, and exploratory pharmacogenomic analysis.",
            "Conclusion": "The phase 2b part of the study will randomize approximately 120 patients into 3 arms. One of the two experimental arms will be dropped out at the end of phase 2b. The study will then enter the phase 3 part and approximately 120 patients will be randomized 1:1 between the control arm and the selected experimental arm. The study is ongoing and recruiting patients in Europe and the USA."
        },
        {
            "Title": "A phase I/II trial of neoadjuvant pegylated arginine deiminase (ADI-PEG20) with ifosfamide and radiotherapy in soft tissue sarcomas (STS) of the trunk or extremity.",
            "Indication": "Soft Tissue Sarcomas (STS) of the trunk or extremity",
            "Drug": "Pegylated Arginine Deiminase (ADI-PEG20), Ifosfamide, and Radiotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary objectives include determining safety and tolerability as well as the recommended phase II dose (RP2D). The primary objective of the phase II portion is to determine the percent necrosis and pathologic complete response (pCR) at the time of resection.",
            "Conclusion": "The trial has begun accrual and is soon to open at multiple sites."
        },
        {
            "Title": "Multi-site randomized trial of stepped palliative care (PC) for patients with advanced lung cancer.",
            "Indication": "Advanced lung cancer",
            "Drug": "Stepped palliative care (SPC) vs. early palliative care (EPC)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Quality of Life (QOL) scores at week 24 were non-inferior for SPC vs. EPC (adjusted means: 100.62 vs. 97.75, p < 0.0001). Mean number of PC visits by week 24 was lower for SPC vs. EPC patients (adjusted means 2.44 vs. 4.70, p < 0.0001). Rate of end-of-life (EOL) communication was non-inferior for SPC vs. EPC (adjusted proportions: 0.30 vs. 0.33, p = 0.09). Non-inferiority was not demonstrated for days in hospice (adjusted means SPC = 19.72 vs. EPC = 34.64, p = 0.9).",
            "Conclusion": "A stepped care model, with PC visits scheduled only at key points in patients\u2019 cancer trajectories and using a decrement in QOL to trigger more intensive PC exposure, results in significantly fewer PC visits without sacrificing the benefits for patients\u2019 QOL. While SPC was associated with fewer days in hospice, this novel model holds promise as a more scalable way to deliver early PC to enhance patient-reported outcomes."
        },
        {
            "Title": "A mobile app\u2013based program for facilitating advance care planning discussions between patients with advanced cancer and oncologists: A randomized controlled trial (J-SUPPORT 2104).",
            "Indication": "Advanced cancer",
            "Drug": "Mobile app-based program for advance care planning (ACP)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Oncologist\u2019s empathic behavior scores were significantly higher in the APP group than the UC group (estimated value [95% CI]: 19.6 [17.9, 21.3] in the ACP group vs. 12.0 [10.3, 13.7] in the UC group, p < 0.0001). The ACP group reported higher satisfaction with oncologists\u2019 response than the UC group (mean scores [SD]: 9.3 [1.2] vs. 8.9 [1.3], p = 0.03). No significant intergroup differences were observed for psychological distress.",
            "Conclusion": "Application-based interventions improved communication between patients with advanced cancer and their oncologists, including ACP discussions, without exacerbating patients\u2019 psychological distress. Further analysis is ongoing to determine the impact of ACP discussions on medical utilization and healthcare outcomes."
        },
        {
            "Title": "BE-a-PAL: A cluster-randomized trial of algorithm-based default palliative care referral among patients with advanced cancer.",
            "Indication": "Advanced solid malignancies (lung or non-colorectal gastrointestinal cancer)",
            "Drug": "Algorithm-based default palliative care referral",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Higher rates of completed PC visits in the intervention arm (46.6% vs. 11.3%, adjusted odds ratio 5.4, 95% CI 3.2 to 9.2). Among 179 decedents, the intervention decreased end-of-life chemotherapy (6.5% vs. 16.1%, p = 0.06). No difference in quality of life or hospice among decedents.",
            "Conclusion": "In a large community oncology network, algorithm-based default PC referrals were acceptable to clinicians and led to a 3-fold increase in specialty PC and decreased end-of-life chemotherapy."
        },
        {
            "Title": "Randomized trial of a multimodal intervention to enhance sexual function and quality of life (QOL) in hematopoietic stem cell transplant (HSCT) survivors.",
            "Indication": "Sexual dysfunction in HSCT survivors",
            "Drug": "Multimodal intervention",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "At 3 months, patients randomized to the intervention reported improved global satisfaction with sex (B=4.7, P < 0.001), interest in sex (B=1.3, P < 0.001), orgasm pleasure (B=3.3, P < 0.001), erectile function (B=10.9, P < 0.001), vaginal discomfort (B=-9.6, P=0.008), QOL (B=12.5, P < 0.001), anxiety (B=-1.7, P=0.003), and depression symptoms (B=-2.0, P < 0.001) compared to those assigned to usual care. Sustained effects at 6 months with improvement in global satisfaction with sex (B=5.2, P < 0.001), interest in sex (B=0.9, P=0.009), orgasm pleasure (B=3.6, P < 0.001), erectile function (B=12.7, P < 0.001), vaginal discomfort (B=-13.5, P < 0.001), QOL (B=9.2, P=0.002), anxiety (B=-2.0, P=0.001), and depression symptoms (B=-1.4, P=0.006).",
            "Conclusion": "A multimodal intervention delivered by trained HSCT clinicians results in sustained improvements in sexual health outcomes, QOL, and psychological distress among HSCT survivors. A future multi-site trial is needed to demonstrate the generalizability of these findings across care settings in diverse HSCT survivors."
        },
        {
            "Title": "Alliance A222001: A randomized, double-blind, placebo-controlled phase II study of oxybutynin versus placebo for the treatment of hot flashes in men receiving androgen deprivation therapy.",
            "Indication": "Hot flashes in men receiving androgen deprivation therapy",
            "Drug": "Oxybutynin",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "Risk prediction model for taxane-induced peripheral neuropathy (TIPN) in patients with early-stage cancer receiving taxane therapy: SWOG S1714.",
            "Indication": "Taxane-induced peripheral neuropathy (TIPN) in early-stage cancer",
            "Drug": "Taxane-based regimen (Paclitaxel, Docetaxel)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "TIPN rate: 62.0%",
            "Other": "TIPN proportions by quartile of risk score: Q1: 39.5%, Q2: 53.3%, Q3: 65.3%, Q4: 76.3%",
            "Conclusion": "A limited set of demographic, baseline comorbid, and treatment factors can be used to predict TIPN risk and may help guide treatment decision-making. Future work to refine risk prediction using biomarkers is ongoing."
        },
        {
            "Title": "Primary outcomes of the enhanced, EHR-facilitated cancer symptom control (E2C2) cluster-randomized, stepped wedge, pragmatic trial.",
            "Indication": "Cancer symptom control",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "Results from a randomised, open-label trial of a multimodal intervention (exercise, nutrition and anti-inflammatory medication) plus standard care versus standard care alone to attenuate cachexia in patients with advanced cancer undergoing chemotherapy.",
            "Indication": "Cachexia in patients with advanced cancer undergoing chemotherapy",
            "Drug": "Multimodal intervention (exercise, nutrition, anti-inflammatory medication)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "Walking dose required to achieve a clinically meaningful reduction in cancer-related fatigue among patients with breast cancer receiving chemotherapy: A URCC NCORP nationwide prospective cohort study.",
            "Indication": "Breast cancer",
            "Drug": "None (exercise intervention)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Patients who increased their walking by 111-162 minutes at a low-intensity pace or 54-108 minutes at a moderate-intensity pace were 43% more likely to experience a clinically meaningful reduction in cancer-related fatigue.",
            "Conclusion": "Over the course of chemotherapy, patients with breast cancer who can increase their walking at a low intensity pace (less than 2.5 mph) up to 151-222 min/wk or at a moderate intensity pace (2.6-4.5 mph) up to 74-146 min/wk are 43% more likely to have clinically meaningful lower levels of cancer-related fatigue post-chemotherapy."
        },
        {
            "Title": "Patterns of depressive symptoms among survivors of early-stage breast cancer (BC).",
            "Indication": "Early-stage breast cancer",
            "Drug": "None (observational study)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Five patterns of depressive symptoms were identified among 9087 patients. Factors such as older age, previous psychiatric morbidity, obesity, and lower income were associated with worse depressive symptoms.",
            "Conclusion": "Early screening and proactive follow-up are crucial to intercept psychological vulnerability. Trials of risk reduction interventions targeting pattern determinants since diagnosis and mitigating the psychological impact of cancer and health risk behaviors during primary treatment seem warranted."
        },
        {
            "Title": "Hope drives quality of life in patients with brain metastases, but the hope center remains elusive: An analysis of NRG-CC003.",
            "Indication": "Small cell lung cancer with brain metastases",
            "Drug": "Prophylactic cranial irradiation (PCI) with or without Hippocampal Avoidance (HA)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "No significant difference in hope levels between the two arms of the trial (PCI vs PCI+HA). Significant correlation between components of hopefulness and quality of life.",
            "Conclusion": "It is feasible to study hopefulness in the context of prospective trials conducted within the NCTN. The hippocampus could not be implicated as a critical structure in a central pathway that coordinates hopefulness. A validated tool prospectively established a relationship between hope and quality of life among patients with cancer."
        },
        {
            "Title": "Effect of nurse-led multi-disciplinary treatment on patients with head and neck tumors: A randomized controlled trial.",
            "Indication": "Head and neck tumors",
            "Drug": "Nurse-led multi-disciplinary treatment (MDT) interventions",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "RTI rate: MDT vs. standard: 11.4% vs. 25.9%, P=0.005; QoL improvements in emotional function, GHS, fatigue, and insomnia; Hospitalization rate: MDT vs. standard: 7.9% vs. 18.8%, P=0.016",
            "Conclusion": "Nurse-led MDT interventions effectively reduce RTI, improve nutritional status of patients, and alleviate their psychological burden, making it a worthy approach for dissemination in developing countries."
        },
        {
            "Title": "Preventive effect of naldemedine for opioid-induced constipation in patients with cancer starting opioids: A multicenter, double-blinded, randomized, placebo-controlled, phase 3 trial.",
            "Indication": "Opioid-induced constipation in cancer patients",
            "Drug": "Naldemedine",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "None"
        },
        {
            "Title": "The influence of yoga and cognitive behavioral therapy on mediational relationships between insomnia and cancer-related fatigue: A URCC NCORP RCT in 550 cancer survivors.",
            "Indication": "Cancer-related fatigue and insomnia in cancer survivors",
            "Drug": "Yoga for Cancer Survivors (YOCAS) and Cognitive Behavioral Therapy for Insomnia (CBT-I)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "YOCAS: CRF improvement at mid-intervention: -0.38\u00b10.16, p=0.01; Insomnia improvement at mid-intervention: -1.15\u00b10.35, p<0.01; CRF improvement at post-intervention: -0.35\u00b10.17, p=0.03; Insomnia improvement at post-intervention: -1.43\u00b10.41, p<0.01; CBT-I: CRF improvement at mid-intervention: -0.32\u00b10.18, p=0.06; Insomnia improvement at mid-intervention: -2.64\u00b10.40, p<0.01; CRF improvement at post-intervention: -0.59\u00b10.18, p<0.01; Insomnia improvement at post-intervention: -4.95\u00b10.46, p<0.01",
            "Conclusion": "Both YOCAS and CBT-I effectively improve CRF and insomnia among survivors. 37%-60% of the reduction in CRF resulting from participating in YOCAS or CBT-I is due to improvement in insomnia. Clinicians should consider prescribing YOCAS yoga or CBT-I for survivors who experience CRF and insomnia."
        },
        {
            "Title": "Technology-enhanced palliative care for patients with advanced cancer undergoing phase I therapies: A pilot randomized clinical trial (RCT)",
            "Indication": "Advanced cancer",
            "Drug": "Palliative care with and without technology enhancement",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "GDS and QOL improvements measured at 2, 4, 8, and 12 weeks",
            "Conclusion": "Higher intensity of PC with closer monitoring showed within-group improvement in symptoms and QOL, while PC alone had some symptom reduction but no QOL improvement. Further studies are needed to confirm the QOL benefit of PC+TEC over PC alone."
        },
        {
            "Title": "Targeting exercise and sedentary behavior for the prevention of allogeneic stem cell transplant-related cardiovascular dysfunction: The ALLO-Active trial",
            "Indication": "Hematological malignancy",
            "Drug": "Exercise and sedentary behavior intervention",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Preservation of VO2peak, COIpeak, and SVIpeak; no treatment effects on cardiac injury biomarkers or global longitudinal strain",
            "Conclusion": "Intervening early in the allo-SCT process with an exercise and sedentary behavior intervention can have significant benefits for the preservation of patient\u2019s cardiovascular reserve capacity. Our results may have important long-term implications for cardiovascular disease incidence and mortality in allo-SCT recipients."
        },
        {
            "Title": "A randomized, double-blind controlled trial of medicinal cannabis vs placebo for symptom management in patients with advanced cancer receiving palliative care.",
            "Indication": "Advanced cancer",
            "Drug": "THC:CBD combination oil",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Confusion (MC: 37.7%, Placebo: 16.7%), Feeling high (MC: 30.4%, Placebo: 13.9%), Exaggerated sense of well-being (MC: 14.5%, Placebo: 2.8%)",
            "Other": "Improvement in pain score (MC: -1.41, Placebo: -0.46, p=0.04); Overall wellbeing (MC: -0.48, Placebo: -1.29, p=0.02)",
            "Conclusion": "Although showing no advantage over placebo with respect to improving total symptom distress, a 1:1 THC:CBD medicinal cannabis oil resulted in a statistically significant improvement in cancer-related pain at the expense of increased psychomimetic toxicity."
        },
        {
            "Title": "Predictors of cardiotoxicity in patients with early breast cancer treated with doxorubicin and/or trastuzumab: Implications of race/ethnicity and insurance status.",
            "Indication": "Early breast cancer",
            "Drug": "Doxorubicin and/or trastuzumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Cardiotoxicity (Black: 41%, White: 19%, Hispanic: 50%)",
            "Other": "Race/ethnicity, HTN, insurance status, and tobacco use were most strongly associated with cardiotoxicity. Black patients were more likely to develop cardiotoxicity compared to White patients (OR 2.60, 95% CI=1.01-6.65, P=0.045). HTN patients were also more likely to develop cardiotoxicity (OR 2.62, 95% CI=1.10-6.43, P=0.031). State insurance was associated with an increased risk of developing cardiotoxicity (OR 3.73, 95% CI=1.47-9.58, P=0.006).",
            "Conclusion": "In our well-characterized population of patients with stage I-III breast cancer, who were prospectively followed and received standard of care systemic therapy, we observed an association between insurance status with increased risk of cardiotoxicity from treatment regimen."
        },
        {
            "Title": "DTx-based cardio-oncology rehabilitation for lung cancer survivors: A randomized controlled trial.",
            "Indication": "Lung cancer",
            "Drug": "Digital therapeutics (DTx)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "VO2 PEAK improvement (3.6606 \u00b1 3.232 vs 1.0886 \u00b1 3.230 mL/Kg/min, p=0.023), better alleviation of affective interference (-1.0916 \u00b1 1.788 vs 0.3106 \u00b1 1.330, p=0.017), and more anxiety relief (-0.3776 \u00b1 0.584 vs -0.0206 \u00b1 0.321, p=0.045) compared with usual care.",
            "Conclusion": "DTx-based cardio-oncology rehabilitation demonstrated improvements in cardiorespiratory fitness and reductions in affective interference and anxiety among lung cancer survivors, indicating high compliance and safety."
        },
        {
            "Title": "The effect of sodium glucose cotransporter-2 inhibitors on mortality and cardiovascular outcomes of patients with prostate cancer receiving luteinizing hormone releasing hormone agonists.",
            "Indication": "Prostate cancer with type 2 diabetes mellitus (T2DM)",
            "Drug": "Sodium glucose cotransporter-2 inhibitors (SGLT2i)",
            "PFS": "None",
            "OS": "All-cause mortality: Hazard Ratio (HR) 0.65 [95% CI, 0.52 to 0.81], p < 0.001",
            "ORR": "None",
            "AE": "Hypertension: HR 0.40 [95% CI: 0.22-0.70], p = 0.001; Major adverse cardiovascular events (MACE): HR 0.70 [95% CI, 0.50 to 0.97], p = 0.029; Heart failure: HR 0.72 [95% CI, 0.53-0.99], p = 0.044; Myocardial infarction: HR 0.53 [95% CI, 0.33-0.86], p = 0.009; Atrial fibrillation/flutter: HR 0.68 [95% CI, 0.44-1.04], p = 0.073",
            "Other": "None",
            "Conclusion": "The use of SGLT2i was associated with a lower risk of mortality, hypertension, and cardiovascular events in patients with prostate cancer and T2DM undergoing LHRHa therapy."
        },
        {
            "Title": "The impact of glucagon-like peptide 1 agonists on anthracycline or human epidermal growth factor receptor 2 inhibitor-associated cardiotoxicity in patients with breast cancer and diabetes mellitus.",
            "Indication": "Breast cancer with type 2 diabetes mellitus (T2DM)",
            "Drug": "Glucagon-like peptide 1 agonists (GLP1a)",
            "PFS": "None",
            "OS": "All-cause mortality: HR 0.67 [95% CI: 0.48-0.93], p = 0.015",
            "ORR": "None",
            "AE": "Major adverse cardiovascular events (MACE): HR 0.59 [95% CI: 0.41-0.86], p = 0.006; Incident heart failure and heart failure hospitalization: HR 0.56 [95% CI: 0.37-0.83], p = 0.004",
            "Other": "None",
            "Conclusion": "GLP1a were associated with a reduction in major adverse cardiovascular events, incident heart failure and heart failure hospitalization, and all-cause mortality among anthracycline or HER-2 inhibitor-treated patients with breast cancer and DM."
        },
        {
            "Title": "Cardiac and mortality outcomes in patients with breast cancer undergoing trastuzumab-based therapies.",
            "Indication": "Breast cancer",
            "Drug": "Trastuzumab",
            "PFS": "None",
            "OS": "All-cause mortality: Multi-line therapies were associated with a higher likelihood of all-cause mortality within 3 years (HR 5.0 [1.8-14.0]; p = 0.002) and 5 years (HR 3.8 [1.2\u20138.7]; p = 0.002) when compared to single-line therapy.",
            "ORR": "None",
            "AE": "MACE: Multi-line therapies were independently associated with higher MACE outcomes within 5 years compared to single-line therapy (Odds Ratio 5.2 [1.5-17.9]; p = 0.009).",
            "Other": "None",
            "Conclusion": "Multi-line trastuzumab therapy in breast cancer is associated with higher all-cause mortality after 1 year from starting treatment. Most importantly, multi-line therapies were identified as an independent risk factor for MACE, particularly heart failure and stroke, within 5 years. Therefore, survivors should be closely monitored for cardiac events after the first year of starting treatment and identified for early stroke prevention strategies."
        },
        {
            "Title": "Fasting mimicking diet reduces anti-OX40/anti-PD-L1 and anti-PD-1/anti-CTLA-4 cardiovascular side effects in preclinical melanoma models.",
            "Indication": "Melanoma",
            "Drug": "Anti-OX40/anti-PD-L1 and anti-CTLA-4/anti-PD-1",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Cardiac fibrosis, necrosis, and hypertrophy prevented/reversed by FMD treatment",
            "Other": "Increased effects against lung cancer model, reduced oxidative stress and inflammation markers",
            "Conclusion": "FMD cycles in combination with immunotherapy can delay cancer growth while reducing side effects including cardiotoxicity. This study sets the stage for clinical trials aimed at assessing the ability of FMD to increase the efficacy of immunotherapy while reducing its side effects."
        },
        {
            "Title": "Molecular predictors and mechanisms of immune checkpoint inhibitor-induced myocarditis: A case-control study with translational correlates.",
            "Indication": "Immune checkpoint inhibitor-induced myocarditis",
            "Drug": "Immune checkpoint inhibitors (ICI)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Elevations of IP10, IL10, IL15, and IL13 at time of myocarditis; T cell and macrophage infiltration among cardiocytes; granulocyte accumulation within fibrotic tissue",
            "Other": "Elevation of blood neutrophil-to-lymphocyte ratio, alanine transaminase, and aspartate aminotransferase associated with ICI-myocarditis",
            "Conclusion": "This is one of the largest translational studies of ICI-myocarditis and controls. Our patients had improved clinical outcomes compared to those reported in the literature, which may be a result of our early screening. Our multiomics analyses of their biospecimens contribute novel data, such as spatial proteomics and transcriptomics analyses on heart tissue. Advancing our understanding of ICI-myocarditis will allow us to design screening strategies and more targeted treatments for ICI-myocarditis."
        },
        {
            "Title": "Predictive factors for cardiac events following concurrent chemotherapy and radiation with durvalumab consolidation for unresectable non-metastatic non-small cell lung cancer.",
            "Indication": "Unresectable non-metastatic non-small cell lung cancer (NSCLC)",
            "Drug": "Durvalumab",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Cardiac events in 25% of patients, including 15 new/worsening arrhythmia, 11 new/worsening cardiomyopathy, and 9 new/worsening coronary artery disease.",
            "Other": "Pre-existing arrhythmia, dyslipidemia, and number of pack years showed significant association with cardiac events. Cumulative dose of radiation was marginally significant. Patients on a statin had a slightly lower odds of cardiac event (OR 0.03, p=0.02).",
            "Conclusion": "Cardiac events following exposure to concurrent chemotherapy and Durvalumab may occur in up to 25% of patients with unresectable non-metastatic NSCLC. Further retrospective analysis of changes in coronary atherosclerotic plaque burden across PET-CT with ICI exposure and correlation with cardiac events is underway."
        },
        {
            "Title": "Efficacy and safety of immune checkpoint inhibitors in advanced solid tumors with hepatitis B infection.",
            "Indication": "Advanced solid tumors with hepatitis B infection",
            "Drug": "Immune checkpoint inhibitors",
            "PFS": "None",
            "OS": "No statistical difference in OS between HBV+ and HBV- patients (P=0.132). HBV+ HCC patients had worse OS than those who remained HBV- during ICIs.",
            "ORR": "None",
            "AE": "HBV+ had higher incidence of hepatic adverse events (hAE) (P<0.001) and severe hepatic adverse events (hSAE) (P=0.001) than HBV-. In HCC, HBV+ patients had more hAE (P=0.023) and hSAE (P=0.020).",
            "Other": "In HBeAg negative (HBeAg-) patients, HBV+ patients have a significantly higher incidence of hAE (P=0.003) and hSAE (P=0.001) than HBV-. In non-HCC, antiviral treatment brought more hAE (P=0.025), which existed in HBeAg- (P=0.029) and HBV- (P=0.047).",
            "Conclusion": "HBV+ patients have higher incidence of hAE and hSAE. In HCC, HBV+ patients are more likely to occur hAE and hSAE. HBV+ HCC patients had worse OS than those who remained HBV- during ICIs. For non-HCC patients with antiviral treatment, there was no difference in OS or AEs and SAEs, regardless of infection status and HBV status. In HBeAg+ HCC patients, there was no statistical difference in OS, hAE, and hSAE regardless of HBV activity. Antiviral treatment was associated with a higher incidence of hAE and hSAE irrespective of tumor, infection status, and HBV DNA status. In advanced HCC, 18.9% of HBV- patients turned HBV+, which not only have worse OS and greater hepatic toxicity. Dynamic monitoring of HBV-related serological indicators and liver enzymes is needed for hepatitis B patients during IO, and HBV reactivation is a sign of poor clinical prognosis."
        },
        {
            "Title": "PROSTOX, a signature of late GU toxicity after SBRT radiotherapy in MIRAGE, a prospective trial.",
            "Indication": "Late genito-urinary (GU) toxicity after SBRT radiotherapy",
            "Drug": "None (radiotherapy)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "PROSTOX predicted late GU toxicity with an AUC of 0.77 and 0.75 in the MRI and CT treated groups, respectively. Higher sensitivity (0.942, 0.927) and F1 score (0.933, 0.884), but lower specificity (0.600, 0.563) and NPV (0.667, 0.750).",
            "Other": "PROSTOX did not predict acute toxicity, but a unique genetic signature for acute toxicity was identified in the MRI-treated cohort (AUC=0.764). However, this signature was not as predictive in the CT-treated cohort (AUC=0.63). The GO analysis revealed 14 pathways enriched in the PROSTOX signature, notably all related to RNA, which appeared to fall into three main categories: RNA regulation, RNA processes, and transcription.",
            "Conclusion": "Our study successfully validated PROSTOX to accurately predict late GU toxicity in patients treated with SBRT, regardless of delivery method. PROSTOX does not predict acute GU toxicity, which appears to have a unique signature but may be impacted by treatment approach. Our GO analysis identified RNA pathways, related to the regulatory mechanisms that control RNA synthesis within the cell, including the biochemical processes necessary for the synthesis, modification, degradation, and turnover of RNA molecules in predicting late GU toxicity. These findings provide insight into potential molecular mechanisms contributing to radiation-induced late toxicity and could lead to therapeutic strategies in the future."
        },
        {
            "Title": "The utility of procalcitonin in febrile neutropenia.",
            "Indication": "Febrile neutropenia in oncology patients",
            "Drug": "Procalcitonin (PCT)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Median baseline and peak PCT levels were significantly greater in patients with confirmed infections. For every unit increase in the log-transformed baseline PCT, the odds of having a proven infection increased by 23%.",
            "Conclusion": "PCT levels in cancer patients with FN are significantly associated with culture-positive infection. This data can help guide treatment and de-escalation of antibiotics in cancer patients admitted with FN. Further evaluation should be taken to determine how to best use PCT to determine the need for admission and antibiotic prescription."
        },
        {
            "Title": "The impact of body mass index (BMI) on overall survival (OS) among patients receiving immune checkpoint inhibitors (ICIs): A population-based study.",
            "Indication": "Solid tumors in patients receiving ICIs",
            "Drug": "Immune checkpoint inhibitors (ICIs)",
            "PFS": "None",
            "OS": "Median OS 317 days. Greater BMI was associated with better OS (aHR=0.98 per unit, 95% CI [0.97-0.99] p<0.001). Obese (aHR=0.77 [0.70-0.85] p<0.001) and overweight patients (aHR=0.85 [0.78-0.93] p<0.001) had better OS compared to normal BMI. Low BMI had poorer OS (aHR=1.39 [1.16-1.66] p<0.001).",
            "ORR": "None",
            "AE": "None",
            "Other": "Among melanoma patients, obese had better OS (aHR=0.84 [0.71-0.98] p=0.03) and low BMI had poorer OS (aHR=1.80 [1.20-2.69] p=0.004). For lung and renal patients, increased BMI was associated with better OS when BMI was evaluated continuously (aHR=0.99 per unit [0.98-1.00] p=0.05; aHR=0.98 per unit [0.95-0.99] p=0.04). Among males, obese (aHR=0.70 [0.62-0.80] p<0.001) and overweight (aHR=0.80 [0.72-0.90] p<0.001) had better OS compared to normal BMI, while low BMI had poorer OS (aHR=1.58 [1.22-2.06] p<0.001). Among females, low BMI was associated with poorer OS (aHR=1.29 [1.01-1.65] p=0.04).",
            "Conclusion": "BMI was identified as a potential prognostic factor among cancer survivors receiving ICIs where greater BMI was associated with better OS. This association varied by cancer type and sex and is particularly notable in melanoma and among males. Further studies understanding these prognostic associations are warranted."
        },
        {
            "Title": "In-hospital outcomes and mortality of drug-induced pneumonitis and radiation pneumonitis in patients with thoracic cancer.",
            "Indication": "Thoracic cancer with drug-induced pneumonitis (DP) and radiation pneumonitis (RP)",
            "Drug": "Various cancer therapies including targeted agents, systemic chemotherapy, immune-checkpoint inhibitors, and radiation therapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "In-hospital mortality rate was highest in DP compared to other groups. Pneumonitis patients had higher odds of intubation (OR: 1.57; 95% CI: 1.24-1.99; p<0.05) and mortality (OR: 1.48; 95% CI: 1.28-1.71; p<0.05) than non-pneumonitis patients. Pneumonitis patients also had 23% longer length of stay than non-pneumonitis patients.",
            "Other": "The trend for DP significantly increased over the study years, whereas the RP trend was stable.",
            "Conclusion": "Hospitalized pneumonitis patients had relatively poor clinical outcomes compared to patients without pneumonitis. The trend of hospitalized drug-induced pneumonitis is increasingly common."
        },
        {
            "Title": "Head and neck cancer survivors: Long-term nutrition impact symptoms, eating problems, and barriers to care impact psychosocial health.",
            "Indication": "Head and neck cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Prevalence of side effects was independent of stage of cancer and time since initial diagnosis. 42% of long-term survivors reported still not eating a solid food diet. 63% ever used a feeding tube. 62% of long-term survivors reported still feeling self-conscious when eating.",
            "Conclusion": "Although head and neck cancers are generally considered 'curable,' the nature of the disease and the required aggressive treatment regimens leave long-term survivors with a broad range of common physical and psychosocial issues that are rarely addressed. Survey data highlight almost universal, long-term persistence of eating and nutrition problems which cause significant psychosocial impact over long-term survivors' lifetime."
        },
        {
            "Title": "Late mortality and chronic health conditions in long-term survivors of nasopharyngeal cancers: The 20-year follow-up of territory-wide Hong Kong Nasopharyngeal Cancer Survivor Study (HKNPCSS).",
            "Indication": "Nasopharyngeal cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "The 20-year cumulative all-cause mortality was 34.0% (95% CI, 29.1%-38.9%), with 1668 (59.9%) of 2785 deaths attributed to health-related causes.",
            "ORR": "None",
            "AE": "59.2% (n=4674) had severe, life-threatening, or fatal (grade 3-5) health conditions, with a 20-year cumulative incidence of 59.0% (95% CI, 56.4%-61.5%).",
            "Other": "The standardized mortality ratio (SMR) for nasopharyngeal cancer survivors compared to the general population was 3.81 (95% CI, 3.67-3.95), with the highest SMRs observed for pulmonary (SMR: 6.18, 95% CI, 5.73-6.64) and cardiovascular (SMR: 2.12, 95% CI, 1.90-2.36) causes. The adjusted hazard ratio (HR) of grade 3-5 chronic condition in nasopharyngeal cancer survivors, compared to healthy cohorts, was 7.02 (95% CI, 6.64-7.42).",
            "Conclusion": "Even 20 years after diagnosis, nasopharyngeal cancer survivors still face increased risks of late mortality and morbidity. The findings emphasize the need for a comprehensive survivorship program to improve outcomes for nasopharyngeal cancer survivors."
        },
        {
            "Title": "Impact of chemotherapy-induced peripheral neuropathy permanence on patients\u2019 preference to discontinue chemotherapy.",
            "Indication": "Breast or colorectal cancer",
            "Drug": "Taxane and/or platinum chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The odds a patient would prefer to discontinue chemotherapy treatment if their CIPN symptoms would be permanent (vs. temporary) were nearly 30 times greater (odds ratio (OR) = 30.15, 95% confidence interval (CI): 15.73-57.79, p < 0.001). There was a statistical trend toward preferring to discontinue chemotherapy treatment as CIPN symptom severity increased (OR = 1.09, 95% CI: 1.00-1.19, p = 0.063).",
            "Conclusion": "The potential for CIPN symptoms to be permanent has a huge effect on whether patients want to continue or discontinue chemotherapy treatment. Additional data is needed on the incidence and predictors of permanent CIPN. More importantly, shared decision-making tools that convey the risk of permanent CIPN, and the potential risk of discontinuing chemotherapy treatment, are needed to facilitate patient-centered treatment decisions so that each patient with cancer achieves their personal treatment goals."
        },
        {
            "Title": "Delayed immune-related adverse events in patients with advanced melanoma treated with immune checkpoint inhibitors.",
            "Indication": "Advanced melanoma",
            "Drug": "Immune checkpoint inhibitors (ICIs)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "A total of 142 delayed irAEs were recorded in 94 (30%) patients. Median time to onset of delayed irAEs was 20.0 months (12.2-80.6 months). The most common delayed irAEs were dermatologic (27%), gastrointestinal (23%), and rheumatologic (20%). Most delayed irAEs were grade \u22642 (84%), but some were grade \u22653 (16%).",
            "Other": "Of individual delayed irAEs, 75 (51%) occurred on ICI treatment at time of onset, 39 (26%) after \u22643 months of the last ICI cycle, and 34 (23%) after >3 months of the last ICI cycle. 79 (56%) and 20 (14%) delayed irAEs were treated with systemic steroids and immunomodulators, respectively. Further, 12 (8%) delayed irAEs led to hospitalization, and 64 (45%) required subspecialty consultation. Patients with a history of autoimmune disease were more likely to have a delayed irAE (p = 0.02), as were patients who achieved a partial or complete response (p = 0.02).",
            "Conclusion": "Delayed irAEs occurred in almost a third of patients with advanced melanoma treated with ICIs and followed for at least 1 year. Extended follow-up to monitor for delayed irAEs should be considered as many events required immunosuppressive therapy and, rarely, may be life-threatening."
        },
        {
            "Title": "Risk and impacts of drug-induced liver injury (DILI) due to immune-checkpoint inhibitors (ICI) on overall survival in patients with newly diagnosed cancer: A retrospective cohort study.",
            "Indication": "Newly diagnosed cancer",
            "Drug": "Immune-checkpoint inhibitors (ICI)",
            "PFS": "None",
            "OS": "Median survival time: 10.4 months; HR for all-cause mortality: 1.40 (95% CI: 1.15\u20131.70); Adjusted HR: 1.63 (95% CI: 1.34\u20131.99); Adjusted HR with HLD status: 1.57 (95% CI: 1.29\u20131.93)",
            "ORR": "None",
            "AE": "11% of patients developed DILI; Higher risk in patients with HLD (17.8% vs. 30.8%, Log-Rank P < 0.001)",
            "Other": "None",
            "Conclusion": "The findings highlight the significant risk and detrimental impact of DILI on overall survival in patients treated with ICIs. The incidence of DILI was significantly higher among patients with HLD, underscoring the elevated vulnerability of this subgroup. Vigilant monitoring and management of liver function in patients receiving ICIs, particularly those with pre-existing liver conditions, are needed to mitigate the risk of DILI and improve survival outcomes."
        },
        {
            "Title": "Physical activity and dexamethasone for cancer-related fatigue: A preliminary placebo-controlled randomized control trial.",
            "Indication": "Cancer-related fatigue in advanced cancer",
            "Drug": "Dexamethasone",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "No significant difference in grade \u22653 adverse events between the two arms (P = 0.36)",
            "Other": "Adherence to Dex and Pl were 91% and 92%, respectively; Satisfaction rates with PA+Dex and PA+Pl arms were 98% and 79%, respectively",
            "Conclusion": "Satisfaction, tolerability to the combination therapy, and medication adherence were excellent. PA+Dex significantly improved CRF at day 8 and 29. The improvement was sustained 3 weeks after discontinuation of Dex. Further larger studies are justified."
        },
        {
            "Title": "Efficacy of treatment with traditional Chinese patent medicine (Fufang E\u2019jiao Syrup) for cancer-related fatigue in patients with advanced cancer: A randomized, double-blinded, placebo-controlled, multicenter trial.",
            "Indication": "Cancer-related fatigue in advanced cancer",
            "Drug": "Fufang E\u2019jiao Syrup (FFEJS)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "No difference in adverse events occurrence between FFEJS and placebo groups (110 [20%] vs 122 [18%], P = .38)",
            "Other": "Adjusted mean difference for a favorable shift to a higher FACIT-F total score at week 6 comparing FFEJS with placebo was 2.77 points (97.36 vs 94.23 points; [95% CI, 0.56-4.96 points]; P = .02)",
            "Conclusion": "In this randomized clinical trial among advanced cancer patients with CRF, FFEJS reduced fatigue severity and improved quality of life compared to placebo. Research is needed to assess the mechanism, clinical effect, and long-term safety of FFEJS for CRF in advanced cancer patients."
        },
        {
            "Title": "The safety and efficacy of diclofenac sodium suppository as adjunctive opioids in the treatment of cancer pain: A prospective, multi-center, real-world study.",
            "Indication": "Cancer pain",
            "Drug": "Diclofenac sodium suppository",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "5 patients experienced any grade treatment-emergent adverse events (TEAEs). All TEAEs were grade 1-2. TEAE leading to treatment discontinuation occurred in one patient.",
            "Other": "The incidence of breakthrough pain (BP) was reduced on the second (20.0% vs 39.8%) and sixth days (14.8% vs 39.8%) of medication, and the difference was statistically significant (P < 0.001, P < 0.001). For patients with baseline BP, 56.2% and 66.9% achieved BP relief on the second and sixth days of medication, respectively. The mean NRS scores for the three visits (baseline, second day, sixth day) were 5.28 \u00b1 1.71, 3.73 \u00b1 1.71, and 2.91 \u00b1 1.88, respectively. The mean pain intensity at each visit was decreased compared to the baseline, and the difference was statistically significant (P < 0.001, P < 0.001).",
            "Conclusion": "This study demonstrated that diclofenac sodium suppository was an effective treatment option for managing breakthrough pain in patients with persistent cancer pain who required additional analgesic therapy. Moreover, diclofenac sodium suppository was found to be well tolerated when administered anally."
        },
        {
            "Title": "An anti-EpCAM x anti-CD3 bispecific antibody, M701, for the treatment of malignant ascites due to epithelial cancer: Interim results of a prospective randomized controlled phase II trial.",
            "Indication": "Malignant ascites due to epithelial cancer",
            "Drug": "M701",
            "PFS": "Median 54 vs 24 days, HR=0.39, 95% CI 0.21-0.72, p=0.001",
            "OS": "Median 113 vs 76 days, HR=0.56, 95% CI 0.31-1.03, p=0.0575",
            "ORR": "None",
            "AE": "Grade 3 or higher treatment-emergent AEs: 52% in Arm E and 57.5% in Arm C. Serious AEs: 38% in Arm E and 50% in Arm C. Cytokine release syndrome: 2 cases (both grade 2) in Arm E.",
            "Other": "6-month survival rates: 35.2% in Arm E and 15.8% in Arm C. Subgroup analysis showed significant OS benefit in gastric cancer patients (median 128 vs 64 days, HR=0.45, 95% CI 0.20-1.00, p=0.0438).",
            "Conclusion": "IP infusions of M701, based on systemic tumor therapy, were well tolerated and did not pose a higher risk compared to the control arm. Epithelial cancer patients with MA who received M701 treatment had longer PuFS and OS. These results are promising and support the pivotal trial of M701 as a novel treatment for MA."
        },
        {
            "Title": "Accuracy of medical oncology prognosis for patients with metastatic cancer evaluated for enrollment onto an ongoing randomized clinical trial.",
            "Indication": "Metastatic cancer",
            "Drug": "Optimal radiation therapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Prognosis accuracy: 56% overall, 68.4% for outpatients, 25% for inpatients",
            "Conclusion": "In patients with symptomatic metastatic spine disease, the estimated prognosis provided by Medical Oncology is often optimistic, as nearly half of patients assigned a prognosis of greater than three months failed to reach this threshold before experiencing death or hospice. These findings indicate that providers considering enrolling patients on clinical trials should expect a prognosis overestimation rate exceeding 40%. Consequently, a more heuristic approach to assessing patient prognosis may be necessary to avoid unwarranted prognostic optimism, particularly for inpatients. Such an approach could potentially provide a more compassionate and cost-effective management of these patients\u2019 remaining lifespan thereby optimizing quality-of-life."
        },
        {
            "Title": "Incidence of osteonecrosis of the jaw (ONJ) in the randomized non-inferiority phase III trial SAKK 96/12 REDUSE comparing denosumab (DN) administered every 4 weeks (q4w) versus every 12 weeks (q12w).",
            "Indication": "metastatic breast cancer (mBC) or metastatic castration-resistant prostate cancer (mCRPC)",
            "Drug": "denosumab (DN)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "ONJ in Arm A (q4w): 8.3%, ONJ in Arm B (q12w): 5.7%",
            "Other": "Time to first ONJ and/or tooth infection: HR 0.67; 95% CI 0.48-0.93",
            "Conclusion": "The observed ONJ rate of 8.3% is in line with the literature for patients who received denosumab q4w for over two years (mBC: 6.0%, mCRPC: 8.2%). Administration of DN q12w reduces the risk of ONJ and/or tooth infections substantially. The numerical difference of events to the standard arm as well as the time to first ONJ and/or tooth infection is clinically relevant with a risk reduction by 33%. Efficacy data for the primary endpoint time to first symptomatic skeletal event is eagerly awaited."
        },
        {
            "Title": "An Indian real-world experience of intravenous fosnetupitant-palonosetron (IV NEPA) in preventing delayed and extended delayed CINV.",
            "Indication": "Chemotherapy-Induced Nausea and Vomiting (CINV)",
            "Drug": "Intravenous Fosnetupitant-Palonosetron (IV NEPA)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "9.55% of patients experienced adverse events, with 95.83% of them being mild in nature. Common adverse effects included headache (2.25%) and injection site reactions (1.68%).",
            "Other": "Complete Response (CR) in delayed and extended delayed phases were 93.26% and 96.07% respectively. Complete Protection (CP) and Complete Control (CC) in delayed and extended delayed phases were 92.13% and 96.07%, and 81.46% and 90.45% respectively.",
            "Conclusion": "IV NEPA demonstrated high efficacy and good tolerability in a real-world Indian setting, exhibiting response rates of over 92% in both delayed and extended delayed phases in patients receiving HEC/MEC regimens."
        },
        {
            "Title": "Behavioraleconomicinterventionstoembedearlypalliativecareincommunity oncology (BE-EPiC): A pragmatic cluster-randomized trial.",
            "Indication": "Advanced cancer",
            "Drug": "Palliative care",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Rates of completed palliative care visits were 14.6% in the intervention arm vs. 8.1% in the control arm (adjusted hazard ratio 1.34 [95% CI 1.25-1.54], p<0.001). Patient-reported quality of life was greater in the intervention arm than the control arm (mean change in FACT-G score at 9 weeks: 6.56 [SD 8.9] intervention vs. -4.48 [SD 13.5] control; adjusted difference 11.4, p=0.05). Rates of intensive end-of-life care were similar in both control and intervention groups.",
            "Conclusion": "Default referrals to specialty PC among patients who met guideline-based criteria led to increases in completed palliative care visits and improved quality of life. Default PC referrals may be an effective strategy to improve access to early specialty palliative care in community oncology, although effect sizes were tempered by high opt-out rates."
        },
        {
            "Title": "Risk-stratified multidisciplinary ambulatory management of malignant bowel obstruction (MAMBO) program in women with advanced gynaecological cancers: A prospective study.",
            "Indication": "Malignant bowel obstruction in advanced gynaecological cancers",
            "Drug": "None",
            "PFS": "None",
            "OS": "The median OS in pts who had an episode of MBO was 5.7 months (95% CI, 3.6-8.4).",
            "ORR": "None",
            "AE": "None",
            "Other": "Proactive outpatient management by the nursing team resulted in symptom resolution in 93% of 'at-risk' pts, with 7% progressing to develop MBO during the first 4 weeks in the program. Throughout the study, 62% (n=57) of pts experienced MBO. At the onset of MBO, 81% (n=46) were platinum-resistant with a median of 4 prior lines of therapy (range, 0-11). While 93% of pts (n=53) required in-patient management, the median time for hospitalization durations within the first 30 and 60 days after MBO diagnosis were 7 (range, 0-30) and 12.5 (range, 0-57) days respectively. Ratio of days in hospital/days out hospital within 60 days, median (range): 0.3 (0-19). During MBO, systemic therapy was administered to 77% (n=44) of pts, predominantly with weekly paclitaxel (38%, n=21). Surgical intervention was performed in 11% of pts, included primarily diverting ileostomy in 4 pts. TPN was administered to 33% (n=19) of pts. MBO resolution occurred in 42% (n=24) pts within 60 days, with recurrent episodes in 11% (n=6) pts and MBO symptoms persisted in 58% (n=33).",
            "Conclusion": "This study confirms the feasibility of the ambulatory MBO management with pts managed mainly outside hospital during the 60 days with reduced hospitalization days."
        },
        {
            "Title": "Abuse potential and analgesic efficacy of different IV opioid infusion rates among patients hospitalized with cancer pain: A randomized crossover trial.",
            "Indication": "Cancer pain",
            "Drug": "IV hydromorphone",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Drowsiness and oxygen desaturation were the most common subjective and objective adverse events observed (up to 52% and 22% of all patients respectively). Drowsiness was more frequent in the standard group than the experimental group (50% vs 29% at 15 minutes [p=0.03], and 52% vs 31% at 60 minutes [p=0.03]).",
            "Other": "There was no significant difference in the mean (SD) peak DEQ-5 'drug liking' subscale between the faster standard and slower experimental infusion rates (24.00 vs 24.34, p=0.82). Similarly, no significant differences were observed with other DEQ-5 subscales. 92% and 94% of experimental and standard IV hydromorphone rate recipients respectively had clinical improvements in pain scores over 120 minutes (OR=0.67, 95% CI: 0.06-5.82, p=0.65). No significant differences were observed in NRS pain scores between the 2 groups at all time points.",
            "Conclusion": "The standard 2-minute IV hydromorphone bolus produced comparable abuse potential and analgesic effects but more sedation than an experimental 15-minute rate. The findings support a 2-minute bolus infusion rate for pain relief with relatively nominal abuse potential, but a slower 15-minute rate may be more desirable to minimize sedation and preserve cognition for critically important inpatient activities."
        },
        {
            "Title": "Proactive, automated monitoring of uncontrolled symptoms in prostate cancer survivors across the cancer control continuum.",
            "Indication": "Prostate cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Prevalence of SPPADE symptoms (sleep disturbance, pain, impaired physical function, anxiety, depression, fatigue) assessed using 0 to 10 numerical rating scales (NRS). Moderate symptoms (NRS score 4-6): fatigue 47%, impaired physical function 40%, sleep disturbance 32%, pain 27%, anxiety 22%, depression/emotional distress 20%. Severe symptoms (NRS score 7+): fatigue 17%, impaired physical function 12%, sleep disturbance 10%.",
            "Conclusion": "Proactive symptom monitoring identifies a high overall burden of moderate-to-severe symptoms, particularly fatigue and impaired physical function, among prostate cancer patients and survivors. Collaborative care approaches and symptom control trials are needed to mitigate and manage these burdensome symptoms."
        },
        {
            "Title": "Real-world quality analysis of palliative care utilization in patients with brain metastases at three academic institutes.",
            "Indication": "Brain metastases",
            "Drug": "None",
            "PFS": "None",
            "OS": "Median survival for patients seen by PC was 7.8 months compared to 8.3 months (p=0.056).",
            "ORR": "None",
            "AE": "None",
            "Other": "80% of patients in the group seen by PC enrolled with hospice care compared to 58% in the non-PC group (p=0.0002). 80% of patients had an AD on file in the group seen by PC compared to 56% in the non-PC group (p=0.0001). There was a trend for more ED visits/readmissions in the group seen by PC compared to the non-PC group (p=0.057).",
            "Conclusion": "In this real-world quality analysis, we found that there is under-utilization of formal PC consultation in patients with BMETs. There was no difference in survival between groups, indicating PC is not synonymous with end-of-life or hospice care. PC was associated with more patients completing ADs and more patients using hospice. Health systems and policy initiatives promoting palliative care utilization earlier in the disease course may support patient values and preferences at the end of life."
        },
        {
            "Title": "Effect of palliative care on economic burden in lung cancer admissions: Insights from the NIS database.",
            "Indication": "Lung cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "PC consultations in LC patients are associated with reduced total hospital charges ($74,399 vs. $84,233, adjusted difference: $6,874, p<0.0001) despite a slight increase in length of stay (7.6 vs. 5.9 days, p<0.0001). Increased discharges with home healthcare (30.3% vs. 24.6%, p<0.0001) and to nursing homes (26.2% vs. 10.8%, p<0.0001). Higher inpatient mortality rate in patients with PC (32%) versus without (3.2%) (p<0.0001).",
            "Conclusion": "PC consultations in LC patients are associated with reduced THC despite a slight increase in LOS. This finding emphasizes the economic and therapeutic value of PC in the treatment of LC, advocating for its earlier and broader integration. By addressing both symptom management and healthcare costs, palliative care can play a significant role in enhancing the comprehensive care of lung cancer patients, highlighting the need for overcoming existing barriers to its adoption."
        },
        {
            "Title": "Impact of steroids indication on the efficacy of immunotherapy in a multi-tumor cohort of patients: Time and dose-dependent evaluation",
            "Indication": "Advanced solid tumors",
            "Drug": "Immune checkpoint inhibitors (ICI)",
            "PFS": "None",
            "OS": "None",
            "ORR": "The ORR was significantly higher in pts who received ST for irAEs than in those treated with ST for other reasons both in D1-30 (40% vs 15.3%; p=0.028) and in D1-90 (42.6% vs 17%; p=0.001). Furthermore, the ORR was very similar to that of patients who did not receive any ST treatment (40% vs 37.8% in D1-30, and 42.6% vs 37.7% in D1-90). There was an inverse correlation between the CD of ST in D1-30 and the ORR (37.78% [no steroids], 29.63% [Q1], 21.42% [Q2], 11.11% [Q3] and 22.22% [Q4]). However, this association was not observed among patients receiving ST for irAEs during D1-30 (37.8% [no steroids], 100% [Q1], 0% [Q2], 33.3% [Q3] and 42.9% [Q4]; p=0.21) and in D1-90 (37.7% [no steroids], 0% [Q1], 41.7% [Q2], 42.9% [Q3] and 52.9% [Q4]; p=0.29).",
            "AE": "None",
            "Other": "None",
            "Conclusion": "Exposure to ST is associated with poorer ICI outcomes. Our study showed that the impact of ST is time- and dose-dependent. Interestingly, these results were not observed among patients exposed to ST due to irAEs, suggesting that immune toxicity may attenuate ST detrimental effects."
        },
        {
            "Title": "Patient-reported symptom interference with activity- and mood-related functioning prior to start of an early-phase oncology combination trial including immune checkpoint blockade",
            "Indication": "Early-phase oncology combination trial",
            "Drug": "Immune checkpoint blockade",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "This first, comprehensive and prospective examination of symptom interference with functioning at trial baseline found that worse symptom interference was linked to worse QOL, poorer clinical outcomes, and shorter trial duration. These findings suggest that baseline patient-reported symptom interference with functioning can serve as a potential biomarker of poor prognosis in investigational trials, and that trial patients may benefit from initiation of supportive care prior to trial start."
        },
        {
            "Title": "Novel genome-wide significant germline genetic variants associated with venous thrombo-embolism (VTE) and arterial embolism (ATE) risk in patients with ALK and ROS1 fusion non-small cell lung cancer (NSCLC)",
            "Indication": "Non-small cell lung cancer (NSCLC) with ALK and ROS1 fusions",
            "Drug": "None",
            "PFS": "None",
            "OS": "Shorter OS was observed in patients with VTE (HR=3.15, p<0.001) and ATE (HR=2.51, p=0.036), compared to those without.",
            "ORR": "None",
            "AE": "None",
            "Other": "Two novel GWAS gene peaks associated with risk of VTE/ATE: at Chromosome 6q15 and at 15p22 (TLN2 gene). Additional variants potentially associated with VTE/ATE in ALK/ROS1 fusion patients were found in: CTBP2, NALCN, WDR7, PAX7, ZNF385D, SORBS2, and CSMD1.",
            "Conclusion": "Two novel globally significant variants, associated with VTE/ATE, are unique to patients with ALK/ROS1 fusion NSCLC. If validated, these biomarkers may help identify ALK/ROS1 patients who are at highest risk of VTE/ATE who may benefit from prophylactic anticoagulation. Further investigation and clinical evaluation are warranted."
        },
        {
            "Title": "The adherence and response of a combined approach of online self-help cognitive behavioral therapy and phone-based psychological guidance among French patients with cancer with insomnia.",
            "Indication": "Insomnia in cancer patients",
            "Drug": "Online self-help CBT-I program (Insomnet)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Adherence rate: 74%, Behavior change: 79%, Insomnia remission rates: 34% at week 6, 46% at week 12, 50% at week 24, Insomnia no longer a problem for 48% at week 6, 63% at week 12, 66% at week 24",
            "Conclusion": "A combination of online self-help CBT-I with phone-based guidance showed satisfactory rates of program adherence, change behavior, and insomnia remission. However, some patient profiles such as those with lower digital skills and severe insomnia at baseline seemed to benefit less from this approach and may require further care intensification."
        },
        {
            "Title": "A decision support intervention for patients with advanced lung cancer amid growing therapeutic complexity: Results of a randomized controlled trial.",
            "Indication": "Advanced lung cancer",
            "Drug": "Decision aid including clarification of personal values accompanied by a decision-coaching session",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Decisional conflict in both groups (total mean score arm A 41.25 and arm B 38.65), PDMS showed most patients considered the intervention helpful (83.8% for preparing for the next visit, 81.6% for preparing to talk to the doctor about what matters most, 81.6% for knowing that the decision depends on what matters most), PICS-subscale 'patient information' showed a statistically significant difference between the two groups (arm A: 2.86 vs arm B: 3.12; p=0.048)",
            "Conclusion": "Patients with advanced lung cancer show a strikingly high decisional conflict. Despite successfully empowering perceived preparation for decision-making and decreasing information needs in the encounter with the physician, the intervention was not strong enough to significantly improve the decisional conflict. In a setting of constantly changing oncological information and increasing prognostic uncertainty, tailored support that flexibly adapts to individual needs in decision-making is urgently needed."
        },
        {
            "Title": "Association between psychological distress and treatment efficacy in patients with newly diagnosed SCLC.",
            "Indication": "Small cell lung cancer (SCLC)",
            "Drug": "Chemotherapy or immunotherapy",
            "PFS": "8.40 vs. 19.47 months; 95% CI 7.84-12.96; P=0.004",
            "OS": "None",
            "ORR": "50.9% vs. 72.7%; P=0.037",
            "AE": "None",
            "Other": "Elevated serum cortisol levels (P<0.001) observed in patients with psychological distress. Higher serum cortisol concentration (P=0.011) associated with poorer response to chemotherapy and immunotherapy.",
            "Conclusion": "Psychological distress is prevalent among patients with SCLC. Those experiencing psychological distress tend to exhibit poorer responses to chemotherapy and immunotherapy. The investigation uncovered a potential mechanism involving neuroendocrine hormone resistance to treatment modalities."
        },
        {
            "Title": "Coping in caregivers of patients with primary malignant brain tumors: A mediation analysis of a randomized controlled trial.",
            "Indication": "Primary malignant brain tumors",
            "Drug": "NeuroCARE (telehealth-based psychological intervention)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Improvements in anxiety symptoms, depression symptoms, and caregiving self-efficacy mediated by increased coping ability.",
            "Conclusion": "The beneficial effects of the intervention on caregivers\u2019 anxiety symptoms, depression symptoms, and self-efficacy were driven indirectly by caregivers\u2019 use of evidence-based coping skills learned in NeuroCARE. The identification of coping as an active intervention component is critical for optimizing the scalability and sustainability of NeuroCARE for this highly burdened and distressed PMBT caregiver population."
        },
        {
            "Title": "Patterns of immune-related adverse events (irAEs) in adolescent and young adult (AYA) patients with melanoma receiving immune checkpoint inhibitors (ICIs)",
            "Indication": "Melanoma",
            "Drug": "Immune Checkpoint Inhibitors (ICIs)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Any-grade irAEs: 60%, Grade III/IV irAEs: 21%, Combination of CTLA-4/PD-1 blockers: Any-grade: 70%, Grade III/IV: 33%, PD-1 blockers monotherapy: Any-grade: 50%, Grade III/IV: 9%",
            "Other": "Rechallenge with ICIs: 53% of patients, 71% had recurrent irAEs, 76% experienced irAEs again, 26% had Grade III/IV irAEs",
            "Conclusion": "In AYAs with melanoma who were treated with ICIs, irAE is one of the major reasons for treatment interruption. The incidence of irAEs in AYAs appears somewhat lower than the rates previously reported in registrational trials, though this should be interpreted with caution given the retrospective nature of the current study. Patients with prior irAEs have a higher risk of recurrent or new irAEs upon rechallenge, however, the majority were low grade without serious negative outcome."
        },
        {
            "Title": "Pooled analysis on characteristics of nausea and vomiting in patients receiving trastuzumab deruxtecan (T-DXd) in clinical studies",
            "Indication": "Various tumor types (HER2+ and HER2-low breast cancer, HER2+ gastric cancer, HER2-mutant non-small cell lung cancer)",
            "Drug": "Trastuzumab deruxtecan (T-DXd)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Nausea: Any-grade: 74.6%, Grade 3: 5.8%, Vomiting: Any-grade: 41.6%, Grade 3: 2.6%",
            "Other": "Most nausea and vomiting events were reported during the first 3 weeks of treatment and resolved. Appropriate prophylaxis of nausea and vomiting is a key management strategy and allows patients to benefit from longer treatment durations with T-DXd.",
            "Conclusion": "Most nausea and vomiting events were reported during the first 3 weeks of treatment and resolved. Appropriate prophylaxis of nausea and vomiting is a key management strategy and allows patients to benefit from longer treatment durations with T-DXd. Ongoing studies are exploring the implementation and impact of prophylaxis for T-DXd\u2013related emesis."
        },
        {
            "Title": "Impact of prophylactic laser therapy on mucositis in patients with head and neck cancer (HNC) undergoing radiotherapy in Brazil",
            "Indication": "Head and neck cancer (HNC)",
            "Drug": "Prophylactic laser therapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Cumulative incidence of any grade mucositis: 65%, Incidence of grade 3/4 mucositis: 28%, Average time from mucositis onset to resolution: 23 days",
            "Conclusion": "The study met its primary endpoint, demonstrating a statistically significant reduction in the cumulative incidence of any grade mucositis with prophylactic laser therapy, as compared to historical controls. The data support further evaluation of this strategy in randomized clinical trials."
        },
        {
            "Title": "Routine, prospective DPYD genotyping guided dose-individualisation for patients receiving fluoropyrimidines: Implementation, prevalence and patient safety outcomes from a multi-institutional clinical trial.",
            "Indication": "Fluoropyrimidine toxicity",
            "Drug": "Fluoropyrimidines",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "24% experienced FP related Grade 3/4 toxicity within 60 days (23% DPYD wildtype, 37% DPYD variant) versus 35% in the retrospective cohort.",
            "Other": "16% had FP related dose delays (16% DPYD wildtype, 12% DPYD variant) versus 25% retrospective cohort. 14% had FP related dose reductions (13% DPYD wildtype, 25% DPYD variant) versus 12% retrospective cohort. 13% had FP related hospital admissions (13% DPYD wildtype, 19% DPYD variant) versus 17% retrospective cohort. 1 death in the DPYD wildtype cohort was deemed potentially related to FP.",
            "Conclusion": "Prospective DPYD genotype guided dosing was feasible in clinical practice, with prevalence of variant alleles comparable to published literature. There was a modest reduction in risk of FP related severe toxicity, dose delays, and hospital admissions with DPYD guided dosing compared to a historical, untested cohort."
        },
        {
            "Title": "Phase I/II trial of gabapentin plus intranasal ketamine for the prevention and treatment of pain in patients undergoing radiotherapy for head and neck cancer.",
            "Indication": "Pain in head and neck cancer patients undergoing radiotherapy",
            "Drug": "Gabapentin plus intranasal ketamine",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Two DLTs were reported at DL4: grade 2 dizziness and grade 2 sedation.",
            "Other": "The MTD of NAS ketamine was 30mg TID combined with gabapentin 300mg.",
            "Conclusion": "The MTD of NAS ketamine was 30mg TID combined with gabapentin 300mg. The Phase II expansion is ongoing."
        },
        {
            "Title": "Organ-specific immune-related adverse events and survival in patients with cancer with immune checkpoint inhibitors.",
            "Indication": "Advanced cancer treated with immune checkpoint inhibitors",
            "Drug": "Immune checkpoint inhibitors",
            "PFS": "8.7 vs 4.8 months, HR: 0.63, p=0.003",
            "OS": "Not reached vs 17.8 months, HR: 0.48, p<0.001",
            "ORR": "24.1% vs 10.3%, p=0.005",
            "AE": "83 (34.9%) patients developed at least one irAE.",
            "Other": "Only skin and endocrine toxicities independently protected against OS and PFS. The first development of skin or endocrine toxicities as protective irAEs rather than other irAEs was an independent indicator for predicting OS (HR: 0.24, p<0.001) and PFS (HR: 0.43, p<0.001). A prognostic irAE score based on organ-specific irAEs was developed to show the effect of total protective irAEs on patient outcomes.",
            "Conclusion": "Not all irAEs are associated with prolonged survival. Identification of organ-specific irAEs but not other irAE characteristics is useful for stratifying patients who actually respond to and benefit from ICIs across different cancer types."
        },
        {
            "Title": "Acupuncture as a modality of treatment for chemotherapy-induced peripheral neuropathy in breast cancer: A phase 3 randomized controlled trial (ABC-CIPN)",
            "Indication": "Chemotherapy-induced peripheral neuropathy (CIPN) in early breast cancer (EBC)",
            "Drug": "Acupuncture with usual care including pregabalin and duloxetine",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Significant improvement in CIPN sensory score (SS), pain symptom score (PSS), total pain score (TPS), and overall quality of life (QoL) in the test arm compared to the control arm.",
            "Conclusion": "Early breast cancer women with CIPN after (neo)adjuvant taxane therapy experienced a significant and clinically meaningful improvement in taxane-induced CIPN related quality of life as a result of an 8-week acupuncture protocol. The results are potentially practice-changing and merit consideration as a new standard of care globally in this unmet need."
        },
        {
            "Title": "Direct oral anticoagulant versus low molecular weight heparin for venous thromboembolism in patients receiving immune checkpoint inhibitors",
            "Indication": "Venous thromboembolism (VTE) in cancer patients receiving immune checkpoint inhibitors (ICIs)",
            "Drug": "Direct oral anticoagulants (DOACs) vs. low molecular weight heparin (LMWHs)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Lower risk of intracranial hemorrhage and all-cause mortality with DOACs compared to LMWHs. No detectable differences in the risk of gastrointestinal bleeding between the two groups.",
            "Other": "DOACs were associated with a similar risk for subsequent VTE events but a lower risk of intracranial bleeding as well as an improvement in mortality compared to LMWHs among ICI-treated patients with a diagnosis of VTE.",
            "Conclusion": "DOACs were associated with a similar risk for subsequent VTE events but a lower risk of intracranial bleeding as well as an improvement in mortality compared to LMWHs among ICI-treated patients with a diagnosis of VTE. Prospective trials are needed to validate these findings."
        },
        {
            "Title": "Effect of enhanced recovery after radiotherapy (ERAR) on the quality of life in patients with nasopharyngeal carcinoma after radiotherapy: A randomized controlled trial",
            "Indication": "Nasopharyngeal carcinoma (NPC)",
            "Drug": "Enhanced Recovery After Radiotherapy (ERAR) interventions",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Significant improvements in Hospital Anxiety and Depression Scale (HADS) anxiety and depression, distress thermometer, quality of life, NRS2002, weight loss, and Oral Health Impact Profile-14 (OHIP-14) scores in the ERAR group compared with the conventional care group.",
            "Conclusion": "The ERAR pathway has a significant benefit on reducing acute radiation-induced toxicity in patients with LA-NPC. It is hoped that this study will provide a reference for clinicians and promote the implementation of a standardized protocol for rapid recovery from radiotherapy."
        },
        {
            "Title": "Deep mapping of the cytokine release syndrome inflammatory continuum: Patterns and risk factors of immune-related adverse events in a real-world cohort with lung cancer receiving immunotherapy.",
            "Indication": "Lung cancer",
            "Drug": "Immune checkpoint inhibitors (ICIs), Pembrolizumab, Tocilizumab (TCZ)",
            "PFS": "None",
            "OS": "Cluster 1 (C1, n=7): 2-year OS of 100%, Cluster 2 (C2, n=17): 2-year OS of 48.49% (HR=4.025 [95% CI = 1.052-15.39], p=0.0419), Cluster 3 (C3, n=3): 6-month OS of 0% (HR=111.4 [95% CI=6.63-1871], p=0.0011) and (HR=30.04 [95% CI=2.686-335.9], p=0.0256)",
            "ORR": "None",
            "AE": "50 irAEs occurred in 39 patients. Most common irAEs: endocrinopathies (34%), pneumonitis (20%), dermatitis (14%), gastrointestinal toxicity (14%)",
            "Other": "70% of irAEs were grade 1 or 2. 44% of irAEs treated with immunosuppression, 38% referred to specialist care, 30% required hospitalizations. 56% of irAEs resolved within 12 months, 67.9% of those were rechallenged with ICIs.",
            "Conclusion": "Our study described the real-world patterns of irAEs in LC patients. SCLC was found to be an independent risk factor of irAEs in our cohort. In NSCLC, younger age and PDL1 positivity were associated with irAEs occurrence. Future studies are required to validate these findings in larger samples."
        },
        {
            "Title": "The influence of sarcopenia and myosteatosis on severe laboratory toxicity and overall mortality in older adults with cancer receiving chemotherapy.",
            "Indication": "Cancer in older adults",
            "Drug": "Chemotherapy",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "132 severe laboratory toxicities occurred during the study period in 41 participants (35.7%). Sarcopenia was a significant predictor of severe laboratory toxicity (adjusted odds ratio (aOR)=3.19, 95% CI=1.19 to 8.52, p=0.012).",
            "Other": "Myosteatosis was significantly associated with overall mortality (adjusted hazard ratio (aHR)=2.51, 95% CI=1.12-5.61, p=0.025).",
            "Conclusion": "Sarcopenia may be used to inform the risk of severe laboratory toxicity in older adults prior to chemotherapy. Pre-treatment myosteatosis defined by low SMD predicts overall mortality in the same cohort. Optimization of skeletal muscle health through targeted exercise and nutritional interventions may improve patient quality of life and clinical outcomes in older adults with cancer receiving chemotherapy."
        },
        {
            "Title": "Early combination with mycophenolate mofetil for immune-related hepatitis in patients with solid tumors treated with immune checkpoint inhibitors.",
            "Indication": "Immune-related hepatitis in patients with solid tumors",
            "Drug": "Mycophenolate mofetil (MMF)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "ALT improvement at day 7: Early combination group (72.3%), Late combination group (41.7%). Total systemic steroid dosage: Early combination group (median: 2120 mg), Late combination group (median: 4005 mg).",
            "Conclusion": "MMF was effective for both steroid-refractory and steroid-resistant ir-hepatitis. Early combination with MMF in addition to systemic steroids can lead to a more rapid improvement of ir-hepatitis compared to late combination with MMF, consequently reducing the total systemic steroid dosage."
        },
        {
            "Title": "AI-based radiomics model for predicting immune checkpoint inhibitor\u2013related pneumonitis (CIP) in patients with advanced NSCLC: An external validation study.",
            "Indication": "Immune checkpoint inhibitor-related pneumonitis (CIP) in advanced non-small cell lung cancer (NSCLC)",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The cross-validated AUC on D1 was 0.76 (95% CI: 0.62-0.90). The AUC was 0.61, 0.62, and 0.64, respectively, on D2, D3, and all validation data combined (D2+D3). The model correctly identified 71.4% of grade 1/2 CIP events and 33.3% of grade 3 CIP, with a corresponding false positive rate of 33.0%.",
            "Conclusion": "We demonstrated a radiomic AI signature could identify patients at risk of CIP prior to treatment across multiple external institutions. This approach could improve the identification and management of CIP in NSCLC patients on immunotherapy, thereby enhancing patient outcomes. Expanded training and validation to account for rare, but possibly fatal, high-grade CIP is needed to expand the clinical benefit of radiologic predictors of pneumonitis to the most severe cases."
        },
        {
            "Title": "Association of a germline single nucleotide polymorphism (SNP) in the interleukin-7 (IL7) gene with immune-related adverse events (irAEs) in the CheckMate025 trial.",
            "Indication": "metastatic renal cell carcinoma (mRCC)",
            "Drug": "nivolumab (NIVO) or everolimus (EVE)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "63 pts (33.3%) in the NIVO arm experienced at least 1 grade 2+ AE, compared to 78 pts (40.4%) in the EVE arm. The most common types of grade 2+ AEs were cutaneous (21.0%), hepatobiliary (16.8%) and endocrine (15.8%) in the NIVO arm, and respiratory (30.1%), cutaneous (22.3%) and gastrointestinal (19.4%) in the EVE arm. The rate of grade 2+ AEs was significantly higher in SNP+ vs. SNP- in the NIVO arm (HR=2.91 [1.48-5.72]), but not in the EVE arm (HR=0.63 [0.3-1.29], SNP\u00d7treatment interaction P=0.002). The rate of recurrent grade 2+ AEs was also significantly higher in SNP+ vs. SNP- in the NIVO arm (HR=3.43 [1.83-6.43]), whereas a trend for fewer recurrent grade 2+ AEs was seen in SNP+ vs. SNP- in the EVE arm (HR=0.46 [0.17-1.25], SNP\u00d7treatment interaction P=0.0005).",
            "Other": "None",
            "Conclusion": "The IL7 SNP (rs16906115) is associated with significantly higher rates of grade 2+ AEs, including recurrent events, in pts with mRCC treated with NIVO but not with EVE, with no effect on survival outcomes. These results affirm the SNP\u2019s predictive potential as a biomarker for irAEs to guide therapeutic decisions in pts treated with ICIs."
        },
        {
            "Title": "Pilot evaluation of portable scalp cooling for chemotherapy-associated alopecia in early-stage breast cancer.",
            "Indication": "Early-stage breast cancer",
            "Drug": "Amma portable scalp cooling system",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary efficacy endpoint of <50% hair loss achieved in 64% participants (n=9/14, [95% CI: 0.39, 0.89]).",
            "Conclusion": "Scalp cooling with the portable Amma system was both feasible and effective in this pilot study. Point estimates of efficacy were like those reported for PAXMAN (Dean\u2019s 0-2: 50%) and DigniCap (66%). A follow-up clinical trial is planned with a primary outcome of efficacy in patients receiving taxane-based chemotherapy (NCT06215469, PI: Rugo)."
        },
        {
            "Title": "Harmonization radiomics model to predict immune checkpoint inhibitor-related pneumonitis (CIP) in patients with non-small cell lung cancer (NSCLC).",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "Immune checkpoint inhibitors",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The model achieved a sensitivity of 0.98 (0.97, 0.99), a specificity of 0.08 (0.05, 0.14), a PPV of 0.91 (0.90, 0.91), and an NPV of 0.33 (0.23) for predicting CIP. The AUC of 0.59 (0.56, 0.66) indicates that the model may predict checkpoint inhibitor-associated pneumonitis with an accuracy of 59%.",
            "Conclusion": "The study provides insights into the potential of radiomic analysis coupled with AI algorithms and the use of harmonization model in predicting CIP among NSCLC patients treated with immunotherapy. However, larger studies are needed to validate our findings and the utility of harmonization models in predicting CIP."
        },
        {
            "Title": "Improving chemotherapy-induced alopecia for women receiving anthracycline plus taxane chemotherapy using the Paxman scalp cooling system at lower temperatures.",
            "Indication": "Stage I-III breast cancer",
            "Drug": "Paxman scalp cooling system",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "At week 4, 81% achieved successful hair preservation (17/21), and at week 8, over half maintained it (53%; 9/17). The majority had hair preservation at EOT (69%; 11/16). At 24 weeks post-chemotherapy, all patients demonstrated continued hair preservation (100%; 11/11) and all evaluated patients at 18 months post-treatment had grade 0 alopecia (100%, 4/4).",
            "Conclusion": "Patients successfully tolerated scalp cooling at lower temperatures, with no reported dose-limiting toxicities and successful hair preservation. This study indicates higher hair preservation rate at EOT (69%; 11/16) compared to studies using PSCS at -5\u00b0C. Post-EOT data shows successful hair regrowth with a higher average hair count than pre-treatment. A larger sample size is needed to assess the additional benefit of lower temperatures on hair preservation and regrowth."
        },
        {
            "Title": "Risk assessment of myelosuppression with PARP inhibitors in prostate cancer: Insights from FAERS.",
            "Indication": "Metastatic castration-resistant prostate cancer (mCRPC)",
            "Drug": "Olaparib, Niraparib, Rucaparib, Talazoparib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Myelosuppressive events: Olaparib (27.32%), Rucaparib (34.55%), Niraparib (56.76%), Talazoparib (72.34%). Anemia (45.94%), Leukopenia (18.17%), Thrombocytopenia (15.81%), Pancytopenia (15.07%), MDS/AML (5.02%).",
            "Other": "None",
            "Conclusion": "This is the first study specific to prostate cancer analyzing the relative rates of myelosuppressive events across different PARP inhibitors. Talazoparib exhibited the highest incidence of myelosuppression. This underscores the careful patient selection and vigilant monitoring needed in PARP inhibitors. Prompt recognition of myelosuppression is needed to optimize the safety and efficacy of PARP inhibitor therapies in prostate cancer."
        },
        {
            "Title": "Risk assessment of cardiovascular adverse events with BTK inhibitors in hematological malignancies: Insights from FAERS.",
            "Indication": "Chronic lymphocytic leukemia (CLL) and non-Hodgkin lymphomas (NHL)",
            "Drug": "Ibrutinib, Acalabrutinib, Zanubrutinib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "Cardiac Arrythmia: Ibrutinib 6.64%, Acalabrutinib 3.12%, Zanubrutinib 2.29%; Atrial fibrillation: Ibrutinib 5.13%, Acalabrutinib 2.15%, Zanubrutinib 1.77%; Ventricular Tachycardia: Ibrutinib 0.33%, Acalabrutinib 0.12%, Zanubrutinib 0.07%; Ventricular fibrillation: Ibrutinib 0.15%, Acalabrutinib 0.02%, Zanubrutinib 0.00%; MACE: Ibrutinib 2.79%, Acalabrutinib 2.40%, Zanubrutinib 1.99%; Pericardial event: Ibrutinib 0.72%, Acalabrutinib 0.21%, Zanubrutinib 0.44%",
            "Other": "None",
            "Conclusion": "Ibrutinib has demonstrated a higher incidence of toxicity, especially in cardiac arrythmias, while smaller but significant differences exist in other toxicities, including major cardiovascular events and pericardial effusion or hemorrhage. Patient selection and toxicity monitoring are important aspects of the care of these patients, who stay on these therapies for indefinite periods."
        },
        {
            "Title": "The CROWN study: Cardiac outcomes with near-complete estrogen deprivation.",
            "Indication": "High/intermediate risk hormone receptor (HR)-positive breast cancer (BC)",
            "Drug": "Ovarian function suppression (OFS) and an aromatase inhibitor (AI)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary objective is to determine the 24-month difference in stress myocardial blood flow as measured by adenosine CMR imaging in both groups. Correlation of CMR imaging with CCTA detail of coronary plaque changes is planned.",
            "Conclusion": "The relevance of pre-existing risk factors on study outcomes, including an emphasis on race and dynamic change in modifiable and treatment-related risk factors will also be assessed. Retention will be a key component of this study as analysis of the primary and secondary endpoints are dependent on completion of imaging."
        },
        {
            "Title": "Telehealth cognitive-behavioral therapy for cancer-related cognitive impairment: 2024 update of a model for remote clinical trial participation.",
            "Indication": "Cancer-related cognitive impairment (CRCI) in breast cancer survivors",
            "Drug": "Memory and Attention Adaptation Training (MAAT)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "None",
            "Conclusion": "The current RCT demonstrates utility, efficiency, and cost-savings of telehealth and remote data capture technology in the conduct of cancer control research. Methods described here can also be adopted for cancer therapeutic trials. Comprehensive cancer centers, where most clinical trials are based, can enhance participation of remote and/or underserved populations that have higher rates of cancer, more disease burden, and less opportunity for trial participation."
        },
        {
            "Title": "Enhancing prostate cancer diagnosis communication: A multicenter study evaluating the impact of animated videos, CartDiag Prostate study.",
            "Indication": "Advanced or metastatic prostate cancer",
            "Drug": "None",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The main objective of our study is to assess the effect of the announcement system, reinforced by animated videos, on patients\u2019 overall understanding of their diagnosis and treatment.",
            "Conclusion": "The findings will support the potential value of integrating visual aids into the diagnostic disclosure consultation to enhance patient understanding and promote informed decision-making."
        },
        {
            "Title": "Comparison of prophylactic effects for chemotherapy-induced neutropenia between same-day vs next-day administration of pegteograstim in chemotherapy regimen composed of day 1 intensive myelosuppressive agent: A randomized phase III trial (CONCISE, KCSG PC22-11)",
            "Indication": "Chemotherapy-induced neutropenia",
            "Drug": "Pegteograstim",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: duration of Grade 4 CIN (cycle 1); Secondary endpoints: incidence of Grade 3-4 CIN (cycle 1), severity of CIN (cycle 1), time to recovery ANC 1000/\u3395 (cycle 1), incidence of febrile neutropenia (FN), CIN-related dose delay, and dose intensity.",
            "Conclusion": "Currently recruiting patients since Nov 2022. Clinical trial information: KCT0007694."
        },
        {
            "Title": "Role of celecoxib in reducing cognitive impairment in patients receiving definitive chemoradiation for head and neck carcinoma: A phase II randomized placebo controlled trial (CELCI-HN study)",
            "Indication": "Head and neck squamous cell carcinoma (HNSCC)",
            "Drug": "Celecoxib",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary objective: determine the activity of celecoxib versus placebo in reducing cognitive decline in patients undergoing definitive CTRT with or without induction chemotherapy in HNSCC through patient-reported outcome tools (FACT cog questionnaire). Secondary objectives: assessment of adverse event profile (measured using CTCAE v5.0), objective assessment of neurocognitive decline using neuropsychological battery of tests, predictors of cognitive decline, and evaluation of inflammatory biomarkers.",
            "Conclusion": "Clinical trial information: CTRI/2022/09/045579."
        },
        {
            "Title": "A randomized phase III clinical trial of acupuncture for chemotherapy-induced peripheral neuropathy treatment (ACT)",
            "Indication": "Chemotherapy-induced peripheral neuropathy (CIPN)",
            "Drug": "Acupuncture",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: pain outcome at 12 weeks post-randomization between EA vs. SA. Secondary endpoint: week 24. Quantitative sensory testing to assess changes in sensory function.",
            "Conclusion": "Currently transitioning this trial to a multicenter trial. As of January 18, 2024, 133 of the planned 250 participants have been enrolled. Clinical trial information: NCT04917796."
        },
        {
            "Title": "A randomized phase III clinical trial of yoga for chemotherapy-induced peripheral neuropathy treatment (YCT)",
            "Indication": "Chemotherapy-induced peripheral neuropathy (CIPN)",
            "Drug": "Yoga",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary outcome: pain and balance symptoms at eight weeks post-randomization between yoga vs. EC. Secondary outcomes: functional improvements by functional reach, timed up-and-go, and chair-to-stand tests. Quantitative sensory testing to assess changes in sensory function.",
            "Conclusion": "Currently transitioning this trial to a multicenter trial. As of January 18, 2024, 104 of the planned 268 participants have been enrolled. Clinical trial information: NCT05121558."
        },
        {
            "Title": "Randomized, placebo-controlled trial of atorvastatin to prevent hearing loss in patients with head and neck squamous cell carcinoma receiving cisplatin-based chemoradiation (CRT).",
            "Indication": "Head and neck squamous cell carcinoma (HNSCC)",
            "Drug": "Atorvastatin",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: Incidence of CTCAE grade \u22652 hearing loss at 3 months after CRT. Secondary endpoints: Other treatment-related grade 3-4 adverse events, overall survival, and disease-free survival.",
            "Conclusion": "None"
        },
        {
            "Title": "Acupuncture for preventing progression of taxane-induced peripheral neuropathy (ATP): A phase II randomized, placebo-controlled trial.",
            "Indication": "Chemotherapy-induced peripheral neuropathy (CIPN) from taxane-based chemotherapy in breast cancer",
            "Drug": "Acupuncture (Real vs. Sham)",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary aim: Evaluate the effectiveness of RA versus SA in preventing taxane-induced peripheral neuropathy progression as measured by Neuropathic Pain Scale (NPS). Secondary aim: Evaluate the effectiveness of RA versus SA on chemotherapy relative dose intensity (RDI) and CIPN-related chemotherapy discontinuation.",
            "Conclusion": "None"
        },
        {
            "Title": "Guard01: A randomized, multicentre, controlled trial on the prophylactic use of efbemalenograstim alfa for concurrent chemo-radiotherapy induced neutropenia.",
            "Indication": "Concurrent chemo-radiotherapy induced neutropenia in various advanced-stage malignant tumors",
            "Drug": "Efbemalenograstim alfa",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary endpoint: Incidence of grade \u22653 neutropenia (ANC < 1.0 x 10^9/L) during concurrent chemo-radiotherapy.",
            "Conclusion": "None"
        },
        {
            "Title": "Preventing chemotherapy-induced peripheral neuropathy with acupuncture, a multinational parallel randomized controlled trials project (PACT).",
            "Indication": "Chemotherapy-induced peripheral neuropathy (CIPN) in breast cancer patients undergoing taxane-based chemotherapy",
            "Drug": "Acupuncture",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "Primary outcome measure: Change in CIPN severity, assessed using the EORTC QLQ-CIPN20 sensory subscale, between baseline and week 12. Secondary outcomes: Occurrence of CIPN and maximum CIPN scores, pain, sleep quality, and overall quality of life.",
            "Conclusion": "None"
        },
        {
            "Title": "LOOP: An observational, prospective, multicenter study of patients with non-small cell lung cancer in the US receiving standard-of-care and initiating an approved therapy with risk of pneumonitis/ILD.",
            "Indication": "Non-small cell lung cancer (NSCLC)",
            "Drug": "FDA-approved immune checkpoint inhibitor (ICI), antibody-drug conjugate (ADC), or small molecule EGFR inhibitor",
            "PFS": "None",
            "OS": "None",
            "ORR": "None",
            "AE": "None",
            "Other": "The primary objective is to enable the development and refinement of an algorithm including documentation of its performance (specificity, sensitivity, and odds ratio) to inform risk of pulmonary/respiratory/thoracic-related events including ILD. A secondary objective proposes to characterize the risk factors, signs, and symptoms leading to onset, diagnosis, and treatment of pneumonitis/ILD in patients receiving SoC.",
            "Conclusion": "The study is currently enrolling; the protocol was approved by a central IRB."
        },
        {
            "Title": "A phase Ib/IIa trial to evaluate the safety and efficacy of PM8002, a bispecific antibody targeting PD-L1 and VEGF-A, as a monotherapy in patients with advanced NSCLC.",
            "Indication": "Advanced Non-Small Cell Lung Cancer (NSCLC)",
            "Drug": "PM8002",
            "PFS": "{'Cohort6': {'treatment_naive': '10.9 months (95% CI: 6.8, -)', '6mPFS': '82.4% (95% CI: 54.7, 93.9)'}, 'Cohort7': {'prior_EGFR_TKI': '4.9 months (95% CI: 2.8, 9.8)', '6mPFS': '43.8% (95% CI: 27.2, 59.2)'}, 'NSCLC_IO_and_PBC_treated': {'6.7 months (95% CI: 1.2, -), 6mPFS: 62.5% (95% CI: 22.9, 86.1)'}}",
            "OS": "None",
            "ORR": "{'Cohort6': '47.1% (95% CI: 23.8, 72.2)', 'Cohort7': '19.4% (95% CI: 8.7, 36.0)', 'NSCLC_IO_and_PBC_treated': '12.5% (95% CI: 0.1, 52.7)'}",
            "AE": "85.2% of patients experienced any-grade treatment-related adverse events (TRAEs), with 18% experiencing Grade 3 or higher TRAEs. Immune-related AEs occurred in 37.7% of patients. Serious adverse events (SAEs) and treatment-related SAEs were observed in 23% and 9.8% of patients, respectively.",
            "Other": "The most common TRAEs (\u226515%) were hypertension, hypothyroidism, proteinuria, hypoalbuminemia, hypocalcemia, aspartate aminotransferase increase, and lactate dehydrogenase increase. 8.2% of patients discontinued PM8002 due to TRAEs.",
            "Conclusion": "PM8002 showed preliminary antitumor activity and acceptable safety in patients with advanced NSCLC. Monotherapy and combination trials with chemotherapy for different indications are still ongoing."
        }
    ]
}