import { Select } from 'antd';

const StudyTypeSelect = ({ name, defaultValue = [], onChange }) => {
  const STATUS = [ 'EXPANDED_ACCESS', 'INTERVENTIONAL', 'OBSERVATIONAL' ];
  return (
    <>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-primary-content"
      >
        Study Types
      </label>
      <Select
        mode="multiple"
        style={{
          width: '100%',
        }}
        placeholder={'Select Study Types'}
        defaultValue={defaultValue}
        onChange={(value) => onChange(value)}
        notFoundContent={null}
        options={STATUS.map((filter) => ({
          label: filter,
          value: filter,
        }))}
      />
    </>
  );
};

export default StudyTypeSelect;
