import DOMPurify from 'dompurify';
import MarkdownIt from 'markdown-it';
import MILA from 'markdown-it-link-attributes';
import MarkdownItKatex from 'markdown-it-texmath';
import ReactDOMServer from 'react-dom/server';

// create a custom link component with circle avatar
const CustomLink = ({ href, children }) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className="inline-flex cursor-pointer relative w-4 h-4 bg-blue-100 rounded-full items-center justify-center text-xs">
      {children}
    </a>
  );
};

const md = new MarkdownIt();
md.use(MarkdownItKatex, {
  delimiters: [ 'dollars', 'brackets' ],
});
md.use(MILA, {
  attrs: {
    target: '_blank',
  },
});

// // TODO: need optimization! better looking and UX
// // to customize the link ([1] => beautiful avatar like)
// md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
//   const href = tokens[idx].attrGet('href');
//   let content = '';

//   // Locate the content token between link_open and link_close
//   for (let i = idx + 1; i < tokens.length; i++) {
//     if (tokens[i].type === 'text') {
//       content = tokens[i].content; // Capture the link body content
//       tokens[i].content = ''; // Example modification
//       break;
//     }

//     if (tokens[i].type === 'link_close') {
//       break; // Stop processing once link_close is found
//     }
//   }

//   console.log('Link content:', content);

//   // Render custom React component
//   const customLink = ReactDOMServer.renderToString(
//     <CustomLink href={href}>{content}</CustomLink>
//   );

//   // Return the custom link component as a string
//   return customLink;
// };

// md.renderer.rules.link_close = function (tokens, idx, options, env, self) {
//   // Since the custom link includes closing tags, return an empty string
//   return '';
// };

// md.renderer.rules.paragraph_open = function (tokens, idx, options, env, self) {
//   return '<p style="display: inline;">'; // Render paragraphs as inline
// };
function processMarkdownToCleanHTML(text) {
  if (!text) return null;
  // Ensure HTTPS in Markdown URLs and handle line breaks for Markdown rendering
  const processedText = text
    .replace(/\[.*?\]\((\S+?)\)/g, (match, url) => {
      // Prepend 'https://' if URL lacks protocol
      if (!/^https?:\/\//i.test(url)) {
        return match.replace(url, `https://${url}`);
      }
      return match;
    })
    .replace(/\n/g, '  \n'); // Handle Markdown line breaks
  
  // Sanitize and render the Markdown text to HTML
  // Placeholder for DOMPurify, assuming it's available in your environment
  const cleanHTML = DOMPurify.sanitize(md.render(processedText), {
    ADD_ATTR: [ 'target' ], // Specify any additional attributes you want to allow through DOMPurify
  });
  
  return cleanHTML;
}

export { processMarkdownToCleanHTML };