import { Select } from 'antd';

import AndOrInput from './common/and-or-select';

const LocationSelect = ({ name, defaultValue, onChange }) => {
  const options = [ 'Georgia', 'Peru', 'Nicaragua', 'Norway', 'Tunisia', 'Panama', 'Dominican Republic', 'El Salvador', 'Egypt', 'Iceland', 'Finland', 'Jamaica', 'Denmark', 'Montserrat', 'Canada', 'Malaysia', 'Mongolia', 'Oman', 'Honduras', 'Italy', 'Virgin Islands (U.S.)', 'Bolivia', 'France', 'Philippines', 'Tanzania', 'Israel', 'Ukraine', 'Kenya', 'Cyprus', 'Bulgaria', 'United Arab Emirates', 'Lebanon', 'Bangladesh', 'Brazil', 'Nigeria', 'Grenada', 'Macedonia, The Former Yugoslav Republic of', 'Croatia', 'Bosnia and Herzegovina', 'Zambia', 'Belarus', 'Hong Kong', 'Ireland', 'Indonesia', 'Sri Lanka', 'Puerto Rico', 'Nepal', 'Botswana', 'Mauritius', 'Sierra Leone', 'Turkey', 'Russian Federation', 'South Africa', 'Portugal', 'Former Serbia and Montenegro', 'New Zealand', 'Estonia', 'Australia', 'Ethiopia', 'Mexico', 'Swaziland', 'Malawi', 'Korea, Republic of', 'Belgium', 'Morocco', 'Ecuador', 'Singapore', 'Bahamas', 'Uganda', 'Netherlands Antilles', 'Sweden', 'Malta', 'Montenegro', 'Costa Rica', 'Greece', 'Romania', 'China', 'Zimbabwe', 'Monaco', 'Pakistan', 'Lithuania', 'Serbia', 'Vietnam', 'Venezuela', 'United States', 'Luxembourg', 'Albania', 'Colombia', 'Uruguay', 'Moldova, Republic of', 'Czech Republic', 'Liberia', 'Poland', 'Netherlands', 'Kuwait', 'Jordan', 'Kazakhstan', 'Congo, The Democratic Republic of the', 'Austria', 'Slovakia', 'Thailand', 'Switzerland', 'North Macedonia', 'Taiwan', 'Algeria', 'Cambodia', 'Hungary', 'Chile', 'Argentina', 'Paraguay', 'India', 'Andorra', 'United Kingdom', 'Germany', 'Qatar', 'Czechia', 'Spain', 'Cuba', 'Ghana', 'Guatemala', 'Slovenia', 'Latvia', 'Armenia', 'Japan', 'Saudi Arabia', 'Guinea' ]
    .sort().map((item)=>({ label: item, value: item }));


  return (
    <>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-primary-content"
      >
        Location
      </label>
      <AndOrInput
        name={name}
        onChange={onChange} // Use the memoized handleChange here
        defaultValue={defaultValue}
        placeholder={'Target name like Target 1'}
        options={options}
      />
      {/* <Select
        mode="multiple"
        style={{
          width: '100%',
        }}
        placeholder={'Select Location'}
        defaultValue={defaultValue}
        onChange={(value) => onChange(value)}
        notFoundContent={null}
        options={options}
      /> */}
    </>
  );
};

export default LocationSelect;