import React from 'react';

import { agents, BIOMEDICAL_TYPE, CLINICAL_TYPE } from '../const/agent';

export default function FileThumbnail({ upload, onDelete, allowDelete=true }) {
  const isImage = (file) => {
    const imageTypes = [ 'image/jpeg', 'image/png', 'image/gif' ];
    return imageTypes.includes(file?.type);
  };

  const handleFileClick = (file) => {
    if (file.upload) {
      window.open(file.upload, '_blank');
    }
  };

  const renderFileIcon = (file) => {
    if (isImage(file)) {
      return (
        <img
          src={URL.createObjectURL(file)}
          alt={file.name}
          className="h-10 w-10 rounded-md"
        />
      );
    } else {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 36 36"
          fill="none"
          className="h-10 w-10 flex-shrink-0"
          width="36"
          height="36"
        >
          <rect width="36" height="36" rx="6" fill="#FF5588"></rect>
          <path
            d="M19.6663 9.66663H12.9997C12.5576 9.66663 12.1337 9.84222 11.8212 10.1548C11.5086 10.4673 11.333 10.8913 11.333 11.3333V24.6666C11.333 25.1087 11.5086 25.5326 11.8212 25.8451C12.1337 26.1577 12.5576 26.3333 12.9997 26.3333H22.9997C23.4417 26.3333 23.8656 26.1577 24.1782 25.8451C24.4907 25.5326 24.6663 25.1087 24.6663 24.6666V14.6666L19.6663 9.66663Z"
            stroke="white"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            d="M19.667 9.66663V14.6666H24.667"
            stroke="white"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            d="M21.3337 18.8334H14.667"
            stroke="white"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            d="M21.3337 22.1666H14.667"
            stroke="white"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            d="M16.3337 15.5H15.5003H14.667"
            stroke="white"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
      );
    }
  };

  return (
    <div className="mx-2 mt-2 flex flex-wrap gap-2">
      <div className="group relative inline-block text-sm text-black/70 dark:text-primary-content/90">
        <div className="relative overflow-hidden rounded-xl border border-gray-200 dark:border-gray-600">
          {!upload?.id && (
            <div className="absolute inset-0 flex items-center justify-center text-primary-content">
              <svg width="120" height="120" viewBox="0 0 120 120" className="h-6 w-6">
                <circle
                  className="origin-[50%_50%] -rotate-90 stroke-gray-400"
                  strokeWidth="10"
                  fill="transparent"
                  r="55"
                  cx="60"
                  cy="60"
                ></circle>
                <circle
                  className="origin-[50%_50%] -rotate-90 transition-[stroke-dashoffset]"
                  stroke="currentColor"
                  strokeWidth="10"
                  strokeDashoffset="342.1194399759285"
                  strokeDasharray="345.57519189487726 345.57519189487726"
                  fill="transparent"
                  r="55"
                  cx="60"
                  cy="60"
                ></circle>
              </svg>
            </div>
          )}

          <div className="p-2 dark:bg-gray-600 w-60 cursor-pointer" onClick={() => handleFileClick(upload)}>
            <div className="flex flex-row items-center gap-2">
              <div className="relative h-10 w-10 shrink-0 overflow-hidden rounded-md">
                {renderFileIcon(upload)}
              </div>
              <div className="overflow-hidden">
                <div className="truncate font-medium">{upload?.name || upload?.oldname || 'doc'}</div>
                <div className="truncate text-gray-300">{isImage(upload) ? 'Image' : 'Document'}</div>
              </div>
            </div>
          </div>
        </div>

        {allowDelete && (
          <button
            onClick={onDelete}
            className="absolute right-1 top-1 -translate-y-1/2 translate-x-1/2 rounded-full border border-white bg-gray-100 p-0.5 text-primary-content transition-colors hover:bg-gray-300 hover:opacity-100 group-hover:opacity-100 md:opacity-0"
          >
            <span className="" data-state="closed">
              <svg
                stroke="black"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="icon-sm"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </span>
          </button>
        )}
      </div>
    </div>
  );
}
