import { useEffect, useRef, useState } from 'react';

import { ViewfinderCircleIcon } from '@heroicons/react/24/outline';
import Cookies from 'js-cookie';
// import { WebSocket } from 'mock-socket';
import {
  Navigate,
  redirect,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import {
  createThread,
  createThreadEmbedding,
  getDiscoverList,
  getHotTopic,
  getThreadMeta,
  getThreadTasks,
} from '../api.js';
import ArticleCard from '../Common/horizonNews.js';
import { i18n as i18nCommon } from '../Common/i18n/common.js';
import { useAlert } from '../utils/context/alert';
import { useHistory } from '../utils/context/history.js';
import { useLanguage } from '../utils/context/lang.js';
import { usePro } from '../utils/context/pro.js';
import { useUser } from '../utils/context/user.js';
import { getFinalAgent } from '../utils/helpers.js';

import { OneConversation } from './components/oneConversation.js';
import { OneRunningConversation } from './components/oneRunningConversation.js';
import SingleLineInput from './components/singleLineInput.js';
// import Slider from './components/slider/index.js';
// import PdfViewer from './components/slider/pdf.js';
import TextInput from './components/textInput.js';
import { i18n as agent_i18n, agents } from './const/agent.js';
import { exampleQuestions } from './const/exampleQuestions.js';
import { i18n } from './i18n/chat.js';

const langMap = {
  en: 'EN',
  zh: 'CN',
  jp: 'JP',
};


export function AskDetail() {
  const navigate = useNavigate();
  const showAlert = useAlert();
  const { showModal } = useUser();
  const location = useLocation();
  const { lang, selectLanguage } = useLanguage();
  const { answerLang, switchAnswerLang } = usePro();
  const [ followups, setFollowups ] = useState([]);
  const [ newAskId, setNewAskId ] = useState(null);
  const [ focusArea, setFocusArea ] = useState(null);
  const [ forceSwitchValue, setForceSwitchValue ] = useState(undefined);
  const [ reachLimit, setReachLimit ] = useState(false);
  const bottomRef = useRef(null);
  const chatContainerRef = useRef(null);

  const {
    needFetchLatest,
    initialData,
    runningTask,
    threadAgentType,
    hotTopics,
    hotArticles,
  } = useLoaderData();
  const [ autoScroll, setAutoScroll ] = useState(true);
  const [ latestQuestion, setLatestQuestion ] = useState(null);
  const [ isFollowupFetching, setIsFollowupFetching ] = useState(false);
  const [ isEmbedding, setIsEmbedding ] = useState(false);
  const [ runningAnswer, setRunningAnswer ] = useState(null);
  const { askId } = useParams(); // path: ask/askId

  const [ searchParams ] = useSearchParams(); // page intermit state, will be finalized as threadID

  // {"thread_name":"hello","agent":"mindsearchrefer","task":{"task_mode":"noahllm","question":"hello"},"context":{"reference":"workflow id", "reference_type":"workflow"}}
  const [ q, contextUUID, contextType ] = [
    searchParams.get('q'),
    searchParams.get('contextUUID'),
    searchParams.get('contextType'),
  ];
  const contextTitle = location.state?.title;
  const mockClient = useRef(null);
  const [ paddingBottom, setPaddingBottom ] = useState('pb-12');
  const [ isSocketOpen, setIsSocketOpen ] = useState(false);
  const latestAskIdRef = useRef(newAskId); // Create a ref for the newAskId state
  const { updateHistory } = useHistory();
  console.log('========askId', askId);
  // this register the query for fetch the latest conversation
  const threadId = newAskId || askId;

  // Update the ref every time newAskId changes
  useEffect(() => {
    latestAskIdRef.current = threadId;
  }, [ threadId ]);

  useEffect(() => {
    if (location.pathname === '/ask/' || location.pathname === '/ask') {
      // Redirect to the home page
      setNewAskId(null);
      setLatestQuestion(null);
      setFollowups([]);
      setRunningAnswer(null);
      setIsFollowupFetching(false);
      // alert('back');
    }
  }, [ location ]);

  // TODO: this might be moved to a context ziwen.
  useEffect(() => {
    let reconnectTimeout;
    let heartbeatInterval;
    const token = Cookies.get('noahAccessToken');
    const connectWebSocket = () => {

      const wsBaseURL = `wss://${
        window.location.host.includes('localhost')
          ? process.env.REACT_APP_API_URL.replace('http://', '').replace(
            'https://',
            ''
          ) // for local dev
          : window.location.host
      }/ws/chat/`;
      const wsFullPath = `${wsBaseURL}?token=${token}`;

      // Avoid reconnecting if the WebSocket is already open
      if (mockClient.current && mockClient.current.readyState === WebSocket.OPEN) {
        console.warn('WebSocket is already connected.');
        return;
      }

      mockClient.current = new WebSocket(wsFullPath);

      mockClient.current.onopen = () => {
        console.warn('Socket client connected to server.');
        setIsSocketOpen(true);

        // Send initial ping and start heartbeat
        mockClient.current.send(JSON.stringify({ type: 'ping' }));
        heartbeatInterval = setInterval(() => {
          if (mockClient.current.readyState === WebSocket.OPEN) {
            // console.log('ping 一下')
            mockClient.current.send(JSON.stringify({ type: 'ping' }));
          }
        }, 20000); // Send a ping every 30 seconds
      };

      mockClient.current.onclose = (error) => {
        console.log('WebSocket closed', error);
        setIsSocketOpen(false);
        setIsFollowupFetching(false);
        clearInterval(heartbeatInterval); // Clear the heartbeat interval

        // Reconnect after a delay
        reconnectTimeout = setTimeout(connectWebSocket, 5000);
      };

      mockClient.current.onerror = (error) => {
        console.warn('WebSocket error:', error);
        setIsSocketOpen(false);
      };

      mockClient.current.onmessage = (e) => {
        const result = JSON.parse(e.data);
        console.warn('Socket client received:', result);
        if(result == 1) {
          return;
        }
        if (!result.type || result.type === 'LIMIT') {
          showAlert('error', i18n.DAILY_LIMIT_ERROR[lang]);
          setIsFollowupFetching(false);
          setForceSwitchValue(false);
        } else if (result.type === 'CONTENT' && result.thread_id === latestAskIdRef.current) {
          setRunningAnswer(result);

          if (result.status === 'DONE') {
            setIsFollowupFetching(false);
            setRunningAnswer(null);

            setFollowups((prev) =>
              prev.some((item) => item.id === result.task_id)
                ? prev.map((item) =>
                  item.id === result.task_id ? result : item
                )
                : [ ...prev, result ]
            );
          }
        }
      };
    };

    connectWebSocket();

    return () => {
      clearTimeout(reconnectTimeout);
      clearInterval(heartbeatInterval); // Ensure cleanup on unmount
      if (mockClient.current) {
        mockClient.current.close();
        mockClient.current = null;
      }
    };
  }, []);

  // this resolves the situation when the initialData is loading
  useEffect(() => {
    if (needFetchLatest) {
      setIsFollowupFetching(true);
      setLatestQuestion(runningTask); // latestQuestion could from the loader data
    } else {
      setIsFollowupFetching(false);
      setLatestQuestion(null);
    }
  }, [ needFetchLatest, askId ]);

  // when a new thread is generated, update url and fetch the data
  useEffect(() => {
    if (newAskId) {
      window.history.replaceState(null, '', `/ask/${newAskId}`);
    }
  }, [ newAskId ]);

  // Add lastScrollTop to track scroll direction
  const lastScrollTop = useRef(0);

  useEffect(() => {
    const container = chatContainerRef.current;
    if (!container) return;

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = container;
      const isAtBottom = Math.abs(scrollHeight - scrollTop - clientHeight) < 50;
      // Detect scroll direction
      const isScrollingUp = scrollTop < lastScrollTop.current;
      lastScrollTop.current = scrollTop;
      // Disable auto-scroll when scrolling up, enable only when at bottom
      setAutoScroll(isAtBottom && !isScrollingUp);
    };

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, []);

  // Modify the existing scroll effect
  useEffect(() => {
    if (autoScroll) {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [ followups, latestQuestion, paddingBottom, runningAnswer, autoScroll ]);

  useEffect(() => {
    if (q && isSocketOpen) {
      // createQuestion(q, [], false, "Clinical");
      if (contextUUID && [ 'discoverArticle', 'workflow' ].includes(contextType)) {
        // alert(`create question with ${q} and ${contextUUID}`);
        createQuestion(q, [], true, 'mindsearchrefer', {
          reference: contextUUID,
          reference_type: contextType,
          reference_title: location.state?.title,
        });
      } else { //mostly for buzz usecase
        createQuestion(q, [], true, 'mindsearch', {
          reference: contextUUID,
          reference_type: contextType,
          reference_title: location.state?.title,
        });
      }
    }
  }, [ q, contextUUID, isSocketOpen ]);

  const currentAgentType =
    runningTask?.agent || initialData?.results[0]?.agent || threadAgentType; // this value won't change, as it comes from the initial data

  const onSubmit = (text, attachments, useUpgrade) => {
    console.log('ppppppppppppppp, im here');
    if (isFollowupFetching) {
      return;
    }
    console.log({ text, attachments, useUpgrade });
    if (newAskId || askId) {
      console.log('follow up');
      submitFollowupQuestion(text, attachments, useUpgrade);
    } else {
      console.log("create normally wouldn't be triggered here...");
    }
  };

  // context is like "context":{"reference":"workflow id", "reference_type":"workflow"}}
  const createQuestion = async (
    text,
    attachments,
    useUpgrade,
    focusArea,
    context
  ) => {
    console.log('======== create question ===========');
    console.log('focusArea is ', focusArea);
    setFocusArea(focusArea);
    setLatestQuestion({
      question: text,
      upload_files: attachments,
      agent: focusArea,
    }); // latest question could be from the input
    setIsFollowupFetching(true);
    try {
      if (mockClient.current.readyState !== mockClient.current.OPEN) {
        // alert("?");
        showAlert(
          'error',
          reachLimit
            ? i18n.DAILY_LIMIT_ERROR[lang]
            : i18n.CONNECTION_ERROR[lang],
          reachLimit
            ? [ i18n.DAILY_LIMIT_HINT1[lang], i18n.DAILY_LIMIT_HINT2[lang] ]
            : []
        );
        setIsFollowupFetching(false);
        return;
      }

      // keep as rest API! no need to move to websocket
      // alert('creating thread');
      // setNewAskId(12345);
      // return;

      const threadId = await createThread(
        null,
        getFinalAgent(useUpgrade, focusArea),
        'noahllm',
        text,
        [],
        context
      );
      // alert('in dev');
      // const threadId = await new Promise((resolve) => {
      //   setTimeout(() => {
      //     resolve("123");
      //   }, 2000);
      // });
      // return;
      setNewAskId(threadId);
      updateHistory();
      if (attachments && attachments.length > 0) {
        console.log(attachments);
        setIsEmbedding(true);
        await createThreadEmbedding(
          threadId,
          attachments?.map((attachment) => attachment.id)
        );
        setIsEmbedding(false);
      }
      mockClient.current.send(
        JSON.stringify({
          thread_id: threadId,
          question: text,
          upload_files: attachments?.map((attachment) => attachment.id),
          agent: getFinalAgent(useUpgrade, focusArea),
          language: answerLang,
          // TODO: enableWebSearch:
        })
      );
    } catch (error) {
      setIsFollowupFetching(false);
      setIsEmbedding(false);
      switch (error?.response?.status) {
      case 429:
        showAlert('error', i18n.DAILY_LIMIT_ERROR[lang]);
        break;
        // setReachLimit(true);
      case 401:
        showAlert('error', i18n.LOGIN_MESSAGE[lang]);
        break;
      default:
        showAlert('error', error?.response?.data?.detail);
        break;
      }
    }
  };

  // if (!currentAgentType) {
  //   // redirect to home page if no agent type is found
  //   showAlert("error", i18n.NO_AGENT_TYPE[lang]);
  //   return <Navigate to="/ask" replace />;
  // }
  const submitFollowupQuestion = async (text, attachments, useUpgrade) => {
    console.log('======== follow up ===========');
    console.log('submitting follow up', text);
    setLatestQuestion({
      question: text,
      upload_files: attachments,
      agent: currentAgentType || focusArea,
    }); // latest question could be from the input
    setIsFollowupFetching(true);
    try {
      console.log(123123);
      console.log(threadId, text);
      // TODO MILD: to avoid connection closing.
      if (mockClient.current.readyState !== mockClient.current.OPEN) {
        showAlert(
          'error',
          reachLimit
            ? i18n.DAILY_LIMIT_ERROR[lang]
            : i18n.CONNECTION_ERROR[lang],
          reachLimit
            ? [ i18n.DAILY_LIMIT_HINT1[lang], i18n.DAILY_LIMIT_HINT2[lang] ]
            : []
        );
        setIsFollowupFetching(false);
        return;
      }
      if (attachments && attachments.length > 0) {
        console.log(attachments); // it contains .name and ,upload as cdn
        setIsEmbedding(true);
        await createThreadEmbedding(
          threadId,
          attachments?.map((attachment) => attachment.id)
        );
        setIsEmbedding(false);
      }
      mockClient.current.send(
        JSON.stringify({
          thread_id: threadId,
          question: text,
          upload_files: attachments?.map((attachment) => attachment.id),
          agent: getFinalAgent(useUpgrade, currentAgentType || focusArea),
          language: answerLang,
        })
      );
    } catch (error) {
      console.log(error);
      setIsEmbedding(false);
      setIsFollowupFetching(false);
      showAlert(
        'error',
        error?.response?.status == 429
          ? i18n.DAILY_LIMIT_ERROR[lang]
          : error?.response?.data?.detail
      );
    }
  };

  // Track Article Clicks in hotTopics
  const handleTopicClick = (itemId, itemTitle) => {
    window.gtag('event', 'click', {
      event_category: 'HotTopic',
      event_label: itemTitle,
    });
    navigate(`/ask/?q=${encodeURIComponent(itemTitle)}&contextUUID=${itemId}&contextType=buzz`);
  };

  // Track Article Clicks in hotArticles
  const handleArticleClick = (articleId) => {
    window.gtag('event', 'click', {
      event_category: 'HotArticle',
      event_label: `Article_${articleId}`,
    });
    navigate(`/discover/article/${articleId}`);
  };

  // Track Language Selection
  const handleLanguageChange = (e) => {
    const selectedLang = e.target.value;
    window.gtag('event', 'change_site_language', {
      event_category: 'Language',
      event_label: selectedLang,
    });
    switchAnswerLang(langMap[selectedLang]); // switch explore answer as well.
    selectLanguage(selectedLang);
  };

  const originalList = initialData?.results || [];
  const conversationList = originalList.map((item, index) => {
    return (
      <OneConversation
        key={'' + index}
        item={item}
        onTriggerNewQuestion={onSubmit}
      />
    );
  });
  const followupList = followups.map((item, index) => {
    return (
      <OneConversation
        key={'' + item.id || item.task_id + index}
        item={item}
        onTriggerNewQuestion={onSubmit}
      />
    );
  });

  if (!askId && !newAskId && !latestQuestion && !q) {
    return (
      <div className="relative  min-h-screen">
        <div className="relative h-full min-h-96 flex flex-col justify-center lg:mx-12">
          <div className="grid grid-cols-1 gap-6 md:grid-cols-6 md:grid-rows-3 m-2">
            <div className="flex flex-col items-center justify-center p-px md:col-span-6 xl:col-start-2 xl:col-end-6 xl:row-span-1">
              <h2 className="mb-8 text-3xl font-bold tracking-tight text-secondary-content sm:text-4xl text-center">
                {i18n.MEET_AGENT[lang]}
              </h2>
              <TextInput
                className="w-full mx-auto left-0 right-0"
                onSubmit={(res) => {
                  console.log(res); // {focusArea: "Clinical", switchValue: true,textInput: "123",uploadInfos: []}
                  // Add tracking event
                  window.gtag('event', 'form', {
                    event_category: 'explore_create',
                    event_label: 'direct',
                    focus_area: res.focusArea,
                    pro: res.switchValue,
                    text_input: res.textInput,
                  });
                  createQuestion(
                    res.textInput,
                    res.uploadInfos,
                    res.switchValue,
                    res.focusArea
                  );
                }}
                enableUpload={true}
                disabled={false}
                onFileCountChange={(count) => {
                  console.log(count);
                }}
              />
            </div>
            <div className="flex p-px md:col-start-1 md:col-end-4 md:row-span-3 xl:col-start-2 xl:row-span-2">
              <div className="flex flex-col rounded-lg">
                <div className="py-4">
                  <p className="my-2 text-lg/7 font-medium tracking-tight text-primary-color border-l-8 pl-4 border-brand">
                    {i18nCommon.NEWS[lang]}
                  </p>

                  <div className="h-[500px] overflow-y-scroll scrollbar-none">
                    {hotTopics.map((item, hIndex) => (
                      <div
                        className="relative w-full mx-auto flex flex-wrap "
                        key={hIndex}
                      >
                        <div
                          className="relative cursor-pointer w-full inline-flex py-3 border-b border-gray-300"
                          onClick={() => handleTopicClick(item.id, item.long_title)}
                        >
                          <div className="w-full">
                            <div className="w-full leading-6 hover:underline">
                              {item.long_title}
                            </div>
                            <p className="text-right text-xs">
                              {new Date(item.created).toLocaleDateString()}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="absolute bottom-0 left-0 w-full h-12 bg-gradient-to-t from-base-100 to-transparent pointer-events-none"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex p-px md:col-start-4 md:col-end-7 md:row-span-2 xl:col-end-6 xl:row-span-2 md:h-1/2">
              <div className="rounded-lg">
                <div className="py-4">
                  <div className="flex justify-between">
                    <p className="my-2 text-lg/7 font-medium tracking-tight text-primary-color border-l-8 pl-4 border-brand">
                      {i18nCommon.TOP_STORIES[lang]}
                    </p>
                  </div>
                  <div className="h-[500px] overflow-y-scroll scrollbar-none">
                    {hotArticles?.map((article) => (
                      <ArticleCard
                        key={article.id}
                        article={article}
                        className="mb-4"
                        onClick={() => handleArticleClick(article.id)}
                      />
                    ))}
                    <div className="absolute bottom-0 left-0 w-full h-12 bg-gradient-to-t from-base-100 to-transparent pointer-events-none"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="z-10 w-full px-sm mt-8 mb-4 text-secondary-content text-center">
          <a href="/about" className="mr-4 hover:underline"> {i18n.ABOUT[lang]} </a>
          {/* <a href="/terms" className="mr-4 hover:underline"> terms </a> */}
          <select
            onChange={handleLanguageChange}
            className="border-none bg-transparent focus:ring-0 focus:outline-none cursur-pointer"
          >
            <option value="en" selected={lang === 'en'}>
              English
            </option>
            <option value="zh" selected={lang === 'zh'}>
              中文
            </option>
          </select>
        </footer>
      </div>
    );
  }
  return (
    // isSocketOpen &&
    <div className="relative h-screen">
      {/* <Slider>
        <PdfViewer url={'/pdf/US-KOL-Wet_AMD_WA6.pdf'} pageNumber={4} />
      </Slider> */}
      <div
        className={`absolute bg-base-100 w-full h-full flex-1 overflow-y-scroll ${paddingBottom}`}
        ref={chatContainerRef}
      >
        {/* {contextType && <p>{agent_i18n["mindsearchrefer"][lang]}</p>}
        {threadAgentType && <p>{agent_i18n[threadAgentType][lang]}</p>} */}
        <div className="mx-auto text-base leading-7 text-secondary-content mt-12">
          {/* {answerSection} */}
          {/* <HelloMsg agentType={currentAgentType} onSubmit={onSubmit} /> */}
          {location.state?.sourceURL && (
            <div
              className="max-w-5xl mb-4 mx-auto"
              onClick={() => {
                window.open(location.state?.sourceURL, '_blank');
              }}
            >
              <div className="cursor-pointer bg-base-300 rounded-lg p-4 mx-auto">
                <div className="mr-2 flex text-md items-center">
                  <ViewfinderCircleIcon className="w-4 h-4 mr-2" />
                  {i18nCommon.CONTEXT_TITLE[lang]}
                </div>
                <p className="text-lg font-bold">{location.state?.title}</p>
              </div>
            </div>
          )}

          {conversationList}
          {followupList}
          {isFollowupFetching && (
            <OneRunningConversation
              item={latestQuestion}
              runningAnswer={runningAnswer}
              isEmbedding={isEmbedding}
            />
          )}
          <div ref={bottomRef} className="h-6"></div>
        </div>
      </div>
      <SingleLineInput
        className="absolute bottom-3 md:w-3/4 lg:w-2/3 w-full mx-auto left-0 right-0"
        onSubmit={onSubmit}
        // enableUpload={
        //   agents.filter((agent) => agent.category === currentAgentType).at(0)
        //     .enableUpload
        // }
        enableUpload={true}
        disabled={isFollowupFetching}
        onFileCountChange={(count) => {
          if (count > 0) {
            setPaddingBottom('pb-32');
          } else {
            setPaddingBottom('pb-12');
          }
        }}
      />
    </div>
  );
}

export async function askDetailLoader({ params }) {
  let runningTask = {};
  if (!params.askId) {
    const hotTopics = await getHotTopic(1, 40);
    const hotArticles = await getDiscoverList(1, 40);
    console.log(hotTopics, hotArticles);
    return {
      needFetchLatest: false,
      initialData: null,
      runningTask: null,
      threadAgentType: null,
      hotTopics: hotTopics?.results,
      hotArticles: hotArticles,
    };
  }
  const initialData = await getThreadTasks(params.askId);
  const thread = await getThreadMeta(params.askId);
  const needFetchLatest =
    initialData?.results?.at(0) &&
    ![ 'complete', 'failed' ].includes(initialData?.results?.at(0)?.task_status);
  if (needFetchLatest) {
    runningTask = initialData.results.splice(0, 1)?.at(0);
  }
  initialData.results.reverse();
  // console.log("===", {needFetchLatest,initialData});
  return {
    needFetchLatest,
    initialData,
    runningTask,
    threadAgentType: thread?.agent,
    hotTopics: null,
    hotArticles: null,
  };
}
