import { Fragment, useState } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

export default function PaperModal({ open, papers, onClose }) {
  // const [open, setOpen] = useState(true)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => onClose(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-base-100 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-base-100 text-neutral-content hover:text-accent-content focus:outline-none"
                    onClick={() => onClose(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-secondary-content"
                    >
                      论文发表信息
                    </Dialog.Title>
                    <Dialog.Description className="text-accent-content"></Dialog.Description>
                    <div className="mt-2 border-t">
                      <ul role="list" className="divide-y divide-gray-100">
                        {papers.map((paper) => (
                          <li
                            key={paper.标题}
                            className="relative flex justify-between gap-x-6 py-5"
                          >
                            <div className="flex min-w-0 gap-x-4">
                              {/* <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={paper.imageUrl} alt="" /> */}
                              <div className="min-w-0 flex-auto">
                                <p className="text-sm font-semibold leading-6 text-secondary-content">
                                  {/* <a href={paper.链接}> */}
                                  <span className="absolute inset-x-0 -top-px bottom-0" />
                                  {paper.标题}
                                  {/* </a> */}
                                </p>
                                <p className="mt-1 flex text-xs leading-5 text-accent-content">
                                  {paper.期刊}
                                </p>
                                <p className="mt-1 flex text-xs leading-5 text-accent-content">
                                  {paper.发表时间}
                                </p>
                                <p className="mt-1 flex text-xs leading-5 text-accent-content">
                                  {paper.DOI}
                                </p>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-1/3 justify-center primary btn text-white"
                    onClick={() => onClose(false)}
                  >
                    确定
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
