import { Fragment, useEffect, useState } from 'react';

import { Switch } from '@headlessui/react';

import { useAlert } from '../../utils/context/alert'; // Adjust path as needed
import { useLanguage } from '../../utils/context/lang.js';
import { usePro } from '../../utils/context/pro.js';
import { useUser } from '../../utils/context/user';
import { i18n } from '../i18n/common';

import FileThumbnail from './fileThumbnail'; // Ensure correct import path
import UploadButton from './uploadBtn';


const maxRows = 6;
export default function SingleLineInput({
  className,
  onSubmit,
  enableUpload = false,
  disabled = false,
  onFileCountChange,
  placeholder='write a message...',
}) {
  const { lang } = useLanguage();
  const showAlert = useAlert();
  const { isProOn, switchPro } = usePro();
  // const { user } = useUser();
  const [ textInput, setTextInput ] = useState('');
  const [ uploadInfos, setUploadInfos ] = useState([]);
  // const [ switchValue, setSwitchValue ] = useState(false);

  // force switch to false
  // useEffect(() => {
  //   // alert(111)
  //   if (forceSwitchValue !== undefined) {
  //     setSwitchValue(forceSwitchValue);
  //   }
  // }, [ forceSwitchValue ]);

  // useEffect(() => {
  //   setSwitchValue(user ? user.price_plan === 'pro' : false);
  // }, [ user ]);

  useEffect(() => {
    onFileCountChange(uploadInfos.length);
  }, [ uploadInfos ]);

  const submitQuestion = () => {
    console.log('submit question ',{ textInput, uploadInfos, isProOn });
    onSubmit(textInput, uploadInfos, isProOn);
    setUploadInfos([]);
    setTextInput('');
    const textarea = document.querySelector('.resize-none'); // Use a more specific selector if needed
    const container = textarea.parentNode;

    // textarea.style.height = 'auto';  // Reset the height of textarea
    container.style.height = '60px'; // Reset the height of the container to the initial height
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (
        e.key === 'Enter' &&
        !e.shiftKey &&
        textInput.length > 0 &&
        !e.isComposing &&
        !disabled
      ) {
        e.preventDefault(); // Prevent default action to avoid submitting form
        submitQuestion();
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [ textInput, disabled ]); // Include `disabled` to ensure the effect updates if the disabled state changes

  const handleSelectFile = (file) => {
    console.log('File selected:', file.name);
    setUploadInfos((prev) => [ ...prev, { name: file.name } ]);
  };
  const handleTextChange = (e) => {
    const textarea = e.target;
    const container = textarea.parentNode; // Assuming the parent node is the container div
    container.style.height = 'auto';
    // Calculate effective height considering padding
    const computedStyle = window.getComputedStyle(textarea);
    const paddingTop = parseInt(computedStyle.paddingTop, 10);
    const paddingBottom = parseInt(computedStyle.paddingBottom, 10);
    const borderHeight =
      parseInt(computedStyle.borderTopWidth, 10) +
      parseInt(computedStyle.borderBottomWidth, 10);

    // Calculate the height to be the greater of scrollHeight (less padding and border) or the minimum height
    const desiredHeight = Math.max(
      textarea.scrollHeight - paddingTop - paddingBottom - borderHeight,
      60
    );

    container.style.height = `${desiredHeight}px`;
    setTextInput(textarea.value); // Update the state with new input
  };
  const handleFileUploaded = (file, uploadData) => {
    console.log('selectedFile', file);
    console.log('File uploaded:', uploadData);
    setUploadInfos((prev) =>
      prev.map((info) =>
        info.name === file.name
          ? {
            ...info,
            upload: uploadData.upload,
            id: uploadData.id,
            name: file.name,
          }
          : info
      )
    );
  };

  const deleteUpload = (fileId) => {
    setUploadInfos((prev) => prev.filter((info) => info.id !== fileId));
  };

  // const onModelChange = (newVal) => {
  //   // if (newVal) {
  //   //   if (user?.price_plan === 'pro') {
  //   //     setSwitchValue(newVal);
  //   //   } else {
  //   //     // optimize this part
  //   //     showAlert('error', i18n.UPGRADE_HINT[lang]);
  //   //   }
  //   // } else {
  //   setSwitchValue(newVal);
  //   onSwitchChange(newVal);
  //   // }
  // };

  return (
    <div
      className={`form-control border border-gray-300 rounded-full bg-base-100 z-10 ${className}`}
    >
      <div className="flex overflow-x-scroll scrollbar-none">
        {uploadInfos.map((upload, index) => (
          <FileThumbnail
            key={upload.id || upload.name}
            upload={upload}
            onDelete={() => deleteUpload(upload.id)}
          />
        ))}
      </div>
      <div className="relative input-group flex items-center justify-center overflow-hidden h-[60px] max-h-[160px] scrollbar-none">
        {/* {enableUpload && (
          <UploadButton
            className=""
            onSelectFile={handleSelectFile}
            onFileUploaded={handleFileUploaded}
            onUploadError={(error) => showAlert('error', 'File upload error')}
            upload={uploadInfos}
            isMini={true}
          />
        )} */}
        <textarea
          id="textarea"
          placeholder={placeholder}
          rows={1}
          className="relative rounded-full scrollbar-none py-4 px-6 h-full min-h-[60px] max-h-[160px] resize-none leading-6 input w-[calc(100%-3rem)] border-none focus:outline-none focus:shadow-none focus:ring-0 overflow-scroll"
          value={textInput}
          onChange={handleTextChange}
        />
        {/* <span>Pro</span>
        <Switch
          checked={isProOn}
          onChange={switchPro}
          className={`${
            isProOn ? 'bg-blue-400' : 'bg-gray-200'
          } relative mr-4 ml-1 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none`}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={`${
              isProOn ? 'translate-x-5' : 'translate-x-0'
            } pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition ease-in-out duration-200`}
          />
        </Switch> */}

        <button
          className="relative btn-circle btn-primary btn rounded-full mr-2"
          onClick={submitQuestion}
          disabled={textInput.length === 0 || disabled}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
