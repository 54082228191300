import React, { createContext, useContext, useEffect, useState } from 'react';

import { hide } from '@popperjs/core';
import Cookies from 'js-cookie';

import { getMyInfo, signIn } from '../../api';

import { useLanguage } from './lang';

export const UserContext = createContext();

export const useUser = () => useContext(UserContext);

const LoginModal = () => {
  const { login, hideModal, loginError } = useUser();
  const [ username, setUsername ] = useState('');
  const [ password, setPassword ] = useState('');
  const { lang } = useLanguage();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(username, password);
    } catch (error) {
      // Handle login error
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-xl font-bold mb-4">Login</h2>
        {loginError && <p className="text-red-500 mb-4">{loginError}</p>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">Username</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div className="flex justify-end">
            <button type="button" onClick={hideModal} className="mr-4 px-4 py-2 bg-gray-500 text-white rounded">
              Cancel {lang}
            </button>
            <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded">
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export const UserProvider = ({ children }) => {
  const [ user, setUser ] = useState({});
  const [ isModalVisible, setIsModalVisible ] = useState(false);
  const [ loginError, setLoginError ] = useState(null);
  
  useEffect(async () => {
    // Initialize user state from cookies on load
    const noahUser = Cookies.get('noahUser');
    const noahAccessToken = Cookies.get('noahAccessToken');

    if (noahUser && noahAccessToken) {
      try {
        const result = await getMyInfo(noahUser);
        console.log('look look look', {
          username: noahUser,
          info: result?.data,
        });
        setUser(result?.data);
      } catch (error) {
        logout();
      }
    } else {
      setUser(null)
    }
  }, []);

  const logout = () => {
    // Remove cookies
    Cookies.remove('noahUser');
    Cookies.remove('noahAccessToken');

    // Update state
    setUser(null);
    location.replace('/');
  };

  const login = async (username, password) => {
    try {
      const result = await signIn(username, password);
      Cookies.set('noahUser', username);
      Cookies.set('noahAccessToken', result.data?.token);
      hideModal();
      return result;
    } catch (error) {
      console.log('login error', error);
      setLoginError('Invalid username or password');
      throw error;
    }
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
    setLoginError(null);
  };

  return (
    <UserContext.Provider value={{ user, logout, login, showModal, hideModal }}>
      {children}
      {isModalVisible && <LoginModal />}
    </UserContext.Provider>
  );
};
