// write a download button with passed in data
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid'

import { downloadXLSX } from './downloadUtil';

export default function DownloadBtn({ data, filename }) {
  return (
    <button
      className="inline-flex items-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-primary-content shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
      onClick={
        () => downloadXLSX(data, filename)
      }
    >
      <ArrowDownTrayIcon className="h-5 w-5" aria-hidden="true" />
    </button>

  );
}