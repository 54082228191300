import { useState } from 'react';

import { i18n } from '../../i18n/paper.js';
import { paperData } from '../../mock/paperData.js';
import DownloadBtn from '../downloadBtn.js';

import IntroModal from './modal/introModal.js';

export const PaperTable = ({ paperData }) => {
  const [ introModalOpen, setIntroModalOpen ] = useState(false);
  const [ paper, setPaper ] = useState(null);

  const showPI = (paper) => {
    setPaper(paper);
    setIntroModalOpen(true);
  };

  return (
    <div className="rounded-lg py-4 overflow-hidden">
      {/* <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base flex items-center content-center font-semibold leading-6 text-secondary-content">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
              />
            </svg>
            <span className="pl-2">Answer</span>
          </h1>
          <p className="mt-2 text-secondary-content pb-2">
            {paperData.content_link}
          </p>
        </div>
      </div> */}
      <div className="flow-root w-full">
        <div className="inline-block w-full py-2 align-middle">
          {/* <div className="mb-6">
            {paperData.summary ? (
              <h3 className="font-bold mt-4">{i18n.MORE_INFO.en}</h3>
            ) : (
              ''
            )}
            <pre className="font-sans m-0 p-0 break-words whitespace-pre-wrap">
              {paperData.summary}
            </pre>
            {paperData.citations ? (
              <h3 className="font-bold mt-4">{i18n.CITATIONS.en}</h3>
            ) : (
              ''
            )}
            <pre className="font-sans break-words whitespace-pre-wrap">
              {paperData.citations}
            </pre>
          </div> */}
          <div className="px-4 sm:px-6 lg:px-8 inline-block w-full bg-base-100 py-2 align-middle rounded-lg overflow-x-auto">
            <div className="text-right mb-2">
              <DownloadBtn data={paperData.papers} filename="paperInfo" />
            </div>
            <div className="shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-300 block h-[480px] overflow-y-scroll ">
                <thead className="sticky top-0">
                  <tr className="bg-base-100 border-b border-gray-200">
                    <th
                      scope="col"
                      className="py-3.5 text-left text-sm font-semibold text-secondary-content sm:pl-0 md:pl-4 "
                    >
                      {i18n.TITLE.en}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-secondary-content"
                    >
                      {i18n.JOURNAL.en}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-secondary-content"
                    >
                      {i18n.YEAR.en}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-secondary-content"
                    >
                      {i18n.CITED_COUNT.en}
                    </th>
                    {/* <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-secondary-content"
                    >
                      {i18n.SCORE.en}
                    </th> */}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {paperData.papers.map((paper) => (
                    <tr key={paper.URL}>
                      <td
                        className="max-w-sm py-4 text-sm font-medium text-secondary-content cursor-pointer sm:pl-0 md:pl-4"
                        onClick={() => showPI(paper)}
                      >
                        <a className="underline hover:text-primary">
                          {paper.title}
                        </a>
                      </td>
                      <td className="px-3 py-4 text-sm text-secondary-content">
                        {paper.journal}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary-content">
                        {paper.year > 1800 ? paper.year : ''}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary-content">
                        {paper.citations > 0 ? paper.citations : ''}
                      </td>
                      {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary-content">
                        {paper.score?.toFixed(2)}
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <IntroModal
        open={introModalOpen}
        paper={paper}
        onClose={setIntroModalOpen}
      />
    </div>
  );
};
