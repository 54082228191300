import React, { createContext, useContext, useEffect, useState } from 'react';

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  // Function to get system theme
  const getSystemTheme = () => {
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  };

  // Initial theme is fetched from system settings
  const [ theme, setTheme ] = useState(getSystemTheme());

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    // Function to handle system theme change
    const handleSystemThemeChange = (e) => {
      const newTheme = e.matches ? 'dark' : 'light';
      setTheme(newTheme);
      document.querySelector('html').setAttribute('data-theme', newTheme);
    };

    // Apply the initial theme
    document.querySelector('html').setAttribute('data-theme', theme);

    // Add listener for system theme changes
    mediaQuery.addEventListener('change', handleSystemThemeChange);

    return () => {
      // Cleanup listener on unmount
      mediaQuery.removeEventListener('change', handleSystemThemeChange);
    };
  }, []); // Empty dependency array ensures this runs once on mount

  return (
    <ThemeContext.Provider value={{ theme }}>
      {children}
    </ThemeContext.Provider>
  );
};
