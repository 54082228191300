import DisplayFormattedText from '../../../AskPage/components/markdownInCell';

export const ASH_COLUMN = [
  {
    headerName: 'Title',
    field: 'citation_title',
    width: 400,
    wrapText: true,
    pinned: 'left',
  },
  {
    headerName: 'Additional Notes',
    field: 'additional_notes',
    wrapText: true,
    width: 600,
    cellRenderer: (params) => {
      return <DisplayFormattedText text={params.data['additional_notes']} className="text-xs"/>;
    },
  },
  {
    headerName: 'Author Institution',
    field: 'citation_author_institution',
    wrapText: true,
    width: 300,
  },
  {
    headerName: 'Author',
    field: 'citation_author',
    wrapText: true,
  },
  {
    headerName: 'Study Type',
    field: 'study_metadata_study_type',
    wrapText: true,
    width: 300,
    cellRenderer: (params) => {
      return <DisplayFormattedText text={params.data['study_metadata_study_type']} className="text-xs"/>;
    },
  },
  {
    headerName: 'Disease',
    field: 'study_metadata_disease',
    wrapText: true,
    width: 400,
    cellRenderer: (params) => {
      return <DisplayFormattedText text={params.data['study_metadata_disease']} className="text-xs"/>;
    },
    
  },
  {
    headerName: 'Intervention',
    field: 'study_metadata_intervention',
    wrapText: true,
    width: 600,
    cellRenderer: (params) => {
      return <DisplayFormattedText text={params.data['study_metadata_intervention']} className="text-xs"/>;
    },
  },
  {
    headerName: 'Population',
    field: 'study_metadata_population',
    wrapText: true,
    width: 300,
    cellRenderer: (params) => {
      return <DisplayFormattedText text={params.data['study_metadata_population']} className="text-xs"/>;
    },
  },
  {
    headerName: 'Overall Response Rate',
    field: 'efficacy_endpoints_overall_response_rate',
    wrapText: true,
    width: 300,
    cellRenderer: (params) => {
      return <DisplayFormattedText text={params.data['efficacy_endpoints_overall_response_rate']} className="text-xs"/>;
    },
  },
  {
    headerName: 'Progression Free Survival',
    field: 'efficacy_endpoints_progression_free_survival',
    wrapText: true,
    width: 300,
    cellRenderer: (params) => {
      return <DisplayFormattedText text={params.data['efficacy_endpoints_progression_free_survival']} className="text-xs"/>;
    },
  },
  {
    headerName: 'Overall Survival',
    field: 'efficacy_endpoints_overall_survival',
    wrapText: true,
    width: 300,
    cellRenderer: (params) => {
      return <DisplayFormattedText text={params.data['efficacy_endpoints_overall_survival']} className="text-xs"/>;
    },
  },
  {
    headerName: 'Complete Response Rate',
    field: 'efficacy_endpoints_complete_response_rate',
    wrapText: true,
    width: 300,
    cellRenderer: (params) => {
      return <DisplayFormattedText text={params.data['efficacy_endpoints_complete_response_rate']} className="text-xs"/>;
    },
  },
  {
    headerName: 'MRD Negativity',
    field: 'efficacy_endpoints_mrd_negativity',
    wrapText: true,
    width: 300,
    cellRenderer: (params) => {
      return <DisplayFormattedText text={params.data['efficacy_endpoints_mrd_negativity']} className="text-xs"/>;
    },
  },
  {
    headerName: 'Cytogenetic Responses',
    field: 'efficacy_endpoints_cytogenetic_responses',
    wrapText: true,
    width: 300,
    cellRenderer: (params) => {
      return <DisplayFormattedText text={params.data['efficacy_endpoints_cytogenetic_responses']} className="text-xs"/>;
    },
  },
  {
    headerName: 'Transfusion Independence',
    field: 'efficacy_endpoints_transfusion_independence',
    wrapText: true,
    width: 300,
    cellRenderer: (params) => {
      return <DisplayFormattedText text={params.data['efficacy_endpoints_transfusion_independence']} className="text-xs"/>;
    },
  },
  {
    headerName: 'Adverse Events',
    field: 'safety_profile_adverse_events',
    wrapText: true,
    width: 300,
    cellRenderer: (params) => {
      return <DisplayFormattedText text={params.data['safety_profile_adverse_events']} className="text-xs"/>;
    },
  },
  {
    headerName: 'Serious Adverse Events',
    field: 'safety_profile_serious_adverse_events',
    wrapText: true,
    width: 300,
    cellRenderer: (params) => {
      return <DisplayFormattedText text={params.data['safety_profile_serious_adverse_events']} className="text-xs"/>;
    },
  },
  {
    headerName: 'Deaths',
    field: 'safety_profile_deaths',
    wrapText: true,
    width: 300,
    cellRenderer: (params) => {
      return <DisplayFormattedText text={params.data['safety_profile_deaths']} className="text-xs"/>;
    },
  },
  {
    headerName: 'Discontinuation Rate',
    field: 'safety_profile_discontinuation_rate',
    wrapText: true,
  },
  {
    headerName: 'Link',
    field: 'link',
    wrapText: true,
    cellRenderer: (params) => {
      return (
        <a
          href={params.data['link']}
          target="_blank"
          rel="noreferrer"
          className="underline text-blue-500 hover:text-blue-700 focus:outline-none focus:underline"
        >
          click to review
        </a>
      );
    },
  },
];
