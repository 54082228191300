import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { getHistoryList } from '../../api';

// Create a context for the history data
const HistoryContext = createContext();

/**
 * Provider component to manage and provide the history list to its children.
 * @param {Object} props - Props passed to the provider component.
 * @param {React.ReactNode} props.children - Child components that consume the context.
 */
export function HistoryProvider({ children }) {
  const [ historyResult, setHistoryResult ] = useState([]);
  const [ exploreContext, setExploreContext ] = useState({});

  // Memoized function to update the history by fetching new data from the API
  const updateHistory = useCallback(async () => {
    try {
      const history = await getHistoryList();
      setHistoryResult(history);
    } catch (error) {
      console.error('Error updating history:', error);
    }
  }, []);

  // Fetch history data when the component mounts
  useEffect(() => {
    updateHistory();
  }, [ updateHistory ]);

  return (
    <HistoryContext.Provider value={{ historyResult, updateHistory, exploreContext, setExploreContext }}>
      {children}
    </HistoryContext.Provider>
  );
}

/**
 * Custom hook to consume the history context.
 * @returns {Object} context - The context value containing history data and the update function.
 */
export function useHistory() {
  const context = useContext(HistoryContext);
  if (!context) {
    throw new Error('useHistory must be used within a HistoryProvider');
  }
  return context;
}
