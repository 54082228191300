import React from 'react';

import { Link } from 'react-router-dom';

const ArticleCard = ({
  className,
  article = {
    id: 1,
    title: 'Article Title',
    abstract: 'Article Abstract',
    author: 'Author Name',
    published: '2021-01-01',
    cover_url: 'https://via.placeholder.com/150',
  },
}) => {
  return (
    <div
      key={article.id}
      className={`overflow-hidden rounded-md transition-shadow duration-50 bg-base-100 shadow-sm hover:shadow-lg ${className}`}
    >
      {/* TODO: ziwen change all anchor as Link, to prevent unnecessary re-render */}
      <Link
        to={`/discover/article/${article.id}`}
        className="flex flex-row group block h-full w-full outline-none flex"
      >
        {/* Image on the left */}
        {/* <div className="relative flex-shrink-0 w-24 flex items-center justify-center">
          <img
            alt={article.title}
            className="h-16 w-16 object-cover rounded-lg"
            src={article.cover_url || 'https://via.placeholder.com/150'}
          />
        </div> */}
        
        {/* Content on the right */}
        <div className="flex flex-col justify-between p-4 pb-2 w-full">
          <div>
            <h2 className="text-md font-medium leading-snug line-clamp-2">{article.title}</h2>
            <p className="mt-1 text-secondary-content line-clamp-2 text-sm text-gray-600">{article.abstract}</p>
          </div>

          {/* Author and date section below title and abstract */}
        </div>
      </Link>
      <div className="flex items-center justify-between mx-4 mb-2 text-primary-color">
        <span className="text-xs">{article.author}</span>
        <span className="text-xs ">
          {new Date(article.published_date).toLocaleDateString()}
        </span>
      </div>
    </div>
  );
};

export default ArticleCard;
