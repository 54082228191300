import { useCallback, useState } from 'react';

import { Select } from 'antd';
import debounce from 'lodash/debounce';

import { fetchTypeAhead } from '../../../api';
import { useLanguage } from '../../../utils/context/lang';

const dict = {
  en: {
    title: 'Company',
    placeholder: 'Select Company',
  },
  zh: {
    title: '公司',
    placeholder: '选择公司',
  },
};

const CompanySelect = ({ name, defaultValue = [], onChange }) => {
  const { lang } = useLanguage();
  const [ options, setOptions ] = useState([]);

  const handleSearch = useCallback(debounce((value) => {
    fetchTypeAhead(value, 'company').then((res) => {
      setOptions(res?.result?.map((item) => ({ label: item, value: item })) || []);
    })
  }, 500), []);
  return (
    <>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-primary-content"
      >
        {dict['en'].title}
      </label>
      <Select
        mode="multiple"
        style={{
          width: '100%',
        }}
        placeholder={dict['en'].placeholder}
        defaultValue={defaultValue}
        onChange={(value) => onChange(value)}
        notFoundContent={null}
        options={options}
        filterOption={false}
        onSearch={handleSearch}
      />
    </>
  );
};

export default CompanySelect;
