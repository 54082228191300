/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { act, useEffect, useState } from 'react';

import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import {
  AcademicCapIcon,
  BanknotesIcon,
  ChatBubbleLeftIcon,
  CheckBadgeIcon,
  ClockIcon,
  ReceiptRefundIcon,
  UsersIcon } from '@heroicons/react/24/outline'
import Cookies from 'js-cookie';
import { Link , redirect, useLoaderData, useNavigate } from 'react-router-dom';

import { getMyInfo } from '../api.js';
import { useLanguage } from '../utils/context/lang.js';

import { agents } from './const/agent.js';
import { i18n } from './i18n/chat.js';

const actions = [
  // {
  //   title: {
  //     en: 'Clinical Design Comparison',
  //     zh: '临床研究设计比较',
  //   },
  //   description: {
  //     en: 'Helps you find clinical trial design information',
  //     zh: '帮助您查找临床试验设计信息',
  //   },
  //   href: '/workflow/new/clinical-design',
  //   icon: UsersIcon,
  //   iconForeground: 'text-teal-700',
  //   iconBackground: 'bg-teal-50',
  // },
  {
    title: {
      en: 'Clinical Results Comparison',
      zh: '临床结果比较',
    },
    description: {
      en: 'Helps you find clinical trial results information',
      zh: '帮助您查找临床试验结果信息',
    },
    href: '/workflow/new/clinical-result',
    icon: ClockIcon,
    iconForeground: 'text-purple-700',
    iconBackground: 'bg-purple-50',
  },
  {
    title: {
      en: 'Explore ASH',
      zh: '探索ASH年会',
    },
    description: {
      en: 'Analyze 5000+ abstracts  - 66th ASH Annual Meeting',
      zh: '5000+会议摘要分析和总结 - 第66届美国血液协会（ASH）年会',
    },
    href: '/workflow/new/ash',
    icon: ClockIcon,
    iconForeground: 'text-purple-700',
    iconBackground: 'bg-purple-50',
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function WorkflowLanding() {
  const { lang } = useLanguage();
  const navigate = useNavigate();
  // const myInfo = useLoaderData();
  // console.log('in landing page', myInfo);
  useEffect(() => {
    window.gtag('event', 'page_view', {
      event_category: 'page_view',
      event_label: 'workflow_landing',
    });
  }, []);

  return (
    <div className="flex py-10 flex-col h-full w-full items-center justify-center">
      <div className="w-4/5">
        <div className="bg-base-100">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <h2 className="text-3xl font-bold tracking-tight mb-12 border-l-8 border-brand pl-6">
                {i18n.FIND_WORKFLOW[lang]}
              </h2>
              {/* <p className="mt-6 text-lg leading-8 text-secondary-content">
                {i18n.ENHANCE_RESEARCH[lang]}
              </p> */}
            </div>
            <ul role="list" className="grid grid-cols-1 gap-x-6 gap-y-8 xl:grid-cols-2">
              {actions.map((action) => (
                <Link key={action.id} to={action.href}>
                  <li className="overflow-hidden rounded-xl border border-gray-200">
                    <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                      <action.icon className="h-8 w-8 text-gray-900" aria-hidden="true" />
                      <p className="text-sm font-medium leading-6 text-gray-900">{action.title[lang]}</p>
                    </div>
                    <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                      <div className="flex justify-between gap-x-4 py-3">
                        <dd className="flex items-start gap-x-2">
                          <div className="text-primary-content line-clamp-1">{action.description[lang]}</div>
                        </dd>
                      </div>
                    </dl>
                  </li>
                </Link>
                
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export async function workflowLandingLoader() {
  console.log('in landing loader')
  const userName = Cookies.get('noahUser');
  if (userName) {
    console.log('有username!', userName)
    try {
      const result = await getMyInfo(userName);
      console.log(result)
      return result?.data
    } catch (error) {
      console.log('error', error)
      return null
      // return location.replace('/login');
    }
  } else {
    return location.replace('/signin');
  }
}
