import { useEffect } from 'react';

import { Select } from 'antd';

const GenderSelect = ({ name, defaultValue, onChange }) => {
  // Trigger onChange with the defaultValue only on mount or when defaultValue changes
  useEffect(() => {
    onChange(defaultValue || 'Both');
  }, [ defaultValue ]); // no onChange

  return (
    <>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-primary-content"
      >
        Gender
      </label>
      <Select
        style={{ width: '100%' }}
        placeholder="Select Gender"
        defaultValue={defaultValue||'Both'}
        onChange={onChange} // Directly pass onChange since it's stable
        notFoundContent={null}
        options={[
          { label: 'Female', value: 'Female' },
          { label: 'Male', value: 'Male' },
          { label: 'Both', value: 'Both' },
        ]}
      />
    </>
  );
};

export default GenderSelect;
