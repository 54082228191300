export const catalyst = [
  {
    'Expected Begin Date': '2024-05-31',
    'Expected End Date': '2024-06-29',
    'Company': 'Annovis Bio, Inc.',
    'Country': 'United States',
    'Drug': 'Buntanetap',
    'Disease Group': 'Neurology',
    'Indication': "Parkinson's Disease (PD)",
    'Phase': 'III',
    'Molecule': 'Small Molecule',
    'Target': 'Alpha-synuclein, Amyloid Beta/Amyloid Plaques, B-APP Synthesis, Tau proteins',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Oral (PO)',
    'Trial Name': 'Phase III - ANVS-22001 (Early PD)',
    'Source Link': 'https://www.globenewswire.com/news-release/2024/05/09/2878650/0/en/Annovis-Announces-Unblinding-of-the-Buntanetap-Phase-III-Data-in-Parkinson-s-Disease.html'
  },
  {
    'Expected Begin Date': '2024-05-31',
    'Expected End Date': '2024-06-29',
    'Company': 'GeNeuro SA',
    'Country': 'Switzerland',
    'Drug': 'GNbAC1',
    'Disease Group': 'Infectious Disease',
    'Indication': 'COVID-19 Treatment',
    'Phase': 'Development Outside U.S.',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'MSRV-Env (Multiple Sclerosis associated retrovirus)',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Intravenous (IV)',
    'Trial Name': 'Phase II - PASC (EU)',
    'Source Link': 'https://www.businesswire.com/news/home/20231210164670/en/Independent-Data-Monitoring-Committee-Recommends-Continuing-the-Trial-Evaluating-Temelimab-in-Long-COVID-Without-Any-Modifications'
  },
  {
    'Expected Begin Date': '2024-06-19',
    'Expected End Date': '2024-07-09',
    'Company': 'Alnylam Pharmaceuticals Inc.',
    'Country': 'United States',
    'Drug': 'Amvuttra',
    'Disease Group': 'Metabolic',
    'Indication': 'Transthyretin Amyloid Cardiomyopathy (ATTR-CM, Wild Type Or Hereditary)',
    'Phase': 'III',
    'Molecule': 'siRNA/RNAi',
    'Target': 'Transthyretin (TTR)',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Subcutaneous (SQ) Injection',
    'Trial Name': 'Phase III - HELIOS-B',
    'Source Link': 'https://investors.alnylam.com/press-release?id=28136'
  },
  {
    'Expected Begin Date': '2024-06-23',
    'Expected End Date': '2024-06-26',
    'Company': 'AB Science S.A.',
    'Country': 'France',
    'Drug': 'Masitinib',
    'Disease Group': 'Neurology',
    'Indication': 'Amyotrophic Lateral Sclerosis (ALS)',
    'Phase': 'III',
    'Molecule': 'Small Molecule',
    'Target': 'Fibroblast Growth Factor Receptor (FGFR) , KIT/c-KIT, Platelet-derived growth factor receptor (PDGFR)',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Oral (PO)',
    'Trial Name': '',
    'Source Link': 'https://www.globenewswire.com/news-release/2024/05/29/2890131/0/en/AB-Science-provides-an-update-on-the-application-for-conditional-marketing-authorisation-of-masitinib-in-ALS.html'
  },
  {
    'Expected Begin Date': '2024-06-25',
    'Expected End Date': '2024-06-25',
    'Company': 'Verona Pharma plc',
    'Country': 'United Kingdom',
    'Drug': 'Ensifentrine',
    'Disease Group': 'Respiratory',
    'Indication': 'Chronic Obstructive Pulmonary Disease (COPD)',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'Phosphodiesterase 3 (PDE3), Phosphodiesterase 4 (PDE4)',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Inhaled',
    'Trial Name': '',
    'Source Link': 'https://www.globenewswire.com/news-release/2024/05/02/2873827/0/en/Verona-Pharma-to-Present-Additional-Analyses-of-Positive-Phase-3-ENHANCE-Studies-in-COPD-at-ATS-2024.html'
  },
  {
    'Expected Begin Date': '2024-06-27',
    'Expected End Date': '2024-06-27',
    'Company': 'Coherus BioSciences, Inc.',
    'Country': 'United States',
    'Drug': 'FYB203',
    'Disease Group': 'Ophthalmology',
    'Indication': 'Wet Age-Related Macular Degeneration (Wet AMD) (Ophthalmology)',
    'Phase': 'BLA',
    'Molecule': 'Protein',
    'Target': 'Placental growth factor (PlGF), VEGF (Vascular endothelial growth factor)',
    'Drug Classification': 'Biosimilar',
    'Route of Administration': 'Intraocular/Subretinal/Subconjunctival',
    'Trial Name': '',
    'Source Link': 'https://www.formycon.com/en/blog/press-release/file-acceptance-fyb203/'
  },
  {
    'Expected Begin Date': '2024-06-27',
    'Expected End Date': '2024-06-27',
    'Company': 'Genmab A/S',
    'Country': 'Denmark',
    'Drug': 'Epkinly',
    'Disease Group': 'Oncology',
    'Indication': 'Follicular Lymphoma (FL)',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'Cluster of Differentiation 20 (CD20), Cluster of Differentiation 3 (CD3)',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Subcutaneous (SQ) Injection',
    'Trial Name': '',
    'Source Link': 'https://www.globenewswire.com/news-release/2024/05/02/2874427/0/en/Genmab-Announces-Financial-Results-for-the-First-Quarter-of-2024.html'
  },
  {
    'Expected Begin Date': '2024-06-27',
    'Expected End Date': '2024-06-27',
    'Company': 'Celltrion, Inc.',
    'Country': 'Korea (South)',
    'Drug': 'Biosimilar Aflibercept (Celltrion)',
    'Disease Group': 'Ophthalmology',
    'Indication': 'Wet Age-Related Macular Degeneration (Wet AMD) (Ophthalmology)',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'Angiogenesis, Placental growth factor (PlGF), VEGF (Vascular endothelial growth factor)',
    'Drug Classification': 'Biosimilar',
    'Route of Administration': 'Intravitreal',
    'Trial Name': '',
    'Source Link': 'https://www.koreabiomed.com/news/articleView.html?idxno=21476'
  },
  {
    'Expected Begin Date': '2024-06-27',
    'Expected End Date': '2024-06-27',
    'Company': 'Celltrion, Inc.',
    'Country': 'Korea (South)',
    'Drug': 'Biosimilar Aflibercept (Celltrion)',
    'Disease Group': 'Ophthalmology',
    'Indication': 'Diabetic Macular Edema (Ophthalmology)',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'Angiogenesis, Placental growth factor (PlGF), VEGF (Vascular endothelial growth factor)',
    'Drug Classification': 'Biosimilar',
    'Route of Administration': 'Intravitreal',
    'Trial Name': '',
    'Source Link': 'https://www.koreabiomed.com/news/articleView.html?idxno=21476'
  },
  {
    'Expected Begin Date': '2024-06-28',
    'Expected End Date': '2024-06-28',
    'Company': 'Shorla Oncology',
    'Country': 'Ireland',
    'Drug': 'SH-105',
    'Disease Group': 'Oncology',
    'Indication': 'Breast Cancer',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'Unknown',
    'Drug Classification': 'Non-NME',
    'Route of Administration': 'Intravenous (IV)',
    'Trial Name': '',
    'Source Link': 'https://www.businesswire.com/news/home/20240109305168/en'
  },
  {
    'Expected Begin Date': '2024-06-28',
    'Expected End Date': '2024-06-28',
    'Company': 'Shorla Oncology',
    'Country': 'Ireland',
    'Drug': 'SH-105',
    'Disease Group': 'Oncology',
    'Indication': 'Ovarian Cancer',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'Unknown',
    'Drug Classification': 'Non-NME',
    'Route of Administration': 'Intravenous (IV)',
    'Trial Name': '',
    'Source Link': 'https://www.businesswire.com/news/home/20240109305168/en'
  },
  {
    'Expected Begin Date': '2024-06-29',
    'Expected End Date': '2024-06-29',
    'Company': 'Rocket Pharmaceuticals Inc.',
    'Country': 'United States',
    'Drug': 'Kresladi',
    'Disease Group': 'Autoimmune/immunology',
    'Indication': 'Autoimmune Disorders',
    'Phase': 'BLA',
    'Molecule': 'Viral Gene Therapy',
    'Target': 'Cluster of Differentiation 18 (CD18)',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'N/A',
    'Trial Name': '',
    'Source Link': 'https://www.businesswire.com/news/home/20240510805823/en'
  },
  {
    'Expected Begin Date': '2024-06-30',
    'Expected End Date': '2024-07-30',
    'Company': 'Sun Pharmaceutical Industries Ltd.',
    'Country': 'India',
    'Drug': 'CTP-543',
    'Disease Group': 'Dermatology',
    'Indication': 'Alopecia Areata',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'JAK/STAT ',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Oral (PO)',
    'Trial Name': '',
    'Source Link': 'https://sunpharma.com/wp-content/uploads/2024/01/Press-Release-Sun-Pharma-Q3FY24-Financial-Result.pdf'
  },
  {
    'Expected Begin Date': '2024-06-30',
    'Expected End Date': '2024-06-30',
    'Company': 'Arcturus Therapeutics Ltd.',
    'Country': 'United States',
    'Drug': 'ARCT-810',
    'Disease Group': 'Metabolic',
    'Indication': 'Ornithine Transcarbamylase (OTC) Deficiency',
    'Phase': 'I',
    'Molecule': 'siRNA/RNAi',
    'Target': 'Ornithine transcarbamylase',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'N/A',
    'Trial Name': 'Phase II - Multiple Dose (Europe)',
    'Source Link': 'https://www.businesswire.com/news/home/20240508223032/en/Arcturus-Therapeutics-Announces-First-Quarter-2024-Financial-Update-and-Pipeline-Progress'
  },
  {
    'Expected Begin Date': '2024-06-30',
    'Expected End Date': '2024-07-30',
    'Company': 'NFL Biosciences',
    'Country': 'France',
    'Drug': 'NFL-101',
    'Disease Group': 'Psychiatry',
    'Indication': 'Smoking Cessation',
    'Phase': 'Development Outside U.S.',
    'Molecule': 'Natural Protein',
    'Target': 'Unknown',
    'Drug Classification': 'Non-NME',
    'Route of Administration': 'Subcutaneous (SQ) Injection',
    'Trial Name': 'Phase II/III - CESTO2',
    'Source Link': 'https://www.nflbiosciences.com/_files/ugd/d7bc94_00f8ded02deb41e49547728930e1c09f.pdf'
  },
  {
    'Expected Begin Date': '2024-07-06',
    'Expected End Date': '2024-07-06',
    'Company': 'Arcutis Biotherapeutics, Inc.',
    'Country': 'United States',
    'Drug': 'Zoryve Topical Cream',
    'Disease Group': 'Allergy',
    'Indication': 'Atopic Dermatitis (Eczema)',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'Phosphodiesterase 4 (PDE4)',
    'Drug Classification': 'Non-NME',
    'Route of Administration': 'Topical',
    'Trial Name': '',
    'Source Link': 'https://www.globenewswire.com/news-release/2024/05/14/2881825/0/en/Arcutis-Announces-First-Quarter-2024-Financial-Results-and-Provides-Business-Update.html'
  },
  {
    'Expected Begin Date': '2024-07-15',
    'Expected End Date': '2024-07-15',
    'Company': 'argenx N.V.',
    'Country': 'Netherlands',
    'Drug': 'ARGX-117',
    'Disease Group': 'Neurology',
    'Indication': 'Multifocal Motor Neuropathy (MMN)',
    'Phase': 'II',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'Complement Pathway',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Subcutaneous (SQ) - Unspecified',
    'Trial Name': 'Phase II - ARDA',
    'Source Link': 'https://www.globenewswire.com/news-release/2024/06/17/2899320/0/en/argenx-to-unveil-its-Vision-2030-Taking-Breakthrough-Science-to-50-000-Patients-during-its-Upcoming-R-D-Day-on-July-16-2024.html'
  },
  {
    'Expected Begin Date': '2024-07-18',
    'Expected End Date': '2024-07-18',
    'Company': 'Phathom Pharmaceuticals, Inc.',
    'Country': 'United States',
    'Drug': 'Voquezna',
    'Disease Group': 'Gastroenterology (Non Inflammatory Bowel Disease)',
    'Indication': 'Gastroesophageal Reflux Disease (GERD)',
    'Phase': 'Approved',
    'Molecule': 'Small Molecule',
    'Target': 'Proton pump',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Oral (PO)',
    'Trial Name': '',
    'Source Link': 'https://investors.phathompharma.com/news-releases/news-release-details/phathom-pharmaceuticals-reports-first-quarter-2024-financial'
  },
  {
    'Expected Begin Date': '2024-07-26',
    'Expected End Date': '2024-07-26',
    'Company': 'Alpha Cognition, Inc.',
    'Country': 'Canada',
    'Drug': 'Alpha-1062',
    'Disease Group': 'Neurology',
    'Indication': "Alzheimer's Disease (AD)",
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'Cholinesterases',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Intranasal, Oral (PO)',
    'Trial Name': '',
    'Source Link': 'https://www.businesswire.com/news/home/20240212742730/en'
  },
  {
    'Expected Begin Date': '2024-07-26',
    'Expected End Date': '2024-07-26',
    'Company': 'Roche Holding AG',
    'Country': 'Switzerland',
    'Drug': 'Crovalimab',
    'Disease Group': 'Autoimmune/immunology',
    'Indication': 'Paroxysmal Nocturnal Hemoglobinuria (PNH)',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'Complement component 5 (C5), Complement Pathway',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Intravenous (IV), Subcutaneous (SQ) Injection',
    'Trial Name': '',
    'Source Link': 'https://assets.cwp.roche.com/f/176343/x/cbba56da34/irp240424-a.pdf'
  },
  {
    'Expected Begin Date': '2024-07-30',
    'Expected End Date': '2024-07-30',
    'Company': 'Xspray Pharma AB',
    'Country': 'Sweden',
    'Drug': 'Dasynoc',
    'Disease Group': 'Oncology',
    'Indication': 'Chronic Myelogenous Leukemia (CML)',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'BCR-ABL Fusion Protein, Src Kinase Family, Tyrosine Kinases',
    'Drug Classification': 'Non-NME',
    'Route of Administration': 'Oral (PO)',
    'Trial Name': '',
    'Source Link': 'https://xspraypharma.com/modular_finance_pressmeddelande/fda-accepts-xpray-pharmas-nda-resubmission-for-dasynoc-pdufa-date-set-to-31-july/'
  },
  {
    'Expected Begin Date': '2024-07-31',
    'Expected End Date': '2024-08-14',
    'Company': 'Diamyd Medical AB',
    'Country': 'Sweden',
    'Drug': 'Diamyd',
    'Disease Group': 'Endocrine',
    'Indication': 'Diabetes Mellitus, Type I',
    'Phase': 'III',
    'Molecule': 'Vaccine',
    'Target': 'Glutamic Acid Decarboxylase (GAD), Immune System',
    'Drug Classification': 'Vaccine',
    'Route of Administration': 'Subcutaneous (SQ) Injection',
    'Trial Name': 'Phase III - DIAGNODE-3',
    'Source Link': 'https://www.diamyd.com/docs/pressClips.aspx?ClipID=4825162'
  },
  {
    'Expected Begin Date': '2024-08-03',
    'Expected End Date': '2024-08-03',
    'Company': 'Adaptimmune Therapeutics plc',
    'Country': 'United Kingdom',
    'Drug': 'Afami-cel',
    'Disease Group': 'Oncology',
    'Indication': 'Synovial Sarcoma',
    'Phase': 'BLA',
    'Molecule': 'Cellular',
    'Target': 'Cluster of Differentiation 107a (CD107a)  / LAMP-1, Melanoma antigen-encoding gene (MAGE), Stem Cells/Other Cell Therapies, T-Cell Receptor (TCR)',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Intravenous (IV)',
    'Trial Name': '',
    'Source Link': 'https://www.newsfilecorp.com/release/203159/Results-of-Adaptimmunes-SPEARHEAD1-Trial-with-Afamicel-in-Advanced-Sarcomas-Published-in-the-Lancet?k='
  },
  {
    'Expected Begin Date': '2024-08-06',
    'Expected End Date': '2024-08-06',
    'Company': 'Amneal Pharmaceuticals, Inc.',
    'Country': 'United States',
    'Drug': 'IPX203',
    'Disease Group': 'Neurology',
    'Indication': "Parkinson's Disease (PD)",
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'Aromatic L-amino acid decarboxylase (AADC), Dopamine Receptor - Unspecified',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Oral (PO)',
    'Trial Name': '',
    'Source Link': 'https://s22.q4cdn.com/186279204/files/doc_financials/2024/q1/AMRX-Q1-2024-Presentation.pdf'
  },
  {
    'Expected Begin Date': '2024-08-09',
    'Expected End Date': '2024-08-09',
    'Company': 'Humacyte, Inc.',
    'Country': 'United States',
    'Drug': 'Humacyl',
    'Disease Group': 'Cardiovascular',
    'Indication': 'Peripheral Arterial Disease (PAD)',
    'Phase': 'BLA',
    'Molecule': 'Protein',
    'Target': 'Tissue Scaffolding',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Surgical Implantation',
    'Trial Name': '',
    'Source Link': 'https://www.globenewswire.com/news-release/2024/06/17/2899594/0/en/Centers-for-Medicare-Medicaid-Services-CMS-Issues-ICD-10-PCS-Codes-for-Humacyte-s-Human-Acellular-Vessel-HAV.html'
  },
  {
    'Expected Begin Date': '2024-08-10',
    'Expected End Date': '2024-08-10',
    'Company': 'Lykos Therapeutics',
    'Country': 'United States',
    'Drug': 'Midomafetamine',
    'Disease Group': 'Psychiatry',
    'Indication': 'Post-Traumatic Stress Disorder (PTSD)',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'Trace Amine Associated Receptor 1 (TAAR1)',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Oral (PO)',
    'Trial Name': '',
    'Source Link': 'https://www.prnewswire.com/news-releases/lykos-therapeutics-announces-completion-of-european-phase-2-study-for-mdma-assisted-therapy-for-ptsd-302126010.html'
  },
  {
    'Expected Begin Date': '2024-08-12',
    'Expected End Date': '2024-08-12',
    'Company': 'Citius Oncology, Inc.',
    'Country': 'United States',
    'Drug': 'Lymphir',
    'Disease Group': 'Oncology',
    'Indication': 'Cutaneous T-Cell Lymphoma (CTCL) - NHL',
    'Phase': 'BLA',
    'Molecule': 'Protein',
    'Target': 'IL-2 (Interleukin-2), Protein synthesis',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Intravenous (IV)',
    'Trial Name': '',
    'Source Link': 'https://www.prnewswire.com/news-releases/citius-pharmaceuticals-announces-fda-acceptance-of-the-bla-resubmission-of-lymphir-denileukin-diftitox-for-the-treatment-of-adults-with-relapsed-or-refractory-cutaneous-t-cell-lymphoma-302090911.html'
  },
  {
    'Expected Begin Date': '2024-08-13',
    'Expected End Date': '2024-08-13',
    'Company': 'Gilead Sciences, Inc.',
    'Country': 'United States',
    'Drug': 'Seladelpar',
    'Disease Group': 'Autoimmune/immunology',
    'Indication': 'Primary Biliary Cholangitis (PBC)',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'PPAR delta',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Oral (PO)',
    'Trial Name': '',
    'Source Link': 'https://www.businesswire.com/news/home/20240604334684/en'
  },
  {
    'Expected Begin Date': '2024-08-13',
    'Expected End Date': '2024-08-13',
    'Company': 'Galderma S.A.',
    'Country': 'Switzerland',
    'Drug': 'Nemolizumab',
    'Disease Group': 'Allergy',
    'Indication': 'Pruritus',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'IL-31/IL-31R (Interleukin-31/Interleukin-31 Receptor)',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Subcutaneous (SQ) Injection',
    'Trial Name': '',
    'Source Link': 'https://www.businesswire.com/news/home/20240213155885/en'
  },
  {
    'Expected Begin Date': '2024-08-13',
    'Expected End Date': '2024-08-13',
    'Company': 'Ascendis Pharma A/S',
    'Country': 'Denmark',
    'Drug': 'TransCon PTH',
    'Disease Group': 'Endocrine',
    'Indication': 'Hypoparathyroidism',
    'Phase': 'NDA',
    'Molecule': 'Peptide',
    'Target': 'Parathyroid Hormone Receptor (PTHR)',
    'Drug Classification': 'Non-NME',
    'Route of Administration': 'Subcutaneous (SQ) Injection',
    'Trial Name': '',
    'Source Link': 'https://www.globenewswire.com/news-release/2024/05/14/2881783/0/en/Ascendis-Pharma-Announces-Extension-of-U-S-Food-and-Drug-Administration-Review-Period-for-TransCon-PTH-for-Adults-with-Hypoparathyroidism.html'
  },
  {
    'Expected Begin Date': '2024-08-19',
    'Expected End Date': '2024-08-19',
    'Company': 'Les Laboratoires Servier',
    'Country': 'France',
    'Drug': 'Vorasidenib',
    'Disease Group': 'Oncology',
    'Indication': 'Brain Cancer (Malignant Glioma; AA and glioblastoma (GBM))',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'Isocitrate Dehydrogenase (IDH)',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Oral (PO)',
    'Trial Name': '',
    'Source Link': 'https://www.globenewswire.com/news-release/2024/05/02/2874006/31990/en/Agios-Reports-Business-Highlights-and-First-Quarter-2024-Financial-Results.html'
  },
  {
    'Expected Begin Date': '2024-08-21',
    'Expected End Date': '2024-08-21',
    'Company': 'Regeneron Pharmaceuticals, Inc.',
    'Country': 'United States',
    'Drug': 'Linvoseltamab',
    'Disease Group': 'Oncology',
    'Indication': 'Multiple Myeloma (MM)',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'B-cell maturation antigen (BCMA), Cluster of Differentiation 3 (CD3), Immune System',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Intravenous (IV)',
    'Trial Name': '',
    'Source Link': 'https://www.globenewswire.com/news-release/2024/04/07/2858843/0/en/Linvoseltamab-Pivotal-Data-Presented-at-AACR-Reinforce-High-Response-Rate-that-Deepens-Over-Time-in-Patients-with-Heavily-Pre-Treated-Multiple-Myeloma.html'
  },
  {
    'Expected Begin Date': '2024-08-27',
    'Expected End Date': '2024-08-27',
    'Company': 'Syndax Pharmaceuticals, Inc.',
    'Country': 'United States',
    'Drug': 'Axatilimab',
    'Disease Group': 'Autoimmune/immunology',
    'Indication': 'Graft vs. Host Disease (GVHD) - Treatment',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'Immune System, Macrophage Colony Stimulating Factor 1 (M-CSF1)/M-CSF Receptor/FMS/CD115/CSF-1/CSF1R',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Intravenous (IV)',
    'Trial Name': '',
    'Source Link': 'https://ir.syndax.com/news-releases/news-release-details/syndax-pharmaceuticals-reports-first-quarter-2024-financial'
  },
  {
    'Expected Begin Date': '2024-08-31',
    'Expected End Date': '2024-09-29',
    'Company': 'Merck & Co., Inc.',
    'Country': 'United States',
    'Drug': 'MK-3475A',
    'Disease Group': 'Oncology',
    'Indication': 'Non-Small Cell Lung Cancer (NSCLC)',
    'Phase': 'III',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'Hyaluronic acid, Programmed death-1 receptor (PD-1)',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Intravenous (IV), Subcutaneous (SQ) - Unspecified',
    'Trial Name': 'Phase III - MK-3475A-D77 (SC vs. IV)',
    'Source Link': 'https://s21.q4cdn.com/488056881/files/doc_events/2024/Mar/12/mrk-usq_transcript_2024-03-12.pdf'
  },
  {
    'Expected Begin Date': '2024-09-04',
    'Expected End Date': '2024-09-04',
    'Company': 'Travere Therapeutics, Inc.',
    'Country': 'United States',
    'Drug': 'Filspari',
    'Disease Group': 'Renal',
    'Indication': "Immunoglobulin A (IgA) Nephropathy (Berger's Disease)",
    'Phase': 'Approved',
    'Molecule': 'Small Molecule',
    'Target': 'Angiotensin II Receptor Type 1 (AT1), Endothelin Receptor Type A (EDNRA)',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Oral (PO)',
    'Trial Name': '',
    'Source Link': 'https://ir.travere.com/news-releases/news-release-details/travere-therapeutics-reports-first-quarter-2024-financial'
  },
  {
    'Expected Begin Date': '2024-09-05',
    'Expected End Date': '2024-09-05',
    'Company': 'Emergent BioSolutions, Inc.',
    'Country': 'United States',
    'Drug': 'ACAM2000',
    'Disease Group': 'Infectious Disease',
    'Indication': 'Monkeypox (Mpox)',
    'Phase': 'BLA',
    'Molecule': 'Vaccine',
    'Target': 'Immune System, Smallpox virus',
    'Drug Classification': 'Vaccine',
    'Route of Administration': 'Percutaneous Catheter/Injection',
    'Trial Name': '',
    'Source Link': ''
  },
  {
    'Expected Begin Date': '2024-09-12',
    'Expected End Date': '2024-09-12',
    'Company': 'Roche Holding AG',
    'Country': 'Switzerland',
    'Drug': 'Ocrevus (Subcutaneous)',
    'Disease Group': 'Neurology',
    'Indication': 'Multiple Sclerosis (MS)',
    'Phase': 'NDA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'Cluster of Differentiation 20 (CD20)',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Subcutaneous (SQ) Injection',
    'Trial Name': '',
    'Source Link': 'https://assets.cwp.roche.com/f/176343/x/cbba56da34/irp240424-a.pdf'
  },
  {
    'Expected Begin Date': '2024-09-14',
    'Expected End Date': '2024-09-14',
    'Company': 'Roche Holding AG',
    'Country': 'Switzerland',
    'Drug': 'Tecentriq (Subcutaneous)',
    'Disease Group': 'Oncology',
    'Indication': 'Small Cell Lung Cancer (SCLC)',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'Immune System, Programmed death-ligand 1 (PD-L1)',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Subcutaneous (SQ) - Unspecified',
    'Trial Name': '',
    'Source Link': 'https://assets.cwp.roche.com/f/176343/x/cbba56da34/irp240424-a.pdf'
  },
  {
    'Expected Begin Date': '2024-09-14',
    'Expected End Date': '2024-09-14',
    'Company': 'Roche Holding AG',
    'Country': 'Switzerland',
    'Drug': 'Tecentriq (Subcutaneous)',
    'Disease Group': 'Oncology',
    'Indication': 'Non-Small Cell Lung Cancer (NSCLC)',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'Immune System, Programmed death-ligand 1 (PD-L1)',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Subcutaneous (SQ) - Unspecified',
    'Trial Name': '',
    'Source Link': 'https://assets.cwp.roche.com/f/176343/x/cbba56da34/irp240424-a.pdf'
  },
  {
    'Expected Begin Date': '2024-09-14',
    'Expected End Date': '2024-09-14',
    'Company': 'Roche Holding AG',
    'Country': 'Switzerland',
    'Drug': 'Tecentriq (Subcutaneous)',
    'Disease Group': 'Oncology',
    'Indication': 'Melanoma',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'Immune System, Programmed death-ligand 1 (PD-L1)',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Subcutaneous (SQ) - Unspecified',
    'Trial Name': '',
    'Source Link': 'https://assets.cwp.roche.com/f/176343/x/cbba56da34/irp240424-a.pdf'
  },
  {
    'Expected Begin Date': '2024-09-14',
    'Expected End Date': '2024-09-14',
    'Company': 'Roche Holding AG',
    'Country': 'Switzerland',
    'Drug': 'Tecentriq (Subcutaneous)',
    'Disease Group': 'Oncology',
    'Indication': 'Hepatocellular (Liver) Cancer (HCC) (Including Secondary Metastases)',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'Immune System, Programmed death-ligand 1 (PD-L1)',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Subcutaneous (SQ) - Unspecified',
    'Trial Name': '',
    'Source Link': 'https://assets.cwp.roche.com/f/176343/x/cbba56da34/irp240424-a.pdf'
  },
  {
    'Expected Begin Date': '2024-09-14',
    'Expected End Date': '2024-09-14',
    'Company': 'Roche Holding AG',
    'Country': 'Switzerland',
    'Drug': 'Tecentriq (Subcutaneous)',
    'Disease Group': 'Oncology',
    'Indication': 'Alveolar Soft Part Sarcoma (ASPS)',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'Immune System, Programmed death-ligand 1 (PD-L1)',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Subcutaneous (SQ) - Unspecified',
    'Trial Name': '',
    'Source Link': 'https://assets.cwp.roche.com/f/176343/x/cbba56da34/irp240424-a.pdf'
  },
  {
    'Expected Begin Date': '2024-09-17',
    'Expected End Date': '2024-09-17',
    'Company': 'Vanda Pharmaceuticals, Inc.',
    'Country': 'United States',
    'Drug': 'Tradipitant',
    'Disease Group': 'Gastroenterology (Non Inflammatory Bowel Disease)',
    'Indication': 'Gastroparesis Therapy',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'Neurokinin Receptor',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Oral (PO)',
    'Trial Name': '',
    'Source Link': 'https://vandapharmaceuticalsinc.gcs-web.com/node/15931/pdf'
  },
  {
    'Expected Begin Date': '2024-09-20',
    'Expected End Date': '2024-09-20',
    'Company': 'Zevra Therapeutics, Inc.',
    'Country': 'United States',
    'Drug': 'Miplyffa',
    'Disease Group': 'Metabolic',
    'Indication': 'Niemann-Pick Disease Type C',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'SOD1',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Oral (PO)',
    'Trial Name': '',
    'Source Link': 'https://www.globenewswire.com/news-release/2024/05/09/2878664/7281/en/XOMA-Reports-First-Quarter-2024-Financial-Results-and-Highlights-Recent-Activities.html'
  },
  {
    'Expected Begin Date': '2024-09-23',
    'Expected End Date': '2024-09-23',
    'Company': 'IntraBio Inc.',
    'Country': 'United Kingdom',
    'Drug': 'IB1001',
    'Disease Group': 'Metabolic',
    'Indication': 'Niemann-Pick Disease Type C',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': '',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Oral (PO)',
    'Trial Name': '',
    'Source Link': 'https://www.accesswire.com/846963/intrabio-announces-us-fda-accepts-new-drug-application-for-ib1001-for-the-treatment-of-niemann-pick-disease-type-c'
  },
  {
    'Expected Begin Date': '2024-09-24',
    'Expected End Date': '2024-09-24',
    'Company': 'Merck & Co., Inc.',
    'Country': 'United States',
    'Drug': 'Keytruda',
    'Disease Group': 'Oncology',
    'Indication': 'Mesothelioma',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'Immune System, Programmed death-1 receptor (PD-1)',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Intravenous (IV)',
    'Trial Name': '',
    'Source Link': 'https://www.businesswire.com/news/home/20240529865307/en'
  },
  {
    'Expected Begin Date': '2024-09-25',
    'Expected End Date': '2024-09-25',
    'Company': 'Bristol Myers Squibb Company',
    'Country': 'United States',
    'Drug': 'KarXT',
    'Disease Group': 'Psychiatry',
    'Indication': 'Schizophrenia',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'Muscarinic acetylcholine receptor, Muscarinic M1 receptor',
    'Drug Classification': 'Non-NME',
    'Route of Administration': 'Oral (PO)',
    'Trial Name': '',
    'Source Link': 'https://www.bms.com/assets/bms/us/en-us/pdf/investor-info/doc_presentations/2024/BMY-2024-Q1-Results-Investor-Presentation.pdf'
  },
  {
    'Expected Begin Date': '2024-09-25',
    'Expected End Date': '2024-09-25',
    'Company': 'Syndax Pharmaceuticals, Inc.',
    'Country': 'United States',
    'Drug': 'Revumenib',
    'Disease Group': 'Oncology',
    'Indication': 'Acute Myelogenous Leukemia (AML)',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'Menin, Mixed Lineage Leukemia (MLL)',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Oral (PO)',
    'Trial Name': '',
    'Source Link': 'https://www.prnewswire.com/news-releases/actinium-presents-first-ever-data-demonstrating-actimab-a-in-combination-with-leading-menin-inhibitors-leads-to-anti-tumor-control-and-potent-leukemic-cell-killing-in-preclinical-acute-myeloid-leukemia-models-at-the-2024-eha-congr-302173741.html'
  },
  {
    'Expected Begin Date': '2024-09-26',
    'Expected End Date': '2024-09-26',
    'Company': 'Sanofi',
    'Country': 'France',
    'Drug': 'Dupixent',
    'Disease Group': 'Respiratory',
    'Indication': 'Chronic Obstructive Pulmonary Disease (COPD)',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'IL-13 (Interleukin-13), IL-4 Receptor (IL-4R)',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Subcutaneous (SQ) Injection',
    'Trial Name': '',
    'Source Link': 'https://www.globenewswire.com/news-release/2024/05/31/2891251/0/en/Update-on-FDA-Priority-Review-of-Dupixent-dupilumab-for-the-Treatment-of-COPD-Patients-with-Type-2-Inflammation.html'
  },
  {
    'Expected Begin Date': '2024-10-01',
    'Expected End Date': '2024-10-01',
    'Company': 'ARS Pharmaceuticals, Inc.',
    'Country': 'United States',
    'Drug': 'Neffy',
    'Disease Group': 'Allergy',
    'Indication': 'Anaphylaxis',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'Alpha Adrenergic Receptors, Beta Adrenergic Receptors',
    'Drug Classification': 'Non-NME',
    'Route of Administration': 'Intranasal',
    'Trial Name': '',
    'Source Link': 'https://www.globenewswire.com/news-release/2024/04/03/2857016/0/en/ARS-Pharmaceuticals-Submits-Response-to-FDA-Complete-Response-Letter-for-neffy-Epinephrine-Nasal-Spray.html'
  },
  {
    'Expected Begin Date': '2024-10-07',
    'Expected End Date': '2024-10-07',
    'Company': 'Bristol Myers Squibb Company',
    'Country': 'United States',
    'Drug': 'Opdivo',
    'Disease Group': 'Oncology',
    'Indication': 'Non-Small Cell Lung Cancer (NSCLC)',
    'Phase': 'Approved',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'Immune System, Programmed death-1 receptor (PD-1)',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Intravenous (IV)',
    'Trial Name': '',
    'Source Link': 'https://www.businesswire.com/news/home/20240206288537/en'
  },
  {
    'Expected Begin Date': '2024-10-07',
    'Expected End Date': '2024-10-07',
    'Company': 'Novo Nordisk A/S',
    'Country': 'Denmark',
    'Drug': 'Zegalogue',
    'Disease Group': 'Endocrine',
    'Indication': 'Congenital Hyperinsulinism (CHI)',
    'Phase': 'NDA',
    'Molecule': 'Peptide',
    'Target': 'Glucagon Receptor',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Subcutaneous (SQ) Injection',
    'Trial Name': '',
    'Source Link': 'https://www.globenewswire.com/news-release/2024/05/16/2883075/0/en/Zealand-Pharma-Announces-Financial-Results-for-the-First-Quarter-of-2024.html'
  },
  {
    'Expected Begin Date': '2024-10-08',
    'Expected End Date': '2024-10-08',
    'Company': 'Luye Pharma Group, Ltd.',
    'Country': 'China',
    'Drug': 'LY03010',
    'Disease Group': 'Psychiatry',
    'Indication': 'Schizophrenia',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'Alpha 1 Adrenergic Receptor , Alpha 2 Adrenergic Receptor, Histamine H1 Receptor (HRH1), Serotonin 5-HT2A receptor, Serotonin 5-HT2B receptor',
    'Drug Classification': 'Non-NME',
    'Route of Administration': 'Intramuscular (IM)',
    'Trial Name': '',
    'Source Link': 'https://www.luye.cn/lvye_en/uploads/2023-10/12/_1697079565_v9lhyg.pdf'
  },
  {
    'Expected Begin Date': '2024-10-20',
    'Expected End Date': '2024-10-20',
    'Company': 'Camurus AB',
    'Country': 'Sweden',
    'Drug': 'Oclaiz',
    'Disease Group': 'Endocrine',
    'Indication': 'Acromegaly',
    'Phase': 'NDA',
    'Molecule': 'Peptide',
    'Target': 'Somatostatin Receptors',
    'Drug Classification': 'Non-NME',
    'Route of Administration': 'Subcutaneous (SQ) Injection',
    'Trial Name': '',
    'Source Link': 'https://www.camurus.com/media/press-releases/2023/camurus-submits-new-drug-application-to-the-us-fda-for-oclaiz-for-treatment-of-acromegaly/#:~:text=Lund%2C%20Sweden%20%E2%80%94%2021%20December%202023,treatment%20of%20patients%20with%20acromegaly.'
  },
  {
    'Expected Begin Date': '2024-10-28',
    'Expected End Date': '2024-10-28',
    'Company': 'PharmaTher, Inc.',
    'Country': 'Canada',
    'Drug': 'Ketarx',
    'Disease Group': 'Neurology',
    'Indication': 'Anesthesia',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'NMDA Glutamate Receptor',
    'Drug Classification': 'Non-NME',
    'Route of Administration': 'Intravenous (IV)',
    'Trial Name': '',
    'Source Link': 'https://www.globenewswire.com/news-release/2024/05/10/2879568/0/en/PharmaTher-Announces-Assigned-FDA-Approval-Goal-Date-of-its-Priority-Original-Abbreviated-New-Drug-Application-for-Ketamine.html'
  },
  {
    'Expected Begin Date': '2024-10-29',
    'Expected End Date': '2024-10-29',
    'Company': 'Medexus Pharmaceuticals, Inc.',
    'Country': 'Canada',
    'Drug': 'Ovastat',
    'Disease Group': 'Oncology',
    'Indication': 'Bone Marrow Transplant and Stem Cell Transplant',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'DNA',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Intravenous (IV), Oral (PO)',
    'Trial Name': '',
    'Source Link': 'https://www.newsfilecorp.com/release/211933/FDA-Accepts-for-Review-Treosulfan-NDA-Resubmission?k=Medexus'
  },
  {
    'Expected Begin Date': '2024-10-31',
    'Expected End Date': '2024-11-29',
    'Company': 'Syros Pharmaceuticals, Inc.',
    'Country': 'United States',
    'Drug': 'Tamibarotene',
    'Disease Group': 'Oncology',
    'Indication': 'Myelodysplastic Syndrome (MDS)',
    'Phase': 'III',
    'Molecule': 'Small Molecule',
    'Target': 'Retinoic acid receptor (RARs)',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Oral (PO)',
    'Trial Name': 'Phase III - SELECT-MDS-1 (RARA+, 1st Line)',
    'Source Link': 'https://www.businesswire.com/news/home/20240613264695/en'
  },
  {
    'Expected Begin Date': '2024-10-31',
    'Expected End Date': '2024-11-29',
    'Company': 'MaaT Pharma SA',
    'Country': 'France',
    'Drug': 'MaaT013',
    'Disease Group': 'Autoimmune/immunology',
    'Indication': 'Graft vs. Host Disease (GVHD) - Treatment',
    'Phase': 'IND',
    'Molecule': 'Bacterial Product',
    'Target': 'Microbiota (flora,  microbiome) excluding the digestive tract',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Rectal',
    'Trial Name': 'Phase III - ARES',
    'Source Link': 'https://www.businesswire.com/news/home/20240507829097/en/MaaT-Pharma-Provides-a-Business-Update-and-Highlights-Key-Milestones-Expected-in-2024'
  },
  {
    'Expected Begin Date': '2024-10-31',
    'Expected End Date': '2024-11-29',
    'Company': 'Johnson & Johnson',
    'Country': 'United States',
    'Drug': 'JNJ-2113',
    'Disease Group': 'Autoimmune/immunology',
    'Indication': 'Psoriasis',
    'Phase': 'III',
    'Molecule': 'Peptide',
    'Target': 'IL-23 (Interleukin-23)  ',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Oral (PO)',
    'Trial Name': 'Phase III - ICONIC-LEAD',
    'Source Link': ''
  },
  {
    'Expected Begin Date': '2024-10-31',
    'Expected End Date': '2024-11-29',
    'Company': 'Johnson & Johnson',
    'Country': 'United States',
    'Drug': 'JNJ-2113',
    'Disease Group': 'Autoimmune/immunology',
    'Indication': 'Psoriasis',
    'Phase': 'III',
    'Molecule': 'Peptide',
    'Target': 'IL-23 (Interleukin-23)  ',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Oral (PO)',
    'Trial Name': 'Phase III - ICONIC-TOTAL',
    'Source Link': ''
  },
  {
    'Expected Begin Date': '2024-10-31',
    'Expected End Date': '2024-10-31',
    'Company': 'Zhuhai Beihai Biotechnology Co., Ltd.',
    'Country': 'China',
    'Drug': 'BH-009',
    'Disease Group': 'Oncology',
    'Indication': 'Solid Tumors',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'Microtubules (Tubulin)',
    'Drug Classification': 'Non-NME',
    'Route of Administration': 'Intravenous (IV)',
    'Trial Name': '',
    'Source Link': 'https://mp.weixin.qq.com/s/ZjTH3H_HhYr6QZzH8BICFw'
  },
  {
    'Expected Begin Date': '2024-11-03',
    'Expected End Date': '2024-11-03',
    'Company': 'Journey Medical Corp.',
    'Country': '',
    'Drug': 'Minolira',
    'Disease Group': 'Dermatology',
    'Indication': 'Rosacea',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'Bacterial ribosome, Protein synthesis',
    'Drug Classification': 'Non-NME',
    'Route of Administration': 'Oral (PO)',
    'Trial Name': '',
    'Source Link': 'https://www.globenewswire.com/news-release/2024/03/18/2847801/0/en/Journey-Medical-Corporation-Announces-U-S-FDA-Acceptance-of-New-Drug-Application-for-DFD-29-for-the-Treatment-of-Rosacea.html'
  },
  {
    'Expected Begin Date': '2024-11-08',
    'Expected End Date': '2024-11-08',
    'Company': 'Astellas Pharma, Inc.',
    'Country': 'Japan',
    'Drug': 'VYLOY',
    'Disease Group': 'Oncology',
    'Indication': 'Gastric Cancer',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'Claudin 18 (CLDN18), GC182 (CLDN18.2)',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Intravenous (IV)',
    'Trial Name': '',
    'Source Link': 'https://www.prnewswire.com/news-releases/us-fda-acknowledges-astellas-resubmission-of-biologics-license-application-for-zolbetuximab-and-sets-new-action-date-302160157.html'
  },
  {
    'Expected Begin Date': '2024-11-12',
    'Expected End Date': '2024-11-12',
    'Company': 'PTC Therapeutics, Inc.',
    'Country': 'United States',
    'Drug': 'Upstaza',
    'Disease Group': 'Neurology',
    'Indication': 'Neurology - Other',
    'Phase': 'BLA',
    'Molecule': 'Viral Gene Therapy',
    'Target': 'Aromatic L-amino acid decarboxylase (AADC)',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Intracerebral/cerebroventricular',
    'Trial Name': '',
    'Source Link': 'https://www.prnewswire.com/news-releases/ptc-therapeutics-announces-fda-acceptance-and-priority-review-of-the-bla-for-upstaza-302144369.html'
  },
  {
    'Expected Begin Date': '2024-11-26',
    'Expected End Date': '2024-11-26',
    'Company': 'Roche Holding AG',
    'Country': 'Switzerland',
    'Drug': 'Inavolisib',
    'Disease Group': 'Oncology',
    'Indication': 'HR+/HER2- Breast Cancer',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'PI3K/AKT pathway',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Oral (PO)',
    'Trial Name': '',
    'Source Link': 'https://www.businesswire.com/news/home/20240528294074/en/'
  },
  {
    'Expected Begin Date': '2024-11-27',
    'Expected End Date': '2024-11-27',
    'Company': 'Applied Therapeutics Inc.',
    'Country': 'United States',
    'Drug': 'AT-007',
    'Disease Group': 'Metabolic',
    'Indication': 'Metabolic - General',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'Aldose Reductase',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Oral (PO)',
    'Trial Name': '',
    'Source Link': 'https://www.globenewswire.com/news-release/2024/03/28/2854501/0/en/Applied-Therapeutics-Provides-FDA-Update-on-PDUFA-Target-Action-Date-for-Govorestat-for-the-Treatment-of-Classic-Galactosemia.html'
  },
  {
    'Expected Begin Date': '2024-11-28',
    'Expected End Date': '2024-11-28',
    'Company': 'Jazz Pharmaceuticals plc',
    'Country': 'Ireland',
    'Drug': 'Zanidatamab',
    'Disease Group': 'Oncology',
    'Indication': 'Biliary Tract Cancer',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'HER2/neu or ErbB-2',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Intravenous (IV)',
    'Trial Name': '',
    'Source Link': 'https://www.prnewswire.com/news-releases/zanidatamab-granted-priority-review-for-her2-positive-metastatic-biliary-tract-cancer-302157979.html'
  },
  {
    'Expected Begin Date': '2024-11-28',
    'Expected End Date': '2024-11-28',
    'Company': 'BridgeBio Pharma, Inc.',
    'Country': 'United States',
    'Drug': 'Acoramidis',
    'Disease Group': 'Metabolic',
    'Indication': 'Transthyretin Amyloid Cardiomyopathy (ATTR-CM, Wild Type Or Hereditary)',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'Transthyretin (TTR)',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Oral (PO)',
    'Trial Name': '',
    'Source Link': 'https://www.globenewswire.com/en/news-release/2024/05/29/2890251/0/en/Additional-Data-Showing-Acoramidis-Increases-Serum-Transthyretin-Which-is-Associated-with-Improved-Cardiovascular-Outcomes-Presented-at-ISA-from-BridgeBio-Pharma-s-Phase-3-ATTRibut.html'
  },
  {
    'Expected Begin Date': '2024-11-28',
    'Expected End Date': '2024-11-28',
    'Company': 'Novo Nordisk A/S',
    'Country': 'Denmark',
    'Drug': 'Wegovy',
    'Disease Group': 'Cardiovascular',
    'Indication': 'Chronic Heart Failure - Preserved Ejection Fraction (Chronic HFpEF)',
    'Phase': 'NDA',
    'Molecule': 'Peptide',
    'Target': 'GLP-1 Receptor',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Subcutaneous (SQ) Injection',
    'Trial Name': '',
    'Source Link': 'https://www.sec.gov/Archives/edgar/data/353278/000162828024002639/caq42023.htm'
  },
  {
    'Expected Begin Date': '2024-11-29',
    'Expected End Date': '2024-11-29',
    'Company': 'Shorla Oncology',
    'Country': 'Ireland',
    'Drug': 'SH-201',
    'Disease Group': 'Oncology',
    'Indication': 'Acute Lymphoblastic Leukemia (ALL)',
    'Phase': 'NDA',
    'Molecule': 'Not Specified',
    'Target': 'Chemotherapy Agents',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Oral (PO)',
    'Trial Name': '',
    'Source Link': 'https://www.businesswire.com/news/home/20240408742912/en/Shorla-Oncology-Announces-FDA-Filing-Acceptance-of-New-Drug-Application-to-Treat-Certain-Forms-of-Leukemia-and-Other-Cancers'
  },
  {
    'Expected Begin Date': '2024-11-30',
    'Expected End Date': '2024-12-30',
    'Company': 'Percheron Therapeutics Limited',
    'Country': 'Australia',
    'Drug': 'ATL1102',
    'Disease Group': 'Metabolic',
    'Indication': 'Duchenne Muscular Dystrophy (DMD)',
    'Phase': 'Preclinical',
    'Molecule': 'Antisense',
    'Target': 'Integrin Alpha-4 beta-1/VLA-4',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Inhaled, Subcutaneous (SQ) Injection',
    'Trial Name': 'Phase IIb - Open Label Extension Study',
    'Source Link': 'https://per.live.irmau.com/pdf/ff204e4c-2743-4ef6-8a79-48651bc5f721/Phase-IIb-study-of-ATL1102-in-DMD-completes-recruitment.pdf'
  },
  {
    'Expected Begin Date': '2024-11-30',
    'Expected End Date': '2024-12-30',
    'Company': 'CervoMed, Inc.',
    'Country': 'United States',
    'Drug': 'Neflamapimod',
    'Disease Group': 'Neurology',
    'Indication': 'Lewy Body Dementia (LBD)',
    'Phase': 'II',
    'Molecule': 'Small Molecule',
    'Target': 'p38 MAP kinase (MAPK)',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Oral (PO)',
    'Trial Name': 'Phase IIb - RewinD-LB',
    'Source Link': 'https://www.globenewswire.com/news-release/2024/06/11/2896702/0/en/CervoMed-Announces-Completion-of-Enrollment-in-Phase-2b-RewinD-LB-Clinical-Trial-of-Neflamapimod-for-the-Treatment-of-Patients-with-Dementia-with-Lewy-Bodies.html'
  },
  {
    'Expected Begin Date': '2024-11-30',
    'Expected End Date': '2024-12-30',
    'Company': 'BeiGene, Ltd.',
    'Country': 'China',
    'Drug': 'Tevimbra',
    'Disease Group': 'Oncology',
    'Indication': 'Gastric Cancer',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'Immune System, Programmed death-1 receptor (PD-1)',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Intravenous (IV)',
    'Trial Name': '',
    'Source Link': 'https://www.businesswire.com/news/home/20240314638305/en/BeiGene-Receives-FDA-Approval-for-TEVIMBRA%C2%AE-for-the-Treatment-of-Advanced-or-Metastatic-Esophageal-Squamous-Cell-Carcinoma-After-Prior-Chemotherapy'
  },
  {
    'Expected Begin Date': '2024-11-30',
    'Expected End Date': '2024-12-30',
    'Company': 'Lipidor AB',
    'Country': 'Sweden',
    'Drug': 'AKP-02',
    'Disease Group': 'Autoimmune/immunology',
    'Indication': 'Psoriasis',
    'Phase': 'Development Outside U.S.',
    'Molecule': 'Small Molecule',
    'Target': 'Unknown',
    'Drug Classification': 'Non-NME',
    'Route of Administration': 'Topical',
    'Trial Name': '',
    'Source Link': 'https://lipidor.se/nyheter/lipidor-signs-agreement-for-clinical-phase-iii-study/'
  },
  {
    'Expected Begin Date': '2024-12-12',
    'Expected End Date': '2024-12-12',
    'Company': 'Dermavant Sciences Inc.',
    'Country': 'United States',
    'Drug': 'Tapinarof',
    'Disease Group': 'Allergy',
    'Indication': 'Atopic Dermatitis (Eczema)',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'Aryl hydrocarbon receptor (AhR), Immune System',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Topical',
    'Trial Name': '',
    'Source Link': 'https://www.businesswire.com/news/home/20240429884934/en'
  },
  {
    'Expected Begin Date': '2024-12-13',
    'Expected End Date': '2024-12-13',
    'Company': 'Galderma S.A.',
    'Country': 'Switzerland',
    'Drug': 'Nemolizumab',
    'Disease Group': 'Allergy',
    'Indication': 'Atopic Dermatitis (Eczema)',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'IL-31/IL-31R (Interleukin-31/Interleukin-31 Receptor)',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Subcutaneous (SQ) Injection',
    'Trial Name': '',
    'Source Link': 'https://www.businesswire.com/news/home/20240213155885/en'
  },
  {
    'Expected Begin Date': '2024-12-16',
    'Expected End Date': '2024-12-16',
    'Company': 'Neurotech Pharmaceuticals, Inc.',
    'Country': 'United States',
    'Drug': 'Renexus',
    'Disease Group': 'Ophthalmology',
    'Indication': 'Macular Telangiectasia',
    'Phase': 'BLA',
    'Molecule': 'Protein',
    'Target': 'Ciliary Neurotrophic Factor Receptor (CNTFR)',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Intraocular/Subretinal/Subconjunctival',
    'Trial Name': '',
    'Source Link': 'https://www.businesswire.com/news/home/20240620620734/en'
  },
  {
    'Expected Begin Date': '2024-12-17',
    'Expected End Date': '2024-12-17',
    'Company': 'CSPC Pharmaceutical Group Limited',
    'Country': 'China',
    'Drug': 'Irinotecan Liposome (CSPC)',
    'Disease Group': 'Oncology',
    'Indication': 'Pancreatic Cancer',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule with Liposomal Delivery System',
    'Target': 'Topoisomerase I (Topo-I)',
    'Drug Classification': 'Non-NME',
    'Route of Administration': 'Intravenous (IV)',
    'Trial Name': '',
    'Source Link': 'https://doc.irasia.com/listco/hk/cspc/announcement/a231218.pdf'
  },
  {
    'Expected Begin Date': '2024-12-19',
    'Expected End Date': '2024-12-19',
    'Company': 'Lexicon Pharmaceuticals, Inc.',
    'Country': 'United States',
    'Drug': 'Inpefa',
    'Disease Group': 'Endocrine',
    'Indication': 'Diabetes Mellitus, Type I',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'SGLT',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Oral (PO)',
    'Trial Name': '',
    'Source Link': 'https://www.globenewswire.com/news-release/2024/06/21/2902250/0/en/Lexicon-Pharmaceuticals-Resubmits-Sotagliflozin-NDA-for-Type-1-Diabetes.html'
  },
  {
    'Expected Begin Date': '2024-12-19',
    'Expected End Date': '2024-12-19',
    'Company': 'Zealand Pharma A/S',
    'Country': 'Denmark',
    'Drug': 'Glepaglutide',
    'Disease Group': 'Gastroenterology (Non Inflammatory Bowel Disease)',
    'Indication': 'Short Bowel Syndrome (SBS)',
    'Phase': 'NDA',
    'Molecule': 'Peptide',
    'Target': 'GLP-2 Receptor',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Subcutaneous (SQ) Injection',
    'Trial Name': '',
    'Source Link': 'https://www.globenewswire.com/news-release/2024/05/16/2883075/0/en/Zealand-Pharma-Announces-Financial-Results-for-the-First-Quarter-of-2024.html'
  },
  {
    'Expected Begin Date': '2024-12-19',
    'Expected End Date': '2024-12-19',
    'Company': 'Daiichi Sankyo Co., Ltd.',
    'Country': 'Japan',
    'Drug': 'Datopotamab Deruxtecan',
    'Disease Group': 'Oncology',
    'Indication': 'Non-Small Cell Lung Cancer (NSCLC)',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'Trop-2',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Intravenous (IV)',
    'Trial Name': '',
    'Source Link': 'https://www.businesswire.com/news/home/20240216552444/en'
  },
  {
    'Expected Begin Date': '2024-12-26',
    'Expected End Date': '2024-12-26',
    'Company': 'Bristol Myers Squibb Company',
    'Country': 'United States',
    'Drug': 'Opdivo (Subcutaneous)',
    'Disease Group': 'Oncology',
    'Indication': 'Solid Tumors',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'Immune System, Programmed death-1 receptor (PD-1)',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Subcutaneous (SQ) - Unspecified',
    'Trial Name': '',
    'Source Link': 'https://www.businesswire.com/news/home/20240520853296/en'
  },
  {
    'Expected Begin Date': '2024-12-26',
    'Expected End Date': '2024-12-26',
    'Company': 'Bristol Myers Squibb Company',
    'Country': 'United States',
    'Drug': 'Opdivo (Subcutaneous)',
    'Disease Group': 'Oncology',
    'Indication': 'Melanoma',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'Immune System, Programmed death-1 receptor (PD-1)',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Subcutaneous (SQ) - Unspecified',
    'Trial Name': '',
    'Source Link': 'https://www.businesswire.com/news/home/20240520853296/en'
  },
  {
    'Expected Begin Date': '2024-12-26',
    'Expected End Date': '2024-12-26',
    'Company': 'Bristol Myers Squibb Company',
    'Country': 'United States',
    'Drug': 'Opdivo (Subcutaneous)',
    'Disease Group': 'Oncology',
    'Indication': 'Renal Cell Cancer (RCC)',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'Immune System, Programmed death-1 receptor (PD-1)',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Subcutaneous (SQ) - Unspecified',
    'Trial Name': '',
    'Source Link': 'https://www.businesswire.com/news/home/20240520853296/en'
  },
  {
    'Expected Begin Date': '2024-12-26',
    'Expected End Date': '2024-12-26',
    'Company': 'Bristol Myers Squibb Company',
    'Country': 'United States',
    'Drug': 'Opdivo (Subcutaneous)',
    'Disease Group': 'Oncology',
    'Indication': 'Bladder Cancer',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'Immune System, Programmed death-1 receptor (PD-1)',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Subcutaneous (SQ) - Unspecified',
    'Trial Name': '',
    'Source Link': 'https://www.businesswire.com/news/home/20240520853296/en'
  },
  {
    'Expected Begin Date': '2024-12-26',
    'Expected End Date': '2024-12-26',
    'Company': 'Biocon, Ltd.',
    'Country': 'India',
    'Drug': 'Bmab-1200',
    'Disease Group': 'Autoimmune/immunology',
    'Indication': 'Psoriasis',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'IL-12 (Interleukin-12) and IL-12 receptor, IL-23 (Interleukin-23)  ',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Subcutaneous (SQ) - Unspecified',
    'Trial Name': '',
    'Source Link': 'https://www.prnewswire.com/news-releases/biocon-biologics-secures-us-market-entry-date-for-bmab-1200-a-proposed-biosimilar-to-stelara-302075944.html'
  },
  {
    'Expected Begin Date': '2024-12-27',
    'Expected End Date': '2024-12-27',
    'Company': 'Xcovery, Inc.',
    'Country': 'United States',
    'Drug': 'Ensartinib',
    'Disease Group': 'Oncology',
    'Indication': 'Non-Small Cell Lung Cancer (NSCLC)',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'Anaplastic lymphoma kinase (ALK)',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Oral (PO)',
    'Trial Name': '',
    'Source Link': 'https://www.businesswire.com/news/home/20240313675039/en'
  },
  {
    'Expected Begin Date': '2024-12-27',
    'Expected End Date': '2024-12-27',
    'Company': 'Jiangsu Vcare PharmaTech Co. Ltd., ',
    'Country': 'China',
    'Drug': 'Vicagrel',
    'Disease Group': 'Cardiovascular',
    'Indication': 'Cardiovascular Disease',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'Adenosine Diphosphate P2Y12 Receptor',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Oral (PO)',
    'Trial Name': '',
    'Source Link': 'https://www.prnewswire.com/news-releases/jiangsu-vcare-files-nda-for-vicagrel-capsules-with-us-fda-302026151.html'
  },
  {
    'Expected Begin Date': '2024-12-31',
    'Expected End Date': '2025-01-30',
    'Company': 'Alterity Therapeutics Limited',
    'Country': 'Australia',
    'Drug': 'ATH434',
    'Disease Group': 'Neurology',
    'Indication': 'Multiple System Atrophy (MSA)',
    'Phase': 'II',
    'Molecule': 'Small Molecule',
    'Target': 'Alpha-synuclein, Metal ions (e.g.,  Ca2+,  Cu2+,  Zn2+,  and Fe2/3+)',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Oral (PO)',
    'Trial Name': 'Phase II - ATH434-201',
    'Source Link': 'https://announcements.asx.com.au/asxpdf/20240508/pdf/063bznfpzd14r7.pdf'
  },
  {
    'Expected Begin Date': '2024-12-31',
    'Expected End Date': '2025-01-30',
    'Company': 'Moberg Pharma AB',
    'Country': 'Sweden',
    'Drug': 'MOB-015',
    'Disease Group': 'Infectious Disease',
    'Indication': 'Onychomycosis',
    'Phase': 'III',
    'Molecule': 'Small Molecule',
    'Target': 'Squalene Epoxidase (Fungal)',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Topical',
    'Trial Name': 'Phase III - MOB015B-33-21-001 (North America)',
    'Source Link': 'https://cipher.investorroom.com/2024-03-14-Cipher-Pharmaceuticals-Reports-Fourth-Quarter-and-Full-Year-2023-Results'
  },
  {
    'Expected Begin Date': '2025-01-02',
    'Expected End Date': '2025-01-02',
    'Company': 'OWP Pharmaceuticals, Inc.',
    'Country': 'United States',
    'Drug': 'SUBVENITE',
    'Disease Group': 'Neurology',
    'Indication': 'Partial / Focal Seizures (Epilepsy)',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'Sodium and Calcium Channels',
    'Drug Classification': 'Non-NME',
    'Route of Administration': 'Oral (PO)',
    'Trial Name': '',
    'Source Link': 'https://www.einpresswire.com/article/713071171/owp-pharmaceuticals-announces-fda-filing-acceptance-for-new-drug-application-of-subvenite-lamotrigine-oral-suspension'
  },
  {
    'Expected Begin Date': '2025-01-02',
    'Expected End Date': '2025-01-02',
    'Company': 'OWP Pharmaceuticals, Inc.',
    'Country': 'United States',
    'Drug': 'SUBVENITE',
    'Disease Group': 'Psychiatry',
    'Indication': 'Bipolar Disorder',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'Sodium and Calcium Channels',
    'Drug Classification': 'Non-NME',
    'Route of Administration': 'Oral (PO)',
    'Trial Name': '',
    'Source Link': 'https://www.einpresswire.com/article/713071171/owp-pharmaceuticals-announces-fda-filing-acceptance-for-new-drug-application-of-subvenite-lamotrigine-oral-suspension'
  },
  {
    'Expected Begin Date': '2025-01-05',
    'Expected End Date': '2025-01-05',
    'Company': 'Merus N.V.',
    'Country': 'Netherlands',
    'Drug': 'Zenocutuzumab',
    'Disease Group': 'Oncology',
    'Indication': 'Pancreatic Cancer',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'ErbB3/HER3, HER2/neu or ErbB-2',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Intravenous (IV)',
    'Trial Name': '',
    'Source Link': 'https://www.globenewswire.com/news-release/2024/05/06/2876137/37568/en/Merus-Announces-U-S-FDA-Acceptance-and-Priority-Review-of-Biologics-License-Application-for-Zeno-for-the-Treatment-of-NRG1-NSCLC-and-PDAC.html'
  },
  {
    'Expected Begin Date': '2025-01-05',
    'Expected End Date': '2025-01-05',
    'Company': 'Merus N.V.',
    'Country': 'Netherlands',
    'Drug': 'Zenocutuzumab',
    'Disease Group': 'Oncology',
    'Indication': 'Non-Small Cell Lung Cancer (NSCLC)',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'ErbB3/HER3, HER2/neu or ErbB-2',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Intravenous (IV)',
    'Trial Name': '',
    'Source Link': 'https://www.globenewswire.com/news-release/2024/05/06/2876137/37568/en/Merus-Announces-U-S-FDA-Acceptance-and-Priority-Review-of-Biologics-License-Application-for-Zeno-for-the-Treatment-of-NRG1-NSCLC-and-PDAC.html'
  },
  {
    'Expected Begin Date': '2025-01-09',
    'Expected End Date': '2025-01-09',
    'Company': 'Johnson & Johnson',
    'Country': 'United States',
    'Drug': 'Tremfya',
    'Disease Group': 'Autoimmune/immunology',
    'Indication': 'Ulcerative Colitis (UC)',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'IL-23 (Interleukin-23)  ',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Intravenous (IV), Subcutaneous (SQ) Injection',
    'Trial Name': '',
    'Source Link': 'https://www.prnewswire.com/news-releases/johnson--johnson-submits-supplemental-biologics-license-application-to-us-fda-seeking-approval-of-tremfya-guselkumab-for-the-treatment-of-adults-with-moderately-to-severely-active-ulcerative-colitis-302085776.html'
  },
  {
    'Expected Begin Date': '2025-01-09',
    'Expected End Date': '2025-01-09',
    'Company': 'Amphastar Pharmaceuticals, Inc.',
    'Country': 'United States',
    'Drug': 'AMP004',
    'Disease Group': 'Endocrine',
    'Indication': 'Diabetes Mellitus, Type II',
    'Phase': 'BLA',
    'Molecule': 'Peptide',
    'Target': 'Insulin Receptor',
    'Drug Classification': 'Biosimilar',
    'Route of Administration': 'Subcutaneous (SQ) Injection',
    'Trial Name': '',
    'Source Link': 'https://ir.amphastar.com/download/companies/270152a/Presentations/2024-01-08%20JPM.pdf'
  },
  {
    'Expected Begin Date': '2025-01-27',
    'Expected End Date': '2025-01-27',
    'Company': 'Celltrion, Inc.',
    'Country': 'Korea (South)',
    'Drug': 'CT-P47',
    'Disease Group': 'Autoimmune/immunology',
    'Indication': 'Rheumatoid Arthritis (RA)',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'IL-6 Receptor (IL-6R)',
    'Drug Classification': 'Biosimilar',
    'Route of Administration': 'Intravenous (IV), Subcutaneous (SQ) Injection',
    'Trial Name': '',
    'Source Link': 'https://www.prnewswire.com/news-releases/celltrion-usa-completes-submission-of-biologics-license-application-for-ct-p47-a-biosimilar-candidate-of-actemra-tocilizumab-302046308.html'
  },
  {
    'Expected Begin Date': '2025-01-28',
    'Expected End Date': '2025-01-28',
    'Company': 'Stealth BioTherapeutics Inc.',
    'Country': 'United States',
    'Drug': 'Elamipretide (Systemic Delivery)',
    'Disease Group': 'Cardiovascular',
    'Indication': 'Cardiomyopathy - Dilated',
    'Phase': 'NDA',
    'Molecule': 'Peptide',
    'Target': 'Mitochondria, Reactive Oxygen Species/Free Radicals',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Intravenous (IV), Oral (PO), Subcutaneous (SQ) Injection',
    'Trial Name': '',
    'Source Link': 'https://www.prnewswire.com/news-releases/stealth-biotherapeutics-barth-syndrome-nda-receives-priority-review-designation-302138192.html'
  },
  {
    'Expected Begin Date': '2025-01-28',
    'Expected End Date': '2025-01-28',
    'Company': 'Daiichi Sankyo Co., Ltd.',
    'Country': 'Japan',
    'Drug': 'Datopotamab Deruxtecan',
    'Disease Group': 'Oncology',
    'Indication': 'HR+/HER2- Breast Cancer',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'Trop-2',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Intravenous (IV)',
    'Trial Name': '',
    'Source Link': 'https://www.businesswire.com/news/home/20240329281783/en'
  },
  {
    'Expected Begin Date': '2025-01-30',
    'Expected End Date': '2025-01-30',
    'Company': 'Eli Lilly and Company',
    'Country': 'United States',
    'Drug': 'Omvoh',
    'Disease Group': 'Autoimmune/immunology',
    'Indication': "Crohn's Disease",
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'IL-23 (Interleukin-23)  ',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Intravenous (IV), Subcutaneous (SQ) - Unspecified',
    'Trial Name': '',
    'Source Link': 'https://investor.lilly.com/static-files/2895c649-cb12-4655-99c2-7c3028fecb15'
  },
  {
    'Expected Begin Date': '2025-02-06',
    'Expected End Date': '2025-02-06',
    'Company': 'Otsuka Holdings Co., Ltd.',
    'Country': 'Japan',
    'Drug': 'Rexulti',
    'Disease Group': 'Psychiatry',
    'Indication': 'Post-Traumatic Stress Disorder (PTSD)',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'Dopamine 2 (D2) Receptor, Norepinephrine (Noradrenaline), Serotonin 5-HT1A receptor, Serotonin 5-HT2A receptor',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Intramuscular (IM), Oral (PO), Subcutaneous (SQ) - Unspecified',
    'Trial Name': '',
    'Source Link': 'https://www.otsuka.co.jp/en/company/newsreleases/2024/20240409_1.html'
  },
  {
    'Expected Begin Date': '2025-02-13',
    'Expected End Date': '2025-02-13',
    'Company': 'GSK plc',
    'Country': 'United Kingdom',
    'Drug': 'MenABCWY (GSK)',
    'Disease Group': 'Infectious Disease',
    'Indication': 'Meningococcal Vaccines and Other Meningococcus-Specific Agents (Antibacterial)',
    'Phase': 'BLA',
    'Molecule': 'Vaccine',
    'Target': 'Immune System, Neisseria meningitidis - Group B, Neisseria meningitidis - Groups A,  C,  Y,  and W-135',
    'Drug Classification': 'Vaccine',
    'Route of Administration': 'Intramuscular (IM)',
    'Trial Name': '',
    'Source Link': 'https://www.gsk.com/media/11099/q1-2024-results-announcement.pdf'
  },
  {
    'Expected Begin Date': '2025-02-20',
    'Expected End Date': '2025-02-20',
    'Company': 'Eli Lilly and Company',
    'Country': 'United States',
    'Drug': 'Mounjaro',
    'Disease Group': 'Respiratory',
    'Indication': 'Sleep Apnea',
    'Phase': 'NDA',
    'Molecule': 'Peptide',
    'Target': 'GIP Receptor (GIPR)/Glucose-Dependent Insulinotropic Polypeptide Receptor, GLP-1 Receptor',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Subcutaneous (SQ) Injection',
    'Trial Name': '',
    'Source Link': 'https://www.prnewswire.com/news-releases/lillys-tirzepatide-reduced-obstructive-sleep-apnea-osa-severity-with-up-to-51-5-of-participants-meeting-the-criteria-for-disease-resolution-302179373.html'
  },
  {
    'Expected Begin Date': '2025-02-27',
    'Expected End Date': '2025-02-27',
    'Company': 'Eton Pharmaceuticals, Inc.',
    'Country': 'United States',
    'Drug': 'ET-400',
    'Disease Group': 'Endocrine',
    'Indication': 'Endocrine Disorder',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'Unknown',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Oral (PO)',
    'Trial Name': '',
    'Source Link': 'https://ir.etonpharma.com/news-releases/news-release-details/eton-pharmaceuticals-reports-first-quarter-2024-financial'
  },
  {
    'Expected Begin Date': '2025-03-09',
    'Expected End Date': '2025-03-09',
    'Company': 'Celltrion, Inc.',
    'Country': 'Korea (South)',
    'Drug': 'Omlyclo',
    'Disease Group': 'Respiratory',
    'Indication': 'Asthma',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'Immunoglobulin E (IgE)',
    'Drug Classification': 'Biosimilar',
    'Route of Administration': 'Subcutaneous (SQ) Injection',
    'Trial Name': '',
    'Source Link': 'https://www.prnewswire.com/news-releases/celltrion-usa-completes-submission-of-biologics-license-application-bla-to-us-fda-for-ct-p39-an-interchangeable-biosimilar-candidate-of-xolair-omalizumab-302084902.html'
  },
  {
    'Expected Begin Date': '2025-03-09',
    'Expected End Date': '2025-03-09',
    'Company': 'Celltrion, Inc.',
    'Country': 'Korea (South)',
    'Drug': 'Omlyclo',
    'Disease Group': 'Allergy',
    'Indication': 'Food Allergies',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'Immunoglobulin E (IgE)',
    'Drug Classification': 'Biosimilar',
    'Route of Administration': 'Subcutaneous (SQ) Injection',
    'Trial Name': '',
    'Source Link': 'https://www.prnewswire.com/news-releases/celltrion-usa-completes-submission-of-biologics-license-application-bla-to-us-fda-for-ct-p39-an-interchangeable-biosimilar-candidate-of-xolair-omalizumab-302084902.html'
  },
  {
    'Expected Begin Date': '2025-03-09',
    'Expected End Date': '2025-03-09',
    'Company': 'Celltrion, Inc.',
    'Country': 'Korea (South)',
    'Drug': 'Omlyclo',
    'Disease Group': 'Allergy',
    'Indication': 'Chronic Rhinosinusitis ',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'Immunoglobulin E (IgE)',
    'Drug Classification': 'Biosimilar',
    'Route of Administration': 'Subcutaneous (SQ) Injection',
    'Trial Name': '',
    'Source Link': 'https://www.prnewswire.com/news-releases/celltrion-usa-completes-submission-of-biologics-license-application-bla-to-us-fda-for-ct-p39-an-interchangeable-biosimilar-candidate-of-xolair-omalizumab-302084902.html'
  },
  {
    'Expected Begin Date': '2025-03-09',
    'Expected End Date': '2025-03-09',
    'Company': 'Celltrion, Inc.',
    'Country': 'Korea (South)',
    'Drug': 'Omlyclo',
    'Disease Group': 'Allergy',
    'Indication': 'Urticaria',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'Immunoglobulin E (IgE)',
    'Drug Classification': 'Biosimilar',
    'Route of Administration': 'Subcutaneous (SQ) Injection',
    'Trial Name': '',
    'Source Link': 'https://www.prnewswire.com/news-releases/celltrion-usa-completes-submission-of-biologics-license-application-bla-to-us-fda-for-ct-p39-an-interchangeable-biosimilar-candidate-of-xolair-omalizumab-302084902.html'
  },
  {
    'Expected Begin Date': '2025-03-09',
    'Expected End Date': '2025-03-09',
    'Company': 'Celltrion, Inc.',
    'Country': 'Korea (South)',
    'Drug': 'Omlyclo',
    'Disease Group': 'Allergy',
    'Indication': 'Nasal Polyposis',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'Immunoglobulin E (IgE)',
    'Drug Classification': 'Biosimilar',
    'Route of Administration': 'Subcutaneous (SQ) Injection',
    'Trial Name': '',
    'Source Link': 'https://www.prnewswire.com/news-releases/celltrion-usa-completes-submission-of-biologics-license-application-bla-to-us-fda-for-ct-p39-an-interchangeable-biosimilar-candidate-of-xolair-omalizumab-302084902.html'
  },
  {
    'Expected Begin Date': '2025-03-25',
    'Expected End Date': '2025-03-25',
    'Company': 'Milestone Pharmaceuticals, Inc.',
    'Country': 'Canada',
    'Drug': 'Cardamyst',
    'Disease Group': 'Cardiovascular',
    'Indication': 'Supraventricular Tachycardia',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'Calcium Channel',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Intranasal',
    'Trial Name': '',
    'Source Link': 'https://www.globenewswire.com/news-release/2024/05/29/2889685/0/en/Milestone-Pharmaceuticals-Announces-FDA-Acceptance-of-New-Drug-Application-for-CARDAMYST.html'
  },
  {
    'Expected Begin Date': '2025-03-27',
    'Expected End Date': '2025-03-27',
    'Company': 'Sanofi',
    'Country': 'France',
    'Drug': 'Fitusiran',
    'Disease Group': 'Hematology',
    'Indication': 'Hemophilia A and B - General Clotting Products',
    'Phase': 'NDA',
    'Molecule': 'siRNA/RNAi',
    'Target': 'Thrombin (Coagulation Factor IIa)',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Subcutaneous (SQ) Injection',
    'Trial Name': '',
    'Source Link': 'https://www.globenewswire.com/news-release/2024/06/21/2902104/0/en/Press-Release-ISTH-Sanofi-advances-leadership-in-hemophilia-with-new-data-for-ALTUVIIIO-and-fitusiran.html'
  },
  {
    'Expected Begin Date': '2025-04-17',
    'Expected End Date': '2025-04-17',
    'Company': 'Johnson & Johnson',
    'Country': 'United States',
    'Drug': 'Tremfya',
    'Disease Group': 'Autoimmune/immunology',
    'Indication': "Crohn's Disease",
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'IL-23 (Interleukin-23)  ',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Intravenous (IV), Subcutaneous (SQ) Injection',
    'Trial Name': '',
    'Source Link': 'https://www.prnewswire.com/news-releases/johnson--johnson-submits-application-to-us-fda-seeking-approval-of-tremfya-guselkumab-for-the-treatment-of-moderately-to-severely-active-crohns-disease-302178478.html'
  },
  {
    'Expected Begin Date': '2025-04-29',
    'Expected End Date': '2025-04-29',
    'Company': 'Neurocrine Biosciences, Inc.',
    'Country': 'United States',
    'Drug': 'Crinecerfont',
    'Disease Group': 'Endocrine',
    'Indication': 'Congenital Adrenal Hyperplasia (CAH)',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'Corticotropin Releasing Factor -Receptor1 (CRF-R1), Corticotropin Releasing Factor -Receptor2 (CRF-R2)',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Oral (PO)',
    'Trial Name': '',
    'Source Link': 'https://www.prnewswire.com/news-releases/neurocrine-biosciences-reports-first-quarter-2024-financial-results-302132611.html'
  },
  {
    'Expected Begin Date': '2025-04-30',
    'Expected End Date': '2025-05-30',
    'Company': 'Eli Lilly and Company',
    'Country': 'United States',
    'Drug': 'Gemzar',
    'Disease Group': 'Oncology',
    'Indication': 'Pancreatic Cancer',
    'Phase': 'Approved (Generic Competition)',
    'Molecule': 'Small Molecule',
    'Target': 'DNA synthesis',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Intravenous (IV)',
    'Trial Name': 'Phase II - CEND1-202 (China)',
    'Source Link': 'https://www.globenewswire.com/news-release/2024/04/23/2867557/18623/en/Lisata-Therapeutics-and-Qilu-Pharmaceutical-Announce-First-Patient-Treated-in-Qilu-s-Phase-2-Trial-in-China-of-LSTA1-in-Patients-with-Metastatic-Pancreatic-Ductal-Adenocarcinoma.html'
  },
  {
    'Expected Begin Date': '2025-04-30',
    'Expected End Date': '2025-05-30',
    'Company': 'Bristol Myers Squibb Company',
    'Country': 'United States',
    'Drug': 'Abraxane',
    'Disease Group': 'Oncology',
    'Indication': 'Pancreatic Cancer',
    'Phase': 'Approved (Generic Competition)',
    'Molecule': 'Small Molecule',
    'Target': 'Microtubules (Tubulin)',
    'Drug Classification': 'Non-NME',
    'Route of Administration': 'Intravenous (IV)',
    'Trial Name': 'Phase II - CEND1-202 (China)',
    'Source Link': 'https://www.globenewswire.com/news-release/2024/04/23/2867557/18623/en/Lisata-Therapeutics-and-Qilu-Pharmaceutical-Announce-First-Patient-Treated-in-Qilu-s-Phase-2-Trial-in-China-of-LSTA1-in-Patients-with-Metastatic-Pancreatic-Ductal-Adenocarcinoma.html'
  },
  {
    'Expected Begin Date': '2025-04-30',
    'Expected End Date': '2025-05-30',
    'Company': 'Lisata Therapeutics, Inc.',
    'Country': 'United States',
    'Drug': 'LSTA1',
    'Disease Group': 'Oncology',
    'Indication': 'Pancreatic Cancer',
    'Phase': 'IIb',
    'Molecule': 'Peptide',
    'Target': 'Integrin Alpha-V Family, Neuropilin 1 (NRP1)',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Intravenous (IV)',
    'Trial Name': 'Phase II - CEND1-202 (China)',
    'Source Link': 'https://www.globenewswire.com/news-release/2024/04/23/2867557/18623/en/Lisata-Therapeutics-and-Qilu-Pharmaceutical-Announce-First-Patient-Treated-in-Qilu-s-Phase-2-Trial-in-China-of-LSTA1-in-Patients-with-Metastatic-Pancreatic-Ductal-Adenocarcinoma.html'
  },
  {
    'Expected Begin Date': '2025-05-13',
    'Expected End Date': '2025-05-13',
    'Company': 'Ferring Pharmaceuticals',
    'Country': 'Switzerland',
    'Drug': 'Hernicore',
    'Disease Group': 'Neurology',
    'Indication': 'Sciatica',
    'Phase': 'BLA',
    'Molecule': 'Protein',
    'Target': 'Extracellular Matrix (ECM)',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Intrathecal',
    'Trial Name': '',
    'Source Link': 'https://www.seikagaku.co.jp/en/news/COPY-COPY-COPY-COPY-COPY-news-906709639294258477/main/0/link/20240514_Press_Release_en_final.pdf'
  },
  {
    'Expected Begin Date': '2025-05-26',
    'Expected End Date': '2025-05-26',
    'Company': 'Telix Pharmaceuticals Limited',
    'Country': 'Australia',
    'Drug': 'TLX007-CDx',
    'Disease Group': 'Oncology',
    'Indication': 'Prostate Cancer - Imaging',
    'Phase': 'NDA',
    'Molecule': 'Not Specified',
    'Target': 'Unknown',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'N/A',
    'Trial Name': '',
    'Source Link': 'https://www.prnewswire.com/apac/news-releases/telix-submits-nda-for-new-prostate-cancer-imaging-agent-302155763.html'
  },
  {
    'Expected Begin Date': '2025-06-01',
    'Expected End Date': '2025-06-01',
    'Company': 'Telix Pharmaceuticals Limited',
    'Country': 'Australia',
    'Drug': 'TLX250-CDx',
    'Disease Group': 'Oncology',
    'Indication': 'Renal Cell Cancer (RCC) - Imaging',
    'Phase': 'BLA',
    'Molecule': 'Not Specified',
    'Target': 'Carbonic Anhydrase',
    'Drug Classification': 'Device',
    'Route of Administration': 'Intravenous (IV)',
    'Trial Name': '',
    'Source Link': 'https://www.globenewswire.com/news-release/2024/06/02/2891867/0/en/Telix-Completes-TLX250-CDx-Zircaix-BLA-Submission-for-Kidney-Cancer-Imaging.html'
  },
  {
    'Expected Begin Date': '2025-06-16',
    'Expected End Date': '2025-06-16',
    'Company': 'Johnson & Johnson',
    'Country': 'United States',
    'Drug': 'Amivantamab SC',
    'Disease Group': 'Oncology',
    'Indication': 'Non-Small Cell Lung Cancer (NSCLC)',
    'Phase': 'BLA',
    'Molecule': 'Monoclonal Antibody',
    'Target': 'EGFR (Epidermal Growth Factor Receptor), Tyrosine Kinases',
    'Drug Classification': 'Biologic',
    'Route of Administration': 'Subcutaneous (SQ) Infusion',
    'Trial Name': '',
    'Source Link': 'https://www.prnewswire.com/news-releases/subcutaneous-amivantamab-biologics-license-application-submitted-to-us-fda-for-patients-with-egfr-mutated-non-small-cell-lung-cancer-302173613.html'
  },
  {
    'Expected Begin Date': '2025-06-17',
    'Expected End Date': '2025-06-17',
    'Company': 'KalVista Pharmaceuticals, Inc.',
    'Country': 'United States',
    'Drug': 'Sebetralstat',
    'Disease Group': 'Autoimmune/immunology',
    'Indication': 'Hereditary Angioedema (HAE)',
    'Phase': 'NDA',
    'Molecule': 'Small Molecule',
    'Target': 'Kinin-Kallikrein System ',
    'Drug Classification': 'New Molecular Entity (NME)',
    'Route of Administration': 'Oral (PO)',
    'Trial Name': '',
    'Source Link': 'https://www.businesswire.com/news/home/20240618507569/en'
  }
]