import { useCallback, useState } from 'react';

import { useLanguage } from '../../utils/context/lang';
import { i18n } from '../i18n/chat';

import CompanySelect from './form/company-select';
import DiseaseInput from './form/disease-input';
import DrugInput from './form/drug-input';
import GenderSelect from './form/gender-select';
import LocationSelect from './form/location-select';
import MiscInput from './form/misc-input';
import NctIdInput from './form/nctid-input';
import PhaseSelect from './form/phase-select';
import StatusSelect from './form/status-select';
import StudyTypeSelect from './form/study-type-select';
import TargetInput from './form/target-input';

const formDict = {
  CompanySelect,
  NctIdInput,
  DrugInput,
  DiseaseInput,
  GenderSelect,
  LocationSelect,
  PhaseSelect,
  StatusSelect,
  StudyTypeSelect,
  MiscInput,
  TargetInput,
};

export default function WorkflowInputForm({
  title,
  params,
  defaultValue = {},
  onSave,
  enableSave,
}) {
  const [ formData, setFormData ] = useState(defaultValue);
  const { lang } = useLanguage();
  console.log('formmmmmmmmm', params);
  const handleInputChange = useCallback((name, value) => {
    console.log(name, value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // return;
    onSave(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-12">
        <div className="pb-12 max-w-screen-xl mx-auto">
          {/* <h2 className="text-base font-semibold leading-7 text-gray-900">
            Workflow
          </h2> */}
          <p className="mt-1 text-lg leading-6 font-bold text-primary-content">{title}</p>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
            {params.map((param, index) => {
              const Component = formDict[param.component];
              return (
                <div key={index}>
                  <Component
                    {...param}
                    defaultValue={formData[param.name]}
                    onChange={(value) => handleInputChange(param.name, value)}
                  />
                </div>
              );
            })}
          </div>
        </div>

        {enableSave && (
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="submit"
              className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              {i18n.PROCEED[lang]}
            </button>
          </div>
        )}
      </div>
    </form>
  );
}
