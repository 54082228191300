import { useCallback, useState } from 'react';

import debounce from 'lodash/debounce';

import { fetchTypeAhead } from '../../../api';
import { useLanguage } from '../../../utils/context/lang';

import AndOrSelect from './common/and-or-select';

const dict = {
  en: {
    title: 'Disease',
    placeholder: 'Disease name like Breast Cancer',
  },
  zh: {
    title: '疾病',
    placeholder: '疾病名称，如 乳腺癌',
  },
};

const DiseaseInput = ({ name, onChange, defaultValue }) => {
  const { lang } = useLanguage();
  const [ options, setOptions ] = useState([]);
  const handleSearch = useCallback(debounce((value) => {
    fetchTypeAhead(value, 'indication').then((res) => {
      setOptions(res?.result?.map((item) => ({ label: item, value: item })) || []);
    })
  }, 500), []);
  return (
    <>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-primary-content"
      >
        {dict['en'].title}
      </label>
      <div className="flex flex-wrap gap-2">
        <AndOrSelect
          name={name}
          onChange={onChange} // Use the memoized handleChange here
          defaultValue={defaultValue}
          placeholder={dict['en'].placeholder}
          options={options}
          filterOption={false}
          onSearch={handleSearch}
        />
      </div>
    </>
  );
};

export default DiseaseInput;
