const SUCCESS_COPY = {
  en: 'Content has been successfully copied to the clipboard.',
  zh: '内容已成功复制到剪贴板。',
};
  
const FAILED_COPY = {
  en: 'Failed to copy content to clipboard.',
  zh: '无法复制内容到剪贴板。',
}

const THOUGHT = {
  en: 'Thought',
  zh: '思考过程',
}

const UPGRADE_HINT = {
  en: 'Upgrade to Pro to unlock more features',
  zh: '升级到专业版以解锁更多功能',
}

const ANALYZING_UPLOAD = {
  en: 'Analyzing upload...',
  zh: '正在分析上传...',
}

const FILE_SIZE_EXCEEDED = {
  en: 'File size exceeded, 10MB limit.',
  zh: '文件大小超出限制, 10MB限制。',
}

const ANSWER = {
  en: 'Answer',
  zh: '回答',
}

const REFERENCE = {
  en: 'Reference',
  zh: '引用',
}

const FOLLOWUP_QUESTIONS = {
  en: 'Follow-up Questions',
  zh: '推荐问题',
}

const REPLY_LANGUAGE = {
  en: 'Reply Language',
  zh: '回复语言',
}

export const i18n = {
  SUCCESS_COPY,
  FAILED_COPY,
  THOUGHT,
  UPGRADE_HINT,
  ANALYZING_UPLOAD,
  FILE_SIZE_EXCEEDED,
  ANSWER,
  REFERENCE,
  FOLLOWUP_QUESTIONS,
  REPLY_LANGUAGE,
}
