// import { piData } from '../../mock/piData.js';
import { useState } from 'react';

import { i18n } from '../../i18n/pi.js';
import DownloadBtn from '../downloadBtn.js';

import AssociationModal from './modal/associationModal.js';
import ClinicalModal from './modal/clinicalModal/clinicalModal.js';
import CollaboratorModal from './modal/collaboratorModal.js';
import IntroModal from './modal/introModal/introModal.js';
import PaperModal from './modal/paperModal.js';


export const PiTable = ({ piData }) => {
  const [ introModalOpen, setIntroModalOpen ] = useState(false);
  const [ person, setPerson ] = useState(null);
  const [ clinical, setClinical ] = useState(null);
  const [ clinicalModalOpen, setClinicalModalOpen ] = useState(false);
  const [ associations, setAssociations ] = useState(null);
  const [ associationModalOpen, setAssociationModalOpen ] = useState(false);
  const [ papers, setPapers ] = useState([]);
  const [ paperModalOpen, setPaperModalOpen ] = useState(false);
  const [ collaborators, setCollaborators ] = useState([]);
  const [ collaboratorModalOpen, setCollaboratorModalOpen ] = useState(false);

  if (!piData?.result_json) return <></>
  const showPI = (person) => {
    setPerson(person);
    setIntroModalOpen(true);
  };

  const showClinical = (person) => {
    setClinical(person);
    setClinicalModalOpen(true);
  };

  const showAssociation = (associations) => {
    setAssociations(associations);
    setAssociationModalOpen(true);
  };

  const showPaper = (papers) => {
    if (papers.length === 0) return;
    setPapers(papers);
    setPaperModalOpen(true);
  };

  const showCollaborator = (collaborators) => {
    setCollaborators(collaborators);
    setCollaboratorModalOpen(true);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 bg-base-100 rounded-lg px-2 py-4 overflow-hidden mt-2">
      {/* <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base flex items-center content-center font-semibold leading-6 text-secondary-content">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
              />
            </svg>
            <span className="pl-2">Answer</span>
          </h1>
        </div>
      </div> */}
      <div className="flow-root w-full">
        
        <div className="inline-block w-full py-2 align-middle">
          <div className="text-right mb-2">
            <DownloadBtn data={piData.result_json} filename="piData" />
          </div>
          <div className="shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg overflow-x-auto">
            <table className="min-w-[900px] divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pr-3 text-left text-sm font-semibold text-secondary-content sm:pl-0 md:pl-4"
                  >
                    {i18n.NAME.zh}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 w-[150px] text-left text-sm font-semibold text-secondary-content"
                  >
                    {i18n.COMPANY_NAME.zh}
                  </th>
                  {/* <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-secondary-content"
                  >
                    {i18n.TITLE.zh}
                  </th> */}
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-secondary-content"
                  >
                    {i18n.ASSOCIATION_INFO.zh}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-secondary-content"
                  >
                    {i18n.CLINICAL_COUNT.zh}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-secondary-content"
                  >
                    {i18n.RELEVANT_CLINICAL_COUNT.zh}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-secondary-content"
                  >
                    {i18n.RELEVANT_THIRD_STAGE_CLINIICAL_COUNT.zh}
                  </th>
                  {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-secondary-content">
                  {INTRODUCTION.zh}
                  </th> */}
                  {/* <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-secondary-content"
                  >
                    {INTERNET_SEARCH_RESULT.zh}
                  </th> */}
                  {/* <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-secondary-content"
                    >
                      {i18n.PAPER_PUBLICATION.zh}
                    </th> */}
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-secondary-content"
                  >
                    {i18n.COLLABORATOR_COUNT.zh}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-secondary-content"
                  >
                    {i18n.COLLABORATOR.zh}
                  </th>
                  {/* <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">{i18n.CHECK_OUT.zh}</span>
                  </th> */}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {piData.result_json.map((person) => (
                  <tr key={person.email}>
                    <td
                      className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-secondary-content sm:pl-0 md:pl-4"
                      // onClick={() => showPI(person)}
                    >
                      {/* <a className="underline hover:text-primary"> */}
                      {person.姓名}
                      {/* </a> */}
                    </td>
                    <td className="overflow-hidden px-3 py-4 text-sm text-secondary-content">
                      {person.单位名称}
                    </td>
                    {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary-content">
                      {person.职称}
                    </td> */}
                    <td
                      className="px-3 py-4 text-sm text-secondary-content"
                      // onClick={() => showAssociation(person.协会信息)}
                    >
                      <p>
                        {/* {person.协会信息 || ''} */}
                        {person.协会信息?.map((association, index) => (
                          
                          <div key={index} className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-secondary text-secondary-content mr-2 mb-2">
                            {association.职务} - {association.协会} {association.分会?'-'+association.分会:''}
                          </div>
                        ))}
                      </p>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary-content">
                      {person.临床试验数}
                    </td>
                    <td
                      className="whitespace-nowrap px-3 py-4 text-sm text-secondary-content"
                      onClick={() => showClinical(person)}
                    >
                      <a className="underline cursor-pointer hover:text-primary">
                        {person.适应症相关临床试验数}
                      </a>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary-content">
                      {person.适应症相关III期临床试验数}
                    </td>
                    {/* <td
                        className="whitespace-nowrap px-3 py-4 text-sm text-secondary-content cursor-pointer"
                        onClick={() => showPaper(person.论文发表信息)}
                      >
                        <a className="underline hover:text-primary">
                          {person.论文发表信息?.length || ''}
                        </a>
                      </td> */}
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary-content">
                      {person.合作者数量}
                    </td>
                    <td
                      className="whitespace-nowrap px-3 py-4 text-sm text-secondary-content"
                      onClick={() => showCollaborator(person.合作者)}
                    >
                      <a
                        className="underline hover:text-primary cursor-pointer"
                      >
                        {i18n.CHECK_OUT.zh}
                        <span className="sr-only"></span>
                      </a>
                    </td>

                    {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <a
                        href="#"
                        className="underline hover:text-primary"
                      >
                        {i18n.CHECK_OUT.zh}<span className="sr-only">, {person.name}</span>
                      </a>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
       
        </div>
      </div>
      <IntroModal
        open={introModalOpen}
        person={person}
        onClose={setIntroModalOpen}
      />
      <AssociationModal
        open={associationModalOpen}
        associations={associations}
        onClose={setAssociationModalOpen}
      />
      <ClinicalModal
        open={clinicalModalOpen}
        person={clinical}
        onClose={setClinicalModalOpen}
      />
      <PaperModal
        open={paperModalOpen}
        papers={papers}
        onClose={setPaperModalOpen}
      />
      <CollaboratorModal
        open={collaboratorModalOpen}
        collaborators={collaborators}
        onClose={setCollaboratorModalOpen}
      />
    </div>
  );
};
