export function getCookie(key) {
  const name = `${encodeURIComponent(key)}=`;
  const cookieArray = document.cookie ? document.cookie.split(';') : [];
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.startsWith(name)) {
      return decodeURIComponent(cookie.substring(name.length, cookie.length));
    }
  }
  return null;
}

export function setCookie(key, value, expire) {
  let expires = new Date();
  expires.setTime(expires.getTime() + expire);
  document.cookie =
    key + '=' + value + ';expires=' + expires.toUTCString() + '; path=/';
}

export function deleteCookie(key) {
  let expires = new Date();
  expires.setTime(expires.getTime() - 1000);
  document.cookie = key + '=;expires=' + expires.toUTCString() + '; path=/';
}

export function setAccessToken(token) {
  // expire in 365 days
  setCookie('noahAccessToken', token, 7 * 24 * 60 * 60 * 1000);
}

export function getAccessToken() {
  return getCookie('noahAccessToken');
}

export function deleteAllCookies() {
  let cookies = document.cookie.split(';');
  for (let i in cookies) {
    if ('noahLang' !== cookies[i].split('=')[0].trim())
      deleteCookie(cookies[i].split('=')[0]);
  }
}
