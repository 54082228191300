const ABSTRACT = { en: 'Abstract', zh: '摘要' };
const CITED_COUNT = { en: 'Cited Count', zh: '引用次数' };
const YEAR = { en: 'Year', zh: '年份' };
const SUMMARY = { en: 'Summary', zh: '总结' };
const MORE_INFO = { en: 'More Info', zh: '更多信息' };
const KEYWORDS = { en: 'Keywords', zh: '关键词' };
const SCORE = { en: 'Score', zh: 'AI得分' };
const TITLE = { en: 'Title', zh: '标题' };
const CITATIONS = { en: 'Citations', zh: '引用' };
const JOURNAL = { en: 'Journal', zh: '期刊' };

export const i18n = {
  ABSTRACT,
  CITED_COUNT,
  YEAR,
  SUMMARY,
  KEYWORDS,
  SCORE,
  MORE_INFO,
  TITLE,
  CITATIONS,
  JOURNAL,
};
