import React, { useState } from 'react';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { addDays, addMonths, format, getDay, getDaysInMonth, isSameDay, isToday, startOfMonth, subDays, subMonths } from 'date-fns';

const generateCalendarDays = (year, month, selectedDate) => {
  const startDate = startOfMonth(new Date(year, month));
  const daysInMonth = getDaysInMonth(startDate);
  const startDay = getDay(startDate); // Get the day of the week the month starts on (0 = Sunday, 1 = Monday, etc.)

  const days = [];

  // Fill initial empty days with dates from the previous month
  const prevMonthDaysCount = startDay;
  for (let i = 0; i < prevMonthDaysCount; i++) {
    const date = subDays(startDate, prevMonthDaysCount - i);
    days.push({
      date: format(date, 'yyyy-MM-dd'),
      isCurrentMonth: false,
      isToday: isToday(date),
      isSelected: isSameDay(date, new Date(selectedDate)),
    });
  }

  // Fill the actual days of the month
  for (let i = 0; i < daysInMonth; i++) {
    const date = addDays(startDate, i);
    days.push({
      date: format(date, 'yyyy-MM-dd'),
      isCurrentMonth: true,
      isToday: isToday(date),
      isSelected: isSameDay(date, new Date(selectedDate)),
    });
  }

  // Fill the remaining days with dates from the next month
  const totalDays = days.length;
  const nextMonthDaysCount = 42 - totalDays; // 6 weeks of 7 days each = 42 days
  for (let i = 0; i < nextMonthDaysCount; i++) {
    const date = addDays(startDate, daysInMonth + i);
    days.push({
      date: format(date, 'yyyy-MM-dd'),
      isCurrentMonth: false,
      isToday: isToday(date),
      isSelected: isSameDay(date, new Date(selectedDate)),
    });
  }

  return days;
};

const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

export function Calendar({ selectedDate, onDateClick }) {
  const [ currentDate, setCurrentDate ] = useState(new Date());

  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const days = generateCalendarDays(currentYear, currentMonth, selectedDate);
  const monthName = format(new Date(currentYear, currentMonth), 'MMMM');

  const handlePrevMonth = () => {
    setCurrentDate(subMonths(currentDate, 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(addMonths(currentDate, 1));
  };

  return (
    <div className="mt-10 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-9 top-10 sticky">
      <div className="flex items-center text-gray-900">
        <button
          type="button"
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          onClick={handlePrevMonth}
        >
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <div className="flex-auto text-sm text-primary-content font-semibold">{monthName} {currentYear}</div>
        <button
          type="button"
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          onClick={handleNextMonth}
        >
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
        <div>S</div>
        <div>M</div>
        <div>T</div>
        <div>W</div>
        <div>T</div>
        <div>F</div>
        <div>S</div>
      </div>
      <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
        {days.map((day, dayIdx) => (
          <button
            key={day.date || `empty-${dayIdx}`}
            type="button"
            className={classNames(
              'py-1.5 hover:bg-gray-100 focus:z-10',
              day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
              (day.isSelected || day.isToday) && 'font-semibold',
              day.isSelected && 'text-white',
              !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
              !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-400',
              day.isToday && !day.isSelected && 'text-blue-600',
              dayIdx === 0 && 'rounded-tl-lg',
              dayIdx === 6 && 'rounded-tr-lg',
              dayIdx === days.length - 7 && 'rounded-bl-lg',
              dayIdx === days.length - 1 && 'rounded-br-lg'
            )}
            onClick={day.date ? () => onDateClick(day.date) : undefined}
            disabled={!day.date}
          >
            {day.date && (
              <time
                dateTime={day.date}
                className={classNames(
                  'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                  day.isSelected && (day.isToday ? 'bg-blue-600' : 'bg-gray-900')
                )}
              >
                {day.date.split('-').pop().replace(/^0/, '')}
              </time>
            )}
          </button>
        ))}
      </div>
    </div>
  );
}
