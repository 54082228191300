import {
  BeakerIcon,
  CalendarIcon,
  MapPinIcon,
} from '@heroicons/react/20/solid'
import { BuildingOffice, MapPinArea, Person, Pill, Virus } from '@phosphor-icons/react';

{/* {
    'Expected Begin Date': '2025-06-02',
    'Expected End Date': '2025-06-02',
    'Company': 'Telix Pharmaceuticals Limited',
    'Country': 'Australia',
    'Drug': 'TLX250-CDx',
    'Disease Group': 'Oncology',
    'Indication': 'Renal Cell Cancer (RCC) - Imaging',
    'Phase': 'BLA',
    'Molecule': 'Not Specified',
    'Target': 'Carbonic Anhydrase',
    'Drug Classification': 'Device',
    'Route of Administration': 'Intravenous (IV)',
    'Trial Name': '',
    'Source Link': 'https://www.globenewswire.com/news-release/2024/06/02/2891867/0/en/Telix-Completes-TLX250-CDx-Zircaix-BLA-Submission-for-Kidney-Cancer-Imaging.html'
  } */}
export function ClinicItem({ meeting }) {
  return (
    <li key={meeting.id} className="relative flex space-x-6 py-6 xl:static">
      <img src={`https://placehold.co/50x50?text=${meeting['Company'][0]}`} alt="" className="h-14 w-14 flex-none rounded-full" />
      <div className="flex-auto">
        <h3 className="pr-10 font-semibold text-primary-content xl:pr-0 cursor-pointer" onClick={()=>{
        // redirect to source link
          window.open(meeting['Source Link'], '_blank');
        }}>{meeting.Company}</h3>
        <dl className="mt-2 flex flex-col text-gray-500 xl:flex-row">
          <div className="mt-2 flex items-start space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
            <dt className="mt-0.5">
              <span className="sr-only">Drug</span>
              <Pill className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </dt>
            <dd>{`${meeting['Trial Name']?meeting['Trial Name']+',':''}`} {meeting.Drug}</dd>
          </div>
          <div className="mt-2 flex items-start space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
            <dt className="mt-0.5">
              <span className="sr-only">Location</span>
              <MapPinArea className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </dt>
            <dd>{meeting.Country}</dd>
          </div>
          
        </dl>
        
        <dl className="mt-2 flex flex-col text-gray-500 xl:flex-row">
         
          <div className="mt-2 flex items-start space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
            <dt className="mt-0.5">
              <span className="sr-only">Target</span>
              <Person className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </dt>
            <dd>{meeting.Target}</dd>
          </div>
        </dl>
        <dl className="mt-2 flex flex-col text-gray-500 xl:flex-row">
          <div className="mt-2 flex items-start space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
            <dt className="mt-0.5">
              <span className="sr-only">Disease Group</span>
              <Virus className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </dt>
            <dd>{meeting['Disease Group']}</dd>
          </div>
          <div className="mt-2 flex items-start space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
            <dt className="mt-0.5">
              <span className="sr-only">Molecule</span>
              <BeakerIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </dt>
            <dd>{meeting.Molecule}</dd>
          </div>
          <div className="mt-2 flex items-start space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
            <dt className="mt-0.5">
              <span className="sr-only">Date</span>
              <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </dt>
            <dd>
              <time dateTime={meeting.datetime}>
                {meeting['Expected Begin Date']} to {meeting['Expected End Date']}
              </time>
            </dd>
          </div>
        </dl>
      </div>
    </li>)
}