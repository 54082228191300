import React, { createContext, useContext, useEffect, useState } from 'react';

import { getCookie, setCookie } from '../cookie';

const LanguageContext = createContext();

export const useLanguage = () => {
  return useContext(LanguageContext);
};

export const LanguageProvider = ({ children }) => {
  const searchParams = new URLSearchParams(window.location.search);
  const queryLang = searchParams.get('lang');
  const defaultLang = getCookie('noahLang') || navigator.language.split('-')[0] || 'en';
  const [ lang, setLanguage ] = useState(queryLang || defaultLang); 

  setCookie('noahLang', lang);

  const toggleLanguage = () => {
    setCookie('noahLang', lang === 'en' ? 'zh' : 'en');
    setLanguage((prevLang) => (prevLang === 'en' ? 'zh' : 'en'));
  };

  const selectLanguage = (lang) => {
    setCookie('noahLang', lang);
    // setLanguage(lang); // we already yse .reload(), so this line is redundant in UX.
    window.location.reload();
  }

  return (
    <LanguageContext.Provider value={{ lang, toggleLanguage, selectLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
