import DisplayFormattedText from '../../../AskPage/components/markdownInCell';

export const CLINICAL_RESULT_COLUMN = [
  {
    headerName: 'Trial ID',
    field: 'nctid',
    width: 140,
    pinned: 'left',
    cellRenderer: (params) => {
      return (
        <a
          href={`https://clinicaltrials.gov/study/${params.data['nctid']}`}
          target="_blank"
          rel="noreferrer"
          className="underline text-blue-500 hover:text-blue-700 focus:outline-none focus:underline"
        >
          {params.data['nctid']}
        </a>
      );
    },
  },
  {
    headerName: 'Official Title',
    field: 'official_title',
    width: 400,
    wrapText: true,
  },
  {
    headerName: 'Lead Company',
    field: 'lead_company',
    wrapText: true,
  },
  {
    headerName: 'Partner Companies',
    field: 'partner_companies',
    wrapText: true,
    cellRenderer: (params) => {
      // return params.data['partner_companies']?.map(item => `- ${item}`).join('\n');
      return <DisplayFormattedText text={params.data['partner_companies']?.map(item => `- ${item}`).join('\n')} className="text-xs"/>;
    },
  },
  {
    headerName: 'Drug Name',
    field: 'drug_name',
    wrapText: true,
  },
  { headerName: 'Indication', field: 'indication_name', wrapText: true, },
  { headerName: 'Phase', width: 75, field: 'phase' },
  // { headerName: 'Actual Enrollment', field: 'actual_enrollment' },
  { headerName: 'Target', field: 'target', wrapText: true, },
  { headerName: 'Current Status', field: 'current_status' },
  {
    headerName: 'Design',
    field: 'design',
    width: 600,
    wrapText: true,
    cellRenderer: (params) => {
      return <DisplayFormattedText text={params.data['design']} className="text-xs"/>;
    },
  },
  {
    headerName: 'Efficacy',
    field: 'efficacy',
    width: 600,
    wrapText: true,
    cellRenderer: (params) => {
      return <DisplayFormattedText text={params.data['efficacy']} className="text-xs"/>;
    },
  },
  {
    headerName: 'Safety',
    field: 'safety',
    width: 600,
    wrapText: true,
    cellRenderer: (params) => {
      return <DisplayFormattedText text={params.data['safety']} />;
    },
  },
  { headerName: 'Location', field: 'locations', tooltipField: 'locations',    wrapText: true, },
  {
    headerName: 'Sources',
    field: 'source',
    width: 300,
    cellRenderer: (params) => {
      return (
        <ul>
          {params.data['source'].map((source, index) => (
            <li key={index}>
              <a
                href={source.href}
                target="_blank"
                rel="noreferrer"
                className="underline text-blue-500 hover:text-blue-700 focus:outline-none focus:underline"
              >
                {source.text}
              </a>
            </li>
          ))}
        </ul>
      );
    },
  },
  {
    headerName: 'Last Updated',
    field: 'last_updated',
  },
];
