import { Children, Fragment, useEffect, useState } from 'react';

import {
  Dialog,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  Transition,
} from '@headlessui/react';
import {
  ArrowsRightLeftIcon,
  Bars3Icon,
  CalendarIcon,
  ChartPieIcon,
  ChevronRightIcon,
  Cog8ToothIcon,
  DocumentDuplicateIcon,
  FireIcon,
  FolderIcon,
  PlusCircleIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import Cookies from 'js-cookie';
import {
  Link,
  Outlet,
  redirect,
  useLoaderData,
  useNavigate,
} from 'react-router-dom';

import { getHistoryList, getUserList } from '../api';
import noah_logo_black from '../logo-black.png';
import noah_logo_white from '../logo-white.png';
import { useHistory } from '../utils/context/history';
import { useLanguage } from '../utils/context/lang';
import { usePro } from '../utils/context/pro';
import { useTheme } from '../utils/context/theme-system';
import { useUser } from '../utils/context/user';

import { i18n } from './i18n/menu';

const langMap = {
  en: 'EN',
  zh: 'CN',
  jp: 'JP',
};

const teams = [
  { id: 1, name: 'Heroicons', href: '#', initial: 'H', current: false },
  { id: 2, name: 'Tailwind Labs', href: '#', initial: 'T', current: false },
  { id: 3, name: 'Workcation', href: '#', initial: 'W', current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// TODO MILD: ziwen. add ErrorBoundary
export function MainContainer({ children }) {
  const [ sidebarOpen, setSidebarOpen ] = useState(false);
  const { user, logout } = useUser();
  const { lang, toggleLanguage, selectLanguage } = useLanguage();
  const { historyResult } = useHistory();
  const { switchHistoryMenu, historyMenuOpen, switchAnswerLang } = usePro();
  const [ navigation, setNavigation ] = useState([
    {
      name: i18n.NEW_THREAD[lang],
      href: '/ask/',
      icon: PlusCircleIcon,
      current: false,
    },
    {
      name: i18n.DISCOVER[lang],
      href: '/discover/',
      icon: FireIcon,
      current: false,
    },
    {
      name: i18n.WORKFLOW[lang],
      href: '/workflow/',
      icon: ArrowsRightLeftIcon,
      current: false,
    },
    {
      name: i18n.HISTORY[lang],
      href: '/ask/history',
      icon: DocumentDuplicateIcon,
      current: false,
      children: [],
    },
  ]);
  // // reassign the value when lang changes
  // useEffect(() => {
  //   setNavigation((prev) => {
  //     return prev.map((item) => {
  //       if (item.name === i18n.NEW_THREAD[lang]) {
  //         return {
  //           ...item,
  //           name: i18n.NEW_THREAD[lang],
  //         };
  //       }
  //       if (item.name === i18n.HISTORY[lang]) {
  //         return {
  //           ...item,
  //           name: i18n.HISTORY[lang],
  //         };
  //       }
  //       return item;
  //     });
  //   });
  // }, [ lang ]);

  useEffect(() => {
    console.log('historyResult', historyResult);
    if (historyResult && historyResult.length) {
      setNavigation((prev) => {
        return prev.map((item) => {
          if (item.name === i18n.HISTORY[lang]) {
            return {
              ...item,
              children: historyResult.map((item) =>
                item.type === 'thread'
                  ? {
                    name: item.name,
                    href: `/ask/${item.id}`,
                    id: item.id,
                  }
                  : {
                    name: item.name,
                    href: `/workflow/${item.id}`,
                    id: item.id,
                  }
              ),
            };
          }
          return item;
        });
      });
    }
  }, [ historyResult ]);

  const { theme } = useTheme();

  console.log('rendering ask container');
  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-50">
        <body class="h-full">
        ```
      */}
      <div className="font-sans">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-primary-content"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-base-100 px-6 pb-2 scrollbar-none">
                    <div className="flex h-16 shrink-0 items-center">
                      <a href={`${location.origin}/ask/`}>
                        <img
                          className="h-8 w-auto"
                          src={
                            theme == 'light' ? noah_logo_black : noah_logo_white
                          }
                          alt="Noah"
                        />
                      </a>
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-2">
                        {navigation.map((item) => (
                          <li key={item.id}>
                            {!item.children ? (
                              <Link
                                to={item.href}
                                className={classNames(
                                  item.current
                                    ? 'bg-gray-50 text-primary'
                                    : 'text-secondary-content hover:text-accent-content hover:bg-accent',
                                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                )}
                              >
                                <item.icon
                                  className={classNames(
                                    item.current
                                      ? 'text-primary'
                                      : 'text-neutral-content group-hover:text-primary',
                                    'h-6 w-6 shrink-0'
                                  )}
                                  aria-hidden="true"
                                />
                                {item.name}
                              </Link>
                            ) : (
                              <Disclosure as="div">
                                <DisclosureButton
                                  className={classNames(
                                    item.current
                                      ? 'group flex w-full bg-gray-50 text-primary'
                                      : 'group flex w-full text-secondary-content hover:text-accent-content hover:bg-accent',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                  onClick={() => switchHistoryMenu()}
                                >
                                  <item.icon
                                    aria-hidden="true"
                                    className="h-6 w-6 shrink-0 text-gray-400"
                                  />
                                  {item.name}
                                  <ChevronRightIcon
                                    aria-hidden="true"
                                    className="ml-auto h-5 w-5 shrink-0 text-gray-400 group-data-[open]:rotate-90 group-data-[open]:text-gray-500"
                                  />
                                </DisclosureButton>
                                <DisclosurePanel as="ul" className="mt-1 px-2">
                                  {item.children.map((subItem) => (
                                    <li key={subItem.name}>
                                      {/* 44px */}
                                      <DisclosureButton
                                        as="a"
                                        href={subItem.href}
                                        className={classNames(
                                          subItem.current
                                            ? 'bg-gray-50'
                                            : 'hover:bg-gray-50',
                                          'block rounded-md py-2 pl-9 pr-2 text-sm leading-6 text-gray-700'
                                        )}
                                      >
                                        {subItem.name}
                                      </DisclosureButton>
                                    </li>
                                  ))}
                                </DisclosurePanel>
                              </Disclosure>
                            )}
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-secondary bg-base-100 px-6 scrollbar-none">
            <div className="flex h-16 shrink-0 items-center">
              <a href={`${location.origin}/ask/`}>
                <img
                  className="h-8 w-auto"
                  src={theme == 'light' ? noah_logo_black : noah_logo_white}
                  alt="Your Company"
                />
              </a>
            </div>
            {/* <p onClick={toggleTheme}>{theme}</p> */}
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-2">
                {navigation.map((item) => (
                  <li key={item.name}>
                    {/* {!item.children ? ( */}
                    <Link
                      to={item.href}
                      className={classNames(
                        item.current
                          ? 'bg-gray-50 text-primary'
                          : 'text-secondary-content hover:text-accent-content hover:bg-accent',
                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.current
                            ? 'text-primary'
                            : 'text-neutral-content group-hover:text-primary',
                          'h-6 w-6 shrink-0'
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </Link>
                    {/* ) : (
                      <Disclosure
                        as="div"
                        className="mb-12 overflow-hidden"
                        defaultOpen={true}
                      >
                        <DisclosureButton
                          className={classNames(
                            item.current
                              ? 'group flex w-full bg-gray-50 text-primary'
                              : 'group flex w-full text-secondary-content hover:text-accent-content hover:bg-accent',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          <item.icon
                            aria-hidden="true"
                            className="h-6 w-6 shrink-0 text-gray-400"
                          />
                          <Link to={item.href}>{item.name}</Link>
                          <ChevronRightIcon
                            aria-hidden="true"
                            className="ml-auto h-5 w-5 shrink-0 text-gray-400 group-data-[open]:rotate-90 text-secondary-content hover:text-accent-content hover:bg-accent"
                          />
                        </DisclosureButton>
                        <DisclosurePanel as="ul" className="mt-1 px-2">
                          {item.children.map((subItem) => (
                            <li key={subItem.id} className="">
                              <DisclosureButton className="relative block w-full text-left rounded-md text-nowrap overflow-hidden  text-sm leading-6 text-secondary-content hover:underline">
                                <Link
                                  to={subItem.href}
                                  className="block w-full py-2 pl-9 pr-2"
                                >
                                  {subItem.name}
                                  <div className="absolute inset-y-0 right-0 w-6 bg-gradient-to-r from-transparent to-base-100 pointer-events-none"></div>
                                </Link>
                              </DisclosureButton>
                            </li>
                          ))}
                        </DisclosurePanel>
                      </Disclosure>
                    )}*/}
                  </li>
                ))}

                {/* <li>
                  <div className="text-xs font-semibold leading-6 text-neutral-content">
                    Your teams
                  </div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    {teams.map((team) => (
                      <li key={team.name}>
                        <a
                          href={team.href}
                          className={classNames(
                            team.current
                              ? "bg-gray-50 text-primary"
                              : "text-secondary-content hover:text-accent-content hover:bg-accent",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          <span
                            className={classNames(
                              team.current
                                ? "text-primary border-primary"
                                : "text-neutral-content border-gray-200 group-hover:border-primary group-hover:text-primary",
                              "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-base-100"
                            )}
                          >
                            {team.initial}
                          </span>
                          <span className="truncate">{team.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </li> */}
                <li className="-mx-6 mt-auto absolute bottom-0 w-full bg-base-100 border-r border-t border-secondary">
                  
                  {!user && (
                    <div className="relative gap-x-4 px-6 py-2 text-sm font-semibold leading-6 text-secondary-content cursor-pointer text-center">
                      <a
                        className="block relative py-2 rounded my-4 bg-blue-600 hover:bg-blue-500 text-white"
                        href="/signup"
                      >
                        {i18n.REGISTER[lang]}
                      </a>
                      <a
                        className="block relative py-2 rounded my-4 bg-base-200 hover:bg-base-300 text-secondary-content"
                        href="/signin?redirectUrl=ask"
                      >
                        {i18n.LOGIN[lang]}
                      </a>
                    </div>
                  )}
                  {user?.username && (
                    <div className="gap-x-4 px-6 py-5 text-sm font-semibold leading-6 text-secondary-content cursor-pointer hover:bg-secondary hover:text-secondary-content">
                      {/* <img
                      className="h-8 w-8 rounded-full bg-gray-50"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    /> */}
                      <Popover className="relative flex justify-between items-center">
                        <Popover.Button className="relative flex justify-between w-full focus:outline-none">
                          <span className="sr-only">Your profile</span>
                          <span aria-hidden="true">{user?.username}</span>

                          <Cog8ToothIcon className="h-6 w-6 ui-open:rotate-180 ui-open:transform" />
                        </Popover.Button>
                        <Popover.Panel className="absolute -top-40 bg-base-100 right-0 w-40 origin-top-right px-4 overflow-hidden rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Link
                            className="relative py-2 block text-secondary-content"
                            to="#"
                          >
                            <select
                              onChange={(e) => {
                                switchAnswerLang(langMap[e.target.value]); // switch explore answer as well.
                                selectLanguage(e.target.value);
                              }}
                              className="select select-bordered w-full max-w-xs"
                            >
                              <option value="en" selected={lang === 'en'}>
                                English
                              </option>
                              <option value="zh" selected={lang === 'zh'}>
                                中文
                              </option>
                            </select>
                          </Link>
                          <Link
                            className="relative py-2 block text-secondary-content"
                            to="/contact"
                            target="_blank"
                          >
                            <p>{i18n.FEEDBACK[lang]}</p>
                          </Link>
                          <Link
                            className="relative py-2 block text-secondary-content"
                            to="#"
                            onClick={() => (window.location.href = '/logout')}
                          >
                            <p>{i18n.LOGOUT[lang]}</p>
                          </Link>
                        </Popover.Panel>
                      </Popover>
                    </div>
                  )}
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-base-100 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-secondary-content lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-secondary-content">
            Dashboard
          </div>
          <div>
            <Popover className="relative">
              <Popover.Button className="relative flex justify-center focus:outline-none">
                <span className="sr-only">Your profile</span>
                {/* <img
              className="h-8 w-8 rounded-full bg-gray-50"
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
            /> */}
                <span aria-hidden="true">{user?.username}</span>

                <Cog8ToothIcon className="h-6 w-6 ui-open:rotate-180 ui-open:transform" />
              </Popover.Button>
              <Popover.Panel className="absolute right-0 w-40 origin-top-right px-4 overflow-hidden rounded-md bg-base-100 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <Link
                  className="relative py-1 pl-3 pr-9 text-secondary-content"
                  to="/contact"
                  target="_blank"
                >
                  <p>{i18n.FEEDBACK[lang]}</p>
                </Link>
                <Link
                  className="relative py-1 pl-3 pr-9 text-secondary-content"
                  to="#"
                  onClick={() => (window.location.href = '/logout')}
                >
                  <p>{i18n.LOGOUT[lang]}</p>
                </Link>
              </Popover.Panel>
            </Popover>
          </div>
        </div>

        <main className="lg:pl-72">
          <Outlet />
          {children}
        </main>
      </div>
    </>
  );
}

export async function mainContainerLoader() {
  // const a = await getUserList();
  // TODO MILD: follow https://github.com/remix-run/react-router/discussions/9856
  // currently in loader, we cannot use context or hooks

  //  get user info from localstorage
  console.log('in ask container loader');
  const userName = Cookies.get('userName') || Cookies.get('noahUser'); //noahUser is the same as email. as required by Backend.
  if (userName) {
    console.log('有username!', userName);
    // const data = await getHistoryList();
    // console.log('jbjbjbjb', data)
    // return data||{};
    return userName;
  } else {
    console.log('no username, need login');
    // console.log('登录啊')
    location.replace('/signin');
    return null;
  }
}
