const MEET_AGENT = {
  en: 'Meet your assistant',
  zh: '认识您的助理',
}

const FIND_WORKFLOW = {
  en: 'Find workflow',
  zh: '高效的工作流',
}

const ENHANCE_RESEARCH = {
  en: 'Enhance your research efficiency',
  zh: '加速您的研究效率',
}

const DAILY_LIMIT_ERROR = {
  en: 'You have reached your monthly pro usage limit',
  zh: '您已达到每月专业版使用限制',
}

const DAILY_LIMIT_HINT1 = {
  en: 'The daily limit for your trial account is 50 questions per month.',
  zh: '您的试用帐户每月限制为50个问题。',
}

const DAILY_LIMIT_HINT2 = {
  en: 'You can upgrade your account to continue',
  zh: '您可以升级您的帐户以继续使用。',
}

const NO_AGENT_TYPE = {
  en: 'No content found',
  zh: '未找到内容',
}

const CONNECTION_CLOSED = {
  en: 'The connection was closed. Please refresh the page to continue.',
  zh: '连接已关闭。请刷新页面继续使用。',
}

const CONNECTION_ERROR = {
  en: 'Connection error. Please refresh the page to continue.',
  zh: '连接错误。请刷新页面继续使用。',
}
const CLINICAL_DESIGN = {
  en: 'Clinical Design Comparison',
  zh: '临床研究设计比较',
}

const CLINICAL_RESULT = {
  en: 'Clinical Result Comparison',
  zh: '临床研究结果比较',
}
const RESULT = {
  en: 'Result',
  zh: '结果',
}

const THIRTY_LIMITS = {
  en: 'Only show the first 30 results',
  zh: '最多仅显示前30个结果',
}

const ASH = {
  en: 'Explore ASH',
  zh: '探索ASH年会',
}

const LOGIN_MESSAGE = {
  en: 'You need to login to continue',
  zh: '您需要登录才能继续',
}

const PROCEED = {
  en: 'Proceed',
  zh: '继续',
}

const CREATE_NEW = {
  en: 'Create new',
  zh: '新建',
}

export const i18n = {
  MEET_AGENT,
  FIND_WORKFLOW,
  ENHANCE_RESEARCH,
  DAILY_LIMIT_ERROR,
  DAILY_LIMIT_HINT1,
  DAILY_LIMIT_HINT2,
  NO_AGENT_TYPE,
  CONNECTION_CLOSED,
  CONNECTION_ERROR,
  RESULT,
  THIRTY_LIMITS,
  CREATE_NEW,
  LOGIN_MESSAGE,
  PROCEED,
  'clinical-design':CLINICAL_DESIGN,
  'clinical-result':CLINICAL_RESULT,
  'ash':ASH,
}