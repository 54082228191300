const paperContent = [];
export default function PaperDetail({ paper }) {
  return (
    <ul role="list" className="divide-y divide-gray-100">
      <li key="abstract" className="relative flex justify-between gap-x-6 py-5">
        <div className="flex min-w-0 gap-x-4">
          <div className="min-w-0 flex-auto">
            <p className="text-sm font-semibold leading-6 text-secondary-content">
              Source
            </p>
            <p className="mt-1 flex text-xs leading-5 text-accent-content">
              <a href={paper.url}>{paper.url}</a>
            </p>
          </div>
        </div>
      </li>
      {/* <li key="abstract" className="relative flex justify-between gap-x-6 py-5">
        <div className="flex min-w-0 gap-x-4">
          <div className="min-w-0 flex-auto">
            <p className="text-sm font-semibold leading-6 text-secondary-content">
              Summary
            </p>
            <p className="mt-1 flex text-xs leading-5 text-accent-content">
              <ul className="list-inside list-disc">
                {paper?.Abstract_Gate_Reason_Dict?.summarization?.map((summary, index) => (
                  <li key={index}>{summary}</li>
                ))}
              </ul>
            </p>
          </div>
        </div>
      </li> */}
      <li key="abstract" className="relative flex justify-between gap-x-6 py-5">
        <div className="flex min-w-0 gap-x-4">
          <div className="min-w-0 flex-auto">
            <p className="text-sm font-semibold leading-6 text-secondary-content">
              Abstract
            </p>
            <p className="mt-1 flex text-xs leading-5 text-accent-content">
              {paper.abstract}
            </p>
          </div>
        </div>
      </li>
      {/* <li key="abstract" className="relative flex justify-between gap-x-6 py-5">
        <div className="flex min-w-0 gap-x-4">
          <div className="min-w-0 flex-auto">
            <p className="text-sm font-semibold leading-6 text-secondary-content">
              Keywords
            </p>
            <p className="mt-1 flex text-xs leading-5 text-accent-content">
              {paper?.Abstract_Gate_Reason_Dict?.keywords?.join(', ')}
            </p>
          </div>
        </div>
      </li> */}
    </ul>
  );
}
