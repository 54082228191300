import DownloadBtn from '../../../downloadBtn';

const COLUMNS = [
  '登记号',
  '药物名称',
  '试验专业题目',
  '试验分期',
  '试验状态',
  '适应症',
  '首次公示信息日期',
];
export default function ClinicalTable({ clinicalInfo }) {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="text-right mb-2">
            <DownloadBtn data={clinicalInfo} filename="clinicalInfo" />
          </div>
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  {COLUMNS.map((column, index) => (
                    <th
                      scope="col"
                      key={index}
                      className="px-3 py-3.5 text-left text-sm font-semibold text-secondary-content whitespace-nowrap"
                    >
                      {column}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {clinicalInfo.map((clinical,index) => (
                  <tr key={index}>
                    {COLUMNS.map((column, index) => (
                      <td
                        key={index}
                        className="py-4 pl-4 pr-3 text-sm font-medium text-secondary-content min-w-md"
                      >
                        {clinical[column]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
