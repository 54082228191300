import { useEffect, useRef, useState } from 'react';

import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import {
  BeakerIcon,
  CalendarIcon,
  MapPinIcon,
} from '@heroicons/react/20/solid'
import { useQuery } from '@tanstack/react-query';
import { format, isWithinInterval, parseISO, set } from 'date-fns';
import Cookies from 'js-cookie';
// import { WebSocket } from 'mock-socket';
import { Navigate, redirect, useLoaderData, useParams } from 'react-router-dom';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import { useAlert } from '../utils/context/alert.js';
import { useLanguage } from '../utils/context/lang.js';

import { Calendar } from './components/calendar.js';
import { ClinicItem } from './components/clinicItem.js';
// import Slider from './components/slider/index.js';
// import PdfViewer from './components/slider/pdf.js';
import { agents } from './const/agent.js';
import { i18n } from './i18n/chat.js';
import { catalyst } from './mock/catalysts_sorted.js';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
// const days = catalyst_6.slice(0, 31)

// const selectedDay = days.find((day) => day.isSelected)
const all_meetings = catalyst;

// console.log(all_meetings)

const isDateWithinRange = (selectedDate, beginDate, endDate) => {
  console.log(selectedDate, beginDate, endDate)
  const selected = parseISO(selectedDate);
  console.log(1)
  const begin = parseISO(beginDate);
  console.log(2)
  const end = parseISO(endDate);
  console.log(selected, begin, end)
  return isWithinInterval(selected, { start: begin, end: end });
};

export function CalendarDetail() {
  const showAlert = useAlert();
  const { lang } = useLanguage();
  const today = format(new Date(), 'yyyy-MM-dd');
  const [ selectedDate, setSelectedDate ] = useState(today);
  const { urlAgentType } = useParams(); // path: new/urlAgentType
  const [ paddingBottom, setPaddingBottom ] = useState('pb-12');
  const [ meetings, setMeetings ] = useState(all_meetings.filter((meeting) => isDateWithinRange(today, meeting['Expected Begin Date'], meeting['Expected End Date'])));


  const handleDateClick = (date) => {
    setSelectedDate(date);
    console.log('Selected date:', date); // You can perform other actions with the selected date here
    setMeetings(all_meetings.filter((meeting) => isDateWithinRange(date, meeting['Expected Begin Date'], meeting['Expected End Date'])));
  };
  return (

    <div
      className={`relative bg-base-100 w-full h-full flex-1 ${paddingBottom}`}
    >
      <div className="relative px-4 sm:px-6 lg:px-8 my-4">
        {/* <img src="/misc/ASCO-2024-logo.webp" alt="ASCO 2024" className="w-2/3 h-auto mx-auto" /> */}
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-[46px] font-semibold text-primary-content">Catalyst Calendar</h1>
            
          </div>
        </div>
          
        {/* <div className="relative -mx-4 mt-8 sm:-mx-0 overflow-x-auto overflow-y-scroll h-[780px]"> */}
        <div className="lg:flex lg:h-full lg:flex-col">
          <div className="relative lg:grid lg:grid-cols-12 lg:gap-x-16">
            <div className="text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-9">
              <Calendar
                selectedDate={selectedDate}
                onDateClick={handleDateClick}
              />
            </div>
            <ol className="mt-4 divide-y divide-gray-100 text-sm leading-6 lg:col-span-7 xl:col-span-8">
               
              {meetings.map((meeting, index) => (
                <ClinicItem key={index} meeting={meeting} />
              ))}
            </ol>

          </div>
        </div>
      </div>
    </div>
    // </div>
      

  );
}

export async function calendarDetailLoader({ params }) {
  return [

  ]
}
