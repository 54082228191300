import { useEffect, useRef, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import Cookies from 'js-cookie';
// import { WebSocket } from 'mock-socket';
import { Navigate, redirect, useLoaderData, useParams } from 'react-router-dom';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import { useAlert } from '../utils/context/alert.js';
import { useLanguage } from '../utils/context/lang.js';
import { getFinalAgent } from '../utils/helpers.js';
import { sleep } from '../utils/sleep.js';


// import Slider from './components/slider/index.js';
// import PdfViewer from './components/slider/pdf.js';
import { agents } from './const/agent.js';
import { i18n } from './i18n/chat.js';
import { asco as asco_june } from './mock/2024_06_asco.js';
import { asco } from './mock/2024_ASCO.js';

export function DiscoverDetail() {
  const showAlert = useAlert();
  const { lang } = useLanguage();

  const { discoverId } = useParams();
  console.log('=============', discoverId)

  let ascoData = undefined
  let title = undefined
  let description = undefined
  if (discoverId === '2024-06-asco') {
    ascoData = asco_june;
    title = 'ASCO 2024 Update - Plenary and Oral Sessions';
  } else if (discoverId === '2024-asco') {
    ascoData = asco;
    title = 'Summary from ASCO 2024';
  }
  const [ paddingBottom, setPaddingBottom ] = useState('pb-12');

  return (

    <div
      className={`relative bg-base-100 w-full h-full flex-1 ${paddingBottom}`}
    >
      <div className="relative px-4 sm:px-6 lg:px-8 my-4">
        <img src="/misc/ASCO-2024-logo.webp" alt="ASCO 2024" className="w-2/3 h-auto mx-auto" />
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-[46px] font-semibold text-primary-content">{title}</h1>
            <p className="text-xl mt-2 text-sm text-primary-content">
              Comprehensive Overview of Innovative Cancer Treatments and Research Findings from the Annual Meeting.
            </p>
            <p className="text-xl mt-2 text-sm text-primary-content">
              Please reference below table to gather detailed summary of a clinical trial including key information such as the indication, the drug used, and outcomes like progression-free survival, overall survival, overall response rate, adverse events, and other relevant data
            </p>
          </div>
        </div>
          
        <div className="relative -mx-4 mt-8 sm:-mx-0 overflow-x-auto overflow-y-scroll h-[780px]">
          <table className="relative w-full divide-y divide-gray-300 overflow-x-scroll">
            <thead className="bg-gray-800 text-white sticky top-0 z-10">
              <tr>
                <th className="text-left py-3 px-4 uppercase font-semibold text-sm w-1/6 sticky left-0 bg-gray-800">Title</th>
                <th className="text-left py-3 px-4 uppercase font-semibold text-sm w-1/8">Indication</th>
                <th className="text-left py-3 px-4 uppercase font-semibold text-sm w-1/8">Drug</th>
                <th className="text-left py-3 px-4 uppercase font-semibold text-sm w-1/4">PFS</th>
                <th className="text-left py-3 px-4 uppercase font-semibold text-sm w-1/4">OS</th>
                <th className="text-left py-3 px-4 uppercase font-semibold text-sm w-1/8">ORR</th>
                <th className="text-left py-3 px-4 uppercase font-semibold text-sm w-1/8">AE</th>
                <th className="text-left py-3 px-4 uppercase font-semibold text-sm w-1/4">Other</th>
                <th className="text-left py-3 px-4 uppercase font-semibold text-sm w-1/4">Conclusion</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {ascoData.Sheet1.map((item,index) => (
                <tr key={index}>
                  <td className="text-left py-3 px-4 whitespace-normal break-words max-w-xs sticky left-0 bg-white shadow-inner-right">
                    {item.Title}
                  </td>
                  <td className="text-left py-3 px-4">{item.Indication}</td>
                  <td className="text-left py-3 px-4">{item.Drug}</td>
                  <td className="text-left py-3 px-4">{item.PFS}</td>
                  <td className="text-left py-3 px-4">{item.OS}</td>
                  <td className="text-left py-3 px-4">{item.ORR}</td>
                  <td className="text-left py-3 px-4">{item.AE}</td>
                  <td className="text-left py-3 px-4">{item.Other}</td>
                  <td className="text-left py-3 px-4">{item.Conclusion}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
      

  );
}

export async function discoverDetailLoader({ params }) {
  return [

  ]
}
