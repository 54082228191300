const clinicalResultInputFields = {
  nctId: {
    display: 'NCT ID',
    component: 'NctIdInput',
    name: 'ids',
  },
  drug: {
    display: 'Drug',
    component: 'DrugInput',
    name: 'drugs',
  },
  Company: {
    display: 'Company',
    component: 'CompanySelect',
    name: 'companies',
  },
  disease: {
    display: 'Disease',
    component: 'DiseaseInput',
    name: 'disease',
  },
  phases: {
    display: 'Phases',
    component: 'PhaseSelect',
    name: 'filter_phases',
  },
  status: {
    display: 'Status',
    component: 'StatusSelect',
    name: 'filter_status',
  },
  // studyTypes: {
  //   display: 'Study Types',
  //   component: 'StudyTypeSelect',
  //   name: 'filter_study_types',
  // },
  locations: {
    display: 'Locations',
    component: 'LocationSelect',
    name: 'filter_locations',
  },

  targets: {
    display: 'Targets',
    component: 'TargetInput',
    name: 'target_expression',
  },
};
const clinicalComparisonInputFields = { ...clinicalResultInputFields,   gender: {
  display: 'Gender',
  component: 'GenderSelect',
  name: 'filter_gender',
} };

const ashInputFields = {
  title: {
    display: 'Title',
    component: 'MiscInput',
    name: 'title',
    title: 'Title',
    placeholder: 'type in the title',
  },
  treatment: {
    display: 'Treatment',
    component: 'MiscInput',
    name: 'intervention',
    title: 'Treatment',
    placeholder: 'type in the treatment',
  },
  targets: {
    display: 'Targets',
    component: 'MiscInput',
    name: 'target',
    title: 'Targets',
    placeholder: 'type in the targets',
  },
  disease: {
    display: 'Disease',
    component: 'MiscInput',
    name: 'disease',
    title: 'Disease',
    placeholder: 'type in the disease',
  },
}

const workflowComparisonInput = {
  id: 1,
  title: '临床研究设计比较',
  href: '/workflow/new/clinical-design',
  params: Object.values(clinicalComparisonInputFields),
}

const workflowResultInput = {
  id: 2,
  title: '临床结果比较',
  href: '/workflow/new/clinical-result',
  params: Object.values(clinicalResultInputFields),
}

const ashInput = {
  id: 3,
  title: 'ASH',
  href: '/workflow/new/ash',
  params: Object.values(ashInputFields),
}

export const ALL_WORKFLOWS = [
  workflowComparisonInput,
  workflowResultInput,
  ashInput,
];

export const getWorkflowInputByType = (type) => {
  const map = {
    'clinical-design': workflowComparisonInput,
    'clinical-result': workflowResultInput,
    'ash': ashInput,
  };
  return map[type];
}