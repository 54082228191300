import { ASH_COLUMN } from './ash';
import { CLINICAL_DESIGN_COLUMN } from './clinical-design';
import { CLINICAL_RESULT_COLUMN } from './clinical-result';

export const getWorkflowTableColumnByType = (type) => {
  const map = {
    'CLINICAL_DESIGN_TABLE': CLINICAL_DESIGN_COLUMN,
    'CLINICAL_RESULT_TABLE': CLINICAL_RESULT_COLUMN,
    'ASH_PAPERS_TABLE': ASH_COLUMN,
  };
  return map[type];
}