const SUCCESS_COPY = {
  en: 'Content has been successfully copied to the clipboard.',
  zh: '内容已成功复制到剪贴板。',
};
  
const FAILED_COPY = {
  en: 'Failed to copy content to clipboard.',
  zh: '无法复制内容到剪贴板。',
}

const THOUGHT = {
  en: 'Thought',
  zh: '思考过程',
}

const UPGRADE_HINT = {
  en: 'Upgrade to Pro to unlock more features',
  zh: '升级到专业版以解锁更多功能',
}

const ANALYZING_UPLOAD = {
  en: 'Analyzing upload...',
  zh: '正在分析上传...',
}

const NEWS = {
  en: 'Picks For You',
  zh: '为您推荐',
};

const TOP_STORIES = {
  en: 'Top Stories',
  zh: '头条新闻',
};

const CURATED_BY = {
  en: '',
  zh: '',
};

const CONTEXT_TITLE = {
  en: 'Follow up to:',
  zh: '继续讨论',
};

const RELATED = {
  en: 'Related',
  zh: '相关问题',
}

const THOUGHT_BREAK_DOWN = {
  en: 'Thought Process and Task Breakdown',
  zh: '思考和任务分解',
}

const SEARCH_SUMMARY = {
  en: 'Search Summary:',
  zh: '搜索总结：',
}

const SEARCH_RESULTS = {
  en: 'Search Results:',
  zh: '搜索结果：',
}

export const i18n = {
  SUCCESS_COPY,
  FAILED_COPY,
  THOUGHT,
  UPGRADE_HINT,
  ANALYZING_UPLOAD,
  NEWS,
  TOP_STORIES,
  CURATED_BY,
  CONTEXT_TITLE,
  RELATED,
  THOUGHT_BREAK_DOWN,
  SEARCH_SUMMARY,
  SEARCH_RESULTS,
}
