import React from 'react';

import { useLanguage } from '../../utils/context/lang';
import { i18n } from '../i18n/common';

const ChatSection = ({ imageUrl, role, children }) => {
  const { lang } = useLanguage();
  return (
    <div className="flex text-base mx-auto mb-4 gap-3 group max-w-5xl">
      {/* <div className="flex-shrink-0 flex flex-col relative items-end">
        <div className="w-10">
          <img className="rounded-full" src={imageUrl} />
        </div>
      </div> */}
      <div className="relative flex w-full flex-col lg:w-[calc(100%)] text-primary-content">
        <div className="font-semibold select-none">{role==='you'? '': i18n.ANSWER[lang]}</div>
        <div className={`flex-col gap-1 md:gap-3 ${role==='you'&&'text-xl font-bold'}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ChatSection;