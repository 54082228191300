import React, { useMemo, useRef } from 'react';

import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { all } from 'axios';

import { getWorkflowTableColumnByType } from '../const/table-const';

const TableComponent = ({ rowData,type }) => {
  console.log('909090909090909090', rowData)

  const gridRef = useRef(null);
  const columnDefs = getWorkflowTableColumnByType(type);
  if (!rowData) {
    rowData = [
      {
        trialTitle:
          'The Farnesoid X Receptor (FXR) Ligand Obeticholic Acid in NASH Treatment Trial (FLINT)',
        identifier: 'NCT01265498',
        phase: 'Phase 3',
        patientsNumber: 2480,
        duration: '72 weeks',
        keyInclusionCriteria:
          'Biopsy-confirmed NASH patients with F2, F3 and high-risk F1 fibrosis',
        arm: '对照组',
        fibrosis: 'xxxxx',
        nashResolution: '8%',
        pValue1: 'n/a',
        fatReduction: '8%',
        pValue2: 'n/a',
        otherOutcome: '8%',
        pValue3: 'n/a',
        isHeader: true,
      },
      {
        trialTitle:
          'The Farnesoid X Receptor (FXR) Ligand Obeticholic Acid in NASH Treatment Trial (FLINT)',
        identifier: 'NCT01265498',
        phase: 'Phase 3',
        patientsNumber: 2480,
        duration: '72 weeks',
        keyInclusionCriteria:
          'Biopsy-confirmed NASH patients with F2, F3 and high-risk F1 fibrosis',
        arm: '实验组1',
        fibrosis: 'xxxxx',
        nashResolution: '11%',
        pValue1: '0.02',
        fatReduction: '11%',
        pValue2: '0.02',
        otherOutcome: '11%',
        pValue3: '0.02',
        isHeader: false,
      },
      {
        trialTitle:
          'The Farnesoid X Receptor (FXR) Ligand Obeticholic Acid in NASH Treatment Trial (FLINT)',
        identifier: 'NCT01265498',
        phase: 'Phase 3',
        patientsNumber: 2480,
        duration: '72 weeks',
        keyInclusionCriteria:
          'Biopsy-confirmed NASH patients with F2, F3 and high-risk F1 fibrosis',
        arm: '实验组2',
        fibrosis: 'xxxxx',
        nashResolution: '15%',
        pValue1: '0.6',
        fatReduction: '15%',
        pValue2: '0.6',
        otherOutcome: '15%',
        pValue3: '0.001',
        isHeader: false,
      },
      {
        trialTitle:
          'A Study to Assess the Safety, Tolerability, Pharmacokinetics and Efficacy of EDP-305 in Subjects With Non-Alcoholic Steatohepatitis',
        identifier: 'NCT03421431',
        phase: 'Phase 2',
        patientsNumber: 283,
        duration: '72 weeks',
        keyInclusionCriteria: 'Biopsy-confirmed NASH patients',
        arm: '对照组',
        fibrosis: 'xxxxx',
        nashResolution: '8%',
        pValue1: 'n/a',
        fatReduction: '8%',
        pValue2: 'n/a',
        otherOutcome: '8%',
        pValue3: 'n/a',
        isHeader: true,
      },
      {
        arm: '实验组1',
        fibrosis: 'xxxxx',
        nashResolution: '11%',
        pValue1: '0.02',
        fatReduction: '11%',
        pValue2: '0.42',
        otherOutcome: '11%',
        pValue3: '0.02',
        isHeader: false,
      },
      {
        arm: '实验组2',
        fibrosis: 'xxxxx',
        nashResolution: '15%',
        pValue1: '0.001',
        fatReduction: '15%',
        pValue2: '0.42',
        otherOutcome: '15%',
        pValue3: '0.6',
        isHeader: false,
      },
      {
        trialTitle:
          'Randomized Global Phase III Study to Evaluate the Impact on NASH with Fibrosis of Obeticholic Acid Treatment (REGENERATE)',
        identifier: 'NCT02854605',
        phase: 'Phase 2',
        patientsNumber: 134,
        duration: '12 weeks',
        keyInclusionCriteria: 'NASH patients with liver fat > 8%',
        arm: '对照组',
        fibrosis: 'xxxxx',
        nashResolution: '8%',
        pValue1: 'n/a',
        fatReduction: '8%',
        pValue2: 'n/a',
        otherOutcome: '8%',
        pValue3: 'n/a',
        isHeader: true,
      },
      {
        arm: '实验组1',
        fibrosis: 'xxxxx',
        nashResolution: '11%',
        pValue1: '0.02',
        fatReduction: '11%',
        pValue2: '0.02',
        otherOutcome: '11%',
        pValue3: '0.02',
        isHeader: false,
      },
      {
        arm: '实验组2',
        fibrosis: 'xxxxx',
        nashResolution: '15%',
        pValue1: '0.001',
        fatReduction: '15%',
        pValue2: '0.42',
        otherOutcome: '15%',
        pValue3: '0.6',
        isHeader: false,
      },
    ];
  }
  const defaultColDef = useMemo(() => {
    return {
      width: 170,
      sortable: false,
    };
  }, []);
  return (
    <div className="h-[500px] mb-12" style={{ width: '100%' }}>
      <div className="text-right">
        <button
          className="rounded bg-brand px-2 py-1 text-xs font-semibold text-white shadow-sm mr-2 mb-2"
          onClick={() => {
            gridRef.current.api.exportDataAsCsv({
              allColumns: true,
            });
          }}
        >
          <ArrowDownTrayIcon className="h-4 w-4 inline-block" />
          {/* Download as CSV file */}
        </button>
      </div>
      
      {/* <>
        <div className="flex items-center gap-x-2 mb-2">
          <div className="w-4 h-4 bg-yellow-400"></div>
          <p className="text-xs">Estimated</p>
        </div>
        <div className="flex items-center gap-x-2 mb-2">
          <div className="w-4 h-4 bg-purple-400"></div>
          <p className="text-xs">Actual</p>
        </div>
      </> */}
      <AgGridReact
        ref={gridRef}
        rowHeight={150}
        columnDefs={columnDefs}
        rowData={rowData || []}
        suppressRowTransform={true}
        defaultColDef={defaultColDef}
        allColumns
        // enableRangeSelection={true}
        enableCellTextSelection={true}
        // rowSelection='multiple' // use for multiple selection
        // suppressRowClickSelection={true}
      />
    </div>
  );
};

export default TableComponent;
