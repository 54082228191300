// const a = {
//   "nctid": "NCT01687998",
//   "indication_name": "Dyslipidemia / Hypercholesterolemia",
//   "official_title": "Assessment of Clinical Effects of Cholesteryl Ester Transfer Protein Inhibition With Evacetrapib in Patients at a High-Risk for Vascular Outcomes",
//   "acronym": "ACCELERATE",
//   "drug_name": "Evacetrapib",
//   "lead_company": "Eli Lilly and Company (LLY)",
//   "current_status": "Final Data",
//   "phase": "III",
//   "locations": [
//     "New Zealand",
//     "Germany",
//     "Mexico",
//     "United States",
//     "South Africa",
//     "Netherlands",
//     "Denmark",
//     "Japan"
//   ],
//   "actual_enrollment": null,
//   "initiation_date": "2012-09-01",
//   "actual_completion_date": null,
//   "estimated_completion_date": "2016-01-01",
//   "endpoints": "## primary endpoint:\n * Time to First Occurence of the Composite Endpoint of Cardiovascular (CV) Death, Myocardial Infarction (MI), Stroke, Coronary Revascularization, or Hospitalization for Unstable Angina (UA) (Baseline to Study Completion (estimated to be up to 4 years))\n## secondary endpoint:\n * Time to First Occurrence of the Composite Endpoint of All-Cause Mortality, MI, Stroke, Coronary Revascularization, or Hospitalization for UA (Baseline through End of Study (estimated up to 4 years))\n* Mean Percent Change from Baseline to 3 Months in Low-Density (LDL-C) and High-Density Lipoprotein Cholesterol (HDL-C) levels (Baseline, 3 Months)\n* Time to First Occurrence of Composite Endpoint of CV Death, MI, or Stroke (Baseline through End of Study (estimated up to 4 years))\n* Time to First Occurrence of the Composite Endpoint of CV Death, MI, or Coronary Revascularization (Baseline through End of Study (estimated up to 4 years))\n* Time to First Occurrence of the Composite Endpoint of CV Death, MI, Stroke, or Hospitalization for UA (Baseline through End of Study (estimated up to 4 years))",
//   "dose": "* Arm I\n  * Evacetrapib\t (Evacetrapib 130 mg tablet, administered orally once , daily for up to 4 years.  Participants will also receive standard of care for high-risk vascular disease (HRVD).)\n* Arm II\n  * Placebo\t (Placebo, tablet administered orally once, daily for up to 4 years.  Participants will also receive standard of care for HRVD.)",
//   "blind method": "Double Blind",
//   "randomized": "Randomized"
// }
import DisplayFormattedText from '../../../AskPage/components/markdown';

export const CLINICAL_DESIGN_COLUMN = [
  {
    headerName: 'Trial ID',
    field: 'nctid',
    width: 140,
    pinned: 'left',
    cellRenderer: (params) => {
      return (
        <a
          href={`https://clinicaltrials.gov/study/${params.data['nctid']}`}
          target="_blank"
          rel="noreferrer"
          className="
    text-blue-500 hover:text-blue-700 focus:outline-none focus:underline"
        >
          {params.data['nctid']}
        </a>
      );
    },
  },
  { field: 'indication_name', tooltipField: 'indication_name', headerName: 'Indication' },
  { field: 'official_title', tooltipField: 'official_title', wrapText: true, headerName: 'Official Title' },
  { field: 'acronym', tooltipField: 'acronym', wrapText: true, headerName: 'Acronym' },
  { field: 'drug_name', tooltipField: 'drug_name', wrapText: true, headerName: 'Drug Name' },
  { field: 'lead_company', tooltipField: 'lead_company', wrapText: true, headerName: 'Lead Company' },
  { field: 'current_status', tooltipField: 'current_status', headerName: 'Current Status' },
  { field: 'phase', tooltipField: 'phase', width: 80, headerName: 'Phase' },
  { field: 'blind method', tooltipField: 'blind method', wrapText: true, headerName: 'Blind Method' },
  { field: 'randomized', tooltipField: 'randomized', headerName: 'Randomized' },
  { field: 'locations', tooltipField: 'locations', wrapText: true, headerName: 'Locations' },
  { field: 'actual_enrollment', tooltipField: 'actual_enrollment', width: 140,headerName: 'Enrollment',cellRenderer: (params) => {
    return `${params.data?.['enrollment']?.count} (${params.data?.['enrollment']?.type==='ACTUAL'?'Actual':'Estimated'})`;
  } },
  { field: 'start_date', tooltipField: 'initiation_date', width: 160,
    cellRenderer: (params) => {
      return `${params.data?.['start_date']?.date} (${params.data?.['start_date']?.type==='ACTUAL'?'Actual':'Estimated'})`;
    },
    headerName: 'Start Date'
  },
  { field: 'completion_date', tooltipField: 'completion_date', width: 160, 
    cellRenderer: (params) => {
      return `${params.data?.['completion_date']?.date} (${params.data?.['completion_date']?.type==='ACTUAL'?'Actual':'Estimated'})`;
    },
    headerName: 'Completion Date'
  },
  // { field: 'estimated_completion_date', tooltipField: 'estimated_completion_date' },
  {
    field: 'endpoints',
    tooltipField: 'endpoints',
    wrapText: true,
    width: 360,
    cellRenderer: (params) => {
      return <DisplayFormattedText text={params.data['endpoints']} />;
    },
    headerName: 'Endpoints',
  },
  {
    field: 'dose',
    tooltipField: 'dose',
    wrapText: true,
    cellRenderer: (params) => {
      return <DisplayFormattedText text={params.data['dose']} />;
    },
    width: 360,
    headerName: 'Dose',
  },

  // { field: 'Target', tooltipField: 'Target', wrapText: true },
  // { field: 'Drug', tooltipField: 'Drug', width: 240, wrapText: true },
  // { field: 'Sponsor', tooltipField: 'Sponsor', wrapText: true },
  // { field: 'Status', tooltipField: 'Status' },
  // { field: 'Phase', width: 120, tooltipField: 'Phase' },
  // {
  //   field: 'Blind method',
  //   width: 120,
  //   tooltipField: 'Blind method',
  //   wrapText: true,
  // },
  // { field: 'Randomized', width: 180, tooltipField: 'Randomized' },
  // {
  //   field: 'Endpoint',
  //   tooltipField: 'Endpoint',
  //   wrapText: true,
  //   cellRenderer: (params) => {
  //     return <p className="m-auto">{params.data['Endpoint']}</p>;
  //   },
  // },
  // { field: 'Dose', tooltipField: 'Dose', wrapText: true,
  //   cellRenderer: (params) => {
  //     // NOTE, align-items not working with overflow, solution: https://stackoverflow.com/questions/33454533/cant-scroll-to-top-of-flex-item-that-is-overflowing-container
  //     return <p className="m-auto">{params.data['Dose']}</p>;
  //   }
  // },
  // { field: 'Sites', tooltipField: 'Sites' },
  // {
  //   headerName: 'Enrollment',
  //   field: 'Enrollment',
  //   valueFormatter: (params) => {
  //     return '' + params.value;
  //   },
  //   width: 120,
  //   tooltipValueGetter: (params) => {
  //     return (
  //       '' +
  //       (params.data['Enrollment Type'] === 'ESTIMATED'
  //         ? ' Estimated'
  //         : params.data['Enrollment Type'] === 'ACTUAL'
  //           ? ' Actual'
  //           : '')
  //     );
  //   },
  //   headerTooltip: '黄色背景表示预计完成日期，紫色背景表示实际完成日期',
  //   cellClass: (param) => {
  //     console.log(param.value);
  //     if (!param.value) {
  //       return [];
  //     }
  //     if (param.data['Enrollment Type'] === 'ESTIMATED') {
  //       return [ 'bg-yellow-100' ];
  //     } else if (param.data['Enrollment Type']) {
  //       // 'ACTUAL'
  //       return [ 'bg-purple-100' ];
  //     }
  //   },
  // },
  // {
  //   field: 'Enrollment Type',
  //   hide: true,
  //   // suppressToolPanel: true,
  // },
  // {
  //   headerName: 'Study Start Date',
  //   field: 'Study Start Date',
  //   valueFormatter: (params) => {
  //     return '' + params.value;
  //   },
  //   width: 150,
  //   tooltipValueGetter: (params) => {
  //     return (
  //       '' +
  //       (params.data['Study Start Date Type'] === 'ESTIMATED'
  //         ? ' Estimated'
  //         : params.data['Study Start Date Type'] === 'ACTUAL'
  //           ? ' Actual'
  //           : '')
  //     );
  //   },
  //   headerTooltip: '黄色背景表示预计完成日期，紫色背景表示实际完成日期',
  //   cellClass: (param) => {
  //     console.log(param.value);
  //     if (!param.value) {
  //       return [];
  //     }
  //     if (param.data['Study Start Date Type'] === 'ESTIMATED') {
  //       return [ 'bg-yellow-100' ];
  //     } else if (param.data['Study Start Date Type']) {
  //       return [ 'bg-purple-100' ];
  //     }
  //   },
  // },
  // {
  //   field: 'Study Start Date Type',
  //   hide: true,
  //   suppressToolPanel: true,
  // },
  // {
  //   headerName: 'Primary Completion Date',
  //   field: 'Primary Completion Date',
  //   valueFormatter: (params) => {
  //     return '' + params.value;
  //   },
  //   width: 120,
  //   tooltipValueGetter: (params) => {
  //     return (
  //       '' +
  //       (params.data['Primary Completion Date Type'] === 'ESTIMATED'
  //         ? ' Estimated'
  //         : params.data['Primary Completion Date Type'] === 'ACTUAL'
  //           ? ' Actual'
  //           : '')
  //     );
  //   },
  //   headerTooltip: '黄色背景表示预计完成日期，紫色背景表示实际完成日期',
  //   cellClass: (param) => {
  //     console.log(param.value);
  //     if (!param.value) {
  //       return [];
  //     }
  //     if (param.data['Primary Completion Date Type'] === 'ESTIMATED') {
  //       return [ 'bg-yellow-100' ];
  //     } else if (param.data['Primary Completion Date Type']) {
  //       return [ 'bg-purple-100' ];
  //     }
  //   },
  // },
  // {
  //   field: 'Primary Completion Date Type',
  //   hide: true,
  //   suppressToolPanel: true,
  // },
  // {
  //   headerName: 'Study Completion Date',
  //   field: 'Study Completion Date',
  //   valueFormatter: (params) => {
  //     return '' + params.value;
  //   },
  //   width: 120,
  //   tooltipValueGetter: (params) => {
  //     return (
  //       '' +
  //       (params.data['Study Completion Date Type'] === 'ESTIMATED'
  //         ? ' Estimated'
  //         : params.data['Study Completion Date Type'] === 'ACTUAL'
  //           ? ' Actual'
  //           : '')
  //     );
  //   },
  //   headerTooltip: '黄色背景表示预计完成日期，紫色背景表示实际完成日期',
  //   cellClass: (param) => {
  //     console.log(param.value);
  //     if (!param.value) {
  //       return [];
  //     }
  //     if (param.data['Study Completion Date Type'] === 'ESTIMATED') {
  //       return [ 'bg-yellow-100' ];
  //     } else if (param.data['Study Completion Date Type']) {
  //       return [ 'bg-purple-100' ];
  //     }
  //   },
  // },
  // {
  //   field: 'Study Completion Date Type',
  //   hide: true,
  //   suppressToolPanel: true,
  // },
];
