import { utils, writeFile } from 'xlsx';

export function downloadXLSX(data, filename) {
  // quick filter data so there is no index as key and no complex object as value
  data = data.map((item) => {
    let newItem = {};
    for (let key in item) {
      if (typeof item[key] !== 'object' && key !== 'index') {
        newItem[key] = item[key];
      }
    }
    return newItem;
  });
  const workbook = utils.book_new();
  const worksheet = utils.json_to_sheet(data);
  utils.book_append_sheet(workbook, worksheet);
  // could use new headers if you don't want the default keys as headers
  // XLSX.utils.sheet_add_aoa(worksheet, [ [ 'Name', 'Birthday' ] ], { origin: 'A1' });
  writeFile(workbook, `${filename}.xlsx`, { compression: true });
}
