//write a function to convert 2023-11-14T03:15:50.918241Z to year/month/day/hour/minute/second ago
export function timeAgo(isoDateString, lang = 'en') {
  const now = new Date();
  const past = new Date(isoDateString);
  const diff = now - past;

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30); // Approximation
  const years = Math.floor(days / 365); // Approximation

  if (years > 0) return lang == 'en' ? `${years} year(s) ago` : `${years}年前`;
  if (months > 0)
    return lang == 'en' ? `${months} month(s) ago` : `${months}月前`;
  if (days > 0) return lang == 'en' ? `${days} day(s) ago` : `${days}天前`;
  if (hours > 0)
    return lang == 'en' ? `${hours} hour(s) ago` : `${hours}小时前`;
  if (minutes > 0)
    return lang == 'en' ? `${minutes} minute(s) ago` : `${minutes}分钟前`;
  return lang == 'en' ? `${seconds} second(s) ago` : `${seconds}秒前`;
}
