const MEET_AGENT = {
  en: 'Explore, Discover, Heal',
  zh: '探索, 发现, 治疗',
}

const ENHANCE_RESEARCH = {
  en: 'Enhance your research efficiency',
  zh: '加速您的研究效率',
}

const DAILY_LIMIT_ERROR = {
  en: 'You have reached your monthly pro usage limit',
  zh: '您已达到每月专业版使用限制',
}

const DAILY_LIMIT_HINT1 = {
  en: 'The daily limit for your trial account is 50 questions per month.',
  zh: '您的试用帐户每月限制为50个问题。',
}

const DAILY_LIMIT_HINT2 = {
  en: 'You can upgrade your account to continue',
  zh: '您可以升级您的帐户以继续使用。',
}

const NO_AGENT_TYPE = {
  en: 'No content found',
  zh: '未找到内容',
}

const CONNECTION_CLOSED = {
  en: 'The connection was closed. Please refresh the page to continue.',
  zh: '连接已关闭。请刷新页面继续使用。',
}

const CONNECTION_ERROR = {
  en: 'Connection error. Please refresh the page to continue.',
  zh: '连接错误。请刷新页面继续使用。',
}

const ABOUT = {
  en: 'About',
  zh: '关于',
}
export const i18n = {
  MEET_AGENT,
  ENHANCE_RESEARCH,
  DAILY_LIMIT_ERROR,
  DAILY_LIMIT_HINT1,
  DAILY_LIMIT_HINT2,
  NO_AGENT_TYPE,
  CONNECTION_CLOSED,
  CONNECTION_ERROR,
  ABOUT,
}