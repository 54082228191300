import { useEffect, useState } from 'react';

import { Select, Space } from 'antd';

// defaultValue is like { data: ['a', 'b'], logic: 'and' }

const extractOperator = (input) => {
  // Extract the logic operator from the input (and/or/not)
  return input.logic;
}

const extractValues = (input) => {
  // Extract the data (tags/values) from the input
  return input.data;
}

export default function AndOrInput({ onChange, defaultValue = { data: [], logic: 'or' }, placeholder, options = [], onSearch, filterOption=false }) {
  const [ operator, setOperator ] = useState(defaultValue.logic); // Can be 'and', 'or', or 'not'
  const [ tags, setTags ] = useState(defaultValue.data || []);

  const essieSyntaxBuilder = (params, operator) => {
    // Build the resulting object based on the operator
    return {
      data: params,
      logic: operator
    }
  };

  useEffect(() => {
    // Always trigger onChange whenever tags or operator change
    onChange(essieSyntaxBuilder(tags, operator));
  }, [ tags, operator ]);

  return (
    <Space.Compact style={{ width: '100%' }}>
      <Select
        className="w-20"
        defaultValue={extractOperator(defaultValue)}
        options={[
          { value: 'and', label: 'AND' },
          { value: 'or', label: 'OR' },
          { value: 'not', label: 'NOT' }  // Add the 'NOT' option
        ]}
        onChange={(value) => setOperator(value)}
      />
      <Select
        mode="tags"
        tokenSeparators={[ ',' ]}
        style={{ width: '100%' }}
        placeholder={placeholder}
        onChange={setTags}
        notFoundContent={null}
        options={options}
        defaultValue={extractValues(defaultValue)}
        onSearch={onSearch}
        filterOption={filterOption}
      />
    </Space.Compact>
  );
}
